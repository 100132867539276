import React from 'react'
import classNames from 'clsx'

import {Grid, makeStyles} from "@material-ui/core"

import {PageSizeSelector} from './PageSizeSelector'
import {Pagination} from './Pagination'
import {PageSelector} from "./PageSelector";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        "width": "100%",
    },
    pager: {
        padding: `${theme.spacing(1.375)}px ${theme.spacing(2.75)}px`,
        backgroundColor: "#e7eaee",
    }
}))

export const Pager = ({
    actions,
    currentPage,
    pageSizes,
    totalPages,
    pageSize,
    onCurrentPageChange,
    onPageSizeChange,
    totalCount,
    getMessage,
    className,
    ...restProps
}) => {
    const classes = useStyles()

    return (
        <Grid
            container
            direction="row"
            justify={actions ? "space-between" : "flex-end"}
            alignItems="center"
            className={classNames(classes.pager, className)}
            {...restProps}
        >
            <Grid item>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                    {!!pageSizes.length ? (
                        <Grid item>
                            <PageSizeSelector
                                pageSize={pageSize}
                                onPageSizeChange={onPageSizeChange}
                                pageSizes={pageSizes}
                                getMessage={getMessage}
                            />
                        </Grid>
                    ) : null}
                    <Grid item>
                        <Pagination
                            totalPages={totalPages}
                            totalCount={totalCount}
                            currentPage={currentPage}
                            onCurrentPageChange={page => onCurrentPageChange(page)}
                            pageSize={pageSize}
                            getMessage={getMessage}
                        />
                    </Grid>
                    <Grid item>
                        <PageSelector
                            totalPages={totalPages}
                            totalCount={totalCount}
                            currentPage={currentPage}
                            onCurrentPageChange={page => onCurrentPageChange(page)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {actions ? (
                <Grid item>
                    <Grid container direction="row" justify="flex-end" alignItems="center">
                        {actions?.map((action, index) => <Grid key={index} item>{action}</Grid>)}
                    </Grid>
                </Grid>
            ) : null}
        </Grid>
    )
}
