import React, {useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {
    Dialog, DialogActions, DialogContent, DialogTitle,
    Grid, IconButton,
    TextField, Typography
} from "@material-ui/core"

import {Submit} from "../../../../App/components/Button/Submit"
import {plan as rules} from "../../../helpers/permissions/rules"
import {Field, FieldArray, Form, Formik} from "formik";
import * as Yup from "yup";
import {TextFieldWithError as CustomTextField} from "../../../../App/components/Inputs/TextFieldWithError";
import {FileUpload} from "../../../../App/components/Inputs/FileUpload";
import {Add as AddIcon, Clear} from "@material-ui/icons";
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon";
import {PurchasePlanActions as PlanActions} from "../../../actions/purchasePlan";

export const Correct = (props) => {
    const dispatch = useDispatch()

    const [dialog, setDialog] = useState(false)

    const { items, onClick } = props

    const {account} = useSelector(state => state.account)

    return rules.correction.create.hasOwnProperty(account.group) ? (
        <Grid item>
            <Submit
                disableElevation
                disabled={!items.length || !!items.filter(el => {
                    const params = rules.correction.create[account.group]
                    const status = el.editRequestStatus ? el.editRequestStatus.index : null

                    if (params.hasOwnProperty(status) && !el.lock) {
                        if (params[status].hasOwnProperty('conditions')) {
                            if (params[status].conditions.hasOwnProperty('plan')) {
                                return !params[status].conditions.plan.includes(el.status.index)
                            }
                        }

                        return false
                    }

                    return true
                }).length}
                variant="contained"
                color="primary"
                type="button"
                style={{height: '35px'}}
                onClick={() => {
                    if (account.group === 'customer') {
                        setDialog(true)
                    } else {
                        return dispatch(PlanActions.sendForCorrection({
                            ids: items.map(el => el.id)
                        })).then(() => onClick(), () => {})
                    }
                }}
            >
                {(account.group === 'customer') ? 'Запрос на корректировку' : 'Отправить на корректировку'}
            </Submit>
            {dialog &&
                <Dialog
                    open={dialog}
                    onClose={() => {
                        setDialog(false)
                    }}
                    fullWidth={true}
                    maxWidth={'sm'}
                >
                    <DialogTitle>Укажите причину запроса корректировки</DialogTitle>
                    <Formik
                        initialValues={{
                            purchasePlans: items.map(el => el.id),
                            comment: '',
                            files: [
                                {
                                    file: '',
                                    description: ''
                                }
                            ]
                        }}
                        validationSchema={Yup.object().shape({
                            purchasePlans: Yup.array().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            comment: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            files: Yup.array().of(Yup.object().shape({
                                file: Yup.mixed().required('Для запроса на корректировку нужно добавить минимум 1 файл').test('fileFormat', 'Документ должен быть файлом одного из следующих типов: csv, xls, xlsx, jpg, jpeg, png, bmp, gif, svg, doc, docx, pdf.', (value) => {
                                    return ((value && (value instanceof File) && [
                                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                        'application/vnd.ms-excel',
                                        'text/csv',
                                        'image/jpeg',
                                        'image/gif',
                                        'image/svg+xml',
                                        'image/bmp',
                                        'image/png',
                                        'application/msword',
                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                        'application/pdf',
                                    ].includes(value.type)) || !value || (value && !(value instanceof File)))
                                }),
                                description: Yup.mixed().when('file', {
                                    is: null,
                                    then: Yup.mixed().nullable(),
                                    otherwise: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                })
                            }))
                        })}
                        onSubmit={(values, {setSubmitting}) => {
                            setSubmitting(false)

                            const formData = new FormData()

                            for (const key in values) {
                                if (values.hasOwnProperty(key)) {
                                    switch (key) {
                                        case 'purchasePlans':
                                            for (const index in values[key]) {
                                                formData.append(`purchasePlans[${index}]`, values[key][index]);
                                            }
                                            break;
                                        case 'files':
                                            Array.from(values[key]).forEach((el, index) => {
                                                if (el.file && el.description) {
                                                    if (el.file instanceof File) {
                                                        formData.append(`${key}[${index}][file]`, el.file, el.file.name);
                                                    } else {
                                                        formData.append(`${key}[${index}][file][id]`, el.file.id);
                                                    }
                                                    formData.append(`${key}[${index}][description]`, el.description);
                                                }
                                            });
                                            break;
                                        default:
                                            formData.append(`${key}`, values[key])
                                    }
                                }
                            }
                            return dispatch(PlanActions.requestForEdit(formData)).then(
                                () => {
                                    setDialog(false)
                                    onClick()
                                },
                                () => {
                                    setSubmitting(false)
                                }
                            )
                        }}
                    >
                        {({
                              dirty,
                              values,
                              isValid,
                              isSubmitting,
                              setFieldValue,
                              setFieldTouched,
                          }) => (
                            <Form>
                                <DialogContent>
                                    <TextField
                                        fullWidth
                                        name="comment"
                                        label="Комментарий"
                                        rowsMax={6}
                                        multiline
                                        variant="standard"
                                        onChange={(e) => setFieldValue(`comment`, e.target.value)}
                                    />
                                    <Grid item xs={12} style={{marginTop: '30px'}}/>
                                    <FieldArray
                                        name={`files`}
                                        render={arrayHelpers => (
                                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography>Документы</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                        {values.files.map((el, index) => (
                                                            <React.Fragment key={index}>
                                                                <Grid item xs={5}>
                                                                    <Field
                                                                        fullWidth
                                                                        name={`files[${index}].description`}
                                                                        type="text"
                                                                        multiline
                                                                        rows="1"
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Field
                                                                        fullWidth
                                                                        size="small"
                                                                        component={(props) => (<FileUpload {...props} endIcon={"Обзор"}/>)}
                                                                        name={`files.${index}.file`}
                                                                        InputLabelProps={{
                                                                            name: values.files && values.files.length && values.files[index] && values.files[index].file && values.files[index].file.name ? values.files[index].file.name : null
                                                                        }}
                                                                        InputProps={{
                                                                            onChange: (event) => {
                                                                                setFieldValue(`files.${index}.file`, event.currentTarget.files[0])
                                                                                setFieldTouched(`files.${index}.file`, true, false)
                                                                            }
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                {values.files.length > 1 &&
                                                                    <Grid item xs={1} style={{padding: "8px 0"}}>
                                                                        <IconButton
                                                                            size="small"
                                                                            style={{padding: "3px 0"}}
                                                                            onClick={() => arrayHelpers.remove(index)}
                                                                        >
                                                                            <Clear/>
                                                                        </IconButton>
                                                                    </Grid>
                                                                }
                                                            </React.Fragment>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={5}/>
                                                <Grid item xs={7}>
                                                    <ButtonIcon
                                                        onClick={(e) => {
                                                            arrayHelpers.insert(values.files.length, {
                                                                file: null,
                                                                description: ''
                                                            })
                                                        }}
                                                        children={<AddIcon/>}>
                                                    </ButtonIcon>
                                                </Grid>
                                            </Grid>
                                        )}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Grid container direction="row" justify="flex-end" alignItems="stretch">
                                        <Grid item>
                                            <Submit
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={isSubmitting || !isValid || !dirty}
                                            >
                                                Отправить
                                            </Submit>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </Dialog>
            }
        </Grid>
    ) : null
}
