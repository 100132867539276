import React from "react"
import {AppBar, Badge, Button, Grid, IconButton, makeStyles, Toolbar, withStyles} from "@material-ui/core";
import {AuthorizationService} from "../Auth/services/authorization";
import {Link} from "react-router-dom";
import {ExitToApp, GetApp, Notifications, Person, QuestionAnswer, ShoppingCart} from "@material-ui/icons";
import {Menu} from "./components/Header/Menu"
import {useSelector} from "react-redux";

const useStyles = makeStyles(() => ({
    active: {
        "color": "#485868 !important",
        "background-color": "#898989",
        "&:after": {
            "position": "absolute",
            "content": "''",
            "bottom": "0",
            "left": "0",
            "height": "5px",
            "width": "100%",
            "background-color": "#898989"
        }
    },
}))

const CustomIconButton = withStyles({
    root: {
        "color": "white",
        "padding": "0 15px",
        'height': "55px",
        "border-radius": "0",
        "&:hover": {
            "background-color": "#898989",
            "color": "#485868"
        }
    }
})(IconButton);

const CustomButton = withStyles({
    root: {
        "color": "white",
        "padding": "0 15px",
        'height': "55px",
        "border-radius": "0",
        "&:hover": {
            "background-color": "#898989",
            "color": "#485868",
        },
        "& .MuiButton-label": {
            "text-transform": "none"
        }
    }
})(Button);

export const Header = ({openMessenger, open, ...props}) => {
    const classes = useStyles();
    const {account, chats} = useSelector(state => {
        return {...state.account, ...state.application, ...state.messenger}
    })
    const {notification, cart} = useSelector(state => state)
    const {location} = props

    return <AppBar>
        <Toolbar>
            <Grid container direction="row" justify="space-between" alignItems="stretch">
                <Grid item xs={8} className={classes.headerMenu}>
                    <Menu {...props} />
                </Grid>
                <Grid item>
                    {AuthorizationService.roles(account, ['customer', 'customer_confirmed_by_admin', 'supplier', 'supplier_confirmed', 'supervisor']) &&
                        <CustomButton disableRipple size="medium" component={Link} to={"/downloads"} classes={{root: (location.pathname === "/downloads") ? classes.active : undefined}}>
                            <GetApp/>
                        </CustomButton>
                    }
                    <CustomButton disableRipple size="medium" onClick={openMessenger(true)} classes={{root: (open) ? classes.active : undefined}}>
                        <Badge
                            badgeContent={chats.reduce((sum, chat) => {
                                return sum + (chat.recent ?? 0)
                            }, 0)}
                            color="primary"
                        >
                            <QuestionAnswer/>
                        </Badge>
                    </CustomButton>
                    {AuthorizationService.roles(account, ["user", "supplier", "customer", "supervisor", "guest_supplier", "customer_confirmed_by_admin", "supplier_confirmed", "supplier_confirmed_by_admin"]) &&
                        <CustomButton disableRipple size="medium" component={Link} to="/notifications" classes={{root: (location.pathname === "/notifications") ? classes.active : undefined}}>
                            <Badge badgeContent={notification.count} color="primary"><Notifications/></Badge>
                        </CustomButton>
                    }
                    {AuthorizationService.roles(account, "customer") && (AuthorizationService.permissions(account, "access_to_elmag") || AuthorizationService.permissions(account, "access_to_order")) &&
                        <CustomButton disableRipple size="medium" component={Link} to={(location.pathname === "/delivery/carts") ? "/carts" : "/delivery/carts"} classes={{root: ((location.pathname === "/carts") || (location.pathname === "/delivery/carts")) ? classes.active : undefined}}>
                            <Badge badgeContent={cart.count} color="primary"><ShoppingCart/></Badge>
                        </CustomButton>
                    }
                    {AuthorizationService.access(account, ["supervisor", "admin", "operator"]) &&
                        <CustomButton disableRipple size="medium" component={Link} to={"/company"} classes={{root: (location.pathname === "/company") ? classes.active : undefined}}>
                            <Person/>
                        </CustomButton>
                    }
                    {AuthorizationService.access(account, ["supplier", "customer", "customer_confirmed_by_admin", "supplier_confirmed"]) &&
                        <CustomButton disableRipple size="medium" component={Link} to={"/account"} classes={{root: (location.pathname === "/account") ? classes.active : undefined}}>
                            <Person/>
                        </CustomButton>
                    }
                    <CustomIconButton size="medium" component={Link} to="/logout">
                        <ExitToApp/>
                    </CustomIconButton>
                </Grid>
            </Grid>
        </Toolbar>
    </AppBar>
}
