import {AuthorizationService} from "../../../Auth/services/authorization"

function isCentralUser(account) {
    return AuthorizationService.roles(account, ['supervisor']) && (account.organization === 'central');
}

function isRegionalUser(account) {
    return AuthorizationService.roles(account, ['supervisor']) && (account.organization === 'regional');
}

function isCustomer(account) {
    return AuthorizationService.roles(account, ['customer']);
}

function isSupplier(account) {
    return AuthorizationService.roles(account, ['supplier', 'supplier_confirmed']);
}

function getRole(account) {
    switch (true) {
        case isCentralUser(account):
            return 'central';
        case isRegionalUser(account):
            return 'regional';
        case isCustomer(account):
            return 'customer';
        case isSupplier(account):
            return 'supplier';
        default:
            return 'user';
    }
}

export {isCentralUser, isRegionalUser, isCustomer, isSupplier, getRole};
