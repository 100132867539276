import React, {useState} from "react"
import {CustomPaging, PagingState, SelectionState,} from "@devexpress/dx-react-grid"
import {PagingPanel, Table, TableHeaderRow, TableSelection} from "@devexpress/dx-react-grid-material-ui"
import clsx from "clsx"

import {Dialog, DialogContent, DialogTitle, Grid, InputAdornment, makeStyles, TextField} from "@material-ui/core"
import {Search as SearchIcon} from "@material-ui/icons"

import {Pager} from "../../../../App/components/Table/Paging/Pager"
import {Grid as GridTable} from "../../../../App/components/Table/Grid"
import {green} from "@material-ui/core/colors";

export const useStyles = makeStyles(theme => ({
    dialogContent: {
        padding: 0,
    },
    filterLabel: {
        "background": "#fff",
        "margin-bottom": "0 !important",
        "margin-top": "5px !important",
        "border-radius": "2px",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        },
        "& input": {
            padding: "6px 15px 7px"
        }
    },
    default: {
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
    },
}))

export const Index = (props) => {
    const classes = useStyles();
    const [selection, setSelection] = useState([]);
    const {
        callback,
        currentPage,
        handlePageChange,
        handlePageSizeChange,
        meta,
        onClose,
        open,
        purchases,
        rowsPerPage,
        setSearch,
    } = props;

    const columns = [
        {name: "name", title: "Наименование"},
        {name: 'purchase_number', title: 'Закупка'},
    ];

    const rowComponent = (props) => {
        const {tableRow, children, onToggle} = props;
        const {row} = tableRow;
        const handleClick = () => {
            onClose();
            callback(row.id);
            onToggle();
        };
        return (
            <Table.Row
                className={classes.default}
                tableRow={tableRow}
                children={children}
                onClick={handleClick}
                row={row}
            />
        );
    }
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle>Выбрать закупку</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid container >
                    <Grid item xs={12}>
                        <TextField
                            id="searchPurchases"
                            variant="standard"
                            name={"searchPurchases"}
                            placeholder={`Поиск: наименование или номер закупки`}
                            fullWidth={true}
                            margin={"normal"}
                            onChange={(event) => {
                                setSearch(event.target.value);
                            }}
                            classes={{
                                root: clsx(
                                    classes.filterLabel,
                                ),
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon
                                            style={{color: "#c1c1c1"}}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item >
                        <GridTable
                            rows={purchases.map((item) => ({
                                id: item.id,
                                name: item.name,
                                purchase_number: item.number,
                            }))}
                            columns={columns}
                        >
                            <PagingState
                                currentPage={currentPage}
                                onCurrentPageChange={handlePageChange}
                                pageSize={rowsPerPage}
                                onPageSizeChange={handlePageSizeChange}
                            />
                            <CustomPaging totalCount={meta.total}/>
                            <SelectionState
                                selection={selection}
                                onSelectionChange={(numbers) => {
                                    let purchaseIndexes = numbers.filter(
                                        (number) => !selection.includes(number)
                                    );
                                    setSelection(purchaseIndexes);
                                    callback(purchases[purchaseIndexes[0]]?.id);
                                }}
                            />
                            <Table />
                            <TableHeaderRow />
                            <TableSelection
                                className={classes.default}
                                selectByRowClick
                                showSelectionColumn={false}
                                highlightRow
                                rowComponent={rowComponent}
                            />
                            <PagingPanel
                                containerComponent={Pager}
                                messages={{
                                    showAll: "Все",
                                    rowsPerPage: "Записей на странице:",
                                    info: "{from}-{to} из {count}",
                                }}
                                pageSizes={[5, 50, 100, 200]}
                            />
                        </GridTable>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
