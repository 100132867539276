import React, {useEffect, useState} from "react"
import {useDispatch} from 'react-redux'
import {OperationBetweenTwoTables} from "../../../../../App/components/OperationBetweenTwoTables"
import {PurchasePlanActions} from "../../../../actions/purchasePlan"
import {PurchaseActions} from "../../../../actions/purchase"
import {localState} from "../../../../../App/components/LocalState";

const copyColumns = [
    {
        caption: 'Номер',
        dataField: 'number',
        type: 'string',
        width: 100
    },
    {
        caption: 'Наименование',
        dataField: 'name',
        type: 'string'
    },
    {
        caption: 'Категория',
        dataField: 'category',
        type: 'string',
        width: 200
    }
]

export const Dialog = (props) => {
    const { purchases, category, onClose, open } = props
    const dispatch = useDispatch()

    const [purchasePlans, setPurchasePlans] = useState(localState)

    const [purchasePlanID, setPurchasePlanID] = useState('')
    const [purchasePlanPurchases, setPurchasePlanPurchases] = useState([])

    const getPurchasePlans = async () => {
        let params = {
            limit: 50,
            page: 1,
            mode: 'copy',
            categories: category,
            simple: true
        }

        return await dispatch(PurchasePlanActions.plans(params))
    }

    const copyPurchases = async (items) => {
        if (purchasePlanID) {
            return await dispatch(PurchasePlanActions.copyPurchases(purchasePlanID, {
                purchases: items.map(item => item.id)
            }))
        } else {
            return await dispatch({type: "ALERT_ERROR", payload: {
                message: 'Выберите план закупки',
                type:'error'
            }})
        }
    }

    const removePurchases = async (items) => {
        if (items.filter(item => !item.lock).length) {
            return await dispatch(
                PurchaseActions.deletePurchases({ids: items.filter(item => !item.lock).map(item => item.id)})
            )
        }
    }

    const getItemsInRightTable = async () => {
        return await dispatch(PurchaseActions.purchasesByPurchasePlanID(purchasePlanID, {
            limit: 100,
            page: 1,
            simple: true
        }, false))
    }

    useEffect(() => {
        if (purchasePlanID) {
            getItemsInRightTable().then(items => {
                setPurchasePlanPurchases(items.data.map(purchase => ({
                    id: purchase.id,
                    number: purchase.number,
                    name: purchase.name,
                    category: (purchase.category.id === purchase.category.index) ? purchase.category.name : `${purchase.category.name} (${purchase.category.root.name})`,
                    lock: purchase.lock
                })))
            })
        }
        // eslint-disable-next-line
    }, [purchasePlanID])

    useEffect(() => {
        const setDefaultValues = () => {
            setPurchasePlanID(null)
        }

        getPurchasePlans().then(response => {
            setPurchasePlans(response)
        })

        return setDefaultValues()
        // eslint-disable-next-line
    }, [])

    return (
        <OperationBetweenTwoTables
            itemsLeft={purchases}
            itemsRight={purchasePlanPurchases}
            columnsTables={copyColumns}
            open={open}
            title={'Копирование Закупки'}
            maxWidth={'lg'}
            fullWidth={true}
            leftToRightCallback={copyPurchases}
            rightToLeftCallback={removePurchases}
            tableSelectorsLeft={[]}
            tableSelectorsRight={[
                {
                    id: 'purchasePlans',
                    title: 'Планы закупок',
                    value: purchasePlanID,
                    callback: (e) => {
                        setPurchasePlanID(e.target.value)
                    },
                    items: purchasePlans.data
                }
            ]}
            onClose={onClose}
            updateRightList={getItemsInRightTable}
        />
    )
}
