import {PriceService} from "../../services/Control/price"

export const PriceActions = {
    items,
    add,
    edit,
    remove,
    positions
}

function items(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "REPORT_PRICE_CONTROL_ITEMS_REQUEST"})

        PriceService.items(params)
            .then(
                items => {
                    dispatch({type: "REPORT_PRICE_CONTROL_ITEMS", payload: {data: items.data, meta: items.meta}})
                    dispatch({type: "LOADING", payload: false})
                    resolve(items)
                },
                error => {
                    dispatch({type: "REPORT_PRICE_CONTROL_ITEMS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function add(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "REPORT_PRICE_CONTROL_ADD_ITEM_REQUEST"})

        PriceService.add(values)
            .then(
                contract => {
                    dispatch({type: "REPORT_PRICE_CONTROL_ADD_ITEM_SUCCESS", payload: contract})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "REPORT_PRICE_CONTROL_ADD_ITEM_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )
    })
}

function edit(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "REPORT_PRICE_CONTROL_EDIT_ITEM_REQUEST"})

        PriceService.edit(id, values)
            .then(
                contract => {
                    dispatch({type: "REPORT_PRICE_CONTROL_EDIT_ITEM_SUCCESS", payload: contract})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                        type:'success'
                    }})
                    resolve()
                },
                error => {
                    dispatch({type: "REPORT_PRICE_CONTROL_EDIT_ITEM_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )
    })
}

function remove(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "REPORT_PRICE_CONTROL_DELETE_ITEM_REQUEST"})

        PriceService.remove(id)
            .then(
                response => {
                    dispatch({type: "REPORT_PRICE_CONTROL_DELETE_ITEM_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "REPORT_PRICE_CONTROL_DELETE_ITEM_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function positions(id, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "REPORT_PRICE_CONTROL_ITEM_POSITIONS_REQUEST"})

        PriceService.positions(id, params)
            .then(
                positions => {
                    dispatch({type: "REPORT_PRICE_CONTROL_ITEM_POSITIONS", payload: {data: positions.data, meta: positions.meta}})
                    dispatch({type: "REPORT_PRICE_CONTROL_ITEM", payload: positions.item})
                    dispatch({type: "LOADING", payload: false})
                    resolve(positions)
                },
                error => {
                    dispatch({type: "REPORT_PRICE_CONTROL_ITEM_POSITIONS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
