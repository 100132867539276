import React from "react"
import {useSelector} from "react-redux"

import {
    Grid
} from "@material-ui/core"

import {history} from "../../../../App/helpers/history"
import {Submit} from "../../../../App/components/Button/Submit"
import {plan as rules} from "../../../helpers/permissions/rules"

export const Create = (props) => {

    const {account} = useSelector(state => state.account)

    return rules.create.hasOwnProperty(account.group) ? (
        <Grid item>
            <Submit
                disableElevation
                variant="contained"
                color="primary"
                type="button"
                style={{height: '35px'}}
                onClick={() => {
                    history.push(`/planning/purchase-plan/add`)
                }}
            >
                Добавить план закупки
            </Submit>
        </Grid>
    ) : null
}
