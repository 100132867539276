import {status as PlanStatus} from "../../dics/Plan/status"
import {status as Status} from "../../dics/Purchase/status"

const plan = {
    'create': {
        'customer': {
            [null]: PlanStatus.DRAFT
        }
    },
    'edit': {
        'customer': {
            [PlanStatus.DRAFT]: [],
            [PlanStatus.ON_COMPLETION_CHUZ]: [],
            [PlanStatus.APPROVED_BY_CDZ]: [
                PlanStatus.ON_CORRECTION
            ]
        },
        'central': {
            [PlanStatus.DRAFT]: [],
            [PlanStatus.APPROVED_BY_CHUZ]: [],
            [PlanStatus.IN_WORK_RDZ]: [],
            [PlanStatus.APPROVED_BY_RDZ]: [],
            [PlanStatus.REJECTED_RDZ]: [],
            [PlanStatus.ON_COMPLETION_CHUZ]: [],
            [PlanStatus.IN_WORK_CDZ]: [],
            [PlanStatus.APPROVED_BY_CDZ]: [
                PlanStatus.ON_CORRECTION,
                PlanStatus.CORRECTION_APPROVED,
                PlanStatus.APPROVED_BY_CHUZ,
                PlanStatus.IN_WORK_CDZ,
                PlanStatus.CORRECTION_REQUEST,
                PlanStatus.CORRECTION_REQUEST_EXCEPTION,
                PlanStatus.CORRECTION_REJECTED
            ],
            [PlanStatus.REJECTED_CDZ]: []
        }
    },
    'copy': [
        'customer', 'central'
    ],
    'approve': {
        'customer': {
            [PlanStatus.DRAFT]: {
                'conditions': {
                    'purchase': [
                        Status.APPROVED_BY_CHUZ
                    ],
                    'items': ['purchase'],
                },
                'status': PlanStatus.APPROVED_BY_CHUZ
            },
            [PlanStatus.ON_COMPLETION_CHUZ]: {
                'conditions': {
                    'purchase': [
                        Status.APPROVED_BY_CHUZ,
                        Status.APPROVED_BY_RDZ,
                        Status.APPROVED_BY_CDZ
                    ],
                    'items': ['purchase'],
                },
                'status': PlanStatus.APPROVED_BY_CHUZ
            },
            [PlanStatus.APPROVED_BY_CDZ]: {
                'conditions': {
                    'correction': [
                        PlanStatus.ON_CORRECTION
                    ],
                    'purchase': [
                        Status.APPROVED_BY_CDZ
                    ]
                },
                'status': Status.APPROVED_BY_CDZ,
                'correct': PlanStatus.APPROVED_BY_CHUZ
            }
        },
        'regional': {
            [PlanStatus.APPROVED_BY_CHUZ]: {
                'conditions': {
                    'settings': {
                        without_in_work_rdz: true
                    }
                },
                'purchases': Status.APPROVED_BY_RDZ,
                'status': PlanStatus.APPROVED_BY_RDZ
            },
        },
        'central': {
            [PlanStatus.IN_WORK_CDZ]: {
                'purchases': Status.APPROVED_BY_CDZ,
                'status': PlanStatus.APPROVED_BY_CDZ
            },
            [PlanStatus.APPROVED_BY_RDZ]: {
                'purchases': Status.APPROVED_BY_CDZ,
                'status': PlanStatus.APPROVED_BY_CDZ
            },
            [PlanStatus.APPROVED_BY_CHUZ]: {
                'purchases': Status.APPROVED_BY_CDZ,
                'status': PlanStatus.APPROVED_BY_CDZ
            },
            [PlanStatus.APPROVED_BY_CDZ]: {
                'conditions': {
                    'correction': [
                        PlanStatus.APPROVED_BY_CHUZ,
                        PlanStatus.IN_WORK_CDZ
                    ]
                },
                'purchases': Status.APPROVED_BY_CDZ,
                'status': PlanStatus.APPROVED_BY_CDZ
            },
        }
    },
    'decline': {
        'regional': {
            [PlanStatus.APPROVED_BY_CHUZ]: {
                'conditions': {
                    'settings': {
                        without_in_work_rdz: true
                    }
                },
                'purchases': Status.REJECTED_RDZ,
                'status': PlanStatus.REJECTED_RDZ
            }
        },
        'central': {
            [PlanStatus.IN_WORK_CDZ]: {
                'purchases': Status.REJECTED_CDZ,
                'status': PlanStatus.REJECTED_CDZ
            },
            [PlanStatus.APPROVED_BY_RDZ]: {
                'purchases': Status.REJECTED_CDZ,
                'status': PlanStatus.REJECTED_CDZ
            },
            [PlanStatus.APPROVED_BY_CHUZ]: {
                'purchases': Status.REJECTED_CDZ,
                'status': PlanStatus.REJECTED_CDZ
            },
            [PlanStatus.APPROVED_BY_CDZ]: {
                'conditions': {
                    'correction': [
                        PlanStatus.APPROVED_BY_CHUZ,
                        PlanStatus.IN_WORK_CDZ
                    ]
                },
                'purchases': Status.REJECTED_CDZ,
                'status': PlanStatus.CORRECTION_REJECTED
            }
        }
    },
    'correction': {
        'create': {
            'customer': {
                [null]: {
                    'conditions': {
                        'plan': [
                            PlanStatus.APPROVED_BY_CDZ
                        ]
                    },
                    'status': PlanStatus.CORRECTION_REQUEST
                },
                [PlanStatus.CORRECTION_REQUEST_DECLINE]: {
                    'conditions': {
                        'plan': [
                            PlanStatus.APPROVED_BY_CDZ
                        ]
                    },
                    'status': PlanStatus.CORRECTION_REQUEST
                },
                [PlanStatus.APPROVED_BY_CDZ]: {
                    'conditions': {
                        'plan': [
                            PlanStatus.APPROVED_BY_CDZ
                        ]
                    },
                    'status': PlanStatus.CORRECTION_REQUEST
                },
            },
            'central': {
                [null]: {
                    'conditions': {
                        'plan': [
                            PlanStatus.APPROVED_BY_CDZ
                        ]
                    },
                    'status': PlanStatus.ON_CORRECTION
                },
                [PlanStatus.CORRECTION_REQUEST_DECLINE]: {
                    'conditions': {
                        'plan': [
                            PlanStatus.APPROVED_BY_CDZ
                        ]
                    },
                    'status': PlanStatus.ON_CORRECTION
                },
                [PlanStatus.APPROVED_BY_CDZ]: {
                    'conditions': {
                        'plan': [
                            PlanStatus.APPROVED_BY_CDZ
                        ]
                    },
                    'status': PlanStatus.ON_CORRECTION
                },
            }
        },
        'approve': {
            'central': {
                [PlanStatus.CORRECTION_REQUEST_EXCEPTION]: {
                    'conditions': {
                        'plan': [
                            PlanStatus.APPROVED_BY_CDZ
                        ]
                    },
                    'status': PlanStatus.CORRECTION_APPROVED
                },
                [PlanStatus.CORRECTION_REQUEST]: {
                    'conditions': {
                        'plan': [
                            PlanStatus.APPROVED_BY_CDZ
                        ]
                    },
                    'status': PlanStatus.CORRECTION_APPROVED
                }
            }
        },
        'decline': {
            'central': {
                [PlanStatus.CORRECTION_REQUEST_EXCEPTION]: {
                    'conditions': {
                        'plan': [
                            PlanStatus.APPROVED_BY_CDZ
                        ]
                    },
                    'status': PlanStatus.CORRECTION_REQUEST_DECLINE
                },
                [PlanStatus.CORRECTION_REQUEST]: {
                    'conditions': {
                        'plan': [
                            PlanStatus.APPROVED_BY_CDZ
                        ]
                    },
                    'status': PlanStatus.CORRECTION_REQUEST_DECLINE
                }
            }
        }
    },
    'delete': {
        'customer': [
            PlanStatus.DRAFT
        ],
        'central': [
            PlanStatus.DRAFT
        ]
    }
}

const purchase = {
    'create': {
        'customer': {
            [PlanStatus.DRAFT]: {
                'status': Status.DRAFT
            },
            [PlanStatus.ON_COMPLETION_CHUZ]: {
                'status': Status.DRAFT
            },
            [PlanStatus.APPROVED_BY_CDZ]: {
                'correct': [
                    PlanStatus.ON_CORRECTION
                ],
                'status': Status.DRAFT
            }
        }
    },
    'publish': {
        'customer': {
            [Status.APPROVED_BY_CHUZ]: {
                'conditions': {
                    'purchase': [
                        Status.APPROVED_BY_CHUZ,
                    ]
                }
            },
            [Status.APPROVED_BY_CDZ]: {
                'conditions': {
                    'purchase': [
                        Status.APPROVED_BY_CDZ,
                    ]
                }
            },
        }
    },
    'edit': {
        'customer': {
            [Status.DRAFT]: [],
            [Status.ON_COMPLETION_CHUZ]: [],
            [Status.APPROVED_BY_CDZ]: [
                Status.ON_CORRECTION
            ]
        },
        'central': {
            [Status.DRAFT]: [],
            [Status.APPROVED_BY_CHUZ]: [],
            [Status.IN_WORK_RDZ]: [],
            [Status.APPROVED_BY_RDZ]: [],
            [Status.REJECTED_RDZ]: [],
            [Status.ON_COMPLETION_CHUZ]: [],
            [Status.IN_WORK_CDZ]: [],
            [Status.APPROVED_BY_CDZ]: [
                Status.CORRECTION_APPROVED,
                Status.APPROVED_BY_CHUZ,
                Status.IN_WORK_CDZ,
                Status.CORRECTION_REQUEST,
                Status.ON_CORRECTION,
                Status.CORRECTION_REQUEST_EXCEPTION,
                Status.CORRECTION_DECLINE,
                Status.CORRECTION_REQUEST_DECLINE
            ],
            [Status.REJECTED_CDZ]: []
        }
    },
    'copy': [
        'customer', 'central'
    ],
    'approve': {
        'customer': {
            [Status.DRAFT]: {
                'conditions': {
                    'plan': {
                        [PlanStatus.DRAFT]: [],
                        [PlanStatus.ON_COMPLETION_CHUZ]: [],
                        [PlanStatus.APPROVED_BY_CDZ]: [
                            PlanStatus.ON_CORRECTION
                        ]
                    },
                    'validated': ['purchase', 'lot'],
                    'items': ['purchase'],
                    'standard': 'confirmed'
                },
                'status': Status.APPROVED_BY_CHUZ
            },
            [Status.ON_COMPLETION_CHUZ]: {
                'conditions': {
                    'plan': {
                        [PlanStatus.ON_COMPLETION_CHUZ]: [],
                        [PlanStatus.APPROVED_BY_CDZ]: [
                            PlanStatus.ON_CORRECTION
                        ]
                    },
                    'validated': ['purchase', 'lot'],
                    'items': ['purchase'],
                    'standard': 'confirmed'
                },
                'status': Status.APPROVED_BY_CHUZ
            },
            [Status.APPROVED_BY_CDZ]: {
                'conditions': {
                    'correction': [
                        Status.ON_CORRECTION
                    ],
                    'items': ['purchase'],
                    'standard': 'confirmed'
                },
                'status': Status.APPROVED_BY_CDZ,
                'correct': Status.APPROVED_BY_CHUZ
            }
        },
        'regional': {
            [Status.IN_WORK_RDZ]: {
                'conditions': {
                    'plan': {
                        [PlanStatus.IN_WORK_RDZ]: [],
                        [PlanStatus.APPROVED_BY_CDZ]: [
                            PlanStatus.ON_CORRECTION
                        ]
                    }
                },
                'status': Status.APPROVED_BY_RDZ
            },
            [Status.APPROVED_BY_CHUZ]: {
                'conditions': {
                    'plan': {
                        [PlanStatus.IN_WORK_RDZ]: [],
                        [PlanStatus.APPROVED_BY_CHUZ]: [],
                        [PlanStatus.APPROVED_BY_CDZ]: [
                            PlanStatus.ON_CORRECTION
                        ]
                    },
                    'settings': {
                        'without_in_work_rdz': true
                    }
                },
                'status': Status.APPROVED_BY_RDZ
            }
        },
        'central': {
            [Status.APPROVED_BY_RDZ]: {
                'conditions': {
                    'plan': {
                        [PlanStatus.IN_WORK_RDZ]: [],
                        [PlanStatus.APPROVED_BY_RDZ]: [],
                        [PlanStatus.IN_WORK_CDZ]: [],
                        [PlanStatus.APPROVED_BY_CDZ]: [
                            PlanStatus.ON_CORRECTION
                        ]
                    }
                },
                'status': Status.APPROVED_BY_CDZ
            },
            [Status.IN_WORK_RDZ]: {
                'conditions': {
                    'plan': {
                        [PlanStatus.IN_WORK_RDZ]: [],
                        [PlanStatus.APPROVED_BY_RDZ]: [],
                        [PlanStatus.IN_WORK_CDZ]: [],
                        [PlanStatus.APPROVED_BY_CDZ]: [
                            PlanStatus.ON_CORRECTION
                        ]
                    }
                },
                'status': Status.APPROVED_BY_CDZ
            },
            [Status.APPROVED_BY_CHUZ]: {
                'conditions': {
                    'plan': {
                        [PlanStatus.IN_WORK_RDZ]: [],
                        [PlanStatus.APPROVED_BY_RDZ]: [],
                        [PlanStatus.APPROVED_BY_CHUZ]: [],
                        [PlanStatus.IN_WORK_CDZ]: [],
                        [PlanStatus.APPROVED_BY_CDZ]: [
                            PlanStatus.ON_CORRECTION
                        ]
                    }
                },
                'status': Status.APPROVED_BY_CDZ
            },
            [Status.IN_WORK_CDZ]: {
                'conditions': {
                    'plan': {
                        [PlanStatus.IN_WORK_CDZ]: [],
                        [PlanStatus.APPROVED_BY_CDZ]: [
                            PlanStatus.ON_CORRECTION
                        ]
                    }
                },
                'status': Status.APPROVED_BY_CDZ
            },
            [Status.APPROVED_BY_CDZ]: {
                'conditions': {
                    'correction': [
                        Status.APPROVED_BY_CHUZ,
                        Status.IN_WORK_CDZ
                    ]
                },
                'status': Status.APPROVED_BY_CDZ,
                'correct': Status.APPROVED_BY_CDZ
            }
        }
    },
    'decline': {
        'regional': {
            [Status.IN_WORK_RDZ]: {
                'conditions': {
                    'plan': {
                        [PlanStatus.IN_WORK_RDZ]: [],
                        [PlanStatus.APPROVED_BY_CDZ]: [
                            PlanStatus.ON_CORRECTION
                        ]
                    }
                },
                'status': Status.REJECTED_RDZ
            },
            [Status.APPROVED_BY_CHUZ]: {
                'conditions': {
                    'plan': {
                        [PlanStatus.IN_WORK_RDZ]: [],
                        [PlanStatus.APPROVED_BY_CHUZ]: [],
                        [PlanStatus.APPROVED_BY_CDZ]: [
                            PlanStatus.ON_CORRECTION
                        ]
                    },
                    'settings': {
                        'without_in_work_rdz': true
                    }
                },
                'status': Status.REJECTED_RDZ
            }
        },
        'central': {
            [Status.APPROVED_BY_RDZ]: {
                'conditions': {
                    'plan': {
                        [PlanStatus.IN_WORK_RDZ]: [],
                        [PlanStatus.APPROVED_BY_RDZ]: [],
                        [PlanStatus.IN_WORK_CDZ]: [],
                        [PlanStatus.APPROVED_BY_CDZ]: [
                            PlanStatus.ON_CORRECTION
                        ]
                    }
                },
                'status': Status.REJECTED_CDZ
            },
            [Status.IN_WORK_RDZ]: {
                'conditions': {
                    'plan': {
                        [PlanStatus.IN_WORK_RDZ]: [],
                        [PlanStatus.APPROVED_BY_RDZ]: [],
                        [PlanStatus.IN_WORK_CDZ]: [],
                        [PlanStatus.APPROVED_BY_CDZ]: [
                            PlanStatus.ON_CORRECTION
                        ]
                    }
                },
                'status': Status.REJECTED_CDZ
            },
            [Status.APPROVED_BY_CHUZ]: {
                'conditions': {
                    'plan': {
                        [PlanStatus.IN_WORK_RDZ]: [],
                        [PlanStatus.APPROVED_BY_RDZ]: [],
                        [PlanStatus.APPROVED_BY_CHUZ]: [],
                        [PlanStatus.IN_WORK_CDZ]: [],
                        [PlanStatus.APPROVED_BY_CDZ]: [
                            PlanStatus.ON_CORRECTION
                        ]
                    }
                },
                'status': Status.REJECTED_CDZ
            },
            [Status.IN_WORK_CDZ]: {
                'conditions': {
                    'plan': {
                        [PlanStatus.IN_WORK_CDZ]: [],
                        [PlanStatus.APPROVED_BY_CDZ]: [
                            PlanStatus.ON_CORRECTION
                        ]
                    }
                },
                'status': Status.REJECTED_CDZ
            },
            [Status.APPROVED_BY_CDZ]: {
                'conditions': {
                    'correction': [
                        Status.APPROVED_BY_CHUZ,
                        Status.IN_WORK_CDZ
                    ]
                },
                'status': Status.APPROVED_BY_CDZ,
                'correct': Status.CORRECTION_DECLINE
            }
        }
    },
    'correction': {
        'create': {
            'customer': {
                null: {
                    'conditions': {
                        'purchase': [
                            Status.APPROVED_BY_CDZ
                        ]
                    },
                    'status': Status.CORRECTION_REQUEST
                },
                [Status.CORRECTION_REQUEST_DECLINE]: {
                    'conditions': {
                        'purchase': [
                            Status.APPROVED_BY_CDZ
                        ]
                    },
                    'status': Status.CORRECTION_REQUEST
                },
                [Status.APPROVED_BY_CDZ]: {
                    'conditions': {
                        'purchase': [
                            Status.APPROVED_BY_CDZ
                        ]
                    },
                    'status': Status.CORRECTION_REQUEST
                }
            },
            'central': {
                null: {
                    'conditions': {
                        'purchase': [
                            Status.APPROVED_BY_CDZ
                        ]
                    },
                    'status': Status.ON_CORRECTION
                },
                [Status.CORRECTION_REQUEST_DECLINE]: {
                    'conditions': {
                        'purchase': [
                            Status.APPROVED_BY_CDZ
                        ]
                    },
                    'status': Status.ON_CORRECTION
                },
                [Status.APPROVED_BY_CDZ]: {
                    'conditions': {
                        'purchase': [
                            Status.APPROVED_BY_CDZ
                        ]
                    },
                    'status': Status.ON_CORRECTION
                }
            }
        },
        'approve': {
            'central': {
                [Status.CORRECTION_REQUEST_EXCEPTION]: {
                    'conditions': {
                        'purchase': [
                            Status.APPROVED_BY_CDZ
                        ]
                    },
                    'status': Status.CORRECTION_APPROVED
                },
                [Status.CORRECTION_REQUEST]: {
                    'conditions': {
                        'purchase': [
                            Status.APPROVED_BY_CDZ
                        ]
                    },
                    'status': Status.CORRECTION_APPROVED
                }
            }
        },
        'decline': {
            'central': {
                [Status.CORRECTION_REQUEST_EXCEPTION]: {
                    'conditions': {
                        'purchase': [
                            Status.APPROVED_BY_CDZ
                        ]
                    },
                    'status': Status.CORRECTION_REQUEST_DECLINE
                },
                [Status.CORRECTION_REQUEST]: {
                    'conditions': {
                        'purchase': [
                            Status.APPROVED_BY_CDZ
                        ]
                    },
                    'status': Status.CORRECTION_REQUEST_DECLINE
                }
            }
        }
    },
    'delete': {
        'customer': {
            [Status.DRAFT]: [],
            [Status.ON_COMPLETION_CHUZ]: [],
            [Status.APPROVED_BY_CDZ]: [
                Status.CORRECTION_APPROVED,
                Status.APPROVED_BY_CHUZ,
                Status.IN_WORK_CDZ,
                Status.APPROVED_BY_CDZ,
                Status.CORRECTION_REQUEST,
                Status.ON_CORRECTION,
                Status.CORRECTION_REQUEST_EXCEPTION,
                Status.CORRECTION_DECLINE,
                Status.CORRECTION_REQUEST_DECLINE
            ]
        },
        'central': {
            [Status.DRAFT]: [],
            [Status.APPROVED_BY_CHUZ]: [],
            [Status.IN_WORK_RDZ]: [],
            [Status.APPROVED_BY_RDZ]: [],
            [Status.REJECTED_RDZ]: [],
            [Status.ON_COMPLETION_CHUZ]: [],
            [Status.IN_WORK_CDZ]: [],
            [Status.APPROVED_BY_CDZ]: [
                Status.CORRECTION_APPROVED,
                Status.APPROVED_BY_CHUZ,
                Status.IN_WORK_CDZ,
                Status.CORRECTION_REQUEST,
                Status.ON_CORRECTION,
                Status.CORRECTION_REQUEST_EXCEPTION,
                Status.CORRECTION_DECLINE,
                Status.CORRECTION_REQUEST_DECLINE
            ],
            [Status.REJECTED_CDZ]: []
        }
    }
}

const item = {
    'copy': [
        'customer'
    ]
}

export { plan, purchase, item };
