import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch} from "react-redux"
import format from "date-fns/format"

import {
    Button,
    Collapse,
    Grid,
    makeStyles,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography
} from "@material-ui/core"
import {GetApp as GetAppIcon} from '@material-ui/icons'

import {ShopActions} from "../Shop/actions/shop"
import {TabPanel} from "../../../Account/components/Tabs/TabPanel"
import {Submit} from "../../../App/components/Button/Submit"
import {Log} from "../Shop/components/Order/Log"
import {getFullName} from "../../../App/helpers/user"
import * as AppStorage from "../../../App/helpers/storage"

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px 0 29px !important",
    },
    input: {
        "width": "100%",
    },
    sale: {
        "color": "red",
        "font-size": "10px",
        "font-weight": "500",
        "display": "block"
    },
    disabledSale: {
        "color": "rgb(193, 193, 193)",
        "font-size": "10px",
        "font-weight": "500",
        "display": "block"
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 303px)",
        "margin-bottom": "4px"
    },
    tableContainer: {
        "overflow": "auto"
    },
    tableRow: {
        "text-decoration": "none"
    },
    tableRowAlert: {
        "cursor": "pointer",
        "background-color": "#f9f1f0",
        "text-decoration": "none"
    },
    tableRowProduct: {
        '& > *': {
            "border-bottom": 'unset'
        },
        "cursor": "pointer"
    },
    tableRowLast: {
        '& > *': {
            "border-bottom": "0"
        }
    },
    tabContent: {
        "padding": "20px 29px !important"
    },
    disabled: {
        "color": "rgb(193, 193, 193)"
    },
    active: {
        "color": "#485868"
    },
    disabledLine: {
        "text-decoration": "line-through",
        "color": "rgb(193, 193, 193)"
    },
    activeLine: {
        "text-decoration": "line-through",
        "color": "#485868"
    },
    itemInput: {
        "margin-top": "0 !important"
    },
    inputContent: {
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    download: {
        "color": "#485868"
    },
    winner: {
        '& .MuiTableCell-root': {
            'font-weight': '500'
        }
    },
    line: {
        "text-decoration": "line-through"
    },
    tooltip: {
        "max-width": "200px",
        "border-radius": "0px !important",
        "font-size": "0.8rem"
    },
    badge: {
        "right": "-12px"
    },
    tabBadge: {
        "padding-right": "34px"
    },
}))

export const Content = (props) => {
    let {id} = useParams()

    const dispatch = useDispatch()
    const classes = useStyles()
    const [order, setOrder] = React.useState(null);
    const [value, setValue] = React.useState(1);
    const [loading, setLoading] = React.useState(false)
    const [expanded, setExpanded] = useState(false)
    const [positions, setPositions] = useState({})

    useEffect(() => {
        const getOrder = async () => {
            return await dispatch(ShopActions.order(id))
        }

        if (!loading) {
            getOrder().then(order => {
                let data = {
                    positions: {}
                }

                if (order.confirmation) {
                    Object.keys(order.confirmation).forEach(name => {

                        data.positions[name] = order.confirmation[name]

                        let total = parseFloat(order.lots.reduce(function (total, lot) {
                            return total + lot.items.reduce(function (sum, item) {
                                return sum + ((item.name === name) ? parseFloat(item.amount) : 0)
                            }, 0)
                        }, 0))

                        data.positions[name] = data.positions[name].sort((first, second) => {
                            if (first.price < second.price) {
                                return -1
                            }
                            if (first.price > second.price) {
                                return 1
                            }
                            return 0
                        }).map(position => {
                            if (total > 0) {
                                let current = ((parseFloat(position.stock_quantity) - total) >= 0) ? (parseFloat(position.stock_quantity) - (parseFloat(position.stock_quantity) - total)) : (total + (parseFloat(position.stock_quantity) - total))

                                if (current >= parseFloat(position.min_ordered_quantity ?? 0)) {
                                    total -= current

                                    return {
                                        ...position,
                                        ...{disabled: false}
                                    }
                                }
                            }

                            return {
                                ...position,
                                ...{disabled: true}
                            }
                        })
                    })
                }
                setPositions(data.positions)

                if (order.winner) {
                    let tmp = {}
                    order.winner.items.forEach((item) => {
                        tmp[item.item_id] = item.price
                    })

                    setValue(0)
                } else if ((order.status.index === 3)) {
                    setValue(0)
                }

                setOrder(order)
                setLoading(true)
            })
        }

    }, [dispatch, id, loading]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return order ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.full}>
                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                            <Grid item className={classes.fullWidth}>
                                <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                    <Grid item className={classes.header}>
                                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                            <Grid item>
                                                <Grid container className={classes.fullWidth} direction="row" justify="space-between" alignItems="stretch">
                                                    <Grid item>
                                                        <Typography className={classes.title}>
                                                            Заказ № {order.id}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="button">
                                                            {order.status.index === 8 ? "В работе" : order.status.name}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="button">
                                                    {format(new Date(order.created), 'dd.MM.yyyy HH:mm')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.input}>
                                        <Grid container direction="column" justify="center" alignItems="stretch">
                                            <Grid item className={classes.fullWidth}>
                                                <Tabs
                                                    value={value}
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                    onChange={handleChange}
                                                >
                                                    {((order.status.index === 3) || (order.status.index === 5)) &&
                                                        <Tab value={0} label="Договор" />}
                                                    <Tab value={1} label="Информация о заказе" />
                                                    <Tab value={2} label="Обоснование выбора" />
                                                </Tabs>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                                    <Grid item className={classes.inputContent}>
                                        {((order.status.index === 3) || (order.status.index === 5)) &&
                                            <TabPanel value={value} index={0}>
                                                <Log order={order} setLoading={setLoading}/>
                                            </TabPanel>
                                        }
                                        <TabPanel value={value} index={1}>
                                            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                <Grid item>
                                                    <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                        <Grid item>
                                                            <Grid container direction="row" justify="space-between" alignItems="stretch">
                                                                <Grid item>
                                                                    <Grid container direction="row"
                                                                          justify="flex-end" alignItems="stretch">
                                                                        {order.files.find((file) => file.type.key === 'draft_agreement') &&
                                                                            <Grid item>
                                                                                <Button
                                                                                    className={classes.download}
                                                                                    endIcon={<GetAppIcon/>}
                                                                                    href={process.env.REACT_APP_HOST_API + `/shop/order/${order.id}/file/${order.files.find((file) => file.type.key === 'draft_agreement').id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                >
                                                                                    Скачать проект договора
                                                                                </Button>
                                                                            </Grid>
                                                                        }
                                                                        {order.files.find((file) => file.type.key === 'technical_task') &&
                                                                            <Grid item>
                                                                                <Button
                                                                                    className={classes.download}
                                                                                    endIcon={<GetAppIcon/>}
                                                                                    href={process.env.REACT_APP_HOST_API + `/shop/order/${order.id}/file/${order.files.find((file) => file.type.key === 'technical_task').id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                >
                                                                                    Скачать техническое задание
                                                                                </Button>
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={4}>
                                                                <Grid item>
                                                                    <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                        <Grid item>
                                                                            <Typography variant="button">
                                                                                Тип:
                                                                            </Typography>
                                                                            <Typography>
                                                                                {order.type === 'static' ? 'Статический запрос' : 'Динамический запрос'}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                        <Grid item>
                                                                            <Typography variant="button">
                                                                                Категория:
                                                                            </Typography>
                                                                            <Typography>
                                                                                {order.category.name}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <TableContainer className={classes.tableContainer}>
                                                        <Table stickyHeader aria-label="sticky table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {[
                                                                        {
                                                                            id: '0',
                                                                            label: 'Лот №',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                        {
                                                                            id: '1',
                                                                            label: 'Товары',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                        {
                                                                            id: '2',
                                                                            label: 'Кол-во',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                        {
                                                                            id: '3',
                                                                            label: 'Мин. срок годности',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                        {
                                                                            id: '4',
                                                                            label: 'Срок поставки товара',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                        {
                                                                            id: '5',
                                                                            label: 'Отсрочка платежа, дней',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                    ].map((column) => (
                                                                        <TableCell key={column.id}>
                                                                            {column.label}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {order.lots.map((lot) => (
                                                                    lot.items.map((item, index) => (
                                                                        <TableRow key={index}>
                                                                            {!index &&
                                                                                <TableCell component="th"
                                                                                           scope="row"
                                                                                           rowSpan={lot.items.length}>
                                                                                    {lot.index}
                                                                                </TableCell>
                                                                            }
                                                                            <TableCell>
                                                                                {item.name}
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                {parseFloat(item.amount).toFixed(2)} {item.unit ? item.unit.short : null}
                                                                            </TableCell>
                                                                            {!index &&
                                                                                <React.Fragment>
                                                                                    <TableCell
                                                                                        rowSpan={lot.items.length}
                                                                                        align="center">
                                                                                        {lot.expiration_date} г.
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        rowSpan={lot.items.length}
                                                                                        align="center">
                                                                                        {lot.contract_time}
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        rowSpan={lot.items.length}
                                                                                        align="center">
                                                                                        {lot.postponement}
                                                                                    </TableCell>
                                                                                </React.Fragment>
                                                                            }
                                                                        </TableRow>
                                                                    ))
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value={value} index={2}>
                                            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                <Grid item>
                                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                                                        <Grid item>
                                                            <Typography variant="button">
                                                                Заказчик:
                                                            </Typography>
                                                            <Typography>
                                                                {order.company.legal_detail.name} {order.user ? getFullName(order.user) : null}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <TableContainer className={classes.tableContainer}>
                                                        <Table stickyHeader className={classes.table}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    {[{
                                                                        id: '0',
                                                                        label: 'Товары'
                                                                    }, {
                                                                        id: '1',
                                                                        label: 'В заказе'
                                                                    }, {
                                                                        id: '2',
                                                                        label: 'Кол-во предложений'
                                                                    }, {
                                                                        id: '3',
                                                                        label: 'Минимальная цена'
                                                                    }, {
                                                                        id: '4',
                                                                        label: 'Максимальная цена'
                                                                    }, {
                                                                        id: '5',
                                                                        label: 'Среднее арифметическое, цена'
                                                                    }, {
                                                                        id: '6',
                                                                        label: 'Среднее взвешенное, цена'
                                                                    }].map((column) => (
                                                                        <TableCell
                                                                            key={column.id}
                                                                        >
                                                                            {column.label}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {Object.keys(positions).map((name, i) => {
                                                                    return (
                                                                        <React.Fragment key={i}>
                                                                            <TableRow hover className={classes.tableRowProduct} onClick={() => setExpanded((!expanded || (expanded !== (i + 1))) ? (i + 1) : false)}>
                                                                                <TableCell>
                                                                                    <Typography>{name}</Typography>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {parseFloat(order.lots.reduce(function (total, lot) {
                                                                                        return total + lot.items.reduce(function (sum, item) {
                                                                                            return sum + ((item.name === name) ? parseFloat(item.amount) : 0)
                                                                                        }, 0)
                                                                                    }, 0)).toFixed(2)}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Typography>{positions[name].length}</Typography>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Typography>{parseFloat(positions[name].reduce((acc, curr) => acc.price < curr.price ? acc : curr).price).toFixed(2)}</Typography>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Typography>{parseFloat(positions[name].reduce((acc, curr) => acc.price > curr.price ? acc : curr).price).toFixed(2)}</Typography>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Typography>{parseFloat(positions[name].reduce((acc, curr) => acc + Number(curr.price), 0) / positions[name].length).toFixed(2)}</Typography>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Typography>{parseFloat(function () {
                                                                                        let weights = {}

                                                                                        positions[name].forEach((position) => {
                                                                                            weights.hasOwnProperty(position.price) ? weights[position.price] += 1 : weights[position.price] = 1
                                                                                        })

                                                                                        let result = 0

                                                                                        for (const price in weights) {
                                                                                            result += price * weights[price]
                                                                                        }

                                                                                        return positions[name].length ? result / positions[name].length : 0
                                                                                    }()).toFixed(2)}</Typography>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell style={{
                                                                                    paddingBottom: 0,
                                                                                    paddingTop: 0
                                                                                }} colSpan={7}>
                                                                                    <Collapse in={!!((expanded === (i + 1)) && (order.confirmation[name].length))} timeout="auto" unmountOnExit>
                                                                                        <TableContainer className={classes.tableContainer}>
                                                                                            <Typography variant="h6" gutterBottom component="div">
                                                                                                Предложения
                                                                                            </Typography>
                                                                                            <Table>
                                                                                                <TableHead>
                                                                                                    <TableRow>
                                                                                                        {[{
                                                                                                            id: '0',
                                                                                                            label: 'Остатки'
                                                                                                        }, {
                                                                                                            id: '1',
                                                                                                            label: 'Заказ, от'
                                                                                                        }, {
                                                                                                            id: '2',
                                                                                                            label: 'Цена'
                                                                                                        }, {
                                                                                                            id: '3',
                                                                                                            label: ''
                                                                                                        }].map((column) => (
                                                                                                            <TableCell
                                                                                                                key={column.id}>
                                                                                                                {column.label}
                                                                                                            </TableCell>
                                                                                                        ))}
                                                                                                    </TableRow>
                                                                                                </TableHead>
                                                                                                <TableBody>
                                                                                                    {positions[name].map((position, index) => {
                                                                                                        return (
                                                                                                            <TableRow key={index} className={(positions[name].length === (index + 1)) ? classes.tableRowLast : classes.tableRow}>
                                                                                                                <TableCell className={(position.active && (!position.hasOwnProperty('disabled') || !position.disabled)) ? classes.active : classes.disabled}>
                                                                                                                    {parseFloat(position.stock_quantity).toFixed(2)}
                                                                                                                </TableCell>
                                                                                                                <TableCell
                                                                                                                    className={(position.active && (!position.hasOwnProperty('disabled') || !position.disabled)) ? classes.active : classes.disabled}>
                                                                                                                    {position.min_ordered_quantity ? parseFloat(position.min_ordered_quantity).toFixed(2) : null}
                                                                                                                </TableCell>
                                                                                                                <TableCell
                                                                                                                    className={(position.active && (!position.hasOwnProperty('disabled') || !position.disabled)) ? classes.active : classes.disabled}>
                                                                                                                    {position.price_discount &&
                                                                                                                        <Typography
                                                                                                                            variant="caption"
                                                                                                                            className={(position.active && (!position.hasOwnProperty('disabled') || !position.disabled)) ? classes.activeLine : classes.disabledLine}>
                                                                                                                            {parseFloat(position.price + (position.price_discount ?? 0)).toLocaleString('ru-RU', {
                                                                                                                                style: 'currency',
                                                                                                                                currency: 'RUB'
                                                                                                                            })}
                                                                                                                        </Typography>
                                                                                                                    }
                                                                                                                    <Typography
                                                                                                                        className={(position.active && (!position.hasOwnProperty('disabled') || !position.disabled)) ? classes.active : classes.disabled}>
                                                                                                                        {parseFloat(position.price).toLocaleString('ru-RU', {
                                                                                                                            style: 'currency',
                                                                                                                            currency: 'RUB'
                                                                                                                        })}
                                                                                                                    </Typography>
                                                                                                                    {position.price_discount &&
                                                                                                                        <Typography className={(position.active && (!position.hasOwnProperty('disabled') || !position.disabled)) ? classes.sale : classes.disabledSale} component="span">
                                                                                                                            С учетом скидки/надбавки {parseFloat(position.price_discount / (position.price + (position.price_discount ?? 0))) * 100} %
                                                                                                                        </Typography>
                                                                                                                    }
                                                                                                                </TableCell>
                                                                                                                <TableCell className={(position.active && (!position.hasOwnProperty('disabled') || !position.disabled)) ? classes.active : classes.disabled}>
                                                                                                                    {((positions[name].length > 1) && !position.active) && "Предложение не прошло антидемпинговую проверку"}
                                                                                                                </TableCell>
                                                                                                            </TableRow>
                                                                                                        );
                                                                                                    })}
                                                                                                </TableBody>
                                                                                            </Table>
                                                                                        </TableContainer>
                                                                                    </Collapse>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.footer}>
                                    <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                        <Grid item className={classes.footerContent}>
                                            <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                {(value === 2) &&
                                                    <Grid item>
                                                        <Submit
                                                            disableElevation
                                                            variant="contained"
                                                            color="primary"
                                                            type="button"
                                                            endIcon={<GetAppIcon/>}
                                                            target="_blank"
                                                            href={process.env.REACT_APP_HOST_API + `/shop/order/${order.id}/confirmation?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                            download
                                                        >
                                                            Скачать
                                                        </Submit>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    ) : null
}
