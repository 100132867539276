import {ChatService} from "../../services/comment/chat"

export const ChatActions = {
    create,
    addUserToChat
}


function create(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({ type: "CREATE_COMMENT_CHAT_REQUEST" })

        ChatService.create(values)
            .then(
                chat => {
                    dispatch({type: "CREATE_COMMENT_CHAT_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(chat)
                },
                error => {
                    dispatch({type: "CREATE_COMMENT_CHAT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function addUserToChat(chatId) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({ type: "ADD_USER_TO_COMMENT_CHAT_REQUEST" })

        ChatService.addUserToChat(chatId)
            .then(
                _ => {
                    dispatch({type: "ADD_USER_TO_COMMENT_CHAT_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "ADD_USER_TO_COMMENT_CHAT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}
