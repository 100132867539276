import {HttpService} from "../../../App/services/http"

export const ShopService = {
    categories,
    deleteProducts,
}

function categories() {
    return HttpService.get("/catalog/supplier/categories")
}

function deleteProducts(params) {
    return HttpService.deleteMethod("/catalog/supplier/products", params, true, 'params')
}
