import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"

import {Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, TablePagination, withStyles} from '@material-ui/core'
import {History as HistoryIcon} from "@material-ui/icons"

import {ContractActions} from "./actions/contract"
import {AuthorizationService} from "../Auth/services/authorization";
import {Link} from "react-router-dom";

const useStyles = makeStyles(() => ({
    item: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "cursor": "pointer"
    },
    active: {
        "background-image": "none !important",
        "background-color": "#898989"
    },
    paginationWrap: {
        backgroundColor: "#e7eaee",
        position: "fixed",
        bottom: "0",
        maxWidth: "300px"
    },
    pagination: {
        "&>div": {
            flexWrap: 'wrap'
        }
    },
    listItem: {
        overflow: "auto",
        height: "calc(100vh - 200px)"
    }
}))

const CustomList = withStyles({
    root: {
        height: "100%",
        "padding": "0",
        "& .MuiListItem-button": {
            "background-image": "linear-gradient(to bottom,#e4e4e4,#f4f4f4)",
            "cursor": "pointer",
            "align-items": "center",
            "min-height": "50px",
            "padding": "5px 25px 0",
            "border-bottom": "1px solid #e4e4e4",
            "outline": "0"
        },
        "& .MuiListItemText-primary": {
            "font-size": "16px",
            "font-weight": "700",
            "text-transform": "uppercase"
        },
        "& .MuiListItem-button:hover": {
            "cursor": "pointer",
            "background-color": "#898989",
            "&:after": {
                "position": "absolute",
                "content": "''",
                "top": "0",
                "left": "0",
                "width": "100%",
                "height": "100%",
                "background-color": "rgba(0,0,0,.04)"
            }
        },
        "& .MuiListItem-root": {
            "cursor": "default"
        },
        "& .MuiListItemIcon-root": {
            "min-width": "30px"
        },
        "& .MuiListItemText-secondary": {
            "color": "#485868",
            "font-size": "16px"
        }
    }
})(List);

export const Menu = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {location} = props
    const {account, companies} = useSelector(state => ({...state.account, ...state.contract}))

    useEffect(() => {
        const getCompanies = async () => {
            return await dispatch(ContractActions.companies({
                limit: companies.filter.limit,
                page: companies.filter.page
            }))
        }

        getCompanies().then(_ => {})
    }, [dispatch, companies.filter.limit, companies.filter.page]);

    const handleChangePage = (event, newPage) => {
        dispatch({type: "CONTRACT_COMPANIES_FILTER", payload: {
                limit: companies.filter.limit,
                page: Number(newPage) + 1,
        }})
    }

    const handleChangeRowsPerPage = (event) => {
        dispatch({type: "CONTRACT_COMPANIES_FILTER", payload: {
                limit: +event.target.value,
                page: 1,
       }})
    }

    const menu = [
        ...(AuthorizationService.permissions(account, ['change_contract_status']) ? [
            {title: "Изменение статуса", to: "/contract/change/status"},
        ] : [
            {title: "Контрагенты"}
        ])
    ]

    return (
        <CustomList>
            {menu.map((option, index) => {
                if (option.hasOwnProperty('to')) {
                    return <ListItem
                        button
                        key={option.title}
                        component={Link}
                        to={option.to}
                        classes={{root: (location.pathname === option.to) ? classes.active : undefined}}
                    >
                        <ListItemText primary={option.title} />
                    </ListItem>
                } else {
                    return <React.Fragment key={index}>
                        <ListItem button key={option.title}>
                            <ListItemText primary={option.title} />
                        </ListItem>
                        <List component="div" disablePadding className={classes.listItem}>
                            {companies.data.map(company => (
                                <ListItem
                                    classes={{root: classes.item}}
                                    key={company.id}
                                >
                                    <ListItemIcon>
                                        <React.Fragment/>
                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={
                                            <Grid component={'span'} container direction="row" justify="flex-start"
                                                  alignItems="center" spacing={1}>
                                                {!company.active && <Grid component={'span'} item><HistoryIcon/></Grid>}<Grid
                                                component={'span'} item>{company.legal_detail.name}</Grid>
                                            </Grid>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                        <ListItem className={classes.paginationWrap}>
                            <TablePagination
                                rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                component='div'
                                classes={{root: classes.pagination}}
                                labelRowsPerPage={'Записей на странице:'}
                                count={companies.meta.total}
                                rowsPerPage={companies.filter.limit}
                                page={companies.filter.page - 1}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </ListItem>
                    </React.Fragment>
                }
            })}
        </CustomList>
    )
}
