import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebouncedCallback} from "use-debounce"
import ArrayStore from 'devextreme/data/array_store'
import {TagBox} from "devextreme-react"
import clsx from "clsx"

import {Grid, InputAdornment, makeStyles, Paper, TablePagination, TextField} from "@material-ui/core"
import {Search as SearchIcon} from "@material-ui/icons"

import {ShopActions} from "../../actions/shop"
import {ButtonList} from "../../../../App/components/ButtonList/ButtonList"
import {OrdersList} from "../../../Orders/components/OrdersList"
import {AuthorizationService} from "../../../../Auth/services/authorization"
import {CompanyActions} from "../../../../Account/actions/company"
import {SystemActions} from "../../../../App/actions/system"
import {history} from "../../../../App/helpers/history";
import {SelectTree} from "../../../../App/components/Inputs/SelectTree";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%",
        "display": "flex",
        "justify-content": "space-between",
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 166px)"
    },
    containerRows: {
        "width": "100%",
        "height": "calc(100vh - 264px)",
        "overflow": "auto",
        "padding": "15px 15px 0 15px"
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100% - 70px)",
        "min-height": "calc(100% - 70px)"
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    searchLabel: {
        "margin-bottom": "14px",
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    },
    filterSelect: {
        "width": "100% !important",
        "border": "2px solid #c1c1c1 !important",
        "padding": "0 15px 0 15px !important",
        "background": "#fff !important",
        "margin-top": "6px !important",
        "transition": "border-color .2s ease-in !important",
        "border-radius": "2px !important",
        "&:hover": {
            "border-color": "#898989 !important",
        },
        "margin-bottom": "14px !important",
        "height": "36px",
        "& .dx-tag-remove-button": {
            "display": "none",
        },
    },
}))

const sortList = [
    {
        key: 'all',
        value: 'Все'
    },
    {
        key: 'active',
        value: 'Активные'
    }
]

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { orders } = useSelector(state => state.mySupplierOrders)
    const { account, company, options } = useSelector(state => {return {...state.account, ...state.company, ...{options: state.system.categories}}})

    const [tab, setTab] = useState(0)
    const [search, setSearch] = useState(null)
    const [sort, setSort] = useState('all')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [regions, setRegions] = useState([])
    const [categories, setCategories] = useState([])

    const containerRef = useRef(null)

    const supRegionsArray = company?.terms_participation?.regions
    const supRegions = supRegionsArray?.map((item) => item.region)

    let supRegionsSource = new ArrayStore({
        data: supRegions,
        key: 'id',
    })

    const SupRegion = function Item(data) {
        return (
            <div className="custom-item">
                <div className="product-name">{data.name}</div>
            </div>
        );
    }

    useEffect(() => {
        const getData = async () => {
            await dispatch(CompanyActions.company({include: "legalDetail.city.region.country,legalDetail.city.region.country,termsParticipation.regions.region"}))
            if (!options.length) {
                await dispatch(SystemActions.categories())
            }
        }
        getData().then(() => {})
        // eslint-disable-next-line
    }, [dispatch])

    const debounced = useDebouncedCallback(
        (value) => {
            setSearch(value)
        }, 700
    )

    const getOrders = async () => {
        return await dispatch(ShopActions.orders({
            limit: rowsPerPage,
            page: page + 1,
            sort: sort,
            ...(regions && regions.length ? {regions: regions.join(',')} : {}),
            ...(categories.length ? {categories: categories.join(',')} : {}),
            ...(search ? {search: search} : {}),
        }))
    }

    const getSortedOrders = () => {
        dispatch({type: 'MY_LOT_CLEAR'})
        getOrders().then(() => {
            containerRef.current && containerRef.current.scrollTo({behavior: 'smooth', top: 0})
            if (!AuthorizationService.permissions(account, ["access_to_full_elmag"])) {
                dispatch({
                    type: "ALERT_ERROR",
                    payload: {
                        message: 'Заполните профиль и условия участия',
                        type: 'error'
                    }
                })
                history.push('/account')
            }
        })
    }

    useEffect(() => {
        getSortedOrders()
        // eslint-disable-next-line
    }, [page, rowsPerPage, sort, search, regions, categories]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    return <Grid item className={classes.content}>
            <Grid container direction="column" justify="center" alignItems="stretch" style={{flexWrap:'nowrap'}}>
                <Grid item>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid item className={ classes.fullWidth } xs={3}>
                            <TextField
                                id="search"
                                variant="standard"
                                name={'search'}
                                placeholder={'Поиск'}
                                fullWidth={true}
                                margin={'normal'}
                                onChange={(event) => {
                                    debounced.callback(event.target.value)
                                }}
                                classes={{
                                    root: clsx(classes.filterLabel, classes.searchLabel)
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon  style={{color: '#c1c1c1'}}/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item className={classes.fullWidth} xs={2}>
                            <TagBox
                                className={classes.filterSelect}
                                dataSource={supRegionsSource}
                                itemRender={SupRegion}
                                displayExpr="name"
                                valueExpr="id"
                                showSelectionControls={true}
                                placeholder="Поиск: регион"
                                multiline={false}
                                searchEnabled={true}
                                onValueChanged={({value}) => {
                                    setRegions(value)
                                }}
                                selectAllText={'Выбрать все'}
                            />
                        </Grid>
                        <Grid item xs={4} style={{ margin: '6px 0 14px 0' }}>
                            <SelectTree
                                label="Поиск: категория"
                                options={options.map(category => ({
                                    ...category,
                                    parent: category.category
                                }))}
                                variant="outlined"
                                value={categories}
                                multiple={true}
                                onChange={(value) => {
                                    setCategories(value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <ButtonList
                                value={tab}
                                values={sortList}
                                onChange={(_, value) => {
                                    setTab(value);
                                    setPage(0)
                                    setSort(sortList[value].key);
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.fullWidth}>
                        <Grid container className={classes.container} direction="column" justify="space-between" alignItems="stretch">
                            <Grid item className={classes.inputContent}>
                                <Grid ref={containerRef} container className={classes.containerRows} direction="column" wrap="nowrap" justify="flex-start" alignItems="flex-start">
                                    <OrdersList orders={orders} />
                                </Grid>
                            </Grid>
                            {orders.data &&
                                <Grid item className={classes.footer}>
                                    <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                        <Grid item className={classes.footerContent}>
                                            <Grid container className={classes.footerContainer} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                <Grid item>
                                                    <TablePagination
                                                        rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                                        component='div'
                                                        labelRowsPerPage={'Записей на странице:'}
                                                        count={orders.meta.total}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onChangePage={handleChangePage}
                                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
}
