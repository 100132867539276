export default function priceTypes(state = {}, action) {
    switch (action.type) {
        case "PRICE_TYPES":
            return {
                priceTypes: action.payload
            }
        default:
            return state
    }
}
