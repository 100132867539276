import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, FieldArray, Form, Formik, useFormikContext} from "formik"
import {CheckboxWithLabel} from "formik-material-ui"

import {Grid, IconButton, makeStyles, Typography} from "@material-ui/core"
import {Clear, Add as AddIcon} from "@material-ui/icons"

import {TextFieldWithError as CustomTextField} from "../../../../App/components/Inputs/TextFieldWithError"
import {Submit} from "../../../../App/components/Button/Submit"
import {AccountActions} from "../../../actions/account"
import {getValidationSchema} from "./validation/credentials"


const useStyles = makeStyles(() => ({
    inputContent: {
        "padding": "20px 30px 0"
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 298px)"
    },
    column: {
        "height": "86px"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    }
}))

export const Credentials = (props) => {
    const dispatch = useDispatch()
    const { setDirty, setLoading } = props
    const classes = useStyles()
    const { account, permissions } = useSelector(state => { return {...state.account, ...state.authentication} })

    const AutoDirty = () => {
        const { dirty } = useFormikContext()

        React.useEffect(() => {
            setDirty(dirty)
        }, [dirty])

        return null
    };

    return (
        <Grid item>
            <Formik
                enableReinitialize={ true }
                initialValues={{
                    last_name: account.last_name,
                    first_name: account.first_name,
                    middle_name: account.middle_name ?? '',
                    login: account.login,
                    email: account.email ?? '',
                    skype: account.skype ?? '',
                    phones: account.phones ? account.phones.map(phone => { return {number: phone.number ?? '', extra: phone.extra ?? ''} }) : [],
                    position: account.position ?? '',
                    password: '',
                    confirmation: '',
                    permissions: permissions.reduce((obj, permission) => {obj[permission.id] = !!(account && account.hasOwnProperty('permissions') && account.permissions.find(item => item.id === permission.id)); return obj}, {})
                }}
                validationSchema={getValidationSchema()}
                onSubmit={(values, {setSubmitting}) => {
                    return dispatch(AccountActions.edit(values)).then(
                        () => {
                            setSubmitting(false)
                            setLoading(false)
                        },
                        errors => {
                            if (errors) {
                            }
                            setSubmitting(false)
                        }
                    )
                }}
            >
                {({
                      dirty,
                      values,
                      isSubmitting,
                      isValid,
                  }) => (
                    <Form>
                        <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                            <Grid item className={classes.inputContent}>
                                <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
                                    <Grid item className={classes.list}>
                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                            <Grid item xs={4} className={classes.column}>
                                                <Field
                                                    fullWidth
                                                    name="last_name"
                                                    type="text"
                                                    label="Фамилия"
                                                    required
                                                    disabled
                                                    component={CustomTextField}
                                                />
                                            </Grid>
                                            <Grid item xs={4} className={classes.column}>
                                                <Field
                                                    fullWidth
                                                    name="first_name"
                                                    type="text"
                                                    label="Имя"
                                                    required
                                                    disabled
                                                    component={CustomTextField}
                                                />
                                            </Grid>
                                            <Grid item xs={4} className={classes.column}>
                                                <Field
                                                    fullWidth
                                                    name="middle_name"
                                                    type="text"
                                                    label="Отчество"
                                                    disabled
                                                    component={CustomTextField}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.list}>
                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                            <Grid item xs={4} className={classes.column}>
                                                <Field
                                                    fullWidth
                                                    name="login"
                                                    type="text"
                                                    label="Имя пользователя"
                                                    required
                                                    disabled
                                                    component={CustomTextField}
                                                />
                                            </Grid>
                                            <Grid item xs={4} className={classes.column}>
                                                <Field
                                                    fullWidth
                                                    name="position"
                                                    type="text"
                                                    label="Должность"
                                                    component={CustomTextField}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.list}>
                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                            <Grid item xs={4} className={classes.column}>
                                                <Field
                                                    fullWidth
                                                    name="password"
                                                    type="password"
                                                    label="Пароль"
                                                    component={CustomTextField}
                                                />
                                            </Grid>
                                            <Grid item xs={4} className={classes.column}>
                                                <Field
                                                    fullWidth
                                                    name="confirmation"
                                                    type="password"
                                                    label="Подтверждение пароля"
                                                    component={CustomTextField}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.list}>
                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                            <Grid item xs={4} className={classes.column}>
                                                <Field
                                                    fullWidth
                                                    name="email"
                                                    type="email"
                                                    label="Адрес электронной почты"
                                                    required
                                                    component={CustomTextField}
                                                />
                                            </Grid>
                                            <Grid item xs={4} className={classes.column}>
                                                <Field
                                                    fullWidth
                                                    name="skype"
                                                    type="text"
                                                    label="Skype"
                                                    component={CustomTextField}
                                                />
                                            </Grid>
                                        </Grid>
                                        <FieldArray name="phones" render={ arrayHelpers => (
                                            <React.Fragment>
                                                {values.phones.map((phone, index) => {
                                                    return (
                                                        <Grid key={index} container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                            <Grid item xs={2} className={classes.column}>
                                                                <Field
                                                                    fullWidth
                                                                    name={`phones.${index}.number`}
                                                                    type="phone"
                                                                    label="Номер телефона"
                                                                    component={CustomTextField}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2} className={classes.column}>
                                                                <Field
                                                                    fullWidth
                                                                    name={`phones.${index}.extra`}
                                                                    type="text"
                                                                    label="доб."
                                                                    component={CustomTextField}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <IconButton
                                                                    size={"small"}
                                                                    onClick={() => {
                                                                        arrayHelpers.remove(index)
                                                                    }}
                                                                >
                                                                    <Clear />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}
                                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                    <Grid item>
                                                        <Submit
                                                            size="small"
                                                            variant="contained"
                                                            component="label"
                                                            color="primary"
                                                            endIcon={<AddIcon/>}
                                                            onClick={() => {
                                                                arrayHelpers.push({number: '', extra: ''})
                                                            }}
                                                        >
                                                            Добавить номер телефона
                                                        </Submit>
                                                    </Grid>
                                                </Grid>
                                            </React.Fragment>
                                        )}/>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.stepTitle}>Назначенные роли</Typography>
                                    </Grid>
                                    {
                                        permissions.filter(per => [
                                            'access_to_standard_settings', 'control_price', 'access_to_companies',
                                            'access_to_contract_settings', 'access_to_order_settings', 'access_to_planning_settings',
                                            'submission_to_request', 'access_to_elmag', 'change_contract_status', 'change_delivery_order_status'
                                        ].indexOf(per.name) === -1).map(permission => (
                                            <Grid item key={permission.id}>
                                                <Field
                                                    component={CheckboxWithLabel}
                                                    name={`permissions[${permission.id}]`}
                                                    color="primary"
                                                    disabled
                                                    Label={{label: permission.description}}
                                                />
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.footer}>
                            <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                <Grid item className={classes.footerContent}>
                                    <Grid container className={classes.footerContainer} direction="row"
                                          justify="flex-end" alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Submit
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={isSubmitting || !isValid || !dirty}
                                            >
                                                Сохранить
                                            </Submit>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <AutoDirty />
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}
