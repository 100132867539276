import React from "react";
import {DoneAll as SignIcon} from "@material-ui/icons";
import {IconButton} from "@material-ui/core";
import {b64toBlob} from "./utils/b64toBlob";

export const Download = (props) => {
  const {file, size = "medium"} = props

  return (
    <IconButton
      size={size}
      onClick={() => {
        const blob = b64toBlob(file.sign, 'text/plain')

        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = `${file.name}.sig`;
        a.click();

        URL.revokeObjectURL(url);
      }}
    >
      <SignIcon />
    </IconButton>
  )
}
