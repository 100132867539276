import React from "react";
import {Link} from "react-router-dom"
import {useDispatch} from "react-redux"
import format from "date-fns/format"
import {ru} from "date-fns/locale"
import clsx from 'clsx'

import {
    Box,
    Button,
    Grid,
    makeStyles,
    Step,
    StepConnector,
    StepContent,
    StepLabel,
    Stepper,
    Tab,
    Tabs,
    Typography,
    withStyles
} from "@material-ui/core"
import {Check, GetApp as GetAppIcon} from '@material-ui/icons'

import {ShopActions} from "../../actions/shop"
import {Submit} from "../../../../../App/components/Button/Submit"
import {day as formatDay} from "../../../../../App/helpers/date"
import {TabPanel} from "../../../../../Account/components/Tabs/TabPanel"
import * as AppStorage from "../../../../../App/helpers/storage"

const useStyles = makeStyles(() => ({
    download: {
        "color": "#485868"
    },
    tabs: {
        "& .MuiTabs-flexContainer": {
            "align-items": "flex-start"
        }
    },
    link: {
        "display": "flex",
        "line-height": "31px",
        "color": "#485868",
    },
    tab: {
        "& .MuiTab-wrapper": {
            "align-items": "flex-start",
            "text-align": "left"
        }
    },
    status: {
        "width": "100%"
    },
    file: {
        "color": "rgba(0, 0, 0, 0.54)"
    },
    icon: {
        "& .MuiButton-endIcon": {
            "margin-left": "0",
            "margin-right": "0"
        }
    },
}))

const Connector = withStyles({
    alternativeLabel: {
        "top": "10",
        "left": "calc(-50% + 16px)",
        "right": "calc(50% + 16px)"
    },
    active: {
        '& $line': {
            "border-color": "#616161"
        },
    },
    completed: {
        '& $line': {
            "border-color": "#616161"
        },
    },
    line: {
        "border-color": "#eaeaf0",
        "border-top-width": "3",
        "border-radius": "1"
    },
    vertical: {
        "padding": "0 0 8px",
        "margin-left": "12px"
    }
})(StepConnector);

const useStepIconStyles = makeStyles({
    root: {
        "color": "#eaeaf0",
        "display": "flex",
        "height": "22",
        "align-items": "center"
    },
    active: {
        "color": "#616161"
    },
    circle: {
        "width": 12,
        "height": 12,
        "border-radius": '50%',
        "background-color": 'currentColor',
        "margin-left": "6px",
        "margin-right": "6px"
    },
    completed: {
        "color": "#616161",
        "z-index": "1",
        "font-size": "18"
    }
});

function StepIcon(props) {
const classes = useStepIconStyles();
const { active, completed } = props;

return (
    <div
        className={clsx(classes.root, {
            [classes.active]: active,
        })}
    >
        {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
);
}

export const Log = (props) => {
const dispatch = useDispatch()
const classes = useStyles()
let { order, setLoading } = props
const [submit, setSubmit] = React.useState(false)
const [value, setValue] = React.useState(order.lots.reduce(function (first, second) {return (first.index < second.index) ? first : second;}).index)

const getStep = (index, item, lot, order) => {
    let content = null

    switch (parseInt(item.status.index)) {
        case 2:
            content = (
                <Step key={item.id} completed={!!index} active={true}>
                    <StepLabel StepIconComponent={StepIcon}>{item.status.name} {lot.log.hasOwnProperty(index - 1) ? `(${format(new Date(lot.log[index - 1].created), 'H:mm PP',  {locale: ru})})` : null}</StepLabel>
                </Step>
            )
            break
        case 3:
            content = (
                <Step key={item.id} completed={!!index} active={!index}>
                    <StepLabel StepIconComponent={StepIcon}>{item.status.name} {`(${format(new Date(item.created), 'H:mm PP',  {locale: ru})})`}</StepLabel>
                    <StepContent>
                        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                            <Grid item>
                                <Typography variant="body2" gutterBottom>{item.comment}</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                                    {lot.winner &&
                                        <React.Fragment>
                                            <Grid item>
                                                <Typography variant="body2" gutterBottom>Победитель: {lot.winner.company.legal_detail.name}</Typography>
                                                <Typography variant="body2" gutterBottom>{(lot.winner.company.legal_detail.city.region.country.code === 'ru') ? `ИНН: ${lot.winner.company.legal_detail.inn}` : `БИН: ${lot.winner.company.legal_detail.bin}`} Адрес: {lot.winner.company.legal_detail.legal_address}</Typography>
                                                <Typography variant="body2" gutterBottom>Срок поставки: {formatDay(lot.winner.revision.contract_time)}. Сумма договора: {parseFloat(lot.winner.contract.total_price).toLocaleString('ru-RU', {style: 'currency', currency: order.currency.code}).replace('KZT', '₸')}. Отсрочка: {lot.winner.revision.postponement ? formatDay(lot.winner.revision.postponement) : null}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                                                    <Grid item>
                                                        <Submit
                                                            variant="contained"
                                                            color="primary"
                                                            type="button"
                                                            component={Link}
                                                            to={`/contracts?id=${lot.winner.contract.id}`}
                                                        >
                                                            Договор
                                                        </Submit>
                                                    </Grid>
                                                    {item.files.find(file => (file.type.key === 'protocol')) ? (
                                                        <Grid item>
                                                            <Button
                                                                className={classes.download}
                                                                endIcon={<GetAppIcon/>}
                                                                href={process.env.REACT_APP_HOST_API + `/shop/order/${order.id}/file/${item.files.find(file => (file.type.key === 'protocol')).id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                            >
                                                                Скачать протокол
                                                            </Button>
                                                        </Grid>
                                                    ) : null}
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </StepContent>
                </Step>
            )
            break
        case 4:
            content = (
                <Step key={item.id} completed={!!index}>
                    <StepLabel StepIconComponent={StepIcon}>{item.status.name} {`(${format(new Date(item.created), 'H:mm PP',  {locale: ru})})`}</StepLabel>
                    <StepContent>
                        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                            <Grid item>
                                <Typography variant="body2" gutterBottom>{item.comment}</Typography>
                            </Grid>
                        </Grid>
                    </StepContent>
                </Step>
            )
            break
        case 5:
            content = (
                <Step key={item.id} completed={!!index} active={true}>
                    <StepLabel StepIconComponent={StepIcon}>{item.status.name} {lot.log.hasOwnProperty(index - 1) ? `(${format(new Date(lot.log[index - 1].created), 'H:mm PP',  {locale: ru})})` : null}</StepLabel>
                    <StepContent>
                        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                            <Grid item>
                                <Typography variant="body2" gutterBottom>{item.comment}</Typography>
                            </Grid>
                            {(!!lot.active && !(!!index)) ?
                                <Grid item>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Submit
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                type="button"
                                                disabled={submit}
                                                onClick={() => {
                                                    setSubmit(true)
                                                    return dispatch(ShopActions.status(order.id, lot.id)).then(
                                                        () => {
                                                            setSubmit(false)
                                                            setLoading(false)
                                                        },
                                                        () => {
                                                            setSubmit(false)
                                                        }
                                                    )
                                                }}
                                            >
                                                Начать согласование на единого поставщика
                                            </Submit>
                                        </Grid>
                                    </Grid>
                                </Grid> :
                                ((!!item.files.length) ?
                                    <Grid item>
                                        <Button
                                            className={classes.download}
                                            endIcon={<GetAppIcon/>}
                                            href={process.env.REACT_APP_HOST_API + `/shop/order/${order.id}/file/${item.files.find(file => file.type.key === 'protocol').id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                        >
                                            Скачать протокол
                                        </Button>
                                    </Grid> : null
                                )
                            }
                        </Grid>
                    </StepContent>
                </Step>
            )
            break
        case 6:
            content = (
                <Step key={item.id} completed={!!index} active={true}>
                    <StepLabel StepIconComponent={StepIcon}>{item.status.name} {lot.log.hasOwnProperty(index - 1) ? `(${format(new Date(lot.log[index - 1].created), 'H:mm PP',  {locale: ru})})` : null}</StepLabel>
                    <StepContent>
                        {lot.status.index === 6 && !lot.winner &&
                                <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                <Grid item>
                                    <Typography variant="body2" gutterBottom>{item.comment}</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Submit
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                type="button"
                                                disabled={submit}
                                                onClick={() => {
                                                    setSubmit(true)
                                                    const formData = new FormData()
                                                    formData.append('confirmed', JSON.stringify(1))
                                                    return dispatch(ShopActions.status(order.id, lot.id, formData)).then(
                                                        () => {
                                                            setSubmit(false)
                                                            setLoading(false)
                                                        },
                                                        () => {
                                                            setSubmit(false)
                                                        }
                                                    )
                                                }}
                                            >
                                                Согласован
                                            </Submit>
                                        </Grid>
                                        <Grid item>
                                            <Submit
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                type="button"
                                                disabled={submit}
                                                onClick={() => {
                                                    setSubmit(true)
                                                    const formData = new FormData()
                                                    formData.append('confirmed', JSON.stringify(0))
                                                    return dispatch(ShopActions.status(order.id, lot.id, formData)).then(
                                                        () => {
                                                            setSubmit(false)
                                                            setLoading(false)
                                                        },
                                                        () => {
                                                            setSubmit(false)
                                                        }
                                                    )
                                                }}
                                            >
                                                Не согласован
                                            </Submit>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </StepContent>
                </Step>
            )
            break
        case 7:
            content = (
                <Step key={item.id} completed={!!index} active={true}>
                    <StepLabel StepIconComponent={StepIcon}>{item.status.name} {lot.log.hasOwnProperty(index - 1) ? `(${format(new Date(lot.log[index - 1].created), 'H:mm PP',  {locale: ru})})` : null}</StepLabel>
                    <StepContent>
                        {item.files.find(file => (file.type.key === 'protocol')) ? (
                            <Grid item>
                                <Button
                                    className={classes.download}
                                    endIcon={<GetAppIcon/>}
                                    href={process.env.REACT_APP_HOST_API + `/shop/order/${order.id}/file/${item.files.find(file => (file.type.key === 'protocol')).id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                >
                                    Скачать протокол
                                </Button>
                            </Grid>
                        ) : null}
                    </StepContent>
                </Step>
            )
            break
        default:
    }

    return content
}

const handleChange = (_, index) => {
    setValue(index);
};

return (
    <Grid item>
        <Grid container direction="row" justify="flex-start" alignItems="stretch">
            <Grid item>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    className={classes.tabs}
                >
                    {order.lots.map((lot, index) => (
                        <Tab
                            className={classes.tab}
                            key={index}
                            label={
                                <React.Fragment>
                                    <Typography>Лот № {lot.number ? lot.number : lot.index}</Typography>
                                    <Typography className={classes.status} variant="caption" noWrap>{lot.status.name}</Typography>
                                    {lot.winner && <Typography className={classes.status} variant="body2" noWrap>{lot.winner.company.legal_detail.name}</Typography>}
                                </React.Fragment>
                            }
                            value={lot.index} />
                    ))}
                </Tabs>
            </Grid>
            <Grid item>
                {order.lots.map((lot, i) => (
                    <TabPanel key={i} value={value} index={lot.index}>
                        <Box p={3}>
                        <Stepper activeStep={0} orientation="vertical" nonLinear={true} connector={<Connector />}>
                            {lot.log.map((item, index) => (
                                getStep(index, item, lot, order)
                            ))}
                        </Stepper>
                        </Box>
                    </TabPanel>
                ))}
            </Grid>
        </Grid>
    </Grid>
)
}
