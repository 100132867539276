const status = {
    DRAFT: 1,
    APPROVED_BY_CHUZ_AGREED_BY_USER: 2,
    APPROVED_BY_CHUZ_AGREED_BY_ECONOMIST: 3,
    APPROVED_BY_CHUZ: 4,
    IN_WORK_RDZ: 5,
    APPROVED_BY_RDZ: 6,
    REJECTED_RDZ: 7,
    ON_COMPLETION_CHUZ: 8,
    IN_WORK_CDZ: 9,
    APPROVED_BY_CDZ: 10,
    REJECTED_CDZ: 11,
    REFUSAL_TO_PURCHASE: 12,
    PURCHASE_TRANSFER: 13,
    FAIL: 14,
    START_PURCHASE_PROCEDURE: 15,
    SUBMISSION_OF_APPLICATION: 16,
    APPLICATIONS_OPENING: 17,
    APPLICATIONS_REVIEW: 18,
    SUMMARIZING: 19,
    SEND_FOR_APPROVAL_TO_CDZ_OR_RDZ: 20,
    CANCEL: 21,
    CONTRACT_IS_CONCLUDED: 22,
    CORRECTION_APPROVED: 23,
    CORRECTION_REQUEST: 24,
    ON_CORRECTION: 25,
    CORRECTION_REQUEST_EXCEPTION: 26,
    CORRECTION_DECLINE: 27,
    CORRECTION_REQUEST_DECLINE: 28,
}

export { status }
