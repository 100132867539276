import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {Table} from "@devexpress/dx-react-grid-material-ui";

import {
    makeStyles,
} from "@material-ui/core"
import {green} from "@material-ui/core/colors"
import {SettingsActions} from "../../Settings/actions/settings"
import {SettingsActions as PlanningSettings} from "../actions/settings"
import {CustomTable} from "../../App/components/Table/Table";
import {TableHeader} from "../../App/components/Table/TableHeader";
import {LogActions} from "../actions/log";
import {localState} from "../../App/components/LocalState";

const useStyles = makeStyles(theme => ({
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 306px)",
        "min-height": "calc(100vh - 306px)",
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    active: {
        'height': '41px',
        'background-color': `${green[100]} !important`,
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        fontSize: '16px',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    }
}))

const columns = [
    {name: 'comment', title: 'Событие'},
    {name: 'author', title: 'Автор'},
    {name: 'purchase', title: 'Закупка', filter: 'purchases'},
    {name: 'lot', title: 'Лот', filter: 'lots'},
    {name: 'item', title: 'ОЗ', filter: 'items'},
    {name: 'created', title: 'Дата и время создания'},
]

export const Log = (params) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {plan, name, purchase = null, hiddenColumns = []} = params

    const {filter} = useSelector(state => state.filters[name])

    const [log, setLog] = useState(localState);

    const [hiddenColumnNames, setHiddenColumnNames] = useState(hiddenColumns);

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [initialize, setInitialize] = useState(false)

    const [tableRef, setTableRef] = useState(null)

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'comment', width: 500},
        {columnName: 'author', width: 250},
        {columnName: 'purchase', width: 150},
        {columnName: 'lot', width: 150},
        {columnName: 'item', width: 150},
        {columnName: 'created', width: 200}
    ])

    const [columnOrder, setColumnOrder] = useState([
        'comment',
        'author',
        'purchase',
        'lot',
        'item',
        'created'
    ])

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...(purchase ? {purchase: purchase.id} : {}),
            ...((filter.hasOwnProperty('purchases') && !!filter.purchases.length) ? {purchases: filter.purchases} : {}),
            ...((filter.hasOwnProperty('lots') && !!filter.lots.length) ? {lots: filter.lots} : {}),
            ...((filter.hasOwnProperty('items') && !!filter.items.length) ? {items: filter.items} : {}),
        }
    }

    const getLog = async () => {
        let params = {
            limit: rowsPerPage,
            page: page,
            ...getParams()
        }
        const response = await dispatch(LogActions.log(plan.id, params))
        setLog(response)
    }

    useEffect(() => {
        getLog().then(() => {
            tableRef && tableRef.current && tableRef.current.scrollIntoView()
        })
        // eslint-disable-next-line
    }, [dispatch, page, rowsPerPage, filter])

    useEffect(() => {
        const getSettings = async () => {
            await dispatch(SettingsActions.table(name)).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                setInitialize(true)
            })
        }

        if (!initialize) {
            getSettings().then(() =>
                setInitialize(true)
            )
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: name,
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    useEffect(() => {
        const getSettings = async () => {
            return await dispatch(PlanningSettings.settings())
        }

        getSettings().then(() => {})
    }, [dispatch]);

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: `${name.toUpperCase()}_FILTER`, payload: {limitRows: newRowsPerPage}})
    }

    return (
        <CustomTable
            meta={log.meta}
            tableName={name}
            rows={log.data.map(event => ({
                comment: event.comment,
                author: event.author?.name,
                purchase: event.purchase?.number,
                lot: event.lot?.number,
                item: event.item?.number,
                created: event.created
            }))}
            columns={columns}
            page={{page: page, setPage: setPage, rowsPerPage: rowsPerPage, handlePageSizeChange: handlePageSizeChange}}
            setTableRef={setTableRef}
            columnsSettings={{
                columnOrder: columnOrder,
                setColumnOrder: setColumnOrder,
                setColumnWidths: setColumnWidths,
                columnWidths: columnWidths,
                hiddenColumnNames: hiddenColumnNames,
                setHiddenColumnNames: setHiddenColumnNames}}
            tableHeader={TableHeader}
            filterActionType={`${name.toUpperCase()}_FILTER`}
            getValues={(params) => {
                if (purchase) {
                    params = {
                        ...params,
                        ...(purchase ? {purchase: purchase.id} : {})
                    }
                }

                return  LogActions.filter(plan.id, params)
            }}
            classInputContent={classes.inputContent}
            rowComponentSelection={(props) => {
                const {tableRow, children, highlighted} = props
                const {row} = tableRow

                return (
                    <Table.Row
                        tableRow={tableRow}
                        children={children}
                        className={highlighted ? classes.active : classes.default}
                        row={row}
                    />
                )
            }}
            rowComponent={({row, tableRow, children}) => (
                <Table.Row
                    tableRow={tableRow}
                    children={children}
                    className={classes.default}
                    row={row}
                />
            )}
            filters={{
                purchase: {
                    name: 'purchase',
                    type: 'values',
                },
                lot: {
                    name: 'lot',
                    type: 'values',
                },
                item: {
                    name: 'item',
                    type: 'values',
                }
            }}
        />
    )
}
