import React from "react"

import {AccountContent} from "./AccountContent";
import {Menu} from "../../../../Menu";
import {Menu as AppMenu} from "../../../../../App/Menu";
import {Content as AppContent} from "../../../../../App/Content";

export const Account = (props) => {
    return <React.Fragment>
        <AppMenu
            Component={Menu}
            {...props}
        />
        <AppContent
            Component={AccountContent}
            {...props}
        />
    </React.Fragment>
}
