import React from "react"
import {useSelector} from "react-redux"

import {
    Grid
} from "@material-ui/core"

import {Submit} from "../../../../App/components/Button/Submit"
import {plan as rules} from "../../../helpers/permissions/rules"
import {history} from "../../../../App/helpers/history";

export const Edit = (props) => {
    const { plan, disabled = false } = props

    const {account} = useSelector(state => state.account)

    return rules.edit.hasOwnProperty(account.group) ? (
        <Grid item>
            <Submit
                disabled={disabled || !plan || !(
                    rules.edit[account.group].hasOwnProperty(plan.status.index) &&
                    (
                        !rules.edit[account.group][plan.status.index].length ||
                        (rules.edit[account.group][plan.status.index].length && rules.edit[account.group][plan.status.index].includes(plan.editRequestStatus?.index))
                    )
                )}
                disableElevation
                variant="contained"
                color="primary"
                type="button"
                style={{height: '35px'}}
                onClick={() => {
                    history.push(`/planning/purchase-plan/${plan?.id}/edit`)
                }}
            >
                Редактировать
            </Submit>
        </Grid>
    ) : null
}
