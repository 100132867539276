import React, {Fragment, useEffect, useState} from "react"

import {
    Box,
    Grid,
    IconButton,
    LinearProgress,
    makeStyles,
    TextField as MuiTextField,
    Tooltip,
    Typography
} from "@material-ui/core"
import {Clear, Check, Help as HelpIcon} from '@material-ui/icons';

import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete"
import {useDebounce} from "use-debounce";
import {SystemActions} from "../../actions/system";
import {useDispatch} from "react-redux";
import {yellow} from "@material-ui/core/colors";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",
        minWidth: "115px",
    },
    label: {
        fontSize: "16px",
        width: "auto",
        height: "auto",
        borderBottom: "dashed 1px #000",
        cursor: "pointer",
    },
    button: {
        fontSize: "16px",
        padding: "0",
        margin: "0 2px",
    },
    iconCheck: {
        color: "#fff",
        backgroundColor: "#898989",
        borderRadius: "4px",
        width: "21px",
        height: "21px",
    },
    iconCancel: {
        color: "#fff",
        backgroundColor: "#d9534f",
        borderRadius: "4px",
        width: "21px",
        height: "21px",
    },
    default: {
        'width': '100%',
        'padding': '6px 24px 6px 16px',
    },
    confirmation: {
        'width': '100%',
        'background-color': yellow[50],
        'padding': '6px 24px 6px 16px',
        '&:hover': {
            'background-color': `rgba(0, 0, 0, 0.01)`
        }
    },
    tooltipOfferStandard: {
        "background-color": "#000",
        "font-size": "15px",
        "color": "#fff",
        "padding": "15px 15px",
    },
    popper: {
        "min-width": "300px !important"
    },
    option: {
        'padding': '0 !important',
        '&:nth-child(2)': {
            'margin-top': '15px'
        }
    },
    proposalStandard: {
        'position': 'fixed',
        'z-index': '2',
        'background-color': '#fff',
        'width': '100%',
        'padding': '6px 24px 6px 16px',
    },
}))

export const ButtonSelectWithAutocomplete = (props) => {
    const dispatch = useDispatch()

    const {value, search, category, name, change, onUpdate, onCancel, propId} = props
    const classes = useStyles()
    const [show, setShow] = useState(false)
    const [val, setVal] = useState(value)
    const [items, setItems] = useState(props.items)
    const [standardName, setStandardName] = useState('')
    const [defaultVal, setDefaultVal] = useState(value)
    const [load, setLoad] = useState(true)

    const [debStandardName] = useDebounce(standardName, 900)

    useEffect(() => {
        const getItems = async () => {
            return await dispatch(SystemActions.items({
                status: ['active', 'confirmed'],
                search: debStandardName,
                ...(category ? {category: category.id} : {}),
                limit: 20
            }, false))
        }

        if (debStandardName) {
            setLoad(false)
            getItems().then(response => {
                setLoad(true)
                setItems(response.data)
            })
        } else {
            setLoad(true)
            setItems(props.items)
        }
        // eslint-disable-next-line
    }, [debStandardName])

    useEffect(() => {
        if (value !== val) {
            setVal(value)
        }
        // eslint-disable-next-line
    }, [value])

    return (
        <div className={classes.root} key={name}>
            {!show ? (
                <Typography className={classes.label} onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setShow(true)
                }}>
                    {val ? val.name : 'Не выбрано'}
                </Typography>
            ) : (
              <Fragment>
                  <Autocomplete
                    filterOptions={(options, state) => {
                        return (load ? [...(debStandardName.length ? (options.length ? [{id: 'button', name: 'Предложить эталон'}, ...options] : ['Результаты отсутствуют', {id: 'button', name: 'Предложить эталон'}]) : (options.length ? options : []))] : [['Загрузка']])
                    }}
                    options={items}
                    name={name}
                    value={val}
                    getOptionLabel={item => {
                        return (item instanceof Object) ? item.name : ''
                    }}
                    noOptionsText='Нет доступных вариантов'
                    renderOption={option => load ? (
                      <React.Fragment>
                          {(option.id === 'button') &&
                            <Grid container className={classes.proposalStandard} justify={'space-between'}>
                                <Grid item><Typography>Предложить эталон</Typography></Grid>
                                <Grid item>
                                    <Tooltip
                                      title="Если товар отсутствует в списке, вы можете предложить свой эталон"
                                      placement="right"
                                      classes={{tooltip: classes.tooltipOfferStandard}}
                                    >
                                        <IconButton
                                          style={{
                                              "width": "24px",
                                              "height": "24px",
                                              "padding": "0"
                                          }}
                                        >
                                            <HelpIcon style={{"color": "#485868"}}/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                          }
                          {(option.id === 'reset_category') &&
                            <Grid container justify={'space-between'}>
                                <Grid item><Typography>Указать категорию</Typography></Grid>
                                <Grid item>
                                    <Tooltip
                                      title="Если товар отсутствует в списке и вы хотите указать категорию"
                                      placement="right"
                                      classes={{tooltip: classes.tooltipOfferStandard}}
                                    >
                                        <IconButton
                                          style={{
                                              "width": "24px",
                                              "height": "24px",
                                              "padding": "0"
                                          }}
                                        >
                                            <HelpIcon style={{"color": "#485868"}}/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                          }
                          {((typeof option === 'object') && Number.isInteger(option.id)) && <Typography className={option.confirmed ? classes.default : classes.confirmation}>{option.name}</Typography>}
                      </React.Fragment>) : (<Box className={classes.default} style={{width: '100%'}}><LinearProgress/></Box>)}
                    onChange={(e, item) => {
                        e.stopPropagation()
                        e.preventDefault()
                        change(name, item ? item.id : null, props.propId)
                        setVal(item)
                    }}
                    classes={{
                        popper: classes.popper,
                        option: classes.option
                    }}
                    style={{maxWidth: (props.style && props.style.maxWidth) ? props.style.maxWidth : "none", width: '100%'}}
                    renderInput={params => {
                        return <MuiTextField
                          fullWidth
                          {...params}
                          placeholder="Начните вводить наименование..."
                          onChange={(e) => {
                              if (search) {
                                  setStandardName(e.target.value)
                              }
                          }}
                        />
                    }}
                  />
                  <IconButton
                    className={classes.button}
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        setShow(false)
                        if (val !== defaultVal) {
                            try {
                                onUpdate(propId, name, val).then(() => {
                                    setDefaultVal(val)
                                }, () => {
                                    setVal(defaultVal)
                                })
                            } catch (e) {
                                setVal(defaultVal)
                            }
                        }
                    }}
                  >
                      <Check className={classes.iconCheck}/>
                  </IconButton>
                  <IconButton
                    className={classes.button}
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        setShow(false)
                        setItems(props.items)
                        setVal(defaultVal)
                        onCancel(name, defaultVal)
                    }}
                  >
                      <Clear className={classes.iconCancel}/>
                  </IconButton>
              </Fragment>
            )}
        </div>
    );
}
