const status = {
    NEW: 1,
    ACTIVE: 2,
    NOT_ACTIVE: 3,
    DELETE: 4,
    APPROVE: 5,
    REJECT: 6,
    SUPPLIER_SIGNING: 7,
    CUSTOMER_SIGNING: 8
}

export { status }
