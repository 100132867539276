import React from "react"
import {Menu} from "./Menu";
import {Menu as AppMenu} from "../App/Menu";
// eslint-disable-line no-unused-vars

export const Index = (props) => {
    return <React.Fragment>
        <AppMenu
            Component={Menu}
            {...props}
        />
    </React.Fragment>
}
