function getPath(path, params) {
    path = `${path}?`

    let paramPath = false
    for (let name in params) {
        if (paramPath) {
            path = `${path}&`
        }

        if (params.hasOwnProperty(name)) {
            path = `${path}${name}=${params[name]}`
        }

        paramPath = true
    }

    return path
}

export { getPath }
