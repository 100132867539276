import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik, getIn} from "formik"

import {Grid, List, ListItem, ListItemText, makeStyles, TextField as MuiTextField, Typography, withStyles} from '@material-ui/core'
import {Autocomplete} from "@material-ui/lab"
import {Home as HomeIcon, ArrowBack as ArrowBackIcon} from '@material-ui/icons'

import {TextField as CustomTextField} from "../../App/components/Inputs/TextField"
import {Submit} from "../../App/components/Button/Submit"
import {ContractActions} from "../../Contract/actions/contract"
import {ButtonIcon} from "../../App/components/Button/ButtonIcon"
import {CatalogActions} from "./actions/catalog"
import {localState} from "../../App/components/LocalState";
import {useDebouncedCallback} from "use-debounce";

const useStyles = makeStyles(theme => ({
    item: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "cursor": "pointer"
    },
    active: {
        "background-image": "none !important",
        "background-color": "#898989"
    },
    nested: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "cursor": "pointer",
        "padding-left": `${theme.spacing(6)}px !important`
    },
    circle: {
        "width": "12px",
        "height": "12px",
        "border-radius": '50%',
        "background-color": '#616161',
        "margin-bottom": "5px"
    },
    catName: {
        fontWeight: "600",
        color: "#898989",
        textTransform: "uppercase",
        padding: "5px 16px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        borderBottom: "1px solid rgba(211,211,211,.4)",
    }
}))

const CustomList = withStyles({
    root: {
        "padding": "0",
        "& .MuiListItem-button": {
            "background-image": "linear-gradient(to bottom,#e4e4e4,#f4f4f4)",
            "cursor": "default !important",
            "align-items": "center",
            "min-height": "50px",
            "padding": "5px 25px 0",
            "border-bottom": "1px solid #e4e4e4",
            "outline": "0"
        },
        "& .MuiListItem-root": {
            "cursor": "pointer"
        },
        "& .MuiListItemIcon-root": {
            "min-width": "30px"
        },
        "& .MuiListItemText-primary": {
            "font-size": "16px",
            "font-weight": "700",
            "text-transform": "uppercase"
        },
        "& .MuiListItemText-secondary": {
            "color": "#485868",
            "font-size": "16px"
        }
    }
})(List);

export const Menu = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { filter } = useSelector(state => {return {...state.catalogSupplier.products}})
    const [contracts, setContracts] = useState(localState)

    const {categories} = useSelector(state => state.supplierCategories)

    const query = new URLSearchParams(props.location.search);

    const [contractId] = useState(() => {
        return parseInt(query.get('contract_id'))
    })

    const [catId, setCatId] = useState(0)
    const [searchCategories, setSearchCategories] = useState([])
    const [contract, setContract] = useState(null)
    const debounced = useDebouncedCallback(
        (value) => {
            setContract(value)
        },
        900
    );

    useEffect(() => {
        if (contract || contractId) {
            const getContracts = async () => {
                let params = {
                    limit: 10,
                    page: 1,
                    ...(contract ? {search: contract} : {}),
                    ...(contractId ? {id: contractId} : {}),
                }
                return await dispatch(ContractActions.contracts(params))
            }
            getContracts().then(resp => {
                setContracts(resp)
                window.history.pushState('Каталог', 'Электронный магазин', '/supplier/catalog/products')
                return dispatch({type: "SUPPLIER_CATALOG_PRODUCTS_FILTER", payload: {
                    ...filter,
                        ...{
                            contract: resp.data.find(contract => contract.id === contractId)
                        }
                    }, })
            })
        }
        // eslint-disable-next-line
    }, [dispatch, contract, contractId])

    useEffect(() => {
        dispatch({type: "CATALOG_PRODUCTS_CATEGORY", payload: catId})
    }, [dispatch, catId])

    const assembly = (categoriesTree, parent = 0, categories) => {
        let result = []

        if (categoriesTree.hasOwnProperty(parent)) {
            categoriesTree[parent].forEach(category => {
                result.push(
                    <ListItem
                        classes={{root: classes.item}}
                        style={(!!category && (category.id === catId)) ? {"background": "#898989"} : {}}
                        onClick={() => {
                            setCatId(category.id)
                            dispatch({type: "SUPPLIER_CATALOG_PRODUCTS_FILTER", payload: {
                                    ...filter,
                                    ...(category.id ? {category_id: category.id} : {})
                                }
                            })
                        }}
                        key={category.id}
                        value={category.id}
                    >
                        <ListItemText secondary={category.name}/>
                    </ListItem>
                )
            })
        } else {
            let cat = categories.find(el => el.id === parent)

            if (!cat) {
                return result;
            }
            return assembly(categoriesTree,  cat.category ? cat.category.id : 0, categories)
        }

        return result
    }

    const getCategoriesTree = categories => {
        let tmp = {}
        categories.forEach(category => {
            if (!tmp.hasOwnProperty((category.category !== null) ? category.category.id : 0)) {
                tmp[(category.category !== null) ? category.category.id : 0] = []
            }

            tmp[(category.category !== null) ? category.category.id : 0].push(category)
        })

        return assembly(tmp, catId, categories)
    }

    useEffect(() => {
        const getSupplierCategories = async () => {
            return await dispatch(CatalogActions.categories())
        }

        if (!categories.length) {
            getSupplierCategories().then(resp => {
                setSearchCategories(resp)
            })
        }
        // eslint-disable-next-line
    }, [dispatch]);

    return (
        <React.Fragment>
            <CustomList>
                <ListItem button>
                    <ListItemText primary="Рубрикатор" />
                </ListItem>
                <List component="div">
                    <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={2} style={{margin: "0 5px"}}>
                        <Grid item xs={2}>
                            <ButtonIcon
                                onClick={
                                    () => {
                                        setCatId(0)
                                        dispatch({
                                            type: "SUPPLIER_CATALOG_PRODUCTS_FILTER", payload: {
                                                ...filter,
                                                ...{
                                                    category_id: 0
                                                }
                                            }
                                        })
                                    }
                                }
                                key={'HomeIcon'}
                                children={[(<HomeIcon key={'HomeIcon'}
                                className={classes.svg} />)]}>
                            </ButtonIcon>
                        </Grid>
                        <Grid item xs={2}>
                            <ButtonIcon
                                onClick={() => {
                                    let cat = categories.filter(el => el.id === catId)
                                    if (cat.length && cat.length > 0 && cat[0].category) {
                                        setCatId(cat[0].category.id)
                                        dispatch({
                                            type: "SUPPLIER_CATALOG_PRODUCTS_FILTER", payload: {
                                                ...filter,
                                                ...{
                                                    category_id: cat[0].category.id
                                                }
                                            }
                                        })
                                    } else {
                                        setCatId(0)
                                        dispatch({
                                            type: "SUPPLIER_CATALOG_PRODUCTS_FILTER", payload: {
                                                ...filter,
                                                ...{
                                                    category_id: 0
                                                }
                                            }
                                        })
                                    }
                                }}
                                key={'ArrowBackIcon'}
                                disabled={!Boolean(catId)}
                                children={[(<ArrowBackIcon key={'ArrowBackIcon'}
                                className={classes.svg} />)]}>
                            </ButtonIcon>
                        </Grid>
                        <Grid item xs={7}>
                            <Autocomplete
                                filterOptions={(options, state) => options}
                                options={searchCategories}
                                getOptionLabel={category => {
                                    return category ? `${category.name}` : ''
                                }}
                                onChange={(e, cat) => {
                                    let id = cat ? cat.id : 0
                                    setCatId(id)
                                    dispatch({
                                        type: "SUPPLIER_CATALOG_PRODUCTS_FILTER", payload: {
                                            ...filter,
                                            ...{
                                                category_id: id
                                            }
                                        }
                                    })
                                }}
                                onClose={(e) => setSearchCategories(categories)}
                                style={{width: "100%"}}
                                noOptionsText='Поиск'
                                renderInput={params => {
                                    return <MuiTextField
                                        {...params}
                                        style={{width: "100%"}}
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                setSearchCategories(categories.filter(el => el.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1))
                                            } else {
                                                setSearchCategories(categories)
                                            }

                                        }}
                                    />
                                }}
                            />
                        </Grid>
                    </Grid>
                </List>
                <Typography className={classes.catName}>
                    {catId ? categories.filter(el => el.id === catId)[0].name : ''}
                </Typography>
                <List component="div" disablePadding style={{"height": "calc(59vh - 170px)", "overflow": "auto"}}>
                    {
                        categories.length && categories.length > 0 ? getCategoriesTree(categories) : null
                    }
                </List>
                <ListItem button>
                    <ListItemText primary="Фильтрация списка" />
                </ListItem>
            </CustomList>
                <Formik
                    enableReinitialize
                    initialValues={{
                        filter: {
                            ...filter,
                            ...(contractId && contracts.data.find(contract => contract.id === contractId) ? {contract: contracts.data.find(contract => contract.id === contractId)} : {})
                        }
                    }}

                    onSubmit={(values, {setSubmitting, setErrors}) => {
                        return dispatch({type: "SUPPLIER_CATALOG_PRODUCTS_FILTER", payload: values.filter })
                    }}
                >
                    {({
                          dirty,
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          submitForm,
                          isSubmitting,
                          isValid,
                          isValidating,
                          setValues,
                          setFieldValue,
                          setFieldTouched
                      }) => (
                        <Form>
                            <Grid container direction="column" justify="space-between" alignItems="stretch" style={{"height": "calc(40vh - 105px)", "width": "100%", "margin": "0px"}} spacing={2}>
                                <Grid item>
                                    <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                        <Grid item>
                                            <Autocomplete
                                                filterOptions={(options, state) => options}
                                                name={'filter.contract'}
                                                options={contracts.data}
                                                value={values.filter.contract}
                                                getOptionLabel={contract => {
                                                    return contract ? `${contract.number ?? contract.id} ${contract.name ?? ''}` : ''
                                                }}
                                                noOptionsText='Договор'
                                                onChange={(e, item) => {
                                                    setFieldValue('filter.contract', item ?? null)
                                                }}
                                                renderInput={params => {
                                                    const error = getIn(touched, 'filter.contract') && getIn(errors, 'filter.contract');

                                                    return <Field
                                                        fullWidth
                                                        component={MuiTextField}
                                                        {...params}
                                                        onChange={(e) => {
                                                            debounced.callback(e.target.value)
                                                        }}
                                                        error={!!error}
                                                        helperText={error}
                                                        name={'filter.contract'}
                                                        label="Договор"
                                                    />
                                                }}
                                                onFocus={(e, value) => {
                                                    dispatch({type: 'CONTRACT_ALL_CONTRACTS_CLEAR'})
                                                    setFieldTouched('filter.contract', true, true)
                                                }}
                                                onBlur={(e, value) => {
                                                    setFieldTouched('filter.contract', true, true)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                <Grid item xs={6}>
                                                    <Field
                                                        fullWidth
                                                        name="filter.price.from"
                                                        type="number"
                                                        label="Цена от"
                                                        inputProps={{
                                                            min: 0,
                                                            step: 0.01
                                                        }}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field
                                                        fullWidth
                                                        name="filter.price.to"
                                                        type="number"
                                                        label="до"
                                                        inputProps={{
                                                            min: 0,
                                                            step: 0.01
                                                        }}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                fullWidth
                                                name="filter.amount"
                                                type="number"
                                                label="Количество, от"
                                                step={1}
                                                min={0}
                                                inputProps={{
                                                    min: 0
                                                }}
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" justify="space-between" alignItems="center">
                                        <Grid item>
                                            <Submit
                                                className={classes.submit}
                                                disableElevation
                                                variant="contained"
                                                color="secondary"
                                                type="button"
                                                onClick={() => {
                                                    setFieldValue('filter', { contract: '', amount: '', price: { from: '', to: '' } })
                                                    dispatch({type: "SUPPLIER_CATALOG_PRODUCTS_FILTER", payload: { contract: '', amount: '', price: { from: '', to: '' } }})
                                                }}
                                            >
                                                Сбросить
                                            </Submit>
                                        </Grid>
                                        <Grid item>
                                            <Submit
                                                className={classes.submit}
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                type="button"
                                                onClick={() => {
                                                    submitForm().then(r => {})
                                                }}
                                            >
                                                Применить
                                            </Submit>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
        </React.Fragment>
    )
}
