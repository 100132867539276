import React from "react"

import {Typography} from "@material-ui/core"

export const TooltipContent = ({params}) => {
    let idx  = 0;

    let notHaveSearchParams = (!params.category || !(params.expirationDate || params.expirationDatePercent) || !params.postponement)
    let tooltipContent = [];

    tooltipContent.push(<Typography key={idx++}>{notHaveSearchParams ? 'Укажите обязательные параметры запроса:' : 'Нажмите кнопку "Найти"'}</Typography>);

    if (!params.category) {
        tooltipContent.push(<Typography key={idx++} variant="body2">* категория;</Typography>);
    }
    if (!params.expirationDate || !params.expirationDatePercent) {
        tooltipContent.push(<Typography key={idx++} variant="body2">* срок годности товаров;</Typography>);
    }
    if (!params.postponement) {
        tooltipContent.push(<Typography key={idx++} variant="body2">* отсрочка платежа;</Typography>);
    }

    return (
        <React.Fragment>
            {tooltipContent}
        </React.Fragment>
    )
}
