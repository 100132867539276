import {HttpService} from "../../App/services/http"
import * as AppStorage from "../../App/helpers/storage"

export const AuthService = {
    login,
    recovery,
    registration,
    permissions,
    roles,
    logout
}

function login(values) {
    return HttpService.post("/auth/login", values, false)
}

function recovery(values) {
    return HttpService.post("/auth/recovery", values, false)
}

function registration(values) {
    return HttpService.post("/auth/registration", values, false)
        .then(response => {
            if (response.account && response.account.company) {
                AppStorage.set('price_token', response.account.company.tokens.price)
                delete (response.account.company.tokens.price)
            }
            if (response.account) {
                AppStorage.set('account', JSON.stringify(response.account))
            }

            return response.account
        })
}

function permissions() {
    return HttpService.get("/auth/permissions", {}, false)
        .then(response => {
            return response.permissions
        })
}

function roles() {
    return HttpService.get("/auth/roles", {}, false)
        .then(response => {
            return response.roles
        })
}

function logout() {
    return HttpService.get("/auth/logout")
}
