import {HttpService} from "../../App/services/http"

export const MessengerService = {
    chats,
    messages,
    direct,
    create,
    users,
    addUser,
    deleteUser
}

function chats(params) {
    return HttpService.get(`/messenger/chats`, params)
        .then(response => {
            return response.data
        })
}

function messages(id, params) {
    return HttpService.get(`/messenger/chat/${id}/messages`, params)
        .then(response => {
            return response.data
        })
}

function direct(id) {
    return HttpService.get(`/messenger/chat/direct/${id}`)
        .then(response => {
            return response.chat
        })
}

function create(values) {
    return HttpService.post(`/messenger/chat`, values)
        .then(response => {
            return response.chat
        })
}

function users(params) {
    return HttpService.get(`/messenger/users`, params)
}

function addUser(chatId, userId) {
    return HttpService.put(`/messenger/chat/${chatId}/${userId}`)
}

function deleteUser(chatId, userId) {
    return HttpService.deleteMethod(`/messenger/chat/${chatId}/${userId}`)
}
