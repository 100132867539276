import React, {useEffect} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import * as Yup from "yup"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Link,
    makeStyles,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography
} from "@material-ui/core"
import {GetApp as GetAppIcon} from '@material-ui/icons'

import {SupMarketplaceActions} from "../../../actions/supMarketplace"
import {TabPanel} from "../../../../../Account/components/Tabs/TabPanel"
import {Submit} from "../../../../../App/components/Button/Submit"
import {TextFieldWithError as CustomTextField} from "../../../../../App/components/Inputs/TextFieldWithError"
import {Log} from "../Log"
import {day as formatDay} from "../../../../../App/helpers/date"
import * as AppStorage from "../../../../../App/helpers/storage"
import {parseCurrency} from "../../../../../App/helpers/currency";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px 0 29px !important",
    },
    input: {
        "width": "100%",
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 278px)",
        "margin-bottom": "4px"
    },
    tableContainer: {
        "overflow": "auto"
    },
    tabContent: {
        "padding": "20px 29px !important"
    },
    itemInput: {
        "margin-top": "0 !important"
    },
    inputContent: {
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    download: {
        "color": "#485868"
    },
    winner: {
        '& .MuiTableCell-root': {
            'font-weight': '500'
        }
    },
    total: {
        "border-bottom": "none",
        'font-weight': '600'
    },
    link: {
        "display": "flex",
        "line-height": "31px",
        "color": "#485868",
    },
}))

export const Content = (props) => {
    let {id} = useParams()

    const dispatch = useDispatch()
    const classes = useStyles()
    const {lot} = useSelector(state => state.mySupplierOrders)
    const [value, setValue] = React.useState(1);
    const [dialog, setDialog] = React.useState(false);

    useEffect(() => {
        const getOrder = async () => {
            return await dispatch(SupMarketplaceActions.lotInfo(id))
        }

        getOrder().then(() => {
        })

        return () => {
            dispatch({type: 'MY_LOT_CLEAR'})
        }
    }, [dispatch, id]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getAction = (lot) => {
        let action = null

        const cancel = (
            <Grid item>
                <Submit
                    disableElevation
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => {
                        setDialog({
                            title: 'Отмена заказа',
                            content: (
                                <Formik
                                    initialValues={{
                                        comment: '',
                                        lotId: lot.id,
                                        orderId: lot.order.id,
                                    }}
                                    validationSchema={Yup.object().shape({
                                        comment: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
                                    })}
                                    onSubmit={(values, {setSubmitting}) => {
                                        return dispatch(SupMarketplaceActions.cancel(lot.offers[0].id, values)).then(
                                            () => {
                                                setDialog(false)
                                            },
                                            errors => {
                                                if (errors) {
                                                }
                                                setSubmitting(false)
                                            }
                                        )
                                    }}
                                >
                                    {({
                                          dirty,
                                          isSubmitting,
                                          isValid
                                      }) => (
                                        <Form>
                                            <DialogContent>
                                                <Field
                                                    fullWidth
                                                    multiline
                                                    rows={6}
                                                    name="comment"
                                                    type="text"
                                                    label="Комментарий"
                                                    component={CustomTextField}
                                                />
                                            </DialogContent>
                                            <DialogActions>
                                                <Grid container direction="row" justify="flex-end" alignItems="stretch">
                                                    <Grid item>
                                                        <Submit
                                                            variant="contained"
                                                            color="primary"
                                                            type="submit"
                                                            disabled={isSubmitting || !isValid || !dirty}
                                                        >
                                                            Отменить
                                                        </Submit>
                                                    </Grid>
                                                </Grid>
                                            </DialogActions>
                                        </Form>
                                    )}
                                </Formik>
                            )
                        })
                    }}
                >
                    Отменить
                </Submit>
            </Grid>
        )

        switch (parseInt(lot.status.index)) {
            case 1:
                action = cancel
                break
            case 2:
                action = cancel
                break
            default:
        }

        return action
    }

    const renderOfferItemTable = (lot) => {
        return (
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {[
                                {
                                    id: '0',
                                    label: '№',
                                    format: (value) => value.toLocaleString()
                                }, {
                                    id: '1',
                                    label: 'Товары',
                                    format: (value) => value.toLocaleString()
                                }, {
                                    id: '2',
                                    label: 'Цена',
                                    format: (value) => value.toLocaleString()
                                }, {
                                    id: '3',
                                    label: 'Количество',
                                    format: (value) => value.toLocaleString()
                                }
                            ].map((column) => (
                                <TableCell key={column.id}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lot
                            .offers[0]
                            .items
                            .map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell width="60%">
                                        {item.item.name}
                                    </TableCell>
                                    <TableCell>
                                        {parseFloat(item.price).toLocaleString('ru-RU', {minimumFractionDigits: 2})}
                                    </TableCell>
                                    <TableCell>
                                        {item.item.amount}
                                    </TableCell>
                                </TableRow>
                            ))}
                        {lot.offers.length > 0 && <TableRow>
                            <TableCell className={classes.total} component="th" scope="row" />
                            <TableCell className={classes.total} width="80%">
                                ИТОГО
                            </TableCell>
                            <TableCell className={classes.total}>
                                {lot
                                    .offers[0]
                                    .items
                                    .reduce(function (sum, item) {
                                        return sum + parseFloat(item.price)
                                    }, 0)
                                    .toFixed(2)}
                            </TableCell>
                            <TableCell className={classes.total}/>
                        </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const renderOrderItemTable = (lot) => {
        return (
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {[
                                {
                                    id: '0',
                                    label: '№',
                                    format: (value) => value.toLocaleString()
                                }, {
                                    id: '1',
                                    label: 'Товары',
                                    format: (value) => value.toLocaleString()
                                }, {
                                    id: '2',
                                    label: 'Цена',
                                    format: (value) => value.toLocaleString()
                                }, {
                                    id: '3',
                                    label: 'Количество',
                                    format: (value) => value.toLocaleString()
                                }
                            ].map((column) => (
                                <TableCell key={column.id}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lot
                            .items
                            .map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell width="60%">
                                        {item.name}
                                    </TableCell>
                                    <TableCell>
                                        {parseFloat(item.average_price).toLocaleString('ru-RU', {minimumFractionDigits: 2})}
                                    </TableCell>
                                    <TableCell>
                                        {item.amount}
                                    </TableCell>
                                </TableRow>
                            ))}
                        <TableRow>
                            <TableCell className={classes.total} component="th" scope="row" />
                            <TableCell className={classes.total} width="80%">
                                ИТОГО
                            </TableCell>
                            <TableCell className={classes.total}>
                                -
                            </TableCell>
                            <TableCell className={classes.total}/>
                            <TableCell className={classes.total}>
                                {parseCurrency(lot?.order?.is_unit_price ?  lot.max_contract_price : lot.average_price, lot?.order.currency.code)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return lot ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.full}>
                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                            <Grid item className={classes.fullWidth}>
                                <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                    <Grid item className={classes.header}>
                                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                            <Grid item>
                                                <Grid container className={classes.fullWidth} direction="row" justify="space-between" alignItems="stretch">
                                                    <Grid item>
                                                        <Typography className={classes.title}>
                                                            Заказ № {lot.index}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="button">
                                                            {(!!lot && !!lot.offers && !!lot.offers.length) ? (lot.offers[0].active ? (lot.offers[0].winner ? lot.status.name : "Не состоялась") : "Предложение отозвано") : lot.status.name}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.input}>
                                        <Grid container direction="column" justify="center" alignItems="stretch">
                                            <Grid item className={classes.fullWidth}>
                                                <Tabs
                                                    value={value}
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                    onChange={handleChange}
                                                >
                                                    {((!!lot.offers && (!!lot.offers.length && lot.offers[0].winner && lot.offers[0].active)) || (lot.status.index === 6) || ((lot.status.index === 5) && (lot.offers.length === 1))) &&
                                                        <Tab value={0} label="Договор" />}
                                                    <Tab value={1} label="Информация о заказе" />
                                                    {([2, 3, 4, 5, 6, 7, 8].indexOf(parseInt(lot.status.index)) >= 0) &&
                                                        <Tab value={2} label="Сообщения" />}
                                                </Tabs>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                                    <Grid item className={classes.inputContent}>
                                        {((lot.offers && lot.offers.length > 0 && lot.offers[0].winner) || (lot.status.index === 11) || ((lot.status.index === 10) && (lot.offers.length === 1))) &&
                                            <TabPanel value={value} index={0}>
                                                <Log lot={lot}/>
                                            </TabPanel>
                                        }
                                        <TabPanel value={value} index={1}>
                                            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                <Grid item>
                                                    <Grid container direction="row" justify="space-between" alignItems="stretch" spacing={2}>
                                                        <Grid item>
                                                            <Grid container direction="column" justify="space-between" alignItems="stretch">
                                                                <Grid item style={{marginBottom: '20px'}}>
                                                                    <Typography variant="button">
                                                                        Ценовой запрос:
                                                                    </Typography>
                                                                    <Typography>
                                                                        {lot.order.name}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                        <Grid item>
                                                                            <Typography variant="button">
                                                                                Тип:
                                                                            </Typography>
                                                                            <Typography>
                                                                                {lot.order.type === 'static' ? 'Статический запрос' : 'Динамический запрос'}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction="column" justify="space-between" alignItems="stretch">
                                                                <Grid item style={{marginBottom: '20px'}}>
                                                                    <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                        <Grid item>
                                                                            <Typography variant="button">
                                                                                Срок действия/исполнения договора:
                                                                            </Typography>
                                                                            <Typography>
                                                                                {lot.contract_time ? formatDay(parseInt(lot.contract_time)) : null}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="button">
                                                                        Категория:
                                                                    </Typography>
                                                                    <Typography>
                                                                        {lot.order.category.name}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction="column" justify="space-between" alignItems="stretch">
                                                                <Grid item style={{marginBottom: '20px'}}>
                                                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                                        {lot.order.files.find((file) => file.type.key === 'draft_agreement') &&
                                                                            <Grid item>
                                                                                <Typography gutterBottom>
                                                                                    <Link
                                                                                        href={process.env.REACT_APP_HOST_API + `/shop/order/${lot.order.id}/file/${lot.order.files.find((file) => file.type.key === 'draft_agreement').id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                        className={classes.link}
                                                                                    >
                                                                                        Скачать проект договора<GetAppIcon viewBox="0 0 24 24" />
                                                                                    </Link>
                                                                                </Typography>
                                                                            </Grid>
                                                                        }
                                                                        {(lot.files.filter(el => el.type !== null)).find((file) => file.type.key === 'technical_task') &&
                                                                            <Grid item>
                                                                                <Typography gutterBottom>
                                                                                    <Link
                                                                                        href={process.env.REACT_APP_HOST_API + `/shop/order/${lot.order.id}/file/${(lot.files.filter(el => el.type !== null)).find((file) => file.type.key === 'technical_task').id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                        className={classes.link}
                                                                                    >
                                                                                        Скачать техническое задание<GetAppIcon viewBox="0 0 24 24" />
                                                                                    </Link>
                                                                                </Typography>
                                                                            </Grid>
                                                                        }
                                                                        {([3, 4, 5, 6, 7, 8].indexOf(parseInt(lot.status.index)) >= 0) && lot.files.filter(el => el.type !== null).find((file) => file.type.key === 'agreement') &&
                                                                            <Grid item>
                                                                                <Typography gutterBottom>
                                                                                    <Link
                                                                                        href={process.env.REACT_APP_HOST_API + `/shop/order/${lot.order.id}/file/${(lot.files.filter(el => el.type !== null)).find((file) => file.type.key === 'agreement').id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                        className={classes.link}
                                                                                    >
                                                                                        Скачать договор<GetAppIcon viewBox="0 0 24 24" />
                                                                                    </Link>
                                                                                </Typography>
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                        <Grid item>
                                                                            <Typography variant="button">
                                                                                Отсрочка платежа:
                                                                            </Typography>
                                                                            <Typography>
                                                                                {lot.postponement ? formatDay(parseInt(lot.postponement)) : null}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    {lot && lot.offers && lot.offers.length > 0 &&
                                                        renderOfferItemTable(lot)
                                                    }
                                                    {lot && lot.offers && lot.offers.length === 0 &&
                                                        renderOrderItemTable(lot)
                                                    }
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.footer}>
                                    <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                        <Grid item className={classes.footerContent}>
                                            <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                {[1, 2].indexOf(lot.status.index) !== -1 && lot.offers && lot.offers.length > 0 && lot.offers[0].active &&
                                                    getAction(lot)}
                                                {dialog &&
                                                    <Dialog
                                                        open={!!dialog}
                                                        onClose={() => {
                                                            setDialog(false)
                                                        }}
                                                        fullWidth={true}
                                                        maxWidth={'sm'}
                                                    >
                                                        <DialogTitle>{dialog.title}</DialogTitle>
                                                        {dialog.content}
                                                    </Dialog>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    ) : null
}
