import React from "react"
import {useSelector} from "react-redux"

import {AuthorizationService} from "../../../Auth/services/authorization"
import {Reports as CompanyReports} from "../Company/Reports"
import {Reports as SupervisorReports} from "../Supervisor/Reports"

export const Content = (props) => {
    const {account} = useSelector(state => state.account)

    return AuthorizationService.roles(account, "supervisor")
            ? <SupervisorReports {...props} />
            : <CompanyReports {...props} />
}
