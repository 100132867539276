import React, {useEffect, useRef, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {Field, FieldArray, Form, Formik, getIn} from "formik"
import {CheckboxWithLabel, TextField} from "formik-material-ui"
import * as Yup from "yup"

import {
    Grid, IconButton, InputAdornment, ListItemIcon, ListItemText, makeStyles, Paper, Table,
    TableBody, TableCell, TableContainer, TableHead, TableRow, TextField as MuiTextField, Typography,
    MenuItem
} from "@material-ui/core"
import {Autocomplete} from "@material-ui/lab"
import {ExpandLess, ExpandMore, Remove as RemoveIcon, Add as AddIcon} from "@material-ui/icons"
import {green, yellow} from "@material-ui/core/colors"

import {Submit} from "../../../../App/components/Button/Submit"
import {TextFieldWithError as CustomTextField} from "../../../../App/components/Inputs/TextFieldWithError"
import {SystemActions} from "../../../../App/actions/system"
import {PurchasePlanActions} from "../../../actions/purchasePlan"
import {PurchaseActions} from "../../../actions/purchase"
import {getChild} from "../../../../App/helpers/category"
import {history} from "../../../../App/helpers/history"
import {ItemsActions} from "../../../actions/items"
import {PurchasePlanItemsActions} from "../../../actions/purchasePlanItems"
import {LotActions} from "../../../actions/lot"
import {PlanningActions} from "../../../actions/planning"
import {Standard} from "../../../../App/components/Inputs/Standard";
import {SettingsActions} from "../../../../Settings/actions/settings";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.planningContent.width,
        padding: theme.planningContent.padding,
        margin: theme.planningContent.margin,
        position: "relative"
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important"
    },
    input: {
        "width": "100%"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
        "position": "absolute",
        "bottom": "0",
        "width": "100%",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    contentBody: {
        position: "relative"
    },
    paramsList: {
        width: "calc(100% + 16px)",
        height: "calc(100vh - 234px)",
        overflow: "auto",
        padding: "0 25px",
        marginBottom: "70px"
    },
    tableContainer: {
        "height": "auto",
        "margin-top": "5px",
    },
    table: {
        "border": "1px solid #e4e4e4",
    },
    row: {
        "width": "100%",
        "height": "max-content",
        "margin-bottom": "40px",
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    td: {
        border: '1px solid rgba(224, 224, 224, 1)'
    },
    add: {
        borderRadius: 0,
        width: "49%",
        background: "#616161",
        color: "#fff",
        marginRight: '10px',
        "&:hover": {
            backgroundColor: "#0e9176",
        }
    },
    tooltipOfferStandard: {
        "background-color": "#000",
        "font-size": "15px",
        "color": "#fff",
        "padding": "15px 15px",
    },
    remove: {
        borderRadius: 0,
        width: "49%",
        background: "#f27360",
        color: "#fff",
        "&:hover": {
            backgroundColor: "#b56a5f",
        }
    },
    activeCategory: {
        'background-color': green[100],
        '&:hover': {
            'background-color': 'rgba(0, 0, 0, 0.04) !important',
        }
    },
    defaultCategory: {
        '&:hover': {
            'background-color': 'rgba(0, 0, 0, 0.04) !important',
        }
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    },
    option: {
        'padding': '0 !important'
    },
    inputRootConfirmation: {
        'background-color': '#fffde7'
    },
    default: {
        'width': '100%',
        'padding': '6px 24px 6px 16px',
    },
    confirmation: {
        'width': '100%',
        'background-color': yellow[50],
        'padding': '6px 24px 6px 16px',
        '&:hover': {
            'background-color': `rgba(0, 0, 0, 0.01)`
        }
    },
    errorCustomField: {
        "&>input": {
            backgroundColor: 'rgb(242 115 96 / 50%)',
            minWidth: "31px"
        }
    },
    inputFundingSource: {
        "&>input": {
            minWidth: "31px"
        }
    }
}));


const nameMonths = [
    {
        number: 1,
        name: 'Январь'
    },
    {
        number: 2,
        name: 'Февраль'
    },
    {
        number: 3,
        name: 'Март'
    },
    {
        number: 4,
        name: 'Апрель'
    },
    {
        number: 5,
        name: 'Май'
    },
    {
        number: 6,
        name: 'Июнь'
    },
    {
        number: 7,
        name: 'Июль'
    },
    {
        number: 8,
        name: 'Август'
    },
    {
        number: 9,
        name: 'Сентябрь'
    },
    {
        number: 10,
        name: 'Октябрь'
    },
    {
        number: 11,
        name: 'Ноябрь'
    },
    {
        number: 12,
        name: 'Декабрь'
    }
]

export const Content = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {purchasePlanID, purchaseID, lotID, key} = useParams()
    const elRefs = useRef([]);

    const { fundingSources } = useSelector(state => ({ ...state.system, ...state.purchases }))
    const [plan, setPlan] = useState(null)
    const {units} = useSelector(state => state.purchasePlans)

    const {items} = useSelector(state => ({...(lotID ? state.purchases : state.purchasePlans)}))

    const {categories} = useSelector(state => state.purchases)
    const [collapse, setCollapse] = useState({})
    const [category, setCategory] = useState({})
    const [settings, setSettings] = useState([])
    const [months, setMonths] = useState([])
    const [loading, setLoading] = useState(null)

    const [childBasic, setChildBasic]  = useState([]);
    const [childDiagnostic, setChildDiagnostic]  = useState([]);


    useEffect(() => {
        const getUnits = async () => {
            return await dispatch(PlanningActions.units())
        }

        if (!units.length) {
            getUnits().then(() => {})
        }
    }, [dispatch, units])

    const assembly = (categories, parent = 0, level = 0, disabled = false) => {
        let result = []

        if (categories.hasOwnProperty(parent)) {
            categories[parent].forEach(option => {
                result.push(
                    <MenuItem key={ option.id } value={ option.id } style={{ paddingLeft: `${16 * (level + 1)}px`}} disabled={ disabled } className={ (category.hasOwnProperty('id') && (option.id === category.id)) ? classes.activeCategory : classes.defaultCategory }>
                        {categories.hasOwnProperty(option.id) &&
                            <ListItemIcon className={ classes.listItemIcon }>
                                <IconButton
                                    size="small"
                                    onClick={e => {
                                        e.stopPropagation()
                                        if (categories.hasOwnProperty(option.id)) {
                                            setCollapse({...collapse, ...{[option.id]: collapse.hasOwnProperty(option.id) ? !collapse[option.id] : true}})
                                        }
                                    }}
                                >
                                    {(collapse.hasOwnProperty(option.id) && collapse[option.id]) ? <ExpandLess/> : <ExpandMore/>}
                                </IconButton>
                            </ListItemIcon>
                        }
                        <ListItemText className={ categories.hasOwnProperty(option.id) ? classes.listItemTextWithIcon : classes.listItemText } primary={ !level ? <Typography variant="body1">{option.name}</Typography> : <Typography variant="body2">{option.name}</Typography> } />
                    </MenuItem>
                )

                const childes = assembly(categories, option.id, level + 1)

                if (!!childes.length && collapse.hasOwnProperty(option.id) && collapse[option.id]) {
                    result = result.concat([
                        childes.map(row => row)
                    ])
                }
            })
        }

        return result
    }

    const getMonths = (year, start = null, end = null) => {
        if (start && end) {
            start = new Date(start)
            end = new Date(end)

            let result = []

            while (true) {
                result.push({...nameMonths.find(month => (month.number === start.getMonth() + 1)), ...{year: start.getFullYear()}})

                start.setMonth(start.getMonth() + 1)

                if (start >= end) {
                    break
                }
            }

            return result
        }

        return nameMonths.map(month => {
            return {
                number: month.number,
                name: month.name,
                year: year
            }
        })
    }

    useEffect(() => {
        const getData = async () => {
            let response = {
                plan: null,
                lot: null
            }

            await dispatch(SettingsActions.getSettings('standard')).then(settings => {
                setSettings(settings)
            })

            await dispatch(SystemActions.categories()).then(r => {
                setChildBasic(getChild(process.env.REACT_APP_CATEGORY_BASIC_ASSETS, r))
                setChildDiagnostic(getChild(process.env.REACT_APP_CATEGORY_DIAGNOSTICS, r))
            })

            await dispatch(PurchasePlanActions.plan(purchasePlanID)).then(data => {
                setPlan(data)
                response.plan = data
            })

            if (lotID) {
                await dispatch(LotActions.getLot(purchaseID, lotID)).then(lot => {
                    response.lot = lot
                })

                await dispatch(ItemsActions.item(lotID, key)).then(() => { })

                await dispatch(PurchaseActions.categoriesForCreate({id: purchasePlanID}))
            } else if (purchaseID) {
                await dispatch(ItemsActions.categoriesForItemsInLot(lotID))

                await dispatch(PurchaseActions.purchase(purchaseID)).then(() => {})
            } else {
                await dispatch(PurchaseActions.categoriesForCreate({id: purchasePlanID}))
                await dispatch(PurchasePlanItemsActions.item(purchasePlanID, key)).then(() => { })
            }

            await dispatch(PurchaseActions.fundingSources())

            return response
        }

        getData().then(response => {
            setMonths(response.lot ? getMonths(parseInt(response.plan.planning_year), response.lot.start_execution_month, response.lot.execution_month) : getMonths(parseInt(response.plan.planning_year)))
            setLoading(true)
        })
        // eslint-disable-next-line
    }, [dispatch]);

    const getCategoriesTree = categories => {
        let tmp = {}

        categories.forEach(category => {
            if (!tmp.hasOwnProperty((category.category !== null) ? category.category.id : 0)) {
                tmp[(category.category !== null) ? category.category.id : 0] = []
            }

            tmp[(category.category !== null) ? category.category.id : 0].push(category)
        })

        return assembly(tmp, Math.min(...Object.keys(tmp).map(id => parseInt(id))))
    }

    const getFundingSources = (items) => {
        let fundingSources = [];

        items.data.forEach((item) => {
            item.funding_sources.forEach((funding_source, index) => {
                fundingSources[index] = {
                    type: funding_source.id,
                    values: fundingSources[index] && fundingSources[index].hasOwnProperty('values') ? fundingSources[index].values : months.map(month=> {
                        return {
                            month: month.number,
                            year: month.year,
                            percent: ''
                        }
                    })
                }

                fundingSources[index].values = fundingSources[index].values.map(value => {
                    return {
                        ...value,
                        ...{
                            percent: value.month === funding_source.pivot.month && value.year === funding_source.pivot.year ? funding_source.pivot.percent : value.percent
                        }
                    }
                })
            })
        })

        return fundingSources;
    }

    const unitAdornment = (standard, name = '') => {
        if (standard instanceof Object ) {
            if (standard.hasOwnProperty('values')) {
                const attribute = standard.values.find(value => (value.attribute.name === 'Фасовка/Вес') || (value.attribute.name === 'Фасованный/Весовой'))

                name = 'шт'

                if (attribute) {
                    if (attribute.value.toLowerCase() === 'вес') {
                        let unit = standard.values.find(value => (value.attribute.type.key === 'unit'));

                        if (unit) {
                            name = unit.value
                        }
                    }
                }

                return name
            }
        }

        return name
    }

    return categories && loading && plan && fundingSources ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" alignItems="center" className={classes.contentBody}>
                    <Formik
                        initialValues={
                            {
                                ...{
                                    key: key,
                                    category: categories.find(cat => (cat.id === items.data[0].category_id)) ?? null,
                                    purchase_plan_id: plan.id,
                                    unified_funding_source: false,
                                    description: items.data[0].description ? items.data[0].description : '',
                                    standard: items.data[0].standard ? {
                                        id: items.data[0].standard.id,
                                        name: items.data[0].standard.name.standard,
                                        typed: items.data[0].standard.name.typed,
                                        assembly: [
                                            ...(items.data[0].standard.name.hasOwnProperty('typed') ? [{
                                                type: {
                                                    key: 'typed'
                                                },
                                                value: items.data[0].standard.name.typed
                                            }] : []),
                                            ...(items.data[0].standard.name.hasOwnProperty('arbitrary') ? [{
                                                type: {
                                                    key: 'arbitrary'
                                                },
                                                value: items.data[0].standard.name.arbitrary
                                            }] : []),
                                        ],
                                        category: {
                                            id: items.data[0].category_id
                                        },
                                        confirmed: items.data[0].standard.confirmed
                                    } : items.data[0].name,
                                    unit: items.data[0].unit_id  ? units.find(unit => unit.id === items.data[0].unit_id)  : '',
                                    price: items.data[0].price,
                                    amounts: months.map(month => {
                                        const item = items.data.find(item => (((new Date(item.demand_start_date).getMonth() + 1) === month.number) && (new Date(item.demand_start_date).getFullYear() === month.year)))
                                        return {
                                            month: month.number,
                                            year: month.year,
                                            value: item ? item.amount : ''
                                        }
                                    }),
                                    funding_sources: getFundingSources(items),
                                    total_amount: items.data.reduce((sum, item) => {return parseFloat(sum) + Number(item.amount)}, 0),
                                    total_price: 0,
                                    no_analogues: items.data[0].no_analogues ?? false,
                                    analogues: items.data[0].analogues ?? ['','',''],
                                    main_technical_specifications: items.data[0].main_technical_specifications ?? ['','',''],
                                    medical_product_type_code: items.data[0].medical_product_type_code ?? null,
                                    exclusivity: items.data[0].exclusivity ?? '',
                                    equipment: items.data[0].equipment ?? ''
                                },
                                ...(lotID && purchaseID ? {
                                    lot_id: lotID,
                                    purchase_id: purchaseID,
                                }: {}),
                            }
                        }
                        validationSchema={Yup.object().shape({
                            unified_funding_source: Yup.boolean().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            category: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            standard: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            unit: Yup.object().shape({
                                id: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!")
                            }),
                            funding_sources: Yup.array().of(Yup.object().shape({
                                type: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                values: Yup.array().of(Yup.object().shape({
                                    percent: Yup.number().min(0, 'Процент финансирования должен быть больше или равен 0').nullable(),
                                }))
                            })),
                            amounts:  Yup.array().of(Yup.object().shape({
                                month: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                year: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                value: Yup.number().min(0, 'Количество должно быть больше или равно 0').nullable()
                            })),
                            price: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!").min(0, 'Цена должна быть больше или равна 0'),
                        })}
                        validate={values => {
                            const errors = {}

                            if (values.category && childBasic.indexOf(values.category.id) !== -1) {
                                if (values.no_analogues && values.exclusivity.replace(/\s/g,"") === "") {
                                    errors.exclusivity = 'Поле не может быть пустым или состоять из одних пробелов!'

                                    let main_technical_specifications = []

                                    values.main_technical_specifications.forEach((main_technical_specification, key) => {
                                        if (main_technical_specification.replace(/\s/g,"") === "") main_technical_specifications[key] = "Необходимо заполнить оставшиеся поля «Основные тех.характеристики»!"
                                    })

                                    if (main_technical_specifications.length > 0) errors.main_technical_specifications = main_technical_specifications
                                } else if (!values.no_analogues) {
                                    let main_technical_specifications = []
                                    let analogues = []

                                    values.main_technical_specifications.forEach((main_technical_specification, key) => {
                                        if (main_technical_specification.replace(/\s/g,"") === "") main_technical_specifications[key] = "Необходимо заполнить оставшиеся поля «Основные тех.характеристики»!"
                                    })
                                    values.analogues.forEach((analog, key) => {
                                        if (analog.replace(/\s/g,"") === "") analogues[key] = "Необходимо заполнить оставшиеся поля ««Аналог»!"
                                    })

                                    if (main_technical_specifications.length > 0) errors.main_technical_specifications = main_technical_specifications
                                    if (analogues.length > 0) errors.analogues = analogues
                                }
                            }

                            values.amounts.forEach((amount) => {
                                if (amount.value) {
                                    for (const key in values.funding_sources) {
                                        for (const id in values.funding_sources[key].values) {
                                            if ((values.funding_sources[key].values[id].month === amount.month) && (values.funding_sources[key].values[id].year === amount.year) && !String(values.funding_sources[key].values[id].percent).length) {
                                                if (!errors.hasOwnProperty('funding_sources')) {
                                                    errors.funding_sources = []
                                                }

                                                if (!errors.funding_sources.hasOwnProperty(key)) {
                                                    errors.funding_sources[key] = {
                                                        values: []
                                                    }
                                                }

                                                errors.funding_sources[key].values[id] = {percent: 'Поле не может быть пустым или состоять из одних пробелов!'}
                                            }
                                        }
                                    }
                                }
                            })

                            return errors
                        }}
                        onSubmit={(values, {setSubmitting}) => {
                            let params = {...values};
                            if (params['category']) {
                                params['category'] = params['category'].id
                            }

                            if (params.category && childBasic.indexOf(params.category) !== -1) {
                                if (params['no_analogues']) {
                                    delete params['analogues']
                                    delete params['medical_product_type_code']
                                } else {
                                    delete params['exclusivity']
                                }
                            } else {
                                delete params['no_analogues']
                                delete params['analogues']
                                delete params['main_technical_specifications']
                                delete params['medical_product_type_code']
                                delete params['exclusivity']
                            }

                            return dispatch(lotID && purchaseID ? ItemsActions.editItems(params) : PurchasePlanItemsActions.editItems(params)).then(
                                () => {
                                    lotID && purchaseID ? history.push(`/planning/purchase-plan/${plan.id}/purchase/${purchaseID}`) : history.push(`/planning/purchase-plan/${plan.id}`)
                                    setSubmitting(false)
                                },
                                errors => {
                                    if (errors) {}
                                    setSubmitting(false)
                                }
                            )
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              isSubmitting,
                              setValues,
                              setFieldValue,
                              submitForm
                          }) => (
                            <Form className={classes.fullWidth}>
                                <Grid item className={classes.inputContent}>
                                    <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                        <Grid item className={classes.header}>
                                            <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                                <Grid item className={classes.fullWidth}>
                                                    <Typography className={classes.title}>
                                                        Редактирование объекта закупок
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container className={classes.paramsList} direction="row" wrap="wrap" spacing={4}>
                                        <Grid item xs={3}>
                                            <Field
                                                fullWidth
                                                type="text"
                                                name="category"
                                                select
                                                label="Подкатегория"
                                                component={TextField}
                                                value={values.category}
                                                required={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                InputProps={{
                                                    onChange: (e) => {
                                                        const {value, name} = e.target
                                                        const category = categories.find(cat => (cat.id === value))

                                                        setFieldValue(name, category)
                                                    }
                                                }}
                                                inputProps={{
                                                    renderValue: (value) => value.name
                                                }}
                                            >
                                                {
                                                    (categories.length > 1) ? getCategoriesTree(categories) : categories.map((category, i) => (
                                                        <MenuItem key={i} value={category.id}>
                                                            {category.name}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Field>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Standard
                                                name={'standard'}
                                                required={true}
                                                edit={false}
                                                type={settings.categories.find(el => el.category.id === values.category.index)?.type}
                                                module={'Планирование'}
                                                category={values.category}
                                                standard={values.standard}
                                                errors={errors}
                                                touched={touched}
                                                onChange={(value) => {
                                                    if (value.standard) {
                                                        if (value.standard?.category?.id) {
                                                            const unit = units.find(unit => unit.short === unitAdornment(value.standard))

                                                            setValues({
                                                                ...values,
                                                                category: categories.find(cat => (cat.id === value.standard.category.id)),
                                                                standard: value.standard ? {
                                                                    ...value.standard,
                                                                    ...{typed: value.standard.assembly.find(value => value.type.key === 'typed')?.value}
                                                                } : null,
                                                                unit: unit ?? values.unit
                                                            })
                                                        } else {
                                                            setFieldValue(`standard`, value.standard)
                                                        }
                                                    } else {
                                                        setFieldValue(`standard`, '')
                                                    }
                                                }}
                                                onAddStandard={(item) => {
                                                    setFieldValue('standard', item ?? null)
                                                    if (item && item.hasOwnProperty('category') && item.category.id) {
                                                        setFieldValue('category', categories.find(cat => (cat.id === item.category.id)))
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Autocomplete
                                                options={units}
                                                value={values.unit ? (values.unit.id ? values.unit : units.find(unit => unit.short === values.unit.short)) : ''}
                                                disabled={!!items.data[0].unit_id}
                                                name={'unit'}
                                                required={true}
                                                getOptionLabel={unit => {
                                                    return unit ? `${unit.name}` : ''
                                                }}
                                                onChange={(e, unit) => {
                                                    setFieldValue(`unit`, unit ? unit : '')
                                                }}
                                                noOptionsText='Единица измерения'
                                                renderInput={params => {
                                                    const error = getIn(touched, 'unit') && getIn(errors, 'unit');

                                                    return <Field
                                                        fullWidth
                                                        component={MuiTextField}
                                                        required={true}
                                                        {...params}
                                                        error={!!error}
                                                        helperText={error ? error.id : null}
                                                        name={'unit'}
                                                        label="Единица измерения"
                                                    />
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Field
                                                fullWidth
                                                label="Цена"
                                                name={`price`}
                                                inputProps={{
                                                    min: 0,
                                                    step:0.01,
                                                    onChange: (e) => {
                                                        let price = e.target.value
                                                        setValues({
                                                            ...values,
                                                            price: price,
                                                            total_price: values.amounts.reduce(function (sum, amount) {
                                                                return parseFloat(sum) + ((amount.value ?? 0) * Number(price))
                                                            }, 0),
                                                        })
                                                    },
                                                }}
                                                required
                                                type="number"
                                                component={CustomTextField}
                                                className={touched.price && errors.price ? classes.errorCustomField : null}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Field
                                                component={CheckboxWithLabel}
                                                name="unified_funding_source"
                                                color="primary"
                                                Label={{label: "Единые ист.фин"}}
                                                inputProps={{
                                                    onChange: (event) => {
                                                        const checked = event.target.checked
                                                        if (checked) {
                                                            let funding_sources = values.funding_sources.map(funding_source => {
                                                                return {
                                                                    type: funding_source.type,
                                                                    values: months.map(() => {
                                                                        return {percent: funding_source.values[0].percent}
                                                                    }),
                                                                }
                                                            })
                                                            setValues({
                                                                ...values,
                                                                unified_funding_source: checked,
                                                                funding_sources: funding_sources
                                                            })
                                                        } else {
                                                            setFieldValue('unified_funding_source', checked)
                                                        }
                                                    }
                                                }}
                                            />
                                            {values.category && childBasic.indexOf(values.category.id) !== -1 &&
                                                <Field
                                                    component={CheckboxWithLabel}
                                                    name="no_analogues"
                                                    color="primary"
                                                    Label={{label: "Нет аналогов"}}
                                                    inputProps={{
                                                        onChange: (event) => {
                                                            const checked = event.target.checked
                                                            if (checked) {
                                                                setValues({
                                                                    ...values,
                                                                    no_analogues: checked,
                                                                })
                                                            } else {
                                                                setFieldValue('no_analogues', checked)
                                                            }
                                                        }
                                                    }}
                                                />
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                <Grid item xs={3} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name={`description`}
                                                        label="Описание"
                                                        rowsMax={4}
                                                        multiline
                                                        variant="standard"
                                                        component={CustomTextField}
                                                        onChange={setFieldValue}
                                                    />
                                                </Grid>
                                                {values.category ? values.category.hasOwnProperty('id') && childDiagnostic.indexOf(values.category.id) !== -1 &&
                                                    <Grid item xs={3} className={classes.column}>
                                                        <Field
                                                            fullWidth
                                                            name={`equipment`}
                                                            type="text"
                                                            label="Оборудование"
                                                            rows={4}
                                                            multiline={true}
                                                            component={CustomTextField}
                                                            onChange={setFieldValue}
                                                        />
                                                    </Grid> : null
                                                }
                                                {(values.category && childBasic.indexOf(values.category.id) !== -1) &&
                                                    <React.Fragment>
                                                        {values.main_technical_specifications.map((main_technical_specification, index) => {
                                                            return (
                                                                <Grid key={index} item xs={3} className={classes.column}>
                                                                    <Field
                                                                        fullWidth
                                                                        name={`main_technical_specifications.${index}`}
                                                                        label={`Основные тех.характеристики ${index+1}`}
                                                                        rowsMax={4}
                                                                        multiline
                                                                        variant="standard"
                                                                        required
                                                                        component={CustomTextField}
                                                                        onChange={setFieldValue}
                                                                    />
                                                                </Grid>
                                                            );
                                                        })}
                                                    </React.Fragment>
                                                }
                                            </Grid>
                                            {(values.category && childBasic.indexOf(values.category.id) !== -1 && values.no_analogues) &&
                                                <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={2}>
                                                    <Grid item xs={3} className={classes.column}>
                                                        <Field
                                                            fullWidth
                                                            name={`exclusivity`}
                                                            label="Обоснование исключительности"
                                                            rowsMax={4}
                                                            multiline
                                                            variant="standard"
                                                            required
                                                            component={CustomTextField}
                                                            onChange={setFieldValue}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            }
                                            {(values.category && childBasic.indexOf(values.category.id) !== -1 && !values.no_analogues) &&
                                                <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={2}>
                                                    <Grid item xs={3}>
                                                        <Field
                                                            fullWidth
                                                            name={`medical_product_type_code`}
                                                            label="Код вида мед. изделия"
                                                            rowsMax={4}
                                                            multiline
                                                            component={CustomTextField}
                                                            variant="standard"
                                                            onChange={setFieldValue}
                                                        />
                                                    </Grid>
                                                    <React.Fragment>
                                                        {values.analogues.map((analog, index) => {
                                                            return (
                                                                <Grid key={index} item xs={3} className={classes.column}>
                                                                    <Field
                                                                        fullWidth
                                                                        name={`analogues.${index}`}
                                                                        label={`Аналог ${index+1}`}
                                                                        rowsMax={4}
                                                                        multiline
                                                                        variant="standard"
                                                                        component={CustomTextField}
                                                                        required
                                                                        onChange={setFieldValue}
                                                                    />
                                                                </Grid>
                                                            );
                                                        })}
                                                    </React.Fragment>
                                                </Grid>
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TableContainer className={classes.tableContainer}>
                                                <Table stickyHeader aria-label="sticky table" className={classes.table}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell />
                                                            {months.map((month) => (
                                                                <TableCell key={month.number}>
                                                                    {month.name}
                                                                </TableCell>
                                                            ))}
                                                            <TableCell>Итого</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow
                                                            className={classes.tableRow}
                                                            hover
                                                            key={0}
                                                            onClick={() => {}}
                                                        >
                                                            <TableCell className={classes.td}>
                                                                Количество
                                                            </TableCell>
                                                            {months.map((column, idx) => (
                                                                <TableCell
                                                                    key={idx}
                                                                    className={classes.td}
                                                                >
                                                                    <Field
                                                                        fullWidth
                                                                        name={`amounts[${idx}].value`}
                                                                        innerRef={el => elRefs.current[idx] = el}
                                                                        inputProps={{
                                                                            min: 0,
                                                                            step:0.01,
                                                                            onChange: (e) => {
                                                                                let amounts = values.amounts;
                                                                                amounts[idx].value = e.target.value;
                                                                                setValues({
                                                                                    ...values,
                                                                                    ...{
                                                                                        amounts: amounts,
                                                                                        total_amount: amounts.reduce(function (sum, amount) { return parseFloat(sum) + Number(amount.value) }, 0),
                                                                                        total_price: amounts.reduce(function (sum, amount) {
                                                                                            return parseFloat(sum) + ((amount.value ?? 0) * Number(values.price))
                                                                                        }, 0),
                                                                                    }
                                                                                })
                                                                            },
                                                                            onKeyDown: (e) => {
                                                                                if (e.keyCode === 39) {
                                                                                    elRefs.current[Number(idx) + 1 < 12 ? Number(idx) + 1 : 0].getElementsByTagName('input')[0].focus()
                                                                                } else if (e.keyCode === 37) {
                                                                                    elRefs.current[Number(idx) - 1 < 0 ? 11 : Number(idx) - 1].getElementsByTagName('input')[0].focus()
                                                                                }
                                                                            },
                                                                        }}

                                                                        type="number"
                                                                        component={CustomTextField}
                                                                    />
                                                                </TableCell>
                                                            ))}
                                                            <TableCell className={classes.td}>
                                                                <Field
                                                                    fullWidth
                                                                    name={`total_amount`}
                                                                    disabled
                                                                    inputProps={{
                                                                        min: 0,
                                                                        step:0.01
                                                                    }}
                                                                    type="number"
                                                                    component={CustomTextField}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            className={classes.tableRow}
                                                            hover
                                                            key={3}
                                                            rowSpan={4}
                                                            onClick={() => {
                                                            }}
                                                        >
                                                            <TableCell component="th" scope="row" colSpan={13} align="center">
                                                                Источники финансирования
                                                            </TableCell>
                                                        </TableRow>
                                                        <FieldArray name={`funding_sources`} render={ arrayHelpers => (
                                                            <React.Fragment>
                                                                {
                                                                    values.funding_sources.map((funding_source, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell style={{maxWidth: '200px'}}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    type="text"
                                                                                    name={`funding_sources[${index}].type`}
                                                                                    select
                                                                                    required
                                                                                    variant="standard"
                                                                                    component={TextField}
                                                                                    InputLabelProps={{
                                                                                        shrink: true
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        // eslint-disable-next-line array-callback-return
                                                                                        (fundingSources.filter(el => values.funding_sources.map((el, idx) => {
                                                                                            if (idx !== index){
                                                                                                return el.type
                                                                                            }
                                                                                        }).indexOf(el.id) === -1)).map(fundingSource => (
                                                                                            <MenuItem key={fundingSource.id} value={fundingSource.id}>
                                                                                                {fundingSource.name}
                                                                                            </MenuItem>
                                                                                        ))
                                                                                    }
                                                                                </Field>
                                                                            </TableCell>
                                                                            {values.funding_sources[index].values.map((column, idx) => (
                                                                                <TableCell
                                                                                    className={classes.td}
                                                                                    key={idx}
                                                                                >
                                                                                    <Field
                                                                                        fullWidth
                                                                                        name={`funding_sources[${index}].values[${idx}].percent`}
                                                                                        disabled={Boolean(values.unified_funding_source && idx !== 0)}
                                                                                        required={Boolean(values.amounts[idx] && values.amounts[idx].value)}
                                                                                        inputProps={{
                                                                                            min: 0,
                                                                                            step:0.01,
                                                                                            max: 100,
                                                                                            onChange: (e) => {
                                                                                                if (values.unified_funding_source) {
                                                                                                    let funding_sources = values.funding_sources;
                                                                                                    funding_sources[index] = {
                                                                                                        type: funding_sources[index].type,
                                                                                                        values: months.map(() => {
                                                                                                            return {percent: e.target.value}
                                                                                                        }),
                                                                                                    }
                                                                                                    setValues({
                                                                                                        ...values,
                                                                                                        funding_sources: funding_sources
                                                                                                    })
                                                                                                } else {
                                                                                                    setFieldValue(`funding_sources[${index}].values[${idx}].percent`, e.target.value)
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                                                        type="number"
                                                                                        component={CustomTextField}
                                                                                        className={(
                                                                                            touched.funding_sources
                                                                                            && touched.hasOwnProperty('funding_sources')
                                                                                            && touched.funding_sources.length > 0
                                                                                            && touched.funding_sources[index]
                                                                                            && errors.hasOwnProperty('funding_sources')
                                                                                            && errors.funding_sources[index]
                                                                                            && errors.funding_sources[index].hasOwnProperty('values')
                                                                                            && errors.funding_sources[index].values
                                                                                            && errors.funding_sources[index].values[idx]
                                                                                            && errors.funding_sources[index].values[idx].percent
                                                                                        )
                                                                                            ? classes.errorCustomField : classes.inputFundingSource
                                                                                        }
                                                                                    />
                                                                                </TableCell>
                                                                            ))}
                                                                        </TableRow>
                                                                    ))
                                                                }
                                                                <TableRow>
                                                                    <TableCell colSpan={14}>
                                                                        <IconButton
                                                                            size="small"
                                                                            aria-label="close"
                                                                            color="inherit"
                                                                            className={classes.add}
                                                                            onClick={() => {
                                                                                arrayHelpers.insert(values.funding_sources.length, {
                                                                                    type: '',
                                                                                    values: months.map(month => {
                                                                                        return {
                                                                                            month: month.number,
                                                                                            year: month.year,
                                                                                            percent: ''
                                                                                        }
                                                                                    })
                                                                                })
                                                                            }}>
                                                                            <AddIcon/>
                                                                        </IconButton>
                                                                        <IconButton
                                                                            size="small"
                                                                            aria-label="close"
                                                                            color="inherit"
                                                                            className={classes.remove}
                                                                            onClick={() => {
                                                                                if (values.funding_sources.length > 1) {
                                                                                    arrayHelpers.remove(values.funding_sources.length - 1);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <RemoveIcon/>
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </React.Fragment>
                                                        )}/>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.footer}>
                                    <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                        <Grid item className={classes.footerContent}>
                                            <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                <Grid item>
                                                    <Submit
                                                        disableElevation
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                        onClick={() => submitForm().then(() => {})}
                                                    >
                                                        Сохранить
                                                    </Submit>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Paper>
        </Grid>) : null
}
