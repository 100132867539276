import React from "react"
import {Link as Route} from "react-router-dom"
import {useSelector} from "react-redux"
import format from "date-fns/format"
import {ru} from "date-fns/locale"

import {
    Grid,
    Link,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core"
import {GetApp as GetAppIcon} from "@material-ui/icons"

import {history} from "../../../App/helpers/history"
import {Submit} from "../../../App/components/Button/Submit"
import {round} from "../../../App/helpers/number"
import {AuthorizationService} from "../../../Auth/services/authorization"
import * as AppStorage from "../../../App/helpers/storage"

const useStyles = makeStyles(() => ({
    link: {
        "display": "flex",
        "line-height": "31px",
        "color": "#485868",
    },
    title: {
        "font-size": "16px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    containerRows: {
        "width": "100%",
        "height": "calc(100vh - 264px)",
        "overflow": "auto",
        "padding": "15px 15px 0 15px"
    },
    column: {
        "height": "86px"
    },
    tableContainer: {
        "height": "auto",
        "margin-top": "5px",
    },
    table: {
        "border": "1px solid #e4e4e4",
    },
    row: {
        "width": "100%",
        "height": "max-content",
        "margin-bottom": "40px",
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    tableRowAlert: {
        "cursor": "pointer",
        "background-color": "#f9f1f0",
        "text-decoration": "none"
    },
    sendOffer: {
        "width": '285px',
        "float": "right",
        "padding": "0px",
        "margin-top": "6px"
    },
    download: {
        "color": "#485868"
    },
    productName:  {
        padding: "5px 0",
        "max-width": "350px",
        "min-width": "350px",
        "margin": "0",
        "margin-block-start": "0",
        "margin-block-end": "0",
    },
    productAttrPadding:  {
        padding: "5px 0",
    },
    actions: {
        width: "250px"
    },
    requestInfo: {
        width: "285px",
        marginTop: "6px",
        padding: "0px",
        float: "right"
    }
}))


const columns = [
    {
        id: 'lot_id',
        label: '№ Лота',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'products',
        label: 'Объект закупки',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'count',
        label: 'Количество',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'average_price',
        label: 'НМЦ (с учётом НДС)',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'total_price',
        label: 'Сумма по позиции (с учётом НДС)',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'status',
        label: 'Статус',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'actions',
        label: 'Действие',
        format: (value) => value.toLocaleString(),
    }
];



export const OrdersList = (props) => {
    const classes = useStyles()
    const {orders} = props;
    const {account} = useSelector(state => state.account)

    const setLocaleCurrency = (price, currencyCode) => price.toLocaleString('ru-RU', {
        style: 'currency',
        currency: currencyCode
    }).replace('KZT', '₸')

    const getColumnContent = (column, lot, order) => {
        switch(column.id){
            case 'lot_id':
                return lot.number ?? lot.index
            case 'products':
                return lot.items.map((item, index) => (
                    <Typography key={index} className={classes.productName}>
                        {item.name}
                    </Typography>
                ))
            case 'count':
                return lot.items.map((item, index) => (
                    <Typography key={index} className={classes.productAttrPadding}>
                        {item.amount || item.amount === 0 ? round(item.amount, 2) : null} {item.unit ? item.unit.short : null}
                    </Typography>
                ))
            case 'average_price':
                return lot.items.map((item, index) => (
                    <Typography key={index} className={classes.productAttrPadding}>
                        {item.average_price ? setLocaleCurrency(parseFloat(item.average_price), order.currency.code) : '-'}
                    </Typography>
                ))
            case 'total_price':
                return lot.items.map((item, index) => (
                    <Typography key={index} className={classes.productAttrPadding}>
                        {item.average_price ? setLocaleCurrency((parseFloat(item.average_price) * parseFloat(item.amount)), order.currency.code) : '-'}
                    </Typography>
                ))
            case 'status':
                if (lot && lot?.offers?.length > 0) {
                    if (lot.offers[0].active) {
                        if (lot.status.index === 8) {
                            return "В работе"
                        }
                        return lot.status.name
                    } else {
                        if (lot.status.index !== 2) {
                            return lot.status.name
                        }
                        if (lot.offers[0].comment) {
                            return "Предложение отозвано"
                        } else {
                            return "Отменено"
                        }
                    }
                } else if (lot && !lot.offers && (order.status.index === 3) && (lot.status.index !== 7)) {
                    return "В работе"
                } else if ((order.status.index === 2) && (lot.status.index === 2)) {
                    return "Прием заявок"
                } else if (order.status.index === lot.status.index) {
                    if (lot.status.index === 8) {
                        return "В работе"
                    }
                    return lot.status.name
                } else {
                    if (lot.status.index === 8) {
                        return "В работе"
                    }
                    return lot.status.name
                }
            case 'actions':
                return (<Typography className={classes.actions} />)
            default:
                return lot[column['id']]
        }
    }

    const renderColumn = (column, order, lot) => {
        const actionsColumnButtons = []
        if (column.id === 'actions') {
            let diffTime = lot.end_time_of_rebidding !== null ? (new Date()).valueOf() - (new Date(lot.end_time_of_rebidding)).valueOf() : 0;
            if ((!!lot?.offers?.length && lot.offers[0].active) && ((order.type === 'static') || (order.type === 'dynamic' && lot.offers[0].last_price) || (order.type === 'dynamic' && diffTime >= 0))) {
                actionsColumnButtons.push(
                    <Submit
                        disableElevation
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={e => e.stopPropagation()}
                        component={Route}
                        className={classes.sendOffer}
                        to={`/supplier/marketplace/orders/lot/${lot.id}/offer/view/${lot.offers[0].id}`}
                    >
                        Посмотреть предложение
                    </Submit>
                )
            }
            if ((order.type === 'dynamic') && (lot.status.index === 8) && !!lot.end_time_of_rebidding && !!lot?.offers?.length && !lot.offers[0].last_price && lot.offers[0].approve) {
                actionsColumnButtons.push(
                    <React.Fragment>
                        {lot.end_time_of_rebidding ? (
                            <Typography style={{marginBottom: "10px", width: "250px"}}>Действие доступно до {format(new Date(lot.end_time_of_rebidding), 'H:mm PP', {locale: ru})}<br/>(мск {format(new Date(new Date(lot.end_time_of_rebidding).toLocaleString("en-US", {timeZone: "Europe/Moscow"})), 'H:mm PP', {locale: ru})})</Typography>
                        ) : null}
                        <Submit
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={e => e.stopPropagation()}
                            type="button"
                            component={Route}
                            className={classes.sendOffer}
                            to={`/supplier/marketplace/orders/lot/${lot.id}/offer/${lot.offers[0].id}/edit`}
                        >
                            Редактировать предложение
                        </Submit>
                    </React.Fragment>
                )
            }
            if (order.from_planning === false && lot && !!lot?.offers?.length && !lot.offers[0].active && (lot.status.index === 2)) {
                (AuthorizationService.permissions(account, ['access_to_elmag'], true) && AuthorizationService.roles(account, 'supplier')) ? (
                    actionsColumnButtons.push(
                        lot.offers[0].comment ? (
                            <Submit
                                disableElevation
                                variant="contained"
                                color="primary"
                                onClick={e => {
                                    e.stopPropagation()
                                }}
                                type="button"
                                component={Route}
                                className={classes.sendOffer}
                                to={`/supplier/marketplace/orders/lot/${lot.id}/offer/add`}
                            >
                                Актуализировать предложение
                            </Submit>
                        ) : (
                            <Submit
                                disableElevation
                                variant="contained"
                                color="primary"
                                onClick={e => {
                                    e.stopPropagation()
                                }}
                                type="button"
                                component={Route}
                                className={classes.sendOffer}
                                to={`/supplier/marketplace/orders/lot/${lot.id}/offer/add`}
                            >
                                Отправить предложение
                            </Submit>
                        )
                    )
                ) : actionsColumnButtons.push(
                    <div style={{color: "#d9534f", fontWeight: 700}}>Заполните профиль и условия участия</div>
                )
            }

            actionsColumnButtons.push(
                <Submit
                    className={classes.requestInfo}
                    disableElevation
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => {
                        history.push(`/supplier/marketplace/my-orders/lot/${lot.id}`)
                    }}
                >
                    Посмотреть информацию о запросе
                </Submit>
            )
            if (order.from_planning === false && !lot?.offers?.length && (lot?.status?.index === 2)) {
                (AuthorizationService.permissions(account, ['access_to_elmag'], true) && AuthorizationService.roles(account, 'supplier')) ? (
                    actionsColumnButtons.push(
                        <Submit
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={e => {
                                e.stopPropagation()
                            }}
                            type="button"
                            component={Route}
                            className={classes.sendOffer}
                            to={`/supplier/marketplace/orders/lot/${lot.id}/offer/add`}
                        >
                            Отправить предложение
                        </Submit>
                    )
                ) : actionsColumnButtons.push(
                    <div style={{color: "#d9534f", fontWeight: 700}}>Заполните профиль и условия участия</div>
                )
            }
        } else if (!actionsColumnButtons.length) {
            return getColumnContent(column, lot, order)
        }
        return actionsColumnButtons
    }

    const trueWordform = (num) => {
        num = Number(num) % 100;
        let num_x = num % 10;
        if (num > 10 && num < 20)
            return 'лотов';
        if (num_x > 1 && num_x < 5)
            return 'лота';
        if (num_x === 1)
            return 'лот';
        return 'лотов';
    }

    return orders.data ? (
        orders.data.map((order, idx) => {
            return (
                <Grid item className={classes.row} key={idx}>
                    <Grid container direction="row" justify="space-between" alignItems="stretch">
                        <Grid item>
                            <Typography className={classes.title}>
                                {order.id ? `№${order.id} - ` : null} {order.name ? order.name : null}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.title}>
                                Заказчик: {(order && order?.company?.legal_detail?.name) ?? null}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="space-between" alignItems="stretch">
                        <Grid item>
                            <Typography className={classes.title}>
                                {order.regions.name + ', '}{order.count.lots} {trueWordform(order.count.lots)}, {order.average_price ? parseFloat(order.average_price).toLocaleString('ru-RU', {
                                style: 'currency',
                                currency: 'RUB',
                            }) : null} от {format(new Date(order.created), 'H:mm PP', {locale: ru})} (мск {format(new Date(new Date(order.created).toLocaleString("en-US", {timeZone: "Europe/Moscow"})), 'H:mm PP', {locale: ru})})
                            </Typography>
                        </Grid>
                        <Grid item>
                            {order.application_deadline &&
                                <Typography className={classes.title}>
                                    Подача предложений до {format(new Date(order.application_deadline), 'H:mm PP', {locale: ru})} (мск {format(new Date(new Date(order.application_deadline).toLocaleString("en-US", {timeZone: "Europe/Moscow"})), 'H:mm PP', {locale: ru})})
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                    {order.files && order.files.length > 0 &&
                        <Grid container direction="row" justify="flex-start" alignItems="stretch">
                            {order.files.map((file, idx) => (['draft_agreement', 'technical_task'].indexOf(file.type.key) !== -1 &&
                                <Typography gutterBottom style={{marginLeft: idx > 0 ? "20px" : "0", marginTop: 5}}>
                                    <Link
                                        href={process.env.REACT_APP_HOST_API + `/shop/order/${order.id}/file/${file.id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                        className={classes.link}>Скачать {file.type.name}<GetAppIcon
                                        className={classes.getIcon}
                                        viewBox="0 0 24 24"
                                    />
                                    </Link>
                                </Typography>
                            ))
                            }
                        </Grid>
                    }
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-label="sticky table" className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{minWidth: column.minWidth}}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {order.lots && order.lots.map((lot) => {
                                    return (
                                        <TableRow
                                            className={classes.tableRow}
                                            hover key={lot.id}
                                            onClick={() => {
                                                history.push(`/supplier/marketplace/my-orders/lot/${lot.id}`)
                                            }}
                                        >
                                            {columns.map((column, index) => (
                                                <TableCell key={index} style={{width: '250px'}}>
                                                    {renderColumn(column, order, lot)}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            );
        })
    ) : null
}
