import * as AppStorage from "../../App/helpers/storage"

export default function contract(state = {
    contract: null,
    statuses: [],
    companies: {
        data: [],
        meta: {
            total: 0, per_page: 15, current_page: 1
        },
        filter: {
            limit: 15,
            page: 1
        }
    },
    searchFilter: AppStorage.get('searchFilterContracts') ? JSON.parse(AppStorage.get('searchFilterContracts')) : {
        search: '',
        sort: 'all',
        tabSort: 0,
        type: 'all',
        tabType: 2,
        form: 'all',
        tabForm: 1
    }
}, action) {
    switch (action.type) {
        case "CONTRACTS":
            return {
                ...state,
                ...action.payload
            }
        case "CONTRACTS_FAILURE":
            return state
        case "CONTRACTS_CLEAR":
            return {
                ...state
            }
        case "CONTRACT_REQUEST":
            return state
        case "CONTRACT":
            return {
                ...state,
                ...{contract: action.payload}
            }
        case "CONTRACT_FAILURE":
            return state
        case "CONTRACT_ITEMS_REQUEST":
            return state
        case "CONTRACT_ITEMS":
            return {
                ...state,
                ...{items: action.payload}
            }
        case "CONTRACT_ITEMS_FAILURE":
            return state
        case "ADD_CONTRACT_REQUEST":
            return state
        case "ADD_CONTRACT_SUCCESS":
            return {
                ...state,
                ...{contract: action.payload}
            }
        case "ADD_CONTRACT_FAILURE":
            return state
        case "EDIT_CONTRACT_REQUEST":
            return state
        case "EDIT_CONTRACT_SUCCESS":
            return {
                ...state,
                ...{contract: action.payload}
            }
        case "EDIT_CONTRACT_FAILURE":
            return state
        case "CHANGE_STATUS_CONTRACT_REQUEST":
            return state
        case "CHANGE_STATUS_CONTRACT_SUCCESS":
            return state
        case "CHANGE_STATUS_CONTRACT_FAILURE":
            return state
        case "PRICE_SAVE_ELMAG_SUCCESS":
            return {
                ...state,
                ...{
                    contract: action.payload
                }
            }
        case "DELETE_CONTRACT_FILE_REQUEST":
            return state
        case "DELETE_CONTRACT_FILE_SUCCESS":
            return {
                ...state,
                ...(
                    state.hasOwnProperty('contract')
                        ? {
                            contract: {
                                ...state.contract,
                                ...{
                                    log: state.contract.log.map(log => {
                                        return {
                                            ...log,
                                            ...{files: log.files.filter(item => (item.id !== action.payload))}
                                        }
                                    })
                                }
                            }
                        }
                        : {}
                )
            }
        case "DELETE_CONTRACT_FILE_FAILURE":
            return state
        case "DELETE_CONTRACT_REQUEST":
            return state
        case "DELETE_CONTRACT_SUCCESS":
            return state
        case "DELETE_CONTRACT_FAILURE":
            return state
        case "CONTRACT_STATUSES_REQUEST":
            return state
        case "CONTRACT_STATUSES":
            return {
                ...state,
                ...{
                    statuses: action.payload
                }
            }
        case "CONTRACT_STATUSES_FAILURE":
            return state
        case "CONTRACT_COMPANIES_REQUEST":
            return state
        case "CONTRACT_COMPANIES":
            return {
                ...state,
                ...{
                    companies: {
                        data: action.payload.data,
                        meta: action.payload.meta,
                        filter: {
                            limit: action.payload.meta ? Number(action.payload.meta.per_page) : 15,
                            page: action.payload.meta ? action.payload.meta.current_page : 1
                        }
                    }
                }
            }
        case "CONTRACT_COMPANIES_FILTER":
            return {
                ...state,
                ...{
                    companies: {
                        ...state.companies,
                        ...{
                            filter: {
                                limit: action.payload.limit,
                                page: action.payload.page
                            }
                        }
                    }
                }
            }
        case "CONTRACT_COMPANIES_FAILURE":
            return state
        case "CONTRACTS_SEARCH_FILTER":
            AppStorage.set('searchFilterContracts', JSON.stringify(action.payload))
            return  {
                ...state,
                ...{
                    searchFilter: action.payload
                }
            }
        default:
            return state
    }
}
