import {HttpService} from "../../App/services/http"

export const NotificationService = {
    notifications,
    settings,
    update,
    count,
    types,
    remove,
    read
}

function notifications(params) {
    return HttpService.get("/notification/events", params)
}

function settings() {
    return HttpService.get("/notification/settings")
        .then(response => {
            return response.settings
        })
}

function update(values) {
    return HttpService.put("/notification/settings", values)
        .then(response => {
            return response.settings
        })
}

function count(params) {
    return HttpService.get("/notification/count", params)
        .then(response => {
            return response.count
        })
}

function types(params) {
    return HttpService.get("/notification/event/types", params)
        .then(response => {
            return response.types
        })
}

function remove(params) {
    return HttpService.deleteMethod('/notification/events', params, true, 'params')
}

function read(id) {
    return HttpService.put(`/notification/event/${id}/read`)
}

