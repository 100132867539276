import {HttpService} from "../../App/services/http"

export const PurchasePlanItemsService = {
    addItems,
    editItems,
    items,
    itemsByParentIDs,
    itemsByPurchasePlanID,
    item,
    deleteItems,
    copyItems,
    deleteItemsInPurchasePlan,
    uploadItems,
    comments,
    moveItems,
    updateStandard,
    filter
}

function addItems(values) {
    return HttpService.post(`/planning/purchase-plan/${values['purchase_plan_id']}/items/add`, values)
}

function copyItems(purchasePlanID, values) {
    return HttpService.post(`/planning/purchase-plan/${purchasePlanID}/items/copy`, values)
}

function editItems(values) {
    return HttpService.put(`/planning/purchase-plan/${values['purchase_plan_id']}/item/${values['key']}`, values)
}

function items(params) {
    return HttpService.get(`/planning/items`, params)
}

function itemsByParentIDs(params) {
    return HttpService.get(`/planning/items-by-parent-ids`, params)
}

function itemsByPurchasePlanID(purchasePlanID, params) {
    return HttpService.get(`/planning/purchase-plan/${purchasePlanID}/items`, params)
}

function item(purchasePlanID, key) {
    return HttpService.get(`/planning/purchase-plan/${purchasePlanID}/item/${key}`)
}

function deleteItems(ids) {
    return HttpService.deleteMethod(`/planning/items/delete`, ids)
}

function deleteItemsInPurchasePlan(purchasePlanID, ids) {
    return HttpService.deleteMethod(`/planning/purchase-plan/${purchasePlanID}/items/delete`, ids)
}

function uploadItems(purchasePlanID, formData) {
    return HttpService.post(`/planning/purchase-plan/${purchasePlanID}/items/upload`, formData, true, true)
}

function comments(itemID, params) {
    return HttpService.get(`/planning/purchase-plan/item/${itemID}/comments`, params)
}

function moveItems(purchasePlanID, values) {
    return HttpService.post(`/planning/purchase-plan/${purchasePlanID}/items/move`, values)
}

function updateStandard(id, params) {
    return HttpService.put(`/planning/purchase-plan/item/${id}/standard`, params)
}

function filter(params) {
    return HttpService.get("/planning/items/filter", params)
}
