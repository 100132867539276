import React, {useState} from "react"

import {Edit as EditIcon} from "@material-ui/icons";
import {IconButton} from "@material-ui/core";
import {Subscription} from "../Subscription";

export const Edit = (props) => {
    const {subscription, setLoading, regions, categories} = props

    const [open, setOpen] = useState(false)

    return (
        <React.Fragment>
            <IconButton
                onClick={() => {
                    setOpen(true)
                }}
            >
                <EditIcon/>
            </IconButton>
            {open ? (
                <Subscription
                    subscription={subscription}
                    open={open}
                    handleClose={() => setOpen(false)}
                    regions={regions}
                    categories={categories}
                    setLoading={setLoading}
                />
            ) : null}
        </React.Fragment>
    )
}
