import {HttpService} from "../../App/services/http"

export const ReportService = {
    reports,
    add,
    file
}

function reports(params) {
    return HttpService.get("/reports", params)
}

function add(values) {
    return HttpService.post("/report", values)
}

function file(id) {
    return HttpService.get(`/report/${id}/file`, null, true, 'blob')
}
