import {OrderService} from "../services/order"

export const OrderActions = {
    orders,
    lots,
    lot
}

function orders(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "REPORT_ORDERS_REQUEST"})

        OrderService.orders(params)
            .then(
                orders => {
                    dispatch({type: "REPORT_ORDERS", payload: {data: orders.data, meta: orders.meta}})
                    dispatch({type: "LOADING", payload: false})
                    resolve(orders)
                },
                error => {
                    dispatch({type: "REPORT_ORDERS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function lots(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "REPORT_LOTS_REQUEST"})

        OrderService.lots(params)
            .then(
                lots => {
                    dispatch({type: "REPORT_LOTS", payload: {data: lots.data, meta: lots.meta}})
                    dispatch({type: "LOADING", payload: false})
                    resolve(orders)
                },
                error => {
                    dispatch({type: "REPORT_LOTS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function lot(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "REPORT_ORDER_LOT_REQUEST"})

        OrderService.lot(id)
            .then(
                lot => {
                    dispatch({type: "REPORT_ORDER_LOT", payload: lot})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "REPORT_ORDER_LOT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
