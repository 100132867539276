import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {Button, Grid, InputAdornment, makeStyles, TextField, Typography} from "@material-ui/core"

import {
    Table,
} from "@devexpress/dx-react-grid-material-ui";
import format from "date-fns/format";
import {InvoiceActions} from "../../actions/invoice";
import {ColumnChooser} from "../../../App/components/Table/ColumnChooser";
import clsx from "clsx";
import {Search as SearchIcon} from "@material-ui/icons";
import {useDebounce} from "use-debounce";
import * as AppStorage from "../../../App/helpers/storage";
import {TableHeader} from "../../../App/components/Table/TableHeader";
import {CustomTable} from "../../../App/components/Table/Table";
import {localState} from "../../../App/components/LocalState";
import {Submit} from "../../../App/components/Button/Submit";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 161px)",
        "min-height": "calc(100vh - 161px)",
    },
    filterLabel: {
        "background": "#fff",
        "margin-bottom": "0 !important",
        "margin-top": "0 !important",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        },
        "& input": {
            padding: "6px 15px 7px"
        }
    },
    searchLabel: {},
    footer: {
        "padding": "4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "18px !important"
    }
}))

const columns = [
    {name: 'created', title: 'Дата создания'},
    {name: 'number', title: 'Номер счета'},
    {name: 'name', title: 'Наименование (назначение платежа)'},
    {name: 'amount', title: 'Сумма платежа'},
    {name: 'status', title: 'Статус', filter: 'status'},
    {name: 'download', title: 'Скачать'}
]

export const Content = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {account} = useSelector(state => state.account)
    const [invoices, setInvoices] = useState(localState)

    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)

    const {filter} = useSelector(state => state.filters['invoices'])
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [expiration, setExpiration] = useState(null)
    const [tableRef, setTableRef] = useState(null)
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [columnOrder, setColumnOrder] = useState(['created', 'number', 'name', 'amount', 'status', 'download'])
    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'created', width: 200},
        {columnName: 'number', width: 200},
        {columnName: 'name', width: 500},
        {columnName: 'amount', width: 200},
        {columnName: 'status', width: 200},
        {columnName: 'download', width: 200}
    ])

    useEffect(() => {
        dispatch(InvoiceActions.subscription(account.company.id)).then(subscription => {
            setExpiration(subscription.expiration)
        })
        // eslint-disable-next-line
    }, [])

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
        }
    }

    useEffect(() => {
        const getInvoices = async () => {
            let params = {
                limit: rowsPerPage,
                page: page,
                ...(searchRequest ? {search: searchRequest} : {}),
                ...getParams()
            }
            return await dispatch(InvoiceActions.invoices(params))
        }

        getInvoices().then(invoices => {
            setInvoices(invoices)
            tableRef && tableRef.current && tableRef.current.scrollIntoView()
        })
        // eslint-disable-next-line
    }, [dispatch, searchRequest, filter])

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: "INVOICES_FILTER", payload: {limitRows: newRowsPerPage}})
    }

    return (
        <Grid item className={classes.content}>
            <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
                <Grid item>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid item xs={6}>
                            <TextField
                                id="searchPurchases"
                                variant="standard"
                                name={'searchPurchases'}
                                placeholder={`Поиск: номер, назначение платежа`}
                                fullWidth
                                margin={'normal'}
                                onChange={(event) => {
                                    setSearch(event.target.value)
                                }}
                                classes={{
                                    root: clsx(classes.filterLabel, classes.searchLabel)
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon style={{color: '#c1c1c1'}}/>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <ColumnChooser
                                columns={columns}
                                hiddenColumnNames={hiddenColumns}
                                setHiddenColumnNames={setHiddenColumns}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <CustomTable
                    meta={invoices.meta}
                    tableName={'invoices'}
                    rows={invoices?.data.map(invoice => ({
                        created: format(new Date(invoice.created), 'dd.MM.yyyy'),
                        number: invoice.number,
                        name: invoice.name,
                        amount: parseFloat(invoice.amount).toLocaleString('ru-RU', {
                            style: 'currency',
                            currency: 'RUB'
                        }),
                        status: invoice.status.name,
                        download: (
                            <Grid container direction="row" justify="flex-start" alignItems="center">
                                <Button
                                    color="primary"
                                    type="button"
                                    href={process.env.REACT_APP_HOST_API + `/company/invoice/${invoice.id}/download?type=bill&token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                    download
                                >
                                    Счет
                                </Button>
                                {invoice.completed ? (
                                    <Button
                                        color="primary"
                                        type="button"
                                        href={process.env.REACT_APP_HOST_API + `/company/invoice/${invoice.id}/download?type=report&token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                        download
                                    >
                                        Акт
                                    </Button>
                                ) : null}
                            </Grid>
                        )
                    }))}
                    columns={columns}
                    page={{
                        page: page,
                        setPage: setPage,
                        rowsPerPage: rowsPerPage,
                        handlePageSizeChange: handlePageSizeChange
                    }}
                    setTableRef={setTableRef}
                    columnsSettings={{
                        columnOrder: columnOrder,
                        setColumnOrder: setColumnOrder,
                        setColumnWidths: setColumnWidths,
                        columnWidths: columnWidths,
                        hiddenColumnNames: hiddenColumnNames,
                        setHiddenColumnNames: setHiddenColumnNames
                    }}
                    tableHeader={TableHeader}
                    filterActionType={"INVOICES_FILTER"}
                    classInputContent={classes.inputContent}
                    rowComponent={({row, tableRow, children}) => (
                        <Table.Row
                            tableRow={tableRow}
                            children={children}
                            className={classes.default}
                            row={row}
                        />
                    )}
                    filters={{
                        download: {
                            nullButton: true
                        },
                    }}
                    actions={[
                        <Grid item>
                            {expiration ? <Typography>Доступ до: {expiration}</Typography> : null}
                        </Grid>
                    ]}
                />
            </Grid>
        </Grid>
    )
}
