import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import format from "date-fns/format"
import DateFnsUtils from "@date-io/date-fns"
import {ru} from "date-fns/locale"

import {
    FormControl,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    MenuItem,
    Select,
    TablePagination,
    Typography,
    withStyles
} from '@material-ui/core'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"

import {ReportActions} from "../../actions/report"
import {type as types} from "../../dics/type"

const useStyles = makeStyles(() => ({
    fullWidth: {
        "width": "100%",
    },
    item: {
        "width": "100%",
        "padding": "16px"
    },
    history: {
        "padding": "8px 16px 0 16px",
        'border-bottom': "1px solid #e4e4e4"
    },
    historyItem: {
        "& .MuiListItemText-primary" : {
            "text-transform": "none !important"
        }
    },
    title: {
        "font-size": "18px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    list: {
        "overflow": "auto",
        "height": "calc(100vh - 350px)"
    },
    date: {
        width: "115px",
        "& .MuiInputAdornment-root .MuiButtonBase-root": {
            padding: 0,
        }
    },
    paginationWrap: {
        backgroundColor: "#e7eaee",
        position: "fixed",
        bottom: "0",
        maxWidth: "300px"
    },
    pagination: {
        "&>div": {
            flexWrap: 'wrap'
        }
    },
}))

const CustomList = withStyles({
    root: {
        "padding": "0",
        "& .MuiListItem-button": {
            "cursor": "pointer",
            "align-items": "center",
            "min-height": "50px",
            "padding": "0 16px",
            "border-bottom": "1px solid #e4e4e4",
            "outline": "0"
        },
        "& .MuiListItemText-primary": {
            "font-size": "16px",
            "font-weight": "700",
            "text-transform": "uppercase"
        },
        "& .MuiListItem-button:hover": {
            "background-color": "rgba(0,0,0,.04)",
            "&:after": {
                "position": "absolute",
                "content": "''",
                "top": "0",
                "left": "0",
                "width": "100%",
                "height": "100%",
                "background-color": "rgba(0,0,0,.04)"
            }
        }
    }
})(List);

const getTypes = () => {
    return Object.entries(types).reduce((result, [key, value]) => {
        result.push({
            value: key,
            name: value
        })
        return result
    }, [])
}

export const Menu = () => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { account, reports, filter } = useSelector(state => ({...state.account, ...state.report}))

    const [beginning, setBeginning] = useState(() => {
        const now = new Date()

        return filter.beginning ?? now.setMonth(now.getMonth() - 1)
    })
    const [ending, setEnding] = useState(filter.ending ?? new Date())
    const [type, setType] = useState(filter.type ? filter.type.value : 'purchase_history')
    const [limit, setLimit] = useState(filter.limit ? filter.limit : 15)
    const [page, setPage] = useState(filter.page ? filter.page : 1)

    const types = getTypes().filter(type => {
        const params = account.permissions.find(permission => permission.name === 'access_to_report')?.params
        if (params?.types) {
            return params.types.includes(type.value)
        }

        return true;
    })

    useEffect(() => {
        dispatch({type: 'REPORT_TYPE', payload: types.find(item => item.value === type) ?? types[0]})

        return () => {
            dispatch({type: 'REPORT_TYPE', payload: null})
        }
        // eslint-disable-next-line
    }, [dispatch, type]);

    useEffect(() => {
        const getReports = async () => {
            return await dispatch(ReportActions.reports({
                beginning: format(filter.beginning ? new Date(beginning) : beginning, 'yyyy-MM-dd'),
                ending: format(filter.ending ? new Date(ending)  : ending, 'yyyy-MM-dd'),
                type: type,
                limit: limit,
                page: page
            }))
        }

        getReports().then(() => {
            dispatch({type: "REPORT_FILE_CLEAR"})
            dispatch({type: 'REPORTS_FILTER', payload: {type: types.find(item => (item.value === type)) ?? types[0], beginning: format(filter.beginning ? new Date(beginning) : beginning, 'yyyy-MM-dd'), ending : format(filter.ending ? new Date(ending)  : ending, 'yyyy-MM-dd'),limit: limit,page: page}})
        })
        // eslint-disable-next-line
    }, [dispatch, type, beginning, ending,limit,page]);

    const hasReport = (type) => {
        switch (type) {
            case 'delivery_times':
            case 'delivery_orders':
            case 'delivery_orders_after_fact':
            case 'contract_unscheduled_purchases':
            case 'order_unscheduled_purchases':
            case 'execution_contract':
            case 'contracts':
            case 'user_activity':
            case 'supplier_activity':
            case 'order_supplier_activity':
            case 'share_categories':
            case 'share_suppliers':
            case 'share_standards':
            case 'price_orders':
            case 'price_standards':
            case 'customer_contracts':
            case 'delivery_orders_items':
            case 'order_standards':
            case 'planning_items':
                return true
            default:
                return false
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(Number(newPage) + 1)
    }

    const handleChangeRowsPerPage = (event) => {
       setLimit(+event.target.value)
       setPage(1)
    }

    return (
        <Grid container className={classes.container} direction="column" justify="flex-start" alignItems="stretch">
            <Grid item className={classes.item}>
                <Grid container className={classes.container} direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                    <Grid item className={classes.fullWidth}>
                        <FormControl fullWidth>
                            <InputLabel>Тип отчёта</InputLabel>
                            <Select
                                value={type}
                                onChange={e => {
                                    setType(e.target.value)
                                }}
                            >
                                {types.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {hasReport(type) &&
                        <Grid item>
                            <Grid container className={classes.container} direction="row" justify="flex-start"
                                  alignItems="stretch" spacing={2}>
                                <Grid item xs={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                        <KeyboardDatePicker
                                            className={classes.date}
                                            fullWidth
                                            invalidDateMessage="Некорректная дата"
                                            minDate={new Date("1970-01-01")}
                                            minDateMessage={""}
                                            disableToolbar
                                            orientation="landscape"
                                            variant="inline"
                                            openTo="year"
                                            name="beginning"
                                            format="dd-MM-yyyy"
                                            autoOk
                                            required
                                            label={'Дата события с'}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={beginning}
                                            onChange={date => {
                                                setBeginning(date)
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                        <KeyboardDatePicker
                                            className={classes.date}
                                            fullWidth
                                            invalidDateMessage="Некорректная дата"
                                            disableToolbar
                                            orientation="landscape"
                                            variant="inline"
                                            openTo="year"
                                            name="ending"
                                            format="dd-MM-yyyy"
                                            autoOk
                                            required
                                            label={'по'}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={ending}
                                            onChange={date => {
                                                setEnding(date)
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
            {hasReport(type) &&
                <React.Fragment>
                    <Grid item className={classes.history}>
                        <Typography variant="overline" gutterBottom className={classes.title}>История</Typography>
                    </Grid>
                    <Grid item>
                        <CustomList className={classes.list}>
                            {reports && reports.data && reports.data.map(report => (
                                <ListItem
                                    button
                                    key={report.id}
                                    onClick={() => {
                                        dispatch(ReportActions.file(report)).then(() => {})
                                    }}
                                    onMouseEnter={() => {

                                    }}
                                >
                                    <ListItemText className={classes.historyItem}
                                        primary={
                                            <React.Fragment>
                                                <Grid container className={classes.container} direction="column" justify="flex-start" alignItems="stretch">
                                                    <Grid item>
                                                        <Grid container className={classes.container} direction="row" justify="space-between" alignItems="stretch">
                                                            <Grid item>
                                                                № {report.id}
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography component={'span'} variant="body2">
                                                                    {format(new Date(report.created), 'H:mm PP', {locale: ru})}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                      <Grid container className={classes.container} direction="column" justify="flex-start" alignItems="stretch">
                                                          <Grid item>
                                                              <Typography component={'span'} variant="body2">
                                                                  Период:
                                                              </Typography>
                                                          </Grid>
                                                          <Grid item>
                                                              <Grid container direction="row" justify="space-between" alignItems="stretch">
                                                                  <Grid item>
                                                                      <Typography component={'span'} variant="body2">
                                                                          {report.beginning} - {report.ending}
                                                                      </Typography>
                                                                  </Grid>
                                                                  <Grid item>
                                                                      <Typography component={'span'} variant="body2">
                                                                        {!report.formed && 'Отчёт формируется'}
                                                                      </Typography>
                                                                  </Grid>
                                                              </Grid>
                                                          </Grid>
                                                      </Grid>
                                                    </Grid>
                                                </Grid>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </CustomList>
                    </Grid>
                    <ListItem className={classes.paginationWrap}>
                        <TablePagination
                            rowsPerPageOptions={[5, 15, 25, 50, 100]}
                            component='div'
                            className={classes.pagination}
                            labelRowsPerPage={'Записей на странице:'}
                            count={(reports && reports.meta) ? reports.meta.total : 0}
                            rowsPerPage={limit}
                            page={page - 1}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </ListItem>
                </React.Fragment>
            }
        </Grid>
    )
}
