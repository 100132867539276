export default function cart(state = {count: 0, loading: false}, action) {
    switch (action.type) {
        case "CART_ITEMS_COUNT_REQUEST":
            return state
        case "CART_ITEMS_COUNT":
            return {
                ...state,
                ...{
                    count: action.payload,
                    loading: true
                }
            }
        case "CART_ITEMS_COUNT_LOADING":
            return {
                ...state,
                ...{
                    loading: action.payload
                }
            }
        case "CART_ITEMS_COUNT_FAILURE":
            return state
        default:
            return state
    }
}
