export default function authentication(state = {}, action) {
    switch (action.type) {
        case "AUTH_LOGIN_REQUEST":
            return {}
        case "AUTH_LOGIN_SUCCESS":
            return {}
        case "AUTH_LOGIN_FAILURE":
            return {}
        case "AUTH_PERMISSIONS_REQUEST":
            return {}
        case "AUTH_PERMISSIONS_SUCCESS":
            return {
                ...state,
                ...{permissions: action.payload}
            }
        case "AUTH_PERMISSIONS_FAILURE":
            return {}
        case "AUTH_ROLES_REQUEST":
            return {}
        case "AUTH_ROLES_SUCCESS":
            return {
                ...state,
                ...{roles: action.payload}
            }
        case "AUTH_ROLES_FAILURE":
            return {}
        case "AUTH_LOGOUT_REQUEST":
            return {}
        case "AUTH_LOGOUT_SUCCESS":
            return {}
        case "AUTH_LOGOUT_FAILURE":
            return {}
        case "AUTH_RECOVERY_REQUEST":
            return {}
        case "AUTH_RECOVERY_SUCCESS":
            return {}
        case "AUTH_RECOVERY_FAILURE":
            return {}
        case "AUTH_REGISTRATION_REQUEST":
            return {}
        case "AUTH_REGISTRATION_SUCCESS":
            return {}
        case "AUTH_REGISTRATION_FAILURE":
            return {}
        default:
            return state
    }
}
