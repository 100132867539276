import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import * as Yup from "yup"

import {Grid, makeStyles, Paper, StepIcon, Typography} from "@material-ui/core"

import {UserActions} from "../../../Company/components/User/actions/user"
import {TextFieldWithError as CustomTextField} from "../../../App/components/Inputs/TextFieldWithError"

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    container: {
        "background-image": "linear-gradient(to bottom,#f6f6f6,#eee 85%,#b1b1b1)",
        "border-radius": 0,
        "height": "auto"
    },
    fullWidth: {
        "width": "100%"
    },
    fullHeight: {},
    full: {
        "width": "100%",
        "height": "inherit"
    },
    column: {
        "height": "86px"
    },
    header: {
        "padding": "15px 35px !important",
    },
    input: {
        "width": "100%",
        "height": "calc(100vh - 274px)",
        "padding-bottom": "4px",
        "overflow": "auto",
        "max-height": "381px",
    },
    inputContent: {
        // "overflow": "auto",
        "padding": "0 35px 3px !important",
        "height": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase"
    },
    stepIcon: {
        "&.MuiStepIcon-active": {
            "color": "#898989"
        }
    },
    stepLabel: {
        "fill": "white"
    },
    stepTitle: {
        "font-size": "16px",
        "color": "#898989"
    },
    list: {
        "margin-bottom": "15px"
    }
}))

export const Content = () => {
    const dispatch = useDispatch()
    const {account} = useSelector(state => state.account)
    const classes = useStyles()
    const initial = {
        login: account.login,
        email: account.email,
        last_name: account.last_name,
        middle_name: account.middle_name,
        first_name: account.first_name,
    }
    const schema = {
        login: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        email: Yup.string().email("Некорректный формат электронной почты!").required("Поле не может быть пустым или состоять из одних пробелов!"),
        last_name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        first_name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        middle_name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
    }

    return <Grid item className={classes.content}>
        <Paper style={{height: "calc(100vh - 110px)"}}>
            <Formik
                initialValues={initial}
                validationSchema={Yup.object().shape(schema)}
                onSubmit={(values, {setSubmitting}) => {
                    return dispatch(UserActions.create({...values})).then(
                        () => {
                            setSubmitting(false);
                        },
                        errors => {
                            if (errors) {
                            }
                            setSubmitting(false);
                        })
                }}
            >
                {() => (
                    <Form>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item className={classes.full}>
                                <Grid container direction="row" justify="space-between" alignItems="stretch">
                                    <Grid item className={classes.header}>
                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                            <Grid item>
                                                <Typography className={classes.title}>
                                                    {account.roles.filter(role => role.name !== 'user')[0].description}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.input}>
                                        <Grid className={classes.fullHeight} container direction="column" justify="center" alignItems="stretch">
                                            <Grid item className={classes.inputContent}>
                                                <Grid container className={classes.fullWidth} direction="column" justify="center" alignItems="stretch" spacing={1}>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                                            <Grid item>
                                                                <StepIcon
                                                                    icon={1}
                                                                    active={true}
                                                                    classes={{
                                                                        root: classes.stepIcon,
                                                                        text: classes.stepLabel
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography className={classes.stepTitle}>Учетные
                                                                    данные в системе</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item className={classes.list}>
                                                        <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                            <Grid item xs={6} className={classes.column}>
                                                                <Field
                                                                    fullWidth
                                                                    name="login"
                                                                    type="text"
                                                                    label="Логин"
                                                                    required={true}
                                                                    disabled={true}
                                                                    component={CustomTextField}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6} className={classes.column}>
                                                                <Field
                                                                    fullWidth
                                                                    name="email"
                                                                    type="email"
                                                                    label="Электронный адрес"
                                                                    required={true}
                                                                    disabled={true}
                                                                    component={CustomTextField}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                            <Grid item xs={6} className={classes.column}>
                                                                <Field
                                                                    fullWidth
                                                                    name="last_name"
                                                                    type="text"
                                                                    label="Фамилия пользователя"
                                                                    required={true}
                                                                    disabled={true}
                                                                    component={CustomTextField}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6} className={classes.column}>
                                                                <Field
                                                                    fullWidth
                                                                    name="first_name"
                                                                    type="text"
                                                                    label="Имя пользователя"
                                                                    required={true}
                                                                    disabled={true}
                                                                    component={CustomTextField}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                            <Grid item xs={6} className={classes.column}>
                                                                <Field
                                                                    fullWidth
                                                                    name="middle_name"
                                                                    type="text"
                                                                    label="Отчество пользователя"
                                                                    required={true}
                                                                    disabled={true}
                                                                    component={CustomTextField}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Paper>
    </Grid>
}
