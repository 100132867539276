export default function offer(state = {}, action) {
    switch (action.type) {
        case "ADD_SUPPLIER_OFFER_REQUEST":
            return state
        case "ADD_SUPPLIER_OFFER_SUCCESS":
            return state
        case "ADD_SUPPLIER_OFFER_FAILURE":
            return state
        default:
            return state
    }
}
