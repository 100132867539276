import {HttpService} from "../../App/services/http"

export const ItemsService = {
    addItems,
    items,
    lotItems,
    categoriesForItemsInLot,
    editItems,
    deleteItems,
    item,
    exclude,
    include,
    filter
}

function addItems(params) {
    return HttpService.post(`/planning/purchase/${params['purchase_id']}/lot/${params['lot_id']}/items/add`, params)
}

function editItems(params) {
    return HttpService.put(`/planning/lot/${params['lot_id']}/item/${params['key']}`, params)
}

function items(purchaseID, params) {
    return HttpService.get(`/planning/purchase/${purchaseID}/lots/items`, params)
}

function lotItems(purchaseID, lotID, params) {
    return HttpService.get(`/planning/purchase/${purchaseID}/lot/${lotID}/items`, params)
}

function categoriesForItemsInLot(lotID) {
    return HttpService.get(`/planning/lot/${lotID}/categories`)
}

function item(lotID, itemID) {
    return HttpService.get(`/planning/lot/${lotID}/item/${itemID}`)
}

function deleteItems(purchaseID, ids) {
    return HttpService.deleteMethod(`/planning/purchase/${purchaseID}/items/delete`, ids)
}

function exclude(purchaseID, itemID) {
    return HttpService.deleteMethod(`/planning/purchase/${purchaseID}/item/${itemID}/exclude`)
}

function include(purchaseID, params) {
    return HttpService.put(`/planning/purchase/${purchaseID}/items/include`, params)
}

function filter(params) {
    return HttpService.get(`/planning/purchase/${params['purchase_id']}/lots/items/filter`, params)
}
