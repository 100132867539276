import React, {useState} from "react"
import {useSelector} from "react-redux"
import {v4 as uuidv4} from "uuid"

import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    Tab,
    Tabs,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@material-ui/core"
import {Close as CloseIcon, ExpandMore, GetApp as GetAppIcon} from "@material-ui/icons"

import {Submit} from "../../App/components/Button/Submit"
import {TabPanel} from "../../Account/components/Tabs/TabPanel"
import {history} from "../../App/helpers/history"
import {AuthorizationService} from "../../Auth/services/authorization"
import * as AppStorage from "../../App/helpers/storage"

const useStyles = makeStyles(() => ({
    title: {
        "margin": "0",
        "padding-top": "2px",
        "font-size": "18px",
        "font-weight": "600",
        "color": "#fff",
        "text-transform": "uppercase",
        "display": "flex",
        "justify-content": "space-between",
        "align-items": "center",
        "min-height": "40px",
        "padding": "0 20px",
        "background-color": "#898989",
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    footerContainer: {
        "padding": "0 25px",
        "background-color": "#e7eaee",
        "height": "75px",
        position: "absolute",
        bottom: 0,
    },
    content: {
        marginTop: "20px",
        marginBottom: "75px",
    },
    submit: {
        "width": "100%",
        "border-bottom": "3px solid #898989",
        "border-radius": "2px",
        "padding": "5px 10px 0",
        "min-width": "95px",
        "min-height": "35px",
        "font-size": "16px",
        "font-weight": "500",
        "color": "white",
        "outline": "0",
        "height": "35px",
        "&:disabled": {
            "background-color": "#c1c1c1",
            "border-bottom-color": "#898989 !important",
            "color": "white"
        }
    },
    dialogContent: {
        padding: "0",
        "overflow": "hidden",
    },
    dialogContainer: {
        "& > div": {
            minHeight: "700px",
            maxHeight: "700px",
            maxWidth: "1152px",
            width: "calc(100vw - 200px)"
        }
    },
    tabContent: {
        height: "475px",
        overflow: "auto",
        "overflow-x": "hidden",
        margin: 0,
        paddingTop: "10px",
        width: "100%"
    },
    avatar: {
        width: "100%",
        height: "auto",
        borderRadius: "unset"
    },
    download: {
        "color": "#485868"
    }
}));

export const Card = (props) => {
    const classes = useStyles();
    const {open, onClose, company, companyType, contractID} = props;
    const [tab, setTab] = useState(0);
    const { account } = useSelector(state => state.account);

    // eslint-disable-next-line
    const [files, setFiles] = useState(() => {
        let files = {}

        const types = {
            ...(((company.type === 'individual') || (company.type === 'self')) ? {} : {
                'constituent_documents': {
                    name: 'Учредительные документы с учётом внесенных в них изменений, свидетельства о государственной регистрации учредительных документов и внесённых в них изменений *',
                    children: {
                        'charter': {
                            name: 'Устав *'
                        },
                        'charter_changes': {
                            name: 'Изменения в уставе'
                        },
                        'protocol': {
                            name: 'Протокол (приказ о создании общества) *'
                        }
                    }
                },
            }),
            'certificates': ((company.type === 'individual') || (company.type === 'self')) ? {
                name: 'ИНН, выписка из ЕГРИП *',
                children: {
                    'INN': {
                        name: 'ИНН *'
                    },
                    'egrip_transcript': {
                        name: 'Выписка из ЕГРИП *'
                    },
                    'passport_copy_first_page': {
                        name: 'Копия паспорта * (разворот лицевой страницы)'
                    },
                    'notification_of_the_transition_to_a_simplified_tax_system': {
                        name: 'Уведомление о переходе на упрощенную систему налогообложения (УСН)'
                    }
                },
            } : {
                name: 'Свидетельства *',
                children: {
                    'certificate_of_registration': {
                        name: 'Свидетельство о постановке на учёт в налоговом органе *'
                    },
                    'certificate_of_state_registration': {
                        name: 'Свидетельство о государственно регистрации контрагента *'
                    },
                    'certificate_of_changes': {
                        name: 'Свидетельство о внесении в госудраственный реестр изменений в сведениях о юридическом лице, не связанных с внесением в учредительные документы'
                    }
                }
            },
            ...(((company.type === 'individual') || (company.type === 'self')) ? {} : {
                'extract_from_the_register': {
                    name: 'Выписка из единого государственного реестра юридических лиц, выданная регистрирующим органом *'
                },
            }),
            'documents_confirming_the_authority_of_the_person': {
                name: 'Документы, подтверждающие полномочия лица на подписание договора'
            },
            'drug_manufacturing_licenses': {
                name: 'Лицензии на производство лекарственных средств или на фармацевтическую деятельность с правом оптовой торговли'
            },
            'licenses_for_activities_with_medical_equipment': {
                name: 'Лицензии на осуществление деятельности по производству и техническому обслуживанию медицинской техники'
            },
            'legal_acts_on_licensing_in_the_field_of_use_of_ionizing_radiation_sources': {
                name: 'Нормативные правовые акты по лицензированию в области использования источников ионизирующего излучения'
            },
            'fuel_storage_licenses': {
                name: 'Лицензии на хранение ГСМ'
            },
            'certificate_for_wholesale_and_retail_sale_of_fuel_and_lubricants': {
                name: 'Свидетельство на оптово-розничную реализацию ГСМ'
            },
            'security_license': {
                name: 'Лицензия на осуществление охранной деятельности'
            }
        }

        for (let type in types) {
            if (company.hasOwnProperty('contents')) {
                if (company.contents && (company.contents.hasOwnProperty(type) || (types[type].hasOwnProperty('children') && !!Object.keys(types[type].children).map(key => key).filter(key => company.contents.hasOwnProperty(key)).length))) {
                    files[type] = {
                        name: types[type].name,
                        files: company.contents
                            ? (company.contents.hasOwnProperty(type) ? company.contents[type].map(file => {
                                return {
                                    loading: false,
                                    error: false,
                                    uuid: uuidv4(),
                                    file: file
                                }
                            }) : [])
                            : [],
                        ...(types[type].hasOwnProperty('children') ? {
                            children: function () {
                                let children = {}

                                for (let key in types[type].children) {
                                    if (company.contents.hasOwnProperty(key)) {
                                        children[key] = {
                                            name: types[type].children[key].name,
                                            files: company.contents
                                                ? (company.contents.hasOwnProperty(key) ? company.contents[key].map(file => {
                                                    return {
                                                        loading: false,
                                                        error: false,
                                                        uuid: uuidv4(),
                                                        file: file
                                                    }
                                                }) : [])
                                                : []
                                        }
                                    }
                                }

                                return children
                            }()
                        } : {})
                    }
                }
            }
        }

        return files
    })

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <Dialog
            aria-labelledby="simple-dialog-title"
            open={open}
            classes={{
                root: classes.dialog,
                container: classes.dialogContainer
            }}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle
                id="cartDialogTitle"
                classes={{
                    root: classes.title,
                }}
                disableTypography={true}
            >
                Карточка {companyType}<CloseIcon className={classes.close} onClick={onClose} />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid container justify={'center'} className={classes.content}>
                    <Grid item xs={12}>
                        {company &&
                            <Grid container justify={'center'}>
                                <Grid item xs={4}>
                                    <Avatar className={classes.avatar} src={company.logo ?? `${process.env.PUBLIC_URL}/storage/no-image.jpg`} />
                                </Grid>
                                <Grid item xs={8}>
                                    <Grid container direction={'column'}>
                                        <Grid item>
                                            <Typography className={classes.supplierTitle}>{company.legal_detail.name}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Tabs
                                                value={tab}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                onChange={handleChange}
                                            >
                                                <Tab value={0} label="Реквизиты" className={classes.tab}/>
                                                {(company && company.hasOwnProperty('contents') && (company.contents instanceof Object) && !!Object.keys(company.contents).length) && <Tab value={1} label="Документы" className={classes.tab}/>}
                                                <Tab label="Отзывы" className={classes.tab}/>
                                            </Tabs>
                                            <TabPanel value={tab} index={0}>
                                                <Grid container direction={'column'} spacing={2} wrap="nowrap" className={classes.tabContent}>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                Телефон:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.contact_information.phones ? company.contact_information.phones.map(el => `${el.number} ${el.extra ? 'доб. ' + el.extra : ''}`).join(', ') : 'Телефон не заполнен'}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                e-mail:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.contact_information.emails ? company.contact_information.emails.join(', ') : null}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                Сайт:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.contact_information.website}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                Юр. адрес:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.legal_detail.legal_address}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                Факт. адрес:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.legal_detail.current_address}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                ИНН:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.legal_detail.inn}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                ОГРН:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.legal_detail.ogrn}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                КПП:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.legal_detail.kpp}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                Р/с:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.payment_detail ? (company.payment_detail.checking_account ?? '') : ''}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                К/с:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.payment_detail ? (company.payment_detail.correspondent_account ?? '') : ''}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                БИК:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.payment_detail ? (company.payment_detail.bik ?? '') : ''}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                Банк:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.payment_detail ? (company.payment_detail.bank ?? '') : ''}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            {(company && company.hasOwnProperty('contents') && (company.contents instanceof Object) && !!Object.keys(company.contents).length) &&
                                                <TabPanel value={tab} index={1}>
                                                    <Grid container direction={'column'} spacing={2} wrap="nowrap"
                                                          className={classes.tabContent}>
                                                        {
                                                            Object.keys(files).map((key, i) => (
                                                                <Accordion key={i}>
                                                                    <AccordionSummary expandIcon={<ExpandMore/>}>
                                                                        <Typography>{files[key].name}</Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                            {files[key].hasOwnProperty('children') ?
                                                                                Object.keys(files[key].children).map((childKey, j) => (
                                                                                    <Box key={j} paddingLeft={2} paddingRight={1}>
                                                                                        <Grid item>
                                                                                            <Typography>{files[key].children[childKey].name}</Typography>
                                                                                        </Grid>
                                                                                        <Grid item>
                                                                                            <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={2}>
                                                                                                <Grid item>
                                                                                                    <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                                                                                                        {!!files[key].children[childKey].files.length &&
                                                                                                            files[key].children[childKey].files.map((option, index) => (
                                                                                                                <Grid item key={index}>
                                                                                                                    <Button
                                                                                                                        className={classes.download}
                                                                                                                        endIcon={< GetAppIcon />}
                                                                                                                        href={process.env.REACT_APP_HOST_API + `/account/company/${company.id}/file/${option.file.id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                                                    >
                                                                                                                        {option.file.name}
                                                                                                                    </Button>
                                                                                                                </Grid>
                                                                                                            ))
                                                                                                        }
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Box>
                                                                                )) : <Grid item>
                                                                                    <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={2}>
                                                                                        <Grid item>
                                                                                            <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                                                                                                {!!files[key].files.length &&
                                                                                                    files[key].files.map((option, index) => (
                                                                                                        <Grid item key={index}>
                                                                                                            <Button
                                                                                                                className={classes.download}
                                                                                                                endIcon={< GetAppIcon />}
                                                                                                                href={process.env.REACT_APP_HOST_API + `/account/company/${company.id}/file/${option.file.id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                                            >
                                                                                                                {option.file.name}
                                                                                                            </Button>
                                                                                                        </Grid>
                                                                                                    ))
                                                                                                }
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            }
                                                                        </Grid>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            ))
                                                        }
                                                    </Grid>
                                                </TabPanel>
                                            }
                                            <TabPanel value={tab} index={2}>
                                                <Grid container direction={'column'} spacing={2} wrap="nowrap" className={classes.tabContent}>
                                                </Grid>
                                            </TabPanel>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.footerContainer}>
                    <Grid item>
                        <Submit
                            className={classes.submit}
                            disableElevation
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={onClose}
                        >
                            Закрыть
                        </Submit>
                    </Grid>
                    {AuthorizationService.roles(account, 'customer') && contractID &&
                    <Grid item>
                        <Submit
                            className={classes.submit}
                            disableElevation
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={(e) => {
                                e.stopPropagation();
                                history.push(`/catalog/products?contract_id=${contractID}`)
                            }}
                        >
                            Каталог поставщика
                        </Submit>
                    </Grid>
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
