import {EmployeeService} from "../services/employee"

export const EmployeeActions = {
    employees,
    add,
    edit
}

function employees(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "EMPLOYEES_REQUEST"})

        EmployeeService.employees(params)
            .then(
                employees => {
                    dispatch({type: "EMPLOYEES", payload: {data: employees.data, meta: employees.meta}})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "EMPLOYEES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function add(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "ADD_EMPLOYEE_REQUEST"})

        EmployeeService.add(id, values)
            .then(
                response => {
                    dispatch({type: "ADD_EMPLOYEE_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "ADD_EMPLOYEE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function edit(company_id, employee_id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "EDIT_EMPLOYEE_REQUEST"})

        EmployeeService.edit(company_id, employee_id, values)
            .then(
                response => {
                    dispatch({type: "EDIT_EMPLOYEE_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "EDIT_EMPLOYEE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}


