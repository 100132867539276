import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce} from 'use-debounce'
import clsx from "clsx"
import {IntegratedSummary,} from '@devexpress/dx-react-grid'

import {Grid, InputAdornment, makeStyles, TextField,} from "@material-ui/core"
import {GetApp as GetAppIcon, Search as SearchIcon} from "@material-ui/icons"
import {green} from "@material-ui/core/colors"
import {Table} from "@devexpress/dx-react-grid-material-ui";

import {PurchasePlanActions} from "../../actions/purchasePlan"
import {history} from "../../../App/helpers/history"
import {AuthorizationService} from "../../../Auth/services/authorization"
import {ColumnChooser} from "../../../App/components/Table/ColumnChooser"
import {ButtonIcon} from "../../../App/components/Button/ButtonIcon"
import {getMonthName} from "../../../App/helpers/date"
import {SettingsActions} from "../../../Settings/actions/settings"
import {SettingsActions as PlanningSettingsActions} from "../../actions/settings"
import {DownloadActions} from "../../../Download/actions/download";
import {CustomTable} from "../../../App/components/Table/Table";
import {TableHeader} from "../../../App/components/Table/TableHeader";
import {Create as CreateButton} from "../PurchasePlan/Buttons/Create";
import {Approve as ApproveButton} from "../PurchasePlan/Buttons/Approve";
import {Decline as DeclineButton} from "../PurchasePlan/Buttons/Decline";
import {Correct as CorrectButton} from "../PurchasePlan/Buttons/Correct";
import {Delete as DeleteButton} from "../PurchasePlan/Buttons/Delete";
import {Edit as EditButton} from "../PurchasePlan/Buttons/Edit";
import {Copy as CopyButton} from "../PurchasePlan/Buttons/Copy";
import {localState} from "../../../App/components/LocalState";
import {status} from "../../dics/Plan/status";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.planningContent.width,
        padding: theme.planningContent.padding,
        margin: theme.planningContent.margin,
    },
    container: {
        "background-image": "linear-gradient(to bottom,#f6f6f6,#eee 85%,#b1b1b1)",
        "width": "100%",
        "height": "calc(100vh - 256px)"
    },
    filterLabel: {
        "background": "#fff",
        "margin-bottom": "0 !important",
        "margin-top": "5px !important",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        },
        "& input": {
            padding: "6px 15px 7px"
        }
    },
    tableContainer: {
        "min-height": "100%",
        "height": "calc(100vh - 400px)"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    searchLabel: {},
    searchInput: {
        "width": "calc(100% - 83px)"
    },
    searchButton: {
        "margin-bottom": "10px"
    },
    fullWidth: {
        "width": "100%"
    },
    inputContentMain: {
        "width": "100%",
        "height": theme.planningContent.height,
        "min-height": theme.planningContent.height,
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    active: {
        'height': '41px',
        'background-color': green[100],
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    loading: {
        'height': '41px',
        'background-color': '#fff',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    submit: {
        "height": "35px",
    },
    iconButton: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    },
    title: {
        "&>h2": {
            color: '#fff'
        }
    },
}))

const columns = [
    {name: 'planning_year', title: 'Планируемый год', filter: 'year'},
    {name: 'category', title: 'Категория', filter: 'categories'},
    {name: 'name', title: 'Наименование'},
    {name: 'status', title: 'Статус', filter: 'status'},
    {name: 'month', title: 'Планируемый месяц проведения', filter: 'month'},
    {name: 'schedule', title: 'Общий план-график', filter: 'schedules'},
    {name: 'purchases_count', title: 'Кол-во закупок'},
    {name: 'average_price_total', title: 'НМЦ (планируемая)'},
    {name: 'average_price_approved', title: 'НМЦ (утв.)'},
    {name: 'average_price_unapproved', title: 'НМЦ (неутв.)'},
    {name: 'railRoad', title: 'ЖД', filter: 'rail_roads'},
    {name: 'region', title: 'Макрорегион', filter: 'regions'},
    {name: 'company', title: 'ЧУЗ', filter: 'companies'},
    {name: 'unlabeled_standards', title: 'Счетчик неразмеченных эталонов'},
    {name: 'edit_request_status', title: 'Статус корректировки', filter: 'edit_request_status'},
    {name: 'budget.limit', title: 'Лимит оперативного бюджета'},
    {name: 'budget.fact', title: 'Факт'},
    {name: 'budget.plan', title: 'План'},
]

export const Content = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [plans, setPlans] = useState({
        ...localState,
        agg_info: {
            total_purchases: 0,
            total_average_price: 0
        }
    })
    const [meta, setMeta] = useState(localState.meta)

    const {location} = props
    const {schedule} = location.state ?? {}

    const {filter} = useSelector(state => state.filters['planning_purchase_plans'])
    const {account} = useSelector(state => state.account)
    const tableName = 'planning_purchase_plans'

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [settings, setSettings] = useState(null)
    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)
    const [selection, setSelection] = useState([])
    const [selectionItems, setSelectionItems] = useState([])
    const [selectionPage, setSelectionPage] = useState({})
    const [initialize, setInitialize] = useState(false);
    const [hiddenColumnNames, setHiddenColumnNames] = useState(['schedule']);
    const [tableRef, setTableRef] = useState(null)
    const [loading, setLoading] = useState(false)

    const [tableColumnExtensions] = useState([
        {columnName: 'total_purchases', align: 'right'},
        {columnName: 'total_average_price', align: 'right'},
    ]);
    const [totalSummaryItems] = useState([
        {columnName: 'purchases_count', type: 'total_purchases'},
        {columnName: 'average_price_total', type: 'total_average_price'},
    ]);

    const messages = {
        total_purchases: 'Кол-во',
        total_average_price: 'Итого',
    };

    const [columnOrder, setColumnOrder] = useState([
        'unlabeled_standards',
        'planning_year',
        'category',
        'name',
        'status',
        'month',
        'schedule',
        'purchases_count',
        'average_price_total',
        'average_price_approved',
        'average_price_unapproved',
        'railRoad',
        'region',
        'company',
        'edit_request_status',
        'budget.limit',
        'budget.fact',
        'budget.plan',
    ])

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'planning_year', width: 150},
        {columnName: 'category', width: 200},
        {columnName: 'name', width: 200},
        {columnName: 'status', width: 125},
        {columnName: 'month', width: 125},
        {columnName: 'schedule', width: 200},
        {columnName: 'purchases_count', width: 125},
        {columnName: 'average_price_total', width: 155},
        {columnName: 'average_price_approved', width: 125},
        {columnName: 'average_price_unapproved', width: 125},
        {columnName: 'railRoad', width: 125},
        {columnName: 'region', width: 125},
        {columnName: 'company', width: 150},
        {columnName: 'unlabeled_standards', width: 200},
        {columnName: 'edit_request_status', width: 200},
        {columnName: 'budget.limit', width: 200},
        {columnName: 'budget.fact', width: 200},
        {columnName: 'budget.plan', width: 200},
    ])

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...((filter.hasOwnProperty('status') && !!filter.status.length) ? {status: filter.status} : {}),
            ...((filter.hasOwnProperty('categories') && !!filter.categories.length) ? {categories: filter.categories} : {}),
            ...((filter.hasOwnProperty('companies') && !!filter.companies.length) ? {companies: filter.companies} : {}),
            ...((filter.hasOwnProperty('schedules') && !!filter.schedules.length) ? {schedules: filter.schedules} : {}),
            ...((filter.hasOwnProperty('rail_roads') && !!filter.rail_roads.length) ? {rail_roads: filter.rail_roads} : {}),
            ...((filter.hasOwnProperty('year') && !!filter.year.from.length) ? {planning_year_from: filter.year.from} : {}),
            ...((filter.hasOwnProperty('year') && !!filter.year.to.length) ? {planning_year_to: filter.year.to} : {}),
            ...((filter.hasOwnProperty('edit_request_status') && !!filter.edit_request_status.length) ? {edit_request_status: filter.edit_request_status} : {}),
            ...((filter.hasOwnProperty('month') && !!filter.month.length) ? {month: filter.month} : {}),
            ...(searchRequest ? {search: searchRequest} : {})
        }
    }

    const getPlans = async () => {
        let params = {
            limit: rowsPerPage,
            page: page,
            simple: true,
            ...getParams()
        }

        return await dispatch(PurchasePlanActions.plans(params))
    }

    const getTotal = async () => {
        let params = {
            limit: rowsPerPage,
            page: page,
            ...getParams()
        }

        return await dispatch(PurchasePlanActions.total(params))
    }

    const getPlanningSettings = async () => {
        return await dispatch(PlanningSettingsActions.settings())
    }

    useEffect(() => {
        if (!loading && initialize) {
            if (!settings) {
                getPlanningSettings().then(response => setSettings(response))
            }

            getTotal().then(response => {
                setMeta({
                    ...meta,
                    ...response
                })
            })

            getPlans().then(response => {
                setPlans(response)

                let numbers = []

                if (selectionPage.hasOwnProperty(page)) {
                    response.data.forEach((item, index) => {
                        if (selectionPage[page].find(id => (id === item.id))) {
                            numbers.push(index)
                        }
                    })
                }

                setSelection(numbers)

                tableRef && tableRef.current && tableRef.current.scrollIntoView()
                setLoading(true)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, page, rowsPerPage, loading, initialize])

    useEffect(() => {
        setLoading(false)
        // eslint-disable-next-line
    }, [searchRequest, page, filter])

    useEffect(() => {
        if (!initialize) {
            if (schedule) {
                dispatch({type: "PURCHASE_PLAN_FILTER", payload: {schedules: [parseInt(schedule)]}})
            }

            dispatch(SettingsActions.table('planning_purchase_plans')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                dispatch({type: "PLANNING_PURCHASE_PLAN_PURCHASES_FILTER_CLEAR"})
                dispatch({type: "PLANNING_PURCHASE_PLAN_PURCHASES_PRODUCTS_FILTER_CLEAR"})
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'planning_purchase_plans',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    const summaryCalculator = (type, rows, getValue) => {
        switch (type) {
            case 'total_purchases':
                return (parseFloat(plans.data.reduce((sum, plan) => {
                    return sum + plan.purchases_count
                }, 0))).toLocaleString('ru-RU');
            case 'total_average_price':
                return (parseFloat(plans.data.reduce((sum, plan) => {
                    return sum + (isNaN(parseFloat(plan.average_price_total)) ? 0 : parseFloat(plan.average_price_total))
                }, 0))).toLocaleString('ru-RU', {style: 'currency', currency: 'RUB'});
            default:
                return IntegratedSummary.defaultCalculator(type, rows, getValue);
        }
    }

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: "PURCHASE_PLAN_FILTER", payload: {limitRows: newRowsPerPage}})
    }

    const getRowStyle = (row) => {
        let warning = false

        if (row.notification) {
            if (row.notification.chuz_users || row.notification.correction_period || row.notification.editing_after_rejection) {
                warning = true
            } else if (AuthorizationService.roles(account, ['supervisor'])) {
                if (row.notification.rdz_users && account.organization === 'regional') {
                    warning = true
                } else if (row.notification.cdz_users && account.organization === 'central') {
                    warning = true
                }
            }
        }

        return warning ? {backgroundColor: '#d9534f'} : {}
    }

    return initialize ? (
        <Grid item className={classes.content}>
            <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        id="search"
                        variant="standard"
                        name={'search'}
                        placeholder={`Поиск: наименование объекта закупки, номер закупки`}
                        fullWidth={true}
                        margin={'normal'}
                        onChange={(event) => {
                            setSearch(event.target.value)
                        }}
                        classes={{
                            root: clsx(classes.filterLabel, classes.searchLabel)
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon style={{color: '#c1c1c1'}}/>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                            <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                <CorrectButton
                                    items={selectionItems}
                                    onClick={() => {
                                        setSelection([])
                                        setSelectionItems([])
                                        setSelectionPage({})
                                        setLoading(false)
                                    }}
                                />
                                <ApproveButton
                                    items={selectionItems}
                                    settings={settings}
                                    onClick={() => {
                                        setSelection([])
                                        setSelectionItems([])
                                        setSelectionPage({})
                                        setLoading(false)
                                    }}
                                />
                                <DeclineButton
                                    items={selectionItems}
                                    settings={settings}
                                    onClick={() => {
                                        setSelection([])
                                        setSelectionItems([])
                                        setSelectionPage({})
                                        setLoading(false)
                                    }}
                                />
                                <EditButton
                                    disabled={(selectionItems.length !== 1)}
                                    plan={selectionItems[0]}
                                />
                                <CreateButton/>
                                <CopyButton
                                    disabled={(selectionItems.length !== 1)}
                                    plan={selectionItems[0]}
                                    onClick={() => {
                                        setSelection([])
                                        setSelectionItems([])
                                        setSelectionPage({})
                                        setLoading(false)
                                    }}
                                />
                                <DeleteButton
                                    items={selectionItems}
                                    onClick={() => {
                                        setSelection([])
                                        setSelectionItems([])
                                        setSelectionPage({})
                                        setLoading(false)
                                    }}
                                />
                                <Grid item>
                                    <ButtonIcon
                                        type="button"
                                        size="small"
                                        disabled={!meta.total}
                                        onClick={() => {
                                            dispatch(DownloadActions.create({
                                                type: 'planning_plans',
                                                columns: columnOrder.filter(name => (hiddenColumnNames.indexOf(name) < 0)),
                                                params: {
                                                    order: (filter.sort.name && filter.sort.direction) ? `${filter.sort.name}, ${filter.sort.direction}` : `created, desc`,
                                                    type: 'planning_plans',
                                                    ...Object.entries(getParams()).reduce((result, [key, value]) => {
                                                        result[key] = Array.isArray(value) ? value.join(',') : value

                                                        return result
                                                    }, {})
                                                }
                                            }))
                                        }}
                                        children={<GetAppIcon/>}
                                        title='Скачать таблицу'
                                        className={classes.iconButton}
                                    />
                                </Grid>
                                <Grid item>
                                    <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <CustomTable
                            meta={meta}
                            tableName={tableName}
                            rows={plans.data.map(purchasePlan => ({
                                id: purchasePlan.id,
                                planning_year: purchasePlan.planning_year,
                                category: purchasePlan.category?.name,
                                name: purchasePlan.name,
                                status: purchasePlan.status.name,
                                purchases_count: !purchasePlan.purchases_count ? 0 : purchasePlan.purchases_count,
                                month: !purchasePlan.min_demand_start_date ? '-' : getMonthName(new Date(purchasePlan.min_demand_start_date)),
                                schedule: purchasePlan.schedule?.name,
                                average_price_total: parseFloat(!purchasePlan.average_price_total ? 0 : purchasePlan.average_price_total).toLocaleString('ru-RU', {
                                    style: 'currency',
                                    currency: 'RUB'
                                }),
                                average_price_approved: parseFloat(!purchasePlan.average_price_approved ? 0 : purchasePlan.average_price_approved).toLocaleString('ru-RU', {
                                    style: 'currency',
                                    currency: 'RUB'
                                }),
                                average_price_unapproved: parseFloat(!purchasePlan.average_price_unapproved ? 0 : purchasePlan.average_price_unapproved).toLocaleString('ru-RU', {
                                    style: 'currency',
                                    currency: 'RUB'
                                }),
                                zero: purchasePlan.zero,
                                railRoad: purchasePlan.company?.railRoad?.name,
                                region: purchasePlan.company?.region?.name,
                                company: purchasePlan.company?.name,
                                notification: purchasePlan.notification,
                                unlabeled_standards: purchasePlan.unlabeled_standards,
                                edit_request_status: purchasePlan.editRequestStatus?.name,
                                edit_request_status_index: purchasePlan.editRequestStatus?.index,
                                'budget.limit': parseFloat(purchasePlan.schedule?.budget?.limit ?? 0).toLocaleString('ru-RU', {
                                        style: 'currency',
                                        currency: 'RUB'
                                    }),
                                'budget.fact': parseFloat(purchasePlan.schedule?.budget?.fact ?? 0).toLocaleString('ru-RU', {
                                        style: 'currency',
                                        currency: 'RUB'
                                    }),
                                'budget.plan': parseFloat(!purchasePlan.schedule ? 0 : !purchasePlan.average_price_total ? 0 : purchasePlan.average_price_total).toLocaleString('ru-RU', {
                                    style: 'currency',
                                    currency: 'RUB'
                                }),
                            }))}
                            columns={columns}
                            page={{
                                page: page,
                                setPage: setPage,
                                rowsPerPage: rowsPerPage,
                                handlePageSizeChange: handlePageSizeChange
                            }}
                            selection={{selection: selection, setSelection: setSelection}}
                            totalSummaryItems={totalSummaryItems}
                            summaryCalculator={summaryCalculator}
                            setTableRef={setTableRef}
                            tableColumnExtensions={tableColumnExtensions}
                            columnsSettings={{
                                columnOrder: columnOrder,
                                setColumnOrder: setColumnOrder,
                                setColumnWidths: setColumnWidths,
                                columnWidths: columnWidths,
                                hiddenColumnNames: hiddenColumnNames,
                                setHiddenColumnNames: setHiddenColumnNames
                            }}
                            tableHeader={TableHeader}
                            filterActionType={'PURCHASE_PLAN_FILTER'}
                            getValues={PurchasePlanActions.purchaseFilter}
                            messages={messages}
                            onSelectionChange={(numbers) => {
                                setSelection(numbers)
                                const data = plans.data.filter((item, index) => (numbers.find(key => (key === index)) !== undefined)).map(item => item)
                                setSelectionItems([
                                    ...data,
                                    ...selectionItems.filter(item => !plans.data.find(el => el.id === item.id))
                                ])
                                setSelectionPage(!!data.length ? {...selectionPage, [page]: data.map(item => item.id)} : Object.fromEntries(
                                    Object.entries(selectionPage).filter(key => (parseInt(key) !== parseInt(page))).map(([key, value]) => [key, value])
                                ))
                            }}
                            classInputContent={classes.inputContentMain}
                            main
                            rowComponentSelection={(props) => {
                                const {tableRow, children, highlighted, onToggle} = props;
                                const {row} = tableRow;

                                let timer = null;
                                let prevent = false;

                                const handleClick = () => {
                                    timer = setTimeout(() => {
                                        if (!prevent) {
                                            if (!window.getSelection().toString()) {
                                                onToggle()
                                            }
                                        }
                                        prevent = false;
                                    }, 250)
                                }

                                const handleDoubleClick = () => {
                                    if (!row.zero || (row.zero && [status.CORRECTION_APPROVED, status.CORRECTION_REJECTED].includes(row.edit_request_status_index))) {
                                        clearTimeout(timer);
                                        prevent = true;
                                        history.push(`/planning/purchase-plan/${row.id}`);
                                    }
                                }

                                return (
                                    <Table.Row
                                        tableRow={tableRow}
                                        children={children}
                                        onClick={handleClick}
                                        onDoubleClick={handleDoubleClick}
                                        style={getRowStyle(row)}
                                        className={highlighted ? classes.active : ((row.loaded === 'Загружен') ? classes.default : classes.loading)}
                                        row={row}
                                    />
                                )
                            }}
                            rowComponent={({row, tableRow, children}) => (
                                <Table.Row
                                    tableRow={tableRow}
                                    children={children}
                                    className={classes.default}
                                    row={row}
                                    color="black"
                                />
                            )}
                            filters={{
                                status: {
                                    name: 'status',
                                    type: 'values',
                                },
                                planning_year: {
                                    name: 'year',
                                    type: 'year',
                                },
                                category: {
                                    name: 'categories',
                                    type: 'values',
                                },
                                schedule: {
                                    name: 'schedules',
                                    type: 'values',
                                },
                                railRoad: {
                                    name: 'rail_roads',
                                    type: 'values',
                                },
                                region: {
                                    name: 'regions',
                                    type: 'values',
                                },
                                company: {
                                    name: 'companies',
                                    type: 'values',
                                },
                                month: {
                                    name: 'month',
                                    type: 'month',
                                },
                                edit_request_status: {
                                    name: 'edit_request_status',
                                    type: 'values',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    ) : null
}
