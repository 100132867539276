import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce, useDebouncedCallback} from 'use-debounce'
import format from "date-fns/format"
import {ru} from "date-fns/locale"
import clsx from "clsx"

import {
    Grid,
    InputAdornment, Link,
    makeStyles,
    TextField, Typography
} from "@material-ui/core"
import {Search as SearchIcon} from '@material-ui/icons'

import {AuthorizationService} from "../../../Auth/services/authorization"
import {Submit} from "../../../App/components/Button/Submit"
import {ReclamationActions} from "../../actions/reclamation"
import {Create} from "../Create"
import {Reclamation} from "../Reclamation"
import {CustomTable} from "../../../App/components/Table/Table";
import {TableHeader} from "../../../App/components/Table/TableHeader";
import {ColumnChooser} from "../../../App/components/Table/ColumnChooser";
import {Information} from "../../../Company/components/Information";
import {Table} from "@devexpress/dx-react-grid-material-ui";
import {localState} from "../../../App/components/LocalState";

const useStyles = makeStyles(theme => ({
    content: {
        width: '100%',
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 168px)"
    },
    input: {
        "width": "100%",
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    tableRowAlert: {
        "cursor": "pointer",
        "background-color": "#f9f1f0",
        "text-decoration": "none"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 223px)",
        "min-height": "calc(100% - 223px)",
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    searchLabel: {
       // "margin-bottom": "15px",
    },
    searchInput: {
        "width": "calc(100% - 83px)"
    },
    searchButton: {
        "margin-bottom": "10px"
    },
    amount: {
        "min-width": "100px"
    },
    tableCell: {
        maxWidth: "200px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
}))

export const Content = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {account} = useSelector(state => {return {...state.account}})

    const [reclamations, setReclamations] = useState(localState)

    const {filter} = useSelector(state => state.filters['reclamations'])
    const [search, setSearch] = useState(filter.search)
    const debounced = useDebouncedCallback(
        (value) => {
            dispatch({
                type: "REСLAMATIONS_FILTER",
                payload: {search: value}
            })
        },
        900
    );

    const [card, setCard] = useState(null)

    const [loading, setLoading] = useState(false)
    const [dialog, setDialog] = useState(false)
    const [edit, setEdit] = useState(null)
    const [id, setId] = useState(null)
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [tableRef, setTableRef] = useState(null)

    const [columns, setColumns] = useState([
        {name: 'id', title: '№'},
        {name: 'created', title: 'Дата создания', filter: 'created'},
        {name: 'company', title: 'Поставщик', filter: 'companies'},
        {name: 'number', title: '№ заказа'},
        {name: 'name', title: 'Наименование товара'},
        {name: 'contract', title: '№ договора'},
        {name: 'status', title: 'Статус', filter: 'status'},
    ])

    useEffect(() => {
        debounced.callback(search)
        // eslint-disable-next-line
    }, [search])

    useEffect(() => {
        if (AuthorizationService.roles(account, 'supplier')) {
            setColumns(columns.map(column => {
                if (column.name === 'company') {
                    column.title = 'Заказчик'
                }

                return column;
            }))
        }
        // eslint-disable-next-line
    }, [account]);

    const [columnOrder, setColumnOrder] = useState([
        'id',
        'created',
        'company',
        'number',
        'name',
        'contract',
        'status'
    ])

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'id', width: 100},
        {columnName: 'company', width: 250},
        {columnName: 'number', width: 250},
        {columnName: 'status', width: 250},
        {columnName: 'name', width: 300},
        {columnName: 'contract', width: 250},
        {columnName: 'created', width: 250}
    ])

    useEffect(() => {
        setLoading(false)
    }, [page, rowsPerPage]);

    useEffect(() => {
        if (!dialog) {
            setLoading(false)
            setEdit(null)
        }
    }, [dialog]);

    useEffect(() => {
        if (!id) {
            setLoading(false)
        }
    }, [id]);

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...((filter.hasOwnProperty('status') && !!filter.status.length) ? {status: filter.status} : {}),
            ...((filter.hasOwnProperty('companies') && !!filter.companies.length) ? {companies: filter.companies} : {}),
            ...((filter.hasOwnProperty('created') && !!filter.created.from.length) ? {created_from: format(new Date(filter.created.from), 'yyyy-MM-dd', true)} : {}),
            ...((filter.hasOwnProperty('created') && !!filter.created.to.length)  ? {created_to: format(new Date(filter.created.to), 'yyyy-MM-dd', true)} : {}),
            ...(search ? {search: search} : {})
        }
    }

    useEffect(() => {
        const getReclamations = async () => {
            dispatch({type: "CONTRACT_COMPANIES", payload: {data: [], meta: {total: 0, per_page: 15, current_page: 1}}})
            return await dispatch(ReclamationActions.reclamations({
                limit: rowsPerPage,
                page: page,
               ...getParams()
            }))
        }

        getReclamations().then((response) => {
            setReclamations(response)
            tableRef && tableRef.current && tableRef.current.scrollIntoView()
            //setLoading(true)
        })
        // eslint-disable-next-line
    }, [dispatch, loading, page, rowsPerPage, filter]);

    useEffect(() => {
        setLoading(false)
    }, [filter]);

    useEffect(() => {
        if (edit) {
            setDialog(true)
        }
    }, [edit]);

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: "REСLAMATIONS_FILTER", payload: {limitRows: newRowsPerPage}})
    }

    return <Grid item className={classes.content}>
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12}>
                <TextField
                    id="search"
                    variant="standard"
                    name={'search'}
                    value={search ?? ''}
                    placeholder={'Поиск: номер заказа, номер рекламации, артикул, наименование товара'}
                    fullWidth={true}
                    margin={'normal'}
                    onChange={(event) => {
                        setSearch(event.target.value)
                    }}
                    classes={{
                        root: clsx(classes.filterLabel, classes.searchLabel)
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon style={{color: '#c1c1c1'}}/>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
        </Grid>
        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
            <Grid item xs={12}>
                <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                            <Grid item>
                            {AuthorizationService.roles(account, ["customer"]) &&
                                <Submit
                                    disableElevation
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    onClick={() => {
                                        setDialog(true)
                                    }}
                                >
                                    Создать
                                </Submit>
                            }
                            </Grid>
                            <Grid item>
                                <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <CustomTable
                        meta={reclamations.meta}
                        tableName={'reclamations'}
                        rows={reclamations.data?.map((reclamation) => ({
                            id: reclamation.id,
                            created: format(new Date(reclamation.created), 'PP', {locale: ru}),
                            company: <Typography noWrap>
                                <Link
                                    onClick={e => {
                                        e.stopPropagation()
                                        setCard(AuthorizationService.roles(account, 'supplier') ? reclamation.delivery.contract.company?.id : reclamation.delivery.contract.offer.company?.id)
                                    }}
                                    className={classes.link}
                                >
                                    {AuthorizationService.roles(account, 'supplier') ? reclamation.delivery.contract.company.legal_detail.name : reclamation.delivery.contract.offer.company.legal_detail.name}
                                </Link>
                            </Typography>,
                            number: reclamation.delivery.number,
                            name: reclamation.item.item.name,
                            contract: reclamation.delivery.contract.number ?? reclamation.delivery.contract.id,
                            status: reclamation.status.name,
                        }))}
                        columns={columns}
                        page={{
                            page: page,
                            setPage: setPage,
                            rowsPerPage: rowsPerPage,
                            handlePageSizeChange: handlePageSizeChange
                        }}
                        setTableRef={setTableRef}
                        columnsSettings={{
                            columnOrder: columnOrder,
                            setColumnOrder: setColumnOrder,
                            setColumnWidths: setColumnWidths,
                            columnWidths: columnWidths,
                            hiddenColumnNames: hiddenColumnNames,
                            setHiddenColumnNames: setHiddenColumnNames
                        }}
                        tableHeader={TableHeader}
                        filterActionType={"REСLAMATIONS_FILTER"}
                        classInputContent={classes.inputContent}
                        rowComponent={({row, tableRow, children}) => (
                            <Table.Row
                                tableRow={tableRow}
                                key={row.id}
                                hover
                                children={children}
                                className={classes.default}
                                row={row}
                                onClick={() => {
                                    setId(row.id)
                                }}
                                style={{'cursor': 'pointer'}}
                            />
                        )}
                        getValues={ReclamationActions.filter}
                        filters={{
                            status: {
                                name: 'status',
                                type: 'values',
                            },
                            created: {
                                name: 'created',
                                type: 'year',
                            },
                            company: {
                                name: 'companies',
                                type: 'values',
                            },
                        }}
                    />
                    <Create open={dialog} setDialog={setDialog} reclamation={edit}/>
                    <Reclamation open={id} setDialog={setId} setEdit={setEdit}/>
                    {!!card && <Information id={card} open={!!card} onClose={() => setCard(null)}/>}
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}
