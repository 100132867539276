import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce} from 'use-debounce'
import clsx from "clsx"
import format from "date-fns/format"
import {IntegratedSummary} from '@devexpress/dx-react-grid'
import {Table} from "@devexpress/dx-react-grid-material-ui";

import {
    Grid,
    InputAdornment,
    makeStyles,
    Tab,
    Tabs,
    TextField,
    Tooltip,
} from "@material-ui/core"
import {
    CommentOutlined as CommentOutlinedIcon,
    Error as ErrorIcon,
    GetApp as GetAppIcon,
    Search as SearchIcon
} from "@material-ui/icons"
import {green} from "@material-ui/core/colors"

import {ColumnChooser} from "../../../../App/components/Table/ColumnChooser"
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon"
import {PurchasePlanActions} from "../../../actions/purchasePlan"
import {Submit} from "../../../../App/components/Button/Submit"
import {AuthorizationService} from "../../../../Auth/services/authorization"
import {getMonthName} from "../../../../App/helpers/date"
import {ButtonSelectWithAutocomplete} from '../../../../App/components/ButtonSelect/ButtonSelectWithAutocomplete'
import {PurchasePlanItemsActions} from "../../../actions/purchasePlanItems"
import {SystemActions} from "../../../../App/actions/system"
import {ChatActions} from '../../../actions/comment/chat';
import {PlanningActions} from "../../../actions/planning"
import {SettingsActions} from "../../../../Settings/actions/settings"
import {StandardForm} from "../../../../Standard/StandardForm"
import {DownloadActions} from "../../../../Download/actions/download";
import {CustomTable} from "../../../../App/components/Table/Table";
import {TableHeader} from "../../../../App/components/Table/TableHeader";
import {plan as rules} from "../../../helpers/permissions/rules";
import {Upload as UploadProductButton} from "../../Products/Buttons/Upload";
import {Copy as CopyProductButton} from "../../Products/Buttons/Copy";
import {Move as MoveProductButton} from "../../Products/Buttons/Move";
import {Create as CreateProductButton} from "../../Products/Buttons/Create";
import {Delete as DeleteProductButton} from "../../Products/Buttons/Delete";
import {Edit as EditProductButton} from "../../Products/Buttons/Edit";
import {localState} from "../../../../App/components/LocalState";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.planningContent.width,
        padding: theme.planningContent.padding,
        margin: theme.planningContent.margin,
        position: 'relative',
    },
    container: {
        "background-image": "linear-gradient(to bottom,#f6f6f6,#eee 85%,#b1b1b1)",
        "width": "100%",
        "height": "calc(100vh - 256px)"
    },
    title: {
        "font-size": "19px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    filterLabel: {
        "background": "#fff",
        "margin-bottom": "0 !important",
        "margin-top": "5px !important",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        },
        "& input": {
            padding: "6px 15px 7px"
        }
    },
    tableContainer: {
        "min-height": "100%",
        "height": "calc(100vh - 400px)"
    },
    tableItemsContainer: {},
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    searchLabel: {},
    searchInput: {
        "width": "calc(100% - 83px)"
    },
    searchButton: {
        "margin-bottom": "10px"
    },
    fullWidth: {
        "width": "100%"
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 414px)",
        "min-height": "calc(100vh - 414px)",
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    inputContentProducts: {
        "width": "100%",
        "height": "calc(100vh - 424px)",
        "min-height": "calc(100vh - 424px)",
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    footer: {
        width: "100%",
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    },
    tabs: {
        "&.MuiTab-textColorPrimary.Mui-selected": {
            backgroundColor: "#fff",
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "4px",
        }
    },
    fontSubtitle: {
        fontWeight: 700
    },
    submit: {
        "height": "35px",
    },
    accordion: {
        "& .MuiAccordionSummary-content": {
            "max-width": "calc(100% - 32px)"
        }
    },
    acDetails: {
        padding: "16px 0"
    },
    active: {
        'height': '41px',
        'background-color': `${green[100]} !important`,
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        fontSize: '16px',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    disabled: {
        'height': '41px',
        'background-color': 'rgba(244, 244, 244, 1)',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    iconButton: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    },
    backIconButton: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        position: 'absolute',
        left: '-8px',
        top: '-15px',
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    },
    svg: {
        "fill": "#d9534f",
        "width": "auto",
        "height": "31px",
        "stroke": "#fff",
        "fill-rule": "evenodd",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-miterlimit": "2",
        "stroke-width": "0px",
    },
    tooltipError: {
        "font-size": "16px",
        "background": "#d9534f",
    },
    badge: {
        top: "-5px",
        left: "2px"
    },
    titleDialog: {
        "&>h2": {
            color: "#fff"
        },
    },
    circle: {
        width: "25px",
        height: "25px",
        backgroundColor: "#ea4a4a",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        "&>span": {
            lineHeight: "20px"
        }
    },
    lightTooltip: {
        "font-size": "0.8rem",
        "backgroundColor": "white",
        "border": "1px solid #ededed",
        "color": "rgba(0, 0, 0, 0.87)",
    },
    header: {
        height: '116px',
        maxHeight: '116px',
        overflow: "hidden"
    }
}))

const columns = [
    {name: 'comment_counter', title: ' '},
    {name: 'number', title: 'Номер ОЗ'},
    {name: 'name', title: 'Начальное наименование'},
    {name: 'standard_name', title: 'Эталонное наименование'},
    {name: 'typed_name', title: 'Типизированное наименование'},
    {name: 'category_name', title: 'Категория', filter: 'categories'},
    {name: 'group', title: 'Группа', filter: 'groups'},
    {name: 'subcategory', title: 'Подкатегория', filter: 'subcategories'},
    {name: 'unit', title: 'Ед. измерения'},
    {name: 'funding_sources', title: 'Источник финансирования'},
    {name: 'generic', title: 'МНН'},
    {name: 'purchase_number', title: 'Закупка'},
    {name: 'lot_number', title: 'Лот'},
    {name: 'lot_name', title: 'Наименование лота'},
    {name: 'price', title: 'Цена'},
    {name: 'month', title: 'Месяц', filter: 'month'},
    {name: 'amount', title: 'Кол-во'},
    {name: 'total', title: 'Сумма'},
    {name: 'railRoad', title: 'ЖД', filter: 'rail_roads'},
    {name: 'company', title: 'ЧУЗ', filter: 'companies'},
    {name: 'creator', title: 'Cоздатель', filter: 'creator'},
    {name: 'created_at', title: 'Дата создания', filter: 'created_at'},
    {name: 'editor', title: 'Редактор'},
    {name: 'updated_at', title: 'Дата редактироания'},
    {name: 'main_technical_specifications', title: 'Технические характеристики'},
    {name: 'analogues', title: 'Аналоги'},
    {name: 'equipment', title: 'Оборудование'},
    {name: 'medical_code', title: 'Код вида мед. изделия'},
    {name: 'exclusivity', title: 'Обоснование'},
    {name: 'description', title: 'Описание'}
]

export const Products = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { plan, setChat, setOpenComment } = props

    const {account, price} = useSelector(state => ({...state.account, ...state.purchases}))
    const tableName = 'planning_purchase_plan_purchases_products';
    const {filter} = useSelector(state => ({...state.filters[tableName]}))
    const [products, setProducts] = useState({
        ...localState,
        agg_info: {
            total_amount: 0,
            total_sum: 0,
        }
    })
    const [meta, setMeta] = useState(localState.meta)
    const {categories} = useSelector(state => state.system)
    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
    const [tab, setTab] = useState(0);

    const [initialize, setInitialize] = useState(false)

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)

    const [tableColumnExtensions] = useState([
        {columnName: 'amount', align: 'right'},
        {columnName: 'total', align: 'right'},
    ]);
    const [totalSummaryItems] = useState([
        {columnName: 'amount', type: 'total_amount'},
        {columnName: 'total', type: 'total_sum'},
    ]);

    const messages = {
        total_amount: 'Кол-во',
        total_sum: 'Итого',
    };

    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)

    const [value, setValue] = useState(0);

    const [selection, setSelection] = useState([])
    const [selectionItems, setSelectionItems] = useState([])
    const [selectionPage, setSelectionPage] = useState({})

    const [tableRef, setTableRef] = useState(null)
    const [loading, setLoading] = useState(false)

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'comment_counter', width: 50},
        {columnName: 'number', width: 150},
        {columnName: 'name', width: 150},
        {columnName: 'standard_name', width: 150},
        {columnName: 'typed_name', width: 150},
        {columnName: 'category_name', width: 200},
        {columnName: 'subcategory', width: 200},
        {columnName: 'group', width: 200},
        {columnName: 'unit', width: 125},
        {columnName: 'funding_sources', width: 200},
        {columnName: 'generic', width: 125},
        {columnName: 'purchase_number', width: 125},
        {columnName: 'lot_number', width: 125},
        {columnName: 'lot_name', width: 150},
        {columnName: 'price', width: 125},
        {columnName: 'month', width: 125},
        {columnName: 'amount', width: 150},
        {columnName: 'total', width: 150},
        {columnName: 'railRoad', width: 125},
        {columnName: 'company', width: 150},
        {columnName: 'creator', width: 125},
        {columnName: 'created_at', width: 125},
        {columnName: 'editor', width: 125},
        {columnName: 'updated_at', width: 150},
        {columnName: 'main_technical_specifications', width: 150},
        {columnName: 'analogues', width: 150},
        {columnName: 'equipment', width: 150},
        {columnName: 'medical_code', width: 150},
        {columnName: 'exclusivity', width: 150},
        {columnName: 'description', width: 150}
    ])

    const [columnOrder, setColumnOrder] = useState([
        'comment_counter',
        'number',
        'name',
        'standard_name',
        'typed_name',
        'category_name',
        'subcategory',
        'group',
        'unit',
        'funding_sources',
        'generic',
        'purchase_number',
        'lot_number',
        'lot_name',
        'price',
        'month',
        'amount',
        'total',
        'railRoad',
        'company',
        'creator',
        'created_at',
        'editor',
        'updated_at',
        'main_technical_specifications',
        'analogues',
        'equipment',
        'medical_code',
        'exclusivity',
        'description'
    ])

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...((filter.hasOwnProperty('companies') && !!filter.companies.length) ? {companies: filter.companies} : {}),
            ...((filter.hasOwnProperty('rail_roads') && !!filter.rail_roads.length) ? {rail_roads: filter.rail_roads} : {}),
            ...((filter.hasOwnProperty('categories') && !!filter.categories.length) ? {categories: filter.categories} : {}),
            ...((filter.hasOwnProperty('subcategories') && !!filter.subcategories.length) ? {subcategories: filter.subcategories} : {}),
            ...((filter.hasOwnProperty('groups') && !!filter.groups.length) ? {groups: filter.groups} : {}),
            ...((filter.hasOwnProperty('month') && !!filter.month.length) ? {month: filter.month} : {}),
            ...((filter.hasOwnProperty('year') && !!filter.year.length) ? {year: filter.year} : {}),
            ...((filter.hasOwnProperty('edit_request_status') && !!filter.edit_request_status.length) ? {edit_request_status: filter.edit_request_status} : {}),
            ...((filter.hasOwnProperty('sources') && !!filter.sources.length) ? {sources: filter.sources} : {}),
            ...((filter.hasOwnProperty('purchase') && !!filter.purchase.length) ? {purchase: filter.purchase} : {}),
            ...(searchRequest ? {search: searchRequest} : {}),
            filter: ['all', 'with_errors', 'without_standard', 'all_without_standard'][tab]
        }
    }

    const getProducts = async () => {
        let params = {
            limit: rowsPerPage,
            page: page,
            simple: true,
            ...getParams()
        }
        return await dispatch(PurchasePlanItemsActions.itemsByPurchasePlanID(plan.id, params))
    }

    useEffect(() => {
        if (!loading) {
            const getTotal = async () => {
                let params = {
                    limit: rowsPerPage,
                    page: page,
                    simple: true,
                    ...getParams()
                }
                return await dispatch(PurchasePlanItemsActions.totalByPurchasePlanID(plan.id, params))
            }

            getTotal().then(response => {
                setMeta({
                    ...meta,
                    ...response
                })
            })

            getProducts().then(response => {
                setProducts(response)

                let numbers = []

                if (selectionPage.hasOwnProperty(page)) {
                    response.data.forEach((item, index) => {
                        if (selectionPage[page].find(id => (id === item.id))) {
                            numbers.push(index)
                        }
                    })
                }

                setSelection(numbers)

                tableRef && tableRef.current && tableRef.current.scrollIntoView()
                setLoading(true)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loading])

    useEffect(() => {
        if (loading) {
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [value,  page, rowsPerPage, filter, searchRequest, tab])

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const summaryCalculator = (type, rows, getValue) => {
        switch (type) {
            case 'total_amount':
                return (products.agg_info.total_amount ? parseFloat(products.agg_info.total_amount) : 0).toLocaleString('ru-RU');
            case 'total_sum':
                return (products.agg_info.total_sum ? parseFloat(products.agg_info.total_sum) : 0).toLocaleString('ru-RU', {
                    style: 'currency',
                    currency: 'RUB'
                });
            default:
                return IntegratedSummary.defaultCalculator(type, rows, getValue);
        }
    };

    useEffect(() => {
        const getCategories = async () => {
            return await dispatch(SystemActions.categories())
        }

        if (!categories.length) {
            getCategories().then(() => {})
        }
    }, [dispatch, categories.length]);

    const createChat = async (values) => {
        return await dispatch(ChatActions.create(values))
    }

    const addUserToChat = async (chatId) => {
        return await dispatch(ChatActions.addUserToChat(chatId))
    }

    const [openStandardForm, setOpenStandardForm] = useState(false)

    const cancelValue = () => {}

    const change = (name, value, productId) => {
        if (value === 'button') {
            setOpenStandardForm(productId)
        }
    }

    const closeStandardForm = () => {
        setOpenStandardForm(false)
        setLoading(false)
    }

    useEffect(() => {
        const getSettings = async () => {
            await dispatch(SettingsActions.table(tableName)).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                setInitialize(true)
            })
        }

        if (!initialize) {
            getSettings().then(() =>
                setInitialize(true)
            )
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: tableName,
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: tableName,
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    const getStandardName = (product) => {
        return (
            !product.category?.active ||
            !rules.edit.hasOwnProperty(account.group) ||
            function () {
                if (rules.edit[account.group].hasOwnProperty(plan.status.index)) {
                    if (rules.edit[account.group][plan.status.index].length) {
                        return !rules.edit[account.group][plan.status.index].includes(plan.editRequestStatus?.index)
                    }

                    return false
                }

                return true
            }()
        )
            ? product.standard?.name?.standard
            : <ButtonSelectWithAutocomplete
                change={change}
                search={true}
                onUpdate={(id, name, item) => {
                    if (item && (item.id === 'button')) {
                        return;
                    }

                    const params = !item ? {
                        category_id: null,
                        name: null,
                        typed: null,
                        standard_id: null,
                        confirmed: null,
                        generic: null
                    } : {
                        category_id: item.category.id,
                        name: item.name,
                        typed: item.assembly.find(value => value.type.key === 'typed')?.value,
                        standard_id: item.id,
                        confirmed: item.confirmed,
                        generic: (item.hasOwnProperty('generic') ? (item.generic ?? null) : (item.values.find(value => {
                            return value.attribute.value === 'generics'
                        })?.value ?? null))
                    }

                    return dispatch(PurchasePlanItemsActions.updateStandard(id, params)).then(() => {
                        setLoading(false)
                    })
                }}
                onCancel={cancelValue}
                propId={product.id}
                key={product.name}
                category={product.category}
                name={'product'}
                value={product.standard ? {
                    id: product.standard.id,
                    name: product.standard.name.standard,
                    typed: product.standard.name?.typed,
                    category: product.category,
                    confirmed: product.standard.confirmed
                } : null}
                style={{maxWidth: "300px"}}
                items={product.suggested_names ? product.suggested_names.filter(el => el.id).map(function (el) {
                    return {
                        id: el.id, name: el.name, category: el.category, confirmed: el.confirmed, assembly: el.assembly, generic: el.generic, values: el.values
                    }
                }) : []}
            />

    }

    const getItemTooltip = (product) => {
        return (
            <Tooltip
                placement="bottom-start"
                classes={{tooltip: classes.lightTooltip}}
                title={product.map(item => <div>{item}</div>)}
            >
                <span>{product.join(', ')}</span>
            </Tooltip>
        )
    }

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: 'PLANNING_PURCHASE_PLAN_PURCHASES_PRODUCTS_FILTER', payload: {limitRows: newRowsPerPage}})
    }

    return (initialize && plan) ? (
        <React.Fragment>
            <StandardForm
                open={openStandardForm}
                module={'Планирование'}
                category={categories.find(el => el.id === plan.category.id)}
                edit={false}
                onCLose={closeStandardForm}
                setOpen={setOpenStandardForm}
                onAddStandard={(item) => {

                    let params = {
                        category_id: item.category.id,
                        name: item.name,
                        standard_id: item.id,
                        generic: item.hasOwnProperty('generic') && item.generic ? item.generic : null,
                        confirmed: item.confirmed
                    }

                    return dispatch(PurchasePlanItemsActions.updateStandard(openStandardForm, params)).then(
                        () => {
                            setOpenStandardForm(false)
                            setLoading(false)
                        }
                    )
                }}
            />
            <Grid item xs={12}>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                            <Grid item xs={4}>
                                <Tabs
                                    value={tab}
                                    indicatorColor="primary"
                                    className={classes.tabs}
                                    textColor="primary"
                                    onChange={handleChange}
                                >
                                    <Tab value={0} label="Все"/>
                                    <Tab value={1} label="Ошибки"/>
                                    {plan.category.active ? <Tab value={2} label="Не сопоставлено"/> : null}
                                </Tabs>
                            </Grid>
                            <Grid item xs={8}>
                                <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                    <UploadProductButton
                                        plan={plan}
                                        price={price}
                                        onClick={() => {
                                            const getPurchasePlan = async () => {
                                                await dispatch(PurchasePlanActions.plan(plan.id))
                                            }

                                            getPurchasePlan().then(() => {})
                                        }}
                                    />
                                    <CreateProductButton
                                        plan={plan}
                                    />
                                    <EditProductButton
                                        disabled={(selectionItems.length !== 1)}
                                        plan={plan}
                                        item={selectionItems[0]}
                                    />
                                    <Grid item>
                                        <Submit
                                            className={classes.submit}
                                            disableElevation
                                            disabled={Boolean(selectionItems.length !== 1)}
                                            variant="contained"
                                            color="primary"
                                            type="button"
                                            onClick={() => {
                                                const product = selectionItems[0];
                                                if (product.chat_id) {
                                                    let chat_id = product.chat_id
                                                    addUserToChat(chat_id).then(() => {
                                                        setChat({
                                                            id: chat_id,
                                                            title: `Комментарии к ОЗ ${product.name}`
                                                        })
                                                        setOpenComment(true)
                                                    })
                                                } else {
                                                    createChat({itemId: product.id}).then(resp => {
                                                        setChat({
                                                            id: resp.chat.id,
                                                            title: `Комментарии к ОЗ ${product.name}`
                                                        })
                                                        setOpenComment(true)
                                                    })
                                                }
                                            }}
                                        >
                                            Комментарии
                                        </Submit>
                                    </Grid>
                                    <CopyProductButton
                                        items={selectionItems}
                                        onClick={() => {
                                            setSelection([])
                                            setSelectionItems([])
                                            setSelectionPage({})
                                            setLoading(false)
                                            dispatch({type: 'PURCHASE_PLAN_ITEMS_BY_PARENT_IDS_CLEAR'})
                                        }}
                                    />
                                    <MoveProductButton
                                        items={selectionItems}
                                        onClick={() => {
                                            setSelection([])
                                            setSelectionItems([])
                                            setSelectionPage({})
                                            setLoading(false)
                                            dispatch({type: 'PURCHASE_PLAN_ITEMS_BY_PARENT_IDS_CLEAR'})
                                        }}
                                    />
                                    <DeleteProductButton
                                        items={selectionItems}
                                        onClick={() => {
                                            setSelection([])
                                            setSelectionItems([])
                                            setSelectionPage({})
                                            setLoading(false)
                                        }}
                                    />
                                    <Grid item>
                                        <ButtonIcon
                                            type="button"
                                            size="small"
                                            disabled={!meta.total}
                                            onClick={() => {
                                                dispatch(DownloadActions.create({
                                                    type: 'planning_plan_items',
                                                    columns: columnOrder.filter(name => (hiddenColumnNames.indexOf(name) < 0)).map(name => {
                                                        switch (name) {
                                                            case 'category_name':
                                                                return 'category';
                                                            default:
                                                                return name;
                                                        }
                                                    }),
                                                    params: {
                                                        order: (filter.sort.name && filter.sort.direction) ? `${filter.sort.name}, ${filter.sort.direction}` : `created, desc`,
                                                        type: 'planning_plan_items',
                                                        'purchase_plan_id': plan.id,
                                                        ...Object.entries(getProducts()).reduce((result, [key, value]) => {
                                                            result[key] = Array.isArray(value) ? value.join(',') : value

                                                            return result
                                                        }, {})
                                                    }
                                                }))
                                            }}
                                            children={<GetAppIcon/>}
                                            title='Скачать таблицу'
                                            className={classes.iconButton}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <ColumnChooser
                                            columns={columns}
                                            hiddenColumnNames={hiddenColumnNames}
                                            setHiddenColumnNames={setHiddenColumnNames}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="searchProducts"
                            variant="standard"
                            name={'searchProducts'}
                            placeholder={`Поиск: наименование объекта закупки, начальное наименование, номер закупки, МНН`}
                            fullWidth={true}
                            margin={'normal'}
                            onChange={(event) => {
                                setSearch(event.target.value)
                            }}
                            classes={{
                                root: clsx(classes.filterLabel, classes.searchLabel)
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon style={{color: '#c1c1c1'}}/>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <CustomTable
                meta={meta}
                tableName={'planning_purchase_plan_purchases_products'}
                rows={products.data.map(product => {
                    let rows = {
                        number: product.number,
                        name: product.name,
                        standard: product.standard,
                        standard_name: getStandardName(product),
                        typed_name: product.standard?.confirmed ? product.standard?.name?.typed : null,
                        category_name: product.category?.dependencies[0],
                        subcategory: product.category?.dependencies[1],
                        group: product.category?.dependencies[2],
                        category: product.category,
                        unit: product.unit?.short,
                        funding_sources: product.funding_sources ? product.funding_sources.join(', ') : '',
                        purchase_number: product.purchase_number,
                        lot_number: product.lot_number,
                        lot_name: product.lot_name,
                        price: parseFloat(product.price).toLocaleString('ru-RU', {
                            style: 'currency',
                            currency: 'RUB'
                        }),
                        generic: product.standard?.params?.generic,
                        month: getMonthName(new Date(product.demand_start_date)),
                        amount: parseFloat((!product.amount || product.joint) ? 0 : product.amount).toLocaleString('ru-RU'),
                        total: parseFloat(product.price * product.amount).toLocaleString('ru-RU', {
                            style: 'currency',
                            currency: 'RUB'
                        }),
                        railRoad: product.railRoad,
                        company: product.company,
                        created_at: format(new Date(product.created_at), 'dd.MM.yyyy'),
                        creator: product.creator,
                        updated_at: product.editor ? format(new Date(product.updated_at), 'dd.MM.yyyy') : null,
                        editor: product.editor,
                        item_id: product.item_id,
                        joint: product.joint,
                        count_unread_messages: product.count_unread_messages,
                        medical_code: product.medical_code,
                        exclusivity: product.exclusivity,
                        description: product.description,
                        equipment: product.equipment,
                        chat_id: product.chat_id,
                        suggested_names: product.suggested_names,
                        main_technical_specifications: product.main_technical_specifications ? getItemTooltip(product.main_technical_specifications) : '',
                        analogues: product.analogues ? getItemTooltip(product.analogues) : ''
                    }
                    if ((product.error_messages && product.error_messages.length > 0) || product.price < 0 || product.funding_sources.length <= 0) {
                        if (product.error_messages && product.error_messages.length > 0) {
                            product.error_messages.forEach(error => {
                                if (error.column === 'unit_id') {
                                    error.column = 'unit'
                                }
                                rows[error.column] = (<Tooltip
                                    classes={{
                                        tooltip: classes.tooltipError
                                    }}
                                    title="Поле пустое или заполнено неверно"
                                    placement="top-start">
                                    <ErrorIcon key={error.column} className={classes.svg}/>
                                </Tooltip>)
                            })
                        }
                        if (product.price < 0) {
                            rows['price'] = (<Tooltip
                                classes={{
                                    tooltip: classes.tooltipError
                                }}
                                title="Поле пустое или заполнено неверно"
                                placement="top-start">
                                <ErrorIcon key={'price'} className={classes.svg}/>
                            </Tooltip>)
                        }
                        if (product.funding_sources.length <= 0) {
                            rows['funding_sources'] = (<Tooltip
                                classes={{
                                    tooltip: classes.tooltipError
                                }}
                                title="Поле пустое или заполнено неверно"
                                placement="top-start">
                                <ErrorIcon key={'funding_sources'} className={classes.svg}/>
                            </Tooltip>)
                        }
                    }
                    return rows
                })}
                columns={columns}
                page={{page: page, setPage: setPage, rowsPerPage: rowsPerPage, handlePageSizeChange: handlePageSizeChange}}
                selection={{selection: selection, setSelection: setSelection}}
                totalSummaryItems={totalSummaryItems}
                summaryCalculator={summaryCalculator}
                setTableRef={setTableRef}
                tableColumnExtensions={tableColumnExtensions}
                columnsSettings={{columnOrder: columnOrder, setColumnOrder: setColumnOrder, setColumnWidths: setColumnWidths, columnWidths: columnWidths, hiddenColumnNames: hiddenColumnNames, setHiddenColumnNames: setHiddenColumnNames}}
                tableHeader={TableHeader}
                filterActionType={'PLANNING_PURCHASE_PLAN_PURCHASES_PRODUCTS_FILTER'}
                getValues={(params) => PurchasePlanItemsActions.filter({plan: plan.id, ...params})}
                onSelectionChange={(numbers) => {
                    setSelection(numbers)
                    const data = products.data.filter((item, index) => (numbers.find(key => (key === index)) !== undefined)).map(item => item)
                    setSelectionItems([
                        ...data,
                        ...selectionItems.filter(item => !products.data.find(el => el.id === item.id))
                    ])
                    setSelectionPage(!!data.length ? {...selectionPage, [page]: data.map(item => item.id)} : Object.fromEntries(
                        Object.entries(selectionPage).filter(key => (parseInt(key) !== parseInt(page))).map(([key, value]) => [key, value])
                    ))
                }}
                messages={messages}
                classInputContent={classes.inputContentProducts}
                rowComponentSelection={(props) => {
                    const {tableRow, children, highlighted, onToggle} = props
                    const {row} = tableRow

                    if (!!Object.keys(products.agg_info.chats_with_count_not_read_comments).length && row.chat_id && products.agg_info.chats_with_count_not_read_comments[row.chat_id]) {
                        row.count_unread_messages = products.agg_info.chats_with_count_not_read_comments[row.chat_id]
                    }

                    row['comment_counter'] =
                        <div className={classes.circle}>
                            <span>{row.count_unread_messages}</span>
                        </div>

                    return (
                        <Table.Row
                            tableRow={tableRow}
                            children={children}
                            onClick={(e) => {
                                if (e.target.parentElement.tagName !== 'TR') {
                                    return;
                                }
                                if (!window.getSelection().toString()) {
                                    onToggle()
                                }
                            }}
                            className={highlighted ? classes.active : (row.joint ? classes.disabled : classes.default)}
                            row={row}
                            style={{...({backgroundColor: AuthorizationService.roles(account, ['customer', 'supervisor']) ? (row.category?.active ? (row.standard?.deleted ? '#ffcccc' : (!row.standard?.confirmed ? '#fffde7' : {})) : {}) : {}})}}
                        />
                    )
                }}
                rowComponent={({row, tableRow, children}) => (
                    <Table.Row
                        tableRow={tableRow}
                        children={children}
                        className={classes.default}
                        row={row}
                    />
                )}
                filters={{
                    year: {
                        name: 'year',
                        type: 'year',
                    },
                    category_name: {
                        name: 'categories',
                        type: 'values',
                    },
                    subcategory: {
                        name: 'subcategories',
                        type: 'values',
                    },
                    group: {
                        name: 'groups',
                        type: 'values',
                    },
                    railRoad: {
                        name: 'rail_roads',
                        type: 'values',
                    },
                    company: {
                        name: 'companies',
                        type: 'values',
                    },
                    month: {
                        name: 'month',
                        type: 'month',
                    },
                    purchase_number: {
                        name: 'purchase',
                        type: 'purchase_number',
                    },
                    funding_sources: {
                        name: 'sources',
                        type: 'source',
                    },
                    comment_counter: {
                        button: <CommentOutlinedIcon
                            fontSize="small"
                            style={{display: "block", margin: "auto"}}
                        />
                    }
                }}
            />
        </React.Fragment>
    ) : null
}
