import React from "react"
import {makeStyles, Tab, Tabs} from "@material-ui/core"

const useStyles = makeStyles(() => ({
    root: {
        "&:hover":{
            "background-color": "#f4f4f4",
        },
        "width": "max-content",
        "min-width": "50px",
        "padding": "0  10px",
        "max-height": "32px",
        "min-height": "32px",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "font-size": "16px",
        "color": "rgba(0,0,0,.87)",
        "border": "1px solid #c1c1c1",
        "border-bottom-width": "5px",
        "border-bottom-color": "#898989",
        "border-radius": "0",
        "outline": "0",
        "background-color": "#fff",
        "cursor": "pointer",
        "letter-spacing":"normal",
        "word-spacing":"normal",
        "text-transform":"none",
        "text-indent":"0px",
        "text-shadow":"none",
        "text-align":"center",
    },
    rootGroup: {
        "max-height": "32px",
        "min-height": "32px",
        "width": "100%",
        "margin-bottom": "15px",
    },
    scroller: {
        "width": "100%",
        "display":"flex",
        "justify-content": "flex-end",
    },
    flexContainer: {
        "border-radius": "4px",
        "border": "1px solid rgba(0,0,0,.12)",
    },
    indicator: {
        "display": "none",
    },
    selected: {
        "background-color": "#898989",
        "&:hover": {
            "background-color": "#616161",
        }
    }
  }));

export const ButtonList = (props) => {
    const listVal = props.values;
    const classes = useStyles();
    const nodeList = listVal.map(val =>
        <Tab key={val.key} label={val.value} classes={{
            root: classes.root,
            selected: classes.selected,
        }}/>
    )

    return (
        <Tabs color="primary" aria-label="outlined primary button group" children={nodeList} classes={{
            root: classes.rootGroup,
            flexContainer: classes.flexContainer,
            scroller: classes.scroller,
            indicator: classes.indicator,
        }} {...props}>
      </Tabs>
    )
}
