import {HttpService} from "../../App/services/http"

export const AutoService = {
    orders,
    order,
    items,
    load
}

function orders(params) {
    return HttpService.get(`/contract/delivery/auto/orders`, params)
}

function order(id) {
    return HttpService.get(`/contract/delivery/auto/order/${id}`)
        .then(response => {
            return response.order
        })
}

function items(id, type, params) {
    return HttpService.get(`/contract/delivery/auto/order/${id}/items/${type}`, params)
}

function load(formData) {
    return HttpService.post("/contract/delivery/auto/order", formData, true, true)
}
