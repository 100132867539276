import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import {CustomPaging, PagingState, RowDetailState} from "@devexpress/dx-react-grid"
import {
    DragDropProvider,
    PagingPanel,
    Table,
    TableHeaderRow,
    TableRowDetail
} from "@devexpress/dx-react-grid-material-ui"
import {useDebounce} from "use-debounce"
import * as Yup from "yup"
import clsx from "clsx"

import {Grid, InputAdornment, makeStyles, MenuItem, TextField} from "@material-ui/core"
import {Search as SearchIcon} from "@material-ui/icons"
import {green} from "@material-ui/core/colors"

import {StickyTable} from "../../../App/components/Table/StikyTable"
import {Pager} from "../../../App/components/Table/Paging/Pager"
import {Grid as GridTable} from "../../../App/components/Table/Grid"
import {SupplierActions} from "../../actions/supplier";
import {TextFieldWithError as CustomTextField} from "../../../App/components/Inputs/TextFieldWithError"
import {taxPercentValuesCatalogPrice} from "../../../App/dics/dic";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {ru} from "date-fns/locale";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    container: {
        "background-image": "linear-gradient(to bottom,#f6f6f6,#eee 85%,#b1b1b1)",
        "width": "100%",
        "height": "calc(100vh - 256px)"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    searchLabel: {},
    fullWidth: {
        "width": "100%"
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 311px)",
        "min-height": "calc(100% - 311px)",
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    active: {
        'height': '41px',
        'background-color': green[100],
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    title: {
        "&>h2": {
            color: '#fff'
        }
    },
    filterLabel: {
        "background": "#fff",
        "margin-bottom": "0 !important",
        "margin-top": "5px !important",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        },
        "& input": {
            padding: "6px 15px 7px"
        }
    },
}))

const columns = [
    {name: 'order_name', title: 'Наименование ЦЗ'},
    {name: 'lot_number', title: 'Номер лота'},
    {name: 'customer', title: 'Заказчик'},
    {name: 'lot_status', title: 'Статус лота'},
    {name: 'offer_active', title: 'Предложение активно'},
]

export const Offers = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {supplierId} = props;
    const {offers} = useSelector(state => (state.supplier));
    const [page, setPage] = useState(offers.data.length > 0 ? 1 : 0)
    const [rowsPerPage, setRowsPerPage] = useState(25)

    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)

    const getOffers = async () => {
        let params = {
            limit: rowsPerPage,
            page: page,
            ...(searchRequest ? {search: searchRequest} : {})
        }
        await dispatch(SupplierActions.offers(supplierId, params))
    }

    useEffect(() => {
        getOffers().then(_ => {})
        return () => {
            dispatch({type: "CLEAR_SUPPLIER_OFFERS"})
        }
        // eslint-disable-next-line
    }, [dispatch, page, rowsPerPage, searchRequest])

    return (
        <Grid item xs={12}>
            <Grid container direction="row" alignItems="center">
                <Grid item xs={12}>
                    <div style={{color:"red", fontSize: "20px", padding: "10px 0 0 10px"}}>Разворачиваем ценовое предложение со списком товаров, редактируем поле минимальное кол-во(можно сразу у всех товаров внутри ЦП), жмем клавищу "Enter"</div>
                </Grid>
                <Grid item xs={12} style={{padding: "0 10px 10px 10px"}}>
                    <TextField
                        id="search"
                        variant="standard"
                        name={'search'}
                        placeholder={`Поиск: номер ЦЗ`}
                        fullWidth={true}
                        margin={'normal'}
                        onChange={(event) => {
                            setSearch(event.target.value)
                        }}
                        classes={{
                            root: clsx(classes.filterLabel, classes.searchLabel)
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon  style={{color: '#c1c1c1'}}/>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item className={classes.inputContent}>
                    {offers.data.length > 0 ?
                        <GridTable
                            rows={offers.data.map(offer => ({
                                order_name: `${offer.lot.order.id ? `№${offer.lot.order.id} - ` : ''} ${offer.lot.order.name ? offer.lot.order.name : ''}`,
                                lot_number: offer.lot.number,
                                customer: offer.lot.order && offer.lot.order.company && offer.lot.order.company.legal_detail && offer.lot.order.company.legal_detail.name ? offer.lot.order.company.legal_detail.name : '',
                                lot_status: offer.lot.status.name,
                                items: offer.items,
                                offer_active: offer.active ? 'Да' : 'Нет',
                                offerId: offer.id
                            }))}
                            columns={columns}
                        >
                            <PagingState
                                currentPage={page}
                                onCurrentPageChange={newPage => {
                                    setPage(newPage)
                                }}
                                pageSize={rowsPerPage}
                                onPageSizeChange={newRowsPerPage => {
                                    setPage(0)
                                    setRowsPerPage(newRowsPerPage)
                                }}
                            />
                            <CustomPaging
                                totalCount={offers.meta.total}
                            />
                            <DragDropProvider/>
                            <RowDetailState/>
                            <Table
                                noDataCellComponent={() => {
                                    return null;
                                }}
                                tableComponent={StickyTable}
                                rowComponent={({row, tableRow, children}) => (
                                    <Table.Row
                                        tableRow={tableRow}
                                        children={children}
                                        className={classes.default}
                                        row={row}
                                        color="black"
                                    />
                                )}
                            />
                            <TableHeaderRow/>
                            <PagingPanel
                                containerComponent={Pager}
                                messages={{
                                    showAll: 'Все',
                                    rowsPerPage: 'Записей на странице:',
                                    info: '{from}-{to} из {count}'
                                }}
                                pageSizes={[25, 50]}
                            />
                            <TableRowDetail
                                contentComponent={({row}) => (
                                    <Formik
                                        initialValues={{
                                            offerId: row.offerId,
                                            items: row.items
                                        }}
                                        validationSchema={Yup.object().shape({
                                            items: Yup.array().of(Yup.object().shape({
                                                name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                                min_ordered_quantity: Yup.string()
                                                    .matches(/^[0-9]*[.,]?[0-9]+$/, 'Поле должно состоять только из цифр!')
                                                    .min(0, "Значение не может быть меньше 0"),
                                                price: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!").min(0, 'Цена должна быть больше или равна 0'),
                                                tax_percent: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                                expiration_date: Yup.mixed().nullable(),
                                                expiration_date_number: Yup.mixed().nullable(),
                                            })),
                                        })}
                                        onSubmit={(values, {setSubmitting}) => {
                                            let items = values.items.map(item => {
                                                return {
                                                    id: item.id,
                                                    name: item.name,
                                                    min_ordered_quantity: item.min_ordered_quantity,
                                                    price: item.price,
                                                    tax_percent: item.tax_percent,
                                                    expiration_date: item.expiration_date,
                                                    expiration_date_number: item.expiration_date_number,
                                                }
                                            })

                                            return dispatch(SupplierActions.editOffers(values.offerId, items)).then(
                                                () => {
                                                    setSubmitting(false);
                                                },
                                                errors => {
                                                    if (errors) {
                                                    }
                                                    setSubmitting(false);
                                                }
                                            )
                                        }}
                                    >
                                        {({
                                              values,
                                              errors,
                                              setFieldValue,
                                              setFieldTouched,
                                              handleSubmit,
                                          }) => (
                                            <Form
                                                className={classes.fullHeight}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleSubmit();
                                                    }
                                                }}
                                            >
                                                {row.items.map((item, index) => (
                                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={1} key={index}>
                                                        <Grid item xs={4}>
                                                            <Field
                                                                fullWidth
                                                                name={`items.${index}.name`}
                                                                label="Наименование товара"
                                                                type="text"
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Field
                                                                fullWidth
                                                                name={`items.${index}.min_ordered_quantity`}
                                                                label="Мин. кол-во для заказа"
                                                                type="text"
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Field
                                                                fullWidth
                                                                label="Цена"
                                                                name={`items.${index}.price`}
                                                                type="text"
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Field
                                                                fullWidth
                                                                type="text"
                                                                name={`items.${index}.tax_percent`}
                                                                label="Ставка НДС"
                                                                select
                                                                className={classes.select}
                                                                variant="standard"
                                                                value={item.tax_percent}
                                                                component={TextField}
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                inputProps={{
                                                                    onChange: async (event) => {
                                                                        setFieldValue(`items.${index}.tax_percent`, event.target.value)
                                                                    },
                                                                    onClose: async () => {
                                                                        handleSubmit()
                                                                    }
                                                                }}
                                                            >
                                                                {taxPercentValuesCatalogPrice.map((tax_percent) => (
                                                                    <MenuItem key={tax_percent.key} value={tax_percent.key}>{tax_percent.value}</MenuItem>
                                                                ))}
                                                            </Field>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            {item.expiration_date ? (
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                    <KeyboardDatePicker
                                                                        disableToolbar
                                                                        fullWidth
                                                                        name={`items.${index}.expiration_date`}
                                                                        required
                                                                        label="Срок годности"
                                                                        key={`items.${index}.expiration_date`}
                                                                        format="dd-MM-yyyy"
                                                                        invalidDateMessage="Некорректная дата"
                                                                        orientation="landscape"
                                                                        variant="inline"
                                                                        openTo="year"
                                                                        autoOk
                                                                        value={item.expiration_date}
                                                                        onChange={date =>
                                                                            setFieldValue(`items.${index}.expiration_date`, date, true)
                                                                        }
                                                                        onClose={() => {
                                                                            handleSubmit()
                                                                        }}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            ) : (
                                                                <Field
                                                                    name={`items.${index}.expiration_date_number`}
                                                                    fullWidth
                                                                    type="number"
                                                                    step={0.01}
                                                                    label="Срок годности"
                                                                    inputProps={{
                                                                        min: 0,
                                                                        step: 0.01
                                                                    }}
                                                                    component={CustomTextField}
                                                                />
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Form>
                                        )}
                                    </Formik>
                                )}
                            />
                        </GridTable>
                        : null}
                </Grid>
            </Grid>
        </Grid>
    )
}
