import React from "react"

import {AddCumulativeContent} from "./AddСumulativeContent";
import {Menu} from "../Menu";
import {Menu as AppMenu} from "../../App/Menu";
import {Content as AppContent} from "../../App/Content";

export const AddCumulative = (props) => {
    return <React.Fragment>
        <AppMenu
            Component={Menu}
            {...props}
        />
        <AppContent
            Component={AddCumulativeContent}
            {...props}
        />
    </React.Fragment>
}

