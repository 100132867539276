import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik, getIn} from "formik"
import {CheckboxWithLabel, TextField} from "formik-material-ui"
import {useDebounce} from 'use-debounce'
import DateFnsUtils from "@date-io/date-fns"
import format from "date-fns/format"
import {ru} from "date-fns/locale"
import * as Yup from "yup"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    MenuItem,
    TablePagination,
    TextField as MuiTextField,
    Typography,
    withStyles
} from "@material-ui/core"
import {Autocomplete} from "@material-ui/lab"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"
import {
    AttachFile as AttachFileIcon,
    Close as CloseIcon,
    Delete as DeleteIcon,
} from "@material-ui/icons"

import {ContractActions} from "../actions/contract";
import {TextFieldWithError as CustomTextField} from "../../App/components/Inputs/TextFieldWithError"
import {AuthorizationService} from "../../Auth/services/authorization"
import {Submit} from "../../App/components/Button/Submit"
import {CompanyActions} from "../actions/company"
import {PriceDialog} from './PriceDialog'
import {EditPriceDialog} from './EditPriceDialog'
import {SystemActions} from "../../App/actions/system"
import {PriceActions} from "../../Price/actions/price"
import {getPriceName} from "../../App/helpers/name"
import * as AppStorage from "../../App/helpers/storage"
import {AdditionalAgreement} from "./Contract/Buttons/AdditionalAgreement";
import {Reject} from "./Contract/Buttons/Reject";
import {Tooltip} from "../../App/components/Data/Tooltip";
import {SettingsActions} from "../../Settings/actions/settings";
import {Category} from "../../App/components/Inputs/Category";
import {Signature} from "../../Crypto/Signature";
import {Download as DownloadSignature} from "../../Crypto/Download";

const useStyles = makeStyles(() => ({
    right: {
        "max-width": "100%",
        "height": "50%"
    },
    item: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "cursor": "pointer",
        "& .MuiListItemText-root": {
            "max-width": "calc(100% - 92px)",
            "& .MuiTypography-root": {
                "white-space": "nowrap",
                "overflow": "hidden",
                "text-overflow": "ellipsis",
            }
        }
    },
    content: {
        "padding": "0 !important"
    },
    column: {
        "height": "86px"
    },
    section: {
        "border-right": "2px solid #c1c1c1",
        "padding": "20px 30px",
        "overflow-y": "auto",
        "max-height": "calc(80vh - 63px)"
    },
    additionally: {
        "max-height": "calc(80vh - 63px)"
    },
    footer: {
        "background-color": "#e7eaee",
        "justify-content": "flex-start",
        minHeight: "30px",
    },
    footerContent: {
        "padding": "10px 0px"
    },
    eclipsis: {
        "overflow": "hidden",
        "text-overflow": "ellipsis",
        "white-space": "nowrap"
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    titleDialog: {
        "& h2": {
            "display": "flex",
            "justify-content": "space-between",
        },
    },
    paginationWrap: {
        backgroundColor: "#e7eaee",
        bottom: "0",
        width: "100%",
        maxHeight: "55px"
    },
    pagination: {
        "&>div": {
            flexWrap: 'wrap'
        }
    },

    "@keyframes myEffect": {
        "0%": {
            background: "#9d9999"
        },
        "100%": {
            background: "#ccc"
        },
    },
    submitCategoryBtn: {
        "position": "sticky",
        "bottom": "10px",
        "width": "calc(100% - 20px) !important",
        "margin": "10px !important",
        '&.MuiButton-root': {
            "border-bottom": "none !important",
        },
    },
    selectCategoryLabel: {
        "font-size": "12px",
    },
    selectCategory: {
        "& .dx-texteditor.dx-editor-outlined": {
            "border": "unset !important",
            "border-bottom": "2px solid #c1c1c1 !important",
            "border-radius": "unset",
            "&:hover": {
                "border-bottom": "2px solid #898989 !important",
            },
        },
        "& .dx-texteditor-input": {
            "padding": "0px !important",
        },
        "&:after":{
            "transform": "scaleX(1)",
            "border-bottom-color": "9px solid #f27360 !important"
        }
    },
    errorCategory: {
        "font-size": "0.75rem",
        "color": "#f27360",
    },
    errorCategoryLabel: {
        "font-size": "0.9rem",
        "color": "#f27360",
    },
}))

const CustomList = withStyles({
    root: {
        "padding": "0",
        "& .MuiListItem-button": {
            "background-image": "linear-gradient(to bottom,#e4e4e4,#f4f4f4)",
            "cursor": "default",
            "align-items": "center",
            "min-height": "50px",
            "padding": "5px 25px 0",
            "border-bottom": "1px solid #e4e4e4",
            "outline": "0"
        },
        "& .MuiListItem-root": {
            "cursor": "default",
            "border-bottom": "1px solid #e4e4e4 !important"
        },
        "& .MuiIconButton-root": {
            "padding": "0px"
        },
        "& .MuiListItemIcon-root": {
            "min-width": "30px"
        },
        "& .MuiListItemText-primary": {
            "font-size": "16px",
            "font-weight": "700"
        },
        "& .MuiListItemText-secondary": {
            "color": "#485868",
            "font-size": "16px"
        }
    }
})(List)

export const Contract = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { account, contract, currencies, categories, filter }  = useSelector(state => { return {...state.contract, ...state.system, ...state.account,...state.catalog.products} })
    const { companies }  = useSelector(state => state.contractCompanies)
    const { id, supplierId, brief } = props

    const [open, setOpen] = useState(props.open)
    const [settings, setSettings] = useState(props.settings)
    const [reload, setReload] = useState(false)
    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)
    const [supplier, setSupplier] = useState(null)

    const [openPriceDialog, setOpenPriceDialog] = useState(false)
    const [openEditPrice, setOpenEditPrice] = useState(false)

    const [files, setFiles] = useState([])
    const [sign, setSign] = useState({open: false, files: []})

    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [page, setPage] = useState(1)
    const [log, setLog] = useState([]);

    const openDialog = (id, open) => {
        setOpenPriceDialog(open)
    }

    const openEditPriceDialog = (id, open) => {
        setOpenEditPrice(open)
    }

    const handleClose = () => {
        setOpenPriceDialog(false)
    }

    const handleEditPriceClose = () => {
        setOpenEditPrice(false)
    }

    useEffect(() => {
        if (account && (searchRequest || supplierId)) {
            const getCompanies = async () => {
                let params = {
                    limit: 10,
                    page: 1,
                    type: AuthorizationService.roles(account,'customer') ? 'supplier' : 'customer',
                    ...(searchRequest ? {name: searchRequest} : {}),
                    ...(supplierId ? {id: supplierId} : {}),
                    ...(brief ? {active: false} : {})
                }
                return await dispatch(CompanyActions.suppliers(params))
            }

            getCompanies().then(companies => {
                if (supplierId) {
                    setSupplier(companies.data.find(company => (parseInt(company.id) === parseInt(supplierId))))
                }
            })
        }
    }, [dispatch, searchRequest, account, supplierId, brief])

    useEffect(() => {
        const getCategories = async () => {
            return await dispatch(SystemActions.categories())
        }

        if (!categories.length) {
            getCategories().then(() => {})
        }
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        const getClear = async () => {
            await dispatch({type: "CONTRACT", payload: null})
        }

        if (props.open) {
            setFiles([])

            if (id) {
                const getContract = async () => {
                    if (!settings) {
                        await dispatch(SettingsActions.getSettings('contract')).then(settings => {
                            setSettings(settings)
                        })
                    }
                    await dispatch(ContractActions.contract(id))
                }

                getContract().then(c => {
                    setOpen(props.open)
                })
            } else {
                getClear().then(_ => {
                    setOpen(props.open)
                })
            }
        } else {
            setOpen(props.open)

            getClear().then(_ => {
                setOpen(props.open)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, id, props.open])

    useEffect(() => {
        if (reload && id) {
            const getContract = async () => {
                await dispatch(ContractActions.contract(id))
            }

            getContract().then((contract) => {
                setReload(false)
            })
        }
    }, [dispatch, id, reload])

    useEffect(() => {
        if (contract) {
            let start = rowsPerPage * (page - 1);
            let end = (rowsPerPage * page);
            setLog(contract.log.slice(start, end))
        }
    }, [dispatch, contract, rowsPerPage, page]);

    const isViewEditPriceButton = () => {
        if (contract && contract.price_id) {
            if (contract.active && ((AuthorizationService.roles(account, "supplier") && contract.without_specification) || (AuthorizationService.permissions(account, "create_delivery_order") && AuthorizationService.roles(account, 'customer') && !contract.without_specification))) {
                return true
            } else if(!contract.active && (AuthorizationService.permissions(account, "delivery_order_after_event"))) {
                return true
            }
        }
        return false
    }

    const isViewLoadPriceButton = () => {
        if (contract && !contract.offer.lot.order.form) {
            if ((AuthorizationService.roles(account, "supplier") && contract.without_specification) || (AuthorizationService.roles(account, 'customer') && !contract.without_specification)) {
                return true
            } else if (!contract.active && (AuthorizationService.permissions(account, "delivery_order_after_event"))) {
                return true
            }
        }
        return false
    }

    const schema = ((contract && contract.brief) || (!contract && brief)) ? {
        number: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        currency: Yup.string().required("Выберите валюту!"),
        name: Yup.string(),
        order: Yup.object().shape({
            number: AuthorizationService.roles(account, 'customer') ? Yup.string().when("unscheduled_purchase", {
                is: false,
                then: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').length(11, 'Поле должно состоять из 11 цифр!').required("Поле не может быть пустым или состоять из одних пробелов!"),
                otherwise: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').length(11, 'Поле должно состоять из 11 цифр!')
            }) : Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').length(11, 'Поле должно состоять из 11 цифр!'),
            unscheduled_purchase: Yup.mixed(),
            reason: AuthorizationService.roles(account, 'customer') ? Yup.string().when("unscheduled_purchase", {
                is: true,
                then: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                otherwise: Yup.string()
            }) : Yup.string(),
            company: Yup.object().shape({
                name: Yup.string().nullable().required("Поле не может быть пустым или состоять из одних пробелов!"),
                inn: Yup.string().nullable().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!'),
                kpp: Yup.string().nullable().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!'),
                bin: Yup.string().nullable().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!'),
            })
        }),
        lot: Yup.object().shape({
            number: AuthorizationService.roles(account, "customer")
                ? Yup.string().nullable().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').length(4, 'Поле должно состоять из 4 цифр!')
                : Yup.string(),
        }),
        offer: Yup.object().shape({
            company: Yup.object().shape({
                name: Yup.string().nullable().required("Поле не может быть пустым или состоять из одних пробелов!"),
                inn: Yup.string().nullable().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!'),
                kpp: Yup.string().nullable().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!'),
                bin: Yup.string().nullable().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!'),
                type: Yup.string().nullable()
            }),
        }),
        total_price: (contract && contract.framework) ? Yup.number().nullable() : Yup.number().when("framework", {
            is: false,
            then: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
            otherwise: Yup.number(),
        }),
        framework: Yup.boolean().when("total_price", {
            is: '',
            then: Yup.boolean().required("Поле не может быть пустым или состоять из одних пробелов!").oneOf([true], "Поле должно быть активно, если сумма договора не выбрана"),
            otherwise: Yup.boolean(),
        })
    } : {
        number: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        start: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
        end: Yup.mixed().when("perpetual", {
            is: false,
            then: Yup.mixed().test('min-date', 'Некорректная дата', function(value) {
                let currentDate = new Date(value);
                let minDate = this.parent.start ? new Date(this.parent.start).setDate(this.parent.start.getDate()) : new Date().setDate(new Date().getDate()) + 1

                return currentDate.valueOf() - minDate.valueOf() >= 0;
            }).required("Поле не может быть пустым или состоять из одних пробелов!"),
            otherwise: Yup.mixed()
        }),
        perpetual: Yup.boolean(),
        type: Yup.string().required("Выберите тип оплаты!"),
        postponement: Yup.string().when("type", {
            is: 'postponement',
            then: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').required("Поле не может быть пустым или состоять из одних пробелов!"),
            otherwise: Yup.string().when("type", {
                is: 'partial',
                then: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').required("Поле не может быть пустым или состоять из одних пробелов!"),
                otherwise: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
            })
        }),
        prepayment: Yup.number().when("type", {
            is: 'partial',
            then: Yup.number().min(1, 'Пожалуйста, введите значение от 1 до 100').max(100, 'Пожалуйста, введите значение от 1 до 100').required("Поле не может быть пустым или состоять из одних пробелов!"),
            otherwise: Yup.number()
        }),
        currency: Yup.string().required("Выберите валюту!"),
        name: Yup.string(),
        contract_time: Yup.number().min(0, 'Срок поставки не может быть отрицательным числом!'),
        order: Yup.object().shape({
            category: contract ? Yup.string() : Yup.string().required("Выберите категорию!"),
            number: AuthorizationService.roles(account, 'customer') ? Yup.string().when("unscheduled_purchase", {
                is: false,
                then: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').length(11, 'Поле должно состоять из 11 цифр!').required("Поле не может быть пустым или состоять из одних пробелов!"),
                otherwise: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').length(11, 'Поле должно состоять из 11 цифр!')
            }) : Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').length(11, 'Поле должно состоять из 11 цифр!'),
            unscheduled_purchase: Yup.mixed(),
            reason: AuthorizationService.roles(account, 'customer') ? Yup.string().when("unscheduled_purchase", {
                is: true,
                then: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                otherwise: Yup.string()
            }) : Yup.string(),
            company: Yup.object().shape({
                name: Yup.string().nullable().required("Поле не может быть пустым или состоять из одних пробелов!"),
                inn: Yup.string().nullable().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!'),
                kpp: Yup.string().nullable().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!'),
                bin: Yup.string().nullable().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!'),
            })
        }),
        lot: Yup.object().shape({
            number: AuthorizationService.roles(account, "customer")
                ? Yup.string().nullable().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').length(4, 'Поле должно состоять из 4 цифр!')
                : Yup.string()
        }),
        offer: Yup.object().shape({
            company: Yup.object().shape({
                name: Yup.string().nullable().required("Поле не может быть пустым или состоять из одних пробелов!"),
                inn: Yup.string().nullable().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!'),
                kpp: Yup.string().nullable().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!'),
                bin: Yup.string().nullable().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!'),
                type: Yup.string().nullable()
            }),
        }),
        total_price: (contract && contract.framework) ? Yup.number().nullable() : Yup.number().when("framework", {
            is: false,
            then: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
            otherwise: Yup.number(),
        }),
        framework: Yup.boolean().when("total_price", {
            is: '',
            then: Yup.boolean().required("Поле не может быть пустым или состоять из одних пробелов!").oneOf([true], "Поле должно быть активно, если сумма договора не выбрана"),
            otherwise: Yup.boolean(),
        }),
        without_specification: Yup.boolean(),
    }

    const setDefault = () => {
        setOpen(false)
        setSupplier(null)
        if (props.hasOwnProperty('setId')) {
            props.setId(null)
        }
        if (props.hasOwnProperty('setSupplierId')) {
            props.setSupplierId(null)
        }
        if (props.hasOwnProperty('setBrief')) {
            props.setBrief(false)
        }
        props.setDialog(false)
    }

    const handleChangePage = (event, newPage) => {
        setPage(Number(newPage) + 1)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(1)
    }

    const sendPrice = (_, contract) => {
        if (contract.price_id) {
            return dispatch(PriceActions.getById(contract.id, contract.price_id)).then((resp) => {
                if (resp.price.status === 'in_process') {
                    return dispatch({
                        type: "ALERT_INFO", payload: {
                            message: 'Подождите,' +
                                ' прайс-лист' +
                                ' в' +
                                ' обработке...',
                            type: 'info'
                        }
                    })
                }
                dispatch({
                    type: "SET_ACTIVE_PRICE", payload: {
                        priceId: resp.price.id,
                        type: resp.price.type.name,
                        company_id: resp.price.company_id,
                        name: getPriceName(resp.price),
                        category: resp?.category
                    }
                })
                openDialog(contract.id, true)
            })
        } else {
            openDialog(contract.id, true)
        }
    }

    return (categories && currencies) ? (
        <Grid item>
            <Dialog
                open={!!open}
                onClose={() => {
                    setDefault()
                }}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle className={classes.titleDialog}>
                    {contract ? 'Договор' : 'Создание договора'}
                    <CloseIcon
                        className={classes.close}
                        onClick={() => {
                            setDefault()
                        }}
                    />
                </DialogTitle>
                <Formik
                    validateOnChange={true}
                    enableReinitialize
                    initialValues={contract ? {
                        brief: contract.brief ?? brief,
                        number: contract.number ?? contract.id,
                        start: contract.start ? new Date(contract.start) : null,
                        end: contract.end ? new Date(contract.end) : (contract.offer.lot.end ? new Date(contract.offer.lot.end) : null),
                        perpetual: !!contract.perpetual,
                        type: contract.type,
                        total_price: contract.total_price ?? '',
                        postponement: contract.postponement ?? (contract.offer.lot.postponement ?? ''),
                        contract_time: contract.contract_time ?? (contract.offer.lot.contract_time ?? ''),
                        prepayment: contract.offer.lot.prepayment ?? 1,
                        framework: contract.framework,
                        currency: contract.currency ? contract.currency.id : '',
                        name: contract.name ?? (contract.offer.lot.order.name ?? ''),
                        without_specification: !!contract.without_specification,
                        order: {
                            number: contract.purchase_number ?? '',
                            category: contract.category ? contract.category.id : '',
                            unscheduled_purchase: contract.unscheduled_purchase,
                            reason: contract.offer.lot.order.reason ?? '',
                            company: {
                                id: contract.company.id,
                                name: contract.company.legal_detail.name,
                                inn: contract.company.legal_detail.inn,
                                kpp: contract.company.legal_detail.kpp,
                                bin: contract.company.legal_detail.bin,
                                country: {
                                    code: contract.offer.company.legal_detail.city.region.country.code
                                }
                            }
                        },
                        lot: {
                            number: contract.offer.lot.number ?? ''
                        },
                        offer: {
                            company: {
                                id: contract.offer.company.id,
                                name: contract.offer.company.legal_detail.name,
                                inn: contract.offer.company.legal_detail.inn,
                                kpp: contract.offer.company.legal_detail.kpp,
                                bin: contract.offer.company.legal_detail.bin,
                                type: contract.offer.company.type,
                                country: {
                                    code: contract.offer.company.legal_detail.city.region.country.code
                                }
                            },
                        }
                    } : {
                        brief: brief,
                        number: '',
                        start: null,
                        end: null,
                        total_price: '',
                        perpetual: false,
                        type: '',
                        framework: false,
                        postponement: '',
                        prepayment: 1,
                        currency: '',
                        name: '',
                        contract_time: '',
                        without_specification: false,
                        order: {
                            number: '',
                            category: '',
                            unscheduled_purchase: false,
                            reason: '',
                            company: AuthorizationService.roles(account, "customer") ? {
                                id: account.company.id,
                                name: account.company.legal_detail.name,
                                inn: account.company.legal_detail.inn,
                                kpp: account.company.legal_detail.kpp,
                                bin: account.company.legal_detail.bin,
                                type: account.company.type,
                                country: {
                                    code: account.company.legal_detail.city.region.country.code
                                }
                            } : {
                                id: '',
                                name:'',
                                inn: '',
                                kpp: '',
                                bin: '',
                                type: '',
                                country: {
                                    code: null
                                }
                            }
                        },
                        lot: {
                            number: ''
                        },
                        offer: {
                            company: AuthorizationService.roles(account, "supplier") ? {
                                id: account.company.id,
                                name: account.company.legal_detail.name,
                                inn: account.company.legal_detail.inn,
                                kpp: account.company.legal_detail.kpp,
                                bin: account.company.legal_detail.bin,
                                type: account.company.type,
                                country: {
                                    code: account.company.legal_detail.city.region.country.code
                                }
                            } : (supplier
                                ? {
                                    id: supplier.id,
                                    name: supplier.legal_detail.name,
                                    inn: supplier.legal_detail.inn,
                                    kpp: supplier.legal_detail.kpp,
                                    bin: supplier.legal_detail.bin,
                                    type: supplier.type,
                                    country: {
                                        code: supplier.legal_detail.city.region.country.code
                                    }
                                }
                                : {
                                    id: '',
                                    name:'',
                                    inn: '',
                                    kpp: '',
                                    bin: '',
                                    type: '',
                                    country: {
                                        code: null
                                    }
                                }
                            ),
                        }
                    }}
                    validationSchema={Yup.object().shape(schema, [['framework', 'total_price']])}
                    onSubmit={(values, {setSubmitting}) => {
                        const formData = new FormData()

                        for (const i in values) {
                            if (values.hasOwnProperty(i)) {
                                if (values[i] || typeof values[i] === 'number') {
                                    switch (i) {
                                        case 'start':
                                        case 'end':
                                            formData.append(i, format(values[i], 'yyyy-MM-dd'))
                                            break
                                        case 'perpetual':
                                        case 'framework':
                                        case 'brief':
                                        case 'without_specification':
                                            formData.append(i, values[i] ? '1' : '0')
                                            break
                                        default:
                                            if (values[i] instanceof Object) {
                                                for (const j in values[i]) {
                                                    if (values[i].hasOwnProperty(j)) {
                                                        switch (j) {
                                                            case 'company':
                                                                formData.append(`${i}[${j}][id]`, values[i][j].id)
                                                                break
                                                            case 'unscheduled_purchase' :
                                                                formData.append(`${i}[${j}]`, values[i][j] ? '1' : '0')
                                                                break
                                                            default:
                                                                formData.append(`${i}[${j}]`, values[i][j])
                                                        }
                                                    }
                                                }
                                            } else {
                                                formData.append(i, values[i])
                                            }
                                    }
                                }
                            }
                        }

                        files.forEach((file, index) => {
                            formData.append(`files[${index}]`, file.file)
                            formData.append(`signatures[${index}]`, file.sign ?? '')
                        })

                        if (!!supplier) {
                            formData.append(`active`, 'false')
                        }

                        return dispatch(contract ? ContractActions.edit(contract.id, formData) : ContractActions.add(formData)).then(
                            () => {
                                setFiles([])
                                setSubmitting(false);
                            },
                            errors => {
                                if (errors) {
                                }
                                setSubmitting(false);
                            }
                        )
                    }}
                    validate={values => {
                        const errors = {}

                        if (values.start && values.end && !values.perpetual && (new Date(values.end.getFullYear(), values.end.getMonth(), values.end.getDate()) <= new Date(values.start.getFullYear(), values.start.getMonth(), values.start.getDate()))) {
                            errors.start = 'Дата заключения договора должна быть меньше даты завершения договора!'
                        }

                        return errors
                    }}
                >
                    {({
                        dirty,
                        values,
                        errors,
                        touched,
                        setValues,
                        setFieldValue,
                        setFieldTouched,
                        isSubmitting,
                        isValid,
                        submitForm,
                        validateForm
                    }) => (
                        <Form>
                            <DialogContent className={classes.content}>
                                <Grid container className={classes.fullWidth} direction="row" justify="space-between" alignItems="stretch">
                                    <Grid item xs={8} className={ classes.section }>
                                        <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                            <Grid item>
                                                {((contract && contract.active) || contract === null) && <Grid item>
                                                    <Typography component="div" style={{"color": "red"}}>Внимание! Спецификацию к договору должен загружать Заказчик.</Typography>
                                                    <Typography component="div" style={{"color": "red"}}>В случае, если спецификация к договору не предусмотрена - каталог должен загрузить поставщик</Typography>
                                                </Grid>}
                                                <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                                                    <Grid item className={classes.column} xs={3}>
                                                        <Field
                                                            fullWidth
                                                            name="number"
                                                            type="text"
                                                            label="№"
                                                            required
                                                            disabled={(!!contract && !contract.active) || (!!contract && ((contract.status.index !== 1) && (contract.status.index !== 7))) || (!!contract && !!contract.offer.lot.order.form && (contract.status.index === 1)) || (!!contract && contract.brief) || (!!contract && AuthorizationService.roles(account, "customer") && ((contract.active && !AuthorizationService.permissions(account, "create_delivery_order")) || (!contract.active && !AuthorizationService.permissions(account, "delivery_order_after_event")))) || isSubmitting}
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                {AuthorizationService.roles(account, "customer") &&
                                                    <React.Fragment>
                                                        <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                            <Grid item className={classes.column} xs={5}>
                                                                <Field
                                                                    fullWidth
                                                                    name="order.number"
                                                                    type="text"
                                                                    label="№ Закупки (11 цифр)"
                                                                    required={!values.order.unscheduled_purchase}
                                                                    disabled={(!!contract && ((contract.status.index === 3) || (contract.status.index === 4) || (contract.status.index === 6) || values.order.unscheduled_purchase)) || (!contract && values.order.unscheduled_purchase) || (!!contract && AuthorizationService.roles(account, "customer") && ((contract.active && !AuthorizationService.permissions(account, "create_delivery_order")) || (!contract.active && !AuthorizationService.permissions(account, "delivery_order_after_event")))) || isSubmitting}
                                                                    component={CustomTextField}
                                                                />
                                                            </Grid>
                                                            {((contract && values.order.number) || (!!values.order.number.length && !values.order.unscheduled_purchase)) &&
                                                                <Grid item className={classes.column} xs={5}>
                                                                    <Field
                                                                        fullWidth
                                                                        name="lot.number"
                                                                        type="text"
                                                                        label="№ Лота"
                                                                        disabled={(!!contract && ((contract.status.index === 3) || (contract.status.index === 4) || (contract.status.index === 6))) || (!!contract && AuthorizationService.roles(account, "customer") && ((contract.active && !AuthorizationService.permissions(account, "create_delivery_order")) || (!contract.active && !AuthorizationService.permissions(account, "delivery_order_after_event")))) || isSubmitting}
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                            }
                                                            <Grid item className={classes.column} xs={2}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="order.unscheduled_purchase"
                                                                    disabled={!!(!!contract && ((contract.status.index === 3) || (contract && contract.purchase_number) || (contract.status.index === 4) || (contract.status.index === 6))) || (!!contract && AuthorizationService.roles(account, "customer") && ((contract.active && !AuthorizationService.permissions(account, "create_delivery_order")) || (!contract.active && !AuthorizationService.permissions(account, "delivery_order_after_event")))) || isSubmitting}
                                                                    color="primary"
                                                                    Label={{label: "Внеплановая закупка"}}
                                                                    inputProps={{
                                                                        onChange: async (event) => {
                                                                            const checked = event.target.checked

                                                                            await setFieldValue("order.unscheduled_purchase", checked)

                                                                            if (checked) {
                                                                                await setFieldValue("order.number", '')
                                                                                await setFieldValue("lot.number", '')
                                                                            }
                                                                        },
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        {values.order.unscheduled_purchase &&
                                                            <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                                <Grid item className={classes.column} xs={12}>
                                                                    <Field
                                                                        fullWidth
                                                                        name="order.reason"
                                                                        type="text"
                                                                        multiline
                                                                        inputProps={{
                                                                            rowsMax: 2
                                                                        }}
                                                                        label="Обоснование внеплановой закупки"
                                                                        required
                                                                        disabled={(!!contract && !!contract.offer.lot.order.form && !!contract.offer.lot.order.reason) || (!!contract && ((contract.status.index !== 1) && (contract.status.index !== 7)) && !!contract.offer.lot.order.reason) || (!!contract && !!contract.offer.lot.order.form && (contract.status.index === 1) && !!contract.offer.lot.order.reason) || !values.order.unscheduled_purchase || (contract && contract.brief) || (!!contract && AuthorizationService.roles(account, "customer") && ((contract.active && !AuthorizationService.permissions(account, "create_delivery_order")) || (!contract.active && !AuthorizationService.permissions(account, "delivery_order_after_event")))) || isSubmitting}
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </React.Fragment>
                                                }
                                                <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                    <Grid item className={classes.column} xs={12}>
                                                        <Field
                                                            fullWidth
                                                            name="name"
                                                            type="text"
                                                            label="Наименование договора"
                                                            disabled={(!!contract && !contract.active) || (contract && ((contract.status.index !== 1) && (contract.status.index !== 7))) || (!!contract && !!contract.offer.lot.order.form && (contract.status.index === 1)) || (!!contract && contract.brief) || (!!contract && AuthorizationService.roles(account, "customer") && ((contract.active && !AuthorizationService.permissions(account, "create_delivery_order")) || (!contract.active && !AuthorizationService.permissions(account, "delivery_order_after_event")))) || isSubmitting}
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                    {(!!contract || AuthorizationService.roles(account, "customer"))
                                                        ? <Grid item className={classes.column} xs={12}>
                                                            <Field
                                                                fullWidth
                                                                name="order.company.name"
                                                                type="text"
                                                                label="Заказ"
                                                                required
                                                                disabled={(!!contract && ((contract.status.index !== 1) && (contract.status.index !== 7))) || (!!contract || AuthorizationService.roles(account, "customer")) || isSubmitting}
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                        : <Grid item className={classes.column} xs={12}>
                                                            <Autocomplete
                                                                filterOptions={(options) => options}
                                                                name={'order.company.name'}
                                                                required
                                                                options={companies.data}
                                                                value={values.order.company}
                                                                getOptionLabel={company => {
                                                                    return company ? (company.hasOwnProperty('legal_detail') ? company.legal_detail.name : (company.name ?? '')) : ''
                                                                }}
                                                                noOptionsText='Заказчик'
                                                                onChange={(e, item) => {
                                                                    setFieldValue('order.company', item ? {id: item.id, name: item.legal_detail.name, inn: item.legal_detail.inn, kpp: item.legal_detail.kpp, bin: item.legal_detail.bin, country: {code: item.legal_detail.city.region.country.code}} : {name: null, inn: null, kpp: null, bin: null, country: {code: null}})
                                                                }}
                                                                renderInput={params => {
                                                                    const error = getIn(touched, 'order.company.name') && getIn(errors, 'order.company.name');

                                                                    return <Field
                                                                        fullWidth
                                                                        component={MuiTextField}
                                                                        required
                                                                        {...params}
                                                                        onChange={(e) => {
                                                                            setSearch(e.target.value)
                                                                        }}
                                                                        error={!!error}
                                                                        helperText={error}
                                                                        name={'order.company.name'}
                                                                        label="Заказчик"
                                                                    />
                                                                }}
                                                                onFocus ={() => {
                                                                    dispatch({type: 'CONTRACT_SUPPLIERS_COMPANIES_CLEAR'})
                                                                    setFieldTouched('order.company.name', true, true)
                                                                }}
                                                                onBlur={() => {
                                                                    setFieldTouched('order.company.name', true, true)
                                                                }}
                                                            />
                                                        </Grid>
                                                    }
                                                </Grid>
                                                {((!!contract || AuthorizationService.roles(account, "customer")) || (!contract && values.order.company.name))  &&
                                                    (
                                                        (values.order.company.country.code === 'ru')
                                                            ?
                                                                <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                                    <Grid item className={classes.column} xs={6}>
                                                                        <Field
                                                                            fullWidth
                                                                            name="order.company.inn"
                                                                            type="text"
                                                                            label="ИНН заказчик"
                                                                            disabled
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item className={classes.column} xs={6}>
                                                                        <Field
                                                                            fullWidth
                                                                            name="order.company.kpp"
                                                                            type="text"
                                                                            label="КПП заказчик"
                                                                            disabled
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            :
                                                                <React.Fragment>
                                                                    <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                                        <Grid item xs={6} className={classes.column}>
                                                                            <Field
                                                                                fullWidth
                                                                                name="order.company.bin"
                                                                                type="text"
                                                                                label="БИН заказчика"
                                                                                disabled
                                                                                component={CustomTextField}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </React.Fragment>
                                                    )
                                                }
                                                <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                    {(!!contract || AuthorizationService.roles(account, "supplier"))
                                                        ? <Grid item className={classes.column} xs={12}>
                                                            <Field
                                                                fullWidth
                                                                name="offer.company.name"
                                                                type="text"
                                                                label="Поставщик"
                                                                required
                                                                disabled={(!!contract && ((contract.status.index !== 1) && (contract.status.index !== 7))) || (!!contract || AuthorizationService.roles(account, "supplier")) || isSubmitting}
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                        : <Grid item className={classes.column} xs={12}>
                                                            <Autocomplete
                                                                filterOptions={(options) => options}
                                                                name={'offer.company.name'}
                                                                required
                                                                options={companies.data}
                                                                value={values.offer.company}
                                                                getOptionLabel={company => {
                                                                    return company ? (company.hasOwnProperty('legal_detail') ? company.legal_detail.name : (company.name ?? '')) : ''
                                                                }}
                                                                noOptionsText='Поставщик'
                                                                onChange={(e, item) => {
                                                                    setFieldValue('offer.company', item ? {id: item.id, name: item.legal_detail.name, inn: item.legal_detail.inn, kpp: item.legal_detail.kpp, bin: item.legal_detail.bin, type: item.type, country: {code: item.legal_detail.city.region.country.code}} : {name: null, inn: null, kpp: null, bin: null, type: null, country: {code: null}})
                                                                }}
                                                                renderInput={params => {
                                                                    const error = getIn(touched, 'offer.company.name') && getIn(errors, 'offer.company.name');

                                                                    return <Field
                                                                        fullWidth
                                                                        component={MuiTextField}
                                                                        required
                                                                        {...params}
                                                                        onChange={(e) => {
                                                                            setSearch(e.target.value)
                                                                        }}
                                                                        error={!!error}
                                                                        helperText={error}
                                                                        name={'offer.company.name'}
                                                                        label="Поставщик"
                                                                    />
                                                                }}
                                                                onFocus={() => {
                                                                    dispatch({type: 'CONTRACT_SUPPLIERS_COMPANIES_CLEAR'})
                                                                    setFieldTouched('offer.company.name', true, true)
                                                                }}
                                                                onBlur={() => {
                                                                    setFieldTouched('offer.company.name', true, true)
                                                                }}
                                                            />
                                                        </Grid>
                                                    }
                                                </Grid>
                                                {((!!contract || AuthorizationService.roles(account, "supplier")) || (!contract && values.offer.company.name)) &&
                                                (
                                                    (values.offer.company.country.code === 'ru')
                                                        ?
                                                        <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                            <Grid item className={classes.column} xs={6}>
                                                                <Field
                                                                    fullWidth
                                                                    name="offer.company.inn"
                                                                    type="text"
                                                                    label="ИНН поставщика"
                                                                    disabled
                                                                    component={CustomTextField}
                                                                />
                                                            </Grid>
                                                            {(values.offer.company.type === 'legal') &&
                                                                <Grid item className={classes.column} xs={6}>
                                                                    <Field
                                                                        fullWidth
                                                                        name="offer.company.kpp"
                                                                        type="text"
                                                                        label="КПП поставщика"
                                                                        disabled
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                        :
                                                        <React.Fragment>
                                                            <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                                <Grid item xs={6} className={classes.column}>
                                                                    <Field
                                                                        fullWidth
                                                                        name="offer.company.bin"
                                                                        type="text"
                                                                        label="БИН поставщика"
                                                                        disabled
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </React.Fragment>
                                                )
                                                }
                                                {((contract && contract.brief) || (!contract && brief)) &&
                                                    <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                        <Grid item className={classes.column} xs={4}>
                                                            <Field
                                                                fullWidth
                                                                type="number"
                                                                name="total_price"
                                                                label="Сумма договора"
                                                                required={Boolean(!values.framework)}
                                                                step={0.01}
                                                                inputProps={{
                                                                    step: 0.01,
                                                                    onChange: (e) => {
                                                                        const {value, name} = e.target
                                                                        setValues({
                                                                            ...values,
                                                                            [name]: value,
                                                                            framework: false
                                                                        })
                                                                    },
                                                                }}
                                                                disabled={
                                                                    (!!contract && (contract.offer.lot.order.form === 'request') && settings?.additional_agreements.request) ||
                                                                    (!!contract && ([1,2,3,7].indexOf(contract.status.index) === -1)) ||
                                                                    (!!contract && !!contract.offer.lot.order.form && (contract.status.index === 1)) ||
                                                                    (!!contract && (contract.offer.lot.order.form === 'request') && (contract.status.index === 7)) ||
                                                                    (contract && (!contract.active) && ([1,2,3,7].indexOf(contract.status.index) === -1)) ||
                                                                    (!!contract && AuthorizationService.roles(account, "customer") && ((contract.active && !AuthorizationService.permissions(account, "create_delivery_order")) || (!contract.active && !AuthorizationService.permissions(account, "delivery_order_after_event")))) ||
                                                                    isSubmitting ||
                                                                    (contract && contract.framework) ||
                                                                    AuthorizationService.roles(account, "supervisor")}
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                        <Grid item className={classes.column} xs={2}>
                                                            <Field
                                                                component={CheckboxWithLabel}
                                                                name="framework"
                                                                color="primary"
                                                                required={Boolean(!values.total_price)}
                                                                disabled={Boolean(contract || AuthorizationService.roles(account, "supervisor"))}
                                                                Label={{label: "Рамочный"}}
                                                                inputProps={{
                                                                    onChange: async (event) => {
                                                                        const checked = event.target.checked
                                                                        setValues({
                                                                            ...values,
                                                                            framework: checked,
                                                                            ...(checked ? {total_price: ''} : {})
                                                                        }, true)
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item className={classes.column} xs={6}>
                                                            <Field
                                                                fullWidth
                                                                type="text"
                                                                name="currency"
                                                                label="Валюта"
                                                                select
                                                                disabled={(!!contract && ((contract.status.index !== 1) && (contract.status.index !== 7))) || (!!contract && !!contract.offer.lot.order.form && (contract.status.index === 1) && (!!contract.currency || (!contract.currency && AuthorizationService.roles(account, 'supplier')))) || (contract && (!contract.active)) || (!!contract && AuthorizationService.roles(account, "customer") && ((contract.active && !AuthorizationService.permissions(account, "create_delivery_order")) || (!contract.active && !AuthorizationService.permissions(account, "delivery_order_after_event")))) || isSubmitting}
                                                                variant="standard"
                                                                component={TextField}
                                                                required
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                            >
                                                                {
                                                                    currencies.map(currency => (
                                                                        <MenuItem key={currency.id} value={currency.id}>
                                                                            {currency.name}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Field>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                {((contract && !contract.brief) || (!contract && !brief)) &&
                                                    <React.Fragment>
                                                        <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                            <Grid item className={classes.column} xs={6}>
                                                                <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-start">
                                                                    <Grid xs={12} className={errors?.category ? classes.selectCategoryError : classes.selectCategory}>
                                                                        <Category
                                                                            isSubmitting={isSubmitting}
                                                                            label="Категория"
                                                                            name="order.category"
                                                                            value={values.order.category}
                                                                            onChange={(value) => {
                                                                                setValues({
                                                                                    ...values,
                                                                                    ...{
                                                                                        order: {
                                                                                            ...values.order,
                                                                                            category: value?.id,
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                            <Grid item className={classes.column} xs={4}>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                    <KeyboardDatePicker
                                                                        disableToolbar
                                                                        fullWidth
                                                                        error={Boolean(errors.hasOwnProperty('start') && errors.start && touched.hasOwnProperty('start') && touched.start)}
                                                                        helperText={errors.hasOwnProperty('start') && touched.hasOwnProperty('start') && (errors.start ? errors.start : null)}
                                                                        name="start"
                                                                        required
                                                                        format="dd-MM-yyyy"
                                                                        invalidDateMessage="Некорректная дата"
                                                                        orientation="landscape"
                                                                        variant="inline"
                                                                        openTo="year"
                                                                        autoOk
                                                                        label="Дата заключения"
                                                                        disabled={(!!contract && ((contract.status.index !== 1) && (contract.status.index !== 7))) || (!!contract && !!contract.offer.lot.order.form && (contract.status.index === 1)) || (!!contract && AuthorizationService.roles(account, "customer") && ((contract.active && !AuthorizationService.permissions(account, "create_delivery_order")) || (!contract.active && !AuthorizationService.permissions(account, "delivery_order_after_event")))) || isSubmitting}
                                                                        value={values.start}
                                                                        onChange={date => {
                                                                            setFieldValue("start", date, true)
                                                                        }}
                                                                        onBlur={() => {
                                                                            setFieldTouched(`start`, true, true)
                                                                        }}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </Grid>
                                                            <Grid item className={classes.column} xs={2}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="perpetual"
                                                                    color="primary"
                                                                    disabled={
                                                                        (!!contract && (contract.status.index === 2) && (contract.offer.lot.order.form === 'request') && settings?.additional_agreements.request) ||
                                                                        (!!contract && ([4,6].indexOf(contract.status.index) !== -1)) ||
                                                                        (!!contract && AuthorizationService.roles(account, "customer") && ((contract.active && !AuthorizationService.permissions(account, "create_delivery_order")) || (!contract.active && !AuthorizationService.permissions(account, "delivery_order_after_event")))) ||
                                                                        isSubmitting ||
                                                                        AuthorizationService.roles(account, "supervisor")
                                                                    }
                                                                    Label={{label: "Бессрочный"}}
                                                                    inputProps={{
                                                                        onChange: (event) => {
                                                                            setFieldValue("perpetual", event.target.checked)

                                                                            if (!event.target.checked) {
                                                                                setFieldValue("end", null)
                                                                                setFieldTouched("end", false, false)
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                            {!values.perpetual &&
                                                                <Grid item className={classes.column} xs={4}>
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                        <KeyboardDatePicker
                                                                            disableToolbar
                                                                            fullWidth
                                                                            error={Boolean(errors.hasOwnProperty('end') && errors.end && touched.hasOwnProperty('end') && touched.end)}
                                                                            helperText={errors.hasOwnProperty('end') && touched.hasOwnProperty('end') && (errors.end ? errors.end : null)}
                                                                            name="end"
                                                                            required
                                                                            format="dd-MM-yyyy"
                                                                            invalidDateMessage="Некорректная дата"
                                                                            orientation="landscape"
                                                                            variant="inline"
                                                                            openTo="year"
                                                                            autoOk
                                                                            label="Дата окончания"
                                                                            disabled={
                                                                                (!!contract && (contract.status.index === 2) && (contract.offer.lot.order.form === 'request') && settings?.additional_agreements.request) ||
                                                                                (!!contract && ([1, 2, 3, 5, 7].indexOf(contract.status.index) === -1)) ||
                                                                                (!!contract && !!contract.offer.lot.order.form && (contract.status.index === 1)) ||
                                                                                (!!contract && AuthorizationService.roles(account, "customer") && ((contract.active && !AuthorizationService.permissions(account, "create_delivery_order")) || (!contract.active && !AuthorizationService.permissions(account, "delivery_order_after_event")))) ||
                                                                                isSubmitting ||
                                                                                AuthorizationService.roles(account, "supervisor")
                                                                            }
                                                                            value={values.end}
                                                                            onChange={async (date) => {
                                                                                await setFieldValue("end", date);
                                                                                await setFieldTouched("start", true, true)
                                                                                await setFieldTouched("end", true, true)
                                                                                await validateForm().then()
                                                                            }}
                                                                            onClose={() => {
                                                                                setFieldTouched("start", true, true)
                                                                                setFieldTouched("end", true, true)
                                                                                validateForm().then()
                                                                            }}
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                        <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                            <Grid item className={classes.column} xs={6}>
                                                                <Field
                                                                    fullWidth
                                                                    type="text"
                                                                    name="type"
                                                                    label="Тип оплаты"
                                                                    select
                                                                    variant="standard"
                                                                    component={TextField}
                                                                    disabled={(!!contract && ((contract.status.index !== 1) && (contract.status.index !== 7))) || (!!contract && !!contract.offer.lot.order.form && (contract.status.index === 1)) || (contract && (!contract.active)) || (!!contract && AuthorizationService.roles(account, "customer") && ((contract.active && !AuthorizationService.permissions(account, "create_delivery_order")) || (!contract.active && !AuthorizationService.permissions(account, "delivery_order_after_event")))) || isSubmitting}
                                                                    required
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                    InputProps={{
                                                                        onChange: (event) => {
                                                                            setFieldValue('type', event.target.value, true)
                                                                            setFieldValue('postponement', '', false)
                                                                            setFieldValue('prepayment', 1, false)
                                                                        }
                                                                    }}
                                                                >
                                                                    {
                                                                        [
                                                                            {type: 'complete', name: 'Предоплата'},
                                                                            {type: 'partial', name: 'Частичная предоплата'},
                                                                            {type: 'postponement', name: 'Постоплата'}
                                                                        ].map((item, index) => (
                                                                            <MenuItem key={index} value={item.type}>
                                                                                {item.name}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </Field>
                                                            </Grid>
                                                            <Grid item className={classes.column} xs={6}>
                                                                <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                                    {((values.type === 'postponement') || (values.type === 'partial')) &&
                                                                        <Grid item xs={(values.type === 'postponement') ? 12 : 8} className={classes.column}>
                                                                            <Field
                                                                                fullWidth
                                                                                name={'postponement'}
                                                                                type="number"
                                                                                label={`${(values.type === 'postponement') ? 'Отсрочка платежа, дней' : 'Отсрочка постоплаты, дней'}`}
                                                                                required
                                                                                disabled={(!!contract && ((contract.status.index !== 1) && (contract.status.index !== 7))) || (!!contract && !!contract.offer.lot.order.form && (contract.status.index === 1)) || (!!contract && AuthorizationService.roles(account, "customer") && ((contract.active && !AuthorizationService.permissions(account, "create_delivery_order")) || (!contract.active && !AuthorizationService.permissions(account, "delivery_order_after_event")))) || isSubmitting}
                                                                                step={1}
                                                                                inputProps={{
                                                                                    min: 0
                                                                                }}
                                                                                component={CustomTextField}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {(values.type === 'partial') &&
                                                                        <Grid item xs={4} className={classes.column}>
                                                                            <Field
                                                                                fullWidth
                                                                                name={'prepayment'}
                                                                                type="number"
                                                                                label="Предоплата"
                                                                                required
                                                                                disabled={(!!contract && ((contract.status.index !== 1) && (contract.status.index !== 7))) || (!!contract && !!contract.offer.lot.order.form && (contract.status.index === 1)) || (!!contract && AuthorizationService.roles(account, "customer") && ((contract.active && !AuthorizationService.permissions(account, "create_delivery_order")) || (!contract.active && !AuthorizationService.permissions(account, "delivery_order_after_event")))) || isSubmitting}
                                                                                step={1}
                                                                                inputProps={{
                                                                                    min: 1,
                                                                                    max: 100
                                                                                }}
                                                                                endAdornment={'%'}
                                                                                component={CustomTextField}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                            <Grid item className={classes.column} xs={4}>
                                                                <Field
                                                                    fullWidth
                                                                    type="number"
                                                                    name="total_price"
                                                                    label="Сумма договора"
                                                                    required={Boolean(!values.framework)}
                                                                    step={0.01}
                                                                    inputProps={{
                                                                        step: 0.01,
                                                                        onChange: (e) => {
                                                                            const {value, name} = e.target
                                                                            setValues({
                                                                                ...values,
                                                                                [name]: value,
                                                                                framework: false
                                                                            })
                                                                        },
                                                                    }}
                                                                    disabled={
                                                                        (!!contract && (contract.offer.lot.order.form === 'request') && (settings?.additional_agreements.request || (contract.status.index === 7))) ||
                                                                        (contract && ([1,2,3,7].indexOf(contract.status.index) === -1)) ||
                                                                        (!!contract && !!contract.offer.lot.order.form && (contract.status.index === 1)) ||
                                                                        (contract && (!contract.active) && ([1,2,3,7].indexOf(contract.status.index) === -1)) ||
                                                                        (!!contract && AuthorizationService.roles(account, "customer") && ((contract.active && !AuthorizationService.permissions(account, "create_delivery_order")) || (!contract.active && !AuthorizationService.permissions(account, "delivery_order_after_event")))) ||
                                                                        isSubmitting ||
                                                                        (contract && contract.framework) ||
                                                                        AuthorizationService.roles(account, "supervisor")}
                                                                    component={CustomTextField}
                                                                />
                                                            </Grid>
                                                            <Grid item className={classes.column} xs={2}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="framework"
                                                                    color="primary"
                                                                    required={Boolean(!values.total_price)}
                                                                    disabled={Boolean(contract || AuthorizationService.roles(account, "supervisor"))}
                                                                    Label={{label: "Рамочный"}}
                                                                    inputProps={{
                                                                        onChange: async (event) => {
                                                                            const checked = event.target.checked
                                                                            setValues({
                                                                                ...values,
                                                                                framework: checked,
                                                                                ...(checked ? {total_price: ''} : {})
                                                                            }, true)
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item className={classes.column} xs={6}>
                                                                <Field
                                                                    fullWidth
                                                                    type="text"
                                                                    name="currency"
                                                                    label="Валюта"
                                                                    select
                                                                    disabled={(contract && ((contract.status.index !== 1) && (contract.status.index !== 7))) || (!!contract && !!contract.offer.lot.order.form && !!contract.currency) || (contract && (!contract.active)) || (!!contract && AuthorizationService.roles(account, "customer") && ((contract.active && !AuthorizationService.permissions(account, "create_delivery_order")) || (!contract.active && !AuthorizationService.permissions(account, "delivery_order_after_event")))) || isSubmitting}
                                                                    variant="standard"
                                                                    component={TextField}
                                                                    required
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                >
                                                                    {
                                                                        currencies.map(currency => (
                                                                            <MenuItem key={currency.id} value={currency.id}>
                                                                                {currency.name}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </Field>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                            <Grid item className={classes.column} xs={6}>
                                                                <Field
                                                                    fullWidth
                                                                    name="contract_time"
                                                                    type="text"
                                                                    label="Минимальный срок поставки, дней"
                                                                    disabled={(contract && ((contract.status.index !== 1) && (contract.status.index !== 7))) || (!!contract && !!contract.offer.lot.order.form && (contract.status.index === 1)) || (contract && (!contract.active)) || (!!contract && AuthorizationService.roles(account, "customer") && ((contract.active && !AuthorizationService.permissions(account, "create_delivery_order")) || (!contract.active && !AuthorizationService.permissions(account, "delivery_order_after_event")))) || isSubmitting}
                                                                    component={CustomTextField}
                                                                />
                                                            </Grid>
                                                            <Grid item className={classes.column} xs={2}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="without_specification"
                                                                    color="primary"
                                                                    disabled={(contract && !AuthorizationService.roles(account, "customer")) || isSubmitting}
                                                                    Label={{label: "Без спецификации"}}
                                                                    inputProps={{
                                                                        onChange: async (event) => {
                                                                            const checked = event.target.checked
                                                                            await setFieldValue("without_specification", checked)
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </React.Fragment>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4} className={classes.additionally}>
                                        <Grid container className={classes.fullWidth} style={{"height": "100%"}} direction="column" justify="flex-start" alignItems="stretch">
                                            <Grid item className={classes.right}>
                                                <CustomList style={{"height": "100%"}}>
                                                    <ListItem button>
                                                        <ListItemText primary="Файлы договора" />
                                                    </ListItem>
                                                    <List component="div" style={{"overflow": "auto", "height": "calc(100% - 50px)"}} disablePadding>
                                                        {
                                                            !!files.length && files.map((file, index) => (
                                                                <ListItem button component="a" key={index} classes={{root: classes.item}} style={{"cursor": "pointer"}}>
                                                                    <ListItemIcon>
                                                                        <AttachFileIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText secondary={`${file.file.name} (новый)`}/>
                                                                    <ListItemSecondaryAction>
                                                                        <Grid container direction="row" justify="flex-end" alignItems="stretch" spacing={2}>
                                                                            {file.sign ? (
                                                                              <Grid item>
                                                                                  <DownloadSignature file={{name: file.file.name, sign: file.sign}} />
                                                                              </Grid>
                                                                            ) : null}
                                                                            <Grid item>
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        setFiles(files.filter((_, i) => i !== index))
                                                                                    }}
                                                                                >
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                            ))
                                                        }
                                                        {
                                                            contract && contract.log.filter(log => log.files.length).map(log => {

                                                                return log.files.map(file => (
                                                                    <ListItem button component="a" key={file.id} classes={{root: classes.item}} href={process.env.REACT_APP_HOST_API + `/shop/order/${contract.offer.lot.order.id}/file/${file.id}?token=${AppStorage.get('token').replace('Bearer ', '')}`} style={{"cursor": "pointer"}}>
                                                                        <ListItemIcon>
                                                                            <AttachFileIcon />
                                                                        </ListItemIcon>
                                                                        <ListItemText secondary={file.name}/>
                                                                        <ListItemSecondaryAction>
                                                                            <Grid container direction="row" justify="flex-end" alignItems="stretch" spacing={2}>
                                                                                {file.sign ? (
                                                                                  <Grid item>
                                                                                      <DownloadSignature file={file} />
                                                                                  </Grid>
                                                                                ) : null}
                                                                                <Grid item>
                                                                                    <IconButton
                                                                                        disabled={(contract && (((contract.status.index !== 1) && (contract.status.index !== 7)) || (AuthorizationService.roles(account, "customer") && ((contract.active && !AuthorizationService.permissions(account, "create_delivery_order")) || (!contract.active && !AuthorizationService.permissions(account, "delivery_order_after_event"))))) || ['draft_agreement'].includes(file.type?.key))}
                                                                                        onClick={() => {
                                                                                            dispatch(ContractActions.deleteFile(contract.offer.lot.order.id, file.id))
                                                                                        }}
                                                                                    >
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                ))
                                                            })
                                                        }
                                                    </List>
                                                </CustomList>
                                            </Grid>
                                            {contract &&
                                                <Grid item className={classes.right}>
                                                    <CustomList style={{"height": "100%"}}>
                                                        <ListItem button>
                                                            <ListItemText primary="История" />
                                                        </ListItem>
                                                        <List component="div" style={{"overflow": "auto", "height": "calc(100% - 103px)"}} disablePadding>
                                                            {
                                                                log && log.map(log => (
                                                                    <ListItem key={log.id} classes={{root: classes.item}}>
                                                                        <ListItemText primary={
                                                                            <React.Fragment>
                                                                                <Grid container direction="row" justify="flex-start" alignItems="stretch">
                                                                                    <Grid item xs={6}>
                                                                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                                                                            <Grid item xs={12}>
                                                                                                <Typography component={'span'} variant="body1">{format(new Date(log.created), 'PP H:mm', {locale: ru})}</Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Tooltip
                                                                                                    title={log.comment}
                                                                                                    placement="bottom"
                                                                                                >
                                                                                                    <Typography component="div" className={classes.eclipsis}>{log.comment ?? log.status.name}</Typography>
                                                                                                </Tooltip>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid item xs={6}>
                                                                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                                                                            <Grid item xs={12}>
                                                                                                <Typography component={'span'} variant="body1">{log.user ? (AuthorizationService.roles(log.user, "customer") ? 'Заказчик' : 'Поставщик') : 'Система'}</Typography>
                                                                                            </Grid>
                                                                                            {log.user &&
                                                                                                <Grid item xs={12}>
                                                                                                    <Typography component="div" className={classes.eclipsis}>{log.user.last_name} {log.user.first_name} {log.user.middle_name}</Typography>
                                                                                                </Grid>
                                                                                            }
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                        }/>
                                                                    </ListItem>
                                                                ))
                                                            }
                                                        </List>
                                                        <Grid>
                                                        <ListItem className={classes.paginationWrap}>
                                                            <TablePagination
                                                                rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                                                component='div'
                                                                className={classes.pagination}
                                                                labelRowsPerPage={'Записей на странице:'}
                                                                count={(contract && log) ? contract.log.length : 0}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page - 1}
                                                                onChangePage={handleChangePage}
                                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                                            />
                                                        </ListItem>
                                                        </Grid>
                                                    </CustomList>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions className={classes.footer}>
                                {!AuthorizationService.roles(account, "supervisor") &&
                                    <Grid container className={classes.footerContent} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                {(contract && AuthorizationService.roles(account, "customer")) &&
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Submit
                                                                    variant="contained"
                                                                    color="primary"
                                                                    type="button"
                                                                    disabled={(contract.status.index !== 2) || !contract.offer.count.items || (contract && (contract.status.index === 3))}
                                                                    component={Link}
                                                                    to={`/catalog/products?contract_id=${contract.id}`}
                                                                    onClick={async () => {
                                                                        await dispatch({type: "CATALOG_PRODUCTS_FILTER", payload: {...filter, ...{contract: {id: contract.id}}}})
                                                                    }}

                                                                >
                                                                    Каталог
                                                                </Submit>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                {(contract && AuthorizationService.roles(account, "supplier")) && contract.price_id &&
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Submit
                                                                    variant="contained"
                                                                    color="primary"
                                                                    type="button"
                                                                    disabled={!contract.offer.count.items || (contract && (contract.status.index === 3))}
                                                                    component={Link}
                                                                    to={`/supplier/catalog/products?contract_id=${contract.id}`}
                                                                    onClick={async () => {
                                                                        await dispatch({type: "CATALOG_PRODUCTS_FILTER", payload: {...filter, ...{contract: {id: contract.id}}}})
                                                                    }}

                                                                >
                                                                    Каталог
                                                                </Submit>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                {isViewEditPriceButton() &&
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Submit
                                                                    variant="contained"
                                                                    component="label"
                                                                    disabled={Boolean(contract && (contract.status.index === 3))}
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        if (contract.price_id) {
                                                                            return dispatch(PriceActions.getById(contract.id, contract.price_id)).then((resp) => {
                                                                                if (resp.price.status === 'in_process') {
                                                                                    return dispatch({
                                                                                        type: "ALERT_INFO", payload: {
                                                                                            message: 'Подождите,' +
                                                                                                ' прайс-лист' +
                                                                                                ' в' +
                                                                                                ' обработке...',
                                                                                            type: 'info'
                                                                                        }
                                                                                    })
                                                                                }
                                                                                dispatch({
                                                                                    type: "SET_ACTIVE_PRICE", payload: {
                                                                                        priceId: resp.price.id,
                                                                                        type: resp.price.type.name,
                                                                                        company_id: resp.price.company_id,
                                                                                        name: getPriceName(resp.price),
                                                                                        category: resp?.category
                                                                                    }
                                                                                })
                                                                                openEditPriceDialog(contract.id, true)
                                                                            })
                                                                        }
                                                                    }}
                                                                >
                                                                    {AuthorizationService.roles(account, 'supplier') ? 'Редактировать прайс-лист' : 'Редактировать спецификацию'}
                                                                </Submit>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                {isViewLoadPriceButton() &&
                                                    <Grid item>
                                                        <Submit
                                                            variant="contained"
                                                            component="label"
                                                            color="primary"
                                                            disabled={Boolean(contract && (contract.status.index === 3))}
                                                            onClick={(e) => sendPrice(e, contract)}
                                                        >
                                                            {AuthorizationService.roles(account, 'supplier') ? 'Загрузить прайс-лист' : 'Загрузить спецификацию'}
                                                        </Submit>
                                                    </Grid>
                                                }
                                                {(AuthorizationService.roles(account, 'supplier') || (contract && AuthorizationService.roles(account, "customer") && ((contract.active && AuthorizationService.permissions(account, "create_delivery_order")) || (!contract.active && AuthorizationService.permissions(account, "delivery_order_after_event")))) || !contract) &&
                                                    <Grid item>
                                                        <Submit
                                                            variant="contained"
                                                            component="label"
                                                            color="primary"
                                                            disabled={(contract && (contract.status.index === 4)) || (contract && (contract.status.index === 6)) || (contract && (contract.status.index === 3))}
                                                        >
                                                            Добавить файл
                                                            <input
                                                                multiple
                                                                type="file"
                                                                style={{display: "none"}}
                                                                onChange={(event) => {
                                                                    const items = event.target.files

                                                                    if (!!items.length) {
                                                                        setSign({open: true, files: [...items]})
                                                                    }

                                                                    event.target.value = null
                                                                }}
                                                            />
                                                        </Submit>
                                                        {sign.open ? (
                                                          <Signature
                                                            open={sign.open}
                                                            onClose={() => setSign({open: false, files: []})}
                                                            files={sign.files}
                                                            onContinue={(objects) => {
                                                                setSign({open: false, files: []})
                                                                setFiles([...files, ...objects])
                                                            }}
                                                          />
                                                        ) : null}
                                                    </Grid>
                                                }
                                                {contract
                                                    ? <React.Fragment>
                                                        <AdditionalAgreement
                                                            settings={settings}
                                                            contract={contract}
                                                            onClick={() => {
                                                                setReload(true)
                                                            }}
                                                        />
                                                        <Grid item>
                                                            <Submit
                                                                disableElevation
                                                                variant="contained"
                                                                color="primary"
                                                                type="button"
                                                                onClick={() => {
                                                                    submitForm().then(() => {})
                                                                }}
                                                                disabled={(contract && ((contract.status.index === 4) || (contract.status.index === 6))) || (!dirty && !files.length) || isSubmitting || !isValid}
                                                            >
                                                                Сохранить
                                                            </Submit>
                                                        </Grid>
                                                        <Reject
                                                            contract={contract}
                                                        />
                                                        {((((contract.status.index === 1) || (contract.status.index === 7)) && ((AuthorizationService.roles(account, 'customer') && ((contract.active && AuthorizationService.permissions(account, "create_delivery_order")) || (!contract.active && AuthorizationService.permissions(account, "delivery_order_after_event"))) && contract.creator === 'supplier') || (AuthorizationService.roles(account, 'supplier') && (contract.creator === 'customer' || contract.creator === 'system')))) || ((contract.status.index === 8) && AuthorizationService.roles(account, 'customer') && AuthorizationService.permissions(account, "create_delivery_order"))) &&
                                                            <Grid item>
                                                                <Submit
                                                                    disableElevation
                                                                    variant="contained"
                                                                    color="primary"
                                                                    type="button"
                                                                    disabled={(contract.status.index === 7 && !(contract.log.find(log => log.status.index === 7) ? contract.log.find(log => log.status.index === 7).files.length : true)) || (contract.status.index === 8 && !(contract.log.find(log => log.status.index === 8) ? contract.log.find(log => log.status.index === 8).files.length : true))}
                                                                    onClick={() => {
                                                                        dispatch(ContractActions.approve(contract.id))
                                                                    }}
                                                                >
                                                                    {(contract.status.index === 1) ? 'Согласовать' : 'Подписать'}
                                                                </Submit>
                                                            </Grid>
                                                        }
                                                    </React.Fragment>
                                                    : <Grid item>
                                                        <Submit
                                                            disableElevation
                                                            variant="contained"
                                                            color="primary"
                                                            type="button"
                                                            onClick={() => {
                                                                if (!!supplier && !files.length) {
                                                                    dispatch({
                                                                        type: "ALERT_ERROR",
                                                                        payload: {
                                                                            message: "Должен быть прикреплен хотя бы один файл",
                                                                            type: 'error'
                                                                        }
                                                                    })
                                                                } else {
                                                                    submitForm().then(() => {
                                                                    })
                                                                }
                                                            }}
                                                            disabled={!dirty || isSubmitting || !isValid}
                                                        >
                                                            Создать
                                                        </Submit>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
                {(contract && !AuthorizationService.roles(account, "supervisor")) &&
                    <PriceDialog  open={openPriceDialog} onClose={handleClose} contract={contract}/>
                }
                {(contract && !AuthorizationService.roles(account, "supervisor") && contract.price_id) &&
                    <EditPriceDialog  open={openEditPrice} onClose={handleEditPriceClose} contract={contract}/>
                }
            </Dialog>
        </Grid>
    ) : null
}
