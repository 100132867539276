import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebouncedCallback} from 'use-debounce'
import format from "date-fns/format"
import {ru} from "date-fns/locale"
import clsx from "clsx"

import {Grid, Icon, InputAdornment, makeStyles, TextField, Typography} from "@material-ui/core"
import {History as HistoryIcon, Search as SearchIcon} from "@material-ui/icons"

import {CompanyActions} from "../../actions/company"
import {history} from "../../../App/helpers/history"
import {ButtonList} from "../../../App/components/ButtonList/ButtonList"
import {supplierStatuses} from '../../../App/dics/dic'
import {localState} from "../../../App/components/LocalState";
import {SettingsActions} from "../../../Settings/actions/settings";
import {CustomTable} from "../../../App/components/Table/Table";
import {TableHeader} from "../../../App/components/Table/TableHeader";
import {Table} from "@devexpress/dx-react-grid-material-ui";
import {ColumnChooser} from "../../../App/components/Table/ColumnChooser";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 223px)",
        "min-height": "calc(100vh- 223px)"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    buttonList: {
        width: "fit-content",
        "& .MuiTabs-scroller ": {
            "justify-content": "flex-start"
        },
    },
}))

const columns = [
    {name: 'company', title: 'Наименование организации'},
    {name: 'id', title: 'ID'},
    {name: 'email', title: 'Эл. адрес'},
    {name: 'inn', title: 'ИНН/БИН'},
    {name: 'kpp', title: 'КПП'},
    {name: 'created', title: 'Дата подачи заявки'},
    {name: 'confirmed', title: 'Дата подписания заявки'},
    {name: 'status', title: 'Статус'},
];

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [suppliers, setSuppliers] = useState(localState)

    const [page, setPage] = useState(1)
    const [tableRef, setTableRef] = useState(null)
    const {filter} = useSelector(state => state.filters['suppliers'])
    const [search, setSearch] = useState(filter.search)
    const debounced = useDebouncedCallback(
        (value) => {
            dispatch({
                type: "SUPPLIERS_FILTER",
                payload: {search: value}
            })
        },
        900
    );
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [status, setStatus] = useState(0)

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'company', width: 300},
        {columnName: 'id', width: 150},
        {columnName: 'email', width: 200},
        {columnName: 'inn', width: 150},
        {columnName: 'kpp', width: 150},
        {columnName: 'created', width: 150},
        {columnName: 'confirmed', width: 150},
        {columnName: 'status', width: 150},
    ])

    const [columnOrder, setColumnOrder] = useState([
        'company',
        'id',
        'email',
        'inn',
        'kpp',
        'created',
        'confirmed',
        'status'
    ])

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const [initialize, setInitialize] = useState(false);

    useEffect(() => {
        debounced.callback(search)
        // eslint-disable-next-line
    }, [search])

    useEffect(() => {
        if (!initialize) {
            dispatch(SettingsActions.table('suppliers')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                dispatch({type: "FILTER_CLEAR"})
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'suppliers',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...(search ? {search: search} : {}),
            ...(status ? {status: supplierStatuses[status].key} : {}),
        }
    }

    useEffect(() => {
        const getSuppliers = async () => {
            return await dispatch(CompanyActions.suppliers({
                limit: rowsPerPage,
                page: page,
                ...getParams()
            }))
        }

        getSuppliers().then((response) => {
            setSuppliers(response)
            tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
        })
    }, [dispatch, page, rowsPerPage, status, filter.sort, filter.search]);

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: 'SUPPLIERS_FILTER', payload: {limitRows: newRowsPerPage}})
    }

    const tabChange = (e, newValue) => {
        setStatus(newValue);
    }

    return <Grid item className={classes.content}>
        <Grid item>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item className={classes.fullWidth}>
                    <TextField
                        id="search"
                        variant="standard"
                        name={'search'}
                        value={search ?? ''}
                        placeholder={'Поиск: название, ИНН/БИН, КПП, адрес, ФИО, номер телефона'}
                        fullWidth={true}
                        margin={'normal'}
                        onChange={(event) => {
                            setSearch(event.target.value)
                        }}
                        classes={{
                            root: clsx(classes.filterLabel)
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon style={{color: '#c1c1c1'}}/>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                        <Grid item className={classes.buttonList}>
                            <ButtonList value={status} values={supplierStatuses} onChange={tabChange}/>
                        </Grid>
                        <Grid item style={{marginTop: '-14px'}}>
                            <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <CustomTable
            meta={suppliers.meta}
            tableName={'suppliers'}
            rows={suppliers?.data.map(supplier => ({
                company: <div style={{display: 'flex', alignItems:' center', padding: '8px'}}>
                    <Icon style={{marginRight: "8px"}}>
                        <img src={supplier.status ? supplier.status.icon : ''} alt={''}/>
                    </Icon>
                    <Typography component="div" noWrap>{!!supplier.legal_detail && supplier.legal_detail.name}</Typography>
                    {supplier.creator ? <HistoryIcon style={{marginLeft: "5px"}}/> : null}
                </div>,
                id: supplier.id,
                email: !!supplier.contact_information && supplier.contact_information.emails,
                inn: !!supplier.legal_detail && (supplier.legal_detail.inn ?? supplier.legal_detail.bin),
                kpp: !!supplier.legal_detail ? supplier.legal_detail.kpp : null,
                created: format(new Date(supplier.created), 'PP', {locale: ru}),
                confirmed: supplier.confirmed ? format(new Date(supplier.confirmed), 'PP', {locale: ru}) : null,
                status: supplier.egrn_status?.name
            }))}
            columns={columns}
            page={{page: page, setPage: setPage, rowsPerPage: rowsPerPage, handlePageSizeChange: handlePageSizeChange}}
            setTableRef={setTableRef}
            columnsSettings={{
                columnOrder: columnOrder,
                setColumnOrder: setColumnOrder,
                setColumnWidths: setColumnWidths,
                columnWidths: columnWidths,
                hiddenColumnNames: hiddenColumnNames,
                setHiddenColumnNames: setHiddenColumnNames
            }}
            tableHeader={TableHeader}
            filterActionType={'SUPPLIERS_FILTER'}
            classInputContent={classes.inputContent}
            rowComponent={({row, tableRow, children}) => (
                <Table.Row
                    tableRow={tableRow}
                    children={children}
                    className={classes.tableRow}
                    row={row}
                    onClick={(e, _) => {
                        history.push(`/supplier/${suppliers.data[tableRow.rowId].id}`)
                    }}
                />
            )}
            filters={{
                email: {
                    nullButton: true
                }
            }}
        />
    </Grid>
}
