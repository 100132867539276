import React, {useState} from "react"

import {Grid, IconButton, makeStyles, TextField, Typography} from "@material-ui/core"
import {Clear, Check} from '@material-ui/icons'

const useStyles = makeStyles(() => ({
    button: {
        fontSize: "16px",
        padding: "0",
        margin: "0 2px",
    },
    iconCheck: {
        color: "#fff",
        backgroundColor: "#898989",
        borderRadius: "4px",
        width: "21px",
        height: "21px",
    },
    iconCancel: {
        color: "#fff",
        backgroundColor: "#d9534f",
        borderRadius: "4px",
        width: "21px",
        height: "21px",
    },
    label: {
        fontSize: "16px",
        width: "auto",
        height: "auto",
        borderBottom: "dashed 1px #000",
        cursor: "pointer",
    },
}))

export const NumberButton = (props) => {
    const classes = useStyles()

    const {name, onSubmit, onSuccess, display, disabled = false, params = {}} = props
    const [value, setValue] = useState(props.value)
    const [show, setShow] = useState(false)

    return show
        ? <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
            <Grid item>
                <TextField
                    type="number"
                    onChange={(e) => {
                        setValue(e.target.value)
                    }}
                    name={name}
                    value={value}
                    inputProps={params}
                />
            </Grid>
            <Grid item>
                <IconButton
                    className={classes.button}
                    onClick={(e) => {
                        e.stopPropagation()
                        onSubmit(value).then(() => {
                            onSuccess(value)
                            setShow(false)
                        })
                    }}
                >
                    <Check className={classes.iconCheck}/>
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton
                    className={classes.button}
                    onClick={(e) => {
                        e.stopPropagation()
                        setValue(props.value)
                        setShow(false)
                    }}
                >
                    <Clear className={classes.iconCancel}/>
                </IconButton>
            </Grid>
        </Grid>
        : <Typography
            className={classes.label}
            onClick={(e) => {
                e.stopPropagation()
                setShow(true)
            }}
            style={{ pointerEvents: disabled ? 'none' : 'all' }}
        >
            {display ? display(value) : value}
        </Typography>
}
