import {PriceService} from "../services/price"
import {ShopService} from "../services/shop"

export const PriceActions = {
    get,
    download,
    downloadFromElmag,
    del,
    getProducts,
    updateProduct,
    imageProduct,
    setStandard,
    types,
    getStandards,
    addProduct,
    getProduct,
    pricesContract,
    getProductsWithCategory,
    setCategory,
    unsetCategory,
    setCanBeOrdered,
    getById,
    unsetStandardInfo,
}

function pricesContract(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: "LOADING", payload: true })
        if (!params.hasOwnProperty('limit')) {
            params.limit = 10;
        }
        if (!params.hasOwnProperty('page')) {
            params.page = 1;
        }
        ShopService.pricesContract(params)
            .then(
                price => {
                    dispatch({ type: "PRICE", payload: price })
                    dispatch({ type: "LOADING", payload: false })
                    resolve(price)
                },
                error => {
                    dispatch({ type: "PRICE_FAILURE" })
                    dispatch({ type: "LOADING", payload: false })
                    reject(error.error)
                }
            )
    })
}

function get(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: "LOADING", payload: true })
        if (!params.hasOwnProperty('limit')) {
            params.limit = 10;
        }
        if (!params.hasOwnProperty('page')) {
            params.page = 1;
        }
        ShopService.prices(params)
            .then(
                price => {
                    dispatch({ type: "PRICE", payload: price })
                    dispatch({ type: "LOADING", payload: false })
                    resolve(price)
                },
                error => {
                    dispatch({ type: "PRICE_FAILURE" })
                    dispatch({ type: "LOADING", payload: false })
                    reject(error.error)
                }
            )
    })
}

function del(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: "PRICE_DELETE_REQUEST" })
        dispatch({ type: "LOADING", payload: true })

        PriceService.del(id)
            .then(
                resp => {
                    dispatch({ type: "PRICE_DELETE", payload: resp })
                    dispatch({ type: "LOADING", payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: "PRICE_DELETE_FAILURE" })
                    dispatch({ type: "LOADING", payload: false })
                    dispatch({ type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }  })
                    reject(error.error)
                })

    })
}

function getById(id, price_id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: "PRICE_GET_REQUEST" })
        dispatch({ type: "LOADING", payload: true })

        PriceService.getById(id, price_id)
            .then(
                resp => {
                    dispatch({ type: "PRICE_GET", payload: resp })
                    dispatch({ type: "LOADING", payload: false })
                    resolve(resp)
                },
                error => {
                    dispatch({ type: "PRICE_GETFAILURE" })
                    dispatch({ type: "LOADING", payload: false })
                    dispatch({ type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }  })
                    reject(error.error)
                })

    })
}


function download(body) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "PRICE_SAVE_REQUEST"})
        dispatch({type: "LOADING", payload: true})

        PriceService.download(body)
            .then(
                resp => {
                    dispatch({type: "PRICE_SAVE", payload: resp})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_SUCCESS", payload: {
                            message: 'Успешно загружен',
                            type: 'success'
                        }
                    })
                    resolve(resp)
                },
                error => {
                    dispatch({type: "PRICE_SAVE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.error)
                })
    })
}


function downloadFromElmag(id, formData) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: "PRICE_SAVE_ELMAG_REQUEST" })
        dispatch({ type: "LOADING", payload: true })
        ShopService.downloadFromElmag(id, formData)
            .then(
                resp => {
                    dispatch({type: "PRICE_SAVE_ELMAG_SUCCESS", payload: resp.contract})
                    dispatch({ type: "LOADING", payload: false })
                    dispatch({
                        type: "ALERT_SUCCESS", payload: {
                            message:'Успешно загружен',
                            type:'success'
                        }
                    })
                    resolve(resp)
                },
                error => {
                    dispatch({ type: "PRICE_SAVE_FAILURE" })
                    dispatch({ type: "LOADING", payload: false })
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }  })
                    reject(error.error)
                })

    })
}

function addProduct(formData) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "PRODUCT_SAVE_REQUEST"})
        dispatch({type: "LOADING", payload: true})
        PriceService.addProduct(formData)
            .then(
                resp => {
                    dispatch({type: "PRODUCT_SAVE_SUCCESS", payload: resp})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_SUCCESS", payload: {
                            message: 'Успешно добавлен',
                            type: 'success'
                        }
                    })
                    resolve()
                },
                error => {
                    dispatch({type: "PRODUCT_SAVE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.error)
                })
    })
}


function getProducts(params = null) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "PRODUCT_REQUEST"})
        dispatch({type: "LOADING", payload: true})
        if (!params.hasOwnProperty('limit')) {
            params.limit = 10;
        }
        if (!params.hasOwnProperty('page')) {
            params.page = 1;
        }
        PriceService.getProducts(params)
            .then(
                product => {
                    dispatch({type: "PRODUCT", payload: product})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PRODUCT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }  })
                    reject(error.error)
                }
            )
    })
}

function setStandard(id, params = null) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "UPDATE_STANDARD_REQUEST"})
        dispatch({type: "LOADING", payload: true})
        PriceService.setStandard(id, params)
            .then(
                () => {
                    dispatch({type: "UPDATE_STANDARD_SUCCESS", payload: id})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                        message: "Успешно обновлено",
                        type:'success'
                    }  })
                    resolve()
                },
                error => {
                    dispatch({type: "UPDATE_STANDARD_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }  })
                    reject(error.error)
                }
            )
    })
}

function updateProduct(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "UPDATE_PRODUCT_REQUEST"})
        ShopService.updateProduct(id, values)
            .then(
                product => {
                    dispatch({type: "UPDATE_PRODUCT_SUCCESS", payload: product})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                        message: "Успешно обновлено",
                        type:'success'
                    }  })
                    resolve()
                },
                error => {
                    dispatch({type: "UPDATE_PRODUCT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.error)
                }
            )
    })
}

function imageProduct(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "UPDATE_IMAGE_PRODUCT_REQUEST"})
        ShopService.imageProduct(id, values)
            .then(
                () => {
                    dispatch({type: "UPDATE_IMAGE_PRODUCT_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                        message: "Успешно обновлено",
                        type:'success'
                    }  })
                    resolve()
                },
                error => {
                    dispatch({type: "UPDATE_IMAGE_PRODUCT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.error)
                }
            )
    })
}

function types() {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: "LOADING", payload: true })
        PriceService.types()
            .then(
                types => {
                    dispatch({ type: "PRICE_TYPES", payload: types })
                    dispatch({ type: "LOADING", payload: false })
                    resolve(types)
                },
                error => {
                    dispatch({ type: "PRICE_TYPES_FAILURE" })
                    dispatch({ type: "LOADING", payload: false })
                    reject(error.error)
                }
            )
    })
}

function getStandards(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: "LOADING", payload: true })
        PriceService.getStandards(params)
            .then(
                standards => {
                    dispatch({ type: "STANDARDS_SUCCESS", payload: standards })
                    dispatch({ type: "LOADING", payload: false })
                    resolve(standards)
                },
                error => {
                    dispatch({ type: "STANDARDS_FAILURE" })
                    dispatch({ type: "LOADING", payload: false })
                    reject(error.error)
                }
            )
    })
}

function getProduct(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: "LOADING", payload: true })
        ShopService.getProduct(id)
            .then(
                product => {
                    dispatch({ type: "GET_PRODUCT_SUCCESS", payload: product })
                    dispatch({ type: "LOADING", payload: false })
                    resolve(product)
                },
                error => {
                    dispatch({ type: "GET_PRODUCT_FAILURE" })
                    dispatch({ type: "LOADING", payload: false })
                    reject(error.error)
                }
            )
    })
}

function getProductsWithCategory(params = null) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "GET_PRODUCT_WITH_CATEGORY_REQUEST"})
        dispatch({type: "LOADING", payload: true})
        if (!params.hasOwnProperty('limit')) {
            params.limit = 10;
        }
        if (!params.hasOwnProperty('page')) {
            params.page = 1;
        }
        PriceService.getProductsWithCategory(params)
            .then(
                products => {
                    dispatch({type: "GET_PRODUCT_WITH_CATEGORY_SUCCESS", payload: products})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "GET_PRODUCT_WITH_CATEGORY_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }  })
                    reject(error.error)
                }
            )
    })
}

function setCategory(params = null) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        PriceService.setCategory(params)
            .then(
                products => {
                    dispatch({type: "SET_CATEGORY_SUCCESS", payload: products})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_SUCCESS", payload: {
                            message: "Товары обновятся в ближайшее время",
                            type: 'success'
                        }
                    })
                    resolve(products)
                },
                error => {
                    dispatch({type: "SET_CATEGORY_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }  })
                    reject(error.error)
                }
            )
    })
}

function unsetCategory(params = null) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        PriceService.unsetCategory(params)
            .then(
                products => {
                    dispatch({type: "UNSET_CATEGORY_SUCCESS", payload: products})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_SUCCESS", payload: {
                            message: "Товары обновятся в ближайшее время",
                            type: 'success'
                        }
                    })
                    resolve()
                },
                error => {
                    dispatch({type: "UNSET_CATEGORY_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }  })
                    reject(error.error)
                }
            )
    })
}

function setCanBeOrdered(params = null) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        PriceService.setCanBeOrdered(params)
            .then(
                products => {
                    dispatch({type: "SET_CAN_BE_ORDERED_SUCCESS", payload: products})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_SUCCESS", payload: {
                            message: "Успешно обновлено",
                            type: 'success'
                        }
                    })
                    resolve()
                },
                error => {
                    dispatch({type: "SET_CAN_BE_ORDERED_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }  })
                    reject(error.error)
                }
            )
    })
}

function unsetStandardInfo(id){
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: "LOADING", payload: true })

        ShopService.unsetStandardInfo(id)
            .then(
                () => {
                    dispatch({ type: "UNSET_STANDARD_INFO_SUCCESS"})
                    dispatch({ type: "LOADING", payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: "UNSET_STANDARD_INFO_FAILURE" })
                    dispatch({ type: "LOADING", payload: false })
                    reject(error.error)
                }
            )
    })
}
