import React, {useEffect, useRef} from 'react'
import {Field, Form, Formik} from "formik"
import * as Yup from "yup"
import format from "date-fns/format"
import {ru} from "date-fns/locale"

import {Drawer, Grid, IconButton, makeStyles, Typography} from '@material-ui/core'
import {Send as SendIcon} from '@material-ui/icons'
import {useChat} from './Chat/Hooks/useChat'
import {MessageLeft, MessageRight} from "./Message"
import {TextFieldWithError as CustomTextField} from "../../../App/components/Inputs/TextFieldWithError";

const useStyles = makeStyles(() => ({
    wrapper: {
        maxWidth: "50vw",
        margin: "0 auto"
    },
    title: {
        padding: "5px 0",
        backgroundColor: "#f3f3f3",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        fontWeight: 500,
        textDecoration: "underline"
    },
    content: {
        padding: "0 15px 25px 15px",
        position: 'relative',
        // height: "calc(100vh - 500px)",
        height: "100vh",
        background: "#f3f3f3",
        overflow: "hidden",
    },
    contentMessages: {
        padding: "30px 10px 30px 0",
        margin: "0",
        minHeight: "calc(100% - 70px)",
        overflowX: "hidden",
        overflowY: "scroll",
        height: "calc(100% - 70px)",
        position: "relative"
    },
    dateDelimiterStick: {
        backgroundColor: "rgba(102, 178, 255, 0.15)",
        width: "fit-content",
        display: "flex",
        padding: "5px",
        borderRadius: "6px",
        fontWeight: "500",
        position: "fixed",
        left: "calc(50% - 98px)"
    },
    dateDelimiter: {
        textAlign: "center",
        top: "50%",
        left: "50%",
        width: "100%",
        transform: "translate(0%, -10%)",
        "&:before": {
            content:'""',
            position: "absolute",
            width: "100%",
            height: "1px",
            top: "50%",
            left: 0,
            zIndex: "-1",
            background: "rgba(224, 224, 224, 1)",
        },
        "&>span": {
            backgroundColor: "#f3f3f3",
            padding: "1rem",
            display: "inline-block",
            fontWeight: 500,
            fontSize: "20px"
        }
    },
    borderTop: {
        borderTop: "1px solid rgba(224, 224, 224, 1)"
    },
    input: {
        height: "50px !important"
    }
}))



export const Chat = (props) => {
    const classes = useStyles()
    const {onClose, open, anchor, user, chatID, token, title, onlyRead} = props
    let defDate = format(new Date(), 'dd.MM.yyyy');
    const chatRef = useRef(null)
    const inputRef = useRef(null)

    const { users, messages, sendMessage } = useChat(chatID, user, token);

    const getCompanyName = (id, users) => {
        let user = users.find(user => user.id === id)

        if (user) {
            if (user.company && user.company.name) {
                return user.company.name
            } else if (user.organization) {
                return user.organization.value
            }
        } else {
            return 'Система'
        }
    }

    useEffect(() => {
        if (chatRef.current && !!messages.length) {
            chatRef.current.scrollTo({
                behavior: 'instant',
                top: chatRef.current.scrollHeight
            })
        }
    }, [chatRef, messages])

    const getUserName = (id, users) => {
        let user = users.find(user => user.id === id);

        return user?.fullName
    }

    return (
        <Drawer anchor={anchor} open={open} onClose={onClose} classes={{paper: classes.wrapper}}>
            <Typography align="center" variant="h5" className={classes.title}>{ title }</Typography>
            <Grid container direction="row" justify="center" className={classes.content}>
                <Grid item xs={12} className={classes.contentMessages} ref={chatRef}>
                    {!!messages.length && messages.map((message, idx) => {
                        let dateDelimiter = null;
                        if (idx === messages.length - 1 && format(new Date(message.created_at), 'dd.MM.yyyy') !== defDate) {
                            dateDelimiter = format(new Date(message.created_at), 'dd MMMM, yyyy', {locale: ru})
                        }

                        if (format(new Date(message.created_at), 'dd.MM.yyyy') !== defDate) {
                            if (format(new Date(), 'dd.MM.yyyy') === format(new Date(message.created_at), 'dd.MM.yyyy')) {
                                dateDelimiter = 'Сегодня'
                            } else {
                                dateDelimiter = format(new Date(message.created_at), 'dd MMMM, yyyy', {locale: ru})
                            }

                            defDate = format(new Date(message.created_at), 'dd.MM.yyyy')
                        }

                        return (
                            <React.Fragment key={message._id}>
                                {dateDelimiter ? <Typography align="center" style={{ marginBottom: "25px" }} className={classes.dateDelimiter}><span>{dateDelimiter}</span></Typography> : null}
                                {((message.user_id === user.id)
                                    ? (
                                        <MessageRight
                                            message={message}
                                            company={getCompanyName(message.user_id, users)}
                                            user={getUserName(message.user_id, users)}
                                        />
                                    )
                                    : (
                                        <MessageLeft
                                            message={message}
                                            company={getCompanyName(message.user_id, users)}
                                            user={getUserName(message.user_id, users)}
                                        />
                                    )
                                )}
                            </React.Fragment>
                        )
                    })}
                </Grid>
                <Grid item xs={12} className={classes.borderTop}>
                    <Formik
                        initialValues={{
                            text: '',
                        }}
                        validationSchema={Yup.object().shape({
                            text: Yup.string()
                        })}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            values.text = values.text.trim()

                            if (values.text.length) {
                                sendMessage(values.text).then(_ => {
                                    resetForm({
                                        values: {
                                            text: '',
                                        }
                                    })
                                    setSubmitting(false)
                                    inputRef.current && inputRef.current.firstChild.focus()
                                })
                            }
                        }}
                    >
                        {({
                            dirty,
                            isSubmitting,
                            isValid,
                            setFieldValue
                          }) => (
                            <Form>
                                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                                    <Grid item xs={11}>
                                        <Field
                                            fullWidth
                                            name="text"
                                            type="string"
                                            label="Напишите комментарий..."
                                            rows={2}
                                            multiline
                                            variant="standard"
                                            component={CustomTextField}
                                            inputProps={{
                                                onChange: (e) => {
                                                    const value = e.target.value
                                                    setFieldValue('text', value)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                            color="primary"
                                            disabled={isSubmitting || !isValid || !dirty || onlyRead}
                                            type="submit"
                                        >
                                            <SendIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Drawer>
    )
}
