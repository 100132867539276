export default function download(state = {}, action) {
    switch (action.type) {
        case "DOWNLOAD_FILES_REQUEST":
            return state
        case "DOWNLOAD_FILES":
            return {
                ...state,
                ...action.payload,
            }
        case "DOWNLOAD_FILES_FAILURE":
            return state
        case 'DOWNLOAD_FILE_CREATE_REQUEST':
            return state
        case 'DOWNLOAD_FILE_CREATE_SUCCESS':
            return state
        case 'DOWNLOAD_FILE_CREATE_FAILURE':
            return state
        default:
            return state
    }
}
