import React from "react"
import {useSelector} from "react-redux"

import {Index as Purchase} from "../Purchase/Index"

export const Index = (props) => {
    const {account} = useSelector(state => state.account)

    return account ? <React.Fragment /> : <Purchase {...props} />
}
