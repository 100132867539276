import React, {useEffect, useState} from "react"

import {
    Avatar,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    Tab,
    Tabs,
    Typography,
} from "@material-ui/core"
import {Close as CloseIcon} from "@material-ui/icons"

import {Submit} from "../../App/components/Button/Submit"
import {TabPanel} from "../../Account/components/Tabs/TabPanel"
import {useDispatch} from "react-redux";
import {CompanyActions} from "../../Account/actions/company";

const useStyles = makeStyles(() => ({
    title: {
        "margin": "0",
        "padding-top": "2px",
        "font-size": "18px",
        "font-weight": "600",
        "color": "#fff",
        "text-transform": "uppercase",
        "display": "flex",
        "justify-content": "space-between",
        "align-items": "center",
        "min-height": "40px",
        "padding": "0 20px",
        "background-color": "#898989",
    },
    supplierTitle: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis",
        marginBottom: "10px",
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    footerContainer: {
        "padding": "0 25px",
        "background-color": "#e7eaee",
        "height": "75px",
        position: "absolute",
        bottom: 0,
    },
    content: {
        marginTop: "20px",
        marginBottom: "75px",
    },
    submit: {
        "width": "100%",
        "border-bottom": "3px solid #898989",
        "border-radius": "2px",
        "padding": "5px 10px 0",
        "min-width": "95px",
        "min-height": "35px",
        "font-size": "16px",
        "font-weight": "500",
        "color": "white",
        "outline": "0",
        "height": "35px",
        "&:disabled": {
            "background-color": "#c1c1c1",
            "border-bottom-color": "#898989 !important",
            "color": "white"
        }
    },
    dialogContent: {
        padding: "0",
        "overflow": "hidden",
    },
    dialogContainer: {
        "& > div": {
            minHeight: "700px",
            maxHeight: "700px",
            maxWidth: "1152px",
            width: "calc(100vw - 200px)"
        }
    },
    tabContent: {
        height: "475px",
        overflow: "auto",
        "overflow-x": "hidden",
        margin: 0,
        paddingTop: "10px",
        width: "100%"
    },
    avatar: {
        width: "100%",
        height: "auto",
        borderRadius: "unset"
    }
}));

export const Information = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {open, onClose, id} = props
    const [tab, setTab] = useState(0)
    const [company, setCompany] = useState(null)

    useEffect(() => {
        const getCompany = async () => {
            return await dispatch(CompanyActions.information(id))
        }

        if (!company && id) {
            getCompany().then(company => {
                setCompany(company)
            })
        }
        // eslint-disable-next-line
    }, [id, company])

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return company ? (
        <Dialog
            aria-labelledby="simple-dialog-title"
            open={open}
            classes={{
                root: classes.dialog,
                container: classes.dialogContainer
            }}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle
                id="cartDialogTitle"
                classes={{
                    root: classes.title,
                }}
                disableTypography={true}
            >
                Карточка {company.type ? 'Поставщика' : 'Заказчика'}<CloseIcon className={classes.close} onClick={onClose} />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid container justify={'center'} className={classes.content}>
                    <Grid item xs={12}>
                        {company &&
                            <Grid container justify={'center'}>
                                <Grid item xs={4}>
                                    <Avatar className={classes.avatar} src={company.logo ?? `${process.env.PUBLIC_URL}/storage/no-image.jpg`} />
                                </Grid>
                                <Grid item xs={8}>
                                    <Grid container direction={'column'}>
                                        <Grid item>
                                            <Typography className={classes.supplierTitle}>{company.legal_detail.name}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Tabs
                                                value={tab}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                onChange={handleChange}
                                            >
                                                <Tab value={0} label="Реквизиты" className={classes.tab}/>
                                            </Tabs>
                                            <TabPanel value={tab} index={0}>
                                                <Grid container direction={'column'} spacing={2} wrap="nowrap" className={classes.tabContent}>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                Телефон:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.contact_information.phones ? company.contact_information.phones.map(el => `${el.number} ${el.extra ? 'доб. ' + el.extra : ''}`).join(', ') : 'Телефон не заполнен'}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                e-mail:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.contact_information.emails ? company.contact_information.emails.join(', ') : null}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                Сайт:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.contact_information.website}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                Юр. адрес:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.legal_detail.legal_address}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                Факт. адрес:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.legal_detail.current_address}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                ИНН:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.legal_detail.inn}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                ОГРН:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.legal_detail.ogrn}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                КПП:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.legal_detail.kpp}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                Р/с:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.payment_detail ? (company.payment_detail.checking_account ?? '') : ''}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                К/с:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.payment_detail ? (company.payment_detail.correspondent_account ?? '') : ''}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                БИК:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.payment_detail ? (company.payment_detail.bik ?? '') : ''}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction={'row'}>
                                                            <Grid item xs={3}>
                                                                Банк:
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                {company.payment_detail ? (company.payment_detail.bank ?? '') : ''}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.footerContainer}>
                    <Grid item>
                        <Submit
                            className={classes.submit}
                            disableElevation
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={onClose}
                        >
                            Закрыть
                        </Submit>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    ) : null
}
