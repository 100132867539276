export default function warehouse(state = {}, action) {
    switch (action.type) {
        case "WAREHOUSES_REQUEST":
            return state
        case "WAREHOUSES_SUCCESS":
            return {
                warehouses: action.payload
            }
        case "WAREHOUSES_FAILURE":
            return state
        case "WAREHOUSE_EDIT_REQUEST":
            return state
        case "WAREHOUSE_EDIT_SUCCESS":
            return state
        case "WAREHOUSE_EDIT_FAILURE":
            return state
        case "WAREHOUSE_CLEAR":
            return {}
        default:
            return state
    }
}
