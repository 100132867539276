import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from 'react-redux'
import {useDebounce} from "use-debounce"

import {OperationBetweenTwoTables} from "../../../../../App/components/OperationBetweenTwoTables"
import {PurchasePlanItemsActions} from '../../../../actions/purchasePlanItems'
import {PurchaseActions} from '../../../../actions/purchase'
import {LotActions} from '../../../../actions/lot'
import {PurchasePlanActions} from "../../../../actions/purchasePlan"
import {localState} from "../../../../../App/components/LocalState";

const copyColumns = [
    {
        caption: 'Номер закупки',
        dataField: 'purchase_number',
        type: 'string',
        width: 160
    },
    {
        caption: 'Наименование ОЗ',
        dataField: 'name',
        type: 'string'
    },
    {
        caption: 'Месяц',
        dataField: 'month',
        type: 'string',
        width: 80
    },
    {
        caption: 'Год',
        dataField: 'year',
        type: 'number',
        width: 80
    }
]

export const Dialog = (props) => {
    const dispatch = useDispatch()
    const { products, onClose, open } = props

    const { itemsByParentIDs} = useSelector(state => state.purchasePlans)

    const [purchasePlans, setPurchasePlans] = useState(localState)
    const [purchases, setPurchases] = useState(localState)
    const [purchasesMeta, setPurchasesMeta] = useState(localState.meta)
    const [lots, setLots] = useState(localState)
    const [copyPurchasePlanID, setCopyPurchasePlanID] = useState('')
    const [copyLotID, setCopyLotID] = useState('')
    const [copyPurchaseID, setCopyPurchaseID] = useState('')
    const [errors, setErrors] = useState([])

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)

    const handlePageChange = (newPage) => setPage(newPage + 1)
    const handlePageSizeChange = (newRowsPerPage) => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
    }

    const copyProducts = async (items) => {
        let products = items.map(item => {
            return {
                id: item.id,
                purchase_id: item.purchase_id,
                purchase_lot_id: item.purchase_lot_id,
                purchase_plan_id: item.purchase_plan_id,
            }
        })

        if (copyPurchasePlanID) {
            return await dispatch(PurchasePlanItemsActions.copyItems(copyPurchasePlanID, {
                items: products,
                ...(copyLotID ? {new_lot_id: copyLotID} : {}),
                ...(copyPurchaseID ? {new_purchase_id: copyPurchaseID} : {}),
            })).catch(error => {
                if (error.hasOwnProperty('items')) {
                    setErrors(error.items)
                }
            })
        } else {
            return new Promise(function (resolve, reject){
                dispatch({type: "ALERT_ERROR", payload: {
                    message: 'Выберите план закупки',
                    type:'error'
                }})
                return reject()
            })
        }
    }

    useEffect(() => {
        const getPurchasePlans = async () => {
            let params = {
                limit: 50,
                page: 1,
                mode: 'copy',
                simple: true,
                purchasePlanIds: Array.from(new Set(products.map(el => el.purchase_plan_id)))
            }
            return await dispatch(PurchasePlanActions.plans(params))
        }

        getPurchasePlans().then(response => {
            setPurchasePlans(response)
        })

        return () => {
            dispatch({type:"PURCHASE_PLANS_CLEAR"})
        }
        // eslint-disable-next-line
    }, [dispatch])

    const removeProducts = async (items) => {
        let values = [];
        items.forEach(el =>  {
            values.push({
                lot_item_id: el.purchase_lot_id,
                id: el.id,
                purchase_plan_id: el.purchase_plan_id
            })
        })

        return await dispatch(
            PurchasePlanItemsActions.deleteItems({
                products: values
            })
        )
    }

    const getItemsInRightTable = async () => {
        let params = {
            limit: 100,
            page: 1,
            ...(copyPurchasePlanID ? {purchase_plan_id: copyPurchasePlanID} : {}),
            ...(copyPurchaseID ? {purchase_id: copyPurchaseID} : {}),
            ...(copyLotID ? {lot_id: copyLotID} : {}),
        }
        await dispatch(PurchasePlanItemsActions.itemsByParentIDs(params))
    }

    const getPurchases = async () => {
        let params = {
            limit: rowsPerPage,
            page: page,
            ...(searchRequest ? { search: searchRequest } : {}),
            simple: true,
            mode: "copy"
        }
        return  await dispatch(PurchaseActions.purchasesByPurchasePlanID(copyPurchasePlanID, params))
    }

    const getLots = async () => {
        let params = {
            limit: 50,
            page: 0
        }
        const r = await dispatch(LotActions.lotsInPurchase(copyPurchaseID, params))
        setLots(r)
    }

    useEffect(() => {
        if (copyPurchaseID) {
            getLots().then(() => {})
        }
        return () => {
            dispatch({type:"PLANNING_PURCHASE_LOTS_CLEAR"})
        }
        // eslint-disable-next-line
    }, [copyPurchaseID]);

    useEffect(() => {
        if (copyPurchasePlanID) {
            const getTotal = async () => {
                let params = {
                    limit: rowsPerPage,
                    page: page,
                    ...(searchRequest ? { search: searchRequest } : {}),
                    mode: "copy"
                }
                return await dispatch(PurchaseActions.totalByPurchasePlanID(copyPurchasePlanID, params))
            }

            getTotal().then(response => {
                setPurchasesMeta({
                    ...purchasesMeta,
                    ...response
                })
            })

            getPurchases().then(response => {
                setPurchases(response)
            })
        }
        return () => {
            dispatch({type:"PLANNING_PURCHASES_CLEAR"})
        }
        // eslint-disable-next-line
    }, [copyPurchasePlanID, page, rowsPerPage, searchRequest]);

    useEffect(() => {
        if (copyPurchasePlanID) {
            getItemsInRightTable().then(() => {})
        }
        // eslint-disable-next-line
    }, [copyPurchasePlanID, copyPurchaseID, copyLotID])

    useEffect(() => {
        const setDefaultValues = () => {
            setCopyLotID(null)
            setCopyPurchasePlanID(null)
            setCopyPurchaseID(null)
        }
        return setDefaultValues()
    }, [])

    return (
            <OperationBetweenTwoTables
                itemsLeft={products}
                errorsLeft={errors}
                itemsRight={itemsByParentIDs.data}
                columnsTables={copyColumns}
                open={open}
                title={'Копирование ОЗ'}
                maxWidth={'lg'}
                fullWidth={true}
                leftToRightCallback={copyProducts}
                rightToLeftCallback={removeProducts}
                tableSelectorsLeft={[]}
                tableSelectorsRight={[
                    {
                        id: 'purchasePlans',
                        title: 'Планы закупок',
                        value: copyPurchasePlanID,
                        callback: (e) => {
                            setCopyPurchasePlanID(e.target.value)
                            setCopyPurchaseID(null)
                            setCopyLotID(null)
                        },
                        items: purchasePlans.data
                    },
                    {
                        id: 'purchases',
                        title: 'Закупки',
                        meta: purchasesMeta,
                        items: purchases.data,
                        value: copyPurchaseID,
                        search: {
                            search,
                            setSearch,
                        },
                        callback: (id) => {
                            setCopyPurchaseID(id)
                            setCopyLotID(null)
                        },
                        isDisabled: !copyPurchasePlanID,
                        pagination: {
                            page,
                            rowsPerPage,
                            handlePageChange,
                            handlePageSizeChange,
                        },
                    },
                    {
                        id: 'lots',
                        title: 'Лоты',
                        value: copyLotID,
                        callback: (e) => {
                            setCopyLotID(e.target.value)
                        },
                        items: lots.data.map(lot => {
                            return {
                                id: lot.id,
                                name: lot.number
                            }
                        }),
                        isDisabled: !copyPurchaseID
                    },
                ]}
                onClose={onClose}
                updateRightList={getItemsInRightTable}
        />
    )
}
