export default function purchasePlans(state = {
    items: [],
    categories: [],
    categoriesForCreate: [],
    itemsByParentIDs: {
        data: [],
        meta: {
            total: 0, per_page: 15, current_page: 1
        },
    },
    units: []
}, action) {
    switch (action.type) {
        case "PURCHASE_PLANS_SUCCESS":
            return {
                ...state,
                ...action.payload
            }
        case "PURCHASE_PLANS_EDIT_REQUESTS_SUCCESS":
            return {
                ...state,
                ...action.payload
            }
        case "PURCHASE_PLAN_ITEMS_SUCCESS":
            return {
                ...state,
                ...action.payload
            }
        case "PURCHASE_PLAN_ITEM_SUCCESS":
            return {
                ...state,
                items: action.payload
            }
        case "PURCHASE_PLAN_ITEM_CLEAR":
            return {
                ...state,
                product: []
            }
        case "PURCHASE_PLAN_SUCCESS":
            return {
                ...state,
                purchasePlan: action.payload.plan,
                price: action.payload.price
            }
        case "PURCHASE_PLAN_CATEGORIES_FOR_CREATE_SUCCESS":
            return {
                ...state,
                categoriesForCreate: action.payload.categories
            }
        case "PURCHASE_PLAN_ITEMS_BY_PARENT_IDS_SUCCESS":
            return {
                ...state,
                itemsByParentIDs: {
                    ...state.itemsByParentIDs,
                    data: action.payload.data,
                    meta: action.payload.meta,
                }
            }
        case "PURCHASE_PLAN_ITEMS_BY_PARENT_IDS_CLEAR":
            return {
                ...state,
                itemsByParentIDs: {
                    data: [],
                    meta: {
                        total: 0, per_page: 15, current_page: 1
                    },
                }
            }
        case "PURCHASE_PLAN_UNITS_SUCCESS":
            return {
                ...state,
                ...{units: action.payload}
            }
        default:
            return state
    }
}
