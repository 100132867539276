import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {
    Grid,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@material-ui/core"

import {SupMarketplaceActions} from "../../actions/supMarketplace"
import {ButtonList} from "../../../../App/components/ButtonList/ButtonList"
import {history} from "../../../../App/helpers/history"

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "16px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 157px)"
    },
    containerRows: {
        "width": "100%",
        "height": "calc(100vh - 264px)",
        "overflow": "auto",
        "padding": "15px 15px 0 15px"
    },
    input: {
        "width": "100%",
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100% - 70px)",
        "min-height": "calc(100% - 70px)"
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    tableContainer: {
        "height": "auto",
        "margin-top": "5px",
        "margin-bottom": "30px",
    },
    table: {
        "border": "1px solid #e4e4e4",
    },
    row: {
        "width": "100%",
        "height": "min-content",
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    tableRowAlert: {
        "cursor": "pointer",
        "background-color": "#f9f1f0",
        "text-decoration": "none"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    },
    sendOffer: {
        width: '225px',
    },
    download: {
        "color": "#485868"
    },
    menu: {
        "width": "300px",
        "height": "100%",
        "background-color": "white",
        "z-index": "1200",
        "overflow-y": "auto",
        "box-shadow": "10px 0 10px -3px rgba(0,0,0,.1)"
    },
}))

const columns = [
    {
        id: 'lot_id',
        label: '№ Лота',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'products',
        label: 'Объект закупки',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'count',
        label: 'Количество',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'postponement',
        label: 'Отсрочка, дней',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'contract_time',
        label: 'Срок исполнения,  дней',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'status',
        label: 'Статус',
        format: (value) => value.toLocaleString(),
    },
];

const sortList = [
    {
        key: 'all',
        value: 'Все'
    },
    {
        key: 'active',
        value: 'Активные'
    }
]

export const Content = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {orders} = useSelector(state => state.supMarketplace)

    const [tab, setTab] = useState(0)
    const [sort, setSort] = useState('all')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const containerRef = useRef(null)

    useEffect(() => {
        const getOrders = async () => {
            return await dispatch(SupMarketplaceActions.orders({
                is_cart: true,
                limit: rowsPerPage,
                page: page + 1,
                sort: sort
            }))
        }
        getOrders().then(r => {
            containerRef.current && containerRef.current.scrollTo({behavior: 'smooth', top: 0})
        })
    }, [dispatch, page, rowsPerPage, sort]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const renderColumn = (column, order, lot) => {
        switch (column.id) {
            case 'lot_id':
                return lot.index
            case 'category':
                return order.category.name
            case 'products':
                return lot.items.map(item => (
                    <Typography key={item.name}>
                        {item.name}
                    </Typography>
                ))
            case 'count':
                return lot.items.map(item => (
                    <Typography key={item.id}>
                        {item.amount}
                    </Typography>
                ))
            case 'status':
                if (!!(!!lot && !!lot.offers && !!lot.offers.length && (lot.status.index !== 2))) {
                    if (lot.offers[0].active) {
                        if (lot.status.index === 8) {
                            return "В работе"
                        }
                        return lot.offers[0].winner ? lot.status.name : "Не состоялась"
                    } else {
                        return "Предложение отозвано"
                    }
                }
                break
            default:
                return lot[column['id']]
        }
    }

    return <Grid item className={classes.content}>
        <Grid container>
            <ButtonList value={tab} values={sortList} onChange={(_, value) => {
                setTab(value);
                setPage(0)
                setSort(sortList[value].key);
            }}/>
        </Grid>
        <Paper>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item className={classes.fullWidth}>
                    <Grid container className={classes.container} direction="column" justify="space-between"
                          alignItems="stretch">
                        <Grid item className={classes.inputContent}>
                            <Grid ref={containerRef} container className={classes.containerRows} direction="row"
                                  justify="flex-start" alignItems="flex-start">
                                {orders.data && orders.data.map((order, idx) => {
                                    return order.lots && order.lots.length > 0 ? (
                                        <Grid item className={classes.row} key={idx}>
                                            <Grid container direction="row" justify="space-between"
                                                  alignItems="stretch">
                                                <Grid item>
                                                    <Typography key={orders.data.length + idx}
                                                                className={classes.title}>
                                                        {order.regions.name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <TableContainer className={classes.tableContainer}>
                                                <Table key={order.name} stickyHeader aria-label="sticky table"
                                                       className={classes.table}>
                                                    <TableHead>
                                                        <TableRow>
                                                            {columns.map((column) => (
                                                                <TableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    style={{minWidth: column.minWidth}}
                                                                >
                                                                    {column.label}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody key={order.id}>
                                                        {order.lots && order.lots.map((lot, index) => {
                                                            return (
                                                                <TableRow
                                                                    className={classes.tableRow}
                                                                    hover key={index}
                                                                    onClick={() => {
                                                                        history.push(`/supplier/marketplace/deliveries/lot/${lot.id}`)
                                                                    }}
                                                                >
                                                                    {columns.map((column) => (
                                                                        <TableCell key={column.id}>
                                                                            {renderColumn(column, order, lot)}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    ) : null;
                                })}
                            </Grid>
                        </Grid>
                        {orders.data &&
                            <Grid item className={classes.footer}>
                                <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                    <Grid item className={classes.footerContent}>
                                        <Grid container className={classes.footerContainer} direction="row"
                                              justify="space-between" alignItems="center" spacing={2}>
                                            <Grid item>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                                    component='div'
                                                    labelRowsPerPage={'Записей на странице:'}
                                                    count={orders.meta.total}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    </Grid>
}
