import React from "react"
import {useDispatch, useSelector} from "react-redux"

import {
    Grid,
    makeStyles
} from "@material-ui/core"

import {purchase as rules} from "../../../../helpers/permissions/rules"
import {ItemsActions} from "../../../../actions/items";
import {Delete as DeleteIcon} from "@material-ui/icons";
import {ButtonIcon} from "../../../../../App/components/Button/ButtonIcon";

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    }
}))

export const Delete = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { purchase, items, onClick } = props

    const {account} = useSelector(state => state.account)

    return rules.edit.hasOwnProperty(account.group) ? (
        <Grid item>
            <ButtonIcon
                disabled={
                    !rules.edit.hasOwnProperty(account.group) ||
                    !purchase ||
                    !items.length ||
                    function () {
                        if (rules.edit[account.group].hasOwnProperty(purchase.status.index) && !purchase.lock) {
                            if (rules.edit[account.group][purchase.status.index].length) {
                                return !rules.edit[account.group][purchase.status.index].includes(purchase.editRequestStatus?.index)
                            }

                            return false
                        }

                        return true
                    }()
                }
                onClick={() => {
                    return dispatch(ItemsActions.deleteItems(purchase.id, {
                        ids: items.map(el => el.id)
                    })).then(() => onClick(), () => {})
                }}
                title='Удалить'
                children={<DeleteIcon/>}
                className={classes.button}
            />
        </Grid>
    ) : null
}
