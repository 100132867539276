import React, {useState} from 'react'
import {useDispatch} from 'react-redux'

import {Button, Grid, IconButton, makeStyles, Popover, Typography} from '@material-ui/core'
import {FilterList, Sort} from '@material-ui/icons'
import {blue, red} from "@material-ui/core/colors"

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    fullHeight: {
        'height': '100%'
    },
    popover: {
        'width': '300px'
    },
    filter: {
        'width': '100%'
    },
    filterItems: {
        'width': '100%',
        'height': 'calc(100% - 158px)',
        'overflow': 'auto'
    },
    filterItem: {
        'white-space': 'normal !important'
    },
    field: {
        'height': '100%'
    },
    data: {
        'height': 'calc(100% - 60px)',
        'width': '100%'
    },
    element: {
        'width': '100%'
    },
    breadcrumb: {
        'width': '100%',
        'height': '40px'
    },
    table: {
        'height': '100%'
    },
    fab: {
        'margin': '0',
        'top': 'auto',
        'right': '90px',
        'bottom': '25px',
        'left': 'auto',
        'position': 'fixed'
    },
    activeCategory: {
        'background-color': red[100],
        '&:hover': {
            'background-color': red[50] + '!important',
        }
    },
    defaultCategory: {
        '&:hover': {
            'background-color': 'rgba(0, 0, 0, 0.04) !important',
        }
    },
    active: {
        'background-color': blue[100],
        '&:hover': {
            'background-color': `${blue[50]} !important`
        }
    },
    confirmation: {
        'background-color': 'rgba(244, 244, 244, 1)',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        }
    },
    default: {
        '&:hover': {
            'background-color': `${blue[50]} !important`
        }
    },
    visuallyHidden: {
        "border": "0",
        "clip": "rect(0 0 0 0)",
        "height": "1",
        "margin": "-1",
        "overflow": "hidden",
        "padding": "0",
        "position": "absolute",
        "top": "20",
        "width": "1",
    },
    attributes: {
        '& .MuiSelect-selectMenu': {
            'padding-top': '9px'
        }
    },
    body: {
        'height': '100%'
    },
    item: {
        'height': '100%'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    },
    sortLabelRoot: {
        maxWidth: '100%',
    },
    sortLabelRight: {
        flexDirection: 'row-reverse',
    },
    sortLabelActive: {
        color: 'inherit',
    },
    toolbar: {
        'padding-left': '0 !important'
    },
    actions: {
        'margin-left': '0 !important'
    },
    filterContent: {
        width: '100%',
        padding: theme.spacing(2)
    },
    divider: {
        width: '100%'
    }
}))

export const Filter = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {name, active, filter, Content, filterActionType} = props

    const [sort, setSort] = useState({name: name, direction: (filter.sort.name === name) ? filter.sort.direction : null})
    const [filters, setFilters] = useState(null)
    const [defaultFilters, setDefaultFilters] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    return (
        <React.Fragment>
            <IconButton
                size="small"
                onClick={(event) => {
                    setSort({name: name, direction: (filter.sort.name === name) ? filter.sort.direction : null})
                    setAnchorEl(event.currentTarget)
                }}
                color={active ? 'secondary' : 'default'}
            >
                <FilterList />
            </IconButton>
            {open ? <Popover
                classes={{
                    paper: classes.popover
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                    setAnchorEl(null)
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                    <Grid item className={classes.filterContent}>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item>
                                <Typography variant="caption">
                                    Сортировка
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                                    <Grid item>
                                        <Button
                                            color="default"
                                            onClick={() => {
                                                const direction = ((sort.direction === 'asc') ? null : 'asc')
                                                setSort({...sort, ...{direction: direction}})
                                            }}
                                            startIcon={<Sort color={(sort.direction === 'asc') ? 'secondary' : 'action'} style={{transform: 'rotatex(180deg)'}} />}
                                        >
                                            <Typography component="span" variant="body2" style={{textTransform: "none"}}>по возрастанию (А-Я)</Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color="default"
                                            onClick={() => {
                                                const direction = ((sort.direction === 'desc') ? null : 'desc')
                                                setSort({...sort, ...{direction: direction}})
                                            }}
                                            startIcon={<Sort color={(sort.direction === 'desc') ? 'secondary' : 'action'} />}
                                        >
                                            <Typography component="span" variant="body2" style={{textTransform: "none"}}>по убыванию (Я-А)</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {Content ? <Content filters={filter} setFilters={setFilters} setDefaultFilters={setDefaultFilters} /> : null}
                        </Grid>
                    </Grid>
                    <Grid item className={classes.filterContent}>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction="row" justify="space-between" alignItems="flex-start">
                                    <Grid item>
                                        <Button
                                            size="small"
                                            color="secondary"
                                            type="button"
                                            onClick={() => {
                                                setSort({...sort, ...{direction: null}})
                                                if (filterActionType) {
                                                    dispatch({type: filterActionType, payload: {...defaultFilters, ...((filter.sort.name === name) ? {sort: {
                                                        name: null,
                                                        direction: null,
                                                    }} : {sort: filter.sort})}})
                                                }
                                            }}
                                        >
                                            Сбросить
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            size="small"
                                            color="primary"
                                            type="button"
                                            onClick={() => {
                                                if (filterActionType) {
                                                    dispatch({type: filterActionType, payload: {...filters, ...{sort: sort.direction ? sort : filter.sort}}})
                                                    setAnchorEl(null)
                                                }
                                            }}
                                        >
                                            Применить
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Popover> : null}
        </React.Fragment>
    )
}
