import React from "react"

import {List, ListItem, ListItemText, makeStyles, withStyles} from '@material-ui/core'

const useStyles = makeStyles(() => ({
    active: {
        "background-image": "none !important",
        "background-color": "#898989"
    },
    pagination: {
        "&>div": {
            flexWrap: 'wrap'
        }
    },
    listItem: {
        overflow: "auto",
        height: "calc(100vh - 200px)"
    }
}))

const CustomList = withStyles({
    root: {
        height: "100%",
        "padding": "0",
        "& .MuiListItem-button": {
            "background-image": "linear-gradient(to bottom,#e4e4e4,#f4f4f4)",
            "cursor": "pointer",
            "align-items": "center",
            "min-height": "50px",
            "padding": "5px 25px 0",
            "border-bottom": "1px solid #e4e4e4",
            "outline": "0"
        },
        "& .MuiListItemText-primary": {
            "font-size": "16px",
            "font-weight": "700",
            "text-transform": "uppercase"
        },
        "& .MuiListItem-button:hover": {
            "cursor": "pointer",
            "background-color": "#898989",
            "&:after": {
                "position": "absolute",
                "content": "''",
                "top": "0",
                "left": "0",
                "width": "100%",
                "height": "100%",
                "background-color": "rgba(0,0,0,.04)"
            }
        },
        "& .MuiListItem-root": {
            "cursor": "default"
        },
        "& .MuiListItemIcon-root": {
            "min-width": "30px"
        },
        "& .MuiListItemText-secondary": {
            "color": "#485868",
            "font-size": "16px"
        }
    }
})(List);

export const Menu = (props) => {
    const classes = useStyles()
    const {dashboard, setDashboard} = props

    const menu = [
        {title: "Ценовые запросы", dashboard: 5},
        {title: "Постфактум", dashboard: 7},
        {title: "Планирование", dashboard: 9},
    ]

    return (
        <CustomList >
            {menu.map(option => {
                return <ListItem
                    button
                    key={option.title}
                    onClick={() => {
                        setDashboard(option.dashboard)
                    }}
                    classes={{root: (dashboard === option.dashboard) ? classes.active : undefined}}
                >
                    <ListItemText primary={option.title} />
                </ListItem>
            })}
        </CustomList>
    )
}
