import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {IconButton, makeStyles, Slide, Snackbar, Typography} from "@material-ui/core"
import {Close as CloseIcon} from "@material-ui/icons"

import {WarningActions} from "../../actions/warning";
import {AuthorizationService} from "../../../Auth/services/authorization";

const useStyles = makeStyles(() => ({
    error: {
        "border-radius": "3px",
        "background-color": "#d9534f",
        "color": "#FFFFFF",
        "box-shadow": "0 1px 3px rgba(0,0,0,.2)",
        "cursor": "default",
        "border-top": "3px solid #ad3e3b !important",
    },
    info: {
        "border-radius": "3px",
        "background-color": "#00bed6",
        "color": "#FFFFFF",
        "box-shadow": "0 1px 3px rgba(0,0,0,.2)",
        "cursor": "default",
        "border-top": "3px solid #00bed6 !important",
    },
    success: {
        "border-radius": "3px",
        "background-color": "#5cb85c",
        "color": "#FFFFFF",
        "box-shadow": "0 1px 3px rgba(0,0,0,.2)",
        "cursor": "default",
        "border-top": "3px solid #5cb85c !important",
    },
    text: {
        "margin-bottom": 0
    }
}))

export const Warning = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { account } = useSelector(state => state.account)
    const {loading} = useSelector(state => state.application)

    const [events, setEvents] = useState({
        data: [],
        meta: {
            total: 0, per_page: 50, current_page: 1
        }
    })
    const [load, setLoad] = useState(false)

    const close = async (event) => {
        if (event.type !== 'critical') {
            await dispatch(WarningActions.read(event.id, false))
        }

        setEvents({
            data: events.data.filter(item => (item.id !== event.id)),
            meta: {
                total: events.total - 1,
                per_page: 50,
                current_page: 1
            }
        })
    }

    useEffect(() => {
        const getEvents = async () => {
            return await dispatch(WarningActions.events({
                limit: 5,
                page: 1
            }, false))
        }

        if (!load && account && !AuthorizationService.roles(account, ['admin'])) {
            getEvents().then((events) => {
                setEvents(events)
                setLoad(true)
            })
        }

        if (!account) {
            setEvents({
                data: [],
                meta: {
                    total: 0, per_page: 50, current_page: 1
                }
            })
            setLoad(false)
        }
        // eslint-disable-next-line
    }, [account]);

    const getType = (type) => {
        // eslint-disable-next-line default-case
        switch (type){
            case 'critical':
                return {
                    root: classes.error
                };
            case 'common':
                return {
                    root: classes.info
                };
        }
    }

    return (load && !loading) ? (
        <React.Fragment>
            {events.data.filter((_, index) => !index).map(event =>
                <Snackbar
                    ContentProps={{
                        classes: getType(event.type)
                    }}
                    anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                    open={true}
                    TransitionComponent={(props) => {
                        return <Slide {...props} direction="left"/>
                    }}
                    message={<Typography className={classes.text} gutterBottom color="inherit">{event.message}</Typography>}
                    action={
                        <React.Fragment>
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={() => close(event)}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </React.Fragment>
                    }
                />
            )}
        </React.Fragment>
    ) : null
}
