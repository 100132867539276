import React, {useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {
    Grid,
    makeStyles
} from "@material-ui/core"

import {purchase as rules} from "../../../helpers/permissions/rules"
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon";
import {Delete as DeleteIcon} from "@material-ui/icons";
import {DialogWithVariantsChoice} from "../../../../App/components/DialogWithVariantsChoice/DialogWithVariantsChoice";
import {PurchaseActions} from "../../../actions/purchase";

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    }
}))

export const Delete = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [dialog, setDialog] = useState(false)

    const { items, onClick } = props

    const {account} = useSelector(state => state.account)

    return  (
        <Grid item>
            <ButtonIcon
                disabled={!rules.delete.hasOwnProperty(account.group) || !items.length || !!items.filter(el => {
                    if (rules.delete[account.group].hasOwnProperty(el.status.index) && !el.lock) {
                        if (rules.delete[account.group][el.status.index].length) {
                            return !rules.delete[account.group][el.status.index].includes(el.editRequestStatus?.index)
                        }

                        return false
                    }

                    return true
                }).length}
                onClick={() => setDialog(true)}
                title='Удалить'
                children={<DeleteIcon/>}
                className={classes.button}
            />
            {dialog &&
                <DialogWithVariantsChoice
                    open={dialog}
                    onClose={() => setDialog(false)}
                    textTitle={'Подтвердите действие: удалить вложенные объекты?'}
                    buttons={[
                        {
                            xs: 6,
                            callback: () => {
                                setDialog(false)

                                return dispatch(
                                    PurchaseActions.deletePurchases({
                                        ids: items.map(el => el.id),
                                        deleteNested: true
                                    })
                                ).then(() => onClick(), () => {})
                            },
                            text: 'Да',
                            color: 'secondary'
                        },
                        {
                            xs: 6,
                            callback: () => {
                                setDialog(false)

                                return dispatch(
                                    PurchaseActions.deletePurchases({
                                        ids: items.map(el => el.id),
                                        deleteNested: false
                                    })
                                ).then(() => onClick(), () => {})
                            },
                            text: 'Нет'
                        },
                    ]}
                />
            }
        </Grid>
    )
}
