export default function discount(state = {}, action) {
    switch (action.type) {
        case "GET_DISCOUNT_REQUEST":
            return state
        case "GET_DISCOUNT_REQUEST_SUCCESS":
            return {
                discount: action.payload
            }
        case "GET_DISCOUNT_REQUEST_FAILURE":
            return state
        case "DISCOUNT_CLEAR":
            return {}
        default:
            return state
    }
}
