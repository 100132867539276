export default function messenger(state = {chats: [], chat: null, messages: []}, action) {
    switch (action.type) {
        case "MESSENGER_CHATS_REQUEST":
            return state
        case "MESSENGER_CHATS":
            return {
                ...state,
                ...{chats: action.payload}
            }
        case "MESSENGER_CHAT_RECENT_CLEAR":
            return {
                ...state,
                ...{chats: state.chats.map(chat => {
                    if (chat.id === action.payload) {
                        chat.recent = null
                    }

                    return chat
                })}
            }
        case "MESSENGER_CHAT":
            return {
                ...state,
                ...{chat: action.payload},
                ...{messages: (action.payload && state.chat && (action.payload.id !== state.chat.id)) ? [] : state.messages}
            }
        case "MESSENGER_CHATS_FAILURE":
            return state
        case "MESSENGER_ADD_CHAT":
            return {
                ...state,
                ...{chats: !state.chats.find(chat => (chat.id === action.payload.id)) ? [action.payload, ...state.chats] : state.chats}
            }
        case "MESSENGER_CHAT_MESSAGES_REQUEST":
            return state
        case "MESSENGER_CHAT_MESSAGES":
            return {
                ...state,
                ...{messages: action.payload}
            }
        case "MESSENGER_CHAT_MESSAGES_FAILURE":
            return state
        case "MESSENGER_ADD_CHAT_MESSAGE":
            const chats = state.chats.map(chat => {
                if (chat.id === action.payload.chat_id) {
                    chat.message = action.payload

                    if (state.chat && (state.chat.id === chat.id)) {
                        chat.message.viewed = true
                    }

                    if (!chat.message.viewed) {
                        chat.recent += 1
                    }
                }

                return chat
            })

            chats.sort((first, second) => {
                return new Date(second.message ? second.message.created : second.created) - new Date(first.message ? first.message.created : first.created)
            })

            return {
                ...state,
                ...{messages: (state.chat && (state.chat.id === action.payload.chat_id) && !state.messages.find(message => (message.id === action.payload.id))) ? [...state.messages, action.payload] : state.messages},
                ...{chats: chats}
            }
        case "MESSENGER_ADD_CHAT_USER":
        case "MESSENGER_DELETE_CHAT_USER":
            return {
                ...state,
                ...{
                    chat: {...state.chat, ...{users: action.payload.data}}
                }
            }
        default:
            return state
    }
}
