export default function shop(state = {orders: {data: [], meta: {total: 0, per_page: 15, current_page: 1}}}, action) {
    switch (action.type) {
        case "SUPPLIER_ORDERS_REQUEST":
            return {
                orders: state.orders
            }
        case "SUPPLIER_ORDERS":
            return {
                orders: action.payload,
            }
        case "SUPPLIER_ORDERS_FAILURE":
            return {
                orders: {}
            }
        case "SUPPLIER_ORDER_REQUEST":
            return {
                order: state.order,
                orders: state.orders
            }
        case "SUPPLIER_ORDER_RESET":
            return {
                orders: state.orders
            }
        case "SUPPLIER_ORDER":
            return {
                order: action.payload,
                orders: state.orders
            }
        case "SUPPLIER_ORDER_FAILURE":
            return {
                order: state.orders,
                orders: state.orders
            }
        case "EDIT_SUPPLIER_ORDER_REQUEST":
            return {
                order: state.order,
                orders: state.orders
            }
        case "EDIT_SUPPLIER_ORDER_SUCCESS":
            return {
                orders: state.orders
            }
        case "EDIT_SUPPLIER_ORDER_FAILURE":
            return {
                order: state.order,
                orders: state.orders
            }
        case "DELETE_SUPPLIER_ORDER_REQUEST":
            return {
                order: state.order,
                orders: state.orders
            }
        case "VIEW_SUPPLIER_OFFER_REQUEST":
            return {
                orders: state.orders
            }
        case "VIEW_SUPPLIER_OFFER_SUCCESS":
            return {
                offer: action.payload,
                orders: state.orders
            }
        case "VIEW_SUPPLIER_OFFER__FAILURE":
            return {
                order: state.order,
                orders: state.orders
            }
        case "DELETE_SUPPLIER_ORDER_SUCCESS":
            return {
                orders: state.orders
            }
        case "DELETE_SUPPLIER_ORDER_FAILURE":
            return {
                order: state.order,
                orders: state.orders
            }
        default:
            return state
    }
}
