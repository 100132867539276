import React from "react"

import {ShopService} from "../services/shop"
import {history} from "../../../App/helpers/history"
import {NoElmagAccessError} from "../../Marketplace/components/NoElmagAccessError"

export const ShopActions = {
    orders,
    order,
    add,
    edit,
    cancel,
    status,
    lotInfo
}

function orders(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "MY_ORDERS_REQUEST"})

        ShopService.orders(params)
            .then(
                orders => {
                    dispatch({type: "MY_ORDERS", payload: {data: orders.data, meta: orders.meta}})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "MY_ORDERS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    const message = error?.type && error.type === 'supplier_has_no_access' ?  <NoElmagAccessError message={error.message}/> : error.message
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function order(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "MY_ORDER_REQUEST"})
        ShopService.order(id)
            .then(
                order => {
                    dispatch({type: "MY_ORDER", payload: order})
                    dispatch({type: "LOADING", payload: false})
                    resolve(order)
                },
                error => {
                    dispatch({type: "MY_ORDER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function add(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "ADD_MY_ORDER_REQUEST"})

        ShopService.add(values)
            .then(
                response => {
                    dispatch({type: "ADD_MY_ORDER_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    history.push("/supplier/marketplace/orders")
                },
                error => {
                    dispatch({type: "ADD_MY_ORDER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function lotInfo(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "MY_LOT_REQUEST"})
        ShopService.lotInfo(id)
            .then(
                lot => {
                    dispatch({type: "MY_LOT", payload: lot})
                    dispatch({type: "LOADING", payload: false})
                    resolve(lot)
                },
                error => {
                    dispatch({type: "MY_LOT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function edit(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "EDIT_MY_ORDER_REQUEST"})

        ShopService.edit(id, values)
            .then(
                response => {
                    dispatch({type: "EDIT_MY_ORDER_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    history.push("/supplier/marketplace/orders")
                },
                error => {
                    dispatch({type: "EDIT_MY_ORDER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function status(order_id, lot_id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "STATUS_MY_ORDER_REQUEST"})

        ShopService.status(order_id, lot_id, values)
            .then(
                response => {
                    dispatch({type: "STATUS_MY_ORDER_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "STATUS_MY_ORDER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function cancel(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "STATUS_MY_ORDER_REQUEST"})

        ShopService.cancel(id, values)
            .then(
                response => {
                    dispatch({type: "STATUS_MY_ORDER_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                        message:'Предложение успешно отозвано',
                        type:'success'
                        }
                    })
                    history.push("/supplier/marketplace/my-orders")
                    resolve()
                },
                error => {
                    dispatch({type: "STATUS_MY_ORDER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
