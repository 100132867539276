import React from "react"
import {
    CustomPaging,
    IntegratedSelection,
    IntegratedSummary,
    PagingState,
    SelectionState,
    SummaryState
} from '@devexpress/dx-react-grid'
import {
    DragDropProvider,
    PagingPanel,
    Table,
    TableColumnReordering,
    TableColumnResizing,
    TableColumnVisibility,
    TableHeaderRow,
    TableSelection,
    TableSummaryRow
} from "@devexpress/dx-react-grid-material-ui";

import {Grid, Paper} from "@material-ui/core"

import {Grid as GridTable} from "../Table/Grid"
import {StickyTable} from "./StikyTable";
import {Pager} from "./Paging/Pager";
import {TableHeader} from "./TableHeader";

export const CustomTable = (props) => {
    const {
        meta,
        tableName,
        rows,
        columns,
        page,
        selection,
        totalSummaryItems,
        summaryCalculator,
        setTableRef,
        tableColumnExtensions,
        columnsSettings,
        messages,
        filterActionType,
        getValues,
        onSelectionChange,
        classInputContent,
        rowComponentSelection,
        rowComponent,
        filters,
        actions
    } = props

    const TableHeaderContent = ({column, children, ...restProps}) => {
        let button = null;

        return (
            <TableHeader
                column={column}
                tablename={tableName}
                getvalues={getValues}
                filteractiontype={filterActionType}
                {...(filters ? {filters: filters} : null)}
                {...(filters && filters.hasOwnProperty(column.name) ? {custombutton: filters[column.name]?.button} : null)}
                {...(filters && filters.hasOwnProperty(column.name) ? {nullbutton: filters[column.name]?.nullButton} : false)}
                {...restProps}
            >
                {children}
                {button}
            </TableHeader>
        )
    };

    const getColumnsNames = (columns) => {
        return columns.map(column => column.name)
    }

    return <Grid item xs={12}>
        <Paper>
            <Grid container direction="row" alignItems="center">
                <Grid item className={classInputContent}>
                    <GridTable rows={rows} columns={columns}>
                        <PagingState
                            currentPage={page.page}
                            onCurrentPageChange={e => page.setPage(e)}
                            pageSize={page.rowsPerPage}
                            onPageSizeChange={page.handlePageSizeChange}
                        />
                        <CustomPaging
                            totalCount={meta.total}
                        />
                        {selection ? (
                            <SelectionState
                                selection={selection.selection}
                                onSelectionChange={onSelectionChange}
                            />
                        ) : null}
                        <DragDropProvider/>
                        {totalSummaryItems ? (
                            <SummaryState
                                totalItems={totalSummaryItems}
                            />
                        ) : null}
                        {summaryCalculator ? (
                            <IntegratedSummary
                                calculator={summaryCalculator}
                            />
                        ) : null}
                        <Table
                            noDataCellComponent={() => {
                                return null;
                            }}
                            tableComponent={props => <StickyTable {...props} setTableRef={setTableRef}/>}
                            rowComponent={rowComponent ?? null}
                            columnExtensions={tableColumnExtensions ?? null}
                        />
                        {columnsSettings.columnOrder && columnsSettings.setColumnOrder
                            ? <TableColumnReordering order={columnsSettings.columnOrder} onOrderChange={columnsSettings.setColumnOrder}/>
                            : <TableColumnReordering defaultOrder={getColumnsNames(columns)}/>
                        }
                        <TableColumnResizing
                            columnWidths={columnsSettings.columnWidths}
                            onColumnWidthsChange={columnsSettings.setColumnWidths}
                        />
                        <TableHeaderRow contentComponent={TableHeaderContent} />
                        {columnsSettings.hiddenColumnNames && columnsSettings.setHiddenColumnNames ?
                            <TableColumnVisibility hiddenColumnNames={columnsSettings.hiddenColumnNames} onHiddenColumnNamesChange={columnsSettings.setHiddenColumnNames}/>
                            :
                            <TableColumnVisibility defaultHiddenColumnNames={getColumnsNames(columns)}/>
                        }
                        {messages ? <TableSummaryRow messages={messages}/> : null}
                        {selection ? <IntegratedSelection/> : null}
                        {selection ? (
                            <TableSelection
                                showSelectAll
                                selectByRowClick
                                showSelectionColumn={true}
                                highlightRow
                                rowComponent={rowComponentSelection}
                            />
                        ) : null}
                        <PagingPanel
                            containerComponent={props => <Pager {...props} actions={actions} />}
                            messages={{
                                showAll: 'Все',
                                rowsPerPage: 'Записей на странице:',
                                info: '{from}-{to} из {count}'
                            }}
                            pageSizes={[50, 100, 200]}
                        />
                    </GridTable>
                </Grid>
            </Grid>
        </Paper>
    </Grid>
}
