import {CartService} from "../services/cart"

export const CartActions = {
    cart,
    carts,
    order,
    remove
}

function cart(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CART_REQUEST"})

        CartService.cart(id)
            .then(
                cart => {
                    dispatch({type: "CART", payload: cart})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "CART_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function carts(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CARTS_REQUEST"})

        CartService.carts(params)
            .then(
                carts => {
                    dispatch({type: "CARTS", payload: carts})
                    dispatch({type: "LOADING", payload: false})
                    resolve(carts)
                },
                error => {
                    dispatch({type: "CARTS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function order(id, formData) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CART_ORDER_REQUEST"})

        CartService.order(id, formData)
            .then(
                response => {
                    dispatch({type: "CART_ORDER_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "CART_ORDER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: 'Не удалось сделать заказ',
                            type: 'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function remove(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CART_DELETE_REQUEST"})

        CartService.remove(id)
            .then(
                response => {
                    dispatch({type: "CART_DELETE_SUCCESS", payload: id})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "CART_DELETE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
