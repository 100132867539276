import {PurchasePlanItemsService} from "../services/purchasePlanItems"

export const PurchasePlanItemsActions = {
    addItems,
    itemsByPurchasePlanID,
    totalByPurchasePlanID,
    items,
    total,
    editItems,
    deleteItems,
    copyItems,
    item,
    deleteItemsInPurchasePlan,
    itemsByParentIDs,
    uploadItems,
    moveItems,
    updateStandard,
    filter
}

function addItems(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "ADD_PURCHASE_REQUEST"})

        PurchasePlanItemsService.addItems(params)
            .then(
                () => {
                    dispatch({type: "ADD_PURCHASE_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Товары успешно созданы',
                            type:'success'
                        }
                    })
                    resolve()
                },
                error => {
                    dispatch({type: "ADD_PURCHASE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function editItems(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "ADD_PURCHASE_REQUEST"})

        PurchasePlanItemsService.editItems(params)
            .then(
                () => {
                    dispatch({type: "ADD_PURCHASE_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Товары успешно созданы',
                            type:'success'
                        }
                    })
                    resolve()
                },
                error => {
                    dispatch({type: "ADD_PURCHASE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function items(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PurchasePlanItemsService.items(params)
            .then(
                items => {
                    dispatch({type: "PURCHASE_PLAN_ITEMS_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(items)
                },
                error => {
                    dispatch({type: "PURCHASE_PLAN_ITEMS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function total(params) {
    return dispatch => new Promise((resolve, reject) => {
        PurchasePlanItemsService.items({...params, meta: 'total'})
            .then(
                items => {
                    resolve(items)
                },
                error => {
                    reject(error)
                }
            )
    })
}

function itemsByPurchasePlanID(purchasePlanID, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PurchasePlanItemsService.itemsByPurchasePlanID(purchasePlanID, params)
            .then(
                items => {
                    dispatch({type: "PURCHASE_PLAN_ITEMS_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(items)
                },
                error => {
                    dispatch({type: "PURCHASE_PLAN_ITEMS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function totalByPurchasePlanID(purchasePlanID, params) {
    return dispatch => new Promise((resolve, reject) => {
        PurchasePlanItemsService.itemsByPurchasePlanID(purchasePlanID, {...params, meta: 'total'})
            .then(
                items => {
                    resolve(items)
                },
                error => {
                    reject(error)
                }
            )
    })
}

function deleteItems(ids) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_PLAN_PRODUCTS_DELETE_REQUEST"})

        PurchasePlanItemsService.deleteItems(ids)
            .then(
                _ => {
                    dispatch({type: "PURCHASE_PLAN_PRODUCTS_DELETE_SUCCESS"})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Товары успешно удалены',
                            type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_PLAN_PRODUCTS_DELETE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function deleteItemsInPurchasePlan(purchasePlanID, ids) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_PLAN_PRODUCTS_DELETE_REQUEST"})

        PurchasePlanItemsService.deleteItemsInPurchasePlan(purchasePlanID, ids)
            .then(
                _ => {
                    dispatch({type: "PURCHASE_PLAN_PRODUCTS_DELETE_SUCCESS"})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Товары успешно удалены',
                            type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_PLAN_PRODUCTS_DELETE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function copyItems(purchasePlanID, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_PLAN_PRODUCTS_COPY_REQUEST"})

        PurchasePlanItemsService.copyItems(purchasePlanID, values)
            .then(
                _ => {
                    dispatch({type: "PURCHASE_PLAN_PRODUCTS_COPY_SUCCESS"})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Товары успешно скопированы',
                            type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_PLAN_PRODUCTS_COPY_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function item(purchasePlanID, key) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_PLAN_ITEM_REQUEST"})

        PurchasePlanItemsService.item(purchasePlanID, key)
            .then(
                item => {
                    dispatch({type: "PURCHASE_PLAN_ITEM_SUCCESS", payload: item})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_PLAN_ITEM_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function itemsByParentIDs(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_PLAN_ITEMS_BY_PARENT_IDS_REQUEST"})

        PurchasePlanItemsService.itemsByParentIDs(params)
            .then(
                items => {
                    dispatch({
                        type: "PURCHASE_PLAN_ITEMS_BY_PARENT_IDS_SUCCESS",
                        payload: {
                            data: items.data,
                            meta: items.meta,
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve(items)
                },
                error => {
                    dispatch({type: "PURCHASE_PLAN_ITEMS_BY_PARENT_IDS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function uploadItems(purchasePlanID, formData) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: "PLANNING_UPLOAD_ITEMS_REQUEST" })
        dispatch({ type: "LOADING", payload: true })
        PurchasePlanItemsService.uploadItems(purchasePlanID, formData)
            .then(
                resp => {
                    dispatch({type: "PLANNING_UPLOAD_ITEMS_SUCCESS", payload: resp.contract})
                    dispatch({ type: "LOADING", payload: false })
                    dispatch({
                        type: "ALERT_SUCCESS", payload: {
                            message:'Успешно загружен',
                            type:'success'
                        }
                    })
                    resolve(resp)
                },
                error => {
                    dispatch({ type: "PLANNING_UPLOAD_ITEMS_FAILURE" })
                    dispatch({ type: "LOADING", payload: false })
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }  })
                    reject(error.error)
                })

    })
}

function moveItems(purchasePlanID, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_PLAN_PRODUCTS_COPY_REQUEST"})

        PurchasePlanItemsService.moveItems(purchasePlanID, values)
            .then(
                _ => {
                    dispatch({type: "PURCHASE_PLAN_PRODUCTS_COPY_SUCCESS"})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Товары успешно перемещены',
                            type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_PLAN_PRODUCTS_COPY_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function updateStandard(id, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_PLAN_PRODUCT_UPDATE_STANDARD_REQUEST"})

        PurchasePlanItemsService.updateStandard(id, params)
            .then(
                _ => {
                    dispatch({type: "PURCHASE_PLAN_PRODUCT_UPDATE_STANDARD_SUCCESS"})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Эталон успешно обновлен',
                            type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_PLAN_PRODUCT_UPDATE_STANDARD_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function filter(params) {
    return dispatch => new Promise((resolve, reject) => {
        PurchasePlanItemsService.filter(params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    reject(error)
                }
            )
    })
}
