import React, {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import {Field} from "formik"
import {TextField} from "formik-material-ui"

import {InputAdornment} from '@material-ui/core'
import {Autocomplete} from "@material-ui/lab"

export const FieldString = (props) => {
    const { id, label, values, attribute, attributes, addition, setFieldValue, setFieldTouched, setSearch, isSubmitting } = props

    const { items } = useSelector(state => { return {items: (attribute.dictionary ? state.standard.dictionaries : state.standard.attributes)} })

    const [value, setValue] = useState({ value: props.value })
    const [listeners] = useState((!!attribute.dictionary && attribute.dictionary.hasOwnProperty('listeners') && !!attribute.dictionary.listeners.length) ? attribute.dictionary.listeners : [])
    const [disabled, setDisabled] = useState(!!(isSubmitting || props.disabled || !!listeners.find(
        listener => !!attributes.find(attr => (attr.dictionary && ((listener.id === attr.dictionary.id) && ((listener.relation === 'one') || !values[attr.id]))))
    )))

    useEffect(() => {
        setDisabled(!!(isSubmitting || props.disabled || !!listeners.find(
            listener => !!attributes.find(attr => (attr.dictionary && ((listener.id === attr.dictionary.id) && ((listener.relation === 'one') || !values[attr.id]))))
        )))
        // eslint-disable-next-line
    }, [values, isSubmitting])

    useEffect(() => {
        setValue({ value: props.value ?? (attribute.multiple ? [] : '') })
    }, [props.value])

    return (
        <Autocomplete
            freeSolo
            multiple={!!attribute.multiple}
            filterOptions={options => options}
            options={ items }
            disabled={ isSubmitting || disabled }
            getOptionLabel={option => option ? ((option instanceof Object) ? (option.value ?? '') : option) : ''}
            name={`attributes.${id}`}
            defaultValue={attribute.multiple ? value.value : value}
            value={attribute.multiple ? value.value : value}
            onChange={(e, value) => {
                if (value && value.hasOwnProperty('items')) {
                    if (attribute.dictionary && attribute.dictionary.hasOwnProperty('attributes') && !!attribute.dictionary.attributes.length) {
                        const attr = attributes.find(attr => attr.dictionary && !!attribute.dictionary.attributes.find(item => (item.type === 'one') && item.dictionary && (item.dictionary.id === attr.dictionary.id)))
                        if (attr) {
                            const item = value.items.find(item => item.attribute.dictionary.id === attr.dictionary.id)
                            if (item) {
                                setFieldValue(`attributes.${attr.id}`, item.value, true)
                            }
                        }
                    }
                }
                setFieldValue(`attributes.${id}`, attribute.multiple ? [...new Map(value.map(el => el.hasOwnProperty('value') ? [el.value, el] : [el, el])).values()].map(el => el.hasOwnProperty('value') ? el.value : el) : ((value && value.hasOwnProperty('value')) ? value.value : value))
            }}
            onBlur={() => {
                setValue({ value: props.value })
                if (attribute.multiple) {
                    setFieldValue(`attributes.${id}`, [...props.value])
                }
                setFieldTouched(`attributes.${id}`, true)
                setSearch({search: null, attribute: attribute.id, type: (attribute.dictionary ? attribute.dictionary.id : null), listeners: {}})
            }}
            renderInput={params => (
                <Field
                    component={TextField}
                    {...params}
                    InputProps={{
                        value: [],
                        ...params.InputProps,
                        ...((!attribute.multiple && addition && addition.prefix.value) ? {startAdornment: <InputAdornment position="start">{addition.prefix.value}</InputAdornment>} : {}),
                        ...((!attribute.multiple && addition && addition.postfix.value) ? {endAdornment: <InputAdornment position="end">{addition.postfix.value}</InputAdornment>} : {}),
                        onChange: (e) => {
                            if (!attribute.multiple) {
                                setValue(e.target.value)
                                setFieldValue(`attributes.${id}`, e.target.value)
                            }

                            setSearch({search: e.target.value, attribute: attribute.id, type: (attribute.dictionary ? attribute.dictionary.id : null), listeners: listeners.reduce((obj, listener) => {
                                obj[listener.id] = values[attributes.find(attr => (attr.dictionary && (listener.id === attr.dictionary.id)))?.id]
                                return obj
                            }, {})})
                        }
                    }}
                    name={`attributes.${id}`}
                    label={label}
                    fullWidth
                />
            )}
        />
    )
}
