import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce} from 'use-debounce'
import {Field, Form, Formik, getIn} from "formik"
import {TextField} from "formik-material-ui"
import * as Yup from "yup"
import DateFnsUtils from "@date-io/date-fns"
import format from "date-fns/format"
import {ru} from "date-fns/locale"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    makeStyles,
    MenuItem,
    StepIcon,
    TextField as MuiTextField,
    Typography
} from "@material-ui/core"
import {Autocomplete, createFilterOptions} from "@material-ui/lab"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"
import {Clear, Add as AddIcon, Close as CloseIcon} from "@material-ui/icons"

import {TextFieldWithError as CustomTextField} from "../../App/components/Inputs/TextFieldWithError"
import {Submit} from "../../App/components/Button/Submit"
import {DeliveryActions} from "../../Delivery/actions/delivery"
import {ReclamationActions} from "../actions/reclamation"
import {FileUpload} from "../../App/components/Inputs/FileUpload"
import {ContractActions} from "../../Contract/actions/contract"

const useStyles = makeStyles(theme => ({
    item: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "cursor": "pointer"
    },
    fullWidth: {
        "width": "100%"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    content: {
        "padding": "0 !important"
    },
    input: {
        "width": "100%",
    },
    column: {
        "height": "86px"
    },
    section: {
        "border-right": "2px solid #c1c1c1",
        "padding": "20px 30px",
        "overflow-y": "auto",
        "max-height": "calc(80vh - 63px)"
    },
    additionally: {
        "max-height": "calc(80vh - 63px)"
    },
    dialogContent: {
        "max-height": "calc(100vh - 210px)"
    },
    footer: {
        "background-color": "#e7eaee",
        "justify-content": "flex-start"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    dialog: {
        "max-height": "70vh"
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    searchLabel: {
        "margin-bottom": "15px",
    },
    footerContent: {
        "padding": "10px 0px"
    },
    eclipsis: {
        "overflow": "hidden",
        "text-overflow": "ellipsis",
        "white-space": "nowrap"
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    titleDialog: {
        "& h2": {
            "display": "flex",
            "justify-content": "space-between",
        },
    }
}))

export const Create = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { companies, orders, orderItems }  = useSelector(state => { return {...state.contract, ...state.delivery}})
    const { types, demands }  = useSelector(state => state.reclamation)

    const { reclamation } = props
    const [open, setOpen] = useState(props.open)
    const [search, setSearch] = useState(null)
    const [company, setCompany] = useState(null)
    const [number, setNumber] = useState(null)
    const [loading, setLoading] = useState(false)
    const [searchItems, setSearchItems] = useState(null)
    const [searchRequest] = useDebounce(searchItems, 900)
    const [orderId, setOrderId] = useState(null)

    useEffect(() => {
        const getData = async () => {
            await dispatch(ReclamationActions.types())
            await dispatch(ReclamationActions.demands())
        }

        if (!types.length || !demands.length) {
            getData().then(r => {
                setOpen(props.open)
                setLoading(true)
            })
        } else {
            setOpen(props.open)
            setLoading(true)
        }
        // eslint-disable-next-line
    }, [dispatch, props.open]);

    const getOrderItems = async () => {
        return await dispatch(DeliveryActions.items(orderId,{
            limit: 30,
            ...(searchRequest ? {search: searchRequest} : {})
        }))
    }

    useEffect(() => {
        if (searchRequest) {
            getOrderItems().then(r => {})
        }
        // eslint-disable-next-line
    }, [dispatch, searchRequest])


    useEffect(() => {
        if (search) {
            const getCompanies = async () => {
                let params = {
                    limit: 10,
                    page: 1,
                    reclamation: true,
                    ...(search ? {name: search} : {})
                }

                return await dispatch(ContractActions.companies(params))
            }

            getCompanies().then(companies => {})
        }
    }, [dispatch, search])

    useEffect(() => {
        if (number) {
            const getOrders = async () => {
                return await dispatch(DeliveryActions.orders({
                    ...{
                        limit: 10,
                        page: 1
                    },
                    ...(number ? {number: number} : {}),
                    ...(company ? {company_id: company.id} : {})
                }))
            }

            getOrders().then(_ => {})
        }
        // eslint-disable-next-line
    }, [dispatch, number])

    useEffect(() => {
        if (reclamation) {
            setCompany(reclamation.delivery.contract.offer.company)
        }
    }, [reclamation])

    const handleClose = () => {
        setOpen(false)
        setCompany(null)
        setNumber(null)
        setSearch(null)
        setSearchItems(null)
        props.setDialog(false)
    }

    return loading ? (
        <Grid item>
            <Dialog
                open={!!open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle className={classes.titleDialog}>
                    {reclamation ? 'Редактирование рекламация' : 'Новая рекламация'}
                    <CloseIcon className={classes.close} onClick={handleClose}/>
                </DialogTitle>
                <Formik
                    initialValues={{
                        company: {
                            id: reclamation ? reclamation.delivery.contract.offer.company.id : '',
                            name: reclamation ? reclamation.delivery.contract.offer.company.legal_detail.name : '',
                            inn: reclamation ? reclamation.delivery.contract.offer.company.legal_detail.inn : '',
                            kpp: reclamation ? reclamation.delivery.contract.offer.company.legal_detail.kpp : '',
                            bin: reclamation ? reclamation.delivery.contract.offer.company.legal_detail.bin : '',
                            ogrn: reclamation ? reclamation.delivery.contract.offer.company.legal_detail.ogrn : '',
                            country: {
                                code: reclamation ? reclamation.delivery.contract.offer.company.legal_detail.city.region.country.code : ''
                            },
                            type: reclamation ? reclamation.delivery.contract.offer.company.type : ''
                        },
                        order: {
                            id: reclamation ? reclamation.delivery.id : '',
                            number: reclamation ? reclamation.delivery.number : '',
                            contract: {
                                number: reclamation ? reclamation.delivery.contract.number ?? reclamation.delivery.contract.id : ''
                            },
                            items: reclamation ? reclamation.delivery.items : ''
                        },
                        number: reclamation ? reclamation.number : '',
                        type: reclamation ? reclamation.type.id : '',
                        item: {
                            id: reclamation ? reclamation.item.id : '',
                            name: reclamation ? reclamation.item.name : '',
                            amount: reclamation ? reclamation.delivery.items.find(item => (item.id === reclamation.item.id)).amount : ''
                        },
                        claim: reclamation ? (reclamation.claim ?? '') : '',
                        amount: reclamation ? reclamation.amount : '',
                        discovered: reclamation ? new Date(reclamation.discovered) : null,
                        correction: reclamation ? new Date(reclamation.correction) : null,
                        demand: reclamation ? reclamation.demand.id : '',
                        description: reclamation ? reclamation.description : '',
                        files: {
                            confirmation_document: reclamation ? reclamation.files.filter(file => file.type && (file.type.key === 'confirmation_document')) : [],
                            scanned_reclamation: reclamation ? reclamation.files.filter(file => file.type && (file.type.key === 'scanned_reclamation')) : [],
                            photo: reclamation ? reclamation.files.filter(file => file.type && (file.type.key === 'photo')) : [],
                            other: reclamation ? reclamation.files.filter(file => !file.type) : []
                        }
                    }}
                    validationSchema={Yup.object().shape({
                        company: Yup.object().shape({
                            name: Yup.string().nullable().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            inn: Yup.string().nullable().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!'),
                            kpp: Yup.string().nullable().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!'),
                            bin: Yup.string().nullable().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!'),
                            ogrn: Yup.string().nullable().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                        }),
                        discovered: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        correction: Yup.mixed().test('min-date', 'Некорректная дата', function(value) {
                            let currentDate = new Date(value);
                            let minDate = new Date()

                            return currentDate.valueOf() - minDate.valueOf() >= 0;
                        }).required("Поле не может быть пустым или состоять из одних пробелов!"),
                        order: Yup.object().shape({
                            id: Yup.number().nullable().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            number: Yup.number().nullable().required("Поле не может быть пустым или состоять из одних пробелов!")
                        }),
                        item: Yup.object().shape({
                            id: Yup.number().nullable().required("Поле не может быть пустым или состоять из одних пробелов!")
                        }),
                        number: Yup.string().nullable().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        type: Yup.string().nullable().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        demand: Yup.string().nullable().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        amount: Yup.string().nullable().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        description: Yup.string().nullable().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        claim: Yup.string().when('type', {
                            is: (val) => (parseFloat(val) === parseFloat(types.find(type => (type.name === 'Другое')).id)),
                            then: Yup.string().nullable().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            otherwise: Yup.string()
                        })
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        const formData = new FormData()

                        for (const i in values) {
                            if (values.hasOwnProperty(i)) {
                                if (values[i]) {
                                    switch (i) {
                                        case 'discovered':
                                            formData.append(i, format(values[i], 'yyyy-MM-dd'))
                                            break
                                        case 'correction':
                                            formData.append(i, format(values[i], 'yyyy-MM-dd'))
                                            break
                                        case 'company':
                                            formData.append('company[id]', values[i].id)
                                            break
                                        case 'item':
                                            formData.append('item[id]', values[i].id)
                                            break
                                        case 'order':
                                            formData.append('order[id]', values[i].id)
                                            break
                                        case 'files':
                                            for (const i in values.files) {
                                                if (values.files.hasOwnProperty(i) && !!values.files[i].length) {
                                                    values.files[i].forEach((file, index) => {
                                                        if (file instanceof File) {
                                                            formData.append(`files[${i}][${index}]`, file)
                                                        } else {
                                                            formData.append(`files[${i}][${index}][id]`, file.id)
                                                        }
                                                    })
                                                }
                                            }
                                            break
                                        default:
                                            formData.append(i, values[i])
                                    }
                                }
                            }
                        }

                        dispatch(reclamation ? ReclamationActions.edit(reclamation.id, formData) : ReclamationActions.add(formData)).then(
                            () => {
                                setSubmitting(false)
                                handleClose()
                            },
                            errors => {
                                if (errors) {
                                }
                                setSubmitting(false);
                            }
                        )
                    }}

                    validate={ values => {
                        const errors = {};

                        if (values.amount && parseInt(values.amount) > parseInt(values.item.amount)) {
                            errors.amount = `В заказе ${values.item.amount} шт.`
                        }

                        return errors
                    }}
                >
                    {({
                          dirty,
                          values,
                          errors,
                          touched,
                          validateField,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setErrors,
                          setValues,
                          setFieldValue,
                          setFieldError,
                          setFieldTouched,
                          isSubmitting,
                          isValid,
                          submitForm,
                          validateForm
                      }) => (
                        <Form>
                            <DialogContent className={classes.dialogContent}>
                                <Grid container className={classes.fullWidth} direction="row" justify="center" alignItems="stretch">
                                    <Grid item className={classes.fullWidth}>
                                        <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
                                            <Grid item>
                                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                                    <Grid item>
                                                        <StepIcon icon={1} active={true} classes={{
                                                            root: classes.stepIcon,
                                                            text: classes.stepLabel
                                                        }}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className={classes.stepTitle}>Реквизиты поставщика</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid item className={classes.list}>
                                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                        <Grid item xs={12} className={classes.column}>
                                                            <Autocomplete
                                                                filterOptions={(options, state) => options}
                                                                name={'company.name'}
                                                                required
                                                                options={companies.data ?? []}
                                                                value={values.company}
                                                                getOptionLabel={company => {
                                                                    return company ? (company.hasOwnProperty('legal_detail') ? company.legal_detail.name : (company.name ?? '')) : ''
                                                                }}
                                                                noOptionsText='Поставщик'
                                                                onChange={async (e, item) => {
                                                                    await setFieldValue('company', item ? {id: item.id, name: item.legal_detail.name, inn: item.legal_detail.inn, kpp: item.legal_detail.kpp, bin: item.legal_detail.bin, type: item.type, country: {code: item.legal_detail.city.region.country.code}} : {name: null, inn: null, kpp: null, bin: null, type: null, country: {code: null}})
                                                                    setCompany(item ?? null)
                                                                    if (!item) {
                                                                        await setFieldValue('order', {
                                                                            id: '',
                                                                            number: '',
                                                                            contract: {
                                                                                number: ''
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                                renderInput={params => {
                                                                    const error = getIn(touched, 'company.name') && getIn(errors, 'company.name');

                                                                    return <Field
                                                                        fullWidth
                                                                        component={MuiTextField}
                                                                        required
                                                                        {...params}
                                                                        onChange={(e) => {
                                                                            setSearch(e.target.value)
                                                                        }}
                                                                        error={!!error}
                                                                        helperText={error}
                                                                        name={'company.name'}
                                                                        label="Поставщик"
                                                                    />
                                                                }}
                                                                onFocus={(e, value) => {
                                                                    dispatch({type: 'CONTRACT_ALL_COMPANIES_CLEAR'})
                                                                    setFieldTouched('company.name', true, true)
                                                                }}
                                                                onBlur={(e, value) => {
                                                                    setFieldTouched('company.name', true, true)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    {!!(values.company.name) ? (
                                                        (values.company.country.code === 'ru')
                                                            ?
                                                            <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                                <Grid item className={classes.column} xs={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        name="company.inn"
                                                                        type="text"
                                                                        label="ИНН поставщика"
                                                                        disabled
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                                {(values.company.type === 'legal') ? (
                                                                    <Grid item className={classes.column} xs={4}>
                                                                        <Field
                                                                            fullWidth
                                                                            name="company.kpp"
                                                                            type="text"
                                                                            label="КПП поставщика"
                                                                            disabled
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                ) : null}
                                                                {(values.company.ogrn) ? (
                                                                    <Grid item className={classes.column} xs={4}>
                                                                        <Field
                                                                            fullWidth
                                                                            name="company.ogrn"
                                                                            type="text"
                                                                            label="ОГРН поставщика"
                                                                            disabled
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                ) : null}
                                                            </Grid>
                                                            :
                                                            <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                                <Grid item xs={4} className={classes.column}>
                                                                    <Field
                                                                        fullWidth
                                                                        name="company.bin"
                                                                        type="text"
                                                                        label="БИН поставщика"
                                                                        disabled
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                    ) : null}
                                                    {!!company ? (
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                            <Grid item xs={4} className={classes.column}>
                                                                <Autocomplete
                                                                    name={'order.number'}
                                                                    required
                                                                    options={orders?.data ?? []}
                                                                    value={values.order.number}
                                                                    getOptionLabel={item => {
                                                                        return item ? ((item instanceof Object) ? `№ ${item.number}, ${format(new Date(item.created), 'PP', {locale: ru})}` : `${item}`) : ''
                                                                    }}
                                                                    noOptionsText='№ заказа'
                                                                    onChange={(e, item) => {
                                                                        if (item && ((item.status.index !== 15) && (item.status.index !== 16))) {
                                                                            setErrors({order: {number: 'Запрещено сохранять рекламацию на данной стадии заказа!'}})
                                                                        } else if (item) {
                                                                            setOrderId(item.id)
                                                                            setFieldValue('order', item ? {...item, ...{contract: {number: item.contract.number ?? item.contract.id}}} : {id: '', number: '', contract: {number: ''}})
                                                                        }
                                                                    }}
                                                                    renderInput={params => {
                                                                        const error = getIn(touched, 'order.number') && getIn(errors, 'order.number');

                                                                        return <Field
                                                                            fullWidth
                                                                            component={MuiTextField}
                                                                            required
                                                                            {...params}
                                                                            onChange={(e) => {
                                                                                setNumber(e.target.value)
                                                                            }}
                                                                            error={!!error}
                                                                            helperText={error}
                                                                            name={'order.number'}
                                                                            label="№ заказа"
                                                                        />
                                                                    }}
                                                                    onFocus={(e, value) => {
                                                                        dispatch({type: 'DELIVERY_ORDERS_CLEAR'})
                                                                        setFieldTouched('order.number', true, true)
                                                                    }}
                                                                    onBlur={(e, value) => {
                                                                        setFieldTouched('order.number', true, true)
                                                                    }}
                                                                />
                                                            </Grid>
                                                            {!!values.order.id ? (
                                                                <Grid item xs={4} className={classes.column}>
                                                                    <Field
                                                                        fullWidth
                                                                        name="order.contract.number"
                                                                        type="text"
                                                                        label="№ договора"
                                                                        disabled
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                            ) : null}
                                                        </Grid>
                                                    ) : null}
                                                </Grid>
                                            </Grid>
                                            {!!(values.order.id) ? (
                                                <React.Fragment>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                                            <Grid item>
                                                                <StepIcon icon={2} active={true} classes={{
                                                                    root: classes.stepIcon,
                                                                    text: classes.stepLabel
                                                                }}/>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography className={classes.stepTitle}>Данные о товаре/услуге</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                            <Grid item className={classes.column} xs={4}>
                                                                <Field
                                                                    fullWidth
                                                                    name="number"
                                                                    type="text"
                                                                    label="№ подтверждающего документа"
                                                                    required
                                                                    component={CustomTextField}
                                                                />
                                                            </Grid>
                                                            <Grid item className={classes.column} xs={4}>
                                                                <Field
                                                                    fullWidth
                                                                    type="text"
                                                                    name="type"
                                                                    label="Тип товарной претензии"
                                                                    select
                                                                    required
                                                                    variant="standard"
                                                                    component={TextField}
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                >
                                                                    {
                                                                        types.map(item => (
                                                                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                                        ))
                                                                    }
                                                                </Field>
                                                            </Grid>
                                                            {(!!(values.type) && (types.find(type => (type.name === 'Другое')).id === values.type)) &&
                                                                <Grid item className={classes.column} xs={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        name="claim"
                                                                        type="text"
                                                                        label="Претензия"
                                                                        required
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                            <Grid item xs={12} className={classes.column}>
                                                                <Autocomplete
                                                                    filterOptions={createFilterOptions({
                                                                        matchFrom: 'start',
                                                                        stringify: item => item.name
                                                                    })}
                                                                    name={'item.name'}
                                                                    required
                                                                    options={orderItems.data}
                                                                    value={values.item}
                                                                    getOptionLabel={item => {
                                                                        return item ? item.name : ''
                                                                    }}
                                                                    noOptionsText='Товар/Услуга'
                                                                    onChange={(e, item) => {
                                                                        setValues({
                                                                            ...values,
                                                                            ...{
                                                                                item: item ? item : {id: '', name: ''},
                                                                                amount: ''
                                                                            }
                                                                        })
                                                                    }}
                                                                    renderInput={params => {
                                                                        const error = getIn(touched, 'item.name') && getIn(errors, 'item.name');

                                                                        return <Field
                                                                            fullWidth
                                                                            component={MuiTextField}
                                                                            required
                                                                            {...params}
                                                                            onChange={(e) => {
                                                                                // setSearch(e.target.value)
                                                                                setSearchItems(e.target.value)
                                                                            }}
                                                                            error={!!error}
                                                                            helperText={error}
                                                                            name={'item.name'}
                                                                            label="Товар/Услуга"
                                                                        />
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                            <Grid item xs={4} className={classes.column}>
                                                                <Field
                                                                    fullWidth
                                                                    name="amount"
                                                                    type="number"
                                                                    label="Количество"
                                                                    required
                                                                    step={1}
                                                                    inputProps={{
                                                                        min: 0,
                                                                        max: values.item.amount ?? 0
                                                                    }}
                                                                    disabled={!values.item.amount}
                                                                    component={CustomTextField}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4} className={classes.column}>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                    <KeyboardDatePicker
                                                                        disableToolbar
                                                                        fullWidth
                                                                        error={Boolean(errors.hasOwnProperty('discovered') && touched.hasOwnProperty('discovered'))}
                                                                        helperText={errors.hasOwnProperty('discovered') && touched.hasOwnProperty('discovered') && (errors.discovered ? errors.discovered : null)}
                                                                        name="discovered"
                                                                        required
                                                                        orientation="landscape"
                                                                        key={`discovered`}
                                                                        variant="inline"
                                                                        minDateMessage="Некорректная дата"
                                                                        format="dd-MM-yyyy"
                                                                        openTo="year"
                                                                        autoOk
                                                                        label="Дата обнаружения"
                                                                        value={values.discovered}
                                                                        onChange={date => {
                                                                            setFieldValue("discovered", date);
                                                                        }}
                                                                        onBlur={e => {
                                                                            setFieldTouched("discovered", true, false)
                                                                        }}
                                                                        onClose={(e, value) => {
                                                                            setFieldTouched("discovered", true, false)
                                                                        }}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </Grid>
                                                            <Grid item xs={4} className={classes.column}>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                    <KeyboardDatePicker
                                                                        disableToolbar
                                                                        fullWidth
                                                                        error={Boolean(errors.hasOwnProperty('correction') && touched.hasOwnProperty('correction'))}
                                                                        helperText={errors.hasOwnProperty('correction') && touched.hasOwnProperty('correction') && (errors.correction ? errors.correction : null)}
                                                                        name="correction"
                                                                        required
                                                                        orientation="landscape"
                                                                        key={`correction`}
                                                                        variant="inline"
                                                                        minDateMessage="Некорректная дата"
                                                                        format="dd-MM-yyyy"
                                                                        openTo="year"
                                                                        autoOk
                                                                        disablePast
                                                                        label="Срок исправления до"
                                                                        value={values.correction}
                                                                        onChange={date => {
                                                                            setFieldValue("correction", date);
                                                                        }}
                                                                        onBlur={e => {
                                                                            setFieldTouched("correction", true, false)
                                                                        }}
                                                                        onClose={(e, value) => {
                                                                            setFieldTouched("correction", true, false)
                                                                        }}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                            <Grid item xs={12} className={classes.column}>
                                                                <Field
                                                                    fullWidth
                                                                    type="text"
                                                                    name="demand"
                                                                    label="Требования рекламации"
                                                                    select
                                                                    required
                                                                    variant="standard"
                                                                    component={TextField}
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                >
                                                                    {
                                                                        demands.map(item => (
                                                                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                                        ))
                                                                    }
                                                                </Field>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                            <Grid item xs={12} className={classes.column}>
                                                                <Field
                                                                    fullWidth
                                                                    name="description"
                                                                    type="text"
                                                                    multiline
                                                                    label="Описание проблемы"
                                                                    required
                                                                    component={CustomTextField}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
                                                            <Grid item>
                                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                                    <Grid item>
                                                                        <Field
                                                                            fullWidth
                                                                            size="small"
                                                                            component={(props) => (
                                                                                <FileUpload {...props} multiple endIcon={<AddIcon/>} />
                                                                            )}
                                                                            label="Скан-копия рекламации"
                                                                            name={`files.scanned_reclamation`}
                                                                            InputLabelProps={{
                                                                                name: null
                                                                            }}
                                                                            InputProps={{
                                                                                multiple: true,
                                                                                onChange: (event) => {
                                                                                    setFieldValue(`files.scanned_reclamation`, (values.files.scanned_reclamation ?? []).concat(Array.from(event.currentTarget.files)));
                                                                                    setFieldTouched(`files.scanned_reclamation`, true, false)
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                                                            {Array.from(values.files.scanned_reclamation ?? []).map((file, index) => {
                                                                                return (
                                                                                    <Grid key={index} item>
                                                                                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                                                            <Grid item>
                                                                                                <Typography>{file.name}</Typography>
                                                                                            </Grid>
                                                                                            <Grid item>
                                                                                                <IconButton
                                                                                                    size={"small"}
                                                                                                    onClick={() => {
                                                                                                        setFieldValue('files.scanned_reclamation', (values.files.scanned_reclamation ?? []).filter((_, i) => i !== index))
                                                                                                    }}
                                                                                                >
                                                                                                    <Clear />
                                                                                                </IconButton>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                )
                                                                            })}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                                    <Grid item>
                                                                        <Field
                                                                            fullWidth
                                                                            size="small"
                                                                            component={(props) => (
                                                                                <FileUpload {...props} multiple endIcon={<AddIcon/>} />
                                                                            )}
                                                                            label="Подтверждающий документ"
                                                                            name={`files.confirmation_document`}
                                                                            InputLabelProps={{
                                                                                name: null
                                                                            }}
                                                                            InputProps={{
                                                                                multiple: true,
                                                                                onChange: (event) => {
                                                                                    setFieldValue(`files.confirmation_document`, (values.files.confirmation_document ?? []).concat(Array.from(event.currentTarget.files)));
                                                                                    setFieldTouched(`files.confirmation_document`, true, false)
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                                                            {Array.from(values.files.confirmation_document ?? []).map((file, index) => {
                                                                                return (
                                                                                    <Grid key={index} item>
                                                                                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                                                            <Grid item>
                                                                                                <Typography>{file.name}</Typography>
                                                                                            </Grid>
                                                                                            <Grid item>
                                                                                                <IconButton
                                                                                                    size={"small"}
                                                                                                    onClick={() => {
                                                                                                        setFieldValue('files.confirmation_document', (values.files.confirmation_document ?? []).filter((_, i) => i !== index))
                                                                                                    }}
                                                                                                >
                                                                                                    <Clear />
                                                                                                </IconButton>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                )
                                                                            })}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                                    <Grid item>
                                                                        <Field
                                                                            fullWidth
                                                                            size="small"
                                                                            component={(props) => (
                                                                                <FileUpload {...props} multiple endIcon={<AddIcon/>} />
                                                                            )}
                                                                            label="Фото"
                                                                            name={`files.photo`}
                                                                            InputLabelProps={{
                                                                                name: null
                                                                            }}
                                                                            InputProps={{
                                                                                multiple: true,
                                                                                onChange: (event) => {
                                                                                    setFieldValue(`files.photo`, (values.files.photo ?? []).concat(Array.from(event.currentTarget.files)));
                                                                                    setFieldTouched(`files.photo`, true, false)
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                                                            {Array.from(values.files.photo ?? []).map((file, index) => {
                                                                                return (
                                                                                    <Grid key={index} item>
                                                                                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                                                            <Grid item>
                                                                                                <Typography>{file.name}</Typography>
                                                                                            </Grid>
                                                                                            <Grid item>
                                                                                                <IconButton
                                                                                                    size={"small"}
                                                                                                    onClick={() => {
                                                                                                        setFieldValue('files.photo', (values.files.photo ?? []).filter((_, i) => i !== index))
                                                                                                    }}
                                                                                                >
                                                                                                    <Clear />
                                                                                                </IconButton>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                )
                                                                            })}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                                    <Grid item>
                                                                        <Field
                                                                            fullWidth
                                                                            size="small"
                                                                            component={(props) => (
                                                                                <FileUpload {...props} multiple endIcon={<AddIcon/>} />
                                                                            )}
                                                                            label="Другое"
                                                                            name={`files.other`}
                                                                            InputLabelProps={{
                                                                                name: null
                                                                            }}
                                                                            InputProps={{
                                                                                multiple: true,
                                                                                onChange: (event) => {
                                                                                    setFieldValue(`files.other`, (values.files.other ?? []).concat(Array.from(event.currentTarget.files)));
                                                                                    setFieldTouched(`files.other`, true, false)
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                                                            {Array.from(values.files.other ?? []).map((file, index) => {
                                                                                return (
                                                                                    <Grid key={index} item>
                                                                                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                                                            <Grid item>
                                                                                                <Typography>{file.name}</Typography>
                                                                                            </Grid>
                                                                                            <Grid item>
                                                                                                <IconButton
                                                                                                    size={"small"}
                                                                                                    onClick={() => {
                                                                                                        setFieldValue('files.other', (values.files.other ?? []).filter((_, i) => i !== index))
                                                                                                    }}
                                                                                                >
                                                                                                    <Clear />
                                                                                                </IconButton>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                )
                                                                            })}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </React.Fragment>
                                            ) : null}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions className={classes.footer}>
                                <Grid container className={ classes.footerContent} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Submit
                                            variant="contained"
                                            component="label"
                                            color="primary"
                                            disabled={isSubmitting || !isValid || !dirty}
                                            onClick={handleSubmit}
                                            type="submit"
                                        >
                                            {reclamation ? 'Сохранить' : 'Создать'}
                                        </Submit>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </Grid>
    ) : null
}
