import React, {useState} from "react"

import {
    Grid
} from "@material-ui/core"

import {Submit} from "../../../../App/components/Button/Submit"
import {Event} from "../Event";

export const Create = (props) => {
    const { onClick } = props
    const [open, setOpen] = useState(false)

    return (
        <React.Fragment>
            <Grid item>
                <Submit
                    disableElevation
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => setOpen(true)}
                >
                    Создать
                </Submit>
            </Grid>
            {open ? (
                <Event open={open} setDialog={setOpen} onClick={onClick} />
            ) : null}
        </React.Fragment>
    )
}
