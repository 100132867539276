import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik, getIn} from "formik"
import {CheckboxWithLabel} from "formik-material-ui"

import {Grid, List, ListItem, ListItemText, makeStyles, TextField as MuiTextField, withStyles} from '@material-ui/core'
import {Autocomplete} from "@material-ui/lab"

import {Submit} from "../../../App/components/Button/Submit"
import {TextField as CustomTextField} from "../../../App/components/Inputs/TextField"
import {SystemActions} from "../../../App/actions/system"
import {CompanyActions} from "../../../Company/actions/company"
import {CustomerActions} from "../../../Company/actions/customer"
import {localState} from "../../../App/components/LocalState";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        "width": "100%"
    },
    active: {
        "background-image": "none !important",
        "background-color": "#898989"
    }
}))

const CustomList = withStyles({
    root: {
        "padding": "0",
        "& .MuiListItem-button": {
            "background-image": "linear-gradient(to bottom,#e4e4e4,#f4f4f4)",
            "cursor": "pointer",
            "align-items": "center",
            "min-height": "50px",
            "padding": "5px 25px 0",
            "border-bottom": "1px solid #e4e4e4",
            "outline": "0"
        },
        "& .MuiListItemText-primary": {
            "font-size": "16px",
            "font-weight": "700",
            "text-transform": "uppercase"
        },
        "& .MuiListItem-button:hover": {
            "background-color": "#898989",
            "&:after": {
                "position": "absolute",
                "content": "''",
                "top": "0",
                "left": "0",
                "width": "100%",
                "height": "100%",
                "background-color": "rgba(0,0,0,.04)"
            }
        }
    }
})(List);

export const Menu = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {location} = props

    const { regions, territories } = useSelector(state => ({...state.system, ...state.customer}))
    const [searchCustomer, setSearchCustomer] = useState('')
    const [searchTerritory, setSearchTerritory] = useState('')
    const [searchSupplier, setSearchSupplier] = useState('')
    const [suppliers, setSuppliers] = useState(localState)
    const [customers, setCustomers] = useState(localState)
    const [customer, setCustomer] = useState(null)
    const [region, setRegion] = useState(null)

    const list = () => {
        return [
            {title: "Заказчики",to: "/customers"},
            {title: "Поставщики",to: "/suppliers"},
            {title: "Контроль цен",to: "/report/control/price"},
        ]
    }

    useEffect(() => {
        const getData = async () => {
            if (!regions.length) {
                await dispatch(SystemActions.regions())
            }
        }

        getData().then(r => {})
        // eslint-disable-next-line
    }, [regions.length])

    useEffect(() => {
        const getCustomers = async () => {
            return await dispatch(CompanyActions.customers({
                limit: 10,
                search: searchCustomer,
                ...(region ? {region: region} : {})
            }))
        }

        if (searchCustomer.length) {
            getCustomers().then(response => setCustomers(response))
        }
    }, [dispatch, searchCustomer, region])

    useEffect(() => {
        const getTerritories = async () => {
            return await dispatch(CustomerActions.territories( {
                search: searchTerritory,
                ...(customer ? {companies: customer} : {})
            }))
        }

        if (searchTerritory.length) {
            getTerritories().then(r => {})
        }
    }, [dispatch, searchTerritory, customer]);

    useEffect(() => {
        const getSuppliers = async () => {
            return await dispatch(CompanyActions.suppliers({
                limit: 10,
                search: searchSupplier
            }))
        }

        if (searchSupplier.length) {
            getSuppliers().then(response => setSuppliers(response))
        }
    }, [dispatch, searchSupplier])

    return (
        <CustomList>
            {list().map((option, index) => (
                <React.Fragment key={index}>
                    <ListItem button key={option.title} component={Link} to={option.to} classes={{ root: ((location.pathname === option.to) || ((location.pathname.indexOf('/customer') !== -1) && (option.to === '/customers')) || ((location.pathname.indexOf('/user/supervisor') !== -1) && (option.to === '/user/supervisors')) || ((location.pathname.indexOf('/user/operators/add') !== -1) && (option.to ===  '/user/operators')) || ((location.pathname.indexOf('/supplier') !== -1) && (option.to === '/suppliers')) || ((location.pathname.indexOf('/report/control/price') !== -1) && (option.to === '/report/control/price'))) ? classes.active : undefined }}>
                        <ListItemText primary={option.title} />
                    </ListItem>
                    {((option.to === '/report/control/price') && (location.pathname === '/report/control/price')) &&
                        <Formik
                            enableReinitialize
                            initialValues={{
                                filter: {
                                    regions: [],
                                    customers: [],
                                    territories: [],
                                    suppliers: [],
                                    price: {
                                        from: '',
                                        to: ''
                                    },
                                    new: false
                                }
                            }}
                            onSubmit={(values, {setSubmitting, setErrors}) => {
                                return dispatch({type: "REPORT_PRICE_CONTROL_ITEMS_FILTER", payload: { regions: values.filter.regions.map(region => region.id).join(','), customers: values.filter.customers.map(customer => customer.id).join(','), territories: values.filter.territories.map(territory => territory.id).join(','), suppliers: values.filter.suppliers.map(supplier => supplier.id).join(','), price: values.filter.price, new: values.filter.new }})
                            }}
                        >
                        {
                            ({
                                dirty,
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                handleReset,
                                submitForm,
                                isSubmitting,
                                isValid,
                                isValidating,
                                setValues,
                                setFieldValue,
                                setFieldTouched
                            }) => (
                                <Form>
                                    <Grid container direction="column" justify="space-between" alignItems="stretch" style={{"width": "100%", "margin": "0px"}} spacing={2}>
                                        <Grid item className={classes.fullWidth}>
                                            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                <Grid item className={classes.fullWidth}>
                                                    <Autocomplete
                                                        name={'filter.regions'}
                                                        multiple
                                                        options={regions}
                                                        value={values.filter.regions}
                                                        getOptionLabel={option => {
                                                            return option ? (option.hasOwnProperty('name') ? option.name : ''): ''
                                                        }}
                                                        noOptionsText={'Регион'}
                                                        onChange={(e, items) => {
                                                            setRegion(items ? items.map(item => item.id) : [])
                                                            setValues({
                                                                ...values,
                                                                ...{
                                                                    filter: {
                                                                        ...values.filter,
                                                                        ...{
                                                                            regions: items ? items : [],
                                                                            customers: [],
                                                                            territories: []
                                                                        }
                                                                    }
                                                                }
                                                            })
                                                        }}
                                                        renderInput={params => {
                                                            const error = getIn(touched, 'filter.regions') && getIn(errors, 'filter.regions');

                                                            return <Field
                                                                fullWidth
                                                                component={MuiTextField}
                                                                {...params}
                                                                error={!!error}
                                                                helperText={error}
                                                                name={'filter.regions'}
                                                                label={'Регион'}
                                                            />
                                                        }}
                                                        onFocus={(e, value) => {
                                                            setFieldTouched('filter.regions', true, true)
                                                        }}
                                                        onBlur={(e, value) => {
                                                            setFieldTouched('filter.regions', true, true)
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.fullWidth}>
                                                    <Autocomplete
                                                        filterOptions={(options, state) => options}
                                                        name={'filter.customers'}
                                                        multiple
                                                        options={customers}
                                                        value={values.filter.customers}
                                                        getOptionLabel={option => {
                                                            return option ? (option.hasOwnProperty('legal_detail') ? option.legal_detail.name : '') : ''
                                                        }}
                                                        noOptionsText={'Заказчик'}
                                                        onChange={(e, items) => {
                                                            setSearchCustomer('')
                                                            setCustomer(items ? items.map(item => item.id) : null)
                                                            setValues({
                                                                ...values,
                                                                ...{
                                                                    filter: {
                                                                        ...values.filter,
                                                                        ...{
                                                                            customers: items.map(item => ({
                                                                                id: item.id,
                                                                                legal_detail: {
                                                                                    name: item.legal_detail.name
                                                                                }
                                                                            })),
                                                                            territories: []
                                                                        }
                                                                    }
                                                                }
                                                            })
                                                        }}
                                                        renderInput={params => {
                                                            const error = getIn(touched, 'filter.customers') && getIn(errors, 'filter.customers');

                                                            return <Field
                                                                fullWidth
                                                                component={MuiTextField}
                                                                {...params}
                                                                onChange={(e) => {
                                                                    setSearchCustomer(e.target.value)
                                                                }}
                                                                error={!!error}
                                                                helperText={error}
                                                                name={'filter.customers'}
                                                                label={'Заказчик'}
                                                            />
                                                        }}
                                                        onFocus={(e, value) => {
                                                            setFieldTouched('filter.customers', true, true)
                                                            dispatch({type: 'CUSTOMERS_CLEAR'})
                                                            setSearchCustomer('')
                                                        }}
                                                        onBlur={(e, value) => {
                                                            setFieldTouched('filter.customers', true, true)
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.fullWidth}>
                                                    <Autocomplete
                                                        filterOptions={(options, state) => options}
                                                        name={'filter.territories'}
                                                        multiple
                                                        options={territories}
                                                        value={values.filter.territories}
                                                        getOptionLabel={option => {
                                                            return option ? (option.hasOwnProperty('name') ? option.name : '') : ''
                                                        }}
                                                        noOptionsText={'Территория'}
                                                        onChange={(e, items) => {
                                                            setSearchTerritory('')
                                                            setValues({
                                                                ...values,
                                                                ...{
                                                                    filter: {
                                                                        ...values.filter,
                                                                        ...{
                                                                            territories: items ? items : []
                                                                        }
                                                                    }
                                                                }
                                                            })
                                                        }}
                                                        renderInput={params => {
                                                            const error = getIn(touched, 'filter.territories') && getIn(errors, 'filter.territories');

                                                            return <Field
                                                                fullWidth
                                                                component={MuiTextField}
                                                                {...params}
                                                                onChange={(e) => {
                                                                    setSearchTerritory(e.target.value)
                                                                }}
                                                                error={!!error}
                                                                helperText={error}
                                                                name={'filter.territories'}
                                                                label={'Территория'}
                                                            />
                                                        }}
                                                        onFocus={(e, value) => {
                                                            setFieldTouched('filter.territories', true, true)
                                                            dispatch({type: 'CUSTOMER_TERRITORIES_CLEAR'})
                                                            setSearchTerritory('')
                                                        }}
                                                        onBlur={(e, value) => {
                                                            setFieldTouched('filter.territories', true, true)
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.fullWidth}>
                                                    <Autocomplete
                                                        filterOptions={(options, state) => options}
                                                        name={'filter.suppliers'}
                                                        multiple
                                                        options={suppliers}
                                                        value={values.filter.suppliers}
                                                        getOptionLabel={option => {
                                                            return option ? (option.hasOwnProperty('legal_detail') ? option.legal_detail.name : '') : ''
                                                        }}
                                                        noOptionsText={'Поставщик'}
                                                        onChange={(e, items) => {
                                                            setSearchSupplier('')
                                                            setValues({
                                                                ...values,
                                                                ...{
                                                                    filter: {
                                                                        ...values.filter,
                                                                        ...{
                                                                            suppliers: items.map(item => ({
                                                                                id: item.id,
                                                                                legal_detail: {
                                                                                    name: item.legal_detail.name
                                                                                }
                                                                            }))
                                                                        }
                                                                    }
                                                                }
                                                            })
                                                        }}
                                                        renderInput={params => {
                                                            const error = getIn(touched, 'filter.suppliers') && getIn(errors, 'filter.suppliers');

                                                            return <Field
                                                                fullWidth
                                                                component={MuiTextField}
                                                                {...params}
                                                                onChange={(e) => {
                                                                    setSearchSupplier(e.target.value)
                                                                }}
                                                                error={!!error}
                                                                helperText={error}
                                                                name={'filter.suppliers'}
                                                                label={'Поставщик'}
                                                            />
                                                        }}
                                                        onFocus={(e, value) => {
                                                            setFieldTouched('filter.suppliers', true, true)
                                                            dispatch({type: 'SUPPLIERS_CLEAR'})
                                                            setSearchSupplier('')
                                                        }}
                                                        onBlur={(e, value) => {
                                                            setFieldTouched('filter.suppliers', true, true)
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Field
                                                                fullWidth
                                                                name="filter.price.from"
                                                                type="number"
                                                                label="Цена от"
                                                                inputProps={{
                                                                    min: 0,
                                                                    step: 0.01
                                                                }}
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Field
                                                                fullWidth
                                                                name="filter.price.to"
                                                                type="number"
                                                                label="до"
                                                                inputProps={{
                                                                    min: 0,
                                                                    step: 0.01
                                                                }}
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Field
                                                        component={CheckboxWithLabel}
                                                        name="filter.new"
                                                        color="primary"
                                                        Label={{label: 'Только новые'}}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="row" justify="space-between" alignItems="center">
                                                <Grid item>
                                                    <Submit
                                                        className={classes.submit}
                                                        disableElevation
                                                        variant="contained"
                                                        color="secondary"
                                                        type="button"
                                                        onClick={() => {
                                                            handleReset()
                                                            dispatch({type: "REPORT_PRICE_CONTROL_ITEMS_FILTER", payload: { region: '', customer: '', territory: '', supplier: '', price: { from: '', to: '' }, new: false }})
                                                        }}
                                                    >
                                                        Сбросить
                                                    </Submit>
                                                </Grid>
                                                <Grid item>
                                                    <Submit
                                                        className={classes.submit}
                                                        disableElevation
                                                        variant="contained"
                                                        color="primary"
                                                        type="button"
                                                        onClick={() => {
                                                            submitForm().then(r => {})
                                                        }}
                                                    >
                                                        Применить
                                                    </Submit>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    }
                </React.Fragment>
            ))}
        </CustomList>
    )
}
