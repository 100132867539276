const numberFormat = (number) => {
    number = number.replaceAll(',', '.')
    number += ''
    const batch = number.split('.')
    let numerator = parseInt(batch[0]).toString()
    const denominator = (batch.length > 1) ? ',' + batch[1] : ''
    const rgx = /(\d+)(\d{3})/

    while (rgx.test(numerator)) {
        // eslint-disable-next-line no-useless-concat
        numerator = numerator.replace(rgx, '$1' + ' ' + '$2')
    }

    return numerator + denominator
}

const stringFormat = (str) => {
    let result = ''

    if (str) {
        let number = ''

        for (let i = 0; i < str.length; i++) {
            if (!isNaN(str[i]) || (!!number.length && ((str[i] === '.') || (str[i] === ',')))) {
                if (str[i] === ' ') {
                    if (number.length) {
                        if (((number.split(".").length + number.split(",").length) - 2) > 1) {
                            result += number
                        } else {
                            result += numberFormat(number)
                        }
                        number = ''
                    }
                    result += str[i]
                } else {
                    number += str[i]
                    if (str.length === (i + 1)) {
                        if (((number.split(".").length + number.split(",").length) - 2) > 1) {
                            result += number
                        } else {
                            result += numberFormat(number)
                        }
                        number = ''
                    }
                }
            } else {
                if (number.length) {
                    if (((number.split(".").length + number.split(",").length) - 2) > 1) {
                        result += number
                    } else {
                        result += numberFormat(number)
                    }
                    number = ''
                }
                result += str[i]
            }
        }
    }
    return result
}




export { stringFormat }
