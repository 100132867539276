import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import format from "date-fns/format"
import {ru} from "date-fns/locale"
import clsx from "clsx"

import {Grid, IconButton, InputAdornment, makeStyles, TextField,} from "@material-ui/core"
import {ExitToApp as ExitToAppIcon, Search as SearchIcon} from "@material-ui/icons"
import {Table} from "@devexpress/dx-react-grid-material-ui";

import {Submit} from "../../../App/components/Button/Submit"
import {UserActions} from "./actions/user"
import {ButtonList} from "../../../App/components/ButtonList/ButtonList"
import {history} from "../../../App/helpers/history"
import {CustomTable} from "../../../App/components/Table/Table";
import {TableHeader} from "../../../App/components/Table/TableHeader";
import {SettingsActions} from "../../../Settings/actions/settings";
import {localState} from "../../../App/components/LocalState";
import {ColumnChooser} from "../../../App/components/Table/ColumnChooser";
import {useDebouncedCallback} from "use-debounce";
import {Delete as DeleteButton} from "./Delete";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin
    },
    fullWidth: {
        "width": "100%"
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 217px)",
        "min-height": "calc(100vh - 217px)"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    searchLabel: {
        "margin-bottom": "14px",
    },
}))

const columns = [
    {name: 'full_name', title: 'Ф.И.О.'},
    {name: 'email', title: 'Эл. адрес'},
    {name: 'login', title: 'Логин'},
    {name: 'abbreviation', title: 'Сокр. наим.'},
    {name: 'created_at', title: 'Дата создания'},
    {name: 'button', title: ' '},
];

const organizationList = [
    {
        key: 'central',
        value: 'ЦДЗ'
    },
    {
        key: 'institution',
        value: 'НУЗ (КО)'
    },
    {
        key: 'regional',
        value: 'РДМО'
    },
    {
        key: 'all',
        value: 'ВСЕ'
    }
]

export const SupervisorsContent = () => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [users, setUsers] = useState(localState)

    const [tabOrganization, setTabOrganization] = useState(3)
    const [organization, setOrganization] = useState('all')

    const [page, setPage] = useState(1)
    const [tableRef, setTableRef] = useState(null)
    const {filter} = useSelector(state => state.filters['supervisors'])
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [search, setSearch] = useState(filter.search)
    const debounced = useDebouncedCallback(
        (value) => {
            dispatch({
                type: "SUPERVISORS_FILTER",
                payload: {search: value}
            })
        },
        900
    );

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'login', width: 200},
        {columnName: 'email', width: 200},
        {columnName: 'full_name', width: 300},
        {columnName: 'abbreviation', width: 150},
        {columnName: 'created_at', width: 150},
        {columnName: 'button', width: 200},
    ])

    const [columnOrder, setColumnOrder] = useState([
        'full_name',
        'login',
        'email',
        'abbreviation',
        'created_at',
        'button',
    ])

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const [initialize, setInitialize] = useState(false);

    useEffect(() => {
        debounced.callback(search)
        // eslint-disable-next-line
    }, [search])

    useEffect(() => {
        if (!initialize) {
            dispatch(SettingsActions.table('supervisors')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                dispatch({type: "FILTER_CLEAR"})
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'supervisors',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...(search ? {search: search} : {}),
        }
    }

    const getSupervisors = async () => {
        return await dispatch(UserActions.users({
            role: 'supervisor',
            limit: rowsPerPage,
            page: page,
            organization: organization,
            ...getParams()
        }))
    }

    useEffect(() => {
        getSupervisors().then((response) => {
            setUsers(response)
            tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
        })
    }, [dispatch, page, rowsPerPage, organization, filter.sort, filter.search]);

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: 'SUPERVISORS_FILTER', payload: {limitRows: newRowsPerPage}})
    }

    return <Grid item className={classes.content}>
        <Grid container direction="column" justify="center" alignItems="stretch">
            <Grid item>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item className={classes.fullWidth} xs={8}>
                        <TextField
                            id="search"
                            variant="standard"
                            name={'search'}
                            value={search ?? ''}
                            placeholder={'Поиск'}
                            fullWidth={true}
                            margin={'normal'}
                            onChange={(event) => {
                                setSearch(event.target.value)
                            }}
                            classes={{
                                root: clsx(classes.filterLabel, classes.searchLabel)
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon style={{color: '#c1c1c1'}}/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <ButtonList value={tabOrganization} values={organizationList} onChange={(_, value) => {
                            setTabOrganization(value);
                            dispatch({type: "USER_FILTER", payload: {search: search, tabOrganization: value}})
                            setPage(1)
                            setOrganization(organizationList[value].key);
                        }}/>
                    </Grid>
                    <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2} style={{marginBottom: '8px'}}>
                        <Grid item>
                            <Submit
                                disableElevation
                                variant="contained"
                                color="primary"
                                type="button"
                                component={Link}
                                to="/user/supervisor"
                            >
                                Создать
                            </Submit>
                        </Grid>
                        <Grid item style={{marginTop: '-4px'}}>
                            <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <CustomTable
            meta={users.meta}
            tableName={'supervisors'}
            rows={users?.data.map(user => ({
                full_name: `${user['last_name']} ${user['first_name']} ${user['middle_name']}`,
                created_at: format(new Date(user.created), 'PP', {locale: ru}),
                abbreviation: user.abbreviation,
                email: user.email,
                login: user.login,
                button: <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                    <Grid item>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()
                                return dispatch(UserActions.login({
                                    login: user.login,
                                }))
                            }}
                        >
                            <ExitToAppIcon/>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <DeleteButton
                            onClick={() => {
                                getSupervisors().then(r => setUsers(r))}}
                            user={user.id}
                        />
                    </Grid>
                </Grid>,
            }))}
            columns={columns}
            page={{page: page, setPage: setPage, rowsPerPage: rowsPerPage, handlePageSizeChange: handlePageSizeChange}}
            setTableRef={setTableRef}
            columnsSettings={{
                columnOrder: columnOrder,
                setColumnOrder: setColumnOrder,
                setColumnWidths: setColumnWidths,
                columnWidths: columnWidths,
                hiddenColumnNames: hiddenColumnNames,
                setHiddenColumnNames: setHiddenColumnNames
            }}
            tableHeader={TableHeader}
            filterActionType={'SUPERVISORS_FILTER'}
            classInputContent={classes.inputContent}
            rowComponent={({row, tableRow, children}) => (
                <Table.Row
                    tableRow={tableRow}
                    children={children}
                    className={classes.tableRow}
                    row={row}
                    onClick={() => {
                        history.push(`/user/supervisor/${users.data[tableRow.rowId].id}`);
                    }}/>
            )}
            filters={{
                button: {
                    nullButton: true
                },
                deleteButton: {
                    nullButton: true
                },
            }}
        />
    </Grid>
}
