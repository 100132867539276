import React, {useEffect} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import DateFnsUtils from "@date-io/date-fns"
import {ru} from "date-fns/locale"

import {
    Grid, Link,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@material-ui/core"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'

import {taxPercentValues} from '../../../../../App/dics/dic'
import {ShopActions} from "../../../actions/shop"
import {Submit} from "../../../../../App/components/Button/Submit";
import {GetApp as GetAppIcon} from "@material-ui/icons";
import * as AppStorage from "../../../../../App/helpers/storage";
import {Download as DownloadSignature} from "../../../../../Crypto/Download";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    subTitle: {
        "font-weight": "bold",
    },
    header: {
        "width": "auto",
        "padding": "20px 29px !important"
    },
    input: {
        "width": "100%"
    },
    tab: {
        "width": "100%",
        "overflow": "auto"
    },
    tabContent: {
        "padding": "20px 29px !important"
    },
    itemInput: {
        "margin-top": "0 !important"
    },
    column: {
        "height": "86px"
    },
    link: {
        "display": "flex",
        "line-height": "31px",
        "color": "#485868",
    },
    footer: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important",
        width: "100%",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    datePicker: {
        "margin-top": "15px !important"
    },
    textField: {
        "width": "100%",
        "&>div": {},
    },
    tableContainer: {
        height: "calc(100vh - 360px)"
    }
}))

export const Content = (props) => {
    let {id} = useParams()
    const dispatch = useDispatch()
    const classes = useStyles()
    const {offer} = useSelector(state => state.supplierShop)

    const columns = [
        {
            id: '0',
            label: '№',
            format: (value) => value.toLocaleString()
        },
        {
            id: '1',
            label: 'Объект закупки',
            format: (value) => value.toLocaleString()
        },
        {
            id: '2',
            label: 'Наименование',
            format: (value) => value.toLocaleString()
        },
        {
            id: '3',
            label: 'НМЦ за единицу',
            format: (value) => value.toLocaleString()
        },
        {
            id: '4',
            label: 'Количество',
            format: (value) => value.toLocaleString()
        },
        {
            id: '5',
            label: 'НДС',
            format: (value) => value.toLocaleString()
        },
        {
            id: '6',
            label: 'Цена за позицию',
            format: (value) => value.toLocaleString()
        },
        {
            id: '7',
            label: 'Мин. количество товара для заказа',
            format: (value) => value.toLocaleString()
        },
        {
            id: '8',
            label: 'Срок годности',
            format: (value) => value.toLocaleString()
        }, {
            id: '9',
            label: 'Описание',
            format: (value) => value.toLocaleString()
        }
    ]

    useEffect(() => {
        const getOffer = async () => {
            return await dispatch(ShopActions.offer(id))
        }

        getOffer().then(() => {})
    }, [dispatch, id]);

    return offer ? (
            <Grid item className={classes.content}>
                <Paper>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item className={classes.full}>
                            <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                <Grid item className={classes.fullWidth}>
                                    <Grid container direction="row" justify="space-between" alignItems="stretch">
                                        <Grid item className={classes.header}>
                                            <Typography className={classes.title}>
                                                Предложение
                                            </Typography>
                                        </Grid>
                                        {offer.approve !== null &&
                                            <Grid item className={classes.header}>
                                                <Typography>
                                                        <span className={classes.subTitle}>Статус:</span> {offer.approve ? 'Допущено' : 'Не допущено'}
                                                </Typography>
                                                {(!offer.approve && offer.comment) &&
                                                    <Typography>
                                                            <span className={classes.subTitle}>Причина:</span> {offer.comment}
                                                    </Typography>
                                                }
                                            </Grid>
                                        }
                                        {(offer.lot.hasOwnProperty('status') && offer.approve === null && offer.lot.status && offer.lot.status.index === 4) &&
                                            <Grid item className={classes.header}>
                                                <Typography>
                                                    <span className={classes.subTitle}>Статус:</span> Предложение отклонено
                                                </Typography>
                                                <Typography>
                                                    <span className={classes.subTitle}>Причина:</span> Заказчик отозвал ценовой запрос
                                                </Typography>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid item>
                                        <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                                            <Grid item className={classes.tabContent}>
                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                    <Grid item xs={3} className={classes.column}>
                                                        <TextField
                                                            type="text"
                                                            label="Минимальный срок поставки, дней"
                                                            defaultValue={offer.revisions[0].contract_time}
                                                            className={classes.textField}
                                                            disabled={true}
                                                            variant="standard"
                                                            fullWidth={true}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} className={classes.column}>
                                                        <TextField
                                                            type="text"
                                                            label="Отсрочка платежа, дней"
                                                            defaultValue={offer.revisions[0].postponement}
                                                            className={classes.textField}
                                                            disabled={true}
                                                            variant="standard"
                                                            fullWidth={true}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} className={classes.column}>
                                                        <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                                                            {offer.files.filter(file => ((file.type && (file.type?.key !== 'price_offer')) || !file.type)).map(file => (
                                                                <Grid item className={classes.fileItem}>
                                                                    <Typography>
                                                                        <Link
                                                                            href={process.env.REACT_APP_HOST_API + `/shop/order/${offer.lot.order.id}/offer/file/${file.id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                            className={classes.link}
                                                                        >
                                                                            {file.type ? file.type.name : file.description}<GetAppIcon viewBox="0 0 24 24"/>
                                                                        </Link>
                                                                    </Typography>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        {<TableContainer className={classes.tableContainer}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        {columns.map((column) => (
                                                            <TableCell key={column.id}>
                                                                {column.label}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {offer.items.map((item, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell component="th" scope="row">
                                                                {index + 1}
                                                            </TableCell>
                                                            <TableCell width="40%">
                                                                {item.lot_item.name}
                                                            </TableCell>
                                                            <TableCell>
                                                                {(item.name !== item.lot_item.name) ? item.name : null}
                                                            </TableCell>
                                                            <TableCell>
                                                                {parseFloat(item.lot_item.average_price).toLocaleString('ru-RU', {
                                                                    style: 'currency',
                                                                    currency: offer.lot.order.currency.code_char
                                                                }).replace('KZT', '₸')}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.lot_item.amount} {item.unit ? item.unit.short : null}
                                                            </TableCell>
                                                            <TableCell>
                                                                {taxPercentValues.filter(el => el.key === item.tax_percent)[0].value}
                                                            </TableCell>
                                                            <TableCell>
                                                                {parseFloat(item.price).toLocaleString('ru-RU', {
                                                                    style: 'currency',
                                                                    currency: offer.lot.order.currency.code_char
                                                                }).replace('KZT', '₸')}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.min_ordered_quantity ?? 'Нет ограничений'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {(item.lot_item.expiration_date_type === 'expiration_date') ?
                                                                    (<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                        <KeyboardDatePicker
                                                                            style={{width: "135px"}}
                                                                            fullWidth
                                                                            invalidDateMessage="Некорректная дата"
                                                                            minDateMessage={""}
                                                                            disablePast
                                                                            disableToolbar
                                                                            orientation="landscape"
                                                                            variant="inline"
                                                                            openTo="date"
                                                                            format="dd-MM-yyyy"
                                                                            autoOk
                                                                            required
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                            name="expiration_date"
                                                                            disabled={true}
                                                                            value={item.expiration_date}
                                                                            className={classes.datePicker}
                                                                        />
                                                                    </MuiPickersUtilsProvider>) :
                                                                    (<TextField
                                                                        type="number"
                                                                        name="expiration_date_number"
                                                                        step={(item.lot_item.expiration_date_type === 'expiration_date_percent') ? 0.01 : 1}
                                                                        style={{marginTop: "0"}}
                                                                        inputProps={{
                                                                            min: 0,
                                                                            step: (item.lot_item.expiration_date_type === 'expiration_date_percent') ? 0.01 : 1
                                                                        }}
                                                                        disabled={true}
                                                                        InputProps={{
                                                                            endAdornment: (item.lot_item.expiration_date_type === 'expiration_date_percent') ? '%' : 'мес.'
                                                                        }}
                                                                        value={(item.lot_item.expiration_date_type === 'warranty_period') ? parseInt(item.expiration_date_number) : item.expiration_date_number}
                                                                    />)
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.description}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.footer}>
                            <Grid container direction="column" justify="center" alignItems="stretch">
                                <Grid item className={classes.footerContent}>
                                    <Grid container className={classes.footerContainer} direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Submit
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                type="button"
                                                endIcon={<GetAppIcon/>}
                                                href={process.env.REACT_APP_HOST_API + `/shop/order/${offer.lot.order.id}/offer/file/${offer.files.find(file => (file.type?.key === 'price_offer')).id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                            >
                                                Скачать ценовое предложение
                                            </Submit>
                                        </Grid>
                                        {offer.files.find(file => (file.type?.key === 'price_offer')).sign ? (
                                          <Grid item>
                                              <DownloadSignature size="small" file={offer.files.find(file => (file.type?.key === 'price_offer'))} />
                                          </Grid>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
        : null
}
