export default function shop(state= {order: null}, action) {
    switch (action.type) {
        case "ORDERS":
            return {
                ...state,
                ...action.payload,
            }
        case "ORDERS_FAILURE":
            return state
        case "ORDER_REQUEST":
            return state
        case "ORDER":
            return {
                order: action.payload,
            }
        case "ORDER_FAILURE":
            return state
        case "ADD_ORDER_REQUEST":
            return state
        case "ADD_ORDER_SUCCESS":
            return state
        case "ADD_ORDER_FAILURE":
            return state
        case "EDIT_ORDER_REQUEST":
            return state
        case "EDIT_ORDER_SUCCESS":
            return state
        case "EDIT_ORDER_FAILURE":
            return state
        case "STATUS_ORDER_REQUEST":
            return state
        case "STATUS_ORDER_SUCCESS":
            return state
        case "STATUS_ORDER_FAILURE":
            return state
        case "DEADLINE_ORDER_REQUEST":
            return state
        case "DEADLINE_ORDER_SUCCESS":
            return state
        case "DEADLINE_ORDER_FAILURE":
            return state
        case "DELETE_ORDER_REQUEST":
            return state
        case "DELETE_ORDER_SUCCESS":
            return state
        case "DELETE_ORDER_FAILURE":
            return state
        case "APPROVE_OFFER_SUCCESS":
            let order = state.order.lots.forEach(lot => {
                lot.offers.forEach(offer => {
                    if (offer.id === action.payload.offer.id) {
                        offer.approve = action.payload.offer.approve
                    }
                })
            })

            return {
                order: {
                    ...state.order,
                    ...order
                },
            }
        case "SET_END_TIME_OF_REBIDDING_SUCCESS":
            let ord = state.order.lots.forEach(lot => {
                if (lot.id === action.payload.lotID) {
                    lot.end_time_of_rebidding = action.payload.end_time_of_rebidding;
                }
            })

            return {
                order: {
                    ...state.order,
                    ...ord
                },
            }
        default:
            return state
    }
}
