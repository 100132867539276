import React from "react"

import {DialogContent, Grid, List, ListItem, makeStyles, Typography} from "@material-ui/core"

import {Submit} from "../Button/Submit"

const useStyles = makeStyles(() => ({
    dialogContent: {
        padding: 0,
    },
    descriptionWrapper: {
        padding: "40px 50px",
        fontSize: "16px",
    },
    descriptionText: {
        margin: "0 0 15px",
        fontSize: "18px",
    },
    descriptionItem: {
        color: "#485868",
        display: "list-item",
        margin: "0 0 15px",
        fontSize: "18px",
    },
    footerContentInfo: {
        width: "100%",
        position: "absolute",
        bottom: 0,
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
}))

export const DialogCategoryRestriction = (props) => {
    const {onContinue} = props
    const classes = useStyles()

    return (
        <React.Fragment>
            <DialogContent className={classes.dialogContent}>
                <Grid container>
                    <Grid item style={{marginBottom: "70px"}}>
                        <Grid container className={classes.descriptionWrapper}>
                            <Grid item>
                                <Typography className={classes.descriptionText}>В силу отсутствия необходимости согласования результатов закупки в электронном магазинне РЖД-Медицина, обращаем Ваше внимание, что в соответствии с пунктами 70,71,72,73 Положения о закупках товаров, работ, услуг для нужд частных учреждений здравоохранения ОАО «РЖД», утвержденого приказом от 5 марта 2021г. №ЦДЗ-18 приобретение товаров, работ и услуг стоимостью свыше 100 000 (сто тысяч) рублей 00 копеек проводится спобами закупок, перечисленных в пункте 52."</Typography>
                                <Typography className={classes.descriptionText}>Пункты Положения:</Typography>
                                <Typography className={classes.descriptionText}>70. Перечень товаров, работ и услуг, приобретение которых осуществляется после письменного согласования с ЦДЗ:</Typography>
                                <List style={{listStyle: "inside decimal",lineHeight: "24px"}}>
                                    <ListItem  className={classes.descriptionItem}>
                                        Автотранспорт;
                                    </ListItem>
                                    <ListItem  className={classes.descriptionItem}>
                                        Основные средства, приобретаемые по договорам лизинга;
                                    </ListItem>
                                    <ListItem  className={classes.descriptionItem}>
                                        Основные средства, приобретаемые ЧУЗ ОАО «РЖД», стоимостью от 700 тыс. руб. (для ЧУЗ ОАО «РЖД» центрального подчинения - от 300 тыс. руб.);
                                    </ListItem>
                                    <ListItem  className={classes.descriptionItem}>
                                        Закупки, связанные с оказанием маркетинговых услуг, в том числе создание, поддержка и продвижение сайтов, продвижение ЧУЗ ОАО «РЖД» в социальных сетях, услуги по контекстной и таргетированной рекламе, услуги по изготовлению сувенирной и полиграфической продукции, услуги по размещению в СМИ (пресса, радио, ТВ, Интернет), производство контента – фото/видео съемка, написание текстов, дизайн, услуги по изготовлению и монтажу элементов внутренней и внешней навигации, оказание услуг по СМС, e-mail, почтовым рассылкам стоимостью от 300 тыс. руб.;
                                    </ListItem>
                                    <ListItem  className={classes.descriptionItem}>
                                        Оказанием ИТ-услуг, в том числе услуги по технической поддержке информационных систем или аппаратных комплексов (программно-аппаратных комплексов), компьютерной и оргтехники, закупки лицензий на использование программного обеспечения, аренда или подписки на программное обеспечение,  обустройство локально-вычислительных сетей, услуги по информационной безопасности стоимостью от 300 тыс. руб.;
                                    </ListItem>
                                    <ListItem  className={classes.descriptionItem}>
                                        Запасные части и комплектующие к оборудованию, работы по техническому обслуживанию и ремонту медицинской техники, а также аренда медицинского оборудования и его частей стоимостью от 300 тыс. руб. (для ЧУЗ ОАО «РЖД» центрального подчинения - от 100 тыс. руб.);
                                    </ListItem>
                                    <ListItem  className={classes.descriptionItem}>
                                        Отдельные номенклатурные позиции (товары, работы и услуг) или их группы, определенных ЦДЗ как стратегически важные.
                                    </ListItem>
                                </List>
                                <Typography className={classes.descriptionText}>71. Приобретение ЧУЗ ОАО «РЖД» (за исключением ЧУЗ ОАО «РЖД» центрального подчинения) основных средств, стоимостью от 100 тыс. руб. до 700 тыс. руб. осуществляется после письменного согласования с Региональной дирекцией здравоохранения.</Typography>
                                <Typography className={classes.descriptionText}>72. Приобретение ЧУЗ ОАО «РЖД» (за исключением ЧУЗ ОАО «РЖД» центрального подчинения) запасных частей и комплектующих к оборудованию, работ по техническому обслуживанию и ремонту медицинской техники, а также аренда медицинского оборудования и его частей, стоимостью от 100 тыс. руб. до 300 тыс. руб. осуществляется после письменного согласования с Региональной дирекцией здравоохранения.</Typography>
                                <Typography className={classes.descriptionText}>73. Приобретение основных средств стоимостью до 100 тыс. руб. осуществляется ЧУЗ ОАО «РЖД» самостоятельно (для ЧУЗ ОАО «РЖД» центрального подчинения - до 300 тыс. руб.).</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.footerContentInfo}>
                        <Grid container direction="row" justify="flex-end" alignItems="center" className={classes.footerContainer} spacing={2}>
                            <Grid item>
                                <Submit
                                    disableElevation
                                    variant="contained"
                                    color="primary"
                                    onClick={onContinue}
                                    type="submit"
                                >
                                    Ок
                                </Submit>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </React.Fragment>
    )
}
