import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {Grid, makeStyles} from "@material-ui/core"

import {Table} from "@devexpress/dx-react-grid-material-ui";
import {PriceActions} from "../../../../Price/actions/price"
import {getPriceName} from "../../../../App/helpers/name"
import {SystemActions} from "../../../../App/actions/system"
import {ProductListDialog} from "../../../../Price/components/ProductListDialog"
import {localState} from "../../../../App/components/LocalState";
import {CustomTable} from "../../../../App/components/Table/Table";
import {TableHeader} from "../../../../App/components/Table/TableHeader";

const useStyles = makeStyles(() => ({
    tab: {
        "width": "100%",
        marginTop: '10px'
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 243px)",
        "min-height": "calc(100vh - 243px)"
    },
}))

const columns = [
    {name: 'price', title: 'Прайс-лист'},
    {name: 'download', title: 'Загружено'},
];

export const Prices = (props) => {
    const dispatch = useDispatch()

    const { company } = props
    const classes = useStyles()
    const {categories } = useSelector(state => {return {...state.system}})
    const { account } = useSelector(state => state.account)
    const [prices, setPrices] = useState(localState)
    const {filter} = useSelector(state => state.filters['supplier_prices'])
    const [tableRef, setTableRef] = useState(null)

    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [page, setPage] = useState(1)
    const [dialog, setDialog] = useState(false)
    const [price, setPrice] = useState();

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'price', width: 350},
        {columnName: 'download', width: 300},
    ])

    const [columnOrder, setColumnOrder] = useState([
        'price',
        'download',
    ])

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
        }
    }

    useEffect(() => {
        const getCategories = async () => {
            return await dispatch(SystemActions.categories())
        }

        if (!categories.length) {
            getCategories().then(() => {})
        }
    }, [dispatch, categories.length]);

    useEffect(() => {
        const getPrice = async () => {
            return await dispatch(PriceActions.get({
                companyId: company.id,
                limit: rowsPerPage,
                page: page,
                type: 'all',
                status: 'done',
                ...getParams()
            }))
        }

        getPrice().then((r) => {
            setPrices(r)
        })
    }, [dispatch, rowsPerPage, page, company.id, filter])

    const closePriceDialog = () => {
        setDialog(false)
        setPrice(null)
    }

    const handlePagesSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: 'SUPPLIER_PRICES_FILTER', payload: {limitRows: newRowsPerPage}})
    }

    return (
        <Grid item className={classes.tab}>
            <CustomTable
                meta={prices.meta}
                tableName={'supplier_prices'}
                rows={prices?.data.map(price => ({
                    price: getPriceName(price),
                    download: price.rows_success,
                }))
                }
                columns={columns}
                page={{page: page, setPage: setPage, rowsPerPage: rowsPerPage, handlePageSizeChange: handlePagesSizeChange}}
                setTableRef={setTableRef}
                columnsSettings={{
                    columnOrder: columnOrder,
                    setColumnOrder: setColumnOrder,
                    setColumnWidths: setColumnWidths,
                    columnWidths: columnWidths,
                    hiddenColumnNames: hiddenColumnNames,
                    setHiddenColumnNames: setHiddenColumnNames
                }}
                tableHeader={TableHeader}
                filterActionType={'SUPPLIER_PRICES_FILTER'}
                classInputContent={classes.inputContent}
                rowComponent={({row, tableRow, children}) => (
                    <Table.Row
                        tableRow={tableRow}
                        children={children}
                        className={classes.tableRow}
                        row={row}
                        onClick={() => {
                            setDialog(true)
                            dispatch({type: "SET_ACTIVE_PRICE", payload: {
                                priceId: prices.data[tableRow.rowId].id,
                                type: prices.data[tableRow.rowId].type.name,
                                price_token: prices.data[tableRow.rowId].price_token,
                                company_id: prices.data[tableRow.rowId].company_id,
                            }})
                            setPrice(prices.data[tableRow.rowId])
                        }}
                    />
                )}
                filters={{
                    price: {
                        nullButton: true
                    },
                    download: {
                        nullButton: true
                    },
                }}
            />
            <ProductListDialog id={price ? price.id : null} account={account} categories={categories} open={dialog} onClose={closePriceDialog} supplierId={price ? price.company_id : null}/>
        </Grid>
    )
}
