import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch} from "react-redux"
import {TextField} from "formik-material-ui"
import {Field, Form, Formik, getIn} from "formik"
import * as Yup from "yup"
import DateFnsUtils from "@date-io/date-fns"
import {ru} from "date-fns/locale"

import {Grid, makeStyles, Paper, Typography, MenuItem} from "@material-ui/core"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"

import {Submit} from "../../../App/components/Button/Submit"
import {ScheduleActions} from "../../actions/schedule";
import {durations} from "../../dics/durations";
import {TextFieldWithError as CustomTextField} from "../../../App/components/Inputs/TextFieldWithError";
import {UserActions} from "../../../Company/components/User/actions/user";
import {history} from "../../../App/helpers/history";
import {AsyncAutocomplete} from "../../../App/components/Inputs/AsyncAutocomplete";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.planningContent.width,
        padding: theme.planningContent.padding,
        margin: theme.planningContent.margin,
        position: "relative"
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 0 !important"
    },
    input: {
        "width": "100%"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
        "position": "absolute",
        "bottom": "0",
        "width": "100%",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    contentBody: {
        position: "relative"
    },
    inputContent: {
        height: "calc(100vh - 181px)",
        overflow: "auto",
        flexWrap: "nowrap",
        padding: "0 25px",
        marginBottom: "70px"
    },
}));

export const Content = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {id} = useParams()

    const [schedule, setSchedule] = useState(null)
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getData = async () => {
            let response = {
                schedule: schedule,
                categories: categories
            }

            if (id) {
                await dispatch(ScheduleActions.schedule(id)).then(data => {
                    response.schedule = data
                })
            }

            await dispatch(ScheduleActions.categories()).then(data => {
                response.categories = data
            })

            return response
        }

        getData().then(response => {
            setSchedule(response.schedule)
            setCategories(response.categories)

            setLoading(true)
        })
        // eslint-disable-next-line
    }, [dispatch]);

    return loading ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" alignItems="center" className={classes.contentBody}>
                    <Formik
                        initialValues={{
                            year: schedule ? new Date(schedule.year, 0, 1) : null,
                            category: schedule ? schedule.category.id : '',
                            duration: schedule ? schedule.duration.key : 'year',
                            comment: schedule?.comment ?? '',
                            budget: {
                                limit: schedule?.budget?.limit ?? '',
                                fact: schedule?.budget?.fact ?? '',
                            },
                            users: schedule ? schedule.users : [],
                            published: !!schedule?.published
                        }}
                        validationSchema={Yup.object().shape({
                            year: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            category: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            duration: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            comment: Yup.string(),
                            budget: Yup.object().shape({
                                limit: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                fact: Yup.number()
                            }),
                            users: Yup.array().min(1, "Поле не может быть пустым или состоять из одних пробелов!"),
                        })}
                        onSubmit={(values, {setSubmitting}) => {
                            values = {
                                ...values,
                                ...{
                                    year: values.year.getFullYear(),
                                    users: values.users.map(user => user.id)
                                }
                            }

                            return dispatch(schedule ? ScheduleActions.edit(schedule.id, values) : ScheduleActions.add(values)).then(
                                () => {
                                    history.push("/planning/schedules")
                                    setSubmitting(false)
                                },
                                errors => {
                                    if (errors) {}
                                    setSubmitting(false)
                                }
                            )
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              isSubmitting,
                              setFieldValue,
                              setFieldTouched,
                              submitForm
                          }) => (
                            <Form className={classes.fullWidth}>
                                <Grid item className={classes.inputContent}>
                                    <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                        <Grid item className={classes.header}>
                                            <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                                <Grid item className={classes.fullWidth}>
                                                    <Typography className={classes.title}>
                                                        {schedule ? 'Редактирование' : 'Создание'} общего план-графика
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch" spacing={4}>
                                                <Grid item>
                                                    <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                                        <Grid container direction="row" alignItems="stretch" wrap="wrap" spacing={2}>
                                                            <Grid item xs={4}>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                    <KeyboardDatePicker
                                                                        disableToolbar
                                                                        fullWidth
                                                                        disabled={isSubmitting || !!schedule?.published}
                                                                        name="year"
                                                                        required
                                                                        minDate={new Date((new Date()).getFullYear(), 0, 1)}
                                                                        orientation="landscape"
                                                                        variant="inline"
                                                                        format="yyyy"
                                                                        openTo="year"
                                                                        views={['year']}
                                                                        autoOk
                                                                        label="Отчетный плановый период"
                                                                        value={values.year}
                                                                        onChange={date => {
                                                                            setFieldValue("year", date, true);
                                                                        }}
                                                                        onBlur={e => {
                                                                            setFieldTouched(`year`, true, true)
                                                                        }}
                                                                        onClose={(e, value) => {
                                                                            setFieldTouched("year", true, true)
                                                                        }}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Field
                                                                    fullWidth
                                                                    type="text"
                                                                    name="duration"
                                                                    disabled={isSubmitting || !!schedule?.published}
                                                                    label="Временная единица плана"
                                                                    select
                                                                    variant="standard"
                                                                    component={TextField}
                                                                    required={true}
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                    InputProps={{
                                                                        onChange: (e) => {
                                                                            const {value, name} = e.target
                                                                            setFieldValue(name, value)
                                                                        }
                                                                    }}
                                                                >
                                                                    {Object.entries(durations).map(([key, duration]) => (
                                                                        <MenuItem key={key} disabled={!duration.active} value={duration.key}>
                                                                            {duration.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Field>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Field
                                                                    fullWidth
                                                                    type="text"
                                                                    name="category"
                                                                    disabled={isSubmitting || !!schedule?.published}
                                                                    label="Категория"
                                                                    select
                                                                    variant="standard"
                                                                    component={TextField}
                                                                    required={true}
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                    InputProps={{
                                                                        onChange: (e) => {
                                                                            const {value, name} = e.target
                                                                            setFieldValue(name, value)
                                                                        }
                                                                    }}
                                                                >
                                                                    {
                                                                        categories.map(category => (
                                                                            <MenuItem key={category.id} disabled={category.years.indexOf(values.year?.getFullYear()) !== -1} value={category.id}>
                                                                                {category.name}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </Field>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction="row" alignItems="stretch" wrap="wrap" spacing={2}>
                                                        <Grid item xs={4}>
                                                            <Field
                                                                fullWidth
                                                                name="budget.limit"
                                                                disabled={isSubmitting}
                                                                type="number"
                                                                label="Лимит оперативного бюджета"
                                                                required
                                                                inputProps={{
                                                                    min: 0,
                                                                    step: 0.01
                                                                }}
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Field
                                                                fullWidth
                                                                name="budget.fact"
                                                                disabled={isSubmitting}
                                                                type="number"
                                                                label="Факт"
                                                                inputProps={{
                                                                    min: 0,
                                                                    step: 0.01
                                                                }}
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <AsyncAutocomplete
                                                                value={values.users}
                                                                name={"users"}
                                                                getOptionLabel={option => `${option.last_name} ${option.first_name} ${option.middle_name}`.trim()}
                                                                label={"Центр финансовой ответственности *"}
                                                                getValues={(search) => UserActions.users({
                                                                    role: 'supervisor',
                                                                    limit: 50,
                                                                    page: 1,
                                                                    organization: 'central',
                                                                    except: values.users.map(user => user.id),
                                                                    ...(search ? {search: search} : {})
                                                                }, true)}
                                                                onChange={async (e, value) => {
                                                                    await setFieldValue('users', value)
                                                                }}
                                                                setTouched={setFieldTouched}
                                                                error={getIn(touched, 'users') && getIn(errors, 'users')}
                                                                disabled={isSubmitting}
                                                                values={schedule?.users}
                                                                default={schedule ? schedule.users : []}
                                                                multiple={true}
                                                                condition={true}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction="row" alignItems="stretch" wrap="wrap" spacing={2}>
                                                        <Grid item xs={4}>
                                                            <Field
                                                                fullWidth
                                                                name="comment"
                                                                disabled={isSubmitting}
                                                                type="text"
                                                                label="Комментарий"
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.footer}>
                                    <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                        <Grid item className={classes.footerContent}>
                                            <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                <Grid item>
                                                    <Submit
                                                        disableElevation
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                    >
                                                        Сохранить
                                                    </Submit>
                                                </Grid>
                                                {(!schedule || !schedule.published) &&
                                                    <Grid item>
                                                        <Submit
                                                            disableElevation
                                                            variant="contained"
                                                            disabled={isSubmitting}
                                                            color="primary"
                                                            type="button"
                                                            onClick={() => {
                                                                setFieldValue('published', true)
                                                                submitForm().then(() => {})
                                                            }}
                                                        >
                                                            Опубликовать
                                                        </Submit>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Paper>
        </Grid>
    ) : null
}
