export default function system(state = {
    cities: [],
    regions: [],
    banks: [],
    categories: [],
    categoriesTree: [],
    units: [],
    items: {
        data: [],
        meta: {
            total: 0,
            per_page: 15,
            current_page: 1
        },
        category: null
    },
    rf_cb_rate_coefficients: []
}, action) {
    switch (action.type) {
        case "SYSTEM_CITIES_REQUEST":
        case "SYSTEM_CURRENCIES_REQUEST":
        case "SYSTEM_REGIONS_REQUEST":
        case "SYSTEM_BANKS_REQUEST":
        case "SYSTEM_ORDER_STATUS_REQUEST":
        case "SYSTEM_CATEGORIES_REQUEST":
        case "SYSTEM_CATEGORIES_TREE_REQUEST":
        case "SYSTEM_ITEMS_REQUEST":
        case 'SYSTEM_UNITS_REQUEST':
            return state
        case "SYSTEM_CITIES_SUCCESS":
            return {
                ...state,
                ...{cities: action.payload}
            }
        case "SYSTEM_CITIES_FAILURE":
        case "SYSTEM_CITIES_CLEAR":
            return {
                ...state,
                ...{cities: []}
            }
        case "SYSTEM_CURRENCIES_SUCCESS":
            return {
                ...state,
                ...{currencies: action.payload}
            }
        case "SYSTEM_CURRENCIES_FAILURE":
            return {
                ...state,
                ...{currencies: []}
            }
        case "SYSTEM_REGIONS_SUCCESS":
            return {
                ...state,
                ...{regions: action.payload}
            }
        case "SYSTEM_REGIONS_FAILURE":
        case "SYSTEM_REGIONS_CLEAR":
            return {
                ...state,
                ...{regions: []}
            }
        case "SYSTEM_BANKS_SUCCESS":
            return {
                ...state,
                ...{banks: action.payload}
            }
        case "SYSTEM_BANKS_FAILURE":
        case "SYSTEM_BANKS_CLEAR":
            return {
                ...state,
                ...{banks: []}
            }
        case "SYSTEM_ORDER_STATUS_SUCCESS":
            return {
                ...state,
                ...{status: action.payload}
            }
        case "SYSTEM_ORDER_STATUS_FAILURE":
        case "SYSTEM_ORDER_STATUS_CLEAR":
            return {
                ...state,
                ...{status: []}
            }
        case "SYSTEM_CATEGORIES_SUCCESS":
            return {
                ...state,
                ...{categories: action.payload}
            }
        case "SYSTEM_CATEGORIES_FAILURE":
        case "SYSTEM_CATEGORIES_CLEAR":
            return {
                ...state,
                ...{categories: []}
            }
        case "SYSTEM_CATEGORIES_TREE_SUCCESS":
            return {
                ...state,
                ...{categoriesTree: action.payload}
            }
        case "SYSTEM_CATEGORIES_TREE_FAILURE":
        case "SYSTEM_CATEGORIES_TREE_CLEAR":
            return {
                ...state,
                ...{categoriesTree: []}
            }
        case "SYSTEM_ITEMS_SUCCESS":
            return {
                ...state,
                ...{items: action.payload}
            }
        case "SYSTEM_ITEMS_FAILURE":
        case "SYSTEM_ITEMS_CLEAR":
            return {
                ...state,
                ...{items: {data: [], meta: {total: 0, per_page: 15, current_page: 1}, category: null}}
            }
        case "SYSTEM_ITEMS_CATEGORY":
            return {
                ...state,
                ...{items: {...state.items, ...{category: action.payload}}}
            }
        case 'SYSTEM_UNITS_SUCCESS':
            return {
                ...state,
                ...{units: action.payload}
            }
        case 'SYSTEM_UNITS_FAILURE':
        case 'SYSTEM_UNITS_CLEAR':
            return {
                ...state,
                ...{units: []}
            }
        case "SYSTEM_GENERICS_SUCCESS":
            return {
                ...state,
                ...{generics: action.payload}
            }
        case "SYSTEM_RF_CB_RATE_COEFFICIENT_SUCCESS":
            return {
                ...state,
                ...{rf_cb_rate_coefficients: action.payload}
            }
        default:
            return state
    }
}
