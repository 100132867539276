export default function notification(state = {
    notifications: {data: [], meta: {total: 0, per_page: 15, current_page: 1}},
    count: 0,
    types: [],
    filters: {
        type: [],
        beginning: null,
        ending: null,
    }
}, action) {
    switch (action.type) {
        case "NOTIFICATION_EVENTS_REQUEST":
            return state
        case "NOTIFICATION_EVENTS":
            return {
                ...state,
                ...{
                    notifications: {data: action.payload.data, meta: action.payload.meta},
                    count: action.payload.count
                }
            }
        case "NOTIFICATION_EVENTS_FAILURE":
            return state
        case "NOTIFICATION_SETTINGS_REQUEST":
            return state
        case "NOTIFICATION_SETTINGS":
            return {
                ...state,
                ...{
                    settings: action.payload,
                }
            }
        case "NOTIFICATION_SETTINGS_FAILURE":
            return state
        case "NOTIFICATION_EVENT_COUNT_REQUEST":
            return state
        case "NOTIFICATION_EVENT_COUNT":
            return {
                ...state,
                ...{count: action.payload}
            }
        case "NOTIFICATION_EVENT_COUNT_FAILURE":
            return state
        case "NOTIFICATION_EVENT_TYPES_REQUEST":
            return state
        case "NOTIFICATION_EVENT_TYPES":
            return {
                ...state,
                ...{types: action.payload}
            }
        case "NOTIFICATION_EVENT_TYPES_FAILURE":
            return state
        case "NOTIFICATION_EVENT_DELETE_REQUEST":
            return state
        case "NOTIFICATION_EVENT_DELETE_SUCCESS":
            return state
        case "NOTIFICATION_EVENT_DELETE_FAILURE":
            return state
        case "NOTIFICATION_EVENT_READ_REQUEST":
            return state
        case "NOTIFICATION_EVENT_READ_SUCCESS":
            return {
                ...state,
                ...{
                    notifications: {
                        data: state.notifications.data.map(event => {
                            if (event.id === action.payload) {
                                return {
                                    ...event,
                                    ...{read: true}
                                }
                            }
                            return event
                        }),
                        meta: state.notifications.meta
                    },
                    count: state.count - 1
                }
            }
        case "NOTIFICATION_EVENT_READ_FAILURE":
            return state
        case "NOTIFICATION_FILTER":
            return {
                ...state,
                ...{
                    filters: {...state.filters, ...action.payload}
                }
            }
        default:
            return state
    }
}
