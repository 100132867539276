import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {Field, Form, Formik} from "formik"

import {
    Grid,
    makeStyles,
    Dialog,
    DialogTitle,
    DialogContent, TextField as MuiTextField
} from "@material-ui/core"
import {TextFieldWithError as CustomTextField} from "../../../../../App/components/Inputs/TextFieldWithError"
import {Submit} from "../../../../../App/components/Button/Submit"
import * as Yup from "yup";
import {IntegrationActions} from "../../../../actions/integration";
import {v4 as uuidv4} from "uuid";
import {Autocomplete} from "@material-ui/lab";

const useStyles = makeStyles(() => ({
    fullWidth: {
        "width": "100%"
    },
    fullWidthDialog: {
        "width": "500px"
    },
    column: {
        "height": "86px"
    }
}))

export const Token = (props) => {
    const dispatch = useDispatch()

    const {integration, open, setOpen, setLoading} = props
    const classes = useStyles()
    const [permissions, setPermissions] = useState([])

    const handleClose = () => {
        setOpen(false);
    };

    useEffect( () => {
        const getData = async () => {
            return dispatch(IntegrationActions.permissions())
        }

        if (open) {
            getData().then(
                (data) => {
                    setPermissions(data)
                }
            )
        }
    }, [open]);

    return open ? (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Интеграция</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Formik
                    initialValues={{
                        name: integration?.name,
                        token: integration?.token ?? uuidv4(),
                        permissions: integration?.permissions ?? []
                    }}
                    validationSchema={Yup.object().shape({
                        ...{
                            name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            token: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            permissions: Yup.array().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        }
                    })}
                    onSubmit={(values, {setSubmitting, setFieldError}) => {
                        return dispatch(integration
                            ? IntegrationActions.edit({...values, permissions: values.permissions.map(permission => permission.id)}, integration.id)
                            : IntegrationActions.create({...values, permissions: values.permissions.map(permission => permission.id)})).then(
                            () => {
                                setSubmitting(false)
                                setLoading(false)
                                setOpen(false)
                            },
                            (errors) => {
                                if (errors) {
                                    Object.keys(errors.errors).forEach(function (key){
                                        setFieldError(key,errors.errors[key].join(','))
                                    });
                                    setSubmitting(false)
                                }
                            }
                        )
                    }}
                >
                    {({
                        values,
                        dirty,
                        isSubmitting,
                        isValid,
                        setFieldValue,
                    }) => (
                        <Form className={classes.fullWidthDialog}>
                            <Grid item xs={12}>
                                <Grid item className={classes.column}>
                                    <Field
                                        fullWidth
                                        disabled={isSubmitting}
                                        name="name"
                                        required
                                        label="Наименование"
                                        inputProps={{
                                            onChange: (e) => {
                                                const {value, name} = e.target
                                                setFieldValue(name, value)
                                            },
                                        }}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item className={classes.column}>
                                    <Field
                                        fullWidth
                                        disabled
                                        name="token"
                                        required
                                        label="Токен"
                                        inputProps={{
                                            onChange: (e) => {
                                                const {value, name} = e.target
                                                setFieldValue(name, value)
                                            }
                                        }}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item className={classes.column}>
                                    <Autocomplete
                                        options={permissions}
                                        required
                                        multiple
                                        name="permissions"
                                        noOptionsText='Нет доступных вариантов'
                                        getOptionLabel={(option) => option.name}
                                        value={values.permissions}
                                        onChange={(e, item) => {
                                            setFieldValue("permissions", item)
                                        }}
                                        renderInput={(params) =>
                                            <MuiTextField
                                                {...params}
                                                fullWidth
                                                label="Права доступа"
                                                name="permissions"
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={2}>
                                    <Grid item>
                                        <Submit
                                            disableElevation
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                setOpen(false)
                                            }}
                                        >
                                            Отменить
                                        </Submit>
                                    </Grid>
                                    <Grid item>
                                        <Submit
                                            disableElevation
                                            variant="contained"
                                            color="primary"
                                            disabled={isSubmitting || !isValid || !dirty}
                                            type="submit"
                                        >
                                            Сохранить
                                        </Submit>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    ) : null
}
