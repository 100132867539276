import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import {useDebounce} from 'use-debounce'
import * as Yup from "yup"
import clsx from "clsx"

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    withStyles
} from "@material-ui/core"

import {
    AddShoppingCart as AddShoppingCartIcon,
    ExpandMore as ExpandMoreIcon,
    Search as SearchIcon
} from '@material-ui/icons'

import {ButtonList} from "../../../App/components/ButtonList/ButtonList"
import {CatalogActions} from "../../actions/catalog"
import {TextFieldWithError as CustomTextField} from "../../../App/components/Inputs/TextFieldWithError"
import {DeliveryActions} from "../../../Delivery/actions/delivery"
import {AuthorizationService} from "../../../Auth/services/authorization"
import {Submit} from "../../../App/components/Button/Submit"
import {Product} from "../Product"
import {round} from "../../../App/helpers/number"
import {endings} from "../../../App/helpers/endings"
import * as AppStorage from "../../../App/helpers/storage"

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    submit: {
        height: '35px'
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 230px)"
    },
    accordion: {
        "& .MuiAccordionSummary-content": {
            "max-width": "calc(100% - 32px)"
        }
    },
    input: {
        "width": "100%",
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100% - 70px)",
        "min-height": "calc(100% - 70px)",
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    article: {
        "cursor": "pointer",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "200px",
    },
    column: {
        "height": "86px"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    searchLabel: {
        "margin-bottom": "14px",
    },
    amount: {
        "min-width": "100px"
    },
    sort: {
        "margin-bottom": "15px"
    },
    lightTooltip: {
        "font-size": "0.8rem",
        "backgroundColor": "white",
        "color": "rgba(0, 0, 0, 0.87)",
        "border": "1px solid #ededed"
    },
    accordionItem: {
        "border-top": "0 !important",
        "box-shadow": "0 0 0 !important",
        "border-bottom": "1px solid rgba(224, 224, 224, 1) !important",
        "border-color": "grey"
    },
}))

const CustomTableCell = withStyles({
    root: {
        "padding": "8px",
        "border-bottom": "none"
    }
})(TableCell);

const sortList = [
    {
        key: 'name_asc',
        value: 'Наименование А-Я'
    },
    {
        key: 'name_desc',
        value: 'Наименование Я-А'
    },
    {
        key: 'article_asc',
        value: 'Артикул А-Я'
    },
    {
        key: 'article_desc',
        value: 'Артикул Я-А'
    },
    {
        key: 'price_asc',
        value: 'Цена по возрастанию'
    },
    {
        key: 'price_desc',
        value: 'Цена по убыванию'
    }
]

const typeList = [
    {
        key: 'after_fact',
        value: 'Постфактум'
    },
    {
        key: 'active',
        value: 'Рабочий'
    },
    {
        key: 'all',
        value: 'Все'
    }
]
export const Content = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    let {location} = props

    const {products, category, filter, account, searchFilter} = useSelector(state => {
        return {...state.catalog, ...state.catalog.products, ...state.account}
    })

    const [initial, setInitial] = useState(false)
    const [searchRequest] = useDebounce(searchFilter.search, 900)
    const [contract, setContract] = useState(null)
    const [dialog, setDialog] = useState(false)
    const [selected, setSelected] = useState([])
    const [page, setPage] = useState(parseInt(products.meta.current_page - 1))
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const containerRef = useRef(null)
    const [expanded, setExpanded] = useState(false)

    const handleChange = (product) => (event, isExpanded) => {
        if (isExpanded) {
            const getItems = async () => {
                return await dispatch(CatalogActions.items(product.key, {
                    ...{
                        limit: rowsPerPage,
                        page: page + 1,
                        type: searchFilter.type,
                        sort: searchFilter.sort,
                        key: product.key
                    },
                    ...(searchRequest ? {search: searchRequest} : {}),
                    ...(category ? {category_id: category} : {}),
                    ...(filter.supplier ? {supplier_id: filter.supplier.id} : {}),
                    ...(filter.contract ? {contract_id: filter.contract.id} : {}),
                    ...(filter.amount ? {amount: filter.amount} : {}),
                    ...(filter.price.from ? {price_from: filter.price.from} : {}),
                    ...(filter.price.to ? {price_to: filter.price.to} : {})
                }))
            }
            getItems().then(_ => {
                setExpanded(product.key)
            })
        } else {
            setExpanded(false)
        }
    };

    const query = new URLSearchParams(location.search);

    useEffect(() => {
        if (!initial) {
            dispatch({type: 'CATALOG_PRODUCTS_CLEAR'})

            setInitial(true)
        }
        // eslint-disable-next-line
    }, [initial]);

    useEffect(() => {
        const getProducts = async () => {
            return await dispatch(CatalogActions.products({
                ...{
                    limit: rowsPerPage,
                    page: page + 1,
                    type: searchFilter.type,
                    sort: searchFilter.sort
                },
                ...(searchRequest ? {search: searchRequest} : {}),
                ...(category ? {category_id: category} : {}),
                ...(filter.supplier ? {supplier_id: filter.supplier.id} : {}),
                ...(filter.contract ? {contract_id: filter.contract.id} : {}),
                ...(filter.amount ? {amount: filter.amount} : {}),
                ...(filter.price.from ? {price_from: filter.price.from} : {}),
                ...(filter.price.to ? {price_to: filter.price.to} : {})
            }))
        }

        const id = parseInt(query.get('contract_id'))
        if (id && !filter.contract && initial) {
            window.history.pushState('Каталог', 'Электронный магазин', '/catalog/products')
            if (!AppStorage.get('CATALOG_PRODUCTS_FILTER_CLEAR')) {
                dispatch({type: "CATALOG_PRODUCTS_FILTER", payload: {...filter, ...{contract: {id: id}}}})
                AppStorage.remove('CATALOG_PRODUCTS_FILTER_CLEAR')
            } else {
                getProducts().then(_ => {
                    setExpanded(false)
                })
            }
        } else if (initial) {
            setContract(filter.contract)

            getProducts().then(_ => {
                setExpanded(false)
            })
        }

        containerRef.current && containerRef.current.scrollTo({behavior: 'smooth', top: 0})
        // eslint-disable-next-line
    }, [dispatch, page, rowsPerPage, searchRequest, searchFilter.type, searchFilter.sort, category, filter, filter.supplier, filter.contract, filter.amount, filter.price.from, filter.price.to, initial]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const getAmount = (item) => {
        let amount = round(round(parseFloat(item.amount), 4) - (item.ordered ? round(parseFloat(item.ordered), 4) : 0), 4);
        return amount < 0 ? 0 : amount;
    }

    const getExpirationDate = (item) => {
        if (item.item.expiration_date_type === 'warranty_period') {
            return item.expiration_date_number ? endings(parseInt(item.expiration_date_number), ['месяц', 'месяца', 'месяцев']) : null;
        } else if (item.item.expiration_date_type === 'expiration_date_percent') {
            return item.expiration_date_number ? `${item.expiration_date_number} %` : null;
        } else {
            return item.expiration_date ? item.expiration_date + ' г.' : null;
        }
    }

    return <Grid item className={classes.content}>
        <Grid container direction="column" justify="center" alignItems="stretch">
            <Grid item>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item className={classes.fullWidth}>
                        <TextField
                            id="search"
                            variant="standard"
                            name={'search'}
                            placeholder={`Поиск: наименование, артикул, МНН`}
                            fullWidth={true}
                            margin={'normal'}
                            value={searchFilter.search}
                            onChange={(event) => {
                                if (event.target.value === '' && searchFilter.search !== '') {
                                    dispatch({
                                        type: "CATALOG_PRODUCTS_SEARCH_FILTER", payload: {
                                            search: '',
                                            sort: 'name_asc',
                                            type: 'all',
                                            tabType: 2,
                                        }
                                    })
                                } else {
                                    dispatch({
                                        type: "CATALOG_PRODUCTS_SEARCH_FILTER",
                                        payload: {...searchFilter, ...{search: event.target.value}}
                                    })
                                }
                            }}
                            classes={{
                                root: clsx(classes.filterLabel, classes.searchLabel)
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon style={{color: '#c1c1c1'}} />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        <FormControl className={classes.sort}>
                            <InputLabel>Сортировка по</InputLabel>
                            <Select
                                fullWidth
                                value={searchFilter.sort}
                                onChange={(e) => {
                                    dispatch({
                                        type: "CATALOG_PRODUCTS_SEARCH_FILTER",
                                        payload: {...searchFilter, ...{sort: e.target.value}}
                                    })
                                }}
                            >
                                {
                                    sortList.map(item => (
                                        <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    {AuthorizationService.permissions(account, 'delivery_order_after_event') &&
                        <Grid item>
                            <ButtonList
                                value={searchFilter.tabType}
                                values={typeList}
                                onChange={(_, value) => {
                                    setPage(0)
                                    setInitial(false)
                                    dispatch({
                                        type: "CATALOG_PRODUCTS_SEARCH_FILTER",
                                        payload: {...searchFilter, ...{tabType: value, type: typeList[value].key}}
                                    })
                                }}
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
        <Paper>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item className={classes.fullWidth}>
                    <Grid container className={classes.container} direction="column" justify="space-between" alignItems="stretch">
                        <Grid ref={containerRef} item className={classes.inputContent}>
                            {
                                products.data.map(product => (
                                    <Accordion key={product.name} expanded={expanded === product.key} onChange={handleChange(product)} className={classes.accordionItem}>
                                        <AccordionSummary className={classes.accordion} expandIcon={<ExpandMoreIcon/>}>
                                            <Grid container direction="row" justify="space-between" alignItems="center">
                                                <Grid item xs={7}>
                                                    <Tooltip classes={{tooltip: classes.lightTooltip}} title={product.name} placement="bottom-start">
                                                        <Typography noWrap>{product.name}</Typography>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container direction="column" justify="flex-start" alignItems="center">
                                                        {
                                                            product.price.map((price, i) => (
                                                                <Grid key={i} item>
                                                                    <Typography variant="body2" noWrap>
                                                                        {parseFloat(price.value).toLocaleString('ru-RU', {
                                                                            style: 'currency',
                                                                            currency: price.currency}).replace('KZT', '₸')}
                                                                    </Typography>
                                                                </Grid>
                                                            ))
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer className={classes.tableContainer}>
                                                <Table stickyHeader aria-label="sticky table">
                                                    <TableBody>
                                                        {product.items && product.items.map((item) => {
                                                            return (
                                                                <TableRow hover key={item.id}>
                                                                    <CustomTableCell
                                                                        style={{maxWidth: "300px"}}>
                                                                        <Typography noWrap>
                                                                            {item.offer.company.legal_detail.name}
                                                                        </Typography>
                                                                        <Grid container direction="row" justify="flex-start" alignItems="center">
                                                                            {(item && item.article_number) &&
                                                                                <Grid item className={classes.article}>
                                                                                    <Typography component="span" noWrap>
                                                                                        <Link
                                                                                            onClick={() => {
                                                                                                setDialog(item)
                                                                                            }
                                                                                            }
                                                                                        >
                                                                                            Артикул {item.article_number}
                                                                                        </Link>
                                                                                    </Typography>
                                                                                </Grid>
                                                                            }
                                                                            <Grid item className={classes.fullWidth}>
                                                                                <Typography noWrap>
                                                                                    Договор <Link
                                                                                    href={`/contracts?id=${item.offer.contract.id}`}
                                                                                    className={classes.link}>{item.offer.contract.number ?? item.offer.contract.id}</Link>
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </CustomTableCell>
                                                                    <CustomTableCell>
                                                                        Остатки: {item.item.amount ? (getAmount(item.item)) : null}
                                                                    </CustomTableCell>
                                                                    <CustomTableCell>
                                                                        {parseFloat(item.price).toLocaleString('ru-RU', {
                                                                            ...(item.offer.contract.currency ? {
                                                                                style: 'currency',
                                                                                currency: item.offer.contract.currency.code
                                                                            } : {})
                                                                        }).replace('KZT', '₸')}
                                                                    </CustomTableCell>
                                                                    <CustomTableCell>
                                                                        {(item.tax_percent >= 0) ? `НДС ${item.tax_percent}%` : 'Без НДС'}
                                                                    </CustomTableCell>
                                                                    <CustomTableCell>
                                                                        {getExpirationDate(item)}
                                                                    </CustomTableCell>
                                                                    <CustomTableCell style={{minWidth: "250px"}}>
                                                                        <Formik
                                                                            initialValues={{
                                                                                id: item.id,
                                                                                amount: 0,
                                                                            }}
                                                                            validationSchema={Yup.object().shape({
                                                                                id: Yup.string(),
                                                                                amount: item.can_be_ordered ? Yup.number()
                                                                                    .min(parseFloat(item.min_ordered_quantity ?? 0).toFixed(2), `Мин. заказ >= ${parseFloat(item.min_ordered_quantity ?? 0).toFixed(2)} ${item.item.unit ? item.item.unit.short : ''}`)
                                                                                    .required("") : Yup.number()
                                                                                    .min(parseFloat(item.min_ordered_quantity ?? 0).toFixed(2), `Мин. заказ >= ${parseFloat(item.min_ordered_quantity ?? 0).toFixed(2)} ${item.item.unit ? item.item.unit.short : ''}`)
                                                                                    .max(
                                                                                        ((parseFloat(item.item.amount) - (item.item.ordered ? parseFloat(item.item.ordered) : 0)) < parseFloat(item.min_ordered_quantity)) ? parseFloat(item.min_ordered_quantity).toFixed(2) : (parseFloat(item.item.amount) - (item.item.ordered ? parseFloat(item.item.ordered) : 0)).toFixed(2),
                                                                                        `Макс. заказ <= ${((parseFloat(item.item.amount) - (item.item.ordered ? parseFloat(item.item.ordered) : 0)) < parseFloat(item.min_ordered_quantity)) ? parseFloat(item.min_ordered_quantity).toFixed(2) : (parseFloat(item.item.amount) - (item.item.ordered ? parseFloat(item.item.ordered) : 0)).toFixed(2)} ${item.item.unit ? item.item.unit.short : ''}`
                                                                                    )
                                                                                    .required("")
                                                                            })}
                                                                            onSubmit={(values, {
                                                                                setSubmitting,
                                                                                setFieldValue
                                                                            }) => {
                                                                                return dispatch(DeliveryActions.addItem(null, values)).then(
                                                                                    () => {
                                                                                        dispatch({
                                                                                            type: 'CART_ITEMS_COUNT_LOADING',
                                                                                            payload: false
                                                                                        })
                                                                                        setSelected(selected.filter(el => el.id !== item.id))
                                                                                        setFieldValue('amount', 0, false)
                                                                                        setSubmitting(false);
                                                                                    },
                                                                                    errors => {
                                                                                        if (errors) {
                                                                                        }
                                                                                        setSubmitting(false);
                                                                                    }
                                                                                )
                                                                            }}
                                                                        >
                                                                            {({
                                                                                  setFieldValue,
                                                                                  values,
                                                                                  isValid
                                                                              }) => (
                                                                                <Form>
                                                                                    <Grid container direction="row" justify="flex-end" alignItems="center">
                                                                                        <Grid item xs={6} className={classes.amount}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                name="amount"
                                                                                                type="number"
                                                                                                inputProps={{
                                                                                                    step: 0.0001,
                                                                                                    min: parseFloat(item.min_ordered_quantity ?? 0).toFixed(2),
                                                                                                    onChange: (e) => {
                                                                                                        const value = e.target.value
                                                                                                        setFieldValue('amount', value)
                                                                                                        let stockQuantity = parseFloat(item.item.amount) - (item.item.ordered ? parseFloat(item.item.ordered) : 0);
                                                                                                        let minOrderedQuantity = parseFloat(item.min_ordered_quantity ?? 0);
                                                                                                        let condition = item.item.amount ? stockQuantity : 0;

                                                                                                        let resultCondition = item.can_be_ordered && item.item.lot.order.form === null ?
                                                                                                            ((parseFloat(value) >= minOrderedQuantity))
                                                                                                            : ((parseFloat(value) >= minOrderedQuantity) && (parseFloat(value) <= stockQuantity) && ((condition > 0) && (condition >= minOrderedQuantity)))

                                                                                                        if (resultCondition) {
                                                                                                            setSelected([...selected.filter(el => el.id !== item.id), {
                                                                                                                id: item.id,
                                                                                                                amount: value
                                                                                                            }])
                                                                                                        } else {
                                                                                                            setSelected(selected.filter(el => el.id !== item.id))
                                                                                                        }
                                                                                                    }
                                                                                                }}
                                                                                                component={CustomTextField}
                                                                                                helperText={`Мин. заказ >= ${parseFloat(item.min_ordered_quantity ?? 0).toFixed(2)} ${item.item.unit ? item.item.unit.short : ''}`}
                                                                                                endAdornment={item.item.unit ?
                                                                                                    <InputAdornment position="end">{item.item.unit.short}</InputAdornment> : null}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item>
                                                                                            <IconButton
                                                                                                color="primary"
                                                                                                aria-label="Добавить в корзину"
                                                                                                type="submit"
                                                                                                disabled={!values.amount || !isValid || (((item.item.amount ? (parseFloat(item.item.amount) - (item.item.ordered ? parseFloat(item.item.ordered) : 0)) : 0) <= 0 && !item.can_be_ordered) && ((item.item.amount ? (parseFloat(item.item.amount) - (item.item.ordered ? parseFloat(item.item.ordered) : 0)) : 0) < parseFloat(item.min_ordered_quantity ?? 0)))}
                                                                                            >
                                                                                                <AddShoppingCartIcon/>
                                                                                            </IconButton>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Form>
                                                                            )}
                                                                        </Formik>
                                                                    </CustomTableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                ))
                            }
                        </Grid>
                        {dialog ? <Product open={dialog} setDialog={setDialog} item={dialog} /> : null}
                        <Grid item className={classes.footer}>
                            <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                <Grid item className={classes.footerContent}>
                                    <Grid container className={classes.footerContainer} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                        <Grid item>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                                component='div'
                                                labelRowsPerPage={'Записей на странице:'}
                                                count={products.meta.total}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                <Grid item>
                                                    <Submit
                                                        disableElevation
                                                        variant="contained"
                                                        color="primary"
                                                        type="button"
                                                        startIcon={<AddShoppingCartIcon/>}
                                                        disabled={!contract}
                                                        style={{height: '35px'}}
                                                        onClick={() => {
                                                            dispatch(DeliveryActions.addAllItems(contract.id)).then(
                                                                () => {
                                                                    setExpanded(false)
                                                                },
                                                                errors => {
                                                                    if (errors) {
                                                                    }
                                                                    setExpanded(false)
                                                                }
                                                            )
                                                        }}
                                                    >
                                                        Добавить все в корзину
                                                    </Submit>
                                                </Grid>
                                                <Grid item>
                                                    <Typography>Выбрано: {selected.length}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Submit
                                                        disableElevation
                                                        variant="contained"
                                                        color="primary"
                                                        type="button"
                                                        disabled={!selected.length}
                                                        startIcon={<AddShoppingCartIcon/>}
                                                        style={{height: '35px'}}
                                                        onClick={() => {
                                                            dispatch(DeliveryActions.addItems({items: selected})).then(
                                                                () => {
                                                                    dispatch({
                                                                        type: 'CART_ITEMS_COUNT_LOADING',
                                                                        payload: false
                                                                    })
                                                                    setSelected([])
                                                                    setExpanded(false)
                                                                },
                                                                errors => {
                                                                    if (errors) {}
                                                                }
                                                            )
                                                        }}
                                                    >
                                                        В корзину
                                                    </Submit>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    </Grid>
}
