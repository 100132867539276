import React from "react"

import {Menu as AppMenu} from "../App/Menu";
import {Content as AppContent} from "../App/Content";
import {Content} from "./components/Settings/Content";
import {Menu} from "../Account/Menu";

export const Settings = (props) => {
    return <React.Fragment>
        <AppMenu
            Component={Menu}
            {...props}
        />
        <AppContent
            Component={Content}
            {...props}
        />
    </React.Fragment>
}
