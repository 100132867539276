import React from "react"
import {Field} from "formik"
import {TextField} from "formik-material-ui"

import {Grid, InputAdornment, makeStyles} from "@material-ui/core"

const useStyles = makeStyles(() => ({
    fullWidth: {
        'width': '100%'
    }
}))

export const FieldNumber = (props) => {
    const classes = useStyles()

    const { id, addition, isSubmitting, disabled } = props

    return (
        <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
            <Grid item sm={6} className={classes.fullWidth}>
                <Field
                    fullWidth
                    name={ `attributes.${id}.number` }
                    type="number"
                    disabled={isSubmitting || disabled}
                    label="Количество"
                    inputProps={{
                        step: 1,
                        onWheel: e => {
                            e.target.blur()
                            e.stopPropagation()
                            setTimeout(() => {
                                e.target.focus()
                            }, 0)
                        }
                    }}
                    component={ TextField }
                    InputProps={{
                        ...((addition && addition.prefix.value) ? {startAdornment: <InputAdornment position="start">{addition.prefix.value}</InputAdornment>} : {})
                    }}
                />
            </Grid>
            <Grid item sm={6} className={classes.fullWidth}>
                <Field
                    fullWidth
                    name={ `attributes.${id}.form` }
                    type="number"
                    label="Виды"
                    disabled={isSubmitting || disabled}
                    inputProps={{
                        step: 1,
                        onWheel: e => {
                            e.target.blur()
                            e.stopPropagation()
                            setTimeout(() => {
                                e.target.focus()
                            }, 0)
                        }
                    }}
                    component={ TextField }
                    InputProps={{
                        ...((addition && addition.postfix.value) ? {endAdornment: <InputAdornment position="end">{addition.postfix.value}</InputAdornment>} : {})
                    }}
                />
            </Grid>
        </Grid>
    )
}
