import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Link} from "react-router-dom"
import {Field, Form, Formik, getIn} from "formik"

import {
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    TextField as MuiTextField,
    withStyles
} from '@material-ui/core'
import {Autocomplete} from "@material-ui/lab"

import {SystemActions} from "../App/actions/system"
import {CartActions} from "../Marketplace/actions/cart"
import {AuthorizationService} from "../Auth/services/authorization"
import {Submit} from "../App/components/Button/Submit"
import {CompanyActions} from "../Contract/actions/company"
import {ContractActions} from "../Contract/actions/contract"
import {localState} from "../App/components/LocalState";

const useStyles = makeStyles(() => ({
    item: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "cursor": "pointer"
    },
    active: {
        "background-image": "none !important",
        "background-color": "#898989"
    },
    circle: {
        "width": "12px",
        "height": "12px",
        "border-radius": '50%',
        "background-color": '#616161',
        "margin-bottom": "5px"
    },
}))

const CustomList = withStyles({
    root: {
        "padding": "0",
        "& .MuiListItem-root": {
            "background-image": "linear-gradient(to bottom,#e4e4e4,#f4f4f4)",
            "align-items": "center",
            "min-height": "50px",
            "padding": "5px 25px 0",
            "border-bottom": "1px solid #e4e4e4",
            "outline": "0"
        },
        "& .MuiListItemIcon-root": {
            "min-width": "30px"
        },
        "& .MuiListItem-button:hover": {
            "background-color": "#898989",
            "&:after": {
                "position": "absolute",
                "content": "''",
                "top": "0",
                "left": "0",
                "width": "100%",
                "height": "100%",
                "background-color": "rgba(0,0,0,.04)"
            }
        },
        "& .MuiListItemText-secondary": {
            "color": "#485868",
            "font-size": "15px"
        },
        "& .MuiListItemText-primary": {
            "font-size": "16px",
            "font-weight": "700",
            "text-transform": "uppercase"
        }
    }
})(List);

export const Menu = (props) => {
    const dispatch = useDispatch()
    const query = new URLSearchParams(props.location.search);
    const classes = useStyles()
    const { account, filter, carts, cart, categories } = useSelector(state => {return {...state.account, ...state.system, ...state.marketplace, ...state.delivery.orders}})
    const { companies }  = useSelector(state => state.contractCompanies)
    const [contracts, setContracts] = useState(localState)

    const [active, setActive] = useState(query.has('category') ? parseInt(query.get('category')) : null)

    const [company, setCompany] = useState(null)
    const [contract, setContract] = useState(null)

    const {location} = props

    useEffect(() => {
        if (company) {
            const getCompanies = async () => {
                let params = {
                    limit: 10,
                    page: 1,
                    type: AuthorizationService.roles(account, "supplier") ? 'customer' : 'supplier',
                    ...(company ? {name: company} : {}),
                    delivery: false
                }
                await dispatch(CompanyActions.companies(params))
            }

            getCompanies().then(_ => {})
        } else {
            dispatch({type: "CONTRACT_ALL_COMPANIES_CLEAR"})
        }
        // eslint-disable-next-line
    }, [dispatch, company])

    useEffect(() => {
        if (contract) {
            const getContracts = async () => {
                let params = {
                    limit: 10,
                    page: 1,
                    cart: true,
                    ...(contract ? {search: contract} : {})
                }
                return await dispatch(ContractActions.contracts(params))
            }
            getContracts().then((response) => {
                setContracts(response)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, contract])

    useEffect(() => {
        dispatch({type: "MARKETPLACE_ITEMS_CATEGORY", payload: active})
    }, [dispatch, active])

    useEffect(() => {
        const getCarts = async () => {
            return await dispatch(CartActions.carts())
        }

        getCarts().then(_ => {})
    }, [dispatch]);

    useEffect(() => {
        const getCategories = async () => {
            return await dispatch(SystemActions.categories())
        }

        if (!categories.length) {
            getCategories().then(() => {})
        }
        // eslint-disable-next-line
    }, [dispatch]);

    return (
        <CustomList>
            {[
                ...(AuthorizationService.permissions(account, "access_to_elmag") && AuthorizationService.permissions(account, "access_to_marketplace") ? [{
                    title: "Без договора",
                    to: "/carts"
                }] : []),
                ...(AuthorizationService.permissions(account, "access_to_order") ? [{
                    title: "По договору",
                    to: "/delivery/carts"
                }] : []),
            ].map((option, index) => (
                <React.Fragment key={index}>
                    <ListItem button key={option.title} onClick={() => { setActive(null) }} component={Link} to={option.to} classes={{ root: (location.pathname === option.to) ? classes.active : undefined }}>
                        <ListItemText primary={option.title} />
                    </ListItem>
                    {((option.to === '/carts') && (location.pathname === '/carts')) &&
                        <List component="div" disablePadding>
                            {carts.map((item, index) => (
                                <ListItem
                                    classes={{root: classes.item}}
                                    onClick={() => {
                                        dispatch(CartActions.cart(item.id))
                                    }}
                                    key={item.id}
                                    value={item.id}
                                >
                                    <ListItemIcon>
                                        {
                                            (cart && (cart.id === item.id) ? <div className={classes.circle} /> : <React.Fragment/>)
                                        }
                                    </ListItemIcon>
                                    <ListItemText secondary={`Корзина № ${index + 1}`} />
                                </ListItem>
                            ))}
                        </List>
                    }
                    {((option.to === '/delivery/carts') && (location.pathname === '/delivery/carts')) &&
                        <Formik
                            enableReinitialize
                            initialValues={{
                                filter: filter
                            }}
                            onSubmit={(values) => {
                                return dispatch({type: "DELIVERY_ORDERS_FILTER", payload: values.filter })
                            }}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  submitForm,
                                  setFieldValue,
                                  setFieldTouched
                              }) => (
                                <Form>
                                    <Grid container direction="column" justify="space-between" alignItems="stretch" style={{"width": "100%", "margin": "0px"}} spacing={2}>
                                        <Grid item>
                                            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                <Grid item>
                                                    <Autocomplete
                                                        filterOptions={(options) => options}
                                                        name={'filter.company'}
                                                        options={companies.data}
                                                        value={values.filter ? values.filter.company : null}
                                                        getOptionLabel={company => {
                                                            return company ? (company.hasOwnProperty('legal_detail') ? company.legal_detail.name : (company.name ?? '')) : ''
                                                        }}
                                                        noOptionsText={AuthorizationService.roles(account, "supplier") ? 'Заказчик' : 'Поставщик'}
                                                        onChange={(e, item) => {
                                                            setFieldValue('filter.company', item ? {
                                                                id: item.id,
                                                                name: item.legal_detail.name
                                                            } : null)
                                                        }}
                                                        renderInput={params => {
                                                            const error = getIn(touched, 'filter.company') && getIn(errors, 'filter.company');

                                                            return <Field
                                                                fullWidth
                                                                component={MuiTextField}
                                                                {...params}
                                                                onChange={(e) => {
                                                                    setCompany(e.target.value)
                                                                }}
                                                                error={!!error}
                                                                helperText={error}
                                                                name={'filter.company'}
                                                                label={AuthorizationService.roles(account, "supplier") ? 'Заказчик' : 'Поставщик'}
                                                            />
                                                        }}
                                                        onFocus={() => {
                                                            dispatch({type: 'CONTRACTS_CLEAR'})
                                                            setFieldTouched('filter.company', true, true)
                                                        }}
                                                        onBlur={() => {
                                                            setFieldTouched('filter.company', true, true)
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Autocomplete
                                                        filterOptions={(options) => options}
                                                        name={'filter.contract'}
                                                        options={contracts.data}
                                                        value={values.filter ? values.filter.contract : null}
                                                        getOptionLabel={contract => {
                                                            return contract ? (contract.hasOwnProperty('offer') ? `${contract.id} ${contract.offer.lot.order.name}` : '') : ''
                                                        }}
                                                        noOptionsText='Договор'
                                                        onChange={(e, item) => {
                                                            setFieldValue('filter.contract', item ?? null)
                                                        }}
                                                        renderInput={params => {
                                                            const error = getIn(touched, 'filter.contract') && getIn(errors, 'filter.contract');

                                                            return <Field
                                                                fullWidth
                                                                component={MuiTextField}
                                                                {...params}
                                                                onChange={(e) => {
                                                                    setContract(e.target.value)
                                                                }}
                                                                error={!!error}
                                                                helperText={error}
                                                                name={'filter.contract'}
                                                                label="Договор"
                                                            />
                                                        }}
                                                        onFocus={() => {
                                                            dispatch({type: 'CONTRACT_ALL_CONTRACTS_CLEAR'})
                                                            setFieldTouched('filter.contract', true, true)
                                                        }}
                                                        onBlur={() => {
                                                            setFieldTouched('filter.contract', true, true)
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="row" justify="space-between" alignItems="center">
                                                <Grid item>
                                                    <Submit
                                                        className={classes.submit}
                                                        disableElevation
                                                        variant="contained"
                                                        color="secondary"
                                                        type="button"
                                                        onClick={() => {
                                                            dispatch({type: "DELIVERY_ORDERS_FILTER", payload: {status: '', company: null, contract: null, order: {number: '', unscheduled_purchase: false}, created: {from: null, to: null}, price: {from: '', to: ''}}})
                                                        }}
                                                    >
                                                        Сбросить
                                                    </Submit>
                                                </Grid>
                                                <Grid item>
                                                    <Submit
                                                        className={classes.submit}
                                                        disableElevation
                                                        variant="contained"
                                                        color="primary"
                                                        type="button"
                                                        onClick={() => {
                                                            submitForm().then(() => {})
                                                        }}
                                                    >
                                                        Применить
                                                    </Submit>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    }
                </React.Fragment>
            ))}
        </CustomList>
    )
}
