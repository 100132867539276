import React from "react"

import {ShopService} from "../services/shop"
import {PriceService} from "../../../Price/services/price"
import {history} from "../../../App/helpers/history"
import {NoElmagAccessError} from "../../Marketplace/components/NoElmagAccessError"

export const ShopActions = {
    orders,
    allOrders,
    order,
    add,
    edit,
    offer,
    lot,
    getProducts
}

function orders(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SUPPLIER_ORDERS_REQUEST"})

        ShopService.orders(params)
            .then(
                orders => {
                    dispatch({type: "SUPPLIER_ORDERS", payload: {data: orders.data, meta: orders.meta}})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "SUPPLIER_ORDERS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    const message = error?.type && error.type === 'supplier_has_no_access' ?  <NoElmagAccessError message={error.message}/> : error.message
                    dispatch({type: "ALERT_ERROR", payload: {
                            message:message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function allOrders(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SUPPLIER_ORDERS_REQUEST"})

        ShopService.allOrders(params)
            .then(
                orders => {
                    dispatch({type: "SUPPLIER_ORDERS", payload: {data: orders.data, meta: orders.meta}})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "SUPPLIER_ORDERS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )
    })
}

function lot(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "LOT_REQUEST"})
        ShopService.lot(id)
            .then(
                lot => {
                    dispatch({type: "LOT", payload: lot})
                    dispatch({type: "LOADING", payload: false})
                    resolve(lot)
                },
                error => {
                    dispatch({type: "LOT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function order(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SUPPLIER_ORDER_REQUEST"})

        ShopService.order(id)
            .then(
                order => {
                    dispatch({type: "SUPPLIER_ORDER", payload: order})
                    dispatch({type: "LOADING", payload: false})
                    resolve(order)
                },
                error => {
                    dispatch({type: "SUPPLIER_ORDER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function add(formData) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "ADD_SUPPLIER_OFFER_REQUEST"})

        ShopService.add(formData)
            .then(
                response => {
                    dispatch({type: "ADD_SUPPLIER_OFFER_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_SUCCESS", payload: {
                            message:'Успешно отправлено',
                            type:'success'
                        }
                    })
                    history.push("/supplier/marketplace/orders")
                },
                error => {
                    dispatch({type: "ADD_SUPPLIER_OFFER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function offer(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "VIEW_SUPPLIER_OFFER_REQUEST"})

        ShopService.offer(id)
            .then(
                response => {
                    dispatch({type: "VIEW_SUPPLIER_OFFER_SUCCESS", payload: response})
                    dispatch({type: "LOADING", payload: false})
                },
                error => {
                    dispatch({type: "VIEW_SUPPLIER_OFFER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function edit(id, formData) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "EDIT_ORDER_REQUEST"})

        ShopService.edit(id, formData)
            .then(
                response => {
                    dispatch({type: "EDIT_ORDER_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_SUCCESS", payload: {
                            message:'Запись успешно обновлена',
                            type:'success'
                        }
                    })
                    history.push("/supplier/marketplace/orders")
                },
                error => {
                    dispatch({type: "EDIT_ORDER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function getProducts(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "GET_PRODUCTS_REQUEST"})

        PriceService.getOfferProducts(values)
            .then(
                response => {
                    dispatch({type: "GET_PRODUCTS_SUCCESS", payload: response.data})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response.data)
                },
                error => {
                    dispatch({type: "GET_PRODUCTS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
