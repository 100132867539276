import {HttpService} from "../../App/services/http"

export const PurchasesService = {
    purchasesByPurchasePlanID,
    purchases,
    conductionMethods,
    add,
    joint,
    edit,
    fundingSources,
    purchase,
    purchaseByID,
    categories,
    approve,
    inProgress,
    decline,
    deletePurchases,
    addComment,
    getDiffVersion,
    actionTypes,
    purchasesFilter,
    getPublish
}

function purchasesByPurchasePlanID(purchasePlanID, params) {
    return HttpService.get(`/planning/purchase-plan/${purchasePlanID}/purchases`, params)
}

function purchases(params) {
    return HttpService.get(`/planning/purchases`, params)
}

function purchaseByID(purchaseID) {
    return HttpService.get(`/planning/purchase/${purchaseID}`)
}

function purchase(purchaseID) {
    return HttpService.get(`/planning/purchase/${purchaseID}`)
}

function conductionMethods(fromPublish) {
    return HttpService.get(`/app/conduction-methods?fromPublish=${fromPublish}`)
}

function fundingSources() {
    return HttpService.get("/planning/purchase/funding-sources")
}

function add(formData) {
    return HttpService.post(`/planning/purchase`, formData, true, true)
}

function joint(values) {
    return HttpService.post(`/planning/purchase/joint`, values)
}

function edit(purchaseID, formData) {
    return HttpService.post(`/planning/purchase/${purchaseID}`, formData, true, true)
}

function categories(purchaseID) {
    return HttpService.get(`/planning/purchase/${purchaseID}/categories`)
}

function approve(ids) {
    return HttpService.put(`/planning/purchases/approve`, ids)
}

function deletePurchases(ids) {
    return HttpService.deleteMethod(`/planning/purchases/delete`, ids)
}

function inProgress(purchaseID) {
    return HttpService.put(`/planning/purchase/${purchaseID}/in-progress`)
}

function decline(values) {
    return HttpService.put(`/planning/purchase/decline`, values)
}

function addComment(purchaseID, values) {
    return HttpService.put(`/planning/purchase/${purchaseID}/addComment`, values)
}

function getDiffVersion(versionId, params) {
    return HttpService.get(`/planning/purchase-version-diff/${versionId}`, params)
}

function actionTypes(params) {
    return HttpService.get(`/planning/purchase-version-diff/action-types`, params)
}

function purchasesFilter(params) {
    return HttpService.get(`/planning/purchases/filter`, params)
}
function getPublish(params) {
    return HttpService.get(`/planning/purchases/publish`, params)
}