import React from "react"
import {useSelector} from "react-redux"

import {IndexNew as Purchase} from "../Purchase/IndexNew"

export const IndexNew = (props) => {
    const {account} = useSelector(state => state.account)

    return account ? <React.Fragment /> : <Purchase {...props} />
}
