import {SettingsService} from "../services/settings"

export const SettingsActions = {
    settings,
    update
}

function settings() {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PLANNING_SETTINGS_REQUEST"})

        SettingsService.settings()
            .then(
                settings => {
                    dispatch({type: "PLANNING_SETTINGS", payload: settings})
                    dispatch({type: "LOADING", payload: false})
                    resolve(settings)
                },
                error => {
                    dispatch({type: "PLANNING_SETTINGS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function update(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PLANNING_SETTINGS_UPDATE_REQUEST"})

        SettingsService.update(params)
            .then(
                settings => {
                    dispatch({type: "PLANNING_SETTINGS", payload: settings})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PLANNING_SETTINGS_UPDATE_FAILURE"})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}
