import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Link as Route} from "react-router-dom"
import format from "date-fns/format"
import {ru} from "date-fns/locale"

import {
    Grid,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@material-ui/core"

import {CumulativeDiscountsActions} from "../../../actions/cumulativeDiscounts"
import {Submit} from "../../../../App/components/Button/Submit"
import {history} from "../../../../App/helpers/history"

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 110px)"
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100% - 70px)",
        "min-height": "calc(100% - 70px)"
    },
    tableContainer: {
        "width": "100%",
        "height": "100%",
        "min-height": "100%",
        "overflow-y": "auto"
    },
    table: {
        "border": "1px solid #e4e4e4",
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    },
    sendOffer: {
        width: '245px',
    },
    contentColumn: {
        "max-width": "245px",
        "min-width": "245px",
    },
    contentColumnMax: {
        "max-width": "345px",
        "min-width": "345px",
    },
}))

const columns = [
    {
        id: 'name',
        label: 'Название',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'levels',
        label: 'Уровни',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'items',
        label: 'Объекты скидок',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'datetime',
        label: 'Срок действия',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'active',
        label: 'Статус',
        format: (value) => value.toLocaleString(),
    }
];

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {discounts} = useSelector(state => state.cumulativeDiscounts)
    const {account} = useSelector(state => state.account)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const tableRef = useRef(null)

    useEffect(() => {
        const getDiscounts = async () => {
            return await dispatch(CumulativeDiscountsActions.discounts({
                limit: rowsPerPage,
                company_id: account.company.id,
                page: page + 1,
            }))
        }
        getDiscounts().then(() => {
            tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
        })
    }, [dispatch, page, rowsPerPage, account.company.id]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const trueWordform = (num) => {
        num = Number(num) % 100;
        let num_x = num % 10;
        if ((num > 10) && (num < 20))
            return 'уровней';
        if ((num_x > 1) && (num_x < 5))
            return 'уровня';
        if (num_x === 1)
            return 'уровень';
        return 'уровень';
    }

    const getColumnContent = (column, discount) => {
        switch (column.id) {
            case 'levels':
                let levels = JSON.parse(discount.levels);
                return (
                    <React.Fragment>
                        {levels && levels.length ?
                            (
                                <Typography className={classes.itemsNames}>
                                    {levels.length + ' ' + trueWordform(levels.length)}
                                </Typography>)
                            : null
                        }
                    </React.Fragment>
                )
            case 'items':
                let items = JSON.parse(discount.items);
                return (
                    <React.Fragment>
                        {items.customers && items.customers.length ?
                            (<Typography className={classes.itemsNames}>
                                <b>Заказчики:</b> {items.customers.map(customer => customer.legal_detail.name).join(', ')}
                            </Typography>) : null
                        }
                    </React.Fragment>
                )
            case 'datetime':
                return (<Typography
                    className={classes.contentColumnMax}>c <b>{format(new Date(discount.datetime_start), 'H:mm PP', {locale: ru})}</b> по <b>{discount.without_end ? ' ...' : format(new Date(discount.datetime_end), 'H:mm PP', {locale: ru})}</b></Typography>)
            case 'active':
                return (<Typography
                    className={classes.contentColumn}>{discount.active ? 'Активна' : 'Не активна'}</Typography>)
            default:
                return discount[column['id']]
        }
    }

    return discounts.data ? (
            <Grid item className={classes.content}>
                <Paper>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item className={classes.fullWidth}>
                            <Grid container className={classes.container} direction="column" justify="space-between"
                                  alignItems="stretch">
                                <Grid item className={classes.inputContent}>
                                    <TableContainer className={classes.tableContainer}>
                                        <Table ref={tableRef} stickyHeader aria-label="sticky table"
                                               className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{
                                                                minWidth: column.minWidth
                                                            }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {discounts && discounts.data.map((discount, index) => (
                                                    <TableRow
                                                        className={classes.tableRow}
                                                        hover
                                                        key={index}
                                                        onClick={() => {
                                                            history.push(`/supplier/cumulativeDiscounts/edit?id=${discount.id}`)
                                                        }}
                                                    >
                                                        {columns.map((column, idx) => (
                                                            <TableCell key={idx}>
                                                                {getColumnContent(column, discount)}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                {discounts.data &&
                                    <Grid item className={classes.footer}>
                                        <Grid container direction="column" justify="center" alignItems="stretch"
                                              spacing={1}>
                                            <Grid item className={classes.footerContent}>
                                                <Grid container className={classes.footerContainer} direction="row"
                                                      justify="space-between" alignItems="center" spacing={2}>
                                                    <Grid item>
                                                        <TablePagination
                                                            rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                                            component='div'
                                                            labelRowsPerPage={'Записей на странице:'}
                                                            count={discounts.meta.total}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            onChangePage={handleChangePage}
                                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Submit
                                                            disableElevation
                                                            variant="contained"
                                                            color="primary"
                                                            type="button"
                                                            component={Route}
                                                            className={classes.sendOffer}
                                                            to={`/supplier/cumulativeDiscounts/add`}
                                                        >
                                                            Создать скидку
                                                        </Submit>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        ) : null
}
