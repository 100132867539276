import React, {useState} from "react"
import {useSelector} from "react-redux"

import {
    Grid
} from "@material-ui/core"

import {Submit} from "../../../../App/components/Button/Submit";
import {AuthorizationService} from "../../../../Auth/services/authorization";
import {status} from "../../../dics/Contract/status";
import {Create} from "../AdditionalAgreement/Create";
import {Items} from "../AdditionalAgreement/Items";

export const AdditionalAgreement = (props) => {
    const {account} = useSelector(state => state.account)

    const { contract, settings, onClick } = props

    const [dialog, setDialog] = useState(false)

    const access = (
        AuthorizationService.roles(account, ['customer']) &&
        !contract.framework &&
        ((contract.offer.lot.order.form === 'request') && settings?.additional_agreements.request) &&
        (
            (settings?.condition_execution_contract.customers.find(item => item.customer.id === contract.company.id)?.condition ?? settings?.condition_execution_contract.common) <=
            (100 - ((((Number(contract.total_price) - Number(contract.orders_sum) > 0) ? Number(contract.total_price) - Number(contract.orders_sum) : 0) * 100) / Number(contract.total_price)))
        ) &&
        [status.ACTIVE, status.NOT_ACTIVE].includes(contract.status.index)
    )

    return access ? (
        !contract.agreement
            ? <Grid item>
                <Submit
                    disableElevation
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => {
                        setDialog(true)
                    }}
                >
                    Заключить доп.соглашение
                </Submit>
                {dialog &&
                    <Create
                        contract={contract}
                        open={dialog}
                        onClose={() => setDialog(false)}
                        onClick={onClick}
                    />
                }
            </Grid>
        : <Grid item>
            <Submit
                disableElevation
                variant="contained"
                color="primary"
                type="button"
                onClick={() => {
                    setDialog(true)
                }}
            >
                Редактировать спецификацию
            </Submit>
            {dialog &&
                <Items
                    settings={settings}
                    contract={contract}
                    open={dialog}
                    onClose={() => setDialog(false)}
                    onClick={() => onClick()}
                />
            }
        </Grid>
    ) : null
}
