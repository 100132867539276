import React, {useEffect} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"

import {Checkbox, FormControlLabel, Grid, makeStyles, Paper, Tab, Tabs, Typography,} from "@material-ui/core"

import {LegalDetail} from "../Tabs/Panels/LegalDetail"
import {TabPanel} from "../Tabs/TabPanel"
import {CompanyActions} from "../../actions/company"
import {CompanyActions as AccountCompanyActions} from "../../../Account/actions/company"
import {Employees} from "../Tabs/Panels/Employees"
import {Prices} from "../Tabs/Panels/Prices"
import {SystemActions} from "../../../App/actions/system"
import {ContactInformation} from "../Tabs/Panels/ContactInformation"
import {Warehouses} from "../../../Account/components/Tabs/Panels/Warehouses"
import {DialogWithChoices} from "../../../App/components/DialogWithChoices/DialogWithChoices"
import {AuthorizationService} from "../../../Auth/services/authorization"
import {Offers} from "./Offers"
import * as AppStorage from "../../../App/helpers/storage"
import {Tooltip} from "../../../App/components/Tooltip";
import {Invoices} from "../Tabs/Panels/Invoices";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        width: "100%",
    },
    full: {
        width: "100%",
    },
    title: {
        "font-size": "20px",
        color: "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        overflow: "hidden",
        "text-overflow": "ellipsis",
    },
    header: {
        width: "100%",
        padding: "20px 29px 8px 29px !important",
    },
    input: {
        width: "100%",
    },
    tab: {
        width: "100%",
        padding: "20px 30px 0",
    },
    column: {
        height: "86px",
    },
}));

export const Content = () => {
    let {id} = useParams()
    const dispatch = useDispatch()
    const classes = useStyles()
    const {supplier, cities, banks} = useSelector((state) => {
        return {...state.companies, ...state.system}
    })
    const {okved} = useSelector((state) => {
        return {...state.company}
    })
    const {account} = useSelector((state) => state.account)
    const [loading, setLoading] = React.useState(false)
    const [dirty, setDirty] = React.useState(false)
    const [dialog, setDialog] = React.useState(false)
    const [value, setValue] = React.useState(1)
    const [active, setActive] = React.useState(false)
    const [certified, setCertified] = React.useState(false)

    useEffect(() => {
        const getSupplier = async () => {
            AppStorage.remove('price_token')
            return await dispatch(CompanyActions.supplier(id, {
                include: "legalDetail.city.region.country,legalDetail.executiveDirector,legalDetail.okpds.okved,legalDetail.city.region.country,legalDetail.passportDetails,contactInformation,users.roles,paymentDetail,termsParticipation.regions.region,creator.company.legalDetail,occupations,status,users.permissions",
            }))
        }

        const getData = async (company) => {
            await dispatch(SystemActions.banks())
            await dispatch(AccountCompanyActions.okved())
            await dispatch(AccountCompanyActions.occupations())
            await dispatch(SystemActions.cities({country: company.legal_detail.city.region.country.code}))
        }

        if (!loading) {
            getSupplier().then((supplier) => {
                getData(supplier).then((_) => {
                    setLoading(true);
                })
                if (supplier.tokens.price) {
                    AppStorage.set('price_token', supplier.tokens.price)
                }
                setActive(!supplier.active)
                setCertified(supplier.certified ?? false)
            });
        }
    }, [dispatch, loading, id]);

    const handleChange = (event, newValue) => {
        if (dirty) {
            setDialog({value: newValue})
        } else {
            setValue(newValue);
        }
    };

    return (supplier && loading) ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.full}>
                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                            <Grid item className={classes.fullWidth}>
                                <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                    <Grid item className={classes.header}>
                                        <Grid container className={classes.fullWidth} direction="row" justify="space-between" alignItems="stretch">
                                            <Grid item className={classes.fullWidth} xs={4}>
                                                <Typography className={classes.title} noWrap>
                                                    {supplier.legal_detail.name}
                                                </Typography>
                                            </Grid>
                                            <Grid>
                                                <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                    <Grid item>
                                                        <Tooltip
                                                            placement="bottom-start"
                                                            condition={!supplier.active}
                                                            title={!supplier.active ? (
                                                                <Grid item>
                                                                    <Typography variant="body2">Создан пользователем: {supplier.creator.login}</Typography>
                                                                    {supplier.creator.company && (
                                                                        <Typography variant="body2">Из организации: {supplier.creator.company.legal_detail.name}</Typography>
                                                                    )}
                                                                </Grid>
                                                            ) : null}
                                                        >
                                                            <FormControlLabel
                                                                value={active}
                                                                checked={active}
                                                                control={<Checkbox color="primary" />}
                                                                label="Постфактум"
                                                                onChange={(event) => {
                                                                    const checked = event.target.checked;
                                                                    setActive(checked);
                                                                    dispatch(AccountCompanyActions.active(supplier.id, {active: checked})).then(() => {
                                                                        setLoading(false)
                                                                    })
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            value={certified}
                                                            disabled={AuthorizationService.roles(account, "operator")}
                                                            checked={certified}
                                                            control={<Checkbox color="primary" />}
                                                            label="Сертифицирован"
                                                            onChange={(event) => {
                                                                const checked = event.target.checked
                                                                setCertified(checked)
                                                                dispatch(AccountCompanyActions.certified(supplier.id, {certified: checked})).then(
                                                                    () => {}
                                                                )
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.input}>
                                        <Grid container direction="column" justify="center" alignItems="stretch">
                                            <Grid item>
                                                <Tabs
                                                    value={value}
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                    onChange={handleChange}
                                                >
                                                    <Tab label="Контактная информация" />
                                                    <Tab label="Юридические реквизиты" />
                                                    <Tab label="Учетные записи" />
                                                    <Tab label="Счета" />
                                                    <Tab label="Прайс-листы" />
                                                    {(AuthorizationService.roles(account, "supplier") || AuthorizationService.roles(account, "admin")) &&
                                                        <Tab label="Складские помещения" />
                                                    }
                                                    {AuthorizationService.roles(account, "admin") &&
                                                        <Tab label="Ценовые предложения" />
                                                    }
                                                </Tabs>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <TabPanel value={value} index={0}>
                                <ContactInformation company={supplier} isSupplier={true}/>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <LegalDetail banks={banks} cities={cities} okved={okved} company={supplier} isSupplier={true} setLoading={setLoading}/>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Employees company={supplier} isSupplier={true}/>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <Invoices company={supplier}/>
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <Prices company={supplier}/>
                            </TabPanel>
                            <TabPanel value={value} index={5}>
                                <Warehouses companyId={supplier.id} setDirty={setDirty} isAdmin={true}/>
                            </TabPanel>
                            <TabPanel value={value} index={6}>
                                <Offers supplierId={supplier.id}/>
                            </TabPanel>
                        </Grid>
                        <DialogWithChoices
                            open={!!dialog}
                            onClose={() => {
                                setDialog(false)
                            }}
                            onContinue={() => {
                                setDirty(false)
                                setDialog(false)
                                setValue(dialog.value);
                            }}/>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    ) : null
}
