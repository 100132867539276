import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce} from 'use-debounce'
import clsx from "clsx"
import format from "date-fns/format"
import {IntegratedSummary} from '@devexpress/dx-react-grid'
import {Table} from "@devexpress/dx-react-grid-material-ui";

import {
    Dialog,
    DialogTitle,
    Grid,
    InputAdornment,
    makeStyles,
    TextField,
    Tooltip,
} from "@material-ui/core"
import {
    Error as ErrorIcon,
    GetApp as GetAppIcon,
    Search as SearchIcon
} from "@material-ui/icons"
import {green} from "@material-ui/core/colors"

import {ColumnChooser} from "../../../../App/components/Table/ColumnChooser"
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon"
import {Submit} from "../../../../App/components/Button/Submit"
import {history} from "../../../../App/helpers/history"
import {PurchaseActions} from "../../../actions/purchase"
import {AuthorizationService} from "../../../../Auth/services/authorization"
import {getMonthName} from "../../../../App/helpers/date"
import {ChatActions} from '../../../actions/comment/chat';
import {SettingsActions} from "../../../../Settings/actions/settings"
import {getFullName} from "../../../../App/helpers/user"
import {Approve as ApproveButton} from "../../Purchase/Buttons/Approve"
import {DownloadActions} from "../../../../Download/actions/download";
import {Create as CreateButton} from "../../Purchase/Buttons/Create";
import {Delete as DeleteButton} from "../../Purchase/Buttons/Delete";
import {Decline as DeclineButton} from "../../Purchase/Buttons/Decline";
import {Correct as CorrectButton} from "../../Purchase/Buttons/Correct";
import {Copy as CopyButton} from "../../Purchase/Buttons/Copy";
import {Edit as EditButton} from "../../Purchase/Buttons/Edit";
import {CustomTable} from "../../../../App/components/Table/Table";
import {TableHeader} from "../../../../App/components/Table/TableHeader";
import {localState} from "../../../../App/components/LocalState";
import {Publish} from "../../Purchase/Buttons/Publish";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.planningContent.width,
        padding: theme.planningContent.padding,
        margin: theme.planningContent.margin,
        position: 'relative',
    },
    container: {
        "background-image": "linear-gradient(to bottom,#f6f6f6,#eee 85%,#b1b1b1)",
        "width": "100%",
        "height": "calc(100vh - 256px)"
    },
    title: {
        "font-size": "19px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    filterLabel: {
        "background": "#fff",
        "margin-bottom": "0 !important",
        "margin-top": "5px !important",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        },
        "& input": {
            padding: "6px 15px 7px"
        }
    },
    tableContainer: {
        "min-height": "100%",
        "height": "calc(100vh - 400px)"
    },
    tableItemsContainer: {},
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    searchLabel: {},
    searchInput: {
        "width": "calc(100% - 83px)"
    },
    searchButton: {
        "margin-bottom": "10px"
    },
    fullWidth: {
        "width": "100%"
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 414px)",
        "min-height": "calc(100vh - 414px)",
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    inputContentProducts: {
        "width": "100%",
        "height": "calc(100vh - 424px)",
        "min-height": "calc(100vh - 424px)",
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    footer: {
        width: "100%",
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    },
    tabs: {
        "&.MuiTab-textColorPrimary.Mui-selected": {
            backgroundColor: "#fff",
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "4px",
        }
    },
    fontSubtitle: {
        fontWeight: 700
    },
    submit: {
        "height": "35px",
    },
    accordion: {
        "& .MuiAccordionSummary-content": {
            "max-width": "calc(100% - 32px)"
        }
    },
    acDetails: {
        padding: "16px 0"
    },
    active: {
        'height': '41px',
        'background-color': `${green[100]} !important`,
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        fontSize: '16px',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    disabled: {
        'height': '41px',
        'background-color': 'rgba(244, 244, 244, 1)',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    iconButton: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    },
    backIconButton: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        position: 'absolute',
        left: '-8px',
        top: '-15px',
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    },
    svg: {
        "fill": "#d9534f",
        "width": "auto",
        "height": "31px",
        "stroke": "#fff",
        "fill-rule": "evenodd",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-miterlimit": "2",
        "stroke-width": "0px",
    },
    tooltipError: {
        "font-size": "16px",
        "background": "#d9534f",
    },
    badge: {
        top: "-5px",
        left: "2px"
    },
    titleDialog: {
        "&>h2": {
            color: "#fff"
        },
    },
    circle: {
        width: "25px",
        height: "25px",
        backgroundColor: "#ea4a4a",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        "&>span": {
            lineHeight: "20px"
        }
    },
    lightTooltip: {
        "font-size": "0.8rem",
        "backgroundColor": "white",
        "border": "1px solid #ededed",
        "color": "rgba(0, 0, 0, 0.87)",
    },
    header: {
        height: '116px',
        maxHeight: '116px',
        overflow: "hidden"
    }
}))

const columns = [
    {name: 'comment_counter', title: ' '},
    {name: 'number', title: 'Номер'},
    {name: 'name', title: 'Наименование'},
    {name: 'category', title: 'Категория', filter: 'categories'},
    {name: 'subcategory', title: 'Подкатегория', filter: 'subcategories'},
    {name: 'group', title: 'Группа', filter: 'groups'},
    {name: 'status', title: 'Статус', filter: 'status'},
    {name: 'publish_status', title: 'Статус публикации', filter: 'publish_status'},
    {name: 'edit_request_status', title: 'Статус корректировки', filter: 'edit_request_status'},
    {name: 'version', title: 'Версия'},
    {name: 'planning_year', title: 'Планируемый год', filter: 'year'},
    {name: 'month', title: 'Планируемый месяц проведения', filter: 'month'},
    {name: 'planing_date_to_publish_notification', title: 'План. срок размещения извещения'},
    {name: 'planning_contract_end', title: 'План. срок окончания дей-ия договора'},
    {name: 'conduction_method', title: 'Способ проведения', filter: 'methods'},
    {name: 'funding_sources', title: 'Источник финансирования'},
    {name: 'type', title: 'Вид закупки'},
    {name: 'address', title: 'Адрес закупки'},
    {name: 'items_count', title: 'Количество ОЗ'},
    {name: 'average_price', title: 'Сумма'},
    {name: 'railRoad', title: 'ЖД', filter: 'rail_roads'},
    {name: 'company', title: 'ЧУЗ', filter: 'companies'},
    {name: 'created', title: 'Дата создания', filter: 'created'},
    {name: 'updated', title: 'Дата редактирования'},
    {name: 'creator', title: 'Создатель', filter: 'creator'}
]

export const Purchases = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { plan, setChat, setOpenComment } = props

    const {account} = useSelector(state => state.account)
    const [purchases, setPurchases] = useState({
        ...localState,
        agg_info: {
            items_count: 0,
            average_price: 0,
        }
    })
    const [meta, setMeta] = useState(localState.meta)
    const tableName = 'planning_purchase_plan_purchases_purchases';
    const {filter} = useSelector(state => ({...state.filters[tableName]}))
    const {planningSettings} = useSelector(state => state)
    const [dialog, setDialog] = useState(false);
    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [initialize, setInitialize] = useState(false)

    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)

    const [tableColumnExtensions] = useState([
        {columnName: 'amount', align: 'right'},
        {columnName: 'total', align: 'right'},
    ]);

    const [totalSummaryItems] = useState([
        {columnName: 'items_count', type: 'items_count'},
        {columnName: 'average_price', type: 'average_price'},
    ]);

    const messages = {
        items_count: 'Кол-во',
        average_price: 'Итого',
    };

    const [selection, setSelection] = useState([])
    const [selectionItems, setSelectionItems] = useState([])
    const [selectionPage, setSelectionPage] = useState({})

    const [tableRef, setTableRef] = useState(null)
    const [loading, setLoading] = useState(false)

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'comment_counter', width: 50},
        {columnName: 'number', width: 150},
        {columnName: 'name', width: 200},
        {columnName: 'category', width: 200},
        {columnName: 'subcategory', width: 200},
        {columnName: 'group', width: 200},
        {columnName: 'status', width: 125},
        {columnName: 'publish_status', width: 200},
        {columnName: 'edit_request_status', width: 200},
        {columnName: 'version', width: 125},
        {columnName: 'planning_year', width: 125},
        {columnName: 'month', width: 125},
        {columnName: 'planing_date_to_publish_notification', width: 125},
        {columnName: 'planning_contract_end', width: 125},
        {columnName: 'conduction_method', width: 125},
        {columnName: 'funding_sources', width: 200},
        {columnName: 'type', width: 125},
        {columnName: 'address', width: 125},
        {columnName: 'items_count', width: 125},
        {columnName: 'average_price', width: 155},
        {columnName: 'railRoad', width: 125},
        {columnName: 'company', width: 150},
        {columnName: 'created', width: 150},
        {columnName: 'updated', width: 150},
        {columnName: 'creator', width: 150}
    ])

    const [columnOrder, setColumnOrder] = useState([
        'comment_counter',
        'number',
        'name',
        'category',
        'subcategory',
        'group',
        'status',
        'publish_status',
        'edit_request_status',
        'version',
        'planning_year',
        'month',
        'planing_date_to_publish_notification',
        'planning_contract_end',
        'conduction_method',
        'funding_sources',
        'type',
        'address',
        'items_count',
        'average_price',
        'railRoad',
        'company',
        'created',
        'updated',
        'creator'
    ])

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...((filter.hasOwnProperty('companies') && !!filter.companies.length) ? {companies: filter.companies} : {}),
            ...((filter.hasOwnProperty('rail_roads') && !!filter.rail_roads.length) ? {rail_roads: filter.rail_roads} : {}),
            ...((filter.hasOwnProperty('categories') && !!filter.categories.length) ? {categories: filter.categories} : {}),
            ...((filter.hasOwnProperty('subcategories') && !!filter.subcategories.length) ? {subcategories: filter.subcategories} : {}),
            ...((filter.hasOwnProperty('groups') && !!filter.groups.length) ? {groups: filter.groups} : {}),
            ...((filter.hasOwnProperty('types') && !!filter.types.length) ? {purchase_types: filter.types} : {}),
            ...((filter.hasOwnProperty('month') && !!filter.month.length) ? {month: filter.month} : {}),
            ...((filter.hasOwnProperty('methods') && !!filter.methods.length) ? {methods: filter.methods} : {}),
            ...((filter.hasOwnProperty('sources') && !!filter.sources.length) ? {sources: filter.sources} : {}),
            ...((filter.hasOwnProperty('year') && !!filter.year.from.length) ? {planning_year_from: filter.year.from} : {}),
            ...((filter.hasOwnProperty('year') && !!filter.year.to.length) ? {planning_year_to: filter.year.to} : {}),
            ...((filter.hasOwnProperty('status') && !!filter.status.length) ? {status: filter.status} : {}),
            ...((filter.hasOwnProperty('publish_status') && !!filter.publish_status.length) ? {publish_status: filter.publish_status} : {}),
            ...((filter.hasOwnProperty('edit_request_status') && !!filter.edit_request_status.length) ? {edit_request_status: filter.edit_request_status} : {}),
            ...(searchRequest ? {search: searchRequest} : {})
        }
    }

    const getPurchases = async () => {
        let params = {
            limit: rowsPerPage,
            page: page,
            simple: true,
            ...getParams()
        }

        return await dispatch(PurchaseActions.purchasesByPurchasePlanID(plan.id, params))
    }

    useEffect(() => {
        if (!loading) {
            const getTotal = async () => {
                let params = {
                    limit: rowsPerPage,
                    page: page,
                    ...getParams()
                }
                return await dispatch(PurchaseActions.totalByPurchasePlanID(plan.id, params))
            }

            getTotal().then(response => {
                setMeta({
                    ...meta,
                    ...response
                })
            })

            getPurchases().then(response => {
                setPurchases(response)

                let numbers = []

                if (selectionPage.hasOwnProperty(page)) {
                    response.data.forEach((item, index) => {
                        if (selectionPage[page].find(id => (id === item.id))) {
                            numbers.push(index)
                        }
                    })
                }

                setSelection(numbers)

                tableRef && tableRef.current && tableRef.current.scrollIntoView()
                setLoading(true)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loading])

    useEffect(() => {
        if (loading) {
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [page, rowsPerPage, filter, searchRequest])

    const summaryCalculator = (type, rows, getValue) => {
        switch (type) {
            case 'items_count':
                return (purchases.agg_info.items_count ? parseFloat(purchases.agg_info.items_count) : 0).toLocaleString('ru-RU');
            case 'average_price':
                return (purchases.agg_info.average_price ? Number(purchases.agg_info.average_price) : 0).toLocaleString('ru-RU', {
                    style: 'currency',
                    currency: 'RUB'
                });
            default:
                return IntegratedSummary.defaultCalculator(type, rows, getValue);
        }
    };

    const getRowStyle = (row) => {
        let warning = false

        if (row.notification) {
            if (row.notification.editing_after_rejection && AuthorizationService.roles(account, ['customer'])) {
                warning = true
            }
        }

        return warning ? {backgroundColor: '#d9534f'} : {}
    }

    const createChat = async (values) => {
        return await dispatch(ChatActions.create(values))
    }

    const addUserToChat = async (chatId) => {
        return await dispatch(ChatActions.addUserToChat(chatId))
    }

    useEffect(() => {
        const getSettings = async () => {
            await dispatch(SettingsActions.table(tableName)).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                setInitialize(true)
            })
        }

        if (!initialize) {
            getSettings().then(() =>
                setInitialize(true)
            )
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: tableName,
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: 'PLANNING_PURCHASE_PLAN_PURCHASES_PURCHASES_FILTER', payload: {limitRows: newRowsPerPage}})
    }

    return (initialize && plan) ? (
        <React.Fragment>
            <Grid item xs={12}>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                            {dialog &&
                                <Grid item>
                                    <Dialog
                                        open={!!dialog}
                                        onClose={() => {
                                            setDialog(false)
                                        }}
                                        fullWidth={true}
                                        maxWidth={'sm'}
                                    >
                                        <DialogTitle className={classes.titleDialog}>
                                            {dialog.title}
                                        </DialogTitle>
                                        {dialog.content}
                                    </Dialog>
                                </Grid>
                            }
                            {Boolean(!!selectionItems.length && !!selectionItems.filter(purchase => {
                                return ((planningSettings.categories_id_approve_unapproved_standards.indexOf(plan.category_id) === -1) || purchase.lock)
                            }).length) ? (
                                <Grid item>
                                    <Tooltip
                                        classes={{
                                            tooltip: classes.tooltipError
                                        }}
                                        title="Утверждение закупки с неутвержденными эталонами запрещено. Обратитесь к администратору портала."
                                        placement="top-start">
                                        <ErrorIcon className={classes.svg}/>
                                    </Tooltip>
                                </Grid>
                            ) : null}
                            {AuthorizationService.permissions(account, 'publish_purchases') && <Publish
                                items={selectionItems}
                                onClick={() => {
                                    setSelection([])
                                    setSelectionItems([])
                                    setSelectionPage({})
                                    setLoading(false)
                                }}
                            />}
                            <CorrectButton
                                items={selectionItems}
                                onClick={() => {
                                    setSelection([])
                                    setSelectionItems([])
                                    setSelectionPage({})
                                    setLoading(false)
                                }}
                            />
                            <ApproveButton
                                items={selectionItems}
                                settings={planningSettings}
                                onClick={() => {
                                    setSelection([])
                                    setSelectionItems([])
                                    setSelectionPage({})
                                    setLoading(false)
                                }}
                            />
                            <DeclineButton
                                items={selectionItems}
                                settings={planningSettings}
                                onClick={() => {
                                    setSelection([])
                                    setSelectionItems([])
                                    setSelectionPage({})
                                    setLoading(false)
                                }}
                            />
                            <EditButton
                                disabled={(selectionItems.length !== 1)}
                                purchase={selectionItems[0]}
                            />
                            <CreateButton plan={plan}/>
                            <Grid item>
                                <Submit
                                    className={classes.submit}
                                    disableElevation
                                    disabled={Boolean(selectionItems.length !== 1)}
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    onClick={() => {
                                        const [first] = selectionItems

                                        if (first.chat_id) {
                                            let chat_id = first.chat_id
                                            addUserToChat(chat_id).then(() => {
                                                setChat({
                                                    id: chat_id,
                                                    title: `Комментарии к закупке ${first.name}`
                                                })
                                                setOpenComment(true)
                                            })
                                        } else {
                                            createChat({purchaseId: first.id}).then(resp => {
                                                setChat({
                                                    id: resp.chat.id,
                                                    title: `Комментарии к закупке ${first.name}`
                                                })
                                                setOpenComment(true)
                                            })
                                        }
                                    }}
                                >
                                    Комментарии
                                </Submit>
                            </Grid>
                            <CopyButton
                                items={selectionItems}
                                onClick={() => {
                                    setSelection([])
                                    setSelectionItems([])
                                    setSelectionPage({})
                                    setLoading(false)
                                    dispatch({type: 'PURCHASE_PLAN_ITEMS_BY_PARENT_IDS_CLEAR'})
                                }}
                            />
                            <DeleteButton
                                items={selectionItems}
                                onClick={() => {
                                    setSelection([])
                                    setSelectionItems([])
                                    setSelectionPage({})
                                    setLoading(false)
                                }}
                            />
                            <Grid item>
                                <ButtonIcon
                                    type="button"
                                    size="small"
                                    disabled={!meta.total}
                                    onClick={() => {
                                        dispatch(DownloadActions.create({
                                            type: 'planning_plan_purchases',
                                            columns: columnOrder.filter(name => (hiddenColumnNames.indexOf(name) < 0)),
                                            params: {
                                                order: (filter.sort.name && filter.sort.direction) ? `${filter.sort.name}, ${filter.sort.direction}` : `created, desc`,
                                                type: 'planning_plan_purchases',
                                                'purchase_plan_id': plan.id,
                                                ...Object.entries(getParams()).reduce((result, [key, value]) => {
                                                    result[key] = Array.isArray(value) ? value.join(',') : value

                                                    return result
                                                }, {})
                                            }
                                        }))
                                    }}
                                    children={<GetAppIcon/>}
                                    title='Скачать таблицу'
                                    className={classes.iconButton}
                                />
                            </Grid>
                            <Grid item>
                                <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="search"
                            variant="standard"
                            name={'search'}
                            placeholder={`Поиск: номер закупки`}
                            fullWidth={true}
                            margin={'normal'}
                            onChange={(event) => {
                                setSearch(event.target.value)
                            }}
                            classes={{
                                root: clsx(classes.filterLabel, classes.searchLabel)
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon style={{color: '#c1c1c1'}}/>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <CustomTable
                meta={meta}
                tableName={tableName}
                rows={purchases.data.map(purchase => ({
                    number: purchase.number,
                    name: purchase.name,
                    category: purchase.category?.dependencies[0],
                    subcategory: purchase.category?.dependencies[1],
                    group: purchase.category?.dependencies[2],
                    status: purchase.status.name,
                    publish_status: purchase.publishStatus?.name,
                    edit_request_status: purchase.editRequestStatus !== null ? purchase.editRequestStatus.name : null,
                    version: purchase.version,
                    planning_year: purchase.purchase_plan ? purchase.purchase_plan.planning_year : '',
                    month: purchase.min_demand_start_date === null ? '-' : getMonthName(new Date(purchase.min_demand_start_date)),
                    planing_date_to_publish_notification: purchase.planing_date_to_publish_notification === null ? '-' : format(new Date(purchase.planing_date_to_publish_notification), 'dd.MM.yyyy'),
                    planning_contract_end: purchase.max_execution_month === null ? '-' : format(new Date(purchase.max_execution_month), 'dd.MM.yyyy'),
                    conduction_method: purchase.conduction_method ? purchase.conduction_method.name : null,
                    funding_sources: purchase.funding_sources ? JSON.parse(purchase.funding_sources).join(', ') : '',
                    type: purchase.type.name,
                    address: purchase.territories.map(territory => territory.name).join(', '),
                    items_count: parseFloat(purchase.items_count === null ? 0 : purchase.items_count).toLocaleString('ru-RU'),
                    average_price: parseFloat(purchase.average_price === null ? 0 : purchase.average_price).toLocaleString('ru-RU', {
                        style: 'currency',
                        currency: 'RUB',
                    }),
                    railRoad: purchase.company?.railRoad?.name,
                    company: purchase.company.name,
                    created: format(new Date(purchase.created), 'dd.MM.yyyy'),
                    updated: format(new Date(purchase.updated), 'dd.MM.yyyy'),
                    creator: purchase.user ? getFullName(purchase.user) : null,
                    count_unread_messages: purchase.count_unread_messages,
                    notification: purchase.notification,
                    chat_id: purchase.chat_id,
                    unlabeled_standards: purchase.unlabeled_standards
                }))}
                columns={plan.category.active ? [...columns, {
                    name: 'unlabeled_standards',
                    title: 'Счетчик неразмеченных эталонов'
                }] : columns}
                page={{page: page, setPage: setPage, rowsPerPage: rowsPerPage, handlePageSizeChange: handlePageSizeChange}}
                selection={{selection: selection, setSelection: setSelection}}
                totalSummaryItems={totalSummaryItems}
                summaryCalculator={summaryCalculator}
                setTableRef={setTableRef}
                tableColumnExtensions={tableColumnExtensions}
                columnsSettings={{
                    columnOrder: plan.category.active ? [...columnOrder, 'unlabeled_standards'] : columnOrder,
                    setColumnOrder: setColumnOrder,
                    setColumnWidths: setColumnWidths,
                    columnWidths: plan.category.active ? [...columnWidths, {
                        columnName: 'unlabeled_standards',
                        width: 250
                    }] : columnWidths,
                    hiddenColumnNames: hiddenColumnNames,
                    setHiddenColumnNames: setHiddenColumnNames}}
                tableHeader={TableHeader}
                filterActionType={'PLANNING_PURCHASE_PLAN_PURCHASES_PURCHASES_FILTER'}
                getValues={(params) => PurchaseActions.purchasesFilter({plan: plan.id, ...params})}
                onSelectionChange={(numbers) => {
                    setSelection(numbers)
                    const data = purchases.data.filter((item, index) => (numbers.find(key => (key === index)) !== undefined)).map(item => item)
                    setSelectionItems([
                        ...data,
                        ...selectionItems.filter(item => !purchases.data.find(el => el.id === item.id))
                    ])
                    setSelectionPage(!!data.length ? {...selectionPage, [page]: data.map(item => item.id)} : Object.fromEntries(
                        Object.entries(selectionPage).filter(key => (parseInt(key) !== parseInt(page))).map(([key, value]) => [key, value])
                    ))
                }}
                messages={messages}
                classInputContent={classes.inputContent}
                rowComponentSelection={(props) => {
                    const {tableRow, children, highlighted, onToggle} = props
                    const {row} = tableRow

                    let timer = null;
                    let prevent = false;

                    const handleClick = () => {
                        timer = setTimeout(() => {
                            if (!prevent) {
                                if (!window.getSelection().toString()) {
                                    onToggle()
                                }
                            }
                            prevent = false;
                        }, 250);
                    };

                    const handleDoubleClick = () => {
                        clearTimeout(timer);
                        prevent = true;
                        history.push(`/planning/purchase-plan/${purchases.data[tableRow.rowId].purchase_plan_id}/purchase/${purchases.data[tableRow.rowId].id}`)
                    }

                    if (Object.keys(purchases.agg_info.chats_with_count_not_read_comments).length > 0 && row.chat_id && purchases.agg_info.chats_with_count_not_read_comments[row.chat_id]) {
                        row.count_unread_messages = purchases.agg_info.chats_with_count_not_read_comments[row.chat_id]
                    }

                    row['comment_counter'] =
                        <div className={classes.circle}>
                            <span>{row.count_unread_messages}</span>
                        </div>

                    return (
                        <Table.Row
                            tableRow={tableRow}
                            children={children}
                            onClick={handleClick}
                            style={getRowStyle(row)}
                            onDoubleClick={handleDoubleClick}
                            className={highlighted ? classes.active : classes.default}
                            row={row}
                        />
                    )
                }}
                rowComponent={({row, tableRow, children}) => (
                    <Table.Row
                        tableRow={tableRow}
                        children={children}
                        className={classes.default}
                        row={row}
                    />
                )}
                filters={{
                    status: {
                        name: 'status',
                        type: 'values',
                    },
                    publish_status: {
                        name: 'publish_status',
                        type: 'values',
                    },
                    planning_year: {
                        name: 'year',
                        type: 'year',
                    },
                    category: {
                        name: 'categories',
                        type: 'values',
                    },
                    subcategory: {
                        name: 'subcategories',
                        type: 'values',
                    },
                    group: {
                        name: 'groups',
                        type: 'values',
                    },
                    railRoad: {
                        name: 'rail_roads',
                        type: 'values',
                    },
                    company: {
                        name: 'companies',
                        type: 'values',
                    },
                    month: {
                        name: 'month',
                        type: 'month',
                    },
                    edit_request_status: {
                        name: 'edit_request_status',
                        type: 'values',
                    },
                    conduction_method: {
                        name: 'methods',
                        type: 'values',
                    },
                    funding_sources: {
                        name: 'sources',
                        type: 'source',
                    },
                    creator: {
                        name: 'creator',
                        type: 'values',
                    },
                }}
            />
        </React.Fragment>
    ) : null
}
