import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {Grid, makeStyles} from "@material-ui/core"

import {Messenger} from "../Messenger/Messenger"
import {AuthorizationService} from "../Auth/services/authorization"
import {NotificationActions} from "../Notification/actions/notification"
import {CartActions} from "../Cart/actions/cart"
import {Header} from "./Header";

const useStyles = makeStyles(() => ({
    body: {
        "background-image": "linear-gradient(to bottom,#f6f6f6,#eee)"
    },
    container: {
        "height": "calc(100vh - 55px)",
        "max-width": "100% !important",
        "padding": "0",
        position: 'relative'
    }
}))

export const App = ({Content, ...props}) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {account} = useSelector(state => {
        return {...state.account, ...state.application, ...state.messenger}
    })
    const {cart} = useSelector(state => state)
    const {location} = props

    useEffect(() => {
        if (location.pathname.indexOf('planning') !== -1) {
            document.title = 'Планирование'
        } else if (location.pathname.indexOf('marketplace') !== -1) {
            document.title = 'Электронный магазин'
        } else {
            document.title = 'Электронный ордер'
        }
    }, [location]);

    useEffect(() => {
        const getCount = async () => {
            return await dispatch(NotificationActions.count())
        }

        if (AuthorizationService.roles(account, ["customer", "supplier", "operator", "supervisor"])) {
            getCount().then(_ => {
            })
        }
    }, [dispatch, account]);

    useEffect(() => {
        const getCount = async () => {
            return await dispatch(CartActions.count())
        }

        if (AuthorizationService.roles(account, ["customer"]) && !cart.loading) {
            getCount().then(_ => {
            })
        }
    }, [dispatch, account, cart.loading]);

    const [open, setOpen] = useState(false)

    const openMessenger = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(open)
    }

    return (
        <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.body}>
            <Header openMessenger={openMessenger} open={open} {...props} />
            <Grid container direction="row" justify="flex-start" alignItems="stretch" className={classes.container}>
                {AuthorizationService.roles(account, ["customer", "supplier", "operator", "admin", "supervisor", "supplier_confirmed", "customer_confirmed_by_admin"]) && !AuthorizationService.roles(account, 'supplier_blocked') && location &&
                    <Content {...props}/>}
            </Grid>
            {<Messenger open={open} onClose={openMessenger(false)} />}
        </Grid>
    )
}
