import {HttpService} from "../../../../App/services/http"

export const ShopService = {
    orders,
    order,
    add,
    edit,
    cancel,
    deadline,
    status,
    remove,
    approveOffer,
    setEndTimeOfTrading,
    copy,
    sign
}

function orders(params) {
    return HttpService.get("/shop/orders", params)
}

function order(id) {
    return HttpService.get(`/shop/order/${id}`)
        .then(response => {
            return response.order
        })
}

function add(formData) {
    return HttpService.post("/shop/order", formData, true, true)
}

function edit(id, formData) {
    return HttpService.post(`/shop/order/${id}`, formData, true, true)
}

function cancel(id, values) {
    return HttpService.put(`/shop/order/${id}/cancel`, values)
}

function deadline(id, values) {
    return HttpService.put(`/shop/order/${id}/deadline`, values)
}

function status(order_id, lot_id, formData) {
    return HttpService.post(`/shop/order/${order_id}/lot/${lot_id}/status`, formData, true, true)
}

function remove(id) {
    return HttpService.deleteMethod(`/shop/order/${id}`)
}

function approveOffer(id, values) {
    return HttpService.put(`/shop/offer/${id}/approve`, values)
}

function setEndTimeOfTrading(id, values) {
    return HttpService.put(`/shop/lot/${id}/setEndTimeOfTrading`, values)
}

function copy(id, values) {
    return HttpService.post(`/shop/order/${id}/copy`, values)
}

function sign(order, file, values) {
    return HttpService.put(`/shop/order/${order}/file/${file}/sign`, values)
}
