import {HttpService} from "../../App/services/http"

export const InvoiceService = {
    invoices: invoice,
    bill,
    confirm,
    subscription,
    unpaid,
    filter
}

function invoice(params) {
    return HttpService.get("/company/invoices", params)
}

function bill() {
    return HttpService.post("/company/invoice").then(response => response.invoice)
}

function confirm(id) {
    return HttpService.put(`/company/invoice/${id}/confirm`).then(response => response.invoice)
}

function subscription(id) {
    return HttpService.get(`/company/${id}/subscription`)
}

function unpaid(id) {
    return HttpService.get(`/company/${id}/unpaid`).then(response => response.exist)
}

function filter(params) {
    return HttpService.get("/company/invoices/filter", params)
}
