import {HttpService} from "../../App/services/http"

export const StatusService = {
    status
}

function status() {
    return HttpService.get(`/contract/delivery/order/status`)
        .then(response => {
            return response.status
        })
}
