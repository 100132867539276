import {HttpService} from '../../App/services/http'

export const StandardService = {
    dictionary,
    attributes,
    standard,
    allow,
    strategic,
    register
}

function dictionary(type, params) {
    return HttpService.get({
        path: `/dictionary/${type}/items`,
        host: process.env.REACT_APP_SERVICE_NOMENCLATURE_API
    }, params, true)
        .then(response => {
            return response.data
        })
}

function attributes(id, params = {}) {
    return HttpService.get({
        path: `/attribute/${id}/items`,
        host: process.env.REACT_APP_SERVICE_NOMENCLATURE_API
    }, params, true)
        .then(response => {
            return response.data
        })
}

function standard(formData) {
    return HttpService.post({
        path: '/item',
        host: process.env.REACT_APP_SERVICE_NOMENCLATURE_API
    }, formData, false, true)
        .then(response => {
            return response.data
        })
}

function allow(values) {
    return HttpService.put('/standard/allow', values, true)
        .then(response => {
            return response
        })
}

function strategic(values) {
    return HttpService.put('/standard/strategic', values, true)
        .then(response => {
            return response
        })
}

function register(params) {
    return HttpService.get('/standard/register', params, true)
        .then(response => {
            return response.data
        })
}
