import React from "react"
import {useDispatch} from "react-redux"
import {Form, Formik} from "formik"

import {Grid, List, ListItem, DialogContent, DialogTitle, Typography, Dialog, makeStyles} from "@material-ui/core"

import {Submit} from "../../../App/components/Button/Submit"
import {CompanyActions} from "../../actions/company"

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin:  theme.content.margin
    },
    footerContent: {
        width: "100%",
        position: "absolute",
        bottom: 0,
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    dialogContainer: {
        "& > div": {
            "max-width": "calc(100vw - 200px)",
            "min-height": "calc(100vh - 300px)",
            overflow: "hidden",
        }
    },
    dialogTitle: {
        "margin": "0",
        "padding-top": "2px",
        "font-size": "18px",
        "font-weight": "600",
        "color": "#fff",
        "text-transform": "uppercase",
        "display": "flex",
        "justify-content": "space-between",
        "align-items": "center",
        "min-height": "40px",
        "padding": "0 20px",
        "background-color": "#898989",
    },
    dialogContent: {
        padding: 0,
    },
    descriptionWrapper: {
        padding: "40px 50px",
        fontSize: "16px",
    },
    descriptionText: {
        margin: "0 0 15px",
        fontSize: "18px",
    },
    descriptionItem: {
        color: "#485868",
        display: "list-item",
        margin: "0 0 15px",
        fontSize: "18px",
    },
    fullHeight: {
        "height": "100%"
    },
}))

export const ImportantInformation = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {open, setOpen} = props

    const onSubmit = (values, {setSubmitting}) => {
        return dispatch(CompanyActions.approveByAccount(values)).then(
            () => {
                setOpen(false)
            },
            () => {
                setSubmitting(false);
            }
        )
    }

    return <Dialog
        aria-labelledby="simple-dialog-title"
        open={open}
        classes={{
            root: classes.dialog,
            container: classes.dialogContainer
        }}
        fullWidth={true}
        maxWidth={'lg'}
    >
        <DialogTitle
            id="dialog-title"
            classes={{
                root: classes.dialogTitle,
            }}
            disableTypography={true}
        >
            Важная информация
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <Formik
                initialValues={{agree_with_the_important_information_notice: true}}
                onSubmit={onSubmit}
            >
                {({
                      handleSubmit,
                  }) => (
                    <Form className={classes.fullHeight}>
                        <Grid container>
                            <Grid item style={{marginBottom: "70px"}}>
                                <Grid container className={classes.descriptionWrapper}>
                                    <Grid item>
                                        <Typography className={classes.descriptionText}>Добрый день! Вас
                                            приветствует компания Эмсофт. Мы оказываем для сети
                                            здравоохранения ОАО "РЖД" - «РЖД-Медицина» услуги по технической
                                            поддержке и модернизации АСЗ "Электронный Ордер"</Typography>
                                        <Typography className={classes.descriptionText}>Хотим поблагодарить
                                            Вас за использование АСЗ "Электронный Ордер" и предложить новые
                                            возможности работы с системой.</Typography>
                                        <Typography className={classes.descriptionText}>Наша компания
                                            запускает пакет услуг для поставщиков сети «РЖД-Медицина»,
                                            позволяющий ускорить обработку заказов в системе АСЗ
                                            "Электронный Ордер" и разгрузить ваших сотрудников от рутины,
                                            вплоть до полной работы в системе за Вашу компанию!</Typography>
                                        <Typography className={classes.descriptionText}>Сотрудники компании
                                            Эмсофт готовы оказать следующие услуги:</Typography>
                                        <List style={{listStyle: "inside decimal", lineHeight: "5px"}}>
                                            <ListItem className={classes.descriptionItem}>
                                                Оформление доступа в личном кабинете (прохождение
                                                верификации);
                                            </ListItem>
                                            <ListItem className={classes.descriptionItem}>
                                                Управление каталогом;
                                            </ListItem>
                                            <ListItem className={classes.descriptionItem}>
                                                Работа с заказами;
                                            </ListItem>
                                            <ListItem className={classes.descriptionItem}>
                                                Персональный менеджер;
                                            </ListItem>
                                            <ListItem className={classes.descriptionItem}>
                                                Работа в АСЗ "Электронный Ордер" вместо сотрудников Вашей
                                                компании.
                                            </ListItem>
                                        </List>
                                        <Typography className={classes.descriptionText}>А также хотим
                                            представить Вам новейший модуль в "Эко-Системе" закупок
                                            "РЖД-Медицина" - "Электронный магазин", данный модуль является
                                            частью АСЗ "Электронный ордер" и открывает совершенно новые
                                            возможности!</Typography>
                                        <Typography className={classes.descriptionText}>Подключение к модулю
                                            "Электронный Магазин" (маркетплейс с публикацией оферт и ценовых
                                            запросов) позволяет получить дополнительные перспективы для
                                            вашей компании, а именно:</Typography>
                                        <List style={{listStyle: "inside decimal", lineHeight: "5px"}}>
                                            <ListItem className={classes.descriptionItem}>
                                                Получение свободного доступа к закупкам всех учреждений
                                                самой крупной частной сети здравоохранения;
                                            </ListItem>
                                            <ListItem className={classes.descriptionItem}>
                                                Размещение всей вашей продукции и услуг в системе для
                                                публикации оферт в маркетплейсе;
                                            </ListItem>
                                            <ListItem className={classes.descriptionItem}>
                                                На равных конкурентных условиях участвовать в ценовых
                                                запросах и заключать контракты на поставку в любое
                                                учреждение сети онлайн;
                                            </ListItem>
                                            <ListItem className={classes.descriptionItem}>
                                                Предложить уникальные скидки/надбавки, настроенные, через
                                                конструктор скидок с ограничением по региону/учреждению;
                                            </ListItem>
                                            <ListItem className={classes.descriptionItem}>
                                                Повысить свою прибыль, расширив гео-охват и увеличив объём
                                                поставок;
                                            </ListItem>
                                            <ListItem className={classes.descriptionItem}>
                                                Получать аналитику о планируемых закупках сети.
                                            </ListItem>
                                        </List>
                                        <Typography className={classes.descriptionText}>Наши
                                            профессиональные менеджеры помогут Вам:</Typography>
                                        <List style={{listStyle: "inside", lineHeight: "5px"}}>
                                            <ListItem className={classes.descriptionItem}>
                                                Снизить нагрузку на сотрудников;
                                            </ListItem>
                                            <ListItem className={classes.descriptionItem}>
                                                Уменьшить количество некорректных данных и документов в АСЗ
                                                "Электронный ордер";
                                            </ListItem>
                                            <ListItem className={classes.descriptionItem}>
                                                Повысить оперативность внесения данных;
                                            </ListItem>
                                            <ListItem className={classes.descriptionItem}>
                                                Увеличить продуктивность работы с РЖД;
                                            </ListItem>
                                            <ListItem className={classes.descriptionItem}>
                                                Выполнить требования заказчика в части проведения заказов и
                                                работы в АСЗ "Электронный Ордер";
                                            </ListItem>
                                            <ListItem className={classes.descriptionItem}>
                                                Отслеживать интересные Вам ценовые запросы.
                                            </ListItem>
                                        </List>
                                        <Typography className={classes.descriptionText}>В случае наличия
                                            интереса к полученной информации, предоставьте, пожалуйста,
                                            обратную связь на почту support@emsoft.ru или по телефону
                                            +7(495)248-06-46 и мы направим Вам более подробное описание со
                                            стоимостью наших услуг.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.footerContent}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="center"
                                    className={classes.footerContainer}
                                    spacing={2}
                                >
                                    <Grid item>
                                        <Submit
                                            disableElevation
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSubmit}
                                            type="submit"
                                        >
                                            Закрыть
                                        </Submit>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </DialogContent>
    </Dialog>
}
