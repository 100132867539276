import * as AppStorage from "../../App/helpers/storage"

export default function companies(state = {customers: {data: [], meta: {total: 0, per_page: 15, current_page: 1}},railroads: [], filterCustomers: AppStorage.get('filterCustomers') ? JSON.parse(AppStorage.get('filterCustomers')) : {search: null,status: 0}, filterSuppliers: AppStorage.get('filterSuppliers') ? JSON.parse(AppStorage.get('filterSuppliers')) : {search: null,status: 0}, suppliers: {data: [], meta: {total: 0, per_page: 15, current_page: 1}}}, action) {
    switch (action.type) {
        case "CUSTOMERS_REQUEST":
            return state
        case "CUSTOMERS_SUCCESS":
            return {
                ...state,
                ...{customers: action.payload}
            }
        case "CUSTOMERS_FAILURE":
            return state
        case "CUSTOMERS_CLEAR":
            return {
                ...state,
                ...{customers: {data: [], meta: {total: 0, per_page: 15, current_page: 1}}}
            }
        case "CUSTOMER_REQUEST":
            return state
        case "CUSTOMER_SUCCESS":
            return {
                ...state,
                ...{customer: action.payload}
            }
        case "CUSTOMER_FAILURE":
            return state
        case "CUSTOMER_EDIT_REQUEST":
            return state
        case "CUSTOMER_EDIT_SUCCESS":
            return {
                ...state,
                ...{customer: action.payload}
            }
        case "CUSTOMER_EDIT_FAILURE":
            return state
        case "CUSTOMER_FILTER":
            AppStorage.set('filterCustomers', JSON.stringify(action.payload))
            return {
                ...state,
                ...{filterCustomers: action.payload}
            }
        case "SUPPLIERS_REQUEST":
            return state
        case "SUPPLIERS_SUCCESS":
            return {
                ...state,
                ...{suppliers: action.payload},
            }
        case "SUPPLIERS_FAILURE":
            return state
        case "SUPPLIERS_CLEAR":
            return {
                ...state,
                ...{suppliers: {data: [], meta: {total: 0, per_page: 15, current_page: 1}}}
            }
        case "SUPPLIER_REQUEST":
            return state
        case "SUPPLIER_SUCCESS":
            return {
                ...state,
                ...{supplier: action.payload}
            }
        case "SUPPLIER_FAILURE":
            return state
        case "SUPPLIER_EDIT_REQUEST":
            return state
        case "SUPPLIER_EDIT_SUCCESS":
            return {
                ...state,
                ...{supplier: action.payload}
            }
        case "SUPPLIER_EDIT_FAILURE":
            return state
        case "SUPPLIER_FILTER":
            AppStorage.set('filterSuppliers', JSON.stringify(action.payload))
            return {
                ...state,
                ...{filterSuppliers: action.payload}
            }
        case "RAILROADS_SUCCESS":
            return {
                ...state,
                ...{railroads: action.payload.railroads}
            }
        default:
            return state
    }
}
