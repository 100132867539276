import {EditRequestService} from "../services/editRequest"

export const EditRequestActions = {
    requestForEdit,
    sendForCorrection
}

function requestForEdit(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASES_REQUEST_FOR_EDIT_REQUEST"})

        EditRequestService.requestForEdit(values)
            .then(
                () => {
                    dispatch({type: "PURCHASES_REQUEST_FOR_EDIT_SUCCESS"})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Запрос на корректировку отправлен',
                            type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASES_REQUEST_FOR_EDIT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function sendForCorrection(ids) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        dispatch({
            type: "ALERT_SUCCESS", payload: {
                message: 'Закупка отправлена на корректировку',
                type: 'success'
            }
        })

        EditRequestService.sendForCorrection(ids)
            .then(_ => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                }, error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}
