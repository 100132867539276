import {HttpService} from "../../App/services/http"

export const DownloadService = {
    files,
    create
}

function files(params) {
    return HttpService.get("/downloads", params)
}

function create(values) {
    return HttpService.post(`/download/file`, values)
}
