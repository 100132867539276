import {MessengerService} from "../services/messenger"

export const MessengerActions = {
    chats,
    messages,
    direct,
    create,
    users,
    addUser,
    deleteUser
}

function chats(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "MESSENGER_CHATS_REQUEST"})

        MessengerService.chats(params)
            .then(
                chats => {
                    dispatch({type: "MESSENGER_CHATS", payload: chats})
                    resolve(chats)
                },
                error => {
                    dispatch({type: "MESSENGER_CHATS_FAILURE"})
                    reject(error)
                }
            )
    })
}

function messages(id, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "MESSENGER_CHAT_MESSAGES_REQUEST"})

        MessengerService.messages(id, params)
            .then(
                messages => {
                    dispatch({type: "MESSENGER_CHAT_MESSAGES", payload: messages})
                    resolve(messages)
                },
                error => {
                    dispatch({type: "MESSENGER_CHAT_MESSAGES_FAILURE", payload: messages})
                    reject(error)
                }
            )
    })
}

function direct(id) {
    return dispatch => new Promise((resolve, reject) => {
        MessengerService.direct(id)
            .then(
                chat => {
                    resolve(chat)
                },
                error => {
                    reject(error)
                }
            )
    })
}

function create(values) {
    return dispatch => new Promise((resolve, reject) => {
        MessengerService.create(values)
            .then(
                chat => {
                    resolve(chat)
                },
                error => {
                    reject(error)
                }
            )
    })
}

function users(params) {
    return dispatch => new Promise((resolve, reject) => {
        MessengerService.users(params)
            .then(
                users => {
                    resolve(users)
                },
                error => {
                    reject(error)
                }
            )
    })
}

function deleteUser(chatId,userId) {
    return dispatch => new Promise((resolve, reject) => {
        MessengerService.deleteUser(chatId,userId)
            .then(
                chat => {
                    resolve(chat)
                },
                error => {
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )
    })
}

function addUser(chatId,userId) {
    return dispatch => new Promise((resolve, reject) => {
        MessengerService.addUser(chatId,userId)
            .then(
                chat => {
                    resolve(chat)
                },
                error => {
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )
    })
}
