import React, {useEffect, useMemo, useState} from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl, FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select, Typography
} from "@material-ui/core"
import {Submit} from "../App/components/Button/Submit";
import ccpa from "crypto-pro-cadesplugin";
import {signFile} from "./utils/signFile";

const useDoCertsList = () =>
  useMemo(async () => {
    const certsApi = await ccpa();
    const certsList = await certsApi.getCertsList();

    return await Promise.all(certsList.map(async cert => {
      const friendlySubjectInfo = cert.friendlySubjectInfo();
      const friendlyIssuerInfo = cert.friendlyIssuerInfo();
      const friendlyValidPeriod = cert.friendlyValidPeriod();
      const isValid = await cert.isValid();
      const {
        to: { ddmmyy, hhmmss }
      } = friendlyValidPeriod;

      return {
        subjectInfo: friendlySubjectInfo,
        issuerInfo: friendlyIssuerInfo,
        validPeriod: friendlyValidPeriod,
        thumbprint: cert.thumbprint,
        isValid: isValid,
        title: `${
          friendlySubjectInfo.filter(el => el.value === 'Владелец')[0].text
        }. Сертификат действителен до: ${ddmmyy} ${hhmmss}`,
        label: cert.subjectInfo
      };
    }));
  }, []);

export const Signature = (props) => {
  const {open, onClose, files, onContinue, required = true} = props
  const [load, setLoad] = useState(false)
  const [initialize, setInitialize] = useState(false)
  const [thumbprint, setThumbprint] = useState(undefined)
  const [certificates, setCertificates] = useState([]);

  useDoCertsList()
    .then((list) => {
      setCertificates(list)
      setLoad(true)
      setInitialize(true)
    })
    .catch(() => {
      setLoad(false)
      setInitialize(true)
    });

  useEffect(() => {
    if (!open) {
      setThumbprint(undefined)
    }
  }, [open]);

  return open ? (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>Электронная подпись</DialogTitle>
      <DialogContent>
        <Grid container direction="row" justify="center" alignItems="stretch" spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel shrink>Сертификат</InputLabel>
              <Select
                fullWidth
                disabled={!load || !initialize}
                value={thumbprint}
                onChange={(e) => setThumbprint(e.target.value)}
                displayEmpty
                {...(!initialize ? {renderValue: () => {
                  return <Typography color="gray">Загрузка . . .</Typography>;
                }} : {})}
              >
                {certificates.map((item, index) => (
                  <MenuItem key={index} value={item.thumbprint}>{item.title}</MenuItem>
                ))}
              </Select>
              {(!load && initialize) ? <FormHelperText>Для выбора сертификата необходимо установить CryptoPro Extension for CAdES Browser Plug-in</FormHelperText> : null}
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justify="flex-end" alignItems="stretch" spacing={2}>
          {required ? (
            <Grid item>
              <Submit
                variant="contained"
                color="primary"
                type="submit"
                disabled={!files.length}
                onClick={() => {
                  onContinue(files.map(file => ({
                    file: file,
                    sign: null
                  })))
                }}
              >
                Загрузить
              </Submit>
            </Grid>
          ) : null}
          <Grid item>
            <Submit
              variant="contained"
              color="primary"
              type="submit"
              disabled={!thumbprint || !files.length}
              onClick={async () => {
                try {
                  const values = []

                  for (const file of files) {
                    const {sign} = await signFile({thumbprint, file})
                    values.push({
                      file: file,
                      sign: sign
                    })
                  }

                  onContinue(values)
                } catch (e) {
                  onClose()
                }
              }}
            >
              Подписать и загрузить
            </Submit>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  ) : null
}
