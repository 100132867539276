import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import format from "date-fns/format"

import {Button, Grid, makeStyles,} from "@material-ui/core"

import {DownloadActions} from "../../actions/download"
import * as AppStorage from "../../../App/helpers/storage";
import {CustomTable} from "../../../App/components/Table/Table";
import {TableHeader} from "../../../App/components/Table/TableHeader";
import {Table} from "@devexpress/dx-react-grid-material-ui";
import {SettingsActions} from "../../../Settings/actions/settings";
import {ColumnChooser} from "../../../App/components/Table/ColumnChooser";
import {localState} from "../../../App/components/LocalState";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    container: {
        "height": "calc(100vh - 122px)"
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 122px)",
        "min-height": "calc(100vh - 122px)"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
}))

const columns = [
    {name: 'name', title: 'Наименование'},
    {name: 'status', title: 'Статус'},
    {name: 'created', title: 'Дата создания'},
    {name: 'updated', title: 'Дата обновления'},
    {name: 'button', title: ' '},
];

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [files, setFiles] = useState(localState)
    const {filter} = useSelector(state => state.filters['files'])

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [tableRef, useTableRef] = useState(null)
    const [initialize, setInitialize] = useState(false);
    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const [columnOrder, setColumnOrder] = useState([
        'name',
        'status',
        'created',
        'updated',
        'button',
    ])

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'updated', width: 250},
        {columnName: 'created', width: 250},
        {columnName: 'name', width: 200},
        {columnName: 'status', width: 125},
        {columnName: 'button', width: 125},
    ])

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
        }
    }

    useEffect(() => {
        const getFiles = async () => {
            return await dispatch(DownloadActions.files({
                limit: rowsPerPage,
                page: page,
                ...getParams()
            }))
        }

        getFiles().then((response) => {
            setFiles(response)
            tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
        })
    }, [dispatch, page, rowsPerPage, filter]);

    useEffect(() => {
        if (!initialize) {
            dispatch(SettingsActions.table('downloads')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                dispatch({type: "FILTER_CLEAR"})
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'downloads',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: 'FILES_FILTER', payload: {limitRows: newRowsPerPage}})
    }

    return <Grid item className={classes.content}>
        <Grid item style={{marginTop: '-20px', marginBottom: '5px', display: 'flex', justifyContent: 'flex-end'}}>
            <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
        </Grid>
        <CustomTable
            meta={files.meta}
            tableName={'files'}
            rows={files?.data.map(file => ({
                name: file.name,
                status: file.status.name,
                created: format(new Date(file.created), 'dd.MM.yyyy HH:mm'),
                updated: format(new Date(file.updated), 'dd.MM.yyyy HH:mm'),
                button: file.path ?
                    <Button
                        color="primary"
                        type="button"
                        href={process.env.REACT_APP_HOST_API + `/download/file/${file.id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                        download
                    >
                        Скачать
                    </Button> : null,
            }))}
            columns={columns}
            page={{page: page, setPage: setPage, rowsPerPage: rowsPerPage, handlePageSizeChange: handlePageSizeChange}}
            columnsSettings={{
                columnOrder: columnOrder,
                setColumnOrder: setColumnOrder,
                setColumnWidths: setColumnWidths,
                columnWidths: columnWidths,
                hiddenColumnNames: hiddenColumnNames,
                setHiddenColumnNames: setHiddenColumnNames
            }}
            tableHeader={TableHeader}
            filterActionType={'FILES_FILTER'}
            classInputContent={classes.inputContent}
            rowComponent={({row, tableRow, children}) => (
                <Table.Row
                    tableRow={tableRow}
                    children={children}
                    row={row}
                    className={classes.tableRow}
                    hover
                />
            )}
            filters={{
                button: {
                    nullButton: true
                }
            }}
        />
    </Grid>
}
