import React, {useEffect, useRef} from "react"

import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@material-ui/core"

import {round, toFloatWithCurrency} from "../../../App/helpers/number"
import {AddToCart} from "./ProductsTable/AddToCart"
import {Positions} from "./ProductsTable/Positions"

const useStyles = makeStyles(theme => ({
    content: {
        "width": "calc(100% - 300px)",
        "padding": "30px 60px"
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 192px)"
    },
    input: {
        "width": "100%",
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "text-decoration": "none"
    },
    tableRowProduct: {
        '& > *': {
            "border-bottom": 'unset'
        },
        "cursor": "pointer"
    },
    tableRowLast: {
        '& > *': {
            "border-bottom": "0"
        }
    },
    tableRowAlert: {
        "cursor": "pointer",
        "background-color": "#f9f1f0",
        "text-decoration": "none"
    },
    amount: {
        "min-width": "100px"
    },
    lightTooltip: {
        "font-size": "0.8rem",
        "backgroundColor": "white",
        "color": "rgba(0, 0, 0, 0.87)",
        "border": "1px solid #ededed"
    },
    productName: {
        maxWidth: "400px",
    }
}))

const columns = [
    {
        id: '0',
        label: 'Название',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '1',
        label: 'Остатки',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '2',
        label: 'В корзине',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '3',
        label: 'Цена, от',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '4',
        label: '',
        format: (value) => value.toLocaleString(),
    }
];

export const ProductsTable = (props) => {
    const { positions, postponement, expirationDateType, expirationDate, expirationDatePercent, cart, find, items, category, setExpanded, expanded, account } = props
    const classes = useStyles()

    const tableRef = useRef(null)

    useEffect(() => {
        tableRef.current && tableRef.current.scrollIntoView({ behavior: 'smooth' })
    }, [items])

    const onStopPropagation = (e) => e.stopPropagation()

    const getKey = (item) => {
        return item.id + Math.random().toString(20).substr(2, 6)
    }

    const params = {
        category: category,
        expirationDate: expirationDate,
        expirationDateType: expirationDateType,
        expirationDatePercent: expirationDatePercent,
        postponement: postponement,
        find: find
    }

    return (
        <TableContainer className={classes.tableContainer}>
            <Table ref={tableRef} stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.data && items.data.map((item) => {
                        return (
                            <React.Fragment key={getKey(item)}>
                                <TableRow hover className={classes.tableRowProduct} onClick={() => setExpanded((!expanded || (expanded !== item.id)) ? item.id : false)}>
                                    <TableCell >
                                        <Tooltip classes={{tooltip: classes.lightTooltip}} title={item.name} placement="bottom">
                                            <Typography noWrap className={classes.productName}>{item.name}</Typography>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        {item.count || item.count === 0 ? round(item.count, 2) : ''}
                                    </TableCell>
                                    <TableCell>
                                        {(cart && cart.items.find(el => parseInt(el.standard_id) === parseInt(item.id))) && `${cart.items.find(el => parseInt(el.standard_id) === parseInt(item.id)).amount}` }
                                    </TableCell>
                                    <TableCell>
                                        {toFloatWithCurrency(item.price)}
                                    </TableCell>
                                    <TableCell onClick={onStopPropagation}>
                                        <AddToCart
                                            item={item}
                                            params={params}
                                            account={account}
                                            cart={cart}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <Positions
                                        item={item}
                                        params={params}
                                        positions={positions}
                                        expanded={expanded}
                                        account={account}
                                        cart={cart}
                                    />
                                </TableRow>
                            </React.Fragment>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
