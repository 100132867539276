import * as AppStorage from "../../App/helpers/storage"

export default function delivery(state = {
    order: null,
    orderItems: {
        data: [],
        meta: {total: 0, per_page: 15, current_page: 1}
    },
    filter: AppStorage.get('filterDeliveryOrders') ? JSON.parse(AppStorage.get('filterDeliveryOrders')) : {
        status: '',
        company: null,
        contract: null,
        order: {
            number: '',
            unscheduled_purchase: false
        },
        created: {
            from: null,
            to: null
        },
        price: {
            from: '',
            to: ''
        }
    },
    searchFilter: AppStorage.get('filterSearchDeliveryOrders') ? JSON.parse(AppStorage.get('filterSearchDeliveryOrders')) : {
        search: '',
        tabType: 2,
        type: 'all',
    },
    auto_order: null,
    auto_order_items: {
        data: [],
        meta: {total: 0, per_page: 15, current_page: 1}
    }
}, action) {
    switch (action.type) {
        case "DELIVERY_ORDER_REQUEST":
            return state
        case "DELIVERY_ORDER":
            return {
                ...state,
                ...{order: action.payload}
            }
        case "DELIVERY_ORDER_ITEMS":
            return {
                ...state,
                ...{orderItems: action.payload}
            }
        case "DELIVERY_ORDER_FAILURE":
            return state
        case "DELIVERY_ORDER_CLEAR":
            return {
                ...state,
                ...{
                    order: null,
                    orderItems: {
                        data: [],
                        meta: {
                            total: 0,
                            per_page: 15,
                            current_page: 1
                        }
                    }
                }
            }
        case "DELIVERY_ORDERS_REQUEST":
            return state
        case "DELIVERY_ORDERS":
            return {
                ...state,
                ...action.payload
            }
        case "DELIVERY_ORDERS_FAILURE":
            return state
        case "DELIVERY_ORDERS_FILTER":
            AppStorage.set('filterDeliveryOrders', JSON.stringify(action.payload))
            return {
                ...state,
                ...{
                    filter: {...state.filter, ...action.payload}
                }
            }
        case "DELIVERY_ORDERS_SEARCH_FILTER":
            AppStorage.set('filterSearchDeliveryOrders', JSON.stringify(action.payload))
            return  {
                ...state,
                ...{
                    searchFilter: action.payload
                }
            }
        case "DELIVERY_ORDERS_CLEAR":
            return {
                ...state,
                ...{
                    orderItems: {
                        data: [],
                        meta: {
                            total: 0,
                            per_page: 15,
                            current_page: 1
                        }
                    },
                    filter: {
                        status: '',
                        company: null,
                        contract: null,
                        order: {
                            number: '',
                            unscheduled_purchase: false
                        },
                        created: {
                            from: null,
                            to: null},
                        price: {
                            from: '',
                            to: ''
                        }
                    },
                    searchFilter: {
                        search: '',
                        tabType: 2,
                        type: 'all',
                    }
                }
            }
        case "DELIVERY_ITEMS_CLEAR":
            return {
                ...state,
                ...{
                    orderItems: {data: [], meta: {total: 0, per_page: 15, current_page: 1}},
                }
            }
        case "DELIVERY_ORDER_DELETE_REQUEST":
            return state
        case "DELIVERY_ORDER_DELETE_SUCCESS":
            return state
        case "DELIVERY_ORDER_DELETE_FAILURE":
            return state
        case "DELIVERY_ORDER_UPDATE_REQUEST":
            return state
        case "DELIVERY_ORDER_UPDATE_SUCCESS":
            return state
        case "DELIVERY_ORDER_UPDATE_FAILURE":
            return state
        case "DELIVERY_ORDER_CANCEL_REQUEST":
            return state
        case "DELIVERY_ORDER_CANCEL_SUCCESS":
            return state
        case "DELIVERY_ORDER_CANCEL_FAILURE":
            return state
        case "DELIVERY_ORDER_STATUS_REQUEST":
            return state
        case "DELIVERY_ORDER_STATUS_SUCCESS":
            return state
        case "DELIVERY_ORDER_STATUS_FAILURE":
            return state
        case "DELIVERY_ORDER_FILES_REQUEST":
            return state
        case "DELIVERY_ORDER_FILES_SUCCESS":
            return state
        case "DELIVERY_ORDER_FILES_FAILURE":
            return state
        case "DELIVERY_ORDER_FILE_DELETE":
            return {
                ...state,
                ...{
                    order: {
                        ...state.order,
                        ...{
                            files: state.order.files.filter(file => (file.id !== action.payload))
                        }
                    }
                }
            }
        case "DELIVERY_ORDER_ADD_ITEM_REQUEST":
            return state
        case "DELIVERY_ORDER_ADD_ITEM_SUCCESS":
            return state
        case "DELIVERY_ORDER_ADD_ITEM_FAILURE":
            return state
        case "DELIVERY_ORDER_ADD_ITEMS_REQUEST":
            return state
        case "DELIVERY_ORDER_ADD_ITEMS_SUCCESS":
            return state
        case "DELIVERY_ORDER_ADD_ITEMS_FAILURE":
            return state
        case "DELIVERY_ORDER_ADD_ALL_ITEM_REQUEST":
            return state
        case "DELIVERY_ORDER_ADD_ALL_ITEM_SUCCESS":
            return state
        case "DELIVERY_ORDER_ADD_ALL_ITEM_FAILURE":
            return state
        case "DELIVERY_ORDER_EDIT_ITEM_FAILURE":
            return state
        case "DELIVERY_ORDER_DELETE_ITEM_REQUEST":
            return state
        case "DELIVERY_ORDER_DELETE_ITEM_SUCCESS":
            return state
        case "DELIVERY_ORDER_DELETE_ITEM_FAILURE":
            return state
        case "DELIVERY_ORDER_ADD_ITEM_ORDERS":
            return state
        case "DELIVERY_AUTO_ORDERS":
            return {
                ...state,
                ...action.payload
            }
        case "DELIVERY_AUTO_ORDERS_FAILURE":
            return state
        case "DELIVERY_AUTO_ORDER_REQUEST":
            return state
        case "DELIVERY_AUTO_ORDER":
            return {
                ...state,
                ...{
                    auto_order: action.payload
                }
            }
        case "DELIVERY_AUTO_ORDER_FAILURE":
            return state
        case "DELIVERY_AUTO_ORDER_ITEMS_REQUEST":
            return state
        case "DELIVERY_AUTO_ORDER_ITEMS":
            return {
                ...state,
                ...{
                    auto_order_items: action.payload
                }
            }
        case "DELIVERY_AUTO_ORDER_ITEMS_FAILURE":
            return state
        case "DELIVERY_LOAD_AUTO_ORDER_REQUEST":
            return state
        case "DELIVERY_LOAD_AUTO_ORDER_SUCCESS":
            return state
        case "DELIVERY_LOAD_AUTO_ORDER_FAILURE":
            return state
        default:
            return state
    }
}
