import {HttpService} from "../../../App/services/http"

export const GroupService = {
    groups,
    add,
    edit,
    remove
}

function groups(params) {
    return HttpService.get(`/report/control/price/item/groups`, params)
        .then(response => {
            return response.groups
        })
}

function add(values) {
    return HttpService.post("/report/control/price/item/group", values)
        .then(response => {
            return response.group
        })
}

function edit(id, values) {
    return HttpService.put(`/report/control/price/item/group/${id}`, values)
        .then(response => {
            return response.group
        })
}

function remove(id) {
    return HttpService.deleteMethod(`/report/control/price/item/group/${id}`)
}
