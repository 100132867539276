import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import format from "date-fns/format"
import {ru} from "date-fns/locale"

import {Grid, makeStyles} from "@material-ui/core"
import {Check} from '@material-ui/icons'
import {Table} from "@devexpress/dx-react-grid-material-ui";
import {ShopActions} from "../../actions/shop";
import {Submit} from "../../../../../App/components/Button/Submit";
import {ButtonList} from "../../../../../App/components/ButtonList/ButtonList";
import {history} from "../../../../../App/helpers/history";
import {CustomTable} from "../../../../../App/components/Table/Table";
import {TableHeader} from "../../../../../App/components/Table/TableHeader";
import {ColumnChooser} from "../../../../../App/components/Table/ColumnChooser";
import {SettingsActions} from "../../../../../Settings/actions/settings";
import {localState} from "../../../../../App/components/LocalState";
import {parseCurrency} from "../../../../../App/helpers/currency";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 158px)"
    },
    input: {
        "width": "100%",
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 209px)",
        "min-height": "calc(100vh - 209px)"
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0",
    },
    column: {
        "height": "86px"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    tableRowAlert: {
        "cursor": "pointer",
        "background-color": "#f9f1f0",
        "text-decoration": "none"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    },
}))

const columns = [
    {name: 'number', title: '№'},
    {name: 'name', title: 'Название'},
    {name: 'purchase_number', title: 'Номер закупки'},
    {name: 'category', title: 'Категория', filter: 'categories'},
    {name: 'lots_count', title: 'Кол-во лотов'},
    {name: 'type', title: 'Возможность изменения'},
    {name: 'average_price', title: 'НМЦД'},
    {name: 'contract_number', title: '№ договора'},
    {name: 'status', title: 'Статус'},
    {name: 'application_start', title: 'Дата начала приема заявок'},
    {name: 'application_deadline', title: 'Дата окончания приёма предложений'},
    {name: 'created', title: 'Дата формирования'},
]

const sortList = [
    {
        key: 'all',
        value: 'Все'
    },
    {
        key: 'active',
        value: 'Активные'
    },
    {
        key: 'draft',
        value: 'Черновики'
    }
]

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {filter} = useSelector(state => state.filters['marketplace_requests'])
    const [orders, setOrders] = useState(localState)
    const [tab, setTab] = useState(1)
    const [sort, setSort] = useState('active')
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [tableRef, setTableRef] = useState(null)

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'number', width: 100},
        {columnName: 'name', width: 200},
        {columnName: 'purchase_number', width: 200},
        {columnName: 'category', width: 200},
        {columnName: 'lots_count', width: 150},
        {columnName: 'type', width: 250},
        {columnName: 'average_price', width: 150},
        {columnName: 'contract_number', width: 150},
        {columnName: 'status', width: 150},
        {columnName: 'application_deadline', width: 200},
        {columnName: 'application_start', width: 200},
        {columnName: 'created', width: 200},
    ])

    const [columnOrder, setColumnOrder] = useState([
        'number',
        'name',
        'purchase_number',
        'category',
        'lots_count',
        'type',
        'average_price',
        'contract_number',
        'status',
        'application_start',
        'application_deadline',
        'created'
    ])

    const [initialize, setInitialize] = useState(false);

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
        }
    }

    useEffect(() => {
        const getOrders = async () => {
            return await dispatch(ShopActions.orders({
                ...{
                    limit: rowsPerPage,
                    page: page,
                    sort: sort,
                    ...getParams()
                }
            }))
        }

        getOrders().then((response) => {
            setOrders(response)
            tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
        })
        // eslint-disable-next-line
    }, [dispatch, page, rowsPerPage, sort, filter]);

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: "MARKETPLACE_REQUESTS_FILTER", payload: {limitRows: newRowsPerPage}})
    }

    useEffect(() => {
        if (!initialize) {
            dispatch(SettingsActions.table('marketplace_requests')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                dispatch({type: "FILTER_CLEAR"})
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'marketplace_requests',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    return <Grid item className={classes.content}>
        <Grid container>
            <ButtonList value={tab} values={sortList} onChange={(_, value) => {
                setTab(value);
                setPage(1)
                setSort(sortList[value].key);
            }}/>
        </Grid>
        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2} style={{marginBottom: '8px'}}>
            <Grid item>
                <Submit
                    disableElevation
                    variant="contained"
                    color="primary"
                    type="button"
                    component={Link}
                    to="/marketplace/request/add"
                >
                    Создать запрос
                </Submit>
            </Grid>
            <Grid item>
                <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
            </Grid>
        </Grid>
        <CustomTable
            meta={orders.meta}
            tableName={'marketplace_requests'}
            rows={orders.data.map((order) => ({
                number: order.id,
                name: order.name,
                purchase_number: order.purchase_number,
                category: order?.category?.name,
                lots_count: order.count.lots,
                type: order.type === 'dynamic' ? <Check fontSize="small"/> : null,
                status: order.status.index === 8 ? "В работе" : order.status.name,
                average_price: order?.is_unit_price ? parseCurrency(order?.lots?.reduce((total, lot) => total + (lot?.max_contract_price ?? 0), 0), order?.currency?.code) : parseCurrency(order?.average_price, order?.currency?.code),
                contract_number: order?.contract_number,
                application_start: order?.application_start ? <>{format(new Date(order.application_start), 'dd.MM.yyyy HH:mm')}<br/>(мск {format(new Date(new Date(order.application_start).toLocaleString("en-US", {timeZone: "Europe/Moscow"})), 'dd.MM.yyyy HH:mm', {locale: ru})})</> : null,
                application_deadline: order?.application_deadline ? <>{format(new Date(order.application_deadline), 'dd.MM.yyyy HH:mm')}<br/>(мск {format(new Date(new Date(order.application_deadline).toLocaleString("en-US", {timeZone: "Europe/Moscow"})), 'dd.MM.yyyy HH:mm', {locale: ru})})</> : null,
                created: <>{format(new Date(order.created), 'dd.MM.yyyy HH:mm')}<br/>(мск {format(new Date(new Date(order.created).toLocaleString("en-US", {timeZone: "Europe/Moscow"})), 'dd.MM.yyyy HH:mm', {locale: ru})})
                </>
            }))}
            columns={columns}
            page={{
                page: page,
                setPage: setPage,
                rowsPerPage: rowsPerPage,
                handlePageSizeChange: handlePageSizeChange
            }}
            setTableRef={setTableRef}
            columnsSettings={{
                columnOrder: columnOrder,
                setColumnOrder: setColumnOrder,
                setColumnWidths: setColumnWidths,
                columnWidths: columnWidths,
                hiddenColumnNames: hiddenColumnNames,
                setHiddenColumnNames: setHiddenColumnNames
            }}
            tableHeader={TableHeader}
            filterActionType={"MARKETPLACE_REQUESTS_FILTER"}
            classInputContent={classes.inputContent}
            rowComponent={({row, tableRow, children}) => (
                <Table.Row
                    tableRow={tableRow}
                    className={classes.tableRow}
                    hover
                    row={row}
                    children={children}
                    onClick={() => {
                        !orders.data[tableRow.rowId].status.index ? history.push(`/marketplace/request/${orders.data[tableRow.rowId].id}/edit`) : history.push(`/marketplace/request/${orders.data[tableRow.rowId].id}`)
                    }}
                    style={{'cursor': 'pointer'}}
                />
            )}
            filters={{
                type: {
                    nullButton: true
                }
            }}
        />
    </Grid>
}
