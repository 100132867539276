import {HttpService} from "../../App/services/http"

export const ContractService = {
    companies,
    contracts,
    contract,
    add,
    edit,
    reject,
    approve,
    remove,
    items,
    deleteFile,
    changeStatus,
    statuses
}

function companies(params) {
    return HttpService.get(`/contract/companies`, params)
}

function contracts(params) {
    return HttpService.get(`/contracts`, params)
}

function contract(id) {
    return HttpService.get(`/contract/${id}`)
        .then(response => {
            return response.contract
        })
}

function add(formData) {
    return HttpService.post("/contract", formData, true, true)
        .then(response => {
            return response.contract
        })
}

function edit(id, formData) {
    return HttpService.post(`/contract/${id}`, formData, true, true)
        .then(response => {
            return response.contract
        })
}

function approve(id) {
    return HttpService.put(`/contract/${id}/approve`)
        .then(response => {
            return response.contract
        })
}

function reject(id, values) {
    return HttpService.put(`/contract/${id}/reject`, values)
        .then(response => {
            return response.contract
        })
}

function remove(id) {
    return HttpService.deleteMethod(`/contract/${id}`)
}

function items(id, params) {
    return HttpService.get(`/contract/${id}/items`, params)
}

function deleteFile(order_id, file_id) {
    return HttpService.deleteMethod(`/shop/order/${order_id}/file/${file_id}`)
}

function changeStatus(id, values) {
    return HttpService.put(`/contract/${id}/status`, values)
}

function statuses() {
    return HttpService.get(`/contract/statuses`)
        .then(response => {
            return response.statuses
        })
}
