import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, FieldArray, Form, Formik} from "formik"
import format from "date-fns/format"
import * as Yup from "yup"

import {
    Collapse,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core"
import {Clear} from "@material-ui/icons"

import {TextFieldWithError as CustomTextField} from "../../../App/components/Inputs/TextFieldWithError"
import {CartActions} from "../../actions/cart"
import {day as formatDay} from "../../../App/helpers/date"
import {Submit} from "../../../App/components/Button/Submit"
import {ItemActions} from "../../actions/cart/items"
import {history} from "../../../App/helpers/history"

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 111px)"
    },
    containerRows: {
        "width": "100%",
        "overflow": "auto",
        "padding": "15px"
    },
    sale: {
        "color": "red",
        "font-size": "10px",
        "font-weight": "500",
        "display": "block"
    },
    disabledSale: {
        "color": "rgb(193, 193, 193)",
        "font-size": "10px",
        "font-weight": "500",
        "display": "block"
    },
    input: {
        "width": "100%",
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100% - 70px)",
        "min-height": "calc(100% - 70px)",
        "overflow": "auto"
    },
    row: {
        "padding-bottom": "24px"
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    table: {
        "border": "1px solid #e4e4e4",
    },
    column: {
        "height": "86px"
    },
    disabled: {
        "color": "rgb(193, 193, 193)"
    },
    active: {
        "color": "#485868"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "text-decoration": "none"
    },
    tableRowAlert: {
        "cursor": "pointer",
        "background-color": "#f9f1f0",
        "text-decoration": "none"
    },
    tableRowProduct: {
        '& > *': {
            "border-bottom": 'unset'
        },
        "cursor": "pointer"
    },
    tableRowLast: {
        '& > *': {
            "border-bottom": "0"
        }
    },
    disabledLine: {
        "text-decoration": "line-through",
        "color": "rgb(193, 193, 193)"
    },
    activeLine: {
        "text-decoration": "line-through",
        "color": "#485868"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "17px"
    },
    searchLabel: {
        "background": "#fff",
        "margin-top": "6px !important",
        "margin-bottom": "15px",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    error: {
        "color": "#f44336"
    },
}))

const columns = [
    {
        id: '0',
        label: 'Название',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '1',
        label: 'Количество',
        align: 'right',
        format: (value) => value.toLocaleString(),
    }
];

const columnsWithSupplier = [
    {
        id: '0',
        label: 'Название',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '1',
        label: 'Количество',
        align: 'right',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '2',
        label: 'Поставщик',
        align: 'right',
        format: (value) => value.toLocaleString(),
    }
];

export const Content = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {account} = useSelector(state => state.account)
    const {cart, carts} = useSelector(state => state.marketplace)

    const [amount, setAmount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [validation, setValidation] = useState()
    const [expanded, setExpanded] = useState(false)
    const [positions, setPositions] = useState({})

    const handleChangeAmount = (id, amount) => {
        const quantity = positions[id].reduce(function (total, position) {
            return Number(total) + (position.active ? Number(position.stock_quantity) : 0)
        }, 0)

        if (validation && (!validation.hasOwnProperty(id) || (validation.hasOwnProperty(id) && !validation[id].hasOwnProperty('rationale')))) {
            if (amount > quantity) {
                setValidation({
                    ...validation,
                    ...{[id]: {amount: quantity}}
                })
            } else {
                let total = parseFloat(amount)

                setPositions({
                    ...positions,
                    ...{
                        [id]: positions[id].map(position => {
                            if (total > 0) {
                                let current = ((parseFloat(position.stock_quantity) - total) >= 0) ? (parseFloat(position.stock_quantity) - (parseFloat(position.stock_quantity) - total)) : (total + (parseFloat(position.stock_quantity) - total))

                                if (current >= parseFloat(position.min_ordered_quantity ?? 0)) {
                                    total -= current

                                    return {
                                        ...position,
                                        ...{disabled: false}
                                    }
                                }
                            }

                            return {
                                ...position,
                                ...{disabled: true}
                            }
                        })
                    }
                })

                setValidation(validation ? {
                    ...validation,
                    ...{[id]: {}}
                } : validation)
            }
        }
    }

    useEffect(() => {
        return () => {
            dispatch({type: "CART", payload: null})
        }
    }, [dispatch])

    useEffect(() => {
        if (cart) {
            let data = {
                total: 0,
                validation: {},
                positions: {}
            }

            cart.items.forEach(item => {
                data.total += 1
                data.positions[item.id] = item.positions
                item.amount = item.amount.replace(',', '')
                if (data.positions[item.id].length <= 1) {
                    data.validation = {
                        ...data.validation,
                        ...{[item.id]: {rationale: true}}
                    }
                } else {
                    const quantity = data.positions[item.id].reduce(function (total, position) {
                        return Number(total) + (position.active ? Number(position.stock_quantity) : 0)
                    }, 0);
                    if (item.amount > quantity) {
                        data.validation = {
                            ...data.validation,
                            ...{[item.id]: {amount: quantity}}
                        }
                    } else {
                        let total = parseFloat(item.amount)
                        data.positions[item.id] = data.positions[item.id].map(position => {
                            if (total > 0) {
                                let current = ((parseFloat(position.stock_quantity) - total) >= 0) ? (parseFloat(position.stock_quantity) - (parseFloat(position.stock_quantity) - total)) : (total + (parseFloat(position.stock_quantity) - total))

                                if (current >= parseFloat(position.min_ordered_quantity ?? 0)) {
                                    total -= current

                                    return {
                                        ...position,
                                        ...{disabled: false}
                                    }
                                }
                            }

                            return {
                                ...position,
                                ...{disabled: true}
                            }
                        })
                    }
                }
            })

            setAmount(data.total)
            setPositions(data.positions)
            setValidation(data.validation)
        }
    }, [cart])

    useEffect(() => {
        const getCarts = async () => {
            return await dispatch(CartActions.carts())
        }

        const getCart = async (id) => {
            return await dispatch(CartActions.cart(id))
        }

        if (!loading) {
            getCarts().then(carts => {
                if (!cart && carts.length) {
                    getCart(carts[0].id).then(_ => {
                    })
                }
                setLoading(true)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loading]);

    const getColumns = (account) => {
        return account.company.settingsMarketplace.show_suppliers ? columnsWithSupplier : columns;
    }

    const onSubmit = (values, {setSubmitting}) => {
        const formData = new FormData()

        values.items.forEach(item => {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    formData.append(`items[${item.id}][${key}]`, item[key])
                }
            }
        })

        return dispatch(CartActions.order(cart.id, formData)).then(
            () => {
                dispatch({type: 'CART_ITEMS_COUNT_LOADING', payload: false})
                history.push("/marketplace/orders")
            },
            error => {
                dispatch({type: 'CART_ITEMS_COUNT_LOADING', payload: false})
                dispatch({
                    type: "CART",
                    payload: {...cart, ...{items: cart.items.filter(item => (error.hasOwnProperty(item.id)))}}
                })
                setLoading(false)
                setValidation(error)
            }
        )
    }

    return cart ? (
            <Grid item className={classes.content}>
                <Paper>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item className={classes.fullWidth}>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    items: cart.items,
                                }}
                                validationSchema={Yup.object().shape({
                                    items: Yup.array().of(Yup.object().shape({
                                        amount: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!")
                                    })).required("Поле не может быть пустым или состоять из одних пробелов!")
                                })}
                                onSubmit={onSubmit}
                            >
                                {({
                                      values,
                                      errors,
                                      touched,
                                      isSubmitting,
                                      isValid,
                                      dirty,
                                      setFieldValue,
                                      setFieldTouched,
                                      validateField
                                  }) => (
                                    <Form>
                                        <Grid container className={classes.container} direction="column" justify="space-between" alignItems="stretch">
                                            <Grid item className={classes.inputContent}>
                                                <Grid container className={classes.containerRows} direction="column" justify="flex-start" alignItems="stretch">
                                                    <Grid item className={classes.row}>
                                                        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                            <Grid item>
                                                                <Grid container direction="row" justify="space-between" alignItems="stretch">
                                                                    <Grid item>
                                                                        <Typography>
                                                                            <Link
                                                                                href={`/marketplace?expiration_date=${cart?.expiration_date ? format(new Date(cart.expiration_date.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2-$1-$3")), 'yyyy-MM-dd') : ''}&postponement=${cart?.postponement}&contract_time=${cart?.contract_time}&category=${cart.category.id}`}>Корзина № {carts.findIndex((item) => item.id === cart.id) + 1}
                                                                            </Link>
                                                                        </Typography>
                                                                        <Typography>
                                                                            {cart.category.name}
                                                                        </Typography>
                                                                        <Typography variant="body2">
                                                                            Минимальный срок годности товаров: {cart.expiration_date}
                                                                        </Typography>
                                                                        <Typography variant="body2">
                                                                            Отсрочка платежа {formatDay(cart.postponement)}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <TableContainer className={classes.tableContainer}>
                                                                    <Table stickyHeader className={classes.table}>
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                {getColumns(account).map((column) => (
                                                                                    <TableCell
                                                                                        key={column.id}
                                                                                        align={column.align}
                                                                                        style={{minWidth: column.minWidth}}
                                                                                    >
                                                                                        {column.label}
                                                                                    </TableCell>
                                                                                ))}
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <FieldArray name="items"
                                                                                    render={arrayHelpers => (
                                                                                        <TableBody>
                                                                                            {values.items.map((item, i) => {
                                                                                                return (
                                                                                                    <React.Fragment
                                                                                                        key={item.id}>
                                                                                                        <TableRow
                                                                                                            hover
                                                                                                            className={classes.tableRowProduct}
                                                                                                            onClick={() => setExpanded((!expanded || (expanded !== item.id)) ? item.id : false)}>
                                                                                                            <TableCell>
                                                                                                                <Typography>{item.name}</Typography>
                                                                                                                {(validation && validation.hasOwnProperty(item.id) && validation[item.id].hasOwnProperty('supplier')) &&
                                                                                                                    <Typography
                                                                                                                        style={{"color": "#f44336"}}
                                                                                                                        variant="caption">{validation[item.id].supplier}</Typography>}
                                                                                                                {(validation && validation.hasOwnProperty(item.id) && validation[item.id].hasOwnProperty('rationale')) &&
                                                                                                                    <Typography
                                                                                                                        variant="body2"
                                                                                                                        gutterBottom>Ввиду
                                                                                                                        отсутствия
                                                                                                                        состязательности
                                                                                                                        закупки
                                                                                                                        предлагаем <Link
                                                                                                                            href="/marketplace/request/add"
                                                                                                                            onClick={(e) => e.stopPropagation()}>оформить
                                                                                                                            ценовой
                                                                                                                            запрос</Link></Typography>}
                                                                                                                {(!item.positions.length) &&
                                                                                                                    <Typography variant="body2" gutterBottom>
                                                                                                                        Отсутствуют предложения от поставщиков по выбранной позиции
                                                                                                                    </Typography>}
                                                                                                            </TableCell>
                                                                                                            <TableCell>
                                                                                                                <Grid container direction="row" justify="flex-end" alignItems="center">
                                                                                                                    <Grid item>
                                                                                                                        <Field
                                                                                                                            onClick={(e) => e.stopPropagation()}
                                                                                                                            fullWidth
                                                                                                                            name={`items.${i}.amount`}
                                                                                                                            type="number"
                                                                                                                            helperText={(validation && validation.hasOwnProperty(item.id) && validation[item.id].hasOwnProperty('amount') && (values.items[i].amount > validation[item.id].amount)) ? `Для заказа доступно: ${validation[item.id].amount}` : null}
                                                                                                                            inputProps={{
                                                                                                                                step: 0.01,
                                                                                                                                min: 1,
                                                                                                                                onChange: (e) => {
                                                                                                                                    const {
                                                                                                                                        value,
                                                                                                                                        name
                                                                                                                                    } = e.target
                                                                                                                                    handleChangeAmount(item.id, value)
                                                                                                                                    setFieldValue(name, value)
                                                                                                                                },
                                                                                                                                onBlur: (e) => {
                                                                                                                                    return dispatch(ItemActions.amount(item.id, {amount: e.target.value})).then(
                                                                                                                                        () => {
                                                                                                                                            dispatch({
                                                                                                                                                type: 'CART_ITEMS_COUNT_LOADING',
                                                                                                                                                payload: false
                                                                                                                                            })
                                                                                                                                        }
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            }}
                                                                                                                            endAdornment={item.unit ?
                                                                                                                                <InputAdornment position="end">{item.unit.short}</InputAdornment> : null}
                                                                                                                            component={CustomTextField}
                                                                                                                        />
                                                                                                                    </Grid>
                                                                                                                    <Grid item>
                                                                                                                        <IconButton
                                                                                                                            size={"small"}
                                                                                                                            onClick={(e) => {
                                                                                                                                e.stopPropagation()
                                                                                                                                return dispatch(ItemActions.remove(item.id)).then(
                                                                                                                                    () => {
                                                                                                                                        dispatch({
                                                                                                                                            type: 'CART_ITEMS_COUNT_LOADING',
                                                                                                                                            payload: false
                                                                                                                                        })

                                                                                                                                        if (values.items.length === 1) {
                                                                                                                                            setLoading(false)
                                                                                                                                            dispatch({
                                                                                                                                                type: "CART",
                                                                                                                                                payload: null
                                                                                                                                            })
                                                                                                                                        } else {
                                                                                                                                            handleChangeAmount(item.id, item.amount)
                                                                                                                                            setAmount(amount - 1)
                                                                                                                                            arrayHelpers.remove(i)
                                                                                                                                        }
                                                                                                                                    },
                                                                                                                                    error => {
                                                                                                                                    }
                                                                                                                                )
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <Clear/>
                                                                                                                        </IconButton>
                                                                                                                    </Grid>
                                                                                                                </Grid>
                                                                                                            </TableCell>
                                                                                                            {account.company.settingsMarketplace.show_suppliers &&
                                                                                                                <TableCell>
                                                                                                                    <Grid container direction="row" justify="flex-end" alignItems="center">
                                                                                                                        <Grid item>
                                                                                                                            {item?.supplier?.legal_detail?.name}
                                                                                                                        </Grid>
                                                                                                                    </Grid>
                                                                                                                </TableCell>
                                                                                                            }
                                                                                                        </TableRow>
                                                                                                        <TableRow>
                                                                                                            <TableCell
                                                                                                                style={{
                                                                                                                    paddingBottom: 0,
                                                                                                                    paddingTop: 0
                                                                                                                }}
                                                                                                                colSpan={5}>
                                                                                                                <Collapse
                                                                                                                    in={!!((expanded === item.id) && (positions.hasOwnProperty(item.id) && positions[item.id].length))}
                                                                                                                    timeout="auto"
                                                                                                                    unmountOnExit>
                                                                                                                    <TableContainer className={classes.tableContainer}>
                                                                                                                        <Typography variant="h6" gutterBottom component="div">
                                                                                                                            Предложения
                                                                                                                        </Typography>
                                                                                                                        <Table>
                                                                                                                            <TableHead>
                                                                                                                                <TableRow>
                                                                                                                                    {[{
                                                                                                                                        id: '0',
                                                                                                                                        label: 'Остатки'
                                                                                                                                    }, {
                                                                                                                                        id: '1',
                                                                                                                                        label: 'Заказ, от'
                                                                                                                                    }, {
                                                                                                                                        id: '2',
                                                                                                                                        label: 'Цена'
                                                                                                                                    }, {
                                                                                                                                        id: '3',
                                                                                                                                        label: ''
                                                                                                                                    }].map((column) => (
                                                                                                                                        <TableCell
                                                                                                                                            key={column.id}>
                                                                                                                                            {column.label}
                                                                                                                                        </TableCell>
                                                                                                                                    ))}
                                                                                                                                </TableRow>
                                                                                                                            </TableHead>
                                                                                                                            <TableBody>
                                                                                                                                {positions.hasOwnProperty(item.id) && positions[item.id].map((position, index) => {
                                                                                                                                    return (
                                                                                                                                        <TableRow
                                                                                                                                            key={index}
                                                                                                                                            className={(positions[item.id].length === (index + 1)) ? classes.tableRowLast : classes.tableRow}>
                                                                                                                                            <TableCell
                                                                                                                                                className={(position.active && (!position.hasOwnProperty('disabled') || !position.disabled)) ? classes.active : classes.disabled}>
                                                                                                                                                {parseFloat(position.stock_quantity).toFixed(2)}
                                                                                                                                            </TableCell>
                                                                                                                                            <TableCell
                                                                                                                                                className={(position.active && (!position.hasOwnProperty('disabled') || !position.disabled)) ? classes.active : classes.disabled}>
                                                                                                                                                {position.min_ordered_quantity ? parseFloat(position.min_ordered_quantity).toFixed(2) : null}
                                                                                                                                            </TableCell>
                                                                                                                                            <TableCell
                                                                                                                                                className={(position.active && (!position.hasOwnProperty('disabled') || !position.disabled)) ? classes.active : classes.disabled}>
                                                                                                                                                {position.price_discount &&
                                                                                                                                                    <Typography
                                                                                                                                                        variant="caption"
                                                                                                                                                        className={(position.active && (!position.hasOwnProperty('disabled') || !position.disabled)) ? classes.activeLine : classes.disabledLine}>
                                                                                                                                                        {parseFloat(position.price + (position.price_discount ?? 0)).toLocaleString('ru-RU', {
                                                                                                                                                            style: 'currency',
                                                                                                                                                            currency: 'RUB'
                                                                                                                                                        })}
                                                                                                                                                    </Typography>
                                                                                                                                                }
                                                                                                                                                <Typography className={(position.active && (!position.hasOwnProperty('disabled') || !position.disabled)) ? classes.active : classes.disabled}>
                                                                                                                                                    {parseFloat(position.price).toLocaleString('ru-RU', {
                                                                                                                                                        style: 'currency',
                                                                                                                                                        currency: 'RUB'
                                                                                                                                                    })}
                                                                                                                                                </Typography>
                                                                                                                                                {position.price_discount &&
                                                                                                                                                    <Typography
                                                                                                                                                        className={(position.active && (!position.hasOwnProperty('disabled') || !position.disabled)) ? classes.sale : classes.disabledSale}
                                                                                                                                                        component="span">
                                                                                                                                                        С
                                                                                                                                                        учетом
                                                                                                                                                        скидки/надбавки {parseFloat(position.price_discount / (position.price + (position.price_discount ?? 0))) * 100} %
                                                                                                                                                    </Typography>
                                                                                                                                                }
                                                                                                                                            </TableCell>
                                                                                                                                            <TableCell className={(position.active && (!position.hasOwnProperty('disabled') || !position.disabled)) ? classes.active : classes.disabled}>
                                                                                                                                                {((positions[item.id].length > 1) && !position.active) && "Предложение не прошло антидемпинговую проверку"}
                                                                                                                                            </TableCell>
                                                                                                                                        </TableRow>
                                                                                                                                    );
                                                                                                                                })}
                                                                                                                            </TableBody>
                                                                                                                        </Table>
                                                                                                                    </TableContainer>
                                                                                                                </Collapse>
                                                                                                            </TableCell>
                                                                                                        </TableRow>
                                                                                                    </React.Fragment>
                                                                                                );
                                                                                            })}
                                                                                        </TableBody>
                                                                                    )}/>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.footer}>
                                                <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                                    <Grid item className={classes.footerContent}>
                                                        <Grid container className={classes.footerContainer} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Typography>Общее количество: {amount}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                                    <Grid item>
                                                                        <Submit
                                                                            disableElevation
                                                                            variant="contained"
                                                                            color="primary"
                                                                            type="button"
                                                                            disabled={isSubmitting}
                                                                            onClick={() => {
                                                                                return dispatch(CartActions.remove(cart.id)).then(
                                                                                    () => {
                                                                                        dispatch({
                                                                                            type: 'CART_ITEMS_COUNT_LOADING',
                                                                                            payload: false
                                                                                        })
                                                                                        setAmount(0)
                                                                                        setLoading(false)
                                                                                    },
                                                                                    error => {
                                                                                    }
                                                                                )
                                                                            }}
                                                                        >
                                                                            Удалить
                                                                        </Submit>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Submit
                                                                            disableElevation
                                                                            variant="contained"
                                                                            color="primary"
                                                                            type="submit"
                                                                            disabled={isSubmitting || !isValid}
                                                                        >
                                                                            Оформить
                                                                        </Submit>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        ) : null
}
