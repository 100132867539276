import {HttpService} from "../../App/services/http"


export const ProductsService = {
    products
}

function products(params) {
    return HttpService.get("/discounts/products", params)
}
