import React from "react"
import {useSelector} from "react-redux"

import {
    Grid,
    makeStyles
} from "@material-ui/core"

import {history} from "../../../../App/helpers/history"
import {Submit} from "../../../../App/components/Button/Submit"
import {purchase as rules} from "../../../helpers/permissions/rules"

const useStyles = makeStyles(theme => ({
    button: {
        "height": "45px",
    }
}))

export const Edit = (props) => {
    const classes = useStyles()

    const { purchase, disabled = false } = props

    const {account} = useSelector(state => state.account)

    return rules.edit.hasOwnProperty(account.group) ? (
        <Grid item>
            <Submit
                className={classes.button}
                disableElevation
                disabled={
                    disabled ||
                    !rules.edit.hasOwnProperty(account.group) ||
                    !purchase ||
                    function () {
                        if (rules.edit[account.group].hasOwnProperty(purchase.status.index) && !purchase.lock) {
                            if (rules.edit[account.group][purchase.status.index].length) {
                                return !rules.edit[account.group][purchase.status.index].includes(purchase.editRequestStatus?.index)
                            }

                            return false
                        }

                        return true
                    }()
                }
                variant="contained"
                color="primary"
                type="button"
                style={{height: '35px'}}
                onClick={() => {
                    history.push(`/planning/purchase/${purchase.id}/edit`)
                }}
            >
                Редактировать закупку
            </Submit>
        </Grid>
    ) : null
}
