import React, {useEffect, useState} from "react"
import {Link as Route} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import format from "date-fns/format"
import {ru} from "date-fns/locale"

import {Grid, makeStyles, Typography} from "@material-ui/core"
import {Table} from "@devexpress/dx-react-grid-material-ui";

import {DiscountsActions} from "../../actions/discounts"
import {Submit} from "../../../App/components/Button/Submit"
import {getPriceName} from "../../../App/helpers/name"
import {history} from "../../../App/helpers/history"
import {CustomTable} from "../../../App/components/Table/Table";
import {ColumnChooser} from "../../../App/components/Table/ColumnChooser";
import {TableHeader} from "../../../App/components/Table/TableHeader";
import {SettingsActions} from "../../../Settings/actions/settings";
import {localState} from "../../../App/components/LocalState";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    inputContent: {
        "height": "calc(100vh - 161px)",
        "min-height": "calc(100vh - 161px)",
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    sendOffer: {
        width: '245px',
    },
    contentColumn: {
        "max-width": "245px",
        "min-width": "245px",
    },
    contentColumnSale: {
        "max-width": "140px",
        "min-width": "140px",
    },
    contentColumnMax: {
        "max-width": "345px",
        "min-width": "345px",
    },
    itemsNames: {
        "max-width": "300px",
        "overflow": "hidden",
        "white-space": "nowrap",
        "text-overflow": "ellipsis",
    },
}))

const columns = [
    {name: 'name', title: 'Название'},
    {name: 'conditions', title: 'Условия предоставления'},
    {name: 'items', title: 'Объекты'},
    {name: 'sale', title: 'Скидка/Надбавка'},
    {name: 'datetime', title: 'Срок действия'},
    {name: 'active', title: 'Статус', filter: 'status'},
];

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {filter} = useSelector(state => state.filters['discounts'])
    const [discounts, setDiscounts] = useState(localState)
    const {account} = useSelector(state => state.account)
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [tableRef, setTableRef] = useState(null)

    const [columnOrder, setColumnOrder] = useState([
        'name',
        'conditions',
        'items',
        'sale',
        'datetime',
        'active',
    ])

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'name', width: 150},
        {columnName: 'conditions', width: 250},
        {columnName: 'items', width: 250},
        {columnName: 'sale', width: 250},
        {columnName: 'datetime', width: 200},
        {columnName: 'active', width: 250},
    ])

    const [initialize, setInitialize] = useState(false);

    const [loading, setLoading] = useState(false)

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
        }
    }

    useEffect(() => {
        const getDiscounts = async () => {
            return await dispatch(DiscountsActions.discounts({
                limit: rowsPerPage,
                company_id: account.company.id,
                page: page,
                ...getParams()
            }))
        }

        getDiscounts().then((response) => {
            setDiscounts(response)
            tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
        })
        // eslint-disable-next-line
    }, [dispatch, loading, page, rowsPerPage, filter]);

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: "DISCOUNTS_FILTER", payload: {limitRows: newRowsPerPage}})
    }

    const getDiscount = (discount) => {
        switch (discount.type) {
            case 'discount':
                return `скидка ${discount.value} %`
            case 'markup':
                return `надбавка ${discount.value} %`
            case 'delivery':
                return 'бесплатная доставка'
            default:
        }
    }

    const trueWordform = (num) => {
        num = Number(num) % 100;
        let num_x = num % 10;
        if ((num > 10) && (num < 20))
            return 'условий';
        if ((num_x > 1) && (num_x < 5))
            return 'условия';
        if (num_x === 1)
            return 'условие';
        return 'условие';
    }

    useEffect(() => {
        if (!initialize) {
            dispatch(SettingsActions.table('discounts')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                dispatch({type: "FILTER_CLEAR"})
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'discounts',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    return discounts.data ? (
        <Grid item className={classes.content}>
            <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                <Grid item>
                    <Submit
                        disableElevation
                        variant="contained"
                        color="primary"
                        type="button"
                        component={Route}
                        className={classes.sendOffer}
                        to={`/supplier/discounts/add`}
                    >
                        Создать
                    </Submit>
                </Grid>
                <Grid item>
                    <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
                </Grid>
                <CustomTable
                    meta={discounts.meta}
                    tableName={'discounts'}
                    rows={discounts?.data.map((discount) => ({
                        name: discount.name,
                        conditions: <React.Fragment>
                            {JSON.parse(discount.conditions) && JSON.parse(discount.conditions).length ?
                                (<Typography className={classes.itemsNames}>
                                    {JSON.parse(discount.conditions).length + ' ' + trueWordform(JSON.parse(discount.conditions).length)}
                                </Typography>) : null}
                        </React.Fragment>,
                        items: <React.Fragment>
                            {JSON.parse(discount.items).customers.length ?
                                (<Typography className={classes.itemsNames}>
                                    <b>Заказчики:</b> {JSON.parse(discount.items).customers.map(customer => customer.legal_detail.name).join(', ')}
                                </Typography>) : null
                            }
                            {JSON.parse(discount.items).prices.length ?
                                (<Typography className={classes.itemsNames}>
                                    <b>Прайс-листы:</b> {JSON.parse(discount.items).prices.map(price => getPriceName(price)).join(', ')}
                                </Typography>) : null
                            }
                            {JSON.parse(discount.items).products.length ?
                                (<Typography className={classes.itemsNames}>
                                    <b>Товары:</b> {JSON.parse(discount.items).products.map(product => product.name).join(', ')}
                                </Typography>) : null
                            }
                        </React.Fragment>,
                        sale: <Typography className={classes.contentColumnSale}>{getDiscount(discount)}</Typography>,
                        datetime: <Typography
                            className={classes.contentColumnMax}>c <b>{format(new Date(discount.datetime_start), 'H:mm PP', {locale: ru})}</b> по <b>{discount.without_end ? ' ...' : format(new Date(discount.datetime_end), 'H:mm PP', {locale: ru})}</b></Typography>,
                        active: <Typography
                            className={classes.contentColumn}>{discount.active ? 'Активна' : 'Неактивна'}</Typography>,
                    }))}
                    columns={columns}
                    page={{
                        page: page,
                        setPage: setPage,
                        rowsPerPage: rowsPerPage,
                        handlePageSizeChange: handlePageSizeChange
                    }}
                    setTableRef={setTableRef}
                    columnsSettings={{
                        columnOrder: columnOrder,
                        setColumnOrder: setColumnOrder,
                        setColumnWidths: setColumnWidths,
                        columnWidths: columnWidths,
                        hiddenColumnNames: hiddenColumnNames,
                        setHiddenColumnNames: setHiddenColumnNames
                    }}
                    tableHeader={TableHeader}
                    filterActionType={"DISCOUNTS_FILTER"}
                    classInputContent={classes.inputContent}
                    rowComponent={({row, tableRow, children}) => (
                        <Table.Row
                            tableRow={tableRow}
                            className={classes.tableRow}
                            hover
                            row={row}
                            children={children}
                            onClick={() => {
                                history.push(`/supplier/discount/edit?id=${discounts.data[tableRow.rowId].id}`)
                            }}
                        />
                    )}
                    filters={{
                        items: {
                            nullButton: true
                        },
                        conditions: {
                            nullButton: true
                        }
                    }}
                />
            </Grid>
        </Grid>
    ) : null
}
