import React from "react"

import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@material-ui/core"

import {Submit} from "../Button/Submit"

export const DialogWithVariantsChoice = (props) => {
    const {open, onClose, textTitle, buttons, textSubTitle, maxWidth, direction} = props

    return (
        <Dialog
            open={Boolean(open)}
            onClose={onClose}
            fullWidth={true}
            maxWidth={maxWidth}
        >
            <DialogTitle>{textTitle}</DialogTitle>
            <DialogContent>
                <Typography>{textSubTitle}</Typography>
            </DialogContent>
            <DialogActions>
                <Grid container direction={direction} justify="space-between" alignItems="stretch" spacing={2}>
                    {buttons.map((button, index) => (
                        <Grid key={index} item xs={button.xs}>
                            <Submit
                                variant="contained"
                                color={button.hasOwnProperty('color') ? button.color : "primary"}
                                type="submit"
                                onClick={button.callback}
                            >
                                {button.text}
                            </Submit>
                        </Grid>
                    ))}
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
