import * as AppStorage from "../../App/helpers/storage"

export default function company(state = {companies: {data: [], meta: {total: 0, per_page: 15, current_page: 1}, filter: AppStorage.get('filterContractPast') ? JSON.parse(AppStorage.get('filterContractPast')) : {name: '', number: '', kpp: ''}}}, action) {
    switch (action.type) {
        case "CONTRACT_ALL_COMPANIES_REQUEST":
            return state
        case "CONTRACT_ALL_COMPANIES":
            return {
                ...state,
                ...{companies: {...action.payload, ...{filter: state.companies.filter}}}
            }
        case "CONTRACT_SUPPLIERS_COMPANIES":
            return {
                ...state,
                ...{companies: {...action.payload, ...{filter: state.companies.filter}}}
            }
        case "CONTRACT_POST_AUDIT_SUPPLIERS_COMPANIES":
            return {
                ...state,
                ...action.payload,
                ...{filter: state.companies.filter}
            }
        case "CONTRACT_ALL_COMPANIES_FAILURE":
            return state
        case "CONTRACT_ALL_COMPANIES_CLEAR":
            return  {
                ...state,
                ...{companies: {data: [], meta: {total: 0, per_page: 15, current_page: 1}, filter: state.companies.filter}}
            }
        case "CONTRACT_SUPPLIERS_COMPANIES_CLEAR":
            return  {
                ...state,
                ...{companies: {data: [], meta: {total: 0, per_page: 15, current_page: 1}, filter: state.companies.filter}}
            }
        case "CONTRACT_ALL_COMPANIES_FILTER":
            AppStorage.set('filterContractPast', JSON.stringify(action.payload))
            return {
                ...state,
                ...{companies: {...state.companies, ...{filter: action.payload}}},
            }
        default:
            return state
    }
}
