import React from 'react'

import {Input, MenuItem, Select, makeStyles} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    pageSizeSelector: {
        ...theme.typography.body2,
        paddingRight: theme.spacing(3),
        display: 'flex',
        alignItems: 'center'
    },
    label: {
        paddingRight: theme.spacing(3)
    },
    inputRoot: {
        fontSize: theme.spacing(1.75),
        textAlign: 'right'
    },
    selectIcon: {
        top: 2
    },
    selectMenu: {
        '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
            position: 'absolute !important'
        }
    },
    '@media (max-width: 768px)': {
        label: {
            display: 'none'
        },
        pageSizeSelector: {
            paddingRight: theme.spacing(2)
        }
    }
}))

export const PageSizeSelector = ({
    pageSize,
    onPageSizeChange,
    pageSizes,
    getMessage,
}) => {
    const classes = useStyles()

    const showAll = getMessage('showAll')
    return (
        <div className={classes.pageSizeSelector}>
            <span className={classes.label}>
                {getMessage('rowsPerPage')}
            </span>
            <Select
                value={pageSize}
                onChange={event => onPageSizeChange(event.target.value)}
                classes={{
                    icon: classes.selectIcon,
                }}
                MenuProps={{
                    className: classes.selectMenu,
                }}
                input={(
                    <Input
                        disableUnderline
                        classes={{ root: classes.inputRoot }}
                    />
                )}
            >
                {pageSizes.map(item => (
                    <MenuItem key={item} value={item}>
                        {item !== 0 ? item : showAll }
                    </MenuItem>
                ))}
            </Select>
        </div>
    )
}
