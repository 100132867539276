import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, FieldArray, Form, Formik, useFormikContext} from "formik"

import {Grid, makeStyles, MenuItem, Select, Typography,} from "@material-ui/core"
import {DeleteForever as DeleteForeverIcon, Add as AddIcon} from "@material-ui/icons"
import {Pagination} from "@material-ui/lab"

import {Submit} from "../../../../App/components/Button/Submit"
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon"
import {TextFieldWithError as CustomTextField} from "../../../../App/components/Inputs/TextFieldWithError"
import {WarehouseActions} from "../../../actions/warehouse"
import {DialogWithChoices} from "../../../../App/components/DialogWithChoices/DialogWithChoices"
import {getValidationSchema} from "./validation/warehouses"

const useStyles = makeStyles((theme) => ({
    rootContainer: {},
    form: {
        width: "100%",
    },
    contentContainer: {
        height: "calc(100vh - 298px)",
        overflowX: "hidden",
        overflowY: "auto",
    },
    fullWidth: {
        width: "100%",
    },
    warehousesContainer: {
        width: "100%",
        padding: "10px 15px",
    },
    footerContent: {
        height: "70px",
        "background-color": "#e7eaee",
        padding: "0 20px !important",
    },
    footerContainer: {
        "padding-top": "7px",
        "padding-right": "5px",
        padding: "12px",
    },
    footerContainerForm: {
        "padding-top": "17px",
        "padding-right": "5px",
        padding: "12px",
    },
    footer: {
        width: "100%",
    },
    addWarehouseBlock: {
        margin: 0,
        paddingRight: "35px",
    },
}))

const countOnPageAr = [5, 10, 25, 100]

export const Warehouses = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { companyId, setDirty, isAdmin } = props
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const { warehouses } = useSelector((state) => state.warehouse)
    const [open, setOpen] = useState(false)
    const [initValues, setInitValues] = useState({})
    const [action, setAction] = useState("")

    const countOnPageNodes = countOnPageAr.map((item, index) => (
        <MenuItem value={index}>{item}</MenuItem>
    ))

    const changePage = (event, page) => {
        setPage(page);
    };

    useEffect(() => {
        const getWareHouses = async () => {
            return await dispatch(isAdmin ? WarehouseActions.getByAdmin(companyId, {limit: limit, page: page}) : WarehouseActions.get({limit: limit, page: page}))
        }
        getWareHouses().then((resp) => {
            setInitValues({
                warehouses: resp && resp.data && resp.data.length > 0 ? resp.data : [{name: "", address: ""}],
                company_id: companyId,
                limit: limit,
                page: page,
                deleteIds: [],
            })
        })

        return () => {
            dispatch({ type: "WAREHOUSE_CLEAR" })
        }
    }, [dispatch, page, limit, isAdmin, companyId])

    const changeLimitOnPage = (event) => {
        if (warehouses && warehouses.meta.total < countOnPageAr[event.target.value]) {
            setPage(1);
        }
        setLimit(countOnPageAr[event.target.value]);
    }

    const AutoDirty = () => {
        const { dirty } = useFormikContext()

        useEffect(() => {
            setDirty(dirty)
        }, [dirty])

        return null
    };

    const handleClose = () => {
        setOpen(false)
    }

    const onContinue = (e, resetForm) => {
        setOpen(false)
        resetForm({
            values: initValues,
        })
        switch (action.name) {
            case "changeLimitOnPage":
                if (warehouses && warehouses.meta.total < countOnPageAr[action.value]) {
                    setPage(1);
                }
                setLimit(countOnPageAr[action.value]);
                break;
            default:
                setPage(action.value);
        }
    }

    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        return dispatch(WarehouseActions.edit(values)).then(
            () => {
                setSubmitting(false);
                resetForm({values: values});
                return dispatch(isAdmin ? WarehouseActions.getByAdmin(companyId, {limit: limit, page: page}) : WarehouseActions.get({limit: limit, page: page})).then((resp) => {
                    setInitValues({
                        warehouses: resp && resp.data && resp.data.length > 0 ? resp.data : [{name: "", address: ""}],
                        company_id: companyId,
                        limit: limit,
                        page: page,
                        deleteIds: [],
                    });
                });
            },
            (errors) => {
                if (errors) {
                }
                setSubmitting(false);
            }
        );
    };

    return (
        <Grid container direction="row" className={classes.rootContainer}>
            <Formik
                initialValues={initValues ? initValues : {}}
                validationSchema={getValidationSchema()}
                enableReinitialize
                onSubmit={handleSubmit}
            >
                {({
                    dirty,
                    values,
                    isSubmitting,
                    setFieldValue,
                    resetForm,
                }) => (
                    <Form className={classes.form}>
                        <Grid item className={classes.contentContainer}>
                            <Grid container direction="row" justify={"flex-start"} alignItems={"flex-start"}>
                                <FieldArray name="warehouses" render={(arrayHelpers) => (
                                        <React.Fragment>
                                            {values.hasOwnProperty("warehouses") && values.warehouses.length === 0 && (
                                                    <Grid item xs={12}>
                                                        <Grid container direction="row" justify={"flex-end"} alignItems={"flex-end"} className={classes.addWarehouseBlock} spacing={2}>
                                                            <Grid item>
                                                                <Typography>Добавить склад</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <ButtonIcon
                                                                    onClick={() => {
                                                                        arrayHelpers.push({name: "", address: ""})}
                                                                    }
                                                                    key={"addRow"}
                                                                    children={[(<AddIcon key={"AddIcon"} className={classes.svg}/>)]}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            {values.hasOwnProperty("warehouses") && values.warehouses.map((warehouse, index) => {
                                                        return (
                                                            <Grid item xs={12} key={index}>
                                                                <Grid container direction="row" className={classes.warehousesContainer} spacing={2} key={index}>
                                                                    <Grid item xs={5} className={classes.column}>
                                                                        <Field
                                                                            fullWidth name={`warehouses.${index}.name`} type="text" label="Склад" component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={5} className={classes.column}>
                                                                        <Field
                                                                            fullWidth name={`warehouses.${index}.address`} type="text" label="Адрес склада" component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <Grid container direction="row" className={classes.fullWidth} spacing={2}>
                                                                            <Grid item>
                                                                                <ButtonIcon
                                                                                    onClick={() => {
                                                                                        if (values.warehouses[index].hasOwnProperty("id")) {
                                                                                            setFieldValue(
                                                                                                "deleteIds",
                                                                                                [
                                                                                                    ...values.deleteIds,
                                                                                                    values.warehouses[index].id,
                                                                                                ])
                                                                                        }
                                                                                        arrayHelpers.remove(index);
                                                                                        if (index === 0 && values.warehouses.length === 1) {
                                                                                            resetForm({
                                                                                                    values: {
                                                                                                        warehouses: [],
                                                                                                        company_id: companyId,
                                                                                                        limit: limit,
                                                                                                        page: page,
                                                                                                        deleteIds: [],
                                                                                                    }
                                                                                                })
                                                                                        }
                                                                                    }}
                                                                                    key={"DeleteForeverIcon"}
                                                                                    children={[<DeleteForeverIcon key={"DeleteForeverIcon"} className={classes.svg}/>]}
                                                                                >

                                                                                </ButtonIcon>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <ButtonIcon
                                                                                    onClick={() => {
                                                                                        arrayHelpers.push({name: "", address: ""})}
                                                                                   }
                                                                                    key={"AddIcon"}
                                                                                    children={[<AddIcon key={"AddIcon"} className={classes.svg}/>]}
                                                                                >

                                                                                </ButtonIcon>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                        </React.Fragment>
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid item className={classes.footer}>
                            <Grid container direction="column" justify="center" alignItems="stretch">
                                <Grid item className={classes.footerContent}>
                                    <Grid container className={classes.footerContainerForm} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                        {warehouses && warehouses.hasOwnProperty("meta") && (
                                                <Grid item>
                                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                                        <Grid item>
                                                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                                                <Typography>Записей на странице:&nbsp;</Typography>
                                                                <Select
                                                                    labelId="countOnPage"
                                                                    id="countOnPage"
                                                                    classes={{select: classes.select,}}
                                                                    value={countOnPageAr.indexOf(limit)}
                                                                    onChange={(e) => {
                                                                        if (dirty) {
                                                                            setAction({name: "changeLimitOnPage", value: e.target.value})
                                                                            return setOpen(true)
                                                                        }
                                                                        changeLimitOnPage(e)
                                                                    }}
                                                                    children={countOnPageNodes}
                                                                >

                                                                </Select>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography>{warehouses.meta.from ? warehouses.meta.from : 0} - {warehouses.meta.to}из{warehouses.meta.total}</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Pagination
                                                                page={warehouses.meta.current_page}
                                                                count={warehouses.meta.last_page}
                                                                onChange={(e, page) => {
                                                                    if (dirty) {
                                                                        setAction({name: "changePage", value: page})
                                                                        return setOpen(true)
                                                                    }
                                                                    changePage(e, page)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        <Grid item>
                                            <Submit
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={isSubmitting || !dirty}
                                            >
                                                Сохранить
                                            </Submit>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <AutoDirty />
                        <DialogWithChoices open={open} onClose={handleClose} onContinue={(e) => onContinue(e, resetForm)}/>
                    </Form>
                )}
            </Formik>
        </Grid>
    );
};
