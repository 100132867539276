import {AccountService} from "../services/account"

export const AccountActions = {
    account,
    edit
}

function account(params= {include: "permissions,roles.permissions,company.purchasingDepartment,company.legalDetail.city.region.country,company.contactInformation,company.settingsMarketplace"}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "ACCOUNT_REQUEST"})

        AccountService.account(params)
            .then(
                response => {
                    dispatch({type: "ACCOUNT", payload: response.account})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response.account)
                },
                error => {
                    dispatch({type: "ACCOUNT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function edit(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})
        dispatch({type: "ACCOUNT_EDIT_REQUEST"})

        AccountService.edit(values)
            .then(
                account => {
                    dispatch({type: "ACCOUNT", payload: account})
                    dispatch({type: "FILLING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "ACCOUNT_EDIT_FAILURE"})
                    dispatch({type: "FILLING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )

    })
}
