export default function filters(state = {
    planning_schedules: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    planning_purchase_plans: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    planning_purchases: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    planning_edit_purchases: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    planning_joint_purchases: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    planning_lots: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    planning_products: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    planning_purchase_plan_purchase_version: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    planning_purchase_plans_edit_requests: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    planning_purchase_plan_products: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    planning_purchase_plan_purchases_purchases: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    planning_purchase_plan_purchases_products: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    planning_purchase_plan_log: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        }
    },
    planning_purchase_log: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        }
    },
    planning_purchase_plan_purchase_lots: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    planning_purchase_plan_purchase_lot_products: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    standards: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    reclamations: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50,
            search: null
        },
    },
    orders: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50,
            search: null
        },
    },
    auto_orders: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    marketplace_requests: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    marketplace_orders: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    contracts: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    contracts_past: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50,
            searchKpp: null,
            searchNumber: null,
            searchName: null,
        },
    },
    files: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    discounts: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    invoices: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    integrations: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    operators: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    customers: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            search: null,
            limitRows: 50
        },
    },
    supervisors: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50,
            search: null
        },
    },
    supervisor: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    suppliers: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            search: null,
            limitRows: 50
        },
    },
    prices: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    employees: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    operator_lots: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        },
    },
    territories: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        }
    },
    institutions_budgets: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        }
    },
    company_subscriptions: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        }
    },
    supplier_prices: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50
        }
    },
    notifications: {
        filter: {
            sort: {
                direction: null,
                name: null
            },
            limitRows: 50,
            selectedItems: []
        }
    }
}, action) {
    switch (action.type) {
        case "PLANNING_SCHEDULES_FILTER":
            return {
                ...state,
                planning_schedules: {
                    filter: {
                        ...state['planning_schedules'].filter,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_SCHEDULES_FILTER_CLEAR":
            return {
                ...state,
                planning_schedules: {
                    filter: {
                        sort: {
                            name: null,
                            direction: null,
                        },
                        limitRows: 50,
                        ...action.payload
                    }
                }
            }
        case "PURCHASE_PLAN_FILTER":
            return {
                ...state,
                planning_purchase_plans: {
                    filter: {
                        ...state['planning_purchase_plans'].filter,
                        ...action.payload
                    }
                }
            }
        case "PURCHASE_PLAN_FILTER_CLEAR":
            return {
                ...state,
                planning_purchase_plans: {
                    filter: {
                        sort: {
                            name: null,
                            direction: null,
                        },
                        limitRows: 50,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_PURCHASE_PLAN_PURCHASES_PURCHASES_FILTER":
            return {
                ...state,
                planning_purchase_plan_purchases_purchases: {
                    filter: {
                        ...state['planning_purchase_plan_purchases_purchases'].filter,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_PURCHASE_PLAN_PURCHASES_PURCHASES_FILTER_CLEAR":
            return {
                ...state,
                planning_purchase_plan_purchases_purchases: {
                    filter: {
                        sort: {
                            name: null,
                            direction: null,
                        },
                        limitRows: 50,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_PURCHASE_PLAN_PURCHASES_PRODUCTS_FILTER":
            return {
                ...state,
                planning_purchase_plan_purchases_products: {
                    filter: {
                        ...state['planning_purchase_plan_purchases_products'].filter,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_PURCHASE_PLAN_PURCHASES_PRODUCTS_FILTER_CLEAR":
            return {
                ...state,
                planning_purchase_plan_purchases_products: {
                    filter: {
                        sort: {
                            name: null,
                            direction: null,
                        },
                        limitRows: 50,
                        ...action.payload
                    }
                }
            }
        case "PURCHASE_PLAN_EDIT_REQUESTS_FILTER":
            return {
                ...state,
                planning_purchase_plans_edit_requests: {
                    filter: {
                        ...state['planning_purchase_plans_edit_requests'].filter,
                        ...action.payload
                    }
                }
            }
        case "PURCHASE_PLAN_EDIT_REQUESTS_FILTER_CLEAR":
            return {
                ...state,
                planning_purchase_plans_edit_requests: {
                    filter: {
                        sort: {
                            name: null,
                            direction: null,
                        },
                        limitRows: 50,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_PURCHASES_FILTER":
            return {
                ...state,
                planning_purchases: {
                    filter: {
                        ...state['planning_purchases'].filter,
                        ...action.payload
                    },
                }
            }
        case "PLANNING_PURCHASES_FILTER_CLEAR":
            return {
                ...state,
                planning_purchases: {
                    filter: {
                        sort: {
                            name: null,
                            direction: null,
                        },
                        limitRows: 50,
                        ...action.payload
                    }
                }
            }
        case "DIFF_PURCHASE_VERSIONS_FILTER":
            return {
                ...state,
                planning_purchase_plan_purchase_version: {
                    filter: {
                        ...state['planning_purchase_plan_purchase_version'].filter,
                        ...action.payload
                    }
                }
            }
        case "DIFF_PURCHASE_VERSIONS_FILTER_CLEAR":
            return {
                ...state,
                planning_purchase_plan_purchase_version: {
                    filter: {
                        sort: {
                            direction: null,
                            name: null
                        },
                        limitRows: 50,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_EDIT_PURCHASES_FILTER":
            return {
                ...state,
                planning_edit_purchases: {
                    filter: {
                        ...state['planning_edit_purchases'].filter,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_EDIT_PURCHASES_FILTER_CLEAR":
            return {
                ...state,
                planning_edit_purchases: {
                    filter: {
                        sort: {
                            name: null,
                            direction: null,
                        },
                        limitRows: 50,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_JOINT_PURCHASES_FILTER":
            return {
                ...state,
                planning_joint_purchases: {
                    filter: {
                        ...state['planning_joint_purchases'].filter,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_JOINT_PURCHASES_FILTER_CLEAR":
            return {
                ...state,
                planning_joint_purchases: {
                    filter: {
                        sort: {
                            name: null,
                            direction: null,
                        },
                        limitRows: 50,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_LOTS_FILTER":
            return {
                ...state,
                planning_lots: {
                    filter: {
                        ...state['planning_lots'].filter,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_LOTS_FILTER_CLEAR":
            return {
                ...state,
                planning_lots: {
                    filter: {
                        sort: {
                            name: null,
                            direction: null,
                        },
                        limitRows: 50,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_PURCHASE_PLAN_PURCHASE_LOTS_FILTER":
            return {
                ...state,
                planning_purchase_plan_purchase_lots: {
                    filter: {
                        ...state['planning_purchase_plan_purchase_lots'].filter,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_PURCHASE_PLAN_PURCHASE_LOTS_FILTER_CLEAR":
            return {
                ...state,
                planning_purchase_plan_purchase_lots: {
                    filter: {
                        sort: {
                            name: null,
                            direction: null,
                        },
                        limitRows: 50,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_PURCHASE_PLAN_LOG_FILTER":
            return {
                ...state,
                planning_purchase_plan_log: {
                    filter: {
                        ...state['planning_purchase_plan_log'].filter,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_PURCHASE_PLAN_LOG_FILTER_CLEAR":
            return {
                ...state,
                planning_purchase_plan_log: {
                    filter: {
                        sort: {
                            name: null,
                            direction: null,
                        },
                        limitRows: 50,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_PURCHASE_LOG_FILTER":
            return {
                ...state,
                planning_purchase_log: {
                    filter: {
                        ...state['planning_purchase_log'].filter,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_PURCHASE_LOG_FILTER_CLEAR":
            return {
                ...state,
                planning_purchase_log: {
                    filter: {
                        sort: {
                            name: null,
                            direction: null,
                        },
                        limitRows: 50,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_PRODUCTS_FILTER":
            return {
                ...state,
                planning_products: {
                    filter: {
                        ...state['planning_products'].filter,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_PRODUCTS_FILTER_CLEAR":
            return {
                ...state,
                planning_products: {
                    filter: {
                        sort: {
                            name: null,
                            direction: null,
                        },
                        limitRows: 50,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_PURCHASE_PLAN_PRODUCTS_FILTER":
            return {
                ...state,
                planning_purchase_plan_products: {
                    filter: {
                        ...state['planning_purchase_plan_products'].filter,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_PURCHASE_PLAN_PRODUCTS_FILTER_CLEAR":
            return {
                ...state,
                planning_purchase_plan_products: {
                    filter: {
                        sort: {
                            name: null,
                            direction: null,
                        },
                        limitRows: 50,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_PURCHASE_PLAN_PURCHASE_LOT_PRODUCTS_FILTER":
            return {
                ...state,
                planning_purchase_plan_purchase_lot_products: {
                    filter: {
                        ...state['planning_purchase_plan_purchase_lot_products'].filter,
                        ...action.payload
                    }
                }
            }
        case "PLANNING_PURCHASE_PLAN_PURCHASE_LOT_PRODUCTS_FILTER_CLEAR":
            return {
                ...state,
                planning_purchase_plan_purchase_lot_products: {
                    filter: {
                        sort: {
                            name: null,
                            direction: null,
                        },
                        limitRows: 50,
                        ...action.payload
                    }
                }
            }
        case "STANDARDS_FILTER":
            return {
                ...state,
                standards: {
                    filter: {
                        ...state['standards'].filter,
                        ...action.payload
                    }
                }
            }
        case "STANDARDS_FILTER_CLEAR":
            return {
                ...state,
                standards: {
                    filter: {
                        sort: {
                            name: null,
                            direction: null,
                        },
                        limitRows: 50,
                        ...action.payload
                    }
                }
            }
        case "REСLAMATIONS_FILTER":
            return {
                ...state,
                reclamations: {
                    filter: {
                        ...state['reclamations'].filter,
                        ...action.payload
                    }
                }
            }
        case "ORDERS_FILTER":
            return {
                ...state,
                orders: {
                    filter: {
                        ...state['orders'].filter,
                        ...action.payload
                    }
                }
            }
        case "AUTO_ORDERS_FILTER":
            return {
                ...state,
                auto_orders: {
                    filter: {
                        ...state['auto_orders'].filter,
                        ...action.payload
                    }
                }
            }
        case "MARKETPLACE_REQUESTS_FILTER":
            return {
                ...state,
                marketplace_requests: {
                    filter: {
                        ...state['marketplace_requests'].filter,
                        ...action.payload
                    }
                }
            }
        case "MARKETPLACE_ORDERS_FILTER":
            return {
                ...state,
                marketplace_orders: {
                    filter: {
                        ...state['marketplace_orders'].filter,
                        ...action.payload
                    }
                }
            }
        case "CONTRACTS_FILTER":
            return {
                ...state,
                contracts: {
                    filter: {
                        ...state['contracts'].filter,
                        ...action.payload
                    }
                }
            }
        case "CONTRACTS_PAST_FILTER":
            return {
                ...state,
                contracts_past: {
                    filter: {
                        ...state['contracts_past'].filter,
                        ...action.payload
                    }
                }
            }
        case "FILES_FILTER":
            return {
                ...state,
                files: {
                    filter: {
                        ...state['files'].filter,
                        ...action.payload
                    }
                }
            }
        case "DISCOUNTS_FILTER":
            return {
                ...state,
                discounts: {
                    filter: {
                        ...state['discounts'].filter,
                        ...action.payload
                    }
                }
            }
        case "INVOICES_FILTER":
            return {
                ...state,
                invoices: {
                    filter: {
                        ...state['invoices'].filter,
                        ...action.payload
                    }
                }
            }
        case "INTEGRATIONS_FILTER":
            return {
                ...state,
                integrations: {
                    filter: {
                        ...state['integrations'].filter,
                        ...action.payload
                    }
                }
            }
        case "OPERATORS_FILTER":
            return {
                ...state,
                operators: {
                    filter: {
                        ...state['operators'].filter,
                        ...action.payload
                    }
                }
            }
        case "CUSTOMERS_FILTER":
            return {
                ...state,
                customers: {
                    filter: {
                        ...state['customers'].filter,
                        ...action.payload
                    }
                }
            }
        case "SUPERVISORS_FILTER":
            return {
                ...state,
                supervisors: {
                    filter: {
                        ...state['supervisors'].filter,
                        ...action.payload
                    }
                }
            }
        case "SUPERVISOR_FILTER":
            return {
                ...state,
                supervisor: {
                    filter: {
                        ...state['supervisor'].filter,
                        ...action.payload
                    }
                }
            }
        case "SUPPLIERS_FILTER":
            return {
                ...state,
                suppliers: {
                    filter: {
                        ...state['suppliers'].filter,
                        ...action.payload
                    }
                }
            }
        case "PRICES_FILTER":
            return {
                ...state,
                prices: {
                    filter: {
                        ...state['prices'].filter,
                        ...action.payload
                    }
                }
            }
        case "EMPLOYEES_FILTER":
            return {
                ...state,
                employees: {
                    filter: {
                        ...state['employees'].filter,
                        ...action.payload
                    }
                }
            }
        case "OPERATOR_LOTS_FILTER":
            return {
                ...state,
                operator_lots: {
                    filter: {
                        ...state['operator_lots'].filter,
                        ...action.payload
                    }
                }
            }
        case "TERRITORIES_FILTER":
            return {
                ...state,
                territories: {
                    filter: {
                        ...state['territories'].filter,
                        ...action.payload
                    }
                }
            }
        case "INSTITUTIONS_BUDGETS_FILTER":
            return {
                ...state,
                institutions_budgets: {
                    filter: {
                        ...state['institutions_budgets'].filter,
                        ...action.payload
                    }
                }
            }
        case "COMPANY_SUBSCRIPTIONS_FILTER":
            return {
                ...state,
                company_subscriptions: {
                    filter: {
                        ...state['company_subscriptions'].filter,
                        ...action.payload
                    }
                }
            }
        case "SUPPLIER_PRICES_FILTER":
            return {
                ...state,
                supplier_prices: {
                    filter: {
                        ...state['supplier_prices'].filter,
                        ...action.payload
                    }
                }
            }
        case "NOTIFICATIONS_FILTER":
            return {
                ...state,
                notifications: {
                    filter: {
                        ...state['notifications'].filter,
                        ...action.payload
                    }
                }
            }
        default:
            return state
    }
}
