import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import format from "date-fns/format"
import clsx from "clsx"

import {
    Button,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    TextField, Typography,
} from "@material-ui/core"
import {DoneAll, Search as SearchIcon} from '@material-ui/icons'

import {AuthorizationService} from "../../../../Auth/services/authorization"
import {CustomTable} from "../../../../App/components/Table/Table";
import {TableHeader} from "../../../../App/components/Table/TableHeader";
import {SettingsActions} from "../../../../Settings/actions/settings";
import {Table} from "@devexpress/dx-react-grid-material-ui";
import {localState} from "../../../../App/components/LocalState";
import {useDebounce} from "use-debounce";
import {ColumnChooser} from "../../../../App/components/Table/ColumnChooser";
import {InvoiceActions} from "../../../../Account/actions/invoice";
import * as AppStorage from "../../../../App/helpers/storage";

const useStyles = makeStyles(() => ({
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "text-decoration": "none"
    },
    search: {
        "padding": "20px 30px 0"
    },
    container: {
        "padding": "0 30px 10px"
    },
    fullWidth: {
        maxWidth: '100%',
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    searchLabel: {
        "margin-bottom": "14px",
    },
    button: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "#898989",
        },
        "width": "auto",
        "max-height": "35px",
        "max-width": "auto",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "#fff",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-bottom": "3px solid #898989",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "#616161",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
            marginTop: "5px",
        }
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 314px)",
        "min-height": "calc(100vh- 314px)"
    },
}))

export const Invoices = (props) => {
    const { company } = props
    const classes = useStyles()
    const {account} = useSelector(state => state.account)
    const [invoices, setInvoices] = useState(localState)
    const [expiration, setExpiration] = useState(null)
    const [tableRef, setTableRef] = useState(null)
    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)
    const {filter} = useSelector(state => state.filters['invoices'])

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)

    const dispatch = useDispatch()

    const [columns, setColumns] = useState([
        {name: 'created', title: 'Дата создания'},
        {name: 'number', title: 'Номер счета'},
        {name: 'name', title: 'Наименование (назначение платежа)'},
        {name: 'amount', title: 'Сумма платежа'},
        {name: 'status', title: 'Статус', filter: 'status'},
        {name: 'button', title: ' '}
    ])

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'created', width: 200},
        {columnName: 'number', width: 200},
        {columnName: 'name', width: 500},
        {columnName: 'amount', width: 200},
        {columnName: 'status', width: 200},
        {columnName: 'button', width: 200}
    ])

    const [columnOrder, setColumnOrder] = useState([
        'created',
        'number',
        'name',
        'amount',
        'status',
        'button',
    ])

    const [initialize, setInitialize] = useState(false);

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    useEffect(() => {
        dispatch(InvoiceActions.subscription(company.id)).then(subscription => {
            setExpiration(subscription.expiration)
        })
        // eslint-disable-next-line
    }, [])

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...(searchRequest ? {search: searchRequest} : {}),
        }
    }

    useEffect(() => {
        if (!initialize) {
            dispatch(SettingsActions.table('invoices')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                dispatch({type: "FILTER_CLEAR"})
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'invoices',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    const getInvoices = async () => {
        let params = {
            limit: rowsPerPage,
            page: page,
            company: company.id,
            ...getParams()
        }
        return await dispatch(InvoiceActions.invoices(params))
    }

    useEffect(() => {
        getInvoices().then(invoices => {
            setInvoices(invoices)
            tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
        })
        // eslint-disable-next-line
    }, [dispatch, company.id, page, rowsPerPage, filter, searchRequest]);

    const handlePagesSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: 'INVOICES_FILTER', payload: {limitRows: newRowsPerPage}})
    }

    return (
        <React.Fragment>
            <Grid item className={classes.fullWidth}>
                <Grid item className={classes.search}>
                    <Grid container direction="row" justify="center" alignItems="stretch" spacing={1}>
                        <Grid item style={{'width': 'calc(100% - 38px)'}}>
                            <TextField
                                id="search"
                                variant="standard"
                                name={'search'}
                                placeholder={'Поиск'}
                                fullWidth={true}
                                margin={'normal'}
                                onChange={(event) => {
                                    setSearch(event.target.value)
                                }}
                                classes={{
                                    root: clsx(classes.filterLabel, classes.searchLabel)
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon  style={{color: '#c1c1c1'}}/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item style={{'padding-top': '12px'}}>
                            <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                    <CustomTable
                        meta={invoices.meta}
                        tableName={'invoices'}
                        rows={invoices?.data.map(invoice => ({
                            created: format(new Date(invoice.created), 'dd.MM.yyyy'),
                            number: invoice.number,
                            name: invoice.name,
                            amount: parseFloat(invoice.amount).toLocaleString('ru-RU', {
                                style: 'currency',
                                currency: 'RUB'
                            }),
                            status: invoice.status.name,
                            button: (AuthorizationService.roles(account, 'admin') && (invoice.status.index == 2)) ? (
                                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <IconButton
                                            onClick={(e) => {
                                                const confirm = async () => {
                                                    return await dispatch(InvoiceActions.confirm(invoice.id))
                                                }

                                                confirm().then(invoice => {
                                                    setInvoices({
                                                        ...invoices,
                                                        data: invoices.data.map(item => ((item.id === invoice.id) ? invoice : item))
                                                    })
                                                })
                                            }}
                                        >
                                            <DoneAll/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container direction="row" justify="flex-start" alignItems="center">
                                    <Button
                                        color="primary"
                                        type="button"
                                        href={process.env.REACT_APP_HOST_API + `/company/invoice/${invoice.id}/download?type=bill&token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                        download
                                    >
                                        Счет
                                    </Button>
                                    {invoice.completed ? (
                                        <Button
                                            color="primary"
                                            type="button"
                                            href={process.env.REACT_APP_HOST_API + `/company/invoice/${invoice.id}/download?type=report&token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                            download
                                        >
                                            Акт
                                        </Button>
                                    ) : null}
                                </Grid>
                            )
                        }))
                        }
                        columns={columns}
                        page={{page: page, setPage: setPage, rowsPerPage: rowsPerPage, handlePageSizeChange: handlePagesSizeChange}}
                        setTableRef={setTableRef}
                        columnsSettings={{
                            columnOrder: columnOrder,
                            setColumnOrder: setColumnOrder,
                            setColumnWidths: setColumnWidths,
                            columnWidths: columnWidths,
                            hiddenColumnNames: hiddenColumnNames,
                            setHiddenColumnNames: setHiddenColumnNames
                        }}
                        tableHeader={TableHeader}
                        filterActionType={'INVOICES_FILTER'}
                        classInputContent={classes.inputContent}
                        rowComponent={({row, tableRow, children}) => (
                            <Table.Row
                                tableRow={tableRow}
                                children={children}
                                className={classes.tableRow}
                                row={row}
                            />
                        )}
                        filters={{
                            button: {
                                nullButton: true
                            }
                        }}
                        actions={[
                            <Grid item>
                                {expiration ? <Typography>Доступ до: {expiration}</Typography> : null}
                            </Grid>
                        ]}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
