import {HttpService} from "../../App/services/http"

export const CompanyService = {
    companies,
    suppliers,
    postAuditSuppliers
}

function companies(params) {
    return HttpService.get(`/contract/companies/all`, params)
}

function suppliers(params) {
    return HttpService.get(`/contract/companies/suppliers`, params)
}

function postAuditSuppliers(params) {
    return HttpService.get(`/contract/companies/postAuditSuppliers`, params)
}
