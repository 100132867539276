import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce} from 'use-debounce'
import clsx from "clsx"

import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputAdornment,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField
} from "@material-ui/core"
import {Search as SearchIcon} from "@material-ui/icons"

import {Submit} from "../../../App/components/Button/Submit"
import {PurchasePlanItemsActions} from "../../actions/purchasePlanItems"
import {getMonthName} from "../../../App/helpers/date"
import {ItemsActions} from "../../actions/items"
import {localState} from "../../../App/components/LocalState";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        "width": "100%"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    content: {
        "padding": "0 !important"
    },
    footer: {
        "background-color": "#e7eaee",
        "justify-content": "flex-start"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    filterLabel: {
        "background": "#fff",
        "margin-bottom": "0 !important",
        "margin-top": "5px !important",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        },
        "& input": {
            "padding": "6px 15px 7px"
        }
    }
}))

const columns = [
    {
        id: '0',
        label: 'Номер ОЗ',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '1',
        label: 'Наименование',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '2',
        label: 'Начальное наименование',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '3',
        label: 'Категория',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '4',
        label: 'Ед. измерения',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '5',
        label: 'Источник финансирования',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '6',
        label: 'МНН',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '7',
        label: 'Закупка',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '8',
        label: 'Лот',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '9',
        label: 'Цена',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '10',
        label: 'Год',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '11',
        label: 'Месяц',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '12',
        label: 'Кол-во',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '13',
        label: 'Сумма',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '14',
        label: 'ЖД',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '15',
        label: 'ЧУЗ',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '16',
        label: 'Описание',
        format: (value) => value.toLocaleString(),
    }
];

export const ProductsDialog = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {purchase} = props
    const {units} = useSelector(state => state.purchasePlans)
    const [products, setProducts] = useState(localState)
    const [meta, setMeta] = useState(localState.meta)
    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)

    const [open, setOpen] = useState(props.open);
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [page, setPage] = useState(0)
    const containerRef = useRef(null)

    const [selected, setSelected] = React.useState([])
    const isSelected = (product) => (selected.find(item => (item.id === product.id)) !== undefined)

    useEffect(() => {
        if (props.open) {
            const getProducts = async () => {
                return await dispatch(PurchasePlanItemsActions.items({
                    limit: rowsPerPage,
                    page: page + 1,
                    type: props.open,
                    joint: false,
                    simple: true,
                    purchase_year: purchase.planing_date_to_publish_notification.replace( /(\d{2}).(\d{2}).(\d{4})/, "$3"),
                    purchase_plan_category: purchase.category_id,
                    ...(searchRequest ? {search: searchRequest} : {}),
                }))
            }

            const getTotal = async () => {
                let params = {
                    limit: rowsPerPage,
                    page: page + 1,
                    type: props.open,
                    joint: false,
                    purchase_year: purchase.planing_date_to_publish_notification.replace( /(\d{2}).(\d{2}).(\d{4})/, "$3"),
                    purchase_plan_category: purchase.category_id,
                    ...(searchRequest ? {search: searchRequest} : {}),
                }

                return await dispatch(PurchasePlanItemsActions.total(params))
            }

            getTotal().then(response => {
                setMeta({
                    ...meta,
                    ...response
                })
            })

            getProducts().then(response => {
                setProducts(response)
                containerRef.current && containerRef.current.scrollTo({ behavior: 'smooth', top: 0 })
                setOpen(props.open)
            })
        } else {
            setSelected([])
            setOpen(props.open)
        }
        // eslint-disable-next-line
    }, [dispatch, props.open, rowsPerPage, page, searchRequest])

    useEffect(() => {
        if (open) {
            setSelected([])
        }
        props.setDialog(open)
    }, [props, open])

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelected(products.data.map((product) => product))
        } else {
            setSelected([])
        }
    };

    const handleSelect = (event, product) => {
        const selectedIndex = selected.find(item => (item.id === product.id))
        let newSelected = []

        if (selectedIndex === undefined) {
            newSelected = newSelected.concat(selected, product)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
        }

        setSelected(newSelected)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    return (
        <Grid item>
            <Dialog
                open={!!open}
                onClose={() => { setOpen(false) }}
                fullWidth={true}
                maxWidth={'xl'}
            >
                <DialogTitle>Объекты закупок</DialogTitle>
                <DialogContent ref={containerRef} className={classes.content}>
                    <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                        <Grid item className={classes.header}>
                            <TextField
                                variant="standard"
                                name={'search'}
                                placeholder={`Поиск: наименование объекта закупки, начальное наименование, номер закупки, МНН`}
                                fullWidth={true}
                                margin={'normal'}
                                onChange={(event) => {
                                    setSearch(event.target.value)
                                }}
                                classes={{
                                    root: clsx(classes.filterLabel, classes.searchLabel)
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon  style={{color: '#c1c1c1'}}/>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TableContainer className={classes.tableContainer}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    indeterminate={selected.length > 0 && selected.length < meta.total}
                                                    checked={meta.total > 0 && selected.length === meta.total}
                                                    onChange={handleSelectAll}
                                                />
                                            </TableCell>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {products.data.map((product, index) => {
                                            const isItemSelected = isSelected(product);

                                            return (
                                                <TableRow
                                                    onClick={(event) => handleSelect(event, product)}
                                                    className={classes.tableRow}
                                                    hover key={index}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {product.number}
                                                    </TableCell>
                                                    <TableCell>
                                                        {product.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {product.standard_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {(product.category.id ===  product.category.index) ? product.category.name : `${product.category.name} (${product.category.root.name})`}
                                                    </TableCell>
                                                    <TableCell>
                                                        {(product.unit_id && units.find(el => el.id === product.unit_id)) ? units.find(el => el.id === product.unit_id).short : ''}
                                                    </TableCell>
                                                    <TableCell>
                                                        {product.funding_sources ? product.funding_sources.join(', ') : ''}
                                                    </TableCell>
                                                    <TableCell>
                                                        {product.generic}
                                                    </TableCell>
                                                    <TableCell>
                                                        {product.purchase_number}
                                                    </TableCell>
                                                    <TableCell>
                                                        {product.lot_number}
                                                    </TableCell>
                                                    <TableCell>
                                                        {parseFloat(product.price * product.amount).toLocaleString('ru-RU', {style: 'currency', currency: 'RUB'})}
                                                    </TableCell>
                                                    <TableCell>
                                                        {(new Date(product.demand_start_date)).getFullYear()}
                                                    </TableCell>
                                                    <TableCell>
                                                        {getMonthName(new Date(product.demand_start_date))}
                                                    </TableCell>
                                                    <TableCell>
                                                        {parseFloat(!product.amount ? 0 : product.amount).toLocaleString('ru-RU')}
                                                    </TableCell>
                                                    <TableCell>
                                                        {parseFloat(product.price * product.amount).toLocaleString('ru-RU', {style: 'currency', currency: 'RUB'})}
                                                    </TableCell>
                                                    <TableCell>
                                                        {product.railRoad}
                                                    </TableCell>
                                                    <TableCell>
                                                        {product.company}
                                                    </TableCell>
                                                    <TableCell>
                                                        {product.description}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.footer}>
                    <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                        <Grid item>
                            <TablePagination
                                rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                component='div'
                                labelRowsPerPage={'Записей на странице:'}
                                count={meta.total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" justify="flex-end" alignItems="stretch" spacing={2}>
                                <Grid item>
                                    <Submit
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={() => {
                                            setSelected([])
                                            setOpen(false)
                                        }}
                                    >
                                        Отмена
                                    </Submit>
                                </Grid>
                                <Grid item>
                                    <Submit
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={!selected.length}
                                        onClick={() => {
                                            dispatch(ItemsActions.include(purchase.id, {items: selected.map(product => product.id)})).then(_ => {
                                                setSelected([])
                                                setOpen(false)
                                                dispatch(ItemsActions.items(purchase.id, {
                                                    limit: rowsPerPage,
                                                    page: page + 1
                                                }))
                                            })
                                        }}
                                    >
                                        Добавить
                                    </Submit>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}
