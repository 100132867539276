import React from 'react'

import {Grid as GridBase} from '@devexpress/dx-react-grid'

import {Root} from './Grid/Root'

export const Grid = ({ children, ...props }) => {
    return (
        <GridBase
            rootComponent={Root}
            {...props}
        >
            {children}
        </GridBase>
    )
}
