import React from "react"

import {Content} from "./components/Contracts/Content";
import {Content as AppContent} from "../App/Content";

export const Contracts = (props) => {
    return <React.Fragment>
        <AppContent
            Component={Content}
            {...props}
        />
    </React.Fragment>
}
