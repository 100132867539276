import {combineReducers} from "redux"

import application from "./application"
import alert from "./alert"
import system from "./system"
import authentication from "../../Auth/reducers/authentication"
import account from "../../Account/reducers/account"
import company from "../../Account/reducers/company"
import download from "../../Download/reducers/download"
import standard from "../../Standard/reducers/standard"
import purchase from "../../Purchase/reducers/purchase"
import prices from "../../Price/reducers/price"
import products from "../../Price/reducers/products"
import shop from "../../Marketplace/components/Shop/reducers/shop"
import marketplace from "../../Marketplace/reducers/marketplace"
import supplierShop from "../../Supplier/Orders/reducers/shop"
import mySupplierOrders from "../../Supplier/MyOrders/reducers/shop"
import offer from "../../Supplier/Orders/reducers/offer"
import lotProducts from "../../Supplier/Orders/reducers/products"
import lot from "../../Supplier/Orders/reducers/lot"
import operator from "../../Operator/reducers/operator"
import users from "../../Company/components/User/reducers/users"
import employee from "../../Company/reducers/employee"
import companies from "../../Company/reducers/companies"
import customer from "../../Company/reducers/customer"
import delivery from "../../Delivery/reducers/delivery"
import deliveryStatus from "../../Delivery/reducers/status"
import reclamation from "../../Reclamation/reducers/reclamation"
import catalog from "../../Catalog/reducers/catalog"
import contract from "../../Contract/reducers/contract"
import contractCompanies from "../../Contract/reducers/company"
import reportCompany from "../../Report/reducers/company"
import reportProducts from "../../Report/reducers/products"
import reportOrder from "../../Report/reducers/order"
import reportDelivery from "../../Report/reducers/delivery"
import reportContract from "../../Report/reducers/contract"
import reportLot from "../../Report/reducers/lot"
import report from "../../Report/reducers/report"
import reportPriceControl from "../../Report/reducers/Control/price"
import reportPriceControlGroup from "../../Report/reducers/Control/group"
import discounts from "../../Discounts/reducers/discounts"
import notification from "../../Notification/reducers/notification"
import discountCustomers from "../../Discounts/reducers/customer"
import discountPrices from "../../Discounts/reducers/prices"
import discountProducts from "../../Discounts/reducers/products"
import cumulativeDiscounts from "../../Discounts/reducers/cumulativeDiscounts"
import discount from "../../Discounts/reducers/discount"
import cart from "../../Cart/reducers/cart"
import priceTypes from "../../Price/reducers/priceTypes"
import catalogSupplier from "../../Supplier/Catalog/reducers/catalogSupplier"
import supMarketplace from "../../Supplier/Marketplace/reducers/supMarketplace"
import supplierCategories from "../../Supplier/Catalog/reducers/supplierCategories"
import warehouse from "../../Account/reducers/warehouse"
import invoice from "../../Account/reducers/invoice"
import settings from "../../Settings/reducers/settings"
import purchasePlans from "../../Planning/reducers/purchasePlan"
import purchases from "../../Planning/reducers/purchases"
import messenger from "../../Messenger/reducers/messenger"
import demo from "../../Demo/reducers/demo"
import planningSettings from "../../Planning/reducers/settings"
import supplier from "../../Company/reducers/supplier"
import dictionaries from "../../Dictionaries/reducers/dictionaries"
import filters from "./filters";

const CombineReducers = combineReducers({
    account,
    alert,
    application,
    authentication,
    cart,
    catalog,
    catalogSupplier,
    companies,
    company,
    contract,
    contractCompanies,
    customer,
    cumulativeDiscounts,
    delivery,
    deliveryStatus,
    demo,
    discount,
    discounts,
    discountCustomers,
    discountPrices,
    discountProducts,
    download,
    employee,
    filters,
    lot,
    lotProducts,
    marketplace,
    messenger,
    mySupplierOrders,
    notification,
    offer,
    operator,
    planningSettings,
    prices,
    priceTypes,
    products,
    purchase,
    purchases,
    purchasePlans,
    reclamation,
    reportCompany,
    report,
    reportContract,
    reportDelivery,
    reportLot,
    reportOrder,
    reportPriceControl,
    reportPriceControlGroup,
    reportProducts,
    settings,
    shop,
    standard,
    supplier,
    supplierShop,
    supMarketplace,
    supplierCategories,
    system,
    users,
    warehouse,
    invoice,
    dictionaries
})

const AppReducers = (state, action) => {
    if (action.type === 'CLEAR') {
        state = undefined
    }

    return CombineReducers(state, action)
}

export default AppReducers
