import React, {useEffect} from "react"
import {Link as Route, useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import * as Yup from "yup"
import format from "date-fns/format"
import {ru} from "date-fns/locale"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Link,
    makeStyles,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Tooltip,
    Typography
} from "@material-ui/core"
import {GetApp as GetAppIcon} from '@material-ui/icons'

import {ShopActions} from "../../../actions/shop"
import {TabPanel} from "../../../../../Account/components/Tabs/TabPanel"
import {Submit} from "../../../../../App/components/Button/Submit"
import {TextFieldWithError as CustomTextField} from "../../../../../App/components/Inputs/TextFieldWithError"
import {Log} from "../Log"
import {day as formatDay} from "../../../../../App/helpers/date"
import {round} from "../../../../../App/helpers/number"
import {History} from "../History"
import {endings} from "../../../../../App/helpers/endings"
import {visibleOffers} from "../../../../../App/helpers/offer"
import * as AppStorage from "../../../../../App/helpers/storage"
import {taxPercentValues} from '../../../../../App/dics/dic'
import {AuthorizationService} from "../../../../../Auth/services/authorization";
import {Offer} from "../../../../Orders/components/Offer";
import {Button as SignatureButton} from "../../../../../Crypto/Action/Button";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    subTitle: {
        "font-weight": "bold",
    },
    header: {
        "width": "100%",
        "padding": "20px 29px 0 29px !important",
    },
    input: {
        "width": "100%",
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "margin-bottom": "4px",
    },
    tableContainer: {
        "overflow": "auto"
    },
    tabContent: {
        "padding": "20px 29px !important"
    },
    itemInput: {
        "margin-top": "0 !important"
    },
    inputContent: {
        "padding": "20px 30px 0",
        "height": "calc(100vh - 303px)"
    },
    column: {
        "height": "86px"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    download: {
        "color": "#485868"
    },
    winner: {
        '& .MuiTableCell-root': {
            'font-weight': '500'
        }
    },
    total: {
        "border-bottom": "none",
        'font-weight': '600'
    },
    link: {
        "display": "flex",
        "line-height": "31px",
        "color": "#485868",
    },
    titleInfo: {
        fontWeight: 700,
    },
    itemHeight: {
        // maxHeight: "90px",
    },
    fileItem: {
        maxHeight: "25px"
    },
    lightTooltip: {
        "font-size": "0.8rem",
        "backgroundColor": "white",
        "color": "rgba(0, 0, 0, 0.87)",
        "border": "1px solid #ededed"
    },
}))

export const Content = () => {
    let {id} = useParams()

    const dispatch = useDispatch()
    const classes = useStyles()
    const {lot} = useSelector(state => {
        return {...state.mySupplierOrders, ...state.account}
    })
    const {account} = useSelector(state => state.account)
    const [value, setValue] = React.useState(1);
    const [dialog, setDialog] = React.useState(false);

    useEffect(() => {
        const getOrder = async () => {
            return await dispatch(ShopActions.lotInfo(id))
        }

        if (!lot) {
            getOrder().then(() => {
            })
        }
    }, [dispatch, id, lot]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getActions = (lot) => {
        let actions = []
        if ((lot.order.type === 'dynamic') && (lot.status.index === 8) && !!lot.end_time_of_rebidding && !!lot?.offer && !lot?.offer.last_price && lot?.offer.approve) {
            actions.push(
                <Grid item>
                    <Grid container direction="row" justify="flex-start" alignItems="stretch">
                        {lot.end_time_of_rebidding ? (
                            <Grid item>
                                <Typography style={{width: "100%", marginRight: '15px'}}>Действие доступно до {format(new Date(lot.end_time_of_rebidding), 'H:mm PP', {locale: ru})}<br/>(мск {format(new Date(new Date(lot.end_time_of_rebidding).toLocaleString("en-US", {timeZone: "Europe/Moscow"})), 'H:mm PP', {locale: ru})})</Typography>
                            </Grid>
                        ) : null}
                        <Grid item>
                            <Submit
                                disableElevation
                                variant="contained"
                                color="primary"
                                onClick={e => e.stopPropagation()}
                                type="button"
                                component={Route}
                                to={`/supplier/marketplace/orders/lot/${lot.id}/offer/${lot?.offer.id}/edit`}
                            >
                                Редактировать предложение
                            </Submit>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
        if (lot?.offer?.active && parseInt(lot.status.index) === 2) {
            actions.push((
                <Grid item>
                    <Submit
                        disableElevation
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={() => {
                            setDialog({
                                title: 'Отмена ценового предложения',
                                content: (
                                    <Formik
                                        initialValues={{
                                            comment: '',
                                            lotId: lot.id,
                                            orderId: lot.order.id,
                                        }}
                                        validationSchema={Yup.object().shape({
                                            comment: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
                                        })}
                                        onSubmit={(values, {setSubmitting}) => {
                                            return dispatch(ShopActions.cancel(lot.offer.id, values)).then(
                                                () => {
                                                    setDialog(false)
                                                },
                                                errors => {
                                                    if (errors) {
                                                    }
                                                    setSubmitting(false)
                                                }
                                            )
                                        }}
                                    >
                                        {({
                                              dirty,
                                              isSubmitting,
                                              isValid
                                          }) => (
                                            <Form>
                                                <DialogContent>
                                                    <Field
                                                        fullWidth
                                                        multiline
                                                        rows={6}
                                                        name="comment"
                                                        type="text"
                                                        label="Комментарий"
                                                        component={CustomTextField}
                                                    />
                                                </DialogContent>
                                                <DialogActions>
                                                    <Grid container direction="row" justify="flex-end"
                                                          alignItems="stretch">
                                                        <Grid item>
                                                            <Submit
                                                                variant="contained"
                                                                color="primary"
                                                                type="submit"
                                                                disabled={isSubmitting || !isValid || !dirty}
                                                            >
                                                                Отменить
                                                            </Submit>
                                                        </Grid>
                                                    </Grid>
                                                </DialogActions>
                                            </Form>
                                        )}
                                    </Formik>
                                )
                            })
                        }}
                    >
                        Отозвать ценовое предложение
                    </Submit>
                </Grid>
            ))
        } else if (lot?.offer ? (lot?.offer?.comment) : false) {
            actions.push((
                <Grid item>
                    <Submit
                        disableElevation
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={e => e.stopPropagation()}
                        component={Route}
                        to={`/supplier/marketplace/orders/lot/${lot.id}/offer/add`}
                    >
                        Актуализировать ценовое предложение
                    </Submit>
                </Grid>
            ))
        } else if (lot.order.status.index === 2 && lot.order.from_planning === false) {
            actions.push(
                <Grid item>
                    <Submit
                        disableElevation
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={e => e.stopPropagation()}
                        component={Route}
                        to={`/supplier/marketplace/orders/lot/${lot.id}/offer/add`}
                    >
                        Отправить предложение
                    </Submit>
                </Grid>
            )
        }
        return actions
    }

    const getExpirationDate = (item, type) => {
        if (type === 'warranty_period') {
            return item.expiration_date_number ? endings(parseInt(item.expiration_date_number), ['месяц', 'месяца', 'месяцев']) : null;
        } else if (type === 'expiration_date_percent') {
            return item.expiration_date_number ? `${item.expiration_date_number} %` : null;
        } else {
            return item.expiration_date ? item.expiration_date + ' г.' : null;
        }
    }

    const renderOfferItemTable = (lot) => {
        return (
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {[
                                {
                                    id: '0',
                                    label: '№',
                                    format: (value) => value.toLocaleString()
                                },
                                {
                                    id: '1',
                                    label: 'Объект закупки',
                                    format: (value) => value.toLocaleString()
                                },
                                {
                                    id: '2',
                                    label: 'Срок годности',
                                    format: (value) => value.toLocaleString()
                                },
                                {
                                    id: '3',
                                    label: 'НМЦ за единицу',
                                    format: (value) => value.toLocaleString()
                                },
                                {
                                    id: '4',
                                    label: 'Цена за позицию',
                                    format: (value) => value.toLocaleString()
                                },
                                {
                                    id: '5',
                                    label: 'НДС',
                                    format: (value) => value.toLocaleString()
                                },
                                {
                                    id: '6',
                                    label: 'Количество',
                                    format: (value) => value.toLocaleString()
                                },
                                {
                                    id: '7',
                                    label: 'Сумма(с учётом НДС)',
                                    format: (value) => value.toLocaleString()
                                }
                            ].map((column) => (
                                <TableCell key={column.id}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lot.items.map((item, index) => {
                            let offerItem = lot.offer ? lot.offer.items.find(oi => oi.item.id === item.id) : undefined

                            return (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell width="30%">
                                        <Tooltip classes={{tooltip: classes.lightTooltip}} title={item.name}
                                                 placement="bottom">
                                            <span>{item.name}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        {getExpirationDate(item, item.expiration_date_type)}
                                    </TableCell>
                                    <TableCell>
                                        {parseFloat(item.average_price).toLocaleString('ru-RU', {
                                            style: 'currency',
                                            currency: lot.order.currency.code
                                        }).replace('KZT', '₸')}
                                    </TableCell>
                                    <TableCell>
                                        {offerItem ? parseFloat(offerItem.price).toLocaleString('ru-RU', {
                                            style: 'currency',
                                            currency: lot.order.currency.code
                                        }).replace('KZT', '₸') : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {offerItem ? taxPercentValues.find(el => el.key === offerItem.tax_percent)?.value : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {(item.amount || (item.amount === 0)) ? round(item.amount, 2) : null} {item.unit ? item.unit.short : null}
                                    </TableCell>
                                    <TableCell>
                                        {offerItem ? (parseFloat(offerItem.price) * item.amount).toLocaleString('ru-RU', {
                                            style: 'currency',
                                            currency: lot.order.currency.code
                                        }).replace('KZT', '₸') : '-'}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                        <TableRow>
                            <TableCell className={classes.total} component="th" scope="row"/>
                            <TableCell className={classes.total} width="50%"/>
                            <TableCell className={classes.total}/>
                            <TableCell className={classes.total}/>
                            <TableCell className={classes.total}/>
                            <TableCell className={classes.total}/>
                            <TableCell className={classes.total}/>
                            <TableCell className={classes.total}>
                                <Typography component="span" variant="h6">Итого: </Typography><Typography
                                component="span"
                                variant="h6">{parseFloat(lot.offer.items.filter(item => !!item.item).reduce(function (sum, item) {
                                return sum + (parseFloat(item.price) * parseFloat(item.item.amount))
                            }, 0)).toLocaleString('ru-RU', {
                                style: 'currency',
                                currency: lot.order.currency.code
                            }).replace('KZT', '₸')}</Typography>
                                {
                                    function () {
                                        let result = []

                                        lot.offer.items.filter(item => !!item.item).forEach(item => {
                                            if (item.tax_percent > 0) {
                                                result[item.tax_percent] = (parseFloat(result[item.tax_percent] ? result[item.tax_percent] : 0) + parseFloat((parseFloat(item.price) * parseFloat(item.item.amount)) / (100 + parseInt(item.tax_percent, 10)) * parseInt(item.tax_percent, 10))).toFixed(2)
                                            }
                                        })

                                        return result
                                    }().map((value, percent) => (
                                        <Typography key={percent} variant="body2">Включая
                                            НДС {percent}%: {parseFloat(value).toLocaleString('ru-RU', {
                                                style: 'currency',
                                                currency: lot.order.currency.code
                                            }).replace('KZT', '₸')}</Typography>
                                    ))
                                }
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const renderOrderItemTable = (lot) => {
        return (
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {[
                                {
                                    id: '0',
                                    label: '№',
                                    format: (value) => value.toLocaleString()
                                },
                                {
                                    id: '1',
                                    label: 'Объект закупки',
                                    format: (value) => value.toLocaleString()
                                },
                                {
                                    id: '2',
                                    label: 'Срок годности',
                                    format: (value) => value.toLocaleString()
                                },
                                {
                                    id: '3',
                                    label: 'Цена',
                                    format: (value) => value.toLocaleString()
                                },
                                {
                                    id: '4',
                                    label: 'Количество',
                                    format: (value) => value.toLocaleString()
                                },
                                {
                                    id: '5',
                                    label: 'НМЦ за единицу',
                                    format: (value) => value.toLocaleString()
                                }
                            ].map((column) => (
                                <TableCell key={column.id}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lot.items.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {index + 1}
                                </TableCell>
                                <TableCell width="60%">
                                    <Tooltip classes={{tooltip: classes.lightTooltip}} title={item.name}
                                             placement="bottom">
                                        <span>{item.name}</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    {getExpirationDate(item, item.expiration_date_type)}
                                </TableCell>
                                <TableCell>
                                    -
                                </TableCell>
                                <TableCell>
                                    {item.amount || item.amount === 0 ? round(item.amount, 2) : null}
                                </TableCell>
                                <TableCell>
                                    {parseFloat(item.average_price).toLocaleString('ru-RU', {
                                        style: 'currency',
                                        currency: lot.order.currency.code
                                    }).replace('KZT', '₸')}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell className={classes.total} component="th" scope="row"/>
                            <TableCell className={classes.total} width="80%">
                                ИТОГО
                            </TableCell>
                            <TableCell className={classes.total}/>
                            <TableCell className={classes.total}>
                                -
                            </TableCell>
                            <TableCell className={classes.total}/>
                            <TableCell className={classes.total}>
                                {parseFloat(lot.average_price).toLocaleString('ru-RU', {
                                    style: 'currency',
                                    currency: lot.order.currency.code
                                }).replace('KZT', '₸')}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const getStatus = (lot) => {
        if (!!(!!lot && !!lot.offer)) {
            if (lot.offer.active) {
                if (lot.status.index === 8) {
                    return "В работе"
                }
                return lot.status.name
            } else {
                if (lot.status.index !== 2) {
                    return lot.status.name
                }

                if (lot.offer.comment) {
                    return "Предложение отозвано"
                } else {
                    return "Отменено"
                }
            }
        } else if (lot && !lot.offer && (lot.order.status.index === 3) && (lot.status.index !== 7)) {
            return "В работе"
        } else if ((lot.order.status.index === 2) && (lot.status.index === 2)) {
            return "Прием заявок"
        } else if (lot.order.status.index === lot.status.index) {
            if (lot.status.index === 8) {
                return "В работе"
            }
            return lot.status.name
        } else {
            if (lot.status.index === 8) {
                return "В работе"
            }
            return lot.status.name
        }
    }

    return lot ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.full}>
                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                            <Grid item className={classes.fullWidth}>
                                <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                    <Grid item className={classes.header}>
                                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                            <Grid item>
                                                <Grid container className={classes.fullWidth} direction="row" justify="space-between" alignItems="stretch">
                                                    <Grid item>
                                                        <Typography className={classes.title}>
                                                            Лот № {lot.number ? lot.number : lot.index}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="button">
                                                            {getStatus(lot)}
                                                        </Typography>
                                                        {(lot.status.index === 4 && lot.log[0].comment) ?
                                                            <Typography>
                                                                <span className={classes.subTitle}>Причина:</span> {lot.log[0].comment}
                                                            </Typography>
                                                        : null}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="button">
                                                    Подача предложений
                                                    до {format(new Date(lot.order.application_deadline), 'H:mm PP', {locale: ru})} (мск {format(new Date(new Date(lot.order.application_deadline).toLocaleString("en-US", {timeZone: "Europe/Moscow"})), 'H:mm PP', {locale: ru})})
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.input}>
                                        <Grid container direction="column" justify="center" alignItems="stretch">
                                            <Grid item className={classes.fullWidth}>
                                                <Tabs
                                                    value={value}
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                    onChange={handleChange}
                                                >
                                                    {((!!lot.offer && (!!lot.offer.winner && !!lot.offer.active)) || (lot.status.index === 6) || ((lot.status.index === 5) && lot.offer)) &&
                                                        <Tab value={0} label="Договор"/>}
                                                    <Tab value={1} label="Информация о лоте"/>
                                                    {visibleOffers(lot) &&
                                                        <Tab value={2} label="Предложения поставщиков"/>}
                                                    {!!Object.keys(lot.order.log).length &&
                                                        <Tab value={3} label="История"/>}
                                                </Tabs>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                                    <Grid item className={classes.inputContent}>
                                        {((lot.offer && lot.offer.winner) || (lot.status.index === 6) || ((lot.status.index === 5) && lot.offer)) &&
                                            <TabPanel value={value} index={0}>
                                                <Log lot={lot}/>
                                            </TabPanel>
                                        }
                                        <TabPanel value={value} index={1}>
                                            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                <Grid item className={classes.itemHeight}>
                                                    <Grid container direction="row" spacing={2}>
                                                        <Grid item xs={4}>
                                                            <Grid container direction="column" justify="space-between">
                                                                <Grid item>
                                                                    <Typography>
                                                                        <span className={classes.titleInfo}>Заказчик:</span> {lot.order.company.legal_detail.name}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography>
                                                                        <span className={classes.titleInfo}>ИНН:</span> {lot.order.company.legal_detail.inn}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography>
                                                                        <span className={classes.titleInfo}>КПП:</span> {lot.order.company.legal_detail.kpp}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Grid container direction="column" justify="space-between">
                                                                <Grid item>
                                                                    <Typography>
                                                                        <span className={classes.titleInfo}>Ценовой запрос:</span> {lot.order.name}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography>
                                                                        <span className={classes.titleInfo}>Тип запроса:</span> {lot.order.type === 'static' ? 'Статический запрос' : 'Динамический запрос'}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography>
                                                                            <span className={classes.titleInfo}>Категория:</span> {lot.order.category.name}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Grid container direction="column" justify="space-between">
                                                                <Grid item>
                                                                    <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                                                                        {lot.files.find((file) => (file.type !== null && file.type.key === 'draft_agreement')) &&
                                                                            <Grid item className={classes.fileItem}>
                                                                                <Typography
                                                                                    style={{
                                                                                        margin: 0,
                                                                                        height: "25px"
                                                                                    }}
                                                                                >
                                                                                    <Link
                                                                                        href={process.env.REACT_APP_HOST_API + `/shop/order/${lot.order.id}/file/${lot.files.find((file) => (file.type !== null && file.type.key === 'draft_agreement')).id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                        className={classes.link}
                                                                                    >
                                                                                        Скачать проект
                                                                                        договора<GetAppIcon
                                                                                        viewBox="0 0 24 24"/>
                                                                                    </Link>
                                                                                </Typography>
                                                                            </Grid>
                                                                        }
                                                                        {(lot.files.filter(el => el.type !== null)).find((file) => (file.type !== null && file.type.key === 'technical_task')) &&
                                                                            <Grid item className={classes.fileItem}>
                                                                                <Typography>
                                                                                    <Link
                                                                                        href={process.env.REACT_APP_HOST_API + `/shop/order/${lot.order.id}/file/${(lot.files.filter(el => el.type !== null)).find((file) => (file.type !== null && file.type.key === 'technical_task')).id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                        className={classes.link}
                                                                                    >
                                                                                        Скачать техническое
                                                                                        задание<GetAppIcon
                                                                                        viewBox="0 0 24 24"/>
                                                                                    </Link>
                                                                                </Typography>
                                                                            </Grid>
                                                                        }
                                                                        {lot.files.filter(el => el.type === null).length > 0 &&
                                                                            lot.files.filter(el => el.type === null).map(file => (
                                                                                <Grid item className={classes.fileItem} key={file.id}>
                                                                                    <Typography>
                                                                                        <Link
                                                                                            href={process.env.REACT_APP_HOST_API + `/shop/order/${lot.order.id}/file/${file.id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                            className={classes.link}
                                                                                        >
                                                                                            Скачать {file.description}<GetAppIcon
                                                                                            viewBox="0 0 24 24"/>
                                                                                        </Link>
                                                                                    </Typography>
                                                                                </Grid>
                                                                            ))
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item className={classes.itemHeight}>
                                                    <Grid container direction="row" spacing={2}>
                                                        <Grid item xs={4}>
                                                            <Typography>
                                                                    <span className={classes.titleInfo}>Адрес доставки:</span> {lot.order.company.legal_detail.current_address ? lot.order.company.legal_detail.current_address : '-'}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography>
                                                                    <span className={classes.titleInfo}>Контакты:</span> {lot.order.company.contact_information
                                                                ? ((lot.order.company.contact_information.phones && (lot.order.company.contact_information.phones.length > 1 || lot.order.company.contact_information.phones[0].number))
                                                                        ? lot.order.company.contact_information.phones.map((phone, index) => {
                                                                            return phone.number ? <Typography key={index} component={'span'}>{index > 0 ? ', ' : ''}+7{phone.number} {phone.extra ? `доб. ${phone.extra}` : ''}</Typography> : null
                                                                        })
                                                                        : 'Не указан'
                                                                )
                                                                : 'Не указан'}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                                                <Grid item>
                                                                    <Typography>
                                                                        <span className={classes.titleInfo}>Отсрочка платежа:</span> {lot.postponement ? formatDay(parseInt(lot.postponement)) : '-'}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography>
                                                                        <span className={classes.titleInfo}>Планируемый месяц исполнения договора:</span> {lot.execution_month}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    {(lot && lot.offer) ? renderOfferItemTable(lot) : null}
                                                    {(lot && !lot.offer) ? renderOrderItemTable(lot) : null}
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value={value} index={2}>
                                            <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                                <Grid item>
                                                    <TableContainer className={classes.tableContainer}>
                                                        <Table stickyHeader aria-label="sticky table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {[
                                                                        {
                                                                            id: '0',
                                                                            label: 'Поставщик',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                        {
                                                                            id: '1',
                                                                            label: 'Срок поставки',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                        {
                                                                            id: '2',
                                                                            label: 'Цена предложения, с учетом НДС',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                        {
                                                                            id: '3',
                                                                            label: 'Дата подачи',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                    ].map((column) => (
                                                                        <TableCell key={column.id}>
                                                                            {column.label}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {lot.offers.map((offer, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell>
                                                                            {offer.company ? offer.company.legal_detail.name : `Поставщик № ${index + 1}`}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {offer.revision.contract_time}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {parseFloat(offer.price).toLocaleString('ru-RU', {
                                                                                style: 'currency',
                                                                                currency: lot.order.currency.code
                                                                            }).replace('KZT', '₸')}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {format(new Date(offer.created), 'dd.MM.yyyy HH:mm')}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                        {!!Object.keys(lot.order.log).length ?
                                            <TabPanel value={value} index={3}>
                                                <History lot={lot}/>
                                            </TabPanel>
                                        : null}
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.footer}>
                                    <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                        <Grid item className={classes.footerContent}>
                                            <Grid container direction="row" justify={"space-between"} alignItems="center" spacing={2}>
                                                <Grid item>
                                                    <Grid container className={classes.footerContainer} direction="row" justify={"flex-start"} alignItems="center" spacing={2}>
                                                        {(lot.order.files.find((file) => file.type.key === 'notice')) ? (
                                                          <Grid item>
                                                              <SignatureButton
                                                                file={lot.order.files.find((file) => file.type.key === 'notice')}
                                                                name="Извещение"
                                                                href={`/shop/order/${lot.order.id}/file/${lot.order.files.find((file) => file.type.key === 'notice').id}`}
                                                              />
                                                          </Grid>
                                                        ) : null}
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid
                                                        container
                                                        className={classes.footerContainer}
                                                        direction="row"
                                                        justify={"flex-start"}
                                                        alignItems="center"
                                                        spacing={2}
                                                    >
                                                        {getActions(lot)}
                                                    </Grid>
                                                </Grid>
                                                {dialog &&
                                                    <Dialog
                                                        open={!!dialog}
                                                        onClose={() => {
                                                            setDialog(false)
                                                        }}
                                                        fullWidth={true}
                                                        maxWidth={'sm'}
                                                    >
                                                        <DialogTitle>{dialog.title}</DialogTitle>
                                                        {dialog.content}
                                                    </Dialog>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    ) : null
}
