import {HttpService} from "../../App/services/http"

export const LotService = {
    addLot,
    editLot,
    lotsInPurchase,
    lots,
    getLot,
    categoriesForItemsInLot,
    deleteLots,
    lotsFilter
}

function lotsInPurchase(purchaseID, params) {
    return HttpService.get(`/planning/purchase/${purchaseID}/lots`, params)
}

function lots(params) {
    return HttpService.get(`/planning/lots`, params)
}

function getLot(purchaseID, lotID) {
    return HttpService.get(`/planning/purchase/${purchaseID}/lot/${lotID}`)
}

function addLot(purchaseID, formData) {
    return HttpService.post(`/planning/purchase/${purchaseID}/lot/add`, formData, true, true)
}

function editLot(purchaseID, lotID, formData) {
    return HttpService.post(`/planning/purchase/${purchaseID}/lot/${lotID}/edit`, formData, true, true)
}

function categoriesForItemsInLot(lotID) {
    return HttpService.get(`/planning/lot/${lotID}/categories`)
}

function deleteLots(ids) {
    return HttpService.deleteMethod(`/planning/lots/delete`, ids)
}

function lotsFilter(params) {
    return HttpService.get(`/planning/lots/filter`, params)
}
