import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, FieldArray, Form, Formik} from "formik"
import * as Yup from "yup"

import {
    FormControl,
    Grid, IconButton, InputLabel,
    makeStyles, MenuItem,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip,
    Typography,
} from "@material-ui/core"

import {SettingsActions} from "../../actions/settings"
import {Submit} from "../../../App/components/Button/Submit"
import {CustomAutocomplete} from "../../../App/components/Inputs/CustomAutocomplete";
import DeleteSweepOutlinedIcon from "@material-ui/icons/DeleteSweepOutlined";
import {Clear} from "@material-ui/icons";
import {SystemActions} from "../../../App/actions/system";
import {Select} from "formik-material-ui";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%"
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 111px)"
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100% - 54px)",
        "min-height": "calc(100% - 54px)",
        "padding": "20px 30px 0",
        "overflow": "auto"
    },
    column: {
        "height": "86px"
    },
    categories: {
        "overflow": "auto",
    },
    categoryInput: {
        "margin-top": "0 !important"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "18px !important"
    },
    params: {
        height: "calc(100vh - 231px)",
        overflow: "auto",
    }
}))

export const Content = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { categories } = useSelector(state => state.system)

    const [loading, setLoading] = useState(false)
    const [settings, setSettings] = useState({})

    useEffect(() => {
        const getSettings = async () => {
            return await dispatch(SettingsActions.getSettings('standard'))
        }

        if (!loading) {
            getSettings().then(settings => {
                setSettings(settings)
                setLoading(true)
            })
        }
    }, [dispatch, loading]);

    useEffect(() => {
        const getData = async () => {
            if (!categories.length) {
                await dispatch(SystemActions.categories())
            }
        }

        getData().then(r => {})
        // eslint-disable-next-line
    }, [categories.length]);

    return loading ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.fullWidth}>
                        <Grid container className={classes.container} direction="column" justify="space-between" alignItems="stretch">
                            <Formik
                                initialValues={settings}
                                validationSchema={Yup.object().shape({
                                    categories: Yup.array().of(Yup.object().shape({type: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")}))
                                })}
                                onSubmit={(values, {setSubmitting}) => {
                                    return dispatch(SettingsActions.setSettings('standard', values)).then(
                                        () => {
                                            setSubmitting(false)
                                        },
                                        () => {
                                            setSubmitting(false)
                                        }
                                    )
                                }}
                            >
                                {({
                                  values,
                                  setValues,
                                  isSubmitting
                                }) => (
                                    <Form className={classes.fullWidth}>
                                        <Grid item className={classes.inputContent}>
                                            <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                                <Grid item className={classes.header}>
                                                    <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                                        <Grid item className={classes.fullWidth}>
                                                            <Typography className={classes.title}>
                                                                Настройки Эталонов
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.params} direction="row" justify="start" alignItems="stretch" spacing={2}>
                                                <Grid item xs={6} className={classes.column}>
                                                    <FieldArray name="categories" render={ arrayHelpers => (
                                                        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                            <Grid item xs={12}>
                                                                <CustomAutocomplete
                                                                    name={'categories'}
                                                                    values={values}
                                                                    setValues={setValues}
                                                                    getObject={item => ({category: {id: item.id}, type: 'standard'})}
                                                                    items={categories.filter(category => !category.category && !values.categories.find(item => (category.id === item.category.id)))}
                                                                    getOptionLabel={(option) => option.name ?? ''}
                                                                    label="Категории"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                    <Grid item>
                                                                        {!!values.categories && !!values.categories.length  &&
                                                                            <TableContainer className={classes.categories}>
                                                                                <Table stickyHeader>
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <TableCell>Категории</TableCell>
                                                                                            <TableCell>Тип</TableCell>
                                                                                            <TableCell align="right">
                                                                                                <Tooltip
                                                                                                    title="Удалить все выбранные категории"
                                                                                                    placement="right"
                                                                                                    classes={{tooltip: classes.lightTooltip}}
                                                                                                >
                                                                                                    <IconButton
                                                                                                        style={{
                                                                                                            "width": "24px",
                                                                                                            "height": "24px",
                                                                                                            "padding": "0"
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            setValues({
                                                                                                                ...values,
                                                                                                                ...{categories: []}
                                                                                                            })
                                                                                                        }}
                                                                                                    >
                                                                                                        <DeleteSweepOutlinedIcon
                                                                                                            color="error"
                                                                                                        />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {values.categories.filter(item => categories.find(category => category.id === item.category.id)).map((option, index) => (
                                                                                            <TableRow key={index}>
                                                                                                <TableCell component="th" scope="row">
                                                                                                    {categories.find(category => category.id === option.category.id)?.name}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    <FormControl fullWidth>
                                                                                                        <Field
                                                                                                            fullWidth
                                                                                                            type="text"
                                                                                                            name={`categories.${index}.type`}
                                                                                                            component={Select}
                                                                                                        >
                                                                                                            {[
                                                                                                                {key: 'standard', name: 'Эталонный'},
                                                                                                                {key: 'typed', name: 'Типизированный'},
                                                                                                                {key: 'arbitrary', name: 'Произвольный'},
                                                                                                            ].map((type, index) => (
                                                                                                                <MenuItem key={index} value={type.key}>{type.name}</MenuItem>
                                                                                                            ))}
                                                                                                        </Field>
                                                                                                    </FormControl>
                                                                                                </TableCell>
                                                                                                <TableCell align="right">
                                                                                                    <IconButton
                                                                                                        size={"small"}
                                                                                                        onClick={() => {
                                                                                                            arrayHelpers.remove(index)
                                                                                                        }}
                                                                                                    >
                                                                                                        <Clear />
                                                                                                    </IconButton>
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        ))}
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.footer}>
                                            <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                                <Grid item className={classes.footerContent}>
                                                    <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                        <Grid item>
                                                            <Submit
                                                                disableElevation
                                                                variant="contained"
                                                                color="primary"
                                                                type="submit"
                                                                disabled={isSubmitting}
                                                            >
                                                                Сохранить
                                                            </Submit>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    ) : null
}
