const orderStatuses = {
    draft: 0,
    open: 1,
    submitApplication: 2,
    in_work: 3,
    cancel: 4,
    fail: 5,
    agreementContractWithSingleSupplier: 6,
    success: 7,
    approveSupplierOffers: 8
};

export {
    orderStatuses
};
