import {DiscountsService} from "../services/discounts"
import {history} from "../../App/helpers/history"

export const DiscountsActions = {
    discounts,
    discount,
    add,
    products,
    customers,
    edit,
}

function discounts(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        DiscountsService.discounts(params)
            .then(
                discounts => {
                    dispatch({type: "SUPPLIER_DISCOUNTS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(discounts)
                },
                error => {
                    dispatch({type: "SUPPLIER_DISCOUNTS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }  })
                    reject(error)
                }
            )
    })
}

function add(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "ADD_SUPPLIER_DISCOUNT_REQUEST"})

        DiscountsService.add(values)
            .then(
                response => {
                    dispatch({type: "ADD_SUPPLIER_DISCOUNT_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Успешно создана',
                            type:'success'
                        }
                    })
                    history.push("/supplier/discounts")
                },
                error => {
                    dispatch({type: "ADD_SUPPLIER_DISCOUNT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    let message = error.message
                    if (error.errors && error.errors['items.customers'] && error.errors['items.prices'] && error.errors['items.products']){
                        message = 'Заполните хотя бы один из объектов скидок'
                    }
                    dispatch({ type: "ALERT_ERROR", payload: {
                        message: message,
                        type:'error'
                    }  })
                    reject(error)
                }
            )
    })
}

function edit(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "EDIT_SUPPLIER_DISCOUNT_REQUEST"})

        DiscountsService.edit(id, values)
            .then(
                response => {
                    dispatch({type: "EDIT_SUPPLIER_DISCOUNT_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Успешно изменена',
                            type:'success'
                        }
                    })
                    history.push("/supplier/discounts")
                },
                error => {
                    dispatch({type: "EDIT_SUPPLIER_DISCOUNT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    let message = error.message
                    if (error.errors && error.errors['items.customers'] && error.errors['items.prices'] && error.errors['items.products']){
                        message = 'Заполните хотя бы один из объектов скидок'
                    }
                    dispatch({ type: "ALERT_ERROR", payload: {
                        message: message,
                        type:'error'
                    }  })
                    reject(error)
                }
            )
    })
}

function products(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "GET_DISCOUNT_PRODUCTS_REQUEST"})

        DiscountsService.products(params)
            .then(
            response => {
                dispatch({type: "GET_DISCOUNT_PRODUCTS_SUCCESS", payload: response.data})
                dispatch({type: "LOADING", payload: false})
                resolve(response.data)
            },
            error => {
                dispatch({type: "GET_DISCOUNT_PRODUCTS_FAILURE"})
                dispatch({type: "LOADING", payload: false})
                reject(error)
            }
        )
    })
}

function discount(companyId, id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "GET_DISCOUNT_REQUEST"})

        DiscountsService.discount(companyId, id)
            .then(
            response => {
                dispatch({type: "GET_DISCOUNT_REQUEST_SUCCESS", payload: response})
                dispatch({type: "LOADING", payload: false})
                resolve(response)
            },
            error => {
                dispatch({type: "GET_DISCOUNT_REQUEST_FAILURE"})
                dispatch({type: "LOADING", payload: false})
                reject(error)
            }
        )
    })
}

function customers(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "GET_DISCOUNT_PRODUCTS_REQUEST"})

        DiscountsService.products(params)
            .then(
            response => {
                dispatch({type: "GET_DISCOUNT_PRODUCTS_SUCCESS", payload: response.data})
                dispatch({type: "LOADING", payload: false})
                resolve(response.data)
            },
            error => {
                dispatch({type: "GET_DISCOUNT_PRODUCTS_FAILURE"})
                dispatch({type: "LOADING", payload: false})
                reject(error)
            }
        )
    })
}
