import {CompanyService} from "../services/company"

export const CompanyActions = {
    companies,
    suppliers,
    postAuditSuppliers,
}

function companies(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CONTRACT_ALL_COMPANIES_REQUEST"})

        CompanyService.companies(params)
            .then(
                companies => {
                    dispatch({type: "CONTRACT_ALL_COMPANIES", payload: companies})
                    dispatch({type: "LOADING", payload: false})
                    resolve(companies)
                },
                error => {
                    dispatch({type: "CONTRACT_ALL_COMPANIES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function suppliers(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CONTRACT_SUPPLIERS_COMPANIES_REQUEST"})

        CompanyService.suppliers(params)
            .then(
                companies => {
                    dispatch({type: "CONTRACT_SUPPLIERS_COMPANIES", payload: companies})
                    dispatch({type: "LOADING", payload: false})
                    resolve(companies)
                },
                error => {
                    dispatch({type: "CONTRACT_SUPPLIERS_COMPANIES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function postAuditSuppliers(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        CompanyService.postAuditSuppliers(params)
            .then(
                companies => {
                    dispatch({type: "CONTRACT_POST_AUDIT_SUPPLIERS_COMPANIES"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(companies)
                },
                error => {
                    dispatch({type: "CONTRACT_POST_AUDIT_SUPPLIERS_COMPANIES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
