import * as AppStorage from "../../../../App/helpers/storage"

export default function users(state = {filter: AppStorage.get('filterSupervisors') ? JSON.parse(AppStorage.get('filterSupervisors')) : {search: null,tabOrganization: 3}}, action) {
    switch (action.type) {
        case "USERS_REQUEST":
            return state
        case "USERS_SUCCESS":
            return {
                ...state,
                ...action.payload,
            }
        case "USERS_FAILURE":
            return state
        case "USER_REQUEST":
            return state
        case "USER_SUCCESS":
            return {
                ...state,
                user: action.payload
            }
        case "USER_FAILURE":
            return state
        case "USER_FILTER":
            AppStorage.set('filterSupervisors', JSON.stringify(action.payload))
            return {
                ...state,
                ...{filter: action.payload}
            }
        default:
            return state
    }
}
