import React, {useState} from "react"
import {DialogWithVariantsChoice} from "../DialogWithVariantsChoice/DialogWithVariantsChoice";

export const ConfirmButton = (props) => {
    const {title, onClick, children} = props

    const [dialog, setDialog] = useState(false)

    return <React.Fragment>
        { React.cloneElement( children, { onClick: () => setDialog(true) } ) }
        {dialog &&
            <DialogWithVariantsChoice
                open={dialog}
                textTitle='Подтверждение действия'
                textSubTitle={title}
                buttons={[
                    {
                        xs: 4,
                        callback: () => {
                            setDialog(false)
                            onClick()
                        },
                        text: 'Да'
                    },
                    {
                        xs: 4,
                        callback: () => {
                            setDialog(false)
                        },
                        text: 'Нет',
                        color: 'secondary'
                    },
                ]}
            />
        }
    </React.Fragment>
}
