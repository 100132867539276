import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, FieldArray, Form, Formik} from "formik"
import {TextField} from "formik-material-ui"
import * as Yup from "yup"

import {Grid, IconButton, makeStyles, MenuItem, Paper, Typography} from "@material-ui/core"
import {Add as AddIcon, Clear} from "@material-ui/icons"

import {TextFieldWithError as CustomTextField} from "../../../App/components/Inputs/TextFieldWithError"
import {Submit} from "../../../App/components/Button/Submit"
import {SystemActions} from "../../../App/actions/system"
import {Autocomplete} from "../../../App/components/Inputs/Autocomplete"
import {CompanyActions} from "../../../Account/actions/company"
import {history} from "../../../App/helpers/history"

const useStyles = makeStyles(theme => ({
    input: {
        "padding": "0 4px 3px !important",
        "width": "100%"
    },
    inputContent: {
        "padding": "20px 30px 0"
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 250px)"
    },
    column: {
        "height": "86px"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    group: {
        "padding": "8px"
    },
    groupTitle: {
        "font-weight": "500"
    },
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
}))

const countries = [
    {
        code: 'ru',
        name: 'Российская Федерация'
    },
    {
        code: 'kz',
        name: 'Республика Казахстан'
    }
]

export const CreateContent = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [loading, setLoading] = React.useState(false)
    const {cities} = useSelector(state => state.system)

    useEffect(() => {
        const getCities = async () => {
            await dispatch(SystemActions.cities())
        }

        if (!loading) {
            setLoading(true)
            getCities().then(() => {
            })
        }
    }, [dispatch, loading]);

    return loading ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.full}>
                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                            <Grid item className={classes.fullWidth}>
                                <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                    <Grid item className={classes.header}>
                                        <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                            <Grid item className={classes.fullWidth}>
                                                <Typography className={classes.title}>
                                                    Создать заказчика
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Formik
                                    initialValues={{
                                        name: '',
                                        country: '',
                                        locality: '',
                                        inn: '',
                                        kpp: '',
                                        bin: '',
                                        email: '',
                                        phones: [],
                                        director: {
                                            last_name: '',
                                            first_name: '',
                                            middle_name: ''
                                        }
                                    }}
                                    validationSchema={Yup.object().shape({
                                        name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                        locality: Yup.object().nullable().required("Необходимо указать город"),
                                        inn: Yup.string().when("country", {
                                            is: 'ru',
                                            then: Yup.string()
                                                .min(10, "Длина ИНН должна от 10 символов!")
                                                .max(12, "Длина ИНН должна до 12 символов!")
                                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                                            otherwise: Yup.string()
                                        }),
                                        kpp: Yup.string().when("country", {
                                            is: 'ru',
                                            then: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                                .length(9, "Длина КПП должна быть 9 символов!")
                                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                                            otherwise: Yup.string()
                                        }),
                                        bin: Yup.string().when("country", {
                                            is: 'kz',
                                            then: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                                .length(12, "Длина БИН должна быть 12 символов!")
                                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                                            otherwise: Yup.string()
                                        }),
                                        email: Yup.string().email("Некорректный формат электронной почты!"),
                                        phones: Yup.array().of(Yup.object().shape({
                                            number: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').when("extra", (extra, schema) => {
                                                return extra ? schema.required("Поле не может быть пустым или состоять из одних пробелов!") : schema
                                            }),
                                            extra: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                        })),
                                        director: Yup.object().shape({
                                            last_name: Yup.string(),
                                            first_name: Yup.string(),
                                            middle_name: Yup.string()
                                        })
                                    })}
                                    onSubmit={(values, {setSubmitting}) => {
                                        dispatch(CompanyActions.create({...values, ...{code: values.country}})).then(
                                            () => {
                                                setSubmitting(false)
                                                history.push(`/customers`)
                                            },
                                            errors => {
                                                if (errors) {
                                                }
                                                setSubmitting(false);
                                            }
                                        )
                                    }}
                                >
                                    {({
                                          dirty,
                                          values,
                                          isSubmitting,
                                          isValid,
                                          setValues,
                                      }) => (
                                        <Form>
                                            <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                                                <Grid item className={classes.inputContent}>
                                                    <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                        <Grid item xs={6} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name="name"
                                                                type="text"
                                                                label="Полное наименование"
                                                                required
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                type="text"
                                                                name="country"
                                                                label="Страна"
                                                                select
                                                                required
                                                                variant="standard"
                                                                component={TextField}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                InputProps={{
                                                                    onChange: (event) => {
                                                                        const code = event.target.value

                                                                        setValues({
                                                                            ...values,
                                                                            ...{
                                                                                country: code,
                                                                                locality: ''
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                            >
                                                                {
                                                                    countries.map(country =>
                                                                        <MenuItem key={country.code}
                                                                                  value={country.code}>{country.name}</MenuItem>
                                                                    )
                                                                }
                                                            </Field>
                                                        </Grid>
                                                        <Grid item xs={3} className={classes.column}>
                                                            <Field
                                                                name="locality"
                                                                type="text"
                                                                options={cities.filter(city => city.region.country.code === values.country)}
                                                                getOptionLabel={option => {
                                                                    return option ? (option.name + ((option.name !== option.region.name) ? ", " + option.region.name : "")) : ''
                                                                }}
                                                                label="Населенный пункт"
                                                                required
                                                                disabled={!values.country}
                                                                component={Autocomplete}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    {values.country &&
                                                        ((values.country === 'ru')
                                                                ?
                                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                    <Grid item xs={4} className={classes.column}>
                                                                        <Field
                                                                            fullWidth
                                                                            name="inn"
                                                                            type="number"
                                                                            label="ИНН"
                                                                            required
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4} className={classes.column}>
                                                                        <Field
                                                                            fullWidth
                                                                            name="kpp"
                                                                            type="text"
                                                                            label="КПП"
                                                                            required
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                :
                                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                    <Grid item xs={4} className={classes.column}>
                                                                        <Field
                                                                            fullWidth
                                                                            name="bin"
                                                                            type="number"
                                                                            label="БИН"
                                                                            required={true}
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                        )
                                                    }
                                                    <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Typography className={classes.groupTitle}>Данные директора</Typography>
                                                            <Grid container className={classes.group} direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                                        <Grid item xs={4} className={classes.column}>
                                                                            <Field
                                                                                fullWidth
                                                                                name="director.last_name"
                                                                                type="text"
                                                                                label="Фамилия"
                                                                                component={CustomTextField}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4} className={classes.column}>
                                                                            <Field
                                                                                fullWidth
                                                                                name="director.first_name"
                                                                                type="text"
                                                                                label="Имя"
                                                                                component={CustomTextField}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4} className={classes.column}>
                                                                            <Field
                                                                                fullWidth
                                                                                name="director.middle_name"
                                                                                type="text"
                                                                                label="Отчество"
                                                                                component={CustomTextField}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                        <Grid item xs={4} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name="email"
                                                                type="email"
                                                                label="Электронная почта"
                                                                required
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <FieldArray name="phones" render={arrayHelpers => (
                                                        <React.Fragment>
                                                            {values.phones.map((phone, index) => {
                                                                return (
                                                                    <Grid key={index} container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                                        <Grid item xs={2} className={classes.column}>
                                                                            <Field
                                                                                fullWidth
                                                                                name={`phones.${index}.number`}
                                                                                type="phone"
                                                                                label="Номер телефона"
                                                                                component={CustomTextField}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={2} className={classes.column}>
                                                                            <Field
                                                                                fullWidth
                                                                                name={`phones.${index}.extra`}
                                                                                type="text"
                                                                                label="доб."
                                                                                component={CustomTextField}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={2}>
                                                                            <IconButton
                                                                                size={"small"}
                                                                                onClick={() => {
                                                                                    arrayHelpers.remove(index)
                                                                                }}
                                                                            >
                                                                                <Clear/>
                                                                            </IconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                );
                                                            })}
                                                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                                <Grid item>
                                                                    <Submit
                                                                        size="small"
                                                                        variant="contained"
                                                                        component="label"
                                                                        color="primary"
                                                                        endIcon={<AddIcon/>}
                                                                        onClick={() => {
                                                                            arrayHelpers.push({
                                                                                number: '',
                                                                                extra: ''
                                                                            })
                                                                        }}
                                                                    >
                                                                        Добавить номер телефона
                                                                    </Submit>
                                                                </Grid>
                                                            </Grid>
                                                        </React.Fragment>
                                                    )}/>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.footer}>
                                                <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                                    <Grid item className={classes.footerContent}>
                                                        <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Submit
                                                                    disableElevation
                                                                    variant="contained"
                                                                    color="primary"
                                                                    type="submit"
                                                                    disabled={isSubmitting || !isValid || !dirty}
                                                                >
                                                                    Создать
                                                                </Submit>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    ) : null
}
