import {ReclamationService} from "../services/reclamation"

export const ReclamationActions = {
    reclamations,
    reclamation,
    add,
    edit,
    action,
    status,
    types,
    demands,
    filter
}

function reclamations(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        ReclamationService.reclamations(params)
            .then(
                response => {
                    dispatch({type: "DELIVERY_RECLAMATIONS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "DELIVERY_RECLAMATIONS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function reclamation(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_RECLAMATION_REQUEST"})

        ReclamationService.reclamation(id)
            .then(
                reclamation => {
                    dispatch({type: "DELIVERY_RECLAMATION", payload: reclamation})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELIVERY_RECLAMATION_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function add(formData) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_ADD_RECLAMATION_REQUEST"})

        ReclamationService.add(formData)
            .then(
                response => {
                    dispatch({type: "DELIVERY_ADD_RECLAMATION_SUCCESS", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELIVERY_ADD_RECLAMATION_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function edit(id, formData) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_EDIT_RECLAMATION_REQUEST"})

        ReclamationService.edit(id, formData)
            .then(
                response => {
                    dispatch({type: "DELIVERY_EDIT_RECLAMATION_SUCCESS", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELIVERY_EDIT_RECLAMATION_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function action(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_RECLAMATION_ACTION_REQUEST"})

        ReclamationService.action(id, values)
            .then(
                response => {
                    dispatch({type: "DELIVERY_RECLAMATION_ACTION_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELIVERY_RECLAMATION_ACTION_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function status() {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_RECLAMATION_STATUS_REQUEST"})

        ReclamationService.status()
            .then(
                status => {
                    dispatch({type: "DELIVERY_RECLAMATION_STATUS", payload: status})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELIVERY_RECLAMATION_STATUS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function types() {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_RECLAMATION_TYPES_REQUEST"})

        ReclamationService.types()
            .then(
                types => {
                    dispatch({type: "DELIVERY_RECLAMATION_TYPES", payload: types})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELIVERY_RECLAMATION_TYPES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function demands() {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_RECLAMATION_DEMANDS_REQUEST"})

        ReclamationService.demands()
            .then(
                demands => {
                    dispatch({type: "DELIVERY_RECLAMATION_DEMANDS", payload: demands})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELIVERY_RECLAMATION_DEMANDS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function filter(params) {
    return dispatch => new Promise((resolve, reject) => {
        ReclamationService.filter(params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    reject(error)
                }
            )
    })
}
