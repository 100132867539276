import React from "react"
import {fieldToTextField} from "formik-material-ui"

import {FormControl, Input, InputLabel} from "@material-ui/core"

export const TextField = (props) => {
    const {
        label,
        fullWidth,
        helperText,
        inputProps,
        ...otherProps
    } = fieldToTextField(props)

    return (
        <FormControl fullWidth={fullWidth}>
            <InputLabel {...otherProps}>{label}</InputLabel>
            <Input inputProps={inputProps} {...otherProps} />
        </FormControl>
    )
}
