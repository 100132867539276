import React, {Fragment, useEffect, useState} from "react"

import {Grid, IconButton, makeStyles, MenuItem, Select, Tooltip, Typography} from "@material-ui/core"
import {Clear, Check} from '@material-ui/icons'

import HelpIcon from "@material-ui/icons/Help"

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",
        minWidth: "115px",
    },
    label: {
        fontSize: "16px",
        width: "auto",
        height: "auto",
        borderBottom: "dashed 1px #000",
        cursor: "pointer",
    },
    button: {
        fontSize: "16px",
        padding: "0",
        margin: "0 2px",
    },
    iconCheck: {
        color: "#fff",
        backgroundColor: "#898989",
        borderRadius: "4px",
        width: "21px",
        height: "21px",
    },
    iconCancel: {
        color: "#fff",
        backgroundColor: "#d9534f",
        borderRadius: "4px",
        width: "21px",
        height: "21px",
    },
    select: {
        "&:focus": {
            "background-color": "#fff",
        },
    },
    tooltipOfferStandard: {
        "background-color": "#000",
        "font-size": "15px",
        "color": "#fff",
        "padding": "15px 15px",
    },
}))

export const ButtonSelect = (props) => {
    const {defaultValue, name, selectItems, propId, disabled} = props
    const classes = useStyles()
    const [show, setShow] = useState(false)
    const [val, setVal] = useState(defaultValue)
    const [defaultVal, setDefaultVal] = useState(defaultValue)
    const change = (e) => {
        e.stopPropagation()
        props.change(e.target.name, e.target.value, props.propId)
        setVal(e.target.value)
    }

    useEffect(() => {
        if (defaultValue !== val) {
            setVal(defaultValue)
        }
        // eslint-disable-next-line
    }, [defaultValue])

    const renderItem = (obj) => {
        switch (obj.key){
            case 'button':
                return (<Grid container justify={'space-between'}>
                    <Grid item><Typography>Предложить эталон</Typography></Grid>
                    <Grid item>
                        <Tooltip
                            title="Если товар отсутствует в списке, вы можете предложить свой эталон"
                            placement="right"
                            classes={{tooltip: classes.tooltipOfferStandard}}
                        >
                            <IconButton
                                style={{
                                    "width": "24px",
                                    "height": "24px",
                                    "padding": "0"
                                }}
                            >
                                <HelpIcon style={{"color": "#485868"}}/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>)
            case 'reset_category':
                return (<Grid container justify={'space-between'}>
                    <Grid item><Typography>Указать категорию</Typography></Grid>
                    <Grid item>
                        <Tooltip
                            title="Если товар отсутствует в списке и вы хотите указать категорию"
                            placement="right"
                            classes={{tooltip: classes.tooltipOfferStandard}}
                        >
                            <IconButton
                                style={{
                                    "width": "24px",
                                    "height": "24px",
                                    "padding": "0"
                                }}
                            >
                                <HelpIcon style={{"color": "#485868"}}/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>)
            default:
                return obj.value
        }

    }

    const nodes = selectItems.map((obj) =>
        <MenuItem value={obj.key}>
            {renderItem(obj)}
        </MenuItem>
    )

    return (
        <div className={classes.root} key={name}>
            {!show &&
            <Typography
                className={classes.label}
                onClick={(e) => {
                    e.stopPropagation()
                    setShow(true)
                }}
                style={{pointerEvents: disabled ? 'none' : 'all'}}>
                    { selectItems.filter(el => el.key === val)[0].value }
            </Typography>}
            {show ?
            <Fragment>
                <Select
                    id={name}
                    classes={{
                        select: classes.select,
                    }}
                    name={name}
                    value={val}
                    style={{maxWidth: props.style && props.style.maxWidth ? props.style.maxWidth : "none",}}
                    onChange={change}
                    children={nodes}
                >
                </Select>
                <IconButton className={classes.button} onClick={(e) => {
                    e.stopPropagation()
                    setShow(false)
                    if (val !== defaultVal) {
                        try {
                            props.onUpdate(propId, name, val, selectItems).then(() => {
                                setDefaultVal(val)
                            }, () => {
                                setVal(defaultVal)
                            })
                        } catch (e) {
                            setVal(defaultVal)
                        }
                    }
                }}><Check className={classes.iconCheck}/></IconButton>
                <IconButton className={classes.button} onClick={(e) => {
                    e.stopPropagation()
                    setShow(false)
                    setVal(defaultVal)
                    props.onCancel(name, defaultVal)
                }}><Clear className={classes.iconCancel}/></IconButton>
            </Fragment> : null}
        </div>
    );
}
