import * as Yup from "yup"

function getValidationSchema() {
    return Yup.object().shape({
        warehouses: Yup.array().of(Yup.object().shape({
            name: Yup.string().nullable(),
            address: Yup.string().nullable()
        })),
    })
}
export {getValidationSchema}
