import React, {useState} from "react"
import {useDispatch} from "react-redux"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles, MenuItem,
} from "@material-ui/core"

import {Submit} from "../../../../App/components/Button/Submit";
import {Close as CloseIcon} from "@material-ui/icons";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {CheckboxWithLabel, TextField} from "formik-material-ui";
import DateFnsUtils from "@date-io/date-fns";
import {ru} from "date-fns/locale";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {type} from "../../../dics/Contract/AdditionalAggrement/type";
import {TextFieldWithError as CustomTextField} from "../../../../App/components/Inputs/TextFieldWithError";
import {FileUpload} from "../../../../App/components/Inputs/FileUpload";
import {AgreementActions} from "../../../actions/AdditionalAgreement/agreement";
import format from "date-fns/format";
import {Signature} from "../../../../Crypto/Signature";

const useStyles = makeStyles(() => ({
    column: {
        "height": "86px"
    },
    text: {
        "height": "118px"
    },
    footer: {
        "background-color": "#e7eaee",
        "justify-content": "flex-start",
        minHeight: "30px",
    },
    footerContent: {
        "padding": "10px 0px"
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    titleDialog: {
        "& h2": {
            "display": "flex",
            "justify-content": "space-between",
        },
    },
}))

export const Create = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { contract, open, onClose, onClick } = props
    const [sign, setSign] = useState({open: false, files: []})

    return (
        <Dialog
            open={!!open}
            onClose={onClose}
            fullWidth={true}
            maxWidth={'sm'}
        >
            <Formik
                enableReinitialize
                initialValues={{
                    type: '',
                    end: contract.end,
                    perpetual: contract.perpetual,
                    comment: '',
                    file: null,
                    signature: null
                }}
                validationSchema={Yup.object().shape({
                    type: Yup.string().required("Выберите тип доп.соглашения!"),
                    end: Yup.mixed().when("perpetual", {
                        is: false,
                        then: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!")
                    }),
                    perpetual: Yup.boolean(),
                    comment: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                    file: Yup.mixed().required("Обязательно прикрепите файл!"),
                    signature: Yup.string().nullable()
                })}
                onSubmit={(values, {setSubmitting}) => {
                    const formData = new FormData()

                    for (const name in values) {
                        switch (name) {
                            case 'perpetual':
                                formData.append(name, JSON.stringify(values[name] ? 1 : 0))
                                break
                            case 'end':
                                if (values[name]) {
                                    formData.append(name, format(new Date(values[name]), 'yyyy-MM-dd'))
                                }
                                break
                            case 'signature':
                                formData.append(name, values[name] ?? '')
                                break
                            default:
                                formData.append(name, values[name])
                        }
                    }

                    return dispatch(AgreementActions.create(contract.id, formData)).then(
                        () => {
                            onClick()
                            if (contract.type.index === type.CONTRACT_END_DATE.index) {
                                onClose()
                            }
                        },
                        () => {
                            setSubmitting(false);
                        }
                    )
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      setValues,
                      setFieldValue,
                      setFieldTouched,
                      isSubmitting,
                      handleSubmit
                  }) => (
                    <Form>
                        <DialogTitle className={classes.titleDialog}>
                            Заключить доп.соглашение
                            <CloseIcon
                                className={classes.close}
                                onClick={onClose}
                            />
                        </DialogTitle>
                        <DialogContent>
                            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                <Grid item>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                        <Grid item className={classes.column} xs={4}>
                                            <Field
                                                fullWidth
                                                type="text"
                                                name="type"
                                                label="Тип доп.соглашения"
                                                select
                                                disabled={isSubmitting}
                                                variant="standard"
                                                component={TextField}
                                                required
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            >
                                                {
                                                    Object.keys(type).map(key => (
                                                        <MenuItem key={type[key].index} value={type[key].index}>
                                                            {type[key].name}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Field>
                                        </Grid>
                                        {(values.type === type.CONTRACT_END_DATE.index) &&
                                            <React.Fragment>
                                                <Grid item xs={3}>
                                                    <Field
                                                        component={CheckboxWithLabel}
                                                        name="perpetual"
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                        Label={{label: "Бессрочный"}}
                                                        inputProps={{
                                                            onChange: (event) => {
                                                                setValues({
                                                                    ...values,
                                                                    ...{
                                                                        perpetual: event.target.checked,
                                                                        end: null
                                                                    }
                                                                })
                                                                setFieldTouched("end", false, false)
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.column} xs={5}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                        <KeyboardDatePicker
                                                            disableToolbar
                                                            fullWidth
                                                            disabled={values.perpetual || isSubmitting}
                                                            error={!!(errors.hasOwnProperty('end') && errors.end && touched.hasOwnProperty('end') && touched.end)}
                                                            helperText={!!(errors.hasOwnProperty('end') && errors.end && touched.hasOwnProperty('end') && touched.end) && (errors.end ? errors.end : null)}
                                                            name="end"
                                                            minDate={new Date()}
                                                            required
                                                            format="dd-MM-yyyy"
                                                            invalidDateMessage="Некорректная дата"
                                                            orientation="landscape"
                                                            variant="inline"
                                                            openTo="year"
                                                            autoOk
                                                            value={values.end}
                                                            label="Дата окончания договора"
                                                            onChange={(date) => {
                                                                setFieldValue("end", date);
                                                            }}
                                                            onBlur={() => {
                                                                setFieldTouched("end", true, true)
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                            </React.Fragment>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                        <Grid item className={classes.text} xs={12}>
                                            <Field
                                                fullWidth
                                                multiline
                                                rows={3}
                                                name="comment"
                                                type="text"
                                                label="Причина"
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                        <Grid item className={classes.column} xs={12}>
                                            <Field
                                                fullWidth
                                                size="small"
                                                required
                                                component={(props) => (
                                                    <FileUpload {...props} endIcon={"Обзор"}/>
                                                )}
                                                label="Файл *"
                                                name={`file`}
                                                InputLabelProps={{
                                                    name: values.file?.name
                                                }}
                                                InputProps={{
                                                    onChange: (event) => {
                                                        setSign({open: true, files: [...event.currentTarget.files]})
                                                    }
                                                }}
                                            />
                                            {sign.open ? (
                                                <Signature
                                                  open={sign.open}
                                                  onClose={() => setSign({open: false, files: []})}
                                                  files={sign.files}
                                                  onContinue={(objects) => {
                                                      setSign({open: false, files: []})
                                                      setFieldValue(`file`, objects[0].file)
                                                      setFieldValue(`signature`, objects[0].sign)
                                                  }}
                                                />
                                            ) : null}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions className={classes.footer}>
                            <Grid container className={classes.footerContent} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                <Grid item>
                                    <Submit
                                        className={classes.submit}
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={handleSubmit}
                                        type="submit"
                                    >
                                        {(!values.type || (values.type === type.CONTRACT_END_DATE.index)) ? 'Отправить на согласование' : 'Редактировать спецификацию'}
                                    </Submit>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}
