import React from "react"
import {useDispatch, useSelector} from "react-redux"

import {IconButton, makeStyles, Slide, Snackbar, Typography} from "@material-ui/core"
import {Close as CloseIcon} from "@material-ui/icons"

import {AlertActions} from "./actions/alert"

const useStyles = makeStyles(() => ({
    error: {
        "border-radius": "3px",
        "background-color": "#d9534f",
        "color": "#FFFFFF",
        "box-shadow": "0 1px 3px rgba(0,0,0,.2)",
        "cursor": "default",
        "border-top": "3px solid #ad3e3b !important",
    },
    info: {
        "border-radius": "3px",
        "background-color": "#00bed6",
        "color": "#FFFFFF",
        "box-shadow": "0 1px 3px rgba(0,0,0,.2)",
        "cursor": "default",
        "border-top": "3px solid #00bed6 !important",
    },
    success: {
        "border-radius": "3px",
        "background-color": "#5cb85c",
        "color": "#FFFFFF",
        "box-shadow": "0 1px 3px rgba(0,0,0,.2)",
        "cursor": "default",
        "border-top": "3px solid #5cb85c !important",
    },
    text: {
        "margin-bottom": 0
    }
}))

export const Alert = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const alert = useSelector(state => state.alert)

    const close = () => {
        dispatch(AlertActions.clear())
    }

    const getType = (type) => {
        // eslint-disable-next-line default-case
        switch (type){
            case 'success':
                return {
                    root: classes.success
                };
            case 'error':
                return {
                    root: classes.error
                };
            case 'info':
                return {
                    root: classes.info
                };
        }
    }

    if (alert.message) {
        return (
            <Snackbar
                ContentProps={{
                    classes: getType(alert.type)
                }}
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                open={true}
                onClose={close}
                TransitionComponent={(props) => {
                    return <Slide {...props} direction="left" />
                }}
                message={<Typography className={classes.text} gutterBottom color="inherit">{alert.message}</Typography>}
                action={
                    <React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={close}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </React.Fragment>
                }
            />
        )
    } else {
        return null
    }
}
