import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useDebounce, useDebouncedCallback} from "use-debounce"
import {AutoSizer, InfiniteLoader, Table} from "react-virtualized"

import {Box, Checkbox, Fade, Grid, makeStyles, TableContainer, TextField, Tooltip, Typography} from '@material-ui/core'
import {Skeleton} from "@material-ui/lab"

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    }
}))

export const ItemActionType = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {filters, setFilters, setDefaultFilters} = props

    const [loading, setLoading] = useState(false)
    const [initialize, setInitialize] = useState(false)
    const [additive, setAdditive] = useState(false)
    const [values, setValues] = useState([])
    const [total, setTotal] = useState(0)
    const [search, setSearch] = useState(null)
    const debounced = useDebouncedCallback(
        (value) => {
            setLoading(value)
        },
        900
    );
    const [searchRequest] = useDebounce(search, 600)
    const [filter, setFilter] = useState({types: filters.hasOwnProperty('types') ? filters.types : []})

    useEffect(() => {
        setFilters(filter)
        // eslint-disable-next-line
    }, [filter])

    const getData = async () => {
        return await dispatch(props.getActionType({
            ...(searchRequest ? {search: searchRequest} : {})
        }))
    }

    useEffect(() => {
        if (!initialize) {
            setDefaultFilters({types: []})
            getData().then(response => {
                setValues(response.action_types)
                setTotal(response.action_types.length)
                setLoading(true)
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (!loading && initialize) {
            getData().then(response => {
                setValues(response.action_types)
                setTotal(response.action_types.length)
                setLoading(true)
            })
        }
        // eslint-disable-next-line
    }, [loading, searchRequest, initialize])

    return (
        <React.Fragment>
            <Grid item>
                <Typography variant="caption">
                    Статус
                </Typography>
            </Grid>
            <Grid item className={classes.fullWidth}>
                <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                    <Grid item className={classes.fullWidth}>
                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                <TextField
                                    fullWidth
                                    label='Поиск'
                                    value={search ?? ''}
                                    onChange={(event) => {
                                        const value = event.target.value

                                        setSearch(!!value.length ? value : null)
                                        debounced.callback(false)
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.fullWidth}>
                        <TableContainer style={{ height: '250px' }}>
                            {loading ?
                                <InfiniteLoader
                                    isRowLoaded={index => !!values[index]}
                                    loadMoreRows={(start, stop) => {
                                        if (!additive && (start.stopIndex >= values.length)) {
                                            setAdditive(true)
                                        }
                                    }}
                                    rowCount={total}
                                    threshold={10}
                                >
                                    {({ onRowsRendered, registerChild }) => (
                                        <AutoSizer disableHeight>
                                            {({width}) =>
                                                <Table
                                                    ref={registerChild}
                                                    rowHeight={42}
                                                    rowCount={values.length}
                                                    width={width}
                                                    height={250}
                                                    headerHeight={0}
                                                    rowGetter={({ index }) => values[index]}
                                                    onRowsRendered={onRowsRendered}
                                                    rowRenderer={({rowData, style}) => {

                                                        const isItemSelected = (filter.types.indexOf(rowData.id) !== -1)

                                                        return (
                                                            <Tooltip key={rowData.id} TransitionComponent={Fade} title={rowData.name} placement="bottom-end">
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justify="flex-start"
                                                                    alignItems="center"
                                                                    spacing={2}
                                                                    style={{...style, ...{cursor: 'pointer'}}}
                                                                    onClick={(event) => {
                                                                        const selectedIndex = filter.types.indexOf(rowData.id)

                                                                        let select = []

                                                                        if (selectedIndex === -1) {
                                                                            select = select.concat(filter.types, rowData.id)
                                                                        } else if (selectedIndex === 0) {
                                                                            select = select.concat(filter.types.slice(1))
                                                                        } else if (selectedIndex === filter.types.length - 1) {
                                                                            select = select.concat(filter.types.slice(0, -1))
                                                                        } else if (selectedIndex > 0) {
                                                                            select = select.concat(
                                                                                filter.types.slice(0, selectedIndex),
                                                                                filter.types.slice(selectedIndex + 1),
                                                                            )
                                                                        }

                                                                        setFilter({types: select})
                                                                    }}
                                                                >
                                                                    <Grid item xs={2}>
                                                                        <Checkbox color="primary" checked={isItemSelected}/>
                                                                    </Grid>
                                                                    <Grid item xs={10}>
                                                                        <Typography color="inherit" variant="body2" noWrap>{rowData.name}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Tooltip>
                                                        )
                                                    }}
                                                />
                                            }
                                        </AutoSizer>
                                    )}
                                </InfiniteLoader> : <Box>
                                    <Skeleton height={42} animation="wave" />
                                    <Skeleton height={42} animation="wave" />
                                    <Skeleton height={42} animation="wave" />
                                    <Skeleton height={42} animation="wave" />
                                    <Skeleton height={42} animation="wave" />
                                    <Skeleton height={42} animation="wave" />
                                </Box>}
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
