import * as Yup from "yup"

import { isWithExpireDate } from "../../../App/helpers/category"

function getValidationSchema(product, categories) {
    return Yup.object().shape({
        category_id: Yup.mixed().required("Выберите категорию!"),
        standard: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
        // standard: Yup.mixed()
        //     .when("category", {
        //         is: category => category.active,
        //         then: Yup.mixed().test('type', 'Для эталонизированныx категорий, доступен только выбор!', value => value?.id),
        //         otherwise: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!")
        //     }),
        tax_percent: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        article_number: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        unit: product
            ? Yup.object()
            : Yup.object().shape({
                id: Yup
                    .number()
                    .required("Поле не может быть пустым или состоять из одних пробелов!"),
            }),
        photo: '',
        producer: Yup.string(),
        site_producer: Yup.string(),
        stock_quantity: Yup.string()
            .matches(/^[0-9]*[.,]?[0-9]+$/, 'Поле должно состоять только из цифр!')
            .required("Поле не может быть пустым или состоять из одних пробелов!"),
        description: Yup.string(),
        min_ordered_quantity: Yup.string()
            .matches(/^[0-9]*[.,]?[0-9]+$/, 'Поле должно состоять только из цифр!')
            .min(0, "Значение не может быть меньше 0"),
        price: Yup.string()
            .matches(/^[0-9]*[.,]?[0-9]+$/, 'Поле должно состоять только из цифр!')
            .required("Поле не может быть пустым или состоять из одних пробелов!"),
        companyId: Yup.number()
            .required("Поле не может быть пустым или состоять из одних пробелов!"),
        expiration_date: Yup.mixed()
            .when("category_id", {
                is: category_id => isWithExpireDate(category_id, categories),
                then: Yup
                    .mixed()
                    .test('min-date', 'Некорректная дата', function(value) {
                        let currentDate = new Date(value);
                        let minDate = new Date().setDate(new Date().getDate() + 2)

                        return currentDate.valueOf() - minDate.valueOf() >= 0;
                    }).required("Поле не может быть пустым или состоять из одних пробелов!"),
                otherwise: Yup
                    .mixed()
                    .nullable()
            }),
        price_id: Yup.string(),
        warehouse_id: Yup.string().nullable(),
    })}

export {getValidationSchema};


