export default function group(state = {groups: []}, action) {
    switch (action.type) {
        case "REPORT_PRICE_CONTROL_ITEM_GROUPS_REQUEST":
            return state
        case "REPORT_PRICE_CONTROL_ITEM_GROUPS":
            return {
                ...state,
                ...{groups: action.payload},
            }
        case "REPORT_PRICE_CONTROL_ITEM_GROUPS_FAILURE":
            return state
        case "REPORT_PRICE_CONTROL_ADD_ITEM_GROUP_REQUEST":
            return state
        case "REPORT_PRICE_CONTROL_ADD_ITEM_GROUP_SUCCESS":
            state.groups.push(action.payload)

            return state
        case "REPORT_PRICE_CONTROL_ADD_ITEM_GROUP_FAILURE":
            return state
        case "REPORT_PRICE_CONTROL_EDIT_ITEM_GROUP_REQUEST":
            return state
        case "REPORT_PRICE_CONTROL_EDIT_ITEM_GROUP_SUCCESS":
            return {
                ...state,
                ...{groups: state.groups.map(group => {
                    if (group.id === action.payload.id) {
                        group = action.payload
                    }

                    return group
                })}
            }
        case "REPORT_PRICE_CONTROL_EDIT_ITEM_GROUP_FAILURE":
            return state
        case "REPORT_PRICE_CONTROL_DELETE_ITEM_GROUP_REQUEST":
            return state
        case "REPORT_PRICE_CONTROL_DELETE_ITEM_GROUP_SUCCESS":
            return {
                ...state,
                ...{groups: state.groups.filter(group => (group.id !== action.payload))}
        }
        case "REPORT_PRICE_CONTROL_DELETE_ITEM_GROUP_FAILURE":
            return state
        default:
            return state
    }
}
