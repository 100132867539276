import {GroupService} from "../../services/Control/group"

export const GroupActions = {
    groups,
    add,
    edit,
    remove,
}

function groups(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "REPORT_PRICE_CONTROL_ITEM_GROUPS_REQUEST"})

        GroupService.groups(params)
            .then(
                groups => {
                    dispatch({type: "REPORT_PRICE_CONTROL_ITEM_GROUPS", payload: groups})
                    dispatch({type: "LOADING", payload: false})
                    resolve(groups)
                },
                error => {
                    dispatch({type: "REPORT_PRICE_CONTROL_ITEM_GROUPS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function add(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "REPORT_PRICE_CONTROL_ADD_ITEM_GROUP_REQUEST"})

        GroupService.add(values)
            .then(
                group => {
                    dispatch({type: "REPORT_PRICE_CONTROL_ADD_ITEM_GROUP_SUCCESS", payload: group})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "REPORT_PRICE_CONTROL_ADD_ITEM_GROUP_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function edit(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "REPORT_PRICE_CONTROL_EDIT_ITEM_GROUP_REQUEST"})

        GroupService.edit(id, values)
            .then(
                group => {
                    dispatch({type: "REPORT_PRICE_CONTROL_EDIT_ITEM_GROUP_SUCCESS", payload: group})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            type:'success'
                        }})
                    resolve()
                },
                error => {
                    dispatch({type: "REPORT_PRICE_CONTROL_EDIT_ITEM_GROUP_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function remove(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "REPORT_PRICE_CONTROL_DELETE_ITEM_GROUP_REQUEST"})

        GroupService.remove(id)
            .then(
                response => {
                    dispatch({type: "REPORT_PRICE_CONTROL_DELETE_ITEM_GROUP_SUCCESS", payload: id})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "REPORT_PRICE_CONTROL_DELETE_ITEM_GROUP_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
