import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import {CheckboxWithLabel} from "formik-material-ui"
import * as Yup from "yup"

import {
    DialogContent,
    DialogTitle,
    Typography,
    Dialog,
    makeStyles,
    Grid,
    Link,
    DialogActions
} from "@material-ui/core"

import {history} from "../../../App/helpers/history"
import {Submit} from "../../../App/components/Button/Submit"
import {CompanyActions} from "../../actions/company"
import {AccountActions} from "../../actions/account"
import {AuthorizationService} from "../../../Auth/services/authorization";

const useStyles = makeStyles(() => ({
    dialogContainer: {
        "& > div": {
            "max-width": "calc(100vw - 200px)",
            "min-height": "calc(100vh - 300px)",
            overflow: "hidden",
        }
    },
    dialogContent: {
        "height": "calc(100vh - 178px)"
    },
    dialogTitle: {
        "margin": "0",
        "padding-top": "2px",
        "font-size": "18px",
        "font-weight": "600",
        "color": "#fff",
        "text-transform": "uppercase",
        "display": "flex",
        "justify-content": "space-between",
        "align-items": "center",
        "min-height": "40px",
        "padding": "0 20px",
        "background-color": "#898989",
    },
    fullHeight: {
        "height": "100%"
    },
    descriptionWrapper: {
        padding: "40px 50px",
        fontSize: "16px",
    },
    descriptionText: {
        margin: "0 0 15px",
        fontSize: "18px",
    },
    link: {
        "text-decoration": "underline",
        "color": "#485868"
    },
    footerContent: {
        width: "100%",
        position: "absolute",
        bottom: 0,
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
}))

export const UpgradeNotice = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {account} = useSelector(state => state.account)
    const {open} = props


    let initial = {
        agreement: false,
        processing_of_personal_data: false,
        privacy_policy: false,
        offer: false
    }
    const schema = {
        agreement: Yup.bool().required("Поле не может быть пустым или состоять из одних пробелов!"),
        processing_of_personal_data: Yup.bool().required("Поле не может быть пустым или состоять из одних пробелов!"),
        privacy_policy: Yup.bool().required("Поле не может быть пустым или состоять из одних пробелов!"),
        offer: Yup.bool().required("Поле не может быть пустым или состоять из одних пробелов!"),
    }

    const onSubmit = (values, {setSubmitting}) => {
        return dispatch(CompanyActions.approveByAccount(values)).then(
            () => {
                setSubmitting(false);
                dispatch(AccountActions.account()).catch(() => {
                    history.push("/logout")
                });
            },
            () => {
                setSubmitting(false);
            }
        )
    }

    const files = AuthorizationService.roles(account, ['customer']) ? [
        'https://drive.google.com/file/d/1MMHzHSkfOiNzVBCMdnlTM2ikADpQb7IN/view?usp=sharing',
        'https://drive.google.com/file/d/1T0lJVkyB3s6UfgkwgWVjXYvAGDA3qdR1/view?usp=sharing',
        'https://drive.google.com/file/d/1ZnSifcHsecagTglTzSaXNrN8unGneFDK/view?usp=sharing',
        'https://drive.google.com/file/d/140mlyc2B-1ayHuHK8xa6-W6qp6Tp2DN7/view?usp=drive_link'
    ] : [
        'https://drive.google.com/file/d/1GOcLTMqneDO47t7qRrxBeZjSJycZJL9g/view?usp=sharing',
        'https://drive.google.com/file/d/1sJKUrtifi90CyZDU1m7H88BzCwPhGDDF/view?usp=sharing',
        'https://drive.google.com/file/d/1eaeCWvSSO9CEVramZfXBa9x33nOQ8OlE/view?usp=sharing',
        'https://drive.google.com/file/d/1ObuTwigv3uztBQPFu3VBjCPHwCwmcjhq/view?usp=sharing'
    ]

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <Formik
                initialValues={initial}
                validationSchema={Yup.object().shape(schema)}
                onSubmit={onSubmit}
            >
                {({
                      dirty,
                      values,
                      handleSubmit,
                      isSubmitting,
                      isValid,
                  }) => (
                    <Form>
                        <DialogTitle>Уведомление об актуализации</DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <Grid container>
                                <Grid item>
                                    <Grid container className={classes.descriptionWrapper}>
                                        <Grid item className={classes.descriptionText}>Для продолжения работы в АСЗ "Электронный ордер" (Далее - "Система") пользователь:</Grid>
                                        <Grid item>
                                            <Typography className={classes.descriptionText}>гарантирует полноту
                                                и достоверность
                                                информации, размещаемой в Системе, либо предоставляемой
                                                Оператору для
                                                опубликования в Системе.
                                            </Typography>
                                            <Typography className={classes.descriptionText}>гарантирует
                                                своевременное обновление
                                                информации в Системе о предлагаемых им товарах, работах и
                                                услугах.
                                            </Typography>
                                            <Typography className={classes.descriptionText}>подтверждает и
                                                гарантирует, что вся информация
                                                о товарах и услугах, предоставленная им в Системе, оформление
                                                заказа которых
                                                возможно с использованием функционала Системы, а также
                                                информация, включая
                                                цену на такой товар, о соответствующем товаре или услуге,
                                                размещенная в
                                                Каталоге Пользователя, является правильной, точной и
                                                достоверной, а также
                                                содержит необходимые в соответствии с законодательством о защите
                                                прав
                                                потребителей сведения, которые должны быть предоставлены
                                                Заказчику.
                                            </Typography>
                                            <Typography className={classes.descriptionText}>обязуется не
                                                изменять условия Заказа,
                                                оформленного Заказчиком с помощью функционала
                                                Системы.
                                            </Typography>
                                            <Typography className={classes.descriptionText}>предоставляет
                                                Оператору сведения о применяемой
                                                Пользователем Системе налогообложения и ставке НДС для
                                                товаров/услуг,
                                                которые будут доступны Заказчикам для приобретения в рамках
                                                функционала
                                                Системы. Применяемая система налогообложения указывается
                                                Заказчиком в Личном
                                                кабинете Пользователя. Ставка НДС указывается Пользователем в
                                                Системе для
                                                всех товаров/услуг, которые будут доступны Заказчикам для
                                                приобретения в
                                                рамках функционала Системы, либо может быть указана отдельно для
                                                каждого
                                                товара/услуги, доступных Заказчикам для заказа в рамках
                                                функционала Системы,
                                                в прайс-листе формата, соответствующего требованиям
                                                Оператора.
                                            </Typography>
                                            <Typography className={classes.descriptionText}>обеспечивает наличие
                                                в Системе достоверных
                                                сведений о товарах, работах, услугах, обеспечивающую возможность
                                                их
                                                правильного выбора Заказчиком, в том числе: наличие товарных
                                                позиций;
                                                текущие остатки на складе; актуальная стоимость
                                                товара.
                                            </Typography>
                                            <Typography className={classes.descriptionText}>обязуется не позднее
                                                даты отправки товара
                                                Заказчику, загрузить в Систему сканированные сопроводительные
                                                документы
                                                (ТОРГ-12, Счёт-Фактуру, Счёт на оплату, сертификаты, лицензии и
                                                др.
                                                требуемые к исполнению заказа документы) в цвете, подписанные
                                                Пользователем.
                                            </Typography>
                                            <Typography className={classes.descriptionText}>Уважаемые участники
                                                Системы! При планировании
                                                работы в Системе, следует учитывать, что за нарушение правил
                                                работы в
                                                Системе предусмотрено блокирование Оператором доступа к Системе.
                                                Согласие с представленными документами подтверждаю.
                                            </Typography>
                                        </Grid>
                                        <Grid item className={classes.agreement} xs={12}>
                                            <Field
                                                component={CheckboxWithLabel}
                                                name="processing_of_personal_data"
                                                color="primary"
                                                Label={{
                                                    label: <Typography>Я <Link className={classes.link} target="_blank" href={files[0]}>согласен</Link> на обработку персональных данных и получение информационных сообщений от ООО ”Эмсофт”</Typography>
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={CheckboxWithLabel}
                                                name="privacy_policy"
                                                color="primary"
                                                Label={{
                                                    label: <Typography>Я <Link className={classes.link} target="_blank" href={files[1]}>согласен</Link> с политикой конфиденциальности</Typography>
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={CheckboxWithLabel}
                                                name="agreement"
                                                color="primary"
                                                Label={{
                                                    label: <Typography>Я <Link className={classes.link} target="_blank" href={files[2]}>согласен</Link> с пользовательским соглашением</Typography>
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={CheckboxWithLabel}
                                                name="offer"
                                                color="primary"
                                                Label={{
                                                    label: <Typography>Я <Link className={classes.link} target="_blank" href={files[3]}>принимаю</Link> оферту</Typography>
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container direction="row" justify="flex-end" alignItems="stretch" spacing={2}>
                                <Grid item>
                                    <Submit
                                        variant="contained"
                                        component="label"
                                        color="primary"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            history.push("/logout")
                                        }}
                                    >
                                        Отмена
                                    </Submit>
                                </Grid>
                                <Grid item>
                                    <Submit
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting || !isValid || !values.agreement || !values.privacy_policy || !values.offer || !values.processing_of_personal_data || !dirty}
                                        onClick={handleSubmit}
                                        type="submit"
                                    >
                                        Принять
                                    </Submit>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}
