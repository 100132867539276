export default function discountCustomers(state = {companies: {data: [], meta: {total: 0, per_page: 15, current_page: 1}}}, action) {
    switch (action.type) {
        case "DISCOUNT_CUSTOMERS_REQUEST":
            return state
        case "DISCOUNT_CUSTOMERS_SUCCESS":
            return {
                companies: action.payload,
            }
        case "DISCOUNT_CUSTOMERS_FAILURE":
            return state
        case "DISCOUNT_CUSTOMER_REQUEST":
            return state
        case "DISCOUNT_CUSTOMER_SUCCESS":
            return {
                customer: action.payload,
                ...state
            }
        case "DISCOUNT_CUSTOMER_FAILURE":
            return state
        default:
            return state
    }
}
