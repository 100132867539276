import {HttpService} from "../../App/services/http"

export const CompanyService = {
    company,
    create,
    editLegalDetail,
    editContactInformation,
    active,
    certified,
    information,
    companies,
    occupations,
    legalDetail,
    contactInformation,
    termsParticipation,
    addFile,
    deleteFile,
    approve,
    block,
    unBlock,
    approveByAccount,
    addInstitutionsBudgets,
    editInstitutionsBudgets,
    deleteInstitutionsBudgets,
    okved,
    getInstitutionsBudgets,
    getSubscriptions,
    createSubscription,
    editSubscription,
    deleteSubscription
}

function company(params) {
    return HttpService.get("/account/company", params)
        .then(response => {
            return response.company
        })
}

function create(values) {
    return HttpService.post("/company", values)
}

function editLegalDetail(id, values) {
    return HttpService.put(`/company/${id}/legal-detail`, values)
        .then(response => {
            return response.company
        })
}

function editContactInformation(id, formData) {
    return HttpService.post(`/company/${id}/contact-information`, formData, true, true)
        .then(response => {
            return response.company
        })
}

function active(id, values) {
    return HttpService.put(`/company/${id}/active`, values)
}

function certified(id, values) {
    return HttpService.put(`/company/${id}/certified`, values)
}

function information(id, params) {
    return HttpService.get(`/company/${id}`, params)
        .then(response => {
            return response.company
        })
}

function companies(params) {
    return HttpService.get("/companies", params)
        .then(response => {
            return response.companies
        })
}

function occupations(params) {
    return HttpService.get("/company/occupations", params)
        .then(response => {
            return response.occupations
        })
}

function legalDetail(values) {
    return HttpService.put("/account/company/legal-detail", values)
}

function contactInformation(formData) {
    return HttpService.post("/account/company/contact-information", formData, true, true)
}

function termsParticipation(values) {
    return HttpService.put("/account/company/terms-participation", values)
}

function addFile(formData) {
    return HttpService.post("/account/company/terms-participation/files", formData, true, true)
        .then(response => {
            return response.content
        })
}

function deleteFile(id) {
    return HttpService.deleteMethod(`/account/company/terms-participation/file/${id}`)
}

function approve(id) {
    return HttpService.put(`/account/company/approve/${id}`)
}

function block(id) {
    return HttpService.put(`/account/company/block/${id}`)
}

function unBlock(id) {
    return HttpService.put(`/account/company/unBlock/${id}`)
}

function approveByAccount(values) {
    return HttpService.put(`/account/company/approveByAccount/`, values)
}

function okved(params) {
    return HttpService.get(`/account/company/okved`, params)
}

function addInstitutionsBudgets(values, companyId) {
    return HttpService.put(`/company/${companyId}/budget-institutions/`, values)
}

function editInstitutionsBudgets(values, companyId, id) {
    return HttpService.post(`/company/${companyId}/budget-institutions/${id}`, values)
}

function deleteInstitutionsBudgets(companyId, id) {
    return HttpService.deleteMethod(`/company/${companyId}/budget-institutions/${id}`)
}

function getInstitutionsBudgets(params, companyId) {
    return HttpService.get(`/company/${companyId}/budget-institutions`, params)
}

function getSubscriptions(params) {
    return HttpService.get(`/account/company/subscriptions`, params)
}

function createSubscription(values) {
    return HttpService.post(`/account/company/subscription`, values, true)
        .then(response => {
            return response.company
        })
}

function editSubscription(id, values) {
    return HttpService.put(`/account/company/subscription/${id}`, values, true)
        .then(response => {
            return response.company
        })
}

function deleteSubscription(id) {
    return HttpService.deleteMethod(`/account/company/subscription/${id}`)
}
