import React from "react"
import {useSelector} from "react-redux"

import {AuthorizationService} from "../Auth/services/authorization"
import {Menu as SupervisorReportsMenu} from "./components/Supervisor/Menu"
import {Menu as CompanyReportsMenu} from "./components/Company/Menu"

export const Menu = (props) => {
    const { account } = useSelector(state => state.account)

    return AuthorizationService.roles(account, "supervisor")
        ? <SupervisorReportsMenu {...props} />
        : <CompanyReportsMenu {...props} />
}
