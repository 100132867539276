import {PlanningService} from "../services/planning"

export const PlanningActions = {
    categories,
    units
}

function categories() {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_PLAN_CATEGORIES_REQUEST"})

        PlanningService.categories()
            .then(
                categories => {
                    dispatch({type: "PURCHASE_PLAN_CATEGORIES_FOR_CREATE_SUCCESS", payload: categories})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_PLAN_CATEGORIES_FOR_CREATE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function units() {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_PLAN_UNITS_REQUEST"})

        PlanningService.units()
            .then(
                categories => {
                    dispatch({type: "PURCHASE_PLAN_UNITS_SUCCESS", payload: categories})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_PLAN_UNITS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}


