import React, {useState} from "react"

import {Submit} from "../../../../../../App/components/Button/Submit"
import {Subscription} from "../Subscription";

export const Create = (props) => {
    const {setLoading, regions, categories} = props

    const [open, setOpen] = useState(false)

    return (
        <React.Fragment>
            <Submit
                disableElevation
                variant="contained"
                color="primary"
                type="button"
                onClick={() => {
                    setOpen(true)
                }}
            >
                Добавить
            </Submit>
            {open ? (
                <Subscription
                    open={open}
                    handleClose={() => setOpen(false)}
                    regions={regions}
                    categories={categories}
                    setLoading={setLoading}
                />
            ) : null}
        </React.Fragment>
    )
}
