import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import {fieldToTextField, TextField} from "formik-material-ui"
import * as Yup from "yup"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    MenuItem,
    TextField as MuiTextField,
} from "@material-ui/core"
import {Autocomplete} from "@material-ui/lab";
import {Close as CloseIcon} from "@material-ui/icons"

import {SystemActions} from "../../../App/actions/system"
import {TextFieldWithError as CustomTextField} from "../../../App/components/Inputs/TextFieldWithError"
import {Submit} from "../../../App/components/Button/Submit"
import {CompanyActions} from "../../../Account/actions/company"

const useStyles = makeStyles(() => ({
    fullWidth: {
        "width": "100%"
    },
    column: {
        "height": "86px"
    },
    footer: {
        "background-color": "#e7eaee",
        "justify-content": "flex-start"
    },
    footerContent: {
        "padding": "10px 0px"
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    titleDialog: {
        "& h2": {
            "display": "flex",
            "justify-content": "space-between",
        },
    }
}))

export const Company = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { cities }  = useSelector(state => state.system)

    const [open, setOpen] = useState(props.open);

    useEffect(() => {
        const getCities = async () => {
            await dispatch(SystemActions.cities())
        }

        if (!cities.length) {
            getCities().then(() => {
                setOpen(props.open)
            })
        } else {
            setOpen(props.open)
        }
        // eslint-disable-next-line
    }, [dispatch, props.open]);

    const getCountryParams = (country, type) => {
        let params = {
            values: {

            },
            schema: {
                name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
            }
        }

        switch (type) {
            case 'individual':
            case 'self':
                switch (country) {
                    case "ru":
                        params = {
                            values: {
                                ...params.values,
                                ...{
                                    inn: ""
                                }
                            },
                            schema: {
                                ...params.schema,
                                ...{
                                    inn: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                        .length(12, "Длина ИНН должна быть 12 символов!")
                                        .required("Поле не может быть пустым или состоять из одних пробелов!")
                                }
                            }
                        }

                        break;
                    case "kz":
                        params = {
                            values: {
                                ...params.values,
                                ...{
                                    bin: ""
                                }
                            },
                            schema: {
                                ...params.schema,
                                ...{
                                    bin: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                        .length(12, "Длина БИН должна быть 12 символов!")
                                        .required("Поле не может быть пустым или состоять из одних пробелов!")
                                }
                            }
                        }

                        break;
                    default:
                }
                break;
            case 'legal':
                switch (country) {
                    case "ru":
                        params = {
                            values: {
                                ...params.values,
                                ...{
                                    inn: "",
                                    kpp: ""
                                }
                            },
                            schema: {
                                ...params.schema,
                                ...{
                                    inn: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                        .length(10, "Длина ИНН должна быть 10 символов!")
                                        .required("Поле не может быть пустым или состоять из одних пробелов!"),
                                    kpp: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                        .length(9, "Длина КПП должна быть 9 символов!")
                                        .required("Поле не может быть пустым или состоять из одних пробелов!")
                                }
                            }
                        }

                        break
                    case "kz":
                        params = {
                            values: {
                                ...params.values,
                                ...{
                                    bin: ""
                                }
                            },
                            schema: {
                                ...params.schema,
                                ...{
                                    bin: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                        .length(12, "Длина БИН должна быть 12 символов!")
                                        .required("Поле не может быть пустым или состоять из одних пробелов!")
                                }
                            }
                        }

                        break
                    default:
                }
                break;
        }

        return params
    }

    const [country, setCountry] = useState(null)
    const [initial, setInitial] = useState({
        locality: null,
        country: "",
        type: "legal",
        name: "",
        agreement: false
    })
    const [schema, setSchema] = useState({
        locality: Yup.object().nullable().required("Необходимо указать город"),
        country: Yup.string(),
        name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        agreement: Yup.bool().required("Поле не может быть пустым или состоять из одних пробелов!"),
        type: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
    })

    const handleClose = () => {
        setOpen(false)
        setCountry(null)
        setInitial({
            locality: null,
            country: "",
            type: "legal",
            name: "",
            agreement: false
        })
        props.setDialog(false)
    }

    return (
        <Grid item>
            <Dialog
                open={!!open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle className={classes.titleDialog}>
                    Создание поставщика-постфактум
                    <CloseIcon
                        className={classes.close}
                        onClick={handleClose}
                    />
                </DialogTitle>
                <Formik
                    initialValues={initial}
                    validationSchema={Yup.object().shape(schema)}
                    onSubmit={(values, {setSubmitting}) => {
                        dispatch(CompanyActions.create({...values, ...{code: country}})).then(
                            () => {
                                setSubmitting(false)
                                handleClose()
                            },
                            errors => {
                                if (errors) {
                                }
                                setSubmitting(false);
                            }
                        )
                    }}
                >
                    {({
                          dirty,
                          values,
                          errors,
                          handleSubmit,
                          setValues,
                          setFieldValue,
                          isSubmitting,
                          isValid
                      }) => (
                        <Form>
                            <DialogContent>
                                <Grid container className={classes.fullWidth} direction="row" justify="center" alignItems="stretch">
                                    <Grid item className={classes.fullWidth}>
                                        <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
                                            <Grid item>
                                                <Grid item className={classes.list}>
                                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                        <Grid item xs={6} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name="name"
                                                                type="text"
                                                                label="Название организации"
                                                                required={true}
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                        <Grid item xs={6} className={classes.column}>
                                                            <Field
                                                                name="locality"
                                                                type="text"
                                                                label="Населенный пункт"
                                                                required
                                                                component={({ textFieldProps, ...props }) => {
                                                                    const { form: { errors, setTouched } } = props;
                                                                    const { label, helperText, error, ...field } = fieldToTextField(props);
                                                                    const { name } = field;

                                                                    return (
                                                                        <Autocomplete
                                                                            {...props}
                                                                            {...field}
                                                                            options={cities}
                                                                            getOptionLabel={option => {
                                                                                return option ? (option.name + ", " + option.region.country.name + ((option.name !== option.region.name) ? ", " + option.region.name : "")) : ""
                                                                            }}
                                                                            noOptionsText='Нет доступных вариантов'
                                                                            onBlur={() => setTouched({ [name]: true })}
                                                                            onChange={(event, option) => {

                                                                                let tmpSchema = schema
                                                                                delete tmpSchema.inn
                                                                                delete tmpSchema.kpp
                                                                                delete tmpSchema.bin

                                                                                let tmpValues = values
                                                                                delete tmpValues.inn
                                                                                delete tmpValues.kpp
                                                                                delete tmpValues.bin

                                                                                if (option) {
                                                                                    const params = getCountryParams(option.region.country.code, values.type)

                                                                                    switch (values.type) {
                                                                                        case 'individual':
                                                                                        case 'self':
                                                                                            delete tmpSchema.kpp
                                                                                            delete tmpValues.kpp
                                                                                            break;
                                                                                    }

                                                                                    if (country === 'ru') {
                                                                                        delete tmpSchema.bin
                                                                                        delete tmpValues.bin
                                                                                    } else {
                                                                                        delete tmpSchema.inn
                                                                                        delete tmpValues.inn
                                                                                    }

                                                                                    setValues({
                                                                                        ...tmpValues,
                                                                                        ...{
                                                                                            locality: option,
                                                                                            country: option.region.country.name
                                                                                        },
                                                                                        ...params.values
                                                                                    })
                                                                                    setCountry(option.region.country.code)

                                                                                    setSchema({...tmpSchema, ...params.schema})
                                                                                    return;
                                                                                }

                                                                                switch (values.type) {
                                                                                    case 'individual':
                                                                                    case 'self':
                                                                                        delete tmpSchema.kpp
                                                                                        delete tmpValues.kpp
                                                                                        break;
                                                                                }

                                                                                if (country === 'ru') {
                                                                                    delete tmpSchema.bin
                                                                                    delete tmpValues.bin
                                                                                } else {
                                                                                    delete tmpSchema.inn
                                                                                    delete tmpValues.inn
                                                                                }

                                                                                setValues({
                                                                                    ...tmpValues,
                                                                                    ...{
                                                                                        locality: null,
                                                                                        country: ""
                                                                                    }
                                                                                })

                                                                                setCountry(null)
                                                                                setSchema(tmpSchema)
                                                                            }}
                                                                            renderInput={ params => (<MuiTextField label={label} {...params} {...textFieldProps} helperText={error ? errors[name] : ''} error={!!error} />)}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name="country"
                                                                type="test"
                                                                label="Страна"
                                                                disabled={true}
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                        <Grid item xs={6} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                type="text"
                                                                name="type"
                                                                label="Тип компании"
                                                                select
                                                                variant="standard"
                                                                component={TextField}
                                                                required={true}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                InputProps={{
                                                                    onChange: (e) => {
                                                                        const {value, name} = e.target

                                                                        setFieldValue(name, value)

                                                                        let tmpSchema = schema
                                                                        let tmpValues = values

                                                                        const params = getCountryParams(country, value)

                                                                        switch (value) {
                                                                            case 'individual':
                                                                            case 'self':
                                                                                delete tmpSchema.kpp
                                                                                delete tmpValues.kpp
                                                                                break;
                                                                        }

                                                                        if (country === 'ru') {
                                                                            delete tmpSchema.bin
                                                                            delete tmpValues.bin
                                                                        } else {
                                                                            delete tmpSchema.inn
                                                                            delete tmpValues.inn
                                                                        }

                                                                        setValues({
                                                                            ...{
                                                                                ...tmpValues,
                                                                                ...{
                                                                                    type: value
                                                                                }
                                                                            },
                                                                            ...params.values
                                                                        })

                                                                        setSchema({...tmpSchema, ...params.schema})
                                                                    }
                                                                }}
                                                            >
                                                                <MenuItem key={1} value="legal">Юридическое лицо</MenuItem>
                                                                <MenuItem key={2} value="individual">Индивидуальный предприниматель</MenuItem>
                                                                <MenuItem key={3} value="self">Самозанятый</MenuItem>
                                                            </Field>
                                                        </Grid>
                                                    </Grid>
                                                    {country ? (
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                            <Grid item xs={6} className={classes.column}>
                                                                {(country === "ru")
                                                                    ?
                                                                    <Field
                                                                        fullWidth
                                                                        name="inn"
                                                                        type="text"
                                                                        label={"ИНН (количество символов: " + ((values.type === "legal") ? "10" : "12") + ")"}
                                                                        required={true}
                                                                        component={CustomTextField}
                                                                    />
                                                                    :
                                                                    <Field
                                                                        fullWidth
                                                                        name="bin"
                                                                        type="text"
                                                                        label="БИН (количество символов: 12)"
                                                                        required={true}
                                                                        component={CustomTextField}
                                                                    />
                                                                }
                                                            </Grid>
                                                            <Grid item xs={6} className={classes.column}>
                                                                {((country === "ru") && (values.type === "legal")) ? (
                                                                    <Grid item>
                                                                        <Field
                                                                            fullWidth
                                                                            name="kpp"
                                                                            type="text"
                                                                            label="КПП (количество символов: 9)"
                                                                            required={true}
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                ) : null}
                                                            </Grid>
                                                        </Grid>
                                                    ) : null}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions className={classes.footer}>
                                <Grid container className={ classes.footerContent} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Submit
                                            variant="contained"
                                            component="label"
                                            color="primary"
                                            disabled={isSubmitting || !isValid || !dirty}
                                            onClick={handleSubmit}
                                            type="submit"
                                        >
                                            Создать
                                        </Submit>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </Grid>
    )
}
