import {HttpService} from "../../App/services/http"

export const DemoService = {
    order,
    contract,
    supplier,
    setPaymentSum,
    setOrderItemsValues
}

function order(params) {
    return HttpService.get(`/integration/contract/delivery/customer/order`, params)
}

function contract(params) {
    return HttpService.get(`/integration/contract/customer/contract`, params)
}

function supplier(params) {
    return HttpService.get(`/integration/supplier`, params)
        .then(response => {
            return response.supplier
        })
}

function setPaymentSum(values) {
    return HttpService.put(`/integration/contract/delivery/order/payment/sum`, values)
        .then(response => {
            return response.contract
        })
}

function setOrderItemsValues(formData) {
    return HttpService.post(`/integration/contract/delivery/order/items`, formData, false, true)
        .then(response => {
            return response.contract
        })
}
