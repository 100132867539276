import React, {useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {
    Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField
} from "@material-ui/core"

import {Submit} from "../../../../App/components/Button/Submit"
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {AuthorizationService} from "../../../../Auth/services/authorization";
import {ContractActions} from "../../../actions/contract";


export const Reject = (props) => {
    const dispatch = useDispatch()

    const [dialog, setDialog] = useState(false)

    const { contract } = props

    const {account} = useSelector(state => state.account)

    return (((AuthorizationService.roles(account, 'customer') && ((contract.active && AuthorizationService.permissions(account, "create_delivery_order")) || (!contract.active && AuthorizationService.permissions(account, "delivery_order_after_event"))) && (contract.status.index === 8)) || ((AuthorizationService.roles(account, 'supplier') && (contract.status.index === 7)))) || ((contract.status.index === 1) && ((AuthorizationService.roles(account, 'customer') && AuthorizationService.permissions(account, "create_delivery_order") && contract.creator === 'supplier') || (AuthorizationService.roles(account, 'supplier') && (contract.creator === 'customer' || contract.creator === 'system'))))) ? (
        <Grid item>
            <Submit
                disableElevation
                variant="contained"
                color="primary"
                type="button"
                onClick={() => {
                    setDialog(true)
                }}
            >
                Отклонить
            </Submit>
            {dialog &&
                <Dialog
                    open={dialog}
                    onClose={() => {
                        setDialog(false)
                    }}
                    fullWidth={true}
                    maxWidth={'sm'}
                >
                    <DialogTitle>Укажите причину отклонения договора</DialogTitle>
                    <Formik
                        initialValues={{
                            comment: ''
                        }}
                        validationSchema={Yup.object().shape({
                            comment: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
                        })}
                        onSubmit={(values, {setSubmitting}) => {
                            return dispatch(ContractActions.reject(contract.id, values)).then(
                                () => {
                                    setDialog(false)
                                },
                                () => {
                                    setSubmitting(false)
                                }
                            )
                        }}
                    >
                        {({
                              dirty,
                              isValid,
                              isSubmitting,
                              setFieldValue,
                          }) => (
                            <Form>
                                <DialogContent>
                                    <TextField
                                        fullWidth
                                        name="comment"
                                        label="Комментарий"
                                        rows={3}
                                        rowsMax={6}
                                        multiline
                                        variant="standard"
                                        onChange={(e) => setFieldValue(`comment`, e.target.value)}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Grid container direction="row" justify="flex-end" alignItems="stretch">
                                        <Grid item>
                                            <Submit
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={isSubmitting || !isValid || !dirty}
                                            >
                                                Отклонить
                                            </Submit>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </Dialog>
            }
        </Grid>
    ) : null
}
