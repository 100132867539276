import {HttpService} from "../../App/services/http"

export const OrderService = {
    orders,
    lots,
    lot
}

function orders(params) {
    return HttpService.get(`/report/orders`, params)
}

function lots(params) {
    return HttpService.get(`/report/lots`, params)
}

function lot(id) {
    return HttpService.get(`/report/order/${id}`)
        .then(response => {
            return response.lot
        })
}
