import React from "react"
import {useSelector} from "react-redux"
import format from "date-fns/format"

import {
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel
} from "@material-ui/core"

import {history} from "../../../App/helpers/history"

const useStyles = makeStyles(theme => ({
    fullWidth: {
        "width": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    tableContainer: {
        "min-height": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 538px)",
        [theme.breakpoints.down('lg')]: {
            "height": "calc(100vh - 610px)"
        },
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
        position: "absolute",
        bottom: 0,
        width: "100%",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    }
}))

const columns = [
    {
        id: '0',
        label: 'ID',
        fieldName: 'id',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '1',
        label: 'Дата создания',
        fieldName: 'created_at',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '2',
        label: 'Заказчик',
        fieldName: 'customer',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '3',
        label: 'Поставщик',
        fieldName: 'supplier',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '4',
        label: 'Позиций',
        fieldName: 'items_count',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '5',
        label: 'Сумма',
        fieldName: 'price',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '6',
        label: 'Сумма договора',
        fieldName: 'total_price',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '7',
        label: 'Оставшаяся сумма',
        fieldName: 'orders_sum',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '8',
        label: 'Номер договора',
        fieldName: 'number',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '9',
        label: 'Номер закупки',
        fieldName: 'purchase_number',
        format: (value) => value.toLocaleString(),
    },
];

export const Contracts = (props) => {
    const classes = useStyles()

    const { tableRef, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, handleSortChange, sort, sortDirection } = props

    const { contracts } = useSelector(state => state.reportContract)

    const handleSortField = (sortBy) => {
        handleSortChange(sortBy)
    }

    return (
        <React.Fragment>
            <Grid item className={classes.fullWidth}>
                <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                    <Grid item className={classes.orders}>
                        <TableContainer className={classes.tableContainer}>
                            <Table ref={tableRef} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                                sortDirection={sort.id === column.id ? sortDirection : false}
                                            >
                                                <TableSortLabel
                                                    active={sort.id === column.id}
                                                    direction={sortDirection}
                                                    hideSortIcon={Boolean(sort.id !== column.id)}
                                                    onClick={(el) => {
                                                        handleSortField({
                                                            id: column.id,
                                                            column: column.fieldName
                                                        })
                                                    }}
                                                >
                                                    {column.label}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {contracts.data && contracts.data.map(contract => {
                                        return (
                                            <TableRow
                                                className={classes.tableRow}
                                                hover key={contract.id}
                                                onClick={() => {
                                                    history.push(`/report/contract/${contract.id}`)
                                                }}
                                            >
                                                <TableCell>
                                                    {contract.id}
                                                </TableCell>
                                                <TableCell>
                                                    {contract.created ? format(new Date(contract.created), 'dd.MM.yyyy HH:mm') : null}
                                                </TableCell>
                                                <TableCell>
                                                    {contract.customer.name}
                                                </TableCell>
                                                <TableCell>
                                                    {contract.supplier.name}
                                                </TableCell>
                                                <TableCell>
                                                    {contract.items_count}
                                                </TableCell>
                                                <TableCell>
                                                    {parseFloat(contract.price ?? '0').toLocaleString('ru-RU', {style: 'currency', currency: 'RUB'})}
                                                </TableCell>
                                                <TableCell>
                                                    {contract.framework ? '-' : Number(contract.total_price).toLocaleString('ru-RU', {style: 'currency', currency: contract.currency.code}).replace('KZT', '₸')}
                                                </TableCell>
                                                <TableCell>
                                                    {contract.framework ? '-' : ((Number(contract.total_price)-Number(contract.orders_sum) > 0) ? Number(contract.total_price)-Number(contract.orders_sum) : 0).toLocaleString('ru-RU', {style: 'currency', currency: contract.currency.code}).replace('KZT', '₸')}
                                                </TableCell>
                                                <TableCell>
                                                    {contract.number}
                                                </TableCell>
                                                <TableCell>
                                                    {!contract.unscheduled_purchase && contract.purchase_number ? `${contract.purchase_number}/${contract.offer.lot.number ? contract.offer.lot.number : contract.offer.lot.index}` : null}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.footer}>
                <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                    <Grid item className={classes.footerContent}>
                        <Grid container className={classes.footerContainer} direction="row" justify="space-between" alignItems="center" spacing={2}>
                            <Grid item>
                                <TablePagination
                                    rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                    component='div'
                                    labelRowsPerPage={'Записей на странице:'}
                                    count={contracts.meta.total}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
