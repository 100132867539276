import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik, getIn, useFormikContext} from "formik"
import {CheckboxWithLabel} from "formik-material-ui"
import * as Yup from "yup"

import {
    Grid, makeStyles, Paper, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody
} from "@material-ui/core"

import {Submit} from "../../../../App/components/Button/Submit"
import {PurchasePlanActions} from "../../../actions/purchasePlan"
import {history} from "../../../../App/helpers/history"
import {DialogWithVariantsChoice} from "../../../../App/components/DialogWithVariantsChoice/DialogWithVariantsChoice"
import {TextFieldWithError as CustomTextField} from "../../../../App/components/Inputs/TextFieldWithError";
import {UserActions} from "../../../../Company/components/User/actions/user";
import {AsyncAutocomplete} from "../../../../App/components/Inputs/AsyncAutocomplete";
import {CompanyActions} from "../../../../Company/actions/company";
import {ScheduleActions} from "../../../actions/schedule";
import {sources} from "../../../dics/Funding/sources";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.planningContent.width,
        padding: theme.planningContent.padding,
        margin: theme.planningContent.margin,
        position: "relative"
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 0 !important"
    },
    input: {
        "width": "100%",
        "height": "64px"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
        "position": "absolute",
        "bottom": "0",
        "width": "100%",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    contentBody: {
        position: "relative"
    },
    inputContent: {
        height: "calc(100vh - 181px)",
        overflow: "auto",
        flexWrap: "nowrap",
        padding: "0 25px",
        marginBottom: "70px"
    },
}));

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {account} = useSelector(state => state.account)
    const {id} = useParams()

    const [loading, setLoading] = useState(false)
    const [plan, setPlan] = useState(null)
    const [open, setOpen] = useState(false)

    const fundingSources = ['OMS', 'DMS', 'SPECIAL_PURPOSE_FINANCING', 'INVESTMENT_PROGRAM']

    useEffect(() => {
        const getData = async () => {
            if (id) {
                await dispatch(PurchasePlanActions.plan(id, true)).then(data => setPlan(data))
            }
        }

        getData().then(() => {
            setLoading(true)
        })
        // eslint-disable-next-line
    }, [dispatch]);

    const Budget = () => {
        const { values, setValues } = useFormikContext()

        useEffect(() => {
            if (values.schedule?.budget) {
                setValues({
                    ...values,
                    ...{
                        budget: {
                            ...values.budget
                        }
                    }
                })
            }
            // eslint-disable-next-line
        }, fundingSources.map(key => values.budget[key.toLowerCase()]))

        useEffect(() => {
            if (!plan && values.schedule?.budget) {
                setValues({
                    ...values,
                    ...{
                        budget:  [...fundingSources, 'OTHER'].reduce((obj, key) => {
                            switch (key) {
                                default:
                                    obj[key.toLowerCase()] = '';
                            }

                            return obj
                        }, {})
                    }
                })
            }
            // eslint-disable-next-line
        }, [values.schedule?.budget])

        return null
    };

    const onClose = () => {
        history.push('/planning/purchase-plans')
    }

    return loading ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" alignItems="center" className={classes.contentBody}>
                    <Formik
                        initialValues={{
                            company: plan ? plan.company : account.company,
                            schedule: plan ? plan.schedule : '',
                            zero: plan ? plan.zero : false,
                            users: plan ? plan.users : [],
                            budget: plan?.budget
                                ? Object.entries(plan.budget).reduce((obj, [key, value]) => {
                                        obj[key.toLowerCase()] = value ?? '';
                                        return obj
                                    }, {})
                                : [...fundingSources, 'OTHER'].reduce((obj, key) => {
                                    obj[key.toLowerCase()] = '';
                                    return obj
                                }, {})
                        }}
                        validationSchema={Yup.object().shape({
                            company: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            schedule: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            zero: Yup.boolean().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            users: Yup.array(),
                            budget: Yup.object().shape([...fundingSources, 'OTHER'].reduce((obj, key) => {
                                obj[key.toLowerCase()] = Yup.number().required(' ');
                                switch (key) {
                                    default:
                                        obj[key.toLowerCase()] = Yup.number();
                                }

                                return obj
                            }, {})),
                        })}
                        onSubmit={(values, {setSubmitting}) => {
                            values = {
                                ...values,
                                ...{
                                    company: values.company.id,
                                    schedule: values.schedule.id,
                                    users: values.users.map(user => user.id)
                                }
                            }

                            return dispatch(id ? PurchasePlanActions.edit(id, values) : PurchasePlanActions.add(values)).then(
                                (response) => {
                                    setSubmitting(false)
                                    if (!id) {
                                        setOpen(response.id)
                                    } else {
                                        onClose()
                                    }
                                },
                                errors => {
                                    if (errors) {}
                                    setSubmitting(false)
                                }
                            )
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              isSubmitting,
                              setValues,
                              setFieldValue,
                              setFieldTouched
                          }) => (
                            <Form className={classes.fullWidth}>
                                <Grid item className={classes.inputContent}>
                                    <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                        <Grid item className={classes.header}>
                                            <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                                <Grid item className={classes.fullWidth}>
                                                    <Typography className={classes.title}>
                                                        {plan ? 'Редактирование' : 'Создание'} плана закупок
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch" spacing={4}>
                                                <Grid item>
                                                    <Grid container direction="row" alignItems="stretch" wrap="wrap" spacing={4}>
                                                        <Grid item xs={4} className={classes.input}>
                                                            <AsyncAutocomplete
                                                                value={values.company}
                                                                name={"company"}
                                                                getOptionLabel={option => option.legal_detail?.name}
                                                                label={"ЧУЗ *"}
                                                                getValues={(search) => CompanyActions.customers({
                                                                    limit: 15,
                                                                    page: 1,
                                                                    ...(search ? {search: search} : {})
                                                                })}
                                                                onChange={async (e, value) => {
                                                                    await setValues({
                                                                        ...values,
                                                                        ...{
                                                                            company: value,
                                                                            schedule: '',
                                                                            users: []
                                                                        }
                                                                    })
                                                                }}
                                                                setTouched={setFieldTouched}
                                                                error={getIn(touched, 'company') && getIn(errors, 'company')}
                                                                disabled
                                                                values={plan ? [plan.company] : []}
                                                                default={plan ? plan.company : ''}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4} className={classes.input}>
                                                            <AsyncAutocomplete
                                                                value={values.schedule}
                                                                name={"schedule"}
                                                                getOptionLabel={option => option.name}
                                                                label={"Общий план-график *"}
                                                                condition={true}
                                                                getValues={(search) => ScheduleActions.get({
                                                                    limit: 50,
                                                                    page: 1,
                                                                    company: values.company.id,
                                                                    ...(search ? {search: search} : {})
                                                                })}
                                                                onChange={async (e, value) => {
                                                                    await setFieldValue('schedule', value)
                                                                }}
                                                                setTouched={setFieldTouched}
                                                                error={getIn(touched, 'schedule') && getIn(errors, 'schedule')}
                                                                disabled={isSubmitting || plan || !values.company}
                                                                values={plan ? [plan.schedule] : []}
                                                                default={plan ? plan.schedule : ''}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4} className={classes.input}>
                                                            <Field
                                                                component={CheckboxWithLabel}
                                                                name="zero"
                                                                color="primary"
                                                                Label={{label: "Нулевой"}}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <TableContainer className={classes.tableContainer}>
                                                        <Table stickyHeader aria-label="sticky table" className={classes.table}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell style={{'background-color': 'white'}} colSpan={5}>Лимит оперативного бюджета</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    {Object.entries(sources).filter(([key]) => (fundingSources.includes(key))).map(([key, source]) => (
                                                                        <TableCell key={key}>{source.name}</TableCell>
                                                                    ))}
                                                                    <TableCell>КОМ</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    {fundingSources.map((key) => (
                                                                        <TableCell key={key}>
                                                                            <Grid item className={classes.input}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    name={`budget.${key.toLowerCase()}`}
                                                                                    disabled={isSubmitting || !values.schedule}
                                                                                    type="number"
                                                                                    inputProps={{
                                                                                        min: 0,
                                                                                        step: 0.01
                                                                                    }}
                                                                                    component={CustomTextField}
                                                                                />
                                                                            </Grid>
                                                                        </TableCell>
                                                                    ))}
                                                                    <TableCell>
                                                                        <Grid item className={classes.input}>
                                                                            <Field
                                                                                fullWidth
                                                                                name="budget.other"
                                                                                type="number"
                                                                                inputProps={{
                                                                                    min: 0,
                                                                                    step: 0.01
                                                                                }}
                                                                                component={CustomTextField}
                                                                            />
                                                                        </Grid>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    <Budget />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.footer}>
                                    <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                        <Grid item className={classes.footerContent}>
                                            <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                <Grid item>
                                                    <Submit
                                                        disableElevation
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                    >
                                                        {plan ? 'Сохранить' : 'Создать'}
                                                    </Submit>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {open ? (
                                    <DialogWithVariantsChoice
                                        open={open}
                                        maxWidth={'sm'}
                                        direction={'column'}
                                        onClose={onClose}
                                        textTitle={'Выберете дальнейшее действие'}
                                        buttons={[
                                            {
                                                xs: 12,
                                                callback: () => {
                                                    history.push('/planning/purchase-plans')
                                                },
                                                text: 'К списку планов закупок',
                                            },
                                            ...(values.zero ? [] : [{
                                                xs: 12,
                                                callback: () => {
                                                    history.push(`/planning/purchase-plan/${open}/purchase/add`)
                                                },
                                                text: 'Создать закупку',
                                            }]),
                                            {
                                                xs: 12,
                                                callback: () => {
                                                    history.go(`/planning/purchase-plan/add`)
                                                },
                                                text: 'Создать новый план закупок',
                                            },
                                        ]}
                                    />
                                ) : null}
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Paper>
        </Grid>
    ) : null
}
