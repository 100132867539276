import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {Field, FieldArray, Form, Formik, useFormikContext} from "formik"
import {v4 as uuidv4} from 'uuid'
import * as Yup from "yup"

import {
    Box, CircularProgress, Grid, IconButton, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Typography, Accordion, AccordionDetails, AccordionSummary, Tooltip
} from "@material-ui/core";
import {
    Clear,
    ExpandMore,
    InsertDriveFileOutlined,
    Replay,
    Error as ErrorIcon
} from '@material-ui/icons'

import {CompanyActions} from "../../../actions/company"
import {Submit} from "../../../../App/components/Button/Submit"
import DeleteSweepOutlinedIcon from '@material-ui/icons/DeleteSweepOutlined';
import {TextField as CustomTextField} from "../../../../App/components/Inputs/TextField"
import { CustomAutocomplete } from "../../../../App/components/Inputs/CustomAutocomplete"
import {AccountActions} from "../../../actions/account";

const useStyles = makeStyles(() => ({
    inputContent: {
        "padding": "20px 30px 0"
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 298px)"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    regions: {
        "overflow": "auto",
        'height': "300px"
    },
    regionInput: {
        "margin-top": "0 !important"
    },
    lightTooltip: {
        "font-size": "0.8rem",
        "backgroundColor": "white",
        "border": "1px solid #ededed",
        "color": "rgba(0, 0, 0, 0.87)",
    },
    tooltip: {
        "background-color": "#d9534f",
        "font-size": "15px",
        "color": "#fff",
        "padding": "15px 15px",
    }
}))

export const TermsOfParticipation = (props) => {
    const dispatch = useDispatch()
    const { company, setDirty, setLoading } = props
    const classes = useStyles()

    const [regions] = useState(company.terms_participation ? company.terms_participation.regions : [])

    const AutoDirty = () => {
        const { dirty } = useFormikContext()
        useEffect(() => {
            setDirty(dirty)
        }, [dirty])

        return null
    };

    const [files, setFiles] = useState(() => {
        let files = {}

        const types = {
            ...(((company.type === 'individual') || (company.type === 'self')) ? {} : {
                'constituent_documents': {
                    name: 'Учредительные документы с учётом внесенных в них изменений, свидетельства о государственной регистрации учредительных документов и внесённых в них изменений *',
                    children: {
                        'charter': {
                            name: 'Устав *'
                        },
                        'charter_changes': {
                            name: 'Изменения в уставе'
                        },
                        'protocol': {
                            name: 'Протокол (приказ о создании общества) *'
                        }
                    }
                },
                'notification_of_the_transition_to_a_simplified_tax_system': {
                    name: 'Уведомление о переходе на упрощенную систему налогообложения (УСН)'
                },
            }),
            'certificates': ((company.type === 'individual') || (company.type === 'self')) ? {
                name: 'ИНН, выписка из ЕГРИП *',
                children: {
                    'INN': {
                        name: 'ИНН *'
                    },
                    'egrip_transcript': {
                        name: 'Выписка из ЕГРИП *'
                    },
                    'passport_copy_first_page': {
                        name: 'Копия паспорта * (разворот лицевой страницы)'
                    },
                    'notification_of_the_transition_to_a_simplified_tax_system': {
                        name: 'Уведомление о переходе на упрощенную систему налогообложения (УСН)'
                    }
                },
            } : {
                name: 'Свидетельства *',
                children: {
                    'certificate_of_registration': {
                        name: 'Свидетельство о постановке на учёт в налоговом органе *'
                    },
                    'certificate_of_state_registration': {
                        name: 'Свидетельство о государственной регистрации контрагента *'
                    },
                    'certificate_of_changes': {
                        name: 'Свидетельство о внесении в госудраственный реестр изменений в сведениях о юридическом лице, не связанных с внесением в учредительные документы'
                    }
                }
            },
            ...(((company.type === 'individual') || (company.type === 'self')) ? {} : {
               'extract_from_the_register': {
                   name: 'Выписка из единого государственного реестра юридических лиц, выданная регистрирующим органом *'
               },
           }),
            'documents_confirming_the_authority_of_the_person': {
                name: 'Документы, подтверждающие полномочия лица на подписание договора'
            },
            'drug_manufacturing_licenses': {
                name: 'Лицензии на производство лекарственных средств или на фармацевтическую деятельность с правом оптовой торговли'
            },
            'licenses_for_activities_with_medical_equipment': {
                name: 'Лицензии на осуществление деятельности по производству и техническому обслуживанию медицинской техники'
            },
            'legal_acts_on_licensing_in_the_field_of_use_of_ionizing_radiation_sources': {
                name: 'Нормативные правовые акты по лицензированию в области использования источников ионизирующего излучения'
            },
            'fuel_storage_licenses': {
                name: 'Лицензии на хранение ГСМ'
            },
            'certificate_for_wholesale_and_retail_sale_of_fuel_and_lubricants': {
                name: 'Свидетельство на оптово-розничную реализацию ГСМ'
            },
            'security_license': {
                name: 'Лицензия на осуществление охранной деятельности'
            }
        }

        for (let type in types) {
            files[type] = {
                name: types[type].name,
                files: company.contents
                    ? (company.contents.hasOwnProperty(type) ? company.contents[type].map(file => {
                        return {
                            loading: false,
                            error: false,
                            uuid: uuidv4(),
                            file: file
                        }
                    }) : [] )
                    : [],
                ...(types[type].hasOwnProperty('children') ? {children: function () {

                    let children = {}

                    for (let key in types[type].children) {
                        children[key] = {
                            name: types[type].children[key].name,
                            files: company.contents
                                ? (company.contents.hasOwnProperty(key) ? company.contents[key].map(file => {
                                    return {
                                        loading: false,
                                        error: false,
                                        uuid: uuidv4(),
                                        file: file
                                    }
                                }) : [] )
                                : []
                        }
                    }

                    return children
                }()} : {})
            }
        }
        return files
    })

    const addFile = (file, type, uuid = null) => {
        const formData = new FormData()
        formData.append('type', type)
        const uuidNew = uuid ?? uuidv4()

        setFiles(prevState => {
            let tmp = {}

            for (let typePrevState in prevState) {
                if (typePrevState === type) {
                    tmp[typePrevState] = {
                        ...prevState[typePrevState],
                        ...{
                            files: uuid
                                ? prevState[typePrevState].files.map((item) => {
                                    if (item.uuid === uuid) {
                                        item.load = true
                                        item.error = false
                                    }
                                    return item
                                })
                                : [...prevState[typePrevState].files, ...[{uuid: uuidNew, load: true, error: false, file: file}]]
                        }
                    }
                } else {
                    tmp[typePrevState] = prevState[typePrevState]
                }

                if (prevState[typePrevState].hasOwnProperty('children')) {
                    for (let typeChildrenPrevState in prevState[typePrevState].children) {
                        if (prevState[typePrevState].children.hasOwnProperty(typeChildrenPrevState)) {
                            if (typeChildrenPrevState === type) {
                                tmp[typePrevState].children[typeChildrenPrevState] = {
                                    ...prevState[typePrevState].children[typeChildrenPrevState],
                                    ...{
                                        files: uuid
                                            ? prevState[typePrevState].children[typeChildrenPrevState].files.map((item) => {
                                                if (item.uuid === uuid) {
                                                    item.load = true
                                                    item.error = false
                                                }
                                                return item
                                            })
                                            : [...prevState[typePrevState].children[typeChildrenPrevState].files, ...[{uuid: uuidNew, load: true, error: false, file: file}]]
                                    }
                                }
                            } else {
                                tmp[typePrevState].children[typeChildrenPrevState] = prevState[typePrevState].children[typeChildrenPrevState]
                            }
                        }
                    }
                }
            }
            return {
                ...prevState,
                ...tmp
            }
        })

        formData.append('file', file)

        dispatch(CompanyActions.addFile(formData)).then(async (content) => {
            setFiles(prevState => {
                let tmp = {}

                for (let typePrevState in prevState) {
                    if (typePrevState === type) {
                        tmp[typePrevState] = {
                            ...prevState[typePrevState],
                            ...{
                                files: prevState[typePrevState].files.map((item) => {
                                    if (item.uuid === uuidNew) {
                                        item.load = false
                                        item.file = {
                                            id: content.id,
                                            name: item.file.name
                                        }
                                    }
                                    return item
                                })
                            }
                        }
                    } else {
                        tmp[typePrevState] = prevState[typePrevState]
                    }

                    if (prevState[typePrevState].hasOwnProperty('children')) {
                        for (let typeChildrenPrevState in prevState[typePrevState].children) {
                            if (prevState[typePrevState].children.hasOwnProperty(typeChildrenPrevState)) {
                                if (typeChildrenPrevState === type) {
                                    tmp[typePrevState].children[typeChildrenPrevState] = {
                                        ...prevState[typePrevState].children[typeChildrenPrevState],
                                        ...{
                                            files: prevState[typePrevState].children[typeChildrenPrevState].files.map((item) => {
                                                if (item.uuid === uuidNew) {
                                                    item.load = false
                                                    item.file = {
                                                        id: content.id,
                                                        name: item.file.name
                                                    }
                                                }
                                                return item
                                            })
                                        }
                                    }
                                } else {
                                    tmp[typePrevState].children[typeChildrenPrevState] = prevState[typePrevState].children[typeChildrenPrevState]
                                }
                            }
                        }
                    }
                }

                return {
                    ...prevState,
                    ...tmp
                }
            })
            await dispatch(AccountActions.account());
        }, () => {
            setFiles(prevState => {
                let tmp = {}

                for (let typePrevState in prevState) {
                    if (typePrevState === type) {
                        tmp[typePrevState] = {
                            ...prevState[typePrevState],
                            ...{
                                files: prevState[typePrevState].files.map((item) => {
                                    if (item.uuid === uuidNew) {
                                        item.load = false
                                    }
                                    return item
                                })
                            }
                        }
                    } else {
                        tmp[typePrevState] = prevState[typePrevState]
                    }

                    if (prevState[typePrevState].hasOwnProperty('children')) {
                        for (let typeChildrenPrevState in prevState[typePrevState].children) {
                            if (prevState[typePrevState].children.hasOwnProperty(typeChildrenPrevState)) {
                                if (typeChildrenPrevState === type) {
                                    tmp[typePrevState].children[typeChildrenPrevState] = {
                                        ...prevState[typePrevState].children[typeChildrenPrevState],
                                        ...{
                                            files: prevState[typePrevState].children[typeChildrenPrevState].files.map((item) => {
                                                if (item.uuid === uuidNew) {
                                                    item.load = false
                                                }
                                                return item
                                            })
                                        }
                                    }
                                } else {
                                    tmp[typePrevState].children[typeChildrenPrevState] = prevState[typePrevState].children[typeChildrenPrevState]
                                }
                            }
                        }
                    }
                }
                return {
                    ...prevState,
                    ...tmp
                }
            })
        })
    }

    const deleteFile = (file, type, uuid) => {
        setFiles((prevState => {
            let tmp = {}

            for (let typePrevState in prevState) {
                if (typePrevState === type) {
                    tmp[typePrevState] = {
                        ...prevState[typePrevState],
                        ...{
                            files: prevState[typePrevState].files.map((item) => {
                                if (item.uuid === uuid) {
                                    item.load = true
                                }
                                return item
                            })
                        }
                    }
                } else {
                    tmp[typePrevState] = prevState[typePrevState]
                }

                if (prevState[typePrevState].hasOwnProperty('children')) {
                    for (let typeChildrenPrevState in prevState[typePrevState].children) {
                        if (prevState[typePrevState].children.hasOwnProperty(typeChildrenPrevState)) {
                            if (typeChildrenPrevState === type) {
                                tmp[typePrevState].children[typeChildrenPrevState] = {
                                    ...prevState[typePrevState].children[typeChildrenPrevState],
                                    ...{
                                        files: prevState[typePrevState].children[typeChildrenPrevState].files.map((item) => {
                                            if (item.uuid === uuid) {
                                                item.load = true
                                            }
                                            return item
                                        })
                                    }
                                }
                            } else {
                                tmp[typePrevState].children[typeChildrenPrevState] = prevState[typePrevState].children[typeChildrenPrevState]
                            }
                        }
                    }
                }
            }

            return {
                ...prevState,
                ...tmp
            }
        }))

        dispatch(CompanyActions.deleteFile(file.id)).then(async () => {
            setFiles((prevState) => {
                let tmp = {}

                for (let typePrevState in prevState) {
                    if (typePrevState === type) {
                        tmp[typePrevState] = {
                            ...prevState[typePrevState],
                            ...{
                                files: prevState[typePrevState].files.filter((item) => {
                                    return item.uuid !== uuid
                                })
                            }
                        }
                    } else {
                        tmp[typePrevState] = prevState[typePrevState]
                    }

                    if (prevState[typePrevState].hasOwnProperty('children')) {
                        for (let typeChildrenPrevState in prevState[typePrevState].children) {
                            if (prevState[typePrevState].children.hasOwnProperty(typeChildrenPrevState)) {
                                if (typeChildrenPrevState === type) {
                                    tmp[typePrevState].children[typeChildrenPrevState] = {
                                        ...prevState[typePrevState].children[typeChildrenPrevState],
                                        ...{
                                            files: prevState[typePrevState].children[typeChildrenPrevState].files.filter((item) => {
                                                return item.uuid !== uuid
                                            })
                                        }
                                    }
                                } else {
                                    tmp[typePrevState].children[typeChildrenPrevState] = prevState[typePrevState].children[typeChildrenPrevState]
                                }
                            }
                        }
                    }
                }

                return {
                    ...prevState,
                    ...tmp
                }
            })
            await dispatch(AccountActions.account());
        }, () => {
            setFiles((prevState => {
                let tmp = {}

                for (let typePrevState in prevState) {
                    if (typePrevState === type) {
                        tmp[typePrevState] = {
                            ...prevState[typePrevState],
                            ...{
                                files: prevState[typePrevState].files.map((item) => {
                                    if (item.uuid === uuid) {
                                        item.load = false
                                    }
                                    return item
                                })
                            }
                        }
                    } else {
                        tmp[typePrevState] = prevState[typePrevState]
                    }

                    if (prevState[typePrevState].hasOwnProperty('children')) {
                        for (let typeChildrenPrevState in prevState[typePrevState].children) {
                            if (prevState[typePrevState].children.hasOwnProperty(typeChildrenPrevState)) {
                                if (typeChildrenPrevState === type) {
                                    tmp[typePrevState].children[typeChildrenPrevState] = {
                                        ...prevState[typePrevState].children[typeChildrenPrevState],
                                        ...{
                                            files: prevState[typePrevState].children[typeChildrenPrevState].files.map((item) => {
                                                if (item.uuid === uuid) {
                                                    item.load = false
                                                }
                                                return item
                                            })
                                        }
                                    }
                                } else {
                                    tmp[typePrevState].children[typeChildrenPrevState] = prevState[typePrevState].children[typeChildrenPrevState]
                                }
                            }
                        }
                    }
                }

                return {
                    ...prevState,
                    ...tmp
                }
            }))
        })
    }

    const showRequiredFilesError = (docTypesName, docTypesKey, company) => {
        let requiredDocKeys = {
            'constituent_documents': [
                'charter',
                'protocol'
            ],
            'certificates': [
                'certificate_of_registration',
                'certificate_of_state_registration',
            ],
            'extract_from_the_register': [],
        }

        if (((company.type === 'individual') || (company.type === 'self'))) {
            requiredDocKeys = {
                'certificates': [
                    'INN',
                    'egrip_transcript',
                    'passport_copy_first_page'
                ]
            }
        }

        let hasError = false;

        if (Object.keys(requiredDocKeys).indexOf(docTypesKey) !== -1) {
            if (docTypesName.hasOwnProperty('children')) {
                let files = docTypesName.children;
                let requiredFileKeys = [];
                for (let type in files) {
                    if (files[type].files.length > 0) {
                        requiredFileKeys.push(type);
                    }
                }
                if (requiredDocKeys[docTypesKey].filter(el => requiredFileKeys.indexOf(el) === -1).length > 0) {
                    hasError = true;
                }
            } else {
                if (docTypesName.files.length === 0) {
                    hasError = true;
                }
            }
        }

        return hasError
    }

    const submit = (values, {setSubmitting}) => {
        return dispatch(CompanyActions.termsParticipation(values)).then(
            async () => {
                await  dispatch(CompanyActions.company({
                    include: "legalDetail.city.region.country,legalDetail.okpds.okved,legalDetail.executiveDirector,purchasingDepartment,legalDetail.city.region.country,legalDetail.passportDetails,paymentDetail,contactInformation,termsParticipation.regions.region,termsParticipation.files,occupations,status,territories.users,institutionsBudgets.fundingSource,institutionsBudgets.responsible"
                }))
                await dispatch(AccountActions.account());
                setSubmitting(false)
                setLoading(false)
            },
            () => {
                setSubmitting(false)
            }
        )
    }

    return (
        <Grid item>
            <Formik
                enableReinitialize={ true }
                initialValues={{
                    region: '',
                    installment_plan: company.terms_participation ? company.terms_participation.installment_plan : 0,
                    regions: regions
                }}
                validationSchema={Yup.object().shape({
                    regions: Yup.array().of(Yup.object().shape({term: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!")}))
                })}
                onSubmit={submit}
            >
                {({
                      dirty,
                      values,
                      setValues,
                      isSubmitting,
                      isValid,
                  }) => (
                    <Form>
                        <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                            <Grid item className={classes.inputContent}>
                                <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                    <Grid item>
                                        <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                            <Grid item xs={8} className={classes.column}>
                                                <FieldArray name="regions" render={ arrayHelpers => (
                                                    <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                        <Grid item xs={10}>
                                                            <CustomAutocomplete
                                                                name={'regions'}
                                                                values={values}
                                                                setValues={setValues}
                                                                getObject={item => ({region: item, term: 0})}
                                                                items={props.regions.filter(region => !values.regions.find(city => (region.id === city.region.id)))}
                                                                getOptionLabel={(option) => option.name ?? ''}
                                                                label="Добавьте регион(ы), в котором вы хотите видеть запросы Заказчика *"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                <Grid item>
                                                                    {!!values.regions && !!values.regions.length  &&
                                                                        <TableContainer className={classes.regions}>
                                                                            <Table stickyHeader>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell>Регион</TableCell>
                                                                                        <TableCell>Срок поставки (дней) *</TableCell>
                                                                                        <TableCell align="right">
                                                                                            <Tooltip
                                                                                                title="Удалить все выбранные регионы"
                                                                                                placement="right"
                                                                                                classes={{tooltip: classes.lightTooltip}}
                                                                                            >
                                                                                                <IconButton
                                                                                                    style={{
                                                                                                        "width": "24px",
                                                                                                        "height": "24px",
                                                                                                        "padding": "0"
                                                                                                    }}
                                                                                                    onClick={() => {
                                                                                                        setValues({
                                                                                                            ...values,
                                                                                                            ...{regions: []}
                                                                                                        })
                                                                                                    }}
                                                                                                >
                                                                                                    <DeleteSweepOutlinedIcon
                                                                                                        color="error"
                                                                                                    />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                    <TableBody>
                                                                                    {values.regions.map((option, index) => (
                                                                                        <TableRow key={index}>
                                                                                            <TableCell component="th" scope="row">
                                                                                                {option.region.name}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                <Field
                                                                                                    className={classes.regionInput}
                                                                                                    fullWidth
                                                                                                    name={`regions.${index}.term`}
                                                                                                    type="number"
                                                                                                    step={1}
                                                                                                    inputProps={{
                                                                                                        min: 1
                                                                                                    }}
                                                                                                    component={CustomTextField}
                                                                                                />
                                                                                            </TableCell>
                                                                                            <TableCell align="right">
                                                                                                <IconButton
                                                                                                    size={"small"}
                                                                                                    onClick={() => {
                                                                                                        arrayHelpers.remove(index)
                                                                                                    }}
                                                                                                >
                                                                                                    <Clear />
                                                                                                </IconButton>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    ))}
                                                                                    </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}/>
                                            </Grid>
                                            <Grid item xs={4} className={classes.column}>
                                                <Field
                                                    fullWidth
                                                    name="installment_plan"
                                                    type="number"
                                                    label="Предоставление рассрочки (дней)"
                                                    step={1}
                                                    min={0}
                                                    inputProps={{
                                                        min: 0
                                                    }}
                                                    component={CustomTextField}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        {
                                            Object.keys(files).map((key, i) => (
                                                <Accordion key={i}>
                                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                                        <Typography>{files[key].name} </Typography>
                                                        {showRequiredFilesError(files[key], key, company) &&
                                                            <Tooltip
                                                                title="Не все документы загружены"
                                                                placement="right"
                                                                classes={{tooltip: classes.tooltip}}
                                                            >
                                                                <IconButton
                                                                    style={{
                                                                        "width": "24px",
                                                                        "height": "24px",
                                                                        "padding": "0"
                                                                }}
                                                                >
                                                                    <ErrorIcon style={{"color": "#d9534f"}}/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                            {files[key].hasOwnProperty('children') ?
                                                                Object.keys(files[key].children).map((childKey, j) => (
                                                                    <Box key={j} paddingLeft={2} paddingRight={1}>
                                                                        <Grid item>
                                                                            <Typography>{files[key].children[childKey].name}</Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={2}>
                                                                                <Grid item>
                                                                                    <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                                                                                        {!!files[key].children[childKey].files.length &&
                                                                                        files[key].children[childKey].files.map((option, index) => (
                                                                                            <Grid item key={index}>
                                                                                                <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                                                                    <Grid item>
                                                                                                        <InsertDriveFileOutlined size="large" />
                                                                                                    </Grid>
                                                                                                    <Grid item>
                                                                                                        {option.file.name}
                                                                                                    </Grid>
                                                                                                    <Grid item>
                                                                                                        {
                                                                                                            option.load
                                                                                                                ? <CircularProgress style={{ 'width': '25px', 'height': '25px' }} />
                                                                                                                : !option.file.hasOwnProperty('id')
                                                                                                                ? <IconButton
                                                                                                                    size={"small"}
                                                                                                                    onClick={() => addFile(option.file, childKey, option.uuid)}
                                                                                                                >
                                                                                                                    <Replay />
                                                                                                                </IconButton>
                                                                                                                : <IconButton
                                                                                                                    size={"small"}
                                                                                                                    onClick={() => deleteFile(option.file, childKey, option.uuid)}
                                                                                                                >
                                                                                                                    <Clear />
                                                                                                                </IconButton>
                                                                                                        }

                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        ))
                                                                                        }
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Submit
                                                                                        variant="contained"
                                                                                        component="label"
                                                                                        color="primary"
                                                                                    >
                                                                                        Обзор
                                                                                        <input
                                                                                            multiple
                                                                                            type="file"
                                                                                            style={{ display: "none" }}
                                                                                            onChange={(event) => {
                                                                                                const items = event.target.files

                                                                                                if (!!items.length) {
                                                                                                    for (let i = 0; i < items.length; i++) {
                                                                                                        addFile(items[i], childKey)
                                                                                                    }
                                                                                                }

                                                                                                event.target.value = null
                                                                                            }}
                                                                                        />
                                                                                    </Submit>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                )) : <Grid item>
                                                                    <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={2}>
                                                                        <Grid item>
                                                                            <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                                {!!files[key].files.length &&
                                                                                files[key].files.map((option, index) => (
                                                                                    <Grid item key={index}>
                                                                                        <Grid container direction="row" justify="space-between" alignItems="center">
                                                                                            <Grid item>
                                                                                                <InsertDriveFileOutlined size="large" />
                                                                                            </Grid>
                                                                                            <Grid item>
                                                                                                {option.file.name}
                                                                                            </Grid>
                                                                                            <Grid item>
                                                                                                {
                                                                                                    option.load
                                                                                                        ? <CircularProgress style={{ 'width': '25px', 'height': '25px' }} />
                                                                                                        : !option.file.hasOwnProperty('id')
                                                                                                        ? <IconButton
                                                                                                            size={"small"}
                                                                                                            onClick={() => addFile(option.file, key, option.uuid)}
                                                                                                        >
                                                                                                            <Replay />
                                                                                                        </IconButton>
                                                                                                        : <IconButton
                                                                                                            size={"small"}
                                                                                                            onClick={() => deleteFile(option.file, key, option.uuid)}
                                                                                                        >
                                                                                                            <Clear />
                                                                                                        </IconButton>
                                                                                                }

                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                ))
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                        {!files[key].hasOwnProperty('children') &&
                                                                        <Grid item>
                                                                            <Submit
                                                                                variant="contained"
                                                                                component="label"
                                                                                color="primary"
                                                                            >
                                                                                Обзор
                                                                                <input
                                                                                    multiple
                                                                                    type="file"
                                                                                    style={{display: "none"}}
                                                                                    onChange={(event) => {
                                                                                        const items = event.target.files

                                                                                        if (!!items.length) {
                                                                                            for (let i = 0; i < items.length; i++) {
                                                                                                addFile(items[i], key)
                                                                                            }
                                                                                        }

                                                                                        event.target.value = null
                                                                                    }}
                                                                                />
                                                                            </Submit>
                                                                        </Grid>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.footer}>
                            <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                <Grid item className={classes.footerContent}>
                                    <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Submit
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={!!values.regions.find(item => item.term === 0) || isSubmitting || !isValid || !dirty}
                                            >
                                                Сохранить
                                            </Submit>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <AutoDirty />
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}
