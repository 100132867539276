import React, {useState} from "react"
import {useDispatch} from "react-redux"

import {
    Grid,
    makeStyles
} from "@material-ui/core"

import {ButtonIcon} from "../../../App/components/Button/ButtonIcon";
import {Delete as DeleteIcon} from "@material-ui/icons";
import {DialogWithVariantsChoice} from "../../../App/components/DialogWithVariantsChoice/DialogWithVariantsChoice";
import {UserActions} from "./actions/user";

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    }
}))

export const Delete = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [dialog, setDialog] = useState(false)

    const {user, onClick} = props

    return <Grid item>
            <ButtonIcon
                onClick={(e) => {
                    e.stopPropagation()
                    setDialog(true)}}
                title='Удалить'
                children={<DeleteIcon/>}
                className={classes.button}
            />
            {dialog &&
                <DialogWithVariantsChoice
                    open={dialog}
                    onClose={() => setDialog(false)}
                    textTitle={'Подтвердите действие: удалить учетную запись?'}
                    buttons={[
                        {
                            xs: 6,
                            callback: (e) => {
                                e.stopPropagation()
                                setDialog(false)

                                return dispatch(
                                    UserActions.deleteUser(user)
                                ).then(() => onClick(), () => {})
                            },
                            text: 'Да',
                            color: 'secondary'
                        },
                        {
                            xs: 6,
                            callback: (e) => {
                                e.stopPropagation()
                                setDialog(false)
                            },
                            text: 'Нет'
                        },
                    ]}
                />
            }
        </Grid>
}
