import {HttpService} from "../../../App/services/http"

export const SupMarketplaceService = {
    orders,
    order,
    add,
    edit,
    cancel,
    status,
    remove,
    lotInfo
}

function orders(params) {
    return HttpService.get("/shop/supplier/my-orders", params)
}

function order(id) {
    return HttpService.get(`/shop/supplier/order/${id}`)
        .then(response => {
            return response.order
        })
}

function lotInfo(id) {
    return HttpService.get(`/shop/supplier/lotInfo/${id}`)
        .then(response => {
            return response.lot
        })
}

function add(formData) {
    return HttpService.post("/shop/supplier/order", formData, true, true)
}

function edit(id, formData) {
    return HttpService.post(`/shop/supplier/order/${id}`, formData, true, true)
}

function cancel(id, values) {
    return HttpService.put(`/shop/supplier/offer/${id}/cancel`, values)
}

function status(order_id, status_id, formData) {
    return HttpService.post(`/shop/order/${order_id}/lot/${status_id}/status`, formData, true, true)
}

function remove(id) {
    return HttpService.deleteMethod(`/shop/supplier/order/${id}`)
}
