import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import {fieldToTextField, Select} from "formik-material-ui"
import * as Yup from "yup"

import {
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    TextField as MuiTextField,
    Typography
} from "@material-ui/core"
import {Autocomplete as MuiAutocomplete} from "@material-ui/lab"

import {TextFieldWithError as CustomTextField} from "../../../../App/components/Inputs/TextFieldWithError"
import {Submit} from "../../../../App/components/Button/Submit"
import {CompanyActions} from "../../../../Account/actions/company"
import {Autocomplete} from "../../../../App/components/Inputs/Autocomplete"
import {AuthorizationService} from "../../../../Auth/services/authorization"
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon"
import {CompanyActions as CompActions} from "../../../actions/company"
import {useDebouncedCallback} from "use-debounce";

const useStyles = makeStyles(() => ({
    input: {
        "padding": "0 4px 3px !important",
        "width": "100%"
    },
    inputContent: {
        "padding": "20px 30px 0"
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 298px)"
    },
    column: {
        "height": "86px",
    },
    okpd: {
        "height": "calc(+ 20px)"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    group: {
        "padding": "8px"
    },
    groupTitle: {
        "font-weight": "500"
    },
    button: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "#898989",
        },
        "width": "auto",
        "max-height": "35px",
        "max-width": "auto",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "#fff",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-bottom": "3px solid #898989",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "#616161",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
            marginTop: "5px",
        }
    },
}))

const useAttributes = (account, country, company, cities, regions, banks, okved, classes) => {
    let attributes = {
        initialValues: {
            name: company.legal_detail.name,
            country: company.legal_detail.city.region.country.name,
            locality: company.legal_detail.city,
            current_address: company.legal_detail.current_address ?? '',
            director: {
                last_name: company.legal_detail.executive_director ? (company.legal_detail.executive_director.last_name ?? '') : '',
                first_name: company.legal_detail.executive_director ? (company.legal_detail.executive_director.first_name ?? '') : '',
                middle_name: company.legal_detail.executive_director ? (company.legal_detail.executive_director.middle_name ?? '') : ''
            },
        },
        validationSchema: {
            name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
            country: Yup.string(),
            locality: Yup.object().nullable().required("Необходимо указать город"),
            current_address: company.type ? Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!") : Yup.string(),
            director: Yup.object().shape({
                last_name: Yup.string(),
                first_name: Yup.string(),
                middle_name: Yup.string()
            }),
        },
        attributes: null
    }

    switch (company.type) {
        case 'individual':
            switch (country) {
                case 'ru':
                    attributes.initialValues = {
                        ...attributes.initialValues,
                        ...{
                            type: company.type,
                            legal_address: company.legal_detail.legal_address ?? '',
                            inn: company.legal_detail.inn,
                            ogrn: company.legal_detail.ogrn ?? '',
                            payment: {
                                bank: company.payment_detail ? (company.payment_detail.bank ?? '') : '',
                                bik: company.payment_detail ? (company.payment_detail.bik ?? '') : '',
                                correspondent_account: company.payment_detail ? (company.payment_detail.correspondent_account ?? '') : '',
                                checking_account: company.payment_detail ? (company.payment_detail.checking_account ?? '') : ''
                            },
                            ...(company.type ? {okpo: company.legal_detail ? (company.legal_detail.okpo ?? '') : '',okpd: company.legal_detail ? (company.legal_detail.okpd ?? {id: '', name: '', code: ''}) : {id: '', name: '', code: ''}} : {})
                        }
                    }

                    attributes.validationSchema = {
                        ...attributes.validationSchema,
                        ...{
                            type: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            legal_address: company.type ? Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!") : Yup.string(),
                            inn: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(12, "Длина ИНН должна быть 12 символов!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            ogrn: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(15, "Длина ОГРНИП должна быть 15 символов!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            payment: Yup.object().shape({
                                bank: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                bik: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                correspondent_account: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                checking_account: Yup.string().length(20, "Длина Р/С должна быть 20 символов!").required("Поле не может быть пустым или состоять из одних пробелов!")
                            }),
                            ...(company.type ? {okpd: Yup.array(),okpo: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').length((((company.type === 'individual') || (company.type === 'self')) ? 10 : 8)  , "Длина ОКПО должна быть " + (((company.type === 'individual') || (company.type === 'self')) ? 10 : 8) +" символов!").required("Поле не может быть пустым или состоять из одних пробелов!")} : {})
                        }
                    }

                    attributes.attributes = (account, values, setFieldValue, setValues, setFieldTouched, touched, search, setSearch) => (
                        <Grid item className={classes.inputContent}>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={8} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="name"
                                        type="text"
                                        label="Полное наименование"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={4} className={classes.column}>
                                    <FormControl fullWidth>
                                        <InputLabel>
                                            Тип
                                        </InputLabel>
                                        <Field
                                            fullWidth
                                            type="text"
                                            name="type"
                                            label="Тип"
                                            required
                                            disabled={!AuthorizationService.roles(account, 'admin')}
                                            component={Select}
                                        >
                                            {[
                                                {key: 'self', name: 'Самозанятый'},
                                                {key: 'individual', name: 'Индивидуальный предприниматель'},
                                                {key: 'legal', name: 'Юридическое лицо'},
                                            ].map((type, index) => (
                                                <MenuItem key={index} value={type.key}>
                                                    {type.name}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="country"
                                        type="text"
                                        label="Страна"
                                        disabled
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        name="locality"
                                        type="text"
                                        options={cities}
                                        getOptionLabel={option => {
                                            return option.name + ((option.name !== option.region.name) ? ", " + option.region.name : "")
                                        }}
                                        label="Населенный пункт"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={Autocomplete}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={company.type ? 4 : 6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="inn"
                                        type="text"
                                        label="ИНН"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={company.type ? 4 : 6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="ogrn"
                                        type="text"
                                        label="ОГРНИП"
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                                {company.type ? (
                                    <Grid item xs={4} className={classes.column}>
                                        <Field
                                            fullWidth
                                            name="okpo"
                                            type="number"
                                            label="Код ОКПО"
                                            required
                                            component={CustomTextField}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>
                            {company.type ? (
                                <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12} className={classes.okpd}>
                                        <MuiAutocomplete
                                            multiple
                                            filterOptions={options => options}
                                            options={okved.data}
                                            name={'okpd'}
                                            disabled={!AuthorizationService.roles(account, 'admin')}
                                            defaultValue={values.okpd ? values.okpd : null}
                                            getOptionLabel={(option) => option.code + ` ` + option.name}
                                            noOptionsText='Нет доступных вариантов'
                                            renderInput={params => {
                                                return <Field
                                                    fullWidth
                                                    component={MuiTextField}
                                                    {...params}
                                                    onChange={(e) => {
                                                        setSearch(e.target.value)
                                                    }}
                                                    name={'okpd'}
                                                    label="Код ОКПД"
                                                />
                                            }}
                                            onChange={(e, option) => {
                                                setFieldValue('okpd', option)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            ) : null}
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.groupTitle}>Платежные реквизиты</Typography>
                                    <Grid container className={classes.group} direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                <Grid item xs={6} className={classes.column}>
                                                    <Field
                                                        name="payment.bank"
                                                        type="text"
                                                        label="Банк"
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={({ textFieldProps, ...props }) => {
                                                            const { form: { errors, setTouched } } = props;
                                                            const { label, helperText, error, ...field } = fieldToTextField(props);
                                                            const { name } = field;

                                                            return (
                                                                <MuiAutocomplete
                                                                    {...props}
                                                                    {...field}
                                                                    options={banks}
                                                                    getOptionLabel={option => {
                                                                        return option ? ((option instanceof Object) ? option.name : option) : ''
                                                                    }}
                                                                    noOptionsText='Нет доступных вариантов'
                                                                    onBlur={() => setTouched({ [name]: true })}
                                                                    onChange={(e, option) => {
                                                                        setFieldValue('payment', {
                                                                            ...values.payment,
                                                                            ...{
                                                                                bank: option.name,
                                                                            },
                                                                            ...((option instanceof Object)
                                                                                    ? {
                                                                                        bik: option.bik,
                                                                                        correspondent_account: option.correspondent_account
                                                                                    }
                                                                                    : {
                                                                                        bik: '',
                                                                                        correspondent_account: ''
                                                                                    }
                                                                            )
                                                                        })
                                                                    }}
                                                                    renderInput={ params => (
                                                                        <MuiTextField required label={label} {...params} {...textFieldProps} helperText={error ? errors[name] : ''} error={!!error} />
                                                                    )}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="payment.bik"
                                                        type="text"
                                                        label="БИК"
                                                        required
                                                        disabled
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                <Grid item xs={6} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="payment.correspondent_account"
                                                        type="text"
                                                        label="К/С"
                                                        required
                                                        disabled
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="payment.checking_account"
                                                        type="text"
                                                        label="Р/С"
                                                        required
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="legal_address"
                                        type="text"
                                        label="Юридический адрес"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="current_address"
                                        type="text"
                                        label="Фактический адрес"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.groupTitle}>Данные директора</Typography>
                                    <Grid container className={classes.group} direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.last_name"
                                                        type="text"
                                                        label="Фамилия"
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.first_name"
                                                        type="text"
                                                        label="Имя"
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.middle_name"
                                                        type="text"
                                                        label="Отчество"
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    break
                case 'kz':
                    attributes.initialValues = {
                        ...attributes.initialValues,
                        ...{
                            type: company.type,
                            legal_address: company.legal_detail.legal_address ?? '',
                            bin: company.legal_detail.bin ?? '',
                            kbe: company.legal_detail.kbe ?? '',
                            iik: company.legal_detail.iik ?? '',
                            iban: company.legal_detail.iban ?? '',
                            payment: {
                                bank: company.payment_detail ? (company.payment_detail.bank ?? '') : '',
                                bik: company.payment_detail ? (company.payment_detail.bik ?? '') : ''
                            }
                        }
                    }

                    attributes.validationSchema = {
                        ...attributes.validationSchema,
                        ...{
                            type: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            legal_address: company.type ? Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!") : Yup.string(),
                            bin: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(12, "Длина БИН должна быть 12 символов!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            kbe: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(2, "Длина КБЕ должна быть 2 символа!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            iik: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(20, "Длина ИИН должна быть 20 символов!"),
                            iban: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(20, "Длина ИБАН должна быть 20 символов!"),
                            payment: Yup.object().shape({
                                bank: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                bik: Yup.string()
                            })
                        }
                    }

                    attributes.attributes = (account) => (
                        <Grid item className={classes.inputContent}>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={8} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="name"
                                        type="text"
                                        label="Полное наименование"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={4} className={classes.column}>
                                    <FormControl fullWidth>
                                        <InputLabel>
                                            Тип
                                        </InputLabel>
                                        <Field
                                            fullWidth
                                            type="text"
                                            name="type"
                                            label="Тип"
                                            required
                                            disabled={!AuthorizationService.roles(account, 'admin')}
                                            component={Select}
                                        >
                                            {[
                                                {key: 'self', name: 'Самозанятый'},
                                                {key: 'individual', name: 'Индивидуальный предприниматель'},
                                                {key: 'legal', name: 'Юридическое лицо'},
                                            ].map((type, index) => (
                                                <MenuItem key={index} value={type.key}>
                                                    {type.name}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="country"
                                        type="text"
                                        label="Страна"
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        name="locality"
                                        type="text"
                                        options={cities}
                                        getOptionLabel={option => {
                                            return option.name + ((option.name !== option.region.name) ? ", " + option.region.name : "")
                                        }}
                                        label="Населенный пункт"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={Autocomplete}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="bin"
                                        type="text"
                                        label="БИН"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="kbe"
                                        type="text"
                                        label="КБЕ"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="iban"
                                        type="text"
                                        label="IBAN/счёт"
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="payment.bank"
                                        type="text"
                                        label="Банк"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="payment.bik"
                                        type="text"
                                        label="БИК"
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="legal_address"
                                        type="text"
                                        label="Юридический адрес"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="current_address"
                                        type="text"
                                        label="Фактический адрес"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.groupTitle}>Данные директора</Typography>
                                    <Grid container className={classes.group} direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.last_name"
                                                        type="text"
                                                        label="Фамилия"
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.first_name"
                                                        type="text"
                                                        label="Имя"
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.middle_name"
                                                        type="text"
                                                        label="Отчество"
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    break
                default:
            }
            break;
        case 'self':
            switch (country) {
                case 'ru':
                    attributes.initialValues = {
                        ...attributes.initialValues,
                        ...{
                            type: company.type,
                            inn: company.legal_detail.inn,
                            ogrn: company.legal_detail.ogrn ?? '',
                            payment: {
                                bank: company.payment_detail ? (company.payment_detail.bank ?? '') : '',
                                bik: company.payment_detail ? (company.payment_detail.bik ?? '') : '',
                                correspondent_account: company.payment_detail ? (company.payment_detail.correspondent_account ?? '') : '',
                                checking_account: company.payment_detail ? (company.payment_detail.checking_account ?? '') : ''
                            },
                            ...(company.type ? {okpd: company.legal_detail ? (company.legal_detail.okpd ?? {id: '', name: '', code: ''}) : {id: '', name: '', code: ''}} : {})
                        }
                    }

                    attributes.validationSchema = {
                        ...attributes.validationSchema,
                        ...{
                            type: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            inn: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(12, "Длина ИНН должна быть 12 символов!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            ogrn: Yup.string(),
                            payment: Yup.object().shape({
                                bank: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                bik: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                correspondent_account: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                checking_account: Yup.string().length(20, "Длина Р/С должна быть 20 символов!").required("Поле не может быть пустым или состоять из одних пробелов!")
                            }),
                            ...(company.type ? {okpd: Yup.array()} : {})
                        }
                    }

                    attributes.attributes = (account, values, setFieldValue, setValues, setFieldTouched, touched, search, setSearch) => (
                        <Grid item className={classes.inputContent}>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={8} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="name"
                                        type="text"
                                        label="Полное наименование"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={4} className={classes.column}>
                                    <FormControl fullWidth>
                                        <InputLabel>
                                            Тип
                                        </InputLabel>
                                        <Field
                                            fullWidth
                                            type="text"
                                            name="type"
                                            label="Тип"
                                            required
                                            disabled={!AuthorizationService.roles(account, 'admin')}
                                            component={Select}
                                        >
                                            {[
                                                {key: 'self', name: 'Самозанятый'},
                                                {key: 'individual', name: 'Индивидуальный предприниматель'},
                                                {key: 'legal', name: 'Юридическое лицо'},
                                            ].map((type, index) => (
                                                <MenuItem key={index} value={type.key}>
                                                    {type.name}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="country"
                                        type="text"
                                        label="Страна"
                                        disabled
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        name="locality"
                                        type="text"
                                        options={cities}
                                        getOptionLabel={option => {
                                            return option.name + ((option.name !== option.region.name) ? ", " + option.region.name : "")
                                        }}
                                        label="Населенный пункт"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={Autocomplete}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                <Grid item xs={company.type ? 4 : 6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="inn"
                                        type="text"
                                        label="ИНН"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            {company.type ? (
                                <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12} className={classes.okpd}>
                                        <MuiAutocomplete
                                            multiple
                                            filterOptions={options => options}
                                            options={okved.data}
                                            name={'okpd'}
                                            disabled={!AuthorizationService.roles(account, 'admin')}
                                            defaultValue={values.okpd ? values.okpd : null}
                                            getOptionLabel={(option) => option.code + ` ` + option.name}
                                            noOptionsText='Нет доступных вариантов'
                                            renderInput={params => {
                                                return <Field
                                                    fullWidth
                                                    component={MuiTextField}
                                                    {...params}
                                                    onChange={(e) => {
                                                        setSearch(e.target.value)
                                                    }}
                                                    name={'okpd'}
                                                    label="Код ОКПД"
                                                />
                                            }}
                                            onChange={(e, option) => {
                                                setFieldValue('okpd', option)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            ) : null}
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.groupTitle}>Платежные реквизиты</Typography>
                                    <Grid container className={classes.group} direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                <Grid item xs={6} className={classes.column}>
                                                    <Field
                                                        name="payment.bank"
                                                        type="text"
                                                        label="Банк"
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={({ textFieldProps, ...props }) => {
                                                            const { form: { errors, setTouched } } = props;
                                                            const { label, helperText, error, ...field } = fieldToTextField(props);
                                                            const { name } = field;

                                                            return (
                                                                <MuiAutocomplete
                                                                    {...props}
                                                                    {...field}
                                                                    options={banks}
                                                                    getOptionLabel={option => {
                                                                        return option ? ((option instanceof Object) ? option.name : option) : ''
                                                                    }}
                                                                    noOptionsText='Нет доступных вариантов'
                                                                    onBlur={() => setTouched({ [name]: true })}
                                                                    onChange={(e, option) => {
                                                                        setFieldValue('payment', {
                                                                            ...values.payment,
                                                                            ...{
                                                                                bank: option.name,
                                                                            },
                                                                            ...((option instanceof Object)
                                                                                    ? {
                                                                                        bik: option.bik,
                                                                                        correspondent_account: option.correspondent_account
                                                                                    }
                                                                                    : {
                                                                                        bik: '',
                                                                                        correspondent_account: ''
                                                                                    }
                                                                            )
                                                                        })
                                                                    }}
                                                                    renderInput={ params => (
                                                                        <MuiTextField required label={label} {...params} {...textFieldProps} helperText={error ? errors[name] : ''} error={!!error} />
                                                                    )}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="payment.bik"
                                                        type="text"
                                                        label="БИК"
                                                        required
                                                        disabled
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                <Grid item xs={6} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="payment.correspondent_account"
                                                        type="text"
                                                        label="К/С"
                                                        required
                                                        disabled
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="payment.checking_account"
                                                        type="text"
                                                        label="Р/С"
                                                        required
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="current_address"
                                        type="text"
                                        label="Фактический адрес"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.groupTitle}>Данные директора</Typography>
                                    <Grid container className={classes.group} direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.last_name"
                                                        type="text"
                                                        label="Фамилия"
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.first_name"
                                                        type="text"
                                                        label="Имя"
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.middle_name"
                                                        type="text"
                                                        label="Отчество"
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    break
                case 'kz':
                    attributes.initialValues = {
                        ...attributes.initialValues,
                        ...{
                            type: company.type,
                            bin: company.legal_detail.bin ?? '',
                            kbe: company.legal_detail.kbe ?? '',
                            iik: company.legal_detail.iik ?? '',
                            iban: company.legal_detail.iban ?? '',
                            payment: {
                                bank: company.payment_detail ? (company.payment_detail.bank ?? '') : '',
                                bik: company.payment_detail ? (company.payment_detail.bik ?? '') : ''
                            }
                        }
                    }

                    attributes.validationSchema = {
                        ...attributes.validationSchema,
                        ...{
                            type: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            bin: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(12, "Длина БИН должна быть 12 символов!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            kbe: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(2, "Длина КБЕ должна быть 2 символа!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            iik: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(20, "Длина ИИН должна быть 20 символов!"),
                            iban: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(20, "Длина ИБАН должна быть 20 символов!"),
                            payment: Yup.object().shape({
                                bank: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                bik: Yup.string()
                            })
                        }
                    }

                    attributes.attributes = (account) => (
                        <Grid item className={classes.inputContent}>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={8} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="name"
                                        type="text"
                                        label="Полное наименование"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={4} className={classes.column}>
                                    <FormControl fullWidth>
                                        <InputLabel>
                                            Тип
                                        </InputLabel>
                                        <Field
                                            fullWidth
                                            type="text"
                                            name="type"
                                            label="Тип"
                                            required
                                            disabled={!AuthorizationService.roles(account, 'admin')}
                                            component={Select}
                                        >
                                            {[
                                                {key: 'self', name: 'Самозанятый'},
                                                {key: 'individual', name: 'Индивидуальный предприниматель'},
                                                {key: 'legal', name: 'Юридическое лицо'},
                                            ].map((type, index) => (
                                                <MenuItem key={index} value={type.key}>
                                                    {type.name}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="country"
                                        type="text"
                                        label="Страна"
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        name="locality"
                                        type="text"
                                        options={cities}
                                        getOptionLabel={option => {
                                            return option.name + ((option.name !== option.region.name) ? ", " + option.region.name : "")
                                        }}
                                        label="Населенный пункт"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={Autocomplete}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="bin"
                                        type="text"
                                        label="БИН"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="kbe"
                                        type="text"
                                        label="КБЕ"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="iban"
                                        type="text"
                                        label="IBAN/счёт"
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="payment.bank"
                                        type="text"
                                        label="Банк"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="payment.bik"
                                        type="text"
                                        label="БИК"
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="current_address"
                                        type="text"
                                        label="Фактический адрес"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.groupTitle}>Данные директора</Typography>
                                    <Grid container className={classes.group} direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.last_name"
                                                        type="text"
                                                        label="Фамилия"
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.first_name"
                                                        type="text"
                                                        label="Имя"
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.middle_name"
                                                        type="text"
                                                        label="Отчество"
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    break
                default:
            }
            break;
        default:
            switch (country) {
                case 'ru':
                    attributes.initialValues = {
                        ...attributes.initialValues,
                        ...{
                            type: company.type,
                            legal_address: company.legal_detail.legal_address ?? '',
                            inn: company.legal_detail.inn ?? '',
                            kpp: company.legal_detail.kpp ?? '',
                            ogrn: company.legal_detail.ogrn ?? '',
                            payment: {
                                bank: company.payment_detail ? (company.payment_detail.bank ?? '') : '',
                                bik: company.payment_detail ? (company.payment_detail.bik ?? '') : '',
                                correspondent_account: company.payment_detail ? (company.payment_detail.correspondent_account ?? '') : '',
                                checking_account: company.payment_detail ? (company.payment_detail.checking_account ?? '') : ''
                            },
                            ...(company.type ? {okpo: company.legal_detail ? (company.legal_detail.okpo ?? '') : '',okpd: company.legal_detail ? (company.legal_detail.okpd ?? {id: '', name: '', code: ''}) : {id: '', name: '', code: ''}} : {region: company.region})
                        }
                    }

                    attributes.validationSchema = {
                        ...attributes.validationSchema,
                        ...{
                            type: Yup.mixed().nullable(),
                            region: Yup.object().nullable(),
                            legal_address: company.type ? Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!") : Yup.string(),
                            inn: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .min(10, "Длина ИНН должна быть от 10 символов!")
                                .max(12, "Длина ИНН должна быть до 12 символов!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            kpp: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(9, "Длина КПП должна быть 9 символов!"),
                            ogrn: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(13, "Длина ОГРН должна быть 13 символов!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            payment: Yup.object().shape({
                                bank: company.type ? Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!") : Yup.mixed(),
                                bik: company.type ? Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!") : Yup.string(),
                                correspondent_account: company.type ? Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!") : Yup.string(),
                                checking_account: company.type ? Yup.string().length(20, "Длина Р/С должна быть 20 символов!").required("Поле не может быть пустым или состоять из одних пробелов!") : Yup.string()
                            }),
                            ...(company.type ? {okpd: Yup.array(), okpo: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').length((((company.type === 'individual') || (company.type === 'self')) ? 10 : 8)  , "Длина ОКПО должна быть " + (((company.type === 'individual') || (company.type === 'self')) ? 10 : 8) +" символов!").required("Поле не может быть пустым или состоять из одних пробелов!")} : {})
                        }
                    }

                    attributes.attributes = (account, values, setFieldValue, setValues, setFieldTouched, touched, search, setSearch) => (
                        <Grid item className={classes.inputContent}>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={company.type ? 8 : 12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="name"
                                        type="text"
                                        label="Полное наименование"
                                        required={!!company.type}
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                {company.type ? (
                                    <Grid item xs={4} className={classes.column}>
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                Тип
                                            </InputLabel>
                                            <Field
                                                fullWidth
                                                type="text"
                                                name="type"
                                                label="Тип"
                                                required
                                                disabled={!AuthorizationService.roles(account, 'admin')}
                                                component={Select}
                                            >
                                                {[
                                                    {key: 'self', name: 'Самозанятый'},
                                                    {key: 'individual', name: 'Индивидуальный предприниматель'},
                                                    {key: 'legal', name: 'Юридическое лицо'},
                                                ].map((type, index) => (
                                                    <MenuItem key={index} value={type.key}>
                                                        {type.name}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </FormControl>
                                    </Grid>
                                ) : null}
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                <Grid item xs={4} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="country"
                                        type="text"
                                        label="Страна"
                                        disabled
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={4} className={classes.column}>
                                    <Field
                                        name="locality"
                                        type="text"
                                        options={cities}
                                        getOptionLabel={option => {
                                            return option.name + ((option.name !== option.region.name) ? ", " + option.region.name : "")
                                        }}
                                        label="Населенный пункт"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={Autocomplete}
                                    />
                                </Grid>
                                {!company.type ? (
                                    <Grid item xs={4} className={classes.column}>
                                        <Field
                                            name="region"
                                            type="text"
                                            options={regions}
                                            getOptionLabel={option => option.name}
                                            label="Макрорегион"
                                            required
                                            disabled={!AuthorizationService.roles(account, 'admin')}
                                            component={Autocomplete}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={company.type ? 3 : 4} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="inn"
                                        type="text"
                                        label="ИНН"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={company.type ? 3 : 4} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="kpp"
                                        type="text"
                                        label="КПП"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={company.type ? 3 : 4} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="ogrn"
                                        type="text"
                                        label="ОГРН"
                                        required={!!company.type}
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                {company.type ? (
                                    <Grid item xs={3} className={classes.column}>
                                        <Field
                                            fullWidth
                                            name="okpo"
                                            type="number"
                                            label="Код ОКПО"
                                            required
                                            disabled={!AuthorizationService.roles(account, 'admin')}
                                            component={CustomTextField}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>
                            {company.type &&
                                <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12} className={classes.okpd}>
                                        <MuiAutocomplete
                                            multiple
                                            filterOptions={options => options}
                                            options={okved.data}
                                            name={'okpd'}
                                            defaultValue={values.okpd ? values.okpd : null}
                                            getOptionLabel={(option) => option.code + ` ` + option.name}
                                            noOptionsText='Нет доступных вариантов'
                                            disabled={!AuthorizationService.roles(account, 'admin')}
                                            renderInput={params => {
                                                return <Field
                                                    fullWidth
                                                    component={MuiTextField}
                                                    {...params}
                                                    onChange={(e) => {
                                                        setSearch(e.target.value)
                                                    }}
                                                    name={'okpd'}
                                                    label="Код ОКПД"
                                                />
                                            }}
                                            onChange={(e, option) => {
                                                setFieldValue('okpd', option)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                name="payment.bank"
                                                type="text"
                                                label="Банк"
                                                disabled={!AuthorizationService.roles(account, 'admin')}
                                                component={({ textFieldProps, ...props }) => {
                                                    const { form: { errors, setTouched } } = props;
                                                    const { label, helperText, error, ...field } = fieldToTextField(props);
                                                    const { name } = field;

                                                    return (
                                                        <MuiAutocomplete
                                                            {...props}
                                                            {...field}
                                                            options={banks}
                                                            getOptionLabel={option => {
                                                                return option ? ((option instanceof Object) ? option.name : option) : ''
                                                            }}
                                                            noOptionsText='Нет доступных вариантов'
                                                            onBlur={() => setTouched({ [name]: true })}
                                                            onChange={(e, option) => {
                                                                setFieldValue('payment', {
                                                                    ...values.payment,
                                                                    ...{
                                                                        bank: option.name,
                                                                    },
                                                                    ...((option instanceof Object)
                                                                            ? {
                                                                                bik: option.bik,
                                                                                correspondent_account: option.correspondent_account
                                                                            }
                                                                            : {
                                                                                bik: '',
                                                                                correspondent_account: ''
                                                                            }
                                                                    )
                                                                })
                                                            }}
                                                            renderInput={ params => (
                                                                <MuiTextField required={!!company.type} label={label} {...params} {...textFieldProps} helperText={error ? errors[name] : ''} error={!!error} />
                                                            )}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                fullWidth
                                                name="payment.bik"
                                                type="text"
                                                label="БИК"
                                                required={!!company.type}
                                                disabled
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                fullWidth
                                                name="payment.correspondent_account"
                                                type="text"
                                                label="К/С"
                                                required={!!company.type}
                                                disabled
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                fullWidth
                                                name="payment.checking_account"
                                                type="text"
                                                label="Р/С"
                                                required={!!company.type}
                                                disabled={!AuthorizationService.roles(account, 'admin')}
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="legal_address"
                                        type="text"
                                        label="Юридический адрес"
                                        required={!!company.type}
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="current_address"
                                        type="text"
                                        label="Фактический адрес"
                                        required={!!company.type}
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.groupTitle}>Данные директора</Typography>
                                    <Grid container className={classes.group} direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.last_name"
                                                        type="text"
                                                        label="Фамилия"
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.first_name"
                                                        type="text"
                                                        label="Имя"
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.middle_name"
                                                        type="text"
                                                        label="Отчество"
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    break
                case 'kz':
                    attributes.initialValues = {
                        ...attributes.initialValues,
                        ...{
                            type: company.type,
                            legal_address: company.legal_detail.legal_address ?? '',
                            bin: company.legal_detail.bin ?? '',
                            kbe: company.legal_detail.kbe ?? '',
                            iik: company.legal_detail.iik ?? '',
                            iban: company.legal_detail.iban ?? '',
                            payment: {
                                bank: company.payment_detail ? (company.payment_detail.bank ?? '') : '',
                                bik: company.payment_detail ? (company.payment_detail.bik ?? '') : ''
                            }
                        }
                    }

                    attributes.validationSchema = {
                        ...attributes.validationSchema,
                        ...{
                            type: Yup.mixed().nullable(),
                            legal_address: company.type ? Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!") : Yup.string(),
                            bin: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(12, "Длина БИН должна быть 12 символов!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            kbe: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(2, "Длина КБЕ должна быть 2 символа!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            iik: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(20, "Длина ИИН должна быть 20 символов!"),
                            iban: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(20, "Длина ИБАН должна быть 20 символов!"),
                            payment: Yup.object().shape({
                                bank: company.type ? Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!") : Yup.mixed(),
                                bik: Yup.string()
                            })
                        }
                    }

                    attributes.attributes = (account) => (
                        <Grid item className={classes.inputContent}>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start">
                                <Grid item xs={company.type ? 8 : 12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="name"
                                        type="text"
                                        label="Полное наименование"
                                        required={!!company.type}
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                {company.type ? (
                                    <Grid item xs={4} className={classes.column}>
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                Тип
                                            </InputLabel>
                                            <Field
                                                fullWidth
                                                type="text"
                                                name="type"
                                                label="Тип"
                                                required
                                                disabled={!AuthorizationService.roles(account, 'admin')}
                                                component={Select}
                                            >
                                                {[
                                                    {key: 'self', name: 'Самозанятый'},
                                                    {key: 'individual', name: 'Индивидуальный предприниматель'},
                                                    {key: 'legal', name: 'Юридическое лицо'},
                                                ].map((type, index) => (
                                                    <MenuItem key={index} value={type.key}>
                                                        {type.name}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </FormControl>
                                    </Grid>
                                ) : null}
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="country"
                                        type="text"
                                        label="Страна"
                                        disabled
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        name="locality"
                                        type="text"
                                        options={cities}
                                        getOptionLabel={option => {
                                            return option.name + ((option.name !== option.region.name) ? ", " + option.region.name : "")
                                        }}
                                        label="Населенный пункт"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={Autocomplete}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="bin"
                                        type="text"
                                        label="БИН"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="kbe"
                                        type="text"
                                        label="КБЕ"
                                        required
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="iban"
                                        type="text"
                                        label="IBAN/счёт"
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="payment.bank"
                                        type="text"
                                        label="Банк"
                                        required={!!company.type}
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="payment.bik"
                                        type="text"
                                        label="БИК"
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="legal_address"
                                        type="text"
                                        label="Юридический адрес"
                                        required={!!company.type}
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="current_address"
                                        type="text"
                                        label="Фактический адрес"
                                        required={!!company.type}
                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.groupTitle}>Данные директора</Typography>
                                    <Grid container className={classes.group} direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.last_name"
                                                        type="text"
                                                        label="Фамилия"
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.first_name"
                                                        type="text"
                                                        label="Имя"
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.middle_name"
                                                        type="text"
                                                        label="Отчество"
                                                        disabled={!AuthorizationService.roles(account, 'admin')}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    break
                default:
            }
            break;
    }

    return attributes
}

export const LegalDetail = (props) => {
    const dispatch = useDispatch()
    const { company, setLoading, cities, regions, banks, isSupplier, okved } = props
    const classes = useStyles()
    const [search, setSearch] = useState(null)
    const debounced = useDebouncedCallback(
        (value) => {
            setSearch(value)
        },
        900
    );
    const { account } = useSelector(state => state.account)
    const [country] = useState(company.legal_detail.city.region.country.code)
    const { attributes, initialValues, validationSchema } = useAttributes(account, country, company, cities, regions, banks,okved, classes)

    useEffect(() => {
        if (account && search) {
            const getOkved = async () => {
                let params = {
                    limit: 10,
                    page: 1,
                    ...(search ? {search: search} : {})
                }
                return await dispatch(CompanyActions.okved(params))
            }
            getOkved().then(_ => {})
        }
        // eslint-disable-next-line
    }, [search])

    return (
        <Grid item>
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape(validationSchema)}
                onSubmit={(values, {setSubmitting}) => {
                    dispatch(CompanyActions.editLegalDetail(company.id, {...values, ...{code: company.legal_detail.city.region.country.code}})).then(
                        () => {
                            setSubmitting(false)
                            setLoading(false)
                        },
                        errors => {
                            if (errors) {
                            }
                            setSubmitting(false);
                        }
                    )
                }}
            >
                {({
                      dirty,
                      values,
                      touched,
                      isSubmitting,
                      isValid,
                      setValues,
                      setFieldValue,
                      setFieldTouched,
                  }) => (
                    <Form>
                        <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                            { attributes(account, values, setFieldValue, setValues, setFieldTouched, touched, search, debounced.callback) }
                        </Grid>
                        <Grid item className={classes.footer}>
                            <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                <Grid item className={classes.footerContent}>
                                    <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                        {(AuthorizationService.roles(account, 'admin') && isSupplier) ? (
                                            <Grid item>
                                                <Grid container spacing={2}>
                                                    {(company.employees.length && !company.employees[0].roles.filter(role => role.name === 'supplier_blocked').length) ? (
                                                        <Grid item>
                                                            <ButtonIcon
                                                                className={classes.button}
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    return dispatch(CompanyActions.block(company.id)).then(() => {
                                                                        dispatch(CompActions.supplier(company.id, {
                                                                            include: "legalDetail.city.region.country,legalDetail.executiveDirector,legalDetail.passportDetails,contactInformation,users.roles,paymentDetail,termsParticipation.regions.region,creator.company.legalDetail,occupations,status"
                                                                        }))
                                                                    })
                                                                }}
                                                                children={['Блокировать']}>
                                                            </ButtonIcon>
                                                        </Grid>
                                                    ) : null}
                                                    {(company.employees.length && company.employees[0].roles.filter(role => role.name === 'supplier_blocked').length) ? (
                                                        <Grid item>
                                                            <ButtonIcon
                                                                className={classes.button}
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    return dispatch(CompanyActions.unBlock(company.id)).then(() => {
                                                                        dispatch(CompActions.supplier(company.id, {
                                                                            include: "legalDetail.city.region.country,legalDetail.executiveDirector,legalDetail.passportDetails,contactInformation,users.roles,paymentDetail,termsParticipation.regions.region,creator.company.legalDetail,occupations,status"
                                                                        }))
                                                                    })
                                                                }}
                                                                children={['Разблокировать']}>
                                                            </ButtonIcon>
                                                        </Grid>
                                                    ) : null}
                                                    {(company.employees.length && company.employees[0].roles.filter(role => role.name === 'guest_supplier').length) ? (
                                                        <Grid item>
                                                            <ButtonIcon
                                                                className={classes.button}
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    return dispatch(CompanyActions.approve(company.id)).then(() => {
                                                                        dispatch(CompActions.supplier(company.id, {
                                                                            include: "legalDetail.city.region.country,legalDetail.executiveDirector,legalDetail.passportDetails,contactInformation,users.roles,paymentDetail,termsParticipation.regions.region,creator.company.legalDetail,occupations,status"
                                                                        }))
                                                                    })
                                                                }}
                                                                children={['Утвердить']}>
                                                            </ButtonIcon>
                                                        </Grid>
                                                    ) : null}
                                                </Grid>
                                            </Grid>
                                        ) : null}
                                        {AuthorizationService.roles(account, 'admin') ? (
                                            <Grid item>
                                                <Submit
                                                    disableElevation
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={isSubmitting || !isValid || !dirty}
                                                >
                                                    Сохранить
                                                </Submit>
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}
