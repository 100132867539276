import React from "react"
import {Field} from "formik"
import {Switch} from "formik-material-ui"

import {FormControlLabel} from "@material-ui/core"

export const FieldBoolean = (props) => {
    const { id, label, isSubmitting, disabled } = props

    return (
        <FormControlLabel
            control={
                <Field
                    name={ `attributes.${id}` }
                    label={ label }
                    disabled={ isSubmitting || disabled }
                    component={ Switch }
                />
            }
            label={ label }
        />
    )
}
