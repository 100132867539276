export default function catalog(state = {status: []}, action) {
    switch (action.type) {
        case "DELIVERY_ORDER_STATUS_REQUEST":
            return state
        case "DELIVERY_ORDER_STATUS":
            return {
                ...state,
                ...{status: action.payload}
            }
        case "DELIVERY_ORDER_STATUS_FAILURE":
            return state
        default:
            return state
    }
}
