import React from "react"
import {useSelector} from "react-redux"

import {
    Grid,
    makeStyles
} from "@material-ui/core"

import {Submit} from "../../../../App/components/Button/Submit"
import {purchase as rules} from "../../../helpers/permissions/rules"
import {history} from "../../../../App/helpers/history";

const useStyles = makeStyles(() => ({
    button: {
        "height": "45px",
    }
}))

export const Publish = (props) => {
    const classes = useStyles()

    const {items} = props

    const {account} = useSelector(state => state.account)

    return <Grid item>
        <Submit
            className={classes.button}
            disableElevation
            disabled={items.length !== 1 || !!items.filter(el => {
                const params = rules.publish[account.group]
                const status = el.status

                if (params.hasOwnProperty(status?.index) && !el.lock && !el?.publishStatus && el?.conduction_method?.index !== 7) {
                    if (params[status.index].hasOwnProperty('conditions')) {
                        if (params[status.index].conditions.hasOwnProperty('purchase')) {
                            return !params[status.index].conditions.purchase.includes(el.status.index)
                        }
                    }
                }
                return true
            }).length}
            variant="contained"
            color="primary"
            type="button"
            style={{height: '35px'}}
            onClick={() => {
                if (items.length === 1) {
                    history.push(`/planning/purchase/${items[0].id}/publish`);
                }
            }}
        >
            Опубликовать закупку
        </Submit>
    </Grid>
}
