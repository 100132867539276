import {HttpService} from "../../App/services/http"

export const CompanyService = {
    customers,
    customer,
    suppliers,
    supplier,
    regions,
    railroads,
    filter
}

function customers(params) {
    return HttpService.get("/company/customers", params)
}

function regions() {
    return HttpService.get("/company/regions")
        .then(response => {
            return response.regions
        })
}

function railroads() {
    return HttpService.get("/company/railroads")
}

function customer(id, params) {
    return HttpService.get(`/company/customer/${id}`, params)
        .then(response => {
            return response.customer
        })
}

function suppliers(params) {
    return HttpService.get("/company/suppliers", params)
}

function supplier(id, params) {
    return HttpService.get(`/company/supplier/${id}`, params)
        .then(response => {
            return response.supplier
        })
}

function filter(params) {
    return HttpService.get("/company/filter", params)
}
