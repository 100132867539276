import {HttpService} from "../../App/services/http"

export const CustomerService = {
    territories,
    territory,
    add,
    edit,
    remove,
    marketplaceSettings,
    editMarketplaceSettings
}

function territories(params) {
    return HttpService.get(`/company/customer/territories`, params).then(response => {
        return response
    })
}

function territory(customer_id, territory_id, params) {
    return HttpService.get(`/company/customer/${customer_id}/territory/${territory_id}`, params).then(response => {
        return response.territory
    })
}

function add(id, values) {
    return HttpService.post(`/company/customer/${id}/territory`, values)
}

function edit(company_id, territory_id, values) {
    return HttpService.put(`/company/customer/${company_id}/territory/${territory_id}`, values)
}

function remove(company_id, territory_id) {
    return HttpService.deleteMethod(`/company/customer/${company_id}/territory/${territory_id}`)
}

function marketplaceSettings(companyId) {
    return HttpService.get(`/company/customer/${companyId}/marketplace/settings`)
}

function editMarketplaceSettings(companyId, values) {
    return HttpService.put(`/company/customer/${companyId}/marketplace/settings`, values)
}
