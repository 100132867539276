import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"

import {
    Grid,
    makeStyles,
    Tab,
    Tabs,
    Typography
} from "@material-ui/core"
import {
    ArrowBack as ArrowBackIcon,
} from "@material-ui/icons"
import {green} from "@material-ui/core/colors"

import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon"
import {PurchasePlanActions} from "../../../actions/purchasePlan"
import {history} from "../../../../App/helpers/history"
import {TabPanel} from "../../../../Account/components/Tabs/TabPanel"
import {SystemActions} from "../../../../App/actions/system"
import {Chat} from "../../Comment/Chat";
import {PlanningActions} from "../../../actions/planning"
import {SettingsActions as PlanningSettings} from "../../../actions/settings"
import * as AppStorage from "../../../../App/helpers/storage"
import {Log} from "../../Log";
import {Purchases} from "./Purchases";
import {Products} from "./Products";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.planningContent.width,
        padding: theme.planningContent.padding,
        margin: theme.planningContent.margin,
        position: 'relative',
    },
    container: {
        "background-image": "linear-gradient(to bottom,#f6f6f6,#eee 85%,#b1b1b1)",
        "width": "100%",
        "height": "calc(100vh - 256px)"
    },
    title: {
        "font-size": "19px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    filterLabel: {
        "background": "#fff",
        "margin-bottom": "0 !important",
        "margin-top": "5px !important",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        },
        "& input": {
            padding: "6px 15px 7px"
        }
    },
    tableContainer: {
        "min-height": "100%",
        "height": "calc(100vh - 400px)"
    },
    tableItemsContainer: {},
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    searchLabel: {},
    searchInput: {
        "width": "calc(100% - 83px)"
    },
    searchButton: {
        "margin-bottom": "10px"
    },
    fullWidth: {
        "width": "100%"
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 414px)",
        "min-height": "calc(100vh - 414px)",
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    inputContentProducts: {
        "width": "100%",
        "height": "calc(100vh - 424px)",
        "min-height": "calc(100vh - 424px)",
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    footer: {
        width: "100%",
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    },
    tabs: {
        "&.MuiTab-textColorPrimary.Mui-selected": {
            backgroundColor: "#fff",
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "4px",
        }
    },
    fontSubtitle: {
        fontWeight: 700
    },
    submit: {
        "height": "35px",
    },
    accordion: {
        "& .MuiAccordionSummary-content": {
            "max-width": "calc(100% - 32px)"
        }
    },
    acDetails: {
        padding: "16px 0"
    },
    active: {
        'height': '41px',
        'background-color': `${green[100]} !important`,
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        fontSize: '16px',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    disabled: {
        'height': '41px',
        'background-color': 'rgba(244, 244, 244, 1)',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    iconButton: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    },
    backIconButton: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        position: 'absolute',
        left: '-8px',
        top: '-15px',
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    },
    svg: {
        "fill": "#d9534f",
        "width": "auto",
        "height": "31px",
        "stroke": "#fff",
        "fill-rule": "evenodd",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-miterlimit": "2",
        "stroke-width": "0px",
    },
    tooltipError: {
        "font-size": "16px",
        "background": "#d9534f",
    },
    badge: {
        top: "-5px",
        left: "2px"
    },
    titleDialog: {
        "&>h2": {
            color: "#fff"
        },
    },
    circle: {
        width: "25px",
        height: "25px",
        backgroundColor: "#ea4a4a",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        "&>span": {
            lineHeight: "20px"
        }
    },
    lightTooltip: {
        "font-size": "0.8rem",
        "backgroundColor": "white",
        "border": "1px solid #ededed",
        "color": "rgba(0, 0, 0, 0.87)",
    },
    header: {
        height: '116px',
        maxHeight: '116px',
        overflow: "hidden"
    }
}))

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {purchasePlanID} = useParams()
    const {account} = useSelector(state => state.account)
    const [plan, setPlan] = useState(null)

    const {units} = useSelector(state => state.purchasePlans)
    const {categories} = useSelector(state => state.system)
    const [value, setValue] = useState(0);

    useEffect(() => {
        const getData = async () => {
            await dispatch(PurchasePlanActions.plan(purchasePlanID, true)).then(response => setPlan(response))
            await dispatch(PlanningActions.units())
        }

        getData().then(() => {})
        // eslint-disable-next-line
    }, [dispatch]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const getCategories = async () => {
            return await dispatch(SystemActions.categories())
        }

        if (!categories.length) {
            getCategories().then(() => {})
        }
    }, [dispatch, categories.length]);

    const [openComment, setOpenComment] = useState(false)
    const [chat, setChat] = useState({
        id: '',
        title: ''
    })

    const closeComment = () => {
        setOpenComment(false)
        setChat({
            id: '',
            title: ''
        })
    }

    useEffect(() => {
        const getSettings = async () => {
            return await dispatch(PlanningSettings.settings())
        }

        getSettings().then(() => {})
    }, [dispatch]);

    return (plan && !!units.length) ? (
        <Grid item className={classes.content}>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} style={{position: 'relative'}}>
                <Grid item xs={12}>
                    <ButtonIcon
                        onClick={() => {
                            history.push('/planning/purchase-plans');
                        }}
                        children={<ArrowBackIcon/>}
                        className={classes.backIconButton}
                    />
                </Grid>
                <Grid item xs={12} className={classes.header}>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                        <Grid item xs={8}>
                            <Typography className={classes.title} style={{whiteSpace: "pre-wrap"}}>{plan.name}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                <Grid item xs={12}>
                                    <Typography className={classes.title} style={{textAlign: 'end'}}>Статус: {plan.status.name}</Typography>
                                </Grid>
                                {plan.editRequestStatus &&
                                    <Grid item xs={12}>
                                        <Typography className={classes.title} style={{textAlign: 'end'}}>
                                            Статус корректировки: {plan.editRequestStatus.name}
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>

                        </Grid>
                        <Grid item xs={2}>
                            <span className={classes.fontSubtitle}>Период:</span> {plan.planning_year}
                        </Grid>
                        <Grid item xs={5}>
                            <span className={classes.fontSubtitle}>Категория:</span> {plan.category.name}
                        </Grid>
                        <Grid item xs={4}>
                            <span className={classes.fontSubtitle}>Нулевой:</span> {plan.zero ? 'да' : 'нет'}
                        </Grid>
                    </Grid>
                    {(openComment && chat.id) &&
                        <Chat anchor={'right'} open={openComment} chatID={chat.id} title={chat.title} onClose={closeComment} user={account} token={AppStorage.get('token')}/>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Tabs
                        value={value}
                        indicatorColor="primary"
                        className={classes.tabs}
                        textColor="primary"
                        onChange={handleChange}
                    >
                        <Tab value={0} label="Закупки"/>
                        <Tab value={1} label="Объекты закупок"/>
                        <Tab value={2} label="История"/>
                    </Tabs>
                </Grid>
                <TabPanel value={value} index={0}>
                    <Purchases plan={plan} setChat={setChat} setOpenComment={setOpenComment} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Products plan={plan} setChat={setChat} setOpenComment={setOpenComment} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Log
                        plan={plan}
                        hiddenColumns={['purchase', 'lot']}
                        name={'planning_purchase_plan_log'}
                    />
                </TabPanel>
            </Grid>
        </Grid>
    ) : null
}
