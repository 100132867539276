import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {Field, Form, Formik} from "formik"
import {Select} from "formik-material-ui"
import * as Yup from "yup"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem
} from "@material-ui/core"
import {Close as CloseIcon} from "@material-ui/icons"

import {TextFieldWithError as CustomTextField} from "../../../../../App/components/Inputs/TextFieldWithError";
import {Submit} from "../../../../../App/components/Button/Submit"
import {CustomerActions} from "../../../../actions/customer";
import {getFullName} from "../../../../../App/helpers/user";

const useStyles = makeStyles(() => ({
    item: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "cursor": "pointer"
    },
    fullWidth: {
        "width": "100%"
    },
    content: {
        "padding": "0 !important"
    },
    input: {
        "width": "100%",
    },
    column: {
        "height": "86px"
    },
    dialogContent: {
        "max-height": "calc(100vh - 210px)"
    },
    footer: {
        "background-color": "#e7eaee",
        "justify-content": "flex-start"
    },
    dialog: {
        "max-height": "70vh"
    },
    footerContent: {
        "padding": "10px 0px"
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    titleDialog: {
        "& h2": {
            "display": "flex",
            "justify-content": "space-between",
        },
    }
}))

export const Territory = (props) => {
    const { company, territory } = props
    const dispatch = useDispatch()
    const classes = useStyles()

    const [open, setOpen] = useState(props.open)

    useEffect(() => {
        setOpen(props.open)
    }, [dispatch, props.open]);

    const handleClose = () => {
        setOpen(false)
        props.setDialog(false)
    }

    return open ? (
        <Grid item>
            <Dialog
                open={!!open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle className={classes.titleDialog}>
                    {territory ? 'Редактировать территорию' : 'Добавить территорию'}
                    <CloseIcon className={classes.close} onClick={handleClose}/>
                </DialogTitle>
                <Formik
                    initialValues={{
                        name: territory ? territory.name : '',
                        address: territory ? territory.address : '',
                        users: territory ? territory.employees.map(user => user.id) : []
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        address: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        dispatch(territory ? CustomerActions.edit(company.id, territory.id, values) : CustomerActions.add(company.id, values)).then(
                            () => {
                                setSubmitting(false)
                                handleClose()
                            },
                            errors => {
                                if (errors) {
                                }
                                setSubmitting(false);
                            }
                        )
                    }}
                >
                    {({
                          dirty,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                      }) => (
                        <Form>
                            <DialogContent className={classes.dialogContent}>
                                <Grid container className={classes.fullWidth} direction="row" justify="center" alignItems="stretch">
                                    <Grid item className={classes.fullWidth}>
                                        <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
                                            <Grid item>
                                                <Grid item className={classes.list}>
                                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                        <Grid item xs={12} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name="name"
                                                                type="text"
                                                                label="Название"
                                                                required
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                        <Grid item xs={6} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name="address"
                                                                type="text"
                                                                label="Адрес"
                                                                required
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} className={classes.column}>
                                                            <FormControl className={classes.fullWidth}>
                                                                <InputLabel shrink={ true } htmlFor="roles">
                                                                    Сотрудники
                                                                </InputLabel>
                                                                <Field
                                                                    fullWidth
                                                                    type="text"
                                                                    name="users"
                                                                    label="Сотрудники"
                                                                    component={ Select }
                                                                    multiple
                                                                >
                                                                    {company.employees.map((user, index) => (
                                                                        <MenuItem key={index} value={user.id}>
                                                                            {getFullName(user)}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Field>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions className={classes.footer}>
                                <Grid container className={ classes.footerContent} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Submit
                                            variant="contained"
                                            component="label"
                                            color="primary"
                                            disabled={isSubmitting || !isValid || !dirty}
                                            onClick={handleSubmit}
                                            type="submit"
                                        >
                                            {territory ? 'Сохранить' : 'Добавить'}
                                        </Submit>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </Grid>
    ) : null
}
