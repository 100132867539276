import * as AppStorage from "../../../App/helpers/storage"

export default function catalogSupplier(state = {
    products: {
        data: [],
        meta: {
            total: 0,
            per_page: 15,
            current_page: 1
        },
        category: AppStorage.get('filterSupplierCatalogProducts') ? JSON.parse(AppStorage.get('filterSupplierCatalogProducts')) : null,
        filter: AppStorage.get('filterSupplierCatalogProducts') ? JSON.parse(AppStorage.get('filterSupplierCatalogProducts')) : {
            supplier: '',
            contract: '',
            amount: '',
            price: {
                from: '',
                to: ''
            }
        },
        searchFilter: AppStorage.get('searchFilterSupplierCatalogProducts') ? JSON.parse(AppStorage.get('searchFilterSupplierCatalogProducts')) : {
            search: '',
            sort: 'name_asc',
            typeView: 'list',
            typeViewIndex: 0,
            tabType: 0
        }
    },
}, action) {
    switch (action.type) {
        case "SUPPLIER_CATALOG_PRODUCTS_REQUEST":
            return state
        case "SUPPLIER_CATALOG_PRODUCTS":
            return {
                ...state,
                ...{
                    products: {
                        ...state.products,
                        ...{
                            data: action.payload.data,
                            meta: action.payload.meta
                        }
                    }
                },
            }
         case "UPDATE_PRODUCT_SUCCESS":
             return {
                 ...state,
                 ...{
                     products: {
                         ...state.products,
                         ...{
                             data: state.products.data.map(product => {
                                 return (product.id === action.payload.product.id) ? action.payload.product : product
                             })
                         }
                     }
                 }
             }
        case "SUPPLIER_CATALOG_PRODUCTS_FAILURE":
            return {
                ...state,
                ...{
                    products: {
                        ...state.products,
                        ...{
                            data: [],
                            meta: {
                                total: 0,
                                per_page: 15,
                                current_page: 1
                            }
                        }
                    }
                },
            }
        case "SUPPLIER_CATALOG_PRODUCTS_EDIT_AMOUNT_ITEM":
            return {
                ...state,
                ...{
                    products: {
                        ...state.products,
                        ...{
                            data: state.products.data.map(product => {
                                return {
                                    ...product,
                                    ...{
                                        items: product.items.map(item => {
                                            return (item.item.id === action.payload.id) ? {...item, ...{ordered: item.ordered + action.payload.amount}} : item
                                        })
                                    }
                                }
                            })
                        }
                    }
                }
            }
        case "SUPPLIER_CATALOG_PRODUCTS_CATEGORY":
            return {
                ...state,
                ...{
                    products: {
                        ...state.products,
                        ...{
                            category: action.payload
                        }
                    }
                }
            }
        case "SUPPLIER_CATALOG_PRODUCTS_FILTER":
            AppStorage.set('filterSupplierCatalogProducts', JSON.stringify(action.payload))
            return {
                ...state,
                ...{
                    products: {
                        ...state.products,
                        ...{
                            filter: action.payload
                        }
                    }
                }
            }
        case "SUPPLIER_CATALOG_PRODUCTS_CLEAR":
            return {
                ...state,
                ...{
                    products: {
                        data: [],
                        meta: {total: 0, per_page: 15, current_page: 1},
                        category: AppStorage.get('filterSupplierCatalogProductsCategory') ? JSON.parse(AppStorage.get('filterSupplierCatalogProductsCategory')) : null,
                        filter: AppStorage.get('filterSupplierCatalogProducts') ? JSON.parse(AppStorage.get('filterSupplierCatalogProducts')) : {
                            contract: '',
                            amount: '',
                            price: {
                                from: '',
                                to: ''
                            }
                        }
                    }
                }
            }
        case "SUPPLIER_CATALOG_PRODUCTS_SEARCH_FILTER":
            AppStorage.set('searchFilterSupplierCatalogProducts', JSON.stringify(action.payload))
            return {
                ...state,
                ...{
                    products: {
                        ...state.products,
                        ...{
                            searchFilter: action.payload
                        }
                    }
                }
            }
        default:
            return state
    }
}
