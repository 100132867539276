import React from "react"
import {makeStyles, Tooltip as MuiTooltip} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    light: {
        "font-size": "0.8rem",
        "backgroundColor": "white",
        "border": "1px solid #ededed",
        "color": "rgba(0, 0, 0, 0.87)",
    }
}));

export const Tooltip = ({children, ...props}) => {
    const classes = useStyles();

    const { placement, title, condition = true } = props

    return condition ? (
        <MuiTooltip
            placement={placement}
            classes={{tooltip: classes.light}}
            title={title}
        >
            {children}
        </MuiTooltip>
    ) : children
}
