import {DeliveryService} from "../services/delivery"

export const DeliveryActions = {
    orders
}

function orders(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "REPORT_DELIVERY_ORDERS_REQUEST"})

        DeliveryService.orders(params)
            .then(
                orders => {
                    dispatch({type: "REPORT_DELIVERY_ORDERS", payload: {data: orders.data, meta: orders.meta}})
                    dispatch({type: "LOADING", payload: false})
                    resolve(orders)
                },
                error => {
                    dispatch({type: "REPORT_DELIVERY_ORDERS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
