import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, FieldArray, Form, Formik, getIn} from "formik"
import {CheckboxWithLabel, TextField} from "formik-material-ui"
import DateFnsUtils from "@date-io/date-fns"
import format from "date-fns/format";
import ru from "date-fns/locale/ru"

import {
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    MenuItem,
    Paper,
    TextField as MuiTextField,
    Tooltip,
    Typography,
} from "@material-ui/core"
import {KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {Autocomplete} from "@material-ui/lab";
import {Add as AddIcon, Clear, Help as HelpIcon} from "@material-ui/icons"

import {Submit} from "../../../../../../../App/components/Button/Submit"
import {TextFieldWithError as CustomTextField} from "../../../../../../../App/components/Inputs/TextFieldWithError"
import {SystemActions} from "../../../../../../../App/actions/system"
import {FileUpload} from "../../../../../../../App/components/Inputs/FileUpload"
import {Group} from "../../../../../../../App/components/Paper/Group"
import {ButtonIcon} from "../../../../../../../App/components/Button/ButtonIcon"
import {CompanyActions} from "../../../../../../../Account/actions/company"
import {SettingsActions} from "../../../../../../../Settings/actions/settings"
import {Category} from "../../../../../../../App/components/Inputs/Category";
import {Standard} from "../../../../../../../App/components/Inputs/Standard";
import {getValidationSchema} from "../../../../validation/order";
import {PurchaseActions} from "../../../../../../../Planning/actions/purchase";
import {ShopActions} from "../../../../actions/shop";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 251px)"
    },
    tabContent: {
        "padding": "20px 29px !important"
    },
    column: {
        "height": "86px"
    },
    block: {
        "height": "172px"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    error: {
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "height": "35px",
        "font-size": "0.75rem",
        "color": "#f44336"
    },
    group: {
        "padding-bottom": "16px"
    },
    itemClose: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0 !important",
    },
    fileRows: {
        overflowY: "auto",
        overflowX: "hidden",
        marginTop: "3px",
        maxHeight: "225px",
        "&>div:nth-child(2n)>div>div>div": {
            flexWrap: "nowrap",
        },
        "& .MuiFormHelperText-root": {
            color: "#f27360",
        }
    },
    filesUpload: {
        "&>div>div>div": {
            flexWrap: "nowrap",
            justifyContent: 'flex-start !important',
            "&>div:first-child": {
                minWidth: "170px"
            }
        }
    },
    selectCategoryError: {
        "& .dx-texteditor.dx-editor-outlined": {
            "transform": "scaleX(1)",
            "border": "unset !important",
            "border-bottom": "2px solid #f27360 !important",
            "border-radius": "unset",
        },
        "& .dx-texteditor-input": {
            "padding": "0px !important",
            "min-height": "35px",
        },
        "margin-top": "-5px",
    },
    selectCategory: {
        "& .dx-texteditor.dx-editor-outlined": {
            "border": "unset !important",
            "border-bottom": "2px solid #c1c1c1 !important",
            "border-radius": "unset",
            "&:hover": {
                "border-bottom": "2px solid #898989 !important",
            },
        },
        "& .dx-texteditor-input": {
            "padding": "0px !important",
        },
        "&:after": {
            "transform": "scaleX(1)",
            "border-bottom-color": "9px solid #f27360 !important"
        }
    },
}))

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {company} = useSelector(state => state.company)
    const {units, categories, currencies} = useSelector(state => state.system)
    const {orderSettings} = useSelector(state => state.settings)
    const {conductionMethods} = useSelector(state => state.purchases)
    const [category, setCategory] = useState({})
    const [generics, setGenerics] = useState({})
    const [standards, setStandards] = useState([])
    const [showCatError, setShowCatError] = useState(false)
    const [loading, setLoading] = useState(false);
    const getData = async () => {
        await dispatch(SystemActions.categories())
        await dispatch(SystemActions.currencies())
        await dispatch(SystemActions.units())
        await dispatch(PurchaseActions.conductionMethods(true))
        await dispatch(SettingsActions.order())
        await dispatch(CompanyActions.company({
            include: "territories,legalDetail.city.region.country,legalDetail.executiveDirector,legalDetail.city.region.country,legalDetail.passportDetails,contactInformation,termsParticipation.regions.region,termsParticipation.files"
        }))
    }

    useEffect(() => {
        if (!loading) {
            getData().then(() => {
                setLoading(true);
            })
        }
    }, [dispatch]);

    const getGenericCategories = (id = null) => {
        id = id ?? categories.find(category => category.name === 'Лекарственные средства').id

        let result = [id]

        categories.filter(category => (category.category && (category.category.id === id))).forEach(category => {
            result = result.concat(getGenericCategories(category.id))
        })

        return result
    }

    const getCategoriesWithWarrantyPeriod = (id = null) => {
        let result = []

        if (id) {
            result.push(id)

            categories.filter(category => (category.category && (category.category.id === id))).forEach(category => {
                result = result.concat(getCategoriesWithWarrantyPeriod(category.id))
            })
        } else {
            categories.filter(category => ([47, 50].indexOf(category.id) !== -1)).forEach(category => {
                result = result.concat(getCategoriesWithWarrantyPeriod(category.id))
            })
        }

        return result
    }

    const unitAdornment = (standard, name = '') => {
        if (standard instanceof Object) {
            if (standard.hasOwnProperty('values')) {
                const attribute = standard.values.find(value => (value.attribute.name === 'Фасовка/Вес') || (value.attribute.name === 'Фасованный/Весовой'))

                name = 'шт'

                if (attribute) {
                    if (attribute.value.toLowerCase() === 'вес') {
                        let unit = standard.values.find(value => (value.attribute.type.key === 'unit'));

                        if (unit) {
                            name = unit.value
                        }
                    }
                }

                return name
            }
        }

        return name
    }

    const onSubmit = (values, {setSubmitting, setErrors, setTouched}) => {
        const formData = new FormData()
        for (const name in values) {
            if (values.hasOwnProperty(name)) {
                if (Array.isArray(values[name])) {
                    for (let i = 0; i < values[name].length; i++) {
                        for (const val in values[name][i]) {
                            if (values[name][i].hasOwnProperty(val)) {
                                if (Array.isArray(values[name][i][val])) {
                                    if (val === 'files') {
                                        Array.from(values[name][i][val]).forEach((el, index) => {
                                            if (el.file && el.description) {
                                                if (el.file instanceof File) {
                                                    formData.append(`${name}[${i}][${val}][${index}][file]`, el.file, el.file.name);
                                                } else {
                                                    formData.append(`${name}[${i}][${val}][${index}][file][id]`, el.file.id);
                                                }
                                                formData.append(`${name}[${i}][${val}][${index}][description]`, el.description);
                                            }
                                        });
                                    } else {
                                        for (let j = 0; j < values[name][i][val].length; j++) {
                                            for (const item in values[name][i][val][j]) {
                                                if (values[name][i][val][j].hasOwnProperty(item)) {
                                                    if (values[name][i][val][j][item]) {
                                                        switch (item) {
                                                            case 'expiration_date':
                                                                formData.append(`${name}[${i}][${val}][${j}][expiration_date]`, format(values[name][i][val][j].expiration_date, 'yyyy-MM-dd'))
                                                                break
                                                            case 'standard':
                                                                if (values[name][i][val][j][item] instanceof Object) {
                                                                    formData.append(`${name}[${i}][${val}][${j}][standard_id]`, values[name][i][val][j][item].id)
                                                                    formData.append(`${name}[${i}][${val}][${j}][name]`, values[name][i][val][j][item].name)
                                                                    if (categories.find((el) => {
                                                                        return (el.id === category?.id) && (getGenericCategories().indexOf(el.id) !== -1)
                                                                    })) {
                                                                        const {value} = values[name][i][val][j][item].hasOwnProperty('values') ? values[name][i][val][j][item].values.find((val) => {
                                                                            return val.attribute.value === 'generics'
                                                                        }) : {value: values[name][i][val][j][item].generic}

                                                                        formData.append(`${name}[${i}][${val}][${j}][generic]`, value)
                                                                    }
                                                                } else {
                                                                    formData.append(`${name}[${i}][${val}][${j}][name]`, values[name][i][val][j][item])
                                                                }
                                                                break
                                                            case 'unit':
                                                                if (values[name][i][val][j][item] instanceof Object) {
                                                                    formData.append(`${name}[${i}][${val}][${j}][${item}]`, values[name][i][val][j][item].id)
                                                                }
                                                                break
                                                            case 'id':
                                                                if (values[name][i][val][j][item]) {
                                                                    formData.append(`${name}[${i}][${val}][${j}][${item}]`, values[name][i][val][j][item])
                                                                }
                                                                break
                                                            default:
                                                                formData.append(`${name}[${i}][${val}][${j}][${item}]`, values[name][i][val][j][item])
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    if (values[name][i][val]) {
                                        switch (val) {
                                            case 'execution_month':
                                                formData.append(`${name}[${i}][${val}]`, format(values[name][i][val], 'MM.yyyy'))
                                                break
                                            case 'rationale':
                                                if (values[name][i][val] instanceof File) {
                                                    formData.append(`${name}[${i}][${val}]`, values[name][i][val])
                                                }
                                                break
                                            case 'technical_task':
                                                if (values[name][i][val] instanceof File) {
                                                    formData.append(`${name}[${i}][${val}]`, values[name][i][val])
                                                }
                                                break
                                            case 'draft_agreement':
                                                if (values[name][i][val] instanceof File) {
                                                    formData.append(`${name}[${i}][${val}]`, values[name][i][val])
                                                }
                                                break
                                            case 'type':
                                                formData.append(`${name}[${i}][type]`, values[name][i].type)
                                                switch (values[name][i][val]) {
                                                    case 'partial':
                                                        formData.append(`${name}[${i}][prepayment]`, values[name][i].prepayment)
                                                        formData.append(`${name}[${i}][postponement]`, values[name][i].postponement)
                                                        break
                                                    case 'postponement':
                                                        formData.append(`${name}[${i}][postponement]`, values[name][i].postponement)
                                                        break
                                                    default:
                                                }
                                                break
                                            case 'prepayment':
                                                break
                                            case 'postponement':
                                                break
                                            default:
                                                formData.append(`${name}[${i}][${val}]`, values[name][i][val])
                                        }
                                    }
                                }
                            }
                        }

                    }
                } else {
                    if (values[name] || ['cooperative_buying', 'unscheduled_purchase', 'is_unit_price'].includes(name)) {
                        switch (name) {
                            case 'application_start':
                            case 'application_deadline':
                                formData.append(name, format(values[name], 'yyyy-MM-dd HH:mmxxxxx'));
                                break;

                            case 'unscheduled_purchase':
                            case 'is_unit_price':
                            case 'cooperative_buying':
                                formData.append(name, JSON.stringify(values[name] ? 1 : 0));
                                break;

                            default:
                                formData.append(name, values[name]);
                                break;
                        }
                    }
                }
            }
        }

        formData.append('from_planning', true)

        return dispatch(ShopActions.add(formData)).then(
            () => {
                setSubmitting(false);
            },
            error => {
                setErrors({
                    ...error.errors,
                    ...(error.errors.hasOwnProperty('lots') ? {
                        lots: error.errors.lots.map(lot => ({
                            ...lot,
                            ...(lot.hasOwnProperty('items') ? {
                                items: lot.items.map(item => ({
                                    ...item,
                                    ...(item.hasOwnProperty('name') ? {
                                        standard: item.name
                                    } : {})
                                }))
                            } : {})
                        }))
                    } : {})
                })

                setTouched({
                    ...Object.keys(error.errors).reduce((obj, key) => ({...obj, [key]: true}), {}),
                    ...(error.errors.hasOwnProperty('lots') ? {
                        lots: error.errors.lots.map(lot => {
                            return {
                                ...(lot.hasOwnProperty('items') ? {
                                    ...Object.keys(lot).reduce((obj, key) => ({...obj, [key]: true}), {}),
                                    items: lot.items.map(item => Object.keys(item).reduce((obj, key) => {
                                        switch (key) {
                                            case 'name':
                                                return {
                                                    ...obj,
                                                    standard: true
                                                }
                                            default:
                                                return {
                                                    ...obj,
                                                    [key]: true
                                                }
                                        }
                                    }, {}))
                                } : lot)
                            }
                        })
                    } : {})
                })
                setSubmitting(false);
            }
        )
    }

    if ((categories && categories.length) && orderSettings && currencies && conductionMethods && company) {
        return (
            <Grid item className={classes.content}>
                <Paper>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item className={classes.full}>
                            <Grid container className={classes.fullWidth} direction="column" justify="space-between"
                                  alignItems="stretch">
                                <Grid item className={classes.fullWidth}>
                                    <Grid container className={classes.fullWidth} direction="column"
                                          justify="space-between" alignItems="stretch">
                                        <Grid item className={classes.header}>
                                            <Grid container className={classes.fullWidth} direction="column"
                                                  justify="flex-start" alignItems="stretch">
                                                <Grid item className={classes.fullWidth}>
                                                    <Typography className={classes.title}>
                                                        Публикация закупки
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Formik
                                        validateOnBlur={false}
                                        initialValues={{
                                            name: '',
                                            type: null,
                                            territory: [],
                                            cooperative_buying: false,
                                            reason: '',
                                            purchase_number: '',
                                            application_start: null,
                                            application_deadline: null,
                                            unscheduled_purchase: false,
                                            category: '',
                                            status: 'draft',
                                            currency: '',
                                            lots: [],
                                            price: 0,
                                            is_unit_price: false
                                        }}
                                        validationSchema={getValidationSchema(false, company, false, orderSettings, category, categories, true)}
                                        onSubmit={onSubmit}
                                    >
                                        {({
                                              values,
                                              errors,
                                              touched,
                                              isSubmitting,
                                              setValues,
                                              setFieldTouched,
                                              setFieldValue,
                                              submitForm,
                                          }) => (
                                            <Form>
                                                <Grid item>
                                                    <Grid className={classes.tab} container direction="column"
                                                          justify="space-between" alignItems="stretch">
                                                        <Grid item className={classes.tabContent}>
                                                            <Grid container direction="row" justify="space-around"
                                                                  alignItems="flex-start" spacing={2}>
                                                                <Grid item xs={6} className={classes.column}>
                                                                    <Field
                                                                        fullWidth
                                                                        name="name"
                                                                        type="text"
                                                                        label="Название"
                                                                        required
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={3} className={classes.column}>
                                                                    <Field
                                                                        fullWidth
                                                                        type="text"
                                                                        name="type"
                                                                        label="Способ проведения закупки"
                                                                        select
                                                                        variant="standard"
                                                                        component={TextField}
                                                                        required={true}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    >
                                                                        {
                                                                            conductionMethods.map(conductionMethod => (
                                                                                <MenuItem key={conductionMethod.id}
                                                                                          value={conductionMethod.key}>
                                                                                    {conductionMethod.name}
                                                                                </MenuItem>
                                                                            ))
                                                                        }
                                                                    </Field>
                                                                </Grid>
                                                                <Grid item xs={3} className={classes.column}>
                                                                    <Field
                                                                        fullWidth
                                                                        type="text"
                                                                        name="currency"
                                                                        label="Валюта"
                                                                        select
                                                                        variant="standard"
                                                                        component={TextField}
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                    >
                                                                        {
                                                                            currencies.map(currency => (
                                                                                <MenuItem key={currency.id}
                                                                                          value={currency.id}>
                                                                                    {currency.name}
                                                                                </MenuItem>
                                                                            ))
                                                                        }
                                                                    </Field>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justify="flex-start"
                                                                alignItems="flex-start"
                                                                spacing={2}
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className={errors?.category ? classes.selectCategoryError : classes.selectCategory}
                                                                >
                                                                    <Category
                                                                        isSubmitting={isSubmitting}
                                                                        label="Категория"
                                                                        value={values.category}
                                                                        onChange={(value) => {
                                                                            if (category?.id !== value?.id) {
                                                                                setCategory(value)
                                                                                setStandards([])
                                                                                setValues({
                                                                                    ...values,
                                                                                    lots: [],
                                                                                    price: 0,
                                                                                    category: value?.id,
                                                                                })
                                                                            }
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item xs={3}
                                                                    className={classes.column}
                                                                >
                                                                    <MuiPickersUtilsProvider
                                                                        utils={DateFnsUtils}
                                                                        locale={ru}
                                                                    >
                                                                        <KeyboardDateTimePicker
                                                                            disableToolbar
                                                                            fullWidth
                                                                            error={Boolean(errors?.hasOwnProperty('application_start') && touched?.hasOwnProperty('application_start'))}
                                                                            helperText={(errors?.hasOwnProperty('application_start') && touched?.hasOwnProperty('application_start')) ? errors?.application_start : null}
                                                                            name="application_start"
                                                                            format="HH:mm dd-MM-yyyy"
                                                                            variant="inline"
                                                                            openTo="year"
                                                                            label="Дата и время начала приема заявок"
                                                                            autoOk
                                                                            value={values.application_start}
                                                                            onChange={date => {
                                                                                setFieldValue("application_start", date);
                                                                            }}
                                                                            onBlur={() => {
                                                                                setFieldTouched("application_start", true, false)
                                                                            }}
                                                                            onClose={() => {
                                                                                setFieldTouched("application_start", true, false)
                                                                            }}
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                </Grid>
                                                                <Grid
                                                                    item xs={3}
                                                                    className={classes.column}>
                                                                    <MuiPickersUtilsProvider
                                                                        utils={DateFnsUtils}
                                                                        locale={ru}
                                                                    >
                                                                        <KeyboardDateTimePicker
                                                                            disableToolbar
                                                                            fullWidth
                                                                            error={Boolean(errors?.hasOwnProperty('application_deadline') && touched?.hasOwnProperty('application_deadline'))}
                                                                            helperText={errors?.application_deadline}
                                                                            name="application_deadline"
                                                                            format="HH:mm dd-MM-yyyy"
                                                                            variant="inline"
                                                                            openTo="year"
                                                                            label="Дата и время окончания приема заявок"
                                                                            autoOk
                                                                            value={values.application_deadline}
                                                                            onChange={date => {
                                                                                setFieldValue("application_deadline", date);
                                                                            }}
                                                                            onBlur={() => {
                                                                                setFieldTouched("application_deadline", true, false)
                                                                            }}
                                                                            onClose={() => {
                                                                                setFieldTouched("application_deadline", true, false)
                                                                            }}
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justify="flex-start"
                                                                alignItems="center"
                                                                spacing={2}
                                                            >
                                                                <Grid item xs={6} className={classes.column}>
                                                                    <Field
                                                                        fullWidth
                                                                        name="purchase_number"
                                                                        type="text"
                                                                        label="Номер закупки"
                                                                        disabled={!!values.unscheduled_purchase}
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={3} className={classes.column}>
                                                                    <Field
                                                                        component={CheckboxWithLabel}
                                                                        name="unscheduled_purchase"
                                                                        required={!values.purchase_number}
                                                                        color="primary"
                                                                        Label={{label: "Внеплановая закупка"}}
                                                                        inputProps={{
                                                                            onChange: async (event) => {
                                                                                const checked = event.target.checked

                                                                                await setFieldValue("unscheduled_purchase", checked)

                                                                                if (checked) {
                                                                                    await setFieldValue("purchase_number", '')
                                                                                    await setFieldValue("reason", '')
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={3} className={classes.column}>
                                                                    <Field
                                                                        fullWidth
                                                                        type="text"
                                                                        name="territory"
                                                                        label="Адрес поставки"
                                                                        select
                                                                        variant="standard"
                                                                        component={TextField}
                                                                        disabled={!company.territories.length}
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                    >
                                                                        {company.territories.map(territory => (
                                                                            <MenuItem key={territory.id}
                                                                                      value={territory.id}>
                                                                                {territory.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Field>
                                                                </Grid>
                                                            </Grid>
                                                            {values.unscheduled_purchase ? (
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justify="flex-start"
                                                                    alignItems="center"
                                                                    spacing={2}
                                                                >
                                                                    <Grid item xs={6} className={classes.column}>
                                                                        <Field
                                                                            fullWidth
                                                                            name="reason"
                                                                            type="text"
                                                                            label="Обоснование внеплановой закупки"
                                                                            multiline
                                                                            required
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            ) : null}
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justify="flex-start"
                                                                alignItems="center"
                                                                spacing={2}
                                                            >
                                                                <Grid item xs={2} className={classes.column}>
                                                                    <Field
                                                                        component={CheckboxWithLabel}
                                                                        name="is_unit_price" color="primary"
                                                                        Label={{label: "Цена за единицу"}}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={2} className={classes.column}>
                                                                    <Field
                                                                        component={CheckboxWithLabel}
                                                                        name="cooperative_buying"
                                                                        color="primary"
                                                                        Label={{label: "Совместная закупка"}}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <FieldArray name="lots" render={arrayHelpers => (
                                                                <Group title="Лоты">
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="flex-start"
                                                                        alignItems="flex-start"
                                                                        spacing={2}
                                                                    >
                                                                        <Grid item>
                                                                            <Submit
                                                                                variant="contained"
                                                                                component="label"
                                                                                color="primary"
                                                                                endIcon={<AddIcon/>}
                                                                                onClick={() => {
                                                                                    if (!category.hasOwnProperty('id')) {
                                                                                        return setShowCatError(true);
                                                                                    }
                                                                                    arrayHelpers.insert(0, {
                                                                                        number: '',
                                                                                        max_contract_price: null,
                                                                                        execution_month: null,
                                                                                        type: 'complete',
                                                                                        prepayment: 1,
                                                                                        postponement: '',
                                                                                        rationale: null,
                                                                                        draft_agreement: null,
                                                                                        technical_task: null,
                                                                                        items: [],
                                                                                        files: [
                                                                                            {
                                                                                                file: null,
                                                                                                description: ''
                                                                                            }
                                                                                        ]
                                                                                    })
                                                                                    let tmp = {}
                                                                                    Object.keys(generics).forEach(key => {
                                                                                        tmp[parseInt(key) + 1] = generics[key];
                                                                                    });
                                                                                    setGenerics(tmp)
                                                                                }}
                                                                            >
                                                                                Новый лот
                                                                            </Submit>
                                                                        </Grid>
                                                                        {(errors?.lots?.length && !values?.lots?.length) ? (
                                                                            <Grid item>
                                                                                <Typography className={classes.error}>
                                                                                    Для публикации создайте лот!
                                                                                </Typography>
                                                                            </Grid>
                                                                        ) : null}
                                                                        <Grid item>
                                                                            {(!category.hasOwnProperty('id') && showCatError) ? (
                                                                                <Typography className={classes.error}>
                                                                                    Для создания лота сначала выберите
                                                                                    категорию
                                                                                </Typography>
                                                                            ) : null}
                                                                        </Grid>
                                                                    </Grid>
                                                                    {values.lots.map((lot, index) => (
                                                                        <Group key={index}>
                                                                            <Grid
                                                                                container
                                                                                className={classes.group}
                                                                                direction="row"
                                                                                justify="space-between"
                                                                                alignItems="stretch"
                                                                                spacing={2}
                                                                            >
                                                                                <Grid item xs={12}
                                                                                      className={classes.itemClose}>
                                                                                    <IconButton
                                                                                        size="medium"
                                                                                        onClick={() => {
                                                                                            let tmp = {}
                                                                                            Object.keys(generics).forEach(key => {
                                                                                                if (parseInt(key) !== index) {
                                                                                                    tmp[parseInt(key) - 1] = generics[key];
                                                                                                }
                                                                                            });
                                                                                            setGenerics(tmp)
                                                                                            setStandards(standards.filter((standard) => (!values.lots[index].items.map((el) => {
                                                                                                if (el.standard) {
                                                                                                    return el.standard.id
                                                                                                }
                                                                                                return null
                                                                                            }).includes(standard))))

                                                                                            const lots = values.lots.map((lot, idx) => {
                                                                                                if (idx === index) {
                                                                                                    lot.price = 0
                                                                                                }

                                                                                                return lot
                                                                                            })

                                                                                            setValues({
                                                                                                ...values,
                                                                                                lots: lots,
                                                                                                price: lots.reduce(function (sum, lot) {
                                                                                                    return sum + parseFloat(lot.price)
                                                                                                }, 0).toFixed(2) ?? ''
                                                                                            })

                                                                                            arrayHelpers.remove(index)
                                                                                        }}
                                                                                    >
                                                                                        <Clear/>
                                                                                    </IconButton>
                                                                                </Grid>
                                                                                <Grid item xs={7}>
                                                                                    <Grid
                                                                                        container
                                                                                        direction="row"
                                                                                        justify="flex-start"
                                                                                        alignItems="flex-start"
                                                                                        spacing={2}
                                                                                    >
                                                                                        {values.is_unit_price ? <Grid item xs={12} className={classes.column}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                name={`lots.${index}.max_contract_price`}
                                                                                                type="number"
                                                                                                step={0.01}
                                                                                                required
                                                                                                label="Максимальная цена договора"
                                                                                                component={CustomTextField}
                                                                                            />
                                                                                        </Grid> : null}
                                                                                        <Grid item xs={6} className={classes.column}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                name={`lots.${index}.number`}
                                                                                                type="text"
                                                                                                label="Лот №"
                                                                                                component={CustomTextField}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={6} className={classes.column}>
                                                                                            <MuiPickersUtilsProvider
                                                                                                utils={DateFnsUtils}
                                                                                                locale={ru}
                                                                                            >
                                                                                                <KeyboardDatePicker
                                                                                                    disableToolbar
                                                                                                    fullWidth
                                                                                                    error={Boolean((errors?.hasOwnProperty('lots') && errors?.lots[index] && errors?.lots[index].execution_month) && (touched?.hasOwnProperty('lots') && touched?.lots[index] && touched?.lots[index].execution_month))}
                                                                                                    helperText={((errors?.hasOwnProperty('lots') && errors?.lots[index] && errors?.lots[index].execution_month) && (touched?.hasOwnProperty('lots') && touched?.lots[index] && touched?.lots[index].execution_month)) ? errors?.lots[index].execution_month : null}
                                                                                                    name={`lots.${index}.execution_month`}
                                                                                                    views={["year", "month"]}
                                                                                                    orientation="landscape"
                                                                                                    variant="inline"
                                                                                                    format="MM.yyyy"
                                                                                                    openTo="year"
                                                                                                    autoOk
                                                                                                    label="Планируемый месяц исполнения договора"
                                                                                                    value={values.lots[index].execution_month}
                                                                                                    onChange={date => {
                                                                                                        setFieldValue(`lots.${index}.execution_month`, date);
                                                                                                    }}
                                                                                                    onBlur={() => {
                                                                                                        setFieldTouched(`lots.${index}.execution_month`, true, true)
                                                                                                    }}
                                                                                                    onClose={() => {
                                                                                                        setFieldTouched(`lots.${index}.execution_month`, true, true)
                                                                                                    }}
                                                                                                />
                                                                                            </MuiPickersUtilsProvider>
                                                                                        </Grid>
                                                                                        <Grid item xs={6}
                                                                                              className={classes.column}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                type="text"
                                                                                                name={`lots.${index}.type`}
                                                                                                label="Тип оплаты"
                                                                                                select
                                                                                                variant="standard"
                                                                                                component={TextField}
                                                                                                InputLabelProps={{
                                                                                                    shrink: true
                                                                                                }}
                                                                                                InputProps={{
                                                                                                    onChange: (event) => {
                                                                                                        setFieldValue(`lots.${index}.type`, event.target.value, true)
                                                                                                        setFieldValue(`lots.${index}.postponement`, '', false)
                                                                                                        setFieldValue(`lots.${index}.prepayment`, 1, false)
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                {[
                                                                                                    {
                                                                                                        type: 'complete',
                                                                                                        name: 'Предоплата'
                                                                                                    },
                                                                                                    {
                                                                                                        type: 'partial',
                                                                                                        name: 'Частичная предоплата'
                                                                                                    },
                                                                                                    {
                                                                                                        type: 'postponement',
                                                                                                        name: 'Постоплата'
                                                                                                    }
                                                                                                ].map((item, i) => (
                                                                                                    <MenuItem key={i}
                                                                                                              value={item.type}>
                                                                                                        {item.name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </Field>
                                                                                        </Grid>
                                                                                        {((values.lots[index].type === 'postponement') || (values.lots[index].type === 'partial')) ? (
                                                                                            <Grid item
                                                                                                  xs={(values.lots[index].type === 'postponement') ? 6 : 4}
                                                                                                  className={classes.column}>
                                                                                                <Field
                                                                                                    fullWidth
                                                                                                    name={`lots.${index}.postponement`}
                                                                                                    type="number"
                                                                                                    label={`${(values.lots[index].type === 'postponement') ? 'Отсрочка платежа, дней' : 'Отсрочка постоплаты, дней'}`}
                                                                                                    step={1}
                                                                                                    inputProps={{
                                                                                                        min: 0
                                                                                                    }}
                                                                                                    component={CustomTextField}
                                                                                                />
                                                                                            </Grid>
                                                                                        ) : null}
                                                                                        {(values.lots[index].type === 'partial') ? (
                                                                                            <Grid item xs={2}
                                                                                                  className={classes.column}>
                                                                                                <Field
                                                                                                    fullWidth
                                                                                                    name={`lots.${index}.prepayment`}
                                                                                                    type="number"
                                                                                                    label="Предоплата"
                                                                                                    step={1}
                                                                                                    inputProps={{
                                                                                                        min: 1,
                                                                                                        max: 100
                                                                                                    }}
                                                                                                    endAdornment={'%'}
                                                                                                    component={CustomTextField}
                                                                                                />
                                                                                            </Grid>
                                                                                        ) : null}
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <Grid
                                                                                        container
                                                                                        direction="column"
                                                                                        justify="flex-start"
                                                                                        alignItems="flex-start"
                                                                                        spacing={2}
                                                                                    >
                                                                                        <Grid item xs={12}
                                                                                              className={classes.fullWidth}>
                                                                                            <Grid
                                                                                                container
                                                                                                direction="row"
                                                                                                justify="flex-start"
                                                                                                alignItems="center"
                                                                                                spacing={2}
                                                                                            >
                                                                                                <Grid
                                                                                                    item
                                                                                                    xs={!(!!values.lots[index].rationale) ? 7 : 12}
                                                                                                    className={classes.filesUpload}>
                                                                                                    <Field
                                                                                                        fullWidth
                                                                                                        size="small"
                                                                                                        component={(props) => (
                                                                                                            <FileUpload {...props}
                                                                                                                        endIcon={"Обзор"} tooltip={true}/>
                                                                                                        )}
                                                                                                        label="Обоснование НМЦ лота"
                                                                                                        name={`lots.${index}.rationale`}
                                                                                                        InputLabelProps={{
                                                                                                            name: lot.rationale ? lot.rationale.name : null
                                                                                                        }}
                                                                                                        InputProps={{
                                                                                                            onChange: (event) => {
                                                                                                                setFieldValue(`lots.${index}.rationale`, event.currentTarget.files[0])
                                                                                                                setFieldTouched(`lots.${index}.rationale`, true, false)
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <Grid item xs={12}
                                                                                              className={classes.fullWidth}>
                                                                                            <Grid
                                                                                                container
                                                                                                direction="row"
                                                                                                justify="flex-start"
                                                                                                alignItems="center"
                                                                                                spacing={2}
                                                                                            >
                                                                                                <Grid
                                                                                                    item
                                                                                                    xs={!(!!values.lots[index].draft_agreement) ? 7 : 12}
                                                                                                    className={classes.filesUpload}
                                                                                                >
                                                                                                    <Field
                                                                                                        fullWidth
                                                                                                        size="small"
                                                                                                        component={(props) => (
                                                                                                            <FileUpload {...props}
                                                                                                                        endIcon={"Обзор"} tooltip={true}/>
                                                                                                        )}
                                                                                                        label="Проект договора"
                                                                                                        name={`lots.${index}.draft_agreement`}
                                                                                                        InputLabelProps={{
                                                                                                            name: lot.draft_agreement ? lot.draft_agreement.name : null
                                                                                                        }}
                                                                                                        InputProps={{
                                                                                                            onChange: (event) => {
                                                                                                                setFieldValue(`lots.${index}.draft_agreement`, event.currentTarget.files[0])
                                                                                                                setFieldTouched(`lots.${index}.draft_agreement`, true, false)
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <Grid item xs={12}
                                                                                              className={classes.fullWidth}>
                                                                                            <Grid
                                                                                                container
                                                                                                direction="row"
                                                                                                justify="flex-start"
                                                                                                alignItems="center"
                                                                                                spacing={2}
                                                                                            >
                                                                                                <Grid
                                                                                                    item
                                                                                                    xs={!(!!values.lots[index].technical_task) ? 7 : 12}
                                                                                                    className={classes.filesUpload}
                                                                                                >
                                                                                                    <Field
                                                                                                        fullWidth
                                                                                                        size="small"
                                                                                                        component={(props) => (
                                                                                                            <FileUpload {...props}
                                                                                                                        endIcon={"Обзор"} tooltip={true}/>
                                                                                                        )}
                                                                                                        label="Техническое задание"
                                                                                                        name={`lots.${index}.technical_task`}
                                                                                                        InputLabelProps={{
                                                                                                            name: lot.technical_task ? lot.technical_task.name : null
                                                                                                        }}
                                                                                                        InputProps={{
                                                                                                            onChange: (event) => {
                                                                                                                setFieldValue(`lots.${index}.technical_task`, event.currentTarget.files[0])
                                                                                                                setFieldTouched(`lots.${index}.technical_task`, true, false)
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <FieldArray
                                                                                            name={`lots[${index}]files`}
                                                                                            render={arrayHelpers => (
                                                                                                <Grid
                                                                                                    container
                                                                                                    direction="row"
                                                                                                    justify="flex-start"
                                                                                                    alignItems="flex-start"
                                                                                                    spacing={2}
                                                                                                    style={{maxWidth: "calc(100% + 9px)"}}
                                                                                                >
                                                                                                    <Grid item xs={12}>
                                                                                                        <Grid
                                                                                                            container
                                                                                                            direction="row"
                                                                                                            justify="flex-start"
                                                                                                            className={classes.fileRows}
                                                                                                            alignItems="flex-start"
                                                                                                            spacing={2}
                                                                                                        >
                                                                                                            {values.lots[index].files.map((el, idx) => (
                                                                                                                <React.Fragment
                                                                                                                    key={idx}
                                                                                                                >
                                                                                                                    <Grid
                                                                                                                        item
                                                                                                                        xs={5}>
                                                                                                                        <Field
                                                                                                                            fullWidth
                                                                                                                            style={{
                                                                                                                                marginTop: "5px",
                                                                                                                                lineHeight: "16px",
                                                                                                                                fontSize: "16px",
                                                                                                                                padding: "4px 0",
                                                                                                                                paddingTop: "5px",
                                                                                                                            }}
                                                                                                                            name={`lots[${index}]files[${idx}].description`}
                                                                                                                            type="text"
                                                                                                                            multiline
                                                                                                                            rows="1"
                                                                                                                            component={CustomTextField}
                                                                                                                        />
                                                                                                                    </Grid>
                                                                                                                    <Grid
                                                                                                                        item
                                                                                                                        xs={6}
                                                                                                                    >
                                                                                                                        <Field
                                                                                                                            fullWidth
                                                                                                                            size="small"
                                                                                                                            component={(props) => (
                                                                                                                                <FileUpload {...props}
                                                                                                                                            endIcon={"Обзор"}/>
                                                                                                                            )}
                                                                                                                            name={`lots.${index}.files.${idx}.file`}
                                                                                                                            InputLabelProps={{
                                                                                                                                name: values.lots[index].files && values.lots[index].files.length && values.lots[index].files[idx] && values.lots[index].files[idx].file && values.lots[index].files[idx].file.name ? values.lots[index].files[idx].file.name : null
                                                                                                                            }}
                                                                                                                            InputProps={{
                                                                                                                                onChange: (event) => {
                                                                                                                                    setFieldValue(`lots.${index}.files.${idx}.file`, event.currentTarget.files[0])
                                                                                                                                    setFieldTouched(`lots.${index}.files.${idx}.file`, true, false)
                                                                                                                                }
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </Grid>
                                                                                                                    <Grid
                                                                                                                        item
                                                                                                                        xs={1}
                                                                                                                        style={{padding: "18px 0"}}
                                                                                                                    >
                                                                                                                        <IconButton
                                                                                                                            size="small"
                                                                                                                            style={{padding: "3px 0"}}
                                                                                                                            onClick={() => {
                                                                                                                                arrayHelpers.remove(idx)
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <Clear/>
                                                                                                                        </IconButton>
                                                                                                                    </Grid>
                                                                                                                </React.Fragment>
                                                                                                            ))}
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={5}/>
                                                                                                    <Grid
                                                                                                        item
                                                                                                        xs={7}
                                                                                                        style={{
                                                                                                            display: "flex",
                                                                                                            justifyContent: "flex-start"
                                                                                                        }}>
                                                                                                        <ButtonIcon
                                                                                                            className={classes.button}
                                                                                                            onClick={(e) => {
                                                                                                                e.stopPropagation()
                                                                                                                arrayHelpers.insert(values.lots[index].files.length, {
                                                                                                                    file: null,
                                                                                                                    description: ''
                                                                                                                })
                                                                                                            }}
                                                                                                            children={
                                                                                                                <AddIcon/>}
                                                                                                        />
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            )}/>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <FieldArray
                                                                                        name={`lots.${index}.items`}
                                                                                        render={arrayHelpersItems => (
                                                                                            <Grid
                                                                                                container
                                                                                                direction="column"
                                                                                                justify="flex-start"
                                                                                                alignItems="stretch"
                                                                                                spacing={2}
                                                                                            >
                                                                                                {lot.items.map((el, i) => (
                                                                                                    <Grid
                                                                                                        item
                                                                                                        key={i}
                                                                                                        style={{
                                                                                                            boxShadow: "0 0 5px 2px grey",
                                                                                                            margin: "20px 0px",
                                                                                                            paddingBottom: '45px'
                                                                                                        }}
                                                                                                    >
                                                                                                        <Grid
                                                                                                            container
                                                                                                            direction="row"
                                                                                                            justify="space-between"
                                                                                                            alignItems="center"
                                                                                                            spacing={2}
                                                                                                        >
                                                                                                            <Grid item
                                                                                                                  xs={10}
                                                                                                                  className={classes.block}
                                                                                                            >
                                                                                                                <Grid
                                                                                                                    container
                                                                                                                    direction="column"
                                                                                                                    justify="flex-start"
                                                                                                                    alignItems="stretch"
                                                                                                                    spacing={2}
                                                                                                                >
                                                                                                                    <Grid
                                                                                                                        item>
                                                                                                                        <Grid
                                                                                                                            container
                                                                                                                            direction="row"
                                                                                                                            justify="flex-start"
                                                                                                                            alignItems="center"
                                                                                                                            spacing={2}
                                                                                                                        >
                                                                                                                            <Grid
                                                                                                                                item
                                                                                                                                xs={10}
                                                                                                                                className={classes.column}
                                                                                                                            >
                                                                                                                                <Standard
                                                                                                                                    name={`lots.${index}.items.${i}.standard`}
                                                                                                                                    module={'ЦЗ'}
                                                                                                                                    required={true}
                                                                                                                                    disabled={!category.hasOwnProperty('id')}
                                                                                                                                    errors={errors}
                                                                                                                                    touched={touched}
                                                                                                                                    noOptionsText='Укажите название товара'
                                                                                                                                    category={category}
                                                                                                                                    standard={values.lots[index].items[i].standard}
                                                                                                                                    params={{
                                                                                                                                        ...(standards.filter(function (el) {
                                                                                                                                            return el ?? (el != null);
                                                                                                                                        }).length ? {
                                                                                                                                            except: standards.filter(function (el) {
                                                                                                                                                return el ?? (el != null);
                                                                                                                                            }).join(',')
                                                                                                                                        } : {}),
                                                                                                                                        //...((generics.hasOwnProperty(index) && generics[index].value) ? {generic: generics[index].value} : {}),
                                                                                                                                    }}
                                                                                                                                    onChange={(value) => {
                                                                                                                                        const item = value[`lots.${index}.items.${i}.standard`]

                                                                                                                                        setFieldValue(`lots.${index}.items.${i}.standard`, item)
                                                                                                                                        setFieldValue(`lots.${index}.items.${i}.unit`, '')

                                                                                                                                        if (item) {
                                                                                                                                            setFieldValue(`lots.${index}.items.${i}.amount`, 1)

                                                                                                                                            if (item instanceof Object) {
                                                                                                                                                const unit = units.find(unit => unit?.short === unitAdornment(item))

                                                                                                                                                if (unit) {
                                                                                                                                                    setFieldValue(`lots.${index}.items.${i}.unit`, unit)
                                                                                                                                                }

                                                                                                                                                setStandards([...standards, ...[item.id]])
                                                                                                                                                if (categories.find((el) => {
                                                                                                                                                    return (el.id === category?.id) && (getGenericCategories().indexOf(el.id) !== -1)
                                                                                                                                                })) {
                                                                                                                                                    const {value} = item.values.find((val) => {
                                                                                                                                                        return val.attribute.value === 'generics'
                                                                                                                                                    })

                                                                                                                                                    if (generics[index]) {
                                                                                                                                                        setGenerics({
                                                                                                                                                            ...generics,
                                                                                                                                                            ...{
                                                                                                                                                                [index]: {
                                                                                                                                                                    value: value,
                                                                                                                                                                    count: generics[index].count + 1
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        })
                                                                                                                                                    } else {
                                                                                                                                                        setGenerics({
                                                                                                                                                            ...generics,
                                                                                                                                                            ...{
                                                                                                                                                                [index]: {
                                                                                                                                                                    value: value,
                                                                                                                                                                    count: 1
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        })
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        } else if (values.lots[index].items[i].standard) {
                                                                                                                                            if (values.lots[index].items[i].standard instanceof Object) {
                                                                                                                                                setStandards(standards.filter((standard) => (standard !== values.lots[index].items[i].standard.id)))

                                                                                                                                                if (categories.find((el) => {
                                                                                                                                                    return (el.id === category?.id) && (getGenericCategories().indexOf(el.id) !== -1)
                                                                                                                                                })) {
                                                                                                                                                    if (generics[index]) {
                                                                                                                                                        if (generics[index].count - 1) {
                                                                                                                                                            setGenerics({
                                                                                                                                                                ...generics,
                                                                                                                                                                ...{
                                                                                                                                                                    [index]: {
                                                                                                                                                                        value: generics[index].value,
                                                                                                                                                                        count: generics[index].count - 1
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            })
                                                                                                                                                        } else {
                                                                                                                                                            const tmp = {}
                                                                                                                                                            Object.keys(generics).forEach(key => {
                                                                                                                                                                if (parseInt(key) !== parseInt(index)) {
                                                                                                                                                                    tmp[key] = generics[key];
                                                                                                                                                                }
                                                                                                                                                            });
                                                                                                                                                            setGenerics(tmp)
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }}
                                                                                                                                    onAddStandard={(item) => {
                                                                                                                                        setFieldValue(`lots.${index}.items.${i}.standard`, item)
                                                                                                                                        setFieldValue(`lots.${index}.items.${i}.unit`, '')

                                                                                                                                        if (item) {
                                                                                                                                            setFieldValue(`lots.${index}.items.${i}.amount`, 1)
                                                                                                                                            if (item instanceof Object) {
                                                                                                                                                const unit = units.find(unit => unit?.short === unitAdornment(item))

                                                                                                                                                if (unit) {
                                                                                                                                                    setFieldValue(`lots.${index}.items.${i}.unit`, unit)
                                                                                                                                                }

                                                                                                                                                setStandards([...standards, ...[item.id]])

                                                                                                                                                if (categories.find((el) => {
                                                                                                                                                    return (el.id === category?.id) && (getGenericCategories().indexOf(el.id) !== -1)
                                                                                                                                                })) {
                                                                                                                                                    const {value} = item.values.find((val) => {
                                                                                                                                                        return val.attribute.value === 'generics'
                                                                                                                                                    })

                                                                                                                                                    if (generics[index]) {
                                                                                                                                                        setGenerics({
                                                                                                                                                            ...generics,
                                                                                                                                                            ...{
                                                                                                                                                                [index]: {
                                                                                                                                                                    value: value,
                                                                                                                                                                    count: generics[index].count + 1
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        })
                                                                                                                                                    } else {
                                                                                                                                                        setGenerics({
                                                                                                                                                            ...generics,
                                                                                                                                                            ...{
                                                                                                                                                                [index]: {
                                                                                                                                                                    value: value,
                                                                                                                                                                    count: 1
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        })
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        } else if (values.lots[index].items[i].standard) {
                                                                                                                                            if (values.lots[index].items[i].standard instanceof Object) {
                                                                                                                                                setStandards(standards.filter((standard) => (standard !== values.lots[index].items[i].standard.id)))

                                                                                                                                                if (categories.find((el) => {
                                                                                                                                                    return (el.id === category?.id) && (getGenericCategories().indexOf(el.id) !== -1)
                                                                                                                                                })) {
                                                                                                                                                    if (generics[index]) {
                                                                                                                                                        if (generics[index].count - 1) {
                                                                                                                                                            setGenerics({
                                                                                                                                                                ...generics,
                                                                                                                                                                ...{
                                                                                                                                                                    [index]: {
                                                                                                                                                                        value: generics[index].value,
                                                                                                                                                                        count: generics[index].count - 1
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            })
                                                                                                                                                        } else {
                                                                                                                                                            const tmp = {}
                                                                                                                                                            Object.keys(generics).forEach(key => {
                                                                                                                                                                if (parseInt(key) !== parseInt(index)) {
                                                                                                                                                                    tmp[key] = generics[key];
                                                                                                                                                                }
                                                                                                                                                            });
                                                                                                                                                            setGenerics(tmp)
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }}
                                                                                                                                />
                                                                                                                            </Grid>
                                                                                                                        </Grid>
                                                                                                                    </Grid>
                                                                                                                    <Grid
                                                                                                                        item>
                                                                                                                        <Grid
                                                                                                                            container
                                                                                                                            direction="row"
                                                                                                                            justify="flex-start"
                                                                                                                            alignItems="center"
                                                                                                                            spacing={2}
                                                                                                                        >
                                                                                                                            <Grid
                                                                                                                                item
                                                                                                                                xs={2}
                                                                                                                                className={classes.column}
                                                                                                                            >
                                                                                                                                <Field
                                                                                                                                    fullWidth
                                                                                                                                    type="text"
                                                                                                                                    name={`lots.${index}.items.${i}.expiration_date_type`}
                                                                                                                                    label="Тип срока годности"
                                                                                                                                    select
                                                                                                                                    variant="standard"
                                                                                                                                    component={TextField}
                                                                                                                                    required={Boolean(category?.active)}
                                                                                                                                    InputLabelProps={{
                                                                                                                                        shrink: true
                                                                                                                                    }}
                                                                                                                                    InputProps={{
                                                                                                                                        onChange: (event) => {
                                                                                                                                            setFieldValue(`lots.${index}.items.${i}.expiration_date_type`, event.target.value, true)
                                                                                                                                            if (event.target.value === 'expiration_date') {
                                                                                                                                                setFieldValue(`lots.${index}.items.${i}.expiration_date_number`, '');
                                                                                                                                            } else {
                                                                                                                                                setFieldValue(`lots.${index}.items.${i}.expiration_date`, null);
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {[
                                                                                                                                        ...[{
                                                                                                                                            type: 'expiration_date',
                                                                                                                                            name: 'Минимальная дата истечения срока годности'
                                                                                                                                        }],
                                                                                                                                        ...((getCategoriesWithWarrantyPeriod().indexOf(category?.id) !== -1) ? [{
                                                                                                                                            type: 'warranty_period',
                                                                                                                                            name: 'Гарантийный срок'
                                                                                                                                        }] : [{
                                                                                                                                            type: 'expiration_date_percent',
                                                                                                                                            name: 'Остаточный срок годности'
                                                                                                                                        }])
                                                                                                                                    ].map((item, i) => (
                                                                                                                                        <MenuItem
                                                                                                                                            key={i}
                                                                                                                                            value={item.type}>
                                                                                                                                            {item.name}
                                                                                                                                        </MenuItem>
                                                                                                                                    ))}
                                                                                                                                </Field>
                                                                                                                            </Grid>
                                                                                                                            <Grid
                                                                                                                                item
                                                                                                                                xs={2}
                                                                                                                                className={classes.column}>
                                                                                                                                {(values.lots[index].items[i].expiration_date_type === 'expiration_date') &&
                                                                                                                                    <MuiPickersUtilsProvider
                                                                                                                                        utils={DateFnsUtils}
                                                                                                                                        locale={ru}>
                                                                                                                                        <KeyboardDatePicker
                                                                                                                                            disableToolbar
                                                                                                                                            fullWidth
                                                                                                                                            error={Boolean((errors?.hasOwnProperty('lots') && errors?.lots[index] && errors?.lots[index].hasOwnProperty('items') && errors?.lots[index].items[i] && errors?.lots[index].items[i].expiration_date) && (touched?.hasOwnProperty('lots') && touched?.lots[index] && touched?.lots[index].hasOwnProperty('items') && touched?.lots[index].items[i] && touched?.lots[index].items[i].expiration_date))}
                                                                                                                                            helperText={((errors?.hasOwnProperty('lots') && errors?.lots[index] && errors?.lots[index].hasOwnProperty('items') && errors?.lots[index].items[i] && errors?.lots[index].items[i].expiration_date) && (touched?.hasOwnProperty('lots') && touched?.lots[index] && touched?.lots[index].hasOwnProperty('items') && touched?.lots[index].items[i] && touched?.lots[index].items[i].expiration_date)) ? errors?.lots[index].items[i].expiration_date : null}
                                                                                                                                            name={`lots.${index}.items.${i}.expiration_date`}
                                                                                                                                            required={Boolean(category?.active && (values.lots[index].items[i].expiration_date_type === 'expiration_date'))}
                                                                                                                                            format="dd-MM-yyyy"
                                                                                                                                            orientation="landscape"
                                                                                                                                            variant="inline"
                                                                                                                                            openTo="year"
                                                                                                                                            label="Срок годности"
                                                                                                                                            autoOk
                                                                                                                                            disablePast
                                                                                                                                            value={values.lots[index].items[i].expiration_date}
                                                                                                                                            onChange={date => {
                                                                                                                                                setFieldValue(`lots.${index}.items.${i}.expiration_date`, date);
                                                                                                                                            }}
                                                                                                                                            onBlur={() => {
                                                                                                                                                setFieldTouched(`lots.${index}.items.${i}.expiration_date`, true, true)
                                                                                                                                            }}
                                                                                                                                            onClose={() => {
                                                                                                                                                setFieldTouched(`lots.${index}.items.${i}.expiration_date`, true, true)
                                                                                                                                            }}
                                                                                                                                        />
                                                                                                                                    </MuiPickersUtilsProvider>
                                                                                                                                }
                                                                                                                                {(values.lots[index].items[i].expiration_date_type === 'warranty_period') ? (
                                                                                                                                    <Field
                                                                                                                                        fullWidth
                                                                                                                                        name={`lots.${index}.items.${i}.expiration_date_number`}
                                                                                                                                        type="number"
                                                                                                                                        label="Срок годности, месяцев"
                                                                                                                                        required={Boolean(category?.active && values.lots[index].items[i].expiration_date_type === 'warranty_period')}
                                                                                                                                        step={1}
                                                                                                                                        inputProps={{
                                                                                                                                            min: 0,
                                                                                                                                            step: 1
                                                                                                                                        }}
                                                                                                                                        component={CustomTextField}
                                                                                                                                    />
                                                                                                                                ) : null}
                                                                                                                                {(values.lots[index].items[i].expiration_date_type === 'expiration_date_percent') ? (
                                                                                                                                    <Field
                                                                                                                                        fullWidth
                                                                                                                                        name={`lots.${index}.items.${i}.expiration_date_number`}
                                                                                                                                        type="number"
                                                                                                                                        label="Срок годности, %"
                                                                                                                                        required={Boolean(category?.active && values.lots[index].items[i].expiration_date_type === 'expiration_date_percent')}
                                                                                                                                        step={0.01}
                                                                                                                                        inputProps={{
                                                                                                                                            min: 0,
                                                                                                                                            step: 0.01,
                                                                                                                                        }}
                                                                                                                                        component={CustomTextField}
                                                                                                                                    />
                                                                                                                                ) : null}
                                                                                                                            </Grid>
                                                                                                                            {!values?.is_unit_price ? <Grid
                                                                                                                                item
                                                                                                                                xs={2}
                                                                                                                                className={classes.column}
                                                                                                                            >
                                                                                                                                <Field
                                                                                                                                    fullWidth
                                                                                                                                    name={`lots.${index}.items.${i}.amount`}
                                                                                                                                    type="text"
                                                                                                                                    label="Кол-во"
                                                                                                                                    step={0.01}
                                                                                                                                    inputProps={{
                                                                                                                                        step: 0.01,
                                                                                                                                        min: 1,
                                                                                                                                        onChange: (e) => {
                                                                                                                                            const lots = values.lots.map((lot, idx) => {
                                                                                                                                                let items = lot.items

                                                                                                                                                if (idx === index) {
                                                                                                                                                    items = items.map((item, j) => {
                                                                                                                                                        return (j === i) ? {
                                                                                                                                                            ...item,
                                                                                                                                                            ...{
                                                                                                                                                                amount: e.target.value,
                                                                                                                                                                price: (e.target.value && item.average_price) ? (e.target.value * item.average_price).toFixed(2) : ''
                                                                                                                                                            }
                                                                                                                                                        } : item
                                                                                                                                                    })
                                                                                                                                                }

                                                                                                                                                return {
                                                                                                                                                    ...lot,
                                                                                                                                                    ...{
                                                                                                                                                        items: items,
                                                                                                                                                        price: items.reduce(function (sum, item) {
                                                                                                                                                            return sum + parseFloat(item.price)
                                                                                                                                                        }, 0).toFixed(2) ?? '',
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            })
                                                                                                                                            setValues({
                                                                                                                                                ...values,
                                                                                                                                                lots: lots,
                                                                                                                                                price: lots.reduce(function (sum, lot) {
                                                                                                                                                    return sum + parseFloat(lot.price)
                                                                                                                                                }, 0).toFixed(2) ?? ''
                                                                                                                                            })
                                                                                                                                        }
                                                                                                                                    }}
                                                                                                                                    endAdornment={
                                                                                                                                        <InputAdornment
                                                                                                                                            position="end">{unitAdornment(values.lots[index].items[i].standard, values.lots[index].items[i].unit?.short)}</InputAdornment>}
                                                                                                                                    component={CustomTextField}
                                                                                                                                />
                                                                                                                            </Grid>: null}
                                                                                                                            {!category?.active ? (
                                                                                                                                <Grid
                                                                                                                                    item
                                                                                                                                    xs={2}
                                                                                                                                    className={classes.column}
                                                                                                                                >
                                                                                                                                    <Autocomplete
                                                                                                                                        options={units}
                                                                                                                                        name={`lots.${index}.items.${i}.unit`}
                                                                                                                                        value={values.lots[index].items[i].unit}
                                                                                                                                        getOptionLabel={unit => {
                                                                                                                                            return unit ? unit.name : ''
                                                                                                                                        }}
                                                                                                                                        onChange={(e, unit) => {
                                                                                                                                            setFieldValue(`lots.${index}.items.${i}.unit`, unit ? unit : '')
                                                                                                                                        }}
                                                                                                                                        noOptionsText='Единица измерения'
                                                                                                                                        renderInput={params => {
                                                                                                                                            const error = getIn(errors, `lots.${index}.items.${i}.unit`);

                                                                                                                                            return <Field
                                                                                                                                                fullWidth
                                                                                                                                                component={MuiTextField}
                                                                                                                                                {...params}
                                                                                                                                                error={!!error}
                                                                                                                                                helperText={error}
                                                                                                                                                name={`lots.${index}.items.${i}.unit`}
                                                                                                                                                label="Единица измерения"
                                                                                                                                            />
                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                </Grid>
                                                                                                                            ) : null}
                                                                                                                            <Grid
                                                                                                                                item
                                                                                                                                xs={2}
                                                                                                                                className={classes.column}
                                                                                                                            >
                                                                                                                                <Field
                                                                                                                                    fullWidth
                                                                                                                                    name={`lots.${index}.items.${i}.average_price`}
                                                                                                                                    type="number"
                                                                                                                                    label="НМЦ за ед. товара"
                                                                                                                                    step={0.01}
                                                                                                                                    inputProps={{
                                                                                                                                        step: 0.01,
                                                                                                                                        min: 0,
                                                                                                                                        onChange: (e) => {
                                                                                                                                            const lots = values.lots.map((lot, idx) => {
                                                                                                                                                let items = lot.items

                                                                                                                                                if (idx === index) {
                                                                                                                                                    items = items.map((item, j) => {
                                                                                                                                                        return (j === i) ? {
                                                                                                                                                            ...item,
                                                                                                                                                            ...{
                                                                                                                                                                average_price: e.target.value,
                                                                                                                                                                price: (item.amount && e.target.value) ? (item.amount * e.target.value).toFixed(2) : ''
                                                                                                                                                            }
                                                                                                                                                        } : item
                                                                                                                                                    })
                                                                                                                                                }

                                                                                                                                                return {
                                                                                                                                                    ...lot,
                                                                                                                                                    ...{
                                                                                                                                                        items: items,
                                                                                                                                                        price: items.reduce(function (sum, item) {
                                                                                                                                                            return sum + parseFloat(item.price)
                                                                                                                                                        }, 0).toFixed(2) ?? '',
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            })

                                                                                                                                            setValues({
                                                                                                                                                ...values,
                                                                                                                                                lots: lots,
                                                                                                                                                price: lots.reduce(function (sum, lot) {
                                                                                                                                                    return sum + parseFloat(lot.price)
                                                                                                                                                }, 0).toFixed(2) ?? ''
                                                                                                                                            })
                                                                                                                                        },
                                                                                                                                    }}
                                                                                                                                    endAdornment={values.currency ?
                                                                                                                                        <InputAdornment
                                                                                                                                            position="end">{currencies.find(currency => currency.id === values.currency).code === 'RUB' ? '₽' : '₸'}</InputAdornment> : null}
                                                                                                                                    component={CustomTextField}
                                                                                                                                />
                                                                                                                            </Grid>
                                                                                                                            {!values?.is_unit_price ? <Grid
                                                                                                                                item
                                                                                                                                xs={2}
                                                                                                                                className={classes.column}
                                                                                                                            >
                                                                                                                                <Field
                                                                                                                                    fullWidth
                                                                                                                                    name={`lots.${index}.items.${i}.price`}
                                                                                                                                    type="number"
                                                                                                                                    step={0.01}
                                                                                                                                    label="Сумма"
                                                                                                                                    inputProps={{
                                                                                                                                        min: 0
                                                                                                                                    }}
                                                                                                                                    disabled
                                                                                                                                    endAdornment={values.currency ?
                                                                                                                                        <InputAdornment
                                                                                                                                            position="end">{currencies.find(currency => currency.id === values.currency).code === 'RUB' ? '₽' : '₸'}</InputAdornment> : null}
                                                                                                                                    component={CustomTextField}
                                                                                                                                />
                                                                                                                            </Grid>: null}
                                                                                                                        </Grid>
                                                                                                                    </Grid>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                            <Grid item>
                                                                                                                <IconButton
                                                                                                                    size={"small"}
                                                                                                                    onClick={() => {
                                                                                                                        if (values.lots[index].items[i].standard) {
                                                                                                                            setStandards(standards.filter((standard) => (standard !== values.lots[index].items[i].standard.id)))
                                                                                                                        }

                                                                                                                        if (categories.find((el) => {
                                                                                                                            return (el.id === category?.id) && (getGenericCategories().indexOf(el.id) !== -1)
                                                                                                                        })) {
                                                                                                                            if (generics[index]) {
                                                                                                                                if (generics[index].count - 1) {
                                                                                                                                    setGenerics({
                                                                                                                                        ...generics, ...{
                                                                                                                                            [index]: {
                                                                                                                                                value: generics[index].value,
                                                                                                                                                count: generics[index].count - 1
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    })
                                                                                                                                } else if (values.lots[index].items[i].standard) {
                                                                                                                                    const tmp = {}
                                                                                                                                    Object.keys(generics).forEach(key => {
                                                                                                                                        if (parseInt(key) !== parseInt(index)) {
                                                                                                                                            tmp[key] = generics[key];
                                                                                                                                        }
                                                                                                                                    });
                                                                                                                                    setGenerics(tmp)
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }

                                                                                                                        const lots = values.lots.map((lot, idx) => {
                                                                                                                            let items = lot.items

                                                                                                                            if (idx === index) {
                                                                                                                                items = items.map((item, j) => {
                                                                                                                                    return (j === i) ? {
                                                                                                                                        ...item,
                                                                                                                                        ...{
                                                                                                                                            price: 0
                                                                                                                                        }
                                                                                                                                    } : item
                                                                                                                                })
                                                                                                                            }

                                                                                                                            return {
                                                                                                                                ...lot,
                                                                                                                                ...{
                                                                                                                                    items: items,
                                                                                                                                    price: items.reduce(function (sum, item) {
                                                                                                                                        return sum + parseFloat(item.price)
                                                                                                                                    }, 0).toFixed(2) ?? ''
                                                                                                                                }
                                                                                                                            }
                                                                                                                        })

                                                                                                                        setValues({
                                                                                                                            ...values,
                                                                                                                            lots: lots,
                                                                                                                            price: lots.reduce(function (sum, lot) {
                                                                                                                                return sum + parseFloat(lot.price)
                                                                                                                            }, 0).toFixed(2) ?? '',
                                                                                                                        })

                                                                                                                        arrayHelpersItems.remove(i)
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Clear/>
                                                                                                                </IconButton>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                ))}
                                                                                                <Grid item xs={8}>
                                                                                                    <Grid
                                                                                                        container
                                                                                                        direction="row"
                                                                                                        justify="space-between"
                                                                                                        alignItems="flex-start"
                                                                                                        spacing={2}
                                                                                                    >
                                                                                                        <Grid item
                                                                                                              xs={8}>
                                                                                                            <Grid
                                                                                                                container
                                                                                                                direction="column"
                                                                                                                justify="flex-start"
                                                                                                                alignItems="flex-start"
                                                                                                            >
                                                                                                                <Grid
                                                                                                                    item>
                                                                                                                    <Submit
                                                                                                                        size="small"
                                                                                                                        variant="contained"
                                                                                                                        component="label"
                                                                                                                        color="primary"
                                                                                                                        endIcon={
                                                                                                                            <AddIcon/>}
                                                                                                                        onClick={() => {
                                                                                                                            arrayHelpersItems.push({
                                                                                                                                standard: '',
                                                                                                                                amount: '',
                                                                                                                                average_price: '',
                                                                                                                                unit: '',
                                                                                                                                price: '',
                                                                                                                                expiration_date: null,
                                                                                                                                expiration_date_type: ((getCategoriesWithWarrantyPeriod().indexOf(category?.id) !== -1) ? 'warranty_period' : 'expiration_date'),
                                                                                                                                expiration_date_number: ''
                                                                                                                            })
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        Добавить
                                                                                                                        объект
                                                                                                                        закупки
                                                                                                                    </Submit>
                                                                                                                </Grid>
                                                                                                                {(errors?.lots?.[index]?.items?.length && !values?.lots?.[index]?.items.length) ? (
                                                                                                                    <Grid
                                                                                                                        item>
                                                                                                                        <Typography
                                                                                                                            className={classes.error}>
                                                                                                                            Для
                                                                                                                            публикации
                                                                                                                            создайте
                                                                                                                            объект
                                                                                                                            закупки!
                                                                                                                        </Typography>
                                                                                                                    </Grid>
                                                                                                                ) : null}
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                        {(values.lots[index].items.length > 1) ? (
                                                                                                            <Grid item
                                                                                                                  xs={2}>
                                                                                                                <Field
                                                                                                                    fullWidth
                                                                                                                    name={`lots.${index}.price`}
                                                                                                                    label="Итого"
                                                                                                                    type="number"
                                                                                                                    step={0.01}
                                                                                                                    inputProps={{min: 0}}
                                                                                                                    disabled
                                                                                                                    endAdornment={values.currency ?
                                                                                                                        <InputAdornment
                                                                                                                            position="end">{currencies.find(currency => currency.id === values.currency).code === 'RUB' ? '₽' : '₸'}</InputAdornment> : null}
                                                                                                                    component={CustomTextField}
                                                                                                                />
                                                                                                            </Grid>
                                                                                                        ) : null}
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        )}/>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Group>
                                                                    ))}
                                                                </Group>
                                                            )}/>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item className={classes.footer}>
                                                    <Grid container direction="column" justify="center"
                                                          alignItems="stretch" spacing={1}>
                                                        <Grid item className={classes.footerContent}>
                                                            <Grid container className={classes.footerContainer}
                                                                  direction="row" justify="space-between"
                                                                  alignItems="center" spacing={2}>
                                                                <Grid item>
                                                                    {values.price ? (
                                                                        <Typography>
                                                                            Итого: {values.currency ? parseFloat(values?.is_unit_price ? values.lots.reduce((total, lot) => total + (lot?.max_contract_price ?? 0), 0) : values.price).toLocaleString('ru-RU', {
                                                                            style: 'currency',
                                                                            currency: currencies.find(currency => currency.id === values.currency).code
                                                                        }).replace('KZT', '₸') : parseFloat(values.price)}</Typography>
                                                                    ) : null}
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container direction="row"
                                                                          justify="flex-end" alignItems="center"
                                                                          spacing={2}>
                                                                        <Grid item>
                                                                            <Submit
                                                                                disableElevation
                                                                                variant="contained"
                                                                                color="primary"
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    setFieldValue('status', null)
                                                                                    submitForm().then(() => {
                                                                                    })
                                                                                }}
                                                                                disabled={isSubmitting}
                                                                            >
                                                                                Создать
                                                                            </Submit>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }
    return null
}
