import React from "react"
import {Link} from "react-router-dom"
import format from "date-fns/format"
import {ru} from "date-fns/locale"
import clsx from 'clsx'

import {
    Grid,
    makeStyles,
    Step,
    StepConnector,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
    withStyles
} from "@material-ui/core"
import {Check} from '@material-ui/icons'

import {Submit} from "../../../../App/components/Button/Submit"

const useStyles = makeStyles(theme => ({
    download: {
        "color": "#485868"
    },
    link: {
        "display": "flex",
        "line-height": "31px",
        "color": "#485868",
    }
}))

const Connector = withStyles({
    alternativeLabel: {
        "top": "10",
        "left": "calc(-50% + 16px)",
        "right": "calc(50% + 16px)"
    },
    active: {
        '& $line': {
            "border-color": "#616161"
        },
    },
    completed: {
        '& $line': {
            "border-color": "#616161"
        },
    },
    line: {
        "border-color": "#eaeaf0",
        "border-top-width": "3",
        "border-radius": "1"
    },
    vertical: {
        "padding": "0 0 8px",
        "margin-left": "12px"
    }
})(StepConnector);

const useStepIconStyles = makeStyles({
    root: {
        "color": "#eaeaf0",
        "display": "flex",
        "height": "22",
        "align-items": "center"
    },
    active: {
        "color": "#616161"
    },
    circle: {
        "width": 12,
        "height": 12,
        "border-radius": '50%',
        "background-color": 'currentColor',
        "margin-left": "6px",
        "margin-right": "6px"
    },
    completed: {
        "color": "#616161",
        "z-index": "1",
        "font-size": "18"
    }
});

function StepIcon(props) {
    const classes = useStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
        </div>
    );
}

export const Log = (props) => {
    const classes = useStyles()
    let { lot } = props

    const getCompleteMessage = (lot, index, item) => {
        if (lot.log.hasOwnProperty(index - 1)){
            return  `(${format(new Date(lot.log[index - 1].created), 'H:mm PP' , {locale: ru})})`
        }
        return  null;
    }

    const getStep = (index, item, lot) => {
        let content = null

        switch (parseInt(item.status.index)) {
            case 3:
                content = (
                    <Step key={item.id} completed={!!index} >
                        <StepLabel StepIconComponent={StepIcon}>{item.status.name} {getCompleteMessage(lot, index, item)}</StepLabel>
                        <StepContent>
                            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                <Grid item>
                                    <Typography variant="body2" gutterBottom>{item.comment}</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                                        <Grid item>
                                            <Typography variant="body2" gutterBottom>Заказчик: {lot.order.company.legal_detail.name}</Typography>
                                            <Typography variant="body2" gutterBottom>{(lot.order.company.legal_detail.city.region.country.code === 'ru') ? `ИНН: ${lot.order.company.legal_detail.inn}` : `БИН: ${lot.order.company.legal_detail.bin}`} Адрес: {lot.order.company.legal_detail.legal_address}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Submit
                                                variant="contained"
                                                color="primary"
                                                type="button"
                                                component={Link}
                                                to={`/contracts?id=${lot.winner.contract.id}`}
                                            >
                                                Договор
                                            </Submit>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </StepContent>
                    </Step>
                )
                break
            default:
                content = (
                    <Step key={item.id} completed={!!index}>
                        <StepLabel StepIconComponent={StepIcon}>{item.status.name} {`(${format(new Date(item.created), 'H:mm PP',  {locale: ru})})`}</StepLabel>
                        <StepContent>
                            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                <Grid item>
                                    <Typography variant="body2" gutterBottom>{item.comment}</Typography>
                                </Grid>
                            </Grid>
                        </StepContent>
                    </Step>
                )
        }

        return content
    }

    return (
        <Grid item className={classes.content}>
            <Stepper activeStep={0} orientation="vertical" nonLinear={true} connector={<Connector />}>
                {lot.log.map((item, index) => (
                    getStep(index, item, lot)
                ))}
            </Stepper>
        </Grid>
    )
}
