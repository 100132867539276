import React from "react"

import {AddContent} from "./AddContent";
import {Menu} from "../../../../Menu";
import {Menu as AppMenu} from "../../../../../App/Menu";
import {Content as AppContent} from "../../../../../App/Content";

export const Add = (props) => {
    return <React.Fragment>
        <AppMenu
            Component={Menu}
            {...props}
        />
        <AppContent
            Component={AddContent}
            {...props}
        />
    </React.Fragment>
}
