import React, {useState} from "react"
import {useSelector} from "react-redux"

import {
    Grid,
    makeStyles
} from "@material-ui/core"

import {purchase as rules} from "../../../helpers/permissions/rules"
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon";
import {FileCopy as FileCopyIcon} from "@material-ui/icons";
import {Dialog} from "./Copy/Dialog";

const useStyles = makeStyles(() => ({
    button: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    }
}))

export const Copy = (props) => {
    const classes = useStyles()

    const [dialog, setDialog] = useState(false)

    const { items, onClick} = props

    const {account} = useSelector(state => state.account)

    return  (
        <Grid item>
            <ButtonIcon
                disabled={!items.length || !rules.copy.includes(account.group)}
                onClick={() => setDialog(true)}
                title='Копировать'
                children={<FileCopyIcon/>}
                className={classes.button}
            />
            {dialog &&
                <Dialog
                    open={dialog}
                    onClose={() => {
                        setDialog(false)
                        onClick()
                    }}
                    purchases={items.map(el => ({
                        id: el.id,
                        number: el.number,
                        name: el.name,
                        category: (el.category.id === el.category.index) ? el.category.name : `${el.category.name} (${el.category.root.name})`
                    }))}
                    category={items.reduce(
                        (index, purchase) => {
                            return (index === null) ? purchase.category.index : (index ? ((index === purchase.category.index) ? index : purchase.category.index) : purchase.category.index)
                        }, null
                    )}
                />
            }
        </Grid>
    )
}
