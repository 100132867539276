export default function operator(state = {}, action) {
    switch (action.type) {
        case "OPERATOR_LOTS_SUCCESS":
            return {
                lots: action.payload.data,
                meta: action.payload.meta,
            }
        case "OPERATOR_LOTS_FAILURE":
            return {}
        case "LOT_CONFIRMED_SUCCESS":
            return {
                lots: state.lots.filter(lot => lot.id !== action.payload.lot.id),
                meta: state.meta,
            }
        case "LOT_CONFIRMED_FAILURE":
            return state
        default:
            return state
    }
}
