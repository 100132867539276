import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce} from 'use-debounce'
import {IntegratedSummary} from '@devexpress/dx-react-grid'
import clsx from "clsx"

import {Button, Grid, InputAdornment, makeStyles, TextField} from "@material-ui/core"
import {GetApp as GetAppIcon, Search as SearchIcon} from "@material-ui/icons"
import {green} from "@material-ui/core/colors"
import {Table} from "@devexpress/dx-react-grid-material-ui";

import {PurchasePlanActions} from "../../../actions/purchasePlan"
import {ColumnChooser} from "../../../../App/components/Table/ColumnChooser"
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon"
import {getMonthName} from "../../../../App/helpers/date"
import {SettingsActions} from "../../../../Settings/actions/settings"
import {SettingsActions as PlanningSettingsActions} from "../../../actions/settings"
import * as AppStorage from "../../../../App/helpers/storage"
import {DownloadActions} from "../../../../Download/actions/download";
import format from "date-fns/format";
import {CustomTable} from "../../../../App/components/Table/Table";
import {TableHeader} from "../../../../App/components/Table/TableHeader";
import {history} from "../../../../App/helpers/history"
import {Approve as ApproveButton} from "../Buttons/Approve";
import {Decline as DeclineButton} from "../Buttons/Decline";
import {localState} from "../../../../App/components/LocalState";
import {status} from "../../../dics/Plan/status";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.planningContent.width,
        padding: theme.planningContent.padding,
        margin: theme.planningContent.margin,
    },
    container: {
        "background-image": "linear-gradient(to bottom,#f6f6f6,#eee 85%,#b1b1b1)",
        "width": "100%",
        "height": "calc(100vh - 256px)"
    },
    filterLabel: {
        "background": "#fff",
        "margin-bottom": "0 !important",
        "margin-top": "5px !important",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        },
        "& input": {
            padding: "6px 15px 7px"
        }
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    searchLabel: {},
    searchInput: {
        "width": "calc(100% - 83px)"
    },
    searchButton: {
        "margin-bottom": "10px"
    },
    fullWidth: {
        "width": "100%"
    },
    active: {
        'height': '41px',
        'background-color': green[100],
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    loading: {
        'height': '41px',
        'background-color': '#fff',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    button: {
        "height": "45px",
    },
    iconButton: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    },
    inputContentMain: {
        "width": "100%",
        "height": theme.planningContent.height,
        "min-height": theme.planningContent.height,
        "overflow": "auto",
        "overflow-x": "hidden"
    },
}))

const columns = [
    {name: 'planning_year', title: 'Планируемый год', filter: 'year'},
    {name: 'category', title: 'Категория', filter: 'categories'},
    {name: 'name', title: 'Наименование'},
    {name: 'status', title: 'Статус', filter: 'status'},
    {name: 'month', title: 'Планируемый месяц проведения', filter: 'month'},
    {name: 'purchases_count', title: 'Кол-во закупок'},
    {name: 'average_price_total', title: 'НМЦ (планируемая)'},
    {name: 'average_price_approved', title: 'НМЦ (утв.)'},
    {name: 'average_price_unapproved', title: 'НМЦ (неутв.)'},
    {name: 'railRoad', title: 'ЖД', filter: 'rail_roads'},
    {name: 'region', title: 'Макрорегион', filter: 'regions'},
    {name: 'company', title: 'ЧУЗ', filter: 'companies'},
    {name: 'edit_request_status', title: 'Статус корректировки', filter: 'edit_request_status'},
    {name: 'edit_request_comment', title: 'Причина'},
    {name: 'edit_request_files', title: 'Файл'},
    {name: 'edit_request_status_date', title: 'Дата запроса корректировки'}
]

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [purchasePlansEditRequests, setPurchasePlansEditRequests] = useState(localState)
    const {filter} = useSelector(state => state.filters['planning_purchase_plans_edit_requests'])
    const {planningSettings} = useSelector(state => state)
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)

    const [selection, setSelection] = useState([])
    const [selectionItems, setSelectionItems] = useState([])
    const [selectionPage, setSelectionPage] = useState({})

    const [initialize, setInitialize] = useState(false);
    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
    const [tableRef, setTableRef] = useState(null)
    const [loading, setLoading] = useState(false)

    const [tableColumnExtensions] = useState([
        {columnName: 'total_purchases', align: 'right'},
        {columnName: 'total_average_price', align: 'right'},
    ]);
    const [totalSummaryItems] = useState([
        {columnName: 'purchases_count', type: 'total_purchases'},
        {columnName: 'average_price_total', type: 'total_average_price'},
    ]);

    const messages = {
        total_purchases: 'Кол-во',
        total_average_price: 'Итого',
    };

    const [columnOrder, setColumnOrder] = useState([
        'planning_year',
        'category',
        'name',
        'status',
        'month',
        'purchases_count',
        'average_price_total',
        'average_price_approved',
        'average_price_unapproved',
        'railRoad',
        'region',
        'company',
        'edit_request_status',
        'edit_request_comment',
        'edit_request_files',
        'edit_request_status_date'
    ])

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'planning_year', width: 150},
        {columnName: 'category', width: 200},
        {columnName: 'name', width: 200},
        {columnName: 'status', width: 125},
        {columnName: 'month', width: 125},
        {columnName: 'purchases_count', width: 125},
        {columnName: 'average_price_total', width: 155},
        {columnName: 'average_price_approved', width: 125},
        {columnName: 'average_price_unapproved', width: 125},
        {columnName: 'railRoad', width: 125},
        {columnName: 'region', width: 125},
        {columnName: 'company', width: 150},
        {columnName: 'edit_request_status', width: 150},
        {columnName: 'edit_request_comment', width: 150},
        {columnName: 'edit_request_files', width: 150},
        {columnName: 'edit_request_status_date', width: 200}
    ])

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...((filter.hasOwnProperty('status') && !!filter.status.length) ? {status: filter.status} : {}),
            ...((filter.hasOwnProperty('categories') && !!filter.categories.length) ? {categories: filter.categories} : {}),
            ...((filter.hasOwnProperty('companies') && !!filter.companies.length) ? {companies: filter.companies} : {}),
            ...((filter.hasOwnProperty('rail_roads') && !!filter.rail_roads.length) ? {rail_roads: filter.rail_roads} : {}),
            ...((filter.hasOwnProperty('year') && !!filter.year.from.length) ? {planning_year_from: filter.year.from} : {}),
            ...((filter.hasOwnProperty('year') && !!filter.year.to.length) ? {planning_year_to: filter.year.to} : {}),
            ...((filter.hasOwnProperty('edit_request_status') && !!filter.edit_request_status.length) ? {edit_request_status: filter.edit_request_status} : {}),
            ...((filter.hasOwnProperty('month') && !!filter.month.length) ? {month: filter.month} : {}),
            ...(searchRequest ? {search: searchRequest} : {})
        }
    }

    const getPlanningSettings = async () => {
        await dispatch(PlanningSettingsActions.settings())
    }

    const getPurchasePlansEditRequests = async () => {
        let params = {
            limit: rowsPerPage,
            page: page,
            editRequest: true,
            ...getParams()
        }
       return await dispatch(PurchasePlanActions.purchasePlansEditRequests(params))
    }

    useEffect(() => {
        if (!loading && initialize) {
            getPurchasePlansEditRequests().then(response => {
                setPurchasePlansEditRequests(response)

                let numbers = []

                if (selectionPage.hasOwnProperty(page)) {
                    response.data.forEach((item, index) => {
                        if (selectionPage[page].find(id => (id === item.id))) {
                            numbers.push(index)
                        }
                    })
                }

                setSelection(numbers)

                setLoading(true)
                tableRef && tableRef.current && tableRef.current.scrollIntoView()
            })
        }
        // eslint-disable-next-line
    }, [dispatch, initialize, loading])

    useEffect(() => {
        setLoading(false)
        // eslint-disable-next-line
    }, [searchRequest, page, rowsPerPage, filter])

    useEffect(() => {
        if (!initialize) {
            getPlanningSettings().then(() => {})

            dispatch(SettingsActions.table('planning_purchase_plans_edit_requests')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                dispatch({type: "FILTER_CLEAR"})
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'planning_purchase_plans_edit_requests',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    const summaryCalculator = (type, rows, getValue) => {
        if (type === 'total_purchases') {
            return (parseFloat(purchasePlansEditRequests.data.reduce((sum, plan) => {
                return sum + plan.purchases_count
            }, 0))).toLocaleString('ru-RU');
        }
        if (type === 'total_average_price') {
            return (parseFloat(purchasePlansEditRequests.data.reduce((sum, plan) => {
                return sum + (isNaN(parseFloat(plan.average_price_total)) ? 0 : parseFloat(plan.average_price_total))
            }, 0))).toLocaleString('ru-RU', {style: 'currency', currency: 'RUB'});
        }
        return IntegratedSummary.defaultCalculator(type, rows, getValue);
    };

    const getFiles = (files) => {
        return files.map(file => {
            return <Grid item>
                <Button
                    className={classes.download}
                    endIcon={<GetAppIcon/>}
                    llll={'ssss'}
                    href={process.env.REACT_APP_HOST_API + `/planning/purchase-plan/${file.purchase_plan_id}/file/${file.id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                >
                    {file.description}
                </Button>
            </Grid>
        });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1)
    }

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: 'PURCHASE_PLAN_EDIT_REQUESTS_FILTER', payload: {limitRows: newRowsPerPage}})
    }

    return initialize ? (
            <Grid item className={classes.content}>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            id="search"
                            variant="standard"
                            name={'search'}
                            placeholder={`Поиск: наименование объекта закупки, номер закупки`}
                            fullWidth={true}
                            margin={'normal'}
                            onChange={(event) => {
                                setSearch(event.target.value)
                            }}
                            classes={{
                                root: clsx(classes.filterLabel, classes.searchLabel)
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon style={{color: '#c1c1c1'}}/>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                            <Grid item xs={12}>
                                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                            <ApproveButton
                                                items={selectionItems}
                                                settings={planningSettings}
                                                onClick={() => {
                                                    setSelection([])
                                                    setSelectionItems([])
                                                    setSelectionPage({})
                                                    setLoading(false)
                                                }}
                                            />
                                            <DeclineButton
                                                style={{height: '35px'}}
                                                items={selectionItems}
                                                settings={planningSettings}
                                                onClick={() => {
                                                    setSelection([])
                                                    setSelectionItems([])
                                                    setSelectionPage({})
                                                    setLoading(false)
                                                }}
                                            />
                                            <ApproveButton
                                                items={selectionItems}
                                                settings={planningSettings}
                                                onClick={() => {
                                                    setSelection([])
                                                    setSelectionItems([])
                                                    setSelectionPage({})
                                                    setLoading(false)
                                                }}
                                                correction={true}
                                            />
                                            <DeclineButton
                                                items={selectionItems}
                                                settings={planningSettings}
                                                onClick={() => {
                                                    setSelection([])
                                                    setSelectionItems([])
                                                    setSelectionPage({})
                                                    setLoading(false)
                                                }}
                                                correction={true}
                                            />
                                            <Grid item>
                                                <ButtonIcon
                                                    type="button"
                                                    size="small"
                                                    disabled={!purchasePlansEditRequests.meta.total}
                                                    onClick={() => {
                                                        dispatch(DownloadActions.create({
                                                            type: 'planning_adjustment_plans',
                                                            columns: columnOrder.filter(name => (hiddenColumnNames.indexOf(name) < 0)),
                                                            params: {
                                                                order: (filter.sort.name && filter.sort.direction) ? `${filter.sort.name}, ${filter.sort.direction}` : `created, desc`,
                                                                type: 'planning_adjustment_plans',
                                                                ...Object.entries(getParams()).reduce((result, [key, value]) => {
                                                                    result[key] = Array.isArray(value) ? value.join(',') : value

                                                                    return result
                                                                }, {})
                                                            }
                                                        }))
                                                    }}
                                                    children={<GetAppIcon/>}
                                                    title='Скачать таблицу'
                                                    className={classes.iconButton}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                    </Grid>
                    <CustomTable
                        meta={purchasePlansEditRequests.meta}
                        tableName={'planning_purchase_plans_edit_requests'}
                        rows={purchasePlansEditRequests.data.map(purchasePlansEditRequest => ({
                            id: purchasePlansEditRequest.id,
                            planning_year: purchasePlansEditRequest.planning_year,
                            category: purchasePlansEditRequest.category?.name,
                            name: purchasePlansEditRequest.name,
                            status: purchasePlansEditRequest.status.name,
                            purchases_count: !purchasePlansEditRequest.purchases_count ? 0 : purchasePlansEditRequest.purchases_count,
                            month: !purchasePlansEditRequest.min_demand_start_date ? '-' : getMonthName(new Date(purchasePlansEditRequest.min_demand_start_date)),
                            average_price_total: parseFloat(purchasePlansEditRequest.average_price_total === null ? 0 : purchasePlansEditRequest.average_price_total).toLocaleString('ru-RU', {
                                style: 'currency',
                                currency: 'RUB'
                            }),
                            average_price_approved: parseFloat(purchasePlansEditRequest.average_price_approved === null ? 0 : purchasePlansEditRequest.average_price_approved).toLocaleString('ru-RU', {
                                style: 'currency',
                                currency: 'RUB'
                            }),
                            average_price_unapproved: parseFloat(purchasePlansEditRequest.average_price_unapproved === null ? 0 : purchasePlansEditRequest.average_price_unapproved).toLocaleString('ru-RU', {
                                style: 'currency',
                                currency: 'RUB'
                            }),
                            zero: purchasePlansEditRequest.zero,
                            railRoad: purchasePlansEditRequest.company?.railRoad?.name,
                            region: purchasePlansEditRequest.company?.region?.name,
                            company: purchasePlansEditRequest.company.name,
                            edit_request_status: purchasePlansEditRequest.editRequestStatus ? purchasePlansEditRequest.editRequestStatus.name : '',
                            edit_request_status_index: purchasePlansEditRequest.editRequestStatus?.index,
                            edit_request_comment: purchasePlansEditRequest.edit_request_comment,
                            edit_request_files: purchasePlansEditRequest.files ? getFiles(purchasePlansEditRequest.files) : null,
                            edit_request_status_date: !purchasePlansEditRequest.edit_request_status_date ? '-' : format(new Date(purchasePlansEditRequest.edit_request_status_date), 'dd.MM.yyyy'),
                        }))}
                        columns={columns}
                        page={{page: page, setPage: handleChangePage, rowsPerPage: rowsPerPage, handlePageSizeChange: handlePageSizeChange}}
                        selection={{selection: selection, setSelection: setSelection}}
                        totalSummaryItems={totalSummaryItems}
                        summaryCalculator={summaryCalculator}
                        setTableRef={setTableRef}
                        tableColumnExtensions={tableColumnExtensions}
                        columnsSettings={{columnOrder: columnOrder, setColumnOrder: setColumnOrder, setColumnWidths: setColumnWidths, columnWidths: columnWidths, hiddenColumnNames: hiddenColumnNames, setHiddenColumnNames: setHiddenColumnNames}}
                        tableHeader={TableHeader}
                        filterActionType={'PURCHASE_PLAN_EDIT_REQUESTS_FILTER'}
                        getValues={PurchasePlanActions.purchaseFilter}
                        messages={messages}
                        onSelectionChange={(numbers) => {
                            setSelection(numbers)
                            const data = purchasePlansEditRequests.data.filter((item, index) => (numbers.find(key => (key === index)) !== undefined)).map(item => item)
                            setSelectionItems([
                                ...data,
                                ...selectionItems.filter(item => !purchasePlansEditRequests.data.find(el => el.id === item.id))
                            ])
                            setSelectionPage(!!data.length ? {...selectionPage, [page]: data.map(item => item.id)} : Object.fromEntries(
                                Object.entries(selectionPage).filter(key => (parseInt(key) !== parseInt(page))).map(([key, value]) => [key, value])
                            ))
                        }}
                        classInputContent={classes.inputContentMain}
                        rowComponentSelection={(props) => {
                            const {tableRow, children, highlighted, onToggle} = props;
                            const {row} = tableRow;

                            let timer = null;
                            let prevent = false;

                            const handleClick = () => {
                                timer = setTimeout(() => {
                                    if (!prevent) {
                                        if (!window.getSelection().toString()) {
                                            onToggle()
                                        }
                                    }
                                    prevent = false;
                                }, 250)
                            }

                            const handleDoubleClick = () => {
                                if (!row.zero || (row.zero && [status.CORRECTION_APPROVED, status.CORRECTION_REJECTED].includes(row.edit_request_status_index))) {
                                    clearTimeout(timer);
                                    prevent = true;
                                    history.push(`/planning/purchase-plan/${row.id}`)
                                }
                            }

                            return (
                                <Table.Row
                                    tableRow={tableRow}
                                    children={children}
                                    onClick={handleClick}
                                    onDoubleClick={handleDoubleClick}
                                    className={highlighted ? classes.active : ((row.loaded === 'Загружен') ? classes.default : classes.loading)}
                                    row={row}
                                />
                            )
                        }}
                        rowComponent={({row, tableRow, children}) => (
                            <Table.Row
                                tableRow={tableRow}
                                children={children}
                                className={classes.default}
                               row={row}
                            />
                        )}
                        filters={{
                            status: {
                                name: 'status',
                                type: 'values',
                            },
                            planning_year: {
                                name: 'year',
                                type: 'year',
                            },
                            category: {
                                name: 'categories',
                                type: 'values',
                            },
                            railRoad: {
                                name: 'rail_roads',
                                type: 'values',
                            },
                            region: {
                                name: 'regions',
                                type: 'values',
                            },
                            company: {
                                name: 'companies',
                                type: 'values',
                            },
                            month: {
                                name: 'month',
                                type: 'month',
                            },
                            edit_request_status: {
                                name: 'edit_request_status',
                                type: 'values',
                            },
                            edit_request_comment: {
                                nullButton: true
                            },
                            edit_request_files: {
                                nullButton: true
                            }
                        }}
                    />
            </Grid>
        </Grid>
    ) : null
}
