import React from 'react'
import {useSelector} from "react-redux"
import {TableHeaderRow} from "@devexpress/dx-react-grid-material-ui"

import {Filter} from "../../../Planning/components/Filter";
import {Values} from "../../../Planning/components/Filter/Values";
import {Period} from "../../../Planning/components/Filter/Period";
import {Month} from "../../../Planning/components/Filter/Month";
import {Source} from "../../../Planning/components/Filter/Source";
import {Purchase} from "../../../Planning/components/Filter/Purchase";
import {ItemActionType} from "../../../Planning/components/Filter/ItemActionType";

export const TableHeader = (props) => {
    const {column, children, tablename, filteractiontype, getvalues, filters, nullbutton, custombutton, ...restProps} = props
    const {filter} = useSelector(state => state.filters[tablename])

    let button = custombutton ?? null;
    let nullButton = nullbutton ?? false;
    let type = null
    let name = null
    let active = false

    if (filters && !button && !nullButton) {
        switch (filters[column.name]?.type) {
            case 'values':
                type = props => <Values {...props} getValues={getvalues} title={column}/>
                name = filters[column.name].name
                active = (filter.hasOwnProperty(name) && !!filter[name].length)
                break;
            case 'year':
                type = props => <Period {...props} title={column}/>
                name = filters[column.name].name
                active = (filter.hasOwnProperty(name) && (!!filter[name].from.length || !!filter[name].to.length))
                break;
            case 'month':
                type = props => <Month {...props} title={column}/>
                name = filters[column.name].name
                active = (filter.hasOwnProperty(name) && !!filter[name].length)
                break;
            case 'source':
                type = props => <Source {...props} getSources={getvalues}/>
                name = filters[column.name].name
                active = (filter.hasOwnProperty(name) && !!filter[name].length)
                break;
            case 'purchase_number':
                type = props => <Purchase {...props} />
                name = filters[column.name].name
                active = (filter.hasOwnProperty(name) && !!filter[name].length)
                break;
            case 'action_type':
                type = props => <ItemActionType {...props} getActionType={getvalues}/>
                name = filters[column.name].name
                active = (filter.hasOwnProperty(name) && !!filter[name].length)
                break;
            default:
                break;
        }

        button = <Filter
            filter={filter}
            active={((filter.sort.name === column.name) || active)}
            name={column.name}
            {...(type ? {Content: type} : null)}
            filterActionType={filteractiontype}
        />
    }

    if (nullButton) {
        button = null
    }

    return (
        <TableHeaderRow.Content
            column={column}
            {...restProps}
            style={{
                justifyContent: 'space-between'
            }}
        >
            {children}
            {button}
        </TableHeaderRow.Content>
    )
}
