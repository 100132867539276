import React, {useEffect, useState} from 'react'
import {makeStyles, TextField} from "@material-ui/core"
import ChevronRight from "@material-ui/icons/ChevronRight"
import IconButton from "@material-ui/core/IconButton"

const useStyles = makeStyles(theme => ({
    page: {
        width: '50px'
    },
    pageSizeSelector: {
        ...theme.typography.body2,
        display: 'flex',
        alignItems: 'center'
    },
    label: {
        paddingRight: theme.spacing(3)
    },
    '@media (max-width: 768px)': {
        label: {
            display: 'none'
        },
        pageSizeSelector: {
            paddingRight: theme.spacing(2)
        }
    }
}))

export const PageSelector = ({
     currentPage,
     totalPages,
     onCurrentPageChange,
     totalCount
}) => {
    const classes = useStyles()

    const [page, setPage] = useState(currentPage)

    useEffect(() => {
       setPage(currentPage)
    }, [currentPage])

    return (
        <div className={classes.pageSizeSelector}>
            <TextField className={classes.page} label="Стр." value={page} onChange={e => setPage(parseInt(e.target.value))} />
            <IconButton
                color="inherit"
                disabled={((page === (totalPages + 1)) || (totalCount === 0)) || !page || (page > totalPages) || (page === 0) || (page === currentPage)}
                onClick={() => onCurrentPageChange(page)}
                aria-label="Next"
            >
                <ChevronRight />
            </IconButton>
        </div>
    )
}
