import {HttpService} from "../../App/services/http"

export const CartService = {
    count
}

function count() {
    return HttpService.get(`/cart/items/count`)
        .then(response => {
            return response.count
        })
}
