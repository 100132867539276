import React from "react"
import {useSelector} from "react-redux"

import {
    Grid,
    makeStyles
} from "@material-ui/core"

import {history} from "../../../../App/helpers/history"
import {Submit} from "../../../../App/components/Button/Submit"
import {plan as rules} from "../../../helpers/permissions/rules"

const useStyles = makeStyles(theme => ({
    button: {
        "height": "45px",
    }
}))

export const Edit = (props) => {
    const classes = useStyles()

    const { plan, item, disabled = false } = props

    const {account} = useSelector(state => state.account)

    return rules.edit.hasOwnProperty(account.group) ? (
        <Grid item>
            <Submit
                className={classes.button}
                disableElevation
                disabled={
                    disabled ||
                    !rules.edit.hasOwnProperty(account.group) ||
                    !plan ||
                    function () {
                        if (rules.edit[account.group].hasOwnProperty(plan.status.index) && !plan.lock) {
                            if (rules.edit[account.group][plan.status.index].length) {
                                return !rules.edit[account.group][plan.status.index].includes(plan.editRequestStatus?.index)
                            }

                            return false
                        }

                        return true
                    }()
                }
                variant="contained"
                color="primary"
                type="button"
                style={{height: '35px'}}
                onClick={() => {
                    if (item.purchase_lot_id) {
                        history.push(`/planning/purchase-plan/${plan.id}/purchase/${item.purchase_id}/lot/${item.purchase_lot_id}/purchase-product/${item.key}/edit`)
                    } else {
                        history.push(`/planning/purchase-plan/${plan.id}/item/${item.key}/edit`);
                    }
                }}
            >
                Редактировать ОЗ
            </Submit>
        </Grid>
    ) : null
}
