export default function lot(state = {}, action) {
    switch (action.type) {
        case "LOT":
            return {lot: action.payload}
        case "LOT_FAILURE":
            return {order: state.order}
        case "LOT_CLEAR":
            return {}
        default:
            return state
    }
}