import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import format from "date-fns/format"
import * as Yup from "yup"

import {
    Grid,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@material-ui/core"
import {Add as AddIcon} from "@material-ui/icons"

import {TextFieldWithError as CustomTextField, TextFieldWithError} from "../App/components/Inputs/TextFieldWithError"
import {Submit} from "../App/components/Button/Submit"
import {DemoActions} from "./actions/demo"
import {FileUpload} from "../App/components/Inputs/FileUpload"

const useStyles = makeStyles(theme => ({
    item: {
        "width": "1100px"
    },
    container: {
        "background-color": "#e4e4e4",
        "border-radius": 0,
        "min-height": "100vh",
        "height": "auto"
    },
    content: {
        "padding": "35px",
        "border-top": `5px solid ${theme.palette.primary.dark}`,
        "border-bottom": "5px solid #c1c1c1",
        "border-radius": "0px",
        "box-shadow": "0 5px 20px rgba(0,0,0,.4)"
    },
    fullWidth: {
        "width": "100%"
    },
    input: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase"
    },
    submit: {
        "height": "35px"
    },
    tableContainer: {
        height: "calc(100vh - 720px)",
        overflow: "auto",
    },
    fontWeight: {
        fontWeight: 700
    }
}))

const columns = [
    {
        id: '0',
        label: 'Наименование товар',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '1',
        label: 'Артикул',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '2',
        label: 'Кол-во товара',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '3',
        label: 'Цена',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '4',
        label: 'Ставка НДС',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '5',
        label: 'Сумма',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '6',
        label: 'Кол-во товара из 1С',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '7',
        label: 'Цена из 1С',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '8',
        label: 'Код товара по ТН ВЭД',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '9',
        label: 'Масса брутто',
        format: (value) => value.toLocaleString(),
    }
];

const types = [
    {type: 'complete', name: 'Предоплата'},
    {type: 'partial', name: 'Частичная'},
    {type: 'postponement', name: 'Постоплата'}
]

export const Demo1cGetOrder = () => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { order, orderItems, contract, contractItems, supplier } = useSelector(state => { return {...state.demo}})

    const [page, setPage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [orderRequest, setOrderRequest] = useState(null)

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    useEffect(() => {
        const getOrder = async () => {
            return await dispatch(DemoActions.order({
                inn: orderRequest.inn,
                kpp: orderRequest.kpp,
                number: orderRequest.number,
                limit: rowsPerPage,
                page: page + 1
            }))
        }
        if (orderRequest !== null) {
            getOrder().then(_ => {})
        }
        // eslint-disable-next-line
    }, [dispatch, page, rowsPerPage]);


    return (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.container}>
            <Grid item className={classes.item}>
                <Paper className={classes.content}>
                    <Formik
                        initialValues={{
                            inn: '',
                            kpp: '',
                            number: '',
                        }}
                        validationSchema={Yup.object().shape({
                            inn: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            kpp: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            number: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
                        })}
                        onSubmit={(values, {setSubmitting}) => {
                            setLoading(false)
                            return dispatch(DemoActions.order({
                                inn: values.inn,
                                kpp: values.kpp,
                                number: values.number,
                                limit: rowsPerPage,
                                page: page + 1
                            })).then(
                                (response) => {
                                    setLoading(true)
                                    setOrderRequest({
                                        inn: values.inn,
                                        kpp: values.kpp,
                                        number: values.number,
                                        files: response.files
                                    })
                                    setSubmitting(false);
                                },
                                () => {
                                    setSubmitting(false);
                                }
                            )
                        }}
                    >
                        {({
                              handleSubmit,
                              isSubmitting
                          }) => (
                            <Form>
                                <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                                    <Grid item className={classes.fullWidth}>
                                        <Grid container direction="row" justify="center" alignItems="stretch" spacing={4}>
                                            <Grid item xs={12}>
                                                <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                                    <Grid item>
                                                        <Typography className={classes.title}>
                                                            Запрос заказа
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.input} xs={4}>
                                                <Field
                                                    fullWidth
                                                    name="inn"
                                                    type="text"
                                                    label="ИНН"
                                                    component={TextFieldWithError}
                                                />
                                            </Grid>
                                            <Grid item className={classes.input} xs={4}>
                                                <Field
                                                    fullWidth
                                                    name="kpp"
                                                    type="text"
                                                    label="КПП"
                                                    component={TextFieldWithError}
                                                />
                                            </Grid>
                                            <Grid item className={classes.input} xs={4}>
                                                <Field
                                                    fullWidth
                                                    name="number"
                                                    type="text"
                                                    label="Номер заказа"
                                                    component={TextFieldWithError}
                                                />
                                            </Grid>
                                            <Grid item className={classes.fullWidth}>
                                                <Grid container direction="row" justify="flex-end"
                                                      alignItems="center">
                                                    <Grid item>
                                                        <Submit
                                                            className={classes.submit}
                                                            disableElevation
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={isSubmitting}
                                                            onClick={handleSubmit}
                                                            type="submit"
                                                        >
                                                            Получить информацию о заказе
                                                        </Submit>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container direction="row" justify="flex-start" alignItems="stretch"
                                                      spacing={1}>
                                                    <Grid item xs={4}><span className={classes.fontWeight}>Номер договора:</span> {order ? order.contract_number : null}</Grid>
                                                    <Grid item xs={8}><span className={classes.fontWeight}>Поставщик (Наименование, ИНН, КПП):</span> {order ? `${order.supplier.name}, ${order.supplier.inn}, ${order.supplier.kpp}` : null}</Grid>
                                                    <Grid item xs={4}><span className={classes.fontWeight}>Номер заказа:</span> {order ? order.order_number : null}</Grid>
                                                    <Grid item xs={8}><span className={classes.fontWeight}>Дата и время создания заказа:</span> {(order && order.created) ? format(new Date(order.created), 'dd.MM.yyyy HH:mm') : ''}</Grid>
                                                    <Grid item xs={8}><span className={classes.fontWeight}>Сумма, оплаченная заказчиком:</span> {(order && order.payment_sum_integration) ? parseFloat(order.payment_sum_integration).toLocaleString('ru-RU', {...(order.currency.code ? {style: 'currency', currency: order.currency.code} : {})}).replace('KZT', '₸') : null} </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TableContainer className={classes.tableContainer}>
                                                    <Table stickyHeader aria-label="sticky table">
                                                        <TableHead>
                                                            <TableRow>
                                                                {columns.map((column) => (
                                                                    <TableCell
                                                                        key={column.id}
                                                                    >
                                                                        {column.label}
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {orderItems.data.length > 0 && orderItems.data.map((item, index) => (
                                                                <TableRow hover key={index}>
                                                                    <TableCell>
                                                                        {item.name}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.article_number}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.amount}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {parseFloat(item.price).toLocaleString('ru-RU', {...(order.currency.code ? {style: 'currency', currency: order.currency.code} : {})}).replace('KZT', '₸')}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {(item.tax_percent > 0) ? `${item.tax_percent}%` : 'Без НДС'}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {parseFloat(item.totalPrice).toLocaleString('ru-RU', {...(order.currency.code ? {style: 'currency', currency: order.currency.code} : {})}).replace('KZT', '₸')}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.amount_integration ? Number(item.amount_integration) : null}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.price_integration ? Number(item.price_integration) : null}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.tnved}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.gross_weight}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid item>
                                                    <TablePagination
                                                        rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                                        component='div'
                                                        labelRowsPerPage={'Записей на странице:'}
                                                        count={orderItems.meta.total}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onChangePage={handleChangePage}
                                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                    <Formik
                        initialValues={{
                            inn: '',
                            kpp: '',
                            number: '',
                            sum: '',
                        }}
                        validationSchema={Yup.object().shape({
                            inn: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            kpp: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            number: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            sum: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
                        })}
                        onSubmit={(values, {setSubmitting}) => {
                            setOrderRequest({
                                inn: values.inn,
                                kpp: values.kpp,
                                number: values.number,
                                sum: values.number,
                            })

                            return dispatch(DemoActions.setPaymentSum(values)).then(
                                () => {
                                    setSubmitting(false);
                                },
                                () => {
                                    setSubmitting(false);
                                }
                            )
                        }}
                    >
                        {({
                              handleSubmit,
                              isSubmitting
                          }) => (
                            <Form>
                                <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                                    <Grid item className={classes.fullWidth}>
                                        <Grid container direction="row" justify="center" alignItems="stretch" spacing={4}>
                                            <Grid item xs={12}>
                                                <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                                    <Grid item>
                                                        <Typography className={classes.title}>
                                                            Отправка полученной суммы заказа
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.input} xs={3}>
                                                <Field
                                                    fullWidth
                                                    name="inn"
                                                    type="text"
                                                    label="ИНН"
                                                    component={TextFieldWithError}
                                                />
                                            </Grid>
                                            <Grid item className={classes.input} xs={3}>
                                                <Field
                                                    fullWidth
                                                    name="kpp"
                                                    type="text"
                                                    label="КПП"
                                                    component={TextFieldWithError}
                                                />
                                            </Grid>
                                            <Grid item className={classes.input} xs={2}>
                                                <Field
                                                    fullWidth
                                                    name="number"
                                                    type="text"
                                                    label="Номер заказа"
                                                    component={TextFieldWithError}
                                                />
                                            </Grid>
                                            <Grid item className={classes.input} xs={4}>
                                                <Field
                                                    fullWidth
                                                    name="sum"
                                                    type="text"
                                                    label="Сумма, оплаченная заказчиком"
                                                    component={TextFieldWithError}
                                                />
                                            </Grid>
                                            <Grid item className={classes.fullWidth}>
                                                <Grid container direction="row" justify="flex-end"
                                                      alignItems="center">
                                                    <Grid item>
                                                        <Submit
                                                            className={classes.submit}
                                                            disableElevation
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={isSubmitting}
                                                            onClick={handleSubmit}
                                                            type="submit"
                                                        >
                                                            Отправить оплаченную сумму
                                                        </Submit>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                    {orderRequest !== null && orderItems.data && loading ?
                        <Formik
                            enableReinitialize
                            initialValues={{
                                inn: orderRequest.inn,
                                kpp: orderRequest.kpp,
                                number: orderRequest.number,
                                items: orderItems.data,
                                waybills: orderRequest.files,
                            }}
                            validationSchema={Yup.object().shape({
                                inn: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                kpp: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                number: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                waybills: Yup.mixed().test('fileFormat', 'Документ должен быть файлом одного из следующих типов: pdf.', (values) => {
                                    return !values || (values && Array.from(values).filter(file => [
                                        'application/pdf',
                                    ].includes(file.type)).length !== 0);
                                }),
                                items: Yup.array().of(Yup.object().shape({
                                    item_id: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                    amount_integration: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                    price_integration: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                })),
                            })}
                            onSubmit={(values, {setSubmitting}) => {
                                const formData = new FormData()
                                if (values.waybills) {
                                    Array.from(values.waybills).forEach(file => {
                                        formData.append("waybills[]", file, file.name);
                                    });
                                }
                                formData.append("inn", values.inn);
                                formData.append("kpp", values.kpp);
                                formData.append("number", values.number);
                                formData.append('items', JSON.stringify(values.items))

                                return dispatch(DemoActions.setOrderItemsValues(formData)).then(
                                    () => {
                                        setSubmitting(false);
                                    },
                                    () => {
                                        setSubmitting(false);
                                    }
                                )
                            }}
                        >
                            {({
                                  values,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue,
                                  setFieldTouched
                              }) => (
                                <Form>
                                    <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                                        <Grid item className={classes.fullWidth}>
                                            <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={4}>
                                                <Grid item xs={12}>
                                                    <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                                        <Grid item>
                                                            <Typography className={classes.title}>
                                                                Отправка данных из 1с
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item className={classes.input} xs={4}>
                                                    <Field
                                                        fullWidth
                                                        disabled
                                                        name="inn"
                                                        type="text"
                                                        label="ИНН"
                                                        component={TextFieldWithError}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.input} xs={4}>
                                                    <Field
                                                        fullWidth
                                                        disabled
                                                        name="kpp"
                                                        type="text"
                                                        label="КПП"
                                                        component={TextFieldWithError}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.input} xs={4}>
                                                    <Field
                                                        fullWidth
                                                        disabled
                                                        name="number"
                                                        type="text"
                                                        label="Номер заказа"
                                                        component={TextFieldWithError}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TableContainer className={classes.tableContainer}>
                                                        <Table stickyHeader aria-label="sticky table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {columns.map((column) => (
                                                                        <TableCell
                                                                            key={column.id}
                                                                        >
                                                                            {column.label}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {orderItems.data.length > 0 && orderItems.data.map((item, index) => (
                                                                    <TableRow hover key={index}>
                                                                        <TableCell>
                                                                            {item.name}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {item.article_number}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {item.amount}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {parseFloat(item.price).toLocaleString('ru-RU', {...(order.currency.code ? {style: 'currency', currency: order.currency.code} : {})}).replace('KZT', '₸')}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {(item.tax_percent > 0) ? `${item.tax_percent}%` : 'Без НДС'}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {parseFloat(item.totalPrice).toLocaleString('ru-RU', {...(order.currency.code ? {style: 'currency', currency: order.currency.code} : {})}).replace('KZT', '₸')}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Field
                                                                                fullWidth
                                                                                name={`items.${index}.amount_integration`}
                                                                                type="number"
                                                                                required
                                                                                inputProps={{
                                                                                    min: 0,
                                                                                    step: 0.01,
                                                                                }}
                                                                                component={CustomTextField}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Field
                                                                                fullWidth
                                                                                name={`items.${index}.price_integration`}
                                                                                type="number"
                                                                                required
                                                                                inputProps={{
                                                                                    min: 0,
                                                                                    step: 0.01,
                                                                                }}
                                                                                component={CustomTextField}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {item.tnved}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {item.gross_weight}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Field
                                                        fullWidth
                                                        component={(props) => (
                                                            <FileUpload
                                                                {...props}
                                                                endIcon={<AddIcon/>} />
                                                        )}
                                                        label="Загрузите ТТН"
                                                        name="waybills"
                                                        InputLabelProps={{
                                                            name: values.waybills ? Array.from(values.waybills).map(el => el.name).join(', ') : null
                                                        }}
                                                        InputProps={{
                                                            onChange: (event) => {
                                                                const files = event.currentTarget.files;
                                                                setFieldValue('waybills', files);
                                                                setFieldTouched("waybills", true, false)
                                                            },
                                                            multiple: true
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.fullWidth}>
                                                    <Grid container direction="row" justify="flex-end" alignItems="center">
                                                        <Grid item>
                                                            <Submit
                                                                className={classes.submit}
                                                                disableElevation
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={isSubmitting}
                                                                onClick={handleSubmit}
                                                                type="submit"
                                                            >
                                                                Отправить данные товаров
                                                            </Submit>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik> : null}
                        <Formik
                            initialValues={{
                                inn: '',
                                kpp: '',
                                number: '',
                            }}
                            validationSchema={Yup.object().shape({
                                inn: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                kpp: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                number: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
                            })}
                            onSubmit={(values, {setSubmitting}) => {
                                setLoading(false)
                                return dispatch(DemoActions.contract({
                                    inn: values.inn,
                                    kpp: values.kpp,
                                    number: values.number,
                                    limit: rowsPerPage,
                                    page: page + 1
                                })).then(
                                    () => {
                                        setLoading(true)
                                        setSubmitting(false);
                                    },
                                    () => {
                                        setSubmitting(false);
                                    }
                                )
                            }}
                        >
                            {({
                                  handleSubmit,
                                  isSubmitting
                              }) => (
                                <Form>
                                    <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                                        <Grid item className={classes.fullWidth}>
                                            <Grid container direction="row" justify="center" alignItems="stretch" spacing={4}>
                                                <Grid item xs={12}>
                                                    <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                                        <Grid item>
                                                            <Typography className={classes.title}>
                                                                Запрос договора
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item className={classes.input} xs={4}>
                                                    <Field
                                                        fullWidth
                                                        name="inn"
                                                        type="text"
                                                        label="ИНН"
                                                        component={TextFieldWithError}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.input} xs={4}>
                                                    <Field
                                                        fullWidth
                                                        name="kpp"
                                                        type="text"
                                                        label="КПП"
                                                        component={TextFieldWithError}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.input} xs={4}>
                                                    <Field
                                                        fullWidth
                                                        name="number"
                                                        type="text"
                                                        label="Номер договора"
                                                        component={TextFieldWithError}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.fullWidth}>
                                                    <Grid container direction="row" justify="flex-end"
                                                          alignItems="center">
                                                        <Grid item>
                                                            <Submit
                                                                className={classes.submit}
                                                                disableElevation
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={isSubmitting}
                                                                onClick={handleSubmit}
                                                                type="submit"
                                                            >
                                                                Получить информацию о договоре
                                                            </Submit>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={1}>
                                                        <Grid item xs={6}><span className={classes.fontWeight}>Номер договора:</span> {contract ? contract.number: null}</Grid>
                                                        <Grid item xs={6}><span className={classes.fontWeight}>Наименование:</span> {contract ? contract.name : null}</Grid>
                                                        <Grid item xs={6}><span className={classes.fontWeight}>Поставщик (Наименование, ИНН, КПП):</span> {contract ? `${contract.supplier.name}, ${contract.supplier.inn}, ${contract.supplier.kpp}` : null}</Grid>
                                                        <Grid item xs={6}><span className={classes.fontWeight}>Дата заключения:</span> {(contract && contract.start) ? format(new Date(contract.start), 'dd.MM.yyyy HH:mm') : null}</Grid>
                                                        <Grid item xs={6}><span className={classes.fontWeight}>Сумма по договору:</span> {(contract && contract.total_price) ? parseFloat(contract.total_price).toLocaleString('ru-RU', {...(contract.currency.code ? {style: 'currency', currency: contract.currency.code} : {})}).replace('KZT', '₸') : null} </Grid>
                                                        <Grid item xs={6}><span className={classes.fontWeight}>Дата окончания:</span> {contract ? (contract.perpetual ? 'Бессрочный' : format(new Date(contract.end), 'dd.MM.yyyy HH:mm')) : null}</Grid>
                                                        <Grid item xs={4}><span className={classes.fontWeight}>Тип оплаты:</span> {contract ? types.find(type => type.type === contract.type).name : null}</Grid>
                                                        <Grid item xs={4}><span className={classes.fontWeight}>Предоплата, %:</span> {contract ? contract.prepayment: null}</Grid>
                                                        <Grid item xs={4}><span className={classes.fontWeight}>Отсрочка платежа, дней:</span> {contract ? contract.postponement : null}</Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TableContainer className={classes.tableContainer}>
                                                        <Table stickyHeader aria-label="sticky table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {[
                                                                        {
                                                                            id: '0',
                                                                            label: 'Наименование товар',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                        {
                                                                            id: '1',
                                                                            label: 'Артикул',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                        {
                                                                            id: '2',
                                                                            label: 'Кол-во товара',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                        {
                                                                            id: '4',
                                                                            label: 'Ставка НДС',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                    ].map((column) => (
                                                                        <TableCell
                                                                            key={column.id}
                                                                        >
                                                                            {column.label}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {!!contractItems.data.length && contractItems.data.map((item, index) => (
                                                                    <TableRow hover key={index}>
                                                                        <TableCell>
                                                                            {item.name}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {item.article_number}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {item.amount}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {(item.tax_percent > 0) ? `${item.tax_percent}%` : 'Без НДС'}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid item>
                                                        <TablePagination
                                                            rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                                            component='div'
                                                            labelRowsPerPage={'Записей на странице:'}
                                                            count={orderItems.meta.total}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            onChangePage={handleChangePage}
                                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                        <Formik
                            initialValues={{
                                inn: '',
                                kpp: ''
                            }}
                            validationSchema={Yup.object().shape({
                                inn: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                kpp: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
                            })}
                            onSubmit={(values, {setSubmitting}) => {
                                setLoading(false)
                                return dispatch(DemoActions.supplier({
                                    inn: values.inn,
                                    kpp: values.kpp,
                                    limit: rowsPerPage,
                                    page: page + 1
                                })).then(
                                    () => {
                                        setLoading(true)
                                        setSubmitting(false);
                                    },
                                    () => {
                                        setSubmitting(false);
                                    }
                                )
                            }}
                        >
                            {({
                                  handleSubmit,
                                  isSubmitting
                              }) => (
                                <Form>
                                    <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                                        <Grid item className={classes.fullWidth}>
                                            <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={4}>
                                                <Grid item xs={12}>
                                                    <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                                        <Grid item>
                                                            <Typography className={classes.title}>
                                                                Запрос информации о поставщике
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item className={classes.input} xs={4}>
                                                    <Field
                                                        fullWidth
                                                        name="inn"
                                                        type="text"
                                                        label="ИНН"
                                                        component={TextFieldWithError}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.input} xs={4}>
                                                    <Field
                                                        fullWidth
                                                        name="kpp"
                                                        type="text"
                                                        label="КПП"
                                                        component={TextFieldWithError}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.fullWidth}>
                                                    <Grid container direction="row" justify="flex-end"
                                                          alignItems="center">
                                                        <Grid item>
                                                            <Submit
                                                                className={classes.submit}
                                                                disableElevation
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={isSubmitting}
                                                                onClick={handleSubmit}
                                                                type="submit"
                                                            >
                                                                Получить информацию о поставщике
                                                            </Submit>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={1}>
                                                        <Grid item xs={8}><span className={classes.fontWeight}>Наименование:</span> {supplier ? supplier.name : null}</Grid>
                                                        <Grid item xs={4}><span className={classes.fontWeight}>Тип:</span> {supplier ? (supplier.type === 'legal') ? 'Юр. лицо' : 'ИП' : null}</Grid>
                                                        <Grid item xs={4}><span className={classes.fontWeight}>ИНН:</span> {supplier ? supplier.inn : null}</Grid>
                                                        <Grid item xs={4}><span className={classes.fontWeight}>КПП:</span> {supplier ? supplier.kpp : null}</Grid>
                                                        <Grid item xs={4}><span className={classes.fontWeight}>ОКПО:</span> {null}</Grid>
                                                        <Grid item xs={8}><span className={classes.fontWeight}>ОКДП:</span> {null}</Grid>
                                                        <Grid item xs={8}><span className={classes.fontWeight}>Наименование банка:</span> {supplier ? supplier.bank : null}</Grid>
                                                        <Grid item xs={4}><span className={classes.fontWeight}>БИК:</span> {supplier ? supplier.bik : null}</Grid>
                                                        <Grid item xs={6}><span className={classes.fontWeight}>К/c:</span> {supplier ? supplier.correspondent_account : null}</Grid>
                                                        <Grid item xs={6}><span className={classes.fontWeight}>Р/c:</span> {supplier ? supplier.checking_account : null}</Grid>
                                                        <Grid item xs={6}><span className={classes.fontWeight}>Юр. адрес:</span> {supplier ? supplier.legal_address : null}</Grid>
                                                        <Grid item xs={6}><span className={classes.fontWeight}>Физ. адрес:</span> {supplier ? supplier.current_address : null}</Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                </Paper>
            </Grid>
        </Grid>
    )
}
