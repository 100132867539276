import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {Field, getIn} from "formik"

import {
    Box,
    Grid,
    IconButton,
    LinearProgress,
    makeStyles,
    TextField as MuiTextField,
    Tooltip,
    Typography
} from "@material-ui/core"

import {Autocomplete} from "@material-ui/lab";
import {useDebouncedCallback} from "use-debounce";
import {Help as HelpIcon} from "@material-ui/icons";
import {yellow} from "@material-ui/core/colors";
import {StandardForm} from "../../../Standard/StandardForm";
import {SystemActions} from "../../actions/system";

const useStyles = makeStyles(theme => ({
    option: {
        'padding': '0 !important',
        '&:nth-child(2)': {
            'margin-top': '15px'
        }
    },
    inputRootConfirmation: {
        'background-color': '#fffde7'
    },
    proposalStandard: {
        'position': 'fixed',
        'z-index': '2',
        'background-color': '#fff',
        'width': '100%',
        'padding': '6px 24px 6px 16px',
    },
    tooltipOfferStandard: {
        "background-color": "#000",
        "font-size": "15px",
        "color": "#fff",
        "padding": "15px 15px",
    },
    default: {
        'width': '100%',
        'padding': '6px 24px 6px 16px',
    },
    confirmation: {
        'width': '100%',
        'background-color': yellow[50],
        'padding': '6px 24px 6px 16px',
        '&:hover': {
            'background-color': `rgba(0, 0, 0, 0.01)`
        }
    },
    errorField: {
        "&>div": {
            backgroundColor: 'rgb(242 115 96 / 50%)'
        }
    },
}));

export const Standard = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {name, category, module, standard, edit = true, disabled = false, required = false, noOptionsText, onChange, errors, touched, onAddStandard, params = {}, type} = props

    const [load, setLoad] = useState(true)
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState([])
    const [value, setValue] = useState(null)

    const debounced = useDebouncedCallback(
        (value) => {
            setValue(value)
        }, 900
    )

    useEffect(() => {
        const getItems = async () => {
            return await dispatch(SystemActions.items({
                status: ['active', 'confirmed'],
                search: value,
                category: category.id,
                limit: 20,
                ...Object.entries(params).reduce((result, [key, value]) => {
                    if (value) {
                        result[key] = value
                    }

                    return result
                }, {})
            }, false))
        }

        if (category?.active && value) {
            setLoad(false)
            getItems().then((response) => {
                setOptions(response.data)
                setLoad(true)
            })
        } else {
            setOptions([])
            setLoad(true)
        }
        // eslint-disable-next-line
    }, [dispatch, value])

    return (
        <React.Fragment>
            <Autocomplete
                name={name}
                freeSolo={!category?.active}
                disabled={disabled}
                options={options ?? []}
                filterOptions={options => {
                    return category?.active
                        ? (load
                            ? [...(value?.length ? (options.length ? ['Предложить эталон', ...options] : ['Результаты отсутствуют', 'Предложить эталон']) : [])]
                            : ['Загрузка'])
                        : options
                }}
                classes={{
                    ...{option: classes.option},
                    ...((standard && !standard.confirmed) ? {inputRoot: classes.inputRootConfirmation} : {})
                }}
                value={standard}
                required={required}
                getOptionLabel={item => {
                    if (item) {
                        if (item instanceof Object) {
                            switch (type) {
                                case 'typed':
                                case 'arbitrary':
                                    return item.assembly.find(el => el.type.key === type)?.value ?? item.name;
                                default:
                                    return item.name;
                            }
                        }

                        return item;
                    }

                    return '';
                }}
                getOptionDisabled={(option) =>
                    (option === 'Загрузка') || (option === 'Результаты отсутствуют')
                }
                noOptionsText={noOptionsText ?? 'Название'}
                onChange={(e, item) => {
                    if (item === 'Предложить эталон') {
                        e.stopPropagation();
                        onChange({[name]: null})
                        return;
                    }

                    if (item?.category?.id) {
                        onChange({
                            [name]: item ? {
                                ...item,
                                ...{typed: item.assembly.find(value => value.type.key === 'typed')?.value}
                            } : null,
                        })
                    } else {
                        if (!item) {
                            setValue(null)
                        }
                        onChange({[name]: item ? item : null})
                    }
                }}
                renderOption={option => {
                    if (load) {
                        switch (option) {
                            case 'Предложить эталон':
                                return (
                                    <Grid
                                        container
                                        justify={'space-between'}
                                        className={classes.proposalStandard}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setOpen(true)
                                        }}
                                    >
                                        <Grid item><Typography>Предложить эталон</Typography></Grid>
                                        <Grid item>
                                            <Tooltip
                                                title="Если товар отсутствует в списке, вы можете предложить свой эталон"
                                                placement="right"
                                                classes={{tooltip: classes.tooltipOfferStandard}}
                                            >
                                                <IconButton
                                                    style={{
                                                        "width": "24px",
                                                        "height": "24px",
                                                        "padding": "0"
                                                    }}
                                                >
                                                    <HelpIcon style={{"color": "#485868"}}/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                )
                            case 'Результаты отсутствуют':
                                return (
                                    <Grid container justify={'space-between'} className={classes.default}>
                                        <Grid item><Typography variant="body2">Результаты отсутствуют</Typography></Grid>
                                    </Grid>
                                )
                            default:
                                if (typeof option === 'object') {
                                    switch (type) {
                                        case 'typed':
                                        case 'arbitrary':
                                            return <Typography className={option.confirmed ? classes.default : classes.confirmation}>{option.assembly.find(el => el.type.key === type)?.value ?? option.name}</Typography>;
                                        default:
                                            return <Typography className={option.confirmed ? classes.default : classes.confirmation}>{option.name}</Typography>;
                                    }
                                }

                                return null;
                        }
                    }

                    return <Box className={classes.default} style={{width: '100%'}}><LinearProgress/></Box>
                }}
                renderInput={params => {
                    const error = getIn(touched, name) && getIn(errors, name);
                    return <Field
                        fullWidth
                        component={MuiTextField}
                        required={true}
                        placeholder="Начните вводить наименование..."
                        {...params}
                        onChange={(e) => {
                            if (category?.active) {
                                debounced.callback(e.target.value)
                            } else if (e.target.value !== 'Предложить эталон') {
                                setValue(e.target.value)
                            }
                        }}
                        error={!!error}
                        helperText={error}
                        name={name}
                        label="Название"
                        className={touched[name] && errors[name] ? classes.errorField : null}
                    />
                }}
                onFocus={() => {
                    setOptions([])
                }}
                onBlur={() => {
                    if (!category?.active) {
                        onChange({[name]: value})
                    }
                    setValue(null)
                }}
            />
            {open ? (
                <StandardForm
                    open={open}
                    module={module}
                    category={category}
                    edit={edit}
                    onCLose={() => {
                        setOpen(false)
                    }}
                    setOpen={setOpen}
                    onAddStandard={onAddStandard}
                />
            ) : null}
        </React.Fragment>
    )
}
