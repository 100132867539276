export default function contract(state = {contracts: {data: [], meta: {total: 0, per_page: 15, current_page: 1}}}, action) {
    switch (action.type) {
        case "REPORT_CONTRACTS_REQUEST":
            return state
        case "REPORT_CONTRACTS":
            return {
                ...state,
                ...{
                    contracts: action.payload,
                }
            }
        case "REPORT_CONTRACTS_FAILURE":
            return state
        default:
            return state
    }
}
