import React, {useEffect, useState,} from "react"
import {useDispatch, useSelector} from "react-redux"
import format from "date-fns/format"

import {Grid, makeStyles, Paper, TablePagination} from "@material-ui/core"

import {MarketplaceActions} from "../../actions/marketplace"
import {ProductsTable} from "./ProductsTable"
import {Controls} from "./Controls"
import {getChild} from "../../../App/helpers/category"
import {SystemActions} from "../../../App/actions/system"

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    container: {
        "width": "100%",
        //"height": "calc(100vh - 187px)"
    },
    input: {
        "width": "100%",
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 231px)",
        "min-height": "calc(100vh - 231px)"
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    },
    amount: {
        "min-width": "100px"
    },
}))

export const Content = (props) => {
    const query = new URLSearchParams(props.location.search)

    const dispatch = useDispatch()
    const classes = useStyles()
    const {items, positions, carts} = useSelector(state => state.marketplace)
    const {category, filter_values} = useSelector(state => state.marketplace.items)
    const {account} = useSelector(state => state.account)
    const {categories} = useSelector(state => state.system)

    const [search, setSearch] = useState(null)
    const [find, setFind] = useState(!!(query.has('expiration_date') && query.has('postponement') && query.has('category')))
    const [expirationDate, setExpirationDate] = useState(query.has('expiration_date') ? new Date(query.get('expiration_date').replace(/(\\d{2}).(\\d{2}).(\\d{4})/, "$3-$1-$1")) : null)
    const [postponement, setPostponement] = useState(query.has('postponement') ? query.get('postponement') : '')
    const [expirationDateType, setExpirationDateType] = useState(query.has('expirationDateType') ? query.get('expirationDateType') : 'expirationDate')
    const [expirationDatePercent, setExpirationDatePercent] = useState(query.has('expirationDatePercent') ? query.get('expirationDatePercent') : '')

    const [formulations, setFormulations] = useState([])
    const [dosages, setDosages] = useState([])
    const [packaging, setPackaging] = useState([])

    const onFormulationChange = ({value}) => setFormulations(value)
    const onDosageChange = ({value}) => setDosages(value)
    const onPackagingChange = ({value}) => setPackaging(value)

    const [page, setPage] = useState(parseInt(items.meta.current_page - 1))
    const [rowsPerPage, setRowsPerPage] = useState(15)

    const [cart, setCart] = useState(null)

    const [loading, setLoading] = useState(false)

    const [expanded, setExpanded] = useState(false)

    useEffect(() => {
        return () => {
            dispatch({type: "MARKETPLACE_ITEMS_CATEGORY", payload: null})
        }
    }, [dispatch])

    useEffect(() => {
        const getPositions = async (expanded) => {
            await dispatch({type: "MARKETPLACE_ITEM_POSITIONS_CLEAR"})
            return await dispatch(MarketplaceActions.positions(expanded, {
                ...(expirationDate ? {expiration_date_from: format(expirationDate, 'yyyy-MM-dd')} : {}),
                ...(expirationDateType ? {expiration_date_type: expirationDateType} : {}),
                ...(expirationDatePercent ? {expiration_date_percent: expirationDatePercent} : {}),
                ...(postponement ? {postponement: postponement} : {})
            }))
        }

        if (expanded) {
            getPositions(expanded).then(_ => {
            })
        }

        return () => {
            dispatch({type: "MARKETPLACE_ITEM_POSITIONS_CLEAR"})
        }
        // eslint-disable-next-line
    }, [expanded]);

    const getItems = async () => {
        return await dispatch(MarketplaceActions.items({
            ...{
                limit: rowsPerPage,
                page: page + 1
            },
            ...(search ? {name: search} : {}),
            ...(category ? {category_id: category} : (query.has('category') ? {category_id: query.get('category')} : {})),
            ...(expirationDate ? {expiration_date_from: format(expirationDate, 'yyyy-MM-dd')} : {}),
            ...(expirationDateType ? {expiration_date_type: expirationDateType} : {}),
            ...(expirationDatePercent ? {expiration_date_percent: expirationDatePercent} : {}),
            ...(postponement ? {postponement: postponement} : {}),
            ...(formulations.length > 0 ? {formulations: encodeURIComponent(formulations.join(','))} : {}),
            ...(packaging.length > 0 ? {packaging: encodeURIComponent(packaging.join(','))} : {}),
            ...(dosages.length > 0 ? {dosages: encodeURIComponent(dosages.join(','))} : {})
        }))
    }

    const getCarts = () => {
        // eslint-disable-next-line array-callback-return
        return carts.find((cart) => {
            if (parseInt(cart.postponement) === parseInt(postponement) && parseInt(cart.category.id) === parseInt(category)) {
                if (expirationDateType === 'expirationDatePercent') {
                    return cart.expiration_date_percent === expirationDatePercent
                } else if (expirationDateType === 'expirationDate') {
                    let date = cart.expiration_date ? new Date(cart.expiration_date.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2-$1-$3")) : null
                    return date !== null && (date.getDay() === expirationDate.getDay()) && (date.getMonth() && expirationDate.getMonth()) && (date.getFullYear() === expirationDate.getFullYear())
                }
            }
        })
    }

    useEffect(() => {
        if (!loading) {
            setLoading(true)
            getItems().then(_ => {
                setCart(getCarts())
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loading, query, page, rowsPerPage, carts, search, expirationDate, category, postponement]);

    useEffect(() => {
        return () => {
            dispatch({
                type: "MARKETPLACE_ITEMS_SUCCESS",
                payload: {data: [], meta: {total: 0, per_page: 15, current_page: 1}, category: null}
            })
        }
    }, [dispatch]);

    useEffect(() => {
        setCart(getCarts())
        // eslint-disable-next-line
    }, [carts, postponement, category, expirationDate])

    useEffect(() => {
        dispatch({
            type: "MARKETPLACE_ITEMS_CATEGORY",
            payload: category
        })
    }, [dispatch, category]);

    useEffect(() => {
        if (!categories.length) {
            dispatch(SystemActions.categories())
        }
    }, [dispatch, categories.length]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        setLoading(false)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
        setLoading(false)
    }

    const handleSetExpirationDate = date => {
        setExpirationDate(date);
    }

    const onChangePostponement = (event) => {
        const value = event.target.value

        setPostponement(value.length ? value : '')
    }

    const onChangeSearch = (event) => {
        const value = event.target.value

        setSearch(value.length ? value : null)
    }

    const onSubmitSearch = () => {
        setExpanded(false)

        getItems().then(
            _ => {
                setFind(true)
                setCart(getCarts())
            },
            _ => {
                setFind(true)
            }
        )
    }

    const handleExpirationDateType = event => {
        setExpirationDateType(event.target.value);
    }

    const handleExpirationDatePercent = event => {
        setExpirationDatePercent(event.target.value.length ? event.target.value : '')
    }

    const isMedicinesCategory = (category, categories) => {
        const childCategoryIds = getChild(process.env.REACT_APP_CATEGORY_MEDICINES, categories)
        return childCategoryIds.indexOf(category) !== -1;
    }

    const controlsCommonParams = {
        category: category,
        query: query,
        filterValues: filter_values,
        isMedicinesCategory: isMedicinesCategory(category, categories)
    }

    const controlsHandlers = {
        onChangeSearch: onChangeSearch,
        onSubmitSearch: onSubmitSearch,

        onFormulationChange: onFormulationChange,
        onDosageChange: onDosageChange,
        onPackagingChange: onPackagingChange
    }

    const controlsParams = {
        postponement: {
            value: postponement,
            handler: onChangePostponement,
        },
        expirationDate: {
            value: expirationDate,
            handler: handleSetExpirationDate,
        },
        expirationDateType: {
            value: expirationDateType,
            handler: handleExpirationDateType,
        },
        expirationDatePercent: {
            value: expirationDatePercent,
            handler: handleExpirationDatePercent,
        },
    }

    return <Grid item className={classes.content}>
        <Grid container direction="column" justify="center" alignItems="stretch">
            <Grid item>
                <Controls
                    commonParams={controlsCommonParams}
                    handlers={controlsHandlers}
                    params={controlsParams}
                />
            </Grid>
        </Grid>
        <Paper>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item className={classes.fullWidth}>
                    <Grid container className={classes.container} direction="column" justify="space-between" alignItems="stretch">
                        <Grid item className={classes.inputContent}>
                            <ProductsTable
                                positions={positions}
                                postponement={postponement}
                                expirationDateType={expirationDateType}
                                expirationDate={expirationDate}
                                expirationDatePercent={expirationDatePercent}
                                cart={cart}
                                find={find}
                                items={items}
                                category={category}
                                setExpanded={setExpanded}
                                expanded={expanded}
                                account={account}
                            />
                        </Grid>
                        <Grid item className={classes.footer}>
                            <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                <Grid item className={classes.footerContent}>
                                    <Grid container className={classes.footerContainer} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                        <Grid item>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                                component='div'
                                                labelRowsPerPage={'Записей на странице:'}
                                                count={items.meta.total}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    </Grid>
}
