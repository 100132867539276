import React from "react"
import {useDispatch} from "react-redux"
import {Field, Form, Formik} from "formik"
import * as Yup from "yup"

import {Grid, Link, makeStyles, Paper, Typography} from "@material-ui/core"

import {AuthActions} from "./actions/authentication"
import {Submit} from "../App/components/Button/Submit"
import {TextFieldWithError} from "../App/components/Inputs/TextFieldWithError"

const useStyles = makeStyles(theme => ({
    item: {
        "width": "450px"
    },
    container: {
        "background-color": "#e4e4e4",
        "border-radius": 0,
        "min-height": "100vh",
        "height": "auto"
    },
    content: {
        "padding": "35px",
        "border-top": `5px solid ${theme.palette.primary.dark}`,
        "border-bottom": "5px solid #c1c1c1",
        "border-radius": "0px",
        "box-shadow": "0 5px 20px rgba(0,0,0,.4)"
    },
    fullWidth: {
        "width": "100%"
    },
    input: {
        "width": "100%"
    },
    link: {
        "text-decoration": "underline",
        "color": theme.palette.primary.dark,
        "font-weight": "700",
        "&:hover": {
            color: theme.palette.primary.main
        },
        "font-size": "16px"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase"
    },
    submit: {
       "height": "35px"
    },
    description: {
        "font-size": "16px",
        "font-weight": "400",
        "color": "#485868",
        "line-height": "unset"
    },
    phone: {
        "font-size": "16px",
        "text-transform": "uppercase",
        "font-weight": "700",
        "color": "#485868"
    }
}))

export const Recovery = () => {
    const dispatch = useDispatch()
    const classes = useStyles()

    return (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.container}>
            <Grid item className={classes.item}>
                <Paper className={classes.content}>
                    <Formik
                        initialValues={{login: ""}}
                        validationSchema={Yup.object().shape({
                            login: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
                        })}
                        onSubmit={(values, {setErrors}) => {
                            return dispatch(AuthActions.recovery(values)).then(() => {},
                                error => {
                                    setErrors(error.errors);
                                }
                            )
                        }}
                    >
                        {({
                              handleSubmit,
                              isSubmitting
                          }) => (
                            <Form>
                                <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                                    <Grid item className={classes.fullWidth}>
                                        <Grid container direction="column" justify="center" alignItems="stretch"
                                              spacing={1}>
                                            <Grid item>
                                                <Grid container direction="column" justify="flex-start"
                                                      alignItems="stretch">
                                                    <Grid item>
                                                        <Typography className={classes.title}>
                                                            Восстановление пароля
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.input}>
                                                <Field
                                                    fullWidth
                                                    name="login"
                                                    type="text"
                                                    label="Имя пользователя"
                                                    component={TextFieldWithError}
                                                />
                                            </Grid>
                                            <Grid item className={classes.fullWidth}>
                                                <Grid container direction="row" justify="flex-start"
                                                      alignItems="center">
                                                    <Grid item>
                                                        <Submit
                                                            className={classes.submit}
                                                            disableElevation
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={isSubmitting}
                                                            onClick={handleSubmit}
                                                            type="submit"
                                                        >
                                                            Выслать пароль
                                                        </Submit>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="column" justify="flex-start"
                                                      alignItems="stretch" spacing={1}>
                                                    <Grid item>
                                                        <Typography className={classes.description}>
                                                            Не получилось восстановить пароль? Напишите, и наши
                                                            сотрудники помогут Вам:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Link href="mailto:support@emsoft.ru" className={classes.link}>
                                                            support@emsoft.ru
                                                        </Link>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className={classes.phone}>
                                                            +7 (495) 248-06-46
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Paper>
            </Grid>
        </Grid>
    )
}
