import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce} from 'use-debounce'
import format from "date-fns/format"
import DateFnsUtils from "@date-io/date-fns"
import {ru} from "date-fns/locale"
import clsx from "clsx"

import {
    Checkbox,
    Grid,
    InputAdornment,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from "@material-ui/core"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"
import {Search as SearchIcon, GetApp as GetAppIcon} from "@material-ui/icons"
import {ButtonList} from "../../../App/components/ButtonList/ButtonList"
import {Submit} from "../../../App/components/Button/Submit"
import {CompanyActions} from "../../actions/company"
import {ReportActions} from "../../actions/report"
import {AuthorizationService} from "../../../Auth/services/authorization"
import {SystemActions} from "../../../App/actions/system"
import {StatusActions as DeliveryStatusActions} from "../../../Delivery/actions/status"
import {ContractActions} from "../../../Contract/actions/contract"
import * as AppStorage from "../../../App/helpers/storage"

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    suppliers: {
        "font-size": "18px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 111px)",
        position: "relative",
    },
    tableContainer: {
        "min-height": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 461px)",
        [theme.breakpoints.down('lg')]: {
            "height": "calc(100vh - 529px)"
        }
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    searchLabel: {
        "margin-bottom": "15px",
    },
    list: {
        "margin-top": "0",
        "margin-bottom": "0"
    },
    frame: {
        "border-width": "0"
    },
    frameContent: {
        "width": "100%",
        "height": "calc(100% - 70px)"
    },
    frameFooterContainer: {
        "padding-right": "5px",
        "padding-top": "17px"
    },
    date: {
        width: "115px",
        "& .MuiInputAdornment-root .MuiButtonBase-root": {
            padding: 0,
        }
    },
    status: {
        "width": "200px"
    },
}))

const columns = [
    {
        id: '0',
        label: 'Наименование',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '1',
        label: 'ИНН/БИН',
        format: (value) => value.toLocaleString(),
    }
]

const contracts = [
    {
        key: 'after_fact',
        value: 'Постфактум'
    },
    {
        key: 'active',
        value: 'Рабочий'
    },
    {
        key: 'all',
        value: 'Все'
    }
]

export const Reports = () => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { account } = useSelector(state => state.account)
    const { companies } = useSelector(state => state.reportCompany)
    const { filter, file } = useSelector(state => state.report)
    const { type } = useSelector(state => state.report.filter)

    const [beginning, setBeginning] = useState(() => {
        const now = new Date()

        return new Date(now.getFullYear(), now.getMonth(), 1)
    })
    const [ending, setEnding] = useState(new Date())
    const [period, setPeriod] = useState(1)
    const [contract, setContract] = useState(2)
    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [downloadType, setDownloadType] = useState('pdf')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const tableRef = useRef(null)
    const [status, setStatus] = useState([])
    const [statuses, setStatuses] = useState([])

    const [selected, setSelected] = useState([])
    const isSelected = (name) => (selected.indexOf(name) !== -1)

    useEffect(() => {
        const getCompanies = async () => {
            return await dispatch(CompanyActions.companies({
                limit: rowsPerPage,
                page: page + 1,
                beginning: format(beginning, 'yyyy-MM-dd'),
                ending: format(ending, 'yyyy-MM-dd'),
                contract: (type && (type.value === 'delivery_orders_after_fact')) ? contracts[0].key : contracts[contract].key,
                ...(type ? {type: type.value} : {}),
                ...(searchRequest ? {search: searchRequest} : {}),
                ...(statuses.length ? {status: statuses.join(',')} : {})
            }))
        }

        getCompanies().then(() => {
            tableRef.current && tableRef.current.scrollIntoView({ behavior: 'smooth' })
        })
    }, [dispatch, page, rowsPerPage, beginning, ending, type, contract, searchRequest, statuses]);

    useEffect(() => {
        if (type) {
            switch (type.value) {
                case 'purchase_history':
                    dispatch(SystemActions.status()).then(data => {
                        setStatus(data)
                    })
                    break
                case 'delivery_history':
                case 'delivery_statistic':
                case 'delivery_statistic_category':
                    dispatch(DeliveryStatusActions.status()).then(data => {
                        setStatus(data)
                    })

                    break
                case 'delivery_orders':
                    dispatch(DeliveryStatusActions.status()).then(data => {
                        setStatus(data)
                    })

                    break
                case 'delivery_orders_after_fact':
                    dispatch(DeliveryStatusActions.status()).then(data => {
                        setStatus(data)
                    })
                    break
                case 'delivery_times':
                    dispatch(DeliveryStatusActions.status()).then(data => {
                        setStatus(data)
                    })
                    break
                case 'execution_contract':
                    dispatch(ContractActions.statuses()).then(data => {
                        setStatus(data)
                    })
                    break
                default:
            }
        }
    }, [dispatch, type]);

    useEffect(() => {
        return () => {
            dispatch({type: "REPORT_FILE_CLEAR"})
        }
    }, [dispatch])

    useEffect(() => {
        setSelected([])
    }, [type, beginning, ending, contract])

    const handlePeriod = (_, value) => {
        setPeriod(value);
        switch (value) {
            case 0:
                setBeginning(() => {
                    const now = new Date()

                    return new Date(now.setDate(now.getDate() - now.getDay() + ((now.getDay() === 0) ? -6 : 1)))
                })
                break
            case 1:
                setBeginning(() => {
                    const now = new Date()

                    return new Date(now.getFullYear(), now.getMonth(), 1)
                })
                break
            case 2:
                setBeginning(() => {
                    const now = new Date()
                    const month = now.getMonth();

                    if (month <= 2) {
                        return new Date(now.getFullYear(), 0, 1)
                    } else if (month <= 5) {
                        return new Date(now.getFullYear(), 3, 1)
                    } else if (month <= 8) {
                        return new Date(now.getFullYear(), 6, 1)
                    } else {
                        return new Date(now.getFullYear(), 9, 1)
                    }
                })
                break
            case 3:
                setBeginning(() => {
                    const now = new Date()

                    return new Date(now.getFullYear(), 0, 1)
                })
                break
            default:
        }

        setEnding(new Date())
    }

    const handleContract = (_, value) => {
        setContract(value);
    }

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelected([...companies.data.map((company) => company.id), ...selected])
        } else {
            setSelected([])
        }
    };

    const handleSelect = (event, name) => {
        const selectedIndex = selected.indexOf(name)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
        }

        setSelected(newSelected)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    return file ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.fullWidth}>
                        <Grid container className={classes.container} direction="column" justify="space-between" alignItems="stretch">
                            <Grid item className={classes.frameContent}>
                                <iframe title="report" className={classes.frame} src={URL.createObjectURL(file.file)} width="100%" height="100%" />
                            </Grid>
                            <Grid item className={classes.footer}>
                                <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                    <Grid item className={classes.footerContent}>
                                        <Grid container className={classes.frameFooterContainer} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                            <Grid item>
                                                <Submit
                                                    disableElevation
                                                    variant="contained"
                                                    color="primary"
                                                    type="button"
                                                    onClick={() => {
                                                        setSelected([])
                                                        dispatch({type: "REPORT_FILE_CLEAR"})
                                                    }}
                                                >
                                                    Создать отчёт
                                                </Submit>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                    <Grid item>
                                                        <Submit
                                                            disableElevation
                                                            variant="contained"
                                                            color="primary"
                                                            type="button"
                                                            endIcon={<GetAppIcon/>}
                                                            href={(downloadType === 'pdf') ? URL.createObjectURL(file.file) : (process.env.REACT_APP_HOST_API + `/report/${file.id}/download?type=${downloadType}&token=${AppStorage.get('token').replace('Bearer ', '')}`)}
                                                            download
                                                        >
                                                            Скачать
                                                        </Submit>
                                                    </Grid>
                                                    <Grid item>
                                                        <Select
                                                            value={downloadType}
                                                            onChange={(e) => {
                                                                setDownloadType(e.target.value)
                                                            }}
                                                        >
                                                            {
                                                                file.formats.map((format, index) => {
                                                                    let name

                                                                    switch (format) {
                                                                        case 'excel':
                                                                            name = 'XLSX'
                                                                            break
                                                                        default:
                                                                            name = format.toUpperCase()
                                                                    }

                                                                    return <MenuItem key={index} value={format}>{name}</MenuItem>
                                                                })
                                                            }
                                                        </Select>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    ) : (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.fullWidth}>
                        <Grid container className={classes.container} direction="column" justify="space-between" alignItems="stretch">
                            <Grid item className={classes.fullWidth}>
                                <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                    <Grid item className={classes.header}>
                                        <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
                                            <Grid item className={classes.fullWidth}>
                                                <Typography className={classes.title}>
                                                    Формирование отчета {type ? `"${type.name}"` : null}
                                                </Typography>
                                            </Grid>
                                            <Grid item className={classes.fullWidth}>
                                                <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                                                    <Grid item>
                                                        <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                                            <Grid item>
                                                                <Typography>
                                                                    За период
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <ButtonList
                                                                    className={classes.list}
                                                                    value={period}
                                                                    onChange={handlePeriod}
                                                                    values={[
                                                                        {
                                                                            key: 'week',
                                                                            value: 'Неделя'
                                                                        },
                                                                        {
                                                                            key: 'month',
                                                                            value: 'Месяц'
                                                                        },
                                                                        {
                                                                            key: 'quarter',
                                                                            value: 'Квартал'
                                                                        },
                                                                        {
                                                                            key: 'year',
                                                                            value: 'Год'
                                                                        }
                                                                    ]}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                                            <Grid item>
                                                                <Typography>
                                                                    Дата с
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                    <KeyboardDatePicker
                                                                        className={classes.date}
                                                                        fullWidth
                                                                        invalidDateMessage="Некорректная дата"
                                                                        minDate={new Date("1970-01-01")}
                                                                        minDateMessage={""}
                                                                        disableToolbar
                                                                        orientation="landscape"
                                                                        variant="inline"
                                                                        openTo="year"
                                                                        name="beginning"
                                                                        format="dd-MM-yyyy"
                                                                        autoOk
                                                                        required
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        value={beginning}
                                                                        onChange={date => {
                                                                            setBeginning(date)
                                                                        }}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                                            <Grid item>
                                                                <Typography>
                                                                    по
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                    <KeyboardDatePicker
                                                                        className={classes.date}
                                                                        fullWidth
                                                                        invalidDateMessage="Некорректная дата"
                                                                        disableToolbar
                                                                        orientation="landscape"
                                                                        variant="inline"
                                                                        openTo="year"
                                                                        name="ending"
                                                                        format="dd-MM-yyyy"
                                                                        autoOk
                                                                        required
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        value={ending}
                                                                        onChange={date => {
                                                                            setEnding(date)
                                                                        }}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {(AuthorizationService.roles(account, 'customer') && (type && ((type.value !== "purchase_history") && (type.value !== 'delivery_orders_after_fact')))) &&
                                                        <Grid item>
                                                            <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                                                <Grid item>
                                                                    <Typography>
                                                                        Договор
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <ButtonList
                                                                        className={classes.list}
                                                                        value={contract}
                                                                        onChange={handleContract}
                                                                        values={contracts}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    <Grid item>
                                                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                                            <Grid item>
                                                                <Typography>
                                                                    Статус
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Select
                                                                    className={classes.status}
                                                                    value={statuses}
                                                                    multiple
                                                                    disabled={!type || ((type.value === 'supplier_activity') || (type.value === 'order_supplier_activity') || (type.value === 'user_activity') || (type.value === 'share_suppliers') || (type.value === 'share_categories') || (type.value === 'share_standards'))}
                                                                    onChange={(event) => {
                                                                        setStatuses(event.target.value)
                                                                    }}
                                                                >
                                                                    {status && status.filter(item => item.index !== 0).map((item) => {
                                                                        return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                                    })}
                                                                </Select>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="overline" gutterBottom className={classes.suppliers}>{AuthorizationService.roles(account, 'customer') ? 'Поставщики' : 'Заказчики'}:</Typography>
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    id="search"
                                                    variant="standard"
                                                    name={'search'}
                                                    placeholder={'Поиск'}
                                                    fullWidth={true}
                                                    margin={'normal'}
                                                    onChange={(event) => {
                                                        setSearch(event.target.value)
                                                    }}
                                                    classes={{
                                                        root: clsx(classes.filterLabel, classes.searchLabel)
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <SearchIcon style={{color: '#c1c1c1'}}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <TableContainer className={classes.tableContainer}>
                                            <Table ref={tableRef} stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                indeterminate={selected.length > 0 && selected.length < companies.meta.total}
                                                                checked={companies.meta.total > 0 && selected.length === companies.meta.total}
                                                                onChange={handleSelectAll}
                                                            />
                                                        </TableCell>
                                                        {columns.map((column) => (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                style={{ minWidth: column.minWidth }}
                                                            >
                                                                {column.label}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {companies.data && companies.data.map((company) => {
                                                        const isItemSelected = isSelected(company.id);

                                                        return (
                                                            <TableRow
                                                                onClick={(event) => handleSelect(event, company.id)}
                                                                className={classes.tableRow}
                                                                hover key={company.id}
                                                                selected={isItemSelected}
                                                            >
                                                                <TableCell padding="checkbox">
                                                                    <Checkbox
                                                                        checked={isItemSelected}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    {company.legal_detail.name}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {company.legal_detail.inn ?? company.legal_detail.bin}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.footer}>
                                <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                    <Grid item className={classes.footerContent}>
                                        <Grid container className={classes.footerContainer} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                            <Grid item>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                                    component='div'
                                                    labelRowsPerPage={'Записей на странице:'}
                                                    count={companies.meta.total}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Submit
                                                    disableElevation
                                                    variant="contained"
                                                    color="primary"
                                                    type="button"
                                                    disabled={isSubmitting || !selected.length || !type}
                                                    onClick={() => {
                                                        setIsSubmitting(true)
                                                        return dispatch(ReportActions.add({
                                                            companies: selected,
                                                            beginning: format(beginning, 'yyyy-MM-dd'),
                                                            ending: format(ending, 'yyyy-MM-dd'),
                                                            type: type.value,
                                                            ...(statuses.length ? {status: statuses} : {}),
                                                        })).then(
                                                            () => {
                                                                dispatch(ReportActions.reports({
                                                                    beginning: filter.beginning,
                                                                    ending: filter.ending,
                                                                    type: filter.type.value
                                                                }))
                                                                setIsSubmitting(false)
                                                            },
                                                            () => {
                                                                setIsSubmitting(false)
                                                            }
                                                        )
                                                    }}
                                                >
                                                    Сформировать отчет
                                                </Submit>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}
