import React, {useEffect, useState} from "react"
import {
    Grid,
    FormControl,
    InputLabel,
    Select,
    IconButton,
    ListItemIcon,
    ListItemText,
    makeStyles,
    MenuItem,
    ListSubheader,
    Typography
} from "@material-ui/core"
import {ExpandLess, ExpandMore} from "@material-ui/icons"
import {Submit} from "../Button/Submit";
import {useDispatch} from "react-redux";

const useStyles = makeStyles(theme => ({
    disabled: {
        'color': 'grey'
    },
    default: {
        'color': 'inherit'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    },
    listItemSubheader: {
        'bottom': 0,
        'background-color': 'white'
    },
    select: {
        "color": "#999 !important"
    },
    control: {
        "background-color": "#fff",
        "& .MuiInputLabel-outlined": {
            "transform": "translate(14px, 9px) scale(1)"
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            "transform": "translate(14px, -6px) scale(0.75)"
        },
        "& .MuiOutlinedInput-input": {
            "background": "#ffffff",
            "padding": "8px 15px 8px 15px"
        },
        "& .MuiOutlinedInput-root": {
            "border-radius": "2px",
            '& fieldset': {
                "border": "solid 2px #c1c1c1"
            },
            '&:hover fieldset': {
                "border": "solid 2px #898989"
            }
        },
        "& .MuiSelect-outlined": {
            "font-weight": "400",
            "font-size": "14px",
            "font-family": '"Helvetica Neue","Segoe UI",helvetica,verdana,sans-serif',
            "line-height": "1.35715",
            "border-radius": "0",
            '& fieldset': {
                "border": "solid 2px #c1c1c1"
            },
            '&:hover fieldset': {
                "border": "solid 2px #898989"
            }
        }
    }
}))

export const SelectTree = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {label, multiple = false, variant, onChange, options = [], reset} = props

    const [open, setOpen] = useState({})
    const [value, setValue] = useState(props.value ?? (multiple ? [] : ''))
    const [display, setDisplay] = useState(false)

    useEffect(() => {
        if (reset) {
            setValue(multiple ? [] : '')
        }
    }, [dispatch, reset]);

    const assembly = (options, parent = 0, level = 0) => {
        let result = []

        if (options.hasOwnProperty(parent)) {
            options[parent].sort((first, second) => {
                return (first.name > second.name) ? 1 : (second.name > first.name) ? -1 : 0
            }).forEach(option => {
                result.push(
                    <MenuItem
                        key={option.id}
                        value={option.id}
                        style={{paddingLeft: `${16 * (level + 1)}px`}}
                    >
                        {options.hasOwnProperty(option.id) ? (
                            <ListItemIcon className={classes.listItemIcon}>
                                <IconButton
                                    size="small"
                                    onClick={e => {
                                        e.stopPropagation()
                                        if (options.hasOwnProperty(option.id)) {
                                            setOpen({...open, ...{[option.id]: open.hasOwnProperty(option.id) ? !open[option.id] : true}})
                                        }
                                    }}
                                >
                                    {(open.hasOwnProperty(option.id) && open[option.id]) ? <ExpandLess/> :
                                        <ExpandMore/>}
                                </IconButton>
                            </ListItemIcon>
                        ) : null}
                        <ListItemText
                            className={options.hasOwnProperty(option.id) ? classes.listItemTextWithIcon : classes.listItemText}
                            primary={!level ?
                                <Typography variant="body1" className={classes.default}>{option.name}</Typography> :
                                <Typography variant="body2" className={classes.default}>{option.name}</Typography>}/>
                    </MenuItem>
                )

                const childes = assembly(options, option.id, level + 1)

                if (!!childes.length && open.hasOwnProperty(option.id) && open[option.id]) {
                    result = result.concat([
                        childes.map(row => row)
                    ])
                }
            })
        }

        return result
    }

    const getTree = options => {
        let tmp = {}
        options.forEach(option => {
            if (!tmp.hasOwnProperty((option.parent !== null) ? option.parent.id : 0)) {
                tmp[(option.parent !== null) ? option.parent.id : 0] = []
            }

            tmp[(option.parent !== null) ? option.parent.id : 0].push(option)
        })

        return assembly(tmp)
    }

    return (
        <FormControl fullWidth variant={variant} {...((variant === 'outlined') ? {className: classes.control} : {})}>
            {((variant === 'outlined') || !label) ? null : <InputLabel>{label}</InputLabel>}
            <Select
                value={value}
                multiple={multiple}
                displayEmpty={(variant === 'outlined')}
                classes={((variant === 'outlined') && !value.length) ? {
                    root: classes.select
                } : {}}
                renderValue={(selected) => {
                    return (selected || selected.length) ? options.filter(option => (multiple ? selected.includes(option.id) : (option.id === selected))).map(option => option.name).join(', ') : label;
                }}
                onChange={(e) => {
                    setDisplay(true)
                    setValue(e.target.value)
                }}
                open={display}
                onOpen={() => setDisplay(true)}
                onClose={() => {
                    setValue(props.value ?? (multiple ? [] : ''))
                    setDisplay(false)
                }}
            >
                {getTree(options)}
                <ListSubheader className={classes.listItemSubheader}>
                    <Grid container direction="row" justify="flex-end" alignItems="stretch">
                        <Grid item>
                            <Submit
                                disableElevation
                                variant="contained"
                                color="primary"
                                type="button"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onChange(value)
                                    setDisplay(false)
                                }}
                            >
                                Подтвердить
                            </Submit>
                        </Grid>
                    </Grid>
                </ListSubheader>
            </Select>
        </FormControl>
    )
}
