export default function products(state = {reportProducts: {data: [], meta: {total: 0, per_page: 15, current_page: 1}}}, action) {
    switch (action.type) {
        case "REPORT_PRODUCTS_REQUEST":
            return {
                reportProducts: state.reportProducts,
            }
        case "REPORT_PRODUCTS":
            return {
                reportProducts: action.payload,
            }
        case "REPORT_PRODUCTS_FAILURE":
            return {
                reportProducts: state.reportProducts
            }
        default:
            return state
    }
}
