import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce} from 'use-debounce'
import format from "date-fns/format"
import {IntegratedSummary} from '@devexpress/dx-react-grid'
import {Table} from "@devexpress/dx-react-grid-material-ui";
import {
    Grid,
    InputAdornment,
    makeStyles,
    TextField,
} from "@material-ui/core"
import {
    Search as SearchIcon,
    GetApp as GetAppIcon
} from "@material-ui/icons"
import {green} from "@material-ui/core/colors"
import {CommentOutlined as CommentOutlinedIcon} from "@material-ui/icons"

import {Submit} from "../../../../App/components/Button/Submit"
import {getMonthName} from "../../../../App/helpers/date"
import {AuthorizationService} from "../../../../Auth/services/authorization"
import {ColumnChooser} from "../../../../App/components/Table/ColumnChooser"
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon"
import {ItemsActions} from "../../../actions/items"
import {ChatActions} from '../../../actions/comment/chat'
import {DownloadActions} from "../../../../Download/actions/download";
import {CustomTable} from "../../../../App/components/Table/Table";
import {TableHeader} from "../../../../App/components/Table/TableHeader";
import {Copy as CopyButton} from "../../Products/Buttons/Copy";
import {Move as MoveButton} from "../../Products/Buttons/Move";
import {Create as CreateLotButton} from "../../Lot/Buttons/Create";
import {Edit as EditButton} from "../../Lot/Buttons/Product/Edit";
import {Delete as DeleteButton} from "../../Lot/Buttons/Product/Delete";
import {localState} from "../../../../App/components/LocalState";
import {SettingsActions} from "../../../../Settings/actions/settings";

const useStyles = makeStyles(() => ({
    filterLabel: {
        "background": "#fff",
        "margin-bottom": "0 !important",
        "margin-top": "5px !important",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        },
        "& input": {
            padding: "6px 15px 7px"
        }
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 414px)",
        "min-height": "calc(100vh - 414px)",
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    submit: {
        "height": "35px",
    },
    active: {
        'height': '41px',
        'background-color': `${green[100]} !important`,
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    disabled: {
        'height': '41px',
        'background-color': 'rgba(244, 244, 244, 1)',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    loading: {
        'height': '41px',
        'background-color': '#fff',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },

        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    iconButton: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    },
    circle: {
        width: "25px",
        height: "25px",
        backgroundColor: "#ea4a4a",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        "&>span": {
            lineHeight: "20px"
        }
    }
}))

const productColumns = [
    {name: 'comment_counter', title: ' '},
    {name: 'number', title: 'Номер ОЗ'},
    {name: 'name', title: 'Начальное наименование'},
    {name: 'standard_name', title: 'Эталонное наименование'},
    {name: 'typed_name', title: 'Типизированное наименование'},
    {name: 'category_name', title: 'Категория', filter: 'categories'},
    {name: 'subcategory', title: 'Подкатегория', filter: 'subcategories'},
    {name: 'group', title: 'Группа', filter: 'groups'},
    {name: 'unit', title: 'Ед. измерения'},
    {name: 'funding_sources', title: 'Источник финансирования'},
    {name: 'generic', title: 'МНН'},
    {name: 'purchase_number', title: 'Закупка'},
    {name: 'lot_number', title: 'Лот'},
    {name: 'lot_name', title: 'Наименование лота'},
    {name: 'price', title: 'Цена'},
    {name: 'month', title: 'Месяц', filter: 'month'},
    {name: 'amount', title: 'Кол-во'},
    {name: 'total', title: 'Сумма'},
    {name: 'railRoad', title: 'ЖД', filter: 'rail_roads'},
    {name: 'company', title: 'ЧУЗ', filter: 'companies'},
    {name: 'creator', title: 'Cоздатель', filter: 'creator'},
    {name: 'created_at', title: 'Дата создания', filter: 'created_at'},
    {name: 'editor', title: 'Редактор'},
    {name: 'updated_at', title: 'Дата редактироания'},
    {name: 'main_technical_specifications', title: 'Технические характеристики'},
    {name: 'analogues', title: 'Аналоги'},
    {name: 'equipment', title: 'Оборудование'},
    {name: 'medical_code', title: 'Код вида мед. изделия'},
    {name: 'exclusivity', title: 'Обоснование'},
    {name: 'description', title: 'Описание'}
]

export const Products = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {purchaseID} = useParams()
    const {
        account,
        purchase,
    } = useSelector(state => ({...state.purchasePlans, ...state.account, ...state.purchases}))
    const {filter} = useSelector(state => state.filters['planning_purchase_plan_purchase_lot_products'])

    const [products, setProducts] = useState({...localState,
        agg_info: {
            total_amount: 0,
            total_sum: 0,
        }
    })
    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)

    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)
    const [selection, setSelection] = useState([])
    const [selectionItems, setSelectionItems] = useState([])
    const [selectionPage, setSelectionPage] = useState({})
    const {
        totalSummaryItems,
        tableColumnExtensions,
        setOpenComment,
        setChat,
        tableRef,
        setTableRef,
        messages
    } = props

    const [initialize, setInitialize] = useState(false)
    const [loading, setLoading] = useState(false)

    const [columnProductWidths, setColumnProductWidths] = useState([
        {columnName: 'comment_counter', width: 50},
        {columnName: 'number', width: 150},
        {columnName: 'name', width: 200},
        {columnName: 'standard_name', width: 200},
        {columnName: 'typed_name', width: 200},
        {columnName: 'category_name', width: 200},
        {columnName: 'subcategory', width: 200},
        {columnName: 'group', width: 200},
        {columnName: 'unit', width: 125},
        {columnName: 'funding_sources', width: 200},
        {columnName: 'generic', width: 125},
        {columnName: 'purchase_number', width: 125},
        {columnName: 'lot_number', width: 125},
        {columnName: 'lot_name', width: 150},
        {columnName: 'price', width: 125},
        {columnName: 'month', width: 125},
        {columnName: 'amount', width: 125},
        {columnName: 'total', width: 155},
        {columnName: 'railRoad', width: 125},
        {columnName: 'company', width: 150},
        {columnName: 'creator', width: 125},
        {columnName: 'created_at', width: 125},
        {columnName: 'editor', width: 125},
        {columnName: 'updated_at', width: 150},
        {columnName: 'main_technical_specifications', width: 150},
        {columnName: 'analogues', width: 150},
        {columnName: 'equipment', width: 150},
        {columnName: 'medical_code', width: 150},
        {columnName: 'exclusivity', width: 150},
        {columnName: 'description', width: 150}
    ])

    const [columnOrder, setColumnOrder] = useState([
        'comment_counter',
        'number',
        'name',
        'standard_name',
        'typed_name',
        'category_name',
        'subcategory',
        'group',
        'unit',
        'funding_sources',
        'generic',
        'purchase_number',
        'lot_number',
        'lot_name',
        'price',
        'month',
        'amount',
        'total',
        'railRoad',
        'company',
        'creator',
        'created_at',
        'editor',
        'updated_at',
        'main_technical_specifications',
        'analogues',
        'equipment',
        'medical_code',
        'exclusivity',
        'description'
    ])

    useEffect(() => {
        const getSettings = async () => {
            await dispatch(SettingsActions.table('planning_purchase_plan_purchase_lot_products')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
            })
        }

        if (!initialize) {
            getSettings().then(() =>
                setInitialize(true)
            )
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'planning_purchase_plan_purchase_lot_products',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    const getProductsParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...((filter.hasOwnProperty('sources') && !!filter.sources.length) ? {sources: filter.sources} : {}),
            ...(searchRequest ? {search: searchRequest} : {}),
        }
    }

    const getProducts = async () => {
        let params = {
            limit: rowsPerPage,
            page: page,
            ...getProductsParams()
        }
        const response = await dispatch(ItemsActions.items(purchaseID, params))
        setProducts(response)
    }

    useEffect(() => {
        if (!loading) {
            getProducts().then(() => {
                setLoading(true)
                tableRef && tableRef.current && tableRef.current.scrollIntoView()
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loading])

    useEffect(() => {
        if (loading) {
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [rowsPerPage, page, searchRequest, filter])

    const handleProductPageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: 'PLANNING_PURCHASE_PLAN_PURCHASE_LOT_PRODUCTS_FILTER', payload: {limitRows: newRowsPerPage}})
    }

    const summaryCalculator = (type, rows, getValue) => {
        if (type === 'total_amount') {
            return (products.agg_info.total_amount ? parseFloat(products.agg_info.total_amount) : 0).toLocaleString('ru-RU');
        }
        if (type === 'total_sum') {
            return (products.agg_info.total_sum ? parseFloat(products.agg_info.total_sum) : 0).toLocaleString('ru-RU', {
                style: 'currency',
                currency: 'RUB'
            });
        }
        return IntegratedSummary.defaultCalculator(type, rows, getValue);
    };

    const createChat = async (values) => {
        return await dispatch(ChatActions.create(values))
    }

    const addUserToChat = async (chatId) => {
        return await dispatch(ChatActions.addUserToChat(chatId))
    }

    return <React.Fragment>
        <Grid item xs={12}>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                        <CreateLotButton
                            purchase={purchase}
                        />
                        <EditButton
                            disabled={(selectionItems.length !== 1)}
                            purchase={purchase}
                            item={selectionItems[0]}
                        />
                        <Grid item>
                            <Submit
                                className={classes.submit}
                                disableElevation
                                disabled={Boolean(selectionItems.length !== 1)}
                                variant="contained"
                                color="primary"
                                type="button"
                                onClick={() => {
                                    let product = selectionItems[0];
                                    if (product.chat_id) {
                                        let chat_id = product.chat_id
                                        addUserToChat(chat_id).then(() => {
                                            setChat({
                                                id: chat_id,
                                                title: `Комментарии к ОЗ ${product.name}`
                                            })
                                            setOpenComment(true)
                                        })
                                    } else {
                                        createChat({itemId: product.id}).then(resp => {
                                            setChat({
                                                id: resp.chat.id,
                                                title: `Комментарии к ОЗ ${product.name}`
                                            })
                                            setOpenComment(true)
                                        })
                                    }
                                }}
                            >
                                Комментарии
                            </Submit>
                        </Grid>
                        <CopyButton
                            items={selectionItems}
                            onClick={() => {
                                setSelection([])
                                setSelectionItems([])
                                setSelectionPage({})
                                setLoading(false)
                                dispatch({type: 'PURCHASE_PLAN_ITEMS_BY_PARENT_IDS_CLEAR'})
                            }}
                        />
                        <MoveButton
                            items={selectionItems}
                            onClick={() => {
                                setSelection([])
                                setSelectionItems([])
                                setSelectionPage({})
                                setLoading(false)
                                dispatch({type: 'PURCHASE_PLAN_ITEMS_BY_PARENT_IDS_CLEAR'})
                            }}
                        />
                        <DeleteButton
                            purchase={purchase}
                            items={selectionItems}
                            onClick={() => {
                                setSelection([])
                                setSelectionItems([])
                                setSelectionPage({})
                                setLoading(false)
                            }}
                        />
                        <Grid item>
                            <ButtonIcon
                                type="button"
                                size="small"
                                disabled={!products.meta.total}
                                onClick={() => {
                                    dispatch(DownloadActions.create({
                                        type: 'planning_purchase_items',
                                        columns: columnOrder.filter(name => (hiddenColumnNames.indexOf(name) < 0)).map(name => {
                                            switch (name) {
                                                case 'category_name':
                                                    return 'category';
                                                default:
                                                    return name;
                                            }
                                        }),
                                        params: {
                                            order: (filter.sort.name && filter.sort.direction) ? `${filter.sort.name}, ${filter.sort.direction}` : `created, desc`,
                                            type: 'planning_purchase_items',
                                            'purchase_id': purchaseID,
                                            ...Object.entries(getProductsParams()).reduce((result, [key, value]) => {
                                                result[key] = Array.isArray(value) ? value.join(',') : value

                                                return result
                                            }, {})
                                        }
                                    }))
                                }}
                                children={<GetAppIcon/>}
                                title='Скачать таблицу'
                                className={classes.iconButton}
                            />
                        </Grid>
                        <Grid item>
                            <ColumnChooser columns={productColumns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="search"
                        variant="standard"
                        name={'search'}
                        placeholder={`Поиск: наименование объекта закупки, начальное наименование, МНН`}
                        fullWidth={true}
                        margin={'normal'}
                        onChange={(event) => {
                            setSearch(event.target.value)
                        }}
                        classes={{
                            root: classes.filterLabel
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon style={{color: '#c1c1c1'}}/>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
        <CustomTable
            meta={products.meta}
            tableName={'planning_purchase_plan_purchase_lot_products'}
            rows={products.data.map(product => ({
                number: product.number,
                name: product.name,
                standard: product.standard,
                standard_name:  product.standard?.name.standard,
                typed_name: product.standard?.confirmed ? product.standard?.name.typed : null,
                category_name: product.category?.dependencies[0],
                subcategory: product.category?.dependencies[1],
                group: product.category?.dependencies[2],
                category: product.category,
                unit: product.unit?.short,
                funding_sources: product.funding_sources ? product.funding_sources.join(', ') : '',
                purchase_number: product.purchase_number,
                lot_number: product.lot_number,
                lot_name: product.lot_name,
                price: parseFloat(product.price).toLocaleString('ru-RU', {
                    style: 'currency',
                    currency: 'RUB'
                }),
                generic: product.standard?.params?.generic,
                month: getMonthName(new Date(product.demand_start_date)),
                amount: parseFloat((!product.amount || product.joint) ? 0 : product.amount).toLocaleString('ru-RU'),
                total: parseFloat(product.price * product.amount).toLocaleString('ru-RU', {
                    style: 'currency',
                    currency: 'RUB'
                }),
                joint: product.joint,
                railRoad: product.railRoad,
                company: product.company,
                created_at: format(new Date(product.created_at), 'dd.MM.yyyy'),
                creator: product.creator,
                updated_at: product.editor ? format(new Date(product.updated_at), 'dd.MM.yyyy') : null,
                editor: product.editor,
                medical_code: product.medical_code,
                exclusivity: product.exclusivity,
                description: product.description,
                equipment: product.equipment,
                chat_id: product.chat_id,
                main_technical_specifications: product.main_technical_specifications ? product.main_technical_specifications.map(spec => (
                    <p style={{margin: '5px 10px'}}>{spec}</p>)) : null,
                analogues: product.analogues ? product.analogues.map(analogues => (
                    <p style={{margin: '5px 10px'}}>{analogues}</p>)) : null
            }))}
            columns={productColumns}
            page={{page: page, setPage: setPage, rowsPerPage: rowsPerPage, handlePageSizeChange: handleProductPageSizeChange}}
            selection={{selection: selection, setSelection: setSelection}}
            totalSummaryItems={totalSummaryItems}
            summaryCalculator={summaryCalculator}
            setTableRef={setTableRef}
            tableColumnExtensions={tableColumnExtensions}
            columnsSettings={{columnOrder: columnOrder, setColumnOrder: setColumnOrder, setColumnWidths: setColumnProductWidths, columnWidths: columnProductWidths, hiddenColumnNames: hiddenColumnNames, setHiddenColumnNames: setHiddenColumnNames}}
            tableHeader={TableHeader}
            filterActionType={'PLANNING_PURCHASE_PLAN_PURCHASE_LOT_PRODUCTS_FILTER'}
            getValues={ItemsActions.filter}
            onSelectionChange={(numbers) => {
                setSelection(numbers)
                const data = products.data.filter((item, index) => (numbers.find(key => (key === index)) !== undefined)).map(item => item)
                setSelectionItems([
                    ...data,
                    ...selectionItems.filter(item => !products.data.find(el => el.id === item.id))
                ])
                setSelectionPage(!!data.length ? {...selectionPage, [page]: data.map(item => item.id)} : Object.fromEntries(
                    Object.entries(selectionPage).filter(key => (parseInt(key) !== parseInt(page))).map(([key, value]) => [key, value])
                ))
            }}
            messages={messages}
            classInputContent={classes.inputContent}
            rowComponentSelection={(props) => {
                const {tableRow, children, highlighted, onToggle} = props
                const {row} = tableRow

                if (Object.keys(products.agg_info.chats_with_count_not_read_comments).length > 0 && row.chat_id && products.agg_info.chats_with_count_not_read_comments[row.chat_id]) {
                    row.count_unread_messages = products.agg_info.chats_with_count_not_read_comments[row.chat_id]
                } else {
                    row.count_unread_messages = 0
                }

                row['comment_counter'] =
                    <div className={classes.circle}>
                        <span>{row.count_unread_messages}</span>
                    </div>

                return (
                    <Table.Row
                        tableRow={tableRow}
                        children={children}
                        onClick={() => {
                            if (!window.getSelection().toString()) {
                                onToggle()
                            }
                        }}
                        className={highlighted ? classes.active : (((row.loaded === 'Загружен') || !row.joint) ? classes.default : (row.joint ? classes.disabled : classes.loading))}
                        row={row}
                        style={{...({backgroundColor: AuthorizationService.roles(account, ['customer', 'supervisor']) ? (row.category?.active ? (row.standard?.deleted ? '#ffcccc' : (!row.standard?.confirmed ? '#fffde7' : {})) : {}) : {}})}}
                    />
                )
            }}
            rowComponent={({row, tableRow, children}) => (
                <Table.Row
                    tableRow={tableRow}
                    children={children}
                    className={classes.default}
                    row={row}
                />
            )}
            filters={{
                funding_sources: {
                    name: 'sources',
                    type: 'source',
                },
                comment_counter: {
                    button: <CommentOutlinedIcon
                        fontSize="small"
                        style={{display: "block", margin: "auto"}}
                    />
                }
            }}
        />
    </React.Fragment>
}
