import React, {useState} from "react"
import DataGrid, {Column, RowDragging, Scrolling, Selection,} from "devextreme-react/data-grid";

import {
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    makeStyles
} from "@material-ui/core"

import {Index as PurchasesTableDialog} from "../../Planning/components/Purchase/TableDialog/Index"

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        "max-height": "calc(100vh - 210px)",
        minHeight: "calc(100% - 64px)",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 150,
    }
}))

export const OperationBetweenTwoTables = (props) => {
    const classes = useStyles()
    const {
        itemsLeft,
        itemsRight,
        columnsTables,
        onClose,
        fullWidth,
        maxWidth,
        title,
        tableSelectorsLeft,
        tableSelectorsRight,
        open,
        leftToRightCallback,
        rightToLeftCallback,
        updateRightList,
    } = props

    const [selectedRowKeysLeft, setSelectedRowKeysLeft] = useState([])
    const [selectedRowKeysRight, setSelectedRowKeysRight] = useState([])

    const [dialog, setDialog] = useState(false)

    const onSelectionChangedLeft = ({selectedRowKeys}) => {
        setSelectedRowKeysLeft(selectedRowKeys)
    }

    const onSelectionChangedRight = ({selectedRowKeys}) => {
        setSelectedRowKeysRight(selectedRowKeys)
    }

    const handleOpenDialog = (id) => {
        id === "purchases" ? setDialog(true) : setDialog(false)
    };

    const handleCloseDialog = () => {
        setDialog(false);
    };

    const onAddGrid = (e) => {
        if (e.fromData === "leftList") {
            if (selectedRowKeysLeft.length) {
                leftToRightCallback(selectedRowKeysLeft).then(
                    () => {
                        updateRightList().then(() => {
                        })
                        setSelectedRowKeysLeft([])
                    },
                    () => {
                        updateRightList().then(() => {
                        })
                    }
                );
            }
        } else if (e.fromData === "rightList") {
            if (selectedRowKeysRight.length) {
                rightToLeftCallback(selectedRowKeysRight).then(
                    () => {
                        setSelectedRowKeysRight([])
                        updateRightList().then(() => {
                        })
                    },
                    () => {
                        updateRightList().then(() => {
                        })
                    }
                );
            }
        }
    };

    return (
        <Dialog
            open={!!open}
            onClose={onClose}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                    <Grid item xs={6}>
                        <Grid container direction={"row"} alignItems={"center"} justify={"flex-start"} spacing={2}>
                            {tableSelectorsLeft.map((selector) => (
                                <Grid item xs={4}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink id={selector.id}>{selector.title}</InputLabel>
                                        <Select
                                            labelId={selector.id}
                                            fullWidth
                                            value={selector.value}
                                            onChange={selector.callback}
                                        >
                                            {selector.items.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container direction={"row"} alignItems={"center"} justify={"flex-end"} spacing={2}>
                            {tableSelectorsRight.map((selector) => (
                                <Grid item xs={4}>
                                    {(selector.id === "purchases") ? (
                                        <PurchasesTableDialog
                                            dialog={dialog}
                                            setDialog={setDialog}
                                            purchases={selector.items}
                                            meta={selector.meta}
                                            currentPage={selector.pagination.page}
                                            rowsPerPage={selector.pagination.rowsPerPage}
                                            handlePageChange={selector.pagination.handlePageChange}
                                            handlePageSizeChange={selector.pagination.handlePageSizeChange}
                                            setSearch={selector.search.setSearch}
                                            open={dialog}
                                            onClose={handleCloseDialog}
                                            callback={selector.callback}
                                        />
                                    ) : null}
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink id={selector.id}>{selector.title}</InputLabel>
                                        <Select
                                            labelId={selector.id}
                                            fullWidth
                                            value={selector.value}
                                            onChange={selector.callback}
                                            disabled={selector.isDisabled}
                                            inputProps={{
                                                readOnly: selector.id === "purchases",
                                            }}
                                            onClick={() =>
                                                handleOpenDialog(selector.id)
                                            }
                                        >
                                            {selector.items.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <DataGrid
                            dataSource={itemsLeft}
                            height={440}
                            showBorders={true}
                            selectedRowKeys={selectedRowKeysLeft}
                            onSelectionChanged={onSelectionChangedLeft}
                        >
                            <RowDragging
                                data={"leftList"}
                                group="productGroup"
                                onAdd={onAddGrid}
                                showDragIcons={false}
                            />
                            <Scrolling mode="virtual" />
                            <Selection
                                selectAllMode={"allPages"}
                                mode="multiple"
                                showCheckBoxesMode="always"
                            />
                            {columnsTables.map((column) => (
                                <Column
                                    caption={column.caption}
                                    dataField={column.dataField}
                                    dataType={column.type}
                                    width={column.width}
                                />
                            ))}
                        </DataGrid>
                    </Grid>
                    <Grid item xs={6}>
                        <DataGrid
                            dataSource={itemsRight}
                            height={440}
                            showBorders={true}
                            selectedRowKeys={selectedRowKeysRight}
                            onSelectionChanged={onSelectionChangedRight}
                        >
                            <RowDragging
                                data={"rightList"}
                                group="productGroup"
                                onAdd={onAddGrid}
                                showDragIcons={false}
                            />
                            <Scrolling mode="virtual" />
                            <Selection
                                electAllMode={"allPages"}
                                mode="multiple"
                                showCheckBoxesMode="always"
                            />
                            {columnsTables.map((column) => (
                                <Column
                                    caption={column.caption}
                                    dataField={column.dataField}
                                    dataType={column.type}
                                    width={column.width}
                                />
                            ))}
                        </DataGrid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
