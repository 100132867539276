import {HttpService} from "../../App/services/http"

export const ContractService = {
    contracts,
    statuses
}

function contracts(params) {
    return HttpService.get(`/report/contracts`, params)
}

function statuses() {
    return HttpService.get(`/contract/statuses`)
        .then(response => {
            return response.statuses
        })
}
