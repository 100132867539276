import {WarehouseService} from "../services/warehouse"

export const WarehouseActions = {
    get,
    getByAdmin,
    edit
}

function get(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "WAREHOUSES_REQUEST"})

        WarehouseService.get(params)
            .then(
                response => {
                    dispatch({type: "WAREHOUSES_SUCCESS", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "WAREHOUSES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function getByAdmin(supId, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "WAREHOUSES_REQUEST"})

        WarehouseService.getByAdmin(supId, params)
            .then(
                response => {
                    dispatch({type: "WAREHOUSES_SUCCESS", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "WAREHOUSES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function edit(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "WAREHOUSE_EDIT_REQUEST"})
        dispatch({type: "FILLING", payload: true})
        WarehouseService.edit(values)
            .then(
                account => {
                    dispatch({type: "FILLING", payload: false})
                    dispatch({type: "WAREHOUSE_EDIT_SUCCESS", payload: account})
                    resolve()
                },
                error => {
                    dispatch({type: "WAREHOUSE_EDIT_FAILURE"})
                    dispatch({type: "FILLING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )

    })
}
