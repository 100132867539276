import React from "react"
import {Field, FieldArray} from "formik"
import { TextField } from "formik-material-ui"

import {Card, CardContent, Grid, IconButton, makeStyles, Typography} from "@material-ui/core"
import {Autocomplete, createFilterOptions} from '@material-ui/lab'
import {Add, Clear} from "@material-ui/icons"
import {Hidden} from "./Hidden";

const useStyles = makeStyles(() => ({
    fullWidth: {
        'width': '100%'
    },
    card: {
        'width': '100%',
        'border-radius': 'unset'
    },
    label: {
        'color': 'rgba(0, 0, 0, 0.54)',
        'padding': '0',
        'font-size': '0.75rem',
        'font-weight': '400',
        'line-height': '1',
        'letter-spacing': '0.00938em'
    }
}))

export const FieldCompositeSize = (props) => {
    const classes = useStyles()

    const { id, label, params, items, values, setFieldValue, setFieldTouched, isSubmitting, disabled } = props

    return (
        <Grid item className={classes.fullWidth}>
            <Card className={classes.card}>
                <CardContent>
                    <Grid item className={classes.fullWidth}>
                        <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                    <Grid item sm={9} className={classes.fullWidth}>
                                        <Typography variant="caption" className={classes.label}>{label}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                {Object.entries(params).reduce((result, [key, index]) => {
                                    if (index) {
                                        result[index] = key
                                    }

                                    return result
                                }, []).map((param, index) => {
                                    let component

                                    switch (param) {
                                        case 'prefix':
                                            component = <Hidden key={index} condition={(params && params['prefix'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item xs={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`attributes.${id}.prefix`}
                                                            disabled={isSubmitting || disabled}
                                                            label="Префикс"
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'postfix':
                                            component = <Hidden key={index} condition={(params && params['postfix'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`attributes.${id}.postfix`}
                                                            disabled={isSubmitting || disabled}
                                                            label="Постфикс"
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'amount':
                                            component = <Hidden key={index} condition={(params && params['amount'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item xs={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`attributes.${id}.amount`}
                                                            type="number"
                                                            disabled={isSubmitting || disabled}
                                                            label="Количество"
                                                            inputProps={{
                                                                step: 0.0001,
                                                                onWheel: e => {
                                                                    e.target.blur()
                                                                    e.stopPropagation()
                                                                    setTimeout(() => {
                                                                        e.target.focus()
                                                                    }, 0)
                                                                }
                                                            }}
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'height':
                                            component = <Hidden key={index} condition={(params && params['height'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`attributes.${id}.height`}
                                                            type="number"
                                                            disabled={isSubmitting || disabled}
                                                            label="Высота"
                                                            inputProps={{
                                                                step: 0.0001,
                                                                onWheel: e => {
                                                                    e.target.blur()
                                                                    e.stopPropagation()
                                                                    setTimeout(() => {
                                                                        e.target.focus()
                                                                    }, 0)
                                                                }
                                                            }}
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'space':
                                            component = <Hidden key={index} condition={(params && params['space'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`attributes.${id}.space`}
                                                            disabled={isSubmitting || disabled}
                                                            label="Пробел"
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'metric':
                                            component = <Hidden key={index} condition={(params && params['metric'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`attributes.${id}.metric`}
                                                            disabled={isSubmitting || disabled}
                                                            label="Метрик"
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'thickness':
                                            component = <Hidden key={index} condition={(params && params['thickness'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`attributes.${id}.thickness`}
                                                            type="number"
                                                            disabled={isSubmitting || disabled}
                                                            label="Толщина"
                                                            inputProps={{
                                                                step: 0.0001,
                                                                onWheel: e => {
                                                                    e.target.blur()
                                                                    e.stopPropagation()
                                                                    setTimeout(() => {
                                                                        e.target.focus()
                                                                    }, 0)
                                                                }
                                                            }}
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'unit':
                                            component = <Hidden key={index} condition={(params && params['unit'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={12} className={classes.fullWidth}>
                                                        <Autocomplete
                                                            filterOptions={createFilterOptions({
                                                                matchFrom: 'start',
                                                                stringify: option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')
                                                            })}
                                                            options={items}
                                                            getOptionLabel={option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')}
                                                            disabled={isSubmitting || disabled}
                                                            onChange={(e, value) => {
                                                                setFieldValue(`attributes.${id}.unit`, ((value instanceof Object) ? value.short : value))
                                                            }}
                                                            defaultValue={() => (values.hasOwnProperty(id) ? { short: values[id].unit } : { short: '' })}
                                                            onBlur={(e) => {
                                                                if (items.find(item => item.short === e.target.value)) {
                                                                    setFieldTouched(`attributes.${id}.unit`, true)
                                                                } else {
                                                                    setFieldValue(`attributes.${id}.unit`, '')
                                                                }
                                                            }}
                                                            renderInput={params => (
                                                                <Field
                                                                    fullWidth
                                                                    {...params}
                                                                    name={`attributes.${id}.unit`}
                                                                    type="text"
                                                                    label="Общая Ед.изм."
                                                                    component={ TextField }
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'gauge':
                                            component = <Hidden key={index} condition={(params && params['gauge'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`attributes.${id}.gauge`}
                                                            disabled={isSubmitting || disabled}
                                                            label="Размер Gauge"
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'size.charrier':
                                            component = <Hidden key={index} condition={(params && params['size.charrier'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item xs={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`attributes.${id}.size.charrier`}
                                                            disabled={isSubmitting || disabled}
                                                            label="Размеры по Шарьеру"
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'size.range':
                                            component = <Hidden key={index} condition={(params && params['size.range'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`attributes.${id}.size.range`}
                                                            disabled={isSubmitting || disabled}
                                                            label="Размерный ряд"
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'diameter.outer':
                                            component = <Hidden key={index} condition={(params && params['diameter.outer'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={6} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`attributes.${id}.diameter.outer.value`}
                                                            type="number"
                                                            disabled={isSubmitting || disabled}
                                                            label="Диаметр"
                                                            inputProps={{
                                                                step: 0.0001,
                                                                onWheel: e => {
                                                                    e.target.blur()
                                                                    e.stopPropagation()
                                                                    setTimeout(() => {
                                                                        e.target.focus()
                                                                    }, 0)
                                                                }
                                                            }}
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={6} className={classes.fullWidth}>
                                                        <Autocomplete
                                                            filterOptions={createFilterOptions({
                                                                matchFrom: 'start',
                                                                stringify: option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')
                                                            })}
                                                            options={items}
                                                            getOptionLabel={option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')}
                                                            disabled={isSubmitting || disabled}
                                                            onChange={(e, value) => {
                                                                setFieldValue(`attributes.${id}.diameter.outer.unit`, ((value instanceof Object) ? value.short : value))
                                                            }}
                                                            defaultValue={() => (values.hasOwnProperty(id) ? { short: values[id].unit } : { short: '' })}
                                                            onBlur={(e) => {
                                                                if (items.find(item => item.short === e.target.value)) {
                                                                    setFieldTouched(`attributes.${id}.diameter.outer.unit`, true)
                                                                } else {
                                                                    setFieldValue(`attributes.${id}.diameter.outer.unit`, '')
                                                                }
                                                            }}
                                                            renderInput={params => (
                                                                <Field
                                                                    fullWidth
                                                                    {...params}
                                                                    name={`attributes.${id}.diameter.outer.unit`}
                                                                    type="text"
                                                                    label="Ед. изм."
                                                                    component={ TextField }
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={6} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`attributes.${id}.diameter.outer.delimiter`}
                                                            disabled={isSubmitting || disabled}
                                                            label="Разделитель"
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'diameter.inner':
                                            component = <Hidden key={index} condition={(params && params['diameter.inner'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`attributes.${id}.diameter.inner.value`}
                                                            type="number"
                                                            disabled={isSubmitting || disabled}
                                                            label="Внутренний диаметр"
                                                            inputProps={{
                                                                step: 0.0001,
                                                                onWheel: e => {
                                                                    e.target.blur()
                                                                    e.stopPropagation()
                                                                    setTimeout(() => {
                                                                        e.target.focus()
                                                                    }, 0)
                                                                }
                                                            }}
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                    <Hidden key={index} condition={false}>
                                                        <Grid item sm={6} className={classes.fullWidth}>
                                                            <Autocomplete
                                                                filterOptions={createFilterOptions({
                                                                    matchFrom: 'start',
                                                                    stringify: option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')
                                                                })}
                                                                options={items}
                                                                getOptionLabel={option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')}
                                                                disabled={isSubmitting || disabled}
                                                                onChange={(e, value) => {
                                                                    setFieldValue(`attributes.${id}.diameter.inner.unit`, ((value instanceof Object) ? value.short : value))
                                                                }}
                                                                defaultValue={() => (values.hasOwnProperty(id) ? { short: values[id].unit } : { short: '' })}
                                                                onBlur={(e) => {
                                                                    if (items.find(item => item.short === e.target.value)) {
                                                                        setFieldTouched(`attributes.${id}.diameter.inner.unit`, true)
                                                                    } else {
                                                                        setFieldValue(`attributes.${id}.diameter.inner.unit`, '')
                                                                    }
                                                                }}
                                                                renderInput={params => (
                                                                    <Field
                                                                        fullWidth
                                                                        {...params}
                                                                        name={`attributes.${id}.diameter.inner.unit`}
                                                                        type="text"
                                                                        label="Ед. изм."
                                                                        component={ TextField }
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Hidden>
                                                </Grid>
                                                <Hidden key={index} condition={false}>
                                                    <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                        <Grid item sm={6} className={classes.fullWidth}>
                                                            <Field
                                                                fullWidth
                                                                name={`attributes.${id}.diameter.inner.delimiter`}
                                                                disabled={isSubmitting || disabled}
                                                                label="Разделитель"
                                                                component={TextField}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Hidden>
                                            </Hidden>
                                            break
                                        case 'width':
                                            component = <Hidden key={index} condition={(params && params['width'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={6} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`attributes.${id}.width.value`}
                                                            type="number"
                                                            disabled={isSubmitting || disabled}
                                                            label="Ширина"
                                                            inputProps={{
                                                                step: 0.0001,
                                                                onWheel: e => {
                                                                    e.target.blur()
                                                                    e.stopPropagation()
                                                                    setTimeout(() => {
                                                                        e.target.focus()
                                                                    }, 0)
                                                                }
                                                            }}
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={6} className={classes.fullWidth}>
                                                        <Autocomplete
                                                            filterOptions={createFilterOptions({
                                                                matchFrom: 'start',
                                                                stringify: option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')
                                                            })}
                                                            options={items}
                                                            getOptionLabel={option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')}
                                                            disabled={isSubmitting || disabled}
                                                            onChange={(e, value) => {
                                                                setFieldValue(`attributes.${id}.width.unit`, ((value instanceof Object) ? value.short : value))
                                                            }}
                                                            defaultValue={() => (values.hasOwnProperty(id) ? { short: values[id].unit } : { short: '' })}
                                                            onBlur={(e) => {
                                                                if (items.find(item => item.short === e.target.value)) {
                                                                    setFieldTouched(`attributes.${id}.width.unit`, true)
                                                                } else {
                                                                    setFieldValue(`attributes.${id}.width.unit`, '')
                                                                }
                                                            }}
                                                            renderInput={params => (
                                                                <Field
                                                                    fullWidth
                                                                    {...params}
                                                                    name={`attributes.${id}.width.unit`}
                                                                    type="text"
                                                                    label="Ед. изм."
                                                                    component={ TextField }
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid key={index} container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={6} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`attributes.${id}.width.delimiter`}
                                                            disabled={isSubmitting || disabled}
                                                            label="Разделитель"
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                        // eslint-disable-next-line
                                        case 'lengths':
                                            component = <React.Fragment key={index}>
                                                {component}
                                                <Hidden condition={(params && params['lengths'])}>
                                                    <FieldArray
                                                        name={`attributes.${id}.lengths`}
                                                        render={arrayOptions => (
                                                            <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                                                {props.value.lengths.map((option, key) => {
                                                                    return (((param === 'lengths') && !key) || ((param === 'width') && !!key)) ? <Grid item key={key} className={classes.fullWidth}>
                                                                        <Grid container direction='column' justify='center' alignItems='flex-start' spacing={2}>
                                                                            <Grid item className={classes.fullWidth}>
                                                                                <Grid container direction='row' justify='flex-start' alignItems='flex-end' spacing={2}>
                                                                                    <Grid item xs={6} className={classes.fullWidth}>
                                                                                        <Field
                                                                                            fullWidth
                                                                                            name={`attributes.${id}.lengths[${key}].value`}
                                                                                            type="number"
                                                                                            disabled={isSubmitting || disabled}
                                                                                            label="Длина"
                                                                                            inputProps={{
                                                                                                step: 0.0001,
                                                                                                onWheel: e => {
                                                                                                    e.target.blur()
                                                                                                    e.stopPropagation()
                                                                                                    setTimeout(() => {
                                                                                                        e.target.focus()
                                                                                                    }, 0)
                                                                                                }
                                                                                            }}
                                                                                            component={TextField}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item sm={4} className={classes.fullWidth}>
                                                                                        <Autocomplete
                                                                                            filterOptions={createFilterOptions({
                                                                                                matchFrom: 'start',
                                                                                                stringify: option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')
                                                                                            })}
                                                                                            options={items}
                                                                                            getOptionLabel={option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')}
                                                                                            disabled={isSubmitting || disabled}
                                                                                            onChange={(e, value) => {
                                                                                                setFieldValue(`attributes.${id}.lengths[${key}].unit`, ((value instanceof Object) ? value.short : value))
                                                                                            }}
                                                                                            defaultValue={() => (values.hasOwnProperty(id) ? { short: values[id].unit } : { short: '' })}
                                                                                            onBlur={(e) => {
                                                                                                if (items.find(item => item.short === e.target.value)) {
                                                                                                    setFieldTouched(`attributes.${id}.lengths[${key}].unit`, true)
                                                                                                } else {
                                                                                                    setFieldValue(`attributes.${id}.lengths[${key}].unit`, '')
                                                                                                }
                                                                                            }}
                                                                                            renderInput={params => (
                                                                                                <Field
                                                                                                    fullWidth
                                                                                                    {...params}
                                                                                                    name={`attributes.${id}.lengths[${key}].unit`}
                                                                                                    type="text"
                                                                                                    label="Ед. изм."
                                                                                                    component={ TextField }
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <IconButton
                                                                                            onClick={() => arrayOptions.remove(key)}
                                                                                            color="primary"
                                                                                            aria-label="Удалить"
                                                                                            component="span"
                                                                                        >
                                                                                            <Clear/>
                                                                                        </IconButton>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item xs={6} className={classes.fullWidth}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    name={`attributes.${id}.lengths[${key}].delimiter`}
                                                                                    disabled={isSubmitting || disabled}
                                                                                    label="Разделитель"
                                                                                    component={TextField}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid> : null
                                                                })}
                                                                {(((param === 'lengths') && (props.value.lengths.length < 2)) || ((param === 'width') && (props.value.lengths.length > 1))) &&
                                                                    <Grid item className={classes.fullWidth}>
                                                                        <IconButton
                                                                            onClick={() => arrayOptions.push({
                                                                                value: '',
                                                                                unit: '',
                                                                                delimiter: ''
                                                                            })}
                                                                            color={'primary'}
                                                                            aria-label="Добавить"
                                                                            component="span"
                                                                        >
                                                                            <Add/>
                                                                        </IconButton>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        )}
                                                    />
                                                </Hidden>
                                            </React.Fragment>
                                            break
                                        default:
                                            component = null
                                    }

                                    return component
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}
