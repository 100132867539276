export default function application(state = {loading: false, filling: false, process: 0, hideMenu: false}, action) {
    switch (action.type) {
        case "LOADING":
            return {
                ...state,
                loading: action.payload ? action.payload : (!!(state.process - 1)),
                filling: state.filling,
                process: action.payload ? state.process + 1 : state.process - 1
            }
        case "PROCESS":
            return {
                ...state,
                loading: false,
                filling: false,
                process: action.payload
            }
        case "FILLING":
            return {
                ...state,
                loading: state.loading,
                filling: action.payload,
                process: state.process
            }
        case "HIDE_MENU":
            return {
                ...state,
                hideMenu: !state.hideMenu
            }
        default:
            return state
    }
}
