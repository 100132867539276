import {CatalogService} from "../services/catalog"

export const CatalogActions = {
    products,
    items
}

function products(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CATALOG_PRODUCTS_REQUEST"})

        CatalogService.products(params)
            .then(
                response => {
                    dispatch({type: "CATALOG_PRODUCTS", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "CATALOG_PRODUCTS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function items(key, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CATALOG_PRODUCT_ITEMS_REQUEST"})

        CatalogService.items(params)
            .then(
                items => {
                    dispatch({type: "CATALOG_PRODUCT_ITEMS", payload: {key: key, items: items}})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "CATALOG_PRODUCT_ITEMS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
