import React from "react"

import {Error} from "../../Status"

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            hasError: null,
        };
    }

    static getDerivedStateFromError(error) {
        return { errorInfo: error };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    render() {
        if (this.state.errorInfo) {
            return <Error/>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
