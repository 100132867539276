import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import DateFnsUtils from "@date-io/date-fns"
import ruLocale from "date-fns/locale/ru"

import {FormControl, Grid, InputLabel, makeStyles, MenuItem, Select} from '@material-ui/core'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"

import {NotificationActions} from "./actions/notification"

const useStyles = makeStyles(() => ({
    item: {
        "padding": "16px"
    },
    history: {
        "padding": "8px 16px 0 16px",
        'border-bottom': "1px solid #e4e4e4"
    },
    historyItem: {
        "& .MuiListItemText-primary" : {
            "text-transform": "none !important"
        }
    },
    title: {
        "font-size": "18px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    list: {
        "overflow": "auto",
        "height": "calc(100vh - 255px)"
    },
    date: {
        width: "115px",
        "& .MuiInputAdornment-root .MuiButtonBase-root": {
            padding: 0,
        }
    }
}))

export const Menu = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { filters, types } = useSelector(state => state.notification)

    const [type, setType] = useState([])
    const [beginning, setBeginning] = useState(filters.beginning)
    const [ending, setEnding] = useState(filters.ending)

    useEffect(() => {
        const getNotifications = async () => {
            return await dispatch(NotificationActions.types())
        }

        getNotifications().then(() => {})
    }, [dispatch]);

    useEffect(() => {
        dispatch({
            type: "NOTIFICATION_FILTER",
            payload: {
                beginning: beginning,
                ending: ending
            }
        })
    }, [dispatch, beginning, ending]);

    useEffect(() => {
        dispatch({
            type: "NOTIFICATION_FILTER",
            payload: {
                type: type
            }
        })
    }, [dispatch, type]);

    return (
        <Grid container className={classes.container} direction="column" justify="flex-start" alignItems="stretch">
            <Grid item className={classes.item}>
                <Grid container className={classes.container} direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                    <Grid item>
                        <FormControl fullWidth>
                            <InputLabel>Тип оповещения</InputLabel>
                            <Select value={type} multiple onChange={(event) => {setType(event.target.value)}}>
                                {types.map(type => (
                                    <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Grid container className={classes.container} direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                                    <KeyboardDatePicker
                                        className={classes.date}
                                        fullWidth
                                        invalidDateMessage="Некорректная дата"
                                        minDate={new Date("1970-01-01")}
                                        minDateMessage={""}
                                        disableToolbar
                                        orientation="landscape"
                                        variant="inline"
                                        openTo="date"
                                        name="beginning"
                                        format="dd-MM-yyyy"
                                        autoOk
                                        required
                                        label={'Дата события с'}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={beginning}
                                        onChange={date => {
                                            setBeginning(date)
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                                    <KeyboardDatePicker
                                        className={classes.date}
                                        fullWidth
                                        invalidDateMessage="Некорректная дата"
                                        disablePast
                                        disableToolbar
                                        orientation="landscape"
                                        variant="inline"
                                        openTo="date"
                                        name="ending"
                                        format="dd-MM-yyyy"
                                        autoOk
                                        required
                                        label={'по'}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={ending}
                                        onChange={date => {
                                            setEnding(date)
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
