import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik, getIn} from "formik"
import {format} from "date-fns"
import DateFnsUtils from "@date-io/date-fns"
import ru from "date-fns/locale/ru"
import * as Yup from "yup"

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField as MuiTextField,
    Typography
} from "@material-ui/core"
import {Autocomplete} from "@material-ui/lab"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"
import {Clear, Close as CloseIcon, ExpandMore as ExpandMoreIcon} from "@material-ui/icons"

import {DeliveryActions} from "../../../actions/delivery"
import {TextFieldWithError as CustomTextField} from "../../../../App/components/Inputs/TextFieldWithError"
import {Submit} from "../../../../App/components/Button/Submit"
import {history} from "../../../../App/helpers/history"
import {AuthorizationService} from "../../../../Auth/services/authorization"
import {round} from "../../../../App/helpers/number"
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon"
import {get as getStorageItem, set as setStorageItem} from "../../../../App/helpers/localStorage"
import * as AppStorage from "../../../../App/helpers/storage"
import {Amount as AmountField} from "../../Input/Amount";
import {Items as ContractItems} from "./../components/Items";

const useStyles = makeStyles(theme => ({
    article: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "170px",
    },
    accordion: {
        "& .MuiAccordionSummary-content": {
            "max-width": "calc(100% - 32px)"
        }
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    table: {
        "border": "1px solid #e4e4e4",
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    dialogContainer: {
        "& > div": {
            "max-width": "500px",
            "min-height": "300px",
            "max-height": "500px",
            overflow: "hidden",
        }
    },
    dialogTitle: {
        "margin": "0",
        "padding-top": "2px",
        "font-size": "18px",
        "font-weight": "600",
        "color": "#fff",
        "text-transform": "uppercase",
        "display": "flex",
        "justify-content": "space-between",
        "align-items": "center",
        "min-height": "40px",
        "padding": "0 20px",
        "background-color": "#898989",
    },
    button: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "#898989",
        },
        "width": "auto",
        "max-height": "35px",
        "max-width": "auto",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "#fff",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-bottom": "3px solid #898989",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "#616161",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
            marginTop: "5px",
        }
    },
    dialogContent: {
        padding: 0,
    },
    footerContentDialog: {
        width: "100%",
        position: "absolute",
        bottom: 0,
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainerDialog: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    descriptionText: {
        margin: "0 0 15px",
        fontSize: "18px",
    },
    descriptionWrapper: {
        padding: "40px 50px",
        fontSize: "16px",
    },
    close: {
        cursor: "pointer"
    },
}))

const columns = [
    {
        id: '0',
        label: '№',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '1',
        label: 'Артикул',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '2',
        label: 'Наименование',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '3',
        label: 'Категория',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '4',
        label: 'НДС',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '5',
        label: 'Остаток на складе',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '6',
        label: 'Кол-во',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '7',
        label: 'Цена',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '8',
        label: 'Сумма',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '9',
        label: ''
    }
]

export const Cart = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {order, setOrder} = props

    const {account} = useSelector(state => state.account)

    const [items, setItems] = useState({
        data: [],
        meta: {
            total: 0, per_page: 15, current_page: 1
        }
    })

    const [page, setPage] = useState(parseInt(items.meta.current_page - 1))
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [loading, setLoading] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const [requestParams, setRequestParams] = useState(null)
    const [open, setOpen] = useState(false)

    const getItems = (id) => {
        const getData = async () => {
            return await dispatch(DeliveryActions.items(id, {
                limit: rowsPerPage,
                page: page + 1,
            }, true))
        }

        getData().then(async response => {
            setItems(response)
            if (response.meta.total) {
                await dispatch(DeliveryActions.order(order.id, {active: false}, true)).then(order => {
                    setOrder(order.id, order)
                })
            } else {
                setExpanded(false)
                setOrder(order.id, null)
            }
        })
    }

    const handleChange = (order) => (event, isExpanded) => {
        if (isExpanded) {
            getItems(order.id)
            setExpanded(true)
        } else {
            setExpanded(false)
        }
    };

    useEffect(() => {
        if (loading) {
            return () => {
                dispatch({type: 'DELIVERY_ORDERS_CLEAR'})
            }
        }
    }, [dispatch, loading]);

    useEffect(() => {
        if (expanded) {
            getItems(order.id)
        }
        // eslint-disable-next-line
    }, [dispatch, rowsPerPage, page]);

    const handleChangePageItems = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPageItems = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const onClose = () => {
        setOpen(false)
    }

    const getDataValue = (key) => {
        let date = getStorageItem('orderData');

        if (key === 'expected_date') {
            return date && date.hasOwnProperty(key) ? new Date(date[key]) : null
        }

        return date && date.hasOwnProperty(key) ? date[key] : ''
    }

    const getInitialValues = (order) => {
        return {
            items: items.data,
            address: order.address ? order.address : getDataValue('address'),
            comment: order.comment ? order.comment : getDataValue('comment'),
            expected_date: order.expected_date ? new Date(order.expected_date) : getDataValue('expected_date'),
        }
    }

    const onAddressBlur = (e, setFieldValue) => {
        setStorageItem('orderData', {address: e.target.value})
        setFieldValue('address', e.target.value)
    }

    const onCommentBlur = (e, setFieldValue) => {
        setStorageItem('orderData', {comment: e.target.value})
        setFieldValue('comment', e.target.value)
    }

    const onChangeDate = (date, setFieldValue) => {
        if (Date.parse(date)) {
            setStorageItem('orderData', {expected_date: format(date, 'yyyy-MM-dd')})
        }
        setFieldValue(`expected_date`, date);
    }

    return (
        <Accordion expanded={expanded} onChange={handleChange(order)}>
            <AccordionSummary className={classes.accordion} expandIcon={<ExpandMoreIcon/>}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item xs={6}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item xs={5}>
                                <Typography>Поставщик:</Typography>
                                <Typography>Договор:</Typography>
                                <Typography>Осталось по договору:</Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography>{order.contract.offer.company.legal_detail.name}</Typography>
                                <Typography noWrap>{order.contract.number ?? order.contract.id}</Typography>
                                <Typography noWrap>
                                    {order.contract.framework
                                        ? '-'
                                        : (order.contract.total_price && (((Number(order.contract.total_price) - Number(order.price) - Number(order.orders.price)) > 0)
                                            ? Number(order.contract.total_price) - Number(order.price) - Number(order.orders.price)
                                            : 0).toLocaleString('ru-RU', {style: 'currency', currency: order.contract.currency.code}).replace('KZT', '₸')
                                        )
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {(expanded !== order.id) &&
                        <Grid item>
                            <Typography component="span" variant="h6">Итого: </Typography>
                            <Typography
                                component="span"
                                variant="h6"
                                color={(order.contract.total_price && (parseFloat(parseFloat(order.price ?? 0) + parseFloat(order.orders.price ?? 0)).toFixed(2)) > parseFloat(parseFloat(order.contract.total_price ?? 0).toFixed(2))) ? "error" : "initial"}
                            >
                                {parseFloat(parseFloat(order.price ?? 0).toFixed(2)).toLocaleString('ru-RU', {style: 'currency', currency: order.contract.currency.code}).replace('KZT', '₸')}
                            </Typography>
                            {
                                order.total_tax_percent_price && order.total_tax_percent_price.map(item => (
                                    <Typography key={item.percent} variant="body2">Включая НДС {item.percent}%: {parseFloat(item.sum).toLocaleString('ru-RU', {style: 'currency', currency: order.contract.currency.code}).replace('KZT', '₸')}</Typography>
                                ))
                            }
                        </Grid>
                    }
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction="column" justify="flex-start" alignItems="stretch">
                    <Grid item>
                        <Formik
                            enableReinitialize={true}
                            initialValues={getInitialValues(order)}
                            validationSchema={Yup.object().shape({})}
                            validate={values => {
                                const errors = {};

                                values.items.forEach((item, index) => {
                                    const min = parseFloat(items.data[index].min_ordered_quantity ?? 0);
                                    const max = parseFloat(items.data[index].needed) + parseFloat(items.data[index].amount)
                                    if (parseFloat(item.amount) < min) {
                                        if (!errors.hasOwnProperty('items')) {
                                            errors.items = []
                                        }
                                        errors.items[index] = {amount: `Мин. заказ >= ${min} ${item.unit ? item.unit.short : ''}`}
                                    } else if (parseFloat(item.amount) > max && !item.can_be_ordered) {
                                        if (!errors.hasOwnProperty('items')) {
                                            errors.items = []
                                        }
                                        errors.items[index] = {amount: `Макс. заказ <= ${max} ${item.unit ? item.unit.short : ''}`}
                                    }
                                })

                                return errors
                            }}
                            onSubmit={(values, {setSubmitting}) => {
                                AppStorage.remove('orderData')

                                let isOnOrder = false;
                                let isOnlyOnOrder = true;

                                if (values.expected_date) {
                                    values.expected_date = format(values.expected_date, 'dd-MM-yyyy')
                                }

                                values.items.forEach(item => {
                                    if ((parseFloat(item.amount_on_order ?? 0) > 0) && !isOnOrder) {
                                        isOnOrder = true;
                                    }
                                    if (parseFloat(item.amount) - parseFloat(item.amount_on_order ?? 0) > 0) {
                                        isOnlyOnOrder = false;
                                    }
                                })

                                if (isOnOrder && !isOnlyOnOrder) {
                                    setRequestParams({
                                        values: values,
                                        orderId: order.id
                                    })
                                    setSubmitting(false)
                                    setOpen(true)
                                    return
                                }

                                return dispatch(DeliveryActions.arrange(order.id, isOnlyOnOrder ? {
                                    ...values,
                                    ...{split: true}
                                } : values)).then(
                                    () => {
                                        dispatch({
                                            type: 'CART_ITEMS_COUNT_LOADING',
                                            payload: false
                                        })
                                        history.push(`/delivery/order/${order.id}`)
                                        setSubmitting(false);
                                    },
                                    errors => {
                                        if (errors) {}
                                        setLoading(false)
                                        setSubmitting(false);
                                    }
                                )
                            }}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  isSubmitting,
                                  isValid,
                                  submitForm,
                                  setFieldValue,
                                  setFieldTouched
                              }) => (
                                <Form>
                                    <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                        <Grid item>
                                            <TableContainer className={classes.tableContainer}>
                                                <Table stickyHeader className={classes.table}>
                                                    <TableHead>
                                                        <TableRow>
                                                            {columns.map((column) => (
                                                                <TableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    style={{minWidth: column.minWidth}}
                                                                >
                                                                    {column.label}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {values.items.map((item, index) => {
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    key={index}
                                                                >
                                                                    <TableCell>
                                                                        {index + 1}
                                                                    </TableCell>
                                                                    <TableCell className={classes.article}>
                                                                        {item.article_number}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.name}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.category ? item.category.name : null}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {(item.tax_percent >= 0) ? `НДС ${item.tax_percent}%` : 'Без НДС'}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.needed ? round(item.needed, 4) : 0}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <AmountField
                                                                            order={order}
                                                                            item={item}
                                                                            name={`items.${index}.amount`}
                                                                            setFieldValue={setFieldValue}
                                                                            onBlur={() => {
                                                                                getItems(order.id)
                                                                            }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {parseFloat(item.price).toLocaleString('ru-RU', {style: 'currency', currency: order.contract.currency.code}).replace('KZT', '₸')}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Typography>{parseFloat(item.price * item.amount).toLocaleString('ru-RU', {style: 'currency', currency: order.contract.currency.code}).replace('KZT', '₸')}</Typography>
                                                                        {(item.tax_percent > 0) &&
                                                                            <Typography variant="body2">
                                                                                НДС: {parseFloat(((item.price * item.amount) / (100 + parseInt(item.tax_percent))) * parseInt(item.tax_percent)).toLocaleString('ru-RU', {style: 'currency', currency: order.contract.currency.code}).replace('KZT', '₸')}
                                                                            </Typography>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <IconButton
                                                                            size={"small"}
                                                                            onClick={() => {
                                                                                return dispatch(DeliveryActions.removeItem(order.id, item.id)).then(() => {
                                                                                    getItems(order.id)
                                                                                })
                                                                            }}
                                                                        >
                                                                            <Clear/>
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        <Grid item>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                                component='div'
                                                labelRowsPerPage={'Записей на странице:'}
                                                count={items.meta.total}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onChangePage={handleChangePageItems}
                                                onChangeRowsPerPage={handleChangeRowsPerPageItems}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                <Grid item xs={6}>
                                                    <ContractItems
                                                        order={order}
                                                        onClick={() => {
                                                            getItems(order.id)
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="row" justify="space-between" alignItems="flex-start">
                                                <Grid item xs={6}>
                                                    <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                                        <Grid item xs={12}>
                                                            <Autocomplete
                                                                name="address"
                                                                freeSolo
                                                                options={order.contract.company.territories ?? []}
                                                                getOptionLabel={item => {
                                                                    return (item instanceof Object) ? item.address : (item ?? '')
                                                                }}
                                                                noOptionsText='Адрес доставки'
                                                                onBlur={(e) => onAddressBlur(e, setFieldValue)}
                                                                defaultValue={values.address}
                                                                renderInput={params => {
                                                                    const error = getIn(touched, 'address') && getIn(errors, 'address');

                                                                    return <Field
                                                                        fullWidth
                                                                        component={MuiTextField}
                                                                        {...params}
                                                                        error={!!error}
                                                                        helperText={error}
                                                                        name="address"
                                                                        label="Адрес доставки"
                                                                    />
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Field
                                                                fullWidth
                                                                name="comment"
                                                                type="text"
                                                                label="Комментарий"
                                                                component={CustomTextField}
                                                                inputProps={{
                                                                    onBlur: (e) => onCommentBlur(e, setFieldValue)
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <MuiPickersUtilsProvider
                                                                utils={DateFnsUtils}
                                                                locale={ru}
                                                            >
                                                                <KeyboardDatePicker
                                                                    disableToolbar
                                                                    fullWidth
                                                                    error={Boolean(errors.hasOwnProperty('expected_date') && touched.hasOwnProperty('expected_date'))}
                                                                    helperText={(errors.hasOwnProperty('expected_date') && touched.hasOwnProperty('expected_date')) && errors.expected_date ? errors.expected_date : null}
                                                                    name="expected_date"
                                                                    format="dd-MM-yyyy"
                                                                    orientation="landscape"
                                                                    variant="inline"
                                                                    openTo="year"
                                                                    label="Желаемая дата доставки"
                                                                    autoOk
                                                                    disablePast
                                                                    value={values.expected_date}
                                                                    onChange={date => onChangeDate(date, setFieldValue)}
                                                                    onBlur={() => {
                                                                        setFieldTouched(`expected_date`, true, true)
                                                                    }}
                                                                    onClose={() => {
                                                                        setFieldTouched(`expected_date`, true, true)
                                                                    }}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Typography component="span" variant="h6">Итого: </Typography>
                                                    <Typography
                                                        component="span" variant="h6"
                                                        color={(order.contract.total_price && (parseFloat((parseFloat(order.price ?? 0) + parseFloat(order.orders.price)).toFixed(2)) > parseFloat(parseFloat(order.contract.total_price ?? 0).toFixed(2)))) ? "error" : "initial"}
                                                    >
                                                        {parseFloat(parseFloat(order.price ?? 0).toFixed(2)).toLocaleString('ru-RU', {style: 'currency', currency: order.contract.currency.code}).replace('KZT', '₸')}
                                                    </Typography>
                                                    {(order.contract.total_price && (parseFloat((parseFloat(order.price ?? 0) + parseFloat(order.orders.price ?? 0)).toFixed(2)) > parseFloat(parseFloat(order.contract.total_price ?? 0).toFixed(2)))) &&
                                                        <Typography color="error" variant="body2">
                                                            Сумма всех заказов больше суммы договора
                                                        </Typography>
                                                    }
                                                    {order.total_tax_percent_price && order.total_tax_percent_price.map(item => (
                                                        <Typography key={item.percent} variant="body2">
                                                            Включая НДС {item.percent}%: {parseFloat(item.sum).toLocaleString('ru-RU', {
                                                            style: 'currency',
                                                            currency: order.contract.currency.code
                                                        }).replace('KZT', '₸')}
                                                        </Typography>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                <Grid item>
                                                    <Submit
                                                        disableElevation
                                                        variant="contained"
                                                        color="secondary"
                                                        type="button"
                                                        onClick={() => {
                                                            return dispatch(DeliveryActions.remove(order.id)).then(
                                                                () => {
                                                                    dispatch({
                                                                        type: 'CART_ITEMS_COUNT_LOADING',
                                                                        payload: false
                                                                    })
                                                                    setOrder(order.id)
                                                                    setExpanded(false)
                                                                    setLoading(false)
                                                                }
                                                            )
                                                        }}
                                                        disabled={isSubmitting}
                                                    >
                                                        Удалить
                                                    </Submit>
                                                </Grid>
                                                {AuthorizationService.permissions(account, "formation_editing_delivery_order") &&
                                                    <Grid item>
                                                        <Submit
                                                            disableElevation
                                                            variant="contained"
                                                            color="primary"
                                                            type="button"
                                                            onClick={() => {
                                                                submitForm().then(() => {})
                                                            }}
                                                            disabled={isSubmitting || (order.contract.total_price && (parseFloat((parseFloat(order.price ?? 0) + parseFloat(order.orders.price ?? 0)).toFixed(2)) > parseFloat(parseFloat(order.contract.total_price ?? 0).toFixed(2)))) || !isValid}
                                                        >
                                                            Оформить заказ
                                                        </Submit>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </AccordionDetails>
            {open && (
                <Dialog
                    aria-labelledby="simple-dialog-title"
                    open={open}
                    classes={{
                        root: classes.dialog,
                        container: classes.dialogContainer
                    }}
                    fullWidth={true}
                    maxWidth={'lg'}
                >
                    <DialogTitle
                        id="dialog-title"
                        classes={{
                            root: classes.dialogTitle,
                        }}
                        disableTypography={true}
                    >
                        Подтверждение действий
                        <CloseIcon className={classes.close} onClick={onClose}/>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid container>
                            <Grid item style={{marginBottom: "70px"}}>
                                <Grid container className={classes.descriptionWrapper}>
                                    <Grid item>
                                        <Typography className={classes.descriptionText}>
                                            В Вашем заказе присутствуют товары, которых нет на складе у данного
                                            поставщика, и доставка которых потребует более длительного
                                            ожидания. Желаете ли Вы разбить данный заказ на 2 заказа с
                                            целью ускорения доставки тех позиций, которые в наличии у
                                            данного поставщика уже сейчас?
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.footerContentDialog}>
                                <Grid container direction="row" justify="flex-end" alignItems="center" className={classes.footerContainerDialog} spacing={2}>
                                    <Grid item>
                                        <ButtonIcon
                                            className={classes.button}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                requestParams.values = {
                                                    ...requestParams.values,
                                                    ...{split: true}
                                                };

                                                return dispatch(DeliveryActions.arrange(requestParams.orderId, requestParams.values)).then(
                                                    () => {
                                                        dispatch({
                                                            type: 'CART_ITEMS_COUNT_LOADING',
                                                            payload: false
                                                        })
                                                        history.push(`/delivery/order/${requestParams.orderId}`)
                                                    }
                                                )

                                            }}
                                            children={['да']}>
                                        </ButtonIcon>
                                    </Grid>
                                    <Grid item>
                                        <ButtonIcon
                                            className={classes.button}
                                            onClick={(e) => {
                                                e.stopPropagation()

                                                return dispatch(DeliveryActions.arrange(requestParams.orderId, requestParams.values)).then(
                                                    () => {
                                                        dispatch({
                                                            type: 'CART_ITEMS_COUNT_LOADING',
                                                            payload: false
                                                        })
                                                        history.push(`/delivery/order/${requestParams.orderId}`)
                                                    }
                                                )
                                            }}
                                            children={['нет']}>
                                        </ButtonIcon>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            )}
        </Accordion>
    )
}
