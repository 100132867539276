import React, {useState} from 'react'

import {
    Button,
    Checkbox,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow as TableRowMUI,
    Typography
} from '@material-ui/core'
import {FilterList, Sort} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    popover: {
        'width': '300px'
    },
    filterItem: {
        'white-space': 'normal !important'
    },
    filterContent: {
        width: '100%',
        padding: theme.spacing(2)
    },
    divider: {
        width: '100%'
    }
}))

export const Filter = (props) => {
    const classes = useStyles()
    const {name, active, filter, setFilter, Content} = props

    const [sort, setSort] = useState({
        name: name,
        direction: (filter.sort.name === name) ? filter.sort.direction : null
    })

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const [status, setStatus] = useState((name === 'status') ? filter.status : [])
    const [category, setCategory] = useState(null)

    return (
        <React.Fragment>
            <IconButton
                size="small"
                onClick={(event) => {
                    setSort({name: name, direction: (filter.sort.name === name) ? filter.sort.direction : null})
                    setAnchorEl(event.currentTarget)
                }}
                color={active ? 'secondary' : 'default'}
            >
                <FilterList/>
            </IconButton>
            {open ? <Popover
                classes={{
                    paper: classes.popover
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                    setAnchorEl(null)
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                    <Grid item className={classes.filterContent}>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item>
                                <Typography variant="caption">
                                    Сортировка
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                                    <Grid item>
                                        <Button
                                            color="default"
                                            onClick={() => {
                                                const direction = ((sort.direction === 'asc') ? null : 'asc')
                                                setSort({...sort, ...{direction: direction}})
                                            }}
                                            startIcon={<Sort color={(sort.direction === 'asc') ? 'secondary' : 'action'} style={{transform: 'rotatex(180deg)'}}/>}
                                        >
                                            <Typography component="span" variant="body2" style={{textTransform: "none"}}>по возрастанию (А-Я)</Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color="default"
                                            onClick={() => {
                                                const direction = ((sort.direction === 'desc') ? null : 'desc')
                                                setSort({...sort, ...{direction: direction}})
                                            }}
                                            startIcon={<Sort color={(sort.direction === 'desc') ? 'secondary' : 'action'}/>}
                                        >
                                            <Typography component="span" variant="body2" style={{textTransform: "none"}}>по убыванию (Я-А)</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {Content ? <Content filter={filter} setCategory={setCategory} /> : null}
                        </Grid>
                    </Grid>
                    {(name === 'status') &&
                        <React.Fragment>
                            <Divider className={classes.divider}/>
                            <Grid item className={classes.filterContent}>
                                <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                                    <Grid item>
                                        <Typography variant="caption">
                                            Фильтр
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.fullWidth}>
                                        <TableContainer style={{height: '129px'}}>
                                            <Table stickyHeader>
                                                <TableBody>
                                                    {[{name: 'Активный', key: 'active'}, {
                                                        name: 'Неактивный',
                                                        key: 'inactive'
                                                    }, {name: 'Не проверен', key: 'confirmed'}].map(item => {
                                                        const isItemSelected = (status.indexOf(item.key) !== -1)

                                                        return (
                                                            <TableRowMUI
                                                                key={item.key}
                                                                onClick={() => {
                                                                    const selectedIndex = status.indexOf(item.key)

                                                                    let select = []

                                                                    if (selectedIndex === -1) {
                                                                        select = select.concat(status, item.key)
                                                                    } else if (selectedIndex === 0) {
                                                                        select = select.concat(status.slice(1))
                                                                    } else if (selectedIndex === status.length - 1) {
                                                                        select = select.concat(status.slice(0, -1))
                                                                    } else if (selectedIndex > 0) {
                                                                        select = select.concat(
                                                                            status.slice(0, selectedIndex),
                                                                            status.slice(selectedIndex + 1),
                                                                        )
                                                                    }

                                                                    setStatus(select)
                                                                }}
                                                                role="checkbox"
                                                                selected={isItemSelected}
                                                            >
                                                                <TableCell padding="checkbox">
                                                                    <Checkbox checked={isItemSelected}/>
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    <Typography variant="body2" noWrap
                                                                                className={classes.filterItem}>{item.name}</Typography>
                                                                </TableCell>
                                                            </TableRowMUI>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                    <Grid item className={classes.filterContent}>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction="row" justify="space-between" alignItems="flex-start">
                                    <Grid item>
                                        <Button
                                            size="small"
                                            color="secondary"
                                            type="button"
                                            onClick={() => {
                                                setSort({...sort, ...{direction: null}})
                                                setStatus([])
                                                setFilter({
                                                    ...{
                                                        sort: {
                                                            direction: null,
                                                            name: null
                                                        },
                                                        status: [],
                                                        category: null
                                                    },
                                                    ...((filter.sort.name === name) ? {
                                                        sort: {
                                                            name: null,
                                                            direction: null,
                                                        }
                                                    } : {sort: filter.sort})
                                                })
                                            }}
                                        >
                                            Сбросить
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            size="small"
                                            color="primary"
                                            type="button"
                                            onClick={() => {
                                                setFilter({
                                                    sort: !!sort.direction ? sort : filter.sort,
                                                    status: (name === 'status') ? status : [],
                                                    category: (name === 'category') ? category : filter.category
                                                })

                                                setAnchorEl(null)
                                            }}
                                        >
                                            Применить
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Popover> : null}
        </React.Fragment>
    )
}
