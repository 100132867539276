import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import {useDebounce} from 'use-debounce'
import format from "date-fns/format"
import DateFnsUtils from "@date-io/date-fns"
import {ru} from "date-fns/locale"
import * as Yup from "yup"
import clsx from "clsx"

import {
    Box, Button, Grid, IconButton, InputAdornment, makeStyles, Menu, MenuItem, Paper, Step, StepConnector, StepLabel,
    Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField as MuiTextField,
    Tooltip, Typography, withStyles
} from "@material-ui/core"
import {Autocomplete} from "@material-ui/lab"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"
import {Add as AddIcon, ArrowForward, Check, Clear, FileCopy, Print} from "@material-ui/icons"

import {DeliveryActions} from "../../actions/delivery"
import {AuthorizationService} from "../../../Auth/services/authorization"

import {TabPanel} from "../../../Account/components/Tabs/TabPanel"
import {StatusActions} from "../../actions/status"
import {TextFieldWithError as CustomTextField} from "../../../App/components/Inputs/TextFieldWithError"
import {MessageInfo} from "../../../App/components/Card/MessageInfo"
import {Submit} from "../../../App/components/Button/Submit"
import {FileUpload} from "../../../App/components/Inputs/FileUpload"
import {ContractActions} from "../../../Contract/actions/contract"
import {history} from "../../../App/helpers/history"
import {Card} from "../../../Company/components/Card"
import Excel from "../../../App/components/Svg/Excel"
import Pdf from "../../../App/components/Svg/Pdf"
import * as AppStorage from "../../../App/helpers/storage"
import {Amount as AmountField} from "../Input/Amount";
import {ConfirmButton} from "../../../App/components/Button/ConfirmButton";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px 0 29px !important",
    },
    input: {
        "width": "100%",
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 294px)",
        "margin-bottom": "4px"
    },
    table: {
        "border": "1px solid #e4e4e4",
    },
    tableContainer: {
        "overflow": "auto"
    },
    inputContent: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "10px",
        "padding-right": "5px",
        "padding": "12px"
    },
    download: {
        "color": "#485868"
    },
    lightTooltip: {
        "font-size": "0.8rem",
        "backgroundColor": "white",
        "color": "rgba(0, 0, 0, 0.87)",
        "border": "1px solid #ededed"
    },
    orderLink: {
        color: "#898989",
        cursor: "pointer",
        "&:hover": {
            color: "#616161"
        }
    },
    accordionItem: {
        "border-top": "0 !important",
        "box-shadow": "0 0 0 !important",
        "border-bottom": "1px solid rgba(224, 224, 224, 1) !important",
        "border-color": "grey"
    },
}))

const columns = [
    {
        id: '0',
        label: '№',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '1',
        label: 'Артикул',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '2',
        label: 'Наименование',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '3',
        label: 'Категория',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '4',
        label: 'Количество',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '5',
        label: 'Цена',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '6',
        label: 'НДС',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '7',
        label: 'Сумма без НДС',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '8',
        label: 'Сумма с НДС',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '9',
        label: 'Код товара по ТН ВЭД',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '10',
        label: 'Масса брутто',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '11',
        label: ''
    },
    {
        id: '12',
        label: 'Кол-во товара из 1С'
    },
    {
        id: '13',
        label: 'Цена из 1С'
    }
]

const Connector = withStyles({
    alternativeLabel: {
        "top": "10",
        "left": "calc(-50% + 16px)",
        "right": "calc(50% + 16px)"
    },
    active: {
        '& $line': {
            "border-color": "#616161"
        },
    },
    completed: {
        '& $line': {
            "border-color": "#616161"
        },
    },
    line: {
        "border-color": "#eaeaf0",
        "border-top-width": "3",
        "border-radius": "1"
    },
    vertical: {
        "padding": "0 0 8px",
        "margin-left": "12px"
    }
})(StepConnector)

const useStepIconStyles = makeStyles({
    root: {
        "color": "#eaeaf0",
        "display": "flex",
        "height": "22",
        "align-items": "center"
    },
    active: {
        "color": "#616161"
    },
    circle: {
        "width": 12,
        "height": 12,
        "border-radius": '50%',
        "background-color": 'currentColor',
        "margin-left": "6px",
        "margin-right": "6px"
    },
    completed: {
        "color": "#616161",
        "z-index": "1",
        "font-size": "18"
    }
});

function StepIcon(props) {
    const classes = useStepIconStyles();
    const {active, completed} = props;

    return (
        <div
            className={clsx(classes.root, {[classes.active]: active,})}
        >
            {completed ? <Check className={classes.completed}/> : <div className={classes.circle}/>}
        </div>
    );
}
export const Content = () => {
    let {id} = useParams()

    const dispatch = useDispatch()
    const classes = useStyles()
    const {account, status, items} = useSelector(state => {
        return {...state.account, ...state.contract, ...state.deliveryStatus}
    })

    const [order, setOrder] = useState(null)
    const [products, setProducts] = useState({data: [], meta: {total: 0}})
    const [loading, setLoading] = useState(false)
    const [update, setUpdate] = useState(false)
    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [change, setChange] = useState(false)
    const [changeFiles, setChangeFiles] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [cardCompany, setCardCompany] = useState(null)
    const [cardContractID, setCardContractID] = useState(null)
    const [openCard, setOpenCard] = useState(false)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(15)

    useEffect(() => {
        if (order) {
            const getItems = async () => {
                let params = {
                    limit: 10,
                    page: 1,
                    ...(searchRequest ? {search: searchRequest} : {})
                }
                return await dispatch(ContractActions.items(order.contract.id, params))
            }
            getItems().then(_ => {})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, order, searchRequest]);

    const getProducts = async () => {
        return await dispatch(DeliveryActions.items(id, {
            limit: rowsPerPage,
            page: page + 1,
        }, true))
    }

    const getOrder = async () => {
        return await dispatch(DeliveryActions.order(id, {}, true))
    }

    useEffect(() => {
        if (update) {
            getOrder().then(obj => {
                setOrder(obj)
                setUpdate(false)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, update]);

    useEffect(() => {
        if (!loading) {
            const getOrder = async () => {
                return await dispatch(DeliveryActions.order(id))
            }

            getOrder().then(obj => {
                setOrder(obj)
                getProducts().then(data => setProducts(data))
                setLoading(true)
                setChange(false)
                setChangeFiles(false)
            })

        } else {
            return () => {
                dispatch({type: 'DELIVERY_ORDER_CLEAR'})
            }
        }
        // eslint-disable-next-line
    }, [dispatch, id, loading, rowsPerPage, page]);


    useEffect(() => {
        if (!status.length) {
            const getStatus = async () => {
                await dispatch(StatusActions.status())
            }

            getStatus().then(_ => {})
        }
    }, [dispatch, status])

    const getSteps = () => {
        let active = true

        return order.contract.steps.map((step) => {
            active = (order.status.index !== 17) ? (active ? !(order.status.id === step.id) : active) : false

            return (
                <Step key={step.id} completed={active} active={(order.status.id === step.id)}>
                    <StepLabel StepIconComponent={StepIcon}>{status.find(item => (item.id === step.id)) ? status.find(item => (item.id === step.id)).name : ''}</StepLabel>
                </Step>
            )
        })
    }

    const handleChangePage = (event, newPage) => {
        setLoading(false)
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setLoading(false)
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const handleClickAnchorEl = (event) => {
        if (anchorEl !== event.currentTarget) setAnchorEl(event.currentTarget);
    }

    const handleCloseAnchorEl = () => {
        setAnchorEl(null);
    }

    const activeCustomer = () => {
        const index = order.contract.steps.findIndex((item) => item.id === order.status.id)

        return (AuthorizationService.roles(account, "customer") && ((index !== -1) && ((Array.isArray(order.contract.steps[index]?.type)) || (order.contract.steps[index]?.type === "customer"))))
    }

    const getStep = (files, handleSubmit) => {
        const index = order.contract.steps.findIndex((item) => item.id === order.status.id);

        let name = null

        if (order.contract.steps.hasOwnProperty(index + 1)) {
            name = status.find((item) => item.id === order.contract.steps[index + 1].id).name
        }

        if (name && (order.status.index !== 17) && ((AuthorizationService.roles(account, "customer") && ((Array.isArray(order.contract.steps[index]?.type)) || (order.contract.steps[index]?.type === "customer"))) || (AuthorizationService.roles(account, "supplier") && ((Array.isArray(order.contract.steps[index]?.type)) || (order.contract.steps[index]?.type === "supplier"))))) {
            switch (order.status.index) {
                case 1:
                    if (AuthorizationService.roles(account, "customer") && AuthorizationService.permissions(account, "confirmation_editing_delivery_order")) {
                        return (
                            <Grid item>
                                <ConfirmButton
                                    title={'Вы уверены, что хотите перейти к следующей стадии заказа?'}
                                    onClick={() => {
                                        setIsSubmitting(true)
                                        return dispatch(DeliveryActions.status(order.id)).then(
                                            () => {
                                                setLoading(false)
                                                setIsSubmitting(false)
                                            },
                                            () => {
                                                setIsSubmitting(false)
                                            }
                                        )
                                    }}
                                >
                                    <Submit
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        disabled={isSubmitting || (order.contract.total_price && (parseFloat((parseFloat(order.price ?? 0) + parseFloat(order.orders.price ?? 0)).toFixed(2)) > parseFloat(parseFloat(order.contract.total_price).toFixed(2))))}
                                        endIcon={<ArrowForward>send</ArrowForward>}
                                    >
                                        {name}
                                    </Submit>
                                </ConfirmButton>
                            </Grid>
                        )
                    }
                    break
                case 2:
                    return (
                        <React.Fragment>
                            <Grid item>
                                <Submit
                                    disableElevation
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    onClick={handleSubmit}
                                    disabled={isSubmitting || !change}
                                >
                                    Сохранить
                                </Submit>
                            </Grid>
                            <Grid item>
                                <ConfirmButton
                                    title={'Вы уверены, что хотите перейти к следующей стадии заказа?'}
                                    onClick={() => {
                                        setIsSubmitting(true)
                                        return dispatch(DeliveryActions.status(order.id)).then(
                                            () => {
                                                setLoading(false)
                                                setIsSubmitting(false)
                                            },
                                            () => {
                                                setIsSubmitting(false)
                                            }
                                        )
                                    }}
                                >
                                    <Submit
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        disabled={isSubmitting || ((order.status.index === 2) && !files.invoice_payment.filter(file => file.hasOwnProperty('id')).length)}
                                        endIcon={<ArrowForward>send</ArrowForward>}
                                    >
                                        {name}
                                    </Submit>
                                </ConfirmButton>
                            </Grid>
                        </React.Fragment>
                    )
                case 3:
                    if (AuthorizationService.roles(account, "customer") && AuthorizationService.permissions(account, "delivery_order_after_event")) {
                        return (
                            <React.Fragment>
                                <Grid item>
                                    <Submit
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        onClick={handleSubmit}
                                        disabled={isSubmitting || !change}
                                    >
                                        Сохранить
                                    </Submit>
                                </Grid>
                                <Grid item>
                                    <ConfirmButton
                                        title={'Вы уверены, что хотите перейти к следующей стадии заказа?'}
                                        onClick={() => {
                                            setIsSubmitting(true)
                                            return dispatch(DeliveryActions.status(order.id)).then(
                                                () => {
                                                    setLoading(false)
                                                    setIsSubmitting(false)
                                                },
                                                () => {
                                                    setIsSubmitting(false)
                                                }
                                            )
                                        }}
                                    >
                                        <Submit
                                            disableElevation
                                            variant="contained"
                                            color="primary"
                                            type="button"
                                            disabled={isSubmitting || ((order.status.index === 3) && !files.document.filter(file => file.hasOwnProperty('id')).length) || !order.actual_order_date}
                                            endIcon={<ArrowForward>send</ArrowForward>}
                                        >
                                            {name}
                                        </Submit>
                                    </ConfirmButton>
                                </Grid>
                            </React.Fragment>
                        )
                    }
                    break
                case 4:
                    if (AuthorizationService.permissions(account, "confirmation_payment_delivery_order")) {
                        return (
                            <React.Fragment>
                                <Grid item>
                                    <Submit
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        onClick={handleSubmit}
                                        disabled={isSubmitting || (!change && !changeFiles)}
                                    >
                                        Сохранить
                                    </Submit>
                                </Grid>
                                <Grid item>
                                    <ConfirmButton
                                        title={'Вы уверены, что хотите перейти к следующей стадии заказа?'}
                                        onClick={() => {
                                            setIsSubmitting(true)
                                            return dispatch(DeliveryActions.status(order.id)).then(
                                                () => {
                                                    setLoading(false)
                                                    setIsSubmitting(false)
                                                },
                                                () => {
                                                    setIsSubmitting(false)
                                                }
                                            )
                                        }}
                                    >
                                        <Submit
                                            disableElevation
                                            variant="contained"
                                            color="primary"
                                            type="button"
                                            disabled={isSubmitting || !order.payment_sum}
                                            endIcon={<ArrowForward>send</ArrowForward>}
                                        >
                                            {name}
                                        </Submit>
                                    </ConfirmButton>
                                </Grid>
                            </React.Fragment>
                        )
                    }
                    break
                case 5:
                    if (AuthorizationService.permissions(account, "confirmation_payment_delivery_order")) {
                        return (
                            <React.Fragment>
                                <Grid item>
                                    <Submit
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        onClick={handleSubmit}
                                        disabled={isSubmitting || (!change && !changeFiles)}
                                    >
                                        Сохранить
                                    </Submit>
                                </Grid>
                                <Grid item>
                                    <ConfirmButton
                                        title={'Вы уверены, что хотите перейти к следующей стадии заказа?'}
                                        onClick={() => {
                                            setIsSubmitting(true)
                                            return dispatch(DeliveryActions.status(order.id)).then(
                                                () => {
                                                    setLoading(false)
                                                    setIsSubmitting(false)
                                                },
                                                () => {
                                                    setIsSubmitting(false)
                                                }
                                            )
                                        }}
                                    >
                                        <Submit
                                            disableElevation
                                            variant="contained"
                                            color="primary"
                                            type="button"
                                            disabled={isSubmitting || !order.payment_sum}
                                            endIcon={<ArrowForward>send</ArrowForward>}
                                        >
                                            {name}
                                        </Submit>
                                    </ConfirmButton>
                                </Grid>
                            </React.Fragment>
                        )
                    }
                    break
                case 6:
                    if (AuthorizationService.permissions(account, "confirmation_payment_delivery_order")) {
                        return (
                            <React.Fragment>
                                <Grid item>
                                    <Submit
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        onClick={handleSubmit}
                                        disabled={isSubmitting || (!change && !changeFiles)}
                                    >
                                        Сохранить
                                    </Submit>
                                </Grid>
                                <Grid item>
                                    <ConfirmButton
                                        title={'Вы уверены, что хотите перейти к следующей стадии заказа?'}
                                        onClick={() => {
                                            setIsSubmitting(true)
                                            return dispatch(DeliveryActions.status(order.id)).then(
                                                () => {
                                                    setLoading(false)
                                                    setIsSubmitting(false)
                                                },
                                                () => {
                                                    setIsSubmitting(false)
                                                }
                                            )
                                        }}
                                    >
                                        <Submit
                                            disableElevation
                                            variant="contained"
                                            color="primary"
                                            type="button"
                                            disabled={isSubmitting || !order.payment_sum}
                                            endIcon={<ArrowForward>send</ArrowForward>}
                                        >
                                            {name}
                                        </Submit>
                                    </ConfirmButton>
                                </Grid>
                            </React.Fragment>
                        )
                    }
                    break
                case 7:
                    if (AuthorizationService.roles(account, "customer") && AuthorizationService.permissions(account, "delivery_order_after_event")) {
                        return (
                            <React.Fragment>
                                <Grid item>
                                    <Submit
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        onClick={handleSubmit}
                                        disabled={isSubmitting || (!changeFiles && !change)}
                                    >
                                        Сохранить
                                    </Submit>
                                </Grid>
                                <Grid item>
                                    <ConfirmButton
                                        title={'Вы уверены, что хотите перейти к следующей стадии заказа?'}
                                        onClick={() => {
                                            setIsSubmitting(true)
                                            return dispatch(DeliveryActions.status(order.id)).then(
                                                () => {
                                                    setLoading(false)
                                                    setIsSubmitting(false)
                                                },
                                                () => {
                                                    setIsSubmitting(false)
                                                }
                                            )
                                        }}
                                    >
                                        <Submit
                                            disableElevation
                                            variant="contained"
                                            color="primary"
                                            type="button"
                                            onClick={() => {
                                                setIsSubmitting(true)
                                                return dispatch(DeliveryActions.status(order.id)).then(
                                                    () => {
                                                        setLoading(false)
                                                        setIsSubmitting(false)
                                                    },
                                                    () => {
                                                        setIsSubmitting(false)
                                                    }
                                                )
                                            }}
                                            disabled={isSubmitting || !files.document.filter(file => file.hasOwnProperty('id')).length || !order.actual_date || !order.payment_sum || !order.actual_order_date}
                                            endIcon={<ArrowForward>send</ArrowForward>}
                                        >
                                            {name}
                                        </Submit>
                                    </ConfirmButton>
                                </Grid>
                            </React.Fragment>
                        )
                    }
                    break
                case 8:
                    return (
                        <React.Fragment>
                            <Grid item>
                                <Submit
                                    disableElevation
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    onClick={handleSubmit}
                                    disabled={isSubmitting || !change}
                                >
                                    Сохранить
                                </Submit>
                            </Grid>
                            <Grid item>
                                <ConfirmButton
                                    title={'Вы уверены, что хотите перейти к следующей стадии заказа?'}
                                    onClick={() => {
                                        setIsSubmitting(true)
                                        return dispatch(DeliveryActions.status(order.id)).then(
                                            () => {
                                                setLoading(false)
                                                setIsSubmitting(false)
                                            },
                                            () => {
                                                setIsSubmitting(false)
                                            }
                                        )
                                    }}
                                >
                                    <Submit
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        disabled={isSubmitting || !order.estimated_date || change}
                                        endIcon={<ArrowForward>send</ArrowForward>}
                                    >
                                        {name}
                                    </Submit>
                                </ConfirmButton>
                            </Grid>
                        </React.Fragment>
                    )
                case 9:
                    if (AuthorizationService.roles(account, "customer") && AuthorizationService.permissions(account, "receiving_confirmation_editing_delivery_order")) {
                        return (
                            <React.Fragment>
                                <Grid item>
                                    <Submit
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        onClick={handleSubmit}
                                        disabled={isSubmitting || (!changeFiles && !change)}
                                    >
                                        Сохранить
                                    </Submit>
                                </Grid>
                                <Grid item>
                                    <ConfirmButton
                                        title={'Вы уверены, что хотите закрыть заказ?'}
                                        onClick={() => {
                                            setIsSubmitting(true)

                                            return dispatch(DeliveryActions.status(order.id)).then(
                                                () => {
                                                    setLoading(false)
                                                    setIsSubmitting(false)
                                                },
                                                () => {
                                                    setIsSubmitting(false)
                                                }
                                            )
                                        }}
                                    >
                                        <Submit
                                            disableElevation
                                            variant="contained"
                                            color="primary"
                                            type="button"
                                            disabled={isSubmitting}
                                            endIcon={<ArrowForward>send</ArrowForward>}
                                        >
                                            {name}
                                        </Submit>
                                    </ConfirmButton>
                                </Grid>
                            </React.Fragment>
                        )
                    }
                    if (AuthorizationService.roles(account, "supplier")) {
                        return (
                            <Grid item>
                                <Submit
                                    disableElevation
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    onClick={handleSubmit}
                                    disabled={isSubmitting || (!changeFiles && !change)}
                                >
                                    Сохранить
                                </Submit>
                            </Grid>
                        )
                    }
                    break
                case 10:
                    if (AuthorizationService.roles(account, "customer") && AuthorizationService.permissions(account, "delivery_order_after_event")) {
                        return (
                            <React.Fragment>
                                <Grid item>
                                    <Submit
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        onClick={handleSubmit}
                                        disabled={isSubmitting || (!changeFiles && !change)}
                                    >
                                        Сохранить
                                    </Submit>
                                </Grid>
                                <Grid item>
                                    <ConfirmButton
                                        title={'Вы уверены, что хотите перейти к следующей стадии заказа?'}
                                        onClick={() => {
                                            setIsSubmitting(true)
                                            return dispatch(DeliveryActions.status(order.id)).then(
                                                () => {
                                                    setLoading(false)
                                                    setIsSubmitting(false)
                                                },
                                                () => {
                                                    setIsSubmitting(false)
                                                }
                                            )
                                        }}
                                    >
                                        <Submit
                                            disableElevation
                                            variant="contained"
                                            color="primary"
                                            type="button"
                                            disabled={isSubmitting || !files.document.filter(file => file.hasOwnProperty('id')).length || !order.actual_date || !order.payment_sum || !order.actual_order_date}
                                            endIcon={<ArrowForward>send</ArrowForward>}
                                        >
                                            {name}
                                        </Submit>
                                    </ConfirmButton>
                                </Grid>
                            </React.Fragment>
                        )
                    }
                    break
                case 11:
                    if (AuthorizationService.permissions(account, "receiving_confirmation_editing_delivery_order")) {
                        return (
                            <React.Fragment>
                                <Grid item>
                                    <Submit
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        onClick={handleSubmit}
                                        disabled={isSubmitting || (!changeFiles && !change)}
                                    >
                                        Сохранить
                                    </Submit>
                                </Grid>
                                <Grid item>
                                    <ConfirmButton
                                        title={'Вы уверены, что хотите перейти к следующей стадии заказа?'}
                                        onClick={() => {
                                            setIsSubmitting(true)
                                            return dispatch(DeliveryActions.status(order.id)).then(
                                                () => {
                                                    setLoading(false)
                                                    setIsSubmitting(false)
                                                },
                                                () => {
                                                    setIsSubmitting(false)
                                                }
                                            )
                                        }}
                                    >
                                        <Submit
                                            disableElevation
                                            variant="contained"
                                            color="primary"
                                            type="button"
                                            disabled={isSubmitting || !order.actual_date || (!order.files.filter(file => ((file.type.key === 'invoice') || (file.type.key === 'certificate_completion') || (file.type.key === 'universal_transmission_document') || (file.type.key === 'waybill'))).length) || (change || changeFiles)}
                                            endIcon={<ArrowForward>send</ArrowForward>}
                                        >
                                            {name}
                                        </Submit>
                                    </ConfirmButton>
                                </Grid>
                            </React.Fragment>
                        )
                    }
                    break
                case 15:
                    break
                default:
                    return (
                        <Grid item>
                            <ConfirmButton
                                title={'Вы уверены, что хотите перейти к следующей стадии заказа?'}
                                onClick={() => {
                                    setIsSubmitting(true)
                                    return dispatch(DeliveryActions.status(order.id)).then(
                                        () => {
                                            setLoading(false)
                                            setIsSubmitting(false)
                                        },
                                        () => {
                                            setIsSubmitting(false)
                                        }
                                    )
                                }}
                            >
                                <Submit
                                    disableElevation
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    disabled={isSubmitting}
                                    endIcon={(order.status.index !== 15) ? <ArrowForward>send</ArrowForward> : null}
                                >
                                    {name}
                                </Submit>
                            </ConfirmButton>
                        </Grid>
                    )
            }
        }
    }

    const getMessage = (index, files) => {
        const indx = order.contract.steps.findIndex((item) => item.id === order.status.id);

        switch (index) {
            case 1:
                if (AuthorizationService.roles(account, "customer")) {
                    if (AuthorizationService.permissions(account, "confirmation_editing_delivery_order")) {
                        return <MessageInfo message={'Проверьте правильность оформления заказа. При необходимости отредактируйте его. Если заказ оформлен правильно, нажмите "Согласование поставщиком".'}/>
                    } else if (AuthorizationService.permissions(account, "formation_editing_delivery_order")) {
                        return <MessageInfo message={'Проверьте правильность оформления заказа. При необходимости отредактируйте его.'}/>
                    } else {
                        return <MessageInfo message={'У Вашей учётной записи недостаточно прав для перевода заказа на следующую стадию.'}/>
                    }
                }
                break
            case 2:
                if (AuthorizationService.roles(account, "supplier") && (!files.invoice_payment.length)) {
                    return <MessageInfo message={'Требуется прикрепить "Счет на оплату".'}/>
                }
                break
            case 3:
                if (AuthorizationService.roles(account, "customer")) {
                    if (AuthorizationService.permissions(account, "delivery_order_after_event")) {
                        if (!files.document.length) {
                            return <MessageInfo message={'Требуется прикрепить "Документы".'}/>
                        }
                    } else {
                        return <MessageInfo message={'У Вашей учётной записи недостаточно прав для перевода заказа на следующую стадию.'}/>
                    }
                }
                break
            case 4:
                if (AuthorizationService.roles(account, "customer") && (!AuthorizationService.permissions(account, "confirmation_payment_delivery_order"))) {
                    return <MessageInfo message={'У Вашей учётной записи недостаточно прав для перевода заказа на следующую стадию.'}/>
                }
                break
            case 5:
                if (AuthorizationService.roles(account, "customer") && (!AuthorizationService.permissions(account, "confirmation_payment_delivery_order"))) {
                    return <MessageInfo message={'У Вашей учётной записи недостаточно прав для перевода заказа на следующую стадию.'}/>
                }
                break
            case 6:
                if (AuthorizationService.roles(account, "customer") && (!AuthorizationService.permissions(account, "confirmation_payment_delivery_order"))) {
                    return <MessageInfo
                        message={'У Вашей учётной записи недостаточно прав для перевода заказа на следующую стадию.'}/>
                }
                break
            case 7:
                if (AuthorizationService.roles(account, "customer")) {
                    if (AuthorizationService.permissions(account, "delivery_order_after_event")) {
                        if (!files.document.length) {
                            return <MessageInfo message={'Требуется прикрепить "Документы".'}/>
                        }
                    } else {
                        return <MessageInfo message={'У Вашей учётной записи недостаточно прав для перевода заказа на следующую стадию.'}/>
                    }
                }
                break
            case 9:
                if ((AuthorizationService.roles(account, "customer") && AuthorizationService.permissions(account, "receiving_confirmation_editing_delivery_order")) || AuthorizationService.roles(account, "supplier")) {
                    return <MessageInfo message={"На данной стадии вы можете отредактировать ранее внесенные данные."}/>
                } else {
                    return <MessageInfo message={'У Вашей учётной записи недостаточно прав.'}/>
                }
            case 10:
                if (AuthorizationService.roles(account, "customer")) {
                    if (AuthorizationService.permissions(account, "delivery_order_after_event")) {
                        if (!files.document.length) {
                            return <MessageInfo message={'Требуется прикрепить "Документы".'}/>
                        }
                    } else {
                        return <MessageInfo message={'У Вашей учётной записи недостаточно прав.'}/>
                    }
                }
                break
            case 11:
                if (AuthorizationService.roles(account, "customer")) {
                    if (AuthorizationService.permissions(account, "receiving_confirmation_editing_delivery_order")) {
                        if (!files.invoice.length && !files.certificate_completion.length && !files.universal_transmission_document.length && !files.waybill.length) {
                            return <MessageInfo message={"Требуется прикрепить один из следующих документов: Счет-фактура, Акт выполненных работ, Универсальный передаточный документ, Товарно-транспортная накладная."}/>
                        }
                    } else {
                        return <MessageInfo message={'У Вашей учётной записи недостаточно прав для перевода заказа на следующую стадию.'}/>
                    }
                }
                break
            case 12:
                if (AuthorizationService.roles(account, "supplier")) {
                    return <MessageInfo
                        message={`Произведена оплата в размере ${parseFloat(order.payment_sum).toLocaleString('ru-RU', {
                            ...(order.contract.currency ? {
                                style: 'currency',
                                currency: order.contract.currency.code
                            } : {})
                        }).replace('KZT', '₸')}. Требуется подтверждение получения ДС.`}
                    />
                }
                break
            case 13:
                if (AuthorizationService.roles(account, "supplier")) {
                    return <MessageInfo
                        message={`Произведена оплата в размере ${parseFloat(order.payment_sum).toLocaleString('ru-RU', {
                            ...(order.contract.currency ? {
                                style: 'currency',
                                currency: order.contract.currency.code
                            } : {})
                        }).replace('KZT', '₸')}. Требуется подтверждение получения ДС.`}
                    />
                }
                break
            case 14:
                if (AuthorizationService.roles(account, "supplier")) {
                    return <MessageInfo
                        message={`Произведена оплата в размере ${parseFloat(order.payment_sum).toLocaleString('ru-RU', {
                            ...(order.contract.currency ? {
                                style: 'currency',
                                currency: order.contract.currency.code
                            } : {})
                        }).replace('KZT', '₸')}. Требуется подтверждение получения ДС.`}
                    />
                }
                break
            case 17:
                return <MessageInfo message={'Заказ отменён.'}/>
            default:
        }

        if (indx !== order.contract.steps.length - 1) {
            if (AuthorizationService.roles(account, "customer") && (order.contract.steps[indx]?.type === "supplier")) {
                return <MessageInfo message={'Ожидайте перевод заказа на следующую стадию со стороны поставщика.'}/>
            }

            if (AuthorizationService.roles(account, "supplier") && (order.contract.steps[indx]?.type === "customer")) {
                return <MessageInfo message={'Ожидайте перевод заказа на следующую стадию со стороны заказчика.'}/>
            }
        }
    }

    const getData = (index, files, setFieldValue, setFieldTouched, values) => {
        switch (index) {
            case 2:
                if (AuthorizationService.roles(account, "supplier")) {
                    return (
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                            <Grid item>
                                <Field
                                    fullWidth
                                    size="small"
                                    component={(props) => (
                                        <FileUpload {...props} multiple endIcon={<AddIcon/>}/>
                                    )}
                                    label="Счет на оплату"
                                    name={`files.invoice_payment`}
                                    InputLabelProps={{
                                        name: null
                                    }}
                                    InputProps={{
                                        multiple: true,
                                        onChange: (event) => {
                                            setChange(true)
                                            setFieldValue(`files.invoice_payment`, (files.invoice_payment ?? []).concat(Array.from(event.currentTarget.files)));
                                            setFieldTouched(`files.invoice_payment`, true, false)
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                    {Array.from(files.invoice_payment ?? []).map((file, index) => {
                                        return (
                                            <Grid key={index} item>
                                                <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                    <Grid item>
                                                        <Typography>{file.name}</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton
                                                            size={"small"}
                                                            onClick={() => {
                                                                if (file.hasOwnProperty('id')) {
                                                                    setChange(true)
                                                                } else if (!(files.invoice_payment ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'invoice_payment').length === (files.invoice_payment ?? []).filter(el => el.hasOwnProperty('id')).length)) {
                                                                    setChange(false)
                                                                }

                                                                setFieldValue('files.invoice_payment', (files.invoice_payment ?? []).filter((_, i) => i !== index))
                                                            }}
                                                        >
                                                            <Clear/>
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
                break
            case 3:
                if (AuthorizationService.roles(account, "customer") && AuthorizationService.permissions(account, "delivery_order_after_event")) {
                    return (
                        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
                            <Grid item>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                    <Grid item>
                                        <Field
                                            fullWidth
                                            size="small"
                                            component={(props) => (
                                                <FileUpload {...props} multiple endIcon={<AddIcon/>}/>
                                            )}
                                            label="Документы"
                                            name={`files.document`}
                                            InputLabelProps={{
                                                name: null
                                            }}
                                            InputProps={{
                                                multiple: true,
                                                onChange: (event) => {
                                                    setChange(true)
                                                    setFieldValue(`files.document`, (files.document ?? []).concat(Array.from(event.currentTarget.files)));
                                                    setFieldTouched(`files.document`, true, false)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                            {Array.from(files.document ?? []).map((file, index) => {
                                                return (
                                                    <Grid key={index} item>
                                                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Typography>{file.name}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    size={"small"}
                                                                    onClick={() => {
                                                                        if (file.hasOwnProperty('id')) {
                                                                            setChange(true)
                                                                        } else if (!(files.document ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'invoice_payment').length === (files.invoice_payment ?? []).filter(el => el.hasOwnProperty('id')).length)) {
                                                                            setChange(false)
                                                                        }

                                                                        setFieldValue('files.document', (files.document ?? []).filter((_, i) => i !== index))
                                                                    }}
                                                                >
                                                                    <Clear/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            fullWidth
                                            name="actual_order_date"
                                            required
                                            disableFuture={true}
                                            orientation="landscape"
                                            variant="inline"
                                            format="dd-MM-yyyy"
                                            openTo="year"
                                            autoOk
                                            label="Фактическая дата заказа"
                                            value={values.actual_order_date}
                                            onChange={date => {
                                                setChange(true)
                                                setFieldValue(`actual_order_date`, date);
                                            }}
                                            onBlur={() => {
                                                setFieldTouched(`actual_order_date`, true, true)
                                            }}
                                            onClose={() => {
                                                setFieldTouched(`actual_date`, true, true)
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
                break
            case 4:
                if (AuthorizationService.roles(account, "customer") && AuthorizationService.permissions(account, "confirmation_payment_delivery_order")) {
                    return (
                        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
                            <Grid item>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                    <Grid item>
                                        <Field
                                            fullWidth
                                            size="small"
                                            component={(props) => (
                                                <FileUpload {...props} multiple endIcon={<AddIcon/>}/>
                                            )}
                                            label="Чек об оплате"
                                            name={`files.check`}
                                            InputLabelProps={{
                                                name: null
                                            }}
                                            InputProps={{
                                                multiple: true,
                                                onChange: (event) => {
                                                    setChangeFiles(true)
                                                    setFieldValue(`files.check`, (files.check ?? []).concat(Array.from(event.currentTarget.files)));
                                                    setFieldTouched(`files.check`, true, false)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                            {Array.from(files.check ?? []).map((file, index) => {
                                                return (
                                                    <Grid key={index} item>
                                                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Typography>{file.name}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    size={"small"}
                                                                    onClick={() => {
                                                                        if (file.hasOwnProperty('id')) {
                                                                            setChange(true)
                                                                        } else if (!(files.check ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'check').length === (files.check ?? []).filter(el => el.hasOwnProperty('id')).length)) {
                                                                            setChange(false)
                                                                        }

                                                                        setFieldValue('files.check', (files.check ?? []).filter((_, i) => i !== index))
                                                                    }}
                                                                >
                                                                    <Clear/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    fullWidth
                                    name="payment_sum"
                                    type="number"
                                    label="Сумма, оплаченная заказчиком"
                                    step={0.01}
                                    required
                                    inputProps={{
                                        min: 0,
                                        onChange: (event) => {
                                            setChange(true)
                                            let val = event.target.value.replace(/[^\d.,|]/g, '')
                                            setFieldValue('payment_sum', val)
                                            setFieldTouched(`payment_sum`, true, false)
                                        }
                                    }}
                                    component={CustomTextField}
                                />
                            </Grid>
                        </Grid>
                    )
                }
                break
            case 5:
                if (AuthorizationService.roles(account, "customer") && AuthorizationService.permissions(account, "confirmation_payment_delivery_order")) {
                    return (
                        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
                            <Grid item>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                    <Grid item>
                                        <Field
                                            fullWidth
                                            size="small"
                                            component={(props) => (
                                                <FileUpload {...props} multiple endIcon={<AddIcon/>}/>
                                            )}
                                            label="Чек об оплате"
                                            name={`files.check`}
                                            InputLabelProps={{
                                                name: null
                                            }}
                                            InputProps={{
                                                multiple: true,
                                                onChange: (event) => {
                                                    setChangeFiles(true)
                                                    setFieldValue(`files.check`, (files.check ?? []).concat(Array.from(event.currentTarget.files)));
                                                    setFieldTouched(`files.check`, true, false)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                            {Array.from(files.check ?? []).map((file, index) => {
                                                return (
                                                    <Grid key={index} item>
                                                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Typography>{file.name}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    size={"small"}
                                                                    onClick={() => {
                                                                        if (file.hasOwnProperty('id')) {
                                                                            setChange(true)
                                                                        } else if (!(files.check ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'check').length === (files.check ?? []).filter(el => el.hasOwnProperty('id')).length)) {
                                                                            setChange(false)
                                                                        }

                                                                        setFieldValue('files.check', (files.check ?? []).filter((_, i) => i !== index))
                                                                    }}
                                                                >
                                                                    <Clear/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    fullWidth
                                    name="payment_sum"
                                    type="number"
                                    label="Сумма, оплаченная заказчиком"
                                    step={0.01}
                                    required
                                    inputProps={{
                                        min: 0,
                                        onChange: (event) => {
                                            setChange(true)
                                            let val = event.target.value.replace(/[^\d.,|]/g, '')
                                            setFieldValue('payment_sum', val)
                                            setFieldTouched(`payment_sum`, true, false)
                                        }
                                    }}
                                    component={CustomTextField}
                                />
                            </Grid>
                        </Grid>
                    )
                }
                break
            case 6:
                if (AuthorizationService.roles(account, "customer") && AuthorizationService.permissions(account, "confirmation_payment_delivery_order")) {
                    return (
                        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
                            <Grid item>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                    <Grid item>
                                        <Field
                                            fullWidth
                                            size="small"
                                            component={(props) => (
                                                <FileUpload {...props} multiple endIcon={<AddIcon/>}/>
                                            )}
                                            label="Чек об оплате"
                                            name={`files.check`}
                                            InputLabelProps={{
                                                name: null
                                            }}
                                            InputProps={{
                                                multiple: true,
                                                onChange: (event) => {
                                                    setChangeFiles(true)
                                                    setFieldValue(`files.check`, (files.check ?? []).concat(Array.from(event.currentTarget.files)));
                                                    setFieldTouched(`files.check`, true, false)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                            {Array.from(files.check ?? []).map((file, index) => {
                                                return (
                                                    <Grid key={index} item>
                                                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Typography>{file.name}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    size={"small"}
                                                                    onClick={() => {
                                                                        if (file.hasOwnProperty('id')) {
                                                                            setChangeFiles(true)
                                                                        } else if (!(files.check ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'check').length === (files.check ?? []).filter(el => el.hasOwnProperty('id')).length)) {
                                                                            setChangeFiles(false)
                                                                        }

                                                                        setFieldValue('files.check', (files.check ?? []).filter((_, i) => i !== index))
                                                                    }}
                                                                >
                                                                    <Clear/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    fullWidth
                                    name="payment_sum"
                                    type="number"
                                    label="Сумма, оплаченная заказчиком"
                                    step={0.01}
                                    required
                                    inputProps={{
                                        min: 0,
                                        onChange: (event) => {
                                            setChange(true)
                                            let val = event.target.value.replace(/[^\d.,|]/g, '')
                                            setFieldValue('payment_sum', val)
                                            setFieldTouched(`payment_sum`, true, false)
                                        }
                                    }}
                                    component={CustomTextField}
                                />
                            </Grid>
                        </Grid>
                    )
                }
                break
            case 7:
                if (AuthorizationService.roles(account, "customer") && AuthorizationService.permissions(account, "delivery_order_after_event")) {
                    return (
                        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
                            <Grid item>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                    <Grid item>
                                        <Field
                                            fullWidth
                                            size="small"
                                            component={(props) => (
                                                <FileUpload {...props} multiple endIcon={<AddIcon/>}/>
                                            )}
                                            label="Документы"
                                            name={`files.document`}
                                            InputLabelProps={{
                                                name: null
                                            }}
                                            InputProps={{
                                                multiple: true,
                                                onChange: (event) => {
                                                    setChangeFiles(true)
                                                    setFieldValue(`files.document`, (files.document ?? []).concat(Array.from(event.currentTarget.files)));
                                                    setFieldTouched(`files.document`, true, false)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                            {Array.from(files.document ?? []).map((file, index) => {
                                                return (
                                                    <Grid key={index} item>
                                                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Typography>{file.name}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    size={"small"}
                                                                    onClick={() => {
                                                                        if (file.hasOwnProperty('id')) {
                                                                            setChangeFiles(true)
                                                                        } else if (!(files.document ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'invoice_payment').length === (files.invoice_payment ?? []).filter(el => el.hasOwnProperty('id')).length)) {
                                                                            setChangeFiles(false)
                                                                        }

                                                                        setFieldValue('files.document', (files.document ?? []).filter((_, i) => i !== index))
                                                                    }}
                                                                >
                                                                    <Clear/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        fullWidth
                                        name="actual_order_date"
                                        required
                                        disableFuture={true}
                                        orientation="landscape"
                                        variant="inline"
                                        format="dd-MM-yyyy"
                                        openTo="year"
                                        autoOk
                                        label="Фактическая дата заказа"
                                        value={values.actual_order_date}
                                        onChange={date => {
                                            setChange(true)
                                            setFieldValue(`actual_order_date`, date);
                                        }}
                                        onBlur={() => {
                                            setFieldTouched(`actual_order_date`, true, true)
                                        }}
                                        onClose={() => {
                                            setFieldTouched(`actual_order_date`, true, true)
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        fullWidth
                                        name="actual_date"
                                        required
                                        orientation="landscape"
                                        variant="inline"
                                        format="dd-MM-yyyy"
                                        openTo="year"
                                        autoOk
                                        label="Фактическая дата доставки"
                                        value={values.actual_date}
                                        onChange={date => {
                                            setChange(true)
                                            setFieldValue(`actual_date`, date);
                                        }}
                                        onBlur={() => {
                                            setFieldTouched(`actual_date`, true, true)
                                        }}
                                        onClose={() => {
                                            setFieldTouched(`actual_date`, true, true)
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    fullWidth
                                    name="payment_sum"
                                    type="number"
                                    label="Сумма, оплаченная заказчиком"
                                    step={0.01}
                                    required
                                    inputProps={{
                                        min: 0,
                                        onChange: (event) => {
                                            setChange(true)
                                            let val = event.target.value.replace(/[^\d.,|]/g, '')
                                            setFieldValue('payment_sum', val)
                                            setFieldTouched(`payment_sum`, true, false)
                                        }
                                    }}
                                    component={CustomTextField}
                                />
                            </Grid>
                        </Grid>
                    )
                }
                break
            case 8:
                if (AuthorizationService.roles(account, "supplier")) {
                    return (
                        <Grid item xs={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    fullWidth
                                    name="estimated_date"
                                    required
                                    orientation="landscape"
                                    variant="inline"
                                    initialFocusedDate={new Date()}
                                    format="dd-MM-yyyy"
                                    openTo="year"
                                    autoOk
                                    label="Предположительная дата доставки"
                                    value={values.estimated_date}
                                    onChange={date => {
                                        setChange(true)
                                        setFieldValue(`estimated_date`, date);
                                    }}
                                    onBlur={() => {
                                        setFieldTouched(`estimated_date`, true, true)
                                    }}
                                    onClose={() => {
                                        setFieldTouched(`estimated_date`, true, true)
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    )
                }
                break
            case 9:
                if (AuthorizationService.roles(account, "customer") && AuthorizationService.permissions(account, "receiving_confirmation_editing_delivery_order")) {
                    return (
                        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
                            <Grid item>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                    <Grid item>
                                        <Field
                                            fullWidth
                                            size="small"
                                            component={(props) => (
                                                <FileUpload {...props} multiple endIcon={<AddIcon/>}/>
                                            )}
                                            label="Чек об оплате"
                                            name={`files.check`}
                                            InputLabelProps={{
                                                name: null
                                            }}
                                            InputProps={{
                                                multiple: true,
                                                onChange: (event) => {
                                                    setChangeFiles(true)
                                                    setFieldValue(`files.check`, (files.check ?? []).concat(Array.from(event.currentTarget.files)));
                                                    setFieldTouched(`files.check`, true, false)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                            {Array.from(files.check ?? []).map((file, index) => {
                                                return (
                                                    <Grid key={index} item>
                                                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Typography>{file.name}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    size={"small"}
                                                                    onClick={() => {
                                                                        if (file.hasOwnProperty('id')) {
                                                                            setChangeFiles(true)
                                                                        } else if (
                                                                            (!(files.check ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'check').length === (files.check ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.invoice ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'invoice').length === (files.invoice ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.certificate_completion ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'certificate_completion').length === (files.certificate_completion ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.universal_transmission_document ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'universal_transmission_document').length === (files.universal_transmission_document ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.waybill ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'waybill').length === (files.waybill ?? []).filter(el => el.hasOwnProperty('id')).length))
                                                                        ) {
                                                                            setChangeFiles(false)
                                                                        }

                                                                        setFieldValue('files.check', (files.check ?? []).filter((_, i) => i !== index))
                                                                    }}
                                                                >
                                                                    <Clear/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                    <Grid item>
                                        <Field
                                            fullWidth
                                            size="small"
                                            component={(props) => (
                                                <FileUpload {...props} multiple endIcon={<AddIcon/>}/>
                                            )}
                                            label="Счет-фактура"
                                            name={`files.invoice`}
                                            InputLabelProps={{
                                                name: null
                                            }}
                                            InputProps={{
                                                multiple: true,
                                                onChange: (event) => {
                                                    setChangeFiles(true)
                                                    setFieldValue(`files.invoice`, (files.invoice ?? []).concat(Array.from(event.currentTarget.files)));
                                                    setFieldTouched(`files.invoice`, true, false)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                            {Array.from(files.invoice ?? []).map((file, index) => {
                                                return (
                                                    <Grid key={index} item>
                                                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Typography>{file.name}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    size={"small"}
                                                                    onClick={() => {
                                                                        if (file.hasOwnProperty('id')) {
                                                                            setChangeFiles(true)
                                                                        } else if (
                                                                            (!(files.check ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'check').length === (files.check ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.invoice ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'invoice').length === (files.invoice ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.certificate_completion ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'certificate_completion').length === (files.certificate_completion ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.universal_transmission_document ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'universal_transmission_document').length === (files.universal_transmission_document ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.waybill ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'waybill').length === (files.waybill ?? []).filter(el => el.hasOwnProperty('id')).length))
                                                                        ) {
                                                                            setChangeFiles(false)
                                                                        }

                                                                        setFieldValue('files.invoice', (files.invoice ?? []).filter((_, i) => i !== index))
                                                                    }}
                                                                >
                                                                    <Clear/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                    <Grid item>
                                        <Field
                                            fullWidth
                                            size="small"
                                            component={(props) => (
                                                <FileUpload {...props} multiple endIcon={<AddIcon/>}/>
                                            )}
                                            label="Акт выполненных работ"
                                            name={`files.certificate_completion`}
                                            InputLabelProps={{
                                                name: null
                                            }}
                                            InputProps={{
                                                multiple: true,
                                                onChange: (event) => {
                                                    setChangeFiles(true)
                                                    setFieldValue(`files.certificate_completion`, (files.certificate_completion ?? []).concat(Array.from(event.currentTarget.files)));
                                                    setFieldTouched(`files.certificate_completion`, true, false)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                            {Array.from(files.certificate_completion ?? []).map((file, index) => {
                                                return (
                                                    <Grid key={index} item>
                                                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Typography>{file.name}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    size={"small"}
                                                                    onClick={() => {
                                                                        if (file.hasOwnProperty('id')) {
                                                                            setChangeFiles(true)
                                                                        } else if (
                                                                            (!(files.check ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'check').length === (files.check ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.invoice ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'invoice').length === (files.invoice ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.certificate_completion ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'certificate_completion').length === (files.certificate_completion ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.universal_transmission_document ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'universal_transmission_document').length === (files.universal_transmission_document ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.waybill ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'waybill').length === (files.waybill ?? []).filter(el => el.hasOwnProperty('id')).length))
                                                                        ) {
                                                                            setChangeFiles(false)
                                                                        }

                                                                        setFieldValue('files.certificate_completion', (files.certificate_completion ?? []).filter((_, i) => i !== index))
                                                                    }}
                                                                >
                                                                    <Clear/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                    <Grid item>
                                        <Field
                                            fullWidth
                                            size="small"
                                            component={(props) => (
                                                <FileUpload {...props} multiple endIcon={<AddIcon/>}/>
                                            )}
                                            label="Универсальный передаточный документ"
                                            name={`files.universal_transmission_document`}
                                            InputLabelProps={{
                                                name: null
                                            }}
                                            InputProps={{
                                                multiple: true,
                                                onChange: (event) => {
                                                    setChangeFiles(true)
                                                    setFieldValue(`files.universal_transmission_document`, (files.universal_transmission_document ?? []).concat(Array.from(event.currentTarget.files)));
                                                    setFieldTouched(`files.universal_transmission_document`, true, false)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                            {Array.from(files.universal_transmission_document ?? []).map((file, index) => {
                                                return (
                                                    <Grid key={index} item>
                                                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Typography>{file.name}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    size={"small"}
                                                                    onClick={() => {
                                                                        if (file.hasOwnProperty('id')) {
                                                                            setChangeFiles(true)
                                                                        } else if (
                                                                            (!(files.check ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'check').length === (files.check ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.invoice ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'invoice').length === (files.invoice ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.certificate_completion ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'certificate_completion').length === (files.certificate_completion ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.universal_transmission_document ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'universal_transmission_document').length === (files.universal_transmission_document ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.waybill ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'waybill').length === (files.waybill ?? []).filter(el => el.hasOwnProperty('id')).length))
                                                                        ) {
                                                                            setChangeFiles(false)
                                                                        }

                                                                        setFieldValue('files.universal_transmission_document', (files.universal_transmission_document ?? []).filter((_, i) => i !== index))
                                                                    }}
                                                                >
                                                                    <Clear/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                    <Grid item>
                                        <Field
                                            fullWidth
                                            size="small"
                                            component={(props) => (
                                                <FileUpload {...props} multiple endIcon={<AddIcon/>}/>
                                            )}
                                            label="Товарно-транспортная накладная"
                                            name={`files.waybill`}
                                            InputLabelProps={{
                                                name: null
                                            }}
                                            InputProps={{
                                                multiple: true,
                                                onChange: (event) => {
                                                    setChangeFiles(true)
                                                    setFieldValue(`files.waybill`, (files.waybill ?? []).concat(Array.from(event.currentTarget.files)));
                                                    setFieldTouched(`files.waybill`, true, false)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                            {Array.from(files.waybill ?? []).map((file, index) => {
                                                return (
                                                    <Grid key={index} item>
                                                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Typography>{file.name}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    size={"small"}
                                                                    onClick={() => {
                                                                        if (file.hasOwnProperty('id')) {
                                                                            setChangeFiles(true)
                                                                        } else if (
                                                                            (!(files.check ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'check').length === (files.check ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.invoice ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'invoice').length === (files.invoice ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.certificate_completion ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'certificate_completion').length === (files.certificate_completion ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.universal_transmission_document ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'universal_transmission_document').length === (files.universal_transmission_document ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.waybill ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'waybill').length === (files.waybill ?? []).filter(el => el.hasOwnProperty('id')).length))
                                                                        ) {
                                                                            setChangeFiles(false)
                                                                        }

                                                                        setFieldValue('files.waybill', (files.waybill ?? []).filter((_, i) => i !== index))
                                                                    }}
                                                                >
                                                                    <Clear/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        fullWidth
                                        name="actual_date"
                                        required
                                        orientation="landscape"
                                        invalidDateMessage="Некорректная дата"
                                        minDateMessage="Некорректная дата"
                                        variant="inline"
                                        format="dd-MM-yyyy"
                                        openTo="year"
                                        autoOk
                                        label="Фактическая дата доставки"
                                        value={values.actual_date}
                                        onChange={date => {
                                            setChange(true)
                                            setFieldValue(`actual_date`, date);
                                        }}
                                        onBlur={() => {
                                            setFieldTouched(`actual_date`, true, true)
                                        }}
                                        onClose={() => {
                                            setFieldTouched(`actual_date`, true, true)
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    fullWidth
                                    name="payment_sum"
                                    type="number"
                                    label="Сумма, оплаченная заказчиком"
                                    step={0.01}
                                    required
                                    inputProps={{
                                        min: 0,
                                        onChange: (event) => {
                                            setChange(true)
                                            let val = event.target.value.replace(/[^\d.,|]/g, '')
                                            setFieldValue('payment_sum', val)
                                            setFieldTouched(`payment_sum`, true, false)
                                        }
                                    }}
                                    component={CustomTextField}
                                />
                            </Grid>
                        </Grid>
                    )
                }
                if (AuthorizationService.roles(account, "supplier")) {
                    return (
                        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
                            <Grid item>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                    <Grid item>
                                        <Field
                                            fullWidth
                                            size="small"
                                            component={(props) => (
                                                <FileUpload {...props} multiple endIcon={<AddIcon/>}/>
                                            )}
                                            label="Счет на оплату"
                                            name={`files.invoice_payment`}
                                            InputLabelProps={{
                                                name: null
                                            }}
                                            InputProps={{
                                                multiple: true,
                                                onChange: (event) => {
                                                    setChangeFiles(true)
                                                    setFieldValue(`files.invoice_payment`, (files.invoice_payment ?? []).concat(Array.from(event.currentTarget.files)));
                                                    setFieldTouched(`files.invoice_payment`, true, false)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                            {Array.from(files.invoice_payment ?? []).map((file, index) => {
                                                return (
                                                    <Grid key={index} item>
                                                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Typography>{file.name}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    size={"small"}
                                                                    onClick={() => {
                                                                        if (file.hasOwnProperty('id')) {
                                                                            setChangeFiles(true)
                                                                        } else if (!(files.invoice_payment ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'invoice_payment').length === (files.invoice_payment ?? []).filter(el => el.hasOwnProperty('id')).length)) {
                                                                            setChangeFiles(false)
                                                                        }

                                                                        setFieldValue('files.invoice_payment', (files.invoice_payment ?? []).filter((_, i) => i !== index))
                                                                    }}
                                                                >
                                                                    <Clear/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        fullWidth
                                        name="estimated_date"
                                        required
                                        orientation="landscape"
                                        variant="inline"
                                        initialFocusedDate={new Date()}
                                        format="dd-MM-yyyy"
                                        openTo="year"
                                        autoOk
                                        label="Предположительная дата доставки"
                                        value={values.estimated_date}
                                        onChange={date => {
                                            setChange(true)
                                            setFieldValue(`estimated_date`, date);
                                        }}
                                        onBlur={() => {
                                            setFieldTouched(`estimated_date`, true, true)
                                        }}
                                        onClose={() => {
                                            setFieldTouched(`estimated_date`, true, true)
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    )
                }
                break
            case 10:
                if (AuthorizationService.roles(account, "customer") && AuthorizationService.permissions(account, "delivery_order_after_event")) {
                    return (
                        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
                            <Grid item>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                    <Grid item>
                                        <Field
                                            fullWidth
                                            size="small"
                                            component={(props) => (
                                                <FileUpload {...props} multiple endIcon={<AddIcon/>}/>
                                            )}
                                            label="Документы"
                                            name={`files.document`}
                                            InputLabelProps={{
                                                name: null
                                            }}
                                            InputProps={{
                                                multiple: true,
                                                onChange: (event) => {
                                                    setChangeFiles(true)
                                                    setFieldValue(`files.document`, (files.document ?? []).concat(Array.from(event.currentTarget.files)));
                                                    setFieldTouched(`files.document`, true, false)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                            {Array.from(files.document ?? []).map((file, index) => {
                                                return (
                                                    <Grid key={index} item>
                                                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Typography>{file.name}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    size={"small"}
                                                                    onClick={() => {
                                                                        if (file.hasOwnProperty('id')) {
                                                                            setChangeFiles(true)
                                                                        } else if (!(files.document ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'invoice_payment').length === (files.invoice_payment ?? []).filter(el => el.hasOwnProperty('id')).length)) {
                                                                            setChangeFiles(false)
                                                                        }

                                                                        setFieldValue('files.document', (files.document ?? []).filter((_, i) => i !== index))
                                                                    }}
                                                                >
                                                                    <Clear/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        fullWidth
                                        name="actual_order_date"
                                        required
                                        disableFuture={true}
                                        orientation="landscape"
                                        variant="inline"
                                        format="dd-MM-yyyy"
                                        openTo="year"
                                        autoOk
                                        label="Фактическая дата заказа"
                                        value={values.actual_order_date}
                                        onChange={date => {
                                            setChange(true)
                                            setFieldValue(`actual_order_date`, date);
                                        }}
                                        onBlur={() => {
                                            setFieldTouched(`actual_order_date`, true, true)
                                        }}
                                        onClose={() => {
                                            setFieldTouched(`actual_order_date`, true, true)
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        fullWidth
                                        name="actual_date"
                                        required
                                        orientation="landscape"
                                        variant="inline"
                                        format="dd-MM-yyyy"
                                        openTo="year"
                                        autoOk
                                        label="Фактическая дата доставки"
                                        value={values.actual_date}
                                        onChange={date => {
                                            setChange(true)
                                            setFieldValue(`actual_date`, date);
                                        }}
                                        onBlur={() => {
                                            setFieldTouched(`actual_date`, true, true)
                                        }}
                                        onClose={() => {
                                            setFieldTouched(`actual_date`, true, true)
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    fullWidth
                                    name="payment_sum"
                                    type="number"
                                    label="Сумма, оплаченная заказчиком"
                                    step={0.01}
                                    required
                                    inputProps={{
                                        min: 0,
                                        onChange: (event) => {
                                            setChange(true)
                                            let val = event.target.value.replace(/[^\d.,|]/g, '')
                                            setFieldValue('payment_sum', val)
                                            setFieldTouched(`payment_sum`, true, false)
                                        }
                                    }}
                                    component={CustomTextField}
                                />
                            </Grid>
                        </Grid>
                    )
                }
                break
            case 11:
                if (AuthorizationService.roles(account, "customer") && AuthorizationService.permissions(account, "receiving_confirmation_editing_delivery_order")) {
                    return (
                        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
                            <Grid item>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                    <Grid item>
                                        <Field
                                            fullWidth
                                            size="small"
                                            component={(props) => (
                                                <FileUpload {...props} multiple endIcon={<AddIcon/>}/>
                                            )}
                                            label="Счет-фактура"
                                            name={`files.invoice`}
                                            InputLabelProps={{
                                                name: null
                                            }}
                                            InputProps={{
                                                multiple: true,
                                                onChange: (event) => {
                                                    setChangeFiles(true)
                                                    setFieldValue(`files.invoice`, (files.invoice ?? []).concat(Array.from(event.currentTarget.files)));
                                                    setFieldTouched(`files.invoice`, true, false)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                            {Array.from(files.invoice ?? []).map((file, index) => {
                                                return (
                                                    <Grid key={index} item>
                                                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Typography>{file.name}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    size={"small"}
                                                                    onClick={() => {
                                                                        if (file.hasOwnProperty('id')) {
                                                                            setChangeFiles(true)
                                                                        } else if (
                                                                            (!(files.invoice ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'invoice').length === (files.invoice ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.certificate_completion ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'certificate_completion').length === (files.certificate_completion ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.universal_transmission_document ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'universal_transmission_document').length === (files.universal_transmission_document ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.waybill ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'waybill').length === (files.waybill ?? []).filter(el => el.hasOwnProperty('id')).length))
                                                                        ) {
                                                                            setChangeFiles(false)
                                                                        }

                                                                        setFieldValue('files.invoice', (files.invoice ?? []).filter((_, i) => i !== index))
                                                                    }}
                                                                >
                                                                    <Clear/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                    <Grid item>
                                        <Field
                                            fullWidth
                                            size="small"
                                            component={(props) => (
                                                <FileUpload {...props} multiple endIcon={<AddIcon/>}/>
                                            )}
                                            label="Акт выполненных работ"
                                            name={`files.certificate_completion`}
                                            InputLabelProps={{
                                                name: null
                                            }}
                                            InputProps={{
                                                multiple: true,
                                                onChange: (event) => {
                                                    setChangeFiles(true)
                                                    setFieldValue(`files.certificate_completion`, (files.certificate_completion ?? []).concat(Array.from(event.currentTarget.files)));
                                                    setFieldTouched(`files.certificate_completion`, true, false)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                            {Array.from(files.certificate_completion ?? []).map((file, index) => {
                                                return (
                                                    <Grid key={index} item>
                                                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Typography>{file.name}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    size={"small"}
                                                                    onClick={() => {
                                                                        if (file.hasOwnProperty('id')) {
                                                                            setChangeFiles(true)
                                                                        } else if (
                                                                            (!(files.invoice ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'invoice').length === (files.invoice ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.certificate_completion ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'certificate_completion').length === (files.certificate_completion ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.universal_transmission_document ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'universal_transmission_document').length === (files.universal_transmission_document ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.waybill ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'waybill').length === (files.waybill ?? []).filter(el => el.hasOwnProperty('id')).length))
                                                                        ) {
                                                                            setChangeFiles(false)
                                                                        }

                                                                        setFieldValue('files.certificate_completion', (files.certificate_completion ?? []).filter((_, i) => i !== index))
                                                                    }}
                                                                >
                                                                    <Clear/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                    <Grid item>
                                        <Field
                                            fullWidth
                                            size="small"
                                            component={(props) => (
                                                <FileUpload {...props} multiple endIcon={<AddIcon/>}/>
                                            )}
                                            label="Универсальный передаточный документ"
                                            name={`files.universal_transmission_document`}
                                            InputLabelProps={{
                                                name: null
                                            }}
                                            InputProps={{
                                                multiple: true,
                                                onChange: (event) => {
                                                    setChangeFiles(true)
                                                    setFieldValue(`files.universal_transmission_document`, (files.universal_transmission_document ?? []).concat(Array.from(event.currentTarget.files)));
                                                    setFieldTouched(`files.universal_transmission_document`, true, false)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                            {Array.from(files.universal_transmission_document ?? []).map((file, index) => {
                                                return (
                                                    <Grid key={index} item>
                                                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Typography>{file.name}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    size={"small"}
                                                                    onClick={() => {
                                                                        if (file.hasOwnProperty('id')) {
                                                                            setChangeFiles(true)
                                                                        } else if (
                                                                            (!(files.invoice ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'invoice').length === (files.invoice ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.certificate_completion ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'certificate_completion').length === (files.certificate_completion ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.universal_transmission_document ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'universal_transmission_document').length === (files.universal_transmission_document ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.waybill ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'waybill').length === (files.waybill ?? []).filter(el => el.hasOwnProperty('id')).length))
                                                                        ) {
                                                                            setChangeFiles(false)
                                                                        }

                                                                        setFieldValue('files.universal_transmission_document', (files.universal_transmission_document ?? []).filter((_, i) => i !== index))
                                                                    }}
                                                                >
                                                                    <Clear/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                    <Grid item>
                                        <Field
                                            fullWidth
                                            size="small"
                                            component={(props) => (
                                                <FileUpload {...props} multiple endIcon={<AddIcon/>}/>
                                            )}
                                            label="Товарно-транспортная накладная"
                                            name={`files.waybill`}
                                            InputLabelProps={{
                                                name: null
                                            }}
                                            InputProps={{
                                                multiple: true,
                                                onChange: (event) => {
                                                    setChangeFiles(true)
                                                    setFieldValue(`files.waybill`, (files.waybill ?? []).concat(Array.from(event.currentTarget.files)));
                                                    setFieldTouched(`files.waybill`, true, false)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                            {Array.from(files.waybill ?? []).map((file, index) => {
                                                return (
                                                    <Grid key={index} item>
                                                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Typography>{file.name}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    size={"small"}
                                                                    onClick={() => {
                                                                        if (file.hasOwnProperty('id')) {
                                                                            setChangeFiles(true)
                                                                        } else if (
                                                                            (!(files.invoice ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'invoice').length === (files.invoice ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.certificate_completion ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'certificate_completion').length === (files.certificate_completion ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.universal_transmission_document ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'universal_transmission_document').length === (files.universal_transmission_document ?? []).filter(el => el.hasOwnProperty('id')).length)) &&
                                                                            (!(files.waybill ?? []).filter((el, i) => !el.hasOwnProperty('id') && (i !== index)).length && (order.files.filter(el => el.type.key === 'waybill').length === (files.waybill ?? []).filter(el => el.hasOwnProperty('id')).length))
                                                                        ) {
                                                                            setChangeFiles(false)
                                                                        }

                                                                        setFieldValue('files.waybill', (files.waybill ?? []).filter((_, i) => i !== index))
                                                                    }}
                                                                >
                                                                    <Clear/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        fullWidth
                                        name="actual_date"
                                        required
                                        orientation="landscape"
                                        variant="inline"
                                        format="dd-MM-yyyy"
                                        openTo="year"
                                        autoOk
                                        label="Фактическая дата доставки"
                                        value={values.actual_date}
                                        onChange={date => {
                                            setChange(true)
                                            setFieldValue(`actual_date`, date);
                                        }}
                                        onBlur={() => {
                                            setFieldTouched(`actual_date`, true, true)
                                        }}
                                        onClose={() => {
                                            setFieldTouched(`actual_date`, true, true)
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    )
                }
                break
            default:
        }
    }

    const getNumber = (order) => {
        let lotNumber = order.contract.offer.lot.number ? order.contract.offer.lot.number : order.contract.offer.lot.index;
        return order.contract.purchase_number ? `${order.contract.purchase_number}/${lotNumber}` : null;
    }

    const handleClose = () => {
        setOpenCard(false)
        setCardCompany(null)
        setCardContractID(null)
    }

    return (order && status.length && items) ? (
        <Grid item className={classes.content}>
            {(openCard && cardCompany) && <Card contractID={cardContractID} companyType={(AuthorizationService.roles(account, "customer") ? 'поставщика' : 'заказчика')} company={cardCompany} open={openCard} onClose={handleClose}/>}
            <Grid container direction="column" justify="center" alignItems="stretch">
                <Grid item>
                    <TabPanel value={1} index={1}>
                        <Box>
                            <Stepper orientation="horizontal" nonLinear={true} className={classes.accordionItem} connector={<Connector/>}>
                                {getSteps()}
                            </Stepper>
                        </Box>
                    </TabPanel>
                </Grid>
            </Grid>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.fullWidth}>
                        <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    items: products.data.map(item => (
                                        {
                                            id: item.id,
                                            amount: item.amount,
                                            price: item.price,
                                            totalPrice: item.total ? parseFloat(item.total).toFixed(2) : (item.price * item.amount).toFixed(2),
                                            gross_weight: item.gross_weight,
                                            tnved: item.tnved,
                                        }
                                    )),
                                    files: {
                                        document: order.files.filter(file => (file.type.key === 'document')),
                                        invoice_payment: order.files.filter(file => (file.type.key === 'invoice_payment')),
                                        check: order.files.filter(file => (file.type.key === 'check')),
                                        invoice: order.files.filter(file => (file.type.key === 'invoice')),
                                        certificate_completion: order.files.filter(file => (file.type.key === 'certificate_completion')),
                                        universal_transmission_document: order.files.filter(file => (file.type.key === 'universal_transmission_document')),
                                        waybill: order.files.filter(file => (file.type.key === 'waybill'))
                                    },
                                    payment_sum: order.payment_sum ?? '',
                                    estimated_date: order.estimated_date,
                                    actual_date: order.actual_date,
                                    actual_order_date: order.actual_order_date,
                                }}
                                validationSchema={Yup.object().shape({})}
                                validate={values => {
                                    const errors = {};
                                    values.items.forEach((item, index) => {
                                        const min = parseFloat(products.data[index].min_ordered_quantity ?? 0);
                                        const max = parseFloat(products.data[index].needed) + parseFloat(products.data[index].amount)

                                        if (parseFloat(item.amount) < min) {
                                            if (!errors.hasOwnProperty('items')) {
                                                errors.items = []
                                            }
                                            errors.items[index] = {amount: `Мин. заказ >= ${min} ${item.unit ? item.unit.short : ''}`}
                                        } else if (parseFloat(item.amount) > max && (order.contract.offer.lot.order.form !== null || !products.data[index].can_be_ordered)) {
                                            if (!errors.hasOwnProperty('items')) {
                                                errors.items = []
                                            }
                                            errors.items[index] = {amount: `Макс. заказ <= ${max} ${item.unit ? item.unit.short : ''}`}
                                        } else if (item.tnved !== null && item.tnved.length !== undefined && item.tnved.length !== 10) {
                                            if (!errors.hasOwnProperty('items')) {
                                                errors.items = []
                                            }
                                            errors.items[index] = {tnved: `Длина ТН ВЭД должна быть 10 символов!`}
                                        }
                                    })

                                    return errors
                                }}
                                onSubmit={(values, {setSubmitting}) => {
                                    const formData = new FormData()

                                    for (const i in values) {
                                        if (values.hasOwnProperty(i)) {
                                            switch (i) {
                                                case 'files':
                                                    const types = ['document', 'invoice_payment', 'check', 'invoice', 'certificate_completion', 'universal_transmission_document', 'waybill']

                                                    types.forEach(type => {
                                                        values[i][type].length ? values[i][type].forEach((file, index) => {
                                                            if (file instanceof File) {
                                                                formData.append(`${i}[${type}][${index}]`, file)
                                                            } else {
                                                                formData.append(`${i}[${type}][${index}][id]`, file.id)
                                                            }
                                                        }) : formData.append(`${i}[${type}]`, null)
                                                    })
                                                    break
                                                case 'items':
                                                    for (const j in values[i]) {
                                                        if (values[i].hasOwnProperty(j)) {
                                                            formData.append(`${i}[${j}][amount]`, values[i][j].amount)
                                                            formData.append(`${i}[${j}][price]`, values[i][j].price)
                                                            formData.append(`${i}[${j}][tnved]`, values[i][j].tnved)
                                                            formData.append(`${i}[${j}][gross_weight]`, values[i][j].gross_weight)
                                                            formData.append(`${i}[${j}][id]`, values[i][j].id)
                                                        }
                                                    }
                                                    break
                                                case 'actual_date':
                                                case 'estimated_date':
                                                case 'actual_order_date':
                                                    if (values[i] instanceof Date) {
                                                        formData.append(i, format(values[i], 'yyyy-MM-dd'))
                                                    }
                                                    break
                                                default:
                                                    formData.append(i, values[i])
                                            }
                                        }
                                    }

                                    return dispatch(DeliveryActions.save(order.id, formData)).then(
                                        () => {
                                            setLoading(false)
                                            setSubmitting(false);
                                        },
                                        errors => {
                                            if (errors) {
                                            }
                                            setSubmitting(false);
                                        }
                                    )
                                }}
                            >
                                {({
                                      values,
                                      isSubmitting,
                                      handleSubmit,
                                      setFieldValue,
                                      setFieldTouched
                                  }) => (
                                    <Form className={classes.fullWidth}>
                                        <Grid item className={classes.fullWidth}>
                                            <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                                <Grid item className={classes.header}>
                                                    <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                                        <Grid item>
                                                            <Grid container className={classes.fullWidth} direction="row" justify="space-between" alignItems="stretch">
                                                                <Grid item>
                                                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                        <Grid item>
                                                                            <Typography className={classes.title}>
                                                                                Заказ № {order.number}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <IconButton
                                                                                size="small"
                                                                                onClick={() => {
                                                                                    const textField = document.createElement('textarea')
                                                                                    textField.innerText = order.number
                                                                                    document.body.appendChild(textField)
                                                                                    textField.select()
                                                                                    document.execCommand('copy')
                                                                                    textField.remove()
                                                                                }}
                                                                            >
                                                                                <FileCopy fontSize="small" />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Button
                                                                        className={classes.download}
                                                                        startIcon={<Print/>}
                                                                        target="_blank"
                                                                        href={process.env.REACT_APP_HOST_API + `/contract/delivery/order/${order.id}/file?type=orderList&token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                    >
                                                                        Состав заказа
                                                                    </Button>
                                                                    <Button
                                                                        className={classes.download}
                                                                        startIcon={<Print/>}
                                                                        aria-owns={anchorEl ? "simple-menu" : undefined}
                                                                        aria-haspopup="true"
                                                                        onClick={handleClickAnchorEl}
                                                                        onMouseOver={handleClickAnchorEl}
                                                                    >
                                                                        Печать
                                                                    </Button>
                                                                    <Menu
                                                                        id="simple-menu"
                                                                        anchorEl={anchorEl}
                                                                        open={Boolean(anchorEl)}
                                                                        onClose={handleCloseAnchorEl}
                                                                        MenuListProps={{onMouseLeave: handleCloseAnchorEl}}
                                                                        getContentAnchorEl={null}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center'
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'center'
                                                                        }}
                                                                    >
                                                                        <MenuItem onClick={handleCloseAnchorEl}>
                                                                            <Button
                                                                                style={{
                                                                                    backgroundColor: "transparent",
                                                                                    padding: "0px"
                                                                                }}
                                                                                startIcon={<Excel/>}
                                                                                className={classes.download}
                                                                                href={process.env.REACT_APP_HOST_API + `/contract/delivery/order/${order.id}/file?type=xlsx&token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                target="_blank"
                                                                            >
                                                                                Excel
                                                                            </Button>
                                                                        </MenuItem>
                                                                        <MenuItem onClick={handleCloseAnchorEl}>
                                                                            <Button
                                                                                style={{
                                                                                    backgroundColor: "transparent",
                                                                                    padding: "0px"
                                                                                }}
                                                                                startIcon={<Pdf/>}
                                                                                className={classes.download}
                                                                                href={process.env.REACT_APP_HOST_API + `/contract/delivery/order/${order.id}/file?type=pdf&token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                target="_blank"
                                                                            >
                                                                                Pdf
                                                                            </Button>
                                                                        </MenuItem>
                                                                    </Menu>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                                                <Grid item className={classes.inputContent}>
                                                    <Grid container direction="column" justify="space-between" alignItems="center" spacing={2}>
                                                        <Grid item className={classes.fullWidth}>
                                                            <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                <Grid item xs={3}>
                                                                    <Typography variant="button">
                                                                        Договор:
                                                                    </Typography>
                                                                    <Typography noWrap onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        history.push(`/contracts?id=${order.contract.id}`)
                                                                    }} className={classes.orderLink}>
                                                                        {order.contract.number ?? order.contract.id}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Typography variant="button">
                                                                        Номер закупки:
                                                                    </Typography>
                                                                    <Typography onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        history.push(`/contracts?id=${order.contract.id}`)
                                                                    }} className={classes.orderLink}>
                                                                        {getNumber(order) ?? "Внеплановая"}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Typography variant="button">
                                                                        {(AuthorizationService.roles(account, "customer") ? 'Поставщик:' : 'Заказчик:')}
                                                                    </Typography>
                                                                    <Typography
                                                                        className={classes.orderLink}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            AuthorizationService.roles(account, "customer") ? setCardCompany(order.contract.offer.company) : setCardCompany(order.user.company)
                                                                            setCardContractID(order.contract.id)
                                                                            setOpenCard(true)
                                                                        }}
                                                                    >
                                                                        {(AuthorizationService.roles(account, "customer") ? order.contract.offer.company.legal_detail.name : order.contract.company.legal_detail.name)}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Typography variant="button">
                                                                        Дата создания:
                                                                    </Typography>
                                                                    <Typography>
                                                                        {format(new Date(order.created), 'H:mm PP', {locale: ru})}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item className={classes.fullWidth}>
                                                            <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                <Grid item xs={3}>
                                                                    <Typography variant="button">
                                                                        Контактный телефон:
                                                                    </Typography>
                                                                    {(AuthorizationService.roles(account, "customer")
                                                                        ? (order.contract.offer.company.contact_information
                                                                            ? ((order.contract.offer.company.contact_information.phones && (order.contract.offer.company.contact_information.phones.length > 1 || order.contract.offer.company.contact_information.phones[0].number))
                                                                                ? order.contract.offer.company.contact_information.phones.map((phone, index) => {
                                                                                    return phone.number ?
                                                                                        <Typography
                                                                                            key={index}>+7{phone.number} {phone.extra ? `доб. ${phone.extra}` : ''}</Typography> : null
                                                                                })
                                                                                : 'Не указан'
                                                                            )
                                                                            : 'Не указан'
                                                                        )
                                                                        : (
                                                                            order.contract.company.contact_information
                                                                                ? ((order.contract.company.contact_information.phones && (order.contract.company.contact_information.phones.length > 1 || order.contract.company.contact_information.phones[0].number))
                                                                                    ? order.contract.company.contact_information.phones.map((phone, index) => {
                                                                                        return phone.number ?
                                                                                            <Typography
                                                                                                key={index}>+7{phone.number} {phone.extra ? `доб. ${phone.extra}` : ''}</Typography> : null
                                                                                    })
                                                                                    : 'Не указан'
                                                                                )
                                                                                : 'Не указан'
                                                                        )
                                                                    )}
                                                                </Grid>
                                                                {((AuthorizationService.roles(account, "customer") && order.contract.offer.company.contact_information && order.contract.offer.company.contact_information.last_name) || (AuthorizationService.roles(account, "supplier") && order.contract.company.contact_information && order.contract.company.contact_information.last_name)) &&
                                                                    <Grid item xs={3}>
                                                                        <Typography variant="button">
                                                                            Контактное лицо:
                                                                        </Typography>
                                                                        <Typography>
                                                                            {(AuthorizationService.roles(account, "customer")
                                                                                ? ((order.contract.offer.company.contact_information && order.contract.offer.company.contact_information.last_name)
                                                                                    ? `${order.contract.offer.company.contact_information.last_name} ${order.contract.offer.company.contact_information.first_name ? `${order.contract.offer.company.contact_information.first_name[0].toUpperCase()}.${order.contract.offer.company.contact_information.middle_name ? `${order.contract.offer.company.contact_information.middle_name[0].toUpperCase()}.` : null}` : null}`
                                                                                    : null
                                                                                )
                                                                                : ((order.contract.company.contact_information && order.contract.company.contact_information.last_name)
                                                                                    ? `${order.contract.company.contact_information.last_name} ${order.contract.company.contact_information.first_name ? `${order.contract.company.contact_information.first_name[0].toUpperCase()}.${order.contract.company.contact_information.middle_name ? `${order.contract.company.contact_information.middle_name[0].toUpperCase()}.` : null}` : null}`
                                                                                    : null
                                                                                )
                                                                            )}
                                                                        </Typography>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        {(order.address || order.expected_date || order.comment) &&
                                                            <Grid item className={classes.fullWidth}>
                                                                <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                    {order.address &&
                                                                        <Grid item xs={3}>
                                                                            <Typography variant="button">
                                                                                Адрес доставки:
                                                                            </Typography>
                                                                            <Typography>
                                                                                {order.address}
                                                                            </Typography>
                                                                        </Grid>
                                                                    }
                                                                    {order.expected_date &&
                                                                        <Grid item xs={3}>
                                                                            <Typography variant="button">
                                                                                Желаемая дата доставки:
                                                                            </Typography>
                                                                            <Typography>
                                                                                {format(new Date(order.expected_date), 'PP', {locale: ru})}
                                                                            </Typography>
                                                                        </Grid>
                                                                    }
                                                                    {order.comment &&
                                                                        <Grid item xs={3}>
                                                                            <Typography variant="button">
                                                                                Комментарий:
                                                                            </Typography>
                                                                            <Typography>
                                                                                {order.comment}
                                                                            </Typography>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                        <Grid item className={classes.fullWidth}>
                                                            <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                {order.payment_sum &&
                                                                    <Grid item xs={3}>
                                                                        <Typography variant="button">
                                                                            Оплачено:
                                                                        </Typography>
                                                                        <Typography>
                                                                            {parseFloat(order.payment_sum).toLocaleString('ru-RU', {
                                                                                ...(order.contract.currency ? {
                                                                                    style: 'currency',
                                                                                    currency: order.contract.currency.code
                                                                                } : {})
                                                                            }).replace('KZT', '₸')}
                                                                        </Typography>
                                                                    </Grid>
                                                                }
                                                                {order.payment_sum_integration &&
                                                                    <Grid item xs={3}>
                                                                        <Typography variant="button">
                                                                            Оплаченная сумма из 1С:
                                                                        </Typography>
                                                                        <Typography>
                                                                            {parseFloat(order.payment_sum_integration).toLocaleString('ru-RU', {
                                                                                ...(order.contract.currency ? {
                                                                                    style: 'currency',
                                                                                    currency: order.contract.currency.code
                                                                                } : {})
                                                                            }).replace('KZT', '₸')}
                                                                        </Typography>
                                                                    </Grid>
                                                                }
                                                                {order.estimated_date &&
                                                                    <Grid item xs={3}>
                                                                        <Typography variant="button">
                                                                            Предположительная дата доставки:
                                                                        </Typography>
                                                                        <Typography>
                                                                            {format(new Date(order.estimated_date), 'PP', {locale: ru})}
                                                                        </Typography>
                                                                    </Grid>
                                                                }
                                                                {order.actual_date &&
                                                                    <Grid item xs={3}>
                                                                        <Typography variant="button">
                                                                            Фактическая дата доставки:
                                                                        </Typography>
                                                                        <Typography>
                                                                            {format(new Date(order.actual_date), 'PP', {locale: ru})}
                                                                        </Typography>
                                                                    </Grid>
                                                                }
                                                                {order.actual_order_date &&
                                                                    <Grid item xs={3}>
                                                                        <Typography variant="button">
                                                                            Фактическая дата заказа:
                                                                        </Typography>
                                                                        <Typography>
                                                                            {format(new Date(order.actual_order_date), 'PP', {locale: ru})}
                                                                        </Typography>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item className={classes.fullWidth}>
                                                            <TableContainer className={classes.tableContainer}>
                                                                <Table stickyHeader className={classes.table}>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            {columns.filter(column =>
                                                                                ((AuthorizationService.roles(account, "customer") && (AuthorizationService.permissions(account, "formation_editing_delivery_order") || AuthorizationService.permissions(account, "confirmation_editing_delivery_order")) && ((order.status.index === 1) || (order.status.index === 9)) && column.id === '11') || column.id !== '11')
                                                                            ).map((column) => (
                                                                                <TableCell key={column.id}>
                                                                                    {column.label}
                                                                                </TableCell>
                                                                            ))}
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {products.data.length > 0 && products.data.map((item, index) => (
                                                                            <TableRow hover key={index}>
                                                                                <TableCell>
                                                                                    {index + 1}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {item.article_number}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Tooltip
                                                                                        classes={{tooltip: classes.lightTooltip}}
                                                                                        title={item.name}
                                                                                        placement="bottom">
                                                                                        <span>{item.name}</span>
                                                                                    </Tooltip>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {item.hasOwnProperty('category') && item.category ? item.category.name : null}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <AmountField
                                                                                        order={order}
                                                                                        item={item}
                                                                                        amount={item.amount}
                                                                                        name={`items.${index}.amount`}
                                                                                        setFieldValue={(name, value) => {
                                                                                            setFieldValue(name, value)
                                                                                            setProducts({
                                                                                                ...products,
                                                                                                data: products.data.map(el => {
                                                                                                    return (el.id === item.id) ?  {
                                                                                                        ...item,
                                                                                                        amount: value
                                                                                                    } : el
                                                                                                })
                                                                                            })
                                                                                        }}
                                                                                        disabled={AuthorizationService.roles(account, "supplier") || (AuthorizationService.roles(account, "customer") && (!AuthorizationService.permissions(account, "formation_editing_delivery_order") && !AuthorizationService.permissions(account, "confirmation_editing_delivery_order"))) || ((order.status.index !== 1) && (order.status.index !== 3) && (order.status.index !== 7) && (order.status.index !== 9) && (order.status.index !== 10))}
                                                                                        onBlur={() => {
                                                                                            setUpdate(true)
                                                                                        }}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {[3, 7, 10].indexOf(order.status.index) !== -1 ?
                                                                                        (<Field
                                                                                            style={{minWidth: "120px"}}
                                                                                            fullWidth
                                                                                            name={`items.${index}.price`}
                                                                                            type="number"
                                                                                            inputProps={{
                                                                                                step: 0.01,
                                                                                                min: 0,
                                                                                                onChange: (e) => {
                                                                                                    const {
                                                                                                        value,
                                                                                                        name
                                                                                                    } = e.target
                                                                                                    setFieldValue(name, value)
                                                                                                },
                                                                                                onBlur: (e) => {
                                                                                                    const value = parseFloat(e.target.value)

                                                                                                    return dispatch(DeliveryActions.editItem(order.id, item.id, {price: value})).then(() => {
                                                                                                        setUpdate(true)
                                                                                                    })
                                                                                                }
                                                                                            }}
                                                                                            disabled={AuthorizationService.roles(account, "supplier") || (AuthorizationService.roles(account, "customer") && (!AuthorizationService.permissions(account, "formation_editing_delivery_order") && !AuthorizationService.permissions(account, "confirmation_editing_delivery_order"))) || ((order.status.index !== 1) && (order.status.index !== 3) && (order.status.index !== 7) && (order.status.index !== 9) && (order.status.index !== 10))}
                                                                                            endAdornment={<InputAdornment position="end">{order.contract.currency.code === 'RUB' ? '₽' : '₸'}</InputAdornment>}
                                                                                            component={CustomTextField}
                                                                                        />)
                                                                                        :
                                                                                        parseFloat(item.price).toLocaleString('ru-RU', {...(order.contract.currency ? {style: 'currency', currency: order.contract.currency.code} : {})}).replace('KZT', '₸')}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {(item.tax_percent > 0) ? `${item.tax_percent}%` : 'Без НДС'}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {(item.priceWithoutTaxPercent).toLocaleString('ru-RU', {...(order.contract.currency ? {style: 'currency', currency: order.contract.currency.code} : {})}).replace('KZT', '₸')}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Field
                                                                                        style={{minWidth: "120px"}}
                                                                                        fullWidth
                                                                                        name={`items.${index}.totalPrice`}
                                                                                        type="number"
                                                                                        inputProps={{
                                                                                            step: 0.01,
                                                                                            min: 0,
                                                                                            onChange: (e) => {
                                                                                                const {value, name} = e.target
                                                                                                setFieldValue(name, value)
                                                                                            },
                                                                                            onBlur: (e) => {
                                                                                                const value = parseFloat(e.target.value)

                                                                                                return dispatch(DeliveryActions.editItem(order.id, item.id, {total: value, amount: item.amount})).then(() => {
                                                                                                    setUpdate(true)
                                                                                                })
                                                                                            }
                                                                                        }}
                                                                                        disabled={AuthorizationService.roles(account, "supplier") || (AuthorizationService.roles(account, "customer") && (!AuthorizationService.permissions(account, "formation_editing_delivery_order") && !AuthorizationService.permissions(account, "confirmation_editing_delivery_order"))) || ((order.status.index !== 1) && (order.status.index !== 3) && (order.status.index !== 7) && (order.status.index !== 9) && (order.status.index !== 10))}
                                                                                        endAdornment={<InputAdornment position="end">{order.contract.currency.code === 'RUB' ? '₽' : '₸'}</InputAdornment>}
                                                                                        component={CustomTextField}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        name={`items.${index}.tnved`}
                                                                                        type="number"
                                                                                        disabled={!AuthorizationService.roles(account, "supplier") || !(order.status.index === 8 || order.status.index === 9)}
                                                                                        component={CustomTextField}
                                                                                        inputProps={{
                                                                                            min: 0,
                                                                                            onChange: (event) => {
                                                                                                const {value, name} = event.target
                                                                                                setFieldValue(name, value)
                                                                                                setChange(true)
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        name={`items.${index}.gross_weight`}
                                                                                        type="number"
                                                                                        component={CustomTextField}
                                                                                        disabled={!AuthorizationService.roles(account, "supplier") || !(order.status.index === 8 || order.status.index === 9)}
                                                                                        endAdornment={<InputAdornment position="end">кг</InputAdornment>}
                                                                                        inputProps={{
                                                                                            min: 0,
                                                                                            onChange: (event) => {
                                                                                                const {value, name} = event.target
                                                                                                let val = value.replace(/[^\d.,|]/g, '')
                                                                                                setFieldValue(name, val)
                                                                                                setChange(true)
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </TableCell>
                                                                                {(AuthorizationService.roles(account, "customer") && (AuthorizationService.permissions(account, "formation_editing_delivery_order") || AuthorizationService.permissions(account, "confirmation_editing_delivery_order")) && (([1, 3, 9, 10].indexOf(order.status.index) !== -1))) &&
                                                                                    <TableCell>
                                                                                        <IconButton
                                                                                            size={"small"}
                                                                                            disabled={AuthorizationService.roles(account, "supplier")}
                                                                                            onClick={() => {
                                                                                                return dispatch(DeliveryActions.removeItem(order.id, item.id)).then(() => {
                                                                                                    setLoading(false)
                                                                                                })
                                                                                            }}
                                                                                        >
                                                                                            <Clear/>
                                                                                        </IconButton>
                                                                                    </TableCell>
                                                                                }
                                                                                <TableCell>
                                                                                    {Number(item.amount_integration)}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {Number(item.price_integration)}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                        <Grid item className={classes.fullWidth}>
                                                            <Grid container direction="row" justify="space-between" alignItems="flex-start">
                                                                <Grid item xs={6}>
                                                                    <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
                                                                        {((order.status.index === 1) || (order.status.index === 9)) && AuthorizationService.roles(account, "customer") &&
                                                                            <Grid item xs={12}>
                                                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                                                    <Grid item xs={12}>
                                                                                        <Autocomplete
                                                                                            filterOptions={options => options}
                                                                                            name={'item.name'}
                                                                                            options={(items && items.hasOwnProperty('data')) ? items.data.filter(
                                                                                                (product) => {
                                                                                                    return ((!products.data.find(item => (parseInt(item.id) === parseInt(product.id))) && ((product.item.amount - (product.item.ordered ?? 0)) >= product.min_ordered_quantity)))
                                                                                                }
                                                                                            ) : []}
                                                                                            value={null}
                                                                                            getOptionLabel={item => {
                                                                                                let name = ''

                                                                                                if (item) {
                                                                                                    if (item.article_number) {
                                                                                                        name += `${item.article_number} | `
                                                                                                    }

                                                                                                    name += `${(item.name ?? item.item.name)} | ${(parseFloat(item.item.amount) - (item.item.ordered ? parseFloat(item.item.ordered) : 0)).toFixed(2)} | ${parseFloat(item.price).toLocaleString('ru-RU', {
                                                                                                        ...(order.contract.currency ? {
                                                                                                            style: 'currency',
                                                                                                            currency: order.contract.currency.code
                                                                                                        } : {})
                                                                                                    }).replace('KZT', '₸')}`
                                                                                                }

                                                                                                return item ? name : ''
                                                                                            }}
                                                                                            noOptionsText='Добавить Товар/Услугу'
                                                                                            onChange={(e, item) => {
                                                                                                return dispatch(DeliveryActions.addItem(order.id, {
                                                                                                    id: item.id,
                                                                                                    amount: item.min_ordered_quantity && item.min_ordered_quantity > 0 ? item.min_ordered_quantity : 1
                                                                                                })).then(
                                                                                                    () => {
                                                                                                        setLoading(false)
                                                                                                    },
                                                                                                    errors => {
                                                                                                        if (errors) {}
                                                                                                    }
                                                                                                )
                                                                                            }}
                                                                                            renderInput={params => {
                                                                                                return <Field
                                                                                                    fullWidth
                                                                                                    component={MuiTextField}
                                                                                                    {...params}
                                                                                                    onChange={(e) => {
                                                                                                        setSearch(e.target.value)
                                                                                                    }}
                                                                                                    name={'item.name'}
                                                                                                    label="Товар/Услуга"
                                                                                                />
                                                                                            }}
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        }
                                                                        <Grid item>
                                                                            {getMessage(order.status.index, values.files)}
                                                                        </Grid>
                                                                        <Grid item>
                                                                            {getData(order.status.index, values.files, setFieldValue, setFieldTouched, values)}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Grid container direction="row" justify="flex-end" alignItems="stretch">
                                                                        <Grid item>
                                                                            <Typography
                                                                                component="span"
                                                                                variant="h6"
                                                                            >
                                                                                Итого:
                                                                            </Typography>
                                                                            <Typography
                                                                                component="span"
                                                                                variant="h6"
                                                                                color={((order.status.index === 1) && (order.contract.total_price && (parseFloat((parseFloat(order.price ?? 0) + parseFloat(order.orders.price ?? 0)).toFixed(2)) > parseFloat(parseFloat(order.contract.total_price).toFixed(2))))) ? "error" : "initial"}
                                                                            >
                                                                                {parseFloat(parseFloat(order.price ?? 0).toFixed(2)).toLocaleString('ru-RU', {...(order.contract.currency ? {style: 'currency', currency: order.contract.currency.code} : {})}).replace('KZT', '₸')}
                                                                            </Typography>
                                                                            {((order.status.index === 1) && (order.contract.total_price && (parseFloat((parseFloat(order.price ?? 0) + parseFloat(order.orders.price ?? 0)).toFixed(2)) > parseFloat(parseFloat(order.contract.total_price).toFixed(2))))) &&
                                                                                <Typography
                                                                                    color="error"
                                                                                    variant="body2"
                                                                                >
                                                                                    Сумма всех заказов больше суммы договора
                                                                                </Typography>
                                                                            }
                                                                            {
                                                                                order.total_tax_percent_price && order.total_tax_percent_price.map(item => (
                                                                                    <Typography
                                                                                        key={item.percent}
                                                                                        variant="body2"
                                                                                    >
                                                                                        Включая НДС {item.percent}%: {parseFloat(item.sum).toLocaleString('ru-RU', {...(order.contract.currency ? {style: 'currency', currency: order.contract.currency.code} : {})}).replace('KZT', '₸')}
                                                                                    </Typography>
                                                                                ))
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.footer}>
                                            <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                                <Grid item className={classes.footerContent}>
                                                    <Grid container className={classes.footerContainer} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                        {((AuthorizationService.roles(account, "supplier") && (order.status.index === 2)) || (activeCustomer() && ((order.status.index !== 15) && (order.status.index !== 16)))) &&
                                                            <Grid item>
                                                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                                    <Grid item>
                                                                        <ConfirmButton
                                                                            title={'Вы уверены, что хотите отменить заказ?'}
                                                                            onClick={() => {
                                                                                setIsSubmitting(true)

                                                                                return dispatch(DeliveryActions.cancel(order.id)).then(
                                                                                    () => {
                                                                                        setLoading(false)
                                                                                        setIsSubmitting(false)
                                                                                    },
                                                                                    () => {
                                                                                        setIsSubmitting(false)
                                                                                    }
                                                                                )
                                                                            }}
                                                                        >
                                                                            <Submit
                                                                                disableElevation
                                                                                variant="contained"
                                                                                color="primary"
                                                                                type="button"
                                                                                disabled={isSubmitting}
                                                                            >
                                                                                Отменить
                                                                            </Submit>
                                                                        </ConfirmButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                        <Grid item>
                                                            <TablePagination
                                                                rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                                                component='div'
                                                                labelRowsPerPage={'Записей на странице:'}
                                                                count={products.meta.total}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                onChangePage={handleChangePage}
                                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                                {getStep(values.files, handleSubmit)}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    ) : null
}
