import React from "react"

import {Menu as AppMenu} from "../../../../../../App/Menu";
import {Menu} from "../../../../../Menu";
import {Content as AppContent} from "../../../../../../App/Content";
import {Content} from "./Create/Content";
export const Create = (props) => {
    return <React.Fragment>
        <AppMenu
            Component={Menu}
            {...props}
        />
        <AppContent
            Component={Content}
            {...props}
        />
    </React.Fragment>
}
