import React from "react"
import {Link} from "react-router-dom"

import {List, ListItem, ListItemText, makeStyles, withStyles} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    active: {
        "background-image": "none !important",
        "background-color": "#898989"
    }
}))

const CustomList = withStyles({
    root: {
        "padding": "0",
        "& .MuiListItem-button": {
            "background-image": "linear-gradient(to bottom,#e4e4e4,#f4f4f4)",
            "cursor": "pointer",
            "align-items": "center",
            "min-height": "50px",
            "padding": "5px 25px 0",
            "border-bottom": "1px solid #e4e4e4",
            "outline": "0"
        },
        "& .MuiListItemText-primary": {
            "font-size": "16px",
            "font-weight": "700",
            "text-transform": "uppercase"
        },
        "& .MuiListItem-button:hover": {
            "background-color": "#898989",
            "&:after": {
                "position": "absolute",
                "content": "''",
                "top": "0",
                "left": "0",
                "width": "100%",
                "height": "100%",
                "background-color": "rgba(0,0,0,.04)"
            }
        }
    }
})(List);

export const Menu = (props) => {
    const classes = useStyles()
    const {location} = props

    return (
        <CustomList>
            {[
                {title: "Данные об организации",to: "/company"},
                {title: "Настройки уведомлений", to: "/notification/settings"}
            ].map((option, index) => (
                <ListItem button key={option.title} component={Link} to={option.to} classes={{ root: (location.pathname === option.to) ? classes.active : undefined }}>
                    <ListItemText primary={option.title} />
                </ListItem>
            ))}
        </CustomList>
    )
}
