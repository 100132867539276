import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import format from "date-fns/format"

import {
    Button,
    Collapse,
    Grid,
    IconButton,
    Link,
    makeStyles,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Tooltip,
    Typography
} from "@material-ui/core"
import {
    GetApp as GetAppIcon,
    Help as HelpIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon
} from "@material-ui/icons"

import {OrderActions} from "../../actions/order"
import {endings} from "../../../App/helpers/endings"
import {TabPanel} from "../../../Account/components/Tabs/TabPanel"
import {Card} from "../../../Company/components/Card"
import * as AppStorage from "../../../App/helpers/storage"
import {Button as SignatureButton} from "../../../Crypto/Action/Button";
import {Link as SignatureLink} from "../../../Crypto/Action/Link";
import {ShopActions} from "../../../Marketplace/components/Shop/actions/shop";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px 0 29px !important",
    },
    input: {
        "width": "100%",
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "margin-bottom": "4px"
    },
    tableContainer: {
        "overflow": "auto"
    },
    tabContent: {
        "padding": "20px 29px !important"
    },
    itemInput: {
        "margin-top": "0 !important"
    },
    inputContent: {
        "padding": "20px 30px 0",
        "height": "calc(100vh - 303px)",
    },
    column: {
        "height": "86px"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    download: {
        "color": "#485868"
    },
    winner: {
        '& .MuiTableCell-root': {
            'font-weight': '500'
        }
    },
    line: {
        "text-decoration": "line-through"
    },
    companyClick: {
        "&:hover": {
            fontWeight: 500,
        }
    },
    tooltip: {
        "max-width": "200px",
        "border-radius": "0px !important",
        "font-size": "0.8rem"
    },
    badge: {
        "right": "-12px"
    },
    tabBadge: {
        "padding-right": "34px"
    },
    fileItem: {
        maxHeight: "25px"
    },
    link: {
        "display": "flex",
        "line-height": "31px",
        "color": "#485868",
    }
}))

export const Content = (props) => {
    let {id} = useParams()

    const dispatch = useDispatch()
    const classes = useStyles()
    const [value, setValue] = useState(0)
    const {lot} = useSelector(state => state.reportOrder)
    const [cardCompany, setCardCompany] = useState(null)
    const [openCard, setOpenCard] = useState(false)
    const [openRow, setOpenRow] = React.useState(false)

    useEffect(() => {
        const getLot = async () => {
            return await dispatch(OrderActions.lot(id))
        }

        getLot().then(() => {
        })

        return () => {
            dispatch({type: 'REPORT_ORDER_LOT_CLEAR'})
        }
    }, [dispatch, id])

    const handleClose = () => {
        setOpenCard(false)
        setCardCompany(null)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const getExpirationDate = (item) => {
        if (item.expiration_date_type === 'warranty_period') {
            return item.expiration_date_number ? endings(parseInt(item.expiration_date_number), ['месяц', 'месяца', 'месяцев']) : null;
        } else if (item.expiration_date_type === 'expiration_date_percent') {
            return item.expiration_date_number ? `${item.expiration_date_number} %` : null;
        } else {
            return item.expiration_date ? item.expiration_date + ' г.' : null;
        }
    }

    const getSupplierStatus = (offer, lot) => {
        let countOffers = lot.offers.filter(offer => offer.approve).length
        let minPrice = Math.min.apply(null, lot.offers.filter(offer => (offer.approve && !offer.winner)).map(el => el.price))
        let secondWinner = lot.offers.filter(offer => (Number(offer.price) === minPrice && !offer.winner && offer.approve))

        if (!offer.active) {
            return 'Отменено';
        }
        if (lot.order.status.index === 2 && offer.active && !offer.winner) {
            return 'Сбор заявок';
        }
        if (offer.active && offer.winner) {
            return 'Победитель';
        }
        if (lot.status.index === 8 && offer.active && !offer.winner) {
            if (offer.approve !== null) {
                return offer.approve ? 'Допущен' : 'Не допущен';
            }
            return 'В работе';
        }
        if (offer.active && offer.approve && secondWinner.length && secondWinner.length > 0 && secondWinner[0].id === offer.id && countOffers > 1) {
            return (
                <Typography> 2 место <Tooltip title="Будет выбран поставщиком, если не состоится сделка с победителем"
                                              placement="left" classes={{tooltip: classes.tooltip}}><IconButton
                    style={{"width": "24px", "height": "24px", "padding": "0"}}><HelpIcon style={{"color": "#485868"}}/></IconButton></Tooltip></Typography>)
        }
        if (offer.approve !== null) {
            return offer.approve ? '-' : 'Не допущен';
        }
        return '—';
    };

    const filterLotFiles = (files, status) => {
        let maxProtocolId = 0;
        files.forEach(file => {
            if ((file.type?.key === 'protocol') && (file.id > maxProtocolId)) maxProtocolId = file.id;
        })
        return files.filter(file => !file.type || file.type.key !== 'protocol' || (status.id !== 6 && file.type.key === 'protocol' && file.id === maxProtocolId))
    }

    return lot ? (
            <Grid item className={classes.content}>
                <Paper>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item className={classes.full}>
                            <Grid container className={classes.fullWidth} direction="column" justify="space-between"
                                  alignItems="stretch">
                                <Grid item className={classes.fullWidth}>
                                    <Grid container className={classes.fullWidth} direction="column"
                                          justify="space-between" alignItems="stretch">
                                        <Grid item className={classes.header}>
                                            <Grid container className={classes.fullWidth} direction="column"
                                                  justify="space-between" alignItems="stretch">
                                                <Grid item>
                                                    <Grid container className={classes.fullWidth} direction="row"
                                                          justify="space-between" alignItems="stretch">
                                                        <Grid item>
                                                            <Typography className={classes.title}>
                                                                Заявка № {lot.order.id}/{lot.index}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="button">
                                                                {lot.status.name}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="button">
                                                        Дата
                                                        создания {format(new Date(lot.order.created), 'dd.MM.yyyy HH:mm')}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.input}>
                                            <Grid container direction="column" justify="center" alignItems="stretch">
                                                <Grid item className={classes.fullWidth}>
                                                    <Tabs
                                                        value={value}
                                                        indicatorColor="primary"
                                                        textColor="primary"
                                                        onChange={handleChange}
                                                    >
                                                        <Tab value={0} label="Информация о ценовом запросе" />
                                                        <Tab value={1} className={classes.tabBadge} label="Предложения поставщиков" />
                                                    </Tabs>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid className={classes.tab} container direction="column" justify="space-between"
                                          alignItems="stretch">
                                        <Grid item className={classes.inputContent}>
                                            <TabPanel value={value} index={0}>
                                                <Grid container direction="column" justify="flex-start"
                                                      alignItems="stretch" spacing={2}>
                                                    <Grid item>
                                                        <Grid container direction="column" justify="flex-start"
                                                              alignItems="stretch" spacing={2}>
                                                            {lot.order.name && <Grid item>
                                                                <Grid container direction="row" justify="space-between"
                                                                      alignItems="stretch">
                                                                    <Grid item xs={3}>
                                                                        <Typography variant="button">
                                                                            Название:
                                                                        </Typography>
                                                                        <Typography>
                                                                            {lot.order.name}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Typography variant="button">
                                                                            Номер закупки:
                                                                        </Typography>
                                                                        <Typography>
                                                                            {lot.order.unscheduled_purchase ? 'Внеплановая' : lot.order.purchase_number}
                                                                        </Typography>
                                                                    </Grid>
                                                                    {lot.winner && <Grid item xs={3}/>}
                                                                    <Grid item xs={3}/>
                                                                </Grid>
                                                            </Grid>}
                                                            <Grid item>
                                                                <Grid container direction="row" justify="space-between"
                                                                      alignItems="stretch">
                                                                    <Grid item xs={3}>
                                                                        <Grid container direction="column"
                                                                              justify="flex-start" alignItems="stretch"
                                                                              spacing={2}>
                                                                            <Grid item>
                                                                                <Typography variant="button">
                                                                                    Тип:
                                                                                </Typography>
                                                                                <Typography>
                                                                                    {lot.order.type === 'static' ? 'Статический запрос' : 'Динамический запрос'}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Grid container direction="column"
                                                                              justify="flex-start" alignItems="stretch"
                                                                              spacing={2}>
                                                                            <Grid item>
                                                                                <Typography variant="button">
                                                                                    Категория:
                                                                                </Typography>
                                                                                <Typography>
                                                                                    {lot.order.category.name}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    {!lot.winner && <Grid item xs={3}/>}
                                                                    {lot.winner && <Grid item xs={3}>
                                                                        <Grid container direction="column"
                                                                              justify="flex-start" alignItems="stretch"
                                                                              spacing={2}>
                                                                            <Grid item>
                                                                                <Typography variant="button">
                                                                                    Поставщик:
                                                                                </Typography>
                                                                                <Typography>
                                                                                    {lot.winner.company.legal_detail.name}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>}
                                                                    <Grid item xs={3}>
                                                                        <Grid container direction="column"
                                                                              justify="flex-start"
                                                                              alignItems="flex-start" spacing={2}>
                                                                            <Grid item>
                                                                                <Typography variant="button">
                                                                                    НМЦД:
                                                                                </Typography>
                                                                                <Typography>
                                                                                    {lot.price ? parseFloat(lot.price).toLocaleString('ru-RU', {
                                                                                        style: 'currency',
                                                                                        currency: 'RUB',
                                                                                    }) : (lot.average_price ? parseFloat(lot.average_price).toLocaleString('ru-RU', {
                                                                                        style: 'currency',
                                                                                        currency: 'RUB',
                                                                                    }) : null)}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                                    <Grid item xs={12} style={{marginBottom: "10px"}}>
                                                                        <Typography variant="button">
                                                                            Документы:
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                            {filterLotFiles(lot.files, lot.status).map((file, index) => {
                                                                                switch (file.type?.key) {
                                                                                    case 'protocol':
                                                                                    case 'rebidding_protocol':
                                                                                        return (
                                                                                          <Grid item key={index}>
                                                                                              <SignatureLink
                                                                                                file={file}
                                                                                                name={`${file.type.name} (Лот № ${lot.number ?? lot.index})`}
                                                                                                href={`/shop/order/${lot.order.id}/file/${file.id}`}
                                                                                              />
                                                                                          </Grid>
                                                                                        )
                                                                                    default:
                                                                                        return (
                                                                                          <Grid item xs={3} key={index}>
                                                                                              <Typography gutterBottom>
                                                                                                  <Link
                                                                                                    href={process.env.REACT_APP_HOST_API + `/shop/order/${lot.order.id}/file/${file.id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                                    className={classes.link}
                                                                                                  >
                                                                                                      {`${file.type?.name ?? file.name} (Лот № ${lot.number ?? lot.index})`}
                                                                                                  </Link>
                                                                                              </Typography>
                                                                                          </Grid>
                                                                                        )
                                                                                }
                                                                            })}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <TableContainer className={classes.tableContainer}>
                                                            <Table stickyHeader aria-label="sticky table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        {[
                                                                            {
                                                                                id: '0',
                                                                                label: 'Объекты закупки',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '1',
                                                                                label: 'Кол-во',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '2',
                                                                                label: 'НМЦ объектов закупки',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '3',
                                                                                label: 'Срок годности',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '4',
                                                                                label: 'Срок исполнения договора, дней',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '5',
                                                                                label: 'Отсрочка платежа, дней',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '6',
                                                                                label: 'Окончание приёма заявок',
                                                                                format: (value) => value.toLocaleString(),
                                                                            }
                                                                        ].filter(column => (((column === '6') && (lot.order.form === 'request')) || (column !== '6'))).map((column) => (
                                                                            <TableCell white-space="nowrap"
                                                                                       key={column.id}>
                                                                                {column.label}
                                                                            </TableCell>
                                                                        ))}
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {lot.items.map((item, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell>
                                                                                {item.name}
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                {item.amount}
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                {(item.average_price || lot.winner) && parseFloat(item.average_price ?? lot.winner.items.find(el => el.item.id === item.id).price).toLocaleString('ru-RU', {
                                                                                    style: 'currency',
                                                                                    currency: 'RUB',
                                                                                })}
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                {getExpirationDate(item)}
                                                                            </TableCell>
                                                                            {!index &&
                                                                                <React.Fragment>
                                                                                    <TableCell
                                                                                        rowSpan={lot.items.length}
                                                                                        align="center">
                                                                                        {lot.contract_time}
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        rowSpan={lot.items.length}
                                                                                        align="center">
                                                                                        {lot.postponement}
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        rowSpan={lot.items.length}
                                                                                        align="center">
                                                                                        {(lot.order.form === 'request') ? (format(new Date(lot.order.application_deadline), 'HH:mm dd.MM.yyyy') + ' г.') : null}
                                                                                    </TableCell>
                                                                                </React.Fragment>
                                                                            }
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={value} index={1}>
                                                {(openCard && cardCompany) && <Card contractID={null} companyType={'поставщика'} company={cardCompany} open={openCard} onClose={handleClose}/>}
                                                <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                                    <Grid item>
                                                        <TableContainer className={classes.tableContainer}>
                                                            <Table stickyHeader aria-label="sticky table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        {[
                                                                            {
                                                                                id: '0',
                                                                                label: '',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '1',
                                                                                label: 'Поставщик',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '2',
                                                                                label: 'Срок поставки',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '3',
                                                                                label: 'Цена предложения, с учетом НДС',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '4',
                                                                                label: 'Статус',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '5',
                                                                                label: 'Дата подачи',
                                                                                format: (value) => value.toLocaleString(),
                                                                            }
                                                                        ].map((column) => (
                                                                            <TableCell key={column.id}>
                                                                                {column.label}
                                                                            </TableCell>
                                                                        ))}
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {lot.offers.map((offer, index) => (
                                                                        <React.Fragment key={offer.created}>
                                                                            <TableRow
                                                                                key={index}
                                                                                className={offer.winner ? classes.winner : null}
                                                                                onClick={() => {
                                                                                    if (offer.files.filter(el => (el.type === null || el.type.key === 'price_offer')).length === 0) {
                                                                                        return;
                                                                                    }
                                                                                    setOpenRow(!openRow || (openRow !== offer.id) ? offer.id : false)
                                                                                }}
                                                                                style={{cursor: "pointer"}}
                                                                            >
                                                                                <TableCell>
                                                                                    {offer.files.filter(el => (el.type === null || el.type.key === 'price_offer')).length !== 0 &&
                                                                                        <IconButton
                                                                                            aria-label="expand row"
                                                                                            size="small">
                                                                                            {openRow === offer.id ?
                                                                                                <KeyboardArrowUpIcon/> :
                                                                                                <KeyboardArrowDownIcon/>}
                                                                                        </IconButton>
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    className={!offer.active ? classes.line : classes.companyClick}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        if (offer.company) {
                                                                                            setCardCompany(offer.company)
                                                                                            setOpenCard(true)
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {offer.company ? offer.company.legal_detail.name : `Поставщик № ${index + 1}`}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {offer.delivery}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {parseFloat(offer.price).toLocaleString('ru-RU', {
                                                                                        style: 'currency',
                                                                                        currency: lot.order.currency.code
                                                                                    }).replace('KZT', '₸')}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {getSupplierStatus(offer, lot)}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {format(new Date(offer.created), 'dd.MM.yyyy HH:mm')}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            {offer.files.filter(el => (el.type === null || el.type.key === 'price_offer')).length !== 0 &&
                                                                                <TableRow key={offer.id}>
                                                                                    <TableCell
                                                                                        style={{
                                                                                            paddingBottom: 0,
                                                                                            paddingTop: 0
                                                                                        }}
                                                                                        colSpan={7}
                                                                                    >
                                                                                        <Collapse
                                                                                            in={offer.id === openRow}
                                                                                            timeout="auto"
                                                                                            unmountOnExit>
                                                                                            <Grid container
                                                                                                  direction="column"
                                                                                                  justify="flex-start"
                                                                                                  alignItems="flex-start"
                                                                                                  style={{margin: "20px 0"}}>
                                                                                                {offer.files.find((file) => (file.type !== null && file.type.key === 'price_offer')) &&
                                                                                                    <Grid item
                                                                                                          className={classes.fileItem}>
                                                                                                        <Button
                                                                                                            className={classes.download}
                                                                                                            endIcon={
                                                                                                                < GetAppIcon/>}
                                                                                                            href={process.env.REACT_APP_HOST_API + `/shop/order/${lot.order.id}/offer/file/${offer.files.find((file) => (file.type !== null && file.type.key === 'price_offer')).id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                                        >
                                                                                                            Скачать
                                                                                                            Ценовое
                                                                                                            предложение
                                                                                                        </Button>
                                                                                                    </Grid>
                                                                                                }
                                                                                                {offer.files.find((file) => (file.type !== null && file.type.key === 'price_rationale')) &&
                                                                                                    <Grid item
                                                                                                          className={classes.fileItem}>
                                                                                                        <Button
                                                                                                            className={classes.download}
                                                                                                            endIcon={
                                                                                                                < GetAppIcon/>}
                                                                                                            href={process.env.REACT_APP_HOST_API + `/shop/order/${lot.order.id}/offer/file/${offer.files.find((file) => (file.type !== null && file.type.key === 'price_rationale')).id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                                        >
                                                                                                            Скачать
                                                                                                            Обоснование
                                                                                                            цены
                                                                                                        </Button>
                                                                                                    </Grid>
                                                                                                }
                                                                                                {offer.files.find((file) => (file.type !== null && file.type.key === 'specification')) &&
                                                                                                    <Grid item
                                                                                                          className={classes.fileItem}>
                                                                                                        <Button
                                                                                                            className={classes.download}
                                                                                                            endIcon={
                                                                                                                < GetAppIcon/>}
                                                                                                            href={process.env.REACT_APP_HOST_API + `/shop/order/${lot.order.id}/offer/file/${offer.files.find((file) => (file.type !== null && file.type.key === 'specification')).id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                                        >
                                                                                                            Скачать
                                                                                                            Спецификацию
                                                                                                        </Button>
                                                                                                    </Grid>
                                                                                                }
                                                                                                {offer.files.filter(el => el.type === null).map(file => (
                                                                                                    <Grid item
                                                                                                          className={classes.fileItem}
                                                                                                          key={file.id}>
                                                                                                        <Button
                                                                                                            className={classes.download}
                                                                                                            endIcon={
                                                                                                                < GetAppIcon/>}
                                                                                                            href={process.env.REACT_APP_HOST_API + `/shop/order/${lot.order.id}/offer/file/${file.id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                                        >
                                                                                                            Скачать {file.description}
                                                                                                        </Button>
                                                                                                    </Grid>
                                                                                                ))}
                                                                                            </Grid>
                                                                                        </Collapse>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            }
                                                                        </React.Fragment>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.footer}>
                                        <Grid container direction="column" justify="center" alignItems="stretch"
                                              spacing={1}>
                                            <Grid item className={classes.footerContent}>
                                                <Grid container className={classes.footerContainer} direction="row"
                                                      justify={(lot.order.files.find((file) => file.type.key === 'notice') ? "space-between" : "flex-end")}
                                                      alignItems="center" spacing={2}>
                                                    {(lot.order.files.find((file) => file.type.key === 'notice')) ? (
                                                      <Grid item>
                                                          <SignatureButton
                                                            file={lot.order.files.find((file) => file.type.key === 'notice')}
                                                            name="Извещение"
                                                            href={`/shop/order/${lot.order.id}/file/${lot.order.files.find((file) => file.type.key === 'notice').id}`}
                                                          />
                                                      </Grid>
                                                    ) : null}
                                                    {(filterLotFiles(lot.files, lot.status).find((file) => (file.type ? (file.type.key === 'protocol') : false))) &&
                                                        <Grid item>
                                                            <Button
                                                                className={classes.download}
                                                                endIcon={<GetAppIcon/>}
                                                                href={process.env.REACT_APP_HOST_API + `/shop/order/${lot.order.id}/file/${lot.files.find((file) => (file.type ? (file.type.key === 'protocol') : false)).id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                            >
                                                                Скачать протокол
                                                            </Button>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        ) : null
}
