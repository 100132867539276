import {ProductsService} from "../services/products"

export const ProductsActions = {
    products
}

function products(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "REPORT_PRODUCTS_REQUEST"})

        ProductsService.products(params)
            .then(
                products => {
                    dispatch({type: "REPORT_PRODUCTS", payload: products})
                    dispatch({type: "LOADING", payload: false})
                    resolve(products)
                },
                error => {
                    dispatch({type: "REPORT_PRODUCTS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
