import React, {useEffect, useState} from 'react'

import {
    Grid, makeStyles, Table as TableMUI, TableRow as TableRowMUI, TableCell as TableCellMUI, Checkbox,
    TableBody, TableContainer, Typography
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    }
}))

export const Purchase = (props) => {
    const classes = useStyles()

    const {filters, setFilters, setDefaultFilters} = props

    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState({purchase: filters.hasOwnProperty('purchase') ? filters.purchase : []})

    useEffect(() => {
        setFilters(filter)
        // eslint-disable-next-line
    }, [filter])

    useEffect(() => {
        if (!loading) {
            setLoading(true)
            setDefaultFilters({purchase: []})
        }
        // eslint-disable-next-line
    }, [loading])

    return (
        <React.Fragment>
            <Grid item>
                <Typography variant="caption">
                    Фильтр
                </Typography>
            </Grid>
            <Grid item className={classes.fullWidth}>
                <TableContainer style={{ height: '129px' }}>
                    <TableMUI stickyHeader>
                        <TableBody>
                            {[{name: 'Без закупки', key: 'plan'}].map(item => {
                                const isItemSelected = (filter.purchase.indexOf(item.key) !== -1)

                                return (
                                    <TableRowMUI
                                        key={item.key}
                                        onClick={(event) => {
                                            const selectedIndex = filter.purchase.indexOf(item.key)

                                            let select = []

                                            if (selectedIndex === -1) {
                                                select = select.concat(filter.purchase, item.key)
                                            } else if (selectedIndex === 0) {
                                                select = select.concat(filter.purchase.slice(1))
                                            } else if (selectedIndex === filter.purchase.length - 1) {
                                                select = select.concat(filter.purchase.slice(0, -1))
                                            } else if (selectedIndex > 0) {
                                                select = select.concat(
                                                    filter.purchase.slice(0, selectedIndex),
                                                    filter.purchase.slice(selectedIndex + 1),
                                                )
                                            }

                                            setFilter({
                                                purchase: select
                                            })
                                        }}
                                        role="checkbox"
                                        selected={isItemSelected}
                                    >
                                        <TableCellMUI padding="checkbox">
                                            <Checkbox checked={isItemSelected}/>
                                        </TableCellMUI>
                                        <TableCellMUI align="left">
                                            <Typography variant="body2" noWrap className={classes.filterItem}>{item.name}</Typography>
                                        </TableCellMUI>
                                    </TableRowMUI>
                                )
                            })}
                        </TableBody>
                    </TableMUI>
                </TableContainer>
            </Grid>
        </React.Fragment>
    )
}
