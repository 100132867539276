export default function order(state = {orders: {data: [], meta: {total: 0, per_page: 15, current_page: 1}}, type: null}, action) {
    switch (action.type) {
        case "REPORT_ORDERS_REQUEST":
            return state
        case "REPORT_ORDERS":
            return {
                ...state,
                ...{
                    orders: action.payload,
                }
            }
        case "REPORT_ORDERS_FAILURE":
            return state
        case "REPORT_ORDER_LOT_REQUEST":
            return state
        case "REPORT_ORDER_LOT":
            return {
                ...state,
                ...{lot: action.payload},
            }
        case "REPORT_ORDER_LOT_FAILURE":
            return state
        case "REPORT_ORDER_LOT_CLEAR":
            return {
                ...state,
                ...{lot: null}
            }
        default:
            return state
    }
}
