import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce, useDebouncedCallback} from 'use-debounce'
import format from "date-fns/format"
import {ru} from "date-fns/locale"
import clsx from "clsx"

import {
    Grid,
    IconButton,
    InputAdornment,
    Link,
    makeStyles,
    TextField,
    Typography
} from "@material-ui/core"
import {FileCopy, History as HistoryIcon, Search as SearchIcon} from '@material-ui/icons'
import {Table} from "@devexpress/dx-react-grid-material-ui";

import {ButtonList} from "../../../App/components/ButtonList/ButtonList"
import {DeliveryActions} from "../../actions/delivery"
import {AuthorizationService} from "../../../Auth/services/authorization"
import {history} from "../../../App/helpers/history"
import {TableHeader} from "../../../App/components/Table/TableHeader";
import {CustomTable} from "../../../App/components/Table/Table";
import {IntegratedSummary} from "@devexpress/dx-react-grid";
import {ColumnChooser} from "../../../App/components/Table/ColumnChooser";
import {SettingsActions} from "../../../Settings/actions/settings";
import {addYears} from "date-fns";
import {localState} from "../../../App/components/LocalState";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    inputContent: {
        "width": "100%",
        "height": 'calc(100vh - 183px)',
        "min-height": 'calc(100vh - 183px)',
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    searchLabel: {
        "margin-bottom": "14px",
    }
}))

const typeList = [
    {
        key: 'after_fact',
        value: 'Постфактум'
    },
    {
        key: 'active',
        value: 'Рабочий'
    },
    {
        key: 'all',
        value: 'Все'
    }
]

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {account, searchFilter} = useSelector(state => {
        return {...state.delivery, ...state.delivery.orders, ...state.account}
    })

    const [orders, setOrders] = useState({...localState,
        agg_info: {
            total_amount_rub: 0,
            total_sum_rub: 0,
            total_amount_kzt: 0,
            total_sum_kzt: 0,
        }
    })
    const {filter} = useSelector(state => state.filters['orders'])
    const [search, setSearch] = useState(filter.search)
    const debounced = useDebouncedCallback(
        (value) => {
            dispatch({
                type: "ORDERS_FILTER",
                payload: {search: value}
            })
        },
        900
    );

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [tableRef, setTableRef] = useState(null)

    const [loading, setLoading] = useState(false)

    const [columns, setColumns] = useState([
        {name: 'number', title: 'Заказ'},
        {name: 'contract', title: 'Договор', filter: 'contract'},
        {name: 'created', title: 'Дата создания', filter: 'created'},
        {name: 'company', title: 'Поставщик', filter: 'companies'},
        {name: 'status', title: 'Статус', filter: 'status'},
        {name: 'purchase', title: 'Закупка', filter: 'purchase'},
        {name: 'items_count', title: 'Позиций'},
        {name: 'price', title: 'На сумму', filter: 'price'},
    ])

    const [columnOrder, setColumnOrder] = useState([
        'number',
        'contract',
        'created',
        'company',
        'status',
        'items_count',
        'price',
        'purchase',
        'amount_rub',
        'total_rub',
        'amount_kzt',
        'total_kzt',
    ])

    const [initialize, setInitialize] = useState(false);

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'items_count', width: 200},
        {columnName: 'company', width: 150},
        {columnName: 'number', width: 250},
        {columnName: 'status', width: 250},
        {columnName: 'price', width: 200},
        {columnName: 'contract', width: 150},
        {columnName: 'created', width: 250},
        {columnName: 'purchase', width: 150}
    ])

    const [tableColumnExtensions, setTableColumnExtensions] = useState([
        {columnName: 'total_amount_rub', align: 'right'},
        {columnName: 'total_amount_kzt', align: 'right'},
        {columnName: 'total_sum_rub', align: 'right'},
        {columnName: 'total_sum_kzt', align: 'right'},
    ]);

    const [totalSummaryItems, setTotalSummaryItems] = useState([
        {columnName: 'items_count', type: 'total_amount_rub'},
        {columnName: 'price', type: 'total_sum_rub'},
        {columnName: 'items_count', type: 'total_amount_kzt'},
        {columnName: 'price', type: 'total_sum_kzt'},
    ]);

    const [messages, setMessages] = useState({
        total_amount_rub: 'Кол-во в рублях',
        total_sum_rub: 'Сумма в рублях',
        total_amount_kzt: 'Кол-во в тенге',
        total_sum_kzt: 'Сумма в тенге',
    });

    useEffect(() => {
        debounced.callback(search)
        // eslint-disable-next-line
    }, [search]);

    useEffect(() => {
        if (AuthorizationService.roles(account, 'supplier')) {
            setColumns(columns.map(column => {
                if (column.name === 'company') {
                    column.title = 'Заказчик'
                }

                return column;
            }))
        }
        // eslint-disable-next-line
    }, [account]);

    const summaryCalculator = (type, rows, getValue) => {
        if (type === 'total_amount_rub') {
          return (orders.agg_info.total_amount_rub ? parseFloat(orders.agg_info.total_amount_rub) : 0).toLocaleString('ru-RU');
        }
        if (type === 'total_sum_rub') {
            return (orders.agg_info.total_sum_rub ? parseFloat(orders.agg_info.total_sum_rub) : 0).toLocaleString('ru-RU');
        }
        if (type === 'total_amount_kzt') {
            return (orders.agg_info.total_amount_kzt ? parseFloat(orders.agg_info.total_amount_kzt) : 0).toLocaleString('ru-RU');
        }
        if (type === 'total_sum_kzt') {
            return Number(orders.agg_info.total_sum_kzt? parseFloat(orders.agg_info.total_sum_kzt) : 0).toLocaleString('ru-RU');
        }

        return IntegratedSummary.defaultCalculator(type, rows, getValue);
    }

    useEffect(() => {
        if (!initialize) {
            dispatch(SettingsActions.table('orders')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'orders',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...((filter.hasOwnProperty('status') && !!filter.status.length) ? {status: filter.status} : {}),
            ...((filter.hasOwnProperty('contract') && !!filter.contract.length) ? {contract: filter.contract} : {}),
            ...((filter.hasOwnProperty('companies') && !!filter.companies.length) ? {companies: filter.companies} : {}),
            ...((filter.hasOwnProperty('created') && !!filter.created.from.length) ? {created_from: format(new Date(filter.created.from), 'yyyy-MM-dd', true)} : {}),
            ...((filter.hasOwnProperty('created') && !!filter.created.to.length)  ? {created_to: format(addYears(new Date(filter.created.to), 1), 'yyyy-MM-dd', true)} : {}),
            ...((filter.hasOwnProperty('price') && !!filter.price.from.length) ? {price_from:  filter.price.from} : {}),
            ...((filter.hasOwnProperty('price') && !!filter.price.to.length)  ? {price_to:  filter.price.to} : {}),
            ...((filter.hasOwnProperty('purchase') && !!filter.purchase.length) ? {purchase: filter.purchase} : {}),
            ...(search ? {search: search} : {})
        }
    }

    useEffect(() => {
        if (loading) {
            if (orders.agg_info.total_amount_kzt === 0) {
                setMessages({
                    total_amount_rub: 'Кол-во в рублях',
                    total_sum_rub: 'Сумма в рублях',
                })
                setTableColumnExtensions([
                    {columnName: 'total_amount_rub', align: 'right'},
                    {columnName: 'total_sum_rub', align: 'right'},
                ]);
                setTotalSummaryItems([
                    {columnName: 'items_count', type: 'total_amount_rub'},
                    {columnName: 'price', type: 'total_sum_rub'},
                ]);
            } else if (orders.agg_info.total_amount_rub === 0) {
                setMessages({
                    total_amount_kzt: 'Кол-во в тенге',
                    total_sum_kzt: 'Сумма в тенге',
                })
                setTableColumnExtensions([
                    {columnName: 'total_amount_kzt', align: 'right'},
                    {columnName: 'total_sum_kzt', align: 'right'},
                ]);
                setTotalSummaryItems([
                    {columnName: 'items_count', type: 'total_amount_kzt'},
                    {columnName: 'price', type: 'total_sum_kzt'},
                ]);
            } else {
                setMessages({
                    total_amount_kzt: 'Кол-во в тенге',
                    total_sum_kzt: 'Сумма в тенге',
                    total_amount_rub: 'Кол-во в рублях',
                    total_sum_rub: 'Сумма в рублях',
                })
                setTableColumnExtensions([
                    {columnName: 'total_amount_kzt', align: 'right'},
                    {columnName: 'total_sum_kzt', align: 'right'},
                    {columnName: 'total_amount_rub', align: 'right'},
                    {columnName: 'total_sum_rub', align: 'right'},
                ]);
                setTotalSummaryItems([
                    {columnName: 'items_count', type: 'total_amount_kzt'},
                    {columnName: 'price', type: 'total_sum_kzt'},
                    {columnName: 'items_count', type: 'total_amount_rub'},
                    {columnName: 'price', type: 'total_sum_rub'},
                ]);
            }
        }
        // eslint-disable-next-line
    },[loading])

    useEffect(() => {
        const getOrders = async () => {
            return await dispatch(DeliveryActions.orders({
                    limit: rowsPerPage,
                    page: page,
                    type: searchFilter.type,
                    ...getParams()
            }))
        }

        getOrders().then((response) => {
            setOrders(response)
            tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
            setLoading(true)
        })
        // eslint-disable-next-line
    }, [dispatch, page, rowsPerPage, filter, searchFilter.type]);

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: "ORDERS_FILTER", payload: {limitRows: newRowsPerPage}})
    }

    return loading ? (
        <Grid item className={classes.content}>
            <Grid container direction="column" justify="center" alignItems="stretch">
                <Grid item>
                    <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                        <Grid item className={classes.fullWidth} xs={(AuthorizationService.roles(account, "customer") && (AuthorizationService.permissions(account, "access_all_delivery_orders") || AuthorizationService.permissions(account, "delivery_order_after_event"))) ? 8 : 12}>
                            <TextField
                                id="search"
                                variant="standard"
                                name={'search'}
                                value={search ?? ''}
                                placeholder={'Поиск: номер, наименование товара, артикул'}
                                fullWidth={true}
                                margin={'normal'}
                                onChange={(event) => {
                                    setSearch(event.target.value)
                                }}
                                classes={{
                                    root: clsx(classes.filterLabel, classes.searchLabel)
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon style={{color: '#c1c1c1'}} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        {(AuthorizationService.roles(account, "customer") && (AuthorizationService.permissions(account, "access_all_delivery_orders") || AuthorizationService.permissions(account, "delivery_order_after_event"))) &&
                            <Grid item xs={4}>
                                <ButtonList value={searchFilter.tabType} values={typeList}
                                            onChange={(_, value) => {
                                                setPage(1)
                                                dispatch({
                                                    type: "DELIVERY_ORDERS_SEARCH_FILTER",
                                                    payload: {
                                                        ...searchFilter, ...{
                                                            tabType: value,
                                                            type: typeList[value].key
                                                        }
                                                    }
                                                })
                                            }}/>
                            </Grid>
                        }
                        <Grid item style={{marginTop: '-30px'}}>
                            <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <CustomTable
                meta={orders.meta}
                tableName={'orders'}
                rows={orders.data.map((order) => ({
                    number:
                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                            <Grid item>
                                {order.number}
                            </Grid>
                            <Grid item>
                                <IconButton size="small" onClick={(e) => {
                                    e.stopPropagation()
                                    const textField = document.createElement('textarea')
                                    textField.innerText = order.number
                                    document.body.appendChild(textField)
                                    textField.select()
                                    document.execCommand('copy')
                                    textField.remove()
                                }}>
                                    <FileCopy fontSize="small" />
                                </IconButton>
                            </Grid>
                        </Grid>,
                    contract:
                        <Typography noWrap>
                            <Link href={`/contracts?id=${order.contract.id}`} className={classes.link}>{order.contract.number ?? order.contract.id}</Link>
                        </Typography>,
                    created: format(new Date(order.created), 'PP', {locale: ru}),
                    company:
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                            {(!order.contract.offer.company.active && AuthorizationService.roles(account, 'customer')) && <Grid item><HistoryIcon/></Grid>}
                            <Grid item>{AuthorizationService.roles(account, 'supplier') ? (order.contract.company ? order.contract.company.legal_detail.name : order.contract.offer.company.legal_detail.name) : order.contract.offer.company.legal_detail.name}</Grid>
                        </Grid>,
                    status: order.status.name,
                    items_count: !order.count.items ? 0 : order.count.items,
                    purchase: order.contract?.purchase_number,
                    price: parseFloat(order.price).toLocaleString('ru-RU', {
                        ...(order.contract.currency ? {
                            style: 'currency',
                            currency: order.contract.currency.code
                        } : 0)
                    }).replace('KZT', '₸')
                }))}
                columns={columns}
                page={{
                    page: page,
                    setPage: setPage,
                    rowsPerPage: rowsPerPage,
                    handlePageSizeChange: handlePageSizeChange
                }}
                setTableRef={setTableRef}
                columnsSettings={{
                    columnOrder: columnOrder,
                    setColumnOrder: setColumnOrder,
                    setColumnWidths: setColumnWidths,
                    columnWidths: columnWidths,
                    hiddenColumnNames: hiddenColumnNames,
                    setHiddenColumnNames: setHiddenColumnNames
                }}
                tableHeader={TableHeader}
                messages={messages}
                tableColumnExtensions={tableColumnExtensions}
                totalSummaryItems={totalSummaryItems}
                summaryCalculator={summaryCalculator}
                filterActionType={"ORDERS_FILTER"}
                classInputContent={classes.inputContent}
                rowComponent={({row, tableRow, children}) => (
                    <Table.Row
                        tableRow={tableRow}
                        className={classes.tableRow}
                        hover
                        row={row}
                        children={children}
                        onClick={() => {
                            history.push(`/delivery/order/${orders.data[tableRow.rowId].id}`)
                        }
                    }
                        style={{'cursor': 'pointer'}}
                    />
                )}
                getValues={DeliveryActions.filter}
                filters={{
                    status: {
                        name: 'status',
                        type: 'values',
                    },
                    created: {
                        name: 'created',
                        type: 'year',
                    },
                    purchase: {
                        name: 'purchase',
                        type: 'values',
                    },
                    company: {
                        name: 'companies',
                        type: 'values',
                    },
                    price: {
                        name: 'price',
                        type: 'year',
                    },
                    contract: {
                        name: 'contract',
                        type: 'values',
                    },
                }}
            />
        </Grid>
    ) : null
}
