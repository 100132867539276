import {ItemsService} from "../services/items"

export const ItemsActions = {
    addItems,
    items,
    lotItems,
    categoriesForItemsInLot,
    item,
    editItems,
    deleteItems,
    exclude,
    include,
    filter
}

function addItems(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "ADD_PURCHASE_ITEMS_REQUEST"})

        ItemsService.addItems(params)
            .then(
                _ => {
                    dispatch({type: "ADD_PURCHASE_ITEMS_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "ADD_PURCHASE_ITEMS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function editItems(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "ADD_PURCHASE_EDIT_ITEMS_REQUEST"})

        ItemsService.editItems(params)
            .then(
                _ => {
                    dispatch({type: "ADD_PURCHASE_EDIT_ITEMS_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "ADD_PURCHASE_ITEMS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function items(purchaseID , params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        ItemsService.items(purchaseID , params)
            .then(
                items => {
                    dispatch({type: "PURCHASE_ITEMS_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(items)
                },
                error => {
                    dispatch({type: "PURCHASE_ITEMS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function lotItems(purchaseID, lotId, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        ItemsService.lotItems(purchaseID, lotId, params)
            .then(
                items => {
                    dispatch({type: "PURCHASE_LOT_ITEMS_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(items)
                },
                error => {
                    dispatch({type: "PURCHASE_LOT_ITEMS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function categoriesForItemsInLot(lotID) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_CATEGORIES_REQUEST"})

        ItemsService.categoriesForItemsInLot(lotID)
            .then(
                categories => {
                    dispatch({type: "PURCHASE_CATEGORIES_SUCCESS", payload: categories})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_CATEGORIES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function item(purchaseLotID, itemID) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_LOT_ITEM_REQUEST"})

        ItemsService.item(purchaseLotID, itemID)
            .then(
                item => {
                    dispatch({type: "PURCHASE_LOT_ITEM_SUCCESS", payload: item})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_LOT_ITEM_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function deleteItems(purchseID, ids) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASES_DELETE_ITEMS_REQUEST"})

        ItemsService.deleteItems(purchseID, ids)
            .then(
                _ => {
                    dispatch({type: "PURCHASES_DELETE_ITEMS_SUCCESS"})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Объекты закупок успешно удалены',
                            type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASES_DELETE_LOTS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message:error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function exclude(purchseID, itemID) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASES_EXCLUDE_ITEM_REQUEST"})

        ItemsService.exclude(purchseID, itemID)
            .then(
                _ => {
                    dispatch({type: "PURCHASES_EXCLUDE_ITEM_SUCCESS"})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Объект закупок успешно исключён',
                            type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASES_EXCLUDE_ITEM_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message:error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function include(purchseID, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASES_INCLUDE_ITEM_REQUEST"})

        ItemsService.include(purchseID, params)
            .then(
                _ => {
                    dispatch({type: "PURCHASES_INCLUDE_ITEM_SUCCESS"})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Объекты закупок успешно добавлены',
                            type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASES_INCLUDE_ITEM_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message:error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function filter(params) {
    return dispatch => new Promise((resolve, reject) => {
        ItemsService.filter(params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    reject(error)
                }
            )
    })
}
