import {ContractService} from "../services/contract"

export const ContractActions = {
    contracts,
    statuses
}

function contracts(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "REPORT_CONTRACTS_REQUEST"})

        ContractService.contracts(params)
            .then(
                orders => {
                    dispatch({type: "REPORT_CONTRACTS", payload: {data: orders.data, meta: orders.meta}})
                    dispatch({type: "LOADING", payload: false})
                    resolve(orders)
                },
                error => {
                    dispatch({type: "REPORT_CONTRACTS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function statuses(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        ContractService.statuses(id, values)
            .then(
                data => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(data)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
