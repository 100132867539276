import React from "react"
import {Field} from "formik"
import {TextField} from "formik-material-ui"

import {Autocomplete, createFilterOptions} from '@material-ui/lab'

export const FieldUnit = (props) => {
    const { id, label, items, values, setFieldValue, setFieldTouched, isSubmitting, disabled } = props

    const value = values.hasOwnProperty(id) ? { short: values[id] } : { short: '' }

    return (
        <Autocomplete
            filterOptions={createFilterOptions({
                matchFrom: 'start',
                stringify: option => option.short ?? ''
            })}
            options={ items }
            name={`attributes.${id}`}
            disabled={ isSubmitting || disabled }
            getOptionLabel={option => option.short}
            defaultValue={value}
            onChange={(e, value) => {
                setFieldValue(`attributes.${id}`, (value.hasOwnProperty('name') ? value.short : value))
            }}
            onBlur={ () => setFieldTouched(`attributes.${id}`, true) }
            renderInput={params => (
                <Field
                    fullWidth
                    {...params}
                    name={`attributes.${id}`}
                    type="text"
                    label={ label }
                    component={ TextField }
                />
            )}
        />
    )
}
