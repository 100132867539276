import format from "date-fns/format"

export function getExpirationDateParams(params, type) {
    let result = {
        expiration_date_type: type
    }

    result[type] = type === 'expirationDate' ? format(params.expirationDate, 'yyyy-MM-dd') : params[type]

    return result
}
