const taxPercentValues = [
    {key: -1, value: 'Без НДС'},
    {key: 0, value: '0'},
    {key: 10, value: '10%'},
    {key: 20, value: '20%'},
];
const taxPercentValuesPrice = [
    {key: -1, value: 'Без НДС'},
    {key: 0, value: 0},
    {key: 10, value: 10},
    {key: 12, value: 12},
    {key: 20, value: 20},
];

const taxPercentValuesProduct = [
    {key: -1, value: 'Без НДС'},
    {key: 0, value: '0%'},
    {key: 10, value: '10%'},
    {key: 12, value: '12%'},
    {key: 20, value: '20%'},
];

const taxPercentValuesCatalogPrice = [
    {key: -1, value: 'Без НДС'},
    {key: 0, value: 'НДС 0%'},
    {key: 10, value: 'НДС 10%'},
    {key: 12, value: 'НДС 12%'},
    {key: 20, value: 'НДС 20%'},
];

const reportTypes = [
    'purchase_history',
    'delivery_history',
    'contract_history',
    'contracts',
    'delivery_orders',
    'delivery_orders_after_fact',
    'delivery_times',
    'contract_unscheduled_purchases',
    'order_unscheduled_purchases',
    'execution_contract',
    'user_activity',
    'supplier_activity',
    'order_supplier_activity',
    'share_categories',
    'share_suppliers',
    'share_standards',
    'price_orders',
    'price_standards',
    'customer_contracts',
    'delivery_orders_items',
    'order_standards',
    'planning_items'
];


const supplierStatuses = [
    {
        key: 'all',
        value: 'Все'
    },
    {
        key: 'confirmed',
        value: 'Подтверждена пользователем'
    },
    {
        key: 'blocked',
        value: 'Блокирована'
    },
    {
        key: 'confirmed_by_admin',
        value: 'Подтверждена администрацией'
    },
    {
        key: 'work',
        value: 'Рабочая'
    },
    {
        key: 'guest',
        value: 'Зарегистрирована'
    },
];

const customerStatuses = [
    {
        key: 'all',
        value: 'Все'
    },
    {
        key: 'confirmed_by_admin',
        value: 'Зарегистрирована'
    },
    {
        key: 'work',
        value: 'Рабочая'
    },
];


export {
    taxPercentValues,
    taxPercentValuesPrice,
    taxPercentValuesCatalogPrice,
    taxPercentValuesProduct,
    supplierStatuses,
    customerStatuses,
    reportTypes
};
