import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce} from 'use-debounce'
import format from "date-fns/format"
import clsx from "clsx";

import {
    Grid,
    InputAdornment,
    Link,
    makeStyles,
    TextField,
    Typography
} from "@material-ui/core"
import {Table} from "@devexpress/dx-react-grid-material-ui";

import {History as HistoryIcon, Search as SearchIcon} from '@material-ui/icons'

import {ContractActions} from "../../actions/contract";
import {ButtonList} from "../../../App/components/ButtonList/ButtonList"
import {AuthorizationService} from "../../../Auth/services/authorization"
import {Contract as Dialog} from "../Contract"
import {Submit} from "../../../App/components/Button/Submit"
import {SystemActions} from "../../../App/actions/system"
import {Information} from "../../../Company/components/Information";
import {SettingsActions} from "../../../Settings/actions/settings";
import {CustomTable} from "../../../App/components/Table/Table";
import {TableHeader} from "../../../App/components/Table/TableHeader";
import {ColumnChooser} from "../../../App/components/Table/ColumnChooser";
import {RemoveButton} from "../../../App/components/Button/RemoveButton";
import {localState} from "../../../App/components/LocalState";

const useStyles = makeStyles(theme => ({
    content: {
        width: '100%',
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 215px)"
    },
    input: {
        "width": "100%",
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 252px)",
        "min-height": "calc(100vh - 252px)"
    },
    inputContentWithoutButton : {
        "width": "100%",
        "height": "calc(100vh - 217px)",
        "min-height": "calc(100vh - 217px)"
    },
    number: {
        "overflow": "hidden",
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        "max-width": "150px"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    searchLabel: {
        "margin-bottom": "15px",
    },
    toggleContainer: {
        "display": "grid",
        "grid-template-columns": "1fr 280px",
    }
}))

const sortList = [
    {
        key: 'all',
        value: 'Все'
    },
    {
        key: 'active',
        value: 'Активный'
    },
    {
        key: 'inactive',
        value: 'Неактивный'
    },
    {
        key: 'new',
        value: 'Новый'
    },
    {
        key: 'delete',
        value: 'Удален'
    }
]

const typeList = [
    {
        key: 'after_fact',
        value: 'Постфактум'
    },
    {
        key: 'active',
        value: 'Рабочий'
    },
    {
        key: 'all',
        value: 'Все'
    }
]

const formList = [
    {
        key: 'elmag',
        value: 'Электронный магазин'
    },
    {
        key: 'all',
        value: 'Все'
    },
]

export const Content = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    let {location} = props

    const query = new URLSearchParams(location.search);
    const {account, companies, searchFilter} = useSelector(state => {
        return {...state.contract, ...state.account}
    })
    const [contracts, setContracts] = useState(localState)
    const {filter} = useSelector(state => state.filters['contracts'])

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [tableRef, setTableRef] = useState(null)

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const [searchRequest] = useDebounce(searchFilter.search, 900)
    const [id, setId] = useState(() => {
        const id = query.get('id')
        location.search = undefined
        if (id) {
            window.history.pushState('Договоры', 'Электронный магазин', '/contracts')
        }
        return id
    })
    const [supplierId, setSupplierId] = useState(() => {
        const id = query.get('supplier_id')
        location.search = undefined
        if (id) {
            window.history.pushState('Договоры', 'Электронный магазин', '/contracts')
        }
        return id
    })
    const [brief, setBrief] = useState(() => {
        location.search = undefined
        if (query.has('brief')) {
            window.history.pushState('Договоры', 'Электронный магазин', '/contracts')
            return true
        }
        return false
    })
    const [dialog, setDialog] = useState(query.has('id') || query.has('supplier_id'))
    const [card, setCard] = useState(null)

    const [loading, setLoading] = useState(false)
    const [settings, setSettings] = useState(null)
    const [columns, setColumns] = useState([
        {name: 'number', title: 'Договор'},
        {name: 'company', title: 'Поставщик'},
        {name: 'id', title: 'ID договора'},
        {name: 'start', title: 'Дата начала'},
        {name: 'end', title: 'Дата окончания'},
        {name: 'status', title: 'Статус'},
        {name: 'total_price', title: 'Сумма договора'},
        {name: 'remainder', title: 'Оставшаяся сумма'},
        {name: 'close', title: ' '},
    ])

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'number', width: 100},
        {columnName: 'company', width: 200},
        {columnName: 'id', width: 200},
        {columnName: 'start', width: 150},
        {columnName: 'end', width: 250},
        {columnName: 'status', width: 150},
        {columnName: 'total_price', width: 250},
        {columnName: 'remainder', width: 250},
        {columnName: 'close', width: 100},
    ])

    const [columnOrder, setColumnOrder] = useState([
        'number',
        'company',
        'id',
        'start',
        'end',
        'status',
        'total_price',
        'remainder',
        'close',
    ])

    const [initialize, setInitialize] = useState(false);

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {})
        }
    }

    useEffect(() => {
        if (AuthorizationService.roles(account, 'supplier')) {
            setColumns(columns.map(column => {
                if (column.name === 'company') {
                    column.title = 'Заказчик'
                }

                return column;
            }))
        }
        // eslint-disable-next-line
    }, [account]);

    useEffect(() => {
        if (!initialize) {
            dispatch(SettingsActions.table('contracts')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                dispatch({type: "FILTER_CLEAR"})
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'contracts',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    useEffect(() => {
        const getCurrencies = async () => {
            await dispatch(SystemActions.currencies())
        }

        getCurrencies().then(_ => {
        })
    }, [dispatch])

    useEffect(() => {
        const getSettings = async () => {
            return await dispatch(SettingsActions.getSettings('contract'))
        }

        getSettings().then(settings => {
            setSettings(settings)
        })
    }, [dispatch])

    useEffect(() => {
        setLoading(false)
    }, [page, rowsPerPage, searchRequest, searchFilter.type, searchFilter.sort, filter, searchFilter.form]);

    useEffect(() => {
        if (id) {
            setDialog(true)
        }
    }, [id]);

    useEffect(() => {
        if (!dialog) {
            setLoading(false)

            dispatch(ContractActions.companies({
                limit: companies.filter.limit,
                page: companies.filter.page
            }))

            setId(null)
        }
        // eslint-disable-next-line
    }, [dialog]);

    useEffect(() => {
        const getContracts = async () => {
            return await dispatch(ContractActions.contracts({
                ...{
                    limit: rowsPerPage,
                    page: page,
                    sort: searchFilter.sort,
                    type: searchFilter.type,
                    form: searchFilter.form,
                    ...getParams()
                },
                ...(searchRequest ? {search: searchRequest} : {}),
            }))
        }

        if (!loading) {
            getContracts().then((response) => {
                setContracts(response)
                tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
                setLoading(true)
            })
        }
        return () => {
            dispatch({type: "SET_ACTIVE_PRICE", payload: null})
        }
        // eslint-disable-next-line
    }, [dispatch, loading, page, rowsPerPage, searchRequest, searchFilter.type, searchFilter.sort, searchFilter.form, filter]);

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: "CONTRACTS_FILTER", payload: {limitRows: newRowsPerPage}})
    }

    return <Grid item className={classes.content}>
        <Grid container direction="column" justify="center" alignItems="stretch">
            <Grid item>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item className={classes.fullWidth} style={{paddingBottom: '4px'}}>
                        <TextField
                            id="search"
                            variant="standard"
                            name={'search'}
                            placeholder={`Поиск: номер, ${AuthorizationService.roles(account, 'customer') ? 'поставщик' : 'заказчик'}`}
                            fullWidth={true}
                            value={searchFilter.search}
                            margin={'normal'}
                            onChange={(event) => {
                                if (event.target.value === '' && searchFilter.search !== '') {
                                    dispatch({
                                        type: "CONTRACTS_SEARCH_FILTER", payload: {
                                            search: '',
                                            sort: 'name_asc',
                                            type: 'all',
                                            tabType: 2,
                                        }
                                    })
                                } else {
                                    dispatch({
                                        type: "CONTRACTS_SEARCH_FILTER",
                                        payload: {...searchFilter, ...{search: event.target.value}}
                                    })
                                }
                            }}
                            classes={{
                                root: clsx(classes.filterLabel, classes.searchLabel)
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon style={{color: '#c1c1c1'}}/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                    <Grid item style={{paddingBottom: '4px'}}>
                        <ButtonList
                            value={searchFilter.tabSort}
                            values={sortList}
                            onChange={(_, value) => {
                                setPage(1)
                                dispatch({
                                    type: "CONTRACTS_SEARCH_FILTER",
                                    payload: {...searchFilter, ...{tabSort: value, sort: sortList[value].key}}
                                })
                            }}
                        />
                    </Grid>
                    <Grid item className={classes.toggleContainer}>
                        <ButtonList
                            value={searchFilter.tabForm}
                            values={formList}
                            onChange={(_, value) => {
                                setPage(1)
                                dispatch({
                                    type: "CONTRACTS_SEARCH_FILTER",
                                    payload: {...searchFilter, ...{tabForm: value, form: formList[value].key}}
                                })
                            }}
                        />
                        {(AuthorizationService.roles(account, 'customer') && AuthorizationService.permissions(account, "delivery_order_after_event")) &&
                            <ButtonList
                                value={searchFilter.tabType}
                                values={typeList}
                                onChange={(_, value) => {
                                    setPage(1)
                                    dispatch({
                                        type: "CONTRACTS_SEARCH_FILTER",
                                        payload: {...searchFilter, ...{tabType: value, type: typeList[value].key}}
                                    })
                                }}
                            />
                        }
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                    <Grid item style={{marginTop: '-10px', marginBottom: '8px'}}>
                        {(AuthorizationService.roles(account, "customer") && AuthorizationService.permissions(account, "create_delivery_order")) &&
                            <Grid item>
                                <Submit
                                    disableElevation
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    onClick={() => {
                                        setDialog(true)
                                    }}
                                >
                                    Новый договор
                                </Submit>
                            </Grid>
                        }
                    </Grid>
                    <Grid item style={{marginTop: '-20px'}}>
                        <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <CustomTable
            meta={contracts.meta}
            tableName={'contracts'}
            rows={contracts?.data.map((contract) => ({
                number: contract.number ?? contract.id,
                company: <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                    {(!contract.active && AuthorizationService.roles(account, 'customer')) &&
                        <Grid item><HistoryIcon/></Grid>}
                    <Grid item>
                        <Typography noWrap>
                            <Link
                                onClick={e => {
                                    e.stopPropagation()
                                    setCard(AuthorizationService.roles(account, 'supplier') ? (contract.company?.id ?? contract.offer.company?.id) : contract.offer.company?.id)
                                }}
                                className={classes.link}
                            >
                                {AuthorizationService.roles(account, 'supplier') ? (contract.company ? contract.company.legal_detail.name : contract.offer.company.legal_detail.name) : contract.offer.company.legal_detail.name}
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>,
                id: contract.id,
                start: contract.start ? format(new Date(contract.start), 'dd.MM.yyyy') : null,
                end: !contract.perpetual ? (contract.end ? format(new Date(contract.end), 'dd.MM.yyyy') : (contract.offer.lot.end ? format(new Date(contract.offer.lot.end), 'dd.MM.yyyy') : null)) : null,
                status: contract.status.name,
                total_price: contract.framework ? '-' : Number(contract.total_price).toLocaleString('ru-RU', {
                    style: 'currency',
                    currency: contract.currency.code
                }).replace('KZT', '₸'),
                remainder: contract.framework
                    ? '-'
                    : ((Number(contract.total_price) - Number(contract.orders_sum) > 0)
                        ? Number(contract.total_price) - Number(contract.orders_sum)
                        : 0
                    ).toLocaleString('ru-RU', {style: 'currency', currency: contract.currency.code}).replace('KZT', '₸'),
                close: <RemoveButton
                    disabled={!((contract.status.index !== 4) && (AuthorizationService.roles(account, "supplier") || (AuthorizationService.roles(account, "customer") && AuthorizationService.permissions(account, "create_delivery_order"))))}
                    item={contract}
                    onSubmit={(id) => {
                        return dispatch(ContractActions.remove(id))
                    }}
                    onSuccess={() => {
                        setLoading(false)
                    }}
                />
            }))}
            columns={columns}
            page={{
                page: page,
                setPage: setPage,
                rowsPerPage: rowsPerPage,
                handlePageSizeChange: handlePageSizeChange
            }}
            setTableRef={setTableRef}
            columnsSettings={{
                columnOrder: columnOrder,
                setColumnOrder: setColumnOrder,
                setColumnWidths: setColumnWidths,
                columnWidths: columnWidths,
                hiddenColumnNames: hiddenColumnNames,
                setHiddenColumnNames: setHiddenColumnNames
            }}
            tableHeader={TableHeader}
            filterActionType={"CONTRACTS_FILTER"}
            classInputContent={(AuthorizationService.roles(account, "customer") ? classes.inputContent : classes.inputContentWithoutButton)}
            rowComponent={({row, tableRow, children}) => (
                <Table.Row
                    tableRow={tableRow}
                    className={classes.default}
                    hover
                    row={row}
                    children={children}
                    onClick={(e, _) => {
                        setId(contracts.data[tableRow.rowId].id)
                    }}
                    style={{'cursor': 'pointer'}}
                />
            )}
            filters={{
                close: {
                    nullButton: true
                }
            }}
        />
        {dialog && <Dialog open={dialog} setDialog={setDialog} id={id} settings={settings} setId={setId} supplierId={supplierId} setSupplierId={setSupplierId} brief={brief} setBrief={setBrief}/>}
        {!!card && <Information id={card} open={!!card} onClose={() => setCard(null)}/>}
    </Grid>
}
