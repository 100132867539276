import React from 'react'
import {TableHeaderRow} from "@devexpress/dx-react-grid-material-ui"

import {Filter} from "../Filter"

export const TableHeaderContent = (props) => {
    const {column, filter, setFilter, children, ...restProps} = props

    let button = null
    // eslint-disable-next-line default-case
    switch (column.name) {
        case 'status':
        case 'id':
        case 'value.keyword':
        case 'created':
            button = <Filter
                filter={filter}
                setFilter={setFilter}
                active={(filter.sort.name === column.name)}
                name={column.name}
            />
            break
        case 'category':
            button = <Filter
                filter={filter}
                setFilter={setFilter}
                active={((filter.sort.name === column.name) || (filter.hasOwnProperty('category') && !!filter.category))}
                name={column.name}
            />
            break
    }

    return (
        <TableHeaderRow.Content
            column={column}
            {...restProps}
            style={{
                justifyContent: 'space-between'
            }}
        >
            {children}
            {button}
        </TableHeaderRow.Content>
    )
}
