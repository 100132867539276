import {HttpService} from "../../App/services/http"

export const DeliveryService = {
    orders,
    order,
    arrange,
    remove,
    save,
    cancel,
    status,
    steps,
    files,
    addItem,
    addItems,
    addAllItems,
    editItem,
    removeItem,
    items,
    filter
}

function orders(params) {
    return HttpService.get(`/contract/delivery/orders`, params)
}

function order(id, params) {
    return HttpService.get(`/contract/delivery/order/${id}`, params)
        .then(response => {
            return response.order
        })
}

function items(id, params) {
    return HttpService.get(`/contract/delivery/order/${id}/listItems`, params)
}

function arrange(id, values) {
    return HttpService.post(`/contract/delivery/order/${id}`, values)
}

function remove(id) {
    return HttpService.deleteMethod(`/contract/delivery/order/${id}`)
}

function save(id, formData) {
    return HttpService.post(`/contract/delivery/order/${id}/save`, formData, true, true)
}

function cancel(id) {
    return HttpService.put(`/contract/delivery/order/${id}/cancel`)
}

function status(id, values) {
    return HttpService.put(`/contract/delivery/order/${id}/status`, values)
}

function steps(id) {
    return HttpService.get(`/contract/delivery/order/${id}/steps`).then(response => response.steps)
}

function files(id, formData) {
    return HttpService.post(`/contract/delivery/order/${id}/files`, formData, true, true)
}

function addItem(id, values) {
    return HttpService.post(id ? `/contract/delivery/order/${id}/item` : `/contract/delivery/order/item`, values)
}

function addItems(values) {
    return HttpService.post(`/contract/delivery/order/items`, values)
}

function addAllItems(id) {
    return HttpService.post(`/contract/delivery/order/contract/${id}/items`)
}

function editItem(order_id, id, values) {
    return HttpService.put(`/contract/delivery/order/${order_id}/item/${id}`, values)
}

function removeItem(order_id, id) {
    return HttpService.deleteMethod(`/contract/delivery/order/${order_id}/item/${id}`)
}

function filter(params) {
    return HttpService.get(`/contract/delivery/filter`, params)
}
