import {HttpService} from "../../App/services/http"

export const EditRequestService = {
    requestForEdit,
    sendForCorrection
}

function requestForEdit(values) {
    return HttpService.post(`/planning/purchase/request-for-edit`, values, true, true)
}

function sendForCorrection(ids) {
    return HttpService.post(`/planning/purchase/send-for-correction`, ids)
}
