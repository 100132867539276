import React, {useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {
    Dialog,
    DialogActions,
    DialogContent, DialogTitle,
    Grid,
    makeStyles, TextField
} from "@material-ui/core"

import {Submit} from "../../../../App/components/Button/Submit"
import {purchase as rules} from "../../../helpers/permissions/rules"
import {PurchaseActions} from "../../../actions/purchase";
import {Form, Formik} from "formik";
import * as Yup from "yup";

const useStyles = makeStyles(theme => ({
    button: {
        "height": "45px",
    },
    title: {
        "&>h2": {
            color: "#fff"
        }
    }
}))

export const Decline = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [dialog, setDialog] = useState(false)

    const { items, settings, correction = false, onClick } = props

    const {account} = useSelector(state => state.account)

    return (correction ? ((account.group === 'central') && rules.correction.decline.hasOwnProperty(account.group)) : rules.decline.hasOwnProperty(account.group)) ? (
        <Grid item>
            <Submit
                className={classes.button}
                disableElevation
                disabled={!items.length || !!items.filter(el => {
                    const params = correction ? rules.correction.decline[account.group] : rules.decline[account.group]
                    const status = correction ? el.editRequestStatus : el.status

                    if (params.hasOwnProperty(status?.index) && !el.lock) {
                        if (params[status.index].hasOwnProperty('conditions')) {
                            if (params[status.index].conditions.hasOwnProperty('purchase')) {
                                return !params[status.index].conditions.purchase.includes(el.status.index)
                            }

                            if (params[status.index].conditions.hasOwnProperty('correction')) {
                                return !params[status.index].conditions.correction.includes(el.editRequestStatus?.index)
                            }

                            if (params[status.index].conditions.hasOwnProperty('settings')) {
                                for (const [key, value] of Object.entries(params[status.index].conditions.settings)) {
                                    if (settings[key] !== value) {
                                        return true
                                    }
                                }
                            }
                        }

                        return false
                    }

                    return true
                }).length}
                variant="contained"
                color="primary"
                style={{height: '35px'}}
                type="button"
                onClick={() => setDialog(true)}
            >
                {correction ? 'Отклонить запрос корректировки' : 'Отклонить закупку'}
            </Submit>
            {dialog &&
                <Dialog
                    open={dialog}
                    onClose={() => {
                        setDialog(false)
                    }}
                    fullWidth={true}
                    maxWidth={'sm'}
                >
                    <DialogTitle className={classes.title}>Укажите причину отклонения закупки</DialogTitle>
                    <Formik
                        initialValues={{
                            ids: items.map(el => el.id),
                            comment: '',
                        }}
                        validationSchema={Yup.object().shape({
                            ids: Yup.array().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            comment: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
                        })}
                        onSubmit={(values, {setSubmitting}) => {
                            return dispatch(PurchaseActions.decline(values)).then(
                                () => {
                                    setDialog(false)
                                    onClick()
                                },
                                () => {
                                    setSubmitting(false)
                                }
                            )
                        }}
                    >
                        {({
                              dirty,
                              isSubmitting,
                              setFieldValue,
                              isValid
                          }) => (
                            <Form>
                                <DialogContent>
                                    <TextField
                                        fullWidth
                                        name="comment"
                                        label="Комментарий"
                                        rowsMax={6}
                                        multiline
                                        variant="standard"
                                        onChange={(e) => setFieldValue(`comment`, e.target.value)}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="stretch"
                                    >
                                        <Grid item>
                                            <Submit
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={isSubmitting || !isValid || !dirty}
                                            >
                                                Отклонить
                                            </Submit>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </Dialog>
            }
        </Grid>
    ) : null
}
