import {HttpService} from "../../App/services/http"

export const PlanningService = {
    categories,
    createChat,
    units
}

function categories() {
    return HttpService.get(`/planning/categories`)
}

function createChat(values) {
    return HttpService.post(`/planning/comments-chat`, values)
}

function units() {
    return HttpService.get(`/planning/units`)
}

