import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {Field, Form, Formik} from "formik"
import {TextField} from "formik-material-ui"
import * as Yup from "yup"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    MenuItem
} from "@material-ui/core"
import {Close as CloseIcon} from "@material-ui/icons"

import {Submit} from "../../../../App/components/Button/Submit"
import {CompanyActions} from "../../../actions/company"
import {PurchaseActions} from "../../../actions/purchase"
import {history} from "../../../../App/helpers/history"
import {getFullName} from "../../../../App/helpers/user"

const useStyles = makeStyles(theme => ({
    fullWidth: {
        "width": "100%"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    column: {
        "height": "86px"
    },
    dialogContent: {
        "max-height": "calc(100vh - 210px)"
    },
    footer: {
        "background-color": "#e7eaee",
        "justify-content": "flex-start"
    },
    dialog: {
        "max-height": "70vh"
    },
    footerContent: {
        "padding": "10px 0px"
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    titleDialog: {
        "& h2": {
            "display": "flex",
            "justify-content": "space-between",
        },
    }
}))

export const JointPurchase = (props) => {
    const { items } = props
    const dispatch = useDispatch()
    const classes = useStyles()

    const [open, setOpen] = useState(props.open)
    const [companies, setCompanies] = useState([])

    useEffect(() => {
        const getCompanies = async () => {
            return await dispatch(CompanyActions.companies({purchases: items.map(item => item.purchase_id).filter((value, index, self) => (self.indexOf(value) === index))}))
        }

        getCompanies().then(response => {
            setCompanies(response.data)
            setOpen(props.open)
        })
        // eslint-disable-next-line
    }, [dispatch, props.open]);

    const handleClose = () => {
        setOpen(false)
        props.setDialogJointPurchase(false)
    }

    return open ? (
        <Grid item>
            <Dialog
                open={!!open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle className={classes.titleDialog}>Создать совместную закупку<CloseIcon className={classes.close} onClick={handleClose}/></DialogTitle>
                <Formik
                    initialValues={{
                        customer: '',
                        user: '',
                        items: items.map(item => item.id)
                    }}
                    validationSchema={Yup.object().shape({
                        customer: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        user: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        items: Yup.array().required("Поле не может быть пустым или состоять из одних пробелов!")
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        return dispatch(PurchaseActions.joint(values)).then(
                            response => {
                                handleClose()
                                setSubmitting(false)
                                history.push('/planning/joint-purchases')
                            },
                            errors => {
                                if (errors) {
                                }
                                setSubmitting(false)
                            }
                        )
                    }}
                >
                    {({
                          dirty,
                          values,
                          errors,
                          touched,
                          validateField,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setValues,
                          setFieldValue,
                          setFieldTouched,
                          isSubmitting,
                          isValid,
                          submitForm
                      }) => (
                        <Form>
                            <DialogContent className={classes.dialogContent}>
                                <Grid container className={classes.fullWidth} direction="row" justify="center" alignItems="stretch">
                                    <Grid item className={classes.fullWidth}>
                                        <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
                                            <Grid item>
                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                    <Grid item xs={6} className={classes.column}>
                                                        <Field
                                                            fullWidth
                                                            type="text"
                                                            name="customer"
                                                            label="ЧУЗ"
                                                            select
                                                            variant="standard"
                                                            component={TextField}
                                                            required
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            InputProps={{
                                                                onChange: (event) => {
                                                                    setFieldValue(`customer`, event.target.value, true)
                                                                }
                                                            }}
                                                        >
                                                            {companies.map(company => (
                                                                <MenuItem key={company.id} value={company.id}>
                                                                    {company.legal_detail.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Field>
                                                    </Grid>
                                                    <Grid item xs={6} className={classes.column}>
                                                        <Field
                                                            fullWidth
                                                            type="text"
                                                            name="user"
                                                            label="Пользователь"
                                                            select
                                                            variant="standard"
                                                            component={TextField}
                                                            disabled={!values.customer || isSubmitting}
                                                            required
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            InputProps={{
                                                                onChange: (event) => {
                                                                    setFieldValue(`user`, event.target.value, true)
                                                                }
                                                            }}
                                                        >
                                                            {(values.customer ? companies.find(company => (company.id === values.customer)).employees : []).map((employee, i) => (
                                                                <MenuItem key={employee.id} value={employee.id}>
                                                                    {getFullName(employee)}
                                                                </MenuItem>
                                                            ))}
                                                        </Field>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <TableContainer className={classes.tableContainer}>
                                                    <Table stickyHeader aria-label="sticky table">
                                                        <TableHead>
                                                            <TableRow>
                                                                {[{id: 0, label: '№'}, {id: 1, label: 'Номер ОЗ'}, {id: 2, label: 'Наименование'}].map((column) => (
                                                                    <TableCell key={column.id}>
                                                                        {column.label}
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {items.map((item, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell component="th" scope="row">
                                                                        {index + 1}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.number}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.name}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions className={classes.footer}>
                                <Grid container className={ classes.footerContent} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Submit
                                            variant="contained"
                                            component="label"
                                            color="primary"
                                            disabled={isSubmitting || !isValid || !dirty}
                                            onClick={handleSubmit}
                                            type="submit"
                                        >
                                            Создать
                                        </Submit>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </Grid>
    ) : null
}
