import {endings} from './endings'

function getPriceName(price) {
    let name = 'публичный'
    if (price.download_type) {
        switch (price.download_type) {
            case 'file':
                if (price.file_names !== null) {
                    const files = JSON.parse(price.file_names)
                    name = files && files.length > 1 ? endings(files.length, ['файл', 'файла', 'файлов']) : files[0]
                } else {
                    name = ''
                }

                break
            default:
                name = price.tasks[0].url
        }
    }

    return name
}

export { getPriceName }
