import React from "react"
import {useDispatch} from "react-redux"
import {Field, Form, Formik} from "formik"
import * as Yup from "yup"

import {Dialog as MUIDialog, DialogContent, DialogTitle, Grid, makeStyles, Typography,} from "@material-ui/core"
import {Close as CloseIcon, Add as AddIcon} from "@material-ui/icons"

import {Submit} from "../../../../../App/components/Button/Submit"
import {ButtonIcon} from "../../../../../App/components/Button/ButtonIcon"
import {FileUpload} from "../../../../../App/components/Inputs/FileUpload"
import {PurchasePlanItemsActions} from "../../../../actions/purchasePlanItems"

const useStyles = makeStyles(() => ({
    tableHeaderItem: {
        "border-right": "2px solid rgba(211,211,211,.4)",
        "height": "40px",
        "display": "flex",
        "padding": "0 15px",
        "align-items": "center",
        "margin": "8px 0",
    },
    borderRightNone: {
        "border-right": "none",
    },
    padLeft: {
        "padding-left": "0",
    },
    title: {
        "margin": "0",
        "padding-top": "2px",
        "font-size": "18px",
        "font-weight": "600",
        "color": "#fff",
        "text-transform": "uppercase",
        "display": "flex",
        "justify-content": "space-between",
        "align-items": "center",
        "min-height": "40px",
        "padding": "0 20px",
        "background-color": "#898989",
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    footerContainer: {
        "padding": "0 25px",
        "background-color": "#e7eaee",
        "height": "75px",
        "position": "absolute",
        "bottom": "0",
    },
    submit: {
        "width": "100%",
        "border-bottom": "3px solid #898989",
        "border-radius": "2px",
        "padding": "5px 10px 0",
        "min-width": "95px",
        "min-height": "35px",
        "font-size": "16px",
        "font-weight": "500",
        "color": "white",
        "outline": "0",
        "height": "35px",
        "&:disabled": {
            "background-color": "#c1c1c1",
            "border-bottom-color": "#898989 !important",
            "color": "white"
        }
    },
    dialogContent: {
        padding: "0",
        "overflow": "hidden",
    },
    saveButton: {
    },
    rows: {
        "min-height": "calc(100vh - 350px)",
        "overflow": "auto",
    },
    dialogContainer: {
        "& > div": {
            "max-width": "60vw",
            "min-height": "calc(100vh - 300px)",
            overflow: "hidden",
        }

    },
    fullWidth: {
        width: "100%",
    },
    attention: {
        color: "red",
        textTransform: "uppercase",
    },
    attentionMessage: {
        color: "red",
    },
    contentContainer: {
        padding: "30px 25px 0 25px",
        marginBottom: "100px",
    },
    downloads: {
        width: "100%",
        minHeight: "240px",
    },
    reset: {
        "&:hover": {
            "background-color": "#898989",
        },
        "min-width": "35px",
        "max-height": "35px",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "#fff",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-bottom": "3px solid #898989",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "#616161",
        "cursor": "pointer",
        "margin": "0",
        "width": "auto",
        "padding": "5px 10px 0 10px",
    }
}));

export const Dialog = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {open, onClose, purchasePlanID} = props

    return (
        <MUIDialog
            aria-labelledby="simple-dialog-title"
            open={open}
            classes={{
                root: classes.dialog,
                container: classes.dialogContainer
            }}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle id="simple-dialog-title" classes={{
                root: classes.title,
            }} disableTypography={true}>Загрузите прайс-лист
                <CloseIcon className={classes.close} onClick={onClose} />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Formik
                    enableReinitialize
                    initialValues={{
                        files: null,
                    }}
                    validationSchema={Yup.object().shape({
                        files: Yup.mixed().test('fileFormat', 'Документ должен быть файлом одного из следующих типов: csv, xls, xlsx.', (values) => {
                            return !values || (values && Array.from(values).filter(file => [
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                'application/vnd.ms-excel',
                                'text/csv'
                            ].includes(file.type)).length !== 0);
                        }).required(),
                    })}

                    onSubmit={(values, {setSubmitting}) => {
                        const formData = new FormData()
                        if (values.files) {
                            Array.from(values.files).forEach(file => {
                                formData.append("files[]", file, file.name);
                            });
                        }
                        // return setSubmitting(false)
                        return dispatch(PurchasePlanItemsActions.uploadItems(purchasePlanID, formData)).then(
                            () => {
                                props.onClose(true)
                                setSubmitting(true)
                            },
                            () => {
                                setSubmitting(false);
                            }
                        )
                    }}
                >
                    {({
                          values,
                          setFieldValue,
                          setFieldTouched
                      }) => (
                        <Form>
                            <Grid container
                                className = {
                                    classes.contentContainer
                                }
                                direction = "row"
                                justify = "flex-end"
                                alignItems = "flex-start"
                                spacing = {2}>
                                <Grid item xs={6}>
                                    <Grid container direction = "row" className={classes.fullWidth}>
                                        <Grid item className={classes.downloads}>
                                            <Field
                                                fullWidth
                                                component={(props) => (
                                                    <FileUpload
                                                        {...props}
                                                        endIcon={<AddIcon/>} />
                                                )}
                                                label="Загрузите ОЗ"
                                                name="files"
                                                InputLabelProps={{
                                                    name: values.files ? Array.from(values.files).map(el => el.name).join(', ') : null
                                                }}
                                                InputProps={{
                                                    onChange: (event) => {
                                                        const files = event.currentTarget.files;
                                                        setFieldValue('files', files);
                                                        setFieldTouched("files", true, false)
                                                    },
                                                    multiple: false
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.attention}>Внимание!</Typography>
                                    <Typography className={classes.attentionMessage}>Шаблон вы можете по ссылке <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/spreadsheets/d/1ke-LEPOZIlAO_3XQBZ4DuHOKJ6t7VZgd/edit#gid=91735015">пример файла</a></Typography>
                                </Grid>
                            </Grid>
                            <Grid container
                                className = {
                                    classes.footerContainer
                                }
                                direction = "row"
                                justify = "flex-end"
                                alignItems = "center"
                                spacing = {2}>
                                <Grid item>
                                    <ButtonIcon
                                        className={classes.reset}
                                        onClick={e => {
                                            e.stopPropagation()
                                            onClose(e)
                                        }}
                                        children={'Отмена'}/>
                                </Grid>
                                <Grid item>
                                    <Submit
                                        className={classes.submit}
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        type="submit">
                                        Загрузить
                                    </Submit>
                                </Grid>
                            </Grid>
                    </Form>
                )}
                </Formik>
            </DialogContent>
        </MUIDialog>
    )
}
