import React, {useState} from "react"

import {Content} from "./components/Dashboards/Content";
import {Menu} from "./Menu"
import {Menu as AppMenu} from "../App/Menu";
import {Content as AppContent} from "../App/Content";

export const Dashboards = (props) => {
    const [dashboard, setDashboard] = useState(5)

    return <React.Fragment>
        <AppMenu
            Component={Menu}
            dashboard={dashboard}
            setDashboard={setDashboard}
            {...props}
        />
        <AppContent
            Component={Content}
            dashboard={dashboard}
            {...props}
        />
    </React.Fragment>
}
