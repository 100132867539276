export default function planningSettings(state = {}, action) {
    switch (action.type) {
        case "PLANNING_SETTINGS_REQUEST":
            return state
        case "PLANNING_SETTINGS":
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}
