import React from "react"
import {Link} from "react-router-dom"

import {Grid, List, ListItem, ListItemText, makeStyles} from '@material-ui/core'
import {withStyles} from "@material-ui/core/styles"
import {AuthorizationService} from "../Auth/services/authorization";
import {useSelector} from "react-redux";

const useStyles = makeStyles(() => ({
    active: {
        "background-image": "none !important",
        "background-color": "#898989"
    },
    fullWidth: {
        width: "100%",
    },
    container: {
        height: "100%",
        width: "100%",
        margin: 0,
    }
}))

const CustomList = withStyles({
    root: {
        "padding": "0",
        "& .MuiListItem-button": {
            "background-image": "linear-gradient(to bottom,#e4e4e4,#f4f4f4)",
            "cursor": "pointer",
            "align-items": "center",
            "min-height": "50px",
            "padding": "5px 25px 0",
            "border-bottom": "1px solid #e4e4e4",
            "outline": "0"
        },
        "& .MuiListItemText-primary": {
            "font-size": "16px",
            "font-weight": "700",
            "text-transform": "uppercase"
        },
        "& .MuiListItem-button:hover": {
            "background-color": "#898989",
            "&:after": {
                "position": "absolute",
                "content": "''",
                "top": "0",
                "left": "0",
                "width": "100%",
                "height": "100%",
                "background-color": "rgba(0,0,0,.04)"
            }
        }
    }
})(List);

export const Menu = (props) => {
    const classes = useStyles()
    const {account} = useSelector(state => state.account)
    const {location} = props

    const list = [
        {title: "Справочник", to: "/dictionary/standards", type: 'menu'},
        {title: "Отобранные эталоны", to: "/dictionary/standards/selected", type: 'menu'},

        ...(AuthorizationService.permissions(account, ['stock_management']) ? [
            {title: "Стратегически важные", to: "/dictionary/standards/strategic", type: 'menu'},
            {title: "Допущенные в МП", to: "/dictionary/standards/allowed", type: 'menu'}
        ] : [])
    ]

    return (
        <React.Fragment>
            <Grid container direction="column" justify="space-between" alignItems="center" className={classes.container}>
                <Grid item className={classes.fullWidth}>
                    <CustomList>
                        {list.map(option => (
                            <ListItem
                                button
                                key={option.title}
                                component={Link}
                                to={option.to}
                                classes={{root: (location.pathname === option.to) ? classes.active : undefined}}
                            >
                                <ListItemText primary={option.title}/>
                            </ListItem>
                        ))}
                    </CustomList>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
