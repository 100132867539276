import React from "react"

import {Delete as DeleteIcon} from "@material-ui/icons";
import {IconButton} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {IntegrationActions} from "../../../../../actions/integration";

export const Delete = (props) => {
    const dispatch = useDispatch()
    const {integration, setLoading} = props

    return (
        <React.Fragment>
            <IconButton
                onClick={() => {
                    return dispatch(IntegrationActions.delete(integration.id)).then(
                        () => {
                            setLoading(false)
                        }
                    )
                }}
            >
                <DeleteIcon/>
            </IconButton>
        </React.Fragment>
    )
}
