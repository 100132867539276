import {ProductsService} from "../services/products"

export const ProductsActions = {
    products,
    product
}

function products(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DISCOUNT_PRODUCTS_REQUEST"})

        ProductsService.products(params)
            .then(
                customers => {
                    dispatch({type: "DISCOUNT_PRODUCTS_SUCCESS", payload: customers})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DISCOUNT_PRODUCTS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function product(id, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CUSTOMER_REQUEST"})

        ProductsService.product(id, params)
            .then(
                customer => {
                    dispatch({type: "CUSTOMER_SUCCESS", payload: customer})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "CUSTOMER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
