import {CartService} from "../services/cart"

export const CartActions = {
    count
}

function count() {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CART_ITEMS_COUNT_REQUEST"})

        CartService.count()
            .then(
                count => {
                    dispatch({type: "CART_ITEMS_COUNT", payload: count})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "CART_ITEMS_COUNT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
