import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import {CheckboxWithLabel} from "formik-material-ui"

import {Grid, makeStyles, Typography} from "@material-ui/core"

import {Submit} from "../../../../../App/components/Button/Submit"
import {CustomerActions} from "../../../../../Company/actions/customer"
import {getValidationSchema} from "../validation/settings"

const useStyles = makeStyles(() => ({
    content: {
        "width": "100%",
        "height": "calc(100vh - 228px)"
    },
    fullWidth: {
        "width": "100%"
    },
    footer: {
        "padding": "4px !important",
        height: "70px",
        alignSelf: "flex-end"
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    inputContent: {
        height: "calc(100vh - 228px)",
        width: "100%",
    }
}))

export const Settings = (props) => {
    const dispatch = useDispatch()

    const {company, setLoading} = props
    const classes = useStyles()
    const {marketplaceSettings} = useSelector(state => state.customer)

    useEffect(() => {
        const getSettings = async () => {
            return await dispatch(CustomerActions.marketplaceSettings(company.id))
        }
        getSettings().then(() => {
        })

        return () => {
            dispatch({type: "CUSTOMER_EDIT_MARKETPLACE_SETTINGS_CLEAR"})
        }
        // eslint-disable-next-line
    }, [dispatch]);

    const changeShowSuppliers = (event, setFieldValue) => {
        const checked = event.target.checked
        setFieldValue('show_suppliers', checked)
        if (checked) {
            setFieldValue('order_from_one_supplier', false)
        }
    }

    const changeOrderFromOneSupplier = (event, setFieldValue) => {
        const checked = event.target.checked
        setFieldValue('order_from_one_supplier', checked)
        if (checked) {
            setFieldValue('show_suppliers', false)
        }
    }

    const onSubmit = (values, {setSubmitting}) => {
        return dispatch(CustomerActions.editMarketplaceSettings(company.id, values)).then(
            () => {
                setSubmitting(false)
                setLoading(false)
            },
            errors => {
                if (errors) {
                }
                setSubmitting(false)
            }
        )
    }

    const getInitValues = (marketplaceSettings) => {
        return {
            show_suppliers: marketplaceSettings.show_suppliers,
            order_from_one_supplier: marketplaceSettings.order_from_one_supplier,
        }
    }



    return marketplaceSettings ? (
        <Grid item className={classes.content}>
            <Formik
                initialValues={getInitValues(marketplaceSettings)}
                validationSchema={getValidationSchema()}
                onSubmit={onSubmit}
            >
                {({
                      dirty,
                      isSubmitting,
                      isValid,
                      setFieldValue,
                      submitForm
                  }) => (
                    <Form className={classes.fullWidth}>
                        <Grid container className={classes.inputContent} direction="row" justify="space-between"
                              alignItems="stretch">
                            <Grid item xs={12} style={{padding: "20px 30px 0"}}>
                                <Grid container className={classes.fullWidth} direction="row" justify="flex-start"
                                      alignItems="stretch">
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Маркетплейс</Typography>
                                    </Grid>
                                    <Grid item className={classes.fullWidth}>
                                        <Field
                                            component={CheckboxWithLabel}
                                            name="show_suppliers"
                                            color="primary"
                                            Label={{label: "Показать поставщиков"}}
                                            inputProps={{
                                                onChange: (event) => changeShowSuppliers(event, setFieldValue)
                                            }}
                                        />
                                        <Field
                                            component={CheckboxWithLabel}
                                            name="order_from_one_supplier"
                                            color="primary"
                                            Label={{label: "Заказ у 1 поставщика"}}
                                            inputProps={{
                                                onChange: (event) => changeOrderFromOneSupplier(event, setFieldValue)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.footer} xs={12}>
                                <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                    <Grid item className={classes.footerContent}>
                                        <Grid container className={classes.footerContainer} direction="row"
                                              justify="flex-end" alignItems="center" spacing={2}>
                                            <Grid item>
                                                <Submit
                                                    disableElevation
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={isSubmitting || !isValid || !dirty}
                                                    onClick={() => submitForm().then(() => {})}
                                                >
                                                    Сохранить
                                                </Submit>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    ) : null
}
