import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import {fieldToTextField} from "formik-material-ui"
import DateFnsUtils from "@date-io/date-fns"
import ru from "date-fns/locale/ru"
import format from "date-fns/format"
import * as Yup from "yup"

import {
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    makeStyles,
    MenuItem,
    Paper,
    Radio,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    withStyles
} from "@material-ui/core"
import {
    Add as AddIcon,
    Clear as ClearIcon,
    Edit as EditIcon,
    Help as HelpIcon,
    Remove as RemoveIcon
} from "@material-ui/icons"
import {KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'

import {Companies as Dialog} from "./Items/Companies"
import {Products} from "./Items/Products"
import {Prices} from "./Items/Prices"
import {SystemActions} from "../../App/actions/system"
import {ButtonIcon} from "../../App/components/Button/ButtonIcon"
import {getPriceName} from '../../App/helpers/name'
import {Submit} from "../../App/components/Button/Submit"
import {TextFieldWithError as CustomTextField} from "../../App/components/Inputs/TextFieldWithError"
import {DiscountsActions} from "../actions/discounts"

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important"
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
    },
    tabContent: {
        "padding": "20px 29px !important",
        "overflow-x": "hidden",
        "height": "calc(100vh - 260px)",
        "width": "100%",
    },
    column: {
        "height": "86px",
        "display": "flex",
        "align-items": "center",
        "& p": {
            display: "none",
        }
    },
    columnStatus: {
        "height": "35px",
        "display": "flex",
        "margin-top": "10px",
        "align-items": "center",
        "& p": {
            display: "none",
        }
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis",
    },
    label: {
        "font-size": "16px",
        "color": "#485868",
        "font-weight": "500",
        "line-height": "1.5rem",
        display: "block !important"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
        "position": "absolute",
        "bottom": "-81px",
        "width": "100%",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    form: {
        "position": "relative",
        "margin-bottom": "70px",
        "min-height": "400px",
    },
    select: {
        "&:focus": {
            "background-color": "#fff",
        },
        "padding-bottom": "2px",
        "padding-top": "3px",
        "height": "40px",
        "line-height": "50px",
        "font-size": "16px",
    },
    selectCondition: {
        "&:focus": {
            "background-color": "#fff",
        },
        "padding-bottom": "2px",
        "padding-top": "3px",
        "height": "40px",
        "line-height": "50px",
        "font-size": "16px",
        "min-width": "90px",
    },
    icon: {
        "& .MuiButton-endIcon": {
            "margin-left": "0",
            "margin-right": "0"
        }
    },
    minus: {
        "&:hover": {
            "background-color": "#ad3e3b",
        },
        "margin-top": "15px",
        "margin-left": "15px",
        "width": "35px",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "#fff",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-bottom": "3px solid #ad3e3b",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "#d9534f",
        "cursor": "pointer",
    },
    tooltip: {
        "background-color": "#d9534f",
        "font-size": "15px",
        "color": "#fff",
        "padding": "15px 15px",
    },
    editButton: {
        "display": "flex",
        "align-items": "flex-end",
    },
    withoutError: {
        "& p": {
            "display": "none",
        },
        maxWidth: "155px",
        display: "flex",
        alignItems: "flex-end",
    },
    itemsNames: {
        "border-bottom": "2px dotted #c1c1c1",
        "min-height": "26px",
        "max-height": "26px",
        "max-width": "444px",
        "overflow": "hidden",
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        "padding-top": "21px",
    },
}))

const GreenRadio = withStyles({
    root: {
        color: "#898989",
        '&$checked': {
            color: "#898989",
        },
    },
    checked: {},
})((props) => <FormControlLabel control={<Radio {...props}/>} value={props.value} label={props.label}/>);

export const AddContent = () => {
    const query = new URLSearchParams(window.location.search)
    const mode = query.has('id') ? 'edit' : 'create'
    const discountId = query.get('id')
    const dispatch = useDispatch()
    const classes = useStyles()
    const {account} = useSelector(state => state.account)
    const {discount} = useSelector(state => state.discount)
    const [countRow, setRow] = useState(0)
    const {categories} = useSelector(state => state.system)
    const [customers, setCustomers] = useState([])
    const [dialog, setDialog] = useState(false)

    const [products, setProducts] = useState([])
    const [dialogProducts, setDialogProducts] = useState(false)

    const [prices, setPrices] = useState([])
    const [dialogPrices, setDialogPrices] = useState(false)

    const addRow = (values) => {
        if (values.conditions && values.conditions.length > 0) {
            values.conditions = [...values.conditions, {
                condition: 'price',
                value: '',
                sign: '>=',
            }];
        } else {
            values.conditions = [{
                condition: 'price',
                value: '',
                sign: '>=',
            }];
        }

        setRow(countRow + 1)
    }
    const deleteRow = (values) => {
        if (countRow > 0) {
            values.conditions = values.conditions.filter((el, idx) => idx !== countRow - 1)
            setRow(countRow - 1)
        }
    }

    const typeNodes = [
        {key: 1, name: 'скидка, %', value: 'discount'},
        {key: 2, name: 'надбавка, %', value: 'markup'},
        {key: 3, name: 'бесплатная доставка', value: 'delivery'},
    ].map((obj) =>
        <MenuItem value={obj.value}>{obj.name}</MenuItem>
    )

    const columns = [
        {
            id: '0',
            key: 'condition',
            label: 'Условия',
            format: (value) => value.toLocaleString()
        },
        {
            id: '1',
            key: 'items',
            label: 'Объекты',
            format: (value) => value.toLocaleString()
        },
        {
            id: '2',
            key: 'type',
            label: 'Тип',
            format: (value) => value.toLocaleString()
        },
        {
            id: '3',
            key: 'calculation',
            label: 'Расчет',
            format: (value) => value.toLocaleString()
        }
    ]

    useEffect(() => {
        const getDiscount = async () => {
            return await dispatch(DiscountsActions.discount(account.company.id, discountId))
        }

        if (mode === 'edit' && !discount) {
            getDiscount().then(async (r) => {
                let items = JSON.parse(r.items)
                let conditions = JSON.parse(r.conditions)
                await setCustomers(items.customers);
                await setProducts(items.products);
                await setPrices(items.prices);
                await setRow(conditions && conditions.length ? conditions.length : 0);
            })
        }
        if (mode === 'edit' && discount) {
            return () => {
                dispatch({type: 'DISCOUNT_CLEAR'})
            }
        }
    }, [dispatch, mode, discount, account.company.id, discountId]);

    useEffect(() => {
        const getCategories = async () => {
            return await dispatch(SystemActions.categories())
        }

        if (!categories.length) {
            getCategories().then(() => {
            })
        }
    }, [dispatch, categories.length]);

    const [showError, setShowError] = useState(false)
    let validateSchema = {
        name: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
        datetime_start: Yup.mixed().test('min-date', 'Некорректная дата', function (value) {
            let currentDate = new Date(value);
            let minDate = new Date().setDate(new Date().getDate())

            return currentDate.valueOf() - minDate.valueOf() >= 0;
        }).required("Поле не может быть пустым или состоять из одних пробелов!"),
        active: Yup.mixed().required(`Поле не может быть пустым!`),
        datetime_end: Yup.mixed()
            .when("without_end", {
                is: false,
                then: Yup.mixed().test('min-date', 'Некорректная дата', function (value) {
                    let currentDate = new Date(value);
                    let minDate = this.parent.datetime_start ? new Date(this.parent.datetime_start).setDate(this.parent.datetime_start.getDate()) : new Date().setDate(new Date().getDate()) + 1

                    return currentDate.valueOf() - minDate.valueOf() >= 0;
                }).required("Поле не может быть пустым или состоять из одних пробелов!"),
                otherwise: Yup.mixed()
            }),
        without_end: Yup.mixed()
            .required(`Выберете "Бессрочно" или заполните поле "Дата и время окончания действия"!`),
        items: Yup.object().shape({
            prices: Yup.array(),
            items: Yup.array(),
            customers: Yup.array(),
        }).required(),
        conditions: Yup
            .array().of(Yup.object().shape({
                sign: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                value: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                condition: Yup.string()
            })),
        type: Yup.mixed().required(),
        value: Yup.mixed()
            .when("type", {
                is: 'delivery',
                then: Yup.mixed(),
                otherwise: Yup.mixed().required(`Заполните поле!`)
            }),
        calculation: Yup.mixed().required(),
    }
    const signs = [
        {key: 1, name: '>=', value: '>='},
        {key: 2, name: '>', value: '>'},
        {key: 3, name: '=', value: '='},
        {key: 4, name: '<', value: '<'},
        {key: 5, name: '<=', value: '<='},
    ]

    const conditionNodes = [
        {key: 1, name: 'цена', value: 'price'},
        {key: 2, name: 'объем', value: 'count'},
        {key: 3, name: 'срок годности', value: 'expiration_date'},
        {key: 4, name: 'срок поставки', value: 'delivery_time'},
        {key: 5, name: 'отсрочка платежа', value: 'payment_deferment'},
        {key: 6, name: 'кратно заводской упаковке', value: 'original_packaging'},
        {key: 7, name: 'без отсрочки', value: 'without_postponement'}
    ].map((obj) =>
        <MenuItem value={obj.value}>{obj.name}</MenuItem>
    )

    const getWidth = (index) => {
        switch (index) {
            case 0:
                return '30%'
            case 1:
                return '37%'
            case 2:
                return '20%'
            default:
                return '13%'
        }
    }

    return (((mode === 'edit') && discount) || (mode === 'create')) ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.fullWidth}>
                        <Grid container className={classes.fullWidth} direction="column" justify="space-between"
                              alignItems="stretch">
                            <Grid item className={classes.fullWidth}>
                                <Grid container className={classes.fullWidth} direction="column"
                                      justify="space-between" alignItems="stretch">
                                    <Grid item className={classes.header}>
                                        <Grid container className={classes.fullWidth} direction="column"
                                              justify="flex-start" alignItems="stretch">
                                            <Grid item className={classes.fullWidth}>
                                                <Typography className={classes.title}>
                                                    {mode === 'edit' ? 'Редактировать' : 'Создать'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Formik
                                    validateOnBlur={false}
                                    initialValues={{
                                        name: (discount && discount.name) ? discount.name : '',
                                        active: discount ? discount.active : true,
                                        conditions: (discount && discount.conditions) ? JSON.parse(discount.conditions) : [],
                                        company_id: account.company.id,
                                        datetime_start: (discount && discount.datetime_start) ? new Date(discount.datetime_start) : null,
                                        datetime_end: (discount && discount.datetime_end) ? new Date(discount.datetime_end) : null,
                                        without_end: discount ? discount.without_end : false,
                                        items: {
                                            customers: (discount && discount.items) ? JSON.parse(discount.items).customers : [],
                                            prices: (discount && discount.items) ? JSON.parse(discount.items).prices : [],
                                            products: (discount && discount.items) ? JSON.parse(discount.items).products : [],
                                        },
                                        type: (discount && discount.type) ? discount.type : 'discount',
                                        value: (discount && discount.value) ? discount.value : '',
                                        calculation: (discount && discount.calculation) ? discount.calculation : '',
                                    }}
                                    validationSchema={Yup.object().shape(validateSchema)}
                                    onSubmit={(values, {setSubmitting}) => {
                                        return dispatch(mode === 'edit' ? DiscountsActions.edit(discount.id, values) : DiscountsActions.add(values)).then(() => {
                                            setSubmitting(false);
                                        }, errors => {
                                            if (errors) {
                                            }
                                            setSubmitting(false);
                                        })
                                    }}
                                >
                                    {({
                                          values,
                                          errors,
                                          isSubmitting,
                                          setFieldValue,
                                          submitForm,
                                          setFieldTouched,
                                          validateForm
                                      }) => (
                                        <Form className={classes.form}>
                                            <Grid item>
                                                <Grid className={classes.tab} container direction="column"
                                                      justify="space-between" alignItems="stretch"
                                                      style={{overflowX: "hidden"}}>
                                                    <Grid item className={classes.tabContent}>
                                                        <Grid container direction="row" justify="flex-start"
                                                              alignItems="flex-start" spacing={5}>
                                                            <Grid item xs={12} className={classes.columnStatus}>
                                                                <Grid container direction="row" justify="flex-start"
                                                                      alignItems="flex-start">
                                                                    <Grid item xs={1}
                                                                          className={classes.columnStatus}>
                                                                        <Typography
                                                                            className={classes.label}>Статус</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2}
                                                                          className={classes.columnStatus}>
                                                                        <Field
                                                                            fullWidth
                                                                            name="name"
                                                                            type="text"
                                                                            step={1}
                                                                            label="Активна"
                                                                            inputprops={{
                                                                                min: 0
                                                                            }}
                                                                            component={({
                                                                                            form: {setFieldValue},
                                                                                            label
                                                                                        }) => (
                                                                                <GreenRadio
                                                                                    checked={values.active}
                                                                                    onChange={() => {
                                                                                        setFieldValue('active', true)
                                                                                    }}
                                                                                    value={true}
                                                                                    name={'active'}
                                                                                    label={label}
                                                                                    inputProps={{label: label}}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}
                                                                          className={classes.columnStatus}>
                                                                        <Field
                                                                            fullWidth
                                                                            name="name"
                                                                            type="text"
                                                                            label="Не активна"
                                                                            step={1}
                                                                            inputprops={{
                                                                                min: 0
                                                                            }}
                                                                            component={({
                                                                                            form: {setFieldValue},
                                                                                            label
                                                                                        }) => (
                                                                                <GreenRadio
                                                                                    checked={!values.active}
                                                                                    onChange={() => {
                                                                                        setFieldValue('active', false)
                                                                                    }}
                                                                                    value={false}
                                                                                    name={'not_active'}
                                                                                    label={label}
                                                                                    inputProps={{label: label}}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={10} className={classes.column}>
                                                                <Field
                                                                    fullWidth
                                                                    name="name"
                                                                    type="text"
                                                                    label="Название"
                                                                    step={1}
                                                                    inputProps={{
                                                                        min: 0
                                                                    }}
                                                                    component={CustomTextField}
                                                                />
                                                                {errors.name && showError &&
                                                                    <Tooltip
                                                                        title={errors.name}
                                                                        placement="right"
                                                                        classes={{tooltip: classes.tooltip}}
                                                                    >
                                                                        <IconButton
                                                                            style={{
                                                                                "width": "24px",
                                                                                "height": "24px",
                                                                                "padding": "0"
                                                                            }}
                                                                        >
                                                                            <HelpIcon
                                                                                style={{"color": "#d9534f",}}/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                }
                                                            </Grid>
                                                            <Grid item xs={12} className={classes.column}>
                                                                <Grid container spacing={8}>
                                                                    <Grid item xs={4} className={classes.column}>
                                                                        <MuiPickersUtilsProvider
                                                                            utils={DateFnsUtils} locale={ru}>
                                                                            <KeyboardDateTimePicker
                                                                                disableToolbar
                                                                                fullWidth
                                                                                error={Boolean(errors.hasOwnProperty('datetime_start'))}
                                                                                helperText={errors.hasOwnProperty('datetime_start') ? errors.datetime_start : null}
                                                                                name="datetime_start"
                                                                                required
                                                                                format="HH:mm dd-MM-yyyy"
                                                                                variant="inline"
                                                                                openTo="date"
                                                                                ampm={false}
                                                                                minDate={new Date().setDate(new Date().getDate())}
                                                                                initialFocusedDate={new Date().setDate(new Date().getDate())}
                                                                                minDateMessage="Некорректная дата"
                                                                                label="Дата и время начала действия"
                                                                                autoOk
                                                                                disablePast
                                                                                value={values.datetime_start}
                                                                                onChange={date => {
                                                                                    setFieldValue("datetime_start", date);
                                                                                }}
                                                                                onClose={() => {
                                                                                    setFieldTouched("datetime_start", true, false)
                                                                                    validateForm().then()
                                                                                }}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                        {errors.datetime_start && showError &&
                                                                            <Tooltip title={errors.datetime_start}
                                                                                     placement="right"
                                                                                     classes={{tooltip: classes.tooltip}}>
                                                                                <IconButton
                                                                                    style={{
                                                                                        "width": "24px",
                                                                                        "height": "24px",
                                                                                        "padding": "0"
                                                                                    }}
                                                                                >
                                                                                    <HelpIcon
                                                                                        style={{"color": "#d9534f",}}/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={2} className={classes.column}>
                                                                        <Field
                                                                            component={({
                                                                                            name,
                                                                                            form: {
                                                                                                setFieldValue,
                                                                                                validateForm
                                                                                            },
                                                                                            field: {value}
                                                                                        }) => {
                                                                                return (
                                                                                    <FormControlLabel
                                                                                        control={
                                                                                            <Checkbox
                                                                                                name={name}
                                                                                                checked={value}
                                                                                                onChange={async () => {
                                                                                                    await setFieldValue(`without_end`, !value)
                                                                                                    setFieldValue(`datetime_end`, null)
                                                                                                    validateForm().then()
                                                                                                }}
                                                                                            />
                                                                                        }
                                                                                        value={value}
                                                                                        label="Бессрочно"
                                                                                    />
                                                                                );
                                                                            }}
                                                                            name="without_end"
                                                                            color="primary"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4} className={classes.column}>
                                                                        <MuiPickersUtilsProvider
                                                                            utils={DateFnsUtils} locale={ru}>
                                                                            <KeyboardDateTimePicker
                                                                                disableToolbar
                                                                                fullWidth
                                                                                error={Boolean(errors.hasOwnProperty('datetime_end'))}
                                                                                helperText={errors.hasOwnProperty('datetime_end') ? errors.datetime_end : null}
                                                                                name="datetime_end"
                                                                                required
                                                                                disabled={!!values.without_end}
                                                                                format="HH:mm dd-MM-yyyy"
                                                                                variant="inline"
                                                                                openTo="date"
                                                                                ampm={false}
                                                                                minDate={new Date().setDate(new Date().getDate())}
                                                                                initialFocusedDate={new Date().setDate(new Date().getDate())}
                                                                                minDateMessage="Некорректная дата"
                                                                                invalidDateMessage="Некорректная дата"
                                                                                label="Дата и время окончания действия"
                                                                                autoOk
                                                                                disablePast
                                                                                value={values.datetime_end}
                                                                                onChange={date => {
                                                                                    setFieldValue("datetime_end", date);
                                                                                }}
                                                                                onClose={() => {
                                                                                    setFieldTouched("datetime_end", true, false)
                                                                                    validateForm().then()
                                                                                }}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                        {errors.datetime_end && showError &&
                                                                            <Tooltip title={errors.datetime_end}
                                                                                     placement="right"
                                                                                     classes={{tooltip: classes.tooltip}}>
                                                                                <IconButton
                                                                                    style={{
                                                                                        "width": "24px",
                                                                                        "height": "24px",
                                                                                        "padding": "0"
                                                                                    }}
                                                                                >
                                                                                    <HelpIcon
                                                                                        style={{"color": "#d9534f"}}/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TableContainer className={classes.tableContainer}>
                                                                    <Table stickyHeader aria-label="sticky table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                {columns.map((column) => (
                                                                                    <TableCell key={column.id}>
                                                                                        {column.label}
                                                                                    </TableCell>
                                                                                ))}
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            <TableRow>
                                                                                {columns.map((column, index) => (
                                                                                    <TableCell key={column.id}
                                                                                               style={{
                                                                                                   width: getWidth(index),
                                                                                                   verticalAlign: "top"
                                                                                               }}>
                                                                                        {(column.key === 'type') &&
                                                                                            <React.Fragment>
                                                                                                <Grid container
                                                                                                      direction="row"
                                                                                                      justify="flex-start"
                                                                                                      alignItems="center"
                                                                                                      spacing={2}>
                                                                                                    <Grid item
                                                                                                          xs={4}
                                                                                                          className={classes.withoutError}>
                                                                                                        <Field
                                                                                                            name="value"
                                                                                                            type="text"
                                                                                                            label="Значение"
                                                                                                            step={1}
                                                                                                            disabled={(values.type === 'delivery')}
                                                                                                            inputProps={{
                                                                                                                min: 0
                                                                                                            }}
                                                                                                            component={CustomTextField}
                                                                                                        />
                                                                                                    </Grid>
                                                                                                    <Grid item
                                                                                                          xs={7}>
                                                                                                        <Field
                                                                                                            name="type"
                                                                                                            key="type"
                                                                                                            type="number"
                                                                                                            label="Тип"
                                                                                                            labelId="type"
                                                                                                            inputProps={{
                                                                                                                min: 0
                                                                                                            }}
                                                                                                            style={{
                                                                                                                width: "120px",
                                                                                                                marginTop: "0"
                                                                                                            }}
                                                                                                            component={({
                                                                                                                            textFieldProps,
                                                                                                                            ...props
                                                                                                                        }) => {
                                                                                                                const {labelId} = props;
                                                                                                                const {
                                                                                                                    label,
                                                                                                                    helperText,
                                                                                                                    error,
                                                                                                                    ...field
                                                                                                                } = fieldToTextField(props);
                                                                                                                const {name} = field;
                                                                                                                return (
                                                                                                                    <Select
                                                                                                                        labelId={labelId}
                                                                                                                        id={name}
                                                                                                                        classes={{
                                                                                                                            select: classes.select
                                                                                                                        }}
                                                                                                                        name={name}
                                                                                                                        value={values.type ? values.type : 1}
                                                                                                                        onChange={async (e) => {
                                                                                                                            await setFieldValue(`type`, e.target.value)
                                                                                                                            if (e.target.value === 'delivery') {
                                                                                                                                setFieldValue(`value`, '')
                                                                                                                            }
                                                                                                                        }}
                                                                                                                        children={typeNodes}
                                                                                                                    />)
                                                                                                            }
                                                                                                            }
                                                                                                        />
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </React.Fragment>
                                                                                        }
                                                                                        {(column.key === 'condition') &&
                                                                                            <React.Fragment>
                                                                                                {Array.from(Array(countRow).keys()).map((option, index) => (
                                                                                                    <Grid
                                                                                                        key={index}
                                                                                                        container
                                                                                                        direction="row"
                                                                                                        justify="flex-start"
                                                                                                        alignItems="flex-end"
                                                                                                        spacing={2}>
                                                                                                        <Grid item>
                                                                                                            <Field
                                                                                                                name={`conditions.${index}.condition`}
                                                                                                                key="type"
                                                                                                                type="number"
                                                                                                                label="Условие"
                                                                                                                labelId={`conditions.${index}.condition`}
                                                                                                                inputProps={{
                                                                                                                    min: 0
                                                                                                                }}
                                                                                                                style={{
                                                                                                                    width: "120px",
                                                                                                                    marginTop: "0"
                                                                                                                }}
                                                                                                                component={({
                                                                                                                                textFieldProps,
                                                                                                                                ...props
                                                                                                                            }) => {
                                                                                                                    const {labelId} = props;
                                                                                                                    const {
                                                                                                                        label,
                                                                                                                        helperText,
                                                                                                                        error,
                                                                                                                        ...field
                                                                                                                    } = fieldToTextField(props);
                                                                                                                    const {name} = field;
                                                                                                                    return (
                                                                                                                        <Select
                                                                                                                            labelId={labelId}
                                                                                                                            id={name}
                                                                                                                            classes={{
                                                                                                                                select: classes.selectCondition
                                                                                                                            }}
                                                                                                                            name={name}
                                                                                                                            value={values.conditions && values.conditions[index] ? values.conditions[index].condition : 'price'}
                                                                                                                            onChange={(e) => {
                                                                                                                                setFieldValue(`conditions.${index}.condition`, e.target.value)
                                                                                                                                if ((e.target.value === 'without_postponement') || (e.target.value === 'original_packaging')) {
                                                                                                                                    setFieldValue(`conditions.${index}.value`, 0)
                                                                                                                                }
                                                                                                                            }}
                                                                                                                            children={conditionNodes}
                                                                                                                        />)
                                                                                                                }}
                                                                                                            />
                                                                                                        </Grid>
                                                                                                        <Grid item>
                                                                                                            {(values.conditions && ((values.conditions[index].condition !== "without_postponement") && (values.conditions[index].condition !== "original_packaging"))) &&
                                                                                                                <Field
                                                                                                                    name={`conditions.${index}.sign`}
                                                                                                                    key={`conditions.${index}.sign`}
                                                                                                                    type="number"
                                                                                                                    label="Знак"
                                                                                                                    labelId={`conditions.${index}.sign`}
                                                                                                                    inputProps={{
                                                                                                                        min: 0
                                                                                                                    }}
                                                                                                                    style={{
                                                                                                                        width: "120px",
                                                                                                                        marginTop: "0"
                                                                                                                    }}
                                                                                                                    component={({
                                                                                                                                    textFieldProps,
                                                                                                                                    ...props
                                                                                                                                }) => {
                                                                                                                        const {labelId} = props;
                                                                                                                        const {
                                                                                                                            label,
                                                                                                                            helperText,
                                                                                                                            error,
                                                                                                                            ...field
                                                                                                                        } = fieldToTextField(props);
                                                                                                                        const {name} = field;
                                                                                                                        return (
                                                                                                                            <Select
                                                                                                                                labelId={labelId}
                                                                                                                                id={name}
                                                                                                                                classes={{
                                                                                                                                    select: classes.select
                                                                                                                                }}
                                                                                                                                name={name}
                                                                                                                                value={values.conditions && values.conditions[index] ? values.conditions[index].sign : '>='}
                                                                                                                                style={{
                                                                                                                                    fontFamily: "sans-serif",
                                                                                                                                    minWidth: "19px"
                                                                                                                                }}
                                                                                                                                onChange={(e) => {
                                                                                                                                    setFieldValue(`conditions.${index}.sign`, e.target.value)
                                                                                                                                }}
                                                                                                                                children={signs.map((obj) =>
                                                                                                                                    <MenuItem
                                                                                                                                        value={obj.value}>{obj.name}</MenuItem>
                                                                                                                                )}
                                                                                                                            />)
                                                                                                                    }}
                                                                                                                />
                                                                                                            }
                                                                                                        </Grid>
                                                                                                        {(values.conditions && ((values.conditions[index].condition !== "without_postponement") && (values.conditions[index].condition !== "original_packaging"))) &&
                                                                                                            <Grid
                                                                                                                item
                                                                                                                className={classes.withoutError}>
                                                                                                                {(values.conditions[index].condition === "expiration_date") ?
                                                                                                                    <MuiPickersUtilsProvider
                                                                                                                        utils={DateFnsUtils}
                                                                                                                        locale={ru}>
                                                                                                                        <KeyboardDatePicker
                                                                                                                            disableToolbar
                                                                                                                            fullWidth
                                                                                                                            name={`conditions.${index}.value`}
                                                                                                                            format="dd-MM-yyyy"
                                                                                                                            orientation="landscape"
                                                                                                                            variant="inline"
                                                                                                                            openTo="date"
                                                                                                                            autoOk
                                                                                                                            value={values.conditions[index].value}
                                                                                                                            onChange={date => {
                                                                                                                                setFieldValue(`conditions.${index}.value`, format(date, 'yyyy-MM-dd'));
                                                                                                                            }}
                                                                                                                            onBlur={() => {
                                                                                                                                setFieldTouched(`conditions.${index}.value`, true, true)
                                                                                                                            }}
                                                                                                                            onClose={() => {
                                                                                                                                setFieldTouched(`conditions.${index}.value`, true, true)
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </MuiPickersUtilsProvider> :
                                                                                                                    <Field
                                                                                                                        name={`conditions.${index}.value`}
                                                                                                                        type="text"
                                                                                                                        label=""
                                                                                                                        key={`conditions.${index}`}
                                                                                                                        step={1}
                                                                                                                        inputProps={{
                                                                                                                            min: 0
                                                                                                                        }}
                                                                                                                        component={CustomTextField}
                                                                                                                    />
                                                                                                                }
                                                                                                                {(errors.conditions && errors.conditions[index] && errors.conditions[index].value && showError) &&
                                                                                                                    <Tooltip
                                                                                                                        title={errors.conditions[index].value}
                                                                                                                        placement="right"
                                                                                                                        classes={{tooltip: classes.tooltip}}
                                                                                                                    >
                                                                                                                        <IconButton
                                                                                                                            style={{
                                                                                                                                "width": "24px",
                                                                                                                                "height": "24px",
                                                                                                                                "padding": "0"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <HelpIcon
                                                                                                                                style={{"color": "#d9534f",}}/>
                                                                                                                        </IconButton>
                                                                                                                    </Tooltip>
                                                                                                                }
                                                                                                            </Grid>
                                                                                                        }
                                                                                                    </Grid>
                                                                                                ))}
                                                                                                <Grid container
                                                                                                      direction="row">
                                                                                                    <ButtonIcon
                                                                                                        onClick={() => addRow(values)}
                                                                                                        key={'addRow'}
                                                                                                        children={[(
                                                                                                            <AddIcon
                                                                                                                key={'AddIcon'}
                                                                                                                className={classes.svg}/>)]}
                                                                                                    />
                                                                                                    <ButtonIcon
                                                                                                        className={classes.minus}
                                                                                                        key={'minus'}
                                                                                                        onClick={() => deleteRow(values)}
                                                                                                        children={[(
                                                                                                            <RemoveIcon
                                                                                                                className={classes.svg}
                                                                                                                key={'RemoveIcon'}/>)]}
                                                                                                    />
                                                                                                </Grid>
                                                                                            </React.Fragment>
                                                                                        }
                                                                                        {(column.key === 'items') &&
                                                                                            <React.Fragment>
                                                                                                <Grid container
                                                                                                      direction="row"
                                                                                                      justify="flex-start"
                                                                                                      alignItems="stretch"
                                                                                                      spacing={2}>
                                                                                                    <Grid item
                                                                                                          xs={8}>
                                                                                                        <Typography
                                                                                                            className={classes.itemsNames}>{customers.length ? customers.map(customer => customer.legal_detail.name).join(', ') : 'Заказчики'}</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item
                                                                                                          xs={2}
                                                                                                          className={classes.editButton}>
                                                                                                        <Submit
                                                                                                            variant="contained"
                                                                                                            component="label"
                                                                                                            color="primary"
                                                                                                            className={classes.icon}
                                                                                                            endIcon={
                                                                                                                <EditIcon/>}
                                                                                                            onClick={() => {
                                                                                                                setDialog('customers')
                                                                                                            }}
                                                                                                        />
                                                                                                    </Grid>
                                                                                                    <Grid item
                                                                                                          xs={2}
                                                                                                          className={classes.editButton}>
                                                                                                        <Submit
                                                                                                            variant="contained"
                                                                                                            component="label"
                                                                                                            color="secondary"
                                                                                                            className={classes.icon}
                                                                                                            endIcon={
                                                                                                                <ClearIcon/>}
                                                                                                            disabled={!customers.length}
                                                                                                            onClick={() => {
                                                                                                                setCustomers([])
                                                                                                                setFieldValue(`items.customers`, [])
                                                                                                            }}
                                                                                                        />
                                                                                                    </Grid>
                                                                                                    <Grid item
                                                                                                          xs={8}>
                                                                                                        <Typography
                                                                                                            className={classes.itemsNames}>{products.length ? products.map(product => product.name).join(', ') : 'Товары'}</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item
                                                                                                          xs={2}
                                                                                                          className={classes.editButton}>
                                                                                                        <Submit
                                                                                                            variant="contained"
                                                                                                            component="label"
                                                                                                            color="primary"
                                                                                                            className={classes.icon}
                                                                                                            endIcon={
                                                                                                                <EditIcon/>}
                                                                                                            onClick={() => {
                                                                                                                setDialogProducts('products')
                                                                                                            }}
                                                                                                        />
                                                                                                    </Grid>
                                                                                                    <Grid item
                                                                                                          xs={2}
                                                                                                          className={classes.editButton}>
                                                                                                        <Submit
                                                                                                            variant="contained"
                                                                                                            component="label"
                                                                                                            color="secondary"
                                                                                                            className={classes.icon}
                                                                                                            endIcon={
                                                                                                                <ClearIcon/>}
                                                                                                            disabled={!products.length}
                                                                                                            onClick={() => {
                                                                                                                setProducts([])
                                                                                                                setFieldValue(`items.products`, [])
                                                                                                            }}
                                                                                                        />
                                                                                                    </Grid>
                                                                                                    <Grid item
                                                                                                          xs={8}>
                                                                                                        <Typography
                                                                                                            className={classes.itemsNames}>{prices.length ? prices.map(price => getPriceName(price)).join(', ') : 'Прайс-листы'}</Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item
                                                                                                          xs={2}
                                                                                                          className={classes.editButton}>
                                                                                                        <Submit
                                                                                                            variant="contained"
                                                                                                            component="label"
                                                                                                            color="primary"
                                                                                                            className={classes.icon}
                                                                                                            endIcon={
                                                                                                                <EditIcon/>}
                                                                                                            onClick={() => {
                                                                                                                setDialogPrices('prices')
                                                                                                            }}
                                                                                                        />
                                                                                                    </Grid>
                                                                                                    <Grid item
                                                                                                          xs={2}
                                                                                                          className={classes.editButton}>
                                                                                                        <Submit
                                                                                                            variant="contained"
                                                                                                            component="label"
                                                                                                            color="secondary"
                                                                                                            className={classes.icon}
                                                                                                            endIcon={
                                                                                                                <ClearIcon/>}
                                                                                                            disabled={!prices.length}
                                                                                                            onClick={() => {
                                                                                                                setPrices([])
                                                                                                                setFieldValue(`items.prices`, [])
                                                                                                            }}
                                                                                                        />
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </React.Fragment>
                                                                                        }
                                                                                        {(column.key === 'calculation') &&
                                                                                            <React.Fragment>
                                                                                                <Grid container
                                                                                                      direction="row"
                                                                                                      justify="flex-start"
                                                                                                      alignItems="center"
                                                                                                      spacing={2}>
                                                                                                    <Grid item
                                                                                                          xs={8}>
                                                                                                        <Field
                                                                                                            fullWidth
                                                                                                            name="calculation"
                                                                                                            type="number"
                                                                                                            label="Расчет"
                                                                                                            style={{
                                                                                                                width: "120px",
                                                                                                                marginTop: "0"
                                                                                                            }}
                                                                                                            component={({
                                                                                                                            textFieldProps,
                                                                                                                            ...props
                                                                                                                        }) => {
                                                                                                                const {
                                                                                                                    label,
                                                                                                                    helperText,
                                                                                                                    error,
                                                                                                                    ...field
                                                                                                                } = fieldToTextField(props);
                                                                                                                const {name} = field;
                                                                                                                return (
                                                                                                                    <Select
                                                                                                                        fullWidth
                                                                                                                        classes={{
                                                                                                                            select: classes.select
                                                                                                                        }}
                                                                                                                        name={name}
                                                                                                                        value={values.calculation}
                                                                                                                        onChange={async (e) => {
                                                                                                                            await setFieldValue(`calculation`, e.target.value)
                                                                                                                        }}
                                                                                                                        children={[
                                                                                                                            {
                                                                                                                                key: 1,
                                                                                                                                name: 'Нетто',
                                                                                                                                value: 'net'
                                                                                                                            },
                                                                                                                            {
                                                                                                                                key: 2,
                                                                                                                                name: 'Брутто',
                                                                                                                                value: 'gross'
                                                                                                                            },
                                                                                                                        ].map((obj) =>
                                                                                                                            <MenuItem
                                                                                                                                value={obj.value}>{obj.name}</MenuItem>
                                                                                                                        )}
                                                                                                                    />)
                                                                                                            }}
                                                                                                        />
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </React.Fragment>
                                                                                        }
                                                                                    </TableCell>
                                                                                ))}
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.footer}>
                                                <Grid container direction="column" justify="center"
                                                      alignItems="stretch" spacing={1}>
                                                    <Grid item className={classes.footerContent}>
                                                        <Grid container className={classes.footerContainer}
                                                              direction="row" justify="flex-end" alignItems="center"
                                                              spacing={2}>
                                                            <Grid item>
                                                                <Submit
                                                                    disableElevation
                                                                    variant="contained"
                                                                    color="primary"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        setShowError(true)
                                                                        submitForm().then(() => {
                                                                        })
                                                                    }}
                                                                    disabled={isSubmitting}>
                                                                    {mode === 'edit' ? 'Сохранить' : 'Создать'}
                                                                </Submit>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Dialog
                                                open={dialog}
                                                setDialog={setDialog}
                                                setCustomers={(customers) => {
                                                    setCustomers(customers)
                                                    setFieldValue(`items.customers`, customers)
                                                }}
                                                customers={customers}
                                            />
                                            <Products
                                                open={dialogProducts}
                                                setDialog={setDialogProducts}
                                                setProducts={(products) => {
                                                    setProducts(products)
                                                    setFieldValue(`items.products`, products)
                                                }}
                                                categories={categories}
                                                products={products}
                                            />
                                            <Prices
                                                open={dialogPrices}
                                                setDialog={setDialogPrices}
                                                setPrices={(prices) => {
                                                    setPrices(prices)
                                                    setFieldValue(`items.prices`, prices)
                                                }}
                                                prices={prices}
                                            />
                                        </Form>
                                    )}
                                </Formik>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    ) : null
}
