import {SettingsService} from "../services/settings"

export const SettingsActions = {
    order,
    updateOrder,
    table,
    tableUpdate,
    marketplace,
    setMarketplace,
    getSettings,
    setSettings
}

function order(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SETTINGS_REQUEST"})

        SettingsService.order(params)
            .then(
                settings => {
                    dispatch({type: "SETTINGS", payload: settings})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "SETTINGS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function updateOrder(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SETTINGS_UPDATE_REQUEST"})

        SettingsService.updateOrder(params)
            .then(
                settings => {
                    dispatch({type: "SETTINGS_UPDATE", payload: settings})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "SETTINGS_UPDATE_FAILURE"})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function table(name) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SETTINGS_TABLE_REQUEST"})

        SettingsService.table(name)
            .then(
                settings => {
                    dispatch({type: "SETTINGS_TABLE", payload: settings})
                    dispatch({type: "LOADING", payload: false})
                    resolve(settings)
                },
                error => {
                    dispatch({type: "SETTINGS_TABLE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function tableUpdate(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SETTINGS_TABLE_UPDATE_REQUEST"})

        SettingsService.tableUpdate(params)
            .then(
                () => {
                    dispatch({type: "SETTINGS_TABLE_UPDATE"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "SETTINGS_TABLE_UPDATE_FAILURE"})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function marketplace() {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SETTINGS_MARKETPLACE_REQUEST"})
        SettingsService.marketplace()
            .then(
                settings => {
                    dispatch({type: "SETTINGS_MARKETPLACE_SUCCESS", payload: settings})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "SETTINGS_MARKETPLACE_FAILURE"})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function setMarketplace(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SETTINGS_SET_MARKETPLACE_REQUEST"})
        SettingsService.setMarketplace(values)
            .then(
                settings => {
                    dispatch({type: "SETTINGS_SET_MARKETPLACE_SUCCESS", payload: settings})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "SETTINGS_SET_MARKETPLACE_FAILURE"})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function getSettings(name) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        SettingsService.getSettings(name)
            .then(
                settings => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(settings)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function setSettings(name, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        SettingsService.setSettings(name, values)
            .then(
                settings => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}
