import {ReportService} from "../services/report"

export const ReportActions = {
    reports,
    add,
    file
}

function reports(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "REPORTS_REQUEST"})

        ReportService.reports(params)
            .then(
                response => {
                    dispatch({type: "REPORTS", payload: {reports: {...{data: response.data, meta: response.meta}}, ...{beginning: params.beginning, ending: params.ending}}})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "REPORTS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function add(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "REPORT_ADD_REQUEST"})

        ReportService.add(params)
            .then(
                response => {
                    dispatch({type: "REPORT_ADD_SUCCESS", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "REPORT_ADD_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function file(report) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "REPORT_FILE_REQUEST"})

        ReportService.file(report.id)
            .then(
                data => {
                    if (data instanceof Blob) {
                        dispatch({type: "REPORT_FILE_SUCCESS", payload: {id: report.id, file: data, formats: report.formed ? report.formats : ['pdf']}})
                        dispatch({type: "REPORT_FORMED", payload: report.id})
                    } else {
                        dispatch({type: "REPORT_FILE_FAILURE"})
                        dispatch({type: "REPORT_FILE_CLEAR"})
                    }
                    dispatch({type: "LOADING", payload: false})
                    resolve(data)
                },
                error => {
                    dispatch({type: "REPORT_FILE_FAILURE"})
                    dispatch({type: "REPORT_FILE_CLEAR"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
