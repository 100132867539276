export default function company(state = {companies: {data: [], meta: {total: 0, per_page: 15, current_page: 1}}}, action) {
    switch (action.type) {
        case "REPORT_COMPANIES_REQUEST":
            return {
                companies: state.companies
            }
        case "REPORT_COMPANIES":
            return {
                companies: action.payload,
            }
        case "REPORT_COMPANIES_FAILURE":
            return {
                companies: state.companies
            }
        default:
            return state
    }
}
