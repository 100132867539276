import React from "react"

import {Grid, makeStyles, Paper, styled} from "@material-ui/core"

const useStyles = makeStyles(() => ({
    title: {
        "margin-top": "-24px",
        "padding-left": "24px !important"
    },
    block: {
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "400",
        "font-size": "1rem",
        "background": "white"
    }
}));

const GroupComponent = styled(Paper)({
    "border": "2px solid #c1c1c1 !important",
    "padding": "16px",
    "box-shadow": "none !important",
    "margin-top": "16px",
});

export const Group = (props) => {
    const classes = useStyles();
    const { children, title } = props

    return (
        <GroupComponent>
            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                {title &&
                    <Grid item className={classes.title}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item className={classes.block}>
                                {title}
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Grid item>
                    {children}
                </Grid>
            </Grid>
        </GroupComponent>
    )
}
