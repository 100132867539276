import React from "react"
import {useDispatch, useSelector} from "react-redux"

import {
    Grid,
    makeStyles
} from "@material-ui/core"

import {plan as rules} from "../../../helpers/permissions/rules"
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon";
import {Delete as DeleteIcon} from "@material-ui/icons";
import {PurchasePlanActions as PlanActions} from "../../../actions/purchasePlan";

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    }
}))

export const Delete = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { items, onClick } = props

    const {account} = useSelector(state => state.account)

    return  (
        <Grid item>
            <ButtonIcon
                disabled={!rules.delete.hasOwnProperty(account.group) || !items.length || !!items.filter(el => {
                    if (rules.delete.hasOwnProperty(account.group) && !el.lock) {
                        return !rules.delete[account.group].includes(el.status?.index)
                    }

                    return true
                }).length}
                onClick={() => {
                    return dispatch(PlanActions.deletePurchasePlans({
                        ids: items.map(item => item.id)
                    })).then(() => onClick(), () => {})
                }}
                title='Удалить'
                children={<DeleteIcon/>}
                className={classes.button}
            />
        </Grid>
    )
}
