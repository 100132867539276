import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"

import {Grid, makeStyles, Paper, Tab, Tabs, Typography} from "@material-ui/core"

import {CompanyActions} from "../../actions/company"
import {InstitutionsBudgets} from "../../../Account/components/Tabs/Panels/InstitutionsBudgets"
import {LegalDetail} from "../Tabs/Panels/LegalDetail"
import {TabPanel} from "../Tabs/TabPanel"
import {Employees} from "../Tabs/Panels/Employees"
import {Territories} from "../Tabs/Panels/Territories"
import {AuthorizationService} from "../../../Auth/services/authorization"
import {SystemActions} from "../../../App/actions/system"
import {ContactInformation} from "../Tabs/Panels/ContactInformation"
import {PurchaseActions} from "../../../Planning/actions/purchase"
import {Settings} from "../../../Account/components/Tabs/Panels/Customer/Settings"
import {NotificationActions} from "../../../Notification/actions/notification";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    input: {
        "width": "100%",
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    }
}))

export const Content = () => {
    let {id} = useParams()
    const dispatch = useDispatch()
    const classes = useStyles()
    const {customer, account, cities, banks} = useSelector(state => {
        return {...state.companies, ...state.account, ...state.system}
    })
    const [regions, setRegions] = useState([])
    const [value, setValue] = useState(0)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!loading) {
            const getCustomer = async () => {
                return await dispatch(CompanyActions.customer(id, {
                    include: "region,purchasingDepartment,legalDetail.city.region.country,legalDetail.executiveDirector,legalDetail.passportDetails,paymentDetail,contactInformation,users.permissions,users.roles,institutionsBudgets.fundingSource,institutionsBudgets.responsible"
                }))
            }

            const getData = async (company) => {
                setRegions(await dispatch(CompanyActions.regions()))
                await dispatch(SystemActions.banks())
                await dispatch(SystemActions.cities({country: company.legal_detail.city.region.country.code}))
                if (AuthorizationService.roles(account, "admin")) await dispatch(PurchaseActions.fundingSources())
            }

            getCustomer().then(customer => {
                getData(customer).then(_ => {
                    setLoading(true)
                })
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loading, id]);

    const handleChange = (event, newValue) => {
        setValue(newValue)
    };

    return (customer && loading) ?
        (<Grid item className={classes.content}>
                <Paper>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item className={classes.full}>
                            <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                <Grid item className={classes.fullWidth}>
                                    <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                        <Grid item className={classes.header}>
                                            <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                                <Grid item className={classes.fullWidth}>
                                                    <Typography className={classes.title}>
                                                        {customer.legal_detail.name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.input}>
                                            <Grid container direction="column" justify="center" alignItems="stretch">
                                                <Grid item>
                                                    <Tabs
                                                        value={value}
                                                        indicatorColor="primary"
                                                        textColor="primary"
                                                        onChange={handleChange}
                                                    >
                                                        <Tab label="Контактная информация" />
                                                        <Tab label="Юридические реквизиты" />
                                                        <Tab label="Учетные записи" />
                                                        {AuthorizationService.roles(account, "admin") ?
                                                            <Tab label="Территории" /> : ''}
                                                        {AuthorizationService.roles(account, "admin") ?
                                                            <Tab label="Бюджет учреждения" /> : ''}
                                                        {AuthorizationService.roles(account, "admin") ?
                                                            <Tab label="Настройки" /> : ''}
                                                    </Tabs>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <TabPanel value={value} index={0}>
                                    <ContactInformation company={customer}/>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <LegalDetail banks={banks} cities={cities} regions={regions} company={customer} setLoading={setLoading}/>
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <Employees company={customer}/>
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                    <Territories company={customer}/>
                                </TabPanel>
                                <TabPanel value={value} index={4}>
                                    <InstitutionsBudgets company={customer} setLoading={setLoading}/>
                                </TabPanel>
                                <TabPanel value={value} index={5}>
                                    <Settings company={customer} setLoading={setLoading}/>
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
    ) : null
}
