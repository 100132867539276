import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Redirect} from "react-router"
import {Route, useParams} from "react-router-dom"

import {Grid} from "@material-ui/core"

import {AuthorizationService} from "./services/authorization"

import {AccountActions} from "../Account/actions/account"
import {history} from "../App/helpers/history";
import {Index as IndexSuperVisor} from "../Company/Index"
import * as AppStorage from "../App/helpers/storage"
import {App} from "../App/App";

export const Authorization = ({Component, ...props}) => {
    const {Roles = [], Permissions = [], require = false, base = true} = props

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(null)
    const {account} = useSelector(state => state)
    const {location, path} = props

    useEffect(() => {
        const token = AppStorage.get('token')

        const getAccount = async () => {
            return dispatch(AccountActions.account()).catch(() => {
                history.push("/logout")
            });
        }

        if (token && ((account.account && !account.load) || !account.account) && path !== '/logout') {
            getAccount().then(account => {
                if (
                    account &&
                    !(AuthorizationService.access(account, Roles, Permissions, require) && (location.pathname !== '/')) &&
                    !((location.pathname === '/') || location.pathname.includes('/lots/') || location.pathname.includes('/supplier/marketplace/my-orders/lot/')) &&
                    !(AuthorizationService.access(account, ["supplier_confirmed"]))
                ) {
                    setAlert('Доступ запрещён.')
                }
                setLoading(true)
            })
        } else {
            setLoading(true)
        }
        // eslint-disable-next-line
    }, [dispatch, account.account, account.load]);

    useEffect(() => {
        if (alert) {
            dispatch({
                type: "ALERT_ERROR",
                payload: {
                    message: alert,
                    type: 'error'
                }
            })
        }
    }, [dispatch, alert]);

    useEffect(() => {
        if (location ? (location.search ? location.search.match(/purchasing_department/gi) : false) : false) {
            setAlert('Заполните данные отдела закупок!')
        }
        // eslint-disable-next-line
    }, [dispatch, location.search]);

    if (loading) {
        if (location.pathname.includes('/lots/')) {
            return <Route path={path} render={(props) => <Component {...props} />} />
        }

        if (account.account) {
            if (AuthorizationService.access(account.account, ["customer"]) && (location.pathname === '/marketplace/request/add') && !account.account.company.purchasing_department) {
                return <Redirect to="/account?purchasing_department=true" />
            }
            if (location.pathname === '/planning') {
                return <Redirect to="/planning/purchase-plans" />
            }
            if ((AuthorizationService.roles(account.account, "customer") && (AuthorizationService.permissions(account.account, "access_to_order") && !AuthorizationService.permissions(account.account, "access_to_elmag"))) && location.pathname === '/carts') {
                return <Redirect to="/delivery/carts" />
            }

            if (AuthorizationService.access(account.account, ["supplier"]) && ['/account', '/logout'].indexOf(location.pathname) < 0 && (account.account.company.legal_detail.city.region.country.code === 'ru') && (account.account.company.type !== 'self') && (account.account.company.legal_detail.okpo === null)) {
                return <Redirect to="/account?okpo=true&dialog=true" />
            }
            if (AuthorizationService.access(account.account, Roles, Permissions, require) && (location.pathname !== '/')) {
                if (AuthorizationService.roles(account.account, ['supplier_blocked', 'customer_confirmed_by_admin']) && ['/account', '/logout', '/support'].indexOf(path) === -1) {
                    return <Redirect to="/account" />
                }

                if (AuthorizationService.roles(account.account, 'supplier') && !account.account.agree_with_notice_of_actualization && ['/account', '/logout', '/support'].indexOf(path) === -1) {
                    return <Redirect to="/supplier/marketplace/orders?sort=selection" />
                }

                if (AuthorizationService.roles(account.account, 'customer') && !account.account.agree_with_notice_of_actualization && !['/marketplace/requests'].indexOf(path)) {
                    return <Redirect to="/account" />
                }

                if (base) {
                    return (
                        <Route
                            path={path}
                            render={(props) => (
                                <Grid item style={{width: "100%"}}>
                                    <App
                                        Content={Component}
                                        {...props}
                                    />
                                </Grid>
                            )}
                        />
                    )
                } else {
                    return <Route path={path} render={(props) => <Component {...props} />} />
                }
            } else {
                if (location.pathname === '/') {
                    if (AuthorizationService.roles(account.account, 'supplier') && !account.account.agree_with_notice_of_actualization) {
                        return <Redirect to="/account?dialog=true" />
                    }

                    if (AuthorizationService.roles(account.account, ['supplier_blocked'])) {
                        return <Redirect to="/account" />
                    }

                    if (AuthorizationService.roles(account.account, ['guest_supplier'])) {
                        return <Redirect to="/account" />
                    }

                    if (AuthorizationService.access(account.account, ["supplier_confirmed"])) {
                        return <Redirect to="/supplier/marketplace/orders?sort=selection" />
                    }

                    if (AuthorizationService.access(account.account, ["customer_confirmed_by_admin"])) {
                        return <Redirect to="/account" />
                    }

                    if (AuthorizationService.access(account.account, ["supplier_confirmed_by_admin"])) {
                        return <Redirect to="/account?dialog=true" />
                    }

                    if (AuthorizationService.access(account.account, ["customer"])) {
                        if (AuthorizationService.permissions(account.account, "access_to_elmag") && account.account.agree_with_notice_of_actualization) {
                            return <Redirect to="/marketplace/requests" />
                        }
                        return <Redirect to="/account" />
                    }

                    if (AuthorizationService.access(account.account, ["admin"])) {
                        return <Redirect to="/companies" />
                    }

                    if (AuthorizationService.access(account.account, ["operator", "admin"])) {
                        return <Redirect to="/company" />
                    }

                    if (AuthorizationService.access(account.account, ["supervisor"])) {
                        if (AuthorizationService.permissions(account.account, ['access_to_companies', 'control_price'])) {
                            return (
                                <Route
                                    path={path}
                                    render={(props) => (
                                        <Grid item style={{width: "100%"}}>
                                            <App
                                                Content={IndexSuperVisor}
                                                {...props}
                                            />
                                        </Grid>
                                    )}
                                />
                            )
                        }

                        return <Redirect to="/company" />
                    }

                    if (AuthorizationService.access(account.account, ["supplier"])) {
                        return <Redirect to="/account?dialog=true&go=true" />
                    }
                }

                if (location.pathname.includes('/supplier/marketplace/my-orders/lot/')) {
                    if (AuthorizationService.access(account.account, ["customer"])) {
                        return <Redirect to={`/lots/${location.pathname.substring(36)}`} />
                    }
                }

                if (location.pathname === '/settings') {
                    if (AuthorizationService.permissions(account.account, ["access_to_order_settings"])) {
                        return <Redirect to="/settings/order" />
                    }
                    if (AuthorizationService.permissions(account.account, ["access_to_contract_settings"])) {
                        return <Redirect to="/settings/contract" />
                    }
                    if (AuthorizationService.access(account.account, ["admin"])) {
                        return <Redirect to="/settings/marketplace" />
                    }
                }

                if (location.pathname === '/companies') {
                    if (AuthorizationService.access(account.account, ["admin"])) {
                        return <Redirect to="/user/operators" />
                    }
                    if (AuthorizationService.access(account.account, ["operator"])) {
                        return <Redirect to="/customers" />
                    }
                }

                if (AuthorizationService.access(account.account, ["supplier_confirmed"]) && AuthorizationService.permissions(account.account, "access_to_elmag") && ['/supplier/marketplace/deliveries', '/supplier/marketplace/my-orders'].indexOf(location.pathname) !== -1) {
                    return <Redirect to={'/supplier/marketplace/orders?sort=selection'} />;
                }

                if (location.pathname === '/support' || (((location.pathname === '/supplier/marketplace/orders' || location.pathname.match(/^\/supplier\/marketplace\/my-orders\/lot\/[0-9]+/g)) && AuthorizationService.permissions(account.account, "access_to_elmag")) && AuthorizationService.access(account.account, ["supplier_confirmed", 'customer_confirmed_by_admin']))) {
                    return (
                        <Route
                            path={path}
                            render={(props) => (
                                <Grid item style={{width: "100%"}}>
                                    <App
                                        Content={Component}
                                        {...props}
                                    />
                                </Grid>
                            )}
                        />
                    )
                }

                return <Redirect to="/" />
            }
        } else {
            if (location.pathname.includes('/supplier/marketplace/my-orders/lot/')) {
                return <Redirect to={`/lots/${location.pathname.substring(36)}`} />
            }

            if (!Roles.length && !Permissions.length) {
                return <Route path={path} render={(props) => <Component {...props} />} />
            } else {
                if (`${location.pathname}${location.search}` !== '/logout') {
                    AppStorage.set('redirect', `${location.pathname}${location.search}`)
                }

                if (`${location.pathname}${location.search}` === '/') {
                    return <Route path={path} render={(props) => <Component {...props} />} />
                }

                return <Redirect to="/login" />
            }
        }
    }

    return null
}
