import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, FieldArray, Form, Formik} from "formik"
import {CheckboxWithLabel, Select} from "formik-material-ui"
import * as Yup from "yup"
import DateFnsUtils from "@date-io/date-fns"
import {ru} from "date-fns/locale"
import format from "date-fns/format"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    StepIcon,
    TextField as MuiTextField,
    Typography
} from "@material-ui/core"
import {Autocomplete} from '@material-ui/lab'
import {Close as CloseIcon, Clear, Add as AddIcon} from "@material-ui/icons"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"

import {TextFieldWithError as CustomTextField} from "../../../../../App/components/Inputs/TextFieldWithError"
import {Submit} from "../../../../../App/components/Button/Submit"
import {EmployeeActions} from "../../../../actions/employee"
import {AuthActions} from "../../../../../Auth/actions/authentication"

const useStyles = makeStyles(() => ({
    item: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "cursor": "pointer"
    },
    fullWidth: {
        "width": "100%"
    },
    content: {
        "padding": "0 !important"
    },
    input: {
        "width": "100%",
    },
    column: {
        "height": "86px"
    },
    dialogContent: {
        "max-height": "calc(100vh - 210px)"
    },
    footer: {
        "background-color": "#e7eaee",
        "justify-content": "flex-start"
    },
    dialog: {
        "max-height": "70vh"
    },
    footerContent: {
        "padding": "10px 0px"
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    titleDialog: {
        "& h2": {
            "display": "flex",
            "justify-content": "space-between",
        },
    }
}))

export const Employee = (props) => {
    const { company, employee } = props
    const dispatch = useDispatch()
    const classes = useStyles()
    const { permissions } = useSelector(state => state.authentication)

    const [open, setOpen] = useState(props.open)

    useEffect(() => {
        if (props.open) {
            const getPermissions = async () => {
                await dispatch(AuthActions.permissions())
            }

            getPermissions().then(_ => {
                setOpen(props.open)
            })
        } else {
            setOpen(props.open)
        }
    }, [dispatch, props.open])

    const handleClose = () => {
        setOpen(false)
        props.setDialog(false)
    }

    return open ? (
        <Grid item>
            <Dialog
                open={!!open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle className={classes.titleDialog}>
                    {employee ? 'Редактировать сотрудника' : 'Добавить сотрудника'}
                    <CloseIcon className={classes.close} onClick={handleClose}/>
                </DialogTitle>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            ...{
                                last_name: employee ? employee.last_name : '',
                                first_name: employee ? employee.first_name : '',
                                middle_name: employee ? employee.middle_name : '',
                                login: employee ? employee.login : '',
                                email: employee ? employee.email : '',
                                skype: employee ? employee.skype : '',
                                phone: employee ? (employee.phone ?? '') : '',
                                phones: employee ? ((employee.phones ? employee.phones.map(phone => { return {number: phone.number ?? '', extra: phone.extra ?? ''} }) : []) ?? []) : [],
                                position: employee ? (employee.position ?? '') : '',
                                password: '',
                                confirmation: '',
                                beginning_work: employee ? new Date(employee.beginning_work) : null,
                                end_work: employee ? new Date(employee.end_work) : null,
                                chiefDoctor: employee ? employee.position === 'Главный врач' : false,
                            },
                            ...(
                                !company.type ? {
                                    permissions: permissions.reduce((obj, permission) => {obj[permission.id] = !!(employee && employee.hasOwnProperty('permissions') && employee.permissions.find(item => item.id === permission.id)); return obj}, {}),
                                    territories: company.territories.filter(territory => (employee && territory.employees.find((user => user.id === employee.id)))).map(territory => territory.id)
                                } : {
                                    permissions: permissions.reduce((obj, permission) => {obj[permission.id] = !!(employee && employee.hasOwnProperty('permissions') && employee.permissions.find(item => item.id === permission.id)); return obj}, {}),
                                }
                            )
                        }
                    }
                    validationSchema={Yup.object().shape({
                        login: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        password: employee ? Yup.string().min(8, "Длина пароля должна быть не менее 8 символов!") : Yup.string().min(8, "Длина пароля должна быть не менее 8 символов!").required("Поле не может быть пустым или состоять из одних пробелов!"),
                        confirmation: Yup.string().when("password", (password, schema) => {
                            return password ? schema.required("Поле не может быть пустым или состоять из одних пробелов!") : schema
                        }).oneOf([Yup.ref("password"), null], "Пароли не совпадают!"),
                        email: Yup.string().email("Некорректный формат электронной почты!").required("Поле не может быть пустым или состоять из одних пробелов!"),
                        skype: Yup.string().nullable(),
                        last_name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        first_name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        middle_name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        phones: Yup.array().required().of(Yup.object().shape({
                            number: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!").matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').when("extra", (extra, schema) => {
                                return extra ? schema.required("Поле не может быть пустым или состоять из одних пробелов!") : schema
                            }),
                            extra: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                        })),
                        permissions: Yup.mixed().nullable(),
                        territories: Yup.array().nullable(),
                        position: Yup.string(),
                        beginning_work: Yup.mixed().when("position", (position, schema) => {
                            return position === 'Главный врач' ? schema.required("Поле не может быть пустым или состоять из одних пробелов!") : schema
                        }),
                        end_work: Yup.mixed().when("position", (position, schema) => {
                            return position === 'Главный врач' ? schema.required("Поле не может быть пустым или состоять из одних пробелов!") : schema
                        }),
                    })}
                    onSubmit={(values, {setSubmitting,
                        setErrors}) => {
                        let params = {
                            ...values,
                            ...(values.hasOwnProperty('permissions') ?
                                {
                                    permissions: Object.keys(values.permissions).filter(id => values.permissions[id])
                                } : {}),
                            ...(values.hasOwnProperty('beginning_work') && values.beginning_work ?
                                {
                                    beginning_work: format(values.beginning_work, 'yyyy-MM-dd')
                                } : {}),
                            ...(values.hasOwnProperty('end_work') && values.end_work  ?
                                {
                                    end_work: format(values.end_work, 'yyyy-MM-dd')
                                } : {})
                        }
                        dispatch(employee ? EmployeeActions.edit(company.id, employee.id, params) : EmployeeActions.add(company.id, params)).then(
                            () => {
                                setSubmitting(false)
                                handleClose()
                            },
                            error => {
                                if (error.errors) {
                                    setErrors(error.errors)
                                }
                                setSubmitting(false);
                            }
                        )
                    }}
                >
                    {({
                          dirty,
                          values,
                          handleSubmit,
                          setFieldValue,
                          setFieldTouched,
                          isSubmitting,
                          isValid
                      }) => (
                        <Form>
                            <DialogContent className={classes.dialogContent}>
                                <Grid container className={classes.fullWidth} direction="row" justify="center" alignItems="stretch">
                                    <Grid item className={classes.fullWidth}>
                                        <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
                                            <Grid item>
                                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                                    <Grid item>
                                                        <StepIcon icon={1} active={true} classes={{
                                                            root: classes.stepIcon,
                                                            text: classes.stepLabel
                                                        }}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className={classes.stepTitle}>Основные данные</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.list}>
                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                    <Grid item xs={4} className={classes.column}>
                                                        <Field
                                                            fullWidth
                                                            name="last_name"
                                                            type="text"
                                                            label="Фамилия"
                                                            required
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} className={classes.column}>
                                                        <Field
                                                            fullWidth
                                                            name="first_name"
                                                            type="text"
                                                            label="Имя"
                                                            required
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} className={classes.column}>
                                                        <Field
                                                            fullWidth
                                                            name="middle_name"
                                                            type="text"
                                                            label="Отчество"
                                                            required
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.list}>
                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                    <Grid item xs={4} className={classes.column}>
                                                        <Field
                                                            fullWidth
                                                            name="login"
                                                            type="text"
                                                            label="Имя пользователя"
                                                            required
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} className={classes.column}>
                                                        <Autocomplete
                                                            options={[{ name: 'Главный врач', position: 'ChiefDoctor', id: 0}]}
                                                            required
                                                            freeSolo
                                                            name="position"
                                                            noOptionsText='Должность'
                                                            defaultValue={{ name: values.position}}
                                                            getOptionLabel={(option) => option.name}
                                                            onChange={(e, item) => {
                                                                if(item){
                                                                    setFieldValue("position", item.name)
                                                                    if(item.id === 0) setFieldValue("chiefDoctor", true)
                                                                }
                                                            }}
                                                            renderInput={(params) =>
                                                                <MuiTextField
                                                                    {...params}
                                                                    onChange={(e) => {
                                                                        setFieldValue("position", e.target.value)
                                                                        setFieldValue("chiefDoctor", false)
                                                                    }}
                                                                    fullWidth
                                                                    label="Должность"
                                                                    name="position"
                                                                />}
                                                        />
                                                    </Grid>
                                                    {!company.type &&
                                                        <Grid item xs={4} className={classes.column}>
                                                            <FormControl className={classes.fullWidth}>
                                                                <InputLabel shrink={true} htmlFor="roles">
                                                                    Территории
                                                                </InputLabel>
                                                                <Field
                                                                    fullWidth
                                                                    type="text"
                                                                    name="territories"
                                                                    label="Территории"
                                                                    component={Select}
                                                                    multiple
                                                                >
                                                                    {company.territories.map((territory, index) => (
                                                                        <MenuItem key={index} value={territory.id}>
                                                                            {territory.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Field>
                                                            </FormControl>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.list}>
                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                    <Grid item xs={4} className={classes.column}>
                                                        <Field
                                                            fullWidth
                                                            name="password"
                                                            type="password"
                                                            label="Пароль"
                                                            required={!employee}
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} className={classes.column}>
                                                        <Field
                                                            fullWidth
                                                            name="confirmation"
                                                            type="password"
                                                            label="Подтверждение пароля"
                                                            required={!employee}
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                    {values.chiefDoctor &&
                                                        <Grid item className={classes.column} xs={4}>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                <KeyboardDatePicker
                                                                    disableToolbar
                                                                    fullWidth
                                                                    name="beginning_work"
                                                                    required
                                                                    format="dd-MM-yyyy"
                                                                    invalidDateMessage="Некорректная дата"
                                                                    orientation="landscape"
                                                                    variant="inline"
                                                                    openTo="date"
                                                                    autoOk
                                                                    label="Дата начала выполнения обязанностей"
                                                                    value={values.beginning_work}
                                                                    onChange={date => {
                                                                        setFieldValue("beginning_work", date, true)
                                                                    }}
                                                                    onBlur={() => {
                                                                        setFieldTouched(`beginning_work`, true, true)
                                                                    }}
                                                                    onClose={() => {
                                                                        setFieldTouched(`beginning_work`, true, true)
                                                                    }}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.list}>
                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                    <Grid item xs={4} className={classes.column}>
                                                        <Field
                                                            fullWidth
                                                            name="email"
                                                            type="email"
                                                            label="Адрес электронной почты"
                                                            required
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} className={classes.column}>
                                                        <Field
                                                            fullWidth
                                                            name="skype"
                                                            type="string"
                                                            label="Skype"
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                    {values.chiefDoctor &&
                                                        <Grid item className={classes.column} xs={4}>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                <KeyboardDatePicker
                                                                    disableToolbar
                                                                    fullWidth
                                                                    name="end_work"
                                                                    required
                                                                    format="dd-MM-yyyy"
                                                                    invalidDateMessage="Некорректная дата"
                                                                    orientation="landscape"
                                                                    variant="inline"
                                                                    openTo="date"
                                                                    autoOk
                                                                    label="Дата окончания выполнения обязанностей"
                                                                    value={values.end_work}
                                                                    onChange={date => {
                                                                        setFieldValue("end_work", date, true)
                                                                    }}
                                                                    onBlur={() => {
                                                                        setFieldTouched(`end_work`, true, true)
                                                                    }}
                                                                    onClose={() => {
                                                                        setFieldTouched(`end_work`, true, true)
                                                                    }}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Grid>
                                                    }
                                                </Grid>
                                                <FieldArray name="phones" render={ arrayHelpers => (
                                                    <React.Fragment>
                                                        {values.phones.map((phone, index) => {
                                                            return (
                                                                <Grid key={index} container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                                    <Grid item xs={2} className={classes.column}>
                                                                        <Field
                                                                            required
                                                                            fullWidth
                                                                            name={`phones.${index}.number`}
                                                                            type="phone"
                                                                            label="Номер телефона"
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2} className={classes.column}>
                                                                        <Field
                                                                            fullWidth
                                                                            name={`phones.${index}.extra`}
                                                                            type="text"
                                                                            label="доб."
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <IconButton
                                                                            size={"small"}
                                                                            onClick={() => {
                                                                                arrayHelpers.remove(index)
                                                                            }}
                                                                        >
                                                                            <Clear />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })}
                                                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Submit
                                                                    size="small"
                                                                    variant="contained"
                                                                    component="label"
                                                                    color="primary"
                                                                    endIcon={<AddIcon/>}
                                                                    onClick={() => {
                                                                        arrayHelpers.push({number: '', extra: ''})
                                                                    }}
                                                                >
                                                                    Добавить номер телефона
                                                                </Submit>
                                                            </Grid>
                                                        </Grid>
                                                    </React.Fragment>
                                                )}/>
                                            </Grid>
                                            {!company.type ? (
                                                <React.Fragment>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                                            <Grid item>
                                                                <StepIcon icon={2} active={true} classes={{
                                                                    root: classes.stepIcon,
                                                                    text: classes.stepLabel
                                                                }}/>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography className={classes.stepTitle}>Назначенные роли</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {
                                                        permissions.filter(per => [
                                                            'access_to_standard_settings', 'control_price', 'access_to_companies',
                                                            'access_to_contract_settings', 'access_to_order_settings', 'access_to_planning_settings',
                                                            'submission_to_request', 'access_to_full_elmag', 'change_contract_status', 'change_delivery_order_status'
                                                        ].indexOf(per.name) === -1).map(permission => (
                                                            <Grid key={permission.id} item className={classes.list}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name={`permissions[${permission.id}]`}
                                                                    color="primary"
                                                                    Label={{label: permission.description}}
                                                                    inputProps={{
                                                                        onChange: (event) => {
                                                                            const checked = event.target.checked
                                                                            let perm = permissions.find(per => per.id === permission.id)
                                                                            setFieldValue(`permissions[${permission.id}]`, checked);

                                                                            if ((perm.name === "access_to_order") && !checked) {
                                                                                let filterPermissions = permissions.filter(per => ['formation_editing_delivery_order', 'confirmation_editing_delivery_order', 'receiving_confirmation_editing_delivery_order', 'confirmation_payment_delivery_order', 'create_delivery_order', 'access_all_delivery_orders', 'delivery_order_after_event'].indexOf(per.name) !== -1)
                                                                                filterPermissions.forEach(per => setFieldValue(`permissions[${per.id}]`, false))
                                                                            }

                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                        ))
                                                    }
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                                            <Grid item>
                                                                <StepIcon icon={2} active={true} classes={{
                                                                    root: classes.stepIcon,
                                                                    text: classes.stepLabel
                                                                }}/>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography className={classes.stepTitle}>Назначенные роли</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {
                                                        permissions.filter(per => ['access_to_elmag', 'access_to_marketplace', 'submission_to_request'].indexOf(per.name) !== -1).map(permission => (
                                                            <Grid key={permission.id} item className={classes.list}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name={`permissions[${permission.id}]`}
                                                                    color="primary"
                                                                    Label={{label: permission.description}}
                                                                    inputProps={{
                                                                        onChange: (event) => {
                                                                            const checked = event.target.checked
                                                                            let perm = permissions.find(per => per.id === permission.id)
                                                                            setFieldValue(`permissions[${permission.id}]`, checked);

                                                                            if ((perm.name === "access_to_order") && !checked) {
                                                                                let filterPermissions = permissions.filter(per => ['formation_editing_delivery_order', 'confirmation_editing_delivery_order', 'receiving_confirmation_editing_delivery_order', 'confirmation_payment_delivery_order', 'create_delivery_order', 'access_all_delivery_orders', 'delivery_order_after_event'].indexOf(per.name) !== -1)
                                                                                filterPermissions.forEach(per => setFieldValue(`permissions[${per.id}]`, false))
                                                                            }

                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                        ))
                                                    }
                                                </React.Fragment>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions className={classes.footer}>
                                <Grid container className={ classes.footerContent} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Submit
                                            variant="contained"
                                            component="label"
                                            color="primary"
                                            disabled={isSubmitting || !isValid || !dirty}
                                            onClick={handleSubmit}
                                            type="submit"
                                        >
                                            {employee ? 'Сохранить' : 'Добавить'}
                                        </Submit>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </Grid>
    ) : null
}
