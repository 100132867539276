import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {Grid, IconButton, makeStyles} from "@material-ui/core";
import {Close as CloseIcon, Menu as MenuIcon} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    menu: {
        "width": "300px",
        "height": "100%",
        "background-color": "white",
        "z-index": "1200",
        "overflow-y": "auto",
        "box-shadow": "10px 0 10px -3px rgba(0,0,0,.1)"
    },
    menuPlanning: {
        "width": "200px",
        "height": "100%",
        "background-color": "white",
        "z-index": "1200",
        "overflow-y": "auto",
        "box-shadow": "10px 0 10px -3px rgba(0,0,0,.1)"
    },
    icon: {
        "color": "#616161",
        "font-weight": "500",
        "&:hover": {
            "background": "rgba(65,103,95,.3)"
        },
        zIndex: '1201',
        position: 'absolute',
        borderRadius: '50%',
        fontSize: '21px',
        cursor: "pointer",
        padding: "4px"
    },
    menuButton: {
        height: '30px',
        left: '12px',
        padding: '12px 16px',
        top: '14px'
    }
}))

export const Menu = ({Component, ...props}) => {
    const {hideMenu} = useSelector(state => state.application)
    const classes = useStyles()
    const dispatch = useDispatch()
    const {location} = props

    return <React.Fragment>
        {hideMenu && location &&
            <IconButton
                className={classes.menuButton}
                onClick={() => {
                    dispatch({type: 'HIDE_MENU'})
                }}
            >
                <MenuIcon className={classes.icon} />
            </IconButton>}
        {!hideMenu && location &&
            <CloseIcon
                className={classes.icon}
                onClick={() => {
                    dispatch({type: 'HIDE_MENU'})
                }}
                style={{left: location && !location.pathname.indexOf('/planning') ? '171px' : '267px'}}
            />
        }
        {!hideMenu ?
            <Grid item className={!location.pathname.indexOf('/planning') ? classes.menuPlanning : classes.menu}>
                <Component {...props} />
            </Grid> : null
        }
    </React.Fragment>
}
