import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce} from 'use-debounce'
import clsx from "clsx"

import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputAdornment,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField
} from "@material-ui/core"
import {Search as SearchIcon} from "@material-ui/icons"

import {Submit} from "../../../App/components/Button/Submit"
import {ProductsActions} from "../../actions/products"

const useStyles = makeStyles(theme => ({
    tableContainer: {
        "height": "100%",
        "min-height": "300px"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    content: {
        "padding": "0 !important"
    },
    footer: {
        "background-color": "#e7eaee",
        "justify-content": "flex-start"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    searchLabel: {
        "margin-bottom": "15px",
    },
}))

const columns = [
    {
        id: '0',
        label: 'Наименование товара',
        format: (value) => value.toLocaleString(),
    },
];

export const Products = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {reportProducts}  = useSelector(state => state.reportProducts)
    const {setProducts, products } = props
    const [search, setSearch] = useState(null)
    const [open, setOpen] = useState(props.openProducts);
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [page, setPage] = useState(0)
    const [searchRequest] = useDebounce(search, 900)
    const containerRef = useRef(null)

    const [selected, setSelected] = React.useState([])
    const isSelected = (product) => (selected.find(item => (item.id === product.id)) !== undefined)

    useEffect(() => {
        if (props.openProducts) {
            const getProducts = async () => {
                let params = {
                    limit: rowsPerPage,
                    page: page + 1,
                    ...(searchRequest ? {search: searchRequest} : {}),
                }
                await dispatch(ProductsActions.products(params))
            }

            getProducts().then(_ => {
                containerRef.current && containerRef.current.scrollTo({ behavior: 'smooth', top: 0 })
                setOpen(props.openProducts)
                setSelected([])
            })
        } else {
            setSelected([])
            setOpen(props.openProducts)
        }
    }, [dispatch, props.openProducts, rowsPerPage, page, searchRequest])

    useEffect(() => {
        if (open) {
            setSelected(products)
        }
        props.setDialogProducts(open)
    }, [props, products, open])

    const handleSelectAll = (event) => {
        if (event.target.checked && selected.length !== reportProducts.data.length) {
            setSelected(reportProducts.data.map((product) => product))
        } else {
            setSelected([])
        }
    };

    const handleSelect = (event, product) => {
        const selectedIndex = selected.indexOf(product)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, product)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
        }

        setSelected(newSelected)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    return (
        <Grid item>
            <Dialog
                open={!!open}
                onClose={() => {
                    setSearch(null)
                    setOpen(false)
                }}
                fullWidth={true}
                maxWidth={'xl'}
            >
                <DialogTitle>Товары</DialogTitle>
                <DialogContent ref={containerRef} className={classes.content}>
                    <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                        <Grid item className={classes.header}>
                            <TextField
                                id="search"
                                variant="standard"
                                name={'search'}
                                placeholder={'Поиск'}
                                fullWidth={true}
                                margin={'normal'}
                                onChange={(event) => {
                                    setSearch(event.target.value)
                                }}
                                classes={{
                                    root: clsx(classes.filterLabel, classes.searchLabel)
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon  style={{color: '#c1c1c1'}}/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TableContainer className={classes.tableContainer}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    indeterminate={selected.length > 0 && selected.length < reportProducts.meta.total}
                                                    checked={reportProducts.meta.total > 0 && selected.length === reportProducts.meta.total}
                                                    onChange={handleSelectAll}
                                                />
                                            </TableCell>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportProducts && reportProducts.hasOwnProperty('data') && reportProducts.data.map((product, index) => {
                                            const isItemSelected = isSelected(product);

                                            return (
                                                <TableRow
                                                    onClick={(event) => handleSelect(event, product)}
                                                    className={classes.tableRow}
                                                    hover key={index}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {product.name}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.footer}>
                    <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                        <Grid item>
                            <TablePagination
                                rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                component='div'
                                labelRowsPerPage={'Записей на странице:'}
                                count={(reportProducts && reportProducts.hasOwnProperty('meta')) ? reportProducts.meta.total : 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" justify="flex-end" alignItems="stretch" spacing={2}>
                                <Grid item>
                                    <Submit
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={() => {
                                            setSelected([])
                                            setSearch(null)
                                            setOpen(false)
                                        }}
                                    >
                                        Отмена
                                    </Submit>
                                </Grid>
                                <Grid item>
                                    <Submit
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={!selected.length}
                                        onClick={() => {
                                            setProducts(selected)
                                            setSelected([])
                                            setOpen(false)
                                        }}
                                    >
                                        Выбрать
                                    </Submit>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}
