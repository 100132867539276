import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, FieldArray, Form, Formik} from "formik"
import * as Yup from "yup"
import {CheckboxWithLabel, fieldToTextField, TextField} from "formik-material-ui"

import {
    Grid,
    Link,
    makeStyles,
    MenuItem,
    Paper,
    StepIcon,
    TextField as MuiTextField,
    Typography
} from "@material-ui/core"
import {Autocomplete} from "@material-ui/lab"

import {AuthActions} from "./actions/authentication"
import {SystemActions} from "../App/actions/system"
import {TextFieldWithError as CustomTextField} from "../App/components/Inputs/TextFieldWithError"
import {Submit} from "../App/components/Button/Submit"
import {toFloatWithCurrency} from "../App/helpers/number";

const useStyles = makeStyles(theme => ({
    item: {
        "width": "655px",
        [theme.breakpoints.up("lg")]: {
            "width": "950px",
        },
        "min-height": "calc(100% - 60px)",
        "height": "auto"
    },
    container: {
        "background-image": "linear-gradient(to bottom,#f6f6f6,#eee 85%,#b1b1b1)",
        "border-radius": 0,
        "min-height": "100vh",
        "height": "auto"
    },
    fullWidth: {
        "width": "100%"
    },
    fullHeight: {
        "height": "100%"
    },
    full: {
        "width": "100%",
        "height": "100%"
    },
    column: {
        "height": "86px"
    },
    header: {
        "padding": "15px 35px !important",
    },
    input: {
        "width": "100%",
        "height": "calc(100vh - 200px)",
        "padding-bottom": "4px"
    },
    inputContent: {
        "overflow": "auto",
        "padding": "0 35px 3px !important",
        "height": "100%"
    },
    submit: {
        "height": "35px",
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase"
    },
    description: {
        "font-size": "16px",
        "color": "#485868"
    },
    info: {
        "font-size": "12px",
        "color": "#485868"
    },
    stepIcon: {
        "&.MuiStepIcon-active": {
            "color": "#898989"
        }
    },
    stepLabel: {
        "fill": "white"
    },
    stepTitle: {
        "font-size": "16px",
        "color": "#898989"
    },
    list: {
        "margin-bottom": "15px"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "background-color": "#e7eaee",
        "padding": "0 20px !important",
    },
    footerContainer: {
        "padding": "12px",
    },
    agreement: {
        "max-width": "380px",
        [theme.breakpoints.up("lg")]: {
            "max-width": "800px",
        }
    },
    agreementText: {
        "line-height": "22px",
        "font-size": "16px",
        "color": "#485868"
    },
    link: {
        "text-decoration": "underline",
        "color": "#485868"
    }
}))

export const Registration = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        const getCities = async () => {
            await dispatch(SystemActions.cities())
        }

        getCities().then(() => {})
    }, [dispatch]);

    const getCountryParams = (country, type) => {
        let params = {
            values: {},
            schema: {}
        }

        switch (type) {
            case 'individual':
            case 'self':
                switch (country) {
                    case "ru":
                        params = {
                            values: {
                                inn: ""
                            },
                            schema: {
                                inn: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                    .length(12, "Длина ИНН должна быть 12 символов!")
                                    .required("Поле не может быть пустым или состоять из одних пробелов!")
                            }
                        }

                        break;
                    case "kz":
                        params = {
                            values: {
                                bin: ""
                            },
                            schema: {
                                bin: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                    .length(12, "Длина БИН должна быть 12 символов!")
                                    .required("Поле не может быть пустым или состоять из одних пробелов!")
                            }
                        }

                        break;
                    default:
                }
                break;
            case 'legal':
                params = {
                    values: {
                        name: ""
                    },
                    schema: {
                        name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
                    }
                }

                switch (country) {
                    case "ru":
                        params = {
                            values: {
                                ...params.values,
                                ...{
                                    inn: "",
                                    kpp: ""
                                }
                            },
                            schema: {
                                ...params.schema,
                                ...{
                                    inn: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                        .length(10, "Длина ИНН должна быть 10 символов!")
                                        .required("Поле не может быть пустым или состоять из одних пробелов!"),
                                    kpp: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                        .length(9, "Длина КПП должна быть 9 символов!")
                                        .required("Поле не может быть пустым или состоять из одних пробелов!"),
                                    name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
                                }
                            }
                        }

                        break
                    case "kz":
                        params = {
                            values: {
                                ...params.values,
                                ...{
                                    bin: ""
                                }
                            },
                            schema: {
                                ...params.schema,
                                ...{
                                    bin: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                        .length(12, "Длина БИН должна быть 12 символов!")
                                        .required("Поле не может быть пустым или состоять из одних пробелов!")
                                }
                            }
                        }

                        break
                    default:
                }
                break;
        }

        return params
    }

    const classes = useStyles()
    const [country, setCountry] = useState(null)
    const [initial, setInitial] = useState({
        login: "",
        password: "",
        email: "",
        last_name: "",
        middle_name: "",
        first_name: "",
        confirmation: "",
        locality: null,
        country: "",
        type: "legal",
        agreement: false,
        processing_of_personal_data: false,
        privacy_policy: false,
        offer: false,
        phones: [{number: '', extra: ''}]
    })
    const [schema, setSchema] = useState({
        login: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        password: Yup.string().min(8, "Длина пароля должна быть не менее 8 символов!").required("Поле не может быть пустым или состоять из одних пробелов!"),
        confirmation: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!").oneOf([Yup.ref("password"), null], "Пароли не совпадают!"),
        email: Yup.string().email("Некорректный формат электронной почты!").required("Поле не может быть пустым или состоять из одних пробелов!"),
        last_name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        first_name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        middle_name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        locality: Yup.object().nullable().required("Необходимо указать город"),
        country: Yup.string(),
        agreement: Yup.bool().required("Поле не может быть пустым или состоять из одних пробелов!"),
        processing_of_personal_data: Yup.bool().required("Поле не может быть пустым или состоять из одних пробелов!"),
        privacy_policy: Yup.bool().required("Поле не может быть пустым или состоять из одних пробелов!"),
        offer: Yup.bool().required("Поле не может быть пустым или состоять из одних пробелов!"),
        type: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        phones: Yup.array().of(Yup.object().shape({
            number: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').required("Поле не может быть пустым или состоять из одних пробелов!").when("extra", (extra, schema) => {
                return extra ? schema.required("Поле не может быть пустым или состоять из одних пробелов!") : schema
            }),
            extra: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
        })),
    })

    const { cities } = useSelector(state => state.system)

    return (
        <Grid container direction="column" justify="center" alignItems="center" className={classes.container}>
            <Grid item className={classes.item}>
                <Paper style={{height: "calc(100% - 10px)"}}>
                    <Formik
                        initialValues={initial}
                        validationSchema={Yup.object().shape(schema)}
                        onSubmit={(values, {setSubmitting}) => {
                            dispatch(AuthActions.registration({...values, ...{code: country}})).then(
                                () => {
                                    setSubmitting(false);
                                },
                                errors => {
                                    if (errors) {
                                    }
                                    setSubmitting(false);
                                }
                            )
                        }}
                    >
                        {({
                              dirty,
                              values,
                              errors,
                              handleSubmit,
                              isSubmitting,
                              isValid,
                              setValues,
                              setFieldValue
                          }) => (
                            <Form className={classes.fullHeight}>
                                <Grid container className={classes.fullHeight} direction="row" justify="center" alignItems="center">
                                    <Grid item className={classes.full}>
                                        <Grid container className={classes.fullHeight} direction="column" justify="space-between" alignItems="stretch">
                                            <Grid item className={classes.header}>
                                                <Grid container direction="row" justify="space-between" alignItems="stretch">
                                                    <Grid item xs={6}>
                                                        <Typography className={classes.title}>
                                                            Регистрация поставщика
                                                        </Typography>
                                                        <Typography className={classes.description}>
                                                            Все поля являются обязательными для заполнения.
                                                        </Typography>
                                                        <Typography className={classes.info}>
                                                            Для регистрации в качестве Заказчика необходимо направить запрос на <Link href="mailto:support@emsoft.ru" className={classes.link}>support@emsoft.ru</Link>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography className={classes.description}>
                                                            С использованием механизма нашего электронного магазина осуществлено 32475 закупок на общую сумму {parseFloat('7646964032.65').toLocaleString('ru-RU', {style: 'currency', currency: 'RUB'})}, зарегистрировано 15409 поставщиков, подрядчиков, исполнителей.
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.input}>
                                                <Grid className={classes.fullHeight} container direction="column" justify="center" alignItems="stretch">
                                                    <Grid item className={classes.inputContent}>
                                                        <Grid container className={classes.fullWidth} direction="column" justify="center" alignItems="stretch" spacing={1}>
                                                            <Grid item>
                                                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                                                    <Grid item>
                                                                        <StepIcon
                                                                            icon={1}
                                                                            active={true}
                                                                            classes={{
                                                                                root: classes.stepIcon,
                                                                                text: classes.stepLabel
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography className={classes.stepTitle}>Учетные данные в системе</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item className={classes.list}>
                                                                <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                                    <Grid item xs={6} className={classes.column}>
                                                                        <Field
                                                                            fullWidth
                                                                            name="login"
                                                                            type="text"
                                                                            label="Логин"
                                                                            required={true}
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6} className={classes.column}>
                                                                        <Field
                                                                            fullWidth
                                                                            name="email"
                                                                            type="email"
                                                                            label="Электронный адрес"
                                                                            required={true}
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                                    <Grid item xs={6} className={classes.column}>
                                                                        <Field
                                                                            fullWidth
                                                                            name="password"
                                                                            type="password"
                                                                            label="Пароль"
                                                                            required={true}
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6} className={classes.column}>
                                                                        <Field
                                                                            fullWidth
                                                                            name="confirmation"
                                                                            type="password"
                                                                            label="Подтверждение пароля"
                                                                            required={true}
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                                    <Grid item xs={6} className={classes.column}>
                                                                        <Field
                                                                            fullWidth
                                                                            name="last_name"
                                                                            type="text"
                                                                            label="Фамилия пользователя"
                                                                            required={true}
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6} className={classes.column}>
                                                                        <Field
                                                                            fullWidth
                                                                            name="first_name"
                                                                            type="text"
                                                                            label="Имя пользователя"
                                                                            required={true}
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                    <Grid item xs={6} className={classes.column}>
                                                                        <Field
                                                                            fullWidth
                                                                            name="middle_name"
                                                                            type="text"
                                                                            label="Отчество пользователя"
                                                                            required={true}
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6} className={classes.column}>
                                                                        <FieldArray name="phones" render={() => (
                                                                            <React.Fragment>
                                                                                {values.phones.map((_, index) => {
                                                                                    return (
                                                                                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                                                            <Grid item xs={8}>
                                                                                                <Field
                                                                                                    required
                                                                                                    fullWidth
                                                                                                    type="phone"
                                                                                                    label="Номер телефона"
                                                                                                    component={CustomTextField}
                                                                                                    name={`phones.${index}.number`}
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <Field
                                                                                                    fullWidth
                                                                                                    type="text"
                                                                                                    label="доб."
                                                                                                    component={CustomTextField}
                                                                                                    name={`phones.${index}.extra`}
                                                                                                />
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    );
                                                                                })}
                                                                            </React.Fragment>
                                                                        )}/>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container className={classes.fullWidth} direction="column" justify="center" alignItems="stretch" spacing={1}>
                                                            <Grid item>
                                                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                                                    <Grid item>
                                                                        <StepIcon
                                                                            icon={2}
                                                                            active={true}
                                                                            classes={{
                                                                                root: classes.stepIcon,
                                                                                text: classes.stepLabel
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography className={classes.stepTitle}>Информация о поставщике</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid item className={classes.list}>
                                                                    <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                                        <Grid item xs={6} className={classes.column}>
                                                                            <Field
                                                                                name="locality"
                                                                                type="text"
                                                                                label="Населенный пункт"
                                                                                required
                                                                                component={({ textFieldProps, ...props }) => {
                                                                                    const { form: { errors, setTouched } } = props;
                                                                                    const { label, helperText, error, ...field } = fieldToTextField(props);
                                                                                    const { name } = field;

                                                                                    return (
                                                                                        <Autocomplete
                                                                                            {...props}
                                                                                            {...field}
                                                                                            options={cities}
                                                                                            getOptionLabel={option => {
                                                                                                return option ? (option.name + ", " + option.region.country.name + ((option.name !== option.region.name) ? ", " + option.region.name : "")) : ""
                                                                                            }}
                                                                                            noOptionsText='Нет доступных вариантов'
                                                                                            onBlur={() => setTouched({ [name]: true })}
                                                                                            onChange={(event, option) => {

                                                                                                let tmpSchema = schema
                                                                                                delete tmpSchema.inn
                                                                                                delete tmpSchema.kpp
                                                                                                delete tmpSchema.bin

                                                                                                let tmpValues = values
                                                                                                delete tmpValues.inn
                                                                                                delete tmpValues.kpp
                                                                                                delete tmpValues.bin

                                                                                                if (option) {
                                                                                                    const params = getCountryParams(option.region.country.code, values.type)

                                                                                                    switch (values.type) {
                                                                                                        case 'individual':
                                                                                                        case 'self':
                                                                                                            delete tmpSchema.name
                                                                                                            delete tmpValues.name
                                                                                                            delete tmpSchema.kpp
                                                                                                            delete tmpValues.kpp
                                                                                                    }

                                                                                                    if (country === 'ru') {
                                                                                                        delete tmpSchema.bin
                                                                                                        delete tmpValues.bin
                                                                                                    } else {
                                                                                                        delete tmpSchema.inn
                                                                                                        delete tmpValues.inn
                                                                                                    }

                                                                                                    setValues({
                                                                                                        ...tmpValues,
                                                                                                        ...{
                                                                                                            locality: option,
                                                                                                            country: option.region.country.name
                                                                                                        },
                                                                                                        ...params.values
                                                                                                    })

                                                                                                    setCountry(option.region.country.code)
                                                                                                    setSchema({...tmpSchema, ...params.schema})
                                                                                                    return;
                                                                                                }

                                                                                                switch (values.type) {
                                                                                                    case 'individual':
                                                                                                    case 'self':
                                                                                                        delete tmpSchema.name
                                                                                                        delete tmpValues.name
                                                                                                        delete tmpSchema.kpp
                                                                                                        delete tmpValues.kpp
                                                                                                }

                                                                                                if (country === 'ru') {
                                                                                                    delete tmpSchema.bin
                                                                                                    delete tmpValues.bin
                                                                                                } else {
                                                                                                    delete tmpSchema.inn
                                                                                                    delete tmpValues.inn
                                                                                                }

                                                                                                setValues({
                                                                                                    ...tmpValues,
                                                                                                    ...{
                                                                                                        locality: null,
                                                                                                        country: ""
                                                                                                    }
                                                                                                })

                                                                                                setCountry(null)
                                                                                                setSchema(tmpSchema)
                                                                                            }}
                                                                                            renderInput={ params => (
                                                                                                <MuiTextField label={label} {...params} {...textFieldProps} helperText={error ? errors[name] : ''} error={!!error} />
                                                                                            )}
                                                                                        />
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6} className={classes.column}>
                                                                            <Field
                                                                                fullWidth
                                                                                name="country"
                                                                                type="test"
                                                                                label="Страна"
                                                                                disabled={true}
                                                                                component={CustomTextField}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                        <Grid item xs={6} className={classes.column}>
                                                                            <Field
                                                                                fullWidth
                                                                                type="text"
                                                                                name="type"
                                                                                label="Тип компании"
                                                                                select
                                                                                variant="standard"
                                                                                component={TextField}
                                                                                required={true}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                InputProps={{
                                                                                    onChange: (e) => {
                                                                                        const {value, name} = e.target

                                                                                        setFieldValue(name, value)

                                                                                        let tmpSchema = schema
                                                                                        let tmpValues = values

                                                                                        const params = getCountryParams(country, value)

                                                                                        switch (value) {
                                                                                            case 'individual':
                                                                                            case 'self':
                                                                                                delete tmpSchema.name
                                                                                                delete tmpValues.name
                                                                                                delete tmpSchema.kpp
                                                                                                delete tmpValues.kpp
                                                                                        }

                                                                                        if (country === 'ru') {
                                                                                            delete tmpSchema.bin
                                                                                            delete tmpValues.bin
                                                                                        } else {
                                                                                            delete tmpSchema.inn
                                                                                            delete tmpValues.inn
                                                                                        }

                                                                                        setValues({
                                                                                            ...{
                                                                                                ...tmpValues,
                                                                                                ...{
                                                                                                    type: value
                                                                                                }
                                                                                            },
                                                                                            ...params.values
                                                                                        })
                                                                                        setSchema({...tmpSchema, ...params.schema})
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <MenuItem key={1} value="legal">
                                                                                    Юридическое лицо
                                                                                </MenuItem>
                                                                                <MenuItem key={2} value="individual">
                                                                                    Индивидуальный предприниматель
                                                                                </MenuItem>
                                                                                <MenuItem key={3} value="self">
                                                                                    Самозанятый
                                                                                </MenuItem>
                                                                            </Field>
                                                                        </Grid>
                                                                    </Grid>
                                                                    {country ? (
                                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                            <Grid item xs={6} className={classes.column}>
                                                                                {(country === "ru")
                                                                                    ?
                                                                                    <Field
                                                                                        fullWidth
                                                                                        name="inn"
                                                                                        type="text"
                                                                                        label={"ИНН (количество символов: " + ((values.type === "legal") ? "10" : "12") + ")"}
                                                                                        required={true}
                                                                                        component={CustomTextField}
                                                                                    />
                                                                                    :
                                                                                    <Field
                                                                                        fullWidth
                                                                                        name="bin"
                                                                                        type="text"
                                                                                        label="БИН (количество символов: 12)"
                                                                                        required={true}
                                                                                        component={CustomTextField}
                                                                                    />
                                                                                }
                                                                            </Grid>
                                                                            <Grid item xs={6} className={classes.column}>
                                                                                {((country === "ru") && (values.type === "legal")) ? (
                                                                                    <Grid item>
                                                                                        <Field
                                                                                            fullWidth
                                                                                            name="kpp"
                                                                                            type="text"
                                                                                            label="КПП (количество символов: 9)"
                                                                                            required={true}
                                                                                            component={CustomTextField}
                                                                                        />
                                                                                    </Grid>
                                                                                ) : null}
                                                                            </Grid>
                                                                        </Grid>
                                                                    ) : null}
                                                                    {(values.type === 'legal') ? (
                                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                            <Grid item xs={6} className={classes.column}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    name="name"
                                                                                    type="text"
                                                                                    label="Название организации"
                                                                                    required={true}
                                                                                    component={CustomTextField}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    ) : null}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="stretch" spacing={1}>
                                                            <Grid item>
                                                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                                                    <Grid item>
                                                                        <StepIcon
                                                                            icon={3}
                                                                            active={true}
                                                                            classes={{
                                                                                root: classes.stepIcon,
                                                                                text: classes.stepLabel
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography className={classes.stepTitle}>Соглашения, Юридическая документация</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item className={classes.agreement} xs={12}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="processing_of_personal_data"
                                                                    color="primary"
                                                                    Label={{
                                                                        label: <Typography>Я <Link className={classes.link} target="_blank" href="https://drive.google.com/file/d/1GOcLTMqneDO47t7qRrxBeZjSJycZJL9g/view?usp=sharing">согласен</Link> на обработку персональных данных и получение информационных сообщений от ООО ”Эмсофт”</Typography>
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item className={classes.agreement} xs={6}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="privacy_policy"
                                                                    color="primary"
                                                                    Label={{
                                                                        label: <Typography>Я <Link className={classes.link} target="_blank" href="https://drive.google.com/file/d/1sJKUrtifi90CyZDU1m7H88BzCwPhGDDF/view?usp=sharing">согласен</Link> с политикой конфиденциальности</Typography>
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item className={classes.agreement} xs={6}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="agreement"
                                                                    color="primary"
                                                                    Label={{
                                                                        label: <Typography>Я <Link className={classes.link} target="_blank" href="https://drive.google.com/file/d/1eaeCWvSSO9CEVramZfXBa9x33nOQ8OlE/view?usp=sharing">согласен</Link> с пользовательским соглашением</Typography>
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item className={classes.agreement} xs={6}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="offer"
                                                                    color="primary"
                                                                    Label={{
                                                                        label: <Typography>Я <Link className={classes.link} target="_blank" href="https://drive.google.com/file/d/1ObuTwigv3uztBQPFu3VBjCPHwCwmcjhq/view?usp=sharing">принимаю</Link> оферту</Typography>
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.footer}>
                                                <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                                    <Grid item className={classes.footerContent}>
                                                        <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                            <Grid item xs={4}>
                                                                <Submit
                                                                    className={classes.submit}
                                                                    disableElevation
                                                                    variant="contained"
                                                                    color="primary"
                                                                    disabled={isSubmitting || !isValid || !values.agreement || !values.privacy_policy || !values.processing_of_personal_data || !dirty || !country}
                                                                    onClick={handleSubmit}
                                                                    type="submit"
                                                                >
                                                                    Зарегистрироваться
                                                                </Submit>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Paper>
            </Grid>
        </Grid>
    )
}
