import React from "react"

import {Typography, Grid, makeStyles} from "@material-ui/core"

const useStyles = makeStyles(() => ({
    container: {
        "border-radius": 0,
        "height": "100%"
    },
    errorContainer: {
        height: "100vh",
        borderRadius: 0,
    }
}))

export const NotFound = () => {
    const classes = useStyles()

    return (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.container}>
            <Grid item>
                <Grid container direction="row" justify="center" alignItems="flex-start" className={classes.container} spacing={4}>
                    <Grid item>
                        <Typography variant="h1" component="h2" gutterBottom>404</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" className={classes.container}>
                            <Grid item>
                                <Typography variant="h3" gutterBottom>Страница не найдена</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" gutterBottom>К сожалению, страница не найдена</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const Forbidden = () => {
    const classes = useStyles()

    return (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.container}>
            <Grid item>
                <Grid container direction="row" justify="center" alignItems="flex-start" className={classes.container} spacing={4}>
                    <Grid item>
                        <Typography variant="h1" component="h2" gutterBottom>401</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" className={classes.container}>
                            <Grid item>
                                <Typography variant="h3" gutterBottom>Доступ запрещён</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" gutterBottom>К сожалению, у Вас нет доступ к выбранной странице</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const Error = () => {
    const classes = useStyles()

    return (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.errorContainer}>
            <Grid item>
                <Grid container direction="row" justify="center" alignItems="flex-start" className={classes.container} spacing={4}>
                    <Grid item>
                        <Typography variant="h1" component="h2" gutterBottom>500</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" className={classes.container}>
                            <Grid item>
                                <Typography variant="h3" gutterBottom>Ошибка</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" gutterBottom>Что-то пошло не так</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
