import {orderStatuses} from '../dics/order'

const visibleOffers = (lot) => {
    let offersCount = lot.offers.filter(el => el.active === true && el.approve === true)

    return (lot.status.index === orderStatuses.in_work)
        || (lot.status.index === orderStatuses.fail && offersCount > 0)
        || (lot.status.index === orderStatuses.agreementContractWithSingleSupplier)
        || (lot.status.index === orderStatuses.approveSupplierOffers && lot.end_time_of_rebidding !== null)
        || (lot.status.index === orderStatuses.success)
}

export {
    visibleOffers
}
