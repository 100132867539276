import {history} from "../helpers/history"
import {getPath} from "../helpers/http"
import * as AppStorage from '../helpers/storage'

export const HttpService = {
    http,
    get,
    post,
    put,
    deleteMethod
}

function http(location, options = {}, authorization = false, formData = false) {
    options.headers = Object.assign({
        "Accept": "application/json",
    }, options.headers ? options.headers : {})

    if (!formData) {
        options.headers["Content-Type"] = "application/json"
    }

    if (authorization) {
        options.headers.Authorization = AppStorage.get('token')
    }

    if (!(location instanceof Object)) {
        location = {path: location}
    }

    if (options.params && Object.keys(options.params).length) {
        location.path = getPath(location.path, options.params)
    }

    const host = location.hasOwnProperty('host') ? location.host : process.env.REACT_APP_HOST_API

    return fetch(`${host}${location.path}`, options)
        .then((response) => handleResponse(response, options))
}

function handleResponse(response, options) {
    let data
    let headers = response.headers

    if (headers.has("Authorization")) {
        AppStorage.remove('token')
        AppStorage.set('token', headers.get('Authorization'))
    }

    switch (response.status) {
        case 204:
        case 403:
            return response
        case 401:
            AppStorage.remove('account')
            AppStorage.remove('token')
            history.push("/logout")
            break
        default:
            if (options.hasOwnProperty('responseType')) {
                switch (options.responseType) {
                    case 'blob':
                        data = response.blob()
                        break
                    default:
                        data = response.json()
                }
            } else {
                data = response.json()
            }
    }

    if (!response.ok) {
        return data ? data.then(Promise.reject.bind(Promise)) : response
    }

    return data
}

function put(location, values = [], authorization = true) {
    const options = {
        method: "PUT",
        body: JSON.stringify(values)
    }
    return HttpService.http(location, options, authorization)
        .then(response => {
            return response
        })
}

function post(location, values = [], authorization = true, formData = false, responseType = null) {
    const options = {
        ...{
            method: "POST",
            body: formData ? values : JSON.stringify(values)
        },
        ...(responseType ? {responseType: responseType} : {})
    }
    return HttpService.http(location, options, authorization, formData)
        .then(response => {
            return response
        })
}

function get(location, params = [], authorization = true, responseType = null) {
    const options = {
        ...{
            method: "GET",
            params: params
        },
        ...(responseType ? {responseType: responseType} : {})
    }
    return HttpService.http(location, options, authorization)
        .then(response => {
            return response
        })
}

function deleteMethod(location, params = {}, authorization = true, key = 'body') {
    const options = {
        method: "DELETE",
        [key]: (key === 'body') ? JSON.stringify(params) : params,
    }
    return HttpService.http(location, options, authorization)
        .then(response => {
            return response
        })
}
