import {ShopService} from "../services/shop"
import {history} from "../../../../App/helpers/history"

export const ShopActions = {
    orders,
    order,
    add,
    edit,
    cancel,
    deadline,
    status,
    remove,
    approveOffer,
    setEndTimeOfTrading,
    copy,
    sign
}

function orders(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        ShopService.orders(params)
            .then(
                orders => {
                    dispatch({type: "ORDERS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(orders)
                },
                error => {
                    dispatch({type: "ORDERS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function order(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "ORDER_REQUEST"})

        ShopService.order(id)
            .then(
                order => {
                    dispatch({type: "ORDER", payload: order})
                    dispatch({type: "LOADING", payload: false})
                    resolve(order)
                },
                error => {
                    dispatch({type: "ORDER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function add(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "ADD_ORDER_REQUEST"})

        ShopService.add(values)
            .then(
                () => {
                    dispatch({type: "ADD_ORDER_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    if (values?.from_planning) {
                        history.push("/marketplace/publish/purchases")
                    } else {
                        history.push("/marketplace/requests")
                    }
                },
                error => {
                    dispatch({type: "ADD_ORDER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR",
                        payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function edit(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "EDIT_ORDER_REQUEST"})

        ShopService.edit(id, values)
            .then(
                () => {
                    dispatch({type: "EDIT_ORDER_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    if (!!Number(values.get('from_planning'))) {
                        history.push("/marketplace/publish/purchases")
                    } else {
                        history.push("/marketplace/requests")
                    }
                },
                error => {
                    dispatch({type: "EDIT_ORDER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR",
                        payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function status(order_id, lot_id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "STATUS_ORDER_REQUEST"})

        ShopService.status(order_id, lot_id, values)
            .then(
                () => {
                    dispatch({type: "STATUS_ORDER_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "STATUS_ORDER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function cancel(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "STATUS_ORDER_REQUEST"})

        ShopService.cancel(id, values)
            .then(
                () => {
                    dispatch({type: "STATUS_ORDER_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "STATUS_ORDER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function deadline(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DEADLINE_ORDER_REQUEST"})

        ShopService.deadline(id, values)
            .then(
                () => {
                    dispatch({type: "DEADLINE_ORDER_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DEADLINE_ORDER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function remove(id, from_planning = false) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELETE_ORDER_REQUEST"})

        ShopService.remove(id)
            .then(
                () => {
                    dispatch({type: "DELETE_ORDER_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    if (from_planning) {
                        history.push("/marketplace/publish/purchases")
                    } else {
                        history.push("/marketplace/requests")
                    }
                },
                error => {
                    dispatch({type: "DELETE_ORDER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function approveOffer(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "APPROVE_OFFER_REQUEST"})

        ShopService.approveOffer(id, values)
            .then(
                offer => {
                    dispatch({type: "APPROVE_OFFER_SUCCESS", payload: offer})
                    dispatch({type: "LOADING", payload: false})
                    resolve(offer)
                },
                error => {
                    dispatch({type: "APPROVE_OFFER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function setEndTimeOfTrading(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SET_END_TIME_OF_REBIDDING_REQUEST"})

        ShopService.setEndTimeOfTrading(id, values)
            .then(
                resp => {
                    dispatch({type: "SET_END_TIME_OF_REBIDDING_SUCCESS", payload: {
                            lotID: id,
                            end_time_of_rebidding: values.end_time_of_rebidding,
                        }})
                    dispatch({type: "LOADING", payload: false})
                    resolve(resp)
                },
                error => {
                    dispatch({type: "SET_END_TIME_OF_REBIDDING_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function copy(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "COPY_ORDER_REQUEST"})

        ShopService.copy(id, values)
            .then(
                () => {
                    dispatch({type: "COPY_ORDER_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    if (values?.from_planning) {
                        history.push("/marketplace/publish/purchases")
                    } else {
                        history.push("/marketplace/requests")
                    }
                },
                error => {
                    dispatch({type: "COPY_ORDER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function sign(order, file, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        ShopService.sign(order, file, values)
            .then(
                () => {
                    dispatch({type: "LOADING", payload: false})
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
