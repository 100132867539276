import React from "react";

import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@material-ui/core"

import {Submit} from "../Button/Submit"

export const DialogWithChoices = (props) => {
    const {open, onClose, onContinue} = props

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth={'sm'}
        >
            <DialogTitle>Предупреждение!</DialogTitle>
            <DialogContent>
                <Typography variant="h6">Изменения не будут сохранены, Вы хотите продолжить?</Typography>
                <Typography variant="body1">Для того что бы сохранить изменения необходимо нажать кнопку ОТМЕНА и далее
                    СОХРАНИТЬ, после чего можете перейти в другой раздел без потери данных.</Typography>
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justify="flex-end" alignItems="stretch" spacing={2}>
                    <Grid item>
                        <Submit
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={onClose}
                        >
                            Отмена
                        </Submit>
                    </Grid>
                    <Grid item>
                        <Submit
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={onContinue}
                        >
                            Продолжить
                        </Submit>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
