import React from "react"

import {OperatorsContent} from "./OperatorsContent";
import {Menu} from "../../Menu";
import {Menu as AppMenu} from "../../../App/Menu";
import {Content as AppContent} from "../../../App/Content";

export const Operators = (props) => {
    return <React.Fragment>
        <AppMenu
            Component={Menu}
            {...props}
        />
        <AppContent
            Component={OperatorsContent}
            {...props}
        />
    </React.Fragment>
}
