import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce} from 'use-debounce'
import clsx from "clsx"
import {IntegratedSummary} from '@devexpress/dx-react-grid'
import {Grid, InputAdornment, makeStyles, TextField} from "@material-ui/core"
import {GetApp as GetAppIcon, Search as SearchIcon} from "@material-ui/icons"
import {green} from "@material-ui/core/colors"
import {Table} from "@devexpress/dx-react-grid-material-ui";

import {getMonthName} from "../../../../App/helpers/date"
import {ColumnChooser} from "../../../../App/components/Table/ColumnChooser"
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon"
import {LotActions} from "../../../actions/lot"
import {SettingsActions} from "../../../../Settings/actions/settings"
import {DownloadActions} from "../../../../Download/actions/download";
import {CustomTable} from "../../../../App/components/Table/Table";
import {TableHeader} from "../../../../App/components/Table/TableHeader";
import {Edit as EditButton} from "../../Lot/Buttons/Edit";
import {Delete as DeleteButton} from "../../Lot/Buttons/Delete";
import {localState} from "../../../../App/components/LocalState";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.planningContent.width,
        padding: theme.planningContent.padding,
        margin: theme.planningContent.margin,
    },
    container: {
        "background-image": "linear-gradient(to bottom,#f6f6f6,#eee 85%,#b1b1b1)",
        "width": "100%",
        "height": "calc(100vh - 256px)"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    filterLabel: {
        "background": "#fff",
        "margin-bottom": "0 !important",
        "margin-top": "5px !important",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        },
        "& input": {
            padding: "6px 15px 7px"
        }
    },
    tableContainer: {
        "min-height": "100%",
        "height": "calc(100vh - 400px)"
    },
    tableItemsContainer: {},
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    searchLabel: {},
    searchInput: {
        "width": "calc(100% - 83px)"
    },
    searchButton: {
        "margin-bottom": "10px"
    },
    fullWidth: {
        "width": "100%"
    },
    inputContentMain: {
        "width": "100%",
        "height": theme.planningContent.height,
        "min-height": theme.planningContent.height,
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    footer: {
        width: "100%",
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    },
    tabs: {
        "&.MuiTab-textColorPrimary.Mui-selected": {
            backgroundColor: "#fff",
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "4px",
        }
    },
    fontSubtitle: {
        fontWeight: 700
    },
    submit: {
        "height": "35px",
    },
    accordion: {
        "& .MuiAccordionSummary-content": {
            "max-width": "calc(100% - 32px)"
        }
    },
    acDetails: {
        padding: "16px 0"
    },
    active: {
        'height': '41px',
        'background-color': green[100],
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    loading: {
        'height': '41px',
        'background-color': '#fff',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    iconButton: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    },
    backIconButton: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        position: 'absolute',
        left: '205px',
        top: '42px',
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    },
}))

const purchaseType = {
    complete: 'Предоплата',
    partial: 'Частичная предоплата',
    postponement: 'Постоплата'
}

const columns = [
    {name: 'name', title: 'Наименование лота'},
    {name: 'number', title: 'Номер'},
    {name: 'purchase_type', title: 'Тип оплаты', filter: 'types'},
    {name: 'category', title: 'Категория', filter: 'categories'},
    {name: 'prepayment', title: 'Аванс'},
    {name: 'postponement', title: 'Отсрочка'},
    {name: 'funding_sources', title: 'Источник финансирования'},
    {name: 'start_execution_month', title: 'Планируемый месяц начала исполнения договора', filter: 'start_month'},
    {name: 'execution_month', title: 'Месяц исполнения договора', filter: 'month'},
    {name: 'items_count', title: 'Количество ОЗ'},
    {name: 'average_price', title: 'НМЦ'},
    {name: 'purchase_number', title: 'Номер закупки'},
    {name: 'purchase_name', title: 'Название закупки'},
    {name: 'railRoad', title: 'ЖД', filter: 'rail_roads'},
    {name: 'region', title: 'Макрорегион', filter: 'regions'},
    {name: 'company', title: 'ЧУЗ', filter: 'companies'},
]

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {filter} = useSelector(state => state.filters['planning_lots'])

    const [lots, setLots] = useState({...localState,
        agg_info: {
            total_amount: 0,
            total_sum: 0,
        }
    })
    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [search, setSearch] = useState(null)
    const [searchRequestPurchases] = useDebounce(search, 900)
    const [selection, setSelection] = useState([])
    const [selectionItems, setSelectionItems] = useState([])
    const [selectionPage, setSelectionPage] = useState({})
    const [initialize, setInitialize] = useState(false);
    const [tableRef, setTableRef] = useState(null)
    const [loading, setLoading] = useState(false)

    const [tableColumnExtensions] = useState([
        {columnName: 'items_count', align: 'right'},
        {columnName: 'average_price', align: 'right'},
    ]);
    const [totalSummaryItems] = useState([
        {columnName: 'items_count', type: 'total_amount'},
        {columnName: 'average_price', type: 'total_sum'},
    ]);

    const messages = {
        total_amount: 'Кол-во',
        total_sum: 'Итого',
    };

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'name', width: 200},
        {columnName: 'number', width: 200},
        {columnName: 'purchase_type', width: 200},
        {columnName: 'category', width: 250},
        {columnName: 'prepayment', width: 200},
        {columnName: 'postponement', width: 200},
        {columnName: 'funding_sources', width: 200},
        {columnName: 'start_execution_month', width: 200},
        {columnName: 'execution_month', width: 200},
        {columnName: 'items_count', width: 125},
        {columnName: 'average_price', width: 200},
        {columnName: 'purchase_number', width: 200},
        {columnName: 'purchase_name', width: 150},
        {columnName: 'railRoad', width: 125},
        {columnName: 'region', width: 125},
        {columnName: 'company', width: 150}
    ])

    const [columnOrder, setColumnOrder] = useState([
        'name',
        'number',
        'purchase_type',
        'category',
        'prepayment',
        'postponement',
        'funding_sources',
        'start_execution_month',
        'execution_month',
        'items_count',
        'average_price',
        'purchase_number',
        'purchase_name',
        'railRoad',
        'region',
        'company'
    ])

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...((filter.hasOwnProperty('companies') && !!filter.companies.length) ? {companies: filter.companies} : {}),
            ...((filter.hasOwnProperty('rail_roads') && !!filter.rail_roads.length) ? {rail_roads: filter.rail_roads} : {}),
            ...((filter.hasOwnProperty('categories') && !!filter.categories.length) ? {categories: filter.categories} : {}),
            ...((filter.hasOwnProperty('types') && !!filter.types.length) ? {purchase_types: filter.types} : {}),
            ...((filter.hasOwnProperty('start_month') && !!filter.start_month.length) ? {start_execution_month: filter.start_month} : {}),
            ...((filter.hasOwnProperty('month') && !!filter.month.length) ? {execution_month: filter.month} : {}),
            ...((filter.hasOwnProperty('sources') && !!filter.sources.length) ? {sources: filter.sources} : {}),
            ...(searchRequestPurchases ? {search: searchRequestPurchases} : {})
        }
    }

    const getLots = async () => {
        let params = {
            limit: rowsPerPage,
            page: page,
            ...getParams()
        }
        return await dispatch(LotActions.lots(params))
    }

    useEffect(() => {
        if (!loading) {
            getLots().then(response => {
                setLots(response)

                let numbers = []

                if (selectionPage.hasOwnProperty(page)) {
                    response.data.forEach((item, index) => {
                        if (selectionPage[page].find(id => (id === item.id))) {
                            numbers.push(index)
                        }
                    })
                }

                setSelection(numbers)

                setLoading(true)
                tableRef && tableRef.current && tableRef.current.scrollIntoView()
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loading])

    useEffect(() => {
        if (loading) {
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [page, rowsPerPage, searchRequestPurchases, filter])

    useEffect(() => {
        if (!initialize) {
            dispatch(SettingsActions.table('planning_lots')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                dispatch({type: "PLANNING_PURCHASE_PLAN_PURCHASE_LOTS_CLEAR"})
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'planning_lots',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    const summaryCalculator = (type, rows, getValue) => {
        if (type === 'total_amount') {
            return (parseFloat(lots.data.reduce((sum, lot) => {
                return sum + lot.items_count
            }, 0))).toLocaleString('ru-RU');
        }
        if (type === 'total_sum') {
            return Number(lots.agg_info.total_sum).toLocaleString('ru-RU', {style: 'currency', currency: 'RUB',});
        }
        return IntegratedSummary.defaultCalculator(type, rows, getValue);
    };

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: 'PLANNING_LOTS_FILTER', payload: {limitRows: newRowsPerPage}})
    }

    return initialize ? (
            <Grid item className={classes.content}>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item xs={12} style={{marginBottom: '-8px'}}>
                                <TextField
                                    id="search"
                                    variant="standard"
                                    name={'search'}
                                    placeholder={`Поиск: наименование, номер лота`}
                                    fullWidth={true}
                                    margin={'normal'}
                                    onChange={(event) => {
                                        setSearch(event.target.value)
                                    }}
                                    classes={{
                                        root: clsx(classes.filterLabel, classes.searchLabel)
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon style={{color: '#c1c1c1'}}/>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                    <EditButton
                                        disabled={(selectionItems.length !== 1)}
                                        purchase={selectionItems[0]?.purchase}
                                        lot={selectionItems[0]}
                                    />
                                    <DeleteButton
                                        items={selectionItems}
                                        onClick={() => {
                                            setSelection([])
                                            setSelectionItems([])
                                            setSelectionPage({})
                                        }}
                                    />
                                    <Grid item>
                                        <ButtonIcon
                                            type="button"
                                            size="small"
                                            disabled={!lots.meta.total}
                                            onClick={() => {
                                                dispatch(DownloadActions.create({
                                                    type: 'planning_lots',
                                                    columns: columnOrder.filter(name => (hiddenColumnNames.indexOf(name) < 0)),
                                                    params: {
                                                        order: (filter.sort.name && filter.sort.direction) ? `${filter.sort.name}, ${filter.sort.direction}` : `created, desc`,
                                                        type: 'planning_lots',
                                                        ...Object.entries(getParams()).reduce((result, [key, value]) => {
                                                            result[key] = Array.isArray(value) ? value.join(',') : value

                                                            return result
                                                        }, {})
                                                    }
                                                }))
                                            }}
                                            children={<GetAppIcon/>}
                                            title='Скачать таблицу'
                                            className={classes.iconButton}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <CustomTable
                        meta={lots.meta}
                        tableName={'planning_lots'}
                        rows={lots.data.map(lot => ({
                            name: lot.name,
                            number: lot.number,
                            purchase_type: purchaseType[lot.purchase_type],
                            category: (lot.category.id === lot.category.index) ? lot.category.name : `${lot.category.name} (${lot.category.root.name})`,
                            prepayment: lot.prepayment !== null ? lot.prepayment + ' %' : '',
                            postponement: lot.postponement !== null ? lot.postponement + ', дней' : '',
                            funding_sources: lot.funding_sources ? JSON.parse(lot.funding_sources).join(', ') : '',
                            start_execution_month: getMonthName(new Date(lot.start_execution_month)),
                            execution_month: getMonthName(new Date(lot.execution_month)),
                            items_count: lot.items_count ? parseFloat(lot.items_count).toLocaleString('ru-RU') : 0,
                            average_price: lot.average_price ? parseFloat(lot.average_price).toLocaleString('ru-RU', {
                                style: 'currency',
                                currency: 'RUB'
                            }) : 0,
                            purchase_name: lot.purchase_name,
                            purchase_number: lot.purchase_number,
                            railRoad: lot.purchase.company.railRoad?.name,
                            region: lot.purchase.company.region?.name,
                            company: lot.purchase.company.name
                        }))}
                        columns={columns}
                        page={{page: page, setPage: setPage, rowsPerPage: rowsPerPage, handlePageSizeChange: handlePageSizeChange}}
                        selection={{selection: selection, setSelection: setSelection}}
                        totalSummaryItems={totalSummaryItems}
                        summaryCalculator={summaryCalculator}
                        setTableRef={setTableRef}
                        tableColumnExtensions={tableColumnExtensions}
                        columnsSettings={{columnOrder: columnOrder, setColumnOrder: setColumnOrder, setColumnWidths: setColumnWidths, columnWidths: columnWidths, hiddenColumnNames: hiddenColumnNames, setHiddenColumnNames: setHiddenColumnNames}}
                        tableHeader={TableHeader}
                        filterActionType={'PLANNING_LOTS_FILTER'}
                        getValues={LotActions.lotsFilter}
                        messages={messages}
                        onSelectionChange={(numbers) => {
                            setSelection(numbers)
                            const data = lots.data.filter((item, index) => (numbers.find(key => (key === index)) !== undefined)).map(item => item)
                            setSelectionItems([
                                ...data,
                                ...selectionItems.filter(item => !lots.data.find(el => el.id === item.id))
                            ])
                            setSelectionPage(!!data.length ? {...selectionPage, [page]: data.map(item => item.id)} : Object.fromEntries(
                                Object.entries(selectionPage).filter(key => (parseInt(key) !== parseInt(page))).map(([key, value]) => [key, value])
                            ))
                        }}
                        main
                        classInputContent={classes.inputContentMain}
                        rowComponentSelection={(props) => {
                            const {tableRow, children, highlighted, onToggle} = props
                            const {row} = tableRow

                            return (
                                <Table.Row
                                    tableRow={tableRow}
                                    children={children}
                                    onClick={() => {
                                        if (!window.getSelection().toString()) {
                                            onToggle()
                                        }
                                    }}
                                    className={highlighted ? classes.active : ((row.loaded === 'Загружен') ? classes.default : classes.loading)}
                                    row={row}
                                />
                            )
                        }}
                        rowComponent={({row, tableRow, children}) => (
                            <Table.Row
                                tableRow={tableRow}
                                children={children}
                                className={classes.default}
                                row={row}
                            />
                        )}
                        filters={{
                            purchase_type: {
                                name: 'types',
                                type: 'values',
                            },
                            start_execution_month: {
                                name: 'start_month',
                                type: 'month',
                            },
                            execution_month: {
                                name: 'month',
                                type: 'month',
                            },
                            category: {
                                name: 'categories',
                                type: 'values',
                            },
                            railRoad: {
                                name: 'rail_roads',
                                type: 'values',
                            },
                            region: {
                                name: 'regions',
                                type: 'values',
                            },
                            company: {
                                name: 'companies',
                                type: 'values',
                            },
                            funding_sources: {
                                name: 'sources',
                                type: 'source',
                            },
                        }}
                    />
                </Grid>
            </Grid>
        ) : null
}
