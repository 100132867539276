import {HttpService} from "./http"

export const SystemService = {
    cities,
    currencies,
    regions,
    banks,
    categories,
    categoriesTree,
    units,
    status,
    items,
    generics,
    getRFCBRateCoefficient,
    customers,
    file
}

function cities(params) {
    return HttpService.get("/app/cities", params)
        .then(response => {
            return response.cities
        })
}

function currencies(params) {
    return HttpService.get("/app/currencies", params)
        .then(response => {
            return response.currencies
        })
}

function regions(params) {
    return HttpService.get("/app/regions", params)
        .then(response => {
            return response.regions
        })
}

function banks(params) {
    return HttpService.get("/app/banks", params)
        .then(response => {
            return response.banks
        })
}

function categories(params) {
    return HttpService.get("/app/categories", params, false)
        .then(response => {
            return response.categories
        })
}

function categoriesTree(params) {
    return HttpService.get("/app/categoriesTree", params, false)
        .then(response => {
            return response.categories
        })
}

function units() {
    return HttpService.get(`/app/units`)
        .then(response => {
            return response.units
        })
}

function status(params) {
    return HttpService.get("/app/order/status", params, false)
        .then(response => {
            return response.status
        })
}

function items(params) {
    return HttpService.get({path: "/items", host: process.env.REACT_APP_SERVICE_NOMENCLATURE_API}, params, false)
}

function generics(params) {
    return HttpService.get({
        path: "/dictionary/generics/items",
        host: process.env.REACT_APP_SERVICE_NOMENCLATURE_API
    }, params, false)
        .then(response => {
            return response.data
        })
}

function getRFCBRateCoefficient() {
    return HttpService.get(`/app/rf-cb-rate-coefficients`, [], false)
        .then(response => {
            return response.rf_cb_rate_coefficients
        })
}

function customers(params) {
    return HttpService.get(`/app/customers`, params, false)
        .then(response => {
            return response
        })
}

function file(path) {
  return HttpService.get(path, null, true, 'blob')
}
