import {CompanyService} from "../services/company"

export const CompanyActions = {
    customers,
    customer,
    suppliers,
    supplier,
    regions,
    railroads,
    filter
}

function customers(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CUSTOMERS_REQUEST"})

        CompanyService.customers(params)
            .then(
                customers => {
                    dispatch({type: "CUSTOMERS_SUCCESS", payload: customers})
                    dispatch({type: "LOADING", payload: false})
                    resolve(customers)
                },
                error => {
                    dispatch({type: "CUSTOMERS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function customer(id, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CUSTOMER_REQUEST"})

        CompanyService.customer(id, params)
            .then(
                customer => {
                    dispatch({type: "CUSTOMER_SUCCESS", payload: customer})
                    dispatch({type: "LOADING", payload: false})
                    resolve(customer)
                },
                error => {
                    dispatch({type: "CUSTOMER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function suppliers(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SUPPLIERS_REQUEST"})

        CompanyService.suppliers(params)
            .then(
                suppliers => {
                    dispatch({type: "SUPPLIERS_SUCCESS", suppliers})
                    dispatch({type: "LOADING", payload: false})
                    resolve(suppliers)
                },
                error => {
                    dispatch({type: "SUPPLIERS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}


function supplier(id, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SUPPLIER_REQUEST"})

        CompanyService.supplier(id, params)
            .then(
                supplier => {
                    dispatch({type: "SUPPLIER_SUCCESS", payload: supplier})
                    dispatch({type: "LOADING", payload: false})
                    resolve(supplier)
                },
                error => {
                    dispatch({type: "SUPPLIER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function regions() {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        CompanyService.regions()
            .then(
                regions => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(regions)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function railroads() {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "RAILROADS_REQUEST"})

        CompanyService.railroads()
            .then(
                railroads => {
                    dispatch({type: "RAILROADS_SUCCESS", payload: railroads})
                    dispatch({type: "LOADING", payload: false})
                    resolve(supplier)
                },
                error => {
                    dispatch({type: "RAILROADS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}


function filter(params) {
    return dispatch => new Promise((resolve, reject) => {
        CompanyService.filter(params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    reject(error)
                }
            )
    })
}
