import * as Yup from "yup"

function getValidationSchema() {
  return Yup.object().shape({
        login: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        password: Yup.string().min(8, "Длина пароля должна быть не менее 8 символов!"),
        confirmation: Yup.string().when("password", (password, schema) => {
            return password ? schema.required("Поле не может быть пустым или состоять из одних пробелов!") : schema
        }).oneOf([Yup.ref("password"), null], "Пароли не совпадают!"),
        email: Yup.string().email("Некорректный формат электронной почты!").required("Поле не может быть пустым или состоять из одних пробелов!"),
        skype: Yup.string(),
        last_name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        first_name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        middle_name: Yup.string(),
        phones: Yup.array().of(Yup.object().shape({
            number: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').when("extra", (extra, schema) => {
                return extra ? schema.required("Поле не может быть пустым или состоять из одних пробелов!") : schema
            }),
            extra: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
        })),
        position: Yup.string()
    })
}
export {getValidationSchema}
