import React from "react"
import {useSelector} from "react-redux"

import {
    Grid,
    makeStyles
} from "@material-ui/core"

import {history} from "../../../../App/helpers/history"
import {Submit} from "../../../../App/components/Button/Submit"
import {purchase as rules} from "../../../helpers/permissions/rules"

const useStyles = makeStyles(theme => ({
    button: {
        "height": "35px",
    }
}))

export const Create = (props) => {
    const classes = useStyles()

    const { plan } = props

    const {account} = useSelector(state => state.account)

    return rules.create.hasOwnProperty(account.group) ? (
        <Grid item>
            <Submit
                className={classes.button}
                disableElevation
                disabled={!(
                    rules.create[account.group].hasOwnProperty(plan.status.index) &&
                    (
                        !rules.create[account.group][plan.status.index].hasOwnProperty('correct') ||
                        (rules.create[account.group][plan.status.index].hasOwnProperty('correct') && rules.create[account.group][plan.status.index]['correct'].includes(plan.editRequestStatus?.index))
                    )
                )}
                variant="contained"
                color="primary"
                type="button"
                onClick={() => {
                    history.push(`/planning/purchase-plan/${plan.id}/purchase/add`)
                }}
            >
                Добавить закупку
            </Submit>
        </Grid>
    ) : null
}
