export default function supMarketplace(state = {orders: {data: [], meta: {total: 0, per_page: 15, current_page: 1}}, order: null}, action) {
    switch (action.type) {
        case "SUP_MARKETPLACE_ORDERS_REQUEST":
            return {
                orders: state.orders
            }
        case "SUP_MARKETPLACE_ORDERS":
            return {
                orders: action.payload,
            }
        case "SUP_MARKETPLACE_ORDERS_FAILURE":
            return {
                orders: state.orders
            }
        default:
            return state
    }
}
