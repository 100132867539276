import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import format from "date-fns/format"
import {ru} from "date-fns/locale"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Link,
    makeStyles,
    StepIcon,
    Typography
} from "@material-ui/core"
import {Close as CloseIcon, AttachFile as AttachFileIcon} from "@material-ui/icons"

import {ReclamationActions} from "../actions/reclamation"
import {AuthorizationService} from "../../Auth/services/authorization"
import {Submit} from "../../App/components/Button/Submit";
import {Confirmation} from "./Сonfirmation";
import * as AppStorage from "../../App/helpers/storage";

const useStyles = makeStyles(() => ({
    item: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "cursor": "pointer"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    fullWidth: {
        "width": "100%"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    content: {
        "padding": "0 !important"
    },
    input: {
        "width": "100%",
    },
    column: {
        "height": "86px"
    },
    section: {
        "border-right": "2px solid #c1c1c1",
        "padding": "20px 30px",
        "overflow-y": "auto",
        "max-height": "calc(80vh - 63px)"
    },
    additionally: {
        "max-height": "calc(80vh - 63px)"
    },
    dialogContent: {
        "max-height": "calc(100vh - 210px)"
    },
    footer: {
        "background-color": "#e7eaee",
        "justify-content": "flex-start"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    dialog: {
        "max-height": "70vh"
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    searchLabel: {
        "margin-bottom": "15px",
    },
    footerContent: {
        "padding": "10px 0px"
    },
    eclipsis: {
        "overflow": "hidden",
        "text-overflow": "ellipsis",
        "white-space": "nowrap"
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    titleDialog: {
        "& h2": {
            "display": "flex",
            "justify-content": "space-between",
        }
    }
}))

export const Reclamation = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { account, reclamation }  = useSelector(state => { return {...state.reclamation, ...state.account}})

    const [open, setOpen] = useState(props.open)
    const [company, setCompany] = useState(null)
    const [confirmation, setConfirmation] = useState(false)
    const [type, setType] = useState(null)
    const [success, setSuccess] = useState(false)
    const { setEdit } = props

    useEffect(() => {
        const getReclamation = async () => {
            await dispatch(ReclamationActions.reclamation(props.open))
        }

        if (props.open) {
            getReclamation().then(() => {
                setOpen(props.open)
            })
        } else {
            setOpen(props.open)
            dispatch({type: 'DELIVERY_RECLAMATION', payload: null})
        }
    }, [dispatch, props.open]);

    useEffect(() => {
        if (reclamation) {
            setCompany(AuthorizationService.roles(account, 'supplier') ? reclamation.delivery.contract.company : reclamation.delivery.contract.offer.company)
        } else {
            setCompany(null)
        }
        // eslint-disable-next-line
    }, [reclamation]);

    const handleClose = () => {
        setOpen(null)
        setCompany(null)
        props.setDialog(false)
    }

    useEffect(() => {
        if (success) {
            setSuccess(false)
            handleClose()
        }
        // eslint-disable-next-line
    }, [success])

    return (reclamation && company) ? (
        <Grid item>
            <Dialog
                open={!!open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle className={classes.titleDialog}>
                    Карточка рекламации
                    <CloseIcon className={classes.close} onClick={handleClose}/>
                </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                    <Grid item>
                                        <Typography className={classes.title}>Рекламация № {reclamation.id}</Typography>
                                        <Typography variant="subtitle2">{format(new Date(reclamation.created), 'H:mm PP',  {locale: ru})}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                    <Grid item>
                                        <StepIcon icon={1} active={true} classes={{
                                            root: classes.stepIcon,
                                            text: classes.stepLabel
                                        }}/>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.stepTitle}>{AuthorizationService.roles(account, "customer") ? 'Реквизиты поставщика' : 'Реквизиты заказчика'}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography variant="button">
                                            Наименование организации:
                                        </Typography>
                                        <Typography>
                                            {company.legal_detail.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                {(company.legal_detail.city.region.country.code === 'ru')
                                    ?
                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                        <Grid item xs={4}>
                                            <Typography variant="button">
                                                ИНН:
                                            </Typography>
                                            <Typography>
                                                {company.legal_detail.inn}
                                            </Typography>
                                        </Grid>
                                        {(company.type === 'legal' || !company.type) &&
                                            <Grid item xs={4}>
                                                <Typography variant="button">
                                                    КПП:
                                                </Typography>
                                                <Typography>
                                                    {company.legal_detail.kpp}
                                                </Typography>
                                            </Grid>
                                        }
                                        {(company.legal_detail.ogrn) &&
                                            <Grid item xs={4}>
                                                <Typography variant="button">
                                                    ОГРН:
                                                </Typography>
                                                <Typography>
                                                    {company.legal_detail.ogrn}
                                                </Typography>
                                            </Grid>
                                        }
                                    </Grid>
                                    :
                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                        <Grid item xs={4}>
                                            <Typography variant="button">
                                                БИН:
                                            </Typography>
                                            <Typography>
                                                {company.legal_detail.bin}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography variant="button">
                                            № договора:
                                        </Typography>
                                        <Typography>
                                            {reclamation.delivery.contract.number ?? reclamation.delivery.contract.id}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="button">
                                            № заказа:
                                        </Typography>
                                        <Typography>
                                            {reclamation.delivery.number}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                    <Grid item>
                                        <StepIcon icon={2} active={true} classes={{
                                            root: classes.stepIcon,
                                            text: classes.stepLabel
                                        }}/>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.stepTitle}>Данные о товаре/услуге</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography variant="button">
                                            Товар/Услуга:
                                        </Typography>
                                        <Typography>
                                            {reclamation.item.item.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography variant="button">
                                            № подтверждающего документа:
                                        </Typography>
                                        <Typography>
                                            {reclamation.number}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography variant="button">
                                            Количество:
                                        </Typography>
                                        <Typography>
                                            {reclamation.amount}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="button">
                                            Дата обнаружения:
                                        </Typography>
                                        <Typography>
                                            {format(new Date(reclamation.discovered), 'PP',  {locale: ru})}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="button">
                                            Срок исправления недостатков:
                                        </Typography>
                                        <Typography>
                                            {format(new Date(reclamation.correction), 'PP',  {locale: ru})}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography variant="button">
                                            Требование рекламации:
                                        </Typography>
                                        <Typography>
                                            {reclamation.demand.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="button">
                                            Тип товарной претензии:
                                        </Typography>
                                        <Typography>
                                            {reclamation.type.name}
                                        </Typography>
                                    </Grid>
                                    {!!reclamation.claim &&
                                        <Grid item xs={4}>
                                            <Typography variant="button">
                                                Претензия:
                                            </Typography>
                                            <Typography>
                                                {reclamation.claim}
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography variant="button">
                                            Описание проблемы:
                                        </Typography>
                                        <Typography>
                                            {reclamation.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                    <Grid item xs={6}>
                                        <Typography variant="button">
                                            История изменения статуса рекламации:
                                        </Typography>
                                        {reclamation.log.map(log => (
                                            <React.Fragment key={log.id}>
                                                <Typography>
                                                    {format(new Date(log.created), 'H:mm PP',  {locale: ru})}: {log.status.name} ({log.user.last_name} {log.user.first_name.substring(1, 1)}. {log.user.middle_name.substring(1, 1)}.)
                                                </Typography>
                                                <Typography>
                                                    {log.comment}
                                                </Typography>
                                            </React.Fragment>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                    {reclamation.files.map(file => (
                                        <Grid item key={file.id}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <AttachFileIcon fontSize="small" style={{color: '#616161'}} />
                                                </Grid>
                                                <Grid item>
                                                    <Link href={process.env.REACT_APP_HOST_API + `/contract/delivery/reclamation/${reclamation.id}/file/${file.id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}>{file.name}</Link>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    {
                        (AuthorizationService.roles(account, "supplier") && (reclamation.status.key === "3")) &&
                            <DialogActions className={classes.footer}>
                                <Grid container className={classes.footerContent} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Submit
                                            variant="contained"
                                            component="label"
                                            color="primary"
                                            type="submit"
                                            onClick={() => {
                                                setConfirmation(true)
                                                setType({type: 'accept', name: 'Принятие'})
                                            }}
                                        >
                                            Принять
                                        </Submit>
                                    </Grid>
                                    <Grid item>
                                        <Submit
                                            variant="contained"
                                            component="label"
                                            color="primary"
                                            type="submit"
                                            onClick={() => {
                                                setConfirmation(true)
                                                setType({type: 'reject', name: 'Отклонение'})
                                            }}
                                        >
                                            Отклонить
                                        </Submit>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                    }
                    {
                        (AuthorizationService.roles(account, "customer") && ((reclamation.status.key === "1") || (reclamation.status.key === "3") || (reclamation.status.key === "4"))) &&
                            ((reclamation.status.key === "3")
                            ?
                                <DialogActions className={classes.footer}>
                                    <Grid container className={classes.footerContent} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Submit
                                                variant="contained"
                                                component="label"
                                                color="primary"
                                                type="submit"
                                                onClick={() => {
                                                    setEdit(reclamation)
                                                    handleClose()
                                                }}
                                            >
                                                Редактировать
                                            </Submit>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            :
                                <DialogActions className={classes.footer}>
                                    <Grid container className={classes.footerContent} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Submit
                                                variant="contained"
                                                component="label"
                                                color="primary"
                                                type="submit"
                                                onClick={() => {
                                                    setConfirmation(true)
                                                    setType({type: 'close', name: 'Закрытие'})
                                                }}
                                            >
                                                Закрыть
                                            </Submit>
                                        </Grid>
                                        <Grid item>
                                            <Submit
                                                variant="contained"
                                                component="label"
                                                color="primary"
                                                type="submit"
                                                onClick={() => {
                                                    setConfirmation(true)
                                                    setType({type: 'not_executed', name: 'Закрытие'})
                                                }}
                                            >
                                                Не исполнено
                                            </Submit>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            )
                    }

            </Dialog>
            <Confirmation open={confirmation} setDialog={setConfirmation} type={type} reclamation={reclamation} setSuccess={setSuccess}/>
        </Grid>
    ) : null
}
