import {OfferService} from "../services/offer"

export const OfferActions = {
    getPriceOffer,
}

function getPriceOffer(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        OfferService.getPriceOffer(values)
            .then(
                data => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(data)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
