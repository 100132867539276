import React, {forwardRef, useState} from "react"

import {IconButton, ListItemIcon, ListItemText, makeStyles, MenuItem, Typography} from "@material-ui/core"
import {ExpandLess, ExpandMore} from "@material-ui/icons"
import {green} from "@material-ui/core/colors"

const useStyles = makeStyles(() => ({
    activeCategory: {
        'background-color': green[100],
        '&:hover': {
            'background-color': 'rgba(0, 0, 0, 0.04) !important',
        }
    },
    defaultCategory: {
        '&:hover': {
            'background-color': 'rgba(0, 0, 0, 0.04) !important',
        }
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    },
}))

export const CategoriesTree = forwardRef(props =>  {
    const classes = useStyles()
    const {categories, category, callback} = props
    const [collapse, setCollapse] = useState({})

    const assembly = (categories, parent = 0, level = 0, disabled = false) => {
        let result = []

        if (categories.hasOwnProperty(parent)) {
            categories[parent].forEach(option => {
                result.push(
                    <MenuItem
                        onClick={() => {
                            callback(option)
                        }}
                        key={ option.id }
                        value={ option.id }
                        style={{ paddingLeft: `${16 * (level + 1)}px`}}
                        disabled={ disabled }
                        className={ (Array.isArray(category) ? category.find(el => { return el.id === option.id}) : (category.hasOwnProperty('id') && option.id === category.id)) ? classes.activeCategory : classes.defaultCategory }
                    >
                        {categories.hasOwnProperty(option.id) &&
                            <ListItemIcon className={ classes.listItemIcon }>
                                <IconButton
                                    size="small"
                                    onClick={e => {
                                        e.stopPropagation()
                                        if (categories.hasOwnProperty(option.id)) {
                                            setCollapse({...collapse, ...{[option.id]: collapse.hasOwnProperty(option.id) ? !collapse[option.id] : true}})
                                        }
                                    }}
                                >
                                    {(collapse.hasOwnProperty(option.id) && collapse[option.id]) ? <ExpandLess/> : <ExpandMore/>}
                                </IconButton>
                            </ListItemIcon>
                        }
                        <ListItemText className={ categories.hasOwnProperty(option.id) ? classes.listItemTextWithIcon : classes.listItemText } primary={ !level ? <Typography variant="body1">{option.name}</Typography> : <Typography variant="body2">{option.name}</Typography> } />
                    </MenuItem>
                )

                const childes = assembly(categories, option.id, level + 1)

                if (!!childes.length && collapse.hasOwnProperty(option.id) && collapse[option.id]) {
                    result = result.concat([
                        childes.map(row => row)
                    ])
                }
            })
        }

        return result
    }

    const getCategoriesTree = categories => {
        let tmp = {}

        categories.forEach(category => {
            if (!tmp.hasOwnProperty((category.category !== null) ? category.category.id : 0)) {
                tmp[(category.category !== null) ? category.category.id : 0] = []
            }

            tmp[(category.category !== null) ? category.category.id : 0].push(category)
        })
        return assembly(tmp)
    }

    return getCategoriesTree(categories)
})
