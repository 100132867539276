export default function lot(state = {lots: {data: [], meta: {total: 0, per_page: 15, current_page: 1}}, type: null}, action) {
    switch (action.type) {
        case "REPORT_LOTS_REQUEST":
            return state
        case "REPORT_LOTS":
            return {
                ...state,
                ...{
                    lots: action.payload,
                }
            }
        case "REPORT_LOTS_FAILURE":
            return state
        default:
            return state
    }
}
