export default function price(state = {getPrice: true}, action) {
    switch (action.type) {
        case "PRICE_REQUEST":
            return {}
        case "PRICE":
            return {
                ...state,
                prices: action.payload,
            }
        case "PRICE_FAILURE":
            return {
                ...state,
                prices: null,
            }
        case "PRICE_SAVE_REQUEST":
            return state
        case "PRICE_SAVE":
            return state
        case "PRICE_SAVE_FAILURE":
            return state
        case "PRICE_DELETE_REQUEST_REQUEST":
            return state
        case "PRICE_DELETE":
            state.prices.data = state.prices.data.map(item => {
                if (item.id !== action.payload.id) {
                  return item
                }
                return {
                  ...item,
                  ...action.payload
                }
              })
            return state
        case "PRICE_DELETE_FAILURE":
            return state
        case "SET_ACTIVE_PRICE":
            return {
                ...state,
                activePrice: action.payload
            }
        case "SET_GET_PRICE":
            return {
                ...state,
                getPrice: action.payload
            }
        default:
            return state
    }
}
