import {HttpService} from "../../App/services/http"

export const CartService = {
    cart,
    carts,
    order,
    remove
}

function cart(id) {
    return HttpService.get(`/marketplace/cart/${id}`)
        .then(response => {
            return response.cart
        })
}

function carts(id, params) {
    return HttpService.get(`/marketplace/carts`, params)
        .then(response => {
            return response.carts
        })
}

function order(id, formData) {
    return HttpService.post(`/marketplace/cart/${id}/order`, formData, true, true)
}

function remove(id) {
    return HttpService.deleteMethod(`/marketplace/cart/${id}`)
}
