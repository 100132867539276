import React, {useEffect, useState} from "react"

import {ItemActions} from "../../../../actions/AdditionalAgreement/item";

import {useDispatch, useSelector} from "react-redux"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputAdornment,
    makeStyles,
    MenuItem,
    TextField as MuiTextField,
    Typography,
} from "@material-ui/core"

import {Submit} from "../../../../../App/components/Button/Submit";
import {Close as CloseIcon} from "@material-ui/icons";
import {Field, Form, Formik, getIn} from "formik";
import * as Yup from "yup";
import {TextFieldWithError as CustomTextField} from "../../../../../App/components/Inputs/TextFieldWithError";
import {Autocomplete} from "@material-ui/lab";
import {SystemActions} from "../../../../../App/actions/system";
import {useDebounce} from "use-debounce";
import {TextField} from "formik-material-ui";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ru from "date-fns/locale/ru";

const useStyles = makeStyles(() => ({
    column: {
        "height": "86px"
    },
    text: {
        "height": "118px"
    },
    footer: {
        "background-color": "#e7eaee",
        "justify-content": "flex-start",
        minHeight: "30px",
    },
    footerContent: {
        "padding": "10px 0px"
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    titleDialog: {
        "& h2": {
            "display": "flex",
            "justify-content": "space-between",
        },
    },
}))

export const Create = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { units, items } = useSelector(state => ({...state.system, ...{items: state.system.items.data}}))

    const { contract, open, onClose, onClick } = props

    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)

    useEffect(() => {
        const getItems = async () => {
            return await dispatch(SystemActions.items({
                status: ['active', 'confirmed'],
                ...{search: searchRequest, category: contract.category.id, limit: 100}
            }))
        }

        if (searchRequest) {
            getItems().then(() => {})
        } else {
            dispatch({type: 'SYSTEM_ITEMS_CLEAR'})
        }
        // eslint-disable-next-line
    }, [dispatch, searchRequest])

    const unitAdornment = (standard, name = '') => {
        if (standard instanceof Object) {
            if (standard.hasOwnProperty('values')) {
                const attribute = standard.values.find(value => (value.attribute.name === 'Фасовка/Вес') || (value.attribute.name === 'Фасованный/Весовой'))

                name = 'шт'

                if (attribute) {
                    if (attribute.value.toLowerCase() === 'вес') {
                        let unit = standard.values.find(value => (value.attribute.type.key === 'unit'));

                        if (unit) {
                            name = unit.value
                        }
                    }
                }

                return name
            }
        }

        return name
    }

    return (
        <Dialog
            open={!!open}
            onClose={onClose}
            fullWidth={true}
            maxWidth={'sm'}
        >
            <Formik
                enableReinitialize
                initialValues={{
                    standard: '',
                    article: '',
                    amount: 0,
                    min_ordered_quantity: 0,
                    unit: '',
                    price: 0,
                    tax: -1,
                    sum: 0,
                    expiration_date_type: '',
                    expiration_date: null,
                    expiration_date_number: ''
                }}
                validationSchema={Yup.object().shape({
                    standard: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                    article: Yup.string(),
                    amount: Yup.number().min(0.0001, 'Поле не может быть пустым или состоять из одних пробелов!').required("Поле не может быть пустым или состоять из одних пробелов!"),
                    min_ordered_quantity: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
                    unit: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                    price: Yup.number().min(0.0001, 'Поле не может быть пустым или состоять из одних пробелов!').required("Поле не может быть пустым или состоять из одних пробелов!"),
                    tax: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
                    expiration_date_type: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                    expiration_date: Yup.mixed().when("expiration_date_type", {
                        is: 'expiration_date',
                        then: Yup.date().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        otherwise: Yup.mixed()
                    }),
                    expiration_date_number: Yup.mixed().when("expiration_date_type", {
                        is: type => (type !== 'expiration_date'),
                        then: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        otherwise: Yup.mixed()
                    })
                })}
                onSubmit={(values, {setSubmitting}) => {
                    return dispatch(ItemActions.create(contract.id, values)).then(
                        item => {
                            onClick(item)
                            onClose()
                        },
                        () => {
                            setSubmitting(false);
                        }
                    )
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      setValues,
                      setFieldValue,
                      setFieldTouched,
                      isSubmitting,
                      handleSubmit
                  }) => (
                    <Form>
                        <DialogTitle className={classes.titleDialog}>
                            Добавить позицию
                            <CloseIcon
                                className={classes.close}
                                onClick={onClose}
                            />
                        </DialogTitle>
                        <DialogContent>
                            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                <Grid item>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                        <Grid item xs={9} className={classes.column}>
                                            <Autocomplete
                                                name={`standard`}
                                                required
                                                options={items}
                                                filterOptions={(options) => {
                                                    return options
                                                }}
                                                value={values.standard}
                                                getOptionLabel={item => item ? ((item instanceof Object) ? item.name : item) : ''}
                                                noOptionsText='Укажите название товара'
                                                disabled={!contract.category}
                                                freeSolo={!contract.category.active}
                                                classes={{
                                                    ...{option: classes.option},
                                                    ...((values.standard && !values.standard.confirmed) ? {inputRoot: classes.inputRootConfirmation} : {})
                                                }}
                                                onChange={(e, item) => {
                                                    setFieldValue(`standard`, item)
                                                    setFieldValue(`unit`, '')
                                                    setSearch({
                                                        value: '',
                                                        lot: null
                                                    })

                                                    if (item) {
                                                        setFieldValue(`amount`, 1)
                                                        if (item instanceof Object) {
                                                            const unit = units.find(unit => unit.short === unitAdornment(item))

                                                            if (unit) {
                                                                setFieldValue(`unit`, unit)
                                                            }
                                                        }
                                                    }
                                                }}
                                                renderOption={option => (
                                                    <Typography className={option.confirmed ? classes.default : classes.confirmation}>{option.name}</Typography>
                                                )}
                                                renderInput={params => {
                                                    const error = getIn(touched, `standard`) && getIn(errors, `standard`);

                                                    return <Field
                                                        fullWidth
                                                        component={MuiTextField}
                                                        required
                                                        placeholder="Начните вводить наименование..."
                                                        {...params}
                                                        onChange={(e) => {
                                                            if (contract.category.active) {
                                                                setSearch(e.target.value)
                                                            } else {
                                                                setFieldValue(`standard`, e.target.value)
                                                            }
                                                        }}
                                                        error={!!error}
                                                        helperText={error}
                                                        name={`standard`}
                                                        label="Наименование объекта закупки"
                                                    />
                                                }}
                                                onFocus={() => {
                                                    dispatch({type: 'SYSTEM_ITEMS_CLEAR'})
                                                    setFieldTouched(`standard`, true, true)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3} className={classes.column}>
                                            <Field
                                                fullWidth
                                                name={`article`}
                                                type="text"
                                                label="Артикул"
                                                inputProps={{
                                                    step: 0.01,
                                                    min: 1
                                                }}
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                fullWidth
                                                name={`amount`}
                                                type="number"
                                                required
                                                label="Кол-во"
                                                step={0.0001}
                                                inputProps={{
                                                    step: 0.0001,
                                                    min: 0,
                                                    onChange: (e) => {
                                                        setValues({
                                                            ...values,
                                                            amount: e.target.value,
                                                            sum: values.price * e.target.value
                                                        })
                                                    }
                                                }}
                                                endAdornment={<InputAdornment position="end">{unitAdornment(values.standard, values.unit.short)}</InputAdornment>}
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.column}>
                                            <Autocomplete
                                                options={units}
                                                name={`unit`}
                                                value={values.unit}
                                                required
                                                getOptionLabel={unit => {
                                                    return unit ? unit.name : ''
                                                }}
                                                onChange={(e, unit) => {
                                                    setFieldValue(`unit`, unit ? unit : '')
                                                }}
                                                noOptionsText='Единица измерения'
                                                renderInput={params => {
                                                    const error = getIn(touched, `unit`) && getIn(errors, `unit`);

                                                    return <Field
                                                        fullWidth
                                                        component={MuiTextField}
                                                        required
                                                        {...params}
                                                        error={!!error}
                                                        helperText={(error instanceof Object) && error.hasOwnProperty('id') ? error.id : null}
                                                        name={`unit`}
                                                        label="Единица измерения"
                                                    />
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                fullWidth
                                                name={`min_ordered_quantity`}
                                                type="number"
                                                required
                                                label="Мин. количество товара для заказа"
                                                step={0.0001}
                                                inputProps={{
                                                    step: 0.0001,
                                                    min: 0
                                                }}
                                                endAdornment={<InputAdornment position="end">{unitAdornment(values.standard, values.unit.short)}</InputAdornment>}
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                fullWidth
                                                name={`price`}
                                                type="number"
                                                label="Цена за ед. товара"
                                                required
                                                step={0.01}
                                                inputProps={{
                                                    step: 0.01,
                                                    min: 0,
                                                    onChange: (e) => {
                                                        setValues({
                                                            ...values,
                                                            price: e.target.value,
                                                            sum: values.amount * e.target.value
                                                        })
                                                    },
                                                }}
                                                endAdornment={contract.currency ? <InputAdornment position="end">{(contract.currency.code === 'RUB') ? '₽' : '₸'}</InputAdornment> : null}
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                        <Grid item xs={3} className={classes.column}>
                                            <Field
                                                fullWidth
                                                type="text"
                                                name="tax"
                                                label="НДС"
                                                select
                                                variant="standard"
                                                component={TextField}
                                                required
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            >
                                                {
                                                    [
                                                        {key: -1, value: 'Без НДС'},
                                                        {key: 10, value: '10%'},
                                                        {key: 20, value: '20%'},
                                                    ].map((obj) => <MenuItem value={obj.key}>{obj.value}</MenuItem>)
                                                }
                                            </Field>
                                        </Grid>
                                        <Grid item xs={3} className={classes.column}>
                                            <Field
                                                fullWidth
                                                name={`sum`}
                                                type="number"
                                                step={0.01}
                                                label="Сумма"
                                                inputProps={{
                                                    min: 0
                                                }}
                                                disabled
                                                endAdornment={contract.currency ? <InputAdornment position="end">{(contract.currency.code === 'RUB') ? '₽' : '₸'}</InputAdornment> : null}
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                fullWidth
                                                type="text"
                                                name={`expiration_date_type`}
                                                label="Тип срока годности"
                                                select
                                                variant="standard"
                                                component={TextField}
                                                required={Boolean(contract.category.active)}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                InputProps={{
                                                    onChange: (event) => {
                                                        setFieldValue(`expiration_date_type`, event.target.value, true)
                                                        if (event.target.value === 'expiration_date') {
                                                            setFieldValue(`expiration_date_number`, '');
                                                        } else {
                                                            setFieldValue(`expiration_date`, null);
                                                        }
                                                    }
                                                }}
                                            >
                                                {
                                                    [{
                                                        type: 'expiration_date',
                                                        name: 'Минимальная дата истечения срока годности'
                                                    }, {
                                                        type: 'warranty_period',
                                                        name: 'Гарантийный срок'
                                                    }, {
                                                        type: 'expiration_date_percent',
                                                        name: 'Остаточный срок годности'
                                                    }].map((item, i) => (
                                                        <MenuItem
                                                            key={i}
                                                            value={item.type}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                            </Field>
                                        </Grid>
                                        <Grid item xs={6} className={classes.column}>
                                            {(values.expiration_date_type === 'expiration_date') &&
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        fullWidth
                                                        name="expiration_date"
                                                        required
                                                        format="dd-MM-yyyy"
                                                        invalidDateMessage="Некорректная дата"
                                                        orientation="landscape"
                                                        variant="inline"
                                                        openTo="date"
                                                        autoOk
                                                        label="Срок годности"
                                                        value={values.expiration_date}
                                                        onChange={date => {
                                                            setFieldValue("expiration_date", date, true)
                                                        }}
                                                        onBlur={() => {
                                                            setFieldTouched(`expiration_date`, true, true)
                                                        }}
                                                        onClose={() => {
                                                            setFieldTouched(`expiration_date`, true, true)
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            }
                                            {(values.expiration_date_type === 'warranty_period') &&
                                                <Field
                                                    fullWidth
                                                    name={`expiration_date_number`}
                                                    type="number"
                                                    label="Срок годности, месяцев"
                                                    required
                                                    step={1}
                                                    inputProps={{
                                                        min: 0,
                                                        step: 1
                                                    }}
                                                    component={CustomTextField}
                                                />
                                            }
                                            {(values.expiration_date_type === 'expiration_date_percent') &&
                                                <Field
                                                    fullWidth
                                                    name={`expiration_date_number`}
                                                    type="number"
                                                    label="Срок годности, %"
                                                    required
                                                    step={0.01}
                                                    inputProps={{
                                                        min: 0,
                                                        step: 0.01,
                                                    }}
                                                    component={CustomTextField}
                                                />
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions className={classes.footer}>
                            <Grid container className={classes.footerContent} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                <Grid item>
                                    <Submit
                                        className={classes.submit}
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={handleSubmit}
                                        type="submit"
                                    >
                                        Добавить
                                    </Submit>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

