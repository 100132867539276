import {DiscountsService} from "../services/discounts"
import {history} from "../../App/helpers/history"

export const CumulativeDiscountsActions = {
    discounts,
    add,
    edit,
}
function discounts(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SUPPLIER_CUMULATIVE_DISCOUNTS_REQUEST"})

        DiscountsService.cumulativeDiscounts(params)
            .then(
                discounts => {
                    dispatch({type: "SUPPLIER_CUMULATIVE_DISCOUNTS", payload: {data: discounts.data, meta: discounts.meta}})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "SUPPLIER_CUMULATIVE_DISCOUNTS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }  })
                    reject(error)
                }
            )
    })
}

function add(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "ADD_SUPPLIER_CUMULATIVE_DISCOUNT_REQUEST"})

        DiscountsService.addCumulative(values)
            .then(
                response => {
                    dispatch({type: "ADD_SUPPLIER_CUMULATIVE_DISCOUNT_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Успешно создана',
                            type:'success'
                        }
                    })
                    history.push("/supplier/cumulativeDiscounts")
                },
                error => {
                    dispatch({type: "ADD_SUPPLIER_CUMULATIVE_DISCOUNT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }  })
                    reject(error)
                }
            )
    })
}

function edit(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "EDIT_SUPPLIER_CUMULATIVE_DISCOUNT_REQUEST"})

        DiscountsService.editCumulative(id, values)
            .then(
                response => {
                    dispatch({type: "EDIT_CUMULATIVE_SUPPLIER_DISCOUNT_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Успешно изменена',
                            type:'success'
                        }
                    })
                    history.push("/supplier/cumulativeDiscounts")
                },
                error => {
                    dispatch({type: "EDIT_SUPPLIER_CUMULATIVE_DISCOUNT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }  })
                    reject(error)
                }
            )
    })
}
