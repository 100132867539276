import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import DateFnsUtils from "@date-io/date-fns"
import {ru} from "date-fns/locale"

import {
    Grid,
    IconButton,
    makeStyles,
    TextField as MuiTextField,
    Dialog,
    DialogTitle,
    DialogContent
} from "@material-ui/core"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"
import {Autocomplete} from '@material-ui/lab'
import {Delete as DeleteIcon, Edit as EditIcon} from '@material-ui/icons'
import {Table} from "@devexpress/dx-react-grid-material-ui";
import {TextFieldWithError as CustomTextField} from "../../../../App/components/Inputs/TextFieldWithError"
import {Submit} from "../../../../App/components/Button/Submit"
import {AuthorizationService} from "../../../../Auth/services/authorization"
import {getValidationSchema} from "./validation/institutionsBudgets"
import {CustomTable} from "../../../../App/components/Table/Table";
import {TableHeader} from "../../../../App/components/Table/TableHeader";
import {localState} from "../../../../App/components/LocalState";
import {useParams} from "react-router-dom";
import {CompanyActions} from "../../../actions/company";

const useStyles = makeStyles(() => ({
    fullWidth: {
        "width": "100%"
    },
    fullWidthDialog: {
        "width": "500px"
    },
    inputContent: {
        "width": "100%",
        'height': 'calc(100vh - 307px)',
        'min-height': 'calc(100vh - 307px)',
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 298px)"
    },
    column: {
        "height": "86px"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
}))

export const InstitutionsBudgets = (props) => {
    const dispatch = useDispatch()
    let {id} = useParams()

    const {company, setLoading} = props
    const classes = useStyles()
    const {fundingSources} = useSelector(state => state.purchases)
    const [useInstitutionsBudgets, seUseInstitutionsBudgets] = useState(null)
    const [open, setOpen] = useState(false)
    const { account, customer } = useSelector(state => {return {...state.companies, ...state.account}})
    const [institutionsBudgets, setInstitutionsBudgets] = useState(localState)

    const columns = [
        {name: 'year', title: 'Год'},
        {name: 'funding_source', title: 'Источник финансирования'},
        {name: 'budget', title: 'Бюджет'},
        {name: 'responsible', title: 'Ответственный'},
        {name: 'buttons', title: ' '},
    ]

    const [tableRef, setTableRef] = useState(null)
    const {filter} = useSelector(state => state.filters['institutions_budgets'])

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'year', width: 150},
        {columnName: 'funding_source', width: 300},
        {columnName: 'budget', width: 150},
        {columnName: 'responsible', width: 150},
        {columnName: 'buttons', width: 350},
    ])

    const [columnOrder, setColumnOrder] = useState([
        'year',
        'funding_source',
        'budget',
        'responsible',
        'buttons',
    ])

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const handlePagesSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: 'INSTITUTIONS_BUDGETS_FILTER', payload: {limitRows: newRowsPerPage}})
    }

    const handleClose = () => {
        setOpen(false);
    };

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
        }
    }

    useEffect(() => {
        const getCustomer = async () => {
            return await dispatch(CompanyActions.getInstitutionsBudgets({
                limit: rowsPerPage,
                page: page,
                ...getParams()
            }, id ?? company.id))
        }

        getCustomer().then((r) => {
            setInstitutionsBudgets(r)
            tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
        })
    }, [dispatch, filter, page, rowsPerPage, id]);

    return (
        <Grid item className={classes.fullWidth}>
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle>Добавить новый бюджет</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Formik
                        initialValues={{
                            year: useInstitutionsBudgets ? new Date(useInstitutionsBudgets.year,1) : new Date((new Date()).getFullYear() + 1, 0, 1),
                            funding_source: useInstitutionsBudgets ? useInstitutionsBudgets.funding_source : null,
                            budget: useInstitutionsBudgets ? useInstitutionsBudgets.budget : 0,
                        }}
                        validationSchema={getValidationSchema()}
                        onSubmit={(values, {setSubmitting, setFieldError}) => {
                            return useInstitutionsBudgets ? dispatch(CompanyActions.editInstitutionsBudgets({...values,...{year: values.year.getFullYear()}},company.id,useInstitutionsBudgets.id)).then(
                                () => {
                                    setSubmitting(false)
                                    setLoading(false)
                                    setOpen(false)
                                },
                                (errors) => {
                                    if(errors) {
                                        Object.keys(errors.errors).forEach(function (key){
                                            setFieldError(key,errors.errors[key].join(','))
                                        });
                                        setSubmitting(false)
                                    }
                                }
                            ) :
                            dispatch(CompanyActions.addInstitutionsBudgets({...values,...{year: values.year.getFullYear()}},company.id)).then(
                                () => {
                                    setSubmitting(false)
                                    setLoading(false)
                                    setOpen(false)
                                },
                                (errors) => {
                                    if(errors) {
                                        Object.keys(errors.errors).forEach(function (key){
                                            setFieldError(key,errors.errors[key].join(','))
                                        });
                                        setSubmitting(false)
                                    }
                                }
                            )
                        }}
                    >
                        {({
                            dirty,
                            values,
                            errors,
                            touched,
                            isSubmitting,
                            isValid,
                            setFieldValue,
                        }) => (
                            <Form className={classes.fullWidthDialog}>
                                <Grid item xs={12}>
                                    <Grid item className={classes.column}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                            <KeyboardDatePicker
                                                error={Boolean(errors.hasOwnProperty('year') && errors.year && touched.hasOwnProperty('year') && touched.year)}
                                                helperText={errors.hasOwnProperty('year') && touched.hasOwnProperty('year') && (errors.year ? errors.year : null)}
                                                disableToolbar
                                                fullWidth
                                                name="year"
                                                required
                                                minDate={new Date((new Date()).getFullYear() + 1, 0, 1)}
                                                minDateMessage={""}
                                                orientation="landscape"
                                                variant="inline"
                                                format="yyyy"
                                                openTo="year"
                                                views={['year']}
                                                autoOk
                                                label="Год"
                                                value={values.year}
                                                onChange={date => {
                                                    setFieldValue("year", date, true);
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item className={classes.column}>
                                        <Autocomplete
                                            options={fundingSources}
                                            required
                                            name="funding_source"
                                            noOptionsText='Нет доступных вариантов'
                                            getOptionLabel={(option) => option.name}
                                            value={values.funding_source}
                                            onChange={(e, item) => {
                                                setFieldValue("funding_source", item)
                                            }}
                                            renderInput={(params) =>
                                                <MuiTextField
                                                    {...params}
                                                    fullWidth
                                                    label="Источник финансирования"
                                                    name="funding_source"
                                                />}
                                        />
                                    </Grid>
                                    <Grid item className={classes.column}>
                                        <Field
                                            fullWidth
                                            name="budget"
                                            type="number"
                                            required
                                            label="Бюджет"
                                            step={0.01}
                                            inputProps={{
                                                step: 0.01,
                                                onChange: (e) => {
                                                    const {value, name} = e.target
                                                    setFieldValue(name, value)
                                                },
                                            }}
                                            component={CustomTextField}
                                        />
                                    </Grid>
                                    <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={2}>
                                        <Grid item>
                                            <Submit
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    setOpen(false)
                                                }}
                                            >
                                                Отменить
                                            </Submit>
                                        </Grid>
                                        <Grid item>
                                            <Submit
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting || !isValid || !dirty}
                                                type="submit"
                                            >
                                                Сохранить
                                            </Submit>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
            <Grid container direction="column" justify="space-between" alignItems="stretch">
                {(AuthorizationService.permissions(account, "administrator") || AuthorizationService.roles(account, "admin")) &&
                    <Grid item className={classes.footer}>
                        <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                            <Grid item className={classes.footerContent}>
                                <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Submit
                                            disableElevation
                                            variant="contained"
                                            color="primary"
                                            type="button"
                                            onClick={() => {
                                                seUseInstitutionsBudgets(null)
                                                setOpen(true)
                                            }}
                                        >
                                            Добавить
                                        </Submit>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <CustomTable
                    meta={institutionsBudgets.meta}
                    tableName={'institutions_budgets'}
                    rows={institutionsBudgets?.data.map((budget_institution) => ({
                        year: budget_institution.year,
                        funding_source: budget_institution.funding_source.name,
                        budget: Number(budget_institution.budget).toLocaleString('ru-RU', {style: 'currency', currency: 'RUB'}),
                        responsible: `${budget_institution.responsible.last_name} ${budget_institution.responsible.first_name[0]}. ${budget_institution.responsible.middle_name[0]}.`,
                        buttons: <React.Fragment>
                            <IconButton
                                disabled={!(AuthorizationService.permissions(account, "administrator") || AuthorizationService.roles(account, "admin"))}
                                onClick={() => {
                                    seUseInstitutionsBudgets(budget_institution)
                                    setOpen(true)
                                }}
                            >
                                <EditIcon/>
                            </IconButton>
                            <IconButton
                                disabled={!(AuthorizationService.permissions(account, "administrator") || AuthorizationService.roles(account, "admin"))}
                                onClick={() => {
                                    return dispatch(CompanyActions.deleteInstitutionsBudgets(company.id,budget_institution.id)).then(
                                        () => {
                                            setLoading(false)
                                        }
                                    )
                                }}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </React.Fragment>
                    }))}
                    columns={columns}
                    page={{page: page, setPage: setPage, rowsPerPage: rowsPerPage, handlePageSizeChange: handlePagesSizeChange}}
                    setTableRef={setTableRef}
                    columnsSettings={{
                        columnOrder: columnOrder,
                        setColumnOrder: setColumnOrder,
                        setColumnWidths: setColumnWidths,
                        columnWidths: columnWidths,
                        hiddenColumnNames: hiddenColumnNames,
                        setHiddenColumnNames: setHiddenColumnNames
                    }}
                    tableHeader={TableHeader}
                    filterActionType={'INSTITUTIONS_BUDGETS_FILTER'}
                    classInputContent={classes.inputContent}
                    rowComponent={({row, tableRow, children}) => (
                        <Table.Row
                            tableRow={tableRow}
                            children={children}
                            className={classes.tableRow}
                            row={row}
                        />
                    )}
                    filters={{
                        buttons: {
                            nullButton: true
                        },
                    }}
                />
            </Grid>
        </Grid>
    )
}
