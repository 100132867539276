import React, {useState} from "react"
import {useSelector} from "react-redux"

import {
    Grid,
    makeStyles
} from "@material-ui/core"

import {Submit} from "../../../../App/components/Button/Submit"
import {plan as rules} from "../../../helpers/permissions/rules"
import {Dialog} from "./Upload/Dialog";

const useStyles = makeStyles(() => ({
    button: {
        "height": "45px",
    }
}))

export const Upload = (props) => {
    const classes = useStyles()

    const {planningSettings} = useSelector(state => state)
    const [dialog, setDialog] = useState(false)

    const { plan, price, onClick } = props

    const {account} = useSelector(state => state.account)

    return rules.edit.hasOwnProperty(account.group) ? (
        <Grid item>
            <Submit
                className={classes.button}
                disableElevation
                disabled={!planningSettings.import_excel || (price?.status === 'in_process') || !(
                    rules.edit[account.group].hasOwnProperty(plan.status.index) &&
                    (
                        !rules.edit[account.group][plan.status.index].length ||
                        (rules.edit[account.group][plan.status.index].length && rules.edit[account.group][plan.status.index].includes(plan.editRequestStatus?.index))
                    )
                )}
                variant="contained"
                color="primary"
                type="button"
                style={{height: '35px'}}
                onClick={() => {
                    setDialog(true)
                }}
            >
                Загрузить ОЗ
            </Submit>
            {dialog &&
                <Dialog
                    open={dialog}
                    onClose={() => {
                        setDialog(false)
                        onClick()
                    }}
                    purchasePlanID={plan.id}
                />
            }
        </Grid>
    ) : null
}
