export default function demo(state = {order: null, orderItems: {data: [], meta: {current_page: 1, total: 0, limit: 15}}, contract: null, contractItems: {data: [], meta: {current_page: 1, total: 0, limit: 15}}, supplier: null}, action) {
    switch (action.type) {
        case "DEMO_ORDER_REQUEST":
            return state
        case "DEMO_ORDER":
            return {
                ...state,
                ...{
                    order: action.payload.order,
                    orderItems: action.payload.items,
                }
            }
        case "DEMO_ORDER_FAILURE":
            return {
                ...state,
                ...{
                    order: null,
                    orderItems: {data: [], meta: {current_page: 1, total: 0, limit: 15}}
                }
            }
        case "DEMO_CONTRACT_REQUEST":
            return state
        case "DEMO_CONTRACT":
            return {
                ...state,
                ...{
                    contract: action.payload.contract,
                    contractItems: action.payload.items,
                }
            }
        case "DEMO_CONTRACT_FAILURE":
            return {
                ...state,
                ...{
                    contract: null,
                    contractItems: {data: [], meta: {current_page: 1, total: 0, limit: 15}}
                }
            }
        case "DEMO_SUPPLIER_REQUEST":
            return state
        case "DEMO_SUPPLIER":
            return {
                ...state,
                ...{
                    supplier: action.payload,
                }
            }
        case "DEMO_SUPPLIER_FAILURE":
            return {
                ...state,
                ...{
                    supplier: null
                }
            }
        default:
            return state
    }
}
