export default function shop(state = {orders: {data: [], meta: {total: 0, per_page: 15, current_page: 1}}}, action) {
    switch (action.type) {
        case "PURCHASE_ORDERS_REQUEST":
            return state
        case "PURCHASE_ORDERS":
            return {
                orders: action.payload,
            }
        case "PURCHASE_ORDERS_FAILURE":
            return state
        case "PURCHASE_ORDER_LOT_REQUEST":
            return state
        case "PURCHASE_ORDER_LOT":
            return {
                ...state,
                ...{lot: action.payload}
            }
        case "PURCHASE_ORDER_LOT_FAILURE":
            return state
        default:
            return state
    }
}
