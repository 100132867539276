export default function delivery(state = {orders: {data: [], meta: {total: 0, per_page: 15, current_page: 1}}}, action) {
    switch (action.type) {
        case "REPORT_DELIVERY_ORDERS_REQUEST":
            return state
        case "REPORT_DELIVERY_ORDERS":
            return {
                ...state,
                ...{
                    orders: action.payload,
                }
            }
        case "REPORT_DELIVERY_ORDERS_FAILURE":
            return state
        default:
            return state
    }
}
