import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {Field, FieldArray, Form, Formik, useFormikContext} from "formik"
import {Select, TextField} from "formik-material-ui"
import DateFnsUtils from "@date-io/date-fns"
import format from "date-fns/format"
import {ru} from "date-fns/locale"

import {Grid, IconButton, makeStyles, Paper, Typography, MenuItem, InputLabel, FormControl} from "@material-ui/core"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"
import {Clear, Add as AddIcon} from "@material-ui/icons"
import {green} from "@material-ui/core/colors"

import {Submit} from "../../../../App/components/Button/Submit"
import {TextFieldWithError as CustomTextField} from "../../../../App/components/Inputs/TextFieldWithError"
import {SystemActions} from "../../../../App/actions/system"
import {FileUpload} from "../../../../App/components/Inputs/FileUpload"
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon"
import {PurchaseActions} from "../../../actions/purchase"
import {PurchasePlanActions} from "../../../actions/purchasePlan"
import {history} from "../../../../App/helpers/history"
import {DialogWithVariantsChoice} from "../../../../App/components/DialogWithVariantsChoice/DialogWithVariantsChoice"
import {getFullName} from "../../../../App/helpers/user"
import { getValidationSchema } from "../validation/Add"
import {Category} from "../../../../App/components/Inputs/Category";
import {SettingsActions} from "../../../../Settings/actions/settings";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.planningContent.width,
        padding: theme.planningContent.padding,
        margin: theme.planningContent.margin,
        position: "relative"
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important"
    },
    input: {
        "width": "100%"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
        "position": "absolute",
        "bottom": "0",
        "width": "100%",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    contentBody: {
        position: "relative"
    },
    paramsList: {
        height: "calc(100vh - 234px)",
        overflow: "auto",
        padding: "0 25px",
        marginBottom: "70px"
    },
    errorField: {
        "&>input": {
            backgroundColor: 'rgb(242 115 96 / 50%)'
        }
    },
    errorFieldDropDown: {
        "&>div": {
            backgroundColor: 'rgb(242 115 96 / 50%)'
        }
    },
    errorFieldDatePicker: {
        "& input": {
            backgroundColor: 'rgb(242 115 96 / 50%)'
        }
    },
    selectCategory: {
        "& .dx-texteditor.dx-editor-outlined": {
            "border": "unset !important",
            "border-bottom": "2px solid #c1c1c1 !important",
            "border-radius": "unset",
        },
        "& .dx-texteditor-input": {
            "padding": "0px !important",
        },
        "&:after":{
            "transform": "scaleX(1)",
            "border-bottom-color": "9px solid #f27360 !important"
        }
    },
}));

export const Content = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {purchasePlanID} = useParams()
    const { currencies } = useSelector(state => state.system)
    const [plan, setPlan] = useState(null)
    const { conductionMethods, purchase } = useSelector(state => state.purchases)
    const {account} = useSelector(state => state.account)
    const {categories} = useSelector(state => state.purchases)
    const [settings, setSettings] = useState([])
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const getData = async () => {
            await dispatch(SettingsActions.getSettings('planning')).then(settings => {
                setSettings(settings)
            })
            await dispatch(SystemActions.currencies())
            await dispatch(PurchaseActions.conductionMethods())
            await dispatch(PurchaseActions.categoriesForCreate({id: purchasePlanID}))
            await dispatch(SystemActions.categories())
            await dispatch(PurchasePlanActions.plan(purchasePlanID, true)).then(response => setPlan(response))
        }

        getData().then(() => {})
        // eslint-disable-next-line
    }, [dispatch]);

    return categories && plan && currencies && conductionMethods ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" alignItems="center" className={classes.contentBody}>
                    <Formik
                        validateOnBlur={false}
                        initialValues={{
                            purchase_plan_id: plan.id,
                            category: '',
                            conduction_method: '',
                            name: '',
                            type: 1,
                            currency: 2,
                            department: '',
                            company_name: account.company ? account.company.legal_detail.name : plan.company.name,
                            railroad: plan.company.railRoad?.name,
                            user: getFullName(account),
                            territories: [],
                            planing_date_to_publish_notification: null,
                            lot: {
                                name: '',
                                start_execution_month: null,
                                execution_month: null,
                                purchase_type: '',
                                files: [],
                                category: '',
                                postponement: '',
                                prepayment: '',
                            }
                        }}
                        validationSchema={getValidationSchema()}
                        validate={values => {
                            const errors = {};

                            if (values.planing_date_to_publish_notification && values.lot.start_execution_month) {
                                if ((values.planing_date_to_publish_notification.getFullYear() > values.lot.start_execution_month.getFullYear()) || ((values.planing_date_to_publish_notification.getFullYear() === values.lot.start_execution_month.getFullYear()) && (values.planing_date_to_publish_notification.getMonth() > values.lot.start_execution_month.getMonth()))) {
                                    errors.planing_date_to_publish_notification = 'План. срок размещения извещения должен в месяц или до начала месяца исполнения договора'
                                }
                            }

                            return errors
                        }}
                        onSubmit={(values, {setSubmitting}) => {
                            const formData = new FormData()
                            for (const key in values) {
                                if (values.hasOwnProperty(key)) {
                                    switch (key) {
                                        case 'category':
                                            if (values[key]) {
                                                formData.append(key, values[key].id)
                                            }
                                            break;
                                        case 'planing_date_to_publish_notification':
                                            if (values[key]) {
                                                formData.append(key, format(new Date(values[key]), 'yyyy-MM-dd'))
                                            }
                                            break;
                                        case 'lot':
                                            for (const lotKey in values.lot) {
                                                if (values.lot.hasOwnProperty(lotKey) && values[key][lotKey]) {
                                                    switch (lotKey) {
                                                        case 'category':
                                                            formData.append(`${key}[${lotKey}]`, values[key][lotKey].id)
                                                            break;
                                                        case 'files':
                                                            Array.from(values[key][lotKey]).forEach((el, index) => {
                                                                if (el.file  && el.description) {
                                                                    if (el.file instanceof File) {
                                                                        formData.append(`${key}[${lotKey}][${index}][file]`, el.file, el.file.name);
                                                                    } else {
                                                                        formData.append(`${key}[${lotKey}][${index}][file][id]`, el.file.id);
                                                                    }
                                                                    formData.append(`${key}[${lotKey}][${index}][description]`, el.description);
                                                                }
                                                            });
                                                            break;
                                                        case 'execution_month':
                                                            formData.append(`${key}[${lotKey}]`, format(new Date(Date.UTC(values[key][lotKey].getFullYear(), values[key][lotKey].getMonth(), 1)), 'yyyy-MM-dd'))
                                                            break;
                                                        case 'start_execution_month':
                                                            formData.append(`${key}[${lotKey}]`, format(new Date(Date.UTC(values[key][lotKey].getFullYear(), values[key][lotKey].getMonth(), 1)), 'yyyy-MM-dd'))
                                                            break;
                                                        case 'purchase_type':
                                                            formData.append(`${key}[${lotKey}]`, values[key][lotKey])
                                                            switch (values[key][lotKey]) {
                                                                case 'partial':
                                                                    formData.append(`${key}[prepayment]`, values[key].prepayment)
                                                                    formData.append(`${key}[postponement]`, values[key].postponement)
                                                                    break
                                                                case 'postponement':
                                                                    formData.append(`${key}[postponement]`, values[key].postponement)
                                                                    break
                                                                default:
                                                            }
                                                            break
                                                        case 'prepayment':
                                                            break
                                                        case 'postponement':
                                                            break
                                                        default:
                                                            formData.append(`${key}[${lotKey}]`, values[key][lotKey])
                                                    }
                                                }
                                            }
                                            break;
                                        case 'territories':
                                            values[key].forEach((id, index) => {
                                                formData.append(`${key}[${index}]`, id);
                                            })
                                            break;
                                        default:
                                            if (values[key]) {
                                                formData.append(key, values[key])
                                            }
                                    }
                                }
                            }

                            return dispatch(PurchaseActions.add(formData)).then(
                                (purchase) => {
                                    setSubmitting(false)
                                    setOpen(purchase)
                                },
                                () => {
                                    setSubmitting(false)
                                }
                            )
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              isSubmitting,
                              setValues,
                              setTouched,
                              setFieldValue,
                              setFieldTouched,
                              handleSubmit
                          }) => (
                            <Form className={classes.fullWidth}>
                                <Grid item className={classes.inputContent}>
                                    <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                        <Grid item className={classes.header}>
                                            <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                                <Grid item className={classes.fullWidth}>
                                                    <Typography className={classes.title}>
                                                        {purchase ? 'Создание' : 'Редактирование'} закупки
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <DialogWithVariantsChoice
                                        open={open}
                                        maxWidth={'sm'}
                                        direction={'column'}
                                        onClose={() => history.push(`/planning/purchase-plan/${plan.id}`)}
                                        textTitle={'Внимание! Выберете дальнейшее действие'}
                                        buttons={[
                                            {
                                                xs: 12,
                                                callback: () => {
                                                    history.push(`/planning/purchase-plan/${plan.id}`)
                                                },
                                                text: 'К списку закупок',
                                            },
                                            ...(open.validated ? [{
                                                xs: 12,
                                                callback: () => {
                                                    history.push(`/planning/purchase/${open.id}/lot/add`)
                                                },
                                                text: 'Создать лот',
                                            }] : []),
                                            {
                                                xs: 12,
                                                callback: () => {
                                                    history.go(`/planning/purchase-plan/${plan.id}/purchase/add`)
                                                },
                                                text: 'Создать новую закупку',
                                            }
                                        ]}
                                    />
                                    <Grid container className={classes.paramsList} direction="row" wrap="wrap" spacing={4}>
                                        <Grid item xs={3}>
                                            <Field
                                                fullWidth
                                                name="railroad"
                                                type="text"
                                                disabled
                                                label="ЖД"
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Field
                                                fullWidth
                                                name="user"
                                                type="text"
                                                disabled
                                                label="Пользователь"
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Field
                                                fullWidth
                                                name="company_name"
                                                type="text"
                                                disabled
                                                label="ЧУЗ"
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                        <Grid item xs={3}/>
                                        <Grid item xs={3}>
                                            <Field
                                                fullWidth
                                                name="name"
                                                type="text"
                                                label="Название"
                                                required
                                                component={CustomTextField}
                                                className={touched.name && errors.name ? classes.errorField : null}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Field
                                                fullWidth
                                                type="text"
                                                name="conduction_method"
                                                label="Способ проведения закупки"
                                                select
                                                variant="standard"
                                                component={TextField}
                                                required
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                className={touched.conduction_method && errors.conduction_method ? classes.errorFieldDropDown : null}
                                            >
                                                {
                                                    conductionMethods.map(purchaseType => (
                                                        <MenuItem key={purchaseType.id} value={purchaseType.id}>
                                                            {purchaseType.name}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Field>
                                        </Grid>
                                        <Grid item xs={3} className={errors.category ? classes.selectCategoryError : classes.selectCategory}>
                                            <Category
                                                isSubmitting={isSubmitting}
                                                label="Подкатегория"
                                                value={values.category?.id}
                                                options={categories.map(category => category.id)}
                                                onChange={(value) => {
                                                    const category = categories.find(item => item.id === value?.id)

                                                    setValues({
                                                        ...values,
                                                        ...{
                                                            category: category,
                                                            lot: {
                                                                ...values.lot,
                                                                ...{
                                                                    category: category
                                                                }
                                                            }
                                                        }
                                                    })
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    fullWidth
                                                    error={Boolean(errors.hasOwnProperty('planing_date_to_publish_notification') && touched.hasOwnProperty('planing_date_to_publish_notification'))}
                                                    helperText={(errors.hasOwnProperty('planing_date_to_publish_notification') && touched.hasOwnProperty('planing_date_to_publish_notification')) && errors.planing_date_to_publish_notification ? errors.planing_date_to_publish_notification : null}
                                                    name={`planing_date_to_publish_notification`}
                                                    required
                                                    minDate={new Date(plan.start_period)}
                                                    format="dd-MM-yyyy"
                                                    orientation="landscape"
                                                    variant="inline"
                                                    openTo="date"
                                                    label="План. срок размещения извещения"
                                                    autoOk
                                                    value={values.planing_date_to_publish_notification}
                                                    onChange={date => {
                                                        setFieldValue(`planing_date_to_publish_notification`, date);
                                                    }}
                                                    onBlur={() => {
                                                        setFieldTouched(`planing_date_to_publish_notification`, true)
                                                    }}
                                                    onClose={() => {
                                                        setFieldTouched(`planing_date_to_publish_notification`, true)
                                                    }}
                                                    className={touched.planing_date_to_publish_notification && errors.planing_date_to_publish_notification ? classes.errorFieldDatePicker : null}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl className={classes.fullWidth}>
                                                <InputLabel required shrink={ true }>
                                                    Адрес закупки
                                                </InputLabel>
                                                <Field
                                                    fullWidth
                                                    type="text"
                                                    name="territories"
                                                    label="Адрес закупки"
                                                    variant="standard"
                                                    component={ Select }
                                                    multiple
                                                    required
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    className={(touched.conduction_method && errors.conduction_method) ? classes.errorFieldDropDown : null}
                                                >
                                                    {plan.company.territories.map(currency => (
                                                        <MenuItem key={currency.id} value={currency.id}>
                                                            {currency.name}
                                                        </MenuItem>
                                                    ))}
                                                </Field>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Field
                                                fullWidth
                                                type="text"
                                                name="currency"
                                                label="Валюта"
                                                select
                                                variant="standard"
                                                component={TextField}
                                                required
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            >
                                                {
                                                    currencies.map(currency => (
                                                        <MenuItem key={currency.id} value={currency.id}>
                                                            {currency.name}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Field>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Field
                                                fullWidth
                                                name="department"
                                                type="text"
                                                required
                                                label="Отделение"
                                                component={CustomTextField}
                                                className={touched.department && errors.department ? classes.errorField : null}
                                            />
                                        </Grid>
                                        <Grid item xs={3} />
                                        <Grid item xs={3}>
                                            <Field
                                                fullWidth
                                                required={settings.categories_id_required_purchase_lot_name?.includes(values.category?.index)}
                                                name="lot.name"
                                                type="text"
                                                label="Наименование лота"
                                                component={CustomTextField}
                                                className={touched.name && errors.name ? classes.errorField : null}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    fullWidth
                                                    error={Boolean(errors.hasOwnProperty('lot') && errors.lot.hasOwnProperty('start_execution_month') && touched.hasOwnProperty('lot') && touched.lot.hasOwnProperty('start_execution_month'))}
                                                    helperText={(errors.hasOwnProperty('lot') && errors.lot.hasOwnProperty('start_execution_month') && touched.hasOwnProperty('lot') && touched.lot.hasOwnProperty('start_execution_month')) && errors.lot.start_execution_month ? errors.lot.start_execution_month : null}
                                                    name={`lot.start_execution_month`}
                                                    required
                                                    minDate={new Date(plan.start_period)}
                                                    maxDate={new Date(`${plan.planning_year}-12-31`)}
                                                    views={["year", "month"]}
                                                    orientation="landscape"
                                                    variant="inline"
                                                    format="MM.yyyy"
                                                    openTo="year"
                                                    autoOk
                                                    label="Планируемый месяц начала исполнения договора"
                                                    value={values.lot.start_execution_month}
                                                    onChange={date => {
                                                        let max = new Date(date)
                                                        max.setMonth(max.getMonth() + 11)

                                                        if (values.lot.execution_month > max) {
                                                            setValues({
                                                                ...values,
                                                                ...{
                                                                    lot: {
                                                                        ...values.lot,
                                                                        ...{
                                                                            execution_month: max,
                                                                            start_execution_month: date
                                                                        }
                                                                    }
                                                                }
                                                            })
                                                        } else {
                                                            setValues({
                                                                ...values,
                                                                ...{
                                                                    lot: {
                                                                        ...values.lot,
                                                                        ...{
                                                                            start_execution_month: date
                                                                        }
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    }}
                                                    onBlur={() => {
                                                        if (values.planing_date_to_publish_notification) {
                                                            setTouched({
                                                                ...touched,
                                                                ...{
                                                                    planing_date_to_publish_notification: true,
                                                                    lot: {
                                                                        ...touched.lot,
                                                                        ...{
                                                                            start_execution_month: true
                                                                        }
                                                                    }
                                                                }
                                                            })
                                                        } else {
                                                            setFieldTouched(`lot.start_execution_month`, true)
                                                        }
                                                    }}
                                                    onClose={() => {
                                                        if (values.planing_date_to_publish_notification) {
                                                            setTouched({
                                                                ...touched,
                                                                ...{
                                                                    planing_date_to_publish_notification: true,
                                                                    lot: {
                                                                        ...touched.lot,
                                                                        ...{
                                                                            start_execution_month: true
                                                                        }
                                                                    }
                                                                }
                                                            })
                                                        } else {
                                                            setFieldTouched(`lot.start_execution_month`, true)
                                                        }
                                                    }}
                                                    className={touched.hasOwnProperty('lot')
                                                    && touched.lot.hasOwnProperty('start_execution_month')
                                                    && errors.hasOwnProperty('lot')
                                                    && errors.lot.hasOwnProperty('start_execution_month') ? classes.errorFieldDatePicker : null}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    fullWidth
                                                    error={Boolean(errors.hasOwnProperty('lot') && errors.lot.hasOwnProperty('execution_month') && touched.hasOwnProperty('lot') && touched.lot.hasOwnProperty('execution_month'))}
                                                    helperText={(errors.hasOwnProperty('lot') && errors.lot.hasOwnProperty('execution_month') && touched.hasOwnProperty('lot') && touched.lot.hasOwnProperty('execution_month')) && errors.lot.execution_month ? errors.lot.execution_month : null}
                                                    name={`lot.execution_month`}
                                                    required
                                                    minDate={function () {
                                                        if (values.lot.start_execution_month) {
                                                            return values.lot.start_execution_month
                                                        }
                                                        return new Date(plan.start_period)
                                                    }()}
                                                    maxDate={function () {
                                                        if (values.lot.start_execution_month) {
                                                            let max = new Date(values.lot.start_execution_month)
                                                            max.setMonth(max.getMonth() + 11)
                                                            return max
                                                        }
                                                        return new Date(`${parseInt(plan.planning_year) + 1}-12-31`)
                                                    }()}
                                                    views={["year", "month"]}
                                                    orientation="landscape"
                                                    variant="inline"
                                                    format="MM.yyyy"
                                                    openTo="year"
                                                    autoOk
                                                    label="Планируемый месяц исполнения договора"
                                                    value={values.lot.execution_month}
                                                    onChange={date => {
                                                        setFieldValue(`lot.execution_month`, date);
                                                    }}
                                                    onBlur={() => {
                                                        setFieldTouched(`lot.execution_month`, true, true)
                                                    }}
                                                    onClose={() => {
                                                        setFieldTouched(`lot.execution_month`, true, true)
                                                    }}
                                                    className={touched.hasOwnProperty('lot')
                                                    && touched.lot.hasOwnProperty('execution_month')
                                                    && errors.hasOwnProperty('lot')
                                                    && errors.lot.hasOwnProperty('execution_month') ? classes.errorFieldDatePicker : null}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={3} className={classes.column}>
                                            <Field
                                                fullWidth
                                                type="text"
                                                name={`lot.purchase_type`}
                                                label="Тип оплаты"
                                                select
                                                variant="standard"
                                                component={TextField}
                                                required
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                InputProps={{
                                                    onChange: (event) => {
                                                        setFieldValue(`lot.purchase_type`, event.target.value, true)
                                                        setFieldValue(`lot.postponement`, '', false)
                                                        setFieldValue(`lot.prepayment`, 1, false)
                                                    }
                                                }}
                                                className={touched.conduction_method && errors.conduction_method ? classes.errorFieldDropDown : null}
                                            >
                                                {[
                                                    {type: 'complete', name: 'Аванс'},
                                                    {type: 'partial', name: 'Частичная предоплата'},
                                                    {type: 'postponement', name: 'Постоплата'}
                                                ].map((item, i) => (
                                                    <MenuItem key={i} value={item.type}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Grid>
                                        {((values.lot.purchase_type === 'postponement') || (values.lot.purchase_type === 'partial')) &&
                                            <Grid item xs={3} className={classes.column}>
                                                <Field
                                                    fullWidth
                                                    name={`lot.postponement`}
                                                    type="number"
                                                    label={`${(values.lot.purchase_type === 'postponement') ? 'Отсрочка платежа, дней' : 'Отсрочка постоплаты, дней'}`}
                                                    required
                                                    step={1}
                                                    inputProps={{
                                                        min: 0
                                                    }}
                                                    component={CustomTextField}
                                                />
                                            </Grid>
                                        }
                                        {(values.lot.purchase_type === 'partial') &&
                                            <Grid item xs={2} className={classes.column}>
                                                <Field
                                                    fullWidth
                                                    name={`lot.prepayment`}
                                                    type="number"
                                                    label="Аванс"
                                                    required
                                                    step={1}
                                                    inputProps={{
                                                        min: 1,
                                                        max: 100
                                                    }}
                                                    endAdornment={'%'}
                                                    component={CustomTextField}
                                                />
                                            </Grid>
                                        }
                                        <Grid item xs={12}/>
                                        <Grid item xs={6}>
                                            <FieldArray name={`lot.files`} render={ arrayHelpers => (
                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2} style={{maxWidth:"calc(100% + 9px)"}}>
                                                    <Grid item xs={12}>
                                                        <Typography>Документы</Typography>
                                                        <Grid container direction="row" justify="flex-start" className={classes.fileRows} alignItems="flex-start" spacing={2}>
                                                            {values.lot.files.map((el, idx) => (
                                                                <React.Fragment key={idx}>
                                                                    <Grid item xs={5} >
                                                                        <Field
                                                                            fullWidth
                                                                            style={{
                                                                                marginTop: "5px",
                                                                                lineHeight: "16px",
                                                                                fontSize: "16px",
                                                                                padding: "4px 0",
                                                                                paddingTop: "5px",
                                                                            }}
                                                                            name={`lot.files[${idx}].description`}
                                                                            type="text"
                                                                            multiline
                                                                            rows="1"
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Field
                                                                            fullWidth
                                                                            size="small"
                                                                            component={(props) => (
                                                                                <FileUpload {...props} endIcon={"Обзор"} />
                                                                            )}
                                                                            name={`lot.files.${idx}.file`}
                                                                            InputLabelProps={{
                                                                                name: values.lot.files && values.lot.files.length && values.lot.files[idx] && values.lot.files[idx].file && values.lot.files[idx].file.name ? values.lot.files[idx].file.name : null
                                                                            }}
                                                                            InputProps={{
                                                                                onChange: (event) => {
                                                                                    setFieldValue(`lot.files.${idx}.file`, event.currentTarget.files[0])
                                                                                    setFieldTouched(`lot.files.${idx}.file`, true, false)
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={1} style={{padding: "8px 0"}}>
                                                                        <IconButton
                                                                            size="small"
                                                                            style={{padding: "3px 0"}}
                                                                            onClick={() => {
                                                                                arrayHelpers.remove(idx)
                                                                            }}
                                                                        >
                                                                            <Clear/>
                                                                        </IconButton>
                                                                    </Grid>
                                                                </React.Fragment>
                                                            ))}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={5}/>
                                                    <Grid item xs={7} style={{
                                                        display: "flex",
                                                        justifyContent: "flex-start"
                                                    }}>
                                                        <ButtonIcon
                                                            className={classes.button}
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                arrayHelpers.insert(values.lot.files.length, {
                                                                    file: null,
                                                                    description: ''
                                                                })
                                                            }}
                                                            children={<AddIcon/>}>
                                                        </ButtonIcon>
                                                    </Grid>
                                                </Grid>
                                            )}/>
                                        </Grid>
                                    </Grid>
                                </Grid>


                                <Grid item className={classes.footer}>
                                    <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                        <Grid item className={classes.footerContent}>
                                            <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                <Grid item>
                                                    <Submit
                                                        disableElevation
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        onClick={handleSubmit}
                                                        disabled={isSubmitting}
                                                    >
                                                        Создать
                                                    </Submit>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Paper>
        </Grid>) : null
}
