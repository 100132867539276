import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {TextFieldWithError as CustomTextField} from "../../../App/components/Inputs/TextFieldWithError"
import {
    FormControl,
    FormControlLabel,
    Grid,
    makeStyles,
    MenuItem,
    Paper,
    Switch,
    Typography
} from "@material-ui/core"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {Submit} from "../../../App/components/Button/Submit"
import {SettingsActions} from "../../actions/settings"
import DateFnsUtils from "@date-io/date-fns"
import {Field, Form, Formik} from "formik"
import {ru} from "date-fns/locale"
import * as Yup from "yup"
import {Select} from "formik-material-ui"
import {PlanningActions} from "../../actions/planning";
import format from "date-fns/format";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.planningContent.width,
        padding: theme.planningContent.padding,
        margin: theme.planningContent.margin
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%"
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 137px)"
    },
    input: {
        "width": "100%",
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100% - 34px)",
        "min-height": "calc(100% - 34px)",
        "padding": "20px 30px 0",
        "overflow": "auto"
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    select: {
        "height": "43px"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    tableRowAlert: {
        "cursor": "pointer",
        "background-color": "#f9f1f0",
        "text-decoration": "none"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "18px !important"
    },
    notificationList: {
        height: "calc(100vh - 230px)",
        overflow: "auto",
    },
    border: {
        "border-bottom": "35px solid #c1c1c1 !important"
    },
    customLabel: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "12px"
    }
}))

export const IndexContent = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {planningSettings} = useSelector(state => state)
    const {categoriesForCreate} = useSelector(state => state.purchasePlans)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getData = async () => {
            await dispatch(PlanningActions.categories())
            await dispatch(SettingsActions.settings())
        }

        if (!loading) {
            getData().then(() => {
                setLoading(true)
            })
        }
    }, [dispatch, loading]);

    return (categoriesForCreate.length && loading) ? (
        <Grid item className={classes.content}>
            <Paper className={classes.border}>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.fullWidth}>
                        <Grid container className={classes.container} direction="column" justify="space-between"
                              alignItems="stretch">
                            <Formik
                                initialValues={planningSettings ? {
                                        approve_chuz: new Date(planningSettings.approve_chuz),
                                        approve_rdz: new Date(planningSettings.approve_rdz),
                                        approve_cdz: new Date(planningSettings.approve_cdz),
                                        approve_chuz_days: planningSettings.approve_chuz_days,
                                        approve_rdz_days: planningSettings.approve_rdz_days,
                                        approve_cdz_days: planningSettings.approve_cdz_days,
                                        correction_period: planningSettings.correction_period,
                                        lead_time_message: planningSettings.lead_time_message,
                                        start_document_days: planningSettings.start_document_days,
                                        editing_after_rejection: planningSettings.editing_after_rejection,
                                        import_excel: planningSettings.import_excel,
                                        without_in_work_rdz: planningSettings.without_in_work_rdz,
                                        view_drafts_of_rdz: planningSettings.view_drafts_of_rdz,
                                        creation_of_a_price_request_before_planned_placement_date: planningSettings.creation_of_a_price_request_before_planned_placement_date,
                                        categories_id_approve_unapproved_standards: planningSettings.categories_id_approve_unapproved_standards,
                                        categories_id_required_purchase_lot_name: planningSettings.categories_id_required_purchase_lot_name,
                                    } :
                                    {
                                        approve_chuz: new Date(),
                                        approve_rdz: new Date(),
                                        approve_cdz: new Date(),
                                        approve_chuz_days: 0,
                                        approve_rdz_days: 0,
                                        approve_cdz_days: 0,
                                        correction_period: 0,
                                        lead_time_message: 0,
                                        start_document_days: 0,
                                        editing_after_rejection: 0,
                                        creation_of_a_price_request_before_planned_placement_date: 0,
                                        import_excel: false,
                                        without_in_work_rdz: false,
                                        view_drafts_of_rdz: false,
                                        categories_id_approve_unapproved_standards: [],
                                        categories_id_required_purchase_lot_name: []
                                    }}
                                validationSchema={Yup.object().shape({
                                    approve_chuz: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                    approve_rdz: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                    approve_cdz: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                    approve_chuz_days: Yup.number().min(0, 'Минимальное значение 0!').required("Поле не может быть пустым или состоять из одних пробелов!"),
                                    approve_rdz_days: Yup.number().min(0, 'Минимальное значение 0!').required("Поле не может быть пустым или состоять из одних пробелов!"),
                                    approve_cdz_days: Yup.number().min(0, 'Минимальное значение 0!').required("Поле не может быть пустым или состоять из одних пробелов!"),
                                    correction_period: Yup.number().min(0, 'Минимальное значение 0!').required("Поле не может быть пустым или состоять из одних пробелов!"),
                                    lead_time_message: Yup.number().min(0, 'Минимальное значение 0!').required("Поле не может быть пустым или состоять из одних пробелов!"),
                                    start_document_days: Yup.number().min(0, 'Минимальное значение 0!').required("Поле не может быть пустым или состоять из одних пробелов!"),
                                    editing_after_rejection: Yup.number().min(0, 'Минимальное значение 0!').required("Поле не может быть пустым или состоять из одних пробелов!"),
                                    creation_of_a_price_request_before_planned_placement_date: Yup.number().min(0, 'Минимальное значение 0!').required("Поле не может быть пустым или состоять из одних пробелов!"),
                                    categories_id_approve_unapproved_standards: Yup.array(),
                                    categories_id_required_purchase_lot_name: Yup.array(),
                                    import_excel: Yup.boolean(),
                                    without_in_work_rdz: Yup.boolean(),
                                    view_drafts_of_rdz: Yup.boolean(),
                                })}
                                onSubmit={(values, {setSubmitting}) => {
                                    return dispatch(SettingsActions.update({
                                        ...values,
                                        ...{
                                            approve_chuz: format(new Date(values.approve_chuz), 'MM-dd'),
                                            approve_rdz: format(new Date(values.approve_rdz), 'MM-dd'),
                                            approve_cdz: format(new Date(values.approve_cdz), 'MM-dd')
                                        }
                                    })).then(
                                        () => {
                                            dispatch({
                                                type: "ALERT_SUCCESS", payload: {
                                                    message: 'Настройки успешно обновлены',
                                                    type: 'success'
                                                }
                                            })
                                            setSubmitting(false)
                                        },
                                        () => {
                                            setSubmitting(false)
                                        }
                                    )
                                }}
                            >
                                {({
                                      dirty,
                                      values,
                                      errors,
                                      touched,
                                      isSubmitting,
                                      isValid,
                                      setFieldValue,
                                      setFieldTouched
                                  }) => (
                                    <Form className={classes.fullWidth}>
                                        <Grid item className={classes.inputContent}>
                                            <Grid container className={classes.fullWidth} direction="column"
                                                  justify="space-between" alignItems="stretch">
                                                <Grid item className={classes.header}>
                                                    <Grid container className={classes.fullWidth} direction="column"
                                                          justify="flex-start" alignItems="stretch">
                                                        <Grid item className={classes.fullWidth}>
                                                            <Typography className={classes.title}>
                                                                Настройки модуля планирования
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.notificationList} direction="row" justify="start" alignItems="stretch" spacing={4}>
                                                <Grid item xs={8}>
                                                    <Grid item container direction="row" justify="flex-start" alignItems="stretch" spacing={4}>
                                                        <Grid item xs={8}>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                <KeyboardDatePicker
                                                                    disableToolbar
                                                                    fullWidth
                                                                    name={`approve_chuz`}
                                                                    required
                                                                    orientation="landscape"
                                                                    variant="inline"
                                                                    format="dd-MM"
                                                                    openTo="date"
                                                                    autoOk
                                                                    error={Boolean(errors.hasOwnProperty('approve_chuz') && errors.approve_chuz && touched.hasOwnProperty('approve_chuz') && touched.approve_chuz)}
                                                                    helperText={errors.hasOwnProperty('approve_chuz') && touched.hasOwnProperty('approve_chuz') && (errors.approve_chuz ? errors.approve_chuz : null)}
                                                                    label="Утверждение ПЗ ЧУЗ до"
                                                                    value={values.approve_chuz}
                                                                    onChange={date => {
                                                                        setFieldValue("approve_chuz", date, true)
                                                                    }}
                                                                    onBlur={() => setFieldTouched(`approve_chuz`, true, true)}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Field
                                                                label="Уведомление пользователям за, дней"
                                                                fullWidth
                                                                name="approve_chuz_days"
                                                                type="number"
                                                                inputProps={{
                                                                    min: 1
                                                                }}
                                                                required
                                                                onChange={event => {
                                                                    setFieldValue("approve_chuz_days", event.target.value, true)
                                                                }}
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item container direction="row" justify="flex-start" alignItems="stretch" spacing={4}>
                                                        <Grid item xs={8}>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                <KeyboardDatePicker
                                                                    disableToolbar
                                                                    fullWidth
                                                                    name={`approve_rdz`}
                                                                    required
                                                                    orientation="landscape"
                                                                    variant="inline"
                                                                    format="dd-MM"
                                                                    openTo="date"
                                                                    autoOk
                                                                    error={Boolean(errors.hasOwnProperty('approve_rdz') && errors.approve_rdz && touched.hasOwnProperty('approve_rdz') && touched.approve_rdz)}
                                                                    helperText={errors.hasOwnProperty('approve_rdz') && touched.hasOwnProperty('approve_rdz') && (errors.approve_rdz ? errors.approve_rdz : null)}
                                                                    label="Утверждение ПЗ РДЗ до"
                                                                    value={values.approve_rdz}
                                                                    onChange={date => {
                                                                        setFieldValue("approve_rdz", date, true)
                                                                    }}
                                                                    onBlur={() => setFieldTouched(`approve_rdz`, true, true)}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Field
                                                                label="Уведомление пользователям за, дней"
                                                                fullWidth
                                                                name="approve_rdz_days"
                                                                type="number"
                                                                inputProps={{
                                                                    min: 1
                                                                }}
                                                                required
                                                                onChange={event => {
                                                                    setFieldValue("approve_rdz_days", event.target.value, true)
                                                                }}
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item container direction="row" justify="flex-start" alignItems="stretch" spacing={4}>
                                                        <Grid item xs={8}>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                <KeyboardDatePicker
                                                                    disableToolbar
                                                                    fullWidth
                                                                    name={`approve_cdz`}
                                                                    required
                                                                    orientation="landscape"
                                                                    variant="inline"
                                                                    format="dd-MM"
                                                                    openTo="date"
                                                                    error={Boolean(errors.hasOwnProperty('approve_cdz') && errors.approve_cdz && touched.hasOwnProperty('approve_cdz') && touched.approve_cdz)}
                                                                    helperText={errors.hasOwnProperty('approve_cdz') && touched.hasOwnProperty('approve_cdz') && (errors.approve_cdz ? errors.approve_cdz : null)}
                                                                    autoOk
                                                                    label="Утверждение ПЗ ЦДЗ до"
                                                                    value={values.approve_cdz}
                                                                    onChange={date => {
                                                                        setFieldValue("approve_cdz", date, true)
                                                                    }}
                                                                    onBlur={() => setFieldTouched(`approve_cdz`, true, true)}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Field
                                                                label="Уведомление пользователям за, дней"
                                                                fullWidth
                                                                name="approve_cdz_days"
                                                                type="number"
                                                                inputProps={{
                                                                    min: 1
                                                                }}
                                                                required
                                                                onChange={event => {
                                                                    setFieldValue("approve_cdz_days", event.target.value, true)
                                                                }}
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Grid item container direction="column" justify="flex-start" alignItems="stretch" spacing={4}>
                                                                <Grid item xs={12}>
                                                                    <Field
                                                                        label="Срок на корректировку ПЗ/Закупок, дней"
                                                                        fullWidth
                                                                        name="correction_period"
                                                                        type="number"
                                                                        inputProps={{
                                                                            min: 1
                                                                        }}
                                                                        required
                                                                        onChange={event => {
                                                                            setFieldValue("correction_period", event.target.value, true)
                                                                        }}
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Field
                                                                        label="Сообщение о сроке до размещения, дней"
                                                                        fullWidth
                                                                        name="lead_time_message"
                                                                        type="number"
                                                                        inputProps={{
                                                                            min: 1
                                                                        }}
                                                                        required
                                                                        onChange={event => {
                                                                            setFieldValue("lead_time_message", event.target.value, true)
                                                                        }}
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Field
                                                                        label="Уведомление о необходимости начать подготовку документации к закупке, дней"
                                                                        fullWidth
                                                                        name="start_document_days"
                                                                        type="number"
                                                                        inputProps={{
                                                                            min: 1
                                                                        }}
                                                                        required
                                                                        onChange={event => {
                                                                            setFieldValue("start_document_days", event.target.value, true)
                                                                        }}
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Field
                                                                        label="Редактирование после отклонения ПЗ/Закупки, дней"
                                                                        fullWidth
                                                                        name="editing_after_rejection"
                                                                        type="number"
                                                                        inputProps={{
                                                                            min: 1
                                                                        }}
                                                                        required
                                                                        onChange={event => {
                                                                            setFieldValue("editing_after_rejection", event.target.value, true)
                                                                        }}
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Field
                                                                        label="Формирование ЦЗ до План. срока размещения извещения, дней"
                                                                        fullWidth
                                                                        name="creation_of_a_price_request_before_planned_placement_date"
                                                                        type="number"
                                                                        inputProps={{
                                                                            min: 1
                                                                        }}
                                                                        required
                                                                        onChange={event => {
                                                                            setFieldValue("creation_of_a_price_request_before_planned_placement_date", event.target.value, true)
                                                                        }}
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid item container direction="row" justify="flex-start" alignItems="stretch" spacing={4}>
                                                        <Grid item xs={12}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        defaultChecked checked={values.import_excel}
                                                                        name="import_excel" color="primary"
                                                                        onChange={event => {
                                                                            setFieldValue("import_excel", event.target.checked, true)
                                                                        }}
                                                                    />
                                                                }
                                                                label="Импорт из Exсel"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item container direction="row" justify="flex-start" alignItems="stretch" spacing={4}>
                                                        <Grid item xs={12}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        defaultChecked
                                                                        checked={values.without_in_work_rdz}
                                                                        name="without_in_work_rdz"
                                                                        color="primary"
                                                                        onChange={event => {
                                                                            setFieldValue("without_in_work_rdz", event.target.checked, true)
                                                                        }}
                                                                    />
                                                                }
                                                                label='Утверждение/отклонение ПЗ/закупок без статуса "В работе РДЗ"'
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item container direction="row" justify="flex-start" alignItems="stretch" spacing={4}>
                                                        <Grid item xs={12}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        defaultChecked
                                                                        checked={values.view_drafts_of_rdz}
                                                                        name="view_drafts_of_rdz" color="primary"
                                                                        onChange={event => {
                                                                            setFieldValue("view_drafts_of_rdz", event.target.checked, true)
                                                                        }}
                                                                    />
                                                                }
                                                                label='Просмотр ПЗ РДЗ в статусе "Черновик"'
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item container direction="row" justify="flex-start" alignItems="stretch" >
                                                        <Grid item xs={12}>
                                                            <FormControl className={classes.fullWidth}>
                                                                <Typography className={classes.customLabel}>Разрешить утверждение ПЗ/закупок с неутвержденными эталонами для категорий:</Typography>
                                                                <Field
                                                                    fullWidth
                                                                    type="text"
                                                                    name="categories_id_approve_unapproved_standards"
                                                                    variant="standard"
                                                                    component={Select}
                                                                    multiple
                                                                >
                                                                    {categoriesForCreate.map(currency => (
                                                                        <MenuItem key={currency.id} value={currency.id}>
                                                                            {currency.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Field>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item container direction="row" justify="flex-start" alignItems="stretch" >
                                                        <Grid item xs={12}>
                                                            <FormControl className={classes.fullWidth}>
                                                                <Typography className={classes.customLabel}>Обязательное наименование лота для категорий:</Typography>
                                                                <Field
                                                                    fullWidth
                                                                    type="text"
                                                                    name="categories_id_required_purchase_lot_name"
                                                                    variant="standard"
                                                                    component={Select}
                                                                    multiple
                                                                >
                                                                    {categoriesForCreate.map(currency => (
                                                                        <MenuItem key={currency.id} value={currency.id}>
                                                                            {currency.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Field>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.footer}>
                                            <Grid container direction="column" justify="center" alignItems="stretch"
                                                  spacing={1}>
                                                <Grid item className={classes.footerContent}>
                                                    <Grid container className={classes.footerContainer} direction="row"
                                                          justify="flex-end" alignItems="center" spacing={2}>
                                                        <Grid item>
                                                            <Submit
                                                                disableElevation
                                                                variant="contained"
                                                                color="primary"
                                                                type="submit"
                                                                disabled={!dirty || isSubmitting || !isValid}
                                                            >
                                                                Сохранить
                                                            </Submit>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    ) : null
}
