import React from "react"
import {useSelector} from "react-redux"

import {
    Grid
} from "@material-ui/core"

import {Submit} from "../../../../App/components/Button/Submit"
import {history} from "../../../../App/helpers/history";
import {AuthorizationService} from "../../../../Auth/services/authorization";

export const Edit = (props) => {
    const { schedule, disabled = false } = props

    const {account} = useSelector(state => state.account)

    return AuthorizationService.roles(account, 'supervisor') ? (
        <Grid item>
            <Submit
                disabled={disabled || !schedule}
                disableElevation
                variant="contained"
                color="primary"
                type="button"
                style={{height: '35px'}}
                onClick={() => {
                    history.push(`/planning/schedule/${schedule?.id}/edit`)
                }}
            >
                Редактировать
            </Submit>
        </Grid>
    ) : null
}
