import {HttpService} from "../../App/services/http"

export const ReclamationService = {
    reclamations,
    reclamation,
    add,
    edit,
    action,
    status,
    types,
    demands,
    filter
}

function reclamations(params) {
    return HttpService.get(`/contract/delivery/reclamations`, params)
}

function reclamation(id) {
    return HttpService.get(`/contract/delivery/reclamation/${id}`)
        .then(response => {
            return response.reclamation
        })
}

function add(formData) {
    return HttpService.post(`/contract/delivery/reclamation`, formData, true, true)
}

function edit(id, formData) {
    return HttpService.post(`/contract/delivery/reclamation/${id}`, formData, true, true)
}

function action(id, values) {
    return HttpService.put(`/contract/delivery/reclamation/${id}/action`, values)
}

function status() {
    return HttpService.get(`/contract/delivery/reclamation/status`)
        .then(response => {
            return response.status
        })
}

function types() {
    return HttpService.get(`/contract/delivery/reclamation/types`)
        .then(response => {
            return response.types
        })
}

function demands() {
    return HttpService.get(`/contract/delivery/reclamation/demands`)
        .then(response => {
            return response.demands
        })
}

function filter(params) {
    return HttpService.get(`/contract/delivery/reclamation/filter`, params)
}
