import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import format from "date-fns/format"

import {Grid, makeStyles} from "@material-ui/core"

import {ShopActions} from "../Shop/actions/shop"
import {history} from "../../../App/helpers/history"
import {CustomTable} from "../../../App/components/Table/Table";
import {TableHeader} from "../../../App/components/Table/TableHeader";
import {Table} from "@devexpress/dx-react-grid-material-ui";
import {SettingsActions} from "../../../Settings/actions/settings";
import {localState} from "../../../App/components/LocalState";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 111px)"
    },
    input: {
        "width": "100%",
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 110px)",
        "min-height": "calc(100vh - 110px)"
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    tableRowAlert: {
        "cursor": "pointer",
        "background-color": "#f9f1f0",
        "text-decoration": "none"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    }
}))

const columns = [
    {name: 'id', title: '№'},
    {name: 'category', title: 'Категория'},
    {name: 'lots_count', title: 'Кол-во лотов'},
    {name: 'contract_number', title: '№ договора'},
    {name: 'status', title: 'Статус'},
    {name: 'application_start', title: 'Дата формирования'},
];

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [orders, setOrders] = useState(localState)
    const {filter} = useSelector(state => state.filters['marketplace_orders'])

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [tableRef, setTableRef] = useState(null)

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'id', width: 100},
        {columnName: 'category', width: 300},
        {columnName: 'lots_count', width: 250},
        {columnName: 'status', width: 250},
        {columnName: 'contract_number', width: 300},
        {columnName: 'application_start', width: 250}
    ])

    const [columnOrder, setColumnOrder] = useState([
        'id',
        'category',
        'lots_count',
        'status',
        'contract_number',
        'application_start',
    ])

    const [initialize, setInitialize] = useState(false);

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
        }
    }

    useEffect(() => {
        const getOrders = async () => {
            return await dispatch(ShopActions.orders({
                limit: rowsPerPage,
                page: page,
                sort: 'active',
                cart: true,
                ...getParams()
            }))
        }

        getOrders().then((response) => {
            setOrders(response)
            tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
        })
        // eslint-disable-next-line
    }, [dispatch, page, rowsPerPage, filter]);

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: "MARKETPLACE_ORDERS_FILTER", payload: {limitRows: newRowsPerPage}})
    }

    useEffect(() => {
        if (!initialize) {
            dispatch(SettingsActions.table('marketplace_orders')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                dispatch({type: "FILTER_CLEAR"})
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'marketplace_orders',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    return <Grid item className={classes.content}>
        <CustomTable
            meta={orders.meta}
            tableName={'marketplace_orders'}
            rows={orders.data?.map((order) => ({
                id: order.id,
                category: order.category ? order.category.name : '',
                lots_count: order.count.lots,
                contract_number: order.contract_number,
                status: order.status.index === 8 ? "В работе" : order.status.name,
                application_start: format(new Date(order.created), 'dd.MM.yyyy HH:mm'),
            }))}
            columns={columns}
            page={{
                page: page,
                setPage: setPage,
                rowsPerPage: rowsPerPage,
                handlePageSizeChange: handlePageSizeChange
            }}
            setTableRef={setTableRef}
            columnsSettings={{
                columnOrder: columnOrder,
                setColumnOrder: setColumnOrder,
                setColumnWidths: setColumnWidths,
                columnWidths: columnWidths,
                hiddenColumnNames: hiddenColumnNames,
                setHiddenColumnNames: setHiddenColumnNames
            }}
            tableHeader={TableHeader}
            filterActionType={"MARKETPLACE_ORDERS_FILTER"}
            classInputContent={classes.inputContent}
            rowComponent={({row, tableRow, children}) => (
                <Table.Row
                    tableRow={tableRow}
                    key={row.id}
                    hover
                    children={children}
                    className={classes.default}
                    row={row}
                    onClick={() => {
                        history.push(`/marketplace/order/${row.id}`)
                    }}
                    style={{'cursor': 'pointer'}}
                />
            )}
            filters={{}}
        />
    </Grid>
}
