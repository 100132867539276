import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Form, Formik} from "formik"
import * as Yup from "yup"

import {
    DialogContent,
    DialogTitle,
    Dialog,
    makeStyles,
    Grid,
    DialogActions
} from "@material-ui/core"

import {Submit} from "../../../App/components/Button/Submit"
import {history} from "../../../App/helpers/history";
import {InvoiceActions} from "../../../Account/actions/invoice";

const useStyles = makeStyles(() => ({
    sendOffer: {
        "width": '285px',
        "float": "right",
        "padding": "0px",
        "margin-top": "6px"
    },
    dialogContainer: {
        "& > div": {
            "max-width": "calc(100vw - 200px)",
            "min-height": "calc(100vh - 300px)",
            overflow: "hidden",
        }
    },
    dialogContent: {
        "padding": 0,
        "height": "calc(100vh - 178px)"
    },
    dialogTitle: {
        "margin": "0",
        "padding-top": "2px",
        "font-size": "18px",
        "font-weight": "600",
        "color": "#fff",
        "text-transform": "uppercase",
        "display": "flex",
        "justify-content": "space-between",
        "align-items": "center",
        "min-height": "40px",
        "padding": "0 20px",
        "background-color": "#898989",
    },
    frame: {
        "border-width": "0",
        "height": "calc(100% - 5px)"
    },
    fullHeight: {
        "height": "100%"
    },
    descriptionWrapper: {
        padding: "40px 50px",
        fontSize: "16px",
    },
    descriptionText: {
        margin: "0 0 15px",
        fontSize: "18px",
    },
    link: {
        "text-decoration": "underline",
        "color": "#485868"
    },
    footerContent: {
        width: "100%",
        position: "absolute",
        bottom: 0,
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
}))

export const Offer = (props) => {
    const {account} = useSelector(state => state.account)
    const dispatch = useDispatch()
    const {label} = props
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [exist, setExist] = useState(false)
    const [load, setLoad] = useState(false)

    const onSubmit = (values, {setSubmitting}) => {
        return dispatch(InvoiceActions.bill()).then(
            () => {
                setSubmitting(false);
                history.push("/invoices")
            },
            () => {
                setSubmitting(false);
            }
        )
    }

    useEffect(() => {
        const exists = async () => {
            return await dispatch(InvoiceActions.unpaid(account.company.id))
        }

        if (load) {
            exists().then(exist => {
                setExist(exist)
                setLoad(false)
                setOpen(true)
            })
        }
        // eslint-disable-next-line
    }, [load])

    return (
        <React.Fragment>
            <Submit
                disableElevation
                variant="contained"
                color="primary"
                onClick={e => {
                    e.stopPropagation()
                    setLoad(true)
                }}
                type="button"
                className={classes.sendOffer}
            >
                {label}
            </Submit>
            {open && !exist ? (
                <Dialog
                    open={open}
                    fullWidth={true}
                    onClose={(e) => {
                        e.stopPropagation()
                        setOpen(false)
                    }}
                    maxWidth={exist ? 'sm' : 'lg'}
                >
                    <Formik
                        initialValues={{}}
                        validationSchema={Yup.object().shape({})}
                        onSubmit={onSubmit}
                    >
                        {({
                              isSubmitting,
                              isValid,
                              handleSubmit
                          }) => (
                            <Form>
                                https://drive.google.com/file/d/1WEadkaoez-ivS2vT1HysN1unO_8QDQ3u/view?usp=sharing

                                <DialogTitle>Оферта</DialogTitle>
                                <DialogContent className={classes.dialogContent}>
                                    <iframe title="Оферта" className={classes.frame} src="https://drive.google.com/file/d/1WEadkaoez-ivS2vT1HysN1unO_8QDQ3u/preview" width="100%"/>
                                </DialogContent>
                                <DialogActions>
                                    <Grid container direction="row" justify="flex-end" alignItems="stretch" spacing={2}>
                                        <Grid item>
                                            <Submit
                                                variant="contained"
                                                component="label"
                                                color="primary"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setOpen(false)
                                                }}
                                            >
                                                Отмена
                                            </Submit>
                                        </Grid>
                                        <Grid item>
                                            <Submit
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting || !isValid}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    handleSubmit()
                                                }}
                                                type="submit"
                                            >
                                                Принять условия Оферты
                                            </Submit>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </Dialog>
            ) : null}
        </React.Fragment>
    )
}
