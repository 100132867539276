import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce, useDebouncedCallback} from 'use-debounce'
import socketIOClient from 'socket.io-client'
import clsx from "clsx"
import useSound from 'use-sound'

import {
    Avatar,
    Badge,
    Divider,
    Drawer,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Tab,
    Tabs,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core"
import {Search as SearchIcon, Group as GroupIcon} from "@material-ui/icons"
import {green} from "@material-ui/core/colors"

import {MessengerActions} from "./actions/messenger"
import {Submit} from "../App/components/Button/Submit"
import {Create} from "./components/Create"
import {Chat} from "./components/Chat"
import notificationSound from './assets/notification_sound.mp3'
import {getUserPosition} from '../App/helpers/position'
import {getFullName} from "../App/helpers/user"
import * as AppStorage from "../App/helpers/storage"

const socket = socketIOClient(process.env.REACT_APP_MESSENGER_SOCKET_HOST, {path: process.env.REACT_APP_MESSENGER_SOCKET_URL})

const useStyles = makeStyles((theme) => ({
    fullWidth: {
        'width': '100%'
    },
    fullHeight: {
        'height': '100%'
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    additional: {
        'width': '320px',
        'min-height': '100vh'
    },
    content: {
        'width': '600px',
        'border-left': '2px solid #c1c1c1',
        'min-height': '100vh'
    },
    creation: {
        'width': '450px',
        'border-left': '2px solid #c1c1c1',
        'min-height': '100vh'
    },
    label: {
        'width': "100%",
        'height': '48px',
        'border-bottom': '2px solid #c1c1c1',
    },
    margin: {
        'margin': "0"
    },
    message: {
        'width': "100%",
        'height': '67px',
        'border-top': '2px solid #c1c1c1',
    },
    text: {
        'width': "calc(100% - 64px)",
        '& .MuiTextField-root': {
            '& .MuiInput-root': {
                'padding': '6px 0 2px'
            }
        }
    },
    search: {
        'width': "100%",
        'padding': "8px",
    },
    column: {
        'width': "100%",
        'height': '86px',
        'padding': "8px",
    },
    contact: {
        'width': "100%"
    },
    button: {
        'width': "100%"
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    default: {
        'height': '60px'
    },
    active: {
        'height': '60px',
        'background-color': `${green[100]} !important`,
        '&:hover': {
            'background-color': `${green[50]} !important`
        }
    },
    eclipsis: {
        'overflow': 'hidden',
        "text-overflow": "ellipsis",
        "white-space": "nowrap"
    },
    position: {
        'color': 'gray',
    },
    users: {
        'overflow-y': 'auto',
        'height': 'calc(100vh - 142px)',
        'padding-top': '0'
    },
    chats: {
        'overflow-y': 'auto',
        'height': 'calc(100vh - 200px)',
        'padding-top': '0'
    },
    messages: {
        'overflow-y': 'auto',
        'height': 'calc(100vh - 121px)',
        'padding-top': '0'
    },
    tabs: {
        "&.MuiTab-textColorPrimary.Mui-selected": {
            backgroundColor: "#fff",
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "4px",
        }
    }
}))

const StyledBadge = withStyles(() => ({
    badge: {
        right: 5,
        bottom: 5
    }
}))(Badge);

export const Messenger = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { chats, chat } = useSelector(state => state.messenger)
    const [play, {stop}] = useSound(notificationSound,{volume: 0.75})
    const [isPlaying, setIsPlaying] = useState(false)

    const [creation, setCreation] = useState(null)
    const [search, setSearch] = useState('')
    const [searchRequest] = useDebounce(search,900)
    const [connect, setConnect] = useState(false)

    const debounced = useDebouncedCallback(
        (value) => {
            setLoading(value)
        }, 700
    )

    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [section, setSection] = useState('chats')
    const {open} = props

    const getDirectChat = async (id) => {
        return await dispatch(MessengerActions.direct(id))
    }

    const getChats = async () => {
        return await dispatch(MessengerActions.chats({
            ...(searchRequest.length ? {search: searchRequest} : {})
        }))
    }

    const getUsers = async () => {
        return await dispatch(MessengerActions.users({
            page: 1,
            limit: 20,
            ...(searchRequest.length ? {search: searchRequest} : {})
        }))
    }

    useEffect(() => {
        if (!connect) {
            getChats().then(() => {
                socket.emit('connection', {token: AppStorage.get('token')})
            })

            socket.on('connection', () => {
                setConnect(true)
            })

            socket.on('disconnect', () => {
                setConnect(false)
                socket.removeAllListeners()
            })

            socket.on('message', message => {
                setIsPlaying(true)
                dispatch({type: "MESSENGER_ADD_CHAT_MESSAGE", payload: message})
            })

            socket.on('chat', chat => {
                dispatch({type: "MESSENGER_ADD_CHAT", payload: chat})
            })
        }
        // eslint-disable-next-line
    }, [connect])

    useEffect(() => {
        if (open && !loading) {
            // eslint-disable-next-line default-case
            switch (section) {
                case 'chats':
                    getChats().then(() => {
                        setLoading(true)
                    })
                    break
                case 'users':
                    getUsers().then(response => {
                        setUsers(response.data)
                        setLoading(true)
                    })
                    break
            }
        }
        // eslint-disable-next-line
    }, [open, loading, searchRequest])

    useEffect(() => {
        if (!open) {
            setSection('chats')
            setCreation(false)
            setLoading(false)
            dispatch({type: "MESSENGER_CHAT", payload: null})
            setUsers([])
        }
        // eslint-disable-next-line
    }, [open])

    useEffect(() => {
        if (chat) {
            setCreation(false)
        }
    }, [chat])

    useEffect(() => {
        if (!creation) {
            setLoading(false)
        }
    }, [creation])

    useEffect(() => {
        if (isPlaying) {
            play()
        }
        setIsPlaying(false)
        // eslint-disable-next-line
    }, [isPlaying])

    useEffect(() => {
        return () => {
            stop()
        }
    }, [stop])

    const getAvatar = (chat) => {
        switch (chat.type.key) {
            case 'group':
                return <Avatar alt={chat.name}><GroupIcon /></Avatar>
            default:
                return <Avatar alt={chat.name}/>
        }
    }

    const getList = () => {
        // eslint-disable-next-line default-case
        switch (section) {
            case 'chats':
                return <List component="nav" className={classes.chats}>
                    {chats.map(item => (<React.Fragment key={item.id}>
                        <Divider/>
                        <ListItem
                            button
                            classes={{ root: (chat && (item.id === chat.id)) ? classes.active : classes.default }}
                            onClick={() => {
                                dispatch({type: "MESSENGER_CHAT", payload: item})
                            }}
                        >
                            <ListItemAvatar>
                                <StyledBadge
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                    badgeContent={item.recent}
                                    color="primary"
                                >
                                    {getAvatar(item)}
                                </StyledBadge>
                            </ListItemAvatar>
                            <ListItemText primary={<Typography className={classes.eclipsis}>{item.name}</Typography>} secondary={<Typography variant="body2" className={classes.eclipsis}>{item.message ? item.message.text : null}</Typography>} />
                        </ListItem>
                    </React.Fragment>))}
                </List>
            case 'users':
                return <List component="nav" className={classes.users}>
                    {users.map(user => (<React.Fragment key={user.id}>
                        <Divider/>
                        <ListItem
                            button
                            onClick={() => {
                                getDirectChat(user.id).then(chat => {
                                    dispatch({type: "MESSENGER_CHAT", payload: chat ?? {id: null, type: {key: 'direct'}, name: getFullName(user), users: [user]}})
                                })
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    alt={user.login}
                                />
                            </ListItemAvatar>
                            <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                                <Typography className={classes.eclipsis}>{getFullName(user)}</Typography>
                                <Typography className={clsx(classes.eclipsis,classes.position)}>{getUserPosition(user)}</Typography>
                            </Grid>
                        </ListItem>
                    </React.Fragment>))}
                </List>
        }
    }

    return (
        <Drawer anchor={'right'} open={open} onClose={props.onClose}>
            {open ? (creation ? <Create socket={socket} handleClose={() => {setCreation(false)}} /> : <Grid container direction="row" justify="center" alignItems="stretch">
                <Grid item className={classes.additional}>
                    <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                        <Grid item>
                            <Tabs
                                value={section}
                                indicatorColor="primary"
                                className={classes.tabs}
                                textColor="primary"
                                onChange={(event, newValue) => {
                                    setSearch('')
                                    setLoading(false)
                                    setSection(newValue)
                                }}
                            >
                                <Tab value={'chats'} label="Чаты" />
                                <Tab value={'users'} label="Пользователи" />
                            </Tabs>
                        </Grid>
                        <Grid item className={classes.column}>
                            <TextField
                                variant="standard"
                                placeholder={`Поиск`}
                                fullWidth
                                margin={'normal'}
                                value={search}
                                onChange={(event) => {
                                    setSearch(event.target.value)
                                    debounced.callback(false)
                                }}
                                classes={{
                                    root: classes.filterLabel
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon  style={{color: '#c1c1c1'}}/>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item className={classes.contact}>
                            {getList()}
                        </Grid>
                        {(section === 'chats') && <Grid item className={classes.button}>
                            <Grid container className={clsx(classes.margin, classes.fullWidth)} direction="row" justify="center" alignItems="center" spacing={2}>
                                <Grid item className={classes.fullWidth}>
                                    <Submit
                                        disabled={creation}
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        onClick={() => {
                                            setCreation(true)
                                            dispatch({type: "MESSENGER_CHAT", payload: null})
                                        }}
                                    >
                                        Создать чат
                                    </Submit>
                                </Grid>
                            </Grid>
                        </Grid>}
                    </Grid>
                </Grid>
                {chat ? <Chat chat={chat} socket={socket} /> : null}
            </Grid>) : null}
        </Drawer>
    )
}
