import {LogService} from "../services/log"

export const LogActions = {
    log,
    filter
}

function log(plan, params = {}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        LogService.log(plan, params)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function filter(plan, params) {
    return dispatch => new Promise((resolve, reject) => {
        LogService.filter(plan, params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    reject(error)
                }
            )
    })
}
