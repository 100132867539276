import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"

import {Grid, InputAdornment, makeStyles, TextField,} from "@material-ui/core"
import {History as HistoryIcon, Search as SearchIcon} from "@material-ui/icons"
import {Table} from "@devexpress/dx-react-grid-material-ui";

import {ButtonList} from "../../../App/components/ButtonList/ButtonList"
import {Submit} from "../../../App/components/Button/Submit"
import {CompanyActions} from "../../actions/company"
import {Company as Dialog} from "./Company"
import {CustomTable} from "../../../App/components/Table/Table";
import {TableHeader} from "../../../App/components/Table/TableHeader";
import {SettingsActions} from "../../../Settings/actions/settings";
import {ColumnChooser} from "../../../App/components/Table/ColumnChooser";
import clsx from "clsx";
import {useDebounce, useDebouncedCallback} from "use-debounce";
import {localState} from "../../../App/components/LocalState";

const useStyles = makeStyles(theme => ({
    content: {
        width: '100%',
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 158px)"
    },
    input: {
        "width": "100%",
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 210px)",
        "min-height": "calc(100vh - 210px)"
    },
    filterLabel: {
        "background": "#fff",
        "margin-bottom": "0 !important",
        "margin-top": "5px !important",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        },
        "& input": {
            padding: "6px 15px 7px"
        }
    },
    searchLabel: {},
}))

const typeList = [
    {
        key: 'all',
        value: 'Все'
    },
    {
        key: 'after_fact',
        value: 'Постфактум'
    },
    {
        key: 'active',
        value: 'Рабочий'
    }
]

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [companies, setCompanies] = useState(localState)
    const {filter} = useSelector(state => state.filters['contracts_past'])

    const [id, setId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [dialog, setDialog] = useState(false)
    const [tabType, setTabType] = useState(0)
    const [type, setType] = useState('all')
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [tableRef, setTableRef] = useState(null)

    const [name, setName] = useState(filter.searchName)
    const [number, setNumber] = useState(filter.searchNumber)
    const [kpp, setKpp] = useState(filter.searchKpp)
    const debounced = useDebouncedCallback(
        (value, name) => {
            switch (name) {
                case 'name':
                    dispatch({
                        type: "CONTRACTS_PAST_FILTER",
                        payload: {searchName: value}
                    })
                    break;
                case 'number':
                    dispatch({
                        type: "CONTRACTS_PAST_FILTER",
                        payload: {searchNumber: value}
                    })
                    break;
                case 'kpp':
                    dispatch({
                        type: "CONTRACTS_PAST_FILTER",
                        payload: {searchKpp: value}
                    })
                    break;
            }
        },
        900
    );

    const [columns] = useState([
        {name: 'company_name', title: 'Наименование организации'},
        {name: 'number', title: 'ИНН/БИН'},
        {name: 'kpp', title: 'КПП'},
        {name: 'status', title: 'Статус'}
    ])

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'number', width: 200},
        {columnName: 'kpp', width: 200},
        {columnName: 'company_name', width: 300},
        {columnName: 'status', width: 150},
    ])

    const [columnOrder, setColumnOrder] = useState([
        'company_name',
        'number',
        'kpp',
        'status',
    ])

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const [initialize, setInitialize] = useState(false);

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...(name ? {company_name: name} : {}),
            ...(number ? {number: number} : {}),
            ...(kpp ? {kpp: kpp} : {}),
            ...((type !== 'all') ? {active: (type === 'active')} : {}),
        }
    }

    useEffect(() => {
        debounced.callback(name, 'name')
    }, [name]);

    useEffect(() => {
        debounced.callback(number, 'number')
    }, [number]);

    useEffect(() => {
        debounced.callback(kpp, 'kpp')
    }, [kpp]);

    useEffect(() => {
        const getCompanies = async () => {
            return await dispatch(CompanyActions.postAuditSuppliers({
                limit: rowsPerPage,
                page: page,
                type: 'supplier',
                ...getParams()
            }))
        }

        if (!loading) {
            getCompanies().then((response) => {
                setCompanies(response)
                setId(null)
                tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
            })
        }
        // eslint-disable-next-line
    }, [dispatch, page, rowsPerPage, type, filter]);

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: 'CONTRACTS_PAST_FILTER', payload: {limitRows: newRowsPerPage}})
    }

    useEffect(() => {
        if (!dialog) {
            setLoading(false)
        }
    }, [dialog]);

    useEffect(() => {
        if (!initialize) {
            dispatch(SettingsActions.table('contracts_past')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                dispatch({type: "FILTER_CLEAR"})
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'contracts_past',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    return <Grid item className={classes.content}>
        <Grid container direction="column" justify="center" alignItems="stretch">
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item xs={3} style={{marginBottom: '15px'}}>
                    <TextField
                        id="name"
                        variant="standard"
                        name={'name'}
                        value={name ?? ''}
                        placeholder={`Название организации`}
                        fullWidth={true}
                        margin={'normal'}
                        onChange={(event) => {
                            setName(event.target.value)
                        }}
                        classes={{
                            root: clsx(classes.filterLabel, classes.searchLabel)
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon style={{color: '#c1c1c1'}}/>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={3} style={{marginBottom: '15px'}}>
                    <TextField
                        id="number"
                        variant="standard"
                        name={'number'}
                        value={number ?? ''}
                        placeholder={`ИНН/БИН`}
                        fullWidth={true}
                        margin={'normal'}
                        onChange={(event) => {
                            setNumber(event.target.value)
                        }}
                        classes={{
                            root: clsx(classes.filterLabel, classes.searchLabel)
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon style={{color: '#c1c1c1'}}/>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={3} style={{marginBottom: '15px'}}>
                    <TextField
                        id="kpp"
                        variant="standard"
                        name={'kpp'}
                        placeholder={`КПП`}
                        value={kpp ?? ''}
                        fullWidth={true}
                        margin={'normal'}
                        onChange={(event) => {
                            setKpp(event.target.value)
                        }}
                        classes={{
                            root: clsx(classes.filterLabel, classes.searchLabel)
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon style={{color: '#c1c1c1'}}/>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        <ButtonList value={tabType} values={typeList} onChange={(_, value) => {
                            setId(null)
                            setTabType(value)
                            setPage(1)
                            setType(typeList[value].key)
                        }}/>
                    </Grid>
                    <Grid item style={{marginTop: '-10px'}}>
                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                            <Grid item>
                                <Submit
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    disabled={!id}
                                    component={Link}
                                    to={`/contracts?supplier_id=${id}`}
                                >
                                    Создать договор
                                </Submit>
                            </Grid>
                            <Grid item>
                                <Submit
                                    disableElevation
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    disabled={!id || (id && !!companies.data.find(company => ((company.id === id) && company.active)))}
                                    component={Link}
                                    to={`/contracts?supplier_id=${id}&brief=true`}
                                >
                                    Основание для расчета
                                </Submit>
                            </Grid>
                            <Grid item>
                                <Submit
                                    disableElevation
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    disabled={!!id}
                                    onClick={() => {
                                        setDialog(true)
                                    }}
                                >
                                    Создать поставщика
                                </Submit>
                            </Grid>
                            <Grid item style={{marginTop: '-4px'}}>
                                <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <CustomTable
            meta={companies.meta}
            tableName={'contracts_past'}
            rows={companies?.data.map(company => ({
                company_name: <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                    {!company.active &&
                        <Grid item><HistoryIcon/></Grid>}
                    <Grid item>{company.legal_detail.name}</Grid>
                </Grid>,
                number: company.legal_detail.inn ?? company.legal_detail.bin,
                kpp: company.legal_detail.kpp,
                status: company.active ? 'Рабочий' : 'Постфактум',
            }))}
            columns={columns}
            page={{page: page, setPage: setPage, rowsPerPage: rowsPerPage, handlePageSizeChange: handlePageSizeChange}}
            setTableRef={setTableRef}
            columnsSettings={{
                columnOrder: columnOrder,
                setColumnOrder: setColumnOrder,
                setColumnWidths: setColumnWidths,
                columnWidths: columnWidths,
                hiddenColumnNames: hiddenColumnNames,
                setHiddenColumnNames: setHiddenColumnNames
            }}
            tableHeader={TableHeader}
            filterActionType={'CONTRACTS_PAST_FILTER'}
            classInputContent={classes.inputContent}
            rowComponent={({row, tableRow, children}) => (
                <Table.Row
                    tableRow={tableRow}
                    children={children}
                    className={classes.default}
                    row={row}
                    onClick={(e, _) => {
                        (companies.data[tableRow.rowId].id === id) ? setId(null) : setId(companies.data[tableRow.rowId].id)
                    }}
                    selected={companies.data[tableRow.rowId].id === id}

                />
            )}
            filters={{}}
        />
        <Dialog open={dialog} setDialog={setDialog}/>
    </Grid>
}
