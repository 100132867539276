import React, {useEffect, useState} from 'react'

import {FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Typography} from '@material-ui/core'

import {Submit} from "../../../App/components/Button/Submit"

const useStyles = makeStyles(() => ({
    fullWidth: {
        'width': '100%'
    },
    submit: {
        width: "95%",
        marginLeft: "7px"
    }
}))

export const Month = (props) => {
    const classes = useStyles()

    const {name, filters, setFilters, setDefaultFilters, title} = props

    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState({[title.filter]: filters.hasOwnProperty([title.filter]) ? filters[title.filter] : []})
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setFilters(filter)
        // eslint-disable-next-line
    }, [filter])

    useEffect(() => {
        if (!loading) {
            setLoading(true)
            setDefaultFilters({[title.filter]: []})
        }
        // eslint-disable-next-line
    }, [loading])

    const months = [
        {value: 1, name: 'Январь'},
        {value: 2, name: 'Февраль'},
        {value: 3, name: 'Март'},
        {value: 4, name: 'Апрель'},
        {value: 5, name: 'Май'},
        {value: 6, name: 'Июнь'},
        {value: 7, name: 'Июль'},
        {value: 8, name: 'Август'},
        {value: 9, name: 'Сентябрь'},
        {value: 10, name: 'Октябрь'},
        {value: 11, name: 'Ноябрь'},
        {value: 12, name: 'Декабрь'}
    ]

    const onClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Grid item>
                <Typography variant="caption">
                    Месяц
                </Typography>
            </Grid>
            <Grid item className={classes.fullWidth}>
                <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel shrink>{name}</InputLabel>
                            <Select
                                fullWidth
                                multiple
                                value={filter[title.filter]}
                                onChange={(event) => {
                                    setFilter({
                                        [title.filter]: event.target.value.filter(Boolean)
                                    })
                                }}
                                open={open}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                            >
                                {months.map(month => (
                                    <MenuItem value={month.value} key={month.value}>{month.name}</MenuItem>
                                ))}
                                <Submit
                                    key="button"
                                    disableElevation
                                    className={classes.submit}
                                    variant="contained"
                                    color="primary"
                                    onClick={onClose}
                                    type="button"
                                >
                                    Применить
                                </Submit>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
