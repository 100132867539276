import React from "react"
import {useDispatch} from "react-redux"
import {Field, Form, Formik} from "formik"
import * as Yup from "yup";

import {Grid, makeStyles, Paper, Typography} from "@material-ui/core"

import {UserActions} from "../../actions/user"
import {TextFieldWithError as CustomTextField} from "../../../../../App/components/Inputs/TextFieldWithError"
import {Submit} from "../../../../../App/components/Button/Submit"
import {history} from "../../../../../App/helpers/history"

const useStyles = makeStyles(theme => ({
    input: {
        "padding": "0 4px 3px !important",
        "width": "100%"
    },
    inputContent: {
        "padding": "20px 30px 0"
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 274px)"
    },
    column: {
        "height": "86px"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
}))

export const AddContent = () => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const initial = {
        login: "",
        password: "",
        confirmation: "",
        email: "",
        last_name: "",
        middle_name: "",
        first_name: "",
    }
    const schema = {
        login: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        password: Yup.string().min(8, "Длина пароля должна быть не менее 8 символов!").required("Поле не может быть пустым или состоять из одних пробелов!"),
        email: Yup.string().email("Некорректный формат электронной почты!").required("Поле не может быть пустым или состоять из одних пробелов!"),
        last_name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        first_name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        middle_name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        confirmation: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!").oneOf([Yup.ref("password"), null], "Пароли не совпадают!"),
    }

    return <Grid item className={classes.content}>
        <Paper>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item className={classes.full}>
                    <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                        <Grid item className={classes.fullWidth}>
                            <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                <Grid item className={classes.header}>
                                    <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                        <Grid item className={classes.fullWidth}>
                                            <Typography className={classes.title}>
                                                Создание оператора
                                            </Typography>
                                            <Typography className={classes.description}>
                                                Все поля являются обязательными для заполнения.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Formik
                                initialValues={initial}
                                validationSchema={Yup.object().shape(schema)}
                                onSubmit={(values, {setSubmitting, setErrors}) => {
                                    return dispatch(UserActions.create({...values, ...{roles: ['operator']}})).then(
                                        () => {
                                            dispatch({
                                                type: "ALERT_SUCCESS", payload: {
                                                    message: 'Оператор успешно создан',
                                                    type: 'success'
                                                }
                                            })
                                            history.push('/user/operators')
                                            setSubmitting(false);
                                        },
                                        error => {
                                            if (error.errors) {
                                                setErrors(error.errors)
                                            }
                                            setSubmitting(false);
                                        }
                                    )
                                }}
                            >
                                {({
                                      dirty,
                                      handleSubmit,
                                      isSubmitting,
                                      isValid,
                                  }) => (
                                    <Form>
                                        <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                                            <Grid item className={classes.inputContent}>
                                                <Grid item className={classes.list}>
                                                    <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                        <Grid item xs={6} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name="login"
                                                                type="text"
                                                                label="Логин"
                                                                required={true}
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name="email"
                                                                type="email"
                                                                label="Электронный адрес"
                                                                required={true}
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                        <Grid item xs={6} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name="password"
                                                                type="password"
                                                                label="Пароль"
                                                                required={true}
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name="confirmation"
                                                                type="password"
                                                                label="Подтверждение пароля"
                                                                required={true}
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                        <Grid item xs={6} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name="last_name"
                                                                type="text"
                                                                label="Фамилия пользователя"
                                                                required={true}
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name="first_name"
                                                                type="text"
                                                                label="Имя пользователя"
                                                                required={true}
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                        <Grid item xs={6} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name="middle_name"
                                                                type="text"
                                                                label="Отчество пользователя"
                                                                required={true}
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.footer}>
                                            <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                                <Grid item className={classes.footerContent}>
                                                    <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                        <Grid item>
                                                            <Submit
                                                                disableElevation
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={isSubmitting || !isValid || !dirty}
                                                                onClick={handleSubmit}
                                                                type="submit"
                                                            >
                                                                Создать
                                                            </Submit>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    </Grid>
}
