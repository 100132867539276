import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import format from "date-fns/format"
import {ru} from "date-fns/locale"

import {
    Grid,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@material-ui/core"

import {DeliveryActions} from "../../../Delivery/actions/delivery"
import {Contract} from "../../../Contract/components/Contract"
import {SystemActions} from "../../../App/actions/system"

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px 0 29px !important",
    },
    input: {
        "width": "100%",
    },
    tableContainer: {
        "width": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 540px)",
        "margin-bottom": "4px"
    },
    tabContent: {
        "padding": "20px 29px !important"
    },
    itemInput: {
        "margin-top": "0 !important"
    },
    inputContent: {
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    download: {
        "color": "#485868"
    },
    winner: {
        '& .MuiTableCell-root': {
            'font-weight': '500'
        }
    },
    line: {
        "text-decoration": "line-through"
    },
    tooltip: {
        "max-width": "200px",
        "border-radius": "0px !important",
        "font-size": "0.8rem"
    },
    badge: {
        "right": "-12px"
    },
    tabBadge: {
        "padding-right": "34px"
    },
    orderLink: {
        color: "#898989",
        cursor: "pointer",
        "&:hover": {
            color: "#616161",
            textDecoration: 'underline',
        }
    },
    dialogContent: {
        padding: "0",
        "overflow": "hidden",
    },
    dialogContainer: {
        "& > div": {
            "max-width": "calc(100vw - 200px)"
        }

    },
}))

export const Content = (props) => {
    let {id} = useParams()

    const dispatch = useDispatch()
    const classes = useStyles()
    const {order, orderItems} = useSelector(state => state.delivery)
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [contractDialog, setContractDialog] = useState(null)
    const [dialog, setDialog] = useState(false)
    const [page, setPage] = useState(parseInt(orderItems.meta.current_page - 1))

    useEffect(() => {
        const getOrder = async () => {
            return await dispatch(DeliveryActions.order(id))
        }

        getOrder().then(() => {})

        return () => {
            dispatch({type: 'DELIVERY_ORDER'})
        }
    }, [dispatch, id]);

    const getOrderItems = async (orderId) => {
        return await dispatch(DeliveryActions.items(orderId, {
            limit: rowsPerPage,
            page: page + 1,
        }))
    }

    useEffect(() => {
        if (order) {
            getOrderItems(order.id).then(r => {
            })
        }
        // eslint-disable-next-line
    }, [dispatch, rowsPerPage, page, order]);

    useEffect(() => {
        const getCurrencies = async () => {
            await dispatch(SystemActions.currencies())
        }

        getCurrencies().then(_ => {
        })
    }, [dispatch])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    return order ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.full}>
                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                            {dialog ? <Contract open={dialog} setDialog={setDialog} id={contractDialog} supplierId={order.contract.offer.company.id}/> : null}
                            <Grid item className={classes.fullWidth}>
                                <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                    <Grid item className={classes.header}>
                                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                            <Grid item>
                                                <Grid container className={classes.fullWidth} direction="row" justify="space-between" alignItems="stretch">
                                                    <Grid item>
                                                        <Typography className={classes.title}>
                                                            Заявка № {order.number}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="button">
                                                            {order.status.name}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="button">
                                                    Дата
                                                    создания {format(new Date(order.created), 'dd.MM.yyyy HH:mm')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.header}>
                                <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                    <Grid item>
                                        <Grid container direction="row" justify="flex-start" alignItems="stretch">
                                            <Grid item xs={3}>
                                                <Typography variant="button">
                                                    Договор:
                                                </Typography>
                                                <Typography onClick={async (e) => {
                                                    setContractDialog(order.contract.id)
                                                    setDialog(true)
                                                }} className={classes.orderLink}>
                                                    {order.contract.number ?? order.contract.id}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="button">
                                                    Номер закупки:
                                                </Typography>
                                                <Typography>
                                                    {order.contract.offer.lot.order.purchase_number ?? 'Внеплановая'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="button">
                                                    Дата создания:
                                                </Typography>
                                                <Typography>
                                                    {format(new Date(order.created), 'H:mm PP', {locale: ru})}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" justify="flex-start" alignItems="stretch">
                                            <Grid item xs={3}>
                                                <Typography variant="button">
                                                    Заказчик:
                                                </Typography>
                                                <Typography>
                                                    {order.contract.company.legal_detail.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="button">
                                                    Поставщик:
                                                </Typography>
                                                <Typography>
                                                    {order.contract.offer.company.legal_detail.name}
                                                </Typography>
                                            </Grid>
                                            {order.actual_order_date &&
                                                <Grid item xs={3}>
                                                    <Typography variant="button">
                                                        Фактическая дата заказа:
                                                    </Typography>
                                                    <Typography>
                                                        {format(new Date(order.actual_order_date), 'H:mm PP', {locale: ru})}
                                                    </Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" justify="flex-start" alignItems="stretch">
                                            {order.address &&
                                                <Grid item xs={3}>
                                                    <Typography variant="button">
                                                        Адрес доставки:
                                                    </Typography>
                                                    <Typography>
                                                        {order.address}
                                                    </Typography>
                                                </Grid>
                                            }
                                            {order.expected_date &&
                                                <Grid item xs={3}>
                                                    <Typography variant="button">
                                                        Желаемая дата доставки:
                                                    </Typography>
                                                    <Typography>
                                                        {format(new Date(order.expected_date), 'PP', {locale: ru})}
                                                    </Typography>
                                                </Grid>
                                            }
                                            {order.comment &&
                                                <Grid item xs={3}>
                                                    <Typography variant="button">
                                                        Комментарий:
                                                    </Typography>
                                                    <Typography>
                                                        {order.comment}
                                                    </Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.tab}>
                                <TableContainer className={classes.tableContainer}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {[
                                                    {
                                                        id: '0',
                                                        label: '№',
                                                        format: (value) => value.toLocaleString(),
                                                    },
                                                    {
                                                        id: '1',
                                                        label: 'Артикул',
                                                        format: (value) => value.toLocaleString(),
                                                    },
                                                    {
                                                        id: '2',
                                                        label: 'Наименование',
                                                        format: (value) => value.toLocaleString(),
                                                    },
                                                    {
                                                        id: '3',
                                                        label: 'Категория',
                                                        format: (value) => value.toLocaleString(),
                                                    },
                                                    {
                                                        id: '4',
                                                        label: 'Количество',
                                                        format: (value) => value.toLocaleString(),
                                                    },
                                                    {
                                                        id: '5',
                                                        label: 'Цена',
                                                        format: (value) => value.toLocaleString(),
                                                    },
                                                    {
                                                        id: '6',
                                                        label: 'НДС',
                                                        format: (value) => value.toLocaleString(),
                                                    },
                                                    {
                                                        id: '7',
                                                        label: 'Сумма без НДС',
                                                        format: (value) => value.toLocaleString(),
                                                    },
                                                    {
                                                        id: '8',
                                                        label: 'Сумма с НДС',
                                                        format: (value) => value.toLocaleString(),
                                                    }
                                                ].map((column) => (
                                                    <TableCell key={column.id}>
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {orderItems.data.map((item, index) => (
                                                <TableRow hover key={index}>
                                                    <TableCell>
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.article_number}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.hasOwnProperty('category') && item.category ? item.category.name : null}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.amount}
                                                    </TableCell>
                                                    <TableCell>
                                                        {parseFloat(item.price).toLocaleString('ru-RU', {
                                                            style: 'currency',
                                                            currency: 'RUB',
                                                        })}
                                                    </TableCell>
                                                    <TableCell>
                                                        {(item.tax_percent > 0) ? `${item.tax_percent}%` : 'Без НДС'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {parseFloat((item.price * item.amount) - ((item.tax_percent > 0) ? (((item.price * item.amount) / (100 + parseInt(item.tax_percent))) * parseInt(item.tax_percent)) : 0)).toLocaleString('ru-RU', {
                                                            style: 'currency',
                                                            currency: 'RUB',
                                                        })}
                                                    </TableCell>
                                                    <TableCell>
                                                        {parseFloat(item.total ? item.total : item.price * item.amount).toLocaleString('ru-RU', {
                                                            ...(order.contract.currency ? {
                                                                style: 'currency',
                                                                currency: order.contract.currency.code
                                                            } : {})
                                                        }).replace('KZT', '₸')}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item className={classes.footer}>
                                <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                    <Grid item className={classes.footerContent}>
                                        <Grid container className={classes.footerContainer} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                            <Grid item>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                                    component='div'
                                                    labelRowsPerPage={'Записей на странице:'}
                                                    count={orderItems.meta.total}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    ) : null
}
