import {HttpService} from "../../App/services/http"

export const IntegrationService = {
    permissions,
    integrations,
    create,
    edit,
    delete: remove
}

function permissions() {
    return HttpService.get(`/company/integration/permissions`)
}

function integrations(params) {
    return HttpService.get(`/company/integrations`, params)
}

function create(values) {
    return HttpService.post(`/company/integration`, values)
}

function edit(values, id) {
    return HttpService.put(`/company/integration/${id}`, values)
}

function remove(id) {
    return HttpService.deleteMethod(`/company/integration/${id}`)
}
