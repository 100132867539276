import React, {Fragment, useEffect, useState} from "react"

import {IconButton, makeStyles, TextField, Typography} from "@material-ui/core"
import {Clear, Check} from '@material-ui/icons'

import {round} from "../../helpers/number"

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",
        minWidth: "115px",
        maxWidth: '150px'
        // justifyContent: "flex-end"
    },
    label: {
        fontSize: "16px",
        width: "auto",
        height: "auto",
        borderBottom: "dashed 1px #000",
        cursor: "pointer",
    },
    button: {
        fontSize: "16px",
        padding: "0",
        margin: "0 2px",
    },
    iconCheck: {
        color: "#fff",
        backgroundColor: "#898989",
        borderRadius: "4px",
        width: "21px",
        height: "21px",
    },
    iconCansel: {
        color: "#fff",
        backgroundColor: "#d9534f",
        borderRadius: "4px",
        width: "21px",
        height: "21px",
    },
    textField: {
        minWidth: "65px",
        "& input": {
            padding: "2px 5px",
        },
    }
}))

export const ButtonInput = (props) => {
    const {defaultValue, name, propId, type, pattern, unit, currency, disabled} = props
    const classes = useStyles()
    const [show, setShow] = useState(false)
    const [defaultVal, setDefaultVal] = useState(defaultValue)
    const [val, setVal] = useState(defaultValue)

    useEffect(() => {
        if (defaultValue !== val) {
            setVal(defaultValue)
        }
        // eslint-disable-next-line
    }, [defaultValue])

    const change = (e) => {
        e.stopPropagation()
        props.change(e.target.name, e.target.value.replace(',', '.'))
        setVal(e.target.value.replace(',', '.'))

        if (pattern) {
            let val = e.target.value.replace(pattern, '')
            if (val) {
                setVal(val)
            }
        }
    }

    const getFormattedNumber = (type, val, currency) => {
        if (val) {
            if (type === 'price') {
                let numberWithCurrency = parseFloat(val);

                if (currency) {
                    numberWithCurrency = numberWithCurrency.toLocaleString('ru-RU', {style: 'currency', currency: currency})
                } else {
                    numberWithCurrency = numberWithCurrency.toLocaleString('ru-RU', {style: 'currency', currency: 'RUB'})
                }

                if (currency && currency !== 'RUB') {
                    return numberWithCurrency.replace('KZT', '₸');
                }

                return numberWithCurrency;
            } else if (type === 'stock_quantity') {
                return round(val, 4);
            } else {
                return round(val, 2);
            }
        }

        return null;
    }

    return (
        <div className={classes.root} key={name} style={{justifyContent: props.justifyContentRoot ? props.justifyContentRoot : 'center'}}>
            {!show &&
                <Typography
                    className={classes.label}
                    onClick={(e) => {
                        e.stopPropagation()
                        setShow(true)
                    }}
                    style={{ pointerEvents: disabled ? 'none' : 'all' }}
                >
                    {getFormattedNumber(type, val, currency)}&nbsp;{unit ?? null}
                </Typography>
            }
            {show
                ? <Fragment>
                    <TextField
                        type="text"
                        onChange={change}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                e.stopPropagation()
                                setShow(false)
                                if (val !== defaultVal) {
                                    props.onUpdate(propId, name, val.replace(',', '.')).then(() => {
                                        setDefaultVal(val.replace(',', '.'))
                                    }, () => {
                                        setVal(defaultVal.replace(',', '.'))
                                    })
                                }
                            }

                        }}
                        name={name}
                        value={val}
                        inputProps={{
                            pattern: pattern ? pattern : null,
                        }}
                        className={classes.textField}
                    />
                    <IconButton
                        className={classes.button}
                        onClick={(e) => {
                            e.stopPropagation()
                            setShow(false)
                            if (val !== defaultVal) {
                                props.onUpdate(propId, name, val.replace(',', '.')).then(() => {
                                    if (typeof val === 'string') {
                                        setVal(val.replace(',', '.'))
                                    } else {
                                        setVal(val)
                                    }
                                }, () => {
                                    if (typeof defaultVal === 'string') {
                                        setVal(defaultVal.replace(',', '.'))
                                    } else {
                                        setVal(defaultVal)
                                    }

                                })
                            }
                        }}
                    >
                        <Check className={classes.iconCheck}/>
                    </IconButton>
                    <IconButton
                        className={classes.button}
                        onClick={(e) => {
                            e.stopPropagation()
                            setShow(false)
                            setVal(defaultVal)
                            props.onCancel(name, defaultVal)
                        }}
                    >
                        <Clear className={classes.iconCansel}/>
                    </IconButton>
                    {unit ?? null}
                </Fragment>
                : null
            }
        </div>
    );
}
