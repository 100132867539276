import {HttpService} from "../../App/services/http"
import * as AppStorage from "../../App/helpers/storage"

export const AccountService = {
    account,
    edit
}

function account(params) {
    return HttpService.get("/account", params)
        .then(response => {
            if (response.account && response.account.company) {
                AppStorage.set('price_token', response.account.company.tokens.price)
                delete (response.account.company.tokens.price)
            }
            if (response.account) {
                AppStorage.set('account', JSON.stringify(response.account))
            }

            return response
        })
}

function edit(values) {
    return HttpService.put("/account", values, true)
        .then(response => {
            return response.account
        })
}
