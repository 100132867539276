const type = {
    purchase_history: 'История закупок',
    delivery_history: 'История заказов',
    contract_history: 'История договоров',
    contracts: 'По договорам',
    delivery_orders: 'По заказам',
    delivery_orders_after_fact: 'Заказы постфактум',
    delivery_times: 'По срокам поставки',
    contract_unscheduled_purchases: 'По внеплановым закупкам',
    execution_contract: 'Исполнение договора',
    user_activity: 'Активность пользователей',
    supplier_activity: 'Активность поставщиков',
    share_suppliers: 'Доля поставщика в общих закупках в разрезе Сети, ЧУЗ, Дороги',
    share_categories: 'Доля категорий Поставщика в закупках в Сети, ЧУЗ, Дороги',
    share_standards: 'Доля номенклатурной единицы в закупках ЧУЗ, сети, ЖД',
    price_orders: 'Цена по заказам (ЭМ)',
    price_standards: 'Цена по номенклатуре (ЭМ)',
    order_unscheduled_purchases: 'Внеплановые закупки (ЭМ)',
    order_supplier_activity: 'Активность поставщиков (ЭМ)',
    customer_contracts: 'Статистика договоров заказчика (ЭМ)',
    delivery_orders_items: 'По номенклатуре',
    order_standards: 'По номенклатуре (ЦЗ)',
    planning_items: 'Планирование (ОЗ)',
}

export { type }
