import * as React from 'react'
import {getIn} from 'formik'

import {Grid, Input, makeStyles, Typography, FormHelperText, Tooltip, IconButton} from '@material-ui/core'

import {Submit} from "../Button/Submit"
import {Help as HelpIcon} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    file: {
        "max-width": "100%",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    name: {
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    icon: {
        "& .MuiButton-endIcon": {
            "margin-left": "0",
            "margin-right": "0",
            "padding-bottom": "0"
        },
        "&>span>span": {
            margin: 0
        }
    },
    label: {
        "color": "rgba(0, 0, 0, 0.54)"
    },
}))

export const FileUpload = ({
    field,
    form: { isSubmitting, touched, errors, setFieldValue, values },
    label,
    disabled = false,
    InputProps: inputProps,
    InputLabelProps: inputLabelProps,
    tooltip = false,
    ...props
}) => {
    const classes = useStyles()
    const error = getIn(touched, field.name) && getIn(errors, field.name);
    return (
        <Grid container direction="column" justify="flex-start" alignItems="stretch" style={{"width": "100%"}}>
            <Grid item style={{"width": "100%"}}>
                <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                    {label &&
                        <Grid item>
                            <Typography className={classes.label}>
                                {label}
                            </Typography>
                        </Grid>
                    }
                    <Grid item>
                        <Submit
                            disabled={disabled || isSubmitting}
                            {...props}
                            variant="contained"
                            component="label"
                            color="primary"
                            className={props.hasOwnProperty('endIcon') || props.hasOwnProperty('startIcon') ? classes.icon : null}
                        >
                            <Input
                                error={!!error}
                                style={{ display: "none" }}
                                inputProps={{
                                    ...inputProps,
                                    ...{
                                        type: 'file',
                                        disabled: disabled || isSubmitting,
                                        name: field.name,
                                        onChange: inputProps.onChange ?? function (event) {
                                            if (inputProps.multiple) {
                                                const files = event.currentTarget.files;
                                                setFieldValue(field.name, files);
                                            } else {
                                                const file = event.currentTarget.files[0];
                                                setFieldValue(field.name, file);
                                            }
                                        },
                                    }
                                }}
                            />
                        </Submit>
                    </Grid>
                    {tooltip && !values?.[field?.name] ? <Grid item xs={1}>
                        <Tooltip
                            title={`Обязательно прикрепите ${label.toLowerCase()}`}
                            placement="right"
                        >
                            <IconButton
                                style={{
                                    "width": "24px",
                                    "height": "24px",
                                    "padding": "0"
                                }}
                            >
                                <HelpIcon style={{"color": "#485868"}}/>
                            </IconButton>
                        </Tooltip>
                    </Grid> : null}
                    {inputLabelProps.name &&
                    <Grid item className={classes.file}>
                        <Typography className={classes.name}>
                            {inputLabelProps.name}
                        </Typography>
                    </Grid>
                    }
                </Grid>
            </Grid>
            {error &&
                <Grid item>
                    {error && <FormHelperText error>{error}</FormHelperText>}
                </Grid>
            }
        </Grid>
    );
};
