import {HttpService} from "../../App/services/http"

export const PurchaseService = {
    lot,
    orders
}

function lot(id) {
    return HttpService.get(`/purchase/order/lot/${id}`)
        .then(response => {
            return response.lot
        })
}

function orders(params) {
    return HttpService.get("/purchase/orders", params)
}
