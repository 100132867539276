import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import {Select, TextField} from "formik-material-ui"
import * as Yup from "yup"
import DateFnsUtils from "@date-io/date-fns"
import format from "date-fns/format"
import {ru} from "date-fns/locale"

import {
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@material-ui/core"
import {Clear} from "@material-ui/icons"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"
import {green} from "@material-ui/core/colors"

import {Submit} from "../../../../App/components/Button/Submit"
import {TextFieldWithError as CustomTextField} from "../../../../App/components/Inputs/TextFieldWithError"
import {SystemActions} from "../../../../App/actions/system"
import {PurchaseActions} from "../../../actions/purchase"
import {history} from "../../../../App/helpers/history"
import {AuthorizationService} from "../../../../Auth/services/authorization"
import {ItemsActions} from "../../../actions/items"
import {ProductsDialog} from "../ProductsDialog"
import {PlanningActions} from "../../../actions/planning"
import {getFullName} from "../../../../App/helpers/user"
import {localState} from "../../../../App/components/LocalState";
import {Category} from "../../../../App/components/Inputs/Category";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.planningContent.width,
        padding: theme.planningContent.padding,
        margin: theme.planningContent.margin,
        position: "relative"
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important"
    },
    input: {
        "width": "100%"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    contentBody: {
        position: "relative"
    },
    paramsList: {
        overflow: "auto",
        padding: "0 25px",
        marginBottom: "70px"
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 250px)"
    },
    tabContent: {
        "padding": "20px 29px !important"
    },
    column: {
        "height": "86px"
    },
    activeCategory: {
        'background-color': green[100],
        '&:hover': {
            'background-color': 'rgba(0, 0, 0, 0.04) !important',
        }
    },
    defaultCategory: {
        '&:hover': {
            'background-color': 'rgba(0, 0, 0, 0.04) !important',
        }
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    },
    errorFieldDatePicker: {
        "& input": {
            backgroundColor: 'rgb(242 115 96 / 50%)'
        }
    },
    errorField: {
        "&>input": {
            backgroundColor: 'rgb(242 115 96 / 50%)'
        }
    },
}));

export const Content = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {purchaseID} = useParams()
    const {
        purchase,
        account,
        categories,
        conductionMethods
    } = useSelector(state => ({...state.purchasePlans, ...state.purchases, ...state.account}))
    const {currencies} = useSelector(state => state.system)
    const [products, setProducts] = useState(localState)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [loading, setLoading] = useState(false)
    const [dialog, setDialog] = useState(false)

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    useEffect(() => {
        dispatch(ItemsActions.items(purchaseID, {
            limit: rowsPerPage,
            page: page + 1
        }))
        // eslint-disable-next-line
    }, [page, rowsPerPage]);

    useEffect(() => {
        const getData = async () => {
            await dispatch(PlanningActions.units())
            await dispatch(SystemActions.currencies())
            await dispatch(PurchaseActions.conductionMethods())
            await dispatch(PurchaseActions.purchase(purchaseID)).then(async (response) => {
                await dispatch(SystemActions.categories())
                await dispatch(PurchaseActions.categoriesForCreate(response.purchase.purchase_plan_id ? {id: response.purchase.purchase_plan_id} : {}))
                if (AuthorizationService.roles(account, 'supervisor')) {
                   const response = await dispatch(ItemsActions.items(purchaseID))
                    setProducts(response)
                }
            })
        }

        getData().then(r => {
            setLoading(true)
        })
        // eslint-disable-next-line
    }, [dispatch]);

    return loading ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.full}>
                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                            <Grid item className={classes.fullWidth}>
                                <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                    <Grid item className={classes.header}>
                                        <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                            <Grid item className={classes.fullWidth}>
                                                <Typography className={classes.title}>
                                                    Редактирование закупки
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Formik
                                    validateOnBlur={false}
                                    initialValues={{
                                        purchase_plan_id: purchase.purchase_plan_id,
                                        category: purchase.category_id ? categories.find(cat => (cat.id === purchase.category_id)) : null,
                                        conduction_method: purchase.conduction_method ? purchase.conduction_method.id : '',
                                        name: purchase.name ?? '',
                                        currency: purchase.currency_id ? purchase.currency_id : '',
                                        department: purchase.department ?? '',
                                        user: getFullName(account),
                                        territories: purchase.territories.map(territory => territory.id),
                                        planing_date_to_publish_notification: purchase.planing_date_to_publish_notification ? new Date(purchase.planing_date_to_publish_notification) : null,
                                        ...(((!purchase.lots_count && !purchase.validated) || ((purchase.type.name === 'Совместная') && AuthorizationService.roles(account, 'customer'))) ? {
                                            lot: {
                                                start_execution_month: null,
                                                execution_month: null,
                                                purchase_type: '',
                                                category: '',
                                                postponement: '',
                                                prepayment: '',
                                            }
                                        } : {})
                                    }}
                                    validationSchema={Yup.object().shape({
                                        purchase_plan_id: Yup.string().nullable(),
                                        category: Yup.mixed(),
                                        name: Yup.string(),
                                        conduction_method: Yup.string(),
                                        department: Yup.string(),
                                        currency: Yup.string(),
                                        territories: Yup.array(),
                                        planing_date_to_publish_notification: Yup.mixed(),
                                        ...(((!purchase.lots_count && !purchase.validated) || ((purchase.type.name === 'Совместная') && AuthorizationService.roles(account, 'customer'))) ? {
                                            lot: Yup.object().shape({
                                                start_execution_month: Yup.mixed(),
                                                execution_month: Yup.mixed(),
                                                purchase_type: Yup.string(),
                                                category: Yup.mixed(),
                                                prepayment: Yup.number().nullable().min(1, 'Пожалуйста, введите значение от 1 до 100').max(100, 'Пожалуйста, введите значение от 1 до 100'),
                                                postponement: Yup.string().when('purchase_type', {
                                                    is: (purchase_type) => (purchase_type === 'postponement') || (purchase_type === 'partial'),
                                                    then: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!'),
                                                    otherwise: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                                }),
                                            })
                                        } : {})
                                    })}
                                    validate={values => {
                                        const errors = {};

                                        if (values.planing_date_to_publish_notification) {
                                            if (purchase.start_execution_month) {
                                                if ((values.planing_date_to_publish_notification.getFullYear() > parseInt(purchase.start_execution_month.substring(0, 4))) || ((values.planing_date_to_publish_notification.getFullYear() === parseInt(purchase.start_execution_month.substr(0, 4))) && (values.planing_date_to_publish_notification.getMonth() > parseInt(purchase.start_execution_month.substr(5, 2))))) {
                                                    errors.planing_date_to_publish_notification = 'План. срок размещения извещения должен в месяц или до начала месяца исполнения договора'
                                                }
                                            }
                                        }

                                        return errors
                                    }}
                                    onSubmit={(values, {setSubmitting}) => {
                                        const formData = new FormData()
                                        for (const key in values) {
                                            if (values.hasOwnProperty(key)) {
                                                switch (key) {
                                                    case 'category':
                                                        if (values[key]) {
                                                            formData.append(key, values[key].id)
                                                        }
                                                        break;
                                                    case 'planing_date_to_publish_notification':
                                                        if (values[key]) {
                                                            formData.append(key, format(new Date(values[key]), 'yyyy-MM-dd'))
                                                        }
                                                        break;
                                                    case 'lot':
                                                        for (const lotKey in values.lot) {
                                                            if (values.lot.hasOwnProperty(lotKey) && values[key][lotKey]) {
                                                                switch (lotKey) {
                                                                    case 'category':
                                                                        formData.append(`${key}[${lotKey}]`, values[key][lotKey].id)
                                                                        break;
                                                                    case 'execution_month':
                                                                        formData.append(`${key}[${lotKey}]`, format(new Date(Date.UTC(values[key][lotKey].getFullYear(), values[key][lotKey].getMonth(), 1)), 'yyyy-MM-dd'))
                                                                        break;
                                                                    case 'start_execution_month':
                                                                        formData.append(`${key}[${lotKey}]`, format(new Date(Date.UTC(values[key][lotKey].getFullYear(), values[key][lotKey].getMonth(), 1)), 'yyyy-MM-dd'))
                                                                        break;
                                                                    case 'purchase_type':
                                                                        formData.append(`${key}[${lotKey}]`, values[key][lotKey])
                                                                        switch (values[key][lotKey]) {
                                                                            case 'partial':
                                                                                formData.append(`${key}[prepayment]`, values[key].prepayment)
                                                                                formData.append(`${key}[postponement]`, values[key].postponement)
                                                                                break
                                                                            case 'postponement':
                                                                                formData.append(`${key}[postponement]`, values[key].postponement)
                                                                                break
                                                                            default:
                                                                        }
                                                                        break
                                                                    case 'prepayment':
                                                                        break
                                                                    case 'postponement':
                                                                        break
                                                                    default:
                                                                        formData.append(`${key}[${lotKey}]`, values[key][lotKey])
                                                                }
                                                            }
                                                        }
                                                        break;
                                                    case 'territories':
                                                        values[key].forEach((id, index) => {
                                                            formData.append(`${key}[${index}]`, id);
                                                        })
                                                        break;
                                                    default:
                                                        if (values[key]) {
                                                            formData.append(key, values[key])
                                                        }
                                                }
                                            }
                                        }

                                        return dispatch(PurchaseActions.edit(purchaseID, formData)).then(
                                            () => {
                                                setSubmitting(false)
                                                if (purchase.purchase_plan_id) {
                                                    history.push(`/planning/purchase-plan/${purchase.purchase_plan_id}`)
                                                } else {
                                                    history.push(`/planning/purchases`)
                                                }
                                            },
                                            errors => {
                                                if (errors) {
                                                }
                                                setSubmitting(false)
                                            }
                                        )
                                    }}
                                >
                                    {({
                                          dirty,
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          handleSubmit,
                                          isSubmitting,
                                          isValid,
                                          isValidating,
                                          setValues,
                                          setTouched,
                                          setFieldTouched,
                                          setFieldValue,
                                          submitForm,
                                          validateField
                                      }) => (
                                        <Form>
                                            <Grid item>
                                                <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                                                    <Grid item className={classes.tabContent}>
                                                        <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                            <Grid item xs={3} className={classes.column}>
                                                                <Field
                                                                    fullWidth
                                                                    disabled={isSubmitting || AuthorizationService.roles(account, 'supervisor')}
                                                                    name="name"
                                                                    type="text"
                                                                    label="Название"
                                                                    required
                                                                    component={CustomTextField}
                                                                    className={touched.name && errors.name ? classes.errorField : null}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3} className={classes.column}>
                                                                <Field
                                                                    fullWidth
                                                                    disabled={isSubmitting || AuthorizationService.roles(account, 'supervisor')}
                                                                    type="text"
                                                                    name="conduction_method"
                                                                    label="Способ проведения закупки"
                                                                    select
                                                                    variant="standard"
                                                                    component={TextField}
                                                                    required
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                >
                                                                    {
                                                                        conductionMethods.map(purchaseType => (
                                                                            <MenuItem key={purchaseType.id}
                                                                                      value={purchaseType.id}>
                                                                                {purchaseType.name}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </Field>
                                                            </Grid>
                                                            <Grid item xs={3} className={classes.column}>
                                                                <Category
                                                                    isSubmitting={isSubmitting}
                                                                    label="Категория"
                                                                    value={values.category?.id}
                                                                    disabled={(!!purchase.lots_count || purchase.validated)}
                                                                    options={categories.map(category => category.id)}
                                                                    onChange={(value) => {
                                                                        const category = categories.find(cat => (cat.id === value?.id))

                                                                        setFieldValue('category', category)
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3} className={classes.column}>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                    <KeyboardDatePicker
                                                                        disableToolbar
                                                                        fullWidth
                                                                        error={Boolean(errors.hasOwnProperty('planing_date_to_publish_notification') && touched.hasOwnProperty('planing_date_to_publish_notification'))}
                                                                        helperText={(errors.hasOwnProperty('planing_date_to_publish_notification') && touched.hasOwnProperty('planing_date_to_publish_notification')) && errors.planing_date_to_publish_notification ? errors.planing_date_to_publish_notification : null}
                                                                        disabled={isSubmitting || AuthorizationService.roles(account, 'supervisor')}
                                                                        name={`planing_date_to_publish_notification`}
                                                                        required
                                                                        format="dd-MM-yyyy"
                                                                        orientation="landscape"
                                                                        variant="inline"
                                                                        openTo="date"
                                                                        label="План. срок размещения извещения"
                                                                        autoOk
                                                                        value={values.planing_date_to_publish_notification}
                                                                        onChange={date => {
                                                                            setFieldValue(`planing_date_to_publish_notification`, date);
                                                                        }}
                                                                        onBlur={e => {
                                                                            setFieldTouched(`planing_date_to_publish_notification`, true, true)
                                                                        }}
                                                                        onClose={(e, value) => {
                                                                            setFieldTouched(`planing_date_to_publish_notification`, true, true)
                                                                        }}
                                                                        className={touched.planing_date_to_publish_notification && errors.planing_date_to_publish_notification ? classes.errorFieldDatePicker : null}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                            <Grid item xs={3}>
                                                                <FormControl className={classes.fullWidth}>
                                                                    <InputLabel required shrink={true}>
                                                                        Адрес закупки
                                                                    </InputLabel>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={isSubmitting || AuthorizationService.roles(account, 'supervisor')}
                                                                        type="text"
                                                                        name="territories"
                                                                        label="Адрес закупки"
                                                                        variant="standard"
                                                                        component={Select}
                                                                        multiple
                                                                        required
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                    >
                                                                        {purchase.purchasePlan ? purchase.purchasePlan.company.territories.map(currency => (
                                                                            <MenuItem key={currency.id} value={currency.id}>
                                                                                {currency.name}
                                                                            </MenuItem>
                                                                        )) : purchase.company.territories.map(currency => (
                                                                            <MenuItem key={currency.id} value={currency.id}>
                                                                                {currency.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Field>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={3} className={classes.column}>
                                                                <Field
                                                                    fullWidth
                                                                    disabled={isSubmitting || AuthorizationService.roles(account, 'supervisor')}
                                                                    type="text"
                                                                    name="currency"
                                                                    label="Валюта"
                                                                    select
                                                                    variant="standard"
                                                                    component={TextField}
                                                                    required
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                >
                                                                    {currencies.map(currency => (
                                                                        <MenuItem key={currency.id} value={currency.id}>
                                                                            {currency.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Field>
                                                            </Grid>
                                                            <Grid item xs={3} className={classes.column}>
                                                                <Field
                                                                    fullWidth
                                                                    disabled={isSubmitting || AuthorizationService.roles(account, 'supervisor')}
                                                                    name="department"
                                                                    type="text"
                                                                    required
                                                                    label="Отделение"
                                                                    component={CustomTextField}
                                                                    className={touched.department && errors.department ? classes.errorField : null}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}/>
                                                            {((!purchase.validated && !purchase.lots_count) || ((purchase.type.name === 'Совместная') && AuthorizationService.roles(account, 'customer'))) &&
                                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                    {((values.lot.purchase_type === 'postponement') || (values.lot.purchase_type === 'partial')) &&
                                                                        <Grid item xs={3} className={classes.column}>
                                                                            <Field
                                                                                fullWidth
                                                                                name={`lot.postponement`}
                                                                                type="number"
                                                                                label={`${(values.lot.purchase_type === 'postponement') ? 'Отсрочка платежа, дней' : 'Отсрочка постоплаты, дней'}`}
                                                                                required
                                                                                step={1}
                                                                                inputProps={{
                                                                                    min: 0
                                                                                }}
                                                                                component={CustomTextField}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {(values.lot.purchase_type === 'partial') &&
                                                                        <Grid item xs={2} className={classes.column}>
                                                                            <Field
                                                                                fullWidth
                                                                                name={`lot.prepayment`}
                                                                                type="number"
                                                                                label="Аванс"
                                                                                required
                                                                                step={1}
                                                                                inputProps={{
                                                                                    min: 1,
                                                                                    max: 100
                                                                                }}
                                                                                endAdornment={'%'}
                                                                                component={CustomTextField}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                        {AuthorizationService.roles(account, 'supervisor') &&
                                                            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                <Grid item>
                                                                    <TableContainer className={classes.tableContainer}>
                                                                        <Table stickyHeader aria-label="sticky table">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    {[
                                                                                        {id: 0, label: '№'}, {id: 1, label: 'Номер ОЗ'}, {id: 2, label: 'ЖД'}, {id: 3, label: 'ЧУЗ'}, {id: 4, label: 'Наименование'},
                                                                                        {id: 5, label: 'Количество'}, {id: 6, label: 'Цена'}, {id: 7, label: 'Сумма'}, {id: 8, label: ''}
                                                                                    ].map((column) => (
                                                                                        <TableCell key={column.id}>
                                                                                            {column.label}
                                                                                        </TableCell>
                                                                                    ))}
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {products?.data.map((item, index) => (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell component="th" scope="row">
                                                                                            {index + 1}
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {item.number}
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {item.railRoad}
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {item.company}
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {item.name}
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {parseFloat((!item.amount || item.joint) ? 0 : item.amount).toLocaleString('ru-RU')}
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {parseFloat(item.price).toLocaleString('ru-RU', {
                                                                                                style: 'currency',
                                                                                                currency: 'RUB',
                                                                                            })}
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {parseFloat(item.price * item.amount).toLocaleString('ru-RU', {
                                                                                                style: 'currency',
                                                                                                currency: 'RUB',
                                                                                            })}
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            <IconButton
                                                                                                size={"small"}
                                                                                                onClick={(e) => {
                                                                                                    dispatch(ItemsActions.exclude(purchaseID, item.id)).then(_ => {
                                                                                                        dispatch(ItemsActions.items(purchaseID, {
                                                                                                            limit: rowsPerPage,
                                                                                                            page: page + 1
                                                                                                        }))
                                                                                                    })
                                                                                                }}
                                                                                            >
                                                                                                <Clear/>
                                                                                            </IconButton>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.footer}>
                                                <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                                    <Grid item className={classes.footerContent}>
                                                        <Grid container className={classes.footerContainer} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                            {AuthorizationService.roles(account, 'supervisor') ?
                                                                <TablePagination
                                                                    rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                                                    component='div'
                                                                    labelRowsPerPage={'Записей на странице:'}
                                                                    count={products?.meta.total}
                                                                    rowsPerPage={rowsPerPage}
                                                                    page={page}
                                                                    onChangePage={handleChangePage}
                                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                                /> : <Grid item/>
                                                            }
                                                            <Grid item>
                                                                <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                                    {AuthorizationService.roles(account, 'supervisor') ?
                                                                        <Grid item>
                                                                            <Submit
                                                                                variant="contained"
                                                                                color="primary"
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    setDialog(true)
                                                                                }}
                                                                            >
                                                                                Добавить ОЗ
                                                                            </Submit>
                                                                        </Grid> :
                                                                        <Grid item>
                                                                            <Submit
                                                                                disableElevation
                                                                                variant="contained"
                                                                                color="primary"
                                                                                type="submit"
                                                                                onClick={() => {
                                                                                    submitForm().then(r => {})
                                                                                }}
                                                                                disabled={isSubmitting}
                                                                            >
                                                                                Сохранить
                                                                            </Submit>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            {dialog ? <ProductsDialog purchase={purchase} open={dialog} setDialog={setDialog}/> : null}
        </Grid>) : null
}
