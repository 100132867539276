function day(day, delimiter = ' ') {
    const endings = ['день', 'дня', 'дней']
    let ending, i

    if (((day % 100) >= 11) && ((day % 100) <= 19)) {
        ending = endings[2]
    }
    else {
        i = (day % 100) % 10
        switch (i) {
            case (1):
                ending = endings[0]
                break
            case (2):
            case (3):
            case (4):
                ending = endings[1]
                break
            default:
                ending = endings[2]
        }
    }
    return `${day}${delimiter}${ending}`
}

const getMonthName = (date) => {
    const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
    return months[date.getMonth()]
}

export { day, getMonthName }
