import React from "react"
import format from "date-fns/format"

import {
    Collapse,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core"

import {round, toFloatWithCurrency} from "../../../../App/helpers/number"
import {AddToCart} from "./Positions/AddToCart"

const useStyles = makeStyles(theme => ({
    input: {
        "width": "100%",
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "text-decoration": "none"
    },
    tableRowLast: {
        '& > *': {
            "border-bottom": "0"
        }
    },
    tableRowAlert: {
        "cursor": "pointer",
        "background-color": "#f9f1f0",
        "text-decoration": "none"
    }
}))

const positionHeaders = [
    {id: '0', label: 'Остатки'},
    {id: '1', label: 'Заказ, от'},
    {id: '2', label: 'Срок годности'},
    {id: '3', label: 'Цена'}
]

const positionHeadersWithSupplier = [
    {id: '0', label: 'Остатки'},
    {id: '1', label: 'Заказ, от'},
    {id: '2', label: 'Срок годности'},
    {id: '3', label: 'Цена'},
    {id: '4', label: 'Поставщик'},
    {id: '5', label: ''}
]

export const Positions = (props) => {
    const { positions, expanded, account, item, params, cart } = props
    const classes = useStyles()

    const headers = account.company.settingsMarketplace.show_suppliers ? positionHeadersWithSupplier : positionHeaders

    const getExpirationDate = (position, params) => {
        const get = (position, type) => {
            switch (type) {
                case 'expirationDate':
                    return position.expiration_date ? format(new Date(position.expiration_date), 'yyyy-MM-dd') : ''
                case 'expirationDatePercent':
                    return position.expiration_date_percent ? `${position.expiration_date_percent} %` : ''
                default:
                    return ''
            }
        }

        let notHaveSearchParams = !params.category || !params.postponement || !params.find || !(params.expirationDate || params.expirationDatePercent)

        if (notHaveSearchParams) {
            return get(position, position.expiration_date !== null ? 'expirationDate' : 'expirationDatePercent')
        } else {
            return get(position, params.expirationDateType)
        }
    }

    return (
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
            <Collapse in={((expanded === item.id) && !!positions)} timeout="auto" unmountOnExit>
                <TableContainer className={classes.tableContainer}>
                    <Typography variant="h6" gutterBottom component="div">
                        Предложения
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headers.map((column) => (
                                    <TableCell
                                        key={column.id}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!positions && positions.map((position, index) => {
                                return (
                                    <TableRow key={index} className={(positions.length === (index + 1)) ? classes.tableRowLast : classes.tableRow}>
                                        <TableCell>
                                            {position.stock_quantity || position.stock_quantity === 0 ? round(position.stock_quantity, 2) : ''}
                                        </TableCell>
                                        <TableCell>
                                            {position.min_ordered_quantity ? parseFloat(position.min_ordered_quantity).toFixed(2) : null}
                                        </TableCell>
                                        <TableCell>
                                            {getExpirationDate(position, params)}
                                        </TableCell>
                                        <TableCell>
                                            {toFloatWithCurrency(position.price)}
                                        </TableCell>
                                        {position.hasOwnProperty('supplier') &&
                                        <TableCell>
                                            {position.supplier.company_name}
                                        </TableCell>
                                        }
                                        {position.hasOwnProperty('supplier') &&
                                            <TableCell>
                                                <AddToCart item={item} position={position} params={params} cart={cart}/>
                                            </TableCell>
                                        }
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Collapse>
        </TableCell>
    )
}
