import React, {useEffect, useRef, useState} from "react"
import {Link} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import parse from 'html-react-parser'
import format from "date-fns/format"

import {
    Checkbox,
    Grid,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@material-ui/core"
import {Link as LinkIcon} from "@material-ui/icons"

import {NotificationActions} from "../../actions/notification"
import {Submit} from "../../../App/components/Button/Submit"
import {ButtonList} from "../../../App/components/ButtonList/ButtonList"
import {getShortFullName} from "../../../App/helpers/user"

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 158px)"
    },
    input: {
        "width": "100%",
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100% - 70px)",
        "min-height": "calc(100% - 70px)"
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    tableRowAlert: {
        "cursor": "pointer",
        "background-color": "#f9f1f0",
        "text-decoration": "none"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    },
    paginationWrap: {
        backgroundColor: "#e7eaee",
        position: "fixed",
        bottom: "0",
        maxWidth: "300px"
    },
    pagination: {
        "&>div": {
            flexWrap: 'wrap'
        }
    },
    download: {
        "color": "#485868"
    },
}))

const columns = [
    {
        id: '0',
        label: 'Дата',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '1',
        label: 'Инициатор',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '2',
        label: 'Автор',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '3',
        label: 'Тип',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '4',
        label: 'Событие',
        format: (value) => value.toLocaleString(),
    }
];

const readList = [
    {
        key: 'new',
        value: 'Новые'
    },
    {
        key: 'all',
        value: 'Все'
    }
]

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {notifications, filters} = useSelector(state => state.notification)

    const {filter} = useSelector(state => state.filters['notifications'])

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [loading, setLoading] = useState(false)
    const [tabRead, setTabRead] = useState(0)
    const [read, setRead] = useState('new')
    const tableRef = useRef(null)

    const [selected, setSelected] = useState(filter.selectedItems)
    const isSelected = (name) => (selected.indexOf(name) !== -1)

    useEffect(() => {
        const getNotifications = async () => {
            return await dispatch(NotificationActions.notifications({
                limit: rowsPerPage,
                page: page,
                read: read,
                ...(filters.beginning ? {beginning: format(filters.beginning, 'yyyy-MM-dd')} : {}),
                ...(filters.ending ? {ending: format(filters.ending, 'yyyy-MM-dd')} : {}),
                ...(filters.type.length ? {type: filters.type.join(',')} : {})
            }))
        }

        if (!loading) {
            getNotifications().then(() => {
                tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
                setLoading(true)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loading, page, read, rowsPerPage, filter]);

    useEffect(() => {
        if (loading) {
            setLoading(!loading)
        }
        // eslint-disable-next-line
    }, [filters]);

    const handleSelectAll = () => {
        if (!selected.length) {
            setSelected(notifications.data.map((notification) => notification.id))
        }
        else if (selected.length && selected.length < notifications.meta.total) {
            let dataIndexes = notifications.data.map((notification) => notification.id)

            if (selected.toString() === dataIndexes.toString() || selected.filter(word => dataIndexes.includes(word)).length) {
                setSelected(selected.filter(word => dataIndexes.indexOf(word) === -1))
            } else {
                setSelected([...selected, ...dataIndexes])
            }
        }
        else {
            setSelected([])
        }
    };

    const handleSelect = (event, name) => {
        const selectedIndex = selected.indexOf(name)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
        }

        setSelected(newSelected)
    };

    const handleChangePage = (event, newPage) => {
        setPage(Number(newPage) + 1)
        setLoading(false)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        dispatch({type: 'NOTIFICATIONS_FILTER', payload: {limitRows: +event.target.value}})
        setPage(1)
        setLoading(false)
    }

    const getType = (author = null) => {
        if (author) {
            if (author.roles.find((role) => role.name === 'customer')) {
                return 'Заказчик'
            }

            if (author.roles.find((role) => role.name === 'supplier')) {
                return 'Поставщик'
            }
        }

        return 'Система'
    }

    const href = (type, entities) => {
        let link

        switch (type) {
            case 'planning_change_status_purchase':
            case 'planning_create_joint_purchase':
            case 'planning_new_comment':
            case 'lead_time_message':
            case 'editing_after_rejection':
            case 'correction_period':
                link = `/planning/purchase-plan/${entities.purchase_plan_id}` + (entities.purchase_id ? `/purchase/${entities.purchase_id}` : '')
                break
            case 'delivery_overdue_date':
            case 'delivery_overdue_payment_deadline':
            case 'delivery_pending_order':
                link = `/delivery/order/${entities.order_id}`
                break
            case 'planning_rejection':
            case 'planning_approval':
            case 'planning_request_for_edit':
            case 'change_standard':
            case 'rejection_standard':
                switch (true) {
                    case entities.hasOwnProperty('purchase_id'):
                        link = `/planning/purchase-plan/${entities.purchase_plan_id}/purchase/${entities.purchase_id}`
                        break;
                    case entities.hasOwnProperty('purchase_plan_id'):
                        link = `/planning/purchase-plan/${entities.purchase_plan_id}`
                        break;
                    default:
                        link = null
                }
                break
            case 'start_rebidding':
            case 'subscription':
                    link = `/lots/${entities.lot_id}`
                break
            default:
                link = null
        }

        return link ? <IconButton
            size={"small"}
            component={Link}
            to={link}
        >
            <LinkIcon/>
        </IconButton> : null
    }

    return <Grid item className={classes.content}>
        <Grid container direction="column" justify="center" alignItems="stretch">
            <Grid item>
                <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                    <Grid item xs={4}>
                        <ButtonList
                            value={tabRead}
                            values={readList}
                            onChange={(_, value) => {
                                setTabRead(value);
                                setRead(readList[value].key)
                                setPage(1)
                                setLoading(false)
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Paper>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item className={classes.fullWidth}>
                    <Grid container className={classes.container} direction="column" justify="space-between" alignItems="stretch">
                        <Grid item className={classes.inputContent}>
                            <TableContainer className={classes.tableContainer}>
                                <Table ref={tableRef} stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    indeterminate={selected.length > 0 && selected.length < notifications.meta.total}
                                                    checked={notifications.meta.total > 0 && selected.length === notifications.meta.total}
                                                    onChange={handleSelectAll}
                                                />
                                            </TableCell>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                            <TableCell padding="checkbox">
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {notifications.data && notifications.data.map((notification) => {
                                            const isItemSelected = isSelected(notification.id);

                                            return (
                                                <TableRow
                                                    onClick={() => {
                                                        if (!(notification.read)) {
                                                            dispatch(NotificationActions.read(notification.id))
                                                        }
                                                    }}
                                                    className={classes.tableRow}
                                                    hover key={notification.id}
                                                    selected={!(notification.read)}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            onClick={(event) => handleSelect(event, notification.id)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {format(new Date(notification.created), 'dd.MM.yyyy HH:mm')}
                                                    </TableCell>
                                                    <TableCell>
                                                        {getType(notification.author)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {notification.author ? getShortFullName(notification.author) : ''}
                                                    </TableCell>
                                                    <TableCell>
                                                        {notification.type.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {notification.message ? notification.message.split("\n").map((message, index) => (
                                                            message ? <Typography key={index} variant="body2"
                                                                                  gutterBottom>{parse(message)}</Typography> : null
                                                        )) : null}
                                                    </TableCell>
                                                    <TableCell>
                                                        {href(notification.type.key, notification.entities)}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item className={classes.footer}>
                            <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                <Grid item className={classes.footerContent}>
                                    <Grid container className={classes.footerContainer} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                        <Grid item>
                                            <TablePagination
                                                rowsPerPageOptions={[50, 100, 200]}
                                                component='div'
                                                labelRowsPerPage={'Записей на странице:'}
                                                count={(notifications && notifications) ? notifications.meta.total : 0}
                                                rowsPerPage={rowsPerPage}
                                                page={page - 1}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="row" justify="flex-start"
                                                  alignItems="center" spacing={2}>
                                                <Grid item>
                                                    <Submit
                                                        disableElevation
                                                        variant="contained"
                                                        color="primary"
                                                        type="button"
                                                        disabled={isSubmitting || !selected.length}
                                                        onClick={() => {
                                                            setIsSubmitting(true)
                                                            return dispatch(NotificationActions.remove({
                                                                id: selected.join(',')
                                                            })).then(
                                                                () => {
                                                                    setLoading(false)
                                                                    setIsSubmitting(false)
                                                                },
                                                                () => {
                                                                    setIsSubmitting(false)
                                                                }
                                                            )
                                                        }}
                                                    >
                                                        Удалить
                                                    </Submit>
                                                </Grid>
                                                <Grid item>
                                                    <Submit
                                                        disableElevation
                                                        variant="contained"
                                                        color="primary"
                                                        type="button"
                                                        disabled={isSubmitting}
                                                        onClick={() => {
                                                            setIsSubmitting(true)
                                                            return dispatch(NotificationActions.remove()).then(
                                                                () => {
                                                                    setLoading(false)
                                                                    setIsSubmitting(false)
                                                                },
                                                                () => {
                                                                    setIsSubmitting(false)
                                                                }
                                                            )
                                                        }}
                                                    >
                                                        Удалить все
                                                    </Submit>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    </Grid>
}
