import {HttpService} from "../../App/services/http"

export const PurchasePlanService = {
    plans,
    copyPurchases,
    add,
    edit,
    plan,
    copyPlan,
    categories,
    approve,
    decline,
    deletePurchasePlans,
    sendForCorrection,
    requestForEdit,
    purchaseFilter
}

function approve(ids) {
    return HttpService.put(`/planning/purchase-plans/approve`, ids)
}

function sendForCorrection(ids) {
    return HttpService.put(`/planning/purchase-plans/send-for-correction`, ids)
}

function requestForEdit(values) {
    return HttpService.post(`/planning/purchase-plans/request-for-edit`, values, true, true)
}

function decline(ids) {
    return HttpService.put(`/planning/purchase-plans/decline`, ids)
}

function plans(params) {
    return HttpService.get("/planning/purchase-plans", params)
}

function copyPurchases(purchasePlanID, values) {
    return HttpService.post(`/planning/purchase-plan/${purchasePlanID}/purchases/copy`, values)
}

function add(values) {
    return HttpService.post(`/planning/purchase-plan`, values)
}

function edit(purchasePlanID, values) {
    return HttpService.put(`/planning/purchase-plan/${purchasePlanID}`, values)
}

function plan(id) {
    return HttpService.get(`/planning/purchase-plan/${id}`)
}

function copyPlan(purchasePlanID, values) {
    return HttpService.post(`/planning/purchase-plan/${purchasePlanID}/copy`, values)
}

function categories(params = {}) {
    return HttpService.get(`/planning/purchase-plan/categories`, params)
}


function deletePurchasePlans(ids) {
    return HttpService.deleteMethod(`/planning/purchase-plans/delete`, ids)
}

function purchaseFilter(params) {
    return HttpService.get("/planning/purchase-plan/filter", params)
}
