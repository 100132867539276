import React from "react"
import {Field} from "formik"
import {TextField} from "formik-material-ui"

import {MenuItem} from "@material-ui/core"

export const FieldSelect = (props) => {
    const { id, label, items, isSubmitting, disabled } = props

    return (
        <Field
            fullWidth
            type="text"
            name={`attributes.${id}`}
            label={ label }
            disabled={ isSubmitting || disabled }
            select
            variant="standard"
            component={ TextField }
            InputLabelProps={{
                shrink: true,
            }}
        >
            {items.map(option => (
                <MenuItem key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>
            ))}
        </Field>
    )
}
