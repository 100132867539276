import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import format from "date-fns/format"
import DateFnsUtils from "@date-io/date-fns"
import {ru} from "date-fns/locale"
import clsx from "clsx"

import {
    Grid,
    InputAdornment,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    TableContainer,
    TextField,
    Typography
} from "@material-ui/core"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"
import {Clear as ClearIcon, Edit as EditIcon, GetApp as GetAppIcon, Search as SearchIcon} from "@material-ui/icons"

import {ButtonList} from "../../../App/components/ButtonList/ButtonList"
import {OrderActions} from "../../actions/order"
import {SystemActions} from "../../../App/actions/system"
import {Submit} from "../../../App/components/Button/Submit"
import {Companies as Dialog} from "../Order/Companies"
import {Products as DialogProducts} from "../Order/Products"
import {DeliveryActions} from "../../actions/delivery"
import {ContractActions} from "../../actions/contract"
import {Lots} from "./Lots"
import {Deliveries} from "./Deliveries"
import {Contracts} from "./Contracts"
import {StatusActions as DeliveryStatusActions} from "../../../Delivery/actions/status"
import {ReportActions} from "../../actions/report"
import {reportTypes} from '../../../App/dics/dic'
import * as AppStorage from "../../../App/helpers/storage"
import {useDebouncedCallback} from "use-debounce";
import {SelectTree} from "../../../App/components/Inputs/SelectTree";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%",
        "& .dx-texteditor.dx-editor-outlined": {
            "border": "none !important",
            "border-bottom": "2px solid #c1c1c1 !important",
            "border-radius": "unset !important",
            "&:hover": {
                "border-bottom": "2px solid #898989 !important",
            },
        },
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 111px)",
        position: "relative",
    },
    tableContainer: {
        "min-height": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 473px)",
        [theme.breakpoints.down('lg')]: {
            "height": "calc(100vh - 428px)"
        },
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    searchLabel: {
        "margin-bottom": "15px",
    },
    status: {
        "width": "200px"
    },
    list: {
        "margin-top": "0",
        "margin-bottom": "0"
    },
    frame: {
        "border-width": "0"
    },
    frameContent: {
        "width": "100%",
        "height": "calc(100% - 70px)"
    },
    frameFooterContainer: {
        "padding-right": "5px",
        "padding-top": "17px"
    },
    icon: {
        "& .MuiButton-endIcon": {
            "margin-left": "0",
            "margin-right": "0"
        }
    },
    report: {
        "padding-top": "18px !important"
    },
    date: {
        width: "115px",
        "& .MuiInputAdornment-root .MuiButtonBase-root": {
            padding: 0,
        }
    },
}))

const contractsTabs = [
    {
        key: 'after_fact',
        value: 'Постфактум'
    },
    {
        key: 'active',
        value: 'Рабочий'
    },
    {
        key: 'all',
        value: 'Все'
    }
]

export const Reports = () => {
    let currentStorageFilterReportsMenu = JSON.parse(AppStorage.get('filterReportsMenu'))

    const dispatch = useDispatch()
    const classes = useStyles()

    const {account, file, type, filter, filterStorage} = useSelector(state => ({...state.account, ...state.report}))
    const {categories} = useSelector(state => state.system)

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [products, setProducts] = useState([])
    const [status, setStatus] = useState([])
    const [contract, setContract] = useState(2)
    const [dialog, setDialog] = useState(false)
    const [dialogProducts, setDialogProducts] = useState(false)
    const [form, setForm] = useState([])
    const [downloadType, setDownloadType] = useState('pdf')
    const [sortDirection, setSortDirection] = useState('asc')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(currentStorageFilterReportsMenu ? currentStorageFilterReportsMenu.limit : 15)
    const [sort, setSort] = useState({
        id: null,
        column: 'id'
    })

    const [search, setSearch] = useState(filterStorage[filter.type.value].search ?? null)
    const debounced = useDebouncedCallback(
        (value) => {
            dispatch({
                type: 'REPORTS_FILTER_STORAGE',
                types: type,
                payload: {
                    ...filterStorage[filter.type.value],
                    search: value
                }
            })
        },
        900
    );

    const tableRef = useRef(null)

    const handleSortChange = (sortBy) => {
        if (sortDirection === 'asc' && sort.id !== null) {
            setSort({
                id: null,
                column: 'id'
            })
        } else {
            setSort(sortBy)
            sortDirection === 'desc' ? setSortDirection('asc') : setSortDirection('desc');
        }
    }

    useEffect(() => {
        const getCategories = async () => {
            return await dispatch(SystemActions.categories())
        }

        if (!categories.length) {
            getCategories().then(() => {
            })
        }

        return () => {
            dispatch({type: "REPORT_FILE_CLEAR"})
        }
        // eslint-disable-next-line
    }, [dispatch]);

    const handleContract = (_, value) => {
        setContract(value);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        currentStorageFilterReportsMenu.limit = event.target.value

        AppStorage.set('filterReportsMenu', JSON.stringify(currentStorageFilterReportsMenu))
        setPage(0)
    }

    useEffect(() => {
        if (type) {
            setProducts([])
            switch (type.value) {
                case 'purchase_history':
                case 'price_orders':
                case 'price_standards':
                case 'order_standards':
                    dispatch(SystemActions.status()).then(data => {
                        setStatus(data)
                    })

                    break
                case 'delivery_history':
                case 'delivery_orders':
                case 'delivery_orders_after_fact':
                case 'delivery_times':
                case 'contract_unscheduled_purchases':
                case 'delivery_orders_items':
                    dispatch(DeliveryStatusActions.status()).then(data => {
                        setStatus(data)
                    })

                    break
                case 'contract_history':
                case 'contracts':
                case 'order_unscheduled_purchases':
                case 'execution_contract':
                case 'share_categories':
                case 'share_suppliers':
                case 'customer_contracts':
                    dispatch(ContractActions.statuses()).then(data => {
                        setStatus(data)
                    })

                    break
                case 'user_activity':
                case 'supplier_activity':
                case 'order_supplier_activity':
                case 'share_standards':
                case 'planning_items':
                    setStatus([])
                    break
                default:
            }
        }
    }, [dispatch, type]);

    const getBody = (type) => {
        switch (type.value) {
            case 'purchase_history':
                return (<Lots  page={page} tableRef={tableRef} rowsPerPage={rowsPerPage} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} handleSortChange={handleSortChange} sort={sort} sortDirection={sortDirection}/>)
            case 'delivery_history':
                return (<Deliveries page={page} tableRef={tableRef} rowsPerPage={rowsPerPage} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} handleSortChange={handleSortChange} sort={sort} sortDirection={sortDirection}/>)
            case 'contract_history':
                return (<Contracts  page={page} tableRef={tableRef} rowsPerPage={rowsPerPage} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} handleSortChange={handleSortChange} sort={sort} sortDirection={sortDirection}/>)
            case 'contracts':
            case 'delivery_orders':
            case 'delivery_orders_after_fact':
            case 'delivery_times':
            case 'contract_unscheduled_purchases':
            case 'order_unscheduled_purchases':
            case 'execution_contract':
            case 'user_activity':
            case 'supplier_activity':
            case 'order_supplier_activity':
            case 'share_categories':
            case 'share_suppliers':
            case 'share_standards':
            case 'price_orders':
            case 'price_standards':
            case 'customer_contracts':
            case 'delivery_orders_items':
            case 'order_standards':
            case 'planning_items':
            default:
                return undefined
        }
    }

    function getFilters(reportType) {
        return {
            limit: rowsPerPage,
            page: page + 1,
            created_from: format(new Date(filterStorage[reportType].beginning), 'yyyy-MM-dd'),
            created_to: format(new Date(filterStorage[reportType].ending), 'yyyy-MM-dd'),
            ...(filterStorage[reportType].category.length ? {categories: filterStorage[reportType].category.join(',')} : {}),
            ...(filterStorage[reportType].statuses.length ? {status: filterStorage[reportType].statuses.join(',')} : {}),
            ...(filterStorage[reportType].search ? {search: filterStorage[reportType].search} : {}),
            ...(filterStorage[reportType].amountFrom ? {amount_from: filterStorage[reportType].amountFrom} : {}),
            ...(filterStorage[reportType].amountTo ? {amount_to: filterStorage[reportType].amountTo} : {}),
            ...(filterStorage[reportType].customers.length ? {customers: filterStorage[reportType].customers.map(company => company.id).join(',')} : {}),
            ...(filterStorage[reportType].suppliers.length ? {suppliers: filterStorage[reportType].suppliers.map(company => company.id).join(',')} : {}),
            ...(sort.id ? {sortColumn: sort.column} : {}),
            ...(sortDirection && sort.id ? {direction: sortDirection} : {}),
        }
    }

    const getLots = async () => {
        return await dispatch(OrderActions.lots(getFilters(filter.type.value)))
    }

    const getDeliveries = async () => {
        return await dispatch(DeliveryActions.orders(getFilters(filter.type.value)))
    }

    const getContracts = async () => {
        return await dispatch(ContractActions.contracts(getFilters(filter.type.value)))
    }

    const getSortedReports = (type) => {
        switch (type.value) {
            case 'purchase_history':
                getLots().then(() => {
                    tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
                })
                break
            case 'delivery_history':
                getDeliveries().then(() => {
                    tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
                })
                break
            case 'contract_history':
                getContracts().then(() => {
                    tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
                })
                break
            default:
        }
    }

    useEffect(() => {
        debounced.callback(search)
        // eslint-disable-next-line
    }, [search]);

    useEffect(() => {
        if (type) {
            getSortedReports(type)
        }
        // eslint-disable-next-line
    }, [dispatch, page, rowsPerPage, type, products, sort, sortDirection, filterStorage[filter.type?.value]]);

    const handlePeriod = (_, value) => {
        const now = new Date()
        let date = null
        switch (value) {
            case 0:
                date = new Date(now.setDate(now.getDate() - now.getDay() + ((now.getDay() === 0) ? -6 : 1)))
                dispatch({
                    type: 'REPORTS_FILTER_STORAGE',
                    types: type,
                    payload: {...filterStorage[filter.type.value], beginning: date, period: value, ending: new Date()}
                })
                return date
            case 1:
                date = new Date(now.getFullYear(), now.getMonth(), 1)
                dispatch({
                    type: 'REPORTS_FILTER_STORAGE',
                    types: type,
                    payload: {...filterStorage[filter.type.value], beginning: date, period: value, ending: new Date()}
                })
                return date
            case 2:
                const month = now.getMonth();
                if (month <= 2) {
                    date = new Date(now.getFullYear(), 0, 1)
                    dispatch({
                        type: 'REPORTS_FILTER_STORAGE',
                        types: type,
                        payload: {
                            ...filterStorage[filter.type.value],
                            beginning: date,
                            period: value,
                            ending: new Date()
                        }
                    })
                    return date
                } else if (month <= 5) {
                    date = new Date(now.getFullYear(), 3, 1)
                    dispatch({
                        type: 'REPORTS_FILTER_STORAGE',
                        types: type,
                        payload: {
                            ...filterStorage[filter.type.value],
                            beginning: date,
                            period: value,
                            ending: new Date()
                        }
                    })
                    return date
                } else if (month <= 8) {
                    date = new Date(now.getFullYear(), 6, 1)
                    dispatch({
                        type: 'REPORTS_FILTER_STORAGE',
                        types: type,
                        payload: {
                            ...filterStorage[filter.type.value],
                            beginning: date,
                            period: value,
                            ending: new Date()
                        }
                    })
                    return date
                } else {
                    date = new Date(now.getFullYear(), 9, 1)
                    dispatch({
                        type: 'REPORTS_FILTER_STORAGE',
                        types: type,
                        payload: {
                            ...filterStorage[filter.type.value],
                            beginning: date,
                            period: value,
                            ending: new Date()
                        }
                    })
                    return date
                }
            case 3:
                date = new Date(now.getFullYear(), 0, 1)
                dispatch({
                    type: 'REPORTS_FILTER_STORAGE',
                    types: type,
                    payload: {...filterStorage[filter.type.value], beginning: date, period: value, ending: new Date()}
                })
                return date
            default:
        }
    }

    const setCustomers = (data) => {
        dispatch({
            type: 'REPORTS_FILTER_STORAGE',
            types: type,
            payload: {...filterStorage[filter.type.value], customers: data}
        })
    }

    const setSuppliers = (data) => {
        dispatch({
            type: 'REPORTS_FILTER_STORAGE',
            types: type,
            payload: {...filterStorage[filter.type.value], suppliers: data}
        })
    }

    const hasReport = (type) => {
        return reportTypes.indexOf(type) !== -1 && ['purchase_history', 'delivery_history', 'contract_history'].indexOf(type) === -1
    }

    const onClickCreateReport = () => {
        setIsSubmitting(true)
        return dispatch(ReportActions.add({
            beginning: format(new Date(filterStorage[filter.type.value].beginning), 'yyyy-MM-dd'),
            ending: format(new Date(filterStorage[filter.type.value].ending), 'yyyy-MM-dd'),
            type: type.value,
            ...(filterStorage[filter.type.value].category.length ? {categories: filterStorage[filter.type.value].category} : {}),
            ...(form.length ? {forms: form} : {}),
            ...(filterStorage[filter.type.value].closed.length ? {closed: filterStorage[filter.type.value].closed} : {}),
            ...(filterStorage[filter.type.value].statuses.length ? {status: filterStorage[filter.type.value].statuses} : {}),
            ...(filterStorage[filter.type.value].amountFrom ? {price_from: filterStorage[filter.type.value].amountFrom} : {}),
            ...(filterStorage[filter.type.value].amountTo ? {price_to: filterStorage[filter.type.value].amountTo} : {}),
            ...(filterStorage[filter.type.value].offerItemName ? {offer_item_name: filterStorage[filter.type.value].offerItemName} : {}),
            ...(filterStorage[filter.type.value].customers.length ? {customers: filterStorage[filter.type.value].customers.map(company => company.id)} : {}),
            ...(filterStorage[filter.type.value].suppliers.length ? {suppliers: filterStorage[filter.type.value].suppliers.map(company => company.id)} : {}),
            ...(products.length ? {products: products.map(product => product.id)} : {}),
            contract: contractsTabs[contract].key,
        })).then(
            () => {
                dispatch(ReportActions.reports({...filter, type: filter.type.value}))
                setIsSubmitting(false)
            },
            () => {
                setIsSubmitting(false)
            }
        )
    }

    return file ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.fullWidth}>
                        <Grid container className={classes.container} direction="column" justify="space-between" alignItems="stretch">
                            <Grid item className={classes.frameContent}>
                                <iframe title="report" className={classes.frame} src={URL.createObjectURL(file.file)} width="100%" height="100%" />
                            </Grid>
                            <Grid item className={classes.footer}>
                                <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                    <Grid item className={classes.footerContent}>
                                        <Grid container className={classes.frameFooterContainer} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                            <Grid item>
                                                <Submit
                                                    disableElevation
                                                    variant="contained"
                                                    color="primary"
                                                    type="button"
                                                    onClick={() => {
                                                        dispatch({type: "REPORT_FILE_CLEAR"})
                                                    }}
                                                >
                                                    Создать отчёт
                                                </Submit>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                    <Grid item>
                                                        <Submit
                                                            disableElevation
                                                            variant="contained"
                                                            color="primary"
                                                            type="button"
                                                            endIcon={<GetAppIcon/>}
                                                            href={(downloadType === 'pdf') ? URL.createObjectURL(file.file) : (process.env.REACT_APP_HOST_API + `/report/${file.id}/download?type=${downloadType}&token=${AppStorage.get('token').replace('Bearer ', '')}`)}
                                                            download
                                                        >
                                                            Скачать
                                                        </Submit>
                                                    </Grid>
                                                    <Grid item>
                                                        <Select
                                                            value={downloadType}
                                                            onChange={(e) => {
                                                                setDownloadType(e.target.value)
                                                            }}
                                                        >
                                                            {
                                                                file.formats.map((format, index) => {
                                                                    let name = format === 'excel' ? 'XLSX' : format.toUpperCase()
                                                                    return <MenuItem key={index} value={format}>{name}</MenuItem>
                                                                })
                                                            }
                                                        </Select>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    ) : (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.fullWidth}>
                        <Grid container className={classes.container} direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item className={classes.fullWidth}>
                                <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                    <Grid item className={classes.header}>
                                        <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
                                            <Grid item className={classes.fullWidth}>
                                                <Typography className={classes.title}>
                                                    Формирование отчета {type ? `"${type.name}"` : null}
                                                </Typography>
                                            </Grid>
                                            <Grid item className={classes.fullWidth}>
                                                <Grid container className={classes.fullWidth} direction="row" justify="space-between" alignItems="stretch" spacing={2}>
                                                    <Grid item xs={10}>
                                                        <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                                                            <Grid item>
                                                                <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                                                    <Grid item>
                                                                        <Typography>
                                                                            За период
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <ButtonList
                                                                            className={classes.list}
                                                                            value={filterStorage[filter.type.value].period}
                                                                            onChange={handlePeriod}
                                                                            values={[
                                                                                {
                                                                                    key: 'week',
                                                                                    value: 'Неделя'
                                                                                },
                                                                                {
                                                                                    key: 'month',
                                                                                    value: 'Месяц'
                                                                                },
                                                                                {
                                                                                    key: 'quarter',
                                                                                    value: 'Квартал'
                                                                                },
                                                                                {
                                                                                    key: 'year',
                                                                                    value: 'Год'
                                                                                }
                                                                            ]}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                                                    <Grid item>
                                                                        <Typography>
                                                                            Дата с
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                            <KeyboardDatePicker
                                                                                className={classes.date}
                                                                                fullWidth
                                                                                invalidDateMessage="Некорректная дата"
                                                                                minDate={new Date("1970-01-01")}
                                                                                minDateMessage={""}
                                                                                disableToolbar
                                                                                orientation="landscape"
                                                                                variant="inline"
                                                                                openTo="year"
                                                                                name="beginning"
                                                                                format="dd-MM-yyyy"
                                                                                autoOk
                                                                                required
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                value={filterStorage[filter.type.value].beginning}
                                                                                onChange={date => {
                                                                                    dispatch({
                                                                                        type: 'REPORTS_FILTER_STORAGE',
                                                                                        types: type,
                                                                                        payload: {
                                                                                            ...filterStorage[filter.type.value],
                                                                                            beginning: date
                                                                                        }
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                                                    <Grid item>
                                                                        <Typography>
                                                                            по
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                            <KeyboardDatePicker
                                                                                className={classes.date}
                                                                                fullWidth
                                                                                invalidDateMessage="Некорректная дата"
                                                                                disableToolbar
                                                                                orientation="landscape"
                                                                                variant="inline"
                                                                                openTo="date"
                                                                                name="ending"
                                                                                format="dd-MM-yyyy"
                                                                                autoOk
                                                                                required
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                value={filterStorage[filter.type.value].ending}
                                                                                onChange={date => {
                                                                                    dispatch({
                                                                                        type: 'REPORTS_FILTER_STORAGE',
                                                                                        types: type,
                                                                                        payload: {
                                                                                            ...filterStorage[filter.type.value],
                                                                                            ending: date
                                                                                        }
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            {(type && ((type.value === 'contracts') || (type.value === 'delivery_orders'))) &&
                                                                <Grid item>
                                                                    <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                                                        <Grid item>
                                                                            <Typography>
                                                                                Договор
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <ButtonList
                                                                                className={classes.list}
                                                                                value={contract}
                                                                                onChange={handleContract}
                                                                                values={contractsTabs}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                                            <Grid item>
                                                                <Typography>
                                                                    Статус
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Select
                                                                    className={classes.status}
                                                                    value={filterStorage[filter.type.value].statuses}
                                                                    multiple
                                                                    disabled={!type || ((type.value === 'supplier_activity') || (type.value === 'order_supplier_activity') || (type.value === 'user_activity') || (type.value === 'share_suppliers') || (type.value === 'share_categories') || (type.value === 'share_standards') || (type.value === 'planning_items'))}
                                                                    onChange={(event) => {
                                                                        dispatch({
                                                                            type: 'REPORTS_FILTER_STORAGE',
                                                                            types: type,
                                                                            payload: {
                                                                                ...filterStorage[filter.type.value],
                                                                                statuses: event.target.value
                                                                            }
                                                                        })
                                                                    }}
                                                                >
                                                                    {status && status.filter((item) => ((item.index !== 0))).map((item) => {
                                                                        return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                                    })}
                                                                </Select>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="stretch">
                                                        <Grid item xs={6}>
                                                            <Grid item>
                                                                <Typography>
                                                                    Заказчики
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                    <Grid item xs={8}>
                                                                        <TextField
                                                                            style={{"margin-top": "auto"}}
                                                                            fullWidth
                                                                            variant="standard"
                                                                            name={'customers'}
                                                                            margin={'normal'}
                                                                            disabled
                                                                            value={filterStorage[filter.type.value].customers.length ? filterStorage[filter.type.value].customers.map(customer => customer.legal_detail.name).join(', ') : 'Все'}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Submit
                                                                            variant="contained"
                                                                            component="label"
                                                                            color="primary"
                                                                            className={classes.icon}
                                                                            endIcon={<EditIcon/>}
                                                                            disabled={!type || (type.value === 'supplier_activity') || (type.value === 'order_supplier_activity')}
                                                                            onClick={() => {
                                                                                setDialog('customers')
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Submit
                                                                            variant="contained"
                                                                            component="label"
                                                                            color="secondary"
                                                                            className={classes.icon}
                                                                            endIcon={<ClearIcon/>}
                                                                            disabled={!filterStorage[filter.type.value].customers.length}
                                                                            onClick={() => {
                                                                                setCustomers([])
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Grid item>
                                                                <Typography>
                                                                    Поставщики
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                    <Grid item xs={8}>
                                                                        <TextField
                                                                            style={{"margin-top": "auto"}}
                                                                            fullWidth
                                                                            variant="standard"
                                                                            name={'suppliers'}
                                                                            margin={'normal'}
                                                                            disabled
                                                                            value={filterStorage[filter.type.value].suppliers.length ? filterStorage[filter.type.value].suppliers.map(supplier => supplier.legal_detail.name).join(', ')  : 'Все'}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Submit
                                                                            variant="contained"
                                                                            component="label"
                                                                            color="primary"
                                                                            className={classes.icon}
                                                                            endIcon={<EditIcon/>}
                                                                            disabled={!type || ((type.value === 'user_activity') || (type.value === 'share_standards') || (type.value === 'planning_items'))}
                                                                            onClick={() => {
                                                                               setDialog('suppliers')
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Submit
                                                                            variant="contained"
                                                                            component="label"
                                                                            color="secondary"
                                                                            className={classes.icon}
                                                                            endIcon={<ClearIcon/>}
                                                                            disabled={!filterStorage[filter.type.value].suppliers.length}
                                                                            onClick={() => {
                                                                                setSuppliers([])
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container className={classes.fullWidth} direction="row" justify="space-between" alignItems="stretch">
                                                        <Grid item xs={type && type.value === 'price_standards' ? 6 : 8}>
                                                            <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                <Grid item xs={4}>
                                                                    <Typography>
                                                                        Категория
                                                                    </Typography>
                                                                    <SelectTree
                                                                        options={categories.filter(category => {
                                                                            const params = account.permissions.find(permission => permission.name === 'access_to_report')?.params
                                                                            if (params?.categories) {
                                                                                if (!category.category) {
                                                                                    return params.categories.includes(category.id)
                                                                                }
                                                                            }

                                                                            return true
                                                                        }).map(category => ({
                                                                            ...category,
                                                                            parent: category.category
                                                                        }))}
                                                                        value={filterStorage[filter.type.value].category}
                                                                        multiple={true}
                                                                        onChange={(value) => {
                                                                            dispatch({
                                                                                type: 'REPORTS_FILTER_STORAGE',
                                                                                types: type,
                                                                                payload: {
                                                                                    ...filterStorage[filter.type.value],
                                                                                    category: value
                                                                                }
                                                                            })
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                {((type && ((type.value !== 'delivery_orders_items') && (type.value !== 'order_standards') && (type.value !== 'planning_items'))) || !type) &&
                                                                    <React.Fragment>
                                                                        <Grid item>
                                                                            <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                                                                <Grid item>
                                                                                    <Typography>
                                                                                        Сумма от
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <TextField
                                                                                        style={{"margin-top": "auto"}}
                                                                                        variant="standard"
                                                                                        name={'amountFrom'}
                                                                                        fullWidth={true}
                                                                                        margin={'normal'}
                                                                                        type="number"
                                                                                        value={filterStorage[filter.type.value].amountFrom}
                                                                                        disabled={!type || ((type.value === 'supplier_activity') || (type.value === 'order_supplier_activity') || (type.value === 'user_activity') || (type.value === 'share_suppliers') || (type.value === 'share_categories') || (type.value === 'share_standards'))}
                                                                                        inputProps={{
                                                                                            step: 0.01
                                                                                        }}
                                                                                        onChange={event => {
                                                                                            dispatch({
                                                                                                type: 'REPORTS_FILTER_STORAGE',
                                                                                                types: type,
                                                                                                payload: {
                                                                                                    ...filterStorage[filter.type.value],
                                                                                                    amountFrom: event.target.value
                                                                                                }
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                                                                <Grid item>
                                                                                    <Typography>
                                                                                        до
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <TextField
                                                                                        style={{"margin-top": "auto"}}
                                                                                        variant="standard"
                                                                                        name={'amountTo'}
                                                                                        fullWidth={true}
                                                                                        margin={'normal'}
                                                                                        type="number"
                                                                                        value={filterStorage[filter.type.value].amountTo}
                                                                                        disabled={!type || ((type.value === 'supplier_activity') || (type.value === 'order_supplier_activity') || (type.value === 'user_activity') || (type.value === 'share_suppliers') || (type.value === 'share_categories') || (type.value === 'share_standards'))}
                                                                                        inputProps={{
                                                                                            step: 0.01
                                                                                        }}
                                                                                        onChange={event => {
                                                                                            dispatch({
                                                                                                type: 'REPORTS_FILTER_STORAGE',
                                                                                                types: type,
                                                                                                payload: {
                                                                                                    ...filterStorage[filter.type.value],
                                                                                                    amountTo: event.target.value
                                                                                                }
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </React.Fragment>
                                                                }
                                                                {(type && ((type.value === 'delivery_orders_items') || (type.value === 'order_standards'))) &&
                                                                    <Grid item>
                                                                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                                                            <Grid item>
                                                                                <Typography>
                                                                                    Наименование
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <TextField
                                                                                    variant="standard"
                                                                                    name={'offer_item_name'}
                                                                                    fullWidth={true}
                                                                                    style={{minWidth: "350px", "margin-top": "auto"}}
                                                                                    margin={'normal'}
                                                                                    type="text"
                                                                                    value={filterStorage[filter.type.value].offerItemName}
                                                                                    onChange={event => {
                                                                                        dispatch({
                                                                                            type: 'REPORTS_FILTER_STORAGE',
                                                                                            types: type,
                                                                                            payload: {
                                                                                                ...filterStorage[filter.type.value],
                                                                                                offerItemName: event.target.value
                                                                                            }
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        {(type && ((type.value === 'contracts') || (type.value === 'delivery_orders'))) &&
                                                            <Grid item xs={4}>
                                                                <Grid container className={classes.fullWidth} direction="row" justify="flex-end" alignItems="stretch" spacing={2}>
                                                                    <Grid item xs={6}>
                                                                        <Typography>
                                                                            Способ закупки
                                                                        </Typography>
                                                                        <Select
                                                                            fullWidth
                                                                            value={form}
                                                                            multiple
                                                                            onChange={(e) => {
                                                                                setForm(e.target.value);
                                                                            }}
                                                                        >
                                                                            <MenuItem value="request">Ценовой запрос</MenuItem>
                                                                            <MenuItem value="store">Маркетплейс</MenuItem>
                                                                        </Select>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography>
                                                                            Состоявшаяся
                                                                        </Typography>
                                                                        <Select
                                                                            fullWidth
                                                                            value={filterStorage[filter.type.value].closed}
                                                                            multiple
                                                                            onChange={(e) => {
                                                                                dispatch({
                                                                                    type: 'REPORTS_FILTER_STORAGE',
                                                                                    types: type,
                                                                                    payload: {
                                                                                        ...filterStorage[filter.type.value],
                                                                                        closed: e.target.value
                                                                                    }
                                                                                })
                                                                            }}
                                                                        >
                                                                            <MenuItem value="yes">Да</MenuItem>
                                                                            <MenuItem value="no">Нет</MenuItem>
                                                                        </Select>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                        {(type && type.value === 'price_standards') &&
                                                            <Grid item xs={6}>
                                                                <Grid item>
                                                                    <Typography>
                                                                        Товары
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                        <Grid item xs={8}>
                                                                            <TextField
                                                                                style={{"margin-top": "auto"}}
                                                                                fullWidth
                                                                                variant="standard"
                                                                                name={'products'}
                                                                                margin={'normal'}
                                                                                disabled
                                                                                value={products.length ? products.map(product => product.name).join(', ') : 'Все'}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Submit
                                                                                variant="contained"
                                                                                component="label"
                                                                                color="primary"
                                                                                className={classes.icon}
                                                                                endIcon={<EditIcon/>}
                                                                                disabled={!type}
                                                                                onClick={() => {
                                                                                    setDialogProducts('products')
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Submit
                                                                                variant="contained"
                                                                                component="label"
                                                                                color="secondary"
                                                                                className={classes.icon}
                                                                                endIcon={<ClearIcon/>}
                                                                                disabled={!products.length}
                                                                                onClick={() => {
                                                                                    setProducts([])
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {(type && !hasReport(type.value)) ? (
                                                <Grid item>
                                                    <TextField
                                                        id="search"
                                                        variant="standard"
                                                        name={'search'}
                                                        value={search}
                                                        placeholder={'Поиск'}
                                                        fullWidth={true}
                                                        margin={'normal'}
                                                        onChange={(event) => {
                                                            setSearch(event.target.value)
                                                        }}
                                                        classes={{
                                                            root: clsx(classes.filterLabel, classes.searchLabel)
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <SearchIcon style={{color: '#c1c1c1'}}/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {type && getBody(type) ? getBody(type) :
                                    <React.Fragment>
                                        <Grid item className={classes.fullWidth}>
                                            <Grid container className={classes.fullWidth} direction="column"
                                                  justify="space-between" alignItems="stretch">
                                                <Grid item className={classes.orders}>
                                                    <TableContainer className={classes.tableContainer}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.footer}>
                                            <Grid container direction="column" justify="center" alignItems="stretch"
                                                  spacing={1}>
                                                <Grid item className={classes.footerContent}>
                                                    <Grid container className={classes.footerContainer}
                                                          direction="row" justify="flex-end" alignItems="center"
                                                          spacing={2}>
                                                        <Grid item className={classes.report}>
                                                            {(type && hasReport(type.value)) &&
                                                                <Submit
                                                                    disableElevation
                                                                    variant="contained"
                                                                    color="primary"
                                                                    type="button"
                                                                    disabled={isSubmitting || !type}
                                                                    onClick={onClickCreateReport}
                                                                >
                                                                    Сформировать отчет
                                                                </Submit>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <Dialog open={dialog} setDialog={setDialog} setCustomers={setCustomers} setSuppliers={setSuppliers} customers={filterStorage[filter.type.value].customers} suppliers={filterStorage[filter.type.value].suppliers}/>
            <DialogProducts openProducts={dialogProducts} setDialogProducts={setDialogProducts} setProducts={setProducts} products={products}/>
        </Grid>
    )
}
