import React, {useState} from "react"
import {Link} from "react-router-dom"
import {useSelector} from "react-redux"

import {Collapse, List, ListItem, ListItemText, makeStyles, withStyles} from '@material-ui/core'
import {ExpandLess, ExpandMore} from "@material-ui/icons"

import {AuthorizationService} from "../Auth/services/authorization"

const useStyles = makeStyles(theme => ({
    item: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "cursor": "pointer"
    },
    nested: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "cursor": "pointer",
        "padding-left": `${theme.spacing(6)}px !important`
    },
    nestedActive: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "background-color": "#898989",
        "cursor": "pointer",
        "padding-left": `${theme.spacing(6)}px !important`
    },
    active: {
        "background-image": "none !important",
        "background-color": "#898989"
    },
    circle: {
        "width": "12px",
        "height": "12px",
        "border-radius": '50%',
        "background-color": '#616161',
        "margin-bottom": "5px"
    },
    categories: {
        "overflow-y": "auto",
        "max-height": "calc(100vh - 255px)"
    }
}))

const CustomList = withStyles({
    root: {
        "padding": "0",
        "& .MuiListItem-root": {
            "background-image": "linear-gradient(to bottom,#e4e4e4,#f4f4f4)",
            "align-items": "center",
            "min-height": "50px",
            "padding": "5px 25px 0",
            "border-bottom": "1px solid #e4e4e4",
            "outline": "0"
        },
        "& .MuiListItemIcon-root": {
            "min-width": "30px"
        },
        "& .MuiListItem-button:hover": {
            "background-color": "#898989",
            "&:after": {
                "position": "absolute",
                "content": "''",
                "top": "0",
                "left": "0",
                "width": "100%",
                "height": "100%",
                "background-color": "rgba(0,0,0,.04)"
            }
        },
        "& .MuiListItemText-secondary": {
            "color": "#485868",
            "font-size": "15px"
        },
        "& .MuiListItemText-primary": {
            "font-size": "16px",
            "font-weight": "700",
            "text-transform": "uppercase"
        }
    }
})(List);

export const Menu = (props) => {
    const classes = useStyles()
    const {location} = props
    const [item, setItem] = useState(location.pathname)
    const {account} = useSelector(state => state.account)

    return (
        <CustomList>
            {[
                ...(
                    AuthorizationService.permissions(account, "access_to_marketplace") ? [{
                        title: "Отбор оферт",
                        to: "/supplier/marketplace/deliveries",
                        type: 'tree',
                        items: [{
                            title: "Заказы",
                            to: "/supplier/marketplace/deliveries",
                            type: null
                        }, ]
                    }] : []
                ),
                {
                    title: "Ценовые запросы",
                    to: "/supplier/marketplace/orders",
                    type: 'tree',
                    items: [{
                            title: "Все запросы",
                            to: "/supplier/marketplace/orders",
                            type: null
                        },
                        {
                            title: "Мои запросы",
                            to: "/supplier/marketplace/my-orders",
                            type: null
                        }
                    ]
                },
            ].map((option, index) => (
                <React.Fragment key={index}>
                    {option.type === 'menu' ?
                        (
                            <ListItem
                                button
                                key={option.title}
                                component={Link}
                                to={option.to}
                                classes={{root: (location.pathname.indexOf(option.to) !== -1) ? classes.active : undefined}}
                            >
                                <ListItemText primary={option.title} />
                            </ListItem>
                        ) :
                        (
                            <React.Fragment key={index}>
                                <ListItem
                                    button
                                    key={option.title}
                                    onClick={() => {
                                        setItem((option.to === item) ? null : option.to)
                                    }}
                                    classes={{root: ((location.pathname === '/marketplace/orders') && (location.pathname === '/marketplace')) ? classes.active : undefined}}
                                >
                                    <ListItemText primary={option.title} />
                                    {item === option.to ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={item === option.to} timeout="auto" unmountOnExit>
                                    {
                                        option.items.map((el, index) => (
                                            <List key={index} component="div" disablePadding>
                                                <ListItem
                                                    button
                                                    key={el.title}
                                                    component={Link}
                                                    to={el.to}
                                                    classes={{root: (location.pathname.indexOf(el.to) !== -1) ? classes.nestedActive : classes.nested}}
                                                >
                                                    <ListItemText primary={el.title} />
                                                </ListItem>
                                            </List>
                                        ))
                                    }
                                </Collapse>
                            </React.Fragment>
                        )
                    }
                </React.Fragment>
            ))}
        </CustomList>
    )
}
