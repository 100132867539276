const sources = {
    OMS: {
        key: 1,
        name: 'Фонд ОМС'
    },
    DMS: {
        key: 2,
        name: 'Фонд ДМС'
    },
    OWN_FUNDS: {
        key: 3,
        name: 'Собственные средства ЧУЗ'
    },
    ENTREPRENEURIAL_ACTIVITY: {
        key: 4,
        name: 'Средства от предпринимательской деятельности'
    },
    RZD: {
        key: 5,
        name: 'Средства ОАО "РЖД"'
    },
    SPECIAL_PURPOSE_FINANCING: {
        key: 6,
        name: 'Целевое финансирование'
    },
    INVESTMENT_PROGRAM: {
        key: 7,
        name: 'Инвестпрограмма'
    },
    FREE_ADMISSION: {
        key: 8,
        name: 'Безвозмездное поступление'
    }
}

export { sources }
