import {HttpService} from "../../App/services/http"

export const ScheduleService = {
    get,
    schedule,
    add,
    edit,
    remove,
    categories,
    filter
}

function get(params) {
    return HttpService.get("/planning/schedules", params)
}

function schedule(id, params) {
    return HttpService.get(`/planning/schedule/${id}`, params).then(response => {
        return response.schedule
    })
}

function add(values) {
    return HttpService.post(`/planning/schedule`, values)
}

function edit(id, values) {
    return HttpService.put(`/planning/schedule/${id}`, values)
}

function remove(ids) {
    return HttpService.deleteMethod(`/planning/schedules`, ids)
}

function categories(params) {
    return HttpService.get("/planning/schedule/categories", params).then(response => {
        return response.categories
    })
}

function filter(params) {
    return HttpService.get("/planning/schedules/filter", params)
}
