const durations = {
    WEEK: {
        key: 'week',
        name: 'Неделя',
        active: false
    },
    MONTH: {
        key: 'month',
        name: 'Месяц',
        active: false
    },
    QUARTER: {
        key: 'quarter',
        name: 'Квартал',
        active: false
    },
    TERM: {
        key: 'term',
        name: 'Семестр',
        active: false
    },
    YEAR: {
        key: 'year',
        name: 'Год',
        active: true
    }
}

export { durations }
