import React from "react"
import {useSelector} from "react-redux"

import {Grid, Link, makeStyles, Paper, Typography} from "@material-ui/core"
import {Check} from "@material-ui/icons"
import {AuthorizationService} from "../../../Auth/services/authorization";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    support: {
        "padding": "20px"
    },
    title: {
        "font-size": "22px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    itemsText: {
        "font-size": "18px",
        display: "flex",
        alignItems: "flex-end",
    },
    completed: {
        '& $line': {
            "border-color": "#616161"
        },
    },
    link: {
        "color": "#485868"
    },
}))

export const Content = () => {
    const classes = useStyles()
    const {account} = useSelector(state => state.account)

    return AuthorizationService.roles(account, ['customer']) ? (
        <Grid item className={classes.content}>
            <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                <Paper>
                    <Grid className={classes.support} container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                        <Grid item>
                            <Typography className={classes.title}>Согласие дано заказчиком: {account.agreements_approve_date_time}</Typography>
                        </Grid>
                        <Grid item className={classes.itemsText}>
                            <Link className={classes.link} target="_blank" href="https://drive.google.com/file/d/1MMHzHSkfOiNzVBCMdnlTM2ikADpQb7IN/view?usp=sharing">Согласие на обработку персональных данных</Link><Check className={classes.completed} />
                        </Grid>
                        <Grid item className={classes.itemsText}>
                            <Link className={classes.link} target="_blank" href="https://drive.google.com/file/d/1ZnSifcHsecagTglTzSaXNrN8unGneFDK/view?usp=sharing">Пользовательское соглашение</Link><Check className={classes.completed} />
                        </Grid>
                        <Grid item className={classes.itemsText}>
                            <Link className={classes.link} target="_blank" href="https://drive.google.com/file/d/1T0lJVkyB3s6UfgkwgWVjXYvAGDA3qdR1/view?usp=sharing">Политика конфиденциальности</Link><Check className={classes.completed} />
                        </Grid>
                        <Grid item className={classes.itemsText}>
                            <Link className={classes.link} target="_blank" href="https://drive.google.com/file/d/1WEadkaoez-ivS2vT1HysN1unO_8QDQ3u/view?usp=sharing">Оферта</Link><Check className={classes.completed} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    ) : (
      <Grid item className={classes.content}>
          <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
              <Paper>
                  <Grid className={classes.support} container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                      <Grid item>
                          <Typography className={classes.title}>Согласие дано поставщиком: {account.agreements_approve_date_time}</Typography>
                      </Grid>
                      <Grid item className={classes.itemsText}>
                          <Link className={classes.link} target="_blank" href="https://drive.google.com/file/d/1GOcLTMqneDO47t7qRrxBeZjSJycZJL9g/view?usp=sharing">Согласие на обработку персональных данных</Link><Check className={classes.completed} />
                      </Grid>
                      <Grid item className={classes.itemsText}>
                          <Link className={classes.link} target="_blank" href="https://drive.google.com/file/d/1eaeCWvSSO9CEVramZfXBa9x33nOQ8OlE/view?usp=sharing">Пользовательское соглашение</Link><Check className={classes.completed} />
                      </Grid>
                      <Grid item className={classes.itemsText}>
                          <Link className={classes.link} target="_blank" href="https://drive.google.com/file/d/1sJKUrtifi90CyZDU1m7H88BzCwPhGDDF/view?usp=sharing">Политика конфиденциальности</Link><Check className={classes.completed} />
                      </Grid>
                      <Grid item className={classes.itemsText}>
                          <Link className={classes.link} target="_blank" href="https://drive.google.com/file/d/1WEadkaoez-ivS2vT1HysN1unO_8QDQ3u/view?usp=sharing">Оферта</Link><Check className={classes.completed} />
                      </Grid>
                  </Grid>
              </Paper>
          </Grid>
      </Grid>
    )
}
