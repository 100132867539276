import {HttpService} from "../../App/services/http"

export const DiscountsService = {
    discounts,
    cumulativeDiscounts,
    discount,
    add,
    addCumulative,
    editCumulative,
    products,
    edit,
}

function discounts(params) {
    return HttpService.get("/discounts", params)
}

function cumulativeDiscounts(params) {
    return HttpService.get("/discounts/cumulative", params)
}

function products(params) {
    return HttpService.get("/prices/products/discounts", params)
}

function discount(companyId, id) {
    return HttpService.get(`/discounts/${companyId}/${id}`)
        .then(response => {
            return response.discount
        })
}

function add(values) {
    return HttpService.post("/discount", values)
}

function edit(id, values) {
    return HttpService.put(`/discounts/${id}`, values)
}

function addCumulative(values) {
    return HttpService.post("/discounts/cumulative", values)
}

function editCumulative(id, values) {
    return HttpService.put(`/discounts/cumulative/${id}`, values)
}
