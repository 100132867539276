import {PurchaseService} from "../services/purchase"

export const PurchaseActions = {
    lot,
    orders
}

function lot(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PurchaseService.lot(id)
            .then(
                lot => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(lot)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function orders(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_ORDERS_REQUEST"})

        PurchaseService.orders(params)
            .then(
                orders => {
                    dispatch({type: "PURCHASE_ORDERS", payload: {data: orders.data, meta: orders.meta}})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_ORDERS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}
