import React from "react"
import {render} from "react-dom"
import {Route, Router, Switch} from "react-router-dom"
import {applyMiddleware, createStore} from "redux"
import thunk from "redux-thunk"
import logger from "redux-logger"
import {Provider} from "react-redux"
import DateFnsUtils from "@date-io/date-fns"
import {ru} from "date-fns/locale"

import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles"
import {Grid} from "@material-ui/core"
import {MuiPickersUtilsProvider} from "@material-ui/pickers"

import "./index.css"
import 'devextreme/dist/css/dx.light.css'

import AppReducers from "./App/reducers"
import {history} from "./App/helpers/history"
import {NotFound} from "./App/Status"
import {Loading} from "./App/Loading"
import {Alert} from "./App/Alert"
import {Login} from "./Auth/Login"
import {Recovery} from "./Auth/Recovery"
import {Logout} from "./Auth/Logout"
import {Registration} from "./Auth/Registration"
import {Authorization} from "./Auth/Authorization"
import {Index} from "./Store/Index"
import {Account} from "./Account/Account"
import {Price} from "./Price/Price"
import {Index as Marketplace} from "./Marketplace/Index"
import {Orders} from "./Marketplace/components/Shop/Orders"
import {Orders as MarketplaceOrders} from "./Marketplace/Orders"
import {Order as MarketplaceOrder} from "./Marketplace/components/Order/Order"
import {Create as AddOrder, Create as EditOrder} from "./Marketplace/components/Shop/components/Order/Create"
import {Add as AddOffer, Add as EditOffer} from "./Supplier/Orders/components/Offer/Add"
import {View as ViewOffer} from "./Supplier/Orders/components/Offer/View"
import {Order} from "./Marketplace/components/Shop/components/Order/Order"
import {Cart} from "./Marketplace/Cart"
import {Orders as SupplierOrders} from "./Supplier/Orders/Orders"
import {Orders as SupplierMarketPlaceOrders} from "./Supplier/Marketplace/Orders"
import {MyOrders as MySupplierOrders} from "./Supplier/MyOrders/MyOrders"
import {Lot as MySupplierLot} from "./Supplier/MyOrders/components/Order/Lot"
import {Lot as SupplierMarketplaceLot} from "./Supplier/Marketplace/components/Order/Lot"
import {Lots as OperatorLots} from "./Operator/Lots"
import {Customers} from "./Company/Customers"
import {Customer} from "./Company/components/Customer"
import {Create as CreateCustomer} from "./Company/components/Customer/Create"
import {Operators} from "./Company/components/User/Operators"
import {Suppliers} from "./Company/Suppliers"
import {Supplier} from "./Company/components/Supplier"
import {Add as AddOperator} from "./Company/components/User/components/Operator/Add"
import {Index as Reports} from "./Report/Index"
import {Order as ReportOrder} from "./Report/components/Order/Order"
import {Order as ReportDeliveryOrder} from "./Report/components/Delivery/Order"
import {Contract as ReportContract} from "./Report/components/Contract/Contract"
import {Price as ReportControlPrice} from "./Report/components/Control/Price"
import {Company} from "./Account/Company"
import {Discounts as SupplierDiscounts} from "./Discounts/Discounts"
import {Discounts as SupplierCumulativeDiscounts} from "./Discounts/components/Discounts/Cumulative/Discounts"
import {Add as SupplierDiscountsAdd} from "./Discounts/components/Add"
import {AddCumulative as SupplierCumulativeDiscountAdd} from "./Discounts/components/AddСumulative"
import {Support} from "./Support/Support"
import {Notifications} from "./Notification/Notifications"
import {Contracts} from "./Contract/Contracts"
import {Status as ContractChangeStatus} from "./Contract/components/Status"
import {Companies as PastCompanies} from "./Contract/components/Past/Companies"
import {Products} from "./Catalog/Products"
import {Orders as DeliveryOrders} from "./Delivery/Orders"
import {Order as DeliveryOrder} from "./Delivery/components/Order"
import {Status as DeliveryOrderChangeStatus} from "./Delivery/components/Status"
import {Index as DeliveryAutoOrders} from "./Delivery/components/Auto/Index"
import {Order as DeliveryAutoOrder} from "./Delivery/components/Auto/Order"
import {Cart as DeliveryCart} from "./Delivery/Cart"
import {Index as DeliveryReclamations} from "./Reclamation/Index"
import {Products as SupplierProducts} from "./Supplier/Catalog/Products"
import {Supervisors} from "./Company/components/User/Supervisors"
import {Account as Supervisor} from "./Company/components/User/components/Supervisor/Account"
import {Files} from "./Download/Files"
import {Settings as NotificationSettings} from "./Notification/Settings"
import {Agreements} from "./Account/Agreements"
import {Order as SettingsOrder} from "./Settings/Order"
import {Contract as SettingsContract} from "./Settings/Contract"
import {Standard as SettingsStandard} from "./Settings/Standard"
import {Marketplace as SettingsMarketplace} from "./Settings/Marketplace"
import {Index as Planning} from "./Planning/Index"
import {Purchases as PlanningPurchases} from "./Planning/components/Purchases"
import {Lots as PlanningLots} from "./Planning/components/Purchase/Lots"
import {Products as PlanningProducts} from "./Planning/components/PurchasePlan/Products"
import {Add as PurchasePlanAdd} from "./Planning/components/PurchasePlan/Add"
import {Add as PurchaseAdd} from "./Planning/components/Purchase/Add"
import {Edit as PurchaseEdit} from "./Planning/components/Purchase/Edit"
import {Add as PurchaseLotAdd} from "./Planning/components/Lot/Add"
import {Edit as PurchaseProductEdit} from "./Planning/components/Product/Edit"
import {Add as PurchaseProductAdd} from "./Planning/components/Product/Add"
import {Index as LotsWithProducts} from "./Planning/components/Purchase/Index"
import {Index as PurchasesWithProducts} from "./Planning/components/PurchasePlan/Index"
import {Index as PlanningSettings} from "./Planning/components/Settings/Index"
import {Demo1cGetOrder} from "./Demo/Demo1cGetOrder"
import {PurchasePlanEditRequests} from "./Planning/components/PurchasePlanEditRequests"
import ErrorBoundary from "./App/components/ErrorBoundary/ErrorBoundary"
import {Standards as DictionaryStandards} from "./Dictionaries/Standards"
import {Selected as DictionarySelectedStandards} from "./Dictionaries/Selected"
import {Dashboards} from "./Dashboard/Dashboards";
import {Schedules} from "./Planning/Schedules";
import {Schedule} from "./Planning/components/Schedule/Schedule";
import {Lot} from "./Purchase/components/Order/Lot";
import {Warnings} from "./Notification/Warnings";
import {Warning} from "./Notification/components/Warnings/Warning";
import {Invoices} from "./Account/Invoices";
import {Purchases} from "./Marketplace/components/Shop/Purchases";
import {Publish as PurchasePublish} from "./Planning/components/Purchase/Publish"
import {Create as PurchasePublishCreate} from "./Marketplace/components/Shop/components/Planning/Purchases/Create"
import {IndexNew} from "./Store/IndexNew";
import {Cookies} from "./App/components/Cookies";

let middleware = [thunk]
if (process.env.REACT_APP_MODE !== 'production') {
    middleware = [...middleware, logger]
}

const store = createStore(
    AppReducers,
    applyMiddleware(...middleware)
)

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            `"Blogger Sans"`,
            "Roboto",
            "sans-serif"
        ].join(",")
    },
    palette: {
        primary: {
            main: "#898989",
            dark: "#616161"
        },
        error: {
            main: "#f27360"
        }
    },
    content: {
        width: "calc(100% - 300px)",
        padding: "0 25px",
        margin: "25px 0",
    },
    planningContent: {
        width: "calc(100% - 211px)",
        padding: "0 25px",
        height: "calc(100vh - 211px)",
        margin: "25px 0",
    },
    overrides: {
        MuiAppBar: {
            root: {
                "min-height": "55px",
                "min-width": "1510px",
                "position": "relative !important",
            },
            colorPrimary: {
                "background-color": "#898989",
                "box-shadow": "0 5px 40px rgba(0,0,0,.3)"
            }
        },
        MuiToolbar: {
            regular: {
                "min-height": "55px !important",
                "padding": "0"
            }
        },
        MuiFormLabel: {
            root: {
                "&.Mui-error": {
                    "color": "rgba(0, 0, 0, 0.54)"
                },
                "&.Mui-focused": {
                    "color": "#616161",
                    "& .MuiFormLabel-asterisk:not(.Mui-error)": {
                        "color": "#f44336"
                    }
                }
            },
            asterisk: {
                '&.Mui-error': {
                    "color": "#f44336"
                }
            }
        },
        MuiFormHelperText: {
            root: {
                "&.Mui-error": {
                    "line-height": "unset",
                },
                "line-height": "1.33"
            }
        },
        MuiFormControl: {
            root: {
                '&.MuiTextField-root': {
                    'margin-top': '0'
                }
            }
        },
        MuiButton: {
            contained: {
                "box-shadow": "none",
                "&:hover": {
                    "box-shadow": "none",
                },
                '&.Mui-disabled': {
                    'color': 'white',
                    'background-color': '#c1c1c1',
                    'border-bottom-color': '#a5adb1 !important',
                }
            },
            containedSecondary: {
                "background-color": "#f27360",
                "&:hover": {
                    "box-shadow": "none",
                    "background-color": "#b56a5f"
                },
                '&.Mui-disabled': {
                    'color': 'white',
                    'background-color': '#c1c1c1',
                    'border-bottom-color': '#a5adb1 !important',
                }
            }
        },
        MuiButtonBase: {
            "&.MuiCheckbox-colorSecondary": {
                "&.MuiChecked": {
                    "color": "#616161",
                }
            },
        },
        MuiCheckbox: {
              colorSecondary: {
                  "&:hover": {
                      "background-color": "rgba(0, 0, 0, 0.04)"
                  },
                  "&.Mui-checked": {
                      "color": "#616161",
                      "&:hover": {
                          "background-color": "rgba(0, 0, 0, 0.04) !important"
                      }
                  }
              }
        },
        MuiChip: {
              root: {
                  "height": "25px",
                  "border-radius": "25px"
              }
        },
        MuiInput: {
            underline: {
                '&.Mui-disabled:before': {
                    "border-bottom-style": "dotted"
                },
                "&:before": {
                    "border-bottom": "2px solid #c1c1c1"
                },
                "&:hover:not(.Mui-disabled):before": {
                    "border-bottom": "2px solid #c1c1c1"
                },
                '&:focus-within': {
                    "border-bottom-color": "#616161",
                },
                '&.Mui-error.Mui-focused:after': {
                    "border-bottom-color": "#616161"
                }
            }
        },
        MuiInputBase: {
            input: {
                "color": "#485868",
                "&:not(.Mui-disabled)": {
                    "font-weight": "500",
                },
            }
        },
        MuiSelect: {
            select: {
                "&.MuiSelect-select": {
                    "font-weight": "500",
                    "color": "#485868"
                }
            }
        },
        MuiList: {
            root: {
                "&.MuiMenu-list": {
                    "&.MuiList-padding": {
                        "padding": "0 !important"
                    }
                }
            }
        },
        MuiStepper:{
            horizontal: {
                "background-color": "transparent",
                "padding": "0 0 16px 0",
                "& .MuiStep-root": {
                    "max-width": "149px"
                }
            }
        },
        MuiStepContent: {
            root: {
                "border-left": "1px solid #eaeaf0",
                "border-color": "#898989"
            }
        },
        MuiMenuItem: {
            root: {
                "&.MuiListItem-root": {
                    "&.Mui-selected": {
                        "background-color": "#616161"
                    }
                },
                "color": "#485868",
                "padding-top": "12px",
                "padding-bottom": "12px"
            }
        },
        MuiTypography: {
            root: {
                "color": "#485868"
            }
        },
        MuiPaper: {
            root: {
                '&:not(.MuiAppBar-root):not(.MuiMenu-paper):not(.MuiExpansionPanel-root):not(.MuiStepper-root):not(.MuiAccordion-root):not(.MuiPickerDTTabs-tabs):not(.MuiCard-root):not(.accordionItem)': {
                    "border-top": "5px solid #898989",
                    "border-bottom": "5px solid #c1c1c1",
                    "border-radius": "0px !important",
                    "box-shadow": "0 5px 20px rgba(0,0,0,.4)",
                    '&.MuiSnackbarContent-root': {
                        "border-bottom": "none"
                    }
                },
            }
        },
        MuiCard: {
            root: {
                "border-radius": "unset",
                "box-shadow": "none"
            }
        },
        MuiAccordion: {
            root: {
                "border-radius": "0 !important",
                "box-shadow": "none",
                "border-bottom": "1px solid rgba(224, 224, 224, 1)",
                "&.Mui-expanded": {
                    "margin": "0"
                }
            }
        },
        MuiPickersBasePicker: {
            container: {
                "& .MuiPaper-root": {
                    "border-top": "none !important",
                    "border-bottom": "none !important",
                    "box-shadow": "none !important",
                }
            }
        },
        MuiTablePagination: {
            caption: {
                "&.MuiTypography-body2": {
                    "font-size": "1rem",
                    "color": "#485868"
                }
            },
            select: {
                '&.MuiSelect-selectMenu': {
                    "font-size": "1rem"
                }
            }
        },
        MuiTableCell: {
            head: {
                "font-weight": "500",
                "font-size": "1rem",
                "color": "#485868"
            },
            body: {
                "color": "#485868"
            },
            root: {
                "font-size": "0.950rem",
                "padding": "8px"
            }
        },
        MuiTableRow: {
            root: {
                "&.Mui-selected": {
                    "background-color": "rgba(0, 0, 0, 0.04)",
                    "&:hover": {
                        "background-color": "rgba(0, 0, 0, 0.04) !important"
                    }
                }
            }
        },
        MuiDialogTitle: {
            root: {
                "font-size": "18px",
                "font-weight": "600",
                "text-transform": "uppercase",
                "min-height": "40px",
                "padding": "7px 20px 0px 20px",
                "background-color": "#616161",
                '& .MuiTypography-root': {
                    "color": "#fff",
                }
            }
        },
        MuiDialogActions: {
            root: {
                "padding": "8px 24px"
            }
        },
        MuiDialog: {
            paper: {
                "max-height": "80vh"
            }
        }
    }
})

render(
    <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
            <Provider store={store}>
                <Loading/>
                <Grid container direction="row" justify="center" alignItems="stretch">
                    <Router history={history}>
                        <Alert/>
                        <Warning />
                        <Cookies />
                        <ErrorBoundary>
                            <Switch>
                                <Authorization exact Component={IndexNew} path={"/"} />
                                <Authorization exact Component={Index} path={"/companies"} />

                                <Authorization exact Component={Lot} path={"/lots/:id"} />

                                <Authorization Component={Login} path="/login" />
                                <Authorization Component={Registration} path="/registration" />
                                <Authorization Component={Demo1cGetOrder} path="/demo1cGetOrder" />
                                <Authorization Component={Recovery} path="/recovery" />
                                <Authorization Component={Logout} Roles={["user",  "supplier", "customer", "guest_supplier", "customer_confirmed_by_admin", "supplier_blocked", "supplier_confirmed", "supplier_confirmed_by_admin"]} path="/logout" />
                                <Authorization Component={Company} Roles={["supervisor", "admin","operator"]} path={"/company"} />
                                <Authorization Component={Account} Roles={["user", "supplier", "customer", "guest_supplier", "customer_confirmed_by_admin", "supplier_confirmed", "supplier_confirmed_by_admin"]} path={"/account"} />
                                <Authorization Component={Dashboards} Roles={["admin"]} path={"/dashboards"} />
                                <Authorization Component={Files} Roles={["user", "customer", "supplier", "supervisor"]} path={"/downloads"} />

                                <Authorization Component={NotificationSettings} Roles={["user", "supplier", "customer", "guest_supplier", "customer_confirmed_by_admin", "supplier_confirmed", "supplier_confirmed_by_admin"]} path={"/notification/settings"} />
                                <Authorization Component={Price} Roles={["supplier"]} path={"/supplier/prices"} />
                                <Authorization exact Component={MySupplierLot} Roles={["supplier"]} path={"/supplier/marketplace/my-orders/lot/:id"} />
                                <Authorization Component={Orders} Roles={["customer"]} path={"/marketplace/requests"} />
                                <Authorization Component={AddOrder} Roles={["customer"]} path={"/marketplace/request/add"} />
                                <Authorization Component={Purchases} Roles={["customer"]} path={"/marketplace/publish/purchases"} />
                                <Authorization Component={PurchasePublishCreate} Roles={["customer"]} path={"/marketplace/planning/purchase/add"} />
                                <Authorization exact Component={Order} Roles={["customer"]} path={"/marketplace/request/:id"} />
                                <Authorization Component={EditOrder} Roles={["customer"]} path={"/marketplace/request/:id/edit"} />
                                <Authorization exact Component={Marketplace} Roles={["customer"]} path={"/marketplace"} />
                                <Authorization Component={MarketplaceOrders} Roles={["customer"]} path={"/marketplace/orders"} />
                                <Authorization Component={MarketplaceOrder} Roles={["customer"]} path={"/marketplace/order/:id"} />

                                <Authorization exact Component={SupplierMarketplaceLot} Roles={["supplier"]} path={"/supplier/marketplace/deliveries/lot/:id"} />

                                <Authorization Component={AddOffer} Roles={["supplier"]} require path={"/supplier/marketplace/orders/lot/:lotId/offer/add"} />
                                <Authorization Component={EditOffer} Roles={["supplier"]} path={"/supplier/marketplace/orders/lot/:lotId/offer/:offerId/edit/"} />
                                <Authorization Component={ViewOffer} Roles={["supplier"]} path={"/supplier/marketplace/orders/lot/:id/offer/view/:id"} />
                                <Authorization Component={SupplierOrders} Roles={["supplier"]} path={"/supplier/marketplace/orders"} />
                                <Authorization Component={MySupplierOrders} Roles={["supplier"]} path={"/supplier/marketplace/my-orders"} />
                                <Authorization Component={SupplierMarketPlaceOrders} Roles={["supplier"]} path={"/supplier/marketplace/deliveries"} />

                                <Authorization Component={Reports} Permissions={['access_to_report']} path={"/reports"} />
                                <Authorization Component={ReportOrder} Roles={["supervisor"]} Permissions={['access_to_report']} require={true} path={"/report/order/:id"} />
                                <Authorization Component={ReportDeliveryOrder} Roles={["supervisor"]} Permissions={['access_to_report']} require={true} path={"/report/delivery/order/:id"} />
                                <Authorization Component={ReportContract} Roles={["supervisor"]} Permissions={['access_to_report']} require={true} path={"/report/contract/:id"} />
                                <Authorization Component={ReportControlPrice} Roles={["supervisor"]} Permissions={['control_price']} require={true} path={"/report/control/price"} />

                                <Authorization Component={OperatorLots} Roles={["operator"]} path={"/operator/offerts"} />

                                <Authorization exact Component={SupplierDiscountsAdd} Roles={["supplier"]} path={"/supplier/discount/edit"} />

                                <Authorization exact Component={SupplierDiscountsAdd} Roles={["supplier"]} path={"/supplier/discounts/add"} />
                                <Authorization exact Component={SupplierDiscounts} Roles={["supplier"]} path={"/supplier/discounts"} />

                                <Authorization exact Component={SupplierProducts} Roles={["supplier"]} path={"/supplier/catalog/products"} />

                                <Authorization exact Component={SupplierCumulativeDiscountAdd} Roles={["supplier"]} path={"/supplier/cumulativeDiscounts/edit"} />

                                <Authorization Component={SupplierCumulativeDiscountAdd} Roles={["supplier"]} path={"/supplier/cumulativeDiscounts/add"} />

                                <Authorization Component={SupplierCumulativeDiscounts} Roles={["supplier"]} path={"/supplier/cumulativeDiscounts"} />

                                <Authorization exact Component={Warnings} Roles={["admin"]} path={"/warnings"} />
                                <Authorization Component={Customers} Permissions={['access_to_companies']} path={"/customers"} />
                                <Authorization exact Component={CreateCustomer} Roles={["admin"]} path={"/customer"} />
                                <Authorization Component={Customer} Roles={["supervisor", "operator", "admin"]} path={"/customer/:id"} />
                                <Authorization Component={Suppliers} Permissions={['access_to_companies']} path={"/suppliers"} />
                                <Authorization Component={Supplier} Roles={["supervisor", "operator", "admin"]} path={"/supplier/:id"} />
                                <Authorization Component={AddOperator} Roles={["admin"]} path={"/user/operators/add"} />
                                <Authorization Component={Operators} Roles={["admin"]} path={"/user/operators"} />
                                <Authorization Component={Supervisors} Roles={["admin"]} path={"/user/supervisors"} />
                                <Authorization exact Component={Supervisor} Roles={["admin"]} path={"/user/supervisor"} />
                                <Authorization exact Component={Supervisor} Roles={["admin"]} path={"/user/supervisor/:id"} />

                                <Authorization Component={Contracts} Roles={["customer", "supplier"]} path={"/contracts"} />
                                <Authorization Component={ContractChangeStatus} Permissions={["change_contract_status"]} path={"/contract/change/status"} />
                                <Authorization Component={PastCompanies} Roles={["customer"]} path={"/contract/past"} />
                                <Authorization Component={Products} Roles={["customer"]} path={"/catalog/products"} />

                                <Authorization Component={DeliveryOrders} Roles={["customer", "supplier"]} path={"/delivery/orders"} />
                                <Authorization exact Component={DeliveryOrder} Roles={["customer", "supplier"]} path={"/delivery/order/:id"} />
                                <Authorization Component={DeliveryOrderChangeStatus} Permissions={["change_delivery_order_status"]} path={"/delivery/order/change/status"} />
                                <Authorization Component={DeliveryAutoOrders} Roles={["customer"]} path={"/delivery/auto/orders"} />
                                <Authorization Component={DeliveryAutoOrder} Roles={["customer"]} path={"/delivery/auto/order/:id"} />

                                <Authorization Component={DeliveryReclamations} Roles={["customer", "supplier"]} path={"/delivery/reclamations"} />

                                <Authorization Component={Support} Roles={["customer", "supplier", "supervisor"]} path={"/support"} />

                                <Authorization Component={Notifications} Roles={["user", "supplier", "customer", "supervisor", "guest_supplier", "customer_confirmed_by_admin", "supplier_confirmed", "supplier_confirmed_by_admin"]} path={"/notifications"} />

                                <Authorization Component={Cart} Roles={["customer"]} path={"/carts"} />
                                <Authorization Component={Cart} Roles={["customer"]} path={"/cart/:id"} />
                                <Authorization Component={DeliveryCart} Roles={["customer"]} path={"/delivery/carts"} />

                                <Authorization Component={Invoices} Roles={["supplier", "supplier_confirmed", "supplier_confirmed_by_admin"]} path={"/invoices"} />
                                <Authorization Component={Agreements} Roles={["customer", "supplier", "supplier_confirmed", "supplier_confirmed_by_admin"]} path={"/agreements"} />

                                <Authorization exact Component={Index} path={"/settings"} />
                                <Authorization Component={SettingsMarketplace} Roles={["admin"]} path={"/settings/marketplace"} />
                                <Authorization Component={SettingsOrder} Permissions={["access_to_order_settings"]} path={"/settings/order"} />
                                <Authorization Component={SettingsContract} Permissions={["access_to_contract_settings"]} path={"/settings/contract"} />
                                <Authorization Component={SettingsStandard} Permissions={["access_to_standard_settings"]} path={"/settings/standard"} />

                                <Authorization exact Component={Schedules} Roles={["customer", "supervisor"]} path={"/planning/schedules"} />
                                <Authorization exact Component={Schedule} Roles={["customer", "supervisor"]} path={"/planning/schedule/:id/edit"} />
                                <Authorization exact Component={Schedule} Roles={["customer", "supervisor"]} path={"/planning/schedule/add"} />

                                <Authorization exact Component={PurchasePlanAdd} Roles={["customer", "supervisor"]} path={"/planning/purchase-plan/:id/edit"} />
                                <Authorization exact Component={PurchasePlanAdd} Roles={["customer", "supervisor"]} path={"/planning/purchase-plan/add"} />

                                <Authorization exact Component={PurchaseProductAdd} Roles={["customer", "supervisor"]} path={"/planning/purchase-plan/:purchasePlanID/purchase-product/add"} />

                                <Authorization exact Component={PurchaseProductEdit} Roles={["customer", "supervisor"]} path={"/planning/purchase-plan/:purchasePlanID/purchase/:purchaseID/lot/:lotID/purchase-product/:key/edit"} />
                                <Authorization exact Component={PurchaseProductEdit} Roles={["customer", "supervisor"]} path={"/planning/purchase-plan/:purchasePlanID/item/:key/edit"} />
                                <Authorization exact Component={PurchaseProductAdd} Roles={["customer", "supervisor"]} path={"/planning/purchase-plan/:purchasePlanID/purchase/:purchaseID/lot/:lotID/purchase-product/add"} />
                                <Authorization exact Component={PurchasePublish} Roles={["customer", "supervisor"]} path={"/planning/purchase/:purchaseID/publish"} />
                                <Authorization exact Component={PurchaseEdit} Roles={["customer", "supervisor"]} path={"/planning/purchase/:purchaseID/edit"} />
                                <Authorization exact Component={PurchaseAdd} Roles={["customer", "supervisor"]} path={"/planning/purchase-plan/:purchasePlanID/purchase/add"} />

                                <Authorization exact Component={PurchaseLotAdd} Roles={["customer", "supervisor"]} path={"/planning/purchase/:purchaseID/lot/:lotID/edit"} />
                                <Authorization exact Component={PurchaseLotAdd} Roles={["customer", "supervisor"]} path={"/planning/purchase/:purchaseID/lot/add"} />

                                <Authorization exact Component={LotsWithProducts} Roles={["customer", "supervisor"]} path={"/planning/purchase-plan/:purchasePlanID/purchase/:purchaseID"} />
                                <Authorization exact Component={LotsWithProducts} Roles={["customer", "supervisor"]} path={"/planning/purchase-plan/purchase/:purchaseID"} />
                                <Authorization exact Component={PurchasesWithProducts} Roles={["customer", "supervisor"]} path={"/planning/purchase-plan/:purchasePlanID"} />

                                <Authorization exact Component={PlanningPurchases} Roles={["customer", "supervisor"]} path={"/planning/purchases/edit-requests"} />
                                <Authorization exact Component={PlanningPurchases} Roles={["customer", "supervisor"]} path={"/planning/purchases"} />
                                <Authorization exact Component={PlanningPurchases} Roles={["customer", "supervisor"]} path={"/planning/joint-purchases"} />

                                <Authorization exact Component={PlanningLots} Roles={["customer", "supervisor"]} path={"/planning/lots"} />
                                <Authorization exact Component={PlanningProducts} Roles={["customer", "supervisor"]} path={"/planning/products"} />

                                <Authorization Component={PurchasePlanEditRequests} Roles={["customer", "supervisor"]} path={"/planning/purchase-plans/edit-requests"} />
                                <Authorization Component={Planning} Roles={["customer", "supervisor"]} path={"/planning/purchase-plans"} />
                                <Authorization exact Component={PlanningSettings} Roles={["admin", "supervisor"]} path={"/planning/settings"} />
                                <Authorization Component={Planning} Roles={["customer", "supervisor"]} path={"/planning"} />

                                <Authorization exact Component={DictionaryStandards} Permissions={['access_to_standard']} path={"/dictionary/standards"} />
                                <Authorization exact Component={DictionarySelectedStandards} Permissions={['access_to_standard']} path={"/dictionary/standards/:type"} />

                                <Route component={NotFound} />
                            </Switch>
                        </ErrorBoundary>
                    </Router>
                </Grid>
            </Provider>
        </MuiPickersUtilsProvider>
    </ThemeProvider>,
    document.getElementById("app")
)
