import {SupplierService} from "../services/supplier"

export const SupplierActions = {
    offers,
    editOffers
}

function offers(supplierId, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SUPPLIER_OFFERS_REQUEST"})

        SupplierService.offers(supplierId, params)
            .then(
                offers => {
                    dispatch({type: "SUPPLIER_OFFERS_SUCCESS", payload: offers})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "SUPPLIER_OFFERS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function editOffers(offerId, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SUPPLIER_OFFER_REQUEST"})

        SupplierService.editOffer(offerId, values)
            .then(
                response => {
                    dispatch({
                        type: "SUPPLIER_OFFER_ITEMS_SUCCESS",
                        payload: {
                            items: response.data,
                            offerId: offerId
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_SUCCESS", payload: {
                            message: 'Предложение успешно обновлено',
                            type: 'success'
                        }
                    })
                    resolve()
                },
                error => {
                    dispatch({type: "SUPPLIER_OFFER_ITEMS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
