export default function price(state = {items: {data: [], meta: {total: 0, per_page: 15, current_page: 1}, filter: { region: '', customer: '', territory: '', supplier: '', price: { from: '', to: '' }, new: false }}, positions: {data: [], meta: {total: 0, per_page: 15, current_page: 1}}}, action) {
    switch (action.type) {
        case "REPORT_PRICE_CONTROL_ITEMS_REQUEST":
            return state
        case "REPORT_PRICE_CONTROL_ITEMS":
            return {
                ...state,
                ...{items: {...state.items, ...action.payload}},
            }
        case "REPORT_PRICE_CONTROL_ITEMS_FAILURE":
            return state
        case "REPORT_PRICE_CONTROL_ITEMS_FILTER":
            return {
                ...state,
                ...{items: {...state.items, ...{filter: action.payload}}},
            }
        case "REPORT_PRICE_CONTROL_ITEM":
            return {
                ...state,
                ...{items: {
                    ...state.items,
                    ...{data: state.items.data.map(item => {
                        if (item.id === action.payload.id) {
                            item = action.payload
                        }

                        return item
                    })}
                }}
            }
        case "REPORT_PRICE_CONTROL_ITEM_POSITIONS_REQUEST":
            return state
        case "REPORT_PRICE_CONTROL_ITEM_POSITIONS":
            return {
                ...state,
                ...{positions: action.payload}
            }
        case "REPORT_PRICE_CONTROL_ITEM_POSITIONS_FAILURE":
            return state
        default:
            return state
    }
}
