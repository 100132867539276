import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {
    Grid,
    InputAdornment,
    makeStyles,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Tooltip,
    Input,
    InputLabel,
    FormControl,
    FormControlLabel,
    Checkbox
} from "@material-ui/core"
import {Pagination} from '@material-ui/lab'
import {ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon, Error as ErrorIcon, Search as SearchIcon} from '@material-ui/icons'

import {PriceActions} from "../actions/price"
import {ButtonList} from "../../App/components/ButtonList/ButtonList"
import {taxPercentValuesProduct} from '../../App/dics/dic'
import {round} from '../../App/helpers/number'
import {ButtonInput} from '../../App/components/ButtonInput/ButtonInput'
import {ButtonSelectWithAutocomplete} from '../../App/components/ButtonSelect/ButtonSelectWithAutocomplete'
import {ButtonSelect} from '../../App/components/ButtonSelect/ButtonSelect'
import {ButtonIcon} from "../../App/components/Button/ButtonIcon"
import {Submit} from "../../App/components/Button/Submit"
import {AddProductDialog} from "./AddProductDialog"
import {StandardForm} from "../../Standard/StandardForm"
import {useDebounce} from "use-debounce";
import {SelectTree} from "../../App/components/Inputs/SelectTree";

const useStyles = makeStyles(theme => ({
    footerContainer: {
        "padding": "0 25px",
        "background-color": "#e7eaee",
        "height": "75px",
    },
    submit: {
        "width": "100%",
        "bproduct-bottom": "3px solid #898989",
        "bproduct-radius": "2px",
        "padding": "5px 10px 0",
        "min-width": "95px",
        "min-height": "35px",
        "font-size": "16px",
        "font-weight": "500",
        "color": "white",
        "outline": "0",
        "height": "35px",
        "&:disabled": {
            "background-color": "#c1c1c1",
            "bproduct-bottom-color": "#898989 !important",
            "color": "white"
        }
    },
    etName: {
        minWidth: "100px",
        maxWidth: "200px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    columnName: {
        minWidth: "100px",
        maxWidth: "210px",
    },
    rootGroup: {
        "max-height": "32px",
        "min-height": "32px",
        "width": "100%",
        "margin-bottom": "15px",
        "margin-top": "22px",
    },
    scroller: {
        "width": "100%",
        "display": "flex",
        "justify-content": "flex-end",
    },
    flexContainer: {
        "bproduct-radius": "4px",
        "bproduct": "1px solid rgba(0,0,0,.12)",
    },
    indicator: {
        "display": "none",
    },
    tableContainer: {
        "height": "calc(100vh - 300px)",
        "border-top": "5px solid #898989",
        "overflow": "auto",
    },
    textField: {
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
    },
    svg: {
        "fill": "#d9534f",
        "width": "auto",
        "height": "31px",
        "stroke": "#fff",
        "fill-rule": "evenodd",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-miterlimit": "2",
        "stroke-width": "0px",
    },
    tooltipError: {
        "font-size": "14px",
        "background": "#d9534f",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    select: {
        "&:focus": {
            "background-color": "#fff",
        },
    },
    arrow: {
        "fill": "#fff",
        "width": "auto",
        "height": "35px",
        "stroke": "#fff",
        "fill-rule": "evenodd",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-miterlimit": "2",
        "stroke-width": "2px",
    },
    arrowBlock: {
        height: "calc(100vh - 294px)",
        marginTop: "52px"
    },
    arrowButton: {
        width: "45px",
        height: "45px",
        maxHeight: "45px"
    },
    searchItemsContainer: {
        alignItems: "center",
        display: "flex",
    },
    productRow: {
        cursor: "pointer"
    },
    tableHeaderCheckbox:{
        paddingLeft: "12px",
        "& .MuiFormControlLabel-root": {
            marginLeft: 0,
        }
    },
    selectedItems: {
        paddingLeft: "12px",
        borderTop: "1px solid rgba(224, 224, 224, 1)"
    },
    tableSelectedContainer: {
        "height": "calc(100vh - 434px)",
        "overflow": "auto",
        border: "1px solid #c1c1c1",
    },
    tableName: {
        fontSize: "24px",
        fontWeight: "600",
    },
    footerTable: {
        width: "calc(100% + 2px)",
        borderBottom: "1px solid #c1c1c1",
        borderLeft: "1px solid #c1c1c1",
        borderRight: "1px solid #c1c1c1",
    },
    tableWrapper: {
        marginBottom:"10px"
    },
    footer: {
        width: "100%",
    },
    searchInput: {
        marginBottom: "0",
        marginTop: "16px !important",
    },
}));

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: "500px",
            maxWidth: "285px",
            minWidth: "285px",
            width: 250,
        },
    },
};

const tabsWithoutSelectionCategory  = [
    {
        key: 'all',
        value: 'Все'
    },
    {
        key: 'without_standard',
        value: 'Не сопоставлено'
    },
    {
        key: 'error',
        value: 'Ошибки'
    }
]

const tabsWithSelectionCategory = [
    {
        key: 'all',
        value: 'Все'
    },
    {
        key: 'without_selection',
        value: 'Загруженные позиции'
    },
    {
        key: 'without_standard',
        value: 'Не сопоставлено'
    },
    {
        key: 'error',
        value: 'Ошибки'
    }
]

export const ProductList = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles();
    const {activePrice} = useSelector(state => state.prices)
    const {priceId, open, supplierId, withContract} = props;
    const categories = props.categories;
    const isMountedRef = useRef(null);
    const {products, productsWithCategory} = useSelector(state => state.products)
    const [tabValue, setTab] = useState(0)
    const [status, setStatus] = useState('all')
    const tableRef = useRef(null)
    const [selectedItems, setSelectedItems] = useState(0)
    const [selectedItemsWithCategory, setSelectedItemsWithCategory] = useState(0)

    const defaultColumns = [
        {
            id: 'article_number',
            label: 'Артикул продукта',
            format: (value) => value.toLocaleString(),
        },
        {
            id: 'batch',
            label: 'Серия',
            format: (value) => value.toLocaleString(),
        },
        {
            id: 'category_id',
            label: 'Категория',
            format: (value) => value.toLocaleString(),
        },
        {
            id: 'name',
            label: 'Название продукта',
            format: (value) => value.toLocaleString(),
        },
        {
            id: 'standard_name',
            label: 'Эталонное наименование',
            format: (value) => value.toLocaleString(),
        },
        {
            id: 'description',
            label: 'Описание продукта',
            format: (value) => value.toLocaleString(),
        },
        {
            id: 'stock_quantity',
            label: 'Остатки',
            format: (value) => value.toLocaleString(),
        },
        {
            id: 'unit',
            label: 'Единицы измерения',
            format: (value) => value.toLocaleString(),
        },
        {
            id: 'price_without_vat',
            label: 'Цена без НДС',
            format: (value) => value.toLocaleString(),
        },
        {
            id: 'tax_percent',
            label: 'Ставка НДС',
            value: 'Ставка НДС',
            format: (value) => value.toLocaleString(),
        },
        {
            id: 'producer',
            label: 'Производитель',
            format: (value) => value.toLocaleString(),
        },
        {
            id: 'expiration_date',
            label: 'Срок годности',
            format: (value) => value.toLocaleString(),
        },
        {
            id: 'min_ordered_quantity',
            label: 'Минимальное количество для заказа',
            format: (value) => value.toLocaleString(),
        },
        ...(withContract ? [
            {
                id: 'can_be_ordered',
                label: 'Под заказ',
                format: (value) => value.toLocaleString(),
            }
        ] : []),
];

    let columns = status !== 'all' ? defaultColumns.filter(el => el.id !== 'can_be_ordered') : defaultColumns
    const [mappingColumns, setMappingColumns] = useState(columns.map(el => el.id ))

    const valuesButtonList = (withContract && activePrice) ? tabsWithSelectionCategory : tabsWithoutSelectionCategory

    const [selectedWithCategory, setSelectedWithCategory] = useState([]);
    const [selectedWithoutCategory, setSelectedWithoutCategory] = useState([]);

    const [totalWithCategory, setTotalWithCategory] = useState(null);
    const [totalWithoutCategory, setTotalWithoutCategory] = useState(null);

    const [search, setSearch] = useState('')
    const [searchRequest] = useDebounce(search, 900)

    const [selectedCategory, setSelectedCategory] = useState(null);

    const [openAddProduct, setOpenAddProduct] = useState(false);

    const [openStandardForm, setOpenStandardForm] = useState(false)

    const closeAddProductDialog = () => {
        setOpenAddProduct(false)
        getProducts().then(() => {})
    }

    const change = (name, value, productId) => {
        if ((name === 'standard_name') && (value === 'button')) {
            setOpenStandardForm(productId)
        }
        if ((name === 'standard_name') && (value === 'reset_category')) {
            return dispatch(PriceActions.unsetStandardInfo(productId)).then(
                () => {
                    getProducts().then(() => {})
                },
                () => {}
            )
        }
    }

    const handleChangeMappingColumn = (e) => {
        setMappingColumns(e.target.value)
    };

    const closeStandardForm = () => {
        setOpenStandardForm(false)
        getProducts().then(() => {})
    }

    const update = (id, name, val) => {
        let params = {
            [name]: val,
        }
        if (supplierId) {
            params = {
                ...params,
                supplier_id: supplierId,
            }
        }
        return dispatch(PriceActions.updateProduct(id, params))
    }

    const updateSelect = (id, name, val) => {
        let params = {
            [name]: val,
        }
        if (supplierId) {
            params = {
                ...params,
                supplier_id: supplierId,
            }
        }
        return dispatch(PriceActions.updateProduct(id, params))
    }

    const cancelValue = () => {
    }

    const renderColumn = (columnName, product) => {
        let nameColumnsError = []
        let error_messages = JSON.parse(product.error_message);
        if (error_messages && error_messages.length > 0) {
            nameColumnsError = error_messages.map(el => el.column)
        }

        switch (columnName) {
            case 'expiration_date':
                return !!product[columnName]
                    ? (
                        <TextField
                            type="date"
                            defaultValue={product[columnName]}
                            className={classes.textField}
                            disabled={true}
                            fullWidth={true}
                        />
                    )
                    : ((nameColumnsError.indexOf(columnName) !== -1) ? (
                        <Tooltip
                            classes={{
                                tooltip: classes.tooltipError
                            }}
                            title="Поле пустое или заполнено неверно"
                            placement="top-start">
                            <div className={classes.defaultError}>
                                {product[columnName]}<ErrorIcon key={columnName} className={classes.svg}/>
                            </div>
                        </Tooltip>
                    ) : null
                )
            case 'category_id':
                return !!product[columnName] ? categories.filter(item => item.id === product[columnName])[0].name : null
            case 'unit':
                return (((nameColumnsError.indexOf(columnName) !== -1) || (nameColumnsError.indexOf('unit_id') !== -1))
                    ? (
                        <Tooltip
                            classes={{
                                tooltip: classes.tooltipError
                            }}
                            title="Поле пустое или заполнено неверно"
                            placement="top-start">
                            <div className={classes.defaultError}>
                                <ErrorIcon key={columnName} className={classes.svg}/>
                            </div>
                        </Tooltip>
                    )
                    : getColumn(columnName, product))
            default:
                return ((nameColumnsError.indexOf(columnName) !== -1)
                    ? (
                        <Tooltip
                            classes={{
                                tooltip: classes.tooltipError
                            }}
                            title="Поле пустое или заполнено неверно"
                            placement="top-start">
                            <div className={classes.defaultError}>
                                {product[columnName]}<ErrorIcon key={columnName} className={classes.svg}/>
                            </div>
                        </Tooltip>
                    )
                    : getColumn(columnName, product))
        }
    }

    const handleUpdateCanBeOrdered = (e, id, name) => {
        let params = {
            [name]: e.target.checked,
        }
        if (supplierId) {
            params = {
                ...params,
                supplier_id: supplierId,
            }
        }
        return dispatch(PriceActions.updateProduct(id, params)).then(() => {
            getProducts().then(() => {})
        })
    }

    const getColumn = (columnName, product) => {
        switch (columnName) {
            case 'stock_quantity':
                return (status === 'all') ? (
                    <ButtonInput
                        pattern={/[^\d.]/g}
                        change={change}
                        onUpdate={update}
                        onCancel={cancelValue}
                        propId={product.id}
                        key={product.name}
                        name={columnName}
                        defaultValue={round(product[columnName], 2)}
                    />
                ) : (<div className={classes.default}>{product[columnName] ? round(product[columnName], 2) : null}</div>)
            case 'tax_percent':
                return (status === 'all') ? (
                    <ButtonSelect
                        pattern={/^[0-9]+$/}
                        change={change}
                        onUpdate={updateSelect}
                        onCancel={cancelValue}
                        propId={product.id}
                        key={product.name}
                        name={columnName}
                        defaultValue={product.tax_percent}
                        selectItems={taxPercentValuesProduct}
                    />
                ) : (<div className={classes.default}>{taxPercentValuesProduct.filter(el => el.key === product.tax_percent)[0].value}</div>)
            case 'price_without_vat':
                return (status === 'all') ? (
                    <ButtonInput
                        pattern={/[^\d.]/g}
                        change={change}
                        onUpdate={update}
                        onCancel={cancelValue}
                        propId={product.id}
                        key={product.name}
                        name={columnName}
                        defaultValue={round(product[columnName], 2)}
                    />
                ) : (<div className={classes.default}>{product[columnName] ? round(product[columnName], 2) : null}</div>)
            case 'min_ordered_quantity':
                return (status === 'all') ? (
                    <ButtonInput
                        pattern={/^[0-9]+$/}
                        change={change}
                        onUpdate={update}
                        onCancel={cancelValue}
                        propId={product.id}
                        key={product.name}
                        name={columnName}
                        defaultValue={round(product[columnName] ?? 0, 2)}
                    />
                ) : (<div className={classes.default}>{product[columnName] ? round(product[columnName], 2) : null}</div>)
            case 'can_be_ordered':
                return (status === 'all') ? (
                    <Checkbox
                        disabled={Boolean(!withContract)}
                        checked={product[columnName]}
                        onChange={(e) => handleUpdateCanBeOrdered(e, product.id, columnName)}
                        key={product.id}
                    />
                ) : null
            case 'standard_name':
                let suggested_names = JSON.parse(product.suggested_names);
                const suggestedNames = suggested_names ? suggested_names.map(function (el) {
                    return {
                        id: el.id, name: el.name, category: el.category, confirmed: el.confirmed, assembly: el.assembly, generic: el.generic, values: el.values
                    }
                }) : [];
                if (activePrice && activePrice.hasOwnProperty('status') && ['done', 'done_with_error'].indexOf(activePrice.status) !== -1) {
                    suggestedNames.push({id: 'button', name: 'Предложить эталон'})
                }
                let value = null;

                if (product.standard && suggested_names) {
                    suggested_names.forEach(standard => {
                        if (standard.id === product.standard?.id) {
                            value = standard;
                        }
                    })
                }
                if (suggested_names && suggested_names.length) {
                    suggested_names.push({id: 0, name: 'Не выбрано'})
                }

                return (['without_standard', 'all'].indexOf(status) !== -1) ? (
                    <ButtonSelectWithAutocomplete
                        change={change}
                        search={true}
                        onUpdate={(id, name, item) => {
                            if (item && (item.id === 'button')) {
                                return;
                            }

                            let params = !item ? {
                                category_id: null,
                                name: null,
                                typed: null,
                                standard_id: null,
                                confirmed: null,
                                generic: null
                            } : {
                                category_id: item.category.id,
                                name: item.name,
                                typed: item.assembly.find(value => value.type.key === 'typed')?.value,
                                standard_id: item.id,
                                confirmed: item.confirmed,
                                generic: (item.hasOwnProperty('generic') ? (item.generic ?? null) : (item.values.find(value => {
                                    return value.attribute.value === 'generics'
                                })?.value ?? null))
                            }

                            if (supplierId) {
                                params = {
                                    ...params,
                                    supplier_id: supplierId,
                                }
                            }

                            return dispatch(PriceActions.setStandard(id, params)).then(() => {
                                getProducts().then(() => {})
                            })
                        }}
                        onCancel={cancelValue}
                        propId={product.id}
                        key={product.name}
                        category={product.category}
                        name={columnName}
                        value={value}
                        style={{maxWidth: "100px"}}
                        items={suggestedNames}
                    />
                ) : (<div className={classes.default}>{product[columnName]}</div>)
            case 'name':
                return (<div className={classes.columnName}>{product[columnName]}</div>)
            case 'unit':
                return (<div className={classes.columnName}>{product.unit ? product.unit.short : ''}</div>)
            default:
                return (<div className={classes.etName}>{product[columnName]}</div>)
        }
    }

    const tabChange = (ev, newValue) => {
        setTab(newValue);
        setStatus(valuesButtonList[newValue].key);
        setSelectedItems(0)
        setSearch(null);
    }

    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)

    const countOnPageAr = [5, 10, 25, 100]
    const countOnPageNodes = countOnPageAr.map((item, index) => <MenuItem value={index}>{item}</MenuItem>)

    const changeLimitOnPage = (event) => {
        if (products && products.meta.total < countOnPageAr[event.target.value]) {
            setPage(1);
        }
        setLimit(countOnPageAr[event.target.value]);
    }

    const changePage = (event, page) => {
        setPage(page);
    }

    const [limitProductsWithCategory, setLimitProductsWithCategory] = useState(10)
    const [pageProductsWithCategory, setPageProductsWithCategory] = useState(1)

    const countOnPageNodesProductsWithCategory = countOnPageAr.map((item, index) => <MenuItem value={index}>{item}</MenuItem>)

    const changeLimitOnPageProductsWithCategory = (event) => {
        if (productsWithCategory && productsWithCategory.meta.total < countOnPageAr[event.target.value]) {
            setPageProductsWithCategory(1);
        }
        setLimitProductsWithCategory(countOnPageAr[event.target.value]);
    }

    const changePageProductsWithCategory = (event, page) => {
        setPageProductsWithCategory(page);
    }

    const getProducts = async () => {
        let params = {
            id: priceId,
            limit: limit,
            page: page,
            status: status,
            ...(searchRequest ? {search: searchRequest} : {}),
            ...(withContract ? {withContract: true} : {}),
        }
        await dispatch(PriceActions.getProducts(params))
    }

    useEffect(() => {
        isMountedRef.current = true;
        if (open) {
            if (isMountedRef.current) {
                if (status !== 'without_selection') {
                    defaultSelected();
                }
                getProducts().then(() => {
                    tableRef.current && tableRef.current.scrollIntoView({ behavior: 'smooth' })
                })
            }
        } else {
            dispatch({type: "PRODUCT_RESET"});
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [dispatch, page, limit, priceId, open, status, searchRequest])

    const getProductsWithCategory = async () => {
        let params = {
            id: priceId,
            limit: limitProductsWithCategory,
            page: pageProductsWithCategory,
            status: status,
            category_id: selectedCategory,
        }
        await dispatch(PriceActions.getProductsWithCategory(params))
    }

    useEffect(() => {
        isMountedRef.current = true;
        if (open && (status === 'without_selection') && selectedCategory) {
            if (isMountedRef.current) {
                getProductsWithCategory().then(() => {})
            }
        } else {
            dispatch({type: "PRODUCT_RESET"});
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [dispatch, pageProductsWithCategory, limitProductsWithCategory, priceId, open, status, selectedCategory])

    const handleSelectAllWithCategoryClick = (e) => {
        selectAll(e, productsWithCategory, setSelectedWithCategory, setSelectedItemsWithCategory, setTotalWithCategory)
    };

    const handleSelectAllWithoutCategoryClick = (e) => {
        selectAll(e, products, setSelectedWithoutCategory, setSelectedItems, setTotalWithoutCategory)
    };

    const selectAll = (e, products, setSelected, setSelectedIt, setTotal) => {
        if (e.target.checked && products && products.data.length > 0) {
            const newSelecteds = products.data.map(el => el.id);
            setSelected(newSelecteds);
            setSelectedIt(newSelecteds.length);
            setTotal(null);
            return;
        }
        setSelected([]);
        setSelectedIt(0);
    }

    const defaultSelected = () => {
        setSelectedWithCategory([]);
        setSelectedWithoutCategory([]);
        setTotalWithoutCategory(null);
        setTotalWithCategory(null);
    }

    const selectTotal = (e, products, setSelected, setTotal, setCountItems) => {
        if (e.target.checked && products && products.data.length > 0) {
            const newSelecteds = products.data.map(el => el.id);
            setSelected(newSelecteds);
            setTotal(products.meta.total);
            setCountItems(products.meta.total);
            return;
        }
        setSelected([]);
        setTotal(0);
        setCountItems(0);
    }

    const handleSelectTotalWithCategoryClick = (e) => {
        selectTotal(e, productsWithCategory, setSelectedWithCategory, setTotalWithCategory, setSelectedItemsWithCategory)
    };

    const handleSelectTotalWithoutCategoryClick = (e) => {
        selectTotal(e, products, setSelectedWithoutCategory, setTotalWithoutCategory, setSelectedItems)
    };

    const handleClickWithCategory = (e, id) => {
        clickRow(id, selectedWithCategory, setSelectedWithCategory, setSelectedItemsWithCategory, setTotalWithCategory)
    };

    const clickRow = (id, selected, setSelected, setCountSelected, setTotal) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selectedWithCategory - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setTotal(null)
        setCountSelected(newSelected.length)
        setSelected(newSelected);
    }

    const handleClickWithoutCategory = (e, id) => {
        clickRow(id, selectedWithoutCategory, setSelectedWithoutCategory, setSelectedItems, setTotalWithoutCategory)
    };

    const isSelectedWithoutCategory = (id) => selectedWithoutCategory.indexOf(id) !== -1;

    const isSelectedWithCategory = (id) => selectedWithCategory.indexOf(id) !== -1;

    const getRenderFooter = (products, countOnPageAr, limit, countOnPageNodes, changeLimitOnPage, changePage, isTableFooter = false) => {
        return (
            <Grid item className={isTableFooter ? classes.footerTable : classes.footer}>
                <StandardForm
                    open={openStandardForm}
                    module={'Ордер'}
                    onCLose={closeStandardForm}
                    setOpen={setOpenStandardForm}
                    onAddStandard={(item) => {
                        let params = {
                            category_id: item.category.id,
                            name: item.name,
                            standard_id: item.id,
                            generic: item.hasOwnProperty('generic') && item.generic ? item.generic : null
                        }
                        if (supplierId) {
                            params = {
                                ...params,
                                supplier_id: supplierId,
                            }
                        }
                        return dispatch(PriceActions.setStandard(openStandardForm, params)).then(
                            () => {
                                setOpenStandardForm(false)
                                getProducts().then(() => {})
                            },
                            () => {}
                        )
                    }}
                />
                {(products && products.hasOwnProperty('meta')) ? (
                    <Grid container direction="row" justify="space-between" alignItems="center" className={classes.footerContainer}>
                        <Grid item>
                            <Grid container direction="row" justify="flex-start" alignItems="center">
                                <Grid item>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Typography>Записей на странице:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Select
                                                labelId="countOnPage"
                                                id="countOnPage"
                                                classes={{
                                                    select: classes.select
                                                }}
                                                value={countOnPageAr.indexOf(limit)}
                                                onChange={changeLimitOnPage}
                                                children={countOnPageNodes}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography>{products.meta.from ? products.meta.from : 1} - {products.meta.to} &nbsp;из {products.meta.total}</Typography>
                                </Grid>
                                <Grid item>
                                    <Pagination
                                        page={products.meta.current_page}
                                        count={products.meta.last_page}
                                        className={classes.pagination}
                                        onChange={changePage}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {!isTableFooter &&
                            <Grid item>
                                <Grid container justify={'space-between'} spacing={2}>
                                    {status === 'all' && withContract &&
                                        <React.Fragment>
                                            <Grid item>
                                                <Submit
                                                    className={classes.submit}
                                                    disableElevation
                                                    disabled={products.data.length === 0}
                                                    variant="contained"
                                                    color="primary"
                                                    type="button"
                                                    onClick={() => {
                                                        return dispatch(PriceActions.setCanBeOrdered({
                                                            product_ids: products.data.map(el => el.id),
                                                            can_be_ordered: false,
                                                        })).then(() => {
                                                            getProducts().then(() => {})
                                                        })
                                                    }}
                                                >
                                                    Снять выделение
                                                </Submit>
                                            </Grid>
                                            <Grid item>
                                                <Submit
                                                    className={classes.submit}
                                                    disableElevation
                                                    disabled={products.data.length === 0}
                                                    variant="contained"
                                                    color="primary"
                                                    type="button"
                                                    onClick={() => {
                                                        return dispatch(PriceActions.setCanBeOrdered({
                                                            product_ids: products.data.map(el => el.id),
                                                            can_be_ordered: true,
                                                        })).then(() => {
                                                            getProducts().then(() => {})
                                                        })
                                                    }}
                                                >
                                                    Выделить все
                                                </Submit>
                                            </Grid>
                                        </React.Fragment>
                                        }
                                    <Grid item>
                                        <Submit
                                            className={classes.submit}
                                            disableElevation
                                            variant="contained"
                                            color="primary"
                                            type="button"
                                            onClick={() => {
                                                setOpenAddProduct(true)
                                            }}
                                        >
                                            Добавить товар
                                        </Submit>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                ) : null}
            </Grid>
        )
    }

    const getAllLabel = () => {
        return `Выделить все товары на странице`
    }

    const getTotalLabel = () => {
        return `Выделить все товары в прайс-листе`
    }

    return (
        <Grid container className={classes.fullHeight} direction="column" justify="space-between" alignItems="stretch">
            {openAddProduct && <AddProductDialog categories={categories} open={openAddProduct} onClose={closeAddProductDialog} mode={'create'}/>}
            <Grid container spacing={0} justify={status !== 'without_selection' ? "space-between" : "flex-end"} style={{marginRight: "72px"}}>
                {(status !== 'without_selection') ? (
                    <Grid item xs={6}>
                        <Grid container justify={"flex-start"} spacing={2}>
                            <Grid item>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="mappingColumn" style={{fontSize: "18px", color: "#898989"}}>Колонки</InputLabel>
                                    <Select
                                        labelId="mappingColumn"
                                        id="mappingColumn"
                                        multiple
                                        classes={{
                                            select: classes.select,
                                        }}
                                        value={mappingColumns}
                                        onChange={handleChangeMappingColumn}
                                        input={<Input/>}
                                        MenuProps={MenuProps}
                                    >
                                        {columns.map((obj) => (
                                            <MenuItem key={obj.id} value={obj.id}>{obj.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {(status !== 'without_selection') ? (
                                <Grid item className={classes.searchItemsContainer} xs={5}>
                                    <TextField
                                        id="search"
                                        variant="standard"
                                        name={'search'}
                                        placeholder={'Поиск по наименованию'}
                                        fullWidth={true}
                                        className={classes.searchInput}
                                        margin={'normal'}
                                        onChange={(e) => {
                                            setSearch(e.target.value)
                                        }}
                                        value={search}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchIcon style={{color: '#c1c1c1'}}/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                ) : null}
                <Grid item>
                    <ButtonList
                        classes={{
                            root: classes.rootGroup,
                            flexContainer: classes.flexContainer,
                            scroller: classes.scroller,
                            indicator: classes.indicator,
                        }}
                        value={tabValue}
                        values={valuesButtonList}
                        onChange={tabChange}
                    />
                </Grid>
            </Grid>
            {(status !== 'without_selection') ? (
                <React.Fragment>
                    <TableContainer className={classes.tableContainer}>
                        <Table ref={tableRef} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.filter(el => mappingColumns.indexOf(el.id) !== -1).map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{minWidth: column.minWidth}}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(products && products.hasOwnProperty('data')) ? products.data.map((product) => {
                                    return (
                                        <TableRow
                                            className={classes.tableRow}
                                            key={product.id}
                                        >
                                            {columns.filter(el => mappingColumns.indexOf(el.id) !== -1).map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{minWidth: column.minWidth}}
                                                >
                                                    {renderColumn(column.id, product)}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                }) : null}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {getRenderFooter(products, countOnPageAr, limit, countOnPageNodes, changeLimitOnPage, changePage)}
                </React.Fragment>
            ) : null}
            {(status === 'without_selection') ? (
                <Grid container justify={'center'}>
                    <Grid item xs={5}>
                        <Grid container direction={'row'} alignItems={'flex-end'} style={{marginTop: "14px"}} className={classes.tableWrapper}>
                            <Grid item xs={3}>
                                <Typography className={classes.tableName}>Без категории</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    id="search"
                                    variant="standard"
                                    name={'search'}
                                    placeholder={'Поиск по наименованию'}
                                    fullWidth={true}
                                    margin={'normal'}
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon style={{color: '#c1c1c1'}}/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <TableContainer className={classes.tableSelectedContainer}>
                            <Table ref={tableRef} className={classes.table} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <FormControlLabel control={
                                                <Checkbox
                                                    checked={Boolean(products && selectedItems > 0 && totalWithoutCategory === null && selectedItems === products.data.length)}
                                                    onChange={handleSelectAllWithoutCategoryClick}
                                                    inputProps={{'aria-label': 'select all'}}
                                                />
                                            } label={getAllLabel(selectedWithoutCategory, totalWithoutCategory, products)}
                                                              className={classes.tableHeaderCheckbox}/>
                                            <FormControlLabel control={
                                                <Checkbox
                                                    checked={!!totalWithoutCategory}
                                                    onChange={handleSelectTotalWithoutCategoryClick}
                                                    inputProps={{'aria-label': 'select all'}}
                                                />
                                            } label={getTotalLabel(totalWithoutCategory)}
                                                              className={classes.tableHeaderCheckbox}/>
                                            <Typography className={classes.selectedItems}>Выделено товаров: {selectedItems}</Typography>
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {products && products.hasOwnProperty('data') && products.data.map((product) => {
                                        const isItemSelected = isSelectedWithoutCategory(product.id);
                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleClickWithoutCategory(event, product.id)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                className={classes.productRow}
                                                tabIndex={-1}
                                                key={product.id}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                    />
                                                    {product.name}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {getRenderFooter(products, countOnPageAr, limit, countOnPageNodes, changeLimitOnPage, changePage, true)}
                    </Grid>
                    <Grid item xs={1}>
                        <Grid container direction={'column'} alignItems={'center'} justify={'center'} className={classes.arrowBlock} spacing={2}>
                            <Grid item>
                                <ButtonIcon
                                    disabled={!(selectedWithoutCategory.length && selectedCategory)}
                                    onClick={() => {
                                        return dispatch(PriceActions.setCategory({
                                            product_ids: totalWithoutCategory ? 'all' : selectedWithoutCategory,
                                            price_id: priceId,
                                            category_id: selectedCategory
                                        })).then(() => {
                                            setSelectedWithoutCategory([]);
                                            setTotalWithoutCategory(null);
                                            setSelectedItems(0);
                                            getProducts().then(() => {
                                                getProductsWithCategory().then(() => {
                                                })
                                            })
                                        })
                                    }}
                                    className={classes.arrowButton}
                                    key={'toArrow'}
                                    children={[(
                                        <ArrowForwardIcon
                                            key={'ArrowForwardIcon'}
                                            className={classes.arrow}/>)]}/>
                            </Grid>
                            <Grid item>
                                <ButtonIcon
                                    disabled={!selectedWithCategory.length > 0}
                                    onClick={() => {
                                        return dispatch(PriceActions.unsetCategory({
                                            product_ids: totalWithCategory ? 'all' : selectedWithCategory,
                                            price_id: priceId
                                        })).then(() => {
                                                setSelectedItemsWithCategory(0);
                                                setSelectedWithCategory([]);
                                                setTotalWithCategory(null);
                                                getProductsWithCategory().then(() => {
                                                    getProducts().then(() => {
                                                    })
                                                })
                                            }
                                        )
                                    }}
                                    className={classes.arrowButton}
                                    key={'backArrow'}
                                    children={[(
                                        <ArrowBackIcon
                                            key={'ArrowBackIcon'}
                                            className={classes.arrow}/>)]}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid item>
                            <Grid container direction={'row'} alignItems={'flex-end'} className={classes.tableWrapper}>
                                <Grid item xs={8} >
                                    <Typography className={classes.tableName}>Выберите категорию</Typography>
                                    <SelectTree
                                        options={categories.map(category => ({
                                            ...category,
                                            parent: category.category
                                        }))}
                                        value={selectedCategory}
                                        onChange={(value) => {
                                            const category = categories.find(item => item.id === value)
                                            setSelectedCategory(category?.id)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <TableContainer className={classes.tableSelectedContainer}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={selectedItemsWithCategory > 0 && totalWithCategory === null && productsWithCategory.hasOwnProperty('data') && selectedItemsWithCategory === productsWithCategory.data.length}
                                                        onChange={handleSelectAllWithCategoryClick}
                                                        inputProps={{'aria-label': 'select all'}}
                                                    />
                                                }
                                                label={getAllLabel(selectedWithCategory, totalWithCategory, productsWithCategory)}
                                                className={classes.tableHeaderCheckbox}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={!!totalWithCategory}
                                                        onChange={handleSelectTotalWithCategoryClick}
                                                        inputProps={{'aria-label': 'select all'}}
                                                    />
                                                }
                                                label={getTotalLabel(totalWithCategory)}
                                                className={classes.tableHeaderCheckbox}
                                            />
                                            <Typography className={classes.selectedItems}>Выделено товаров: {selectedItemsWithCategory}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {productsWithCategory && productsWithCategory.hasOwnProperty('data') && productsWithCategory.data.map((product) => {
                                        const isItemSelected = isSelectedWithCategory(product.id);
                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleClickWithCategory(event, product.id)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={product.name}
                                                className={classes.productRow}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox checked={isItemSelected} />{product.name}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {getRenderFooter(productsWithCategory, countOnPageAr, limitProductsWithCategory, countOnPageNodesProductsWithCategory, changeLimitOnPageProductsWithCategory, changePageProductsWithCategory, true)}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                            className={classes.footerContainer}>
                            <Grid item>
                                <Submit
                                    className={classes.submit}
                                    disableElevation
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    onClick={() => {
                                        setOpenAddProduct(true)
                                    }}
                                >
                                    Добавить товар
                                </Submit>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : null}
        </Grid>
    );
}
