import React from "react"
import {useDispatch} from "react-redux"
import {Field, Form, Formik} from "formik"
import * as Yup from "yup"

import {Grid, InputAdornment, makeStyles, Tooltip, IconButton} from "@material-ui/core"
import {AddShoppingCart as AddShoppingCartIcon} from "@material-ui/icons"

import {TextFieldWithError as CustomTextField} from "../../../../App/components/Inputs/TextFieldWithError"
import {ItemActions} from "../../../actions/cart/items"
import {CartActions} from "../../../actions/cart"
import {TooltipContent} from "./TooltipContent"
import {getExpirationDateParams} from "./Helpers/params"

const useStyles = makeStyles(() => ({
    content: {
        "width": "calc(100% - 300px)",
        "padding": "30px 60px"
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 192px)"
    },
    input: {
        "width": "100%",
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "text-decoration": "none"
    },
    tableRowProduct: {
        '& > *': {
            "border-bottom": 'unset'
        },
        "cursor": "pointer"
    },
    tableRowLast: {
        '& > *': {
            "border-bottom": "0"
        }
    },
    tableRowAlert: {
        "cursor": "pointer",
        "background-color": "#f9f1f0",
        "text-decoration": "none"
    },
    amount: {
        "min-width": "100px"
    },
    lightTooltip: {
        "font-size": "0.8rem",
        "backgroundColor": "white",
        "color": "rgba(0, 0, 0, 0.87)",
        "border": "1px solid #ededed"
    },
    productName: {
        maxWidth: "400px",
    }
}))

export const AddToCart = ({item, params, account, cart}) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    let notHaveSearchParams = (!params.category || !(params.expirationDate || params.expirationDatePercent) || !params.postponement || !params.find)

    const getInitValues = () => {
        return {
            amount: 1,
        }
    }

    const getValidationSchema = (item) => {
        return Yup.object().shape({
            amount: Yup.number()
                .required("Поле не может быть пустым или состоять из одних пробелов!")
                .max(
                    item.count ? item.count - ((cart && cart.items.find(el => parseInt(el.standard_id) === parseInt(item.id))) ? cart.items.find(el => parseInt(el.standard_id) === parseInt(item.id)).amount : 0) : 0,
                    "Превышено максимальное число для заказа"
                )
        })
    }

    const addToCart = (values, {setSubmitting}, item) => {
        return dispatch(ItemActions.add({
            ...values,
            ...{
                item: item
            },
            ...getExpirationDateParams(params, params.expirationDateType),
            ...{
                postponement: params.postponement,
                category: params.category
            }
        })).then(
            () => {
                dispatch({type: 'CART_ITEMS_COUNT_LOADING', payload: false})
                dispatch(CartActions.carts())
                setSubmitting(false);
            },
            () => {
                setSubmitting(false);
            }
        )
    }

    return (
        <Formik
            initialValues={getInitValues()}
            validationSchema={getValidationSchema(item)}
            onSubmit={(values, {setSubmitting}) => addToCart(values, {setSubmitting}, item)}
        >
            {() => (
                <Form>
                    <Grid container direction="row" justify="flex-end" alignItems="center">
                        <Grid item xs={3} className={classes.amount}>
                            <Field
                                fullWidth
                                name="amount"
                                type="number"
                                inputProps={{
                                    step: 0.01,
                                    min: 1
                                }}
                                endAdornment={item.unit ? <InputAdornment position="end">{item.unit.short}</InputAdornment> : null}
                                component={CustomTextField}
                            />
                        </Grid>
                        <Grid item>
                            {notHaveSearchParams ?
                                <Tooltip
                                    classes={{tooltip: classes.lightTooltip}}
                                    title={
                                        <Grid container direction="column" justify="center" alignItems="center" style={{ "height": "calc(100% - 100px)" }}>
                                            <Grid item>
                                                <TooltipContent params={params}/>
                                            </Grid>
                                        </Grid>
                                    }
                                    arrow
                                    placement="left"
                                >
                                        <span>
                                            <IconButton
                                                color="primary"
                                                aria-label="Добавить в корзину"
                                                type="submit"
                                                disabled={(notHaveSearchParams || account.company.settingsMarketplace.show_suppliers)}
                                            >
                                                <AddShoppingCartIcon/>
                                            </IconButton>
                                        </span>
                                </Tooltip>
                                :
                                <IconButton
                                    color="primary"
                                    aria-label="Добавить в корзину"
                                    type="submit"
                                    disabled={account.company.settingsMarketplace.show_suppliers}
                                >
                                    <AddShoppingCartIcon />
                                </IconButton>
                            }
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    )
}
