export default function products(state = {products: []}, action) {
    switch (action.type) {
        case "GET_PRODUCTS_SUCCESS":
            return {
                products: action.payload
            }
        case "GET_PRODUCTS_FAILURE":
            return state
        case "PRODUCTS_CLEAR":
            return {
                products: []
            }
        default:
            return state

    }
}
