import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import DateFnsUtils from "@date-io/date-fns"
import format from "date-fns/format"
import {ru} from "date-fns/locale"

import {Grid, Link, List, ListItem, ListItemText, makeStyles, Typography, withStyles} from '@material-ui/core'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"

import * as AppStorage from "../../../App/helpers/storage"

const useStyles = makeStyles(() => ({
    item: {
        "padding": "16px",
        "border-bottom": "1px solid #e4e4e4"
    },
    history: {
        "padding": "8px 16px 0 16px",
        'border-bottom': "1px solid #e4e4e4"
    },
    historyItem: {
        "& .MuiListItemText-primary" : {
            "text-transform": "none !important"
        }
    },
    title: {
        "font-size": "18px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    list: {
        "overflow": "auto",
        "height": "calc(100vh - 200px)"
    },
    date: {
        width: "115px",
        "& .MuiInputAdornment-root .MuiButtonBase-root": {
            padding: 0,
        }
    }
}))

const CustomList = withStyles({
    root: {
        "padding": "0",
        "& .MuiListItem-root": {
            "cursor": "default",
            "align-items": "center",
            "min-height": "50px",
            "padding": "0 16px",
            "border-bottom": "1px solid #e4e4e4",
            "outline": "0"
        },
        "& .MuiListItemText-primary": {
            "font-size": "16px",
            "font-weight": "700",
            "text-transform": "uppercase"
        },
        "& .MuiListItem-button:hover": {
            "background-color": "rgba(0,0,0,.04)",
            "&:after": {
                "position": "absolute",
                "content": "''",
                "top": "0",
                "left": "0",
                "width": "100%",
                "height": "100%",
                "background-color": "rgba(0,0,0,.04)"
            }
        }
    }
})(List);

export const Menu = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { order } = useSelector(state => state.delivery)

    const [beginning, setBeginning] = useState(null)
    const [ending, setEnding] = useState(new Date())
    const [log, setLog] = useState([]);

    useEffect(() => {
        if (order) {
            setLog(order.log.filter(item => {
                const from = new Date(beginning)
                const to = new Date(ending)
                const created = new Date(item.created)
                ending.setHours(23,59,59,999);

                return from.valueOf() <= created.valueOf() && created.valueOf() <= to.valueOf()
            }))
        }
    }, [dispatch, beginning, ending, order]);

    useEffect(() => {
        if (order) {
            setBeginning(new Date(order.created))
            setLog(order.log)
        }
    }, [order]);

    return (
        <Grid container className={classes.container} direction="column" justify="flex-start" alignItems="stretch">
            <Grid item className={classes.item}>
                <Grid container className={classes.container} direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                    <Grid item>
                        <Typography variant="overline" gutterBottom className={classes.title}>История заказа:</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container className={classes.container} direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                    <KeyboardDatePicker
                                        className={classes.date}
                                        fullWidth
                                        invalidDateMessage="Некорректная дата"
                                        minDate={new Date("1970-01-01")}
                                        minDateMessage={""}
                                        disableToolbar
                                        orientation="landscape"
                                        variant="inline"
                                        openTo="year"
                                        name="beginning"
                                        format="dd-MM-yyyy"
                                        autoOk
                                        required
                                        label={'Дата события с'}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={beginning}
                                        onChange={date => {
                                            setBeginning(date)
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                    <KeyboardDatePicker
                                        className={classes.date}
                                        fullWidth
                                        invalidDateMessage="Некорректная дата"
                                        disablePast
                                        disableToolbar
                                        orientation="landscape"
                                        variant="inline"
                                        openTo="year"
                                        name="ending"
                                        format="dd-MM-yyyy"
                                        autoOk
                                        required
                                        label={'по'}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={ending}
                                        onChange={date => {
                                            setEnding(date)
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <CustomList className={classes.list}>
                    {log && log.map(item => (
                        <ListItem key={item.id}>
                            <ListItemText className={classes.historyItem}
                                  primary={
                                      <React.Fragment>
                                          <Grid container className={classes.container} direction="column" justify="flex-start" alignItems="stretch">
                                              <Grid item>
                                                  <Grid container className={classes.container} direction="row" justify="space-between" alignItems="stretch">
                                                      <Grid item>
                                                          <Typography component={'span'} variant="body2">
                                                              {format(new Date(item.created), 'H:mm PP',  {locale: ru})}
                                                          </Typography>
                                                      </Grid>
                                                  </Grid>
                                              </Grid>
                                              <Grid item>
                                                  <Grid container className={classes.container} direction="column" justify="flex-start" alignItems="stretch">
                                                      {!item.comment &&
                                                          <Grid item>
                                                              <Typography component={'span'} variant="body2">
                                                                  Статус:
                                                              </Typography>
                                                          </Grid>
                                                      }
                                                      <Grid item>
                                                          <Typography component={'span'} variant="subtitle2">
                                                              {item.comment ?? (item.status ? item.status.name : null)}
                                                          </Typography>
                                                      </Grid>
                                                      <Grid item>
                                                          {
                                                              item.files.map(file => (
                                                                  <Typography key={file.id} component={'span'} variant="body2">
                                                                      {file.type.name} (<Link href={process.env.REACT_APP_HOST_API + `/shop/order/${order.contract.offer.lot.order.id}/file/${file.id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}>{file.name}</Link>)
                                                                  </Typography>
                                                              ))
                                                          }
                                                      </Grid>
                                                  </Grid>
                                              </Grid>
                                          </Grid>
                                      </React.Fragment>
                                  }
                            />
                        </ListItem>
                    ))}
                </CustomList>
            </Grid>
        </Grid>
    )
}
