import React from "react"
import {useSelector} from "react-redux"

import {Grid, Link, makeStyles, Paper, Typography} from "@material-ui/core"

import {AuthorizationService} from "../../Auth/services/authorization"
import {getRole} from "../../Planning/helpers/permissions/users";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    support: {
        "padding": "20px"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
}))

const instructions = {
    supplier: [
        {
            title: "Инструкция по работе с системой",
            url: "https://docs.google.com/document/d/10AtpiQDSX-AnLVV_b6zY3zj9Z8Wob-uHR3Wu5PGyMhs/edit?usp=sharing"
        }
    ],
    customer: [
        {
            title: "Инструкция по работе с системой",
            url: "https://docs.google.com/document/d/1m1DvyK292KXcLZPhkIyHmLHod4-ZSPWV6tg99CKWvm8/edit?usp=sharing"
        }
    ],
    regional: [
        {
            title: "Инструкция по работе в модуле планирования",
            url: "https://docs.google.com/document/d/18FnHPth9dc-PAzZSHbuOPZnYK42g0d3TOnuoYY6kO7Y/edit?usp=sharing"
        },
        {
            title: "Руководство по заведению новых Эталонных наименований по категориям в Справочнике эталонов",
            url: "https://docs.google.com/document/d/1BbZ2zOzwG5YozI1PmSVDCj_OCtYNdJlZ7M3dcx4F-Fw/edit?usp=sharing"
        }
    ],
    central: [
        {
            title: "Руководство по заведению новых Эталонных наименований по категориям в Справочнике эталонов",
            url: "https://docs.google.com/document/d/1BbZ2zOzwG5YozI1PmSVDCj_OCtYNdJlZ7M3dcx4F-Fw/edit?usp=sharing"
        }
    ]
}

export const Content = (props) => {
    const classes = useStyles()
    const {account} = useSelector(state => state.account)

    return <Grid item className={classes.content}>
        <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
            <Paper>
                <Grid className={classes.support} container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                    <Grid item>
                        <Typography className={classes.title}>Помощь</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                            <Grid item>
                                Телефон:
                            </Grid>
                            <Grid item>
                                +7 (495)
                                248-06-46 {AuthorizationService.roles(account, ["customer", 'customer_confirmed_by_admin']) ? 'доб. 2' : null} {AuthorizationService.roles(account, ["supplier", 'supplier_confirmed']) ? 'доб. 1' : null}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                            <Grid item>
                                Электронная почта:
                            </Grid>
                            <Grid item>
                                <Link href="mailto:support@emsoft.ru">support@emsoft.ru</Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title}>Инструкции</Typography>
                    </Grid>
                    {instructions[getRole(account)]?.map(({title, url}) => (
                        <Grid item key={url}>
                            <Link target="_blank" href={url}>{title}</Link>
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </Grid>
    </Grid>
}
