const status = {
    DRAFT: 1,
    APPROVED_BY_CHUZ_AGREED_BY_USER: 2,
    APPROVED_BY_CHUZ_AGREED_BY_ECONOMIST: 3,
    APPROVED_BY_CHUZ: 4,
    IN_WORK_RDZ: 5,
    APPROVED_BY_RDZ: 6,
    REJECTED_RDZ: 7,
    ON_COMPLETION_CHUZ: 8,
    IN_WORK_CDZ: 9,
    APPROVED_BY_CDZ: 10,
    REJECTED_CDZ: 11,
    CORRECTION_APPROVED: 12,
    CORRECTION_REQUEST: 13,
    ON_CORRECTION: 14,
    CORRECTION_REJECTED: 15,
    CORRECTION_REQUEST_EXCEPTION: 16,
    CORRECTION_REQUEST_DECLINE: 17
}

export { status }
