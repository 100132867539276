import * as AppStorage from "../../App/helpers/storage"

export default function catalog(state = {
    loading: false,
    products: {
        data: [],
        meta: {
            total: 0,
            per_page: 15,
            current_page: 1
        },
        category: AppStorage.get('filterCatalogProductsCategory') ? JSON.parse(AppStorage.get('filterCatalogProductsCategory')) : null,
        filter: AppStorage.get('filterCatalogProducts') ? JSON.parse(AppStorage.get('filterCatalogProducts')) : {
            supplier: '',
            contract: '',
            amount: '',
            price: {
                from: '',
                to: ''
            }
        },
        searchFilter: AppStorage.get('searchFilterCatalogProducts') ? JSON.parse(AppStorage.get('searchFilterCatalogProducts')) : {
            search: '',
            sort: 'name_asc',
            type: 'all',
            tabType: 2,
        }
    },
}, action) {
    switch (action.type) {
        case "CATALOG_PRODUCTS_LOADING":
            return {
                ...state,
                ...{
                    loading: action.payload
                }
            }
        case "CATALOG_PRODUCTS_REQUEST":
            return {
                ...state,
                ...{
                    loading: true
                }
            }
        case "CATALOG_PRODUCTS":
            return {
                ...state,
                ...{
                    products: {
                        ...state.products,
                        ...{
                            data: action.payload.data,
                            meta: action.payload.meta
                        }
                    }
                },
                ...{
                    loading: true
                }
            }
        case "CATALOG_PRODUCTS_FAILURE":
            return {
                ...state,
                ...{loading: false}
            }
        case "CATALOG_PRODUCT_ITEMS_REQUEST":
            return state
        case "CATALOG_PRODUCT_ITEMS":
            return {
                ...state,
                ...{
                    products: {
                        ...state.products,
                        ...{
                            data: state.products.data.map(product => {
                                if (product.key === action.payload.key) {
                                    product = {
                                        ...product,
                                        ...{items: action.payload.items}
                                    }
                                }

                                return product
                            }),
                            meta: state.products.meta
                        }
                    }
                }
            }
        case "CATALOG_PRODUCT_ITEMS_FAILURE":
            return state
        case "CATALOG_PRODUCTS_EDIT_AMOUNT_ITEM":
            return {
                ...state,
                ...{
                    products: {
                        ...state.products,
                        ...{
                            data: state.products.data.map(product => {
                                return {
                                    ...product,
                                    ...(product.items ? {
                                        items: product.items.map(item => {
                                            let ordered = (item.id === action.payload.id) ? (item.item.ordered ? parseFloat(item.item.ordered) : 0) + parseFloat(action.payload.amount) : 0;
                                            return (item.id === action.payload.id) ? {
                                                ...item,
                                                ...{
                                                    item: {
                                                        ...item.item,
                                                        ...{ordered: ordered},
                                                    }
                                                }
                                            } : item
                                        })
                                    } : {})
                                }
                            })
                        }
                    }
                }
            }
        case "CATALOG_PRODUCTS_CATEGORY":
            return {
                ...state,
                ...{
                    products: {
                        ...state.products,
                        ...{
                            category: action.payload
                        }
                    }
                },
                ...{loading: false}
            }
        case "CATALOG_PRODUCTS_FILTER":
            AppStorage.set('filterCatalogProducts', JSON.stringify(action.payload))
            return {
                ...state,
                ...{
                    products: {
                        ...state.products,
                        ...{
                            filter: action.payload
                        }
                    }
                },
                ...{loading: false}
            }
        case "CATALOG_PRODUCTS_CLEAR":
            return {
                ...state,
                ...{
                    products:
                    {
                        ...state.products,
                        ...{
                            data: [],
                            meta: {
                                total: 0,
                                per_page: 15,
                                current_page: 1
                            },
                            category: null,
                        }
                    }
                },
                ...{
                    loading: false
                }
            }
        case "CATALOG_PRODUCTS_SEARCH_FILTER":
            AppStorage.set('searchFilterCatalogProducts', JSON.stringify(action.payload))
            return  {
                ...state,
                ...{
                    products: {
                        ...state.products,
                        ...{
                            searchFilter: action.payload
                        }
                    }
                },
            }
        default:
            return state
    }
}
