import React from "react"
import {Button, makeStyles, Tooltip} from "@material-ui/core"

const useStyles = makeStyles(() => ({
    root: {
        "&:hover":{
            "background-color": "#898989",
        },
        "margin-top": "15px",
        "width": "35px",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "#fff",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-bottom": "3px solid #898989",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "#616161",
        "cursor": "pointer",
    },
    disabled: {
        "background-color": "#e7eaee !important",
        "border-bottom": "3px solid #c6c9cc !important",
    }
  }));


export const ButtonIcon = (props) => {
    const classes = useStyles();

    const { title, ...attributes } = props

    const button = <Button
        classes={{
            root: classes.root,
            disabled: classes.disabled
        }}
        {...attributes}
    >
    </Button>

    return title ? (
        <Tooltip title={title}>
            {button}
        </Tooltip>
    ) : button
}
