import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik, useFormikContext} from "formik"
import {fieldToTextField} from "formik-material-ui"
import * as Yup from "yup"

import {Grid, makeStyles, TextField as MuiTextField, Typography} from "@material-ui/core"
import {Autocomplete as MuiAutocomplete} from "@material-ui/lab"

import {TextFieldWithError as CustomTextField} from "../../../../App/components/Inputs/TextFieldWithError"
import {Submit} from "../../../../App/components/Button/Submit"
import {CompanyActions} from "../../../actions/company"
import {Autocomplete} from "../../../../App/components/Inputs/Autocomplete"
import {AccountActions} from "../../../actions/account"
import {history} from "../../../../App/helpers/history"
import {useDebouncedCallback} from "use-debounce";
import {AuthorizationService} from "../../../../Auth/services/authorization";

const useStyles = makeStyles(() => ({
    input: {
        "padding": "0 4px 3px !important",
        "width": "100%"
    },
    inputContent: {
        "padding": "20px 30px 0"
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 298px)"
    },
    column: {
        "height": "86px"
    },
    okpd: {
        "height": "calc(+ 20px)"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    group: {
        "padding": "8px"
    },
    groupTitle: {
        "font-weight": "500"
    }
}))

const useAttributes = (account, country, cities, banks, okved, company, classes) => {
    const customer = AuthorizationService.roles(account, ["customer"]);

    let attributes = {
        initialValues: {
            name: company.legal_detail.name,
            country: company.legal_detail.city.region.country.name,
            locality: company.legal_detail.city,
            current_address: company.legal_detail.current_address ?? '',
            director: {
                last_name: company.legal_detail.executive_director ? (company.legal_detail.executive_director.last_name ?? '') : '',
                first_name: company.legal_detail.executive_director ? (company.legal_detail.executive_director.first_name ?? '') : '',
                middle_name: company.legal_detail.executive_director ? (company.legal_detail.executive_director.middle_name ?? '') : ''
            }
        },
        validationSchema: {
            name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
            country: Yup.string(),
            locality: Yup.object().nullable().required("Необходимо указать город"),
            current_address: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
            director: Yup.object().shape({
                last_name: Yup.string(),
                first_name: Yup.string(),
                middle_name: Yup.string()
            }),
        },
        attributes: null
    }

    switch (company.type) {
        case 'individual':
            switch (country) {
                case 'ru':
                    attributes.initialValues = {
                        ...attributes.initialValues,
                        ...{
                            legal_address: company.legal_detail.legal_address ?? '',
                            inn: company.legal_detail.inn ?? '',
                            ogrn: company.legal_detail.ogrn ?? '',
                            payment: {
                                bank: company.payment_detail ? (company.payment_detail.bank ?? '') : '',
                                bik: company.payment_detail ? (company.payment_detail.bik ?? '') : '',
                                correspondent_account: company.payment_detail ? (company.payment_detail.correspondent_account ?? '') : '',
                                checking_account: company.payment_detail ? (company.payment_detail.checking_account ?? '') : ''
                            },
                            ...(company.type ? {
                                okpo: company.legal_detail ? (company.legal_detail.okpo ?? '') : '',
                                okpd: company.legal_detail ? (company.legal_detail.okpd ?? []) : []
                            } : {})
                        }
                    }

                    attributes.validationSchema = {
                        ...attributes.validationSchema,
                        ...{
                            legal_address: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            inn: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(12, "Длина ИНН должна быть 12 символов!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            ogrn: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(15, "Длина ОГРНИП должна быть 15 символов!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            payment: Yup.object().shape({
                                bank: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                bik: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                correspondent_account: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                checking_account: Yup.string().length(20, "Длина Р/С должна быть 20 символов!").required("Поле не может быть пустым или состоять из одних пробелов!")
                            }),
                            ...(company.type ? {
                                okpd: Yup.array(),
                                okpo: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').length((((company.type === 'individual') || (company.type === 'self')) ? 10 : 8), "Длина ОКПО должна быть " + (((company.type === 'individual') || (company.type === 'self')) ? 10 : 8) + " символов!").required("Поле не может быть пустым или состоять из одних пробелов!")
                            } : {})
                        }
                    }

                    attributes.attributes = ( values, setFieldValue, setValues, setFieldTouched, touched, search, setSearch) => (
                        <Grid item className={classes.inputContent}>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="name"
                                        type="text"
                                        label="Полное наименование"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="country"
                                        type="text"
                                        label="Страна"
                                        disabled
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        name="locality"
                                        type="text"
                                        options={cities}
                                        getOptionLabel={option => {
                                            return option.name + ((option.name !== option.region.name) ? ", " + option.region.name : "")
                                        }}
                                        label="Населенный пункт"
                                        required
                                        component={Autocomplete}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={company.type ? 4 : 6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="inn"
                                        type="text"
                                        label="ИНН"
                                        required
                                        disabled={!!company.legal_detail.inn}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={company.type ? 4 : 6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="ogrn"
                                        type="text"
                                        label="ОГРНИП"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                                {company.type &&
                                    <Grid item xs={4} className={classes.column}>
                                        <Field
                                            fullWidth
                                            name="okpo"
                                            type="number"
                                            label="Код ОКПО"
                                            required
                                            component={CustomTextField}
                                        />
                                    </Grid>
                                }
                            </Grid>
                            {company.type &&
                                <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12} className={classes.column}>
                                        <MuiAutocomplete
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            multiple
                                            filterOptions={options => options}
                                            options={okved.data}
                                            name={'okpd'}
                                            defaultValue={values.okpd ? values.okpd : null}
                                            getOptionLabel={(option) => option.code + ` ` + option.name}
                                            noOptionsText='Нет доступных вариантов'
                                            renderInput={params => {
                                                return <Field
                                                    fullWidth
                                                    component={MuiTextField}
                                                    {...params}
                                                    onChange={(e) => {
                                                        setSearch(e.target.value)
                                                    }}
                                                    name={'okpd'}
                                                    label="Код ОКПД"
                                                />
                                            }}
                                            onChange={(e, option) => {
                                                setFieldValue('okpd', option)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container direction="row" justify="space-around" alignItems="flex-start"
                                          spacing={2}>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                name="payment.bank"
                                                type="text"
                                                label="Банк"
                                                component={({textFieldProps, ...props}) => {
                                                    const {form: {errors, setTouched}} = props;
                                                    const {label, helperText, error, ...field} = fieldToTextField(props);
                                                    const {name} = field;

                                                    return (
                                                        <MuiAutocomplete
                                                            {...props}
                                                            {...field}
                                                            options={banks}
                                                            getOptionLabel={option => {
                                                                return option ? ((option instanceof Object) ? option.name : option) : ''
                                                            }}
                                                            noOptionsText='Нет доступных вариантов'
                                                            onBlur={() => setTouched({[name]: true})}
                                                            onChange={(e, option) => {
                                                                setFieldValue('payment', {
                                                                    ...values.payment,
                                                                    ...{
                                                                        bank: option,
                                                                    },
                                                                    ...((option instanceof Object)
                                                                            ? {
                                                                                bik: option.bik,
                                                                                correspondent_account: option.correspondent_account
                                                                            }
                                                                            : {
                                                                                bik: '',
                                                                                correspondent_account: ''
                                                                            }
                                                                    )
                                                                })
                                                            }}
                                                            renderInput={params => (
                                                                <MuiTextField required
                                                                              label={label} {...params} {...textFieldProps}
                                                                              helperText={error ? errors[name] : ''}
                                                                              error={!!error}/>
                                                            )}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                fullWidth
                                                name="payment.bik"
                                                type="text"
                                                label="БИК"
                                                required
                                                disabled
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" justify="space-around" alignItems="flex-start"
                                          spacing={2}>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                fullWidth
                                                name="payment.correspondent_account"
                                                type="text"
                                                label="К/С"
                                                required
                                                disabled
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                fullWidth
                                                name="payment.checking_account"
                                                type="text"
                                                label="Р/С"
                                                required
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="legal_address"
                                        type="text"
                                        label="Юридический адрес"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="current_address"
                                        type="text"
                                        label="Фактический адрес"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.groupTitle}>Данные директора</Typography>
                                    <Grid container className={classes.group} direction="row" justify="space-around"
                                          alignItems="flex-start" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="space-around" alignItems="flex-start"
                                                  spacing={2}>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.last_name"
                                                        type="text"
                                                        label="Фамилия"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.first_name"
                                                        type="text"
                                                        label="Имя"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.middle_name"
                                                        type="text"
                                                        label="Отчество"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    break
                case 'kz':
                    attributes.initialValues = {
                        ...attributes.initialValues,
                        ...{
                            legal_address: company.legal_detail.legal_address ?? '',
                            bin: company.legal_detail.bin ?? '',
                            kbe: company.legal_detail.kbe ?? '',
                            iik: company.legal_detail.iik ?? '',
                            iban: company.legal_detail.iban ?? '',
                            payment: {
                                bank: company.payment_detail ? (company.payment_detail.bank ?? '') : '',
                                bik: company.payment_detail ? (company.payment_detail.bik ?? '') : ''
                            }
                        }
                    }

                    attributes.validationSchema = {
                        ...attributes.validationSchema,
                        ...{
                            legal_address: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            bin: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(12, "Длина БИН должна быть 12 символов!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            kbe: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(2, "Длина КБЕ должна быть 2 символа!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            iik: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(20, "Длина ИИН должна быть 20 символов!"),
                            iban: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(20, "Длина ИБАН должна быть 20 символов!"),
                            payment: Yup.object().shape({
                                bank: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                bik: Yup.string()
                            })
                        }
                    }

                    attributes.attributes = () => (
                        <Grid item className={classes.inputContent}>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="name"
                                        type="text"
                                        label="Полное наименование"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="country"
                                        type="text"
                                        label="Страна"
                                        disabled
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        name="locality"
                                        type="text"
                                        options={cities}
                                        getOptionLabel={option => {
                                            return option.name + ((option.name !== option.region.name) ? ", " + option.region.name : "")
                                        }}
                                        label="Населенный пункт"
                                        required
                                        component={Autocomplete}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="bin"
                                        type="text"
                                        label="БИН"
                                        required
                                        disabled
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="kbe"
                                        type="text"
                                        label="КБЕ"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="iik"
                                        type="text"
                                        label="ИИК"
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="iban"
                                        type="text"
                                        label="IBAN/счёт"
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="payment.bank"
                                        type="text"
                                        label="Банк"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="payment.bik"
                                        type="text"
                                        label="БИК"
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="legal_address"
                                        type="text"
                                        label="Юридический адрес"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="current_address"
                                        type="text"
                                        label="Фактический адрес"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.groupTitle}>Данные директора</Typography>
                                    <Grid container className={classes.group} direction="row" justify="space-around"
                                          alignItems="flex-start" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="space-around" alignItems="flex-start"
                                                  spacing={2}>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.last_name"
                                                        type="text"
                                                        label="Фамилия"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.first_name"
                                                        type="text"
                                                        label="Имя"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.middle_name"
                                                        type="text"
                                                        label="Отчество"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    break
                default:
            }
            break;
        case 'self':
            switch (country) {
                case 'ru':
                    attributes.initialValues = {
                        ...attributes.initialValues,
                        ...{
                            inn: company.legal_detail.inn ?? '',
                            ogrn: company.legal_detail.ogrn ?? '',
                            payment: {
                                bank: company.payment_detail ? (company.payment_detail.bank ?? '') : '',
                                bik: company.payment_detail ? (company.payment_detail.bik ?? '') : '',
                                correspondent_account: company.payment_detail ? (company.payment_detail.correspondent_account ?? '') : '',
                                checking_account: company.payment_detail ? (company.payment_detail.checking_account ?? '') : ''
                            },
                            ...(company.type ? {
                                okpo: company.legal_detail ? (company.legal_detail.okpo ?? '') : '',
                                okpd: company.legal_detail ? (company.legal_detail.okpd ?? []) : []
                            } : {})
                        }
                    }

                    attributes.validationSchema = {
                        ...attributes.validationSchema,
                        ...{
                            inn: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(12, "Длина ИНН должна быть 12 символов!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            ogrn: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(15, "Длина ОГРНИП должна быть 15 символов!"),
                            payment: Yup.object().shape({
                                bank: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                bik: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                correspondent_account: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                checking_account: Yup.string().length(20, "Длина Р/С должна быть 20 символов!").required("Поле не может быть пустым или состоять из одних пробелов!")
                            }),
                            ...(company.type ? {
                                okpd: Yup.array(),
                                okpo: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').length((((company.type === 'individual') || (company.type === 'self')) ? 10 : 8), "Длина ОКПО должна быть " + (((company.type === 'individual') || (company.type === 'self')) ? 10 : 8) + " символов!")
                            } : {})
                        }
                    }

                    attributes.attributes = ( values, setFieldValue, setValues, setFieldTouched, touched, search, setSearch) => (
                        <Grid item className={classes.inputContent}>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="name"
                                        type="text"
                                        label="Полное наименование"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="country"
                                        type="text"
                                        label="Страна"
                                        disabled
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        name="locality"
                                        type="text"
                                        options={cities}
                                        getOptionLabel={option => {
                                            return option.name + ((option.name !== option.region.name) ? ", " + option.region.name : "")
                                        }}
                                        label="Населенный пункт"
                                        required
                                        component={Autocomplete}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                <Grid item xs={company.type ? 4 : 6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="inn"
                                        type="text"
                                        label="ИНН"
                                        required
                                        disabled={!!company.legal_detail.inn}
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            {company.type &&
                                <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12} className={classes.column}>
                                        <MuiAutocomplete
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            multiple
                                            filterOptions={options => options}
                                            options={okved.data}
                                            name={'okpd'}
                                            defaultValue={values.okpd ? values.okpd : null}
                                            getOptionLabel={(option) => option.code + ` ` + option.name}
                                            noOptionsText='Нет доступных вариантов'
                                            renderInput={params => {
                                                return <Field
                                                    fullWidth
                                                    component={MuiTextField}
                                                    {...params}
                                                    onChange={(e) => {
                                                        setSearch(e.target.value)
                                                    }}
                                                    name={'okpd'}
                                                    label="Код ОКПД"
                                                />
                                            }}
                                            onChange={(e, option) => {
                                                setFieldValue('okpd', option)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container direction="row" justify="space-around" alignItems="flex-start"
                                          spacing={2}>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                name="payment.bank"
                                                type="text"
                                                label="Банк"
                                                component={({textFieldProps, ...props}) => {
                                                    const {form: {errors, setTouched}} = props;
                                                    const {label, helperText, error, ...field} = fieldToTextField(props);
                                                    const {name} = field;

                                                    return (
                                                        <MuiAutocomplete
                                                            {...props}
                                                            {...field}
                                                            options={banks}
                                                            getOptionLabel={option => {
                                                                return option ? ((option instanceof Object) ? option.name : option) : ''
                                                            }}
                                                            noOptionsText='Нет доступных вариантов'
                                                            onBlur={() => setTouched({[name]: true})}
                                                            onChange={(e, option) => {
                                                                setFieldValue('payment', {
                                                                    ...values.payment,
                                                                    ...{
                                                                        bank: option,
                                                                    },
                                                                    ...((option instanceof Object)
                                                                            ? {
                                                                                bik: option.bik,
                                                                                correspondent_account: option.correspondent_account
                                                                            }
                                                                            : {
                                                                                bik: '',
                                                                                correspondent_account: ''
                                                                            }
                                                                    )
                                                                })
                                                            }}
                                                            renderInput={params => (
                                                                <MuiTextField required
                                                                              label={label} {...params} {...textFieldProps}
                                                                              helperText={error ? errors[name] : ''}
                                                                              error={!!error}/>
                                                            )}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                fullWidth
                                                name="payment.bik"
                                                type="text"
                                                label="БИК"
                                                required
                                                disabled
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" justify="space-around" alignItems="flex-start"
                                          spacing={2}>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                fullWidth
                                                name="payment.correspondent_account"
                                                type="text"
                                                label="К/С"
                                                required
                                                disabled
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                fullWidth
                                                name="payment.checking_account"
                                                type="text"
                                                label="Р/С"
                                                required
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="current_address"
                                        type="text"
                                        label="Фактический адрес"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.groupTitle}>Данные директора</Typography>
                                    <Grid container className={classes.group} direction="row" justify="space-around"
                                          alignItems="flex-start" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="space-around" alignItems="flex-start"
                                                  spacing={2}>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.last_name"
                                                        type="text"
                                                        label="Фамилия"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.first_name"
                                                        type="text"
                                                        label="Имя"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.middle_name"
                                                        type="text"
                                                        label="Отчество"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    break
                case 'kz':
                    attributes.initialValues = {
                        ...attributes.initialValues,
                        ...{
                            bin: company.legal_detail.bin ?? '',
                            kbe: company.legal_detail.kbe ?? '',
                            iik: company.legal_detail.iik ?? '',
                            iban: company.legal_detail.iban ?? '',
                            payment: {
                                bank: company.payment_detail ? (company.payment_detail.bank ?? '') : '',
                                bik: company.payment_detail ? (company.payment_detail.bik ?? '') : ''
                            }
                        }
                    }

                    attributes.validationSchema = {
                        ...attributes.validationSchema,
                        ...{
                            bin: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(12, "Длина БИН должна быть 12 символов!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            kbe: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(2, "Длина КБЕ должна быть 2 символа!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            iik: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(20, "Длина ИИН должна быть 20 символов!"),
                            iban: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(20, "Длина ИБАН должна быть 20 символов!"),
                            payment: Yup.object().shape({
                                bank: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                bik: Yup.string()
                            })
                        }
                    }

                    attributes.attributes = () => (
                        <Grid item className={classes.inputContent}>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="name"
                                        type="text"
                                        label="Полное наименование"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="country"
                                        type="text"
                                        label="Страна"
                                        disabled
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        name="locality"
                                        type="text"
                                        options={cities}
                                        getOptionLabel={option => {
                                            return option.name + ((option.name !== option.region.name) ? ", " + option.region.name : "")
                                        }}
                                        label="Населенный пункт"
                                        required
                                        component={Autocomplete}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="bin"
                                        type="text"
                                        label="БИН"
                                        required
                                        disabled
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="kbe"
                                        type="text"
                                        label="КБЕ"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="iik"
                                        type="text"
                                        label="ИИК"
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="iban"
                                        type="text"
                                        label="IBAN/счёт"
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="payment.bank"
                                        type="text"
                                        label="Банк"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="payment.bik"
                                        type="text"
                                        label="БИК"
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="current_address"
                                        type="text"
                                        label="Фактический адрес"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.groupTitle}>Данные директора</Typography>
                                    <Grid container className={classes.group} direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="space-around" alignItems="flex-start"
                                                  spacing={2}>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.last_name"
                                                        type="text"
                                                        label="Фамилия"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.first_name"
                                                        type="text"
                                                        label="Имя"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.middle_name"
                                                        type="text"
                                                        label="Отчество"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    break
                default:
            }
            break;
        case 'legal':
            switch (country) {
                case 'ru':
                    attributes.initialValues = {
                        ...attributes.initialValues,
                        ...{
                            legal_address: company.legal_detail.legal_address ?? '',
                            director: {
                                ...attributes.initialValues.director,
                                ...{
                                    inn: company.legal_detail.executive_director ? (company.legal_detail.executive_director.inn ?? '') : '',
                                }
                            },
                            inn: company.legal_detail.inn ?? '',
                            kpp: company.legal_detail.kpp ?? '',
                            ogrn: company.legal_detail.ogrn ?? '',
                            payment: {
                                bank: company.payment_detail ? (company.payment_detail.bank ?? '') : '',
                                bik: company.payment_detail ? (company.payment_detail.bik ?? '') : '',
                                correspondent_account: company.payment_detail ? (company.payment_detail.correspondent_account ?? '') : '',
                                checking_account: company.payment_detail ? (company.payment_detail.checking_account ?? '') : ''
                            },
                            ...(company.type ? {
                                okpo: company.legal_detail ? (company.legal_detail.okpo ?? '') : '',
                                okpd: company.legal_detail ? (company.legal_detail.okpd ?? []) : []
                            } : {})
                        }
                    }

                    attributes.validationSchema = {
                        ...attributes.validationSchema,
                        ...{
                            legal_address: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            director: attributes.validationSchema.director.concat(Yup.object().shape({
                                inn: Yup.string()
                                    .length(12, "Длина ИНН должна быть 12 символов!")
                            })),
                            inn: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .min(10, "Длина ИНН должна от 10 символов!")
                                .max(12, "Длина ИНН должна до 12 символов!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            kpp: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(9, "Длина КПП должна быть 9 символов!"),
                            ogrn: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(13, "Длина ОГРН должна быть 13 символов!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            payment: Yup.object().shape({
                                bank: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                bik: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                correspondent_account: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                checking_account: Yup.string().length(20, "Длина Р/С должна быть 20 символов!").required("Поле не может быть пустым или состоять из одних пробелов!")
                            }),
                            ...(company.type ? {
                                okpd: Yup.array(),
                                okpo: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').length((((company.type === 'individual') || (company.type === 'self')) ? 10 : 8), "Длина ОКПО должна быть " + (((company.type === 'individual') || (company.type === 'self')) ? 10 : 8) + " символов!").required("Поле не может быть пустым или состоять из одних пробелов!")
                            } : {})
                        }
                    }

                    attributes.attributes = ( values, setFieldValue, setValues, setFieldTouched, touched, search, setSearch) => (
                        <Grid item className={classes.inputContent}>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="name"
                                        type="text"
                                        label="Полное наименование"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="country"
                                        type="text"
                                        label="Страна"
                                        disabled
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        name="locality"
                                        type="text"
                                        options={cities}
                                        getOptionLabel={option => {
                                            return option.name + ((option.name !== option.region.name) ? ", " + option.region.name : "")
                                        }}
                                        label="Населенный пункт"
                                        required
                                        component={Autocomplete}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={company.type ? 3 : 4} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="inn"
                                        type="text"
                                        label="ИНН"
                                        required
                                        disabled={!!company.legal_detail.inn}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={company.type ? 3 : 4} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="kpp"
                                        type="text"
                                        label="КПП"
                                        disabled={!!company.legal_detail.kpp}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={company.type ? 3 : 4} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="ogrn"
                                        type="text"
                                        label="ОГРН"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                                {company.type &&
                                    <Grid item xs={3} className={classes.column}>
                                        <Field
                                            fullWidth
                                            name="okpo"
                                            type="number"
                                            label="Код ОКПО"
                                            required
                                            component={CustomTextField}
                                        />
                                    </Grid>
                                }
                            </Grid>
                            {company.type &&
                                <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12} className={classes.okpd}>
                                        <MuiAutocomplete
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            multiple
                                            filterOptions={options => options}
                                            options={okved.data}
                                            name={'okpd'}
                                            defaultValue={values.okpd ? values.okpd : null}
                                            getOptionLabel={(option) => option.code + ` ` + option.name}
                                            noOptionsText='Нет доступных вариантов'
                                            renderInput={params => {
                                                return <Field
                                                    fullWidth
                                                    component={MuiTextField}
                                                    {...params}
                                                    onChange={(e) => {
                                                        setSearch(e.target.value)
                                                    }}
                                                    name={'okpd'}
                                                    label="Код ОКПД"
                                                />
                                            }}
                                            onChange={(e, option) => {
                                                setFieldValue('okpd', option)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container direction="row" justify="space-around" alignItems="flex-start"
                                          spacing={2}>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                name="payment.bank"
                                                type="text"
                                                label="Банк"
                                                component={({textFieldProps, ...props}) => {
                                                    const {form: {errors, setTouched}} = props;
                                                    const {label, helperText, error, ...field} = fieldToTextField(props);
                                                    const {name} = field;

                                                    return (
                                                        <MuiAutocomplete
                                                            {...props}
                                                            {...field}
                                                            options={banks}
                                                            getOptionLabel={option => {
                                                                return option ? ((option instanceof Object) ? option.name : option) : ''
                                                            }}
                                                            noOptionsText='Нет доступных вариантов'
                                                            onBlur={() => setTouched({[name]: true})}
                                                            onChange={(e, option) => {
                                                                setFieldValue('payment', {
                                                                    ...values.payment,
                                                                    ...{
                                                                        bank: option,
                                                                    },
                                                                    ...((option instanceof Object)
                                                                            ? {
                                                                                bik: option.bik,
                                                                                correspondent_account: option.correspondent_account
                                                                            }
                                                                            : {
                                                                                bik: '',
                                                                                correspondent_account: ''
                                                                            }
                                                                    )
                                                                })
                                                            }}
                                                            renderInput={params => (
                                                                <MuiTextField required
                                                                              label={label} {...params} {...textFieldProps}
                                                                              helperText={error ? errors[name] : ''}
                                                                              error={!!error}/>
                                                            )}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                fullWidth
                                                name="payment.bik"
                                                type="text"
                                                label="БИК"
                                                required
                                                disabled
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" justify="space-around" alignItems="flex-start"
                                          spacing={2}>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                fullWidth
                                                name="payment.correspondent_account"
                                                type="text"
                                                label="К/С"
                                                required
                                                disabled
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                fullWidth
                                                name="payment.checking_account"
                                                type="text"
                                                label="Р/С"
                                                required
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="legal_address"
                                        type="text"
                                        label="Юридический адрес"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="current_address"
                                        type="text"
                                        label="Фактический адрес"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.groupTitle}>Данные директора</Typography>
                                    <Grid container className={classes.group} direction="row" justify="space-around"
                                          alignItems="flex-start" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="space-around" alignItems="flex-start"
                                                  spacing={2}>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.last_name"
                                                        type="text"
                                                        label="Фамилия"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.first_name"
                                                        type="text"
                                                        label="Имя"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.middle_name"
                                                        type="text"
                                                        label="Отчество"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    break
                case 'kz':
                    attributes.initialValues = {
                        ...attributes.initialValues,
                        ...{
                            legal_address: company.legal_detail.legal_address ?? '',
                            bin: company.legal_detail.bin ?? '',
                            kbe: company.legal_detail.kbe ?? '',
                            iik: company.legal_detail.iik ?? '',
                            iban: company.legal_detail.iban ?? '',
                            payment: {
                                bank: company.payment_detail ? (company.payment_detail.bank ?? '') : '',
                                bik: company.payment_detail ? (company.payment_detail.bik ?? '') : ''
                            }
                        }
                    }

                    attributes.validationSchema = {
                        ...attributes.validationSchema,
                        ...{
                            legal_address: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            bin: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(12, "Длина БИН должна быть 12 символов!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            kbe: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(2, "Длина КБЕ должна быть 2 символа!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            iik: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(20, "Длина ИИН должна быть 20 символов!"),
                            iban: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(20, "Длина ИБАН должна быть 20 символов!"),
                            payment: Yup.object().shape({
                                bank: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                bik: Yup.string()
                            })
                        }
                    }

                    attributes.attributes = () => (
                        <Grid item className={classes.inputContent}>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start">
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="name"
                                        type="text"
                                        label="Полное наименование"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="country"
                                        type="text"
                                        label="Страна"
                                        disabled
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        name="locality"
                                        type="text"
                                        options={cities}
                                        getOptionLabel={option => {
                                            return option.name + ((option.name !== option.region.name) ? ", " + option.region.name : "")
                                        }}
                                        label="Населенный пункт"
                                        required
                                        component={Autocomplete}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="bin"
                                        type="text"
                                        label="БИН"
                                        required
                                        disabled
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="kbe"
                                        type="text"
                                        label="КБЕ"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                {company.legal_detail.city.region.country.id !== 113 && <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="iik"
                                        type="text"
                                        label="ИИК"
                                        component={CustomTextField}
                                    />
                                </Grid>}
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="iban"
                                        type="text"
                                        label="IBAN/счёт"
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="payment.bank"
                                        type="text"
                                        label="Банк"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="payment.bik"
                                        type="text"
                                        label="БИК"
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="legal_address"
                                        type="text"
                                        label="Юридический адрес"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="current_address"
                                        type="text"
                                        label="Фактический адрес"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.groupTitle}>Данные директора</Typography>
                                    <Grid container className={classes.group} direction="row" justify="space-around"
                                          alignItems="flex-start" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="space-around" alignItems="flex-start"
                                                  spacing={2}>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.last_name"
                                                        type="text"
                                                        label="Фамилия"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.first_name"
                                                        type="text"
                                                        label="Имя"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.middle_name"
                                                        type="text"
                                                        label="Отчество"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    break
                default:
            }
            break;
        default:
            switch (country) {
                case 'ru':
                    attributes.initialValues = {
                        ...attributes.initialValues,
                        ...{
                            legal_address: company.legal_detail.legal_address ?? '',
                            inn: company.legal_detail.inn ?? '',
                            kpp: company.legal_detail.kpp ?? '',
                            ogrn: company.legal_detail.ogrn ?? '',
                            payment: {
                                bank: company.payment_detail ? (company.payment_detail.bank ?? '') : '',
                                bik: company.payment_detail ? (company.payment_detail.bik ?? '') : '',
                                correspondent_account: company.payment_detail ? (company.payment_detail.correspondent_account ?? '') : '',
                                checking_account: company.payment_detail ? (company.payment_detail.checking_account ?? '') : ''
                            },
                            ...(company.type ? {
                                okpo: company.legal_detail ? (company.legal_detail.okpo ?? '') : '',
                                okpd: company.legal_detail ? (company.legal_detail.okpd ?? []) : []
                            } : {})
                        }
                    }

                    attributes.validationSchema = {
                        ...attributes.validationSchema,
                        ...{
                            legal_address: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            inn: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .min(10, "Длина ИНН должна быть от 10 символов!")
                                .max(12, "Длина ИНН должна быть до 12 символов!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            kpp: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(9, "Длина КПП должна быть 9 символов!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            ogrn: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(13, "Длина ОГРН должна быть 13 символов!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            payment: Yup.object().shape({
                                bank: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                bik: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                correspondent_account: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                checking_account: Yup.string().length(20, "Длина Р/С должна быть 20 символов!").required("Поле не может быть пустым или состоять из одних пробелов!")
                            }),
                            ...(company.type ? {
                                okpd: Yup.array(),
                                okpo: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').length((((company.type === 'individual') || (company.type === 'self')) ? 10 : 8), "Длина ОКПО должна быть " + (((company.type === 'individual') || (company.type === 'self')) ? 10 : 8) + " символов!").required("Поле не может быть пустым или состоять из одних пробелов!")
                            } : {})
                        }
                    }

                    attributes.attributes = ( values, setFieldValue, setValues, setFieldTouched, touched, search, setSearch) => (
                        <Grid item className={classes.inputContent}>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        disabled={customer}
                                        name="name"
                                        type="text"
                                        label="Полное наименование"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="country"
                                        type="text"
                                        label="Страна"
                                        disabled
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        name="locality"
                                        type="text"
                                        options={cities}
                                        getOptionLabel={option => {
                                            return option.name + ((option.name !== option.region.name) ? ", " + option.region.name : "")
                                        }}
                                        label="Населенный пункт"
                                        required
                                        component={Autocomplete}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={company.type ? 3 : 4} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="inn"
                                        type="text"
                                        label="ИНН"
                                        required
                                        disabled={!!company.legal_detail.inn}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={company.type ? 3 : 4} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="kpp"
                                        type="text"
                                        label="КПП"
                                        disabled={!!company.legal_detail.kpp}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={company.type ? 3 : 4} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="ogrn"
                                        type="text"
                                        label="ОГРН"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                                {company.type &&
                                    <Grid item xs={3} className={classes.column}>
                                        <Field
                                            fullWidth
                                            name="okpo"
                                            type="number"
                                            label="Код ОКПО"
                                            required
                                            component={CustomTextField}
                                        />
                                    </Grid>
                                }
                            </Grid>
                            {company.type &&
                                <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12} className={classes.okpd}>
                                        <MuiAutocomplete
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            multiple
                                            filterOptions={options => options}
                                            options={okved.data}
                                            name={'okpd'}
                                            defaultValue={values.okpd ? values.okpd : null}
                                            getOptionLabel={(option) => option.code + ` ` + option.name}
                                            noOptionsText='Нет доступных вариантов'
                                            renderInput={params => {
                                                return <Field
                                                    fullWidth
                                                    component={MuiTextField}
                                                    {...params}
                                                    onChange={(e) => {
                                                        setSearch(e.target.value)
                                                    }}
                                                    name={'okpd'}
                                                    label="Код ОКПД"
                                                />
                                            }}
                                            onChange={(e, option) => {
                                                setFieldValue('okpd', option)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container direction="row" justify="space-around" alignItems="flex-start"
                                          spacing={2}>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                name="payment.bank"
                                                type="text"
                                                label="Банк"
                                                component={({textFieldProps, ...props}) => {
                                                    const {form: {errors, setTouched}} = props;
                                                    const {label, helperText, error, ...field} = fieldToTextField(props);
                                                    const {name} = field;

                                                    return (
                                                        <MuiAutocomplete
                                                            {...props}
                                                            {...field}
                                                            options={banks}
                                                            getOptionLabel={option => {
                                                                return option ? ((option instanceof Object) ? option.name : option) : ''
                                                            }}
                                                            noOptionsText='Нет доступных вариантов'
                                                            onBlur={() => setTouched({[name]: true})}
                                                            onChange={(e, option) => {
                                                                setFieldValue('payment', {
                                                                    ...values.payment,
                                                                    ...{
                                                                        bank: option,
                                                                    },
                                                                    ...((option instanceof Object)
                                                                            ? {
                                                                                bik: option.bik,
                                                                                correspondent_account: option.correspondent_account
                                                                            }
                                                                            : {
                                                                                bik: '',
                                                                                correspondent_account: ''
                                                                            }
                                                                    )
                                                                })
                                                            }}
                                                            renderInput={params => (
                                                                <MuiTextField required
                                                                              label={label} {...params} {...textFieldProps}
                                                                              helperText={error ? errors[name] : ''}
                                                                              error={!!error}/>
                                                            )}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                fullWidth
                                                name="payment.bik"
                                                type="text"
                                                label="БИК"
                                                required
                                                disabled
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" justify="space-around" alignItems="flex-start"
                                          spacing={2}>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                fullWidth
                                                name="payment.correspondent_account"
                                                type="text"
                                                label="К/С"
                                                required
                                                disabled
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.column}>
                                            <Field
                                                fullWidth
                                                name="payment.checking_account"
                                                type="text"
                                                label="Р/С"
                                                required
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="legal_address"
                                        type="text"
                                        label="Юридический адрес"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="current_address"
                                        type="text"
                                        label="Фактический адрес"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.groupTitle}>Данные директора</Typography>
                                    <Grid container className={classes.group} direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.last_name"
                                                        type="text"
                                                        label="Фамилия"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.first_name"
                                                        type="text"
                                                        label="Имя"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.middle_name"
                                                        type="text"
                                                        label="Отчество"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    break
                case 'kz':
                    attributes.initialValues = {
                        ...attributes.initialValues,
                        ...{
                            legal_address: company.legal_detail.legal_address ?? '',
                            bin: company.legal_detail.bin ?? '',
                            kbe: company.legal_detail.kbe ?? '',
                            iik: company.legal_detail.iik ?? '',
                            iban: company.legal_detail.iban ?? '',
                            payment: {
                                bank: company.payment_detail ? (company.payment_detail ? (company.payment_detail.bank ?? '') : '') : '',
                                bik: company.payment_detail ? (company.payment_detail ? (company.payment_detail.bik ?? '') : '') : ''
                            }
                        }
                    }

                    attributes.validationSchema = {
                        ...attributes.validationSchema,
                        ...{
                            legal_address: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            bin: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(12, "Длина БИН должна быть 12 символов!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            kbe: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(2, "Длина КБЕ должна быть 2 символа!")
                                .required("Поле не может быть пустым или состоять из одних пробелов!"),
                            iik: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(20, "Длина ИИН должна быть 20 символов!"),
                            iban: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                .length(20, "Длина ИБАН должна быть 20 символов!"),
                            payment: Yup.object().shape({
                                bank: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                bik: Yup.string()
                            })
                        }
                    }

                    attributes.attributes = () => (
                        <Grid item className={classes.inputContent}>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        disabled={customer}
                                        name="name"
                                        type="text"
                                        label="Полное наименование"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="country"
                                        type="text"
                                        label="Страна"
                                        disabled
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        name="locality"
                                        type="text"
                                        options={cities}
                                        getOptionLabel={option => {
                                            return option.name + ((option.name !== option.region.name) ? ", " + option.region.name : "")
                                        }}
                                        label="Населенный пункт"
                                        required
                                        component={Autocomplete}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="bin"
                                        type="text"
                                        label="БИН"
                                        required
                                        disabled
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="kbe"
                                        type="text"
                                        label="КБЕ"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="iik"
                                        type="text"
                                        label="ИИК"
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="iban"
                                        type="text"
                                        label="IBAN/счёт"
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="payment.bank"
                                        type="text"
                                        label="Банк"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="payment.bik"
                                        type="text"
                                        label="БИК"
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="legal_address"
                                        type="text"
                                        label="Юридический адрес"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="current_address"
                                        type="text"
                                        label="Фактический адрес"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.groupTitle}>Данные директора</Typography>
                                    <Grid container className={classes.group} direction="row" justify="space-around"
                                          alignItems="flex-start" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="space-around" alignItems="flex-start"
                                                  spacing={2}>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.last_name"
                                                        type="text"
                                                        label="Фамилия"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.first_name"
                                                        type="text"
                                                        label="Имя"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="director.middle_name"
                                                        type="text"
                                                        label="Отчество"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    break
                default:
            }
    }

    return attributes
}

export const LegalDetail = (props) => {
    const dispatch = useDispatch()

    const {company, cities, banks, setDirty, setLoading, okved} = props
    const classes = useStyles()
    const [search, setSearch] = useState(null)
    const debounced = useDebouncedCallback(
        (value) => {
            setSearch(value)
        },
        900
    );
    const {account} = useSelector(state => state.account)
    const [country] = useState(company.legal_detail.city.region.country.code)
    const {attributes, initialValues, validationSchema} = useAttributes(account, country, cities, banks, okved, company, classes)

    const AutoDirty = () => {
        const {dirty} = useFormikContext()

        React.useEffect(() => {
            setDirty(dirty)
        }, [dirty])

        return null
    };

    useEffect(() => {
        if (account && search) {
            const getOkved = async () => {
                let params = {
                    limit: 10,
                    page: 1,
                    ...(search ? {search: search} : {})
                }
                return await dispatch(CompanyActions.okved(params))
            }
            getOkved().then(_ => {
            })
        }
        // eslint-disable-next-line
    }, [search])

    return (
        <Grid item>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object().shape(validationSchema)}
                onSubmit={(values, {setSubmitting}) => {
                    return dispatch(CompanyActions.legalDetail({
                        ...values, ...{
                            code: company.legal_detail.city.region.country.code,
                            type: company.type
                        }
                    })).then(
                        () => {
                            const getAccount = async () => {
                                return dispatch(AccountActions.account()).catch(() => {
                                    history.push("/logout")
                                });
                            }
                            getAccount().then(() => {})
                            setSubmitting(false)
                            setLoading(false)
                        },
                        errors => {
                            if (errors) {
                            }
                            setSubmitting(false)
                        }
                    )
                }}
            >
                {({
                      dirty,
                      values,
                      touched,
                      isSubmitting,
                      isValid,
                      setValues,
                      setFieldValue,
                      setFieldTouched
                  }) => (
                    <Form>
                        <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                            {attributes(values, setFieldValue, setFieldTouched, setValues, touched, search, debounced.callback)}
                        </Grid>
                        <Grid item className={classes.footer}>
                            <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                <Grid item className={classes.footerContent}>
                                    <Grid container className={classes.footerContainer} direction="row"
                                          justify="flex-end" alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Submit
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={isSubmitting || !dirty || !isValid}
                                            >
                                                Сохранить
                                            </Submit>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <AutoDirty/>
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}
