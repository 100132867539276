import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"

import {Grid, makeStyles, Paper, Tab, Tabs, Typography} from "@material-ui/core"

import {AutoActions} from "../../actions/auto"
import {TabPanel} from "./Tabs/TabPanel"
import {Present} from "./Tabs/Panels/Present"
import {Absent} from "./Tabs/Panels/Absent"
import {Defect} from "./Tabs/Panels/Defect"

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    input: {
        "width": "100%",
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
}))

export const OrderContent = () => {
    let {id} = useParams()

    const dispatch = useDispatch()
    const classes = useStyles()

    const {order} = useSelector(state => {
        return {order: state.delivery.auto_order}
    })

    const [tab, setTab] = useState(0)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getOrder = async () => {
            return await dispatch(AutoActions.order(id))
        }

        if (!loading) {
            getOrder().then(_ => {
                setLoading(true)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loading, order])

    return loading ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.fullWidth}>
                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                            <Grid item className={classes.fullWidth}>
                                <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                    <Grid item className={classes.header}>
                                        <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                            <Grid item className={classes.fullWidth}>
                                                <Typography className={classes.title}>
                                                    Заказ № {order.number}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.input}>
                                        <Grid container direction="column" justify="center" alignItems="stretch">
                                            <Grid item>
                                                <Tabs
                                                    value={tab}
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                    onChange={(event, newValue) => {
                                                        setTab(newValue);
                                                    }}
                                                >
                                                    <Tab label="Присутствующие" className={classes.tab}/>
                                                    <Tab label="Отсутствующие" className={classes.tab}/>
                                                    <Tab label="Лист дефектуры" className={classes.tab}/>
                                                </Tabs>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <TabPanel value={tab} index={0}>
                                <Present order={order}/>
                            </TabPanel>
                            <TabPanel value={tab} index={1}>
                                <Absent order={order}/>
                            </TabPanel>
                            <TabPanel value={tab} index={2}>
                                <Defect order={order}/>
                            </TabPanel>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    ) : null
}
