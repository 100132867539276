import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce} from "use-debounce"
import {Field, Form, Formik} from 'formik'
import {TextField} from 'formik-material-ui'

import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    makeStyles,
    MenuItem,
    Typography
} from '@material-ui/core'
import {Clear, ExpandLess, ExpandMore, Close as CloseIcon, Add as AddIcon} from "@material-ui/icons"

import {FieldString} from "./components/Attribute/FieldString"
import {FieldInteger} from "./components/Attribute/FieldInteger"
import {FieldDoubleUnit} from "./components/Attribute/FieldDoubleUnit"
import {FieldIntegerUnit} from "./components/Attribute/FieldIntegerUnit"
import {FieldBoolean} from "./components/Attribute/FieldBoolean"
import {FieldSelect} from "./components/Attribute/FieldSelect"
import {FieldMultiselect} from "./components/Attribute/FieldMultiselect"
import {FieldVolume} from "./components/Attribute/FieldVolume"
import {FieldDictionary} from "./components/Attribute/FieldDictionary"
import {FieldUnit} from "./components/Attribute/FieldUnit"
import {FieldDouble} from "./components/Attribute/FieldDouble"
import {FieldNumber} from "./components/Attribute/FieldNumber"
import {FieldRangeUnit} from "./components/Attribute/FieldRangeUnit"
import {FieldSize} from "./components/Attribute/FieldSize"
import {StandardActions} from "./actions/standard"
import {stringFormat} from "../App/helpers/string"
import {SystemActions} from "../App/actions/system"
import {getFullName} from "../App/helpers/user"
import {FieldCompositeSize} from "./components/Attribute/FieldCompositeSize"
import {FieldCompositeVolume} from "./components/Attribute/FieldCompositeVolume"
import {FieldDosage} from "./components/Attribute/FieldDosage"
import {FieldNumberDefinitions} from "./components/Attribute/FieldNumberDefinitions"
import {FieldNeedle} from "./components/Attribute/FieldNeedle"
import {FileUpload} from "../App/components/Inputs/FileUpload"

const useStyles = makeStyles(() => ({
    dialog: {
        'border-radius': 0,
    },
    item: {
        'padding': '8px',
        'width': '100%'
    },
    fullWidth: {
        'width': '100%'
    },
    dialogForm: {
        width: "100%",
        minWidth: "600px",
        maxWidth: "600px",
        minHeight: "500px",
        overflowX: "hidden",
        overflowY: "auto",
    },
    title: {
        "margin": "0",
        "padding-top": "2px",
        "font-size": "18px",
        "font-weight": "600",
        "color": "#fff",
        "text-transform": "uppercase",
        "display": "flex",
        "justify-content": "space-between",
        "align-items": "center",
        "min-height": "40px",
        "padding": "0 20px",
        "background-color": "#898989",
    },
    close: {
        cursor: "pointer"
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
}))

export const StandardForm = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {open, onClose, setOpen, edit, module} = props;
    const { categories, units, account } = useSelector(state => ({...state.system, ...state.account}))
    const { dictionaries } = useSelector(state => state.standard)
    const formCategories = categories.filter(el => el.active)
    const [category, setCategory ] = useState(props.category ? props.category : {})
    const [search, setSearch] = useState({search: null, type: null, attribute: null})
    const [initialize, setInitialize] = useState(false)
    const [searchRequest] = useDebounce(search, 900)
    useEffect(() => {
        if (!initialize) {
            if (!units.length) {
                dispatch(SystemActions.units()).then(() => {
                    setInitialize(true)
                })
            } else {
                setInitialize(true)
            }
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        setCategory(props.category ? props.category : {})
    }, [props.category])
    const [openCategory, setOpenCategory] = useState({})

    const getAttributesFunction = (current, attributes) => {
        current.attributes.forEach((attribute) => {
            switch(attribute.type.key) {
                case 'string':
                    attributes.push({ Field: FieldString, attribute: attribute })
                    break
                case 'integer':
                    attributes.push({ Field: FieldInteger, attribute: attribute })
                    break
                case 'double':
                    attributes.push({ Field: FieldDouble, attribute: attribute })
                    break
                case 'boolean':
                    attributes.push({ Field: FieldBoolean, attribute: attribute })
                    break
                case 'select':
                    attributes.push({ Field: FieldSelect, attribute: attribute })
                    break
                case 'multiselect':
                    attributes.push({ Field: FieldMultiselect, attribute: attribute })
                    break
                case 'dictionary':
                    attributes.push({ Field: FieldDictionary, attribute: attribute })
                    break
                case 'unit':
                    attributes.push({ Field: FieldUnit, attribute: attribute })
                    break
                case 'integer_unit':
                    attributes.push({ Field: FieldIntegerUnit, attribute: attribute })
                    break
                case 'double_unit':
                    attributes.push({ Field: FieldDoubleUnit, attribute: attribute })
                    break
                case 'range_unit':
                    attributes.push({ Field: FieldRangeUnit, attribute: attribute })
                    break
                case 'number':
                    attributes.push({ Field: FieldNumber, attribute: attribute })
                    break
                case 'size':
                    if (attribute.dictionary) {
                        attributes.push({Field: FieldString, attribute: attribute})
                    } else {
                        attributes.push({Field: FieldSize, attribute: attribute})
                    }
                    break
                case 'composite_size':
                    if (attribute.dictionary) {
                        attributes.push({Field: FieldString, attribute: attribute})
                    } else {
                        attributes.push({Field: FieldCompositeSize, attribute: attribute})
                    }
                    break
                case 'volume':
                    if (attribute.dictionary) {
                        attributes.push({Field: FieldString, attribute: attribute})
                    } else {
                        attributes.push({Field: FieldVolume, attribute: attribute})
                    }
                    break
                case 'composite_volume':
                    if (attribute.dictionary) {
                        attributes.push({Field: FieldString, attribute: attribute})
                    } else {
                        attributes.push({Field: FieldCompositeVolume, attribute: attribute})
                    }
                    break;
                case 'dosage':
                    if (attribute.dictionary) {
                        attributes.push({Field: FieldString, attribute: attribute})
                    } else {
                        attributes.push({Field: FieldDosage, attribute: attribute})
                    }
                    break
                case 'number_definitions':
                    if (attribute.dictionary) {
                        attributes.push({Field: FieldString, attribute: attribute})
                    } else {
                        attributes.push({Field: FieldNumberDefinitions, attribute: attribute})
                    }
                    break
                case 'needle':
                    if (attribute.dictionary) {
                        attributes.push({Field: FieldString, attribute: attribute})
                    } else {
                        attributes.push({Field: FieldNeedle, attribute: attribute})
                    }
                    break
                default:
                    break
            }
        })

        return attributes.sort((first, second) => {
            return (!first.attribute.index || (second.attribute.index && (first.attribute.index > second.attribute.index))) ? 1 : ((!second.attribute.index || first.attribute.index < second.attribute.index) ? -1 : 0)
        });
    }

    const [ attributes, setAttributes ] = useState([])

    const getValuesFunction = (current) => {
        let items = {}
        if (current && current.hasOwnProperty('attributes')) {
            current.attributes.forEach((attribute) => {
                switch(attribute.type.key) {
                    case 'string':
                        items[`${attribute.id}`] = attribute.multiple ? [] : ''
                        break
                    case 'integer':
                        items[`${attribute.id}`] = 0
                        break
                    case 'double':
                        items[`${attribute.id}`] = 0.00000
                        break
                    case 'boolean':
                        items[`${attribute.id}`] = false
                        break
                    case 'select':
                        items[`${attribute.id}`] = 0
                        break
                    case 'multiselect':
                        items[`${attribute.id}`] = []
                        break
                    case 'dictionary':
                        items[`${attribute.id}`] = attribute.multiple ? [] : ''
                        break
                    case 'unit':
                        items[`${attribute.id}`] = ''
                        break
                    case 'integer_unit':
                        items[`${attribute.id}`] = {
                            value: '',
                            unit: ''
                        }
                        break
                    case 'double_unit':
                        items[`${attribute.id}`] = {
                            value: '',
                            unit: ''
                        }
                        break
                    case 'range_unit':
                        items[`${attribute.id}`] = {
                            value: '',
                            max: '',
                            unit: ''
                        }
                        break
                    case 'number':
                        items[`${attribute.id}`] = {
                            number: '',
                            form: ''
                        }
                        break
                    case 'size':
                        if (attribute.dictionary) {
                            items[`${attribute.id}`] = ''
                        } else {
                            items[`${attribute.id}`] = {
                                width: '',
                                height: '',
                                depth: {
                                    value: '',
                                    max: ''
                                },
                                unit: '',
                                delimiter: '',
                                symbol: ''
                            }
                        }
                        break
                    case 'composite_size':
                        if (attribute.dictionary) {
                            items[`${attribute.id}`] = ''
                        } else {
                            items[`${attribute.id}`] = {
                                prefix: '',
                                amount: '',
                                size: {
                                    range: '',
                                    charrier: ''
                                },
                                space: '',
                                diameter: {
                                    outer: {
                                        value: '',
                                        unit: '',
                                        delimiter: ''
                                    },
                                    inner: {
                                        value: '',
                                        unit: '',
                                        delimiter: ''
                                    }
                                },
                                lengths: [
                                    {
                                        value: '',
                                        unit: '',
                                        delimiter: ''
                                    }
                                ],
                                width: {
                                    value: '',
                                    unit: '',
                                    delimiter: ''
                                },
                                metric: '',
                                height: '',
                                thickness: '',
                                unit: '',
                                gauge: '',
                                postfix: ''
                            }
                        }
                        break
                    case 'volume':
                        if (attribute.dictionary) {
                            items[`${attribute.id}`] = ''
                        } else {
                            items[`${attribute.id}`] = {
                                volume: '',
                                weight: '',
                                dose: {
                                    value: '',
                                    max: ''
                                },
                                unit: '',
                                delimiter: '',
                                symbol: ''
                            }
                        }
                        break
                    case 'composite_volume':
                        if (attribute.dictionary) {
                            items[`${attribute.id}`] = ''
                        } else {
                            items[`${attribute.id}`] = {
                                prefix: '',
                                values: [
                                    {
                                        amount: '',
                                        delimiter: '',
                                        volume: '',
                                        weight: '',
                                        dose: '',
                                        unit: '',
                                        space: ''
                                    }
                                ],
                                postfix: ''
                            }
                        }
                        break
                    case 'dosage':
                        if (attribute.dictionary) {
                            items[`${attribute.id}`] = ''
                        } else {
                            items[`${attribute.id}`] = {
                                prefix: '',
                                values: [
                                    {
                                        amount: '',
                                        unit: '',
                                        delimiter: ''
                                    }
                                ],
                                volume: {
                                    value: '',
                                    unit: '',
                                    delimiter: ''
                                },
                                description: '',
                                initial: ''
                            }
                        }
                        break
                    case 'number_definitions':
                        if (attribute.dictionary) {
                            items[`${attribute.id}`] = ''
                        } else {
                            items[`${attribute.id}`] = {
                                values: [
                                    {
                                        amount: '',
                                        definitions: '',
                                        unit: '',
                                        delimiter: ''
                                    }
                                ]
                            }
                        }
                        break
                    case 'needle':
                        if (attribute.dictionary) {
                            items[`${attribute.id}`] = ''
                        } else {
                            items[`${attribute.id}`] = {
                                information: '',
                                amount: '',
                                gauge: {
                                    value: '',
                                    additional: ''
                                },
                                diameter: {
                                    value: '',
                                    delimiter: ''
                                },
                                length: {
                                    value: '',
                                    unit: '',
                                    delimiter: ''
                                },
                                bend: '',
                                description: ''
                            }
                        }
                        break
                    default:
                        break
                }
            })
        }

        return items
    }

    const [valuesItems] = useState(() => {
        let items = {}

        if (props.category) {
            const current = formCategories.find((element) => {
                return element.id === props.category.id
            })
            items = getValuesFunction(current)

            if (current?.category) {
                let parent = formCategories.find(cat => (cat.id === current.category.id))

                while (parent) {
                    let tmp = []

                    items = Object.assign(items, getValuesFunction(parent))

                    // eslint-disable-next-line no-loop-func
                    parent = parent.category ? formCategories.find(cat => (cat.id === parent.category.id)) : null
                }
            }
        }
        return items
    })

    const attribute = (item, items= [], values= [], errors= [], setFieldValue, setFieldTouched, setSearch = () => {}, isSubmitting= false, disabled = false) => {
        const { Field, attribute } = item

        return <Field
            id={ attribute.id }
            label={ attribute.name }
            addition={ attribute.addition }
            attribute={ attribute }
            attributes={ attributes.map(item => item.attribute) }
            params={ attribute.params }
            items={ items }
            value={ values[attribute.id] }
            values={ values }
            errors={ errors }
            setFieldValue={ setFieldValue }
            setFieldTouched={ setFieldTouched }
            setSearch={ setSearch }
            isSubmitting={ isSubmitting }
            disabled={disabled}
        />
    }

    const getItems = (attribute) => {
        switch (attribute.type.key) {
            case 'dictionary':
                if (attribute.value) {
                    return dictionaries.items
                }
                break
            case 'select':
                return attribute.options.map(option => { return { id: option.id, name: option.option } })
            case 'multiselect':
                return attribute.options.map(option => { return { id: option.id, name: option.option } })
            case 'integer_unit':
            case 'double_unit':
            case 'range_unit':
            case 'unit':
            case 'size':
            case 'composite_size':
            case 'volume':
            case 'composite_volume':
            case 'number_definitions':
            case 'dosage':
            case 'needle':
                return units
            default:
                return []
        }
    }

    const standard = (values) => {
        let name = ''

        attributes.filter(item => (item.attribute.index && item.attribute.standard)).sort((first, second) => {
            return (first.attribute.index > second.attribute.index) ? 1 : ((first.attribute.index < second.attribute.index) ? -1 : 0)
        }).forEach((item) => {
            const attribute = item.attribute
            let val = ''

            if (values.hasOwnProperty(attribute.id)) {
                switch (attribute.type.key) {
                    case 'integer_unit':
                    case 'double_unit':
                        for (const key in values[attribute.id]) {
                            if (values[attribute.id].hasOwnProperty(key)) {
                                val += val.length ? `${attribute.space ? ' ' : ''}${values[attribute.id][key] ?? ''}` : (values[attribute.id][key] ?? '')
                            }
                        }
                        break
                    case 'range_unit':
                        val = `${values[attribute.id].value}${values[attribute.id].max ? ` - ${values[attribute.id].max}` : ''}${attribute.space ? ' ' : ''}${values[attribute.id].unit ?? ''}`

                        break
                    case 'multiselect':
                        attribute.options.filter(option => values[attribute.id].includes(option.id)).forEach(option => {
                            val = val.length ? `${val}, ${option.option}` : option.option
                        })
                        break
                    case 'select':
                        const option = attribute.options.find(option => (option.id === parseInt(values[attribute.id])))
                        if (option) {
                            val = option.option
                        }
                        break
                    case 'number':
                        if (values[attribute.id]['number']) {
                            val = values[attribute.id]['number']
                            if (values[attribute.id]['form']) {
                                val = `${val}x${values[attribute.id]['form']}`
                            }
                        }

                        if (val) {
                            val = attribute.addition ? (attribute.addition.prefix.value ? `${attribute.addition.prefix.value}${attribute.addition.prefix.space ? ' ' : ''}` : '') + val + (attribute.addition.postfix.value ? `${attribute.addition.postfix.space ? ' ' : ''}${attribute.addition.postfix.value}` : '') : val
                        }
                        break
                    case 'size':
                        val = attribute.dictionary ? values[attribute.id] : `${values[attribute.id].width ?? ''}${((values[attribute.id].width && (values[attribute.id].height || values[attribute.id].depth.value)) ? (values[attribute.id].delimiter ? values[attribute.id].delimiter : 'x') : '')}${values[attribute.id].height ?? ''}${((values[attribute.id].height && values[attribute.id].depth.value) ? (values[attribute.id].delimiter ? values[attribute.id].delimiter : 'x') : '')}${values[attribute.id].depth.value ?? ''}${((values[attribute.id].depth.value && values[attribute.id].depth.max) ? `-${values[attribute.id].depth.max}` : '')}${(values[attribute.id].unit && attribute.space) ? ' ' : ''}${values[attribute.id].unit ?? ''} ${values[attribute.id].symbol ?? ''}`

                        break
                    case 'composite_size':
                        val = attribute.dictionary ? values[attribute.id] : `${values[attribute.id].prefix ?? ''} ${values[attribute.id].amount ?? ''} ${values[attribute.id].size.range ?? ''} ${values[attribute.id].space ?? ''} ${values[attribute.id].size.charrier ?? ''} ${values[attribute.id].diameter.outer.value ?? ''} ${values[attribute.id].diameter.outer.unit ?? ''}${values[attribute.id].diameter.outer.delimiter ?? ' '}${values[attribute.id].lengths.reduce((str, length) => { return `${str} ${length.value ?? ''} ${length.unit ?? ''}${length.delimiter ?? ' '}` }, '')}${values[attribute.id].width.value ?? ''} ${values[attribute.id].width.unit ?? ''}${values[attribute.id].width.delimiter ?? ' '}${values[attribute.id].metric ?? ''} ${values[attribute.id].height ?? ''} ${values[attribute.id].thickness ?? ''} ${values[attribute.id].diameter.inner.value ?? ''} ${values[attribute.id].diameter.inner.unit ?? ''}${values[attribute.id].diameter.inner.delimiter ?? ' '}${values[attribute.id].unit ?? ''} ${values[attribute.id].gauge ?? ''} ${values[attribute.id].postfix ?? ''}`

                        break
                    case 'volume':
                        val = attribute.dictionary ? values[attribute.id] : `${values[attribute.id].volume ?? ''}${((values[attribute.id].volume && (values[attribute.id].weight || values[attribute.id].dose.value)) ? (values[attribute.id].delimiter ? values[attribute.id].delimiter : 'x') : '')}${values[attribute.id].weight ?? ''}${((values[attribute.id].weight && values[attribute.id].dose.value) ? (values[attribute.id].delimiter ? values[attribute.id].delimiter : 'x') : '')}${values[attribute.id].dose.value ?? ''}${((values[attribute.id].dose.value && values[attribute.id].dose.max) ? `-${values[attribute.id].dose.max}` : '')}${(values[attribute.id].unit && attribute.space) ? ' ' : ''}${values[attribute.id].unit ?? ''} ${values[attribute.id].symbol ?? ''}`

                        break
                    case 'composite_volume':
                        val = attribute.dictionary ? values[attribute.id] : `${values[attribute.id].prefix ?? ''} ${values[attribute.id].values.reduce((str, value) => { return `${str} ${value.amount ?? ''}${value.delimiter ?? ' '}${value.volume ?? ''} ${value.weight ?? ''} ${value.dose ?? ''} ${value.unit ?? ''} ${value.space ?? ''}` }, '')} ${values[attribute.id].postfix ?? ''}`

                        break
                    case 'dosage':
                        val = attribute.dictionary ? values[attribute.id] : `${values[attribute.id].prefix ?? ''}${values[attribute.id].values.reduce((str, value) => { return `${str} ${value.amount ?? ''} ${value.unit ?? ''}${value.delimiter ?? ' '}` }, '')}${values[attribute.id].volume.delimiter ?? ' '}${values[attribute.id].volume.value ?? ''} ${values[attribute.id].volume.unit ?? ''} ${values[attribute.id].description ?? ''} ${values[attribute.id].initial ?? ''}`

                        break
                    case 'number_definitions':
                        val = attribute.dictionary ? values[attribute.id] : `${values[attribute.id].values.reduce((str, value) => { return `${str} ${value.amount ?? ''} ${value.definitions ?? ''} ${value.unit ?? ''}${value.delimiter ?? ' '}` }, '')}`

                        break
                    case 'needle':
                        val = attribute.dictionary ? values[attribute.id] : `${values[attribute.id].information ?? ''} ${values[attribute.id].gauge.value ?? ''} ${values[attribute.id].diameter.value ?? ''}${values[attribute.id].diameter.delimiter ?? ' '}${values[attribute.id].length.value ?? ''} ${values[attribute.id].length.unit ?? ''}${values[attribute.id].length.delimiter ?? ' '}${values[attribute.id].amount ?? ''} ${values[attribute.id].gauge.additional ?? ''} ${values[attribute.id].bend ?? ''} ${values[attribute.id].description ?? ''}`

                        break
                    case 'integer':
                        if (values[attribute.id]) {
                            val = attribute.addition ? (attribute.addition.prefix.value ? `${attribute.addition.prefix.value}${attribute.addition.prefix.space ? ' ' : ''}` : '') + parseInt(values[attribute.id]) + (attribute.addition.postfix.value ? `${attribute.addition.postfix.space ? ' ' : ''}${attribute.addition.postfix.value}` : '') : parseInt(values[attribute.id])
                        }
                        break
                    case 'double':
                        if (values[attribute.id]) {
                            val = attribute.addition ? (attribute.addition.prefix.value ? `${attribute.addition.prefix.value}${attribute.addition.prefix.space ? ' ' : ''}` : '') + parseFloat(values[attribute.id]) + (attribute.addition.postfix.value ? `${attribute.addition.postfix.space ? ' ' : ''}${attribute.addition.postfix.value}` : '') : parseFloat(values[attribute.id])
                        }
                        break
                    case 'string':
                        if (values[attribute.id]) {
                            val = attribute.addition ? (attribute.addition.prefix.value ? `${attribute.addition.prefix.value}${attribute.addition.prefix.space ? ' ' : ''}` : '') + attribute.multiple ? values[attribute.id].join(',') : values[attribute.id] + (attribute.addition.postfix.value ? `${attribute.addition.postfix.space ? ' ' : ''}${attribute.addition.postfix.value}` : '') : attribute.multiple ? values[attribute.id].join(',') : values[attribute.id]
                        }
                        break
                    case 'dictionary':
                        val = attribute.multiple ? values[attribute.id].join(',') : values[attribute.id];
                        break
                    default:
                        val = values[attribute.id]
                        break
                }
            }

            // eslint-disable-next-line default-case
            switch (attribute.name) {
                case 'Дозировка':
                case 'Объем, вес и дозы':
                case 'Вес, объем':
                case 'Фасовка':
                case 'Количество':
                case 'Фасовка/Количество':
                case 'Концентрация':
                case 'Игла':
                case 'Плотность/жесткость':
                    val = stringFormat(String(val))
            }

            if (val) {
                name = `${name} ${val}`
            }
        })

        return name ?? null
    }

    const assembly = (categories, parent = 0, level = 0) => {
        let result = []

        if (categories.hasOwnProperty(parent)) {
            categories[parent].forEach(category => {
                result.push(
                    <MenuItem key={ category.id } value={ category } style={{ paddingLeft: `${16 * (level + 1)}px`}}>
                        {categories.hasOwnProperty(category.id) &&
                        <ListItemIcon className={ classes.listItemIcon }>
                            <IconButton
                                size="small"
                                onClick={e => {
                                    e.stopPropagation()
                                    if (categories.hasOwnProperty(category.id)) {
                                        setOpenCategory({...openCategory, ...{[category.id]: openCategory.hasOwnProperty(category.id) ? !openCategory[category.id] : true}})
                                    }
                                }}
                            >
                                {(openCategory.hasOwnProperty(category.id) && openCategory[category.id]) ? <ExpandLess/> :
                                    <ExpandMore/>}
                            </IconButton>
                        </ListItemIcon>
                        }
                        <ListItemText className={ categories.hasOwnProperty(category.id) ? classes.listItemTextWithIcon : classes.listItemText } primary={ !level ? <Typography variant="body1">{category.name}</Typography> : <Typography variant="body2">{category.name}</Typography> } />
                    </MenuItem>
                )

                const childes = assembly(categories, category.id, level + 1)

                if (!!childes.length && openCategory.hasOwnProperty(category.id) && openCategory[category.id]) {
                    result = result.concat([
                        childes.map(row => row)
                    ])
                }
            })
        }

        return result
    }

    const getCategoriesTree = categories => {
        let tmp = {}
        categories.forEach(category => {
            if (!tmp.hasOwnProperty((category.category !== null) ? category.category.id : 0)) {
                tmp[(category.category !== null) ? category.category.id : 0] = []
            }

            tmp[(category.category !== null) ? category.category.id : 0].push(category)
        })

        return assembly(tmp)
    }

    const getAttributes = () => {
        let attributesLocal = []
        let current = null
        if (props.category) {
            current = formCategories.find((element) => {
                return element.id === props.category.id
            })
            if (current && current.hasOwnProperty('category') && current.category) {
                let parent = formCategories.find(cat => (cat.id === current.category.id))

                while (parent) {
                    let tmp = []

                    tmp = getAttributesFunction(parent, tmp)

                    attributesLocal = tmp.concat(attributesLocal)

                    // eslint-disable-next-line no-loop-func
                    parent = parent.category ? formCategories.find(cat => (cat.id === parent.category.id)) : null
                }
            }
        }

        return [attributesLocal, current]
    }

    useEffect(() => {
        if (searchRequest.type) {
            if (searchRequest.search) {
                let listeners = Object.entries(search.listeners).reduce((result, [id, value]) => {
                    result[`listeners[${id}]`] = value

                    return result
                }, {})

                dispatch(StandardActions.dictionary(searchRequest.type, {...{search: searchRequest.search, status: ['active']}, ...(!!Object.keys(listeners).length ? listeners : {}), ...{limit: 20}}))
            }
        } else if (searchRequest.attribute) {
            if (searchRequest.search) {
                dispatch(StandardActions.attributes(searchRequest.attribute, {...{search: searchRequest.search}, ...{limit: 20}}))
            }
        }
        // eslint-disable-next-line
    }, [searchRequest])

    useEffect(() => {
        if (search.type) {
            if (!search.search) {
                dispatch({type: 'STANDARD_DICTIONARY_ITEMS_CLEAR'})
            }
        } else if (search.attribute) {
            if (!search.search) {
                dispatch({type: 'STANDARD_ATTRIBUTE_ITEMS_CLEAR'})
            }
        }
        // eslint-disable-next-line
    }, [search])

    useEffect(() => {
        const [attributesLocal, current] = getAttributes()
        if (current) {
            setAttributes(getAttributesFunction(current, attributesLocal))
        }
        // eslint-disable-next-line
    }, [props.category])

    const getCompanyType = (type) => {
        switch (type) {
            case 'individual':
            case 'self':
            case 'legal':
                return 'supplier'
            default:
                return 'customer'
        }
    }

    return (
        <Dialog
            open={!!open}
            onClose={onClose}
            className={classes.dialog}
            maxWidth={'xl'}
        >
            <DialogTitle disableTypography={true} classes={{root: classes.title}}>
                Предложить эталон<CloseIcon className={classes.close} onClick={() => setOpen(false)}/>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Formik
                    initialValues = {{
                        category: Object.keys(category).length ? category : "",
                        attributes: valuesItems,
                        images: [],
                        source: {
                            type: getCompanyType(account.company ? account.company.type : null),
                            company: account.company ? account.company.legal_detail.name : 'ЦДЗ',
                            login: account.login,
                            user: getFullName(account),
                            module: module ?? null,
                            contacts: {
                                emails: account.company && account.company.contact_information ? account.company.contact_information.emails : null,
                                phones:account.company && account.company.contact_information ? account.company.contact_information.phones : null
                            }
                        }
                    }}
                    validate = {values => {
                        const errors = {}

                        if (!values.category) {
                            errors.category = 'Выберите категорию'
                        }

                        if (!!values.images.length) {
                            if (values.images.length > 15) {
                                errors.images = 'Максимальное количество файлов <= 15'
                            }

                            values.images.forEach(image => {
                                if (!['image/jpeg', 'image/gif', 'image/svg+xml', 'image/bmp', 'image/png', 'application/pdf',].includes(image.type)) {
                                    errors.images = 'Изображения должны быть следующих типов: jpg, jpeg, png, bmp, gif, svg, pdf.'
                                }
                            })
                        }

                        errors.attributes = {}

                        if (!!category && !!Object.keys(category).length) {
                            attributes.map(item => item.attribute).forEach(attribute => {
                                if ((attribute.type.key !== 'boolean') && !values.attributes[`${attribute.id}`] && !!attribute.required) {
                                    errors.attributes[`${attribute.id}`] = `Введите ${attribute.name.toLowerCase()}`
                                }

                                if (attribute.type.key === 'multiselect') {
                                    if (!!attribute.required && !values.attributes[`${attribute.id}`].length) {
                                        errors.attributes[`${attribute.id}`] = `Выберите ${attribute.name.toLowerCase()}`
                                    }
                                }

                                if (attribute.type.key === 'select') {
                                    if (!!attribute.required && !values.attributes.hasOwnProperty(`${attribute.id}`)) {
                                        errors.attributes[`${attribute.id}`] = `Выберите ${attribute.name.toLowerCase()}`
                                    }
                                }

                                if (attribute.type.key === 'range_unit') {
                                    if (!!attribute.required && values.attributes.hasOwnProperty(`${attribute.id}`)) {
                                        if (!values.attributes[attribute.id].width) {
                                            if (!errors.attributes.hasOwnProperty(`${attribute.id}`)) {
                                                errors.attributes[`${attribute.id}`] = {}
                                            }

                                            errors.attributes[`${attribute.id}`].value = `Введите значение`
                                        }

                                        if (!values.attributes[attribute.id].unit || !values.attributes[attribute.id].unit.length) {
                                            if (!errors.attributes.hasOwnProperty(`${attribute.id}`)) {
                                                errors.attributes[`${attribute.id}`] = {}
                                            }

                                            errors.attributes[`${attribute.id}`].unit = `Укажите ед. изм.`
                                        }
                                    }
                                }

                                if (attribute.type.key === 'size') {
                                    if (!!attribute.required && values.attributes.hasOwnProperty(`${attribute.id}`)) {
                                        if (attribute.dictionary && !values.attributes[attribute.id]) {
                                            errors.attributes[`${attribute.id}`] = `Введите ${attribute.name}`
                                        } else {
                                            if (!values.attributes[attribute.id].width) {
                                                if (!errors.attributes.hasOwnProperty(`${attribute.id}`)) {
                                                    errors.attributes[`${attribute.id}`] = {}
                                                }

                                                errors.attributes[`${attribute.id}`]['width'] = `Введите ширину`
                                            }

                                            if (!values.attributes[attribute.id].height) {
                                                if (!errors.attributes.hasOwnProperty(`${attribute.id}`)) {
                                                    errors.attributes[`${attribute.id}`] = {}
                                                }

                                                errors.attributes[`${attribute.id}`]['height'] = `Введите высоту`
                                            }

                                            if (!values.attributes[attribute.id].depth.value) {
                                                if (!errors.attributes.hasOwnProperty(`${attribute.id}`)) {
                                                    errors.attributes[`${attribute.id}`] = {}
                                                }

                                                errors.attributes[`${attribute.id}`]['depth'] = {value: `Введите глубину`}
                                            }

                                            if (!values.attributes[attribute.id].unit || !values.attributes[attribute.id].unit.length) {
                                                if (!errors.attributes.hasOwnProperty(`${attribute.id}`)) {
                                                    errors.attributes[`${attribute.id}`] = {}
                                                }

                                                errors.attributes[`${attribute.id}`]['unit'] = `Укажите ед. изм.`
                                            }
                                        }
                                    }
                                }

                                if (attribute.type.key === 'volume') {
                                    if (!!attribute.required && values.attributes.hasOwnProperty(`${attribute.id}`)) {
                                        if (attribute.dictionary && !values.attributes[attribute.id]) {
                                            errors.attributes[`${attribute.id}`] = `Введите ${attribute.name}`
                                        } else {
                                            if (!values.attributes[attribute.id].volume) {
                                                if (!errors.attributes.hasOwnProperty(`${attribute.id}`)) {
                                                    errors.attributes[`${attribute.id}`] = {}
                                                }

                                                errors.attributes[`${attribute.id}`]['volume'] = `Введите объём`
                                            }

                                            if (!values.attributes[attribute.id].weight) {
                                                if (!errors.attributes.hasOwnProperty(`${attribute.id}`)) {
                                                    errors.attributes[`${attribute.id}`] = {}
                                                }

                                                errors.attributes[`${attribute.id}`]['weight'] = `Введите вес`
                                            }

                                            if (!values.attributes[attribute.id].dose.value) {
                                                if (!errors.attributes.hasOwnProperty(`${attribute.id}`)) {
                                                    errors.attributes[`${attribute.id}`] = {}
                                                }

                                                errors.attributes[`${attribute.id}`]['dose'] = {value: `Введите дозу`}
                                            }

                                            if (!values.attributes[attribute.id].unit || !values.attributes[attribute.id].unit.length) {
                                                if (!errors.attributes.hasOwnProperty(`${attribute.id}`)) {
                                                    errors.attributes[`${attribute.id}`] = {}
                                                }

                                                errors.attributes[`${attribute.id}`]['unit'] = `Укажите ед. изм.`
                                            }
                                        }
                                    }
                                }
                            })
                        }

                        if (!Object.keys(errors.attributes).length) {
                            delete errors.attributes
                        }

                        return errors
                    }}
                    onSubmit = {(values, { setSubmitting, setErrors }) => {
                        const formData = new FormData()

                        for (const name in values) {
                            switch (name) {
                                case 'attributes':
                                    Object.keys(values[name]).forEach(id => {
                                        let attribute = attributes.find(({Field, attribute}) => (parseInt(attribute.id) === parseInt(id)))?.attribute

                                        if (attribute) {
                                            switch (attribute.type.key) {
                                                case 'multiselect':
                                                    values[name][id].forEach((value, index) => {
                                                        formData.append(`${name}[${id}][${index}]`, value ?? '')
                                                    })
                                                    break
                                                case 'integer_unit':
                                                case 'double_unit':
                                                case 'range_unit':
                                                case 'number':
                                                    Object.keys(values[name][id]).forEach(key => {
                                                        formData.append(`${name}[${id}][${key}]`, values[name][id][key] ?? '')
                                                    })
                                                    break
                                                case 'size':
                                                    if (attribute.dictionary) {
                                                        formData.append(`${name}[${id}]`, values[name][id] ?? '')
                                                    } else {
                                                        Object.keys(values[name][id]).forEach(key => {
                                                            switch (key) {
                                                                case 'depth':
                                                                    Object.keys(values[name][id][key]).forEach(i => {
                                                                        formData.append(`${name}[${id}][${key}][${i}]`, values[name][id][key][i] ?? '')
                                                                    })
                                                                    break
                                                                default:
                                                                    formData.append(`${name}[${id}][${key}]`, values[name][id][key] ?? '')
                                                            }
                                                        })
                                                    }
                                                    break
                                                case 'composite_size':
                                                    if (attribute.dictionary) {
                                                        formData.append(`${name}[${id}]`, values[name][id] ?? '')
                                                    } else {
                                                        Object.keys(values[name][id]).forEach(key => {
                                                            switch (key) {
                                                                case 'width':
                                                                case 'size':
                                                                    Object.keys(values[name][id][key]).forEach(i => {
                                                                        formData.append(`${name}[${id}][${key}][${i}]`, values[name][id][key][i] ?? '')
                                                                    })
                                                                    break
                                                                case 'diameter':
                                                                    Object.keys(values[name][id][key]).forEach(i => {
                                                                        Object.keys(values[name][id][key][i]).forEach(j => {
                                                                            formData.append(`${name}[${id}][${key}][${i}][${j}]`, values[name][id][key][i][j] ?? '')
                                                                        })
                                                                    })
                                                                    break
                                                                case 'lengths':
                                                                    values[name][id][key].forEach((obj, index) => {
                                                                        Object.keys(values[name][id][key][index]).forEach(i => {
                                                                            formData.append(`${name}[${id}][${key}][${index}][${i}]`, values[name][id][key][index][i] ?? '')
                                                                        })
                                                                    })
                                                                    break
                                                                default:
                                                                    formData.append(`${name}[${id}][${key}]`, values[name][id][key] ?? '')
                                                            }
                                                        })
                                                    }
                                                    break
                                                case 'volume':
                                                    if (attribute.dictionary) {
                                                        formData.append(`${name}[${id}]`, values[name][id] ?? '')
                                                    } else {
                                                        Object.keys(values[name][id]).forEach(key => {
                                                            switch (key) {
                                                                case 'dose':
                                                                    Object.keys(values[name][id][key]).forEach(i => {
                                                                        formData.append(`${name}[${id}][${key}][${i}]`, values[name][id][key][i] ?? '')
                                                                    })
                                                                    break
                                                                default:
                                                                    formData.append(`${name}[${id}][${key}]`, values[name][id][key] ?? '')
                                                            }
                                                        })
                                                    }
                                                    break
                                                case 'composite_volume':
                                                case 'number_definitions':
                                                    if (attribute.dictionary) {
                                                        formData.append(`${name}[${id}]`, values[name][id])
                                                    } else {
                                                        Object.keys(values[name][id]).forEach(key => {
                                                            switch (key) {
                                                                case 'values':
                                                                    values[name][id][key].forEach((obj, index) => {
                                                                        Object.keys(values[name][id][key][index]).forEach(i => {
                                                                            formData.append(`${name}[${id}][${key}][${index}][${i}]`, values[name][id][key][index][i] ?? '')
                                                                        })
                                                                    })
                                                                    break
                                                                default:
                                                                    formData.append(`${name}[${id}][${key}]`, values[name][id][key] ?? '')
                                                            }
                                                        })
                                                    }
                                                    break
                                                case 'dosage':
                                                    if (attribute.dictionary) {
                                                        formData.append(`${name}[${id}]`, values[name][id] ?? '')
                                                    } else {
                                                        Object.keys(values[name][id]).forEach(key => {
                                                            switch (key) {
                                                                case 'volume':
                                                                    Object.keys(values[name][id][key]).forEach(i => {
                                                                        formData.append(`${name}[${id}][${key}][${i}]`, values[name][id][key][i] ?? '')
                                                                    })
                                                                    break
                                                                case 'values':
                                                                    values[name][id][key].forEach((obj, index) => {
                                                                        Object.keys(values[name][id][key][index]).forEach(i => {
                                                                            formData.append(`${name}[${id}][${key}][${index}][${i}]`, values[name][id][key][index][i] ?? '')
                                                                        })
                                                                    })
                                                                    break
                                                                default:
                                                                    formData.append(`${name}[${id}][${key}]`, values[name][id][key] ?? '')
                                                            }
                                                        })
                                                    }
                                                    break
                                                case 'needle':
                                                    if (attribute.dictionary) {
                                                        formData.append(`${name}[${id}]`, values[name][id] ?? '')
                                                    } else {
                                                        Object.keys(values[name][id]).forEach(key => {
                                                            switch (key) {
                                                                case 'gauge':
                                                                case 'diameter':
                                                                case 'length':
                                                                    Object.keys(values[name][id][key]).forEach(i => {
                                                                        formData.append(`${name}[${id}][${key}][${i}]`, values[name][id][key][i] ?? '')
                                                                    })
                                                                    break
                                                                default:
                                                                    formData.append(`${name}[${id}][${key}]`, values[name][id][key] ?? '')
                                                            }
                                                        })
                                                    }
                                                    break
                                                case 'dictionary':
                                                    if (attribute.multiple) {
                                                        values[name][id].forEach((value, index) => {
                                                            formData.append(`${name}[${id}][${index}]`, value ?? '')
                                                        })
                                                    } else {
                                                        formData.append(`${name}[${id}]`, values[name][id] ?? '')
                                                    }
                                                    break;
                                                case 'string':
                                                    if (attribute.multiple) {
                                                        values[name][id].forEach((value, index) => {
                                                            formData.append(`${name}[${id}][${index}]`, value ?? '')
                                                        })
                                                    } else {
                                                        formData.append(`${name}[${id}]`, values[name][id] ?? '')
                                                    }
                                                    break;
                                                default:
                                                    formData.append(`${name}[${id}]`, values[name][id] ?? '')
                                            }
                                        }
                                    })
                                    break
                                case 'category':
                                    formData.append(`${name}[id]`, values[name].id)
                                    break
                                case 'images':
                                    if (values[name].length) {
                                        values[name].forEach((image, index) => {
                                            formData.append(`${name}[${index}]`, image)
                                        })
                                    }
                                    break
                                case 'source':
                                    Object.keys(values[name]).forEach(key => {
                                        switch (key) {
                                            case 'contacts':
                                                Object.keys(values[name][key]).forEach(type => {
                                                    if (values[name][key][type]) {
                                                        switch (type) {
                                                            case 'phones':
                                                                values[name][key][type].forEach((obj, index) => {
                                                                    Object.keys(obj).forEach(i => {
                                                                        formData.append(`${name}[${key}][${type}][${index}][${i}]`, values[name][key][type][index][i] ?? '')
                                                                    })
                                                                })
                                                                break
                                                            default:
                                                                formData.append(`${name}[${key}][${type}][]`, values[name][key][type] ?? '')
                                                        }
                                                    } else {
                                                        formData.append(`${name}[${key}][${type}]`, null)
                                                    }
                                                })
                                                break
                                            default:
                                                formData.append(`${name}[${key}]`, values[name][key])
                                        }
                                    })
                                    break
                                default:
                                    formData.append(`${name}`, values[name])
                            }
                        }

                        return dispatch(StandardActions.standard(formData)).then(
                            standard => {
                                props.onAddStandard(standard)
                                setSubmitting(false);
                                setOpen(false)
                            },
                            error => {
                                if (error.hasOwnProperty('errors')) {
                                    setErrors({
                                        attributes: error.errors
                                    })
                                }
                                setSubmitting(false);
                            }
                       )
                    }}
                >
                    {({
                          values,
                          errors,
                          handleSubmit,
                          setFieldValue,
                          setFieldTouched,
                          setValues,
                          isSubmitting
                      }) => (
                        <Form className={classes.dialogForm}>
                            <Grid container direction='column' justify='center' alignItems='center'>
                                <Grid item sm={12} className={classes.item}>
                                    <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                        <Grid item xs={10} className={classes.fullWidth}>
                                            <Typography variant="caption" style={{ color: "rgba(0, 0, 0, 0.54)" }}>Эталон</Typography>
                                            <Typography>{standard(values.attributes)}</Typography>
                                        </Grid>
                                        <Grid item sm={10} className={classes.fullWidth}>
                                            <Field
                                                fullWidth
                                                type="text"
                                                name="category"
                                                label="Категория"
                                                disabled={Boolean(props.category && !edit)}
                                                select
                                                variant="standard"
                                                component={ TextField }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    onChange: (e) => {
                                                        let current = e.target.value
                                                        let attributes = []
                                                        let attributeValues = {}

                                                        if (current?.category) {
                                                            let parent = formCategories.find(cat => (cat.id === current.category.id))

                                                            while (parent) {
                                                                let tmp = []

                                                                tmp = getAttributesFunction(parent, tmp)
                                                                attributeValues = Object.assign(attributeValues, getValuesFunction(parent))

                                                                attributes = tmp.concat(attributes)

                                                                // eslint-disable-next-line no-loop-func
                                                                parent = parent.category ? formCategories.find(cat => (cat.id === parent.category.id)) : null
                                                            }
                                                        }

                                                        setCategory(current)
                                                        setValues({
                                                            ...values,
                                                            ...{category: current},
                                                            ...{attributes: Object.assign(attributeValues, getValuesFunction(current))}
                                                        })
                                                        setAttributes(getAttributesFunction(current, attributes))
                                                    }
                                                }}
                                                inputProps={{
                                                    renderValue: (value) => value.name
                                                }}
                                            >
                                                {
                                                    getCategoriesTree(formCategories).map(el => el)
                                                }
                                            </Field>
                                        </Grid>
                                        {
                                            attributes.map((item, index) => (
                                                <Grid item sm={10} key={index} className={classes.fullWidth}>
                                                    {attribute(
                                                        item,
                                                        getItems(item.attribute),
                                                        values.attributes,
                                                        errors,
                                                        setFieldValue,
                                                        setFieldTouched,
                                                        setSearch,
                                                        isSubmitting,
                                                        false
                                                    )}
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                                {!!values.images.length &&
                                    <Grid item sm={10} className={classes.item}>
                                        <Grid container direction='row' justify='flex-start' alignItems='stretch'>
                                            {values.images.map((image, index) => (
                                                <Grid item xs={6} key={index}>
                                                    <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                        <Grid item xs={10}>
                                                            <Typography noWrap>{image.name}</Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <IconButton
                                                                disabled={isSubmitting}
                                                                size={"small"}
                                                                onClick={() => {
                                                                    setFieldValue('images', (values.images ?? []).filter((_, i) => i !== index))
                                                                }}
                                                            >
                                                                <Clear />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item sm={10} className={classes.item}>
                                    <Grid container direction='row' justify='flex-start' alignItems='stretch'>
                                        <Grid item>
                                            <Field
                                                fullWidth
                                                size="small"
                                                component={(props) => (
                                                    <FileUpload {...props} multiple endIcon={<AddIcon/>} />
                                                )}
                                                label="Добавить изображение"
                                                name={`images`}
                                                InputLabelProps={{
                                                    name: null
                                                }}
                                                InputProps={{
                                                    multiple: true,
                                                    onChange: (event) => {
                                                        setFieldValue(`images`, (values.images ?? []).concat(Array.from(event.currentTarget.files)));
                                                        setFieldTouched(`images`, true, false)
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={10} className={classes.item}>
                                    <Grid container direction='row' justify='flex-end' alignItems='center'>
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                disabled={ isSubmitting }
                                                onClick={ handleSubmit }
                                                type="submit"
                                            >
                                                { isSubmitting ? <CircularProgress size={24} /> : 'Предложить' }
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}
