import {PriceService} from "../../Price/services/price"

export const ProductsActions = {
    prices
}

function prices(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DISCOUNT_PRICES_REQUEST"})

        PriceService.prices(params)
            .then(
                prices => {
                    dispatch({type: "DISCOUNT_PRICES_SUCCESS", payload: prices})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DISCOUNT_PRICES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
