import {LotService} from "../services/lot"

export const LotActions = {
    addLot,
    editLot,
    getLot,
    deleteLots,
    lotsInPurchase,
    lots,
    lotsFilter
}


function getLot(purchaseID, lotID) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_LOT_REQUEST"})

        LotService.getLot(purchaseID, lotID)
            .then(
                lot => {
                    dispatch({type: "PURCHASE_LOT_SUCCESS", payload: lot})
                    dispatch({type: "LOADING", payload: false})
                    resolve(lot.lot)
                },
                error => {
                    dispatch({type: "PURCHASE_LOT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )
    })
}

function addLot(purchaseID, formData) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "ADD_PURCHASE_LOT_ADD_REQUEST"})

        LotService.addLot(purchaseID, formData)
            .then(
                resp => {
                    dispatch({type: "ADD_PURCHASE_LOT_ADD_SUCCESS", payload: resp})
                    dispatch({type: "LOADING", payload: false})
                    resolve(resp)
                },
                error => {
                    dispatch({type: "ADD_PURCHASE_LOT_ADD_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function editLot(purchaseID, lotID, formData) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "ADD_PURCHASE_LOT_EDIT_REQUEST"})

        LotService.editLot(purchaseID, lotID, formData)
            .then(
                contract => {
                    dispatch({type: "ADD_PURCHASE_LOT_EDIT_SUCCESS", payload: contract})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "ADD_PURCHASE_LOT_ADD_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}


function deleteLots(ids) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASES_DELETE_LOTS_REQUEST"})

        LotService.deleteLots(ids)
            .then(
                _ => {
                    dispatch({type: "PURCHASES_DELETE_LOTS_SUCCESS"})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Лоты успешно удалены',
                            type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASES_DELETE_LOTS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message:error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function lotsInPurchase(purchaseID, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        LotService.lotsInPurchase(purchaseID, params)
            .then(
                lots => {
                    dispatch({type: "PURCHASE_LOTS_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(lots)
                },
                error => {
                    dispatch({type: "PURCHASE_LOTS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )
    })
}

function lots(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        LotService.lots(params)
            .then(
                lots => {
                    dispatch({type: "PURCHASE_LOTS_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(lots)
                },
                error => {
                    dispatch({type: "PURCHASE_LOTS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function lotsFilter(params) {
    return dispatch => new Promise((resolve, reject) => {
        LotService.lotsFilter(params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    reject(error)
                }
            )
    })
}
