export default function companies(state = {
    territories: [],
    territory: null,
    marketplaceSettings: null
}, action) {
    switch (action.type) {
        case "CUSTOMER_TERRITORIES_REQUEST":
            return state
        case "CUSTOMER_TERRITORIES_SUCCESS":
            return {
                ...state,
                ...{territories: action.payload},
            }
        case "CUSTOMER_TERRITORIES_FAILURE":
            return state
        case "CUSTOMER_TERRITORIES_CLEAR":
            return {
                ...state,
                ...{territories: []}
            }
        case "CUSTOMER_TERRITORY_REQUEST":
            return state
        case "CUSTOMER_TERRITORY_SUCCESS":
            return {
                ...state,
                ...{territory: action.payload}
            }
        case "CUSTOMER_TERRITORY_FAILURE":
            return state
        case "CUSTOMER_ADD_TERRITORY_REQUEST":
            return state
        case "CUSTOMER_ADD_TERRITORY_SUCCESS":
            return state
        case "CUSTOMER_ADD_TERRITORY_FAILURE":
            return state
        case "CUSTOMER_EDIT_TERRITORY_REQUEST":
            return state
        case "CUSTOMER_EDIT_TERRITORY_SUCCESS":
            return state
        case "CUSTOMER_EDIT_TERRITORY_FAILURE":
            return state
        case "CUSTOMER_MARKETPLACE_SETTINGS_SUCCESS":
            return {
                ...state,
                ...{marketplaceSettings: action.payload.settings}
            }
        case "CUSTOMER_EDIT_MARKETPLACE_SETTINGS_CLEAR":
            return {
                ...state,
                ...{marketplaceSettings: null}
            }
        default:
            return state
    }
}
