import {HttpService} from "../../App/services/http"

export const SettingsService = {
    order,
    updateOrder,
    table,
    tableUpdate,
    marketplace,
    setMarketplace,
    getSettings,
    setSettings
}

function order(params) {
    return HttpService.get("/settings/order", params)
        .then(response => {
            return response.settings
        })
}

function updateOrder(values) {
    return HttpService.put("/settings/order", values)
        .then(response => {
            return response.settings
        })
}

function table(name) {
    return HttpService.get(`/settings/table/${name}`)
        .then(response => {
            return response.settings
        })
}

function tableUpdate(values) {
    return HttpService.put("/settings/table", values)
}

function marketplace() {
    return HttpService.get(`/settings/marketplace`)
        .then(response => {
            return response.settings
        })
}

function setMarketplace(values) {
    return HttpService.put("/settings/marketplace", values)
}

function getSettings(name) {
    return HttpService.get(`/settings/${name}`, )
        .then(response => {
            return response.settings
        })
}

function setSettings(name, values) {
    return HttpService.put(`/settings/${name}`, values)
        .then(response => {
            return response.settings
        })
}
