import React from 'react'

import {makeStyles, TextField as MuiTextField,} from '@material-ui/core'


const useStyles = makeStyles(() => ({
    input: {
        background: 'white',
        border: 'none',
        borderRadius: '12px',
        padding: '10px 0px'
    }
}))

export const TextField = (props) => {
    const classes = useStyles()

    return <MuiTextField
        variant="standard"
        fullWidth={true}
        margin={'normal'}
        className={classes.input}
        inputProps={{
            style: {
                fontFamily: 'ALS Sirius',
                fontWeight: 'normal',
                fontSize: '18px',
                color: 'black'
            }
        }}
        {...props}
    />
}
