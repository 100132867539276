import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce} from 'use-debounce'
import format from "date-fns/format"
import clsx from "clsx"

import {
    Breadcrumbs,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Checkbox,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    Link,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from "@material-ui/core"
import {Search as SearchIcon, ViewHeadline as ViewHeadlineIcon, ViewModule as ViewModuleIcon} from '@material-ui/icons'

import {ButtonList} from "../../../../App/components/ButtonList/ButtonList"
import {CatalogActions} from "../../actions/catalog"
import {ButtonInput} from "../../../../App/components/ButtonInput/ButtonInput"
import {ButtonSelect} from "../../../../App/components/ButtonSelect/ButtonSelect"
import {taxPercentValuesCatalogPrice, taxPercentValuesProduct} from "../../../../App/dics/dic"
import {round} from "../../../../App/helpers/number"
import {PriceActions} from "../../../../Price/actions/price"
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon"
import {history} from "../../../../App/helpers/history"
import {AddProductDialog} from "../../../../Price/components/AddProductDialog"
import {AuthorizationService} from "../../../../Auth/services/authorization"

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 292px)"
    },
    input: {
        "width": "100%",
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100% - 70px)",
        "min-height": "calc(100% - 70px)",
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%",
        width: "100%",
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none",
        height: "65px",
    },
    tableRowAlert: {
        "cursor": "pointer",
        "background-color": "#f9f1f0",
        "text-decoration": "none"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    searchLabel: {
        "margin-bottom": "14px",
    },
    searchInput: {
        "width": "calc(100% - 83px)"
    },
    searchButton: {
        "margin-bottom": "10px"
    },
    amount: {
        "min-width": "100px"
    },
    sort: {
        "margin-bottom": "15px"
    },
    type: {
        padding: "0 13px",
        fontSize: "14px",
        borderRadius: "12px",
        backgroundColor: "#d7f4e7",
        width: "max-content",
    },
    button: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "#898989",
        },
        "width": "auto",
        "max-height": "35px",
        "max-width": "auto",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "#fff",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-bottom": "3px solid #898989",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "#616161",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    },
    productItem: {
        cursor: "pointer"
    },
    selected: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        cursor: "pointer"
    },
    link: {
        color: "#898989",
        "&:hover": {
            textDecoration: "underline",
        }
    },
    linkContractNumber: {
        color: "#898989",
        maxWidth: "170px",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis",
        "&:hover": {
            textDecoration: "underline",
        }
    },
    etName: {
        width: "fit-content",
        maxWidth: "400px",
        "overflow": "hidden",
        "text-overflow": "ellipsis",
        "&:hover": {
            color: "#898989",
        }
    },
    linkToCategory: {
        fontWeight: 600,
        color: "#898989",
        cursor: "pointer",
        "& p": {
            fontWeight: 600,
            color: "#898989",
        },
        "&:hover": {
            textDecoration: "underline",
        }
    },
    blockBreadcrumbCat: {
        display: "flex",
        color: "#a5adb1",
        "& p": {
            color: "#a5adb1",
        }
    },
    defColumnStyle: {
        maxWidth: "150px",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis",
    },
}))

const cardStyles = makeStyles((theme) => ({
    root: {
        borderRight: "2px solid rgba(211,211,211,.4)",
        borderBottom: "2px solid rgba(211,211,211,.4)",
        minHeight: "520px",
        maxHeight: "520px",
        position: "relative",
    },
    selected: {
        borderRight: "2px solid rgba(211,211,211,.4)",
        borderBottom: "2px solid rgba(211,211,211,.4)",
        minHeight: "520px",
        maxHeight: "520px",
        position: "relative",
        backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    media: {
        height: 0,
        paddingTop: '56.25%',
        marginTop: "20px",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
    },
    title: {
        fontSize: "18px",
        fontWeight: 600,
        minHeight: "58px",
        "&:hover": {
            color: "#898989",
        }
    },
    header: {
        position: "absolute",
        background: "transparent",
        padding: "10px",
    }
}));

const sortList = [
    {
        key: 'name_asc',
        value: 'Наименование А-Я'
    },
    {
        key: 'name_desc',
        value: 'Наименование Я-А'
    },
    {
        key: 'article_number_asc',
        value: 'Артикулу А-Я'
    },
    {
        key: 'article_number_desc',
        value: 'Артикулу Я-А'
    },
    {
        key: 'price_asc',
        value: 'Цене по возрастанию'
    },
    {
        key: 'price_desc',
        value: 'Цене по убыванию'
    },
]

const sortQueryParams = {
    name_asc: {
        orderBy: 'name',
        sortBy: 'asc'
    },
    name_desc: {
        orderBy: 'name',
        sortBy: 'desc'
    },
    article_number_asc: {
        orderBy: 'article_number',
        sortBy: 'asc'
    },
    article_number_desc: {
        orderBy: 'article_number',
        sortBy: 'desc'
    },
    price_asc: {
        orderBy: 'price',
        sortBy: 'asc'
    },
    price_desc: {
        orderBy: 'price',
        sortBy: 'desc'
    },
}

const typeViews = [
    {
        key: 'list',
        value: (<ViewHeadlineIcon/>)
    },
    {
        key: 'card',
        value: (<ViewModuleIcon/>)
    },
]

const columnsView = [
    {
        id: 'name',
        label: 'Название продукта',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'contract_number',
        label: 'Номер договора',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'article_number',
        label: 'Артикул продукта',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'product',
        label: 'Тип',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'tax_percent',
        label: 'Ставка НДС',
        value: 'Ставка НДС',
        format: (value) => value.toLocaleString(),
        minWidth: "90px",
        maxWidth: "90px",
    },
    {
        id: 'stock_quantity',
        label: 'Остатки',
        format: (value) => value.toLocaleString(),
        minWidth: "90px",
        maxWidth: "90px",
    },
    {
        id: 'expiration_date',
        label: 'Срок годности',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'price',
        label: 'Цена',
        format: (value) => value.toLocaleString(),
        minWidth: "160px",
        maxWidth: "160px",
    },
];

const defaultTypes = [
    {
        key: 'public',
        value: 'Без договора'
    },
    {
        key: 'contract',
        value: 'С договором'
    }
]

const typeWithContract = [{
    key: 'contract',
    value: 'С договором'
}]

export const Content = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const cardClasses = cardStyles();

    const {products} = useSelector(state => state.catalogSupplier)
    const {category, filter, searchFilter} = useSelector(state => state.catalogSupplier.products)
    const {account} = useSelector(state => state.account)
    const {categories} = useSelector(state => state.supplierCategories)

    const query = new URLSearchParams(props.location.search);

    const types = AuthorizationService.permissions(account, ["access_to_elmag", "access_to_marketplace"], true) ? defaultTypes : typeWithContract

    const getTypeIdx = () => {
        return types.map(el => el.key).indexOf(searchFilter.tabType) === -1 ? 0 : types.map(el => el.key).indexOf(searchFilter.tabType)
    }

    const [tab, setTab] = useState(query.get('contract_id') ? types.map(el => el.key).indexOf('contract') : getTypeIdx())

    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = useState(parseInt(products.meta.current_page) - 1)
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [productId, setProductId] = useState(null)
    const [searchRequest] = useDebounce(searchFilter.search, 900)
    const tableRef = useRef(null)

    const getProducts = async () => {

        return await dispatch(CatalogActions.products({
            ...{
                limit: rowsPerPage,
                page: page + 1,
                sort: sortQueryParams[searchFilter.sort].sortBy,
                orderBy: sortQueryParams[searchFilter.sort].orderBy,
                type: types[tab].key,
                companyId: account.company.id
            },
            ...(searchRequest ? {search: searchRequest} : {}),
            ...(filter.category_id ? {category_id: filter.category_id} : {}),
            ...(filter.contract ? {contract_id: filter.contract.id} : {}),
            ...(filter.amount ? {amount: filter.amount} : {}),
            ...(filter.price.from ? {price_from: filter.price.from} : {}),
            ...(filter.price.to ? {price_to: filter.price.to} : {})
        }))
    }

    useEffect(() => {
        getProducts().then(_ => {
            tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
            setSelected([]);
        })
        // eslint-disable-next-line
    }, [dispatch, page, rowsPerPage, searchRequest, tab, searchFilter.sort, category, filter.supplier, filter.category_id, filter.contract, filter.amount, filter.price.from, filter.price.to, account.company.id]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    };

    const update = (id, name, val) => {
        return dispatch(PriceActions.updateProduct(id, {
            [name]: val
        }))
    }

    const canselValue = (name, value) => {
    }

    const updateSelect = (id, name, val, selectedItems) => {
        return dispatch(PriceActions.updateProduct(id, {
            [name]: val
        }))
    }

    const change = (name, value) => {
    }

    const getCardView = (product, index, isItemSelected, labelId) => {
        let readOnly = !product.without_specification

        return (
            <Grid item xs={3} key={index} className={classes.productItem}
                  onClick={(e) => handleSelectedClick(e, product.id)}>
                <Card className={isItemSelected ? cardClasses.selected : cardClasses.root}>
                    <CardHeader
                        className={cardClasses.header}
                        avatar={
                            <Checkbox
                                checked={isItemSelected}
                                inputProps={{'aria-labelledby': labelId}}
                            />
                        }
                    />
                    <CardMedia className={cardClasses.media} image={product['photo_path']}/>
                    <CardContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid
                                item
                                className={cardClasses.title}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setProductId(product.id)
                                    setOpenAddProduct(true)
                                }}
                            >
                                {product['name']}
                            </Grid>
                            {product.contract_id &&
                                <Grid item>
                                    <Grid container direction={'row'} alignItems={'center'} spacing={2}>
                                        <Grid item>
                                            Договор:
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                className={classes.link}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    history.push(`/contracts?id=${product.contract_id}`)
                                                }}
                                            >{product.contract_number}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            <Grid item>
                                <Grid container direction={'row'}
                                      alignItems={'center'} spacing={2}>
                                    <Grid item>
                                        Артикул:
                                    </Grid>
                                    <Grid item>
                                        {product['article_number']}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {(product.tax_percent || product.tax_percent === 0) &&
                                <Grid item>
                                    <Grid container direction={'row'}
                                          alignItems={'center'} spacing={2}>
                                        <Grid item>
                                            НДС:
                                        </Grid>
                                        <Grid item>
                                            <ButtonSelect
                                                change={change}
                                                onUpdate={updateSelect}
                                                onCancel={canselValue}
                                                propId={product.id}
                                                disabled={readOnly}
                                                key={product.tax_percent}
                                                name={'tax_percent'}
                                                defaultValue={product.tax_percent}
                                                selectItems={taxPercentValuesProduct}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            <Grid item>
                                <Grid container direction={'row'} alignItems={'center'} spacing={2}>
                                    <Grid item>
                                        Кол-во:
                                    </Grid>
                                    <Grid item>
                                        <ButtonInput
                                            change={change}
                                            onUpdate={update}
                                            justifyContentRoot={'flex-end'}
                                            onCancel={canselValue}
                                            propId={product.id}
                                            disabled={readOnly}
                                            key={product.name}
                                            name={'stock_quantity'}
                                            defaultValue={product['stock_quantity']}
                                            unit={product['unit'] ? product['unit']['short'] : null}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction={'row'} alignItems={'center'} spacing={2}>
                                    <Grid item>
                                        Цена:
                                    </Grid>
                                    <Grid item>
                                        <ButtonInput
                                            type={'price'}
                                            change={change}
                                            justifyContentRoot={'flex-end'}
                                            onUpdate={update}
                                            onCancel={canselValue}
                                            propId={product.id}
                                            disabled={readOnly}
                                            key={product.name}
                                            name={'price'}
                                            defaultValue={round(product['price'], 2)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        )
    }

    const getColumn = (columnName, product) => {
        let readOnly = !product.without_specification

        switch (columnName) {
            case 'stock_quantity':
                return (
                    <ButtonInput
                        pattern={/[^\d.]/g}
                        type={'stock_quantity'}
                        change={change}
                        onUpdate={update}
                        justifyContentRoot={'flex-end'}
                        onCancel={canselValue}
                        propId={product.id}
                        key={product.name}
                        name={columnName}
                        defaultValue={product[columnName]}
                        unit={product['unit'] ? product['unit']['short'] : null}
                        disabled={readOnly}
                    />
                )
            case 'tax_percent':
                return (product.tax_percent || product.tax_percent === 0) ? (
                    <ButtonSelect
                        change={change}
                        onUpdate={updateSelect}
                        onCancel={canselValue}
                        propId={product.id}
                        key={product.tax_percent}
                        name={columnName}
                        defaultValue={product.tax_percent}
                        selectItems={taxPercentValuesCatalogPrice}
                        disabled={readOnly}
                    />
                ) : null
            case 'price':
                return (
                    <ButtonInput
                        pattern={/[^\d.]/g}
                        type={'price'}
                        currency={product.code_char}
                        change={change}
                        justifyContentRoot={'flex-end'}
                        onUpdate={update}
                        onCancel={canselValue} propId={product.id}
                        key={product.name}
                        name={columnName}
                        defaultValue={round(product[columnName], 2)}
                        disabled={readOnly}
                    />
                )
            case 'product':
                return (<Typography className={classes.type}>Товар</Typography>)
            case 'contract_number':
                return product.contract_id
                    ? (
                        <Grid item style={{maxWidth: "200px"}}>
                            <Typography
                                onClick={(e) => {
                                    e.stopPropagation();
                                    history.push(`/contracts?id=${product.contract_id}`)
                                }}
                                className={classes.linkContractNumber}
                                noWrap
                            >
                                {product.contract_number}
                            </Typography>
                        </Grid>
                    )
                    : null
            case 'name':
                return (<div className={classes.etName} onClick={(e) => {
                    e.stopPropagation()
                    setProductId(product.id)
                    setOpenAddProduct(true)
                }}>{product[columnName]}</div>)
            case 'expiration_date':
                return (product[columnName] ? <div
                    className={classes.defColumnStyle}>{format(new Date(product[columnName]), 'dd.MM.yyyy')}</div> : null)
            default:
                return (<div className={classes.defColumnStyle}>{product[columnName]}</div>)
        }
    }


    const getTreeItemBreadcrumb = (category) => {
        if (category.category !== null) {
            return (
                <Grid container spacing={1}>
                    <Grid item>
                        <Typography className={classes.linkToCategory} onClick={() => {
                            dispatch({
                                type: "SUPPLIER_CATALOG_PRODUCTS_FILTER", payload: {
                                    ...filter,
                                    ...{
                                        category_id: category.category.id
                                    }
                                }
                            })
                        }}>
                            {getTreeItem(category.category)}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.blockBreadcrumbCat}>/&nbsp;{category.name}</Grid>
                </Grid>
            )
        } else {
            return (
                <Typography color="textPrimary" style={{color: "#a5adb1"}}>{category.name}</Typography>
            )
        }
    }

    const getTreeItem = (category) => {
        if (category) {
            if (category.category !== null) {
                return (
                    <React.Fragment>
                        <Link color="inherit" href="src/resources/views/src/Supplier/Catalog/components/Products/ProductsContent#" onClick={() => {
                            dispatch({
                                type: "SUPPLIER_CATALOG_PRODUCTS_FILTER", payload: {
                                    ...filter,
                                    ...{
                                        category_id: category.id
                                    }
                                }
                            })
                        }}>
                            {category.name}
                        </Link>
                        {getTreeItem(category.category)}
                    </React.Fragment>
                )
            } else {
                return (
                    <Typography color="textPrimary">{category.name}</Typography>
                )
            }
        } else {
            return null;
        }
    }

    const handleSelectedClick = (e, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const getBreadcrumbs = (catId) => {
        let category = categories.filter(el => el.id === catId)

        return category.length > 0 ? getTreeItemBreadcrumb(category[0]) : null
    }

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const [openAddProduct, setOpenAddProduct] = useState(false);

    const closeAddProductDialog = () => {
        setProductId(null)
        setOpenAddProduct(false)
        getProducts().then(_ => {
            setSelected([]);
        })
    }

    const getModeForAddProductDialog = (products, productId) => {
        return products && products.hasOwnProperty('data') && !products.data.find(product => product.id === productId).without_specification ? 'view' : 'edit'
    }

    const onChangeStandard = (event) => {
        if (event.target.value === '' && searchFilter.search !== '') {
            dispatch({
                type: "SUPPLIER_CATALOG_PRODUCTS_SEARCH_FILTER", payload: {
                    search: '',
                    sort: 'name_asc',
                    typeView: 'list',
                    typeViewIndex: 0
                }
            })
        } else {
            dispatch({
                type: "SUPPLIER_CATALOG_PRODUCTS_SEARCH_FILTER",
                payload: {...searchFilter, ...{search: event.target.value}}
            })
        }
    }

    const standardInputProps = {
        endAdornment: (
            <InputAdornment position="end">
                <SearchIcon style={{color: '#c1c1c1'}}/>
            </InputAdornment>
        ),
    }

    const onChangeTab = (_, value) => {
        setTab(value);
        setPage(0)
        dispatch({
            type: "SUPPLIER_CATALOG_PRODUCTS_SEARCH_FILTER",
            payload: {...searchFilter, ...{tabType: value, type: types[value].key}}
        })
    }

    const onChangeTypeView = (_, value) => {
        setPage(0)
        dispatch({
            type: "SUPPLIER_CATALOG_PRODUCTS_SEARCH_FILTER",
            payload: {...searchFilter, ...{typeViewIndex: value, typeView: typeViews[value].key}}
        })
    }

    return <Grid item className={classes.content}>
        {openAddProduct && <AddProductDialog categories={categories} open={openAddProduct} productId={productId} onClose={closeAddProductDialog} mode={getModeForAddProductDialog(products, productId)}/>}
        <Grid container direction="column" justify="center" alignItems="stretch">
            <Grid item>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item className={classes.fullWidth}>
                        <Typography className={classes.title}>
                            Товары и услуги
                        </Typography>
                    </Grid>
                    <Grid item className={classes.fullWidth}>
                        <Breadcrumbs aria-label="breadcrumb">
                            {getBreadcrumbs(filter.hasOwnProperty('category_id') && filter.category_id ? filter.category_id : null)}
                        </Breadcrumbs>
                    </Grid>
                    <Grid item className={classes.fullWidth}>
                        <TextField
                            id="search"
                            variant="standard"
                            name={'search'}
                            placeholder={'Наименование, артикул, МНН'}
                            fullWidth={true}
                            margin={'normal'}
                            value={searchFilter.search}
                            onChange={onChangeStandard}
                            classes={{
                                root: clsx(classes.filterLabel, classes.searchLabel)
                            }}
                            InputProps={standardInputProps}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        <FormControl className={classes.sort}>
                            <InputLabel>Сортировка по</InputLabel>
                            <Select
                                fullWidth
                                value={searchFilter.sort}
                                onChange={(e) => {
                                    dispatch({
                                        type: "SUPPLIER_CATALOG_PRODUCTS_SEARCH_FILTER",
                                        payload: {...searchFilter, ...{sort: e.target.value}}
                                    })
                                }}
                            >
                                {
                                    sortList.map(item => (
                                        <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                            <Grid item>
                                <ButtonList
                                    value={tab}
                                    values={types}
                                    onChange={onChangeTab}
                                />
                            </Grid>
                            <Grid item>
                                <ButtonList
                                    value={searchFilter.typeViewIndex}
                                    values={typeViews}
                                    onChange={onChangeTypeView}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Paper>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item className={classes.fullWidth}>
                    <Grid container className={classes.container} direction="column" justify="space-between"
                          alignItems="stretch">
                        <Grid item className={classes.inputContent}>
                            {searchFilter.typeView === 'list' &&
                                <TableContainer className={classes.tableContainer}>
                                    <Table ref={tableRef} stickyHeader aria-label="sticky table">
                                        <TableBody>
                                            {products && products.hasOwnProperty('data') && products.data.map((product, index) => {
                                                const isItemSelected = isSelected(product.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <TableRow
                                                        className={classes.tableRow}
                                                        onClick={(e) => handleSelectedClick(e, product.id)}
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        key={product.id}
                                                        selected={isItemSelected}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                checked={isItemSelected}
                                                                inputProps={{'aria-labelledby': labelId}}
                                                            />
                                                        </TableCell>
                                                        {columnsView.map((column) => (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                style={{
                                                                    minWidth: column.minWidth,
                                                                    maxWidth: column.maxWidth ? column.maxWidth : 'auto'
                                                                }}
                                                            >
                                                                {getColumn(column.id, product)}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                            {searchFilter.typeView === 'card' &&
                                <Grid container direction="row">
                                    {products && products.hasOwnProperty('data') && products.data.map((product, index) => {
                                        const isItemSelected = isSelected(product.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return getCardView(product, index, isItemSelected, labelId);
                                    })
                                    }
                                </Grid>
                            }
                        </Grid>
                        <Grid item className={classes.footer}>
                            <Grid container direction="column" justify="center" alignItems="stretch"
                                  spacing={1}>
                                <Grid item className={classes.footerContent}>
                                    <Grid container className={classes.footerContainer} direction="row"
                                          justify="space-between" alignItems="center" spacing={2}>
                                        <Grid item>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                                component='div'
                                                labelRowsPerPage={'Записей на странице:'}
                                                count={products.meta.total}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="row"
                                                  justify="space-between" alignItems="center" spacing={2}>
                                                <Grid item>
                                                    Выбрано: {selected.length}
                                                </Grid>
                                                <Grid item>
                                                    <ButtonIcon
                                                        className={classes.button}
                                                        onClick={() => {
                                                            return dispatch(CatalogActions.deleteProducts({
                                                                productIds: selected,
                                                            })).then(() => {
                                                                getProducts().then(_ => {
                                                                    setSelected([]);
                                                                })
                                                            }, errors => {
                                                            })
                                                        }}
                                                        children={'Удалить'}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    </Grid>
}
