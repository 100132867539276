import React from "react"

import {Card, CardContent, Grid, makeStyles, Typography, withStyles} from "@material-ui/core"
import {Info} from "@material-ui/icons"

const useStyles = makeStyles(() => ({
    message: {
        "max-width": "calc(100% - 51px)"
    },
    accordionItem: {
        "border-top": "0 !important",
        "box-shadow": "0 0 0 !important",
        "border-bottom": "none !important",
    },
}));

const Message = withStyles({
    root: {
        "background-color": "#616161",
        "& .MuiCardContent-root": {
            "padding": "8px"
        }
    }
})(Card);

export const MessageInfo = (props) => {
    const classes = useStyles();
    const {message} = props

    return (
        <Message className={classes.accordionItem}>
            <CardContent>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item>
                        <Info fontSize="large" style={{color: "white"}}/>
                    </Grid>
                    <Grid item className={classes.message}>
                        <Typography style={{color: "white"}}>{message}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Message>
    )
}
