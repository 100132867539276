import React from "react"

import {SupMarketplaceService} from "../services/shop"
import {history} from "../../../App/helpers/history"
import {NoElmagAccessError} from "../components/NoElmagAccessError"

export const SupMarketplaceActions = {
    orders,
    cancel,
    lotInfo
}

function orders(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SUP_MARKETPLACE_ORDERS_REQUEST"})

        SupMarketplaceService.orders(params)
            .then(
                orders => {
                    dispatch({type: "SUP_MARKETPLACE_ORDERS", payload: {data: orders.data, meta: orders.meta}})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "SUP_MARKETPLACE_ORDERS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    const message = error?.type && error.type === 'supplier_has_no_access' ?  <NoElmagAccessError message={error.message}/> : error.message
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: message,
                            type:'error',
                        }})
                    reject(error)
                }
            )
    })
}

function lotInfo(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "MY_LOT_REQUEST"})
        SupMarketplaceService.lotInfo(id)
            .then(
                lot => {
                    dispatch({type: "MY_LOT", payload: lot})
                    dispatch({type: "LOADING", payload: false})
                    resolve(lot)
                },
                error => {
                    dispatch({type: "MY_LOT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function cancel(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "STATUS_MY_ORDER_REQUEST"})

        SupMarketplaceService.cancel(id, values)
            .then(
                response => {
                    dispatch({type: "STATUS_MY_ORDER_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                        message:'Предложение успешно отозвано',
                        type:'success'
                        }
                    })
                    history.push("/supplier/marketplace/deliveries")
                    resolve()
                },
                error => {
                    dispatch({type: "STATUS_MY_ORDER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
