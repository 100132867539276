import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {Field} from "formik"

import {TextField as MuiTextField} from "@material-ui/core"

import {Autocomplete} from "@material-ui/lab";
import {useDebounce} from "use-debounce";

export const AsyncAutocomplete = (props) => {
    const dispatch = useDispatch()

    const {value, name, label, multiple = false, getValues, condition = false, getOptionLabel, onChange, setTouched, error, disabled} = props

    const [values, setValues] = useState(props.values ?? [])

    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)

    useEffect(() => {
        const getData = async () => {
            return await dispatch(getValues(searchRequest))
        }

        if (condition || searchRequest) {
            getData().then(response => {
                setValues(response.data)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, condition, searchRequest]);

    return (
        <Autocomplete
            filterOptions={options => options}
            options={values}
            required
            multiple={multiple}
            getOptionLabel={getOptionLabel}
            disabled={disabled}
            onChange={(e, value) => {
                onChange(e, value).then(() => {
                    setSearch(null)
                })
            }}
            defaultValue={props.default}
            value={value}
            noOptionsText='Нет доступных вариантов'
            onBlur={() => {
                setTouched(name, true)
            }}
            name={name}
            renderInput={params => {
                return <Field
                    fullWidth
                    component={MuiTextField}
                    {...params}
                    onChange={(e) => {
                        setSearch(e.target.value)
                    }}
                    error={!!error}
                    helperText={error}
                    type="text"
                    label={label}
                />
            }}
        />
    )
}
