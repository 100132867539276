export default function dictionaries(state = {
    standards: {
        data: [],
        meta: {
            total: 0,
            per_page: 15,
            current_page: 1
        }
    },
}, action) {
    switch (action.type) {
        case "SELECTED_STANDARDS_SUCCESS":
            return {
                ...state,
                standards: action.payload
            }
        case "SELECTED_STANDARDS_CLEAR":
            return {
                ...state,
                standards: {
                    data: [],
                    meta: {
                        total: 0,
                        per_page: 15,
                        current_page: 1
                    }
                }
            }
        case "SELECTED_STANDARDS_FILTER":
            return state
        default:
            return state
    }
}
