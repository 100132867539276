import React, {useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, FieldArray, Form, Formik, useFormikContext} from "formik"

import {Select} from "formik-material-ui"
import {Clear, Add, Search} from "@material-ui/icons"
import {Avatar, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Typography, Input, FormHelperText} from "@material-ui/core"

import {TextFieldWithError as CustomTextField} from "../../../../App/components/Inputs/TextFieldWithError"
import {Submit} from "../../../../App/components/Button/Submit"
import {CompanyActions} from "../../../actions/company"
import {AccountActions} from "../../../actions/account"
import {history} from "../../../../App/helpers/history"
import {getValidationSchema} from "./validation/contractInformation"

const useStyles = makeStyles(() => ({
    fullWidth: {
        "width": "100%"
    },
    inputContent: {
        "padding": "20px 30px 0",
        "width": "100%"
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 298px)"
    },
    column: {
        "height": "86px"
    },
    avatar: {
        "width": "150px",
        "height": "150px",
        "border-radius": "unset"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    group: {
        "padding": "8px"
    },
    groupTitle: {
        "font-weight": "500"
    }
}))

export const ContactInformation = (props) => {
    const dispatch = useDispatch()

    const { company, setDirty, setLoading } = props
    const { occupations } = useSelector(state => state.company)
    const classes = useStyles()
    const [logo, setLogo] = useState(company.logo ?? `${process.env.PUBLIC_URL}/storage/no-image.jpg`)

    const phonesArrLength = company?.contact_information?.phones?.length

    const AutoDirty = () => {
        const { dirty } = useFormikContext()

        useEffect(() => {
            setDirty(dirty)
        }, [dirty])

        return null
    };

    return (
        <Grid item className={classes.fullWidth}>
            <Formik
                validateOnMount
                initialTouched={{
                   phones: [{number: true}]
                }}
                initialValues={{
                    ...{
                        website: company.contact_information ? (company.contact_information.website ?? '') : '',
                        email: company.contact_information ? (company.contact_information.emails ? company.contact_information.emails[0] : '') : '',
                        phones: company.contact_information ? ((company.contact_information.phones ? company.contact_information.phones.map(phone => { return {number: phone.number ?? '', extra: phone.extra ?? ''} }) : [{number: '', extra: ''}]) ?? [{number: '', extra: ''}]) : [{number: '', extra: ''}],
                        erp_system: company.contact_information ? (company.contact_information.erp_system ?? '') : '',
                        description: company.contact_information ? (company.contact_information.description ?? '') : '',
                        last_name: company.contact_information ? (company.contact_information.last_name ?? '') : '',
                        first_name: company.contact_information ? (company.contact_information.first_name ?? '') : '',
                        middle_name: company.contact_information ? (company.contact_information.middle_name ?? '') : '',
                        position: company.contact_information ? (company.contact_information.position ?? '') : '',
                        purchasing_department_phones: company.purchasing_department ? ((company.purchasing_department.phones ? company.purchasing_department.phones.map(phone => { return {number: phone.number ?? '', extra: phone.extra ?? ''} }) : [{number: '', extra: '' }]) ?? [{number: '', extra: '' }]) : [{number: '', extra: '' }],
                        purchasing_department_email: company.purchasing_department ? (company.purchasing_department.email ? company.purchasing_department.email : '') : '',
                        logo: company.logo ?? ''
                    },
                    ...(
                        company.type
                            ? {
                                occupations: company.hasOwnProperty('occupations') && company.occupations && company.occupations.length && company.occupations.length > 0 ? company.occupations.map(occupation => occupation.id) : []
                            }
                            : {}
                    )
                }}
                validationSchema={getValidationSchema(company)}
                onSubmit={(values, {setSubmitting}) => {
                    const formData = new FormData()

                    for (const key in values) {
                        if (values.hasOwnProperty(key)) {
                            switch (key) {
                                case 'phones':
                                case 'purchasing_department_phones':
                                    for (const i in values[key]) {
                                        if (values[key].hasOwnProperty(i)) {
                                            formData.append(`${key}[${i}][number]`, values[key][i].number)
                                            formData.append(`${key}[${i}][extra]`, values[key][i].extra)
                                        }
                                    }
                                    break
                                case 'logo':
                                    if (values[key] instanceof File) {
                                        formData.append(key, values[key])
                                    }
                                    break
                                case 'occupations':
                                    for (const i in values[key]) {
                                        if (values[key].hasOwnProperty(i)) {
                                            formData.append(`${key}[${i}]`, values[key][i])
                                        }
                                    }
                                    break
                                default:
                                    formData.append(key, values[key])
                            }
                        }
                    }

                    if (company.type) {
                        formData.append('type', company.type)
                    }

                    return dispatch(CompanyActions.contactInformation(formData)).then(
                        () => {
                            const getAccount = async () => {
                                return dispatch(AccountActions.account()).catch(() => {
                                    history.push("/logout")
                                });
                            }
                            getAccount().then(() => {})
                            setSubmitting(false)
                            setLoading(false)
                        },
                        errors => {
                            if (errors) {
                            }
                            setSubmitting(false)
                        }
                    )
                }}
            >
                {({
                      dirty,
                      values,
                      errors,
                      isSubmitting,
                      isValid,
                      setFieldValue
                  }) => (
                    <Form className={classes.fullWidth}>
                        <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                            <Grid item className={classes.inputContent}>
                                <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                    <Grid item xs={6} className={classes.column}>
                                        <Field
                                            fullWidth
                                            name="website"
                                            type="text"
                                            label="Сайт"
                                            component={CustomTextField}
                                        />
                                    </Grid>
                                    <Grid item xs={6} className={classes.column}>
                                        <Field
                                            fullWidth
                                            name="email"
                                            type="email"
                                            label="Электронная почта"
                                            component={CustomTextField}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12} className={classes.column}>
                                        <Field
                                            fullWidth
                                            name="erp_system"
                                            type="text"
                                            label="ERP-Система"
                                            component={CustomTextField}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12} className={classes.column}>
                                        <Field
                                            fullWidth
                                            name="description"
                                            type="text"
                                            label="Кратко о компании"
                                            component={CustomTextField}
                                        />
                                    </Grid>
                                </Grid>
                                {company.type &&
                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                        <Grid item xs={12} className={classes.column}>
                                            <FormControl className={classes.fullWidth}>
                                                <InputLabel required shrink={ true } htmlFor="roles">
                                                    Род деятельности
                                                </InputLabel>
                                                <Field
                                                    fullWidth
                                                    type="text"
                                                    name="occupations"
                                                    label="Род деятельности"
                                                    component={ Select }
                                                    multiple
                                                    required
                                                >
                                                    {occupations.map(occupation => (
                                                        <MenuItem key={occupation.id} value={occupation.id}>
                                                            {occupation.name}
                                                        </MenuItem>
                                                    ))}
                                                </Field>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.groupTitle}>Контактное лицо</Typography>
                                        <Grid container className={classes.group} direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                    <Grid item xs={4} className={classes.column}>
                                                        <Field
                                                            fullWidth
                                                            name="last_name"
                                                            type="text"
                                                            label="Фамилия"
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} className={classes.column}>
                                                        <Field
                                                            fullWidth
                                                            name="first_name"
                                                            type="text"
                                                            label="Имя"
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} className={classes.column}>
                                                        <Field
                                                            fullWidth
                                                            name="middle_name"
                                                            type="text"
                                                            label="Отчество"
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                    <Grid item xs={12} className={classes.column}>
                                                        <Field
                                                            fullWidth
                                                            name="position"
                                                            type="text"
                                                            label="Должность"
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <FieldArray name="phones" render={ arrayHelpers => (
                                                    <React.Fragment>
                                                        {values.phones.map((phone, index) => {
                                                            return (
                                                                <Grid key={index} container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                                    <Grid item xs={2} className={classes.column}>
                                                                        <Field
                                                                            fullWidth
                                                                            name={`phones.${index}.number`}
                                                                            type="phone"
                                                                            label="Номер телефона"
                                                                            required
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2} className={classes.column}>
                                                                        <Field
                                                                            fullWidth
                                                                            name={`phones.${index}.extra`}
                                                                            type="text"
                                                                            label="доб."
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <IconButton
                                                                            size={"small"}
                                                                            onClick={() => {
                                                                                return phonesArrLength > 1 ? arrayHelpers.remove(index) : null
                                                                            }}
                                                                        >
                                                                            <Clear />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })}
                                                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Submit
                                                                    size="small"
                                                                    variant="contained"
                                                                    component="label"
                                                                    color="primary"
                                                                    endIcon={<Add/>}
                                                                    onClick={() => {
                                                                        arrayHelpers.push({number: '', extra: ''})
                                                                    }}
                                                                >
                                                                    Добавить номер телефона
                                                                </Submit>
                                                            </Grid>
                                                        </Grid>
                                                    </React.Fragment>
                                                )}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {!company.type &&
                                <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.groupTitle}>Отдел закупок</Typography>
                                        <Grid container className={classes.group} direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                    <Grid item xs={6} className={classes.column}>
                                                        <Field
                                                            fullWidth
                                                            name="purchasing_department_email"
                                                            type="email"
                                                            label="Электронная почта"
                                                            required
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                {values.purchasing_department_phones.map((phone, index) => {
                                                    return (
                                                        <Grid key={index} container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                            <Grid item xs={2} className={classes.column}>
                                                                <Field
                                                                fullWidth
                                                                name={`purchasing_department_phones.${index}.number`}
                                                                type="phone"
                                                                label="Номер телефона"
                                                                required
                                                                component={CustomTextField} />
                                                            </Grid>
                                                            <Grid item xs={2} className={classes.column}>
                                                                <Field
                                                                fullWidth
                                                                name={`purchasing_department_phones.${index}.extra`}
                                                                type="text"
                                                                label="доб."
                                                                component={CustomTextField} />
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                }
                                <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.groupTitle}>Логотип</Typography>
                                        <Grid container className={classes.group} direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                    <Grid item>
                                                        <Avatar className={classes.avatar} src={logo} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Field
                                                                    label="Загрузить логотип"
                                                                    name="logo"
                                                                    component={() => (
                                                                        <Submit
                                                                            variant="contained"
                                                                            component="label"
                                                                            color="primary"
                                                                            size="small"
                                                                            startIcon={<Search />}
                                                                        >
                                                                            Загрузить логотип
                                                                            <Input
                                                                                style={{ display: "none" }}
                                                                                inputProps={{
                                                                                    ...{
                                                                                        type: 'file',
                                                                                        name: 'logo',
                                                                                        onChange: (event) => {
                                                                                            const file = event.currentTarget.files[0];
                                                                                            setFieldValue("logo", file);

                                                                                            if (['image/jpeg', 'image/gif', 'image/svg+xml', 'image/bmp', 'image/png'].includes(file.type)) {
                                                                                                setLogo(URL.createObjectURL(new Blob(event.currentTarget.files, {type: file.type})))
                                                                                            }
                                                                                        },
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Submit>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            {(errors && errors.hasOwnProperty('logo')) &&
                                                                <Grid item>
                                                                    <FormHelperText error>{errors.logo}</FormHelperText>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.footer}>
                            <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                <Grid item className={classes.footerContent}>
                                    <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Submit
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={isSubmitting || !isValid || !dirty}
                                            >
                                                Сохранить
                                            </Submit>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <AutoDirty />
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}
