export default function supplierCategories(state = {categories: []}, action) {
    switch (action.type) {
        case "SUPPLIER_CATEGORY_REQUEST":
            return state
        case "SUPPLIER_CATEGORY_SUCCESS":
            return {
                categories: action.payload.categories,
            }
        case "SUPPLIER_CATEGORY_FAILURE":
            return state
        default:
            return state
    }
}
