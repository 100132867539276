import {CatalogService} from "../services/catalog"
import {ShopService} from "../services/shop"

export const CatalogActions = {
    products,
    categories,
    deleteProducts
}

function products(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SUPPLIER_CATALOG_PRODUCTS_REQUEST"})

        CatalogService.products(params)
            .then(
                response => {
                    dispatch({type: "SUPPLIER_CATALOG_PRODUCTS", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "SUPPLIER_CATALOG_PRODUCTS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function categories(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SUPPLIER_CATEGORY_REQUEST"})

        ShopService.categories(params)
            .then(
                response => {
                    dispatch({type: "SUPPLIER_CATEGORY_SUCCESS", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response.categories)
                },
                error => {
                    dispatch({type: "SUPPLIER_CATEGORY_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function deleteProducts(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELETE_PRODUCTS_REQUEST"})

        ShopService.deleteProducts(params)
            .then(
                response => {
                    dispatch({type: "DELETE_PRODUCTS_SUCCESS", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Успешно удалено',
                            type:'success'
                        }
                    })
                    resolve(response)
                },
                error => {
                    dispatch({type: "DELETE_PRODUCTS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}
