import * as AppStorage from "../../App/helpers/storage"

export default function account(state = {account: JSON.parse(AppStorage.get('account'))}, action) {
    switch (action.type) {
        case "ACCOUNT_REQUEST":
            return {
                account: state.account
            }
        case "ACCOUNT":
            return {
                account: action.payload,
                load: true
            }
        case "ACCOUNT_FAILURE":
            return {
                account: state.account
            }
        case "ACCOUNT_EDIT_REQUEST":
            return state
        case "ACCOUNT_EDIT_SUCCESS":
            return {
                account: action.payload,
                load: true
            }
        case "ACCOUNT_EDIT_FAILURE":
            return state
        case "ACCOUNT_DELETE_REQUEST":
            return state
        case "ACCOUNT_DELETE_SUCCESS":
            return state
        case "ACCOUNT_DELETE_FAILURE":
            return state
        default:
            return state
    }
}
