import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {Grid, makeStyles, Paper, Tab, Tabs, Typography} from "@material-ui/core"
import {ErrorOutline as ErrorOutlineIcon} from '@material-ui/icons'

import {DialogWithChoices} from "../../../App/components/DialogWithChoices/DialogWithChoices"
import {TermsOfParticipation} from "../Tabs/Panels/TermsOfParticipation"
import {InstitutionsBudgets} from "../Tabs/Panels/InstitutionsBudgets"
import {ContactInformation} from "../Tabs/Panels/ContactInformation"
import {ImportantInformation} from "../Dialogs/ImportantInformation"
import {AuthorizationService} from "../../../Auth/services/authorization"
import {LegalDetail} from "../Tabs/Panels/LegalDetail"
import {ChiefDoctor} from "../Tabs/Panels/ChiefDoctor"
import {Credentials} from "../Tabs/Panels/Credentials"
import {UpgradeNotice} from "../Dialogs/UpgradeNotice"
import {Warehouses} from "../Tabs/Panels/Warehouses"
import {Employees} from "../Tabs/Panels/Employees"
import {PurchaseActions} from "../../../Planning/actions/purchase"
import {AuthActions} from "../../../Auth/actions/authentication"
import {TabPanel} from "../Tabs/TabPanel"
import {SystemActions} from "../../../App/actions/system"
import {CompanyActions} from "../../actions/company"
import {Subscriptions} from "../Tabs/Panels/Subscriptions";
import {Integrations} from "../Tabs/Panels/Integrations";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    input: {
        "width": "100%",
    },
    welcomeContent: {
        "width": "100%",
        "padding": "100px"
    },
    blocked: {
        width: "970px",
        padding: "70px 80px 60px",
        borderTop: "5px solid #d9534f",
        boxShadow: "0 5px 20px rgba(0,0,0,.4)",
        borderBottom: "5px solid #c1c1c1",
        borderRadius: "0px !important",
        margin: "0 auto",
    },
    blockedTitle: {
        color: "#d9534f",
        fontSize: "24px",
        textTransform: "uppercase",
        fontWeight: 700,
    },
}))

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [loadingFundingSources, setLoadingFundingSources] = React.useState(false)
    const [loadingPermissions, setLoadingPermissions] = React.useState(false)
    const [loadingCompany, setLoadingCompany] = React.useState(false)
    const [loadingRegions, setLoadingRegions] = React.useState(false)
    const [loadingCategories, setLoadingCategories] = React.useState(false)
    const [loadingOccupations, setLoadingOccupations] = React.useState(false)
    const {company, occupations, okved} = useSelector(state => state.company)
    const [loadingCitites, setLoadingCities] = React.useState(false)
    const [loadingOkved, setLoadingOkved] = React.useState(false)
    const [loadingBanks, setLoadingBanks] = React.useState(false)
    const {cities, regions, categories, banks} = useSelector(state => state.system)
    const [loading, setLoading] = React.useState(false)
    const [dirty, setDirty] = React.useState(false)
    const [dialog, setDialog] = React.useState(false)
    const [open, setOpen] = React.useState(true)
    const {account} = useSelector(state => state.account)
    const [alert, setAlert] = useState(null)
    const [value, setValue] = React.useState(2)

    useEffect(() => {
        if (!loadingCompany) {
            dispatch(CompanyActions.company({
                include: "legalDetail.city.region.country,legalDetail.okpds.okved,legalDetail.executiveDirector,purchasingDepartment,legalDetail.city.region.country,legalDetail.passportDetails,paymentDetail,contactInformation,termsParticipation.regions.region,termsParticipation.files,occupations,status,territories.users,institutionsBudgets.fundingSource,institutionsBudgets.responsible"
            })).then(company => {
                if (company.purchasing_department === null && window.location.search.match(/purchasing_department=true/gi)) {
                    setAlert('Для дальнейшего пользования электронным магазином, заполните данные отдела закупок!')
                } else if ((company.type !== 'self') && (company.legal_detail.okpo === null) && window.location.search.match(/okpo=true/gi)) {
                    setAlert('Для дальнейшего пользования электронным магазином, заполните данные коды деятельности ОКПО!')
                    setValue(1);
                }

                setLoadingCompany(true)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, value, loadingCompany]);

    useEffect(() => {
        if (!loadingOccupations) {
            dispatch(CompanyActions.occupations()).then(
                () => {
                    setLoadingOccupations(true)
                }
            )
        }
        // eslint-disable-next-line
    }, [dispatch, loadingOccupations, company]);


    useEffect(() => {
        if (AuthorizationService.roles(account, ['customer']) && AuthorizationService.permissions(account, ['access_to_planning']) && AuthorizationService.permissions(account, ['administrator'])) {
            if (!loadingFundingSources) {
                dispatch(PurchaseActions.fundingSources()).then(
                    () => {
                        setLoadingFundingSources(true)
                    }
                )
            }
        } else {
            setLoadingFundingSources(true)
        }
        // eslint-disable-next-line
    }, [dispatch, loadingFundingSources]);

    useEffect(() => {
        if (!loadingRegions && !regions.length) {
            dispatch(SystemActions.regions()).then(
                () => {
                    setLoadingRegions(true)
                }
            )
        }
        // eslint-disable-next-line
    }, [dispatch, loadingRegions]);

    useEffect(() => {
        if (!loadingCategories && !categories.length) {
            dispatch(SystemActions.categories()).then(
                () => {
                    setLoadingCategories(true)
                }
            )
        }
        // eslint-disable-next-line
    }, [dispatch, loadingCategories]);

    useEffect(() => {
        if (!loadingCitites && !cities.length && company?.legal_detail) {
            dispatch(SystemActions.cities({country: company.legal_detail.city.region.country.code})).then(
                () => {
                    setLoadingCities(true)
                }
            )
        }
        // eslint-disable-next-line
    }, [dispatch, loadingCitites, company]);

    useEffect(() => {
        if (!loadingOkved) {
            dispatch(CompanyActions.okved()).then(
                () => {
                    setLoadingOkved(true)
                }
            )
        }
        // eslint-disable-next-line
    }, [dispatch, loadingOkved]);

    useEffect(() => {
        if (!loadingBanks && !banks.length) {
            dispatch(SystemActions.banks()).then(
                () => {
                    setLoadingBanks(true)
                }
            )
        }
        // eslint-disable-next-line
    }, [dispatch, loadingBanks]);

    useEffect(() => {
        if (!loadingPermissions) {
            dispatch(AuthActions.permissions()).then(
                () => {
                    setLoadingPermissions(true)
                }
            )
        }
        // eslint-disable-next-line
    }, [dispatch, loadingPermissions]);

    useEffect(() => {
        if (
            (loadingOkved) &&
            (loadingCompany) &&
            (loadingFundingSources) &&
            (loadingBanks || banks.length) &&
            (loadingCitites || cities.length) &&
            (loadingRegions || regions.length) &&
            (loadingPermissions || !account?.permissions?.length) &&
            (loadingOccupations || (company?.type && !occupations?.length))
        ) {
            setLoading(true)
        }
        // eslint-disable-next-line
    }, [dispatch, loading, loadingCompany, loadingOkved, loadingFundingSources, loadingBanks, loadingCitites, loadingRegions, loadingPermissions, loadingOccupations]);

    useEffect(() => {
        if (alert) {
            dispatch({
                type: "ALERT_ERROR",
                payload: {
                    message: alert,
                    type: 'error'
                }
            })
        }
        // eslint-disable-next-line
    }, [dispatch, alert]);

    const handleChange = (event, newValue) => {
        if (dirty) {
            setDialog({value: newValue})
        } else {
            setValue(newValue);
        }
    };

    const getRole = (roles) => {
        let role = 'пользователь';
        roles.forEach(r => {
            if (r.name.toLowerCase() === 'customer') {
                role = 'заказчик';
            }
            if (r.name.toLowerCase() === 'supplier') {
                role = 'поставщик';
            }

            if (r.name.toLowerCase() === 'supervisor') {
                role = 'контролирующий орган';
            }
        })
        return role;
    }

    return <React.Fragment>
        {loading ? (
            <Grid item className={AuthorizationService.roles(account, ['guest_supplier', 'supplier_blocked']) ? classes.welcomeContent : classes.content}>
                {AuthorizationService.roles(account, 'supplier_blocked') &&
                    <Grid container direction={'column'} className={classes.blocked} spacing={2}>
                        <Grid item>
                            <Grid container direction={'row'}>
                                <Grid item>
                                    <ErrorOutlineIcon className={classes.blockedIcon} />
                                </Grid>
                                <Grid item style={{maxWidth: "580px"}}>
                                    <Typography className={classes.blockedTitle}>Пользователь не активен - обратитесь к администратору системы!</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {(AuthorizationService.roles(account, ['supplier_confirmed_by_admin', 'supplier_confirmed', 'supplier', 'supplier_confirmed_by_admin', 'supplier_confirmed']) && !account.agree_with_the_important_information_notice && !AuthorizationService.roles(account, 'supplier_blocked') && window.location.search.match(/dialog/gi)) &&
                    <ImportantInformation
                        open={open}
                        setOpen={setOpen}
                    />
                }
                {(AuthorizationService.roles(account, ['supplier_confirmed_by_admin', 'supplier_confirmed', 'supplier', 'customer']) && !account.agree_with_notice_of_actualization) && !AuthorizationService.roles(account, 'supplier_blocked') &&
                    <UpgradeNotice open={open} />
                }
                {AuthorizationService.roles(account, ['customer', 'supplier', 'supervisor', 'supplier_confirmed', 'customer_confirmed_by_admin']) && !AuthorizationService.roles(account, 'supplier_blocked') &&
                    <Paper>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item className={classes.full}>
                                <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                    <Grid item className={classes.fullWidth}>
                                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                            <Grid item className={classes.header}>
                                                <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                                    <Grid item className={classes.fullWidth}>
                                                        <Typography className={classes.title}>
                                                            {getRole(account.roles)} {company.legal_detail.name}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.input}>
                                                <Grid container direction="column" justify="center" alignItems="stretch">
                                                    <Grid item>
                                                        <Tabs
                                                            value={value}
                                                            indicatorColor="primary"
                                                            textColor="primary"
                                                            onChange={handleChange}
                                                        >
                                                            <Tab label="Контактная информация" className={classes.tab} />
                                                            <Tab label="Юридические реквизиты" className={classes.tab} />
                                                            {(AuthorizationService.roles(account, ['supplier', 'supplier_confirmed']) && AuthorizationService.permissions(account, "access_to_elmag")) ? (
                                                                <Tab label="Условия участия" className={classes.tab} />
                                                            ) : null}
                                                            {AuthorizationService.roles(account, 'customer') ? (
                                                                <Tab label="Учётная запись" className={classes.tab} />
                                                            ) : null}
                                                            {AuthorizationService.roles(account, 'supplier') ? (
                                                                ['Учётные записи', 'Складские помещения', 'Подписки'].map((label, index) => <Tab key={index} label={label} className={classes.tab} />)
                                                            ) : null}
                                                            {(AuthorizationService.roles(account, ['customer']) && AuthorizationService.permissions(account, ['access_to_planning']) && AuthorizationService.permissions(account, ['administrator'])) ? (
                                                                <Tab label="Главный врач" className={classes.tab} />
                                                            ) : null}
                                                            {(AuthorizationService.roles(account, ['customer']) && AuthorizationService.permissions(account, ['access_to_planning']) && AuthorizationService.permissions(account, ['administrator'])) ? (
                                                                <Tab label="Бюджет учреждения" className={classes.tab} />
                                                            ) : null}
                                                            {(AuthorizationService.roles(account, ['customer']) && AuthorizationService.permissions(account, ['administrator'])) ? (
                                                                <Tab label="Интеграции" className={classes.tab} />
                                                            ) : null}
                                                        </Tabs>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <TabPanel value={value} index={0}>
                                        <ContactInformation
                                            company={company}
                                            setDirty={setDirty}
                                            setLoading={setLoadingCompany}
                                        />
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <LegalDetail
                                            company={company}
                                            banks={banks}
                                            okved={okved}
                                            cities={cities}
                                            setDirty={setDirty}
                                            setLoading={setLoadingCompany}
                                        />
                                    </TabPanel>
                                    {AuthorizationService.roles(account, ['supplier', 'supplier_confirmed']) ? (
                                        <React.Fragment>
                                            {AuthorizationService.permissions(account, "access_to_elmag") &&
                                                <TabPanel value={value} index={2}>
                                                    <TermsOfParticipation
                                                        company={company} cities={cities}
                                                        regions={regions} setDirty={setDirty}
                                                        setLoading={setLoading}
                                                    />
                                                </TabPanel>
                                            }
                                            <TabPanel
                                                value={value}
                                                index={AuthorizationService.permissions(account, "access_to_elmag") ? 3 : 2}
                                            >
                                                <Employees company={company} setLoading={setLoading}/>
                                            </TabPanel>
                                            <TabPanel
                                                value={value}
                                                index={AuthorizationService.permissions(account, "access_to_elmag") ? 4 : 3}
                                            >
                                                <Warehouses
                                                    companyId={company.id}
                                                    setDirty={setDirty}
                                                    isAdmin={false}
                                                />
                                            </TabPanel>
                                            <TabPanel
                                                value={value}
                                                index={AuthorizationService.permissions(account, "access_to_elmag") ? 5 : 4}
                                            >
                                                <Subscriptions
                                                    regions={regions}
                                                    categories={categories.filter(category => !category.category)}
                                                    setDirty={setDirty}
                                                    isAdmin={false}
                                                />
                                            </TabPanel>
                                        </React.Fragment>
                                    ) : null}
                                    {AuthorizationService.roles(account, 'customer') ? (
                                        <TabPanel value={value} index={2}>
                                            <Credentials setDirty={setDirty} setLoading={setLoading}/>
                                        </TabPanel>
                                    ) : null}
                                    {(AuthorizationService.roles(account, ['customer']) && AuthorizationService.permissions(account, ['access_to_planning']) && AuthorizationService.permissions(account, ['administrator'])) ? (
                                        <TabPanel value={value} index={3}>
                                            <ChiefDoctor company={company} setLoading={setLoading}/>
                                        </TabPanel>
                                    ) : null}
                                    {(AuthorizationService.roles(account, ['customer']) && AuthorizationService.permissions(account, ['access_to_planning']) && AuthorizationService.permissions(account, ['administrator'])) ? (
                                        <TabPanel value={value} index={4}>
                                            <InstitutionsBudgets company={company} setLoading={setLoading}/>
                                        </TabPanel>
                                    ) : null}
                                    {(AuthorizationService.roles(account, ['customer']) && AuthorizationService.permissions(account, ['administrator'])) ? (
                                        <TabPanel value={value} index={5}>
                                            <Integrations company={company} setLoading={setLoading}/>
                                        </TabPanel>
                                    ) : null}
                                    {dialog &&
                                        <DialogWithChoices
                                            open={!!dialog}
                                            onClose={() => {
                                                setDialog(false)
                                            }}
                                            onContinue={() => {
                                                setDirty(false)
                                                setDialog(false)
                                                setValue(dialog.value);
                                            }}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                }
            </Grid>
        ) : null}
    </React.Fragment>
}
