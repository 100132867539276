import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {Field} from "formik"
import {useDebounce} from 'use-debounce'

import {TextField as MuiTextField,} from "@material-ui/core"
import {Autocomplete} from "@material-ui/lab"

import {DeliveryActions} from "../../../actions/delivery"
import {ContractActions} from "../../../../Contract/actions/contract"

export const Items = (props) => {
    const dispatch = useDispatch()

    const { order, onClick } = props

    const [items, setItems] = useState([])
    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)


    useEffect(() => {
        if (searchRequest) {
            const getItems = async () => {
                let params = {
                    limit: 10,
                    page: 1,
                    order: order.id,
                    ...(searchRequest ? {search: searchRequest} : {})
                }
                return await dispatch(ContractActions.items(order.contract.id, params))
            }

            getItems().then(response => {
                setItems(response.data)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, searchRequest]);

    return (
        <Autocomplete
            filterOptions={options => options}
            name={'item.name'}
            options={items.filter((product) => ((product.item.amount - (product.item.ordered ?? 0)) >= product.min_ordered_quantity))}
            value={null}
            getOptionLabel={item => {
                let name = ''

                if (item) {
                    if (item.article_number) {
                        name += `${item.article_number} | `
                    }

                    name += `${(item.name ?? item.item.name)} | ${(parseFloat(item.item.amount) - (item.item.ordered ? parseFloat(item.item.ordered) : 0)).toFixed(2)} | ${parseFloat(item.price).toLocaleString('ru-RU', {
                        style: 'currency',
                        currency: order.contract.currency.code
                    }).replace('KZT', '₸')}`
                }

                return item ? name : ''
            }}
            noOptionsText='Добавить Товар/Услугу'
            onChange={(e, item) => {
                return dispatch(DeliveryActions.addItem(null, {
                    id: item.id,
                    amount: !!parseFloat(item.min_ordered_quantity) ? parseFloat(item.min_ordered_quantity) : 1
                })).then(() => onClick(), () => {})
            }}
            renderInput={params => {
                return <Field
                    fullWidth
                    component={MuiTextField}
                    {...params}
                    onChange={(e) => {
                        setSearch(e.target.value)
                    }}
                    name={'item.name'}
                    label="Товар/Услуга"
                />
            }}
        />
    )
}
