import {InvoiceService} from "../services/invoice"
import {Link as RouterLink} from "react-router-dom";
import {Link, Typography} from "@material-ui/core";
import React from "react";

export const InvoiceActions = {
    invoices: invoice,
    bill,
    confirm,
    subscription,
    unpaid,
    filter
}

function invoice(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "INVOICES_REQUEST"})

        InvoiceService.invoices(params)
            .then(
                response => {
                    dispatch({type: "INVOICES_SUCCESS", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "INVOICES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function bill() {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CREATE_INVOICE_REQUEST"})

        InvoiceService.bill()
            .then(
                invoice => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "CREATE_INVOICE_SUCCESS", payload: invoice})
                    resolve(invoice)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "CREATE_INVOICE_FAILURE"})
                    reject(error)
                }
            )
    })
}

function confirm(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        InvoiceService.confirm(id)
            .then(
                invoice => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(invoice)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function subscription(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        InvoiceService.subscription(id)
            .then(
                subscription => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(subscription)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function unpaid(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        InvoiceService.unpaid(id)
            .then(
                exist => {
                    dispatch({type: "LOADING", payload: false})
                    if (exist) {
                        dispatch({ type: "ALERT_ERROR", payload: {
                            message: <Typography color="inherit">Для предоставления доступа необходимо оплатить счет, расположенный в разделе <Link component={RouterLink} to={"/invoices"} color="inherit">"Счета и акты"</Link></Typography> ,
                            type:'error'
                        }})
                    }
                    resolve(exist)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function filter(params) {
    return dispatch => new Promise((resolve, reject) => {
        InvoiceService.filter(params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    reject(error)
                }
            )
    })
}
