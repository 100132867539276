import React, {useEffect} from "react"
import {Link} from "react-router-dom"
import {useSelector} from "react-redux";

import {makeStyles, Tab, Tabs} from '@material-ui/core'
import {
    Assignment as AssignmentIcon,
    AssignmentTurnedIn as AssignmentTurnedInIcon,
    Cached as CachedIcon,
    Class as ClassIcon,
    EventNote as EventNoteIcon,
    HelpOutline,
    History as HistoryIcon,
    ListAlt as ListAltIcon,
    ShoppingCart as ShoppingCartIcon,
    TrendingUp as TrendingUpIcon
} from "@material-ui/icons"

import {AuthorizationService} from "../../../Auth/services/authorization"
import {isSupplier} from "../../../Planning/helpers/permissions/users";

const useStyles = makeStyles(() => ({
    tabs: {
        padding: "0",
        display: "flex",
        margin: "0",
        height: "55px",
        "& .MuiButtonBase-root": {
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "500",
            minWidth: "auto",
            minHeight: "55px",
            paddingTop: "14px",
            "& .Mui-selected": {
                color: "#485868",
            }
        },
        "& .MuiTabScrollButton-root": {
            color: "#FFFFFF",
            paddingTop: "unset",
            "&.Mui-disabled": {
                display: "none",
            },
        },
        "& .MuiTab-wrapper": {
            alignItems: "center",
            flexDirection: "row",
        },
        "& .MuiTouchRipple-root": {
            color: "#485868",
        },
        "& .PrivateTabIndicator-root-20": {
            backgroundColor: "#898989",
            height: "4px"
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "#898989"
        },
        "& .MuiTab-textColorPrimary": {
            color: "#FFFFFF",
            borderBottom: "5px solid #898989",
            "&.Mui-selected": {
                color: "#485868",
                "& .MuiSvgIcon-root": {
                    color: "#485868",
                },
            },
            "&:hover": {
                color: "#485868",
                backgroundColor: "#898989",
                borderBottom: "5px solid #898989",
                "& .MuiSvgIcon-root": {
                    color: "#485868",
                },
                "&:after": {
                    backgroundColor: "rgba(0,0,0,.04)"
                },
            }
        },
    },
    active: {
        backgroundImage: "none !important",
        backgroundColor: "#898989",
        color: "#485868 !important",
        "& .MuiSvgIcon-root": {
            color: "#485868",
        },
        borderBottom: "5px solid #898989 !important",
    },
    default: {
        color: "#FFFFFF",
        "& .MuiSvgIcon-root": {
            "color": "#FFFFFF",
        },
        borderBottom: "5px solid #898989",
    },
    blocked: {
        "& .MuiTab-textColorPrimary": {
            color: "#E0E0E0 !important",
        },
    },
    iconBlocked: {
        color: "#D1D1D1 !important",
    },
    "icon": {
        color: "#fff",
        marginRight: "5px",
        fontWeight: "500",
        "&:hover": {
            color: "#616161"
        },
    }
}))

export const Menu = (props) => {
    const classes = useStyles()
    const {location} = props
    const [value, setValue] = React.useState(location.pathname);
    const {account} = useSelector(state => state.account)

    useEffect(() => {
        setValue(location.pathname)
    }, [location.pathname])

    const getElmagMenu = () => {
        const isAccessToElmag = AuthorizationService.permissions(account, "access_to_elmag");
        const isAccessToPublishPurchases = AuthorizationService.permissions(account, "publish_purchases");
        if (isAccessToElmag || isAccessToPublishPurchases) {
            return [
                {
                    title: 'Электронный магазин',
                    to: isAccessToElmag ? '/marketplace/requests' : '/marketplace/publish/purchases',
                    icon: <ShoppingCartIcon className={getClassesIcon()}/>
                },
            ];
        }
        return [];
    }

    const menu = () => {
        if (AuthorizationService.roles(account, ['customer', 'customer_confirmed_by_admin'])) {
            return [
                ...(AuthorizationService.permissions(account, "access_to_planning") ? [
                    {
                        title: 'Планирование',
                        to: '/planning/purchase-plans',
                        icon: <EventNoteIcon className={getClassesIcon()}/>
                    }
                ] : []),
                ...(getElmagMenu()),
                ...(AuthorizationService.permissions(account, "access_to_order") ? [
                    {title: 'Каталог', to: '/catalog/products', icon: <ClassIcon className={getClassesIcon()}/>},
                    {title: 'Заказы', to: '/delivery/orders', icon: <ListAltIcon className={getClassesIcon()}/>},
                    {title: 'Договоры', to: '/contracts', icon: <AssignmentTurnedInIcon className={getClassesIcon()}/>},
                ] : []),
                ...(AuthorizationService.permissions(account, "delivery_order_after_event") && AuthorizationService.permissions(account, "access_to_order") ? [
                    {title: 'Постфактум', to: '/contract/past', icon: <HistoryIcon className={getClassesIcon()}/>}
                ] : []),
                ...(AuthorizationService.permissions(account, "access_to_order") ? [
                    {
                        title: 'Рекламации',
                        to: '/delivery/reclamations',
                        icon: <CachedIcon className={getClassesIcon()}/>
                    },
                ] : []),
                ...(AuthorizationService.permissions(account, "access_to_report") ? [
                    {title: 'Отчёты', to: '/reports', icon: <TrendingUpIcon className={getClassesIcon()}/>},
                ] : []),
                ...(AuthorizationService.permissions(account, "access_to_standard") ? [
                    {title: 'Эталоны', to: '/dictionary/standards', icon: <HelpOutline className={getClassesIcon()}/>},
                ] : []),
                {title: 'Помощь', to: '/support', icon: <HelpOutline className={getClassesIcon()}/>}
            ]
        } else if (AuthorizationService.roles(account, ['supplier', 'supplier_confirmed'])) {
            return [
                ...(AuthorizationService.permissions(account, "access_to_elmag") ? [
                    {title: 'Электронный магазин', to: '/supplier/marketplace/orders', icon: <ShoppingCartIcon className={getClassesIcon()}/>},
                ] : []),
                {title: 'Каталог', to: '/supplier/catalog/products', icon: <ClassIcon className={getClassesIcon()}/>},
                {title: "Прайс-листы", to: "/supplier/prices", icon: <AssignmentIcon className={getClassesIcon()}/>},
                ...(AuthorizationService.permissions(account, "access_to_elmag") && AuthorizationService.permissions(account, "access_to_marketplace") ? [
                    {title: "Скидки/Надбавки", to: "/supplier/discounts", icon: <i className={getClassesIcon()}>%</i>},
                ] : []),
                {title: 'Заказы', to: '/delivery/orders', icon: <ListAltIcon className={getClassesIcon()}/>},
                {title: 'Договоры', to: '/contracts', icon: <AssignmentTurnedInIcon className={getClassesIcon()}/>},
                {title: 'Рекламации', to: '/delivery/reclamations', icon: <CachedIcon className={getClassesIcon()}/>},
                ...(AuthorizationService.permissions(account, "access_to_report") ? [
                    {title: 'Отчёты', to: '/reports', icon: <TrendingUpIcon className={getClassesIcon()}/>},
                ] : []),
                ...(AuthorizationService.permissions(account, "access_to_standard") ? [
                    {title: 'Эталоны', to: '/dictionary/standards', icon: <HelpOutline className={getClassesIcon()}/>},
                ] : []),
                {title: 'Помощь', to: '/support', icon: <HelpOutline className={getClassesIcon()}/>}
            ]
        } else if (AuthorizationService.roles(account, 'supervisor')) {
            return [
                ...(AuthorizationService.permissions(account, ['access_to_companies', 'control_price']) ? [
                    {title: "Главная", to: "/", icon: null},
                ] : []),
                ...(AuthorizationService.permissions(account, "access_to_planning") ? [
                    {
                        title: 'Планирование',
                        to: '/planning/purchase-plans',
                        icon: <EventNoteIcon className={getClassesIcon()}/>
                    }
                ] : []),
                ...(AuthorizationService.permissions(account, "access_to_report") ? [
                    {title: 'Отчёты', to: '/reports', icon: <TrendingUpIcon className={getClassesIcon()}/>},
                ] : []),
                ...(AuthorizationService.permissions(account, "access_to_standard") ? [
                    {title: 'Эталоны', to: '/dictionary/standards', icon: <HelpOutline className={getClassesIcon()}/>},
                ] : []),
                {title: 'Помощь', to: '/support', icon: <HelpOutline className={getClassesIcon()}/>},
            ]
        } else if (AuthorizationService.roles(account, 'operator')) {
            return [
                {title: "Роли учетных записей", to: "/companies", icon: null},
                ...(AuthorizationService.permissions(account, "change_contract_status") ? [
                    {
                        title: 'Договоры',
                        to: '/contract/change/status',
                        icon: <AssignmentTurnedInIcon className={getClassesIcon()}/>
                    }
                ] : []),
                ...(AuthorizationService.permissions(account, "change_delivery_order_status") ? [
                    {
                        title: 'Заказы',
                        to: '/delivery/order/change/status',
                        icon: <ListAltIcon className={getClassesIcon()}/>
                    }
                ] : []),
                ...(AuthorizationService.permissions(account, "access_to_order_settings") ? [
                    {title: "Настройки", to: "/settings", icon: null}
                ] : []),
                {title: "Оферты", to: "/operator/offerts", icon: null}
            ]
        } else if (AuthorizationService.roles(account, 'admin')) {
            return [
                {title: "Роли учетных записей", to: "/companies", icon: null},
                {title: "Статистика", to: "/dashboards", icon: null},
                ...(AuthorizationService.permissions(account, "change_contract_status") ? [
                    {
                        title: 'Договоры',
                        to: '/contract/change/status',
                        icon: <AssignmentTurnedInIcon className={getClassesIcon()}/>
                    }
                ] : []),
                ...(AuthorizationService.permissions(account, "change_delivery_order_status") ? [
                    {
                        title: 'Заказы',
                        to: '/delivery/order/change/status',
                        icon: <ListAltIcon className={getClassesIcon()}/>
                    }
                ] : []),
                {title: "Уведомления", to: "/warnings", icon: null},
                ...(AuthorizationService.permissions(account, "access_to_order_settings") ? [
                    {title: "Настройки", to: "/settings", icon: null}
                ] : []),
            ]
        }

        return []
    }

    const getClassesIcon = () => {
        return isBlocked() ? classes.iconBlocked : classes.icon
    }

    const getClassesTab = (location, to) => {
        switch (true) {
            case isBlocked():
                return {root: classes.blocked};
            case isActive(location, to):
                return {root: classes.active};
            default:
                return {root: classes.default};
        }
    }

    const isActive = (location, to) => {
        if ((to === "/") || (to === "/companies")) {
            return ["/customers", "/suppliers", "/report/control/price", "/", "/user/operators"].indexOf(location.pathname) !== -1;
        } else {
            return location.pathname.match(new RegExp('^' + to)) !== null;
        }
    }

    const isBlocked = () => {
        if (isSupplier(account)) {
            return !AuthorizationService.roles(account, ['supplier_confirmed']);
        }
        return false;
    }

    return (
        <Tabs
            value={value}
            onChange={(event, val) => {
                if (isActive(location, val)) {
                    setValue(val)
                }
            }}
            variant="scrollable"
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabs}
            classes={isBlocked() ? {root: classes.blocked} : {}}
        >
            {menu().map((option, index) => {
                return (
                    <Tab
                        value={option.to}
                        disabled={Boolean((isBlocked() && (option.to !== '/supplier/marketplace/deliveries')) || AuthorizationService.roles(account, ['customer_confirmed_by_admin']))}
                        key={index}
                        icon={option.icon}
                        label={option.title}
                        component={Link}
                        to={option.to}
                        classes={getClassesTab(location, option.to)}
                    />
                )
            })}
        </Tabs>
    )
}
