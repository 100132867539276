import React from "react"
import {Dialog, DialogContent, DialogTitle, Grid, makeStyles, TextField as MuiTextField} from "@material-ui/core";
import {Form, Formik} from "formik";
import {Autocomplete} from "@material-ui/lab";
import {Submit} from "../../../../../App/components/Button/Submit";
import * as Yup from "yup";
import {AccountActions} from "../../../../actions/account";
import {CompanyActions} from "../../../../actions/company";
import {useDispatch} from "react-redux";

const useStyles = makeStyles(() => ({
    fullWidthDialog: {
        "width": "500px"
    },
    column: {
        "minHeight": "86px"
    }
}))

export const Subscription = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {subscription, open, handleClose, setLoading, regions, categories} = props

    return (
        <Dialog
            onClose={handleClose}
            open={open}
        >
            <DialogTitle>{subscription ? 'Редактировать подписку' : 'Добавить подписку'}</DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        regions: subscription?.regions.length ? regions.filter(region => subscription.regions.includes(region.id)) : [],
                        categories: subscription?.categories.length ? categories.filter(category => subscription.categories.includes(category.id)) : []
                    }}
                    validationSchema={Yup.object().shape({
                        regions: Yup.mixed().when('categories', {
                            is: (categories) => !categories.length,
                            then: Yup.array().required('Выберите регион')
                        }),
                        categories: Yup.mixed()
                    })}
                    onSubmit={(values, {setSubmitting, setFieldError}) => {
                        return dispatch(subscription
                            ? CompanyActions.editSubscription(subscription.id, {
                                regions: values.regions.map(region => region.id),
                                categories: values.categories.map(category => category.id),
                            })
                            : CompanyActions.createSubscription({
                                regions: values.regions.map(region => region.id),
                                categories: values.categories.map(category => category.id),
                            })
                        ).then(
                            () => {
                                setSubmitting(false)
                                setLoading(false)
                                handleClose()
                            },
                            errors => {
                                if (errors) {
                                }
                                setSubmitting(false)
                            }
                        )
                    }}
                >
                    {({
                          dirty,
                          values,
                          errors,
                          touched,
                          isSubmitting,
                          isValid,
                          setFieldValue,
                      }) => (
                        <Form className={classes.fullWidthDialog}>
                            <Grid container direction="row" justify="space-between" alignItems="stretch" spacing={2}>
                                <Grid item xs={12} className={classes.column}>
                                    <Autocomplete
                                        options={regions}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        required
                                        multiple
                                        name="regions"
                                        noOptionsText='Нет доступных вариантов'
                                        getOptionLabel={(option) => option.name}
                                        value={values.regions}
                                        onChange={(e, items) => {
                                            setFieldValue("regions", items)
                                        }}
                                        renderInput={(params) =>
                                            <MuiTextField
                                                {...params}
                                                fullWidth
                                                label="Регионы"
                                                name="regions"
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.column}>
                                    <Autocomplete
                                        options={categories}
                                        required
                                        multiple
                                        name="categories"
                                        noOptionsText='Нет доступных вариантов'
                                        getOptionLabel={(option) => option.name}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        value={values.categories}
                                        onChange={(e, items) => {
                                            setFieldValue("categories", items)
                                        }}
                                        renderInput={(params) =>
                                            <MuiTextField
                                                {...params}
                                                fullWidth
                                                label="Категории"
                                                name="categories"
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={2}>
                                        <Grid item>
                                            <Submit
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                onClick={handleClose}
                                            >
                                                Отменить
                                            </Submit>
                                        </Grid>
                                        <Grid item>
                                            <Submit
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting || !isValid || !dirty}
                                                type="submit"
                                            >
                                                Сохранить
                                            </Submit>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}
