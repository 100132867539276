export default function purchases(state = {
    comments: {
        data: [],
        meta: {
            total: 0,
            per_page: 15,
            current_page: 1
        }
    },
    items: [],
    purchase: {},
    categories: [],
    lot: {}
}, action) {
    switch (action.type) {
        case "PURCHASES_SUCCESS":
            return {
                ...state,
                ...action.payload
            }
        case "COMMENTS_SUCCESS":
            return {
                ...state,
                comments: {
                    data: action.payload.data,
                    meta: action.payload.meta
                }
            }
        case "PURCHASE_SUCCESS":
            return {
                ...state,
                purchase: {
                    ...action.payload.purchase
                }
            }
        case "PURCHASE_LOTS_SUCCESS":
            return {
                ...state,
                ...action.payload
            }
        case "PURCHASE_CONDUCTION_METHODS_SUCCESS":
            return {
                ...state,
                conductionMethods: action.payload.conductionMethods
            }
        case "PURCHASE_FUNDING_SOURCES_SUCCESS":
            return {
                ...state,
                fundingSources: action.payload.fundingSources
            }
        case "PURCHASE_ITEMS_SUCCESS":
            return {
                ...state,
                ...action.payload,
            }
        case "PURCHASE_CATEGORIES_SUCCESS":
            return {
                ...state,
                categories: action.payload.categories
            }
        case "PURCHASE_LOT_ITEM_SUCCESS":
            return {
                ...state,
                items: action.payload
            }
        case "PURCHASE_LOT_SUCCESS":
            return {
                ...state,
                lot: action.payload.lot
            }
        case "PURCHASE_LOT_CLEAR":
            return {
                ...state,
                lot: {}
            }
        case "PURCHASE_ITEM_SUCCESS":
            return {
                ...state,
                items: action.payload
            }
        default:
            return state
    }
}
