import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce} from 'use-debounce'
import format from "date-fns/format"
import clsx from "clsx"
import {Table} from "@devexpress/dx-react-grid-material-ui";
import {Button, Grid, InputAdornment, makeStyles, TextField,} from "@material-ui/core"
import {CommentOutlined as CommentOutlinedIcon, Search as SearchIcon} from "@material-ui/icons"

import {PlanningActions} from "../../actions/planning"
import {Submit} from "../../../App/components/Button/Submit"
import {history} from "../../../App/helpers/history"
import {PurchaseActions} from "../../actions/purchase"
import {AuthorizationService} from "../../../Auth/services/authorization"
import {getMonthName} from "../../../App/helpers/date"

import {IntegratedSummary,} from '@devexpress/dx-react-grid'
import {green} from "@material-ui/core/colors";
import {ColumnChooser} from "../../../App/components/Table/ColumnChooser";
import {ButtonIcon} from "../../../App/components/Button/ButtonIcon";
import {useRouteMatch} from "react-router";
import GetAppIcon from "@material-ui/icons/GetApp";
import {SettingsActions} from "../../../Settings/actions/settings";
import {getFullName} from "../../../App/helpers/user";
import {SettingsActions as PlanningSettings} from "../../actions/settings";
import {isCustomer} from "../../helpers/permissions/users";
import * as AppStorage from "../../../App/helpers/storage";
import {DownloadActions} from "../../../Download/actions/download";
import {Delete as DeleteButton} from "../Purchase/Buttons/Delete";
import {Approve as ApproveButton} from "../Purchase/Buttons/Approve";
import {Decline as DeclineButton} from "../Purchase/Buttons/Decline";
import {CustomTable} from "../../../App/components/Table/Table";
import {TableHeader} from "../../../App/components/Table/TableHeader";
import {Edit as EditButton} from "../Purchase/Buttons/Edit";
import {Copy as CopyButton} from "../Purchase/Buttons/Copy";
import {localState} from "../../../App/components/LocalState";
import {Publish} from "../Purchase/Buttons/Publish";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.planningContent.width,
        padding: theme.planningContent.padding,
        margin: theme.planningContent.margin,
    },
    container: {
        "background-image": "linear-gradient(to bottom,#f6f6f6,#eee 85%,#b1b1b1)",
        "width": "100%",
        "height": "calc(100vh - 256px)"
    },
    download: {
        "color": "#485868"
    },
    filterLabel: {
        "background": "#fff",
        "margin-bottom": "0 !important",
        "margin-top": "5px !important",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        },
        "& input": {
            padding: "6px 15px 7px"
        }
    },

    tableContainer: {
        "min-height": "100%",
        "height": "calc(100vh - 400px)"
    },
    tableItemsContainer: {},
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    searchLabel: {},
    searchInput: {
        "width": "calc(100% - 83px)"
    },
    searchButton: {
        "margin-bottom": "10px"
    },
    fullWidth: {
        "width": "100%"
    },
    inputContentMain: {
        "width": "100%",
        "height": theme.planningContent.height,
        "min-height": theme.planningContent.height,
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    tabs: {
        "&.MuiTab-textColorPrimary.Mui-selected": {
            backgroundColor: "#fff",
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "4px",
        }
    },
    fontSubtitle: {
        fontWeight: 700
    },
    submit: {
        height: "35px",
    },
    accordion: {
        "& .MuiAccordionSummary-content": {
            "max-width": "calc(100% - 32px)"
        }
    },
    acDetails: {
        padding: "16px 0"
    },
    active: {
        'height': '41px',
        'background-color': green[100],
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    loading: {
        'height': '41px',
        'background-color': '#fff',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    iconButton: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    },
    backIconButton: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        position: 'absolute',
        left: '205px',
        top: '42px',
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    },
    titleDialog: {
        "&>h2": {
            color: "#fff"
        }
    },
    circle: {
        width: "25px",
        height: "25px",
        backgroundColor: "#ea4a4a",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        "&>span": {
            lineHeight: "20px"
        }
    }
}))

const columnTitlesForColumnChooser = new Map([
    ['comment_counter', 'Счетчик комментариев'],
])

const columns = [
    {name: 'comment_counter', title: ' '},
    {name: 'unlabeled_standards', title: 'Счетчик неразмеченных эталонов'},
    {name: 'number', title: 'Номер'},
    {name: 'name', title: 'Наименование'},
    {name: 'category', title: 'Категория', filter: 'categories'},
    {name: 'subcategory', title: 'Подкатегория', filter: 'subcategories'},
    {name: 'group', title: 'Группа', filter: 'groups'},
    {name: 'status', title: 'Статус', filter: 'status'},
    {name: 'edit_request_status', title: 'Статус корректировки', filter: 'edit_request_status'},
    {name: 'version', title: 'Версия'},
    {name: 'planning_year', title: 'Планируемый год', filter: 'year'},
    {name: 'publish_status', title: 'Статус публикации', filter: 'publish_status'},
    {name: 'month', title: 'Планируемый месяц проведения', filter: 'month'},
    {name: 'planing_date_to_publish_notification', title: 'План. срок размещения извещения'},
    {name: 'planning_contract_end', title: 'План. срок окончания дей-ия договора'},
    {name: 'conduction_method', title: 'Способ проведения', filter: 'methods'},
    {name: 'funding_sources', title: 'Источник финансирования'},
    {name: 'type', title: 'Вид закупки'},
    {name: 'address', title: 'Адрес закупки'},
    {name: 'items_count', title: 'Количество ОЗ'},
    {name: 'average_price', title: 'Сумма'},
    {name: 'railRoad', title: 'ЖД', filter: 'rail_roads'},
    {name: 'region', title: 'Макрорегион', filter: 'regions'},
    {name: 'company', title: 'ЧУЗ', filter: 'companies'},
    {name: 'created', title: 'Дата создания', filter: 'created'},
    {name: 'updated', title: 'Дата редактирования'},
    {name: 'creator', title: 'Создатель', filter: 'creator'},
]

const adjustmentColumns = [
    {name: 'number', title: 'Номер'},
    {name: 'name', title: 'Наименование'},
    {name: 'category', title: 'Категория', filter: 'categories'},
    {name: 'subcategory', title: 'Подкатегория', filter: 'subcategories'},
    {name: 'group', title: 'Группа', filter: 'groups'},
    {name: 'status', title: 'Статус', filter: 'status'},
    {name: 'edit_request_status', title: 'Статус корректировки', filter: 'edit_request_status'},
    {name: 'edit_request_comment', title: 'Причина корректировки'},
    {name: 'edit_request_files', title: 'Файлы корректировки'},
    {name: 'version', title: 'Версия'},
    {name: 'planning_year', title: 'Планируемый год', filter: 'year'},
    {name: 'edit_request_status', title: 'Статус корректировки', filter: 'edit_request_status'},
    {name: 'month', title: 'Планируемый месяц проведения', filter: 'month'},
    {name: 'planing_date_to_publish_notification', title: 'План. срок размещения извещения'},
    {name: 'planning_contract_end', title: 'План. срок окончания дей-ия договора'},
    {name: 'conduction_method', title: 'Способ проведения', filter: 'methods'},
    {name: 'funding_sources', title: 'Источник финансирования'},
    {name: 'type', title: 'Вид закупки'},
    {name: 'address', title: 'Адрес закупки'},
    {name: 'items_count', title: 'Количество ОЗ'},
    {name: 'average_price', title: 'Сумма'},
    {name: 'railRoad', title: 'ЖД', filter: 'rail_roads'},
    {name: 'region', title: 'Макрорегион', filter: 'regions'},
    {name: 'company', title: 'ЧУЗ', filter: 'companies'},
    {name: 'edit_request_status_date', title: 'Дата запроса корректировки'}
]

export const Content = (props) => {
    const isEditRequests = useRouteMatch('/planning/purchases/edit-requests')
    const isJoint = useRouteMatch('/planning/joint-purchases')

    const dispatch = useDispatch()
    const classes = useStyles()
    const {location} = props
    const {account} = useSelector(state => ({...state.account, ...state.purchasePlans}))
    const [purchases, setPurchases] = useState({
        ...localState,
        agg_info: {
            items_count: 0,
            average_price: 0,
        }
    })
    const [meta, setMeta] = useState(localState.meta)
    const {units} = useSelector(state => state.purchasePlans)
    const {planningSettings} = useSelector(state => state)
    const [initialize, setInitialize] = useState(false);
    const purchaseType = isEditRequests ? 'requests' : (isJoint ? 'joint' : 'default')
    const tableName = (purchaseType === "default") ? 'planning_purchases' : (purchaseType === 'requests') ? 'planning_edit_purchases' : 'planning_joint_purchases'

    useEffect(() => {
        setInitialize(false)
    }, [tableName])

    const {filter} = useSelector(state => state.filters[tableName])

    const typeAction = (purchaseType === "default") ? 'PLANNING_PURCHASES_FILTER' : (purchaseType === "requests" ? 'PLANNING_EDIT_PURCHASES_FILTER' : 'PLANNING_JOINT_PURCHASES_FILTER')

    const [hiddenColumnNames, setHiddenColumnNames] = useState( []);

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [tableRef, setTableRef] = useState(null)
    const [loading, setLoading] = useState(false)

    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)

    const [totalPurchaseSummaryItems] = useState([
        {columnName: 'items_count', type: 'items_count'},
        {columnName: 'average_price', type: 'average_price'},
    ]);

    const messagesPurchases = {
        items_count: 'Кол-во',
        average_price: 'Итого',
    };

    const [selection, setSelection] = useState([])
    const [selectionItems, setSelectionItems] = useState([])
    const [selectionPage, setSelectionPage] = useState({})

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'comment_counter', width: 50},
        {columnName: 'unlabeled_standards', width: 200},
        {columnName: 'number', width: 150},
        {columnName: 'name', width: 200},
        {columnName: 'category', width: 200},
        {columnName: 'subcategory', width: 200},
        {columnName: 'group', width: 200},
        {columnName: 'status', width: 125},
        {columnName: 'edit_request_status', width: 200},
        {columnName: 'publish_status', width: 200},
        {columnName: 'edit_request_comment', width: 125},
        {columnName: 'edit_request_files', width: 200},
        {columnName: 'version', width: 125},
        {columnName: 'planning_year', width: 125},
        {columnName: 'month', width: 125},
        {columnName: 'planing_date_to_publish_notification', width: 125},
        {columnName: 'planning_contract_end', width: 125},
        {columnName: 'conduction_method', width: 125},
        {columnName: 'funding_sources', width: 125},
        {columnName: 'type', width: 125},
        {columnName: 'address', width: 125},
        {columnName: 'items_count', width: 125},
        {columnName: 'average_price', width: 155},
        {columnName: 'railRoad', width: 125},
        {columnName: 'region', width: 125},
        {columnName: 'company', width: 150},
        {columnName: 'created', width: 150},
        {columnName: 'updated', width: 150},
        {columnName: 'creator', width: 150},
        {columnName: 'edit_request_status_date', width: 200}
    ])

    const [columnOrder, setColumnOrder] = useState([
        'comment_counter',
        'unlabeled_standards',
        'number',
        'name',
        'category',
        'subcategory',
        'group',
        'status',
        'edit_request_status',
        'publish_status',
        'edit_request_status_date',
        'edit_request_comment',
        'edit_request_files',
        'version',
        'planning_year',
        'month',
        'planing_date_to_publish_notification',
        'planning_contract_end',
        'conduction_method',
        'funding_sources',
        'type',
        'address',
        'items_count',
        'average_price',
        'railRoad',
        'region',
        'company',
        'created',
        'updated',
        'creator'
    ])

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...((filter.hasOwnProperty('status') && !!filter.status.length) ? {status: filter.status} : {}),
            ...((filter.hasOwnProperty('publish_status') && !!filter.publish_status.length) ? {publish_status: filter.publish_status} : {}),
            ...((filter.hasOwnProperty('categories') && !!filter.categories.length) ? {categories: filter.categories} : {}),
            ...((filter.hasOwnProperty('subcategories') && !!filter.subcategories.length) ? {subcategories: filter.subcategories} : {}),
            ...((filter.hasOwnProperty('groups') && !!filter.groups.length) ? {groups: filter.groups} : {}),
            ...((filter.hasOwnProperty('edit_request_status') && !!filter.edit_request_status.length) ? {edit_request_status: filter.edit_request_status} : {}),
            ...((filter.hasOwnProperty('sources') && !!filter.sources.length) ? {sources: filter.sources} : {}),
            ...((filter.hasOwnProperty('methods') && !!filter.methods.length) ? {methods: filter.methods} : {}),
            ...((filter.hasOwnProperty('companies') && !!filter.companies.length) ? {companies: filter.companies} : {}),
            ...((filter.hasOwnProperty('rail_roads') && !!filter.rail_roads.length) ? {rail_roads: filter.rail_roads} : {}),
            ...((filter.hasOwnProperty('month') && !!filter.month.length) ? {month: filter.month} : {}),
            ...((filter.hasOwnProperty('year') && !!filter.year.from.length) ? {planning_year_from: filter.year.from} : {}),
            ...((filter.hasOwnProperty('year') && !!filter.year.to.length) ? {planning_year_to: filter.year.to} : {}),
            ...((filter.hasOwnProperty('creator') && !!filter.creator.length) ? {creator: filter.creator} : {}),
            ...(searchRequest ? {search: searchRequest} : {}),
            ...(isEditRequests ? {isEditRequests: true} : {}),
            ...((location.pathname === '/planning/joint-purchases') ? {joint: true} : {})
        }
    }

    const getPurchases = async () => {
        let params = {
            limit: rowsPerPage,
            page: page,
            simple: true,
            ...getParams()
        }
       return await dispatch(PurchaseActions.purchases(params))
    }

    const getTotal = async () => {
        let params = {
            limit: rowsPerPage,
            page: page,
            ...getParams()
        }
        return await dispatch(PurchaseActions.total(params))
    }

    useEffect(() => {
        if (!loading) {
            getTotal().then(response => {
                setMeta({
                    ...meta,
                    ...response
                })
            })

            getPurchases().then(response => {
                setPurchases(response)

                let numbers = []

                if (selectionPage.hasOwnProperty(page)) {
                    response.data.forEach((item, index) => {
                        if (selectionPage[page].find(id => (id === item.id))) {
                            numbers.push(index)
                        }
                    })
                }

                setSelection(numbers)

                setLoading(true)
                tableRef && tableRef.current && tableRef.current.scrollIntoView()
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loading])

    useEffect(() => {
        if (loading) {
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [filter, page, rowsPerPage, searchRequest, purchaseType, location.pathname])

    useEffect(() => {
        if (!initialize) {
            const getSettings = async () => {
                return await dispatch(PlanningSettings.settings())
            }

            getSettings().then(() => {})

            const getData = async () => {
                await dispatch(PlanningActions.units())
            }

            getData().then(() => {})

            dispatch(SettingsActions.table(tableName)).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: tableName,
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder, tableName])

    const summaryPurchasesCalculator = (type, rows, getValue) => {
        switch (type) {
            case 'items_count':
                return Number(purchases.agg_info.items_count).toLocaleString('ru-RU');
            case 'average_price':
                return Number(purchases.agg_info.average_price).toLocaleString('ru-RU', {
                    style: 'currency',
                    currency: 'RUB',
                });
            default:
                return IntegratedSummary.defaultCalculator(type, rows, getValue);
        }
    };

    const getFiles = (files) => {
        return files.map(file => {
            return <Grid item key={file.id}>
                <Button
                    className={classes.download}
                    endIcon={<GetAppIcon/>}
                    href={process.env.REACT_APP_HOST_API + `/planning/purchase/${file.purchase_id}/file/${file.id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                >
                    {file.description}
                </Button>
            </Grid>
        });
    }

    const prepareColumnsForColumnChooser = (columns) => {
        let preparedColumns = JSON.parse(JSON.stringify(columns))

        preparedColumns.forEach((column) => {
            if (column.title.trim() === '') {
                column.title = columnTitlesForColumnChooser.get(column.name)
            }
        })

        return preparedColumns
    }

    const getPurchaseRows = (purchases) => {
        return purchases.data.map(purchase => ({
            number: purchase.number,
            name: purchase.name,
            category: purchase.category?.dependencies[0],
            subcategory: purchase.category?.dependencies[1],
            group: purchase.category?.dependencies[2],
            status: purchase.status.name,
            publish_status: purchase.publishStatus?.name,
            edit_request_status: purchase.editRequestStatus?.name,
            edit_request_comment: purchase.edit_request_comment,
            edit_request_files: purchase.files ? getFiles(purchase.files) : null,
            edit_request_status_date: !purchase.edit_request_status_date ? '-' : (new Date(purchase.edit_request_status_date)).toLocaleDateString(),
            version: purchase.version,
            planning_year: purchase.purchase_plan ? purchase.purchase_plan.planning_year : purchase.planing_date_to_publish_notification.replace(/(\d{2}).(\d{2}).(\d{4})/, "$3"),
            month: !purchase.min_demand_start_date ? '-' : getMonthName(new Date(purchase.min_demand_start_date)),
            planing_date_to_publish_notification: format(new Date(purchase.planing_date_to_publish_notification), 'dd.MM.yyyy'),
            planning_contract_end: !purchase.max_execution_month ? '-' : format(new Date(purchase.max_execution_month), 'dd.MM.yyyy'),
            conduction_method: purchase.conduction_method ? purchase.conduction_method.name : null,
            funding_sources: purchase.funding_sources ? JSON.parse(purchase.funding_sources).join(', ') : '',
            type: purchase.type.name,
            address: purchase.territories.map(territory => territory.name).join(', '),
            items_count: parseFloat(!purchase.items_count ? 0 : purchase.items_count).toLocaleString('ru-RU'),
            average_price: parseFloat(!purchase.average_price ? 0 : purchase.average_price).toLocaleString('ru-RU', {
                style: 'currency',
                currency: 'RUB'
            }),
            railRoad: purchase.company.railRoad?.name,
            region: purchase.company.region?.name,
            company: purchase.company.name,
            created: format(new Date(purchase.created), 'dd.MM.yyyy'),
            updated: format(new Date(purchase.updated), 'dd.MM.yyyy'),
            creator: purchase.user ? getFullName(purchase.user) : null,
            notification: purchase.notification,
            chat_id: purchase.chat_id,
            unlabeled_standards: purchase.unlabeled_standards,
        }))
    }

    const getRowStyle = (row) => {
        let warning = false

        if (row.notification) {
            if (isEditRequests && row.notification.correction_period) {
                warning = true
            } else if (row.notification.editing_after_rejection && AuthorizationService.roles(account, ['customer'])) {
                warning = true
            }
        }

        return warning ? {backgroundColor: '#d9534f'} : {}
    }

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: typeAction, payload: {limitRows: newRowsPerPage}})
    }

    return (initialize && !!units.length) ? (
        <Grid item className={classes.content}>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        id="search"
                        variant="standard"
                        name={'search'}
                        placeholder={`Поиск: номер закупки`}
                        fullWidth={true}
                        margin={'normal'}
                        onChange={(event) => {
                            setSearch(event.target.value)
                        }}
                        classes={{
                            root: clsx(classes.filterLabel, classes.searchLabel)
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon style={{color: '#c1c1c1'}}/>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                            <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                {!isJoint &&
                                    <React.Fragment>
                                        {AuthorizationService.permissions(account, 'publish_purchases') && <Publish
                                            items={selectionItems}
                                            onClick={() => {
                                                setSelection([])
                                                setSelectionItems([])
                                                setSelectionPage({})
                                                setLoading(false)
                                            }}
                                        />}
                                        <ApproveButton
                                            items={selectionItems}
                                            settings={planningSettings}
                                            onClick={() => {
                                                setSelection([])
                                                setSelectionItems([])
                                                setSelectionPage({})
                                                setLoading(false)
                                            }}
                                        />
                                        {(!isCustomer(account) || (!isEditRequests && isCustomer(account))) &&
                                            <DeclineButton
                                                items={selectionItems}
                                                settings={planningSettings}
                                                onClick={() => {
                                                    setSelection([])
                                                    setSelectionItems([])
                                                    setSelectionPage({})
                                                    setLoading(false)
                                                }}
                                            />
                                        }
                                    </React.Fragment>
                                }
                                {isEditRequests &&
                                    <React.Fragment>
                                        <ApproveButton
                                            correction={true}
                                            items={selectionItems}
                                            settings={planningSettings}
                                            onClick={() => {
                                                setSelection([])
                                                setSelectionItems([])
                                                setSelectionPage({})
                                                setLoading(false)
                                            }}
                                        />
                                        <DeclineButton
                                            correction={true}
                                            items={selectionItems}
                                            settings={planningSettings}
                                            onClick={() => {
                                                setSelection([])
                                                setSelectionItems([])
                                                setSelectionPage({})
                                                setLoading(false)
                                            }}
                                        />
                                    </React.Fragment>
                                }
                                {location.pathname === '/planning/joint-purchases' && AuthorizationService.roles(account, 'supervisor') && account.organization !== 'regional' &&
                                    <Grid item>
                                        <Submit
                                            className={classes.submit}
                                            disableElevation
                                            variant="contained"
                                            color="primary"
                                            type="button"
                                            onClick={() => {
                                                history.push('/planning/products')
                                            }}
                                        >
                                            Создать совместную закупку
                                        </Submit>
                                    </Grid>
                                }
                                <EditButton
                                    disabled={(selectionItems.length !== 1)}
                                    purchase={selectionItems[0]}
                                />
                                <CopyButton
                                    items={selectionItems}
                                    onClick={() => {
                                        setSelection([])
                                        setSelectionItems([])
                                        setSelectionPage({})
                                        dispatch({type: 'PURCHASE_PLAN_ITEMS_BY_PARENT_IDS_CLEAR'})
                                    }}
                                />
                                <DeleteButton
                                    items={selectionItems}
                                    onClick={() => {
                                        setSelection([])
                                        setSelectionItems([])
                                        setSelectionPage({})
                                        setLoading(false)
                                    }}
                                />
                                <Grid item>
                                    <ButtonIcon
                                        type="button"
                                        size="small"
                                        disabled={!meta.total}
                                        onClick={() => {
                                            const type = (location.pathname === '/planning/joint-purchases') ? 'planning_joint_purchases' : (isEditRequests ? 'planning_adjustment_purchases' : 'planning_purchases')

                                            dispatch(DownloadActions.create({
                                                type: type,
                                                columns: columnOrder.filter(name => (hiddenColumnNames.indexOf(name) < 0)),
                                                params: {
                                                    order: (filter.sort.name && filter.sort.direction) ? `${filter.sort.name}, ${filter.sort.direction}` : `created, desc`,
                                                    type: type,
                                                    ...Object.entries(getParams()).reduce((result, [key, value]) => {
                                                        result[key] = Array.isArray(value) ? value.join(',') : value

                                                        return result
                                                    }, {})
                                                }
                                            }))
                                        }}
                                        children={<GetAppIcon/>}
                                        title='Скачать таблицу'
                                        className={classes.iconButton}
                                    />
                                </Grid>
                                <Grid item>
                                    <ColumnChooser
                                        columns={isEditRequests !== null ? adjustmentColumns : prepareColumnsForColumnChooser(columns)}
                                        hiddenColumnNames={hiddenColumnNames}
                                        setHiddenColumnNames={setHiddenColumnNames}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <CustomTable
                    meta={meta}
                    rows={getPurchaseRows(purchases)}
                    tableName={tableName}
                    columns={isEditRequests !== null ? adjustmentColumns : columns}
                    page={{page: page, setPage: setPage, rowsPerPage: rowsPerPage, handlePageSizeChange: handlePageSizeChange}}
                    selection={{selection: selection, setSelection: setSelection}}
                    totalSummaryItems={totalPurchaseSummaryItems}
                    summaryCalculator={summaryPurchasesCalculator}
                    setTableRef={setTableRef}
                    columnsSettings={{columnOrder: columnOrder, setColumnOrder: setColumnOrder, setColumnWidths: setColumnWidths, columnWidths: columnWidths, hiddenColumnNames: hiddenColumnNames, setHiddenColumnNames: setHiddenColumnNames}}
                    tableHeader={TableHeader}
                    filterActionType={typeAction}
                    getValues={PurchaseActions.purchasesFilter}
                    messages={messagesPurchases}
                    onSelectionChange={(numbers) => {
                        setSelection(numbers)
                        const data = purchases.data.filter((item, index) => (numbers.find(key => (key === index)) !== undefined)).map(item => item)
                        setSelectionItems([
                            ...data,
                            ...selectionItems.filter(item => !purchases.data.find(el => el.id === item.id))
                        ])
                        setSelectionPage(!!data.length ? {...selectionPage, [page]: data.map(item => item.id)} : Object.fromEntries(
                            Object.entries(selectionPage).filter(key => (parseInt(key) !== parseInt(page))).map(([key, value]) => [key, value])
                        ))
                    }}
                    main
                    classInputContent={classes.inputContentMain}
                    rowComponentSelection={(props) => {
                        const {tableRow, children, highlighted, onToggle} = props
                        const {row} = tableRow

                        let timer = null;
                        let prevent = false;

                        if (Object.keys(purchases.agg_info.chats_with_count_not_read_comments).length > 0 && row.chat_id && purchases.agg_info.chats_with_count_not_read_comments[row.chat_id]) {
                            row.count_unread_messages = purchases.agg_info.chats_with_count_not_read_comments[row.chat_id]
                        } else {
                            row.count_unread_messages = 0
                        }

                        row['comment_counter'] =
                            <div className={classes.circle}>
                                <span>{row.count_unread_messages}</span>
                            </div>

                        const handleClick = (e) => {
                            let element = e.target
                            let useTimer = true

                            for (let i = 0; i < 5; i++) {
                                if (!element.hasAttribute('href')) {
                                    element = element.parentNode
                                } else {
                                    useTimer = false
                                }
                            }

                            if (useTimer) {
                                timer = setTimeout(() => {
                                    if (!prevent) {
                                        if (!window.getSelection().toString()) {
                                            onToggle()
                                        }
                                    }
                                    prevent = false;
                                }, 250);
                            }
                        }

                        const handleDoubleClick = () => {
                            clearTimeout(timer);
                            prevent = true;
                            history.push(purchases.data[tableRow.rowId].purchase_plan_id ? `/planning/purchase-plan/${purchases.data[tableRow.rowId].purchase_plan_id}/purchase/${purchases.data[tableRow.rowId].id}` : `/planning/purchase-plan/purchase/${purchases.data[tableRow.rowId].id}`)
                        }

                        return (
                            <Table.Row
                                tableRow={tableRow}
                                children={children}
                                onClick={handleClick}
                                style={getRowStyle(row)}
                                onDoubleClick={handleDoubleClick}
                                className={highlighted ? classes.active : ((row.loaded === 'Загружен') ? classes.default : classes.loading)}
                                row={row}
                            />
                        )
                    }}
                    rowComponent={({row, tableRow, children}) => (
                        <Table.Row
                            tableRow={tableRow}
                            children={children}
                            className={classes.default}
                            row={row}
                        />
                    )}
                    filters={{
                        status: {
                            name: 'status',
                            type: 'values',
                        },
                        publish_status: {
                            name: 'publish_status',
                            type: 'values',
                        },
                        planning_year: {
                            name: 'year',
                            type: 'year',
                        },
                        category: {
                            name: 'categories',
                            type: 'values',
                        },
                        subcategory: {
                            name: 'subcategories',
                            type: 'values',
                        },
                        group: {
                            name: 'groups',
                            type: 'values',
                        },
                        railRoad: {
                            name: 'rail_roads',
                            type: 'values',
                        },
                        region: {
                            name: 'regions',
                            type: 'values',
                        },
                        company: {
                            name: 'companies',
                            type: 'values',
                        },
                        month: {
                            name: 'month',
                            type: 'month',
                        },
                        edit_request_status: {
                            name: 'edit_request_status',
                            type: 'values',
                        },
                        conduction_method: {
                            name: 'methods',
                            type: 'values',
                        },
                        funding_sources: {
                            name: 'sources',
                            type: 'source',
                        },
                        creator: {
                            name: 'creator',
                            type: 'values',
                        },
                        comment_counter: {
                            button: <CommentOutlinedIcon
                                fontSize="small"
                                style={{display: "block", margin: "auto"}}
                            />
                        },
                        edit_request_comment: {
                            nullButton: true
                        },
                        edit_request_files: {
                            nullButton: true
                    }
                }}
                />
            </Grid>
        </Grid>
    ) : null
}
