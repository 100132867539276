import {AutoService} from "../services/auto"

export const AutoActions = {
    orders,
    order,
    items,
    load
}

function orders(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        AutoService.orders(params)
            .then(
                response => {
                    dispatch({type: "DELIVERY_AUTO_ORDERS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "DELIVERY_AUTO_ORDERS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function order(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_AUTO_ORDER_REQUEST"})

        AutoService.order(id)
            .then(
                order => {
                    dispatch({type: "DELIVERY_AUTO_ORDER", payload: order})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELIVERY_AUTO_ORDER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function items(id, type, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_AUTO_ORDER_ITEMS_REQUEST"})

        AutoService.items(id, type, params)
            .then(
                response => {
                    dispatch({type: "DELIVERY_AUTO_ORDER_ITEMS", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELIVERY_AUTO_ORDER_ITEMS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function load(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_LOAD_AUTO_ORDER_REQUEST"})

        AutoService.load(params)
            .then(
                response => {
                    dispatch({type: "DELIVERY_LOAD_AUTO_ORDER_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELIVERY_LOAD_AUTO_ORDER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
