import React from "react"

import "spinkit/spinkit.min.css"

export const Wave = () => {
    return (
        <div className="sk-wave">
            <div className="sk-wave-rect" />
            <div className="sk-wave-rect" />
            <div className="sk-wave-rect" />
            <div className="sk-wave-rect" />
            <div className="sk-wave-rect" />
        </div>
    )
}
