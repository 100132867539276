import {HttpService} from "../../App/services/http"

export const PriceService = {
    prices,
    save,
    del,
    download,
    getProducts,
    getProductsWithCategory,
    getOfferProducts,
    setStandard,
    types,
    getStandards,
    addProduct,
    setCategory,
    unsetCategory,
    setCanBeOrdered,
    getById
}

function prices(params) {
    return HttpService.get("/prices", params)
}

function download(body) {
    return HttpService.post(`/prices`, body, true, true)
}

function del(id) {
    return HttpService.deleteMethod(`/prices/${id}`)
}

function types() {
    return HttpService.get("/prices/types")
}

function getProducts(params) {
    return HttpService.get("/prices/products", params)
}

function getOfferProducts(params) {
    return HttpService.get("/prices/products/offer", params)
}

function setStandard(id, params) {
    return HttpService.put(`/prices/products/${id}/standard`, params)
}


function addProduct(formData) {
    return HttpService.post(`/prices/${formData.get('price_id')}/product`, formData, true, true)
}

function getProductsWithCategory(params) {
    return HttpService.get("/prices/products", params)
}

function setCategory(params) {
    return HttpService.put(`/prices/products/category/set`, params)
}

function unsetCategory(params) {
    return HttpService.put(`/prices/products/category/unset`, params)
}

function setCanBeOrdered(params) {
    return HttpService.put(`/prices/products/can-be-ordered`, params)
}

function getById(id, price_id) {
    return HttpService.get(`/contract/${id}/price/${price_id}`)
}

function save(params) {
    return HttpService.post("/prices", params)
}

function getStandards(params) {
    return HttpService.get({path: "/items", host: process.env.REACT_APP_SERVICE_NOMENCLATURE_API}, params, false)
        .then(response => {
            return response.data
        })
}
