import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core"

import {AutoActions} from "../../../../actions/auto"

const useStyles = makeStyles(() => ({
    fullWidth: {
        "width": "100%"
    },
    inputContent: {
        "padding": "20px 30px 20px",
        "width": "100%"
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 299px)"
    },
    table: {
        "border": "1px solid #e4e4e4",
    },
    tableContainer: {
        "height": "582px",
        "overflow": "auto"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "7px",
        "padding-right": "5px",
    },
}))

const columns = [
    {
        id: 0,
        label: 'Наименование',
        key: 'name',
        format: (value) => value.toLocaleString()
    },
    {
        id: 2,
        label: 'Кол-во',
        key: 'amount',
        format: (value) => value.toLocaleString()
    },
    {
        id: 3,
        label: 'Тип',
        key: 'type',
        format: (value) => value.toLocaleString()
    }
]

const types = {
    marketplace: 'Маркетплейс',
    contract: 'По договору'
}

export const Present = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { order } = props
    const { items } = useSelector(state => { return {items: state.delivery.auto_order_items}})

    const [page, setPage] = useState(parseInt(items.meta.current_page - 1))
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [loading, setLoading] = useState(false)
    const tableRef = useRef(null)

    useEffect(() => {
        const getOrders = async () => {
            return await dispatch(AutoActions.items(order.id, 'present', {
                limit: rowsPerPage,
                page: page + 1
            }));
        }

        if (!loading) {
            getOrders().then(_ => {
                tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
                setLoading(true)
            })
        }
    }, [dispatch, loading, page, rowsPerPage, order])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        setLoading(false)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
        setLoading(false)
    }

    return (
        <Grid item className={classes.fullWidth}>
            <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                <Grid item className={classes.inputContent}>
                    <TableContainer className={classes.tableContainer}>
                        <Table ref={tableRef} stickyHeader className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.data.map(item => (
                                    <TableRow hover key={item.id}>
                                        <TableCell>
                                            {item.name}
                                        </TableCell>
                                        <TableCell>
                                            {item.amount}
                                        </TableCell>
                                        <TableCell>
                                            {item.type ? types[item.type] : null}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Grid item className={classes.footer}>
                <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                    <Grid item className={classes.footerContent}>
                        <Grid container className={classes.footerContainer} direction="row" justify="space-between" alignItems="center" spacing={2}>
                            <Grid item>
                                <TablePagination
                                    rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                    component='div'
                                    labelRowsPerPage={'Записей на странице:'}
                                    count={items.meta.total}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
