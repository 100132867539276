import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import format from "date-fns/format"
import {ru} from "date-fns/locale"

import {Grid, makeStyles} from "@material-ui/core"
import {Check} from '@material-ui/icons'
import {Table} from "@devexpress/dx-react-grid-material-ui";

import {Submit} from "../../../../../../App/components/Button/Submit";
import {history} from "../../../../../../App/helpers/history";
import {CustomTable} from "../../../../../../App/components/Table/Table";
import {TableHeader} from "../../../../../../App/components/Table/TableHeader";
import {ColumnChooser} from "../../../../../../App/components/Table/ColumnChooser";
import {SettingsActions} from "../../../../../../Settings/actions/settings";
import {localState} from "../../../../../../App/components/LocalState";
import {PurchaseActions} from "../../../../../../Planning/actions/purchase";
import {parseCurrency} from "../../../../../../App/helpers/currency";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 158px)"
    },
    input: {
        "width": "100%",
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 209px)",
        "min-height": "calc(100vh - 209px)"
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0",
    },
    column: {
        "height": "86px"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    tableRowAlert: {
        "cursor": "pointer",
        "background-color": "#f9f1f0",
        "text-decoration": "none"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    },
}))

const columns = [
    {name: 'number', title: '№'},
    {name: 'name', title: 'Название'},
    {name: 'category', title: 'Категория', filter: 'categories'},
    {name: 'lots_count', title: 'Кол-во лотов'},
    {name: 'type', title: 'Возможность изменения'},
    {name: 'average_price', title: 'НМЦД'},
    {name: 'status', title: 'Статус'},
    {name: 'application_deadline', title: 'Дата окончания приёма предложений'},
    {name: 'application_start', title: 'Дата формирования'},
]

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {filter} = useSelector(state => state.filters['marketplace_requests'])
    const [purchases, setPurchases] = useState(localState)
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [tableRef, setTableRef] = useState(null)
    const [selection, setSelection] = useState([])
    const [selectionItems, setSelectionItems] = useState([])
    const [selectionPage, setSelectionPage] = useState({})

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'number', width: 100},
        {columnName: 'name', width: 200},
        {columnName: 'category', width: 200},
        {columnName: 'lots_count', width: 150},
        {columnName: 'type', width: 250},
        {columnName: 'average_price', width: 150},
        {columnName: 'status', width: 150},
        {columnName: 'application_deadline', width: 200},
        {columnName: 'application_start', width: 200},
    ])

    const [columnOrder, setColumnOrder] = useState([
        'number',
        'name',
        'category',
        'lots_count',
        'type',
        'average_price',
        'status',
        'application_deadline',
        'application_start'
    ])

    const [initialize, setInitialize] = useState(false);

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
        }
    }

    const getPurchases = async () => {
        return await dispatch(PurchaseActions.getPublish({
            ...{
                limit: rowsPerPage,
                page: page,
                ...getParams()
            }
        }))
    }

    useEffect(() => {
        getPurchases().then((response) => {
            setPurchases(response)

            let numbers = []

            if (selectionPage.hasOwnProperty(page)) {
                response.data.forEach((item, index) => {
                    if (selectionPage[page].find(id => (id === item.id))) {
                        numbers.push(index)
                    }
                })
            }

            setSelection(numbers)

            tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
        })
        // eslint-disable-next-line
    }, [dispatch, page, rowsPerPage, filter]);

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: "MARKETPLACE_REQUESTS_FILTER", payload: {limitRows: newRowsPerPage}})
    }

    useEffect(() => {
        if (!initialize) {
            dispatch(SettingsActions.table('marketplace_requests')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                dispatch({type: "FILTER_CLEAR"})
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'marketplace_requests',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    return <Grid item className={classes.content}>
        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2} style={{marginBottom: '8px'}}>
            <Grid item>
                <Submit
                    disableElevation
                    variant="contained"
                    color="primary"
                    type="button"
                    disabled={selectionItems.length !== 1}
                    onClick={() => {
                        history.push(`/marketplace/request/${selectionItems.length === 1 ? selectionItems[0].id : ''}/edit`)
                    }}
                >
                    Редактировать
                </Submit>
            </Grid>
            <Grid item>
                <Submit
                    disableElevation
                    variant="contained"
                    color="primary"
                    type="button"
                    component={Link}
                    to="/marketplace/planning/purchase/add"
                >
                    Опубликовать закупку
                </Submit>
            </Grid>
            <Grid item>
                <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
            </Grid>
        </Grid>
        <CustomTable
            meta={purchases.meta}
            tableName={'marketplace_requests'}
            onSelectionChange={(numbers) => {
                setSelection(numbers)
                const data = purchases.data.filter((item, index) => (numbers.find(key => (key === index)) !== undefined)).map(item => item)
                setSelectionItems([
                    ...data,
                    ...selectionItems.filter(item => !purchases.data.find(el => el.id === item.id))
                ])
                setSelectionPage(!!data.length ? {...selectionPage, [page]: data.map(item => item.id)} : Object.fromEntries(
                    Object.entries(selectionPage).filter(key => (parseInt(key) !== parseInt(page))).map(([key, value]) => [key, value])
                ))
            }}
            selection={{selection: selection, setSelection: setSelection}}
            rows={purchases.data.map((order) => ({
                number: order.id,
                name: order.name,
                category: order?.category?.name,
                lots_count: order.count.lots,
                type: order.type === 'dynamic' ? <Check fontSize="small"/> : null,
                status: order.status.index === 8 ? "В работе" : order.status.name,
                average_price: order?.is_unit_price ? parseCurrency(order?.lots?.reduce((total, lot) => total + (lot?.max_contract_price ?? 0), 0), order?.currency?.code) : parseCurrency(order?.average_price, order?.currency?.code),
                application_deadline: order?.application_deadline ? <>{format(new Date(order.application_deadline), 'dd.MM.yyyy HH:mm')}<br/>(мск {format(new Date(new Date(order.application_deadline).toLocaleString("en-US", {timeZone: "Europe/Moscow"})), 'dd.MM.yyyy HH:mm', {locale: ru})})</> : null,
                application_start: <>{format(new Date(order.created), 'dd.MM.yyyy HH:mm')}<br/>(мск {format(new Date(new Date(order.created).toLocaleString("en-US", {timeZone: "Europe/Moscow"})), 'dd.MM.yyyy HH:mm', {locale: ru})})
                </>
            }))}
            columns={columns}
            page={{
                page: page,
                setPage: setPage,
                rowsPerPage: rowsPerPage,
                handlePageSizeChange: handlePageSizeChange
            }}
            setTableRef={setTableRef}
            columnsSettings={{
                columnOrder: columnOrder,
                setColumnOrder: setColumnOrder,
                setColumnWidths: setColumnWidths,
                columnWidths: columnWidths,
                hiddenColumnNames: hiddenColumnNames,
                setHiddenColumnNames: setHiddenColumnNames
            }}
            rowComponentSelection={(props) => {
                const {tableRow, children, highlighted, onToggle} = props
                const {row} = tableRow

                let timer = null;
                let prevent = false;

                row['comment_counter'] =
                    <div className={classes.circle}>
                        <span>{row.count_unread_messages}</span>
                    </div>

                const handleClick = (e) => {
                    let element = e.target
                    let useTimer = true

                    for (let i = 0; i < 5; i++) {
                        if (!element.hasAttribute('href')) {
                            element = element.parentNode
                        } else {
                            useTimer = false
                        }
                    }

                    if (useTimer) {
                        timer = setTimeout(() => {
                            if (!prevent) {
                                if (!window.getSelection().toString()) {
                                    onToggle()
                                }
                            }
                            prevent = false;
                        }, 250);
                    }
                }

                const handleDoubleClick = () => {
                    clearTimeout(timer);
                    prevent = true;
                    history.push(`/marketplace/request/${purchases.data[tableRow.rowId].id}`)
                }

                return (
                    <Table.Row
                        tableRow={tableRow}
                        children={children}
                        onClick={handleClick}
                        style={{}}
                        onDoubleClick={handleDoubleClick}
                        className={highlighted ? classes.active : ((row.loaded === 'Загружен') ? classes.default : classes.loading)}
                        row={row}
                    />
                )
            }}
            tableHeader={TableHeader}
            filterActionType={"MARKETPLACE_REQUESTS_FILTER"}
            classInputContent={classes.inputContent}
            rowComponent={({row, tableRow, children}) => (
                <Table.Row
                    tableRow={tableRow}
                    className={classes.tableRow}
                    hover
                    row={row}
                    children={children}
                    onClick={() => {
                        !purchases.data[tableRow.rowId].status.index ? history.push(`/marketplace/request/${purchases.data[tableRow.rowId].id}/edit`) : history.push(`/marketplace/request/${purchases.data[tableRow.rowId].id}`)
                    }}
                    style={{'cursor': 'pointer'}}
                />
            )}
            filters={{
                type: {
                    nullButton: true
                }
            }}
        />
    </Grid>
}
