export default function shop(state = {orders: {data: [], meta: {total: 0, per_page: 15, current_page: 1}}, order: null}, action) {
    switch (action.type) {
        case "MY_ORDERS_CLEAR":
            return {orders: {data: [], meta: {total: 0, per_page: 15, current_page: 1}}, order: null}
        case "MY_ORDERS_REQUEST":
            return {
                orders: state.orders
            }
        case "MY_ORDERS":
            return {
                orders: action.payload,
            }
        case "MY_ORDERS_FAILURE":
            return {
                orders: state.orders
            }
        case "MY_ORDER_REQUEST":
            return {
                order: state.order,
                orders: state.orders
            }
        case "MY_ORDER":
            return {
                order: action.payload,
                orders: state.orders
            }
        case "MY_ORDER_FAILURE":
            return {
                order: state.orders,
                orders: state.orders
            }
        case "ADD_MY_ORDER_REQUEST":
            return {
                orders: state.orders
            }
        case "ADD_MY_ORDER_SUCCESS":
            return {
                orders: state.orders
            }
        case "ADD_MY_ORDER_FAILURE":
            return {
                order: state.orders,
                orders: state.orders
            }
        case "MY_LOT_REQUEST":
            return {
                order: state.orders,
                orders: state.orders
            }
        case "MY_LOT":
            return {
                lot: action.payload,
                order: state.orders,
                orders: state.orders
            }
        case "MY_LOT_CLEAR":
            return {
                order: state.orders,
                orders: state.orders
            }
        case "MY_LOT_FAILURE":
            return state
        case "EDIT_MY_ORDER_REQUEST":
            return {
                order: state.order,
                orders: state.orders
            }
        case "EDIT_MY_ORDER_SUCCESS":
            return {
                orders: state.orders
            }
        case "EDIT_MY_ORDER_FAILURE":
            return {
                order: state.order,
                orders: state.orders
            }
        case "STATUS_MY_ORDER_REQUEST":
            return state
        case "STATUS_MY_ORDER_SUCCESS":
            return {
                orders: state.orders
            }
        case "STATUS_MY_ORDER_FAILURE":
            return state
        case "DELETE_MY_ORDER_REQUEST":
            return {
                order: state.order,
                orders: state.orders
            }
        case "DELETE_MY_ORDER_SUCCESS":
            return {
                orders: state.orders
            }
        case "DELETE_MY_ORDER_FAILURE":
            return {
                order: state.order,
                orders: state.orders
            }
        default:
            return state
    }
}
