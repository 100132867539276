import {HttpService} from "../../../App/services/http"

export const AgreementService = {
    create,
    approve,
    items
}

function create(id, formData) {
    return HttpService.post(`/contract/${id}/agreement`, formData, true, true)
}

function approve(id) {
    return HttpService.post(`/contract/${id}/agreement/approve`)
}

function items(id, params) {
    return HttpService.get(`/contract/${id}/agreement/items`, params)
}
