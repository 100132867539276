import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik, getIn} from "formik"

import {
    Collapse,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    TextField as MuiTextField,
    withStyles
} from '@material-ui/core'
import {Autocomplete} from "@material-ui/lab"
import {ExpandMore, ExpandLess} from "@material-ui/icons"

import {SystemActions} from "../App/actions/system"
import {TextField as CustomTextField} from "../App/components/Inputs/TextField"
import {Submit} from "../App/components/Button/Submit"
import {ContractActions} from "../Contract/actions/contract"
import {CompanyActions} from "../Contract/actions/company"
import * as AppStorage from "../App/helpers/storage"
import {localState} from "../App/components/LocalState";
import {useDebouncedCallback} from "use-debounce";

const useStyles = makeStyles(theme => ({
    item: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "cursor": "pointer"
    },
    active: {
        "background-image": "none !important",
        "background-color": "#898989"
    },
    nested: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "cursor": "pointer",
        "padding-left": `${theme.spacing(6)}px !important`
    }
}))

const CustomList = withStyles({
    root: {
        "padding": "0",
        "& .MuiListItem-button": {
            "background-image": "linear-gradient(to bottom,#e4e4e4,#f4f4f4)",
            "cursor": "default !important",
            "align-items": "center",
            "min-height": "50px",
            "padding": "5px 25px 0",
            "border-bottom": "1px solid #e4e4e4",
            "outline": "0"
        },
        "& .MuiListItem-root": {
            "cursor": "pointer"
        },
        "& .MuiListItemIcon-root": {
            "min-width": "30px"
        },
        "& .MuiListItemText-primary": {
            "font-size": "16px",
            "font-weight": "700",
            "text-transform": "uppercase"
        },
        "& .MuiListItemText-secondary": {
            "color": "#485868",
            "font-size": "16px"
        }
    }
})(List);

export const Menu = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { categories, filter } = useSelector(state => {return {...state.system, ...state.catalog.products}})
    const { companies }  = useSelector(state => state.contractCompanies)
    const [contracts, setContracts] = useState(localState)
    const query = new URLSearchParams(props.location.search);

    const storageContract = AppStorage.get('filterCatalogProducts') ? JSON.parse(AppStorage.get('filterCatalogProducts')) : null

    const storageContractId = storageContract && storageContract.contract !== null && storageContract.contract.id !== null ? storageContract.contract.id  : null

    const contractId = !isNaN(parseInt(query.get('contract_id'))) ? parseInt(query.get('contract_id')) : storageContractId

    const [open, setOpen] = useState([])
    const [active, setActive] = useState(null)
    const [supplier, setSupplier] = useState(null)
    const [contract, setContract] = useState(null)
    const debounced = useDebouncedCallback(
        (value, type) => {
            switch (type) {
                case 'supplier':
                    setSupplier(value)
                    break;
                case 'contract':
                    setContract(value)
                    break;
            }
        },
        900
    );
    const [initial, setInitial] = useState({filter: filter})

    useEffect(() => {
        if (supplier) {
            const getCompanies = async () => {
                let params = {
                    limit: 10,
                    page: 1,
                    type: 'supplier',
                    ...(supplier ? {name: supplier} : {}),
                }
                await dispatch(CompanyActions.companies(params))
            }

            getCompanies().then(_ => {})
        }
    }, [dispatch, supplier])

    useEffect(() => {
        if (contract || contractId) {
            const getContracts = async () => {
                let params = {
                    limit: 10,
                    page: 1,
                    ...(contract ? {search: contract} : {}),
                    ...(contractId ? {id: contractId} : {}),
                }
                return await dispatch(ContractActions.contracts(params))
            }

            getContracts().then(contracts => {
                setInitial({
                    filter: {
                        ...filter,
                        ...(contractId && contracts.data.find(contract => contract.id === contractId) ? {contract: contracts.data.find(contract => contract.id === contractId)} : {})
                    }
                })
                setContracts(contracts)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, contract, contractId])

    useEffect(() => {
        dispatch({type: "CATALOG_PRODUCTS_CATEGORY", payload: active})
    }, [dispatch, active])

    const assembly = (categories, parent = 0, level = 0) => {
        let result = []
        const handleClick = (id) => {
            setActive(id)
            if ((open.indexOf(id) >= 0)) {
                setOpen(open.filter((item) => parseInt(item) !== parseInt(id)));
            } else {
                setOpen( [...open, id])
            }
        };

        if (categories.hasOwnProperty(parent)) {
            categories[parent].forEach(category => {
                result.push(
                    <ListItem
                        classes={{root: level ? classes.nested : classes.item}}
                        style={(!!category && (category.id === active)) ? {"background": "#898989"} : {}}
                        onClick={() => {
                            handleClick(category.id)
                        }}
                        key={category.id}
                        value={category.id}
                    >
                        <ListItemIcon>
                            {categories.hasOwnProperty(category.id)
                                ? ((open.indexOf(category.id) >= 0) ? <ExpandMore/> : <ExpandLess/>)
                                : <React.Fragment/>
                            }
                        </ListItemIcon>
                        <ListItemText secondary={category.name} />
                    </ListItem>
                )

                result = result.concat(
                    <Collapse key={`${category.id}_collapse`} in={(open.indexOf(category.id) >= 0)} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {assembly(categories, category.id, level + 1)}
                        </List>
                    </Collapse>
                )
            })
        }

        return result
    }

    const getCategoriesTree = categories => {
        let tmp = {}
        categories.forEach(category => {
            if (!tmp.hasOwnProperty((category.category !== null) ? category.category.id : 0)) {
                tmp[(category.category !== null) ? category.category.id : 0] = []
            }

            tmp[(category.category !== null) ? category.category.id : 0].push(category)
        })

        return assembly(tmp)
    }

    useEffect(() => {
        const getCategories = async () => {
            return await dispatch(SystemActions.categories())
        }

        if (!categories.length) {
            getCategories().then(_ => {})
        }
        // eslint-disable-next-line
    }, [dispatch]);

    return (
        <React.Fragment>
            <CustomList>
                <ListItem button>
                    <ListItemText primary="Категории" />
                </ListItem>
                <List component="div" disablePadding style={{"height": "calc(50vh - 50px)", "overflow": "auto"}}>
                    {
                        categories.length && getCategoriesTree(categories)
                    }
                </List>
                <ListItem button>
                    <ListItemText primary="Фильтрация списка" />
                </ListItem>
            </CustomList>
                <Formik
                    enableReinitialize
                    initialValues={initial}
                    onSubmit={(values) => {
                        return dispatch({type: "CATALOG_PRODUCTS_FILTER", payload: values.filter })
                    }}
                >
                    {({
                      values,
                      errors,
                      touched,
                      submitForm,
                      setFieldValue,
                      setFieldTouched
                    }) => (
                    <Form>
                        <Grid container direction="column" justify="space-between" alignItems="stretch" style={{"height": "calc(50vh - 105px)", "width": "100%", "margin": "0px"}} spacing={2}>
                            <Grid item>
                                <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                    <Grid item>
                                        <Autocomplete
                                            filterOptions={options => options}
                                            name={'filter.supplier'}
                                            options={companies.data}
                                            value={values.filter.supplier}
                                            getOptionLabel={company => {
                                                return company ? (company.hasOwnProperty('legal_detail') ? company.legal_detail.name : (company.name ?? '')) : ''
                                            }}
                                            noOptionsText='Поставщик'
                                            onChange={(e, item) => {
                                                setFieldValue('filter.supplier', item ? {id: item.id, name: item.legal_detail.name} : null)
                                            }}
                                            renderInput={params => {
                                                const error = getIn(touched, 'filter.supplier') && getIn(errors, 'filter.supplier');

                                                return <Field
                                                    fullWidth
                                                    component={MuiTextField}
                                                    {...params}
                                                    onChange={(e) => {
                                                        debounced.callback(e.target.value, 'supplier')
                                                    }}
                                                    error={!!error}
                                                    helperText={error}
                                                    name={'filter.supplier'}
                                                    label="Поставщик"
                                                />
                                            }}
                                            onFocus={() => {
                                                dispatch({type: 'CONTRACTS_CLEAR'})
                                                setFieldTouched('filter.supplier', true, true)
                                            }}
                                            onBlur={() => {
                                                setFieldTouched('filter.supplier', true, true)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Autocomplete
                                            filterOptions={options => options}
                                            name={'filter.contract'}
                                            options={contracts.data}
                                            value={values.filter.contract}
                                            getOptionLabel={contract => {
                                                return contract ? (contract.hasOwnProperty('offer') ? (contract.offer.lot.order.name ? `${contract.id} ${contract.offer.lot.order.name}` : `${contract.id}`) : '') : ''
                                            }}
                                            noOptionsText='Договор'
                                            onChange={(e, item) => {
                                                setFieldValue('filter.contract', item ?? null)
                                            }}
                                            renderInput={params => {
                                                const error = getIn(touched, 'filter.contract') && getIn(errors, 'filter.contract');

                                                return <Field
                                                    fullWidth
                                                    component={MuiTextField}
                                                    {...params}
                                                    onChange={(e) => {
                                                        debounced.callback(e.target.value, 'contract')
                                                    }}
                                                    error={!!error}
                                                    helperText={error}
                                                    name={'filter.contract'}
                                                    label="Договор"
                                                />
                                            }}
                                            onFocus={() => {
                                                dispatch({type: 'CONTRACT_ALL_CONTRACTS_CLEAR'})
                                                setFieldTouched('filter.contract', true, true)
                                            }}
                                            onBlur={() => {
                                                setFieldTouched('filter.contract', true, true)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                            <Grid item xs={6}>
                                                <Field
                                                    fullWidth
                                                    name="filter.price.from"
                                                    type="number"
                                                    label="Цена от"
                                                    inputProps={{
                                                        min: 0,
                                                        step: 0.01
                                                    }}
                                                    component={CustomTextField}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field
                                                    fullWidth
                                                    name="filter.price.to"
                                                    type="number"
                                                    label="до"
                                                    inputProps={{
                                                        min: 0,
                                                        step: 0.01
                                                    }}
                                                    component={CustomTextField}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Field
                                            fullWidth
                                            name="filter.amount"
                                            type="number"
                                            label="Количество, от"
                                            step={1}
                                            min={0}
                                            inputProps={{
                                                min: 0
                                            }}
                                            component={CustomTextField}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Submit
                                            className={classes.submit}
                                            disableElevation
                                            variant="contained"
                                            color="secondary"
                                            type="button"
                                            onClick={() => {
                                                setFieldValue('filter', { supplier: '', contract: '', amount: '', price: { from: '', to: '' } })
                                                dispatch({type: "CATALOG_PRODUCTS_FILTER", payload: { supplier: '', contract: '', amount: '', price: { from: '', to: '' } }})
                                                AppStorage.set('CATALOG_PRODUCTS_FILTER_CLEAR', true)
                                            }}
                                        >
                                            Сбросить
                                        </Submit>
                                    </Grid>
                                    <Grid item>
                                        <Submit
                                            className={classes.submit}
                                            disableElevation
                                            variant="contained"
                                            color="primary"
                                            type="button"
                                            onClick={() => {
                                                submitForm().then(() => {})
                                            }}
                                        >
                                            Применить
                                        </Submit>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
                </Formik>
        </React.Fragment>
    )
}
