import {HttpService} from "../../App/services/http"

export const WarningService = {
    events,
    add,
    edit,
    read,
    remove,
}

function events(params) {
    return HttpService.get("/warning/events", params)
}

function add(values) {
    return HttpService.post("/warning/event", values, true).then(response => response.event)
}

function edit(id, values) {
    return HttpService.put(`/warning/event/${id}`, values, true).then(response => response.event)
}

function read(id) {
    return HttpService.put(`/warning/event/${id}/read`).then(response => response.event)
}

function remove(params) {
    return HttpService.deleteMethod('/warning/events', params, true, 'params')
}
