import React from "react"
import {Field} from "formik"
import {TextField} from "formik-material-ui"

import {InputAdornment} from "@material-ui/core"

export const FieldInteger = (props) => {
    const { id, label, addition, isSubmitting, disabled } = props

    return (
        <Field
            fullWidth
            name={ `attributes.${id}` }
            type="number"
            disabled={ isSubmitting || disabled }
            label={ `${label}` }
            component={ TextField }
            inputProps={{
                step: 1,
                onWheel: e => {
                    e.target.blur()
                    e.stopPropagation()
                    setTimeout(() => {
                        e.target.focus()
                    }, 0)
                }
            }}
            InputProps={{
                ...((addition && addition.prefix.value) ? {startAdornment: <InputAdornment position="start">{addition.prefix.value}</InputAdornment>} : {}),
                ...((addition && addition.postfix.value) ? {endAdornment: <InputAdornment position="end">{addition.postfix.value}</InputAdornment>} : {})
            }}
        />
    )
}
