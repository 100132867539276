import * as Yup from "yup"

function getValidationSchema() {
    Yup.object().shape({
        ...{
            year: Yup.date().required("Поле не может быть пустым или состоять из одних пробелов!"),
            funding_source: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
            budget: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!").min(0, 'Цена должна быть больше или равна 0'),
        },
    })
}
export {getValidationSchema}
