import React, {useEffect, useState} from "react"
import { Field } from "formik"
import { TextField } from "formik-material-ui"
import {Grid, IconButton, ListItemIcon, ListItemText, makeStyles, MenuItem, ListSubheader, Typography} from "@material-ui/core"
import {ExpandLess, ExpandMore} from "@material-ui/icons"
import {useSelector} from "react-redux"
import {Submit} from "../Button/Submit";

const useStyles = makeStyles(() => ({
    disabled: {
        'color': 'grey'
    },
    default: {
        'color': 'inherit'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    },
    listItemSubheader: {
        'bottom': 0,
        'background-color': 'white'
    }
}))

export const Category = (props) => {
    const classes = useStyles()

    const { isSubmitting, disabled, required = false, label, name = null, onChange, options = [] } = props

    const {categories} = useSelector(state => ({categories: state.system.categories.filter(category => !!options.length ? options.includes(category.id) : true)}))
    const [openCategory, setOpenCategory] = useState({})
    const [display, setDisplay] = useState(false)
    const [value, setValue] = useState(props.value ? categories.find(category => category.id === props.value) : null)

    useEffect(() => {
        setValue(props.value ? categories.find(category => category.id === props.value) : null)
    }, [props.value])

    const assembly = (options, parent = 0, level = 0) => {
        let result = []

        if (options.hasOwnProperty(parent)) {
            options[parent].sort((first, second) => {
                return (first.name > second.name) ? 1 : (second.name > first.name) ? -1 : 0
            }).forEach(category => {
                result.push(
                    <MenuItem
                        key={ category.id }
                        value={ category }
                        selected={value?.id === category?.id}
                        style={{ paddingLeft: `${16 * (level + 1)}px`}}
                    >
                        {options.hasOwnProperty(category.id) ? (
                            <ListItemIcon className={ classes.listItemIcon }>
                                <IconButton
                                    size="small"
                                    onClick={e => {
                                        e.stopPropagation()
                                        if (options.hasOwnProperty(category.id)) {
                                            setOpenCategory({...openCategory, ...{[category.id]: openCategory.hasOwnProperty(category.id) ? !openCategory[category.id] : true}})
                                        }
                                    }}
                                >
                                    {(openCategory.hasOwnProperty(category.id) && openCategory[category.id]) ? <ExpandLess/> : <ExpandMore/>}
                                </IconButton>
                            </ListItemIcon>
                        ) : null}
                        <ListItemText className={ options.hasOwnProperty(category.id) ? classes.listItemTextWithIcon : classes.listItemText } primary={ !level ? <Typography variant="body1" className={classes.default}>{category.name}</Typography> : <Typography variant="body2" className={classes.default}>{category.name}</Typography> } />
                    </MenuItem>
                )

                const childes = assembly(options, category.id, level + 1)

                if (!!childes.length && openCategory.hasOwnProperty(category.id) && openCategory[category.id]) {
                    result = result.concat([
                        childes.map(row => row)
                    ])
                }
            })
        }

        return result
    }

    const getCategoriesTree = options => {
        let tmp = {}

        options.forEach(category => {
            if (!tmp.hasOwnProperty((category.category && categories.find(cat => (cat.id === category.category?.id))) ? category.category.id : 0)) {
                tmp[(category.category && categories.find(cat => (cat.id === category.category?.id))) ? category.category.id : 0] = []
            }

            tmp[(category.category && categories.find(cat => (cat.id === category.category?.id))) ? category.category.id : 0].push(category)
        })

        return assembly(tmp)
    }

    return (
        <Field
            fullWidth
            type="text"
            name={name ?? "category"}
            label={label}
            select
            required={required}
            variant="standard"
            disabled={ isSubmitting || disabled }
            component={ TextField }
            InputLabelProps={{
                shrink: true
            }}
            InputProps={{
                onChange: (e) => {
                    e.stopPropagation()
                    setDisplay(true)
                    setValue(e.target.value)
                }
            }}
            inputProps={{
                open: display,
                onOpen: () => {
                    setDisplay(true)
                },
                onClose: () => {
                    setValue(props.value ? categories.find(category => category.id === props.value) : null)
                    setDisplay(false)
                },
                value: value,
                renderValue: value => value.name,
            }}
        >
            {getCategoriesTree(categories)}
            <ListSubheader className={classes.listItemSubheader}>
                <Grid container direction="row" justify="flex-end" alignItems="stretch">
                    <Grid item>
                        <Submit
                            disableElevation
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={(e) => {
                                e.stopPropagation()
                                onChange(value)
                                setDisplay(false)
                            }}
                        >
                            Подтвердить
                        </Submit>
                    </Grid>
                </Grid>
            </ListSubheader>
        </Field>
    )
}
