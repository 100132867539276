import {ItemService} from "../../services/cart/item"

export const ItemActions = {
    items,
    count,
    add,
    amount,
    remove
}

function items(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CART_ITEMS_REQUEST"})

        ItemService.items(params)
            .then(
                response => {
                    dispatch({type: "CART_ITEMS", payload: response.items})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response.items)
                },
                error => {
                    dispatch({type: "CART_ITEMS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function count() {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CART_ITEM_COUNT_REQUEST"})

        ItemService.count()
            .then(
                count => {
                    dispatch({type: "CART_ITEM_COUNT", payload: count})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "CART_ITEM_COUNT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function add(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CART_ADD_ITEM_REQUEST"})

        ItemService.add(values)
            .then(
                response => {
                    dispatch({type: "CART_ADD_ITEM_SUCCESS", payload: values.amount})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "CART_ADD_ITEM_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function amount(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CART_EDIT_ITEM_REQUEST"})

        ItemService.amount(id, values)
            .then(
                response => {
                    dispatch({type: "CART_EDIT_ITEM_SUCCESS", payload: {id: id, amount: values.amount}})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "CART_EDIT_ITEM_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function remove(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CART_DELETE_ITEM_REQUEST"})

        ItemService.remove(id)
            .then(
                response => {
                    dispatch({type: "CART_DELETE_ITEM_SUCCESS", payload: id})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "CART_DELETE_ITEM_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
