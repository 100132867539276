import React, {useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, FieldArray, Form, Formik} from "formik"
import * as Yup from "yup"
import DateFnsUtils from "@date-io/date-fns"
import {ru} from "date-fns/locale"
import format from "date-fns/format"

import {Grid, IconButton, makeStyles, Typography,} from "@material-ui/core"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"
import {Clear, Add as AddIcon} from "@material-ui/icons"

import {TextFieldWithError as CustomTextField} from "../../../../App/components/Inputs/TextFieldWithError"
import {Submit} from "../../../../App/components/Button/Submit"
import {EmployeeActions} from "../../../../Company/actions/employee"
import {AuthorizationService} from "../../../../Auth/services/authorization"

const useStyles = makeStyles(() => ({
    fullWidth: {
        "width": "100%"
    },
    inputContent: {
        "padding": "20px 30px 0",
        "width": "100%"
    },
    column: {
        "height": "86px"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    group: {
        "padding": "8px"
    },
    groupTitle: {
        "font-weight": "500"
    },
}))

export const ChiefDoctor = (props) => {
    const dispatch = useDispatch()

    const { company, setLoading } = props
    const classes = useStyles()
    const [employee, setEmployee] = useState(false)
    const { account } = useSelector(state => state.account)

    const getEmployee = () => {
        if (employee) return true
        if (company) {
            company.territories.forEach(territory => {
                territory.employees.forEach(useEmployee => {
                    if (useEmployee.position === 'Главный врач') {
                        if (useEmployee.phones) {
                            setEmployee({...useEmployee,...{phones: useEmployee.phones.map(phone => {
                                if (phone.number === null) phone.number = ''
                                if (phone.extra === null) phone.extra = ''
                                return phone
                            })}})
                        } else {
                            setEmployee({...useEmployee,...{phones: [{number: '', extra: ''}]}})
                        }
                        return true;
                    }
                })
            })
        }
    };

    return getEmployee() ? (
        <Grid item className={classes.fullWidth}>
            <Formik
                initialValues={{
                    last_name: employee ? employee.last_name : '',
                    first_name: employee ? employee.first_name : '',
                    middle_name: employee ? employee.middle_name : '',
                    email: employee ? employee.email : '',
                    login: employee ? employee.login : '',
                    position: employee ? (employee.position ?? '') : '',
                    skype: employee ? employee.skype : '',
                    phones: employee ? ((employee.phones ? employee.phones.map(phone => { return {number: phone.number ?? '', extra: phone.extra ?? ''} }) : []) ?? []) : [],
                    beginning_work: employee ? new Date(employee.beginning_work) : null,
                    end_work: employee ? new Date(employee.end_work) : null,
                }}
                validationSchema={Yup.object().shape({
                    ...{
                        last_name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        first_name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        middle_name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        phones: Yup.array().of(Yup.object().shape({
                            number: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').when("extra", (extra, schema) => {
                                return extra ? schema.required("Поле не может быть пустым или состоять из одних пробелов!") : schema
                            }),
                            extra: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                        })),
                        beginning_work: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        end_work: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                    },
                })}
                onSubmit={(values, {setSubmitting}) => {
                    let params = {
                        ...values,
                        ...(values.hasOwnProperty('beginning_work') && values.beginning_work ?
                            {
                                beginning_work: format(values.beginning_work, 'yyyy-MM-dd')
                            } : {}),
                        ...(values.hasOwnProperty('end_work') && values.end_work  ?
                            {
                                end_work: format(values.end_work, 'yyyy-MM-dd')
                            } : {})
                    }
                    return dispatch(EmployeeActions.edit(company.id,employee.id,params)).then(
                        () => {
                            setSubmitting(false)
                            setLoading(false)
                        },
                        errors => {
                            if (errors) {
                            }
                            setSubmitting(false)
                        }
                    )
                }}
            >
                {({
                    dirty,
                    values,
                    isSubmitting,
                    isValid,
                    setFieldValue,
                    setFieldTouched
                }) => (
                    <Form className={classes.fullWidth}>
                        <Grid item className={classes.inputContent}>
                            <Grid item xs={12}>
                                <Typography className={classes.groupTitle}>Данные главного врача</Typography>
                                <Grid container className={classes.group} direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                            <Grid item xs={4} className={classes.column}>
                                                <Field
                                                    fullWidth
                                                    name="last_name"
                                                    type="text"
                                                    required
                                                    disabled={!(AuthorizationService.permissions(account, "administrator") || AuthorizationService.roles(account, "admin"))}
                                                    label="Фамилия"
                                                    component={CustomTextField}
                                                />
                                            </Grid>
                                            <Grid item xs={4} className={classes.column}>
                                                <Field
                                                    fullWidth
                                                    name="first_name"
                                                    type="text"
                                                    required
                                                    label="Имя"
                                                    disabled={!(AuthorizationService.permissions(account, "administrator") || AuthorizationService.roles(account, "admin"))}
                                                    component={CustomTextField}
                                                />
                                            </Grid>
                                            <Grid item xs={4} className={classes.column}>
                                                <Field
                                                    fullWidth
                                                    name="middle_name"
                                                    type="text"
                                                    disabled={!(AuthorizationService.permissions(account, "administrator") || AuthorizationService.roles(account, "admin"))}
                                                    required
                                                    label="Отчество"
                                                    component={CustomTextField}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <FieldArray name="phones" render={ arrayHelpers => (
                                        <React.Fragment>
                                            {values.phones.map((phone, index) => {
                                                return (
                                                    <Grid key={index} container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                        <Grid item xs={4} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name={`phones.${index}.number`}
                                                                type="phone"
                                                                required
                                                                disabled={!(AuthorizationService.permissions(account, "administrator") || AuthorizationService.roles(account, "admin"))}
                                                                label="Номер телефона"
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name={`phones.${index}.extra`}
                                                                type="text"
                                                                disabled={!(AuthorizationService.permissions(account, "administrator") || AuthorizationService.roles(account, "admin"))}
                                                                label="доб."
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                        {(values.phones.length > 1 && (AuthorizationService.permissions(account, "administrator") || AuthorizationService.roles(account, "admin"))) &&
                                                            <Grid item xs={2}>
                                                                <IconButton
                                                                    size={"small"}
                                                                    onClick={() => {
                                                                        arrayHelpers.remove(index)
                                                                    }}
                                                                >
                                                                    <Clear />
                                                                </IconButton>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                );
                                            })}
                                            {(AuthorizationService.permissions(account, "administrator") || AuthorizationService.roles(account, "admin")) &&
                                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                    <Grid item>
                                                        <Submit
                                                            size="small"
                                                            variant="contained"
                                                            component="label"
                                                            color="primary"
                                                            endIcon={<AddIcon/>}
                                                            onClick={() => {
                                                                arrayHelpers.push({number: '', extra: ''})
                                                            }}
                                                        >
                                                            Добавить номер телефона
                                                        </Submit>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </React.Fragment>
                                    )}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.groupTitle}>Срок выполнения обязанностей</Typography>
                                <Grid container className={classes.group} direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                            <Grid item className={classes.column} xs={6}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        fullWidth
                                                        name="beginning_work"
                                                        required
                                                        format="dd-MM-yyyy"
                                                        invalidDateMessage="Некорректная дата"
                                                        disabled={!(AuthorizationService.permissions(account, "administrator") || AuthorizationService.roles(account, "admin"))}
                                                        orientation="landscape"
                                                        variant="inline"
                                                        openTo="date"
                                                        autoOk
                                                        label="Дата начала"
                                                        value={values.beginning_work}
                                                        onChange={date => {
                                                            setFieldValue("beginning_work", date, true)
                                                        }}
                                                        onBlur={() => {
                                                            setFieldTouched(`beginning_work`, true, true)
                                                        }}
                                                        onClose={() => {
                                                            setFieldTouched(`beginning_work`, true, true)
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item className={classes.column} xs={6}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        fullWidth
                                                        name="end_work"
                                                        required
                                                        format="dd-MM-yyyy"
                                                        invalidDateMessage="Некорректная дата"
                                                        disabled={!(AuthorizationService.permissions(account, "administrator") || AuthorizationService.roles(account, "admin"))}
                                                        orientation="landscape"
                                                        variant="inline"
                                                        openTo="date"
                                                        autoOk
                                                        label="Дата окончания"
                                                        value={values.end_work}
                                                        onChange={date => {
                                                            setFieldValue("end_work", date, true)
                                                        }}
                                                        onBlur={() => {
                                                            setFieldTouched(`end_work`, true, true)
                                                        }}
                                                        onClose={() => {
                                                            setFieldTouched(`end_work`, true, true)
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {(AuthorizationService.permissions(account, "administrator") || AuthorizationService.roles(account, "admin")) &&
                            <Grid item className={classes.footer}>
                                <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                    <Grid item className={classes.footerContent}>
                                        <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                            <Grid item>
                                                <Submit
                                                    disableElevation
                                                    variant="contained"
                                                    disabled={isSubmitting || !isValid || !dirty}
                                                    color="primary"
                                                    type="submit"
                                                >
                                                    Сохранить
                                                </Submit>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Form>
                )}
            </Formik>
        </Grid>
    ) : null
}
