import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import format from "date-fns/format"

import {Grid, makeStyles,} from "@material-ui/core"

import {Table} from "@devexpress/dx-react-grid-material-ui";

import {AutoActions} from "../../actions/auto"
import {FileUpload} from "../../../App/components/Inputs/FileUpload"
import {history} from "../../../App/helpers/history"
import {CustomTable} from "../../../App/components/Table/Table";
import {TableHeader} from "../../../App/components/Table/TableHeader";
import {ColumnChooser} from "../../../App/components/Table/ColumnChooser";
import {localState} from "../../../App/components/LocalState";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 111px)"
    },
    inputContent: {
        "width": "100%",
        "height": 'calc(100vh - 163px)',
        "min-height": 'calc(100vh - 163px)',
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    }
}))

const columns = [
    {name: 'number', title: 'Номер'},
    {name: 'created', title: 'Дата загрузки', filter: 'created'},
    {name: 'items_count', title: 'Кол-во позиций'},
    {name: 'defect_items_count', title: 'Количество дефектуры'},
    {name: 'predecessor', title: 'Предшественник'},
    {name: 'user', title: 'Пользователь'},
    {name: 'status', title: 'Статус', filter: 'status'},
]

export const IndexContent = () => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [orders, setOrders] = useState(localState)

    const {filter} = useSelector(state => state.filters['auto_orders'])

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [tableRef, setTableRef] = useState(null)

    const [columnOrder, setColumnOrder] = useState([
        'number',
        'created',
        'items_count',
        'defect_items_count',
        'predecessor',
        'user',
        'status',
    ])

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'items_count', width: 150},
        {columnName: 'user', width: 250},
        {columnName: 'number', width: 250},
        {columnName: 'status', width: 250},
        {columnName: 'predecessor', width: 200},
        {columnName: 'created', width: 250},
        {columnName: 'defect_items_count', width: 250}
    ])

    const [loading, setLoading] = useState(false)

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
        }
    }

    useEffect(() => {
        const getOrders = async () => {
            return await dispatch(AutoActions.orders({
                ...{
                    limit: rowsPerPage,
                    page: page,
                    ...getParams()
                },
            }))
        }

            getOrders().then((response) => {
                setOrders(response)
                tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
                setLoading(true)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, loading, page, rowsPerPage, filter])

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: "AUTO_ORDERS_FILTER", payload: {limitRows: newRowsPerPage}})
    }

    return <Grid item className={classes.content}>
        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
            <Grid item>
                <Formik
                    initialValues={{
                        file: null
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        if (['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(values.file.type)) {
                            const formData = new FormData()

                            formData.append(`file`, values.file)

                            return dispatch(AutoActions.load(formData)).then(
                                () => {
                                    setLoading(false)
                                    setSubmitting(false)
                                },
                                errors => {
                                    if (errors) {
                                    }
                                    setSubmitting(false)
                                }
                            )
                        } else {
                            setSubmitting(false)

                            dispatch({
                                type: "ALERT_ERROR", payload: {
                                    message: 'Файл может быть одним из следующих типов: xls, xlsx.',
                                    type: 'error'
                                }
                            })
                        }
                    }}
                >
                    {({
                          handleSubmit,
                          setFieldTouched,
                          setFieldValue
                      }) => (
                        <Form>
                            <Field
                                fullWidth
                                component={(props) => (
                                    <FileUpload {...props}
                                                endIcon={"Загрузить"}/>
                                )}
                                name={`file`}
                                InputLabelProps={{
                                    name: null
                                }}
                                InputProps={{
                                    onChange: (event) => {
                                        setFieldValue(`file`, event.currentTarget.files[0])
                                        setFieldTouched(`file`, true, false)
                                        handleSubmit()
                                    }
                                }}
                            />
                        </Form>
                    )}
                </Formik>
            </Grid>
            <Grid item>
                <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
            </Grid>
            <CustomTable
                meta={orders.meta}
                tableName={'auto_orders'}
                rows={orders.data.map((order) => ({
                    number: order.number,
                    created: format(new Date(order.created), 'dd.MM.yyyy HH:mm'),
                    items_count: order.items_count,
                    defect_items_count: order.defect_items_count,
                    status: order.status.name,
                    predecessor: order.predecessor ? order.predecessor.number : null,
                    user: `${order.user.last_name} ${order.user.first_name} ${order.user.middle_name}`
                }))}
                columns={columns}
                page={{
                    page: page,
                    setPage: setPage,
                    rowsPerPage: rowsPerPage,
                    handlePageSizeChange: handlePageSizeChange
                }}
                setTableRef={setTableRef}
                columnsSettings={{
                    columnOrder: columnOrder,
                    setColumnOrder: setColumnOrder,
                    setColumnWidths: setColumnWidths,
                    columnWidths: columnWidths,
                    hiddenColumnNames: hiddenColumnNames,
                    setHiddenColumnNames: setHiddenColumnNames
                }}
                tableHeader={TableHeader}
                filterActionType={"AUTO_ORDERS_FILTER"}
                classInputContent={classes.inputContent}
                rowComponent={({row, tableRow, children}) => (
                    <Table.Row
                        tableRow={tableRow}
                        className={classes.tableRow}
                        hover
                        row={row}
                        children={children}
                        onClick={() => {
                            if (orders.data[tableRow.rowId].status.index === 5) {
                                history.push(`/delivery/auto/order/${orders.data[tableRow.rowId].id}`)
                            }
                        }}
                    />
                )}
                filters={{}}
            />
        </Grid>
    </Grid>
}
