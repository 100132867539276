import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import {v4 as uuidv4} from 'uuid'
import * as Yup from "yup"

import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid, Link,
    makeStyles,
    MenuItem,
    Select,
    TextField as MuiTextField,
    Typography
} from "@material-ui/core"
import {Autocomplete} from "@material-ui/lab"
import {Close as CloseIcon, Add as AddIcon, DeleteForever as DeleteForeverIcon} from "@material-ui/icons"

import {Submit} from "../../App/components/Button/Submit"
import {ButtonIcon} from "../../App/components/Button/ButtonIcon"
import {ButtonList} from "../../App/components/ButtonList/ButtonList"
import {FileUpload} from "../../App/components/Inputs/FileUpload"
import {TextFieldWithError as CustomTextField} from "../../App/components/Inputs/TextFieldWithError"
import {ContractActions} from "../actions/contract"
import {PriceActions} from "../../Price/actions/price"
import {CompanyActions} from "../../Account/actions/company"
import {getPriceName} from "../../App/helpers/name"

const useStyles = makeStyles(() => ({
    title: {
        "margin": "0",
        "padding-top": "2px",
        "font-size": "18px",
        "font-weight": "600",
        "color": "#fff",
        "text-transform": "uppercase",
        "display": "flex",
        "justify-content": "space-between",
        "align-items": "center",
        "min-height": "40px",
        "padding": "0 20px",
        "background-color": "#898989",
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    footerContainer: {
        "padding": "0 25px",
        "background-color": "#e7eaee",
        "height": "75px",
        "position": "absolute",
        "bottom": "0",
    },
    submit: {
        "width": "100%",
        "border-bottom": "3px solid #898989",
        "border-radius": "2px",
        "padding": "5px 10px 0",
        "min-width": "95px",
        "min-height": "35px",
        "font-size": "16px",
        "font-weight": "500",
        "color": "white",
        "outline": "0",
        "height": "35px",
        "&:disabled": {
            "background-color": "#c1c1c1",
            "border-bottom-color": "#898989 !important",
            "color": "white"
        }
    },
    dialogContent: {
        padding: "0",
        "overflow": "hidden",
    },
    dialogContainer: {
        "& > div": {
            "max-width": "60vw",
            "min-height": "calc(100vh - 300px)",
            overflow: "hidden",
        }

    },
    fullWidth: {
        width: "100%",
    },
    attention: {
        color: "red",
        textTransform: "uppercase",
    },
    attentionMessage: {
        color: "red",
    },
    contentContainer: {
        padding: "30px 25px 0 25px",
        marginBottom: "100px",
    },
    tabs: {
        marginTop: "50px",
        "&>div": {
            justifyContent: "flex-start"
        },
        marginBottom: "35px",
    },
    downloads: {
        width: "100%",
        minHeight: "240px",
    },
    input: {
        marginBottom: "10px",
    },
    numberContract: {
        minWidth: "255px",
        maxWidth: "255px",
        margin: "15px 8px 0 8px",
        display: "flex",
        alignItems: "flex-end",
        borderBottom: "2px dotted #c4c4c4",
        marginBottom: "20px"
    },
    reset: {
        "&:hover": {
            "background-color": "#898989",
        },
        "min-width": "35px",
        "max-height": "35px",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "#fff",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-bottom": "3px solid #898989",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "#616161",
        "cursor": "pointer",
        "margin": "0",
        "width": "auto",
        "padding": "5px 10px 0 10px",
    }
}));

const tabList = [
    {
        key: 'file',
        value: 'Из файла'
    },
    {
        key: 'url',
        value: 'По ссылке'
    },
    {
        key: 'ftp',
        value: 'FTP'
    },
]

export const PriceDialog = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {open, onClose, contract} = props
    const {priceTypes} = useSelector(state => state.priceTypes)
    const {account} = useSelector(state => state.account)
    const { company } = useSelector(state => state.company)
    const [tab, setTab] = useState(0)
    const [contracts, setContracts] = useState([])
    const [downloadType, setDownloadType] = useState('file')
    const [contractName, setContractName] = useState('')

    useEffect(() => {
        const getCompany = async () => {
            return await dispatch(CompanyActions.company({
                include: "territories,legalDetail.city.region.country,legalDetail.executiveDirector,legalDetail.city.region.country,legalDetail.passportDetails,contactInformation,termsParticipation.regions.region,termsParticipation.files"
            }))
        }
        if(!company) {
            getCompany().then(()  => {})
        }
        // eslint-disable-next-line
    }, [dispatch, account.id]);
    useEffect(() => {
        const getTypes = async () => {
            await dispatch(PriceActions.types())
        }

        if (!priceTypes) {
            getTypes().then(() => {})
        }
        // eslint-disable-next-line
    }, [dispatch, account.id]);

    const tabChange = (ev, newValue) => {
        setTab(newValue);
        setDownloadType(tabList[newValue].key);
    }

    const updatePeriodNodes = [
        { key: '', value: 'Не выбрано' },
        { key: 30, value: 'Каждые 30 минут' },
        { key: 60, value: 'Каждый час' },
        { key: 180, value: 'Каждые 3 часа' },
        { key: 360, value: 'Каждые 6 часов' },
        { key: 720, value: 'Каждые 12 часов' },
        { key: 5040, value: 'Каждые 7 дней' }
    ].map((obj) =>
        <MenuItem value={obj.key}>{obj.value}</MenuItem>
    )

    useEffect(() => {
        const getContracts = async () => {
            return await dispatch(ContractActions.contracts({
                ...{
                    limit: 15,
                    page: 1,
                    sort: 'all',
                    type: 'all',
                    status: [1,2,5]
                },
                ...(contractName ? {number: contractName} : {}),
            }))
        }
        if (open) {
            getContracts().then(response => {
                setContracts(response.data)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, contractName]);

    const emptyString = (el) => el !== "";

    return (
        <Dialog aria-labelledby="simple-dialog-title" open={open} classes={{
            root: classes.dialog,
            container: classes.dialogContainer
        }} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle id="simple-dialog-title" classes={{
                root: classes.title,
            }} disableTypography={true}>Загрузите прайс-лист
                <CloseIcon className={classes.close} onClick={onClose} />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Formik
                    enableReinitialize
                    initialValues={{
                        priceId: contract.price_id ? contract.price_id : uuidv4(),
                        contractNumbers: [contract.number],
                        contractIds: [contract.id],
                        contract: null,
                        files: null,
                        url: {
                            link: '',
                            update_period: '',
                        },
                        ftp: {
                            login: '',
                            password: '',
                            link: '',
                            update_period: '',
                        },
                    }}
                    validationSchema={Yup.object().shape({
                        files: Yup.mixed().test('fileFormat', 'Документ должен быть файлом одного из следующих типов: csv, xls, xlsx.', (values) => {
                            return !values || (values && Array.from(values).filter(file => [
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                'application/vnd.ms-excel',
                                'text/csv'
                            ].includes(file.type)).length !== 0);
                        }),
                    })}

                    onSubmit={(values, {setSubmitting}) => {
                        const formData = new FormData()
                        let url = '';
                        let updatePeriod = '';
                        if (values.url.link) {
                            url = values.url.link
                            updatePeriod = values.url.update_period
                        }
                        if (values.ftp.link) {
                            url = values.ftp.login && values.ftp.password ? `ftp://${values.ftp.login}:${values.ftp.password}@${values.ftp.link.replace('ftp://', '')}` : values.ftp.link
                            updatePeriod = values.ftp.update_period
                        }
                        if (values.files) {
                            Array.from(values.files).forEach(file => {
                                formData.append("files[]", file, file.name);
                            });
                        }
                        let deadline = contract.end

                        if (contract.end === null && !contract.perpetual) {
                            deadline = new Date(new Date().getFullYear() + 1, 0, 1, 0, 0, 0).toJSON()
                        }
                        formData.append('url', url)
                        formData.append("region_ids[]", JSON.stringify(company && company.terms_participation && company.terms_participation.regions ? [...company.terms_participation.regions.map(el => el.region.id), company.legal_detail.city.region.id] : []));
                        formData.append("download_type", downloadType);
                        formData.append("price_type", 'contract');
                        formData.append('updatePeriod', updatePeriod)
                        formData.append('deadline', deadline ? deadline : '')
                        formData.append('price_id', values.priceId)
                        formData.append('companyId', contract.without_specification ? contract.offer.company.id : contract.company.id)
                        formData.append('contract_ids', JSON.stringify(values.contractIds.filter(id => id !== contract.id)))

                        return dispatch(PriceActions.downloadFromElmag(contract.id, formData)).then(
                            (resp) => {
                                    return dispatch(PriceActions.getById(resp.contract.id, resp.contract.price_id)).then(resp => {
                                        dispatch({ type: "ALERT_SUCCESS", payload: {
                                                message:'Прайс-лист успешно загружен',
                                                type:'success'
                                            }
                                        })
                                        props.onClose(true)
                                        setSubmitting(true)
                                        dispatch({type: "SET_ACTIVE_PRICE", payload: {
                                                priceId: resp.price.id,
                                                type: resp.price.type.name,
                                                price_token: resp.price.price_token,
                                                company_id: resp.price.company_id,
                                                name: getPriceName(resp.price),
                                                in_process: Boolean(resp.price.status === 'in_process'),
                                                category: resp.category
                                            }})
                                    })
                            },
                            () => {
                                setSubmitting(false);
                            }
                        )
                    }}
                >
                    {({
                          values,
                          setFieldValue,
                          setFieldTouched
                      }) => (
                        <Form>
                            <Grid container
                                className = {
                                    classes.contentContainer
                                }
                                direction = "row"
                                justify = "flex-end"
                                alignItems = "flex-start"
                                spacing = {2}>
                                <Grid item xs={6}>
                                    <Grid container direction = "row" className={classes.fullWidth}>
                                        {values.contractNumbers && values.contractNumbers.length > 0 ? values.contractNumbers.map((num) => (
                                            <Grid item key={num} className={classes.fullWidth}>
                                                <Grid container direction="row" className={classes.fullWidth} spacing={2}>
                                                    <Grid item xs={6} className={classes.numberContract}>
                                                        <Typography noWrap>{num}</Typography>
                                                    </Grid>
                                                    <Grid item xs={5} style={{
                                                        paddingLeft:"4px",
                                                    }}>
                                                        <ButtonIcon
                                                            onClick={() => {
                                                                if (values.contractNumbers.length > 1){
                                                                    setFieldValue('contractNumbers', values.contractNumbers.filter(el => el !== num))
                                                                }
                                                            }}
                                                            disabled={values.contractNumbers.length <= 1}
                                                            key={'DeleteForeverIcon'}
                                                            children={[(<DeleteForeverIcon key={'DeleteForeverIcon'}
                                                            className={classes.svg} />)]}>
                                                        </ButtonIcon>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )) : null}
                                        <Grid item className={classes.fullWidth}>
                                            <Grid container direction="row" className={classes.fullWidth} spacing={2}>
                                                <Grid item xs={6}>
                                                    <Autocomplete
                                                        filterOptions={options => options}
                                                        style={{minWidth:"200px"}}
                                                        name={`contractId`}
                                                        options={contracts}
                                                        value={values.contract}
                                                        getOptionLabel={contract => {
                                                            return contract ? contract.number : ''
                                                        }}
                                                        noOptionsText='Договоры не найдены'
                                                        onChange={(e, item) => {
                                                            setFieldValue('contract', item ?? null)
                                                        }}
                                                        renderInput={params => {
                                                            return <Field
                                                                fullWidth
                                                                component={MuiTextField}
                                                                {...params}
                                                                onChange={(e) => {
                                                                    setContractName(e.target.value)
                                                                }}
                                                                name={`contractId`}
                                                                label="Введите номер договора"
                                                            />
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <ButtonIcon
                                                        disabled={!values.contract}
                                                        onClick={() => {
                                                            if (values.contractNumbers.indexOf(values.contract.number) === -1) {
                                                                setFieldValue('contractNumbers', [...values.contractNumbers, values.contract.number]);
                                                                setFieldValue('contractIds', [...values.contractIds, values.contract.id]);
                                                                setFieldValue('contract', null);
                                                            }
                                                        }}
                                                        key={'AddIcon'}
                                                        children={[(<AddIcon key={'AddIcon'}
                                                        className={classes.svg} />)]}>
                                                    </ButtonIcon>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.downloads}>
                                            <ButtonList value={tab} values={tabList} onChange={tabChange} className={classes.tabs}/>
                                            {downloadType === 'file' &&
                                                <Field
                                                    fullWidth
                                                    component={(props) => (
                                                        <FileUpload
                                                            {...props}
                                                            endIcon={<AddIcon/>}/>
                                                    )}
                                                    label="Загрузите прайс-лист"
                                                    name="files"
                                                    disabled={Object.values(values.url).some(emptyString) || Object.values(values.ftp).some(emptyString)}
                                                    InputLabelProps={{
                                                        name: values.files ? Array.from(values.files).map(el => el.name).join(', ') : null
                                                    }}
                                                    InputProps={{
                                                        onChange: (event) => {
                                                            const files = event.currentTarget.files;
                                                            setFieldValue('files', files);
                                                            setFieldTouched("files", true, false)
                                                        },
                                                        accept: '.xlsx, .xls',
                                                        multiple: true
                                                    }}
                                                />
                                            }
                                            {downloadType === 'url' &&
                                                <Grid container direction="row" className={classes.fullWidth} spacing={2}>
                                                    <Grid item xs={6}>
                                                        <Field
                                                            fullWidth
                                                            name={`url.link`}
                                                            type="text"
                                                            label="Вставьте ссылку"
                                                            required={!values.files && !values.ftp}
                                                            disabled={Boolean(values.files || Object.values(values.ftp).some(emptyString))}
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Field
                                                            component={({
                                                                            name, form: {setFieldValue}
                                                                        }) => {
                                                                return (
                                                                    <Select
                                                                id={name}
                                                                classes={{
                                                                    select: classes.select,
                                                                }}
                                                                name={name}
                                                                value={values.url.update_period}
                                                                disabled={values.files || Object.values(values.ftp).some(emptyString)}
                                                                style={{
                                                                    minWidth:"80px",
                                                                    marginTop: "16px"
                                                                }}
                                                                onChange={(e) => {
                                                                    setFieldValue(`url.update_period`, e.target.value)
                                                                }}
                                                                children={updatePeriodNodes}
                                                                >
                                                                </Select>
                                                            );
                                                            }}
                                                            name={`url.update_period`}
                                                            />
                                                    </Grid>
                                                </Grid>
                                            }
                                            {downloadType === 'ftp' &&
                                                <React.Fragment>
                                                    <Grid container direction="row" className={classes.fullWidth} spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Field
                                                                className={classes.input}
                                                                fullWidth
                                                                name={`ftp.login`}
                                                                type="text"
                                                                disabled={!!(values.files || Object.values(values.url).some(emptyString))}
                                                                label="Логин(необязательное поле)"
                                                                component={CustomTextField}
                                                                />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Field
                                                                fullWidth
                                                                className={classes.input}
                                                                name={`ftp.password`}
                                                                type="text"
                                                                disabled={!!(values.files || Object.values(values.url).some(emptyString))}
                                                                label="Пароль(необязательное поле)"
                                                                component={CustomTextField}
                                                                />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction="row" className={classes.fullWidth} spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Field
                                                                fullWidth
                                                                className={classes.input}
                                                                name={`ftp.link`}
                                                                type="text"
                                                                disabled={!!(values.files || Object.values(values.url).some(emptyString))}
                                                                label="Ссылка"
                                                                component={CustomTextField}
                                                                />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Field
                                                                component={({
                                                                                name, form: {setFieldValue}
                                                                            }) => {
                                                                    return (<Select
                                                                    id={name}
                                                                    classes={{
                                                                        select: classes.select,
                                                                    }}
                                                                    name={name}
                                                                    value={values.ftp.update_period}
                                                                    style={{
                                                                        minWidth:"80px",
                                                                        marginTop: "16px"
                                                                    }}
                                                                    disabled={!!(values.files || Object.values(values.url).some(emptyString))}
                                                                    onChange={(e) => {
                                                                        setFieldValue(`ftp.update_period`, e.target.value)
                                                                    }}
                                                                    children={updatePeriodNodes}
                                                                    >
                                                                    </Select>
                                                                );
                                                                }}
                                                                name={`ftp.update_period`}
                                                                />
                                                        </Grid>
                                                    </Grid>
                                                </React.Fragment>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.attention}>Внимание!</Typography>
                                    <Typography className={classes.attentionMessage}><Link className={classes.link} target="_blank" href={process.env.PUBLIC_URL + `/storage/Прайс-лист.xlsx`}>Скачать шаблон Прайс-листа</Link></Typography>
                                </Grid>
                            </Grid>
                            <Grid container
                                className = {
                                    classes.footerContainer
                                }
                                direction = "row"
                                justify = "flex-end"
                                alignItems = "center"
                                spacing = {2}>
                                <Grid item>
                                    <ButtonIcon
                                        className={classes.reset}
                                        onClick={e => {
                                            e.stopPropagation()
                                            onClose(e)
                                        }}
                                        children={'Отмена'}/>
                                </Grid>
                                <Grid item>
                                    <Submit
                                        className={classes.submit}
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        type="submit">
                                        Загрузить
                                    </Submit>
                                </Grid>
                            </Grid>
                    </Form>
                )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}
