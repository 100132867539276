import * as Yup from "yup"

function getValidationSchema(company) {
   return Yup.object().shape({
        ...{
            website: Yup.string(),
            email: Yup.string().email("Некорректный формат электронной почты!"),
            phones: Yup.array().of(Yup.object().shape({
                number: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').required("Поле не может быть пустым или состоять из одних пробелов!").when("extra", (extra, schema) => {
                    return extra ? schema.required("Поле не может быть пустым или состоять из одних пробелов!") : schema
                }),
                extra: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
            })),
            purchasing_department_email: Yup.string().email("Некорректный формат электронной почты!"),
            purchasing_department_phones: Yup.array().of(Yup.object().shape({
                number: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').when("extra", (extra, schema) => {
                    return extra ? schema.required("Поле не может быть пустым или состоять из одних пробелов!") : schema
                }),
                extra: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
            })),
            erp_system: Yup.string(),
            description: Yup.string(),
            last_name: Yup.string(),
            first_name: Yup.string(),
            middle_name: Yup.string(),
            position: Yup.string(),
            logo: Yup.mixed().test('fileFormat', 'Логотип должен быть файлом одного из следующих типов: .jpg, .jpeg, .png, .bmp, .gif, .svg', (value) => {
                return ((value && (value instanceof File) && [
                    'image/jpeg', 'image/gif', 'image/svg+xml', 'image/bmp', 'image/png'
                ].includes(value.type)) || !value || (value && !(value instanceof File)))
            })
        },
        ...(
            company.type
                ? {
                    occupations: Yup.array().required("Поле не может быть пустым или состоять из одних пробелов!")
                }
                : {}
        )
    })
}

export { getValidationSchema}
