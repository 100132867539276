import * as AppStorage from "./storage"

function get(key) {
    return AppStorage.get(key) ? JSON.parse(AppStorage.get(key)) : null
}

function set(key, value) {
    let tmpValue = {
        ...JSON.parse(AppStorage.get(key)),
        ...value
    }

    AppStorage.set(key, JSON.stringify(tmpValue))
}

export { get, set }
