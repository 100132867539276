import React, {useState} from "react"

import {Submit} from "../../../../../../App/components/Button/Submit"
import {Token} from "../Token";

export const Add = (props) => {
    const {setLoading} = props
    const [open, setOpen] = useState(false)

    return (
        <React.Fragment>
            <Submit
                disableElevation
                variant="contained"
                color="primary"
                type="button"
                onClick={() => {
                    setOpen(true)
                }}
            >
                Добавить
            </Submit>
            {open ? <Token open={open} setOpen={setOpen} setLoading={setLoading} /> : null}
        </React.Fragment>
    )
}
