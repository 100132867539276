const type = {
    COST_REDUCTION: {
        index: 1,
        name: 'Уменьшение стоимости'
    },
    COST_INCREASE: {
        index: 2,
        name: 'Увеличение стоимости'
    },
    CONTRACT_END_DATE: {
        index: 3,
        name: 'Изменение срока договора'
    }
}

export { type }
