export default function supplier(state = {
    offers: {
        data: [],
        meta: {
            total: 0, per_page: 15, current_page: 1
        }
    }
}, action) {
    switch (action.type) {
        case "SUPPLIER_OFFERS_REQUEST":
            return state
        case "SUPPLIER_OFFERS_SUCCESS":
            return {
                ...state,
                ...{
                    offers: action.payload
                }
            }
        case "SUPPLIER_OFFER_ITEMS_SUCCESS":
            return {
                ...state,
                ...{
                    offers: {
                        ...state.offers,
                        data: state.offers.data.map(offer => {
                            if (offer.id === action.payload.offerId) {
                                return {
                                    ...offer,
                                    items: action.payload.items
                                }
                            } else {
                                return offer
                            }
                        }),
                    }
                }
            }
        default:
            return state
    }
}
