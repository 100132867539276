import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"

import {Collapse, List, ListItem, ListItemIcon, ListItemText, makeStyles, withStyles} from '@material-ui/core'
import {ExpandLess, ExpandMore} from '@material-ui/icons'

import {SystemActions} from "../App/actions/system"
import {CartActions} from "./actions/cart"
import {AuthorizationService} from "../Auth/services/authorization"

const useStyles = makeStyles(theme => ({
    item: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "cursor": "pointer"
    },
    nested: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "cursor": "pointer",
        "padding-left": `${theme.spacing(6)}px !important`
    },
    nestedActive: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "background-color": "#898989",
        "cursor": "pointer",
        "padding-left": `${theme.spacing(6)}px !important`
    },
    active: {
        "background-image": "none !important",
        "background-color": "#898989"
    },
    circle: {
        "width": "12px",
        "height": "12px",
        "border-radius": '50%',
        "background-color": '#616161',
        "margin-bottom": "5px"
    },
    categories: {
        "overflow-y": "auto",
        "max-height": "calc(100vh - 255px)"
    }
}))

const CustomList = withStyles({
    root: {
        "padding": "0",
        "& .MuiListItem-root": {
            "background-image": "linear-gradient(to bottom,#e4e4e4,#f4f4f4)",
            "align-items": "center",
            "min-height": "50px",
            "padding": "5px 25px 0",
            "border-bottom": "1px solid #e4e4e4",
            "outline": "0"
        },
        "& .MuiListItemIcon-root": {
            "min-width": "30px"
        },
        "& .MuiListItem-button:hover": {
            "background-color": "#898989",
            "&:after": {
                "position": "absolute",
                "content": "''",
                "top": "0",
                "left": "0",
                "width": "100%",
                "height": "100%",
                "background-color": "rgba(0,0,0,.04)"
            }
        },
        "& .MuiListItemText-secondary": {
            "color": "#485868",
            "font-size": "15px"
        },
        "& .MuiListItemText-primary": {
            "font-size": "16px",
            "font-weight": "700",
            "text-transform": "uppercase"
        }
    }
})(List);

export const Menu = (props) => {
    const dispatch = useDispatch()
    const query = new URLSearchParams(props.location.search);
    const classes = useStyles()
    const {account, categories} = useSelector(state => {
        return {...state.account, ...state.system}
    })
    const [open, setOpen] = useState([])
    const [item, setItem] = useState(true)
    const [active, setActive] = useState(query.has('category') ? parseInt(query.get('category')) : null)
    const {location} = props

    useEffect(() => {
        dispatch({type: "MARKETPLACE_ITEMS_CATEGORY", payload: active})
    }, [dispatch, active])

    useEffect(() => {
        const getCarts = async () => {
            return await dispatch(CartActions.carts())
        }

        getCarts().then(_ => {
        })
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        const getCategories = async () => {
            return await dispatch(SystemActions.categories())
        }

        if (!categories.length) {
            getCategories().then(() => {
            })
        }
        // eslint-disable-next-line
    }, [dispatch]);

    const assembly = (categories, parent = 0, level = 0) => {
        let result = []
        const handleClick = (id) => {
            setActive(id)
            if ((open.indexOf(id) >= 0)) {
                setOpen(open.filter((item) => parseInt(item) !== parseInt(id)));
            } else {
                setOpen([...open, id])
            }
        };

        if (categories.hasOwnProperty(parent)) {
            categories[parent].forEach(category => {
                if (category.active) {
                    result.push(
                        <ListItem
                            classes={{root: level ? classes.nested : classes.item}}
                            style={(category.id === active) ? {"background": "#898989"} : {}}
                            onClick={() => {
                                handleClick(category.id)
                            }}
                            key={category.id}
                            value={category.id}
                        >
                            <ListItemIcon>
                                {categories.hasOwnProperty(category.id) ? ((open.indexOf(category.id) >= 0) ? <ExpandMore/> : <ExpandLess/>) : <React.Fragment/>}
                            </ListItemIcon>
                            <ListItemText secondary={category.name}/>
                        </ListItem>
                    )
                }

                result = result.concat(
                    <Collapse key={`${category.id}_collapse`} in={(open.indexOf(category.id) >= 0)} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {assembly(categories, category.id, level + 1)}
                        </List>
                    </Collapse>
                )
            })
        }

        return result
    }

    const getCategoriesTree = categories => {
        let tmp = {}
        categories.forEach(category => {
            if (!tmp.hasOwnProperty((category.category !== null) ? category.category.id : 0)) {
                tmp[(category.category !== null) ? category.category.id : 0] = []
            }

            tmp[(category.category !== null) ? category.category.id : 0].push(category)
        })

        return assembly(tmp)
    }

    return (
        <CustomList>
            {[
                ...(
                    AuthorizationService.permissions(account, "publish_purchases") ? [{
                        title: "Публикация закупок",
                        to: "/marketplace/publish/purchases",
                        type: 'menu'
                    }] : []
                ),
                ...(
                    AuthorizationService.permissions(account, "access_to_elmag") ? [{
                        title: "Ценовые запросы",
                        to: "/marketplace/requests",
                        type: 'menu'
                    }] : []
                ),
                ...(
                    AuthorizationService.permissions(account, "access_to_marketplace") ? [{
                        title: "Отбор оферт",
                        to: '/marketplace/orders',
                        type: 'tree',
                        items: [{
                            title: "Заказы",
                            to: "/marketplace/orders",
                            type: null
                        },
                            {
                                title: "Маркетплейс",
                                to: '/marketplace',
                                type: null
                            },
                        ]
                    }] : []
                )
            ].map((option, index) => (
                <React.Fragment key={index}>
                    {option.type === 'menu' ?
                        (
                            <ListItem
                                button
                                key={option.title}
                                onClick={() => {
                                    setActive(null)
                                }}
                                component={Link}
                                to={option.to}
                                classes={{root: ((location.pathname === option.to) || ((option.to === '/marketplace/requests') && (location.pathname.indexOf('/request/') !== -1))) ? classes.active : undefined}}
                            >
                                <ListItemText primary={option.title}/>
                            </ListItem>
                        ) :
                        (
                            <React.Fragment key={index}>
                                <ListItem
                                    button
                                    key={option.title}
                                    onClick={() => {
                                        setItem(!item)
                                    }}
                                    classes={{root: ((location.pathname === '/marketplace/orders') && (location.pathname === '/marketplace')) ? classes.active : undefined}}
                                >
                                    <ListItemText primary={option.title}/>
                                    {item ? <ExpandLess/> : <ExpandMore/>}
                                </ListItem>
                                <Collapse in={item} timeout="auto" unmountOnExit>
                                    {
                                        option.items.map((el, i) => (
                                            <List key={i} component="div" disablePadding>
                                                <ListItem
                                                    button
                                                    key={el.title}
                                                    onClick={() => {
                                                        setActive(null)
                                                    }}
                                                    component={Link}
                                                    to={el.to}
                                                    classes={{root: (el.to === location.pathname) ? classes.nestedActive : classes.nested}}
                                                >
                                                    <ListItemText primary={el.title}/>
                                                </ListItem>
                                                {((el.to === '/marketplace') && (location.pathname === '/marketplace')) &&
                                                    <List component="div" disablePadding className={classes.categories}>
                                                        {getCategoriesTree(categories)}
                                                    </List>
                                                }
                                            </List>
                                        ))
                                    }
                                </Collapse>
                            </React.Fragment>
                        )
                    }
                </React.Fragment>
            ))}
        </CustomList>
    )
}
