export default function discountProducts(state = {priceProducts: {data: [], meta: {total: 0, per_page: 15, current_page: 1}}}, action) {
    switch (action.type) {
        case "DISCOUNT_PRODUCTS_SUCCESS":
            return {
                priceProducts: action.payload
            }
        case "DISCOUNT_PRODUCTS_FAILURE":
            return state
        case "DISCOUNT_PRODUCTS_CLEAR":
            return {}
        default:
            return state

    }
}