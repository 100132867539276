import {toBase64} from "./toBase64";
import ccpa from "crypto-pro-cadesplugin";

export const signFile = async ({file, thumbprint}) => {
  if (file) {
    const header = ';base64,';
    const sFileData = await toBase64(file);

    const sBase64Data = sFileData?.slice(
      sFileData.indexOf(header) + header.length,
    );

    const certsApi = await ccpa();

    const sign = await certsApi.signBase64(thumbprint, sBase64Data);

    return { sign: sign }
  }

  return { sign: null };
};