import React, {useEffect, useRef, useState} from "react"
import {Link} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import parse from 'html-react-parser'
import format from "date-fns/format"

import {
    Checkbox,
    Grid,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@material-ui/core"
import {Link as LinkIcon} from "@material-ui/icons"

import {NotificationActions} from "../../actions/notification"
import {Submit} from "../../../App/components/Button/Submit"
import {ButtonList} from "../../../App/components/ButtonList/ButtonList"
import {getShortFullName} from "../../../App/helpers/user"
import {WarningActions} from "../../actions/warning";
import {Create} from "./Buttons/Create";
import {Edit} from "./Buttons/Edit";
import {Event} from "./Event";

const useStyles = makeStyles(theme => ({
    content: {
        width: '100%',
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 110px)"
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100% - 70px)",
        "min-height": "calc(100% - 70px)"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    }
}))

const columns = [
    {
        id: '0',
        label: 'Срок',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '1',
        label: 'Тип',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '2',
        label: 'Роли',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '3',
        label: 'Событие',
        format: (value) => value.toLocaleString(),
    }
];

const Types = [
    {name: 'Обычное', key: 'common'},
    {name: 'Критичное', key: 'critical'}
]

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [events, setEvents] = useState({
        data: [],
        meta: {
            total: 0, per_page: 50, current_page: 1
        }
    })
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [loading, setLoading] = useState(false)
    const tableRef = useRef(null)

    const [selected, setSelected] = useState([])
    const [event, setEvent] = useState(null)
    const isSelected = (name) => (selected.indexOf(name) !== -1)

    useEffect(() => {
        const getEvents = async () => {
            return await dispatch(WarningActions.events({
                limit: rowsPerPage,
                page: page
            }))
        }

        if (!loading) {
            getEvents().then((events) => {
                setSelected([])
                setEvents(events)
                tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
                setLoading(true)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loading, page, rowsPerPage]);

    const handleSelectAll = () => {
        if (!selected.length) {
            setSelected(events.data.map((event) => event.id))
        }
        else if (selected.length && selected.length < events.meta.total) {
            let dataIndexes = events.data.map((event) => event.id)

            if (selected.toString() === dataIndexes.toString() || selected.filter(word => dataIndexes.includes(word)).length) {
                setSelected(selected.filter(word => dataIndexes.indexOf(word) === -1))
            } else {
                setSelected([...selected, ...dataIndexes])
            }
        }
        else {
            setSelected([])
        }
    };

    const handleSelect = (event, name) => {
        const selectedIndex = selected.indexOf(name)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
        }

        setSelected(newSelected)
    };

    const handleChangePage = (event, newPage) => {
        setPage(Number(newPage) + 1)
        setLoading(false)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(1)
        setLoading(false)
    }

    return (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.fullWidth}>
                        <Grid container className={classes.container} direction="column" justify="space-between" alignItems="stretch">
                            <Grid item className={classes.inputContent}>
                                <TableContainer className={classes.tableContainer}>
                                    <Table ref={tableRef} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        indeterminate={selected.length > 0 && selected.length < events.meta.total}
                                                        checked={events.meta.total > 0 && selected.length === events.meta.total}
                                                        onChange={handleSelectAll}
                                                    />
                                                </TableCell>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {events.data.map((event) => {
                                                const isItemSelected = isSelected(event.id);

                                                return (
                                                    <TableRow
                                                        className={classes.tableRow}
                                                        hover
                                                        key={event.id}
                                                        onDoubleClick={() => setEvent(event)}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                checked={isItemSelected}
                                                                onClick={(e) => handleSelect(e, event.id)}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            {event.validity ? format(new Date(event.validity), 'dd.MM.yyyy HH:mm') : null}
                                                        </TableCell>
                                                        <TableCell>
                                                            {Types.find(type => type.key === event.type)?.name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {event.roles.map(role => role.description).join(', ')}
                                                        </TableCell>
                                                        <TableCell>
                                                            {event.message ? event.message.split("\n").map((message, index) => (
                                                                message ? <Typography key={index} variant="body2" gutterBottom>{parse(message)}</Typography> : null
                                                            )) : null}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item className={classes.footer}>
                                <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                    <Grid item className={classes.footerContent}>
                                        <Grid container className={classes.footerContainer} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                            <Grid item>
                                                <TablePagination
                                                    rowsPerPageOptions={[50, 100, 200]}
                                                    component='div'
                                                    labelRowsPerPage={'Записей на странице:'}
                                                    count={events.meta.total}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page - 1}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                    <Grid item>
                                                        <Submit
                                                            disableElevation
                                                            variant="contained"
                                                            color="primary"
                                                            type="button"
                                                            disabled={isSubmitting || !selected.length}
                                                            onClick={() => {
                                                                setIsSubmitting(true)
                                                                return dispatch(WarningActions.remove({
                                                                    id: selected.join(',')
                                                                })).then(
                                                                    () => {
                                                                        setLoading(false)
                                                                        setIsSubmitting(false)
                                                                    },
                                                                    () => {
                                                                        setIsSubmitting(false)
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            Удалить
                                                        </Submit>
                                                    </Grid>
                                                    <Grid item>
                                                        <Submit
                                                            disableElevation
                                                            variant="contained"
                                                            color="primary"
                                                            type="button"
                                                            disabled={isSubmitting}
                                                            onClick={() => {
                                                                setIsSubmitting(true)
                                                                return dispatch(WarningActions.remove()).then(
                                                                    () => {
                                                                        setLoading(false)
                                                                        setIsSubmitting(false)
                                                                    },
                                                                    () => {
                                                                        setIsSubmitting(false)
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            Удалить все
                                                        </Submit>
                                                    </Grid>
                                                    <Create
                                                        onClick={event => {
                                                            setEvents({
                                                                data: [
                                                                    event,
                                                                    ...((events.data.length === rowsPerPage) ? events.data.slice(0, rowsPerPage) : events.data)
                                                                ],
                                                                meta: {
                                                                    total: (events.meta?.total ?? 0) + 1
                                                                },
                                                            })
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            {event ? (
                <Event
                    event={event}
                    open={!!event}
                    onClick={event => {
                        setEvents({
                            ...events,
                            ...{data: events.data.map((el) => (el.id === event.id) ? event : el)}
                        })
                    }}
                    setDialog={(open) => setEvent(open ? event : null)}
                />
            ) : null}
        </Grid>
    )
}
