import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {
    Grid,
    IconButton,
    makeStyles
} from "@material-ui/core"
import {Delete as DeleteIcon, Edit as EditIcon} from '@material-ui/icons'
import {Table} from "@devexpress/dx-react-grid-material-ui";
import {Submit} from "../../../../App/components/Button/Submit"
import {CustomTable} from "../../../../App/components/Table/Table";
import {TableHeader} from "../../../../App/components/Table/TableHeader";
import {localState} from "../../../../App/components/LocalState";
import {CompanyActions} from "../../../actions/company";
import {Delete} from "./Subscription/Buttons/Delete";
import {Edit} from "./Subscription/Buttons/Edit";
import {Create} from "./Subscription/Buttons/Create";

const useStyles = makeStyles(() => ({
    fullWidth: {
        "width": "100%"
    },
    fullWidthDialog: {
        "width": "500px"
    },
    inputContent: {
        "width": "100%",
        'height': 'calc(100vh - 307px)',
        'min-height': 'calc(100vh - 307px)',
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 298px)"
    },
    column: {
        "height": "86px"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
}))

export const Subscriptions = (props) => {
    const dispatch = useDispatch()

    const {regions, categories} = props
    const classes = useStyles()
    const [subscriptions, setSubscriptions] = useState(localState)
    const [loading, setLoading] = useState(false)

    const columns = [
        {name: 'regions', title: 'Регионы'},
        {name: 'categories', title: 'Категории'},
        {name: 'buttons', title: ' '},
    ]

    const [tableRef, setTableRef] = useState(null)
    const {filter} = useSelector(state => state.filters['company_subscriptions'])

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'regions', width: 450},
        {columnName: 'categories', width: 450},
        {columnName: 'buttons', width: 350},
    ])

    const [columnOrder, setColumnOrder] = useState([
        'regions',
        'categories',
        'buttons'
    ])

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const handlePagesSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: 'COMPANY_SUBSCRIPTIONS_FILTER', payload: {limitRows: newRowsPerPage}})
    }

    useEffect(() => {
        const getSubscriptions = async () => {
            return await dispatch(CompanyActions.getSubscriptions({
                limit: rowsPerPage,
                page: page,
            }))
        }

        if (!loading) {
            getSubscriptions().then((response) => {
                setSubscriptions(response)
                setLoading(true)
                tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
            })
        }
    }, [dispatch, filter, page, rowsPerPage, loading]);

    return (
        <Grid item className={classes.fullWidth}>
            <Grid container direction="column" justify="space-between" alignItems="stretch">
                <Grid item className={classes.footer}>
                    <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                        <Grid item className={classes.footerContent}>
                            <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                <Grid item>
                                    <Create setLoading={setLoading} regions={regions} categories={categories}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <CustomTable
                    meta={subscriptions.meta}
                    tableName={'company_subscriptions'}
                    rows={subscriptions?.data.map((subscription) => ({
                        regions: regions.filter(region => subscription.regions.includes(region.id)).map(region => region.name).join(', '),
                        categories: categories.filter(category => subscription.categories.includes(category.id)).map(category => category.name).join(', '),
                        buttons: <React.Fragment>
                            <Edit subscription={subscription} setLoading={setLoading} regions={regions} categories={categories}/>
                            <Delete id={subscription.id} setLoading={setLoading}/>
                        </React.Fragment>
                    }))}
                    columns={columns}
                    page={{page: page, setPage: setPage, rowsPerPage: rowsPerPage, handlePageSizeChange: handlePagesSizeChange}}
                    setTableRef={setTableRef}
                    columnsSettings={{
                        columnOrder: columnOrder,
                        setColumnOrder: setColumnOrder,
                        setColumnWidths: setColumnWidths,
                        columnWidths: columnWidths,
                        hiddenColumnNames: hiddenColumnNames,
                        setHiddenColumnNames: setHiddenColumnNames
                    }}
                    tableHeader={TableHeader}
                    filterActionType={'COMPANY_SUBSCRIPTIONS_FILTER'}
                    classInputContent={classes.inputContent}
                    rowComponent={({row, tableRow, children}) => (
                        <Table.Row
                            tableRow={tableRow}
                            children={children}
                            className={classes.tableRow}
                            row={row}
                        />
                    )}
                    filters={{
                        regions: {
                            nullButton: true
                        },
                        categories: {
                            nullButton: true
                        },
                        buttons: {
                            nullButton: true
                        },
                    }}
                />
            </Grid>
        </Grid>
    )
}
