import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce} from 'use-debounce'
import format from "date-fns/format"
import {ru} from "date-fns/locale"
import {CustomPaging, IntegratedSelection, PagingState, SelectionState} from '@devexpress/dx-react-grid'
import {
    DragDropProvider,
    PagingPanel,
    Table,
    TableColumnReordering,
    TableColumnResizing,
    TableColumnVisibility,
    TableHeaderRow,
    TableSelection
} from '@devexpress/dx-react-grid-material-ui'

import {Grid, InputAdornment, makeStyles, Paper, Select, TextField, withStyles} from "@material-ui/core"
import {Alert} from '@material-ui/lab'
import {Delete as DeleteIcon, GetApp as GetAppIcon, Search as SearchIcon} from "@material-ui/icons"
import {green} from "@material-ui/core/colors"

import {Grid as GridTable} from "../../../App/components/Table/Grid"
import {Pager} from "../../../App/components/Table/Paging/Pager"
import {StickyTable} from "../../../App/components/Table/StikyTable"
import {ColumnChooser} from "../../../App/components/Table/ColumnChooser"
import {DictionaryActions} from "../../actions/standards"
import {TableHeaderContent} from "../Table/TableHeaderContent"
import {CategoriesTree} from "../../../App/components/Select/CategoriesTree"
import {SystemActions} from "../../../App/actions/system"
import {ButtonIcon} from "../../../App/components/Button/ButtonIcon"
import {getPath} from "../../../App/helpers/http"
import {Submit} from "../../../App/components/Button/Submit"
import * as AppStorage from "../../../App/helpers/storage"
import {useParams} from "react-router-dom";
import {StandardActions} from "../../../Standard/actions/standard";
import {SettingsActions} from "../../../Settings/actions/settings";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    filterLabel: {
        "background": "#fff",
        "margin-bottom": "0 !important",
        "margin-top": "5px !important",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        },
        "& input": {
            padding: "6px 15px 7px"
        }
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 187px)",
        "min-height": "calc(100% - 187px)",
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    footer: {
        width: "100%",
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        // "height": "50px",
        "padding": "0 8px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    },
    active: {
        'height': '41px',
        'background-color': green[100],
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    loading: {
        'height': '41px',
        'background-color': '#fff',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    iconButton: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    },
    disableDefaultAlert: {
        boxShadow: 'none !important',
        borderTop: 'none !important',
        borderBottom: 'none !important',
    },
    submitCategoryBtn: {
        "position": "sticky",
        "bottom": "10px",
        "width": "calc(100% - 20px) !important",
        "margin": "10px !important",
        '&.MuiButton-root': {
            "border-bottom": "none !important",
        },
    },
}))

const columns = [
    {name: 'id', title: 'ID'},
    {name: 'value.keyword', title: 'Эталон'},
    {name: 'category', title: 'Категория'},
    {name: 'status', title: 'Статус'},
    {name: 'created', title: 'Дата создания', filter: 'created'}
]

const StyledAlert = withStyles(() => ({
    message: {
        'padding': '0'
    },
}))(Alert)

export const Content = () => {
    const {type} = useParams()

    const dispatch = useDispatch()
    const classes = useStyles()
    const {standards} = useSelector(state => state.dictionaries)
    const {categories} = useSelector(state => state.system)

    const [page, setPage] = useState(1)
    const [filter, setFilter] = useState({
        sort: {
            direction: null,
            name: null
        },
        status: [],
        category: null
    })
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [settings, setSettings] = useState([])
    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)
    const [selection, setSelection] = useState([])
    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
    const [tableRef, setTableRef] = useState(null)
    const [category, setCategory] = useState({})
    const [loading, setLoading] = useState(false)
    const [initialized, setInitialized] = useState(false)

    const [columnOrder, setColumnOrder] = useState([
        'id',
        'value.keyword',
        'category',
        'name',
        'status',
        'created'
    ])

    useEffect(() => {
        const getData = async () => {
            if (!categories.length) {
                await dispatch(SystemActions.categories())
            }
        }

        getData().then(() => {})
        // eslint-disable-next-line
    }, [dispatch]);

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'id', width: 150},
        {columnName: 'value.keyword', width: 300},
        {columnName: 'category', width: 300},
        {columnName: 'status', width: 200},
        {columnName: 'created', width: 200}
    ])

    const getParams = () => {
        return {
            order: (filter.sort.name && filter.sort.direction) ? [filter.sort.name, filter.sort.direction] : ['confirmed', 'asc'],
            ...(!!filter.status.length ? {status: filter.status} : []),
            ...(searchRequest ? {search: searchRequest} : {}),
            ...(filter.category ? {category: filter.category} : {})
        }
    }

    const getStandards = async () => {
        let params = {
            limit: rowsPerPage,
            page: page,
            ...getParams()
        }
        await dispatch(DictionaryActions.selectedStandards(type, params))
    }

    useEffect(() => {
        if (!loading) {
            getStandards().then(() => {
                tableRef && tableRef.current && tableRef.current.scrollIntoView()
                setLoading(true)
            })
        }
        // eslint-disable-next-line
    }, [loading])

    useEffect(() => {
        if (loading) {
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [page, rowsPerPage, searchRequest, filter, type])

    useEffect(() => {
        setCategory(filter.category ? categories.find(el => el.id === filter.category) : {})
        // eslint-disable-next-line
    }, [filter.category])

    useEffect(() => {
        const getSettings = async () => {
            return await dispatch(SettingsActions.getSettings('standard')).then(settings => {
                setSettings(settings)
            })
        }

        if (!initialized) {
            getSettings().then(() => {
                setInitialized(true)
            })
        }
        // eslint-disable-next-line
    }, [])

    const handlePageChange = newPage => setPage(newPage)
    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        setSelection([])
    }

    const tableSelectionRowComponent = (props) => {
        const {tableRow, children, highlighted, onToggle} = props;
        const {row} = tableRow;

        return (
            <Table.Row
                tableRow={tableRow}
                children={children}
                onClick={onToggle}
                className={highlighted ? classes.active : ((row.loaded === 'Загружен') ? classes.default : classes.loading)}
                row={row}
            />
        )
    }

    const getStatus = (item) => {
        if (!item.active) {
            if (item.confirmed) {
                return <StyledAlert
                    icon={false}
                    style={{
                        backgroundColor: 'rgba(244, 244, 244, 1)',
                        color: 'rgba(0, 0, 0, 0.87)',
                    }}
                    className={classes.disableDefaultAlert}
                >
                    Неактивный
                </StyledAlert>
            } else {
                return <StyledAlert icon={false} color="error" className={classes.disableDefaultAlert}>Не проверен</StyledAlert>
            }
        }

        return <StyledAlert icon={false} color="success" className={classes.disableDefaultAlert}>Активный</StyledAlert>
    }

    const onCloseSelect = () => {
        setFilter({
            ...filter,
            ...(category && category.hasOwnProperty('id') ? {category: category.id} : {category: null})
        })
    }

    return initialized ? (
        <Grid item className={classes.content}>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item className={classes.footer}>
                    <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                        <Grid item className={classes.footerContent}>
                            <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                <Grid item xs={12} style={{paddingBottom: 0}}>
                                    <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                        <Grid item xs={4}>
                                            <Select
                                                fullWidth
                                                id="category"
                                                onClose={onCloseSelect}
                                                value={!!Object.keys(category).length ? category : ''}
                                                renderValue={(value) => value.hasOwnProperty('name') ? value.name : null}
                                            >
                                                <CategoriesTree
                                                    callback={value => {
                                                        if (value.id === category.id) {
                                                            setCategory({})
                                                        } else {
                                                            setCategory(value)
                                                        }
                                                    }}
                                                    categories={categories}
                                                    category={category}
                                                />
                                                <Submit
                                                    className={classes.submitCategoryBtn}
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    onClick={onCloseSelect}
                                                >
                                                    Подтвердить
                                                </Submit>
                                            </Select>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                <Grid item>
                                                    <ButtonIcon
                                                        type="button"
                                                        target="_self"
                                                        href={process.env.REACT_APP_HOST_API + getPath('/dictionary/standards/selected', {
                                                            ...getParams(),
                                                            ...{
                                                                download: true,
                                                                columns: columns.filter(el => hiddenColumnNames.indexOf(el.name) === -1).map(el => el.name).join(','),
                                                                token: AppStorage.get('token').replace('Bearer ', '')
                                                            }
                                                        })}
                                                        children={<GetAppIcon/>}
                                                        title='Скачать таблицу'
                                                        className={classes.iconButton}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <ButtonIcon
                                                        disabled={Boolean(!selection.length)}
                                                        onClick={() => {
                                                            switch (type) {
                                                                case 'allowed':
                                                                    return dispatch(
                                                                        StandardActions.allow({
                                                                            ids: standards.data.filter((el, idx) => selection.indexOf(idx) !== -1).map(el => el.id),
                                                                            active: false
                                                                        })
                                                                    ).then(() => {
                                                                        setLoading(false)
                                                                        setSelection([])
                                                                    })
                                                                case 'strategic':
                                                                    return dispatch(
                                                                        StandardActions.strategic({
                                                                            ids: standards.data.filter((el, idx) => selection.indexOf(idx) !== -1).map(el => el.id),
                                                                            active: false
                                                                        })
                                                                    ).then(() => {
                                                                        setLoading(false)
                                                                        setSelection([])
                                                                    })
                                                                default:
                                                                    return dispatch(
                                                                        DictionaryActions.deleteSelected({
                                                                            ids: standards.data.filter((el, idx) => selection.indexOf(idx) !== -1).map(el => el.id)
                                                                        })
                                                                    ).then(() => {
                                                                        setLoading(false)
                                                                        setSelection([])
                                                                    })
                                                            }

                                                        }}
                                                        title='Удалить'
                                                        children={<DeleteIcon/>}
                                                        className={classes.iconButton}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <ColumnChooser
                                                        columns={columns}
                                                        hiddenColumnNames={hiddenColumnNames}
                                                        setHiddenColumnNames={setHiddenColumnNames}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{paddingTop: "4px"}}>
                                    <TextField
                                        id="search"
                                        variant="standard"
                                        name={'search'}
                                        placeholder={`Поиск: наименование эталона`}
                                        fullWidth={true}
                                        margin={'normal'}
                                        onChange={(e) => {
                                            setSearch(e.target.value)
                                        }}
                                        classes={{root: classes.filterLabel}}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchIcon style={{color: '#c1c1c1'}}/>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Grid container direction="row" alignItems="center">
                            <Grid item className={classes.inputContent}>
                                <GridTable
                                    rows={standards.data.map(standard => {
                                        const type = settings.categories.find(el => el.category.id === standard.category?.index)?.type

                                        const data = {
                                            id: standard.id,
                                            'value.keyword': standard.name?.standard,
                                            category: standard.category.name,
                                            status: getStatus(standard),
                                            created: standard.created ? format(new Date(standard.created), 'H:mm PP', {locale: ru}) : null
                                        }

                                        switch (type) {
                                            case 'typed':
                                            case 'arbitrary':
                                                data['value.keyword'] = standard.name.hasOwnProperty(type) ? standard.name[type] : standard.name;
                                                break;
                                            default:
                                                data['value.keyword'] = standard.name['standard']
                                        }

                                        return data
                                    })}
                                    columns={columns}
                                >
                                    <PagingState
                                        currentPage={page}
                                        onCurrentPageChange={handlePageChange}
                                        pageSize={rowsPerPage}
                                        onPageSizeChange={handlePageSizeChange}
                                    />
                                    <CustomPaging
                                        totalCount={standards.meta.total}
                                    />
                                    <SelectionState
                                        selection={selection}
                                        onSelectionChange={(numbers) => {
                                            setSelection(Array.from(new Set(numbers)))
                                        }}
                                    />
                                    <DragDropProvider/>
                                    <Table
                                        noDataCellComponent={() => {
                                            return null;
                                        }}
                                        tableComponent={props => <StickyTable {...props} setTableRef={setTableRef}/>}
                                        rowComponent={({row, tableRow, children}) => (
                                            <Table.Row
                                                tableRow={tableRow}
                                                children={children}
                                                className={classes.default}
                                                row={row}
                                                color="black"
                                            />
                                        )}
                                    />
                                    <TableColumnReordering
                                        order={columnOrder}
                                        onOrderChange={setColumnOrder}
                                    />
                                    <TableColumnResizing
                                        columnWidths={columnWidths}
                                        onColumnWidthsChange={setColumnWidths}
                                    />
                                    <TableHeaderRow
                                        contentComponent={(props) => <TableHeaderContent {...props} filter={filter} setFilter={setFilter}/>}
                                    />
                                    <TableColumnVisibility
                                        hiddenColumnNames={hiddenColumnNames}
                                        onHiddenColumnNamesChange={setHiddenColumnNames}
                                    />
                                    <IntegratedSelection/>
                                    <TableSelection
                                        showSelectAll
                                        selectByRowClick
                                        showSelectionColumn={true}
                                        highlightRow
                                        rowComponent={tableSelectionRowComponent}
                                    />
                                    <PagingPanel
                                        containerComponent={Pager}
                                        messages={{showAll: 'Все', rowsPerPage: 'Записей на странице:', info: '{from} - {to} из {count}'}}
                                        pageSizes={[50, 100, 200]}
                                    />
                                </GridTable>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    ) : null
}

