import React from "react"
import {useDispatch} from "react-redux"
import format from "date-fns/format"
import {ru} from "date-fns/locale"

import {
    Grid,
    makeStyles,
    Typography
} from "@material-ui/core"
import {Table} from "@devexpress/dx-react-grid-material-ui";

import {UserActions} from "./actions/user"
import {TableHeader} from "../../../App/components/Table/TableHeader";
import {CustomTable} from "../../../App/components/Table/Table";
import {Submit} from "../../../App/components/Button/Submit";
import {history} from "../../../App/helpers/history";
import {ColumnChooser} from "../../../App/components/Table/ColumnChooser";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 162px)"
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 180px)",
        "min-height": "calc(100vh - 180px)"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
}))

const columns = [
    {name: 'full_name', title: 'ФИО'},
    {name: 'login', title: 'Логин'},
    {name: 'email', title: 'Почта'},
    {name: 'roles', title: 'Роли'},
    {name: 'created_at', title: 'Дата создания'}
];

export const Users = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {setTableRef, operators, page, rowsPerPage, label, columnWidths, hiddenColumnNames, setHiddenColumnNames, columnOrder, setColumnOrder, setColumnWidths, setPage, handlePagesSizeChange} = props

    return (
        <Grid item className={classes.content}>
            <Typography variant="h4">{label}</Typography>
            <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2} style={{marginTop: '-25px'}}>
                <Grid item style={{marginBottom: '10px'}}>
                    <Submit
                        disableElevation
                        variant="contained"
                        color="primary"
                        type="button"
                        style={{width: '225px'}}
                        onClick={() => {
                            history.push('/user/operators/add')
                        }}
                    >
                        Создать
                    </Submit>
                </Grid>
                <Grid item style={{marginTop: '-4px', marginBottom: '10px'}}>
                    <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
                </Grid>
            </Grid>
            <CustomTable
                meta={operators.meta}
                tableName={'operators'}
                rows={operators?.data.map(user => ({
                    full_name: `${user['last_name']} ${user['first_name']} ${user['middle_name']}`,
                    created_at: format(new Date(user.created), 'PP',  {locale: ru}),
                    roles: user.roles.map(el  => el.description).join(', '),
                    email: user.email,
                    login: user.login
                }))}
                columns={columns}
                page={{page: page, setPage: setPage, rowsPerPage: rowsPerPage, handlePageSizeChange: handlePagesSizeChange}}
                setTableRef={setTableRef}
                columnsSettings={{
                    columnOrder: columnOrder,
                    setColumnOrder: setColumnOrder,
                    setColumnWidths: setColumnWidths,
                    columnWidths: columnWidths,
                    hiddenColumnNames: hiddenColumnNames,
                    setHiddenColumnNames: setHiddenColumnNames
                }}
                tableHeader={TableHeader}
                filterActionType={'OPERATORS_FILTER'}
                classInputContent={classes.inputContent}
                rowComponent={({row, tableRow, children}) => (
                    <Table.Row
                        tableRow={tableRow}
                        children={children}
                        className={classes.tableRow}
                        row={row}
                        onClick={(e, _) => {
                            return dispatch(UserActions.login({
                                login: operators.data[tableRow.rowId].login,
                            }))
                        }}
                    />
                )}
                filters={{
                    roles: {
                        nullButton: true
                    },
                }}
            />
        </Grid>)
}
