import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import {CheckboxWithLabel, Select} from "formik-material-ui"
import * as Yup from "yup"

import {Checkbox, FormControl, Grid, InputLabel, makeStyles, MenuItem, Paper, Typography} from "@material-ui/core"
import {Table} from "@devexpress/dx-react-grid-material-ui";

import {TextFieldWithError as CustomTextField} from "../../../../../App/components/Inputs/TextFieldWithError"
import {Submit} from "../../../../../App/components/Button/Submit"
import {history} from "../../../../../App/helpers/history"
import {UserActions} from "../../actions/user"
import {CompanyActions} from "../../../../actions/company"
import {AuthActions} from "../../../../../Auth/actions/authentication"
import {SettingsActions} from "../../../../../Settings/actions/settings";
import {useDebounce} from "use-debounce";
import {TableHeader} from "../../../../../App/components/Table/TableHeader";
import {CustomTable} from "../../../../../App/components/Table/Table";
import {localState} from "../../../../../App/components/LocalState";
import {type as types} from "../../../../../Report/dics/type";
import {SystemActions} from "../../../../../App/actions/system";

const useStyles = makeStyles(theme => ({
    input: {
        "width": "100%",
        "padding": "0 4px 3px !important",
    },
    inputContent: {
        "height": "calc(100vh - 519px)"
    },
    fullContent: {
        "height": "calc(100vh - 110px)"
    },
    inputContainer: {
        "width": "100%",
        "padding": "20px 30px 0",
    },
    tab: {
        "width": "100%",
    },
    column: {
        "width": "100%",
        "height": "70px"
    },
    checkbox: {
        '&.MuiIconButton-root': {
            "padding": "6px"
        }
    },
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    searchLabel: {
        "margin-bottom": "14px",
    },
    fullWidth: {
        "width": "100%",
    },
    full: {
        "width": "100%",
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    select: {
        minWidth: "200px",
        "&:focus": {
            "background-color": "#fff",
        },
    },
    button: {
        marginTop: '10px'
    }
}))

const organizations = [
    {
        key: 'central',
        value: 'ЦДЗ'
    },
    {
        key: 'institution',
        value: 'НУЗ (КО)'
    },
    {
        key: 'regional',
        value: 'РДМО'
    }
]

export const AccountContent = (props) => {
    let {id} = useParams()

    const dispatch = useDispatch()
    const classes = useStyles()

    const {user} = useSelector(state => {
        return {...state.companies, ...state.users}
    })

    const [customers, setCustomers] = useState(localState)

    const [loading, setLoading] = useState(false)

    const {permissions, categories} = useSelector(state => ({
        ...state.authentication, ...state.system
    }))

    const [page, setPage] = useState(1)
    const {filter} = useSelector(state => state.filters['supervisor'])
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)

    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)
    const [tableRef, setTableRef] = useState(null)

    const [selected, setSelected] = useState([])

    const isSelected = (name) => (selected.indexOf(name) !== -1)

    const [columns, setColumns] = useState([
        {name: 'company', title: 'Наименование организации', filter: 'companies'},
        {name: 'id', title: 'ID'},
        {name: 'inn', title: 'ИНН', filter: 'inn'},
        {name: 'kpp', title: 'КПП', filter: 'kpp'},
        {name: 'bin', title: 'БИН'},
        {name: 'kbe', title: 'КБЕ'}]);

    const [columnOrder, setColumnOrder] = useState([
        'checkbox',
        'company',
        'id',
        'inn',
        'kpp',
        'bin',
        'kbe'
    ])

    const [initialize, setInitialize] = useState(false);

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'checkbox', width: 55},
        {columnName: 'company', width: 300},
        {columnName: 'id', width: 100},
        {columnName: 'inn', width: 100},
        {columnName: 'kpp', width: 100},
        {columnName: 'bin', width: 100},
        {columnName: 'kbe', width: 100},
    ])

    useEffect(() => {
        if (!initialize) {
            dispatch(SettingsActions.table('supervisor')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'supervisor',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    useEffect(() => {
        const getPermissions = async () => {
            await dispatch(AuthActions.permissions())
        }
        getPermissions().then(_ => {})
    }, [dispatch, props.open])

    useEffect(() => {
        const getCategories = async () => {
            await dispatch(SystemActions.categories())
        }
        getCategories().then(_ => {})
    }, [dispatch, props.open])

    const handleSelectAll = (event, setFieldValue) => {
        if (event.target.checked && selected.length !== customers.data.length) {
            const companies = customers.data.map((customer) => customer.id)

            setSelected(companies)
            setFieldValue('companies', companies)
        } else {
            setSelected([])
            setFieldValue('companies', [])
        }
    };

    const handleSelect = (event, setFieldValue, name) => {
        const selectedIndex = selected.indexOf(name)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
        }

        setSelected(newSelected)
        setFieldValue('companies', newSelected)
    };

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: "SUPERVISOR_FILTER", payload: {limitRows: newRowsPerPage}})
    }

    useEffect(() => {
        if (!loading) {
            const getUser = async () => {
                return await dispatch(UserActions.user(id))
            }

            if (id) {
                getUser().then(account => {
                    setSelected(account.companies.map(company => company.id))
                    setLoading(true)
                })
            } else {
                dispatch({type: 'USER_SUCCESS', payload: null})
                setLoading(true)
            }
        }
    }, [dispatch, loading, id]);

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...((filter.hasOwnProperty('companies') && !!filter.companies.length) ? {companies: filter.companies} : {}),
            ...((filter.hasOwnProperty('kpp') && !!filter.kpp.length) ? {kpp: filter.kpp} : {}),
            ...((filter.hasOwnProperty('inn') && !!filter.inn.length) ? {inn: filter.inn} : {}),
        }
    }

    useEffect(() => {
        const getCustomers = async () => {
            return await dispatch(CompanyActions.customers({
                limit: rowsPerPage,
                page: page,
                ...getParams()
            }))
        }

        getCustomers().then((response) => {
            setCustomers(response)
            tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
        })
    }, [dispatch, searchRequest, page, rowsPerPage, filter]);

    return loading && permissions ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center" className={classes.fullContent}>
                    <Grid item className={classes.full}>
                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                            <Grid item className={classes.fullWidth}>
                                <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                    <Grid item className={classes.header}>
                                        <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                            <Grid item className={classes.fullWidth}>
                                                <Typography className={classes.title}>
                                                    {user ? 'Редактировать' : 'Создать'} учётную запись контролирующего органа
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Formik
                                    initialValues={
                                        {
                                            last_name: user ? user.last_name : '',
                                            first_name: user ? user.first_name : '',
                                            middle_name: user ? user.middle_name : '',
                                            login: user ? user.login : '',
                                            email: user ? user.email : '',
                                            phones: user ? (user.phones ? user.phones.map(phone => ({
                                                number: phone.number ?? '',
                                                extra: phone.extra ?? ''
                                            })) : [{number: '', extra: ''}]) : [{number: '', extra: ''}],
                                            organization: user ? (user.organization ?? '') : '',
                                            abbreviation: user ? (user.abbreviation ?? '') : '',
                                            companies: selected,
                                            password: '',
                                            confirmation: '',
                                            report: {
                                                types: [],
                                                categories: [],
                                                ...(user ? user.permissions.find(item => item.name === 'access_to_report')?.params : {})
                                            },
                                            permissions: permissions.reduce((obj, permission) => {
                                                obj[permission.id] = !!(user && user.hasOwnProperty('permissions') && user.permissions.find(item => item.id === permission.id));
                                                return obj
                                            }, {})
                                        }
                                    }
                                    validationSchema={Yup.object().shape({
                                        login: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                        password: user ? Yup.string().min(8, "Длина пароля должна быть не менее 8 символов!") : Yup.string().min(8, "Длина пароля должна быть не менее 8 символов!").required("Поле не может быть пустым или состоять из одних пробелов!"),
                                        confirmation: Yup.string().when("password", (password, schema) => {
                                            return password ? schema.required("Поле не может быть пустым или состоять из одних пробелов!") : schema
                                        }).oneOf([Yup.ref("password"), null], "Пароли не совпадают!"),
                                        email: Yup.string().email("Некорректный формат электронной почты!").required("Поле не может быть пустым или состоять из одних пробелов!"),
                                        last_name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                        first_name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                        middle_name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                        abbreviation: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                        organization: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                        phones: Yup.array().of(Yup.object().shape({
                                            number: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').when("extra", (extra, schema) => {
                                                return extra ? schema.required("Поле не может быть пустым или состоять из одних пробелов!") : schema
                                            }),
                                            extra: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                                        })),
                                        report: Yup.object().shape({
                                            types: Yup.array(),
                                            categories: Yup.array(),
                                        }),
                                        permissions: Yup.mixed().nullable(),
                                    })}
                                    onSubmit={(values, {setSubmitting, setErrors}) => {
                                        return dispatch(user ? UserActions.update(user.id, values) : UserActions.create({...values, ...{roles: ['supervisor']}})).then(
                                            () => {
                                                history.push('/user/supervisors')
                                                setSubmitting(false);
                                            },
                                            error => {
                                                if (error.errors) {
                                                    setErrors(error.errors)
                                                }
                                                setSubmitting(false);
                                            }
                                        )
                                    }}
                                >
                                    {({
                                          values,
                                          dirty,
                                          isSubmitting,
                                          isValid,
                                          setValues,
                                          setFieldValue,
                                      }) => (
                                        <Form>
                                            <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                                                <Grid item className={classes.inputContainer}>
                                                    <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                                                        <Grid item xs={4}>
                                                            <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                                                <Grid item className={classes.column}>
                                                                    <Field
                                                                        fullWidth
                                                                        name="last_name"
                                                                        type="text"
                                                                        label="Фамилия"
                                                                        required
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                                <Grid item className={classes.column}>
                                                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                                        <Grid item xs={6}>
                                                                            <Field
                                                                                fullWidth
                                                                                name="login"
                                                                                type="text"
                                                                                label="Имя пользователя"
                                                                                required
                                                                                component={CustomTextField}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Field
                                                                                fullWidth
                                                                                name="abbreviation"
                                                                                type="text"
                                                                                label="Сокращенное наименование"
                                                                                required
                                                                                component={CustomTextField}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item className={classes.column}>
                                                                    <Field
                                                                        fullWidth
                                                                        name="email"
                                                                        type="email"
                                                                        label="Адрес электронной почты"
                                                                        required
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                                <Grid item className={classes.column}>
                                                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                                        <Grid item xs={6}>
                                                                            <Field
                                                                                fullWidth
                                                                                name="password"
                                                                                type="password"
                                                                                label="Пароль"
                                                                                required={!user}
                                                                                component={CustomTextField}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Field
                                                                                fullWidth
                                                                                name="confirmation"
                                                                                type="password"
                                                                                label="Подтверждение пароля"
                                                                                required={!user}
                                                                                component={CustomTextField}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={4} >
                                                            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                <Grid item className={classes.column}>
                                                                    <Field
                                                                        fullWidth
                                                                        name="first_name"
                                                                        type="text"
                                                                        label="Имя"
                                                                        required
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                                <Grid item className={classes.column}>
                                                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                                        <Grid item xs={9}>
                                                                            <Field
                                                                                fullWidth
                                                                                name={`phones.0.number`}
                                                                                type="phone"
                                                                                label="Номер телефона"
                                                                                component={CustomTextField}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={3}>
                                                                            <Field
                                                                                fullWidth
                                                                                name={`phones.0.extra`}
                                                                                type="text"
                                                                                label="доб."
                                                                                component={CustomTextField}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item className={classes.column}>
                                                                    <FormControl fullWidth>
                                                                        <InputLabel>
                                                                            Отчёты (Тип)
                                                                        </InputLabel>
                                                                        <Field
                                                                            fullWidth
                                                                            type="text"
                                                                            name="report.types"
                                                                            multiple
                                                                            component={Select}
                                                                            inputProps={{
                                                                                onChange: (e) => {
                                                                                    if (!e.target.value.length) {
                                                                                        setValues({
                                                                                            ...values,
                                                                                            ...{
                                                                                                report: {
                                                                                                    types: [],
                                                                                                    categories: []
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    } else {
                                                                                        setFieldValue('report.types', e.target.value)
                                                                                    }
                                                                                }
                                                                            }}
                                                                        >
                                                                            {Object.entries(types).reduce((result, [key, value]) => {
                                                                                result.push({
                                                                                    value: key,
                                                                                    name: value
                                                                                })
                                                                                return result
                                                                            }, []).map((report, index) => (
                                                                                <MenuItem key={index} value={report.value}>{report.name}</MenuItem>
                                                                            ))}
                                                                        </Field>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item className={classes.column}>
                                                                    <FormControl fullWidth>
                                                                        <InputLabel>
                                                                            Отчёты (Категория)
                                                                        </InputLabel>
                                                                        <Field
                                                                            fullWidth
                                                                            disabled={!values.report.types.length}
                                                                            type="text"
                                                                            name="report.categories"
                                                                            multiple
                                                                            component={Select}
                                                                        >
                                                                            {categories.filter(category => !category.category).map((category, index) => (
                                                                                <MenuItem key={index} value={category.id}>{category.name}</MenuItem>
                                                                            ))}
                                                                        </Field>
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={4} >
                                                            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                <Grid item className={classes.column}>
                                                                    <Field
                                                                        fullWidth
                                                                        name="middle_name"
                                                                        type="text"
                                                                        label="Отчество"
                                                                        required
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <FormControl fullWidth>
                                                                        <InputLabel>
                                                                            Организация
                                                                        </InputLabel>
                                                                        <Field
                                                                            fullWidth
                                                                            type="text"
                                                                            name="organization"
                                                                            required
                                                                            component={Select}
                                                                        >
                                                                            {organizations.map((organization, index) => (
                                                                                <MenuItem key={index} value={organization.key}>{organization.value}</MenuItem>
                                                                            ))}
                                                                        </Field>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item>
                                                                    {permissions.filter(per => [
                                                                        'access_to_planning', 'access_to_standard', 'access_to_planning_settings', 'access_to_companies', 'control_price'
                                                                    ].indexOf(per.name) !== -1).map(permission => (
                                                                        <Grid key={permission.id} item>
                                                                            <Field
                                                                                className={classes.checkbox}
                                                                                component={CheckboxWithLabel}
                                                                                name={`permissions[${permission.id}]`}
                                                                                color="primary"
                                                                                Label={{label: permission.description}}
                                                                            />
                                                                        </Grid>
                                                                    ))}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <CustomTable
                                                meta={customers.meta}
                                                tableName={'supervisor'}
                                                rows={customers?.data.map((customer) => ({
                                                    checkbox: <Checkbox checked={isSelected(customer.id)}/>,
                                                    company: customer.legal_detail.name,
                                                    id: customer.id,
                                                    inn: customer.legal_detail.inn,
                                                    kpp: customer.legal_detail.kpp,
                                                    bin: customer.legal_detail.bin,
                                                    kbe: customer.legal_detail.kbe,
                                                }))}
                                                columns={[
                                                    ...columns,
                                                    {
                                                        name: 'checkbox',
                                                        title: <Checkbox
                                                            indeterminate={selected.length > 0 && selected.length < customers.meta.total}
                                                            checked={customers.meta.total > 0 && selected.length === customers.meta.total}
                                                            onChange={event => handleSelectAll(event, setFieldValue)}
                                                        />
                                                    }
                                                ]}
                                                page={{
                                                    page: page,
                                                    setPage: setPage,
                                                    rowsPerPage: rowsPerPage,
                                                    handlePageSizeChange: handlePageSizeChange
                                                }}
                                                setTableRef={setTableRef}
                                                columnsSettings={{
                                                    columnOrder: columnOrder,
                                                    setColumnOrder: setColumnOrder,
                                                    setColumnWidths: setColumnWidths,
                                                    columnWidths: columnWidths,
                                                    hiddenColumnNames: hiddenColumnNames,
                                                    setHiddenColumnNames: setHiddenColumnNames
                                                }}
                                                tableHeader={TableHeader}
                                                filterActionType={"SUPERVISOR_FILTER"}
                                                getValues={CompanyActions.filter}
                                                classInputContent={classes.inputContent}
                                                rowComponent={({row, tableRow, children}) => (
                                                    <Table.Row
                                                        className={classes.tableRow}
                                                        key={customers.data[tableRow.rowId].id}
                                                        selected={isSelected(customers.data[tableRow.rowId].id)}
                                                        tableRow={tableRow}
                                                        row={row}
                                                        children={children}
                                                        onClick={(event) => {
                                                            handleSelect(event, setFieldValue, customers.data[tableRow.rowId].id)
                                                        }}
                                                        style={{'cursor': 'pointer'}}
                                                    />
                                                )}
                                                filters={{
                                                    checkbox: {
                                                        nullButton: true,
                                                    },
                                                    company: {
                                                        name: 'companies',
                                                        type: 'values',
                                                    },
                                                    inn: {
                                                        name: 'inn',
                                                        type: 'values',
                                                    },
                                                    kpp: {
                                                        name: 'kpp',
                                                        type: 'values',
                                                    },
                                                }}
                                                actions={[
                                                    <Submit
                                                        disableElevation
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        disabled={isSubmitting || !isValid || !dirty}
                                                    >
                                                        {user ? 'Сохранить' : 'Создать'}
                                                    </Submit>
                                                ]}
                                            />
                                        </Form>
                                    )}
                                </Formik>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    ) : null
}
