import React from "react"
import {useDispatch, useSelector} from "react-redux"

import {
    Grid,
    makeStyles
} from "@material-ui/core"

import {plan, purchase} from "../../../helpers/permissions/rules"
import {Delete as DeleteIcon} from "@material-ui/icons";
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon";
import {PurchasePlanItemsActions} from "../../../actions/purchasePlanItems";

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    }
}))

export const Delete = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { items, onClick } = props

    const {account} = useSelector(state => state.account)

    return (plan.edit.hasOwnProperty(account.group) || purchase.edit.hasOwnProperty(account.group)) ? (
        <Grid item>
            <ButtonIcon
                disabled={!items.length || !!items.filter(el => {
                    if (el.purchase) {
                        if (purchase.edit[account.group].hasOwnProperty(el.purchase.status.index) && !el.purchase.lock) {
                            if (purchase.edit[account.group][el.purchase.status.index].length) {
                                return !purchase.edit[account.group][el.purchase.status.index].includes(el.purchase.editRequestStatus?.index)
                            }

                            return false
                        }

                        return true
                    } else {
                        if (plan.edit[account.group].hasOwnProperty(el.purchasePlan.status.index) && !el.purchasePlan.lock) {
                            if (plan.edit[account.group][el.purchasePlan.status.index].length) {
                                return !plan.edit[account.group][el.purchasePlan.status.index].includes(el.purchasePlan.editRequestStatus?.index)
                            }

                            return false
                        }

                        return true
                    }
                }).length}
                onClick={() => {
                    return dispatch(PurchasePlanItemsActions.deleteItems({
                        products: items.map(el => ({
                            id: el.id,
                            lot_item_id: el.purchase_lot_id,
                            purchase_plan_id: el.purchase_plan_id
                        }))
                    })).then(() => onClick(), () => {})
                }}
                title='Удалить'
                children={<DeleteIcon/>}
                className={classes.button}
            />
        </Grid>
    ) : null
}
