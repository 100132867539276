import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce} from 'use-debounce'
import format from "date-fns/format"
import {ru} from "date-fns/locale"
import {CustomPaging, IntegratedSelection, PagingState, SelectionState} from '@devexpress/dx-react-grid'
import {
    DragDropProvider,
    PagingPanel,
    Table,
    TableColumnReordering,
    TableColumnResizing,
    TableColumnVisibility,
    TableHeaderRow,
    TableSelection
} from '@devexpress/dx-react-grid-material-ui'

import {Grid, InputAdornment, makeStyles, Paper, Select, TextField, withStyles} from "@material-ui/core"
import {Alert} from '@material-ui/lab'
import {GetApp as GetAppIcon, Search as SearchIcon} from "@material-ui/icons"
import {green} from "@material-ui/core/colors"

import {Grid as GridTable} from "../../../App/components/Table/Grid"
import {Pager} from "../../../App/components/Table/Paging/Pager"
import {StickyTable} from "../../../App/components/Table/StikyTable"
import {ColumnChooser} from "../../../App/components/Table/ColumnChooser"
import {DictionaryActions} from "../../actions/standards"
import {TableHeaderContent} from "../Table/TableHeaderContent"
import {CategoriesTree} from "../../../App/components/Select/CategoriesTree"
import {SystemActions} from "../../../App/actions/system"
import {Submit} from "../../../App/components/Button/Submit"
import {StandardForm} from "../../../Standard/StandardForm"
import {ButtonIcon} from "../../../App/components/Button/ButtonIcon";
import {DownloadActions} from "../../../Download/actions/download";
import {StandardActions} from "../../../Standard/actions/standard";
import {AuthorizationService} from "../../../Auth/services/authorization";
import {SettingsActions} from "../../../Settings/actions/settings";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    filterLabel: {
        "background": "#fff",
        "margin-bottom": "0 !important",
        "margin-top": "5px !important",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        },
        "& input": {
            padding: "6px 15px 7px"
        }
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 196px)",
        "min-height": "calc(100% - 196px)",
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    footer: {
        width: "100%",
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "padding": "0 8px !important"
    },
    active: {
        'height': '41px',
        'background-color': green[100],
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    loading: {
        'height': '41px',
        'background-color': '#fff',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    submit: {
        minWidth: "215px",
        maxWidth: "215px",
    },
    iconButton: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    },
    disableDefaultAlert: {
        boxShadow: 'none !important',
        borderTop: 'none !important',
        borderBottom: 'none !important',
    },
    submitCategoryBtn: {
        "position": "sticky",
        "bottom": "10px",
        "width": "calc(100% - 20px) !important",
        "margin": "10px !important",
        '&.MuiButton-root': {
            "border-bottom": "none !important",
        },
    },
}))

const columns = [
    {name: 'id', title: 'ID'},
    {name: 'value.keyword', title: 'Эталон'},
    {name: 'category', title: 'Категория'},
    {name: 'status', title: 'Статус'},
    {name: 'created', title: 'Дата создания', filter: 'created'},
    {name: 'registry', title: 'Реестр'}
]

const StyledAlert = withStyles(() => ({
    message: {
        'padding': '0'
    },
}))(Alert)

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {account, categories} = useSelector(state => ({...state.account, ...state.system}))

    const [page, setPage] = useState(1)
    const [filter, setFilter] = useState({
        sort: {
            direction: null,
            name: null
        },
        status: [],
        category: null
    })
    const [standards, setStandards] = useState({
        data: [],
        meta: {
            total: 0, per_page: 50, current_page: 1
        }
    })
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [settings, setSettings] = useState([])
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)
    const [selection, setSelection] = useState([])
    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
    const [tableRef, setTableRef] = useState(null)
    const [category, setCategory] = useState({})
    const [openStandardForm, setOpenStandardForm] = useState(false)

    const [columnOrder, setColumnOrder] = useState([
        'id',
        'value.keyword',
        'category',
        'status',
        'created',
        'registry'
    ])

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'id', width: 150},
        {columnName: 'value.keyword', width: 300},
        {columnName: 'category', width: 300},
        {columnName: 'status', width: 200},
        {columnName: 'created', width: 200},
        {columnName: 'registry', width: 200}
    ])

    const getParams = () => {
        return {
            order: (filter.sort.name && filter.sort.direction) ? [filter.sort.name, filter.sort.direction] : ['confirmed', 'asc'],
            type: 'standard',
            ...(!!filter.status.length ? {status: filter.status} : []),
            ...(searchRequest ? {search: searchRequest} : {}),
            ...(((category && category.hasOwnProperty('id')) || filter.category) ? {category: category.hasOwnProperty('id') ? category.id : filter.category} : {})
        }
    }

    const getStandards = async () => {
        let params = {
            limit: rowsPerPage,
            page: page,
            ...getParams()
        }
        if (!categories.length) {
            await dispatch(SystemActions.categories())
        }

        const items = await dispatch(SystemActions.items(params, false))
        const register = await dispatch(StandardActions.register({ids: items.data.map(item => item.id)}))

        setStandards({
            ...items,
            data: items.data.map(item => {
                const el = register.find(el => (el.id === item.id))
                return {
                    ...item,
                    allowed: !!el?.allowed,
                    strategic: !!el?.strategic
                }
            })
        })
    }

    useEffect(() => {
        if (filter.hasOwnProperty('resetSelection') && filter.resetSelection) {
            setSelection([])
        }

        getStandards().then(() => {
            tableRef && tableRef.current && tableRef.current.scrollIntoView()
        })
        // eslint-disable-next-line
    }, [dispatch, page, rowsPerPage, searchRequest, filter])

    useEffect(() => {
        setCategory(filter.category ? categories.find(el => el.id === filter.category) : {})
        // eslint-disable-next-line
    }, [filter.category])

    useEffect(() => {
        const getSettings = async () => {
            return await dispatch(SettingsActions.getSettings('standard')).then(settings => {
                setSettings(settings)
            })
        }

        if (!loading) {
            getSettings().then(() => {
                setLoading(true)
            })
        }
        // eslint-disable-next-line
    }, [])

    const handlePageChange = newPage => setPage(newPage)
    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        setSelection([])
    }

    const tableSelectionRowComponent = (props) => {
        const {tableRow, children, highlighted, onToggle} = props;
        const {row} = tableRow;

        return (
            <Table.Row
                tableRow={tableRow}
                children={children}
                onClick={onToggle}
                className={highlighted ? classes.active : ((row.loaded === 'Загружен') ? classes.default : classes.loading)}
                row={row}
            />
        )
    }

    const getStatus = (item) => {
        if (!item.active) {
            if (item.confirmed) {
                return <StyledAlert
                    icon={false}
                    style={{
                        backgroundColor: 'rgba(244, 244, 244, 1)',
                        color: 'rgba(0, 0, 0, 0.87)',
                    }}
                    className={classes.disableDefaultAlert}
                >
                    Неактивный
                </StyledAlert>
            } else {
                return <StyledAlert icon={false} color="error" className={classes.disableDefaultAlert}>Не проверен</StyledAlert>
            }
        }

        return <StyledAlert icon={false} color="success" className={classes.disableDefaultAlert}>Активный</StyledAlert>
    }

    const onCloseSelect = () => {
        setFilter({
            ...filter,
            ...(category && category.hasOwnProperty('id') ? {category: category.id} : {category: null})
        })
    }

    const addToSelection = (selection, standards) => {
        let selectionStandards = standards.data.filter((el, idx) => selection.indexOf(idx) !== -1).map(el => el.id)
        return dispatch(DictionaryActions.addToSelection({
            standard_ids: selectionStandards
        })).then(_ => {})
    }

    const closeStandardForm = () => setOpenStandardForm(false)

    const onAddStandard = () => setOpenStandardForm(false)

    const onOpenStandardForm = () => setOpenStandardForm(true)

    return loading ? (
        <Grid item className={classes.content}>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item className={classes.footer}>
                    <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                        <Grid item className={classes.footerContent}>
                            <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                <Grid item xs={12} style={{paddingBottom: 0}}>
                                    <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                        <Grid item xs={2}>
                                            <Select
                                                fullWidth
                                                id="category"
                                                onClose={onCloseSelect}
                                                value={!!Object.keys(category).length ? category : ''}
                                                renderValue={(value) => value.hasOwnProperty('name') ? value.name : null}
                                            >
                                                <CategoriesTree
                                                    callback={value => {
                                                        if (value.id === category.id) {
                                                            setCategory({})
                                                        } else {
                                                            setCategory(value)
                                                            setSelection([])
                                                        }
                                                        dispatch({
                                                            type: 'SELECTED_CATEGORIES_SUCCESS', payload: [value.id]
                                                        })
                                                    }}
                                                    categories={categories}
                                                    category={category}
                                                />
                                                <Submit
                                                    className={classes.submitCategoryBtn}
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    onClick={onCloseSelect}
                                                >
                                                    Подтвердить
                                                </Submit>
                                            </Select>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                <Grid item>
                                                    {openStandardForm && <StandardForm
                                                        open={openStandardForm}
                                                        module={'Справочник эталонов'}
                                                        edit={true}
                                                        onCLose={closeStandardForm}
                                                        setOpen={setOpenStandardForm}
                                                        onAddStandard={(item) => onAddStandard(item)}
                                                    />}
                                                    <Submit
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        className={classes.submit}
                                                        onClick={onOpenStandardForm}
                                                    >
                                                        Предложить эталон
                                                    </Submit>
                                                </Grid>
                                                <Grid item>
                                                    <Submit
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        className={classes.submit}
                                                        onClick={() => addToSelection(selection, standards)}
                                                        disabled={!selection.length}
                                                    >
                                                        Добавить в отбор
                                                    </Submit>
                                                </Grid>
                                                {AuthorizationService.permissions(account, ['stock_management']) ? (
                                                    <React.Fragment>
                                                        <Grid item>
                                                            <Submit
                                                                variant="contained"
                                                                color="primary"
                                                                type="submit"
                                                                className={classes.submit}
                                                                onClick={() => {
                                                                    const ids = standards.data.filter((el, idx) => selection.indexOf(idx) !== -1).map(el => el.id)
                                                                    const allow = async () => {
                                                                        return await dispatch(StandardActions.allow({
                                                                            ids: ids,
                                                                            active: true
                                                                        }))
                                                                    }

                                                                    allow().then(() => {
                                                                        setStandards({
                                                                            ...standards,
                                                                            data: standards.data.map(item => {
                                                                                const el = ids.find(id => (id === item.id))

                                                                                return {
                                                                                    ...item,
                                                                                    allowed: !!(el ? true : item?.allowed)
                                                                                }
                                                                            })
                                                                        })
                                                                    })
                                                                }}
                                                                disabled={!selection.length}
                                                            >
                                                                Допустить в МП
                                                            </Submit>
                                                        </Grid>
                                                        <Grid item>
                                                            <Submit
                                                                variant="contained"
                                                                color="primary"
                                                                type="submit"
                                                                className={classes.submit}
                                                                onClick={() => {
                                                                    const ids = standards.data.filter((el, idx) => selection.indexOf(idx) !== -1).map(el => el.id)

                                                                    const strategic = async () => {
                                                                        dispatch(StandardActions.strategic({
                                                                            ids: ids,
                                                                            active: true
                                                                        }))
                                                                    }

                                                                    strategic().then(() => {
                                                                        setStandards({
                                                                            ...standards,
                                                                            data: standards.data.map(item => {
                                                                                const el = ids.find(id => (id === item.id))

                                                                                return {
                                                                                    ...item,
                                                                                    strategic: !!(el ? true : item?.strategic)
                                                                                }
                                                                            })
                                                                        })
                                                                    })
                                                                }}
                                                                disabled={!selection.length}
                                                            >
                                                                Стратегически важный
                                                            </Submit>
                                                        </Grid>
                                                    </React.Fragment>
                                                ) : null}
                                                <Grid item>
                                                    <ButtonIcon
                                                        type="button"
                                                        size="small"
                                                        disabled={!Object.keys(category).length}
                                                        onClick={() => {
                                                            dispatch(DownloadActions.create({
                                                                type: 'standards',
                                                                columns: columnOrder.filter(name => (hiddenColumnNames.indexOf(name) < 0)),
                                                                params: {
                                                                    order: (filter.sort.name && filter.sort.direction) ? `${filter.sort.name}, ${filter.sort.direction}` : `confirmed, asc`,
                                                                    type: 'standard',
                                                                    ...(!!Object.keys(category).length ? {category: category.id} : {}),
                                                                    ...(search ? {search: search} : {}),
                                                                    ...(!!filter.status.length ? {status: filter.status.join(',')} : [])
                                                                }
                                                            }))
                                                        }}
                                                        children={<GetAppIcon/>}
                                                        title='Скачать таблицу'
                                                        className={classes.iconButton}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <ColumnChooser
                                                        columns={columns}
                                                        hiddenColumnNames={hiddenColumnNames}
                                                        setHiddenColumnNames={setHiddenColumnNames}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{paddingTop: "4px"}}>
                                    <TextField
                                        id="search"
                                        variant="standard"
                                        name={'search'}
                                        placeholder={`Поиск: наименование эталона`}
                                        fullWidth={true}
                                        margin={'normal'}
                                        onChange={(event) => {
                                            setSearch(event.target.value)
                                        }}
                                        classes={{
                                            root: classes.filterLabel
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchIcon style={{color: '#c1c1c1'}}/>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Grid container direction="row" alignItems="center">
                            <Grid item className={classes.inputContent}>
                                <GridTable
                                    rows={standards.data.map(standard => {
                                        const type = settings.categories.find(el => el.category.id === standard.category?.index)?.type

                                        const data = {
                                            id: standard.id,
                                            'value.keyword': standard.name,
                                            category: standard.category && (standard.category.name + ' (' + categories.find(el => el.id === standard.category.index)?.name + ')'),
                                            status: getStatus(standard),
                                            created: format(new Date(standard.created), 'H:mm PP', {locale: ru}),
                                            registry: [{key: 'allowed', name: 'Допущен в МП'}, {key: 'strategic', name: 'Стратегически важный'}].filter(item => standard[item.key]).map(item => {
                                                return <StyledAlert icon={false} color="success" className={classes.disableDefaultAlert}>{item.name}</StyledAlert>
                                            })
                                        }

                                        switch (type) {
                                            case 'typed':
                                            case 'arbitrary':
                                                data['value.keyword'] = standard.assembly.find(el => el.type.key === type)?.value ?? standard.name;
                                        }

                                        return data
                                    })}
                                    columns={columns}
                                >
                                    <PagingState
                                        currentPage={page}
                                        onCurrentPageChange={handlePageChange}
                                        pageSize={rowsPerPage}
                                        onPageSizeChange={handlePageSizeChange}
                                    />
                                    <CustomPaging
                                        totalCount={standards.meta.total}
                                    />
                                    <SelectionState
                                        selection={selection}
                                        onSelectionChange={(numbers) => {
                                            setSelection(Array.from(new Set(numbers)))
                                        }}
                                    />
                                    <DragDropProvider/>
                                    <Table
                                        noDataCellComponent={() => {
                                            return null;
                                        }}
                                        tableComponent={props => <StickyTable {...props} setTableRef={setTableRef}/>}
                                        rowComponent={({row, tableRow, children}) => (
                                            <Table.Row
                                                tableRow={tableRow}
                                                children={children}
                                                className={classes.default}
                                                row={row}
                                                color="black"
                                            />
                                        )}
                                    />
                                    <TableColumnReordering
                                        order={columnOrder}
                                        onOrderChange={setColumnOrder}
                                    />
                                    <TableColumnResizing
                                        columnWidths={columnWidths}
                                        onColumnWidthsChange={setColumnWidths}
                                    />
                                    <TableHeaderRow
                                        contentComponent={(props) => <TableHeaderContent {...props} filter={filter}
                                                                                         setFilter={setFilter}/>}
                                    />
                                    <TableColumnVisibility
                                        hiddenColumnNames={hiddenColumnNames}
                                        onHiddenColumnNamesChange={setHiddenColumnNames}
                                    />
                                    <IntegratedSelection/>
                                    <TableSelection
                                        showSelectAll
                                        selectByRowClick
                                        showSelectionColumn={true}
                                        highlightRow
                                        rowComponent={tableSelectionRowComponent}
                                    />
                                    <PagingPanel
                                        containerComponent={Pager}
                                        messages={{showAll: 'Все', rowsPerPage: 'Записей на странице:', info: '{from} - {to} из {count}'}}
                                        pageSizes={[50, 100, 200]}
                                    />
                                </GridTable>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    ) : null
}
