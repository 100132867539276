import {CustomerService} from "../services/customer"

export const CustomerActions = {
    territories,
    territory,
    add,
    edit,
    remove,
    marketplaceSettings,
    editMarketplaceSettings
}

function territories(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CUSTOMER_TERRITORIES_REQUEST"})

        CustomerService.territories(params)
            .then(
                (territories) => {
                    dispatch({type: "CUSTOMER_TERRITORIES_SUCCESS", payload: territories})
                    dispatch({type: "LOADING", payload: false})
                    resolve(territories)
                },
                error => {
                    dispatch({type: "CUSTOMER_TERRITORIES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}


function territory(id, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CUSTOMER_TERRITORY_REQUEST"})

        CustomerService.territory(id, params)
            .then(
                territory => {
                    dispatch({type: "CUSTOMER_TERRITORY_SUCCESS", payload: territory})
                    dispatch({type: "LOADING", payload: false})
                    resolve(territory)
                },
                error => {
                    dispatch({type: "CUSTOMER_TERRITORY_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function add(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CUSTOMER_ADD_TERRITORY_REQUEST"})

        CustomerService.add(id, values)
            .then(
                response => {
                    dispatch({type: "CUSTOMER_ADD_TERRITORY_SUCCESS", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "CUSTOMER_ADD_TERRITORY_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function edit(company_id, territory_id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CUSTOMER_EDIT_TERRITORY_REQUEST"})

        CustomerService.edit(company_id, territory_id, values)
            .then(
                response => {
                    dispatch({type: "CUSTOMER_EDIT_TERRITORY_SUCCESS", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "CUSTOMER_EDIT_TERRITORY_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function remove(company_id, territory_id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CUSTOMER_DELETE_TERRITORY_REQUEST"})

        CustomerService.remove(company_id, territory_id)
            .then(
                () => {
                    dispatch({type: "CUSTOMER_DELETE_TERRITORY_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "CUSTOMER_DELETE_TERRITORY_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function marketplaceSettings(companyId) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CUSTOMER_MARKETPLACE_SETTINGS_REQUEST"})

        CustomerService.marketplaceSettings(companyId)
            .then(
                response => {
                    dispatch({type: "CUSTOMER_MARKETPLACE_SETTINGS_SUCCESS", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "CUSTOMER_MARKETPLACE_SETTINGS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function editMarketplaceSettings(companyId, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CUSTOMER_EDIT_MARKETPLACE_SETTINGS_REQUEST"})

        CustomerService.editMarketplaceSettings(companyId, values)
            .then(
                response => {
                    dispatch({type: "CUSTOMER_EDIT_MARKETPLACE_SETTINGS_SUCCESS", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "CUSTOMER_EDIT_MARKETPLACE_SETTINGS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
