import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {Field, FieldArray, Form, Formik} from "formik"
import * as Yup from "yup"

import {
    Grid,
    makeStyles,
    Paper,
    Typography,
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    Table, TableHead, TableRow, TableCell, Tooltip, IconButton, TableBody, TableContainer, TextField
} from "@material-ui/core"

import {SettingsActions} from "../../actions/settings"
import {Submit} from "../../../App/components/Button/Submit"
import {TextFieldWithError as CustomTextField} from "../../../App/components/Inputs/TextFieldWithError"
import {Switch} from "formik-material-ui";
import DeleteSweepOutlinedIcon from "@material-ui/icons/DeleteSweepOutlined";
import {Clear} from "@material-ui/icons";
import {CompanyActions} from "../../../Company/actions/company";
import {Autocomplete} from "@material-ui/lab";
import {useDebounce} from "use-debounce";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%"
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 111px)"
    },
    input: {
        "width": "100%",
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100% - 54px)",
        "min-height": "calc(100% - 54px)",
        "padding": "20px 30px 0",
        "overflow": "auto"
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    select: {
        "height": "43px"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    tableRowAlert: {
        "cursor": "pointer",
        "background-color": "#f9f1f0",
        "text-decoration": "none"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "18px !important"
    },
    params: {
        height: "calc(100vh - 231px)",
        overflow: "auto",
    },
    border: {
        "border-bottom": "35px solid #c1c1c1 !important"
    }
}))

export const Content = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [loading, setLoading] = useState(false)
    const [settings, setSettings] = useState({})

    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)
    const [customers, setCustomers] = useState([])

    useEffect(() => {
        const getSettings = async () => {
            return await dispatch(SettingsActions.getSettings('contract'))
        }

        if (!loading) {
            getSettings().then(settings => {
                setSettings(settings)
                setLoading(true)
            })
        }
    }, [dispatch, loading]);

    useEffect(() => {
        const getCustomers = async () => {
            return await dispatch(CompanyActions.customers({
                search: searchRequest
            }))
        }

        if (searchRequest) {
            getCustomers().then(response => setCustomers(response.data))
        }
    }, [dispatch, searchRequest]);

    return loading ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.fullWidth}>
                        <Grid container className={classes.container} direction="column" justify="space-between" alignItems="stretch">
                            <Formik
                                initialValues={settings}
                                validationSchema={Yup.object().shape({
                                    maximum_deviation: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                    additional_agreements: Yup.object().shape({
                                        common: Yup.boolean().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                        request: Yup.boolean().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                    }),
                                    specification_editing: Yup.object().shape({
                                        add: Yup.boolean().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                        delete: Yup.boolean().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                        price: Yup.object().shape({
                                            increase: Yup.boolean().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                            reduction: Yup.boolean().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                        }),
                                        amount: Yup.object().shape({
                                            increase: Yup.boolean().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                            reduction: Yup.boolean().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                        }),
                                    }),
                                    condition_execution_contract: Yup.object().shape({
                                        common: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
                                        customers: Yup.array()
                                    })
                                })}
                                onSubmit={(values, {setSubmitting}) => {
                                    return dispatch(SettingsActions.setSettings(
                                        'contract',
                                        {
                                            ...values,
                                            ...{condition_execution_contract: {
                                                    common: values.condition_execution_contract.common,
                                                    customers: values.condition_execution_contract.customers.map(item => ({customer: {id: item.customer.id}, condition: item.condition}))
                                                }}
                                        })
                                    ).then(
                                        () => {
                                            setSubmitting(false)
                                        },
                                        () => {
                                            setSubmitting(false)
                                        }
                                    )
                                }}
                            >
                                {({
                                      values,
                                      setValues,
                                      isSubmitting
                                  }) => (
                                    <Form className={classes.fullWidth}>
                                        <Grid item className={classes.inputContent}>
                                            <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                                <Grid item className={classes.header}>
                                                    <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                                        <Grid item className={classes.fullWidth}>
                                                            <Typography className={classes.title}>
                                                                Настройки договоров
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.params} direction="row" justify="start" alignItems="stretch" spacing={2}>
                                                <Grid item xs={3}>
                                                    <Grid container direction="column" justify="start" alignItems="stretch" spacing={4}>
                                                        <Grid item>
                                                            <FormControl>
                                                                <FormLabel>Разрешить/запретить доп. соглашения</FormLabel>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Field
                                                                                disabled
                                                                                component={Switch}
                                                                                name="additional_agreements.common"
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label="Для простых договоров"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Field
                                                                                component={Switch}
                                                                                name="additional_agreements.request"
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label="Для договоров по ЦЗ"
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item>
                                                            <FormControl>
                                                                <FormLabel>Разрешить при редактировании спецификации</FormLabel>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Field
                                                                                component={Switch}
                                                                                name="specification_editing.add"
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label="Добавление номенклатуры"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Field
                                                                                component={Switch}
                                                                                name="specification_editing.delete"
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label="Удаление номенклатуры"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Field
                                                                                component={Switch}
                                                                                name="specification_editing.price.increase"
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label="Увеличение цены"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Field
                                                                                component={Switch}
                                                                                name="specification_editing.price.reduction"
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label="Уменьшение цены"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Field
                                                                                component={Switch}
                                                                                name="specification_editing.amount.increase"
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label="Увеличение количества"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Field
                                                                                component={Switch}
                                                                                name="specification_editing.amount.reduction"
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label="Уменьшение количества"
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item>
                                                            <Field
                                                                fullWidth
                                                                name="maximum_deviation"
                                                                type="number"
                                                                label="Максимальное отклонение суммы договора %"
                                                                required
                                                                inputProps={{
                                                                    min: 0,
                                                                    max: 100,
                                                                    step: 0.01
                                                                }}
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid container direction="column" justify="start" alignItems="stretch" spacing={2}>
                                                        <Grid item>
                                                            <FormControl fullWidth>
                                                                <FormLabel>Условия исполнения договора</FormLabel>
                                                                <FormGroup>
                                                                    <Grid container direction="column" justify="start" alignItems="stretch" spacing={2}>
                                                                        <Grid item xs={6}>
                                                                            <Field
                                                                                fullWidth
                                                                                name="condition_execution_contract.common"
                                                                                type="number"
                                                                                label="Общее (для всех ЧУЗ)"
                                                                                required
                                                                                inputProps={{
                                                                                    min: 0,
                                                                                    max: 100,
                                                                                    step: 0.01
                                                                                }}
                                                                                component={CustomTextField}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Autocomplete
                                                                                required
                                                                                options={customers.map(customer => ({id: customer.id, name: customer.legal_detail.name}))}
                                                                                value={null}
                                                                                onChange={(e, option)=> {
                                                                                    if (option) {
                                                                                        if (!values.condition_execution_contract.customers.find(item => item.customer.id === option.id)) {
                                                                                            setValues({
                                                                                                ...values,
                                                                                                ...{condition_execution_contract: {
                                                                                                        common: values.condition_execution_contract.common,
                                                                                                        customers: [
                                                                                                            ...values.condition_execution_contract.customers,
                                                                                                            ...[{customer: option, condition: 0}]
                                                                                                        ]
                                                                                                    }}
                                                                                            })
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                onBlur={() => {
                                                                                    setSearch(null)
                                                                                    setCustomers([])
                                                                                }}
                                                                                renderOption={(option) => (
                                                                                    <Grid>{option.name}</Grid>
                                                                                )}
                                                                                noOptionsText='Нет доступных вариантов'
                                                                                getOptionLabel={(option) => option.name ?? ''}
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        {...params}
                                                                                        variant="standard"
                                                                                        label="Выбрать ЧУЗ"
                                                                                        onChange={(e) => {
                                                                                            setSearch(e.target.value)
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <FieldArray name="condition_execution_contract.customers" render={ arrayHelpers => (
                                                                                <TableContainer>
                                                                                    <Table stickyHeader>
                                                                                        <TableHead>
                                                                                            <TableRow>
                                                                                                <TableCell>ЧУЗ</TableCell>
                                                                                                <TableCell>Исполнение договора (%) *</TableCell>
                                                                                                <TableCell align="right">
                                                                                                    <Tooltip
                                                                                                        title="Удалить все выбранные ЧУЗ"
                                                                                                        placement="right"
                                                                                                        classes={{tooltip: classes.lightTooltip}}
                                                                                                    >
                                                                                                        <IconButton
                                                                                                            style={{
                                                                                                                "width": "24px",
                                                                                                                "height": "24px",
                                                                                                                "padding": "0"
                                                                                                            }}
                                                                                                            onClick={() => {
                                                                                                                setValues({
                                                                                                                    ...values,
                                                                                                                    ...{condition_execution_contract: {
                                                                                                                            common: values.condition_execution_contract.common,
                                                                                                                            customers: []
                                                                                                                        }}
                                                                                                                })
                                                                                                            }}
                                                                                                        >
                                                                                                            <DeleteSweepOutlinedIcon
                                                                                                                color="error"
                                                                                                            />
                                                                                                        </IconButton>
                                                                                                    </Tooltip>
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>
                                                                                            {values.condition_execution_contract.customers.map((option, index) => (
                                                                                                <TableRow key={index}>
                                                                                                    <TableCell component="th" scope="row">
                                                                                                        {option.customer.name}
                                                                                                    </TableCell>
                                                                                                    <TableCell>
                                                                                                        <Field
                                                                                                            fullWidth
                                                                                                            name={`condition_execution_contract.customers.${index}.condition`}
                                                                                                            type="number"
                                                                                                            inputProps={{
                                                                                                                min: 0,
                                                                                                                max: 100,
                                                                                                                step: 0.01
                                                                                                            }}
                                                                                                            component={CustomTextField}
                                                                                                        />
                                                                                                    </TableCell>
                                                                                                    <TableCell align="right">
                                                                                                        <IconButton
                                                                                                            size={"small"}
                                                                                                            onClick={() => {
                                                                                                                arrayHelpers.remove(index)
                                                                                                            }}
                                                                                                        >
                                                                                                            <Clear />
                                                                                                        </IconButton>
                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            ))}
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </TableContainer>
                                                                            )}/>
                                                                        </Grid>
                                                                    </Grid>
                                                                </FormGroup>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.footer}>
                                            <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                                <Grid item className={classes.footerContent}>
                                                    <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                        <Grid item>
                                                            <Submit
                                                                disableElevation
                                                                variant="contained"
                                                                color="primary"
                                                                type="submit"
                                                                disabled={isSubmitting}
                                                            >
                                                                Сохранить
                                                            </Submit>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    ) : null
}
