import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce} from 'use-debounce'
import {
    IntegratedSummary,
} from '@devexpress/dx-react-grid'

import {
    Grid,
    InputAdornment,
    makeStyles,
    TextField,
} from "@material-ui/core"
import {
    Search as SearchIcon, GetApp as GetAppIcon
} from "@material-ui/icons"
import {green} from "@material-ui/core/colors"
import {Table} from "@devexpress/dx-react-grid-material-ui";

import {day, getMonthName} from "../../../../App/helpers/date"
import {ColumnChooser} from "../../../../App/components/Table/ColumnChooser"
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon"
import {LotActions} from "../../../actions/lot"
import {DownloadActions} from "../../../../Download/actions/download";
import {CustomTable} from "../../../../App/components/Table/Table";
import {TableHeader} from "../../../../App/components/Table/TableHeader";
import {Create as CreateButton} from "../../Lot/Buttons/Create";
import {Edit as EditButton} from "../../Lot/Buttons/Edit";
import {Create as CreateProductButton} from "../../Lot/Buttons/Product/Create";
import {Delete} from "../../Lot/Buttons/Delete";
import {localState} from "../../../../App/components/LocalState";
import {SettingsActions} from "../../../../Settings/actions/settings";

const useStyles = makeStyles(() => ({
    filterLabel: {
        "background": "#fff",
        "margin-bottom": "0 !important",
        "margin-top": "5px !important",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        },
        "& input": {
            padding: "6px 15px 7px"
        }
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 414px)",
        "min-height": "calc(100vh - 414px)",
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    submit: {
        "height": "35px",
    },
    active: {
        'height': '41px',
        'background-color': `${green[100]} !important`,
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    disabled: {
        'height': '41px',
        'background-color': 'rgba(244, 244, 244, 1)',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    loading: {
        'height': '41px',
        'background-color': '#fff',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },

        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    iconButton: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    }
}))

const purchaseType = {
    complete: 'Предоплата',
    partial: 'Частичная предоплата',
    postponement: 'Постоплата'
}
const columns = [
    {name: 'name', title: 'Наименование лота'},
    {name: 'number', title: 'Номер'},
    {name: 'purchase_type', title: 'Тип оплаты', filter: 'types'},
    {name: 'category', title: 'Категория', filter: 'categories'},
    {name: 'prepayment', title: 'Аванс'},
    {name: 'funding_sources', title: 'Источник финансирования'},
    {name: 'postponement', title: 'Отсрочка'},
    {name: 'execution_month', title: 'Месяц исполнения договора', filter: 'month'},
    {name: 'start_execution_month', title: 'Планируемый месяц начала исполнения договора', filter: 'start_month'},
    {name: 'items_count', title: 'Количество ОЗ'},
    {name: 'total', title: 'НМЦ'},
    {name: 'railRoad', title: 'ЖД', filter: 'rail_roads'},
    {name: 'company', title: 'ЧУЗ', filter: 'companies'},
]

export const Lots = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {purchaseID} = useParams()
    const {purchase} = useSelector(state => ({...state.purchasePlans, ...state.account, ...state.purchases}))
    const {filter} = useSelector(state => state.filters['planning_purchase_plan_purchase_lots'])
    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
    const [lots, setLots] = useState({...localState,
        agg_info: {
            total_amount: 0,
            total_sum: 0,
        }
    })

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)

    const [selection, setSelection] = useState([])
    const [selectionItems, setSelectionItems] = useState([])
    const [selectionPage, setSelectionPage] = useState({})

    const {
        totalSummaryItems,
        tableColumnExtensions,
        setColumnWidths,
        columnWidths,
        tableRef,
        setTableRef,
        messages
    } = props

    const [initialize, setInitialize] = useState(false)
    const [loading, setLoading] = useState(false)

    const [columnOrder, setColumnOrder] = useState([
        'name',
        'number',
        'purchase_type',
        'category',
        'prepayment',
        'funding_sources',
        'postponement',
        'start_execution_month',
        'execution_month',
        'items_count',
        'total',
        'railRoad',
        'company'
    ])

    useEffect(() => {
        const getSettings = async () => {
            await dispatch(SettingsActions.table('planning_purchase_plan_purchase_lots')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
            })
        }

        if (!initialize) {
            getSettings().then(() =>
                setInitialize(true)
            )
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'planning_purchase_plan_purchase_lots',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    const getLotsParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...((filter.hasOwnProperty('companies') && !!filter.companies.length) ? {companies: filter.companies} : {}),
            ...((filter.hasOwnProperty('rail_roads') && !!filter.rail_roads.length) ? {rail_roads: filter.rail_roads} : {}),
            ...((filter.hasOwnProperty('categories') && !!filter.categories.length) ? {categories: filter.categories} : {}),
            ...((filter.hasOwnProperty('types') && !!filter.types.length) ? {purchase_types: filter.types} : {}),
            ...((filter.hasOwnProperty('start_month') && !!filter.start_month.length) ? {start_execution_month: filter.start_month} : {}),
            ...((filter.hasOwnProperty('month') && !!filter.month.length) ? {execution_month: filter.month} : {}),
            ...((filter.hasOwnProperty('sources') && !!filter.sources.length) ? {sources: filter.sources} : {}),
            ...(searchRequest ? {search: searchRequest} : {}),
        }
    }

    const getLots = async () => {
        let params = {
            limit: rowsPerPage,
            page: page,
            ...getLotsParams(),
        }
        const response = await dispatch(LotActions.lotsInPurchase(purchaseID, params))

        let numbers = []

        if (selectionPage.hasOwnProperty(page)) {
            response.data.forEach((item, index) => {
                if (selectionPage[page].find(id => (id === item.id))) {
                    numbers.push(index)
                }
            })
        }

        setSelection(numbers)

        setLots(response)
    }

    useEffect(() => {
        if (!loading) {
            getLots().then(() => {
                setLoading(true)
                tableRef && tableRef.current && tableRef.current.scrollIntoView()
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loading])

    useEffect(() => {
        if (loading) {
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [page, rowsPerPage, searchRequest, filter])

    const summaryCalculatorLots = (type, rows, getValue) => {
        switch (type) {
            case 'total_amount':
                return (lots.agg_info.total_amount ? parseFloat(lots.agg_info.total_amount) : 0).toLocaleString('ru-RU');
            case 'total_sum':
                return (lots.agg_info.total_sum ? parseFloat(lots.agg_info.total_sum) : 0).toLocaleString('ru-RU', {
                    style: 'currency',
                    currency: 'RUB'
                });
            default:
                return IntegratedSummary.defaultCalculator(type, rows, getValue);
        }
    };

    const handleLotPageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: 'PLANNING_PURCHASE_PLAN_PURCHASE_LOTS_FILTER', payload: {limitRows: newRowsPerPage}})
    }

    return <React.Fragment>
        <Grid item xs={12}>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                        <EditButton
                            disabled={(selectionItems.length !== 1)}
                            purchase={purchase}
                            lot={selectionItems[0]}
                        />
                        <CreateButton
                            purchase={purchase}
                        />
                        <CreateProductButton
                            disabled={(selectionItems.length !== 1)}
                            purchase={purchase}
                            lot={selectionItems[0]}
                        />
                        <Delete
                            items={selectionItems}
                            onClick={() => {
                                setSelection([])
                                setSelectionItems([])
                                setSelectionPage({})
                                setLoading(false)
                            }}
                        />
                        <Grid item>
                            <ButtonIcon
                                type="button"
                                size="small"
                                disabled={!lots.meta.total}
                                onClick={() => {
                                    dispatch(DownloadActions.create({
                                        type: 'planning_purchase_lots',
                                        columns: columnOrder.filter(name => (hiddenColumnNames.indexOf(name) < 0)),
                                        params: {
                                            order: (filter.sort.name && filter.sort.direction) ? `${filter.sort.name}, ${filter.sort.direction}` : `created, desc`,
                                            type: 'planning_purchase_lots',
                                            'purchase_id': purchaseID,
                                            ...Object.entries(getLotsParams()).reduce((result, [key, value]) => {
                                                result[key] = Array.isArray(value) ? value.join(',') : value

                                                return result
                                            }, {})
                                        }
                                    }))
                                }}
                                children={<GetAppIcon/>}
                                title='Скачать таблицу'
                                className={classes.iconButton}
                            />
                        </Grid>
                        <Grid item>
                            <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="search"
                        variant="standard"
                        name={'search'}
                        placeholder={`Поиск: наименование, номер лота`}
                        fullWidth={true}
                        margin={'normal'}
                        onChange={(event) => {
                            setSearch(event.target.value)
                        }}
                        classes={{
                            root: classes.filterLabel
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon style={{color: '#c1c1c1'}}/>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
        <CustomTable
            meta={lots.meta}
            tableName={'planning_purchase_plan_purchase_lots'}
            rows={lots.data.map(lot => ({
                name: lot.name,
                number: lot.number,
                purchase_type: purchaseType[lot.purchase_type],
                category: lot.category.name,
                prepayment: lot.prepayment !== null ? lot.prepayment + ' %' : '',
                funding_sources: lot.funding_sources ? JSON.parse(lot.funding_sources).join(', ') : '',
                postponement: lot.postponement !== null ? day(lot.postponement, ', ') : '',
                start_execution_month: getMonthName(new Date(lot.start_execution_month)),
                execution_month: getMonthName(new Date(lot.execution_month)),
                items_count: lot.items_count ? parseFloat(lot.items_count).toLocaleString('ru-RU') : 0,
                total: lot.average_price ? parseFloat(lot.average_price).toLocaleString('ru-RU', {
                    style: 'currency',
                    currency: 'RUB'
                }) : 0,
                railRoad: lot.purchase.company.railRoad?.name,
                company: lot.purchase.company.name
            }))}
            columns={columns}
            page={{page: page, setPage: setPage, rowsPerPage: rowsPerPage, handlePageSizeChange: handleLotPageSizeChange}}
            selection={{selection: selection, setSelection: setSelection}}
            totalSummaryItems={totalSummaryItems}
            summaryCalculator={summaryCalculatorLots}
            setTableRef={setTableRef}
            tableColumnExtensions={tableColumnExtensions}
            columnsSettings={{columnOrder: columnOrder, setColumnOrder: setColumnOrder, setColumnWidths: setColumnWidths, columnWidths: columnWidths, hiddenColumnNames: hiddenColumnNames, setHiddenColumnNames: setHiddenColumnNames}}
            tableHeader={TableHeader}
            filterActionType={'PLANNING_PURCHASE_PLAN_PURCHASE_LOTS_FILTER'}
            getValues={LotActions.lotsFilter}
            onSelectionChange={(numbers) => {
                setSelection(numbers)
                const data = lots.data.filter((item, index) => (numbers.find(key => (key === index)) !== undefined)).map(item => item)
                setSelectionItems([
                    ...data,
                    ...selectionItems.filter(item => !lots.data.find(el => el.id === item.id))
                ])
                setSelectionPage(!!data.length ? {...selectionPage, [page]: data.map(item => item.id)} : Object.fromEntries(
                    Object.entries(selectionPage).filter(key => (parseInt(key) !== parseInt(page))).map(([key, value]) => [key, value])
                ))
            }}
            messages={messages}
            classInputContent={classes.inputContent}
            rowComponentSelection={(props) => {
                const {tableRow, children, highlighted, onToggle} = props
                const {row} = tableRow

                return (
                    <Table.Row
                        tableRow={tableRow}
                        children={children}
                        onClick={() => {
                            if (!window.getSelection().toString()) {
                                onToggle()
                            }
                        }}
                        className={highlighted ? classes.active : ((row.loaded === 'Загружен') ? classes.default : classes.loading)}
                        row={row}
                    />
                )
            }}
            rowComponent={({row, tableRow, children}) => (
                <Table.Row
                    tableRow={tableRow}
                    children={children}
                    className={classes.default}
                    row={row}
                />
            )}
            filters={{
                category: {
                    name: 'categories',
                    type: 'values',
                },
                railRoad: {
                    name: 'rail_roads',
                    type: 'values',
                },
                company: {
                    name: 'companies',
                    type: 'values',
                },
                start_execution_month: {
                    name: 'start_month',
                    type: 'month',
                },
                execution_month: {
                    name: 'month',
                    type: 'month',
                },
                funding_sources: {
                    name: 'sources',
                    type: 'source',
                },
                purchase_type: {
                    name: 'types',
                    type: 'values',
                }
            }}
        />
    </React.Fragment>
}
