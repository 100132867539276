import {history} from "../../App/helpers/history"
import {AuthService} from "../services/auth"
import * as AppStorage from "../../App/helpers/storage"

export const AuthActions = {
    login,
    recovery,
    registration,
    permissions,
    roles,
    logout
}

function login(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "CLEAR"})
        dispatch({type: "AUTH_LOGIN_REQUEST"})
        dispatch({type: "LOADING", payload: true})

        AuthService.login(values)
            .then(
                response => {
                    dispatch({type: "AUTH_LOGIN_SUCCESS"})
                    dispatch({type: "ACCOUNT", payload: response.account})
                    dispatch({type: "LOADING", payload: false})

                    const redirect = AppStorage.get('redirect')
                    AppStorage.remove('redirect')
                    if (redirect) {
                        history.push(redirect)
                    }
                },
                error => {
                    dispatch({type: "AUTH_LOGIN_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error.message)
                }
            )
    })
}

function recovery(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "AUTH_RECOVERY_REQUEST"})
        dispatch({type: "LOADING", payload: true})

        AuthService.recovery(values)
            .then(
                () => {
                    dispatch({type: "AUTH_RECOVERY_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_SUCCESS", payload: {
                            message: 'Инструкция по восстановлению пароля направлена на электронную почту.',
                            type: 'success'
                        }
                    })
                    history.push("/login")
                },
                error => {
                    dispatch({type: "AUTH_RECOVERY_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function registration(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "CLEAR"})
        dispatch({type: "AUTH_REGISTRATION_REQUEST"})
        dispatch({type: "LOADING", payload: true})

        AuthService.registration(values)
            .then(
                account => {
                    dispatch({type: "AUTH_REGISTRATION_SUCCESS"})
                    dispatch({type: "ACCOUNT", payload: account})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_SUCCESS", payload: {
                            message: 'Регистрация прошла успешно!',
                            type: 'success'
                        }
                    })
                    history.push("/account")
                    history.go("/login")
                },
                error => {
                    if (error.hasOwnProperty('errors') && error.errors.hasOwnProperty('login')) {
                        dispatch({
                            type: "ALERT_ERROR", payload: {
                                message: error.errors.login[0],
                                type: 'error'
                            }
                        })
                    } else {
                        dispatch({
                            type: "ALERT_ERROR", payload: {
                                message: error.message,
                                type: 'error'
                            }
                        })
                    }

                    dispatch({type: "AUTH_REGISTRATION_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error.message)
                }
            )
    })
}

function permissions() {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "AUTH_PERMISSIONS_REQUEST"})
        dispatch({type: "LOADING", payload: true})

        AuthService.permissions()
            .then(
                permissions => {
                    dispatch({type: "AUTH_PERMISSIONS_SUCCESS", payload: permissions})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    dispatch({type: "AUTH_PERMISSIONS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error.message)
                }
            )
    })
}

function roles() {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "AUTH_ROLES_REQUEST"})
        dispatch({type: "LOADING", payload: true})

        AuthService.roles()
            .then(
                roles => {
                    dispatch({type: "AUTH_ROLES_SUCCESS", payload: roles})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    dispatch({type: "AUTH_ROLES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error.message)
                }
            )
    })
}

function logout() {
    return dispatch => new Promise(() => {
        dispatch({type: "CLEAR"})
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "AUTH_LOGOUT_REQUEST"})

        if (AppStorage.get('redirect') === '/logout') {
            AppStorage.remove('redirect')
        }

        AppStorage.remove('account')

        AuthService.logout().then(() => {
            dispatch({type: "AUTH_LOGOUT_SUCCESS"});
            AppStorage.clear()
            history.push("/login")
            dispatch({type: "PROCESS"})
        })
    })
}
