import {ScheduleService} from "../services/schedule"

export const ScheduleActions = {
    get,
    schedule,
    add,
    edit,
    remove,
    categories,
    filter
}

function get(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PLANNING_SCHEDULES_REQUEST"})

        ScheduleService.get(params)
            .then(
                schedules => {
                    dispatch({type: "PLANNING_SCHEDULES_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(schedules)
                },
                error => {
                    dispatch({type: "PLANNING_SCHEDULES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR",
                        payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function schedule(id, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PLANNING_SCHEDULE_REQUEST"})

        ScheduleService.schedule(id, params)
            .then(
                schedule => {
                    dispatch({type: "PLANNING_SCHEDULE_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(schedule)
                },
                error => {
                    dispatch({type: "PLANNING_SCHEDULE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR",
                        payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function add(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PLANNING_ADD_SCHEDULE_REQUEST"})

        ScheduleService.add(values)
            .then(
                schedule => {
                    dispatch({type: "PLANNING_ADD_SCHEDULE_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(schedule)
                },
                error => {
                    dispatch({type: "PLANNING_ADD_SCHEDULE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR",
                        payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function edit(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PLANNING_EDIT_SCHEDULE_REQUEST"})

        ScheduleService.edit(id, values)
            .then(
                schedule => {
                    dispatch({type: "PLANNING_EDIT_SCHEDULE_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(schedule)
                },
                error => {
                    dispatch({type: "PLANNING_EDIT_SCHEDULE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR",
                        payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function remove(ids) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PLANNING_SCHEDULE_DELETE_REQUEST"})

        ScheduleService.remove(ids)
            .then(
                _ => {
                    dispatch({type: "PLANNING_SCHEDULE_DELETE_SUCCESS"})
                    dispatch({
                        type: "ALERT_SUCCESS",
                        payload: {
                            message:'Общие план-графики успешно удалены',
                            type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PLANNING_SCHEDULE_DELETE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR",
                        payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function categories(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PLANNING_SCHEDULE_CATEGORIES_REQUEST"})

        ScheduleService.categories(params)
            .then(
                categories => {
                    dispatch({type: "PLANNING_SCHEDULE_CATEGORIES_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(categories)
                },
                error => {
                    dispatch({type: "PLANNING_SCHEDULE_CATEGORIES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR",
                        payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function filter(params) {
    return dispatch => new Promise((resolve, reject) => {
        ScheduleService.filter(params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    reject(error)
                }
            )
    })
}

