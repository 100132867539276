import React, {useState} from "react"

import {Token} from "../Token";
import {Edit as EditIcon} from "@material-ui/icons";
import {IconButton} from "@material-ui/core";

export const Edit = (props) => {
    const {integration, setLoading} = props
    const [open, setOpen] = useState(false)

    return (
        <React.Fragment>
            <IconButton
                onClick={() => {
                    setOpen(true)
                }}
            >
                <EditIcon/>
            </IconButton>
            {open ? <Token integration={integration} open={open} setOpen={setOpen} setLoading={setLoading} /> : null}
        </React.Fragment>
    )
}
