import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {
    Grid,
    makeStyles,
} from "@material-ui/core"

import {Table} from "@devexpress/dx-react-grid-material-ui";
import {CustomerActions} from "../../../actions/customer";
import {Submit} from "../../../../App/components/Button/Submit";
import {Territory} from "./Territory/Territory";
import {CustomTable} from "../../../../App/components/Table/Table";
import {TableHeader} from "../../../../App/components/Table/TableHeader";
import {localState} from "../../../../App/components/LocalState";
import {SettingsActions} from "../../../../Settings/actions/settings";
import {AuthorizationService} from "../../../../Auth/services/authorization";
import {ContractActions} from "../../../../Contract/actions/contract";
import {RemoveButton} from "../../../../App/components/Button/RemoveButton";

const useStyles = makeStyles(() => ({
    tab: {
        "width": "100%",
        "overflow": "auto",
    },
    container: {
        "max-width": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 293px)",
        "min-height": "calc(100vh - 293px)"
    },
    buttonContainer: {
        "padding": "12px"
    },
}))

const columns = [
    {name: 'territory', title: 'Название'},
    {name: 'address', title: 'Адрес'},
    {name: 'action', title: ' '},
];

export const Territories = (props) => {
    const { company } = props
    const classes = useStyles()
    const [territories, setTerritories] = useState(localState)
    const [dialog, setDialog] = useState(false)
    const [territory, setTerritory] = useState(null)
    const [initialize, setInitialize] = useState(false);

    const dispatch = useDispatch()
    const [tableRef, setTableRef] = useState(null)
    const {filter} = useSelector(state => state.filters['territories'])

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'territory', width: 350},
        {columnName: 'address', width: 450},
        {columnName: 'action', width: 100},
    ])

    const [columnOrder, setColumnOrder] = useState([
        'territory',
        'address',
        'action'
    ])

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
        }
    }

    useEffect(() => {
        if (!initialize) {
            dispatch(SettingsActions.table('territories')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                dispatch({type: "FILTER_CLEAR"})
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'territories',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    const getTerritories = async () => {
        return await dispatch(CustomerActions.territories({
                companies: [company.id],
                limit: rowsPerPage,
                page: page,
                ...getParams()
            }
        ))
    }

    useEffect(() => {
        getTerritories().then((r) => {
            setTerritories(r)
            tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
        })
    }, [dispatch, company.id, filter, page, rowsPerPage, dialog]);

    useEffect(() => {
        if (!dialog) {
            setTerritory(null)
        }
    }, [dialog, territory]);

    const handlePagesSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: 'TERRITORIES_FILTER', payload: {limitRows: newRowsPerPage}})
    }

    return (
        <React.Fragment>
            <Grid item className={classes.container}>
                <Grid container className={classes.buttonContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                    <Grid item>
                        <Submit
                            className={classes.submit}
                            disableElevation
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={() => {
                                setDialog(true)
                            }}
                        >
                            Добавить
                        </Submit>
                    </Grid>
                </Grid>
                <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                    <CustomTable
                        meta={territories.meta}
                        tableName={'territories'}
                        rows={territories?.data.map(territory => ({
                            territory: territory.name,
                            address: territory.address,
                            action: <RemoveButton
                                item={territory}
                                onSubmit={(id) => {
                                    return dispatch(CustomerActions.remove(company.id, id))
                                }}
                                onSuccess={() => {
                                    setTerritories({
                                        data: territories.data.filter(item => item.id !== territory.id),
                                        meta: {
                                            ...territories.meta,
                                            total: territories.meta.total - 1
                                        }
                                    })
                                }}
                            />
                        }))}
                        columns={columns}
                        page={{page: page, setPage: setPage, rowsPerPage: rowsPerPage, handlePageSizeChange: handlePagesSizeChange}}
                        setTableRef={setTableRef}
                        columnsSettings={{
                            columnOrder: columnOrder,
                            setColumnOrder: setColumnOrder,
                            setColumnWidths: setColumnWidths,
                            columnWidths: columnWidths,
                            hiddenColumnNames: hiddenColumnNames,
                            setHiddenColumnNames: setHiddenColumnNames
                        }}
                        tableHeader={TableHeader}
                        filterActionType={'TERRITORIES_FILTER'}
                        classInputContent={classes.inputContent}
                        rowComponent={({row, tableRow, children}) => (
                            <Table.Row
                                tableRow={tableRow}
                                children={children}
                                className={classes.tableRow}
                                key={territories.data[tableRow.rowId].id}
                                row={row}
                                onClick={() => {
                                    setTerritory(territories.data[tableRow.rowId])
                                    setDialog(true)
                                }}

                            />
                        )}
                        filters={{
                            action: {
                                nullButton: true
                            }
                        }}
                    />
                </Grid>
            </Grid>
            <Territory open={dialog} setDialog={setDialog} company={company} territory={territory}/>
        </React.Fragment>
    )
}
