import React from "react"
import {useDispatch, useSelector} from "react-redux"

import {
    Grid
} from "@material-ui/core"

import {Submit} from "../../../../App/components/Button/Submit"
import {plan as rules} from "../../../helpers/permissions/rules"
import {PurchasePlanActions as PlanActions} from "../../../actions/purchasePlan";

export const Approve = (props) => {
    const dispatch = useDispatch()

    const { items, settings, correction = false, onClick } = props

    const {account} = useSelector(state => state.account)

    return (correction ? ((account.group === 'central') && rules.correction.approve.hasOwnProperty(account.group)) : rules.approve.hasOwnProperty(account.group)) ? (
        <Grid item>
            <Submit
                disableElevation
                disabled={!items.length || !!items.filter(el => {
                    const params = correction ? rules.correction.approve[account.group] : rules.approve[account.group]
                    const status = correction ? el.editRequestStatus : el.status

                    if (params.hasOwnProperty(status?.index) && !el.lock) {
                        if (params[status.index].hasOwnProperty('conditions')) {
                            if (params[status.index].conditions.hasOwnProperty('plan')) {
                                return !params[status.index].conditions.plan.includes(el.status.index)
                            }

                            if (params[status.index].conditions.hasOwnProperty('correction')) {
                                return !params[status.index].conditions.correction.includes(el.editRequestStatus?.index)
                            }

                            if (params[status.index].conditions.hasOwnProperty('settings')) {
                                for (const [key, value] of Object.entries(params[status.index].conditions.settings)) {
                                    if (settings[key] !== value) {
                                        return true
                                    }
                                }
                            }
                        }

                        return false
                    }

                    return true
                }).length}
                variant="contained"
                color="primary"
                type="button"
                style={{height: '35px'}}
                onClick={() => {
                    return dispatch(PlanActions.approve({
                        ids: items.map(item => item.id)
                    })).then(() => onClick(), () => {})
                }}
            >
                {correction ? 'Утвердить запрос корректировки' : 'Утвердить план закупки'}
            </Submit>
        </Grid>
    ) : null
}
