import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import format from "date-fns/format"
import {ru} from "date-fns/locale"
import clsx from "clsx"

import {
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    TextField,
    Typography
} from "@material-ui/core"
import {ExitToApp as ExitToAppIcon, Search as SearchIcon} from '@material-ui/icons'

import {AuthorizationService} from "../../../../Auth/services/authorization"
import {Employee} from "./Employee/Employee"
import {CompanyActions} from "../../../actions/company"
import {Submit} from "../../../../App/components/Button/Submit"
import {UserActions} from "../../User/actions/user"
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon"
import {CompanyActions as CompActions} from "../../../../Account/actions/company"
import {Delete as DeleteButton} from "../../User/Delete";
import {CustomTable} from "../../../../App/components/Table/Table";
import {TableHeader} from "../../../../App/components/Table/TableHeader";
import {SettingsActions} from "../../../../Settings/actions/settings";
import {Table} from "@devexpress/dx-react-grid-material-ui";
import {localState} from "../../../../App/components/LocalState";
import {useDebounce} from "use-debounce";
import {ColumnChooser} from "../../../../App/components/Table/ColumnChooser";

const useStyles = makeStyles(() => ({
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    search: {
        "padding": "20px 30px 0"
    },
    container: {
        "padding": "0 30px 10px"
    },
    fullWidth: {
        maxWidth: '100%',
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    searchLabel: {
        "margin-bottom": "14px",
    },
    button: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "#898989",
        },
        "width": "auto",
        "max-height": "35px",
        "max-width": "auto",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "#fff",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-bottom": "3px solid #898989",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "#616161",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
            marginTop: "5px",
        }
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 359px)",
        "min-height": "calc(100vh- 359px)"
    },
}))

export const Employees = (props) => {
    const { company, isSupplier } = props
    const classes = useStyles()
    const {account} = useSelector(state => state.account)
    const [employees, setEmployees] = useState(localState)
    const [tableRef, setTableRef] = useState(null)
    const [dialog, setDialog] = useState(false)
    const [employee, setEmployee] = useState(null)
    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)
    const {filter} = useSelector(state => state.filters['employees'])

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)

    const dispatch = useDispatch()

    const [columns, setColumns] = useState([
        {name: 'full_name', title: 'ФИО'},
        {name: 'email', title: 'Эл. адрес'},
        {name: 'position', title: 'Должность'},
        {name: 'roles', title: 'Роли'},
        {name: 'created_at', title: 'Дата создания'},
        {name: 'button', title: ' '},
    ])

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'full_name', width: 200},
        {columnName: 'email', width: 150},
        {columnName: 'position', width: 150},
        {columnName: 'roles', width: 150},
        {columnName: 'created_at', width: 250},
        {columnName: 'button', width: 200},
    ])

    const [columnOrder, setColumnOrder] = useState([
        'full_name',
        'email',
        'position',
        'roles',
        'created_at',
        'button',
    ])

    const [initialize, setInitialize] = useState(false);

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...(searchRequest ? {search: searchRequest} : {}),
        }
    }

    useEffect(() => {
        if (!initialize) {
            dispatch(SettingsActions.table('employees')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                dispatch({type: "FILTER_CLEAR"})
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'employees',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    const getCompany = async () => {
        return await dispatch(company.type ? CompanyActions.supplier(company.id, {
            ...{
                include: "legalDetail.city.region.country,legalDetail.executiveDirector,legalDetail.okpds.okved,legalDetail.city.region.country,legalDetail.passportDetails,contactInformation,users.roles,paymentDetail,termsParticipation.regions.region,creator.company.legalDetail,occupations,status,users.permissions"
            },
            limit: rowsPerPage,
            page: page,
            ...getParams()
        }) : CompanyActions.customer(company.id, {
            ...{
                include: "purchasingDepartment,legalDetail.city.region.country,legalDetail.executiveDirector,legalDetail.city.region.country,legalDetail.passportDetails,paymentDetail,contactInformation,users.roles,users.permissions,territories.users,status"
            },
            limit: rowsPerPage,
            page: page,
            ...getParams()
        }))
    }

    useEffect(() => {
        if (!dialog) {
            getCompany().then(r => {
                setEmployees({data: r.employees, meta: {total: r.employees.length, current_page: page, per_page: rowsPerPage}})
                tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
            })
        }
        // eslint-disable-next-line
    }, [dispatch, company.id, page, rowsPerPage, filter, dialog, searchRequest]);

    useEffect(() => {
        if (!dialog) {
            setEmployee(null)
        }
    }, [dialog]);

    const handlePagesSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: 'EMPLOYEES_FILTER', payload: {limitRows: newRowsPerPage}})
    }

    return (
        <React.Fragment>
            <Grid item className={classes.fullWidth}>
                <Grid item className={classes.search}>
                    <TextField
                        id="search"
                        variant="standard"
                        name={'search'}
                        placeholder={'Поиск'}
                        fullWidth={true}
                        margin={'normal'}
                        onChange={(event) => {
                           setSearch(event.target.value)
                        }}
                        classes={{
                            root: clsx(classes.filterLabel, classes.searchLabel)
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon  style={{color: '#c1c1c1'}}/>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item className={classes.container}>
                    <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                        <Grid item>
                            <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                {AuthorizationService.roles(account, 'admin') && isSupplier &&
                                    <Grid item>
                                        <Grid container spacing={2}>
                                            {employees.length > 0 && employees[0].roles.filter(role => role.name === 'supplier_blocked').length === 0 &&
                                                <Grid item>
                                                    <ButtonIcon
                                                        className={classes.button}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            return dispatch(CompActions.block(company.id)).then(() => {
                                                                dispatch(CompanyActions.supplier(company.id, {
                                                                    include: "legalDetail.city.region.country,legalDetail.executiveDirector,legalDetail.passportDetails,contactInformation,users.roles,paymentDetail,termsParticipation.regions.region,creator.company.legalDetail,occupations,status"
                                                                }))
                                                            })
                                                        }}
                                                        children={['Блокировать']}>
                                                    </ButtonIcon>
                                                </Grid>
                                            }
                                            {employees.length > 0 && employees[0].roles.filter(role => role.name === 'supplier_blocked').length > 0 &&
                                                <Grid item>
                                                    <ButtonIcon
                                                        className={classes.button}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            return dispatch(CompActions.unBlock(company.id)).then(() => {
                                                                dispatch(CompanyActions.supplier(company.id, {
                                                                    include: "legalDetail.city.region.country,legalDetail.executiveDirector,legalDetail.passportDetails,contactInformation,users.roles,paymentDetail,termsParticipation.regions.region,creator.company.legalDetail,occupations,status"
                                                                }))
                                                            })
                                                        }}
                                                        children={['Разблокировать']}>
                                                    </ButtonIcon>
                                                </Grid>
                                            }
                                            {employees.length > 0 && employees[0].roles.filter(role => role.name === 'guest_supplier').length > 0 &&
                                                <Grid item>
                                                    <ButtonIcon
                                                        className={classes.button}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            return dispatch(CompActions.approve(company.id)).then(() => {
                                                                dispatch(CompanyActions.supplier(company.id, {
                                                                    include: "legalDetail.city.region.country,legalDetail.executiveDirector,legalDetail.passportDetails,contactInformation,users.roles,paymentDetail,termsParticipation.regions.region,creator.company.legalDetail,occupations,status"
                                                                }))
                                                            })
                                                        }}
                                                        children={['Утвердить']}>
                                                    </ButtonIcon>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item>
                                    {AuthorizationService.roles(account, "admin") &&
                                        <Submit
                                            className={classes.submit}
                                            disableElevation
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            onClick={() => {
                                                setDialog(true)
                                            }}
                                        >
                                            Добавить
                                        </Submit>
                                    }
                                </Grid>
                                <Grid item style={{marginTop: AuthorizationService.roles(account, "admin") ? '-4px' : '8px'}}>
                                    <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                    <CustomTable
                        meta={employees.meta}
                        tableName={'employees'}
                        rows={employees?.data.map(employee => ({
                            item: employee,
                            full_name: `${employee['last_name']} ${employee['first_name']} ${employee['middle_name']}`,
                            email: employee.email,
                            position: employee.position,
                            roles: !company.type && <Typography component="div" noWrap>{employee.permissions.length ? employee.permissions.map(permission => permission.description).join(', ') : 'Заказчик'}</Typography>,
                            created_at: format(new Date(employee.created), 'PP',  {locale: ru}),
                            button: AuthorizationService.roles(account, ["admin", "operator"]) &&
                                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                return dispatch(UserActions.login({
                                                    login: employee.login,
                                                }))
                                            }}
                                        >
                                            <ExitToAppIcon/>
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <DeleteButton
                                            onClick={() =>{getCompany().then(r => {setEmployees({data: r.employees, meta: {total: r.employees.length, current_page: page, per_page: rowsPerPage}})})}}
                                            user={employee.id}
                                        />
                                    </Grid>
                                </Grid>
                        }))
                    }
                        columns={columns.filter(column => (column.name === 'roles' && !company.type) || (column.name !== 'roles'))}
                        page={{page: page, setPage: setPage, rowsPerPage: rowsPerPage, handlePageSizeChange: handlePagesSizeChange}}
                        setTableRef={setTableRef}
                        columnsSettings={{
                            columnOrder: columnOrder,
                            setColumnOrder: setColumnOrder,
                            setColumnWidths: setColumnWidths,
                            columnWidths: columnWidths,
                            hiddenColumnNames: hiddenColumnNames,
                            setHiddenColumnNames: setHiddenColumnNames
                        }}
                        tableHeader={TableHeader}
                        filterActionType={'EMPLOYEES_FILTER'}
                        classInputContent={classes.inputContent}
                        rowComponent={({row, tableRow, children}) => (
                            <Table.Row
                                tableRow={tableRow}
                                children={children}
                                className={classes.tableRow}
                                row={row}
                                onClick={() => {
                                    if (AuthorizationService.roles(account, "admin")) {
                                        setEmployee(row.item)
                                        setDialog(true)
                                    }
                                }}
                            />
                        )}
                        filters={{
                            button: {
                                nullButton: true
                            },
                        }}
                    />
                </Grid>
            </Grid>
            <Employee open={dialog} setDialog={setDialog} company={company} employee={employee}/>
        </React.Fragment>
    )
}
