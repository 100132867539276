import React, {Fragment, useRef, useState} from "react";
import {
  Link as MuiLink,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  makeStyles, Typography
} from "@material-ui/core";
import * as AppStorage from "../../App/helpers/storage";
import {SystemActions} from "../../App/actions/system";
import {useDispatch} from "react-redux";
import {b64toBlob} from "../utils/b64toBlob";
import {Signature} from "../Signature";

const useStyles = makeStyles(theme => ({
  link: {
    "cursor": "pointer",
    "display": "flex",
    "line-height": "31px",
    "color": "#485868",
  },
  paper: {
    "margin-top": "4px",
    "border": "none !important",
    "& .MuiButtonBase-root": {
      "padding": "8px 12px"
    }
  }
}))

export const Link = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const {file, name, href, onContinue, required = true} = props
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [signature, setSignature] = useState(file.sign)
  const [sign, setSign] = useState({open: false, files: []})

  const handleClose = () => setOpen(false)

  return (
    <Fragment>
      <Typography gutterBottom>
        <MuiLink
          ref={anchorRef}
          className={classes.link}
          onClick={() => setOpen(true)}
        >
          {name}
        </MuiLink>
      </Typography>
      {open ? (
        <Popper open={open} anchorEl={anchorRef.current} placement="top-start">
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={() => handleClose()}>
              <MenuList >
                <MenuItem
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = process.env.REACT_APP_HOST_API + href + `?token=${AppStorage.get('token').replace('Bearer ', '')}`;
                    link.download = file.name;
                    link.click();
                    handleClose()
                  }}
                >
                  Скачать файл
                </MenuItem>
                {signature ? (
                  <MenuItem
                    onClick={() => {
                      handleClose()
                      const blob = b64toBlob(signature, 'text/plain')

                      const url = URL.createObjectURL(blob);

                      const a = document.createElement("a");
                      a.href = url;
                      a.download = `${file.name}.sig`;
                      a.click();

                      URL.revokeObjectURL(url);
                    }}
                  >
                    Скачать подпись
                  </MenuItem>
                ) : (onContinue ? (
                  <MenuItem
                    onClick={async () => {
                      handleClose()
                      dispatch(SystemActions.file(href)).then(
                        (blob) => {
                          const object = new File([blob], file.name, { type: blob.type });
                          setSign({open: true, files: [object]})
                        }
                      )
                    }}
                  >
                    Подписать
                  </MenuItem>
                ) : null)}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      ) : null}
      {sign.open ? (
        <Signature
          open={sign.open}
          onClose={() => setSign({open: false, files: []})}
          files={sign.files}
          onContinue={(objects) => {
            setSign({open: false, files: []})
            setSignature(objects[0].sign)
            onContinue(objects[0].sign)
          }}
          required={required}
        />
      ) : null}
    </Fragment>
  )
}
