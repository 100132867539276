import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import {Select} from "formik-material-ui"
import * as Yup from "yup"

import {FormControl, Grid, InputLabel, makeStyles, MenuItem, Paper, Typography} from "@material-ui/core"

import {Submit} from "../../../App/components/Button/Submit"
import {SettingsActions} from "../../actions/settings"
import {SystemActions} from "../../../App/actions/system"

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%"
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 111px)"
    },
    input: {
        "width": "100%",
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100% - 54px)",
        "min-height": "calc(100% - 54px)",
        "padding": "20px 30px 0",
        "overflow": "auto"
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    select: {
        "height": "43px"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    tableRowAlert: {
        "cursor": "pointer",
        "background-color": "#f9f1f0",
        "text-decoration": "none"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "18px !important"
    },
    notificationList: {
        height: "calc(100vh - 215px)",
        overflow: "auto",
    }
}))

export const Content = () => {
    const {rf_cb_rate_coefficients} = useSelector(state => state.system)
    const {marketplace} = useSelector(state => state.settings)

    const dispatch = useDispatch()
    const classes = useStyles()

    const [loading, setLoading] = useState(false)

    const getRFCBRateCoefficient = async () => {
        await dispatch(SystemActions.getRFCBRateCoefficient())
    }

    const getSettingsMarketplace = async () => {
        await dispatch(SettingsActions.marketplace())
    }

    useEffect(() => {
        if (rf_cb_rate_coefficients.length === 0) {
            getRFCBRateCoefficient().then(_ => {
            })
        }
        // eslint-disable-next-line
    }, [dispatch])

    useEffect(() => {
        if (!loading) {
            getSettingsMarketplace().then(_ => {
                setLoading(true)
            })
        }
        // eslint-disable-next-line
    }, [loading]);

    const getInitValues = () => {
        return {
            rf_cb_rate_coefficient_id: marketplace?.rf_cb_rate_coefficient_id,
        }
    }

    const getValidation = () => {
        return Yup.object().shape({
            rf_cb_rate_coefficient_id: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        })
    }

    const onSubmit = (values, {setSubmitting}) => {
        return dispatch(SettingsActions.setMarketplace(values)).then(
            () => {
                setSubmitting(false)
            },
            () => {
                setSubmitting(false)
            }
        )
    }

    return loading ? (
            <Grid item className={classes.content}>
                <Paper>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item className={classes.fullWidth}>
                            <Grid container className={classes.container} direction="column" justify="space-between" alignItems="stretch">
                                <Formik
                                    initialValues={getInitValues()}
                                    validationSchema={getValidation()}
                                    onSubmit={onSubmit}
                                >
                                    {({
                                          isSubmitting
                                      }) => (
                                        <Form className={classes.fullWidth}>
                                            <Grid item className={classes.inputContent}>
                                                <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                                    <Grid item className={classes.header}>
                                                        <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                                            <Grid item className={classes.fullWidth}>
                                                                <Typography className={classes.title}>
                                                                    Настройка маркетплейса
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid container className={classes.notificationList} direction="row" justify="flex-start" alignItems="stretch" spacing={4}>
                                                    <Grid item xs={4}>
                                                        <FormControl fullWidth>
                                                            <InputLabel>
                                                                Коэффициента ставки ЦБ РФ
                                                            </InputLabel>
                                                            <Field
                                                                fullWidth
                                                                type="text"
                                                                name="rf_cb_rate_coefficient_id"
                                                                required
                                                                component={Select}
                                                            >
                                                                {
                                                                    rf_cb_rate_coefficients.map((rf_cb_rate_coefficient, index) => (
                                                                        <MenuItem
                                                                            key={index}
                                                                            value={rf_cb_rate_coefficient.id}
                                                                        >
                                                                            {rf_cb_rate_coefficient.name}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.footer}>
                                                <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                                    <Grid item className={classes.footerContent}>
                                                        <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Submit
                                                                    disableElevation
                                                                    variant="contained"
                                                                    color="primary"
                                                                    type="submit"
                                                                    disabled={isSubmitting}
                                                                >
                                                                    Сохранить
                                                                </Submit>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        ) : null
}
