import React from "react"
import {Button, makeStyles} from "@material-ui/core"

const useStyles = makeStyles(() => ({
    root: {
        "width": "100%",
        "border-bottom": "3px solid #898989",
        "border-radius": "2px",
        "padding": "5px 10px 0",
        "min-height": "35px",
        "height":"35px",
        "font-size": "16px",
        "font-weight": "500",
        "color": "white",
        "outline": "0",
        "min-width": "0",
        "&:disabled": {
            "background-color": "#c1c1c1",
            "border-bottom-color": "#a5adb1 !important",
            "color": "white"
        },
        '& .MuiButton-endIcon': {
            'padding-bottom': '3px'
        },
        '&.MuiButton-sizeSmall': {
            "min-height": "30px",
            "height": "30px",
            "line-height": "1.50",
            "font-size": "0.9rem",
            "padding": "5px 7px 0"
        },
        '&.MuiButton-containedSecondary': {
            "border-bottom": "3px solid #b56a5f",
        }
    }
}))

export const Submit = (props) => {
    const { children, size } = props
    const classes = useStyles()

    return (
        <Button size={size} classes={{ root: classes.root}} {...props}>{children ?? <React.Fragment />}</Button>
    )
}
