import React from "react"
import {Link} from "react-router-dom"
import {useSelector} from "react-redux"

import {List, ListItem, ListItemText, makeStyles, withStyles} from '@material-ui/core'

import {AuthorizationService} from "../Auth/services/authorization"
import {Redirect} from "react-router";
import {history} from "../App/helpers/history";

const useStyles = makeStyles(() => ({
    active: {
        "background-image": "none !important",
        "background-color": "#898989"
    }
}))

const CustomList = withStyles({
    root: {
        "padding": "0",
        "& .MuiListItem-button": {
            "background-image": "linear-gradient(to bottom,#e4e4e4,#f4f4f4)",
            "cursor": "pointer",
            "align-items": "center",
            "min-height": "50px",
            "padding": "5px 25px 0",
            "border-bottom": "1px solid #e4e4e4",
            "outline": "0"
        },
        "& .MuiListItemText-primary": {
            "font-size": "16px",
            "font-weight": "700",
            "text-transform": "uppercase"
        },
        "& .MuiListItem-button:hover": {
            "background-color": "#898989",
            "&:after": {
                "position": "absolute",
                "content": "''",
                "top": "0",
                "left": "0",
                "width": "100%",
                "height": "100%",
                "background-color": "rgba(0,0,0,.04)"
            }
        }
    }
})(List);

export const Menu = (props) => {
    const classes = useStyles()
    const {account} = useSelector(state => state.account)
    const {location} = props

    const list = () => {
        if (AuthorizationService.roles(account, 'supervisor')) {
            return [
                ...(AuthorizationService.permissions(account, 'access_to_companies') ? [
                    {title: "Заказчики",to: "/customers"},
                    {title: "Поставщики",to: "/suppliers"},
                ] : []),
                ...(AuthorizationService.permissions(account, 'control_price') ? [
                    {title: "Контроль цен",to: "/report/control/price"}
                ] : []),
            ]
        } else if (AuthorizationService.roles(account, 'admin')) {
            return [
                ...(AuthorizationService.permissions(account, 'access_to_companies') ? [
                    {title: "Заказчики",to: "/customers"},
                    {title: "Поставщики",to: "/suppliers"},
                ] : []),
                {title: "Операторы",to: "/user/operators"},
                {title: "Контролирующие органы",to: "/user/supervisors"}
            ]
        } else if (AuthorizationService.roles(account, 'operator')) {
            return [
                ...(AuthorizationService.permissions(account, 'access_to_companies') ? [
                    {title: "Заказчики",to: "/customers"},
                    {title: "Поставщики",to: "/suppliers"},
                ] : []),
            ]
        }

        return []
    }

    return (
        <CustomList>
            {list().map(option => (
                <ListItem button key={option.title} component={Link} to={option.to} classes={{ root: ((location.pathname === option.to) || ((location.pathname.indexOf('/customer') !== -1) && (option.to === '/customers')) || ((location.pathname.indexOf('/user/supervisor') !== -1) && (option.to === '/user/supervisors')) || ((location.pathname.indexOf('/user/operators/add') !== -1) && (option.to ===  '/user/operators')) || ((location.pathname.indexOf('/supplier') !== -1) && (option.to === '/suppliers')) || ((location.pathname.indexOf('/report/control/price') !== -1) && (option.to === '/report/control/price'))) ? classes.active : undefined }}>
                    <ListItemText primary={option.title} />
                </ListItem>
            ))}
        </CustomList>
    )
}
