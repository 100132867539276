import React from "react"
import {useSelector} from "react-redux"

import {Dialog, DialogContent, DialogTitle, makeStyles} from "@material-ui/core"
import {Close as CloseIcon} from "@material-ui/icons"

import {ProductList as ProductListSupervisor} from "./supervisor/ProductList"
import {ProductList} from "./ProductList"
import {AuthorizationService} from "../../Auth/services/authorization"

const useStyles = makeStyles(theme => ({
    tableHeaderItem: {
        "border-right": "2px solid rgba(211,211,211,.4)",
        "height": "40px",
        "display": "flex",
        "padding": "0 15px",
        "align-items": "center",
        "margin": "8px 0",
    },
    borderRightNone: {
        "border-right": "none",
    },
    padLeft: {
        "padding-left": "0",
    },
    title: {
        "margin": "0",
        "padding-top": "2px",
        "font-size": "18px",
        "font-weight": "600",
        "color": "#fff",
        "text-transform": "uppercase",
        "display": "flex",
        "justify-content": "space-between",
        "align-items": "center",
        "min-height": "40px",
        "padding": "0 20px",
        "background-color": "#898989",
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    footerContainer: {
        "padding": "0 25px",
        "background-color": "#e7eaee",
        "height": "75px",
    },
    submit: {
        "width": "100%",
        "border-bottom": "3px solid #898989",
        "border-radius": "2px",
        "padding": "5px 10px 0",
        "min-width": "95px",
        "min-height": "35px",
        "font-size": "16px",
        "font-weight": "500",
        "color": "white",
        "outline": "0",
        "height": "35px",
        "&:disabled": {
            "background-color": "#c1c1c1",
            "border-bottom-color": "#898989 !important",
            "color": "white"
        }
    },
    dialogContent: {
        padding: "0",
        "overflow": "hidden",
    },
    saveButton: {
    },
    rows: {
        "min-height": "calc(100vh - 350px)",
        "overflow": "auto",
    },
    dialogContainer: {
        "& > div": {
            "max-width": "calc(100vw - 200px)"
        }

    }
}));

export const ProductListDialog = (props) => {
    const classes = useStyles();
    const {open, id, onClose, categories, supplierId, withContract, account} = props;
    const {activePrice} = useSelector(state => state.prices)

    return (
        <Dialog
            aria-labelledby="simple-dialog-title"
            open={open}
            classes={{
                root: classes.dialog,
                container: classes.dialogContainer
            }}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle
                id="simple-dialog-title"
                classes={{
                    root: classes.title,
                }}
                disableTypography={true}
            >
                Карточка прайс-листа {activePrice && activePrice.hasOwnProperty('name') ? activePrice.name : null}<CloseIcon className={classes.close} onClick={onClose} />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {AuthorizationService.roles(account, 'supervisor')
                    ? <ProductListSupervisor categories={categories} priceId={id} open={open} supplierId={supplierId} withContract={withContract}/>
                    : <ProductList categories={categories} priceId={id} open={open} supplierId={supplierId} withContract={withContract}/>
                }
            </DialogContent>
        </Dialog>
    )
}
