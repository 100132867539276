import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useParams} from "react-router-dom"
import format from "date-fns/format"

import {Grid, makeStyles, Paper, Typography} from "@material-ui/core"

import {ContractActions} from "../../../Contract/actions/contract"

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px 0 29px !important",
    },
    input: {
        "width": "100%",
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "margin-bottom": "4px",
        "height": "calc(100vh - 188px)",
    },
    tableContainer: {
        "overflow": "auto"
    },
    tabContent: {
        "padding": "20px 29px !important",
    },
    itemInput: {
        "margin-top": "0 !important"
    },
    inputContent: {
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    download: {
        "color": "#485868"
    },
    winner: {
        '& .MuiTableCell-root': {
            'font-weight': '500'
        }
    },
    line: {
        "text-decoration": "line-through"
    },
    tooltip: {
        "max-width": "200px",
        "border-radius": "0px !important",
        "font-size": "0.8rem"
    },
    badge: {
        "right": "-12px"
    },
    tabBadge: {
        "padding-right": "34px"
    },
}))

const types = [
    {type: 'complete', name: 'Предоплата'},
    {type: 'partial', name: 'Частичная'},
    {type: 'postponement', name: 'Постоплата'}
]

export const Content = (props) => {
    let {id} = useParams()

    const dispatch = useDispatch()
    const classes = useStyles()
    const {contract} = useSelector(state => state.contract)

    useEffect(() => {
        const getContract = async () => {
            return await dispatch(ContractActions.contract(id))
        }

        getContract().then(r => {
        })

        return () => {
            dispatch({type: 'CONTRACT'})
        }
    }, [dispatch, id]);

    return contract ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.full}>
                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                            <Grid item className={classes.fullWidth}>
                                <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                    <Grid item className={classes.header}>
                                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                            <Grid item>
                                                <Grid container className={classes.fullWidth} direction="row" justify="space-between" alignItems="stretch">
                                                    <Grid item>
                                                        <Typography className={classes.title}>
                                                            Договор № {contract.number ?? contract.id}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="button">
                                                            {contract.status.name}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="button">
                                                    Дата
                                                    создания {contract.created ? format(new Date(contract.created), 'dd.MM.yyyy HH:mm') : null}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid className={classes.tab} container direction="column" justify="space-between"
                                      alignItems="stretch">
                                    <Grid item className={classes.inputContent}>
                                        <Grid container direction="column" justify="flex-start"
                                              alignItems="stretch">
                                            <Grid item>
                                                <Grid container direction="row" justify="flex-start"
                                                      alignItems="stretch">
                                                    <Grid item xs={6}>
                                                        <Typography variant="button">
                                                            Наименование:
                                                        </Typography>
                                                        <Typography>
                                                            {contract.name ?? (contract.offer.lot.order.name ?? '')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography variant="button">
                                                            Дата заключения:
                                                        </Typography>
                                                        <Typography>
                                                            {contract.start ? format(new Date(contract.start), 'dd.MM.yyyy HH:mm') : null}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography variant="button">
                                                            Дата окончания:
                                                        </Typography>
                                                        <Typography>
                                                            {!!contract.perpetual ? 'Бессрочный' : (contract.end ? format(new Date(contract.end), 'dd.MM.yyyy HH:mm') : null)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="row" justify="flex-start"
                                                      alignItems="stretch">
                                                    <Grid item xs={3}>
                                                        <Typography variant="button">
                                                            Закупка:
                                                        </Typography>
                                                        <Typography>
                                                            {contract.unscheduled_purchase ? 'Внеплановая' : (contract.purchase_number ? `№ ${contract.purchase_number}` : '')}
                                                        </Typography>
                                                    </Grid>
                                                    {contract.offer.lot.order.reason &&
                                                        <Grid item xs={3}>
                                                            <Typography variant="button">
                                                                Обоснование:
                                                            </Typography>
                                                            <Typography>
                                                                {contract.offer.lot.order.reason}
                                                            </Typography>
                                                        </Grid>
                                                    }
                                                    {contract.offer.lot.number &&
                                                        <Grid item xs={3}>
                                                            <Typography variant="button">
                                                                Лот:
                                                            </Typography>
                                                            <Typography>
                                                                {`№ ${contract.offer.lot.number}`}
                                                            </Typography>
                                                        </Grid>
                                                    }
                                                    <Grid item xs={3}>
                                                        <Typography variant="button">
                                                            Категория:
                                                        </Typography>
                                                        <Typography>
                                                            {contract.category ? contract.category.name : ''}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography variant="button">
                                                            Минимальный срок поставки, дней:
                                                        </Typography>
                                                        <Typography>
                                                            {contract.contract_time ?? (contract.offer.lot.contract_time ?? '')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="row" justify="flex-start"
                                                      alignItems="stretch">
                                                    {contract.type &&
                                                        <Grid item xs={3}>
                                                            <Typography variant="button">
                                                                Тип оплаты:
                                                            </Typography>
                                                            <Typography>
                                                                {types.find(type => type.type === contract.type).name}
                                                            </Typography>
                                                        </Grid>
                                                    }
                                                    <Grid item xs={3}>
                                                        <Typography variant="button">
                                                            Сумма договора:
                                                        </Typography>
                                                        <Typography>
                                                            {contract.total_price}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography variant="button">
                                                            Валюта:
                                                        </Typography>
                                                        <Typography>
                                                            {contract.currency ? contract.currency.name : null}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="row" justify="flex-start"
                                                      alignItems="stretch">
                                                    <Grid item xs={6}>
                                                        <Typography variant="button">
                                                            Заказчик:
                                                        </Typography>
                                                        <Typography>
                                                            {contract.company.legal_detail.name}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="button">
                                                            Поставщик:
                                                        </Typography>
                                                        <Typography>
                                                            {contract.offer.company.legal_detail.name}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    ) : null
}
