import {AuthorizationService} from "../../Auth/services/authorization"

function getUserPosition(user)
{
    if(AuthorizationService.roles(user, ['customer','supplier'])){
        return user.position ?? ''
    }
    if(AuthorizationService.roles(user, ['operator'])){
        return 'Оператор'
    }
    if(AuthorizationService.roles(user, ['supervisor']) && (user.organization === 'regional')){
        return 'РДМО'
    }
    if(AuthorizationService.roles(user, ['supervisor']) && (user.organization === 'central')){
        return 'ЦДЗ'
    }
}

export { getUserPosition }
