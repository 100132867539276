import React from "react"

import {Delete as DeleteIcon} from "@material-ui/icons";
import {IconButton} from "@material-ui/core";
import {CompanyActions} from "../../../../../actions/company";
import {useDispatch} from "react-redux";

export const Delete = (props) => {
    const dispatch = useDispatch()
    const {setLoading, id} = props

    return (
        <IconButton
            onClick={() => {
                return dispatch(CompanyActions.deleteSubscription(id)).then(
                    () => {
                        setLoading(false)
                    }
                )
            }}
        >
            <DeleteIcon/>
        </IconButton>
    )
}
