import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import {CheckboxWithLabel} from "formik-material-ui"
import * as Yup from "yup"

import {Grid, makeStyles, Paper, Tab, Tabs, Typography} from "@material-ui/core"

import {NotificationActions} from "../../actions/notification"
import {Submit} from "../../../App/components/Button/Submit"
import {TabPanel} from "../../../Account/components/Tabs/TabPanel"
import {TextFieldWithError as CustomTextField} from "../../../App/components/Inputs/TextFieldWithError"
import {AuthorizationService} from "../../../Auth/services/authorization"

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%"
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 137px)"
    },
    input: {
        "width": "100%",
    },
    inputTab: {
        "width": "100%",
    },
    inputHeader: {
        "width": "100%",
        "padding": "20px 30px 0",
        "overflow": "auto"
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100% - 256px)",
        "min-height": "calc(100% - 256px)",
        "padding": "20px 30px 0",
        "overflow": "auto"
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    column: {
        "height": "66px"
    },
    select: {
        "height": "43px"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    tableRowAlert: {
        "cursor": "pointer",
        "background-color": "#f9f1f0",
        "text-decoration": "none"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "18px !important"
    },
    notificationList: {
        height: "calc(100vh - 342px)",
        flexWrap: "nowrap"
    },
    border: {
       "border-bottom": "31px solid #c1c1c1 !important"
    }
}))

export const Content = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { account } = useSelector(state => state.account)
    const { settings } = useSelector(state => state.notification)
    const [loading, setLoading] = useState(false)
    const [value, setValue] = React.useState(0)

    useEffect(() => {
        const getSettings = async () => {
            return await dispatch(NotificationActions.settings())
        }

        if (!loading) {
            getSettings().then(r => {
                setLoading(true)
            })
        }
    }, [dispatch, loading]);

    return loading ? (
        <Grid item className={classes.content} >
            <Paper className={classes.border}>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.fullWidth}>
                        <Grid container className={classes.container} direction="column" justify="space-between" alignItems="stretch">
                            <Formik
                                initialValues={{
                                    email: settings.email ?? '',
                                    notifications: {
                                        new_contract_created: !!settings.notifications.new_contract_created,
                                        contract_edited: !!settings.notifications.contract_edited,
                                        contract_status_active: !!settings.notifications.contract_status_active,
                                        contract_status_agreed: !!settings.notifications.contract_status_agreed,
                                        contract_status: !!settings.notifications.contract_status,
                                        contract_file: !!settings.notifications.contract_file,
                                        contract_price_list: !!settings.notifications.contract_price_list,
                                        contract_rejected: !!settings.notifications.contract_rejected,
                                        contract_terminated: !!settings.notifications.contract_terminated,
                                        contract_deleted: !!settings.notifications.contract_deleted,
                                        new_delivery_order_created: !!settings.notifications.new_delivery_order_created,
                                        delivery_order_status: !!settings.notifications.delivery_order_status,
                                        delivery_edited: !!settings.notifications.delivery_edited,
                                        delivery_order_integration: !!settings.notifications.delivery_order_integration,
                                        delivery_file: !!settings.notifications.delivery_file,
                                        delivery_overdue_date: !!settings.notifications.delivery_overdue_date,
                                        delivery_overdue_payment_deadline: !!settings.notifications.delivery_overdue_payment_deadline,
                                        delivery_pending_order: !!settings.notifications.delivery_pending_order,
                                        change_order_request: !!settings.notifications.change_order_request,
                                        start_rebidding: !!settings.notifications.start_rebidding,
                                        planning_new_comment: !!settings.notifications.planning_new_comment,
                                        planning_change_status_purchase: !!settings.notifications.planning_change_status_purchase,
                                        planning_addition_file: !!settings.notifications.planning_addition_file,
                                        planning_exclusion_or_addition_procurement: !!settings.notifications.planning_exclusion_or_addition_procurement,
                                        planning_create_joint_purchase: !!settings.notifications.planning_create_joint_purchase,
                                        planning_approval: !!settings.notifications.planning_approval,
                                        planning_submitting_for_revision: !!settings.notifications.planning_submitting_for_revision,
                                        planning_request_for_edit: !!settings.notifications.planning_request_for_edit,
                                        planning_rejection: !!settings.notifications.planning_rejection,
                                        planning_editing_expiration_warning: !!settings.notifications.planning_editing_expiration_warning,
                                        planning_approaching_date_for_approval: !!settings.notifications.planning_approaching_date_for_approval,
                                        planning_upcoming_joint_purchase: !!settings.notifications.planning_upcoming_joint_purchase,
                                        planning_create_important_goods_purchase: !!settings.notifications.planning_create_important_goods_purchase,
                                        planning_upcoming_important_goods_purchase: !!settings.notifications.planning_upcoming_important_goods_purchase,
                                        rejection_standard: !!settings.notifications.rejection_standard,
                                        change_standard: !!settings.notifications.change_standard,
                                        chat_message: !!settings.notifications.chat_message,
                                        pucrhase_plan_approve_chuz: !!settings.notifications.pucrhase_plan_approve_chuz,
                                        pucrhase_plan_approve_rdz:  !!settings.notifications.pucrhase_plan_approve_rdz,
                                        pucrhase_plan_approve_cdz:  !!settings.notifications.pucrhase_plan_approve_cdz,
                                        correction_period: !!settings.notifications.correction_period,
                                        lead_time_message: !!settings.notifications.lead_time_message,
                                        editing_after_rejection: !!settings.notifications.editing_after_rejection,
                                        send_for_correction: !!settings.notifications.send_for_correction,
                                        invoice: !!settings.notifications.invoice,
                                    }
                                }}
                                validationSchema={Yup.object().shape({
                                    email: Yup.string().email("Некорректный формат электронной почты!"),
                                    notifications: Yup.object().shape({
                                        new_contract_created: Yup.boolean(),
                                        contract_edited: Yup.boolean(),
                                        contract_status_active: Yup.boolean(),
                                        contract_status_agreed: Yup.boolean(),
                                        contract_status: Yup.boolean(),
                                        contract_file: Yup.boolean(),
                                        contract_price_list: Yup.boolean(),
                                        contract_rejected: Yup.boolean(),
                                        contract_terminated: Yup.boolean(),
                                        contract_deleted: Yup.boolean(),
                                        new_delivery_order_created: Yup.boolean(),
                                        delivery_order_status: Yup.boolean(),
                                        delivery_edited: Yup.boolean(),
                                        delivery_order_integration: Yup.boolean(),
                                        delivery_file: Yup.boolean(),
                                        delivery_overdue_date: Yup.boolean(),
                                        delivery_overdue_payment_deadline: Yup.boolean(),
                                        delivery_pending_order: Yup.boolean(),
                                        change_order_request: Yup.boolean(),
                                        start_rebidding: Yup.boolean(),
                                        planning_new_comment: Yup.boolean(),
                                        planning_change_status_purchase: Yup.boolean(),
                                        planning_addition_file: Yup.boolean(),
                                        planning_exclusion_or_addition_procurement: Yup.boolean(),
                                        planning_create_joint_purchase: Yup.boolean(),
                                        planning_approval: Yup.boolean(),
                                        planning_submitting_for_revision: Yup.boolean(),
                                        planning_request_for_edit: Yup.boolean(),
                                        planning_rejection: Yup.boolean(),
                                        planning_editing_expiration_warning: Yup.boolean(),
                                        planning_approaching_date_for_approval: Yup.boolean(),
                                        planning_upcoming_joint_purchase: Yup.boolean(),
                                        planning_create_important_goods_purchase: Yup.boolean(),
                                        planning_upcoming_important_goods_purchase: Yup.boolean(),
                                        rejection_standard: Yup.boolean(),
                                        change_standard: Yup.boolean(),
                                        chat_message: Yup.boolean(),
                                        pucrhase_plan_approve_chuz: Yup.boolean(),
                                        pucrhase_plan_approve_rdz:  Yup.boolean(),
                                        pucrhase_plan_approve_cdz:  Yup.boolean(),
                                        correction_period: Yup.boolean(),
                                        lead_time_message: Yup.boolean(),
                                        editing_after_rejection: Yup.boolean(),
                                        send_for_correction: Yup.boolean(),
                                        invoice: Yup.boolean(),
                                    })
                                })}
                                onSubmit={(values, {setSubmitting, resetForm}) => {
                                    return dispatch(NotificationActions.update(values)).then(
                                        () => {
                                            setSubmitting(false)
                                        },
                                        errors => {
                                            if (errors) {
                                            }
                                            setSubmitting(false)
                                        }
                                    )
                                }}
                            >
                                {({
                                      dirty,
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      handleSubmit,
                                      isSubmitting,
                                      isValid,
                                      isValidating,
                                      setValues,
                                      setFieldValue
                                  }) => (
                                    <Form className={classes.fullWidth}>
                                        <Grid item className={classes.inputHeader}>
                                            <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                                <Grid item className={classes.header}>
                                                    <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                                        <Grid item className={classes.fullWidth}>
                                                            <Typography className={classes.title}>
                                                                Настройки уведомлений
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                        <Grid item xs={6} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name="email"
                                                                type="email"
                                                                label="Электронная почта"
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.inputTab}>
                                            <Grid container direction="column" justify="center" alignItems="stretch">
                                                <Grid item>
                                                    <Tabs
                                                        value={value}
                                                        indicatorColor="primary"
                                                        textColor="primary"
                                                        onChange={(event, newValue) => {
                                                            setValue(newValue);
                                                        }}
                                                    >
                                                        <Tab label="Общие" className={classes.tab}/>
                                                        {AuthorizationService.permissions(account, "access_to_planning") &&
                                                            <Tab label="Модуль планирования" className={classes.tab}/>
                                                        }
                                                    </Tabs>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <TabPanel value={value} index={0}>
                                            <Grid item className={classes.inputContent}>
                                                <Grid container className={classes.notificationList} direction="column" justify="space-between" alignItems="stretch">
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.new_contract_created"
                                                                    color="primary"
                                                                    Label={{label: "Создан новый договор"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.contract_edited"
                                                                    color="primary"
                                                                    Label={{label: "Договор отредактирован"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.contract_status_active"
                                                                    color="primary"
                                                                    Label={{label: 'Договор переведен в статус "активный"'}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.contract_status_agreed"
                                                                    color="primary"
                                                                    Label={{label: 'Договор переведен в статус "согласован"'}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.contract_status"
                                                                    color="primary"
                                                                    Label={{label: 'Договор переведен в новый статус (Согласование)'}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.contract_file"
                                                                    color="primary"
                                                                    Label={{label: "Добавление/открепление файла договора"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.contract_price_list"
                                                                    color="primary"
                                                                    Label={{label: "Добавление прайс-листа к договору"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.contract_rejected"
                                                                    color="primary"
                                                                    Label={{label: "Договор отклонен"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.contract_terminated"
                                                                    color="primary"
                                                                    Label={{label: "Договор прекратил свое действие"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.contract_deleted"
                                                                    color="primary"
                                                                    Label={{label: "Договор удален"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.new_delivery_order_created"
                                                                    color="primary"
                                                                    Label={{label: "Создан новый заказ"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.delivery_order_status"
                                                                    color="primary"
                                                                    Label={{label: "Заказ переведен на новый этап"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.delivery_edited"
                                                                    color="primary"
                                                                    Label={{label: "Заказ изменен"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.delivery_order_integration"
                                                                    color="primary"
                                                                    Label={{label: "Оплата по данным 1С"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.delivery_file"
                                                                    color="primary"
                                                                    Label={{label: "Добавление файла к заказу"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.delivery_overdue_date"
                                                                    color="primary"
                                                                    Label={{label: "Срок поставки заказа"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.delivery_overdue_payment_deadline"
                                                                    color="primary"
                                                                    Label={{label: "Срок оплаты заказа"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.delivery_pending_order"
                                                                    color="primary"
                                                                    Label={{label: "Незакрытый заказ"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.change_order_request"
                                                                    color="primary"
                                                                    Label={{label: "Изменение ценового запроса"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.start_rebidding"
                                                                    color="primary"
                                                                    Label={{label: "Начало переторжки"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {AuthorizationService.roles(account, ['supplier', 'supplier_confirmed']) &&
                                                        <Grid item>
                                                            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                                <Grid item className={classes.select}>
                                                                    <Field
                                                                        component={CheckboxWithLabel}
                                                                        name="notifications.invoice"
                                                                        color="primary"
                                                                        Label={{label: "Счет на оплату"}}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                            <Grid item className={classes.inputContent}>
                                                <Grid container className={classes.notificationList} direction="column" justify="space-between" alignItems="stretch">
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.planning_new_comment"
                                                                    color="primary"
                                                                    Label={{label: "Новый комментарий"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item xs={6} className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.planning_change_status_purchase"
                                                                    color="primary"
                                                                    Label={{label: "Смена статуса закупки"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.planning_addition_file"
                                                                    color="primary"
                                                                    Label={{label: 'Прикрепление документа'}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.planning_exclusion_or_addition_procurement"
                                                                    color="primary"
                                                                    Label={{label: 'Исключение или добавление к закупке'}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.planning_create_joint_purchase"
                                                                    color="primary"
                                                                    Label={{label: "Создание совместной закупки"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.planning_approval"
                                                                    color="primary"
                                                                    Label={{label: "Утверждение закупки/ПГ"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.planning_submitting_for_revision"
                                                                    color="primary"
                                                                    Label={{label: "Отправка на доработку закупки/ПГ"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.planning_request_for_edit"
                                                                    color="primary"
                                                                    Label={{label: "Запрос на корректировку закупки/ПГ"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.planning_rejection"
                                                                    color="primary"
                                                                    Label={{label: "Отклонение закупки/ПГ"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.planning_editing_expiration_warning"
                                                                    color="primary"
                                                                    Label={{label: "Предупреждение об окончании срока редактирования закупки/ПГ"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.planning_approaching_date_for_approval"
                                                                    color="primary"
                                                                    Label={{label: "Приближающийся срок утверждения планирования на будущий период"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.planning_upcoming_joint_purchase"
                                                                    color="primary"
                                                                    Label={{label: "Уведомление о предстоящем проведении Совместной закупки"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.planning_create_important_goods_purchase"
                                                                    color="primary"
                                                                    Label={{label: "Создание закупки отдельных и стратегически важных товаров"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.planning_upcoming_important_goods_purchase"
                                                                    color="primary"
                                                                    Label={{label: "Уведомление о предстоящем проведении закупки отдельных и стратегически важных товаров"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.rejection_standard"
                                                                    color="primary"
                                                                    Label={{label: "Отклонение предложенного Номенклатурного наименования"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.change_standard"
                                                                    color="primary"
                                                                    Label={{label: "Изменение Номенклатурного наименования"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item className={classes.select}>
                                                                <Field
                                                                    component={CheckboxWithLabel}
                                                                    name="notifications.chat_message"
                                                                    color="primary"
                                                                    Label={{label: "Новое сообщение в чате"}}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {AuthorizationService.roles(account, ['customer']) &&
                                                        <React.Fragment>
                                                            <Grid item>
                                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                                    <Grid item className={classes.select}>
                                                                        <Field
                                                                            component={CheckboxWithLabel}
                                                                            name="notifications.pucrhase_plan_approve_chuz"
                                                                            color="primary"
                                                                            Label={{label: "Уведомление об приближающемся сроке утверждения планирования на будущий период"}}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                                    <Grid item className={classes.select}>
                                                                        <Field
                                                                            component={CheckboxWithLabel}
                                                                            name="notifications.correction_period"
                                                                            color="primary"
                                                                            Label={{label: "Уведомление об закончевшемся сроке корректировки ПЗ/Закупки"}}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                                    <Grid item className={classes.select}>
                                                                        <Field
                                                                            component={CheckboxWithLabel}
                                                                            name="notifications.lead_time_message"
                                                                            color="primary"
                                                                            Label={{label: "Уведомление об подходящем сроке размещения извещении/документации"}}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                                    <Grid item className={classes.select}>
                                                                        <Field
                                                                            component={CheckboxWithLabel}
                                                                            name="notifications.editing_after_rejection"
                                                                            color="primary"
                                                                            Label={{label: "Уведомление об истечении срока редактирования ПЗ/Закупки"}}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                                    <Grid item className={classes.select}>
                                                                        <Field
                                                                            component={CheckboxWithLabel}
                                                                            name="notifications.send_for_correction"
                                                                            color="primary"
                                                                            Label={{label: "Уведомление об отправке ПЗ/Закупки на корректировку"}}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </React.Fragment>
                                                    }
                                                    {AuthorizationService.roles(account, ['supervisor']) && (account.organization === 'regional') &&
                                                        <Grid item>
                                                            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                                <Grid item className={classes.select}>
                                                                    <Field
                                                                        component={CheckboxWithLabel}
                                                                        name="notifications.pucrhase_plan_approve_rdz"
                                                                        color="primary"
                                                                        Label={{label: "Уведомление об приближающемся сроке утверждения планирования на будущий период"}}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    {AuthorizationService.roles(account, ['supervisor']) && (account.organization === 'central') &&
                                                        <Grid item>
                                                            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                                <Grid item className={classes.select}>
                                                                    <Field
                                                                        component={CheckboxWithLabel}
                                                                        name="notifications.pucrhase_plan_approve_cdz"
                                                                        color="primary"
                                                                        Label={{label: "Уведомление об приближающемся сроке утверждения планирования на будущий период"}}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                        <Grid item className={classes.footer}>
                                            <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                                <Grid item className={classes.footerContent}>
                                                    <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                        <Grid item>
                                                            <Submit
                                                                disableElevation
                                                                variant="contained"
                                                                color="primary"
                                                                type="submit"
                                                                disabled={isSubmitting}
                                                            >
                                                                Сохранить
                                                            </Submit>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    ) : null
}
