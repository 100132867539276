import * as Yup from "yup"

import {isWithExpireDate} from "../../../../App/helpers/category"

function fileValidate(name) {
    return Yup.mixed().test('fileFormat', `${name} должно быть файлом одного из следующих типов: doc, docx, xls, xlsx, pdf.`, (value) => {
        return ((value && [
            'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'
        ].includes(value.type)) || (value && !value.type && value.name) || !Boolean(value?.name));
    }).nullable()
}

function getValidationSchema(order, company, dialog, orderSettings, category, categories, from_planning = false) {
    return Yup.object().shape({
        name: Yup.string().nullable(),
        type: Yup.string().nullable(),
        territory: Yup.string().nullable(),
        ...((order && (order.status.index === 2) && !!dialog) ? {comment: Yup.string().nullable()} : {}),
        application_start: Yup.mixed().test('min-date', 'Некорректная дата', function (value) {
            if (from_planning) {
                return true;
            }
            if (value) {
                if ((!order || (![1, 2].includes(order.status.index))))
                {
                    let currentDate = new Date(value)
                    let minDate = new Date()

                    return currentDate.valueOf() - minDate.valueOf() >= 0
                }
            }

            return true
        }).nullable(),
        application_deadline: Yup.mixed().test('min-date', 'Некорректная дата', function (value) {
            if (from_planning) {
                if (value) {
                    let currentDate = new Date(value)
                    let minDate = new Date(this.parent.application_start)

                    return currentDate.valueOf() - minDate.valueOf() >= 0
                }
                return true;
            }
            if (value) {
                let currentDate = new Date(value)
                let diffTime = Number(orderSettings.time_to_submit_applications) * 3600000;
                let minDate = this.parent.application_start ? new Date(this.parent.application_start) : new Date();

                return currentDate.valueOf() - minDate.valueOf() >= diffTime
            }

            return true
        }).nullable(),
        cooperative_buying: Yup.mixed().nullable(),
        unscheduled_purchase: Yup.mixed().nullable(),
        currency: Yup.string().nullable(),
        reason: Yup.string().when("unscheduled_purchase", {
            is: true,
            then: Yup.string().nullable(),
            otherwise: Yup.string()
        }),
        purchase_number: Yup.string().when("unscheduled_purchase", {
            is: false,
            then: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').length(11, 'Поле должно состоять из 11 цифр!').nullable(),
            otherwise: Yup.string().nullable()
        }),
        params: Yup.object().shape({
            requirements: Yup.string().nullable(),
            documentation: Yup.object().shape({
                term: Yup.string().nullable(),
                address: Yup.string().nullable(),
                procedure: Yup.string().nullable(),
            }),
            payment: Yup.string().nullable(),
            result_date: Yup.mixed().nullable(),
            information: Yup.string().nullable()
        }),
        category: Yup.string().nullable(),
        lots: Yup.array().of(Yup.object().shape({
            max_contract_price: Yup.string().when("is_unit_price", {
                is: false,
                then: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').nullable(),
                otherwise: Yup.string().nullable()
            }),
            execution_month: Yup.mixed().nullable(),
            type: Yup.string().nullable(),
            prepayment: Yup.number().nullable().min(1, 'Пожалуйста, введите значение от 1 до 100').max(100, 'Пожалуйста, введите значение от 1 до 100').nullable(),
            postponement: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!'),
            rationale: fileValidate('Обоснование НМЦД лота'),
            technical_task: fileValidate('Техническое задание'),
            draft_agreement: fileValidate('Проект договора'),
            number: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!').length(4, 'Поле должно состоять из 4 цифр!'),
            params: Yup.object().shape({
                information: Yup.string().nullable(),
                subject: Yup.string().nullable(),
                description: Yup.string().nullable()
            }),
            items: Yup.array().of(Yup.object().shape({
                standard: Yup.mixed().nullable(),
                amount: Yup.string().when("is_unit_price", {
                    is: false,
                    then: Yup.string().matches(/^[1-9]+[0-9]*[.]?[0-9]*$/, 'Поле должно состоять только из цифр и быть больше нуля!').nullable(),
                    otherwise: Yup.string().nullable()
                }),
                params: Yup.object().shape({
                    okpd: Yup.string().nullable(),
                    okved: Yup.string().nullable()
                }),
                average_price: Yup.string().test('numberFormat', 'Поле должно состоять только из цифр и быть больше нуля!', (value) => (Number(value) && Number(value) > 0) || !value),
                price: Yup.string(),
                unit: Yup.object().nullable().shape({
                    id: Yup.number().nullable()
                }),
                expiration_date_type: Yup.string().nullable(),
                expiration_date: Yup.mixed().when('expiration_date_type', (expiration_date_type, schema) => {
                    return ((expiration_date_type === 'expiration_date') && category && categories && isWithExpireDate(category, categories)) ?
                        schema.test('min-date', 'Некорректная дата', function (value) {
                            if (value && (!order || (order.status.index !== 2))) {
                                let currentDate = new Date(value);
                                let minDate = new Date()
                                minDate.setDate(new Date().getDate() + 2)

                                return currentDate.valueOf() - minDate.valueOf() >= 0
                            }

                            return true
                        }).nullable()
                        : schema
                }),
                expiration_date_number: Yup.number().when('expiration_date_type', (expiration_date_type, schema) => {
                    return ((expiration_date_type === 'expiration_date_percent') && category && categories && isWithExpireDate(category, categories)) || (expiration_date_type === 'warranty_period') ? schema.min(0, 'Не может быть меньше 0!').nullable() : schema.nullable()
                }),
            })).nullable(),
            price: Yup.string(),
            files: Yup
                .array()
                .of(Yup.object().shape({
                    file: Yup.mixed().test('fileFormat', 'Документ должен быть файлом одного из следующих типов: csv, xls, xlsx, jpg, jpeg, png, bmp, gif, svg, doc, docx, pdf.', (value) => {
                        return ((value && (value instanceof File) && [
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            'application/vnd.ms-excel',
                            'text/csv',
                            'image/jpeg',
                            'image/gif',
                            'image/svg+xml',
                            'image/bmp',
                            'image/png',
                            'application/msword',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            'application/pdf',
                        ].includes(value.type)) || !value || (value && !(value instanceof File)))
                    }),
                    description: Yup.mixed().when('file', {
                        is: null,
                        then: Yup.mixed().nullable(),
                        otherwise: Yup.mixed().nullable(),
                    }),
                })),
        })).nullable(),
        price: Yup.string(),
        ...(from_planning ? {} : {
            averagePriceTotal: Yup
                .number()
                .when('cooperative_buying', {
                    is: false,
                    then: Yup
                        .number().max(20000000, `Сумма НМЦ лотов не должна превышать ${parseFloat('20000000').toLocaleString('ru-RU', {minimumFractionDigits: 0})} рублей.`),
                    otherwise: Yup
                        .number()
                })
                .when('lots', (lots, schema) =>
                    schema.transform(() =>
                        lots.reduce(function (sum, lot) {
                            return sum + parseFloat(lot && lot.items ? lot.items.reduce(function (sum, item) {
                                return sum + parseFloat(item.average_price ? item.average_price : 0)
                            }, 0) : 0)
                        }, 0),
                    ),
                )
        })
    })
}

export {getValidationSchema};
