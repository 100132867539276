import {HttpService} from "../../App/services/http"

export const OperatorService = {
    lots,
    confirmed,
    notConfirmed,
}

function lots(params) {
    return HttpService.get("/operator/lots", params)
}

function confirmed(id) {
    return HttpService.put(`/operator/lot/${id}/confirmed`)
}

function notConfirmed(id) {
    return HttpService.put(`/operator/lot/${id}/notConfirmed`)
}
