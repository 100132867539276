import React, {useState} from "react"
import {useDispatch} from "react-redux"
import {Link as RouterLink} from "react-router-dom"
import {Field, Form, Formik} from "formik"
import * as Yup from "yup"

import {Grid, Link, makeStyles, Paper, Typography} from "@material-ui/core"

import {AuthActions} from "./actions/authentication"
import {TextField} from "../App/components/Inputs/TextField"
import {Submit} from "../App/components/Button/Submit"

const useStyles = makeStyles(theme => ({
    item: {
        "width": "435px"
    },
    container: {
        "background-image": "linear-gradient(to bottom,#f6f6f6,#eee 85%,#b1b1b1)",
        "border-radius": 0,
        "min-height": "100vh",
        "height": "auto"
    },
    content: {
        "padding": "40px 50px",
    },
    fullWidth: {
        "width": "100%"
    },
    input: {
        "width": "100%",
        "margin-bottom": "25px"
    },
    link: {
        "text-decoration": "underline",
        "color": theme.palette.primary.dark,
        "font-weight": "500",
        "&:hover": {
            color: theme.palette.primary.main
        },
        "font-size": "18px"
    },
    form: {
        "width": "100%",
        "padding": "0 !important"
    },
    submit: {
        "height": "35px",
        "&:disabled": {
            "border-bottom-color": "#898989 !important",
        }
    },
    head: {
        "width": "100%",
        "padding": "0 !important"
    },
    title: {
        "font-size": "24px",
        "color": "#485868",
        "font-weight": "400",
    },
    name: {
        "margin": "20px 0 0 0",
        "font-size": "24px",
        "text-transform": "uppercase",
        "font-weight": "bold",
        "color": "#485868"
    },
    error: {
        "font-size": "16px",
        "font-weight": "500",
        "color": "#f27360",
        "line-height": "unset",
        "margin": "0 0 10px"
    }
}))

export const Login = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [error, setError] = useState(null)

    const onSubmit = (values, {setSubmitting}) => {
        return dispatch(AuthActions.login(values)).then(
            () => {
                setSubmitting(false);
            },
            errorMessage => {
                setError(errorMessage)
                setSubmitting(false);
            }
        )
    }

    return (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.container}>
            <Grid item className={classes.item}>
                <Paper className={classes.content}>
                    <Formik
                        initialValues={{login: "", password: ""}}
                        validationSchema={Yup.object().shape({
                            login: Yup.string()
                                .required("Введите имя пользователя"),
                            password: Yup.string()
                                .required("Введите пароль"),
                        })}
                        onSubmit={onSubmit}
                    >
                        {({
                              values,
                              handleSubmit,
                              isSubmitting
                          }) => (
                            <Form>
                                <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                                    <Grid item className={classes.fullWidth}>
                                        <Grid container direction="column" justify="center" alignItems="stretch"
                                              spacing={2}>
                                            <Grid item className={classes.head}>
                                                <Typography className={classes.title} align={"center"}>
                                                    Электронный магазин Автоматизированная Программа закупок «Ордер Хантер»
                                                </Typography>
                                                <Typography className={classes.name} align={"center"}>
                                                    ВХОД В СИСТЕМУ
                                                </Typography>
                                            </Grid>
                                            <Grid item className={classes.input}>
                                                <Field
                                                    fullWidth
                                                    name="login"
                                                    type="text"
                                                    label="Имя пользователя"
                                                    required={true}
                                                    component={TextField}
                                                />
                                            </Grid>
                                            <Grid item className={classes.input}>
                                                <Field
                                                    fullWidth
                                                    type="password"
                                                    name="password"
                                                    label="Пароль"
                                                    required={true}
                                                    component={TextField}
                                                />
                                            </Grid>
                                            {
                                                error &&
                                                <Grid item>
                                                    <Typography className={classes.error}>
                                                        {error}
                                                    </Typography>
                                                </Grid>
                                            }
                                            <Grid item className={classes.fullWidth}>
                                                <Grid container direction="row" justify="center"
                                                      alignItems="center">
                                                    <Grid item className={classes.fullWidth}>
                                                        <Submit
                                                            className={classes.submit}
                                                            fullWidth
                                                            disableElevation
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={isSubmitting || !(values.login && values.password)}
                                                            onClick={handleSubmit}
                                                            type="submit"
                                                        >
                                                            Войти
                                                        </Submit>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="row" justify="space-between"
                                                      alignItems="center">
                                                    <Grid item>
                                                        <Link component={RouterLink} to={"/recovery"} className={classes.link}>
                                                            Напомнить пароль
                                                        </Link>
                                                    </Grid>
                                                    <Grid item>
                                                        <Link component={RouterLink} to={"/registration"}
                                                              className={classes.link}>
                                                            Регистрация
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Paper>
            </Grid>
        </Grid>
    )
}
