import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import * as Yup from "yup"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    Grid, InputLabel,
    makeStyles, MenuItem,
    StepIcon, TextField,
    Typography
} from "@material-ui/core"
import {Close as CloseIcon} from "@material-ui/icons"

import {TextFieldWithError as CustomTextField} from "../../../App/components/Inputs/TextFieldWithError"
import {Submit} from "../../../App/components/Button/Submit"
import {AuthActions} from "../../../Auth/actions/authentication";
import {WarningActions} from "../../actions/warning";
import {Select} from "formik-material-ui";
import DateFnsUtils from "@date-io/date-fns";
import ru from "date-fns/locale/ru";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

const useStyles = makeStyles(() => ({
    item: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "cursor": "pointer"
    },
    fullWidth: {
        "width": "100%"
    },
    content: {
        "padding": "0 !important"
    },
    input: {
        "width": "100%",
    },
    column: {
        "height": "86px"
    },
    dialogContent: {
        "min-height": "calc(20vh)",
        "max-height": "calc(100vh - 210px)"
    },
    footer: {
        "background-color": "#e7eaee",
        "justify-content": "flex-start"
    },
    dialog: {
        "max-height": "70vh"
    },
    footerContent: {
        "padding": "10px 0px"
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    titleDialog: {
        "& h2": {
            "display": "flex",
            "justify-content": "space-between",
        },
    }
}))

export const Event = (props) => {
    const { event, onClick } = props
    const dispatch = useDispatch()
    const classes = useStyles()
    const { roles } = useSelector(state => state.authentication)

    const [open, setOpen] = useState(props.open)

    useEffect(() => {
        if (props.open) {
            const getRoles = async () => {
                await dispatch(AuthActions.roles())
            }

            if (!roles?.length) {
                getRoles().then(_ => {
                    setOpen(props.open)
                })
            }
        } else {
            setOpen(props.open)
        }
    }, [dispatch, props.open])

    const handleClose = () => {
        setOpen(false)
        props.setDialog(false)
    }

    return open ? (
        <Grid item>
            <Dialog
                open={!!open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle className={classes.titleDialog}>
                    {event ? 'Редактировать уведомление' : 'Добавить уведомление'}
                    <CloseIcon className={classes.close} onClick={handleClose}/>
                </DialogTitle>
                <Formik
                    enableReinitialize
                    initialValues={{
                        type: event ? event.type : null,
                        message: event ? event.message : '',
                        validity: event?.validity ? new Date(event.validity) : null,
                        roles: event ? event.roles?.map(role => role.id) : []
                    }}
                    validationSchema={Yup.object().shape({
                        type: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        message: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                        validity: Yup.mixed().nullable(),
                        roles: Yup.array().required("Поле не может быть пустым или состоять из одних пробелов!")
                    })}
                    onSubmit={(values, {setSubmitting, setErrors}) => {
                        dispatch(event ? WarningActions.edit(event.id, values) : WarningActions.add(values)).then(
                            (event) => {
                                setSubmitting(false)
                                if (onClick) {
                                    onClick(event)
                                }
                                handleClose()
                            },
                            error => {
                                if (error.errors) {
                                    setErrors(error.errors)
                                }
                                setSubmitting(false);
                            }
                        )
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        dirty,
                        handleSubmit,
                        setFieldValue,
                        setFieldTouched,
                        isSubmitting,
                        isValid
                    }) => (
                        <Form>
                            <DialogContent className={classes.dialogContent}>
                                <Grid container className={classes.fullWidth} direction="row" justify="center" alignItems="stretch">
                                    <Grid item className={classes.fullWidth}>
                                        <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
                                            <Grid item>
                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                    <Grid item xs={4} className={classes.column}>
                                                        <FormControl className={classes.fullWidth}>
                                                            <InputLabel shrink={true} htmlFor="roles">
                                                                Тип
                                                            </InputLabel>
                                                            <Field
                                                                fullWidth
                                                                required
                                                                type="text"
                                                                name="type"
                                                                label="Тип"
                                                                component={Select}
                                                            >
                                                                {[{name: 'Обычное', key: 'common'}, {name: 'Критичное', key: 'critical'}].map((type) => (
                                                                    <MenuItem key={type.key} value={type.key}>
                                                                        {type.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={4} className={classes.column}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                            <KeyboardDateTimePicker
                                                                disableToolbar
                                                                fullWidth
                                                                error={Boolean(errors?.hasOwnProperty('validity') && touched?.hasOwnProperty('validity'))}
                                                                helperText={(errors?.hasOwnProperty('validity') && touched?.hasOwnProperty('validity')) ? errors?.validity : null}
                                                                name="validity"
                                                                format="HH:mm dd-MM-yyyy"
                                                                variant="inline"
                                                                openTo="year"
                                                                label="Срок отображения (дата и время)"
                                                                autoOk
                                                                value={values.validity}
                                                                onChange={date => {
                                                                    setFieldValue("validity", date);
                                                                }}
                                                                onBlur={() => {
                                                                    setFieldTouched("validity", true, false)
                                                                }}
                                                                onClose={() => {
                                                                    setFieldTouched("validity", true, false)
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Grid>
                                                    <Grid item xs={4} className={classes.column}>
                                                        <FormControl className={classes.fullWidth}>
                                                            <InputLabel shrink={true} htmlFor="roles">
                                                                Роли
                                                            </InputLabel>
                                                            <Field
                                                                fullWidth
                                                                required
                                                                type="text"
                                                                name="roles"
                                                                label="Роли"
                                                                component={Select}
                                                                multiple
                                                            >
                                                                {roles?.filter(role => ['user', 'operator', 'supplier', 'customer', 'supervisor'].includes(role.name)).map((role) => (
                                                                    <MenuItem key={role.id} value={role.id}>
                                                                        {role.description}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                    <Grid item xs={12} className={classes.column}>
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            name="message"
                                                            label="Сообщение"
                                                            rows={3}
                                                            rowsMax={3}
                                                            value={values.message}
                                                            multiline
                                                            onChange={(e) => setFieldValue(`message`, e.target.value)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions className={classes.footer}>
                                <Grid container className={ classes.footerContent} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Submit
                                            variant="contained"
                                            component="label"
                                            color="primary"
                                            disabled={isSubmitting || !isValid || !dirty}
                                            onClick={handleSubmit}
                                            type="submit"
                                        >
                                            {event ? 'Сохранить' : 'Добавить'}
                                        </Submit>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </Grid>
    ) : null
}
