import * as React from 'react';
import {Button, IconButton, Snackbar, Typography, Grid} from "@material-ui/core";
import {useState} from "react";
import {Close} from "@material-ui/icons";
import {useSelector} from "react-redux";
import * as AppStorage from "../helpers/storage";

export const Cookies = () => {
  const {account} = useSelector(state => state.account)
  const [open, setOpen] = useState(!AppStorage.get('cookies'))

  const action = (
    <Grid
      container
      direction="row"
    >
      <Grid item>
        <Button
          color="primary"
          size="small"
          onClick={() => {
            AppStorage.set('cookies', true)
            setOpen(false)
          }}
        >
          Принять и закрыть
        </Button>
      </Grid>
      <Grid item>
        <IconButton
          size="small"
          color="inherit"
          onClick={() => {
            AppStorage.set('cookies', false)
            setOpen(false)
          }}
        >
          <Close fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  );

  return account ? (
    <Snackbar
      style={{
        width: '50%'
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={open}
      message={<Typography variant="caption" style={{ color: 'white' }}>Мы используем cookie-файлы для целей персонализации сервисов и повышения удобства пользования Сайтом. Если вы не хотите использовать файлы cookie, измените настройки браузера. Продолжая использовать сайт, Вы соглашаетесь с использованием cookie-файлов в соответствии с Политикой в отношении обработки персональных данных</Typography>}
      action={action}
    />
  ) : null
}