import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import format from "date-fns/format"
import DateFnsUtils from "@date-io/date-fns"
import {ru} from "date-fns/locale"

import {
    FormControl,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    MenuItem,
    Select,
    TablePagination,
    Typography,
    withStyles
} from '@material-ui/core'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"

import {ReportActions} from "../../actions/report"
import {AuthorizationService} from "../../../Auth/services/authorization"

const useStyles = makeStyles(theme => ({
    item: {
        "padding": "16px"
    },
    history: {
        "padding": "8px 16px 0 16px",
        'border-bottom': "1px solid #e4e4e4"
    },
    historyItem: {
        "& .MuiListItemText-primary" : {
            "text-transform": "none !important"
        }
    },
    title: {
        "font-size": "18px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    list: {
        "overflow": "auto",
        "height": "calc(100vh - 350px)"
    },
    date: {
        width: "115px",
        "& .MuiInputAdornment-root .MuiButtonBase-root": {
            padding: 0,
        }
    },
    paginationWrap: {
        backgroundColor: "#e7eaee",
        position: "fixed",
        bottom: "0",
        maxWidth: "300px"
    },
    pagination: {
        "&>div": {
            flexWrap: 'wrap'
        }
    },
}))

const CustomList = withStyles({
    root: {
        "padding": "0",
        "& .MuiListItem-button": {
            "cursor": "pointer",
            "align-items": "center",
            "min-height": "50px",
            "padding": "0 16px",
            "border-bottom": "1px solid #e4e4e4",
            "outline": "0"
        },
        "& .MuiListItemText-primary": {
            "font-size": "16px",
            "font-weight": "700",
            "text-transform": "uppercase"
        },
        "& .MuiListItem-button:hover": {
            "background-color": "rgba(0,0,0,.04)",
            "&:after": {
                "position": "absolute",
                "content": "''",
                "top": "0",
                "left": "0",
                "width": "100%",
                "height": "100%",
                "background-color": "rgba(0,0,0,.04)"
            }
        }
    }
})(List);

export const Menu = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { account, reports,filter } = useSelector(state => { return {...state.account, ...state.report} })

    const [beginning, setBeginning] = useState(() => {
        const now = new Date()

        return now.setMonth(now.getMonth() - 1)
    })
    const [ending, setEnding] = useState(new Date())
    const [type, setType] = useState(filter.type ? filter.type.value : '')

    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [page, setPage] = useState(1)

    const types = [
        ...(AuthorizationService.permissions(account, "access_to_elmag") ? [
            {
                name: 'История закупок',
                value: 'purchase_history',
            }
        ] : []),
        ...[
            {
                name: 'История заказов',
                value: 'delivery_history',
            },
            {
                name: 'Статистика заказов',
                value: 'delivery_statistic',
            },
            {
                name: 'Статистика заказов по категориям',
                value: 'delivery_statistic_category',
            }
        ],
        ...(AuthorizationService.roles(account, "customer") ? [
            {
                name: 'Исполнение договора',
                value: 'execution_contract',
            },
            {
                name: 'По срокам поставки',
                value: 'delivery_times',
            },
            {
                name: 'Заказы постфактум',
                value: 'delivery_orders_after_fact',
            },
            {
                name: 'По заказам',
                value: 'delivery_orders',
            }
        ] : [])
    ]

    useEffect(() => {
        const getReports = async () => {
            return await dispatch(ReportActions.reports({
                beginning: format(beginning, 'yyyy-MM-dd'),
                ending: format(ending, 'yyyy-MM-dd'),
                type: type,
                limit: rowsPerPage,
                page: page
            }))
        }

        if (type) {
            getReports().then(r => {
                dispatch({type: "REPORT_FILE_CLEAR"})
                dispatch({type: 'REPORTS_FILTER',
                    payload: {
                        type: types.find(item => (item.value === type)) ?? null,
                        beginning: format(beginning, 'yyyy-MM-dd'),
                        ending: format(ending, 'yyyy-MM-dd')
                    }
                })
            })
        }
        // eslint-disable-next-line
    }, [dispatch, type, beginning, ending,rowsPerPage,page]);

    const handleChangePage = (event, newPage) => {
        setPage(Number(newPage) + 1)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(1)
    }

    return (
        <Grid container className={classes.container} direction="column" justify="flex-start" alignItems="stretch">
            <Grid item className={classes.item}>
                <Grid container className={classes.container} direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                    <Grid item>
                        <FormControl fullWidth>
                            <InputLabel>Тип отчёта</InputLabel>
                            <Select
                                value={type}
                                onChange={e => {
                                    setType(e.target.value)
                                }}
                            >
                                {types.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Grid container className={classes.container} direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                    <KeyboardDatePicker
                                        className={classes.date}
                                        fullWidth
                                        invalidDateMessage="Некорректная дата"
                                        minDate={new Date("1970-01-01")}
                                        minDateMessage={""}
                                        disableToolbar
                                        orientation="landscape"
                                        variant="inline"
                                        openTo="year"
                                        name="beginning"
                                        format="dd-MM-yyyy"
                                        autoOk
                                        required
                                        label={'Дата события с'}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={beginning}
                                        onChange={date => {
                                            setBeginning(date)
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                    <KeyboardDatePicker
                                        className={classes.date}
                                        fullWidth
                                        invalidDateMessage="Некорректная дата"
                                        disableToolbar
                                        orientation="landscape"
                                        variant="inline"
                                        openTo="year"
                                        name="ending"
                                        format="dd-MM-yyyy"
                                        autoOk
                                        required
                                        label={'по'}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={ending}
                                        onChange={date => {
                                            setEnding(date)
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.history}>
                {type && <Typography variant="overline" gutterBottom className={classes.title}>История</Typography>}
            </Grid>
            <Grid item>
                <CustomList className={classes.list}>
                    {reports && reports.data && reports.data.map((report, index) => (
                        <ListItem
                            button
                            key={report.id}
                            onClick={() => {
                                dispatch(ReportActions.file(report)).then(
                                    () => {

                                    },
                                    error => {
                                    }
                                )
                            }}
                        >
                            <ListItemText className={classes.historyItem}
                                primary={
                                    <React.Fragment>
                                        <Grid container className={classes.container} direction="column" justify="flex-start" alignItems="stretch">
                                            <Grid item>
                                                <Grid container className={classes.container} direction="row" justify="space-between" alignItems="stretch">
                                                    <Grid item>
                                                        № {report.id}
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography component={'span'} variant="body2">
                                                            {format(new Date(report.created), 'H:mm PP',  {locale: ru})}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container className={classes.container} direction="column" justify="flex-start" alignItems="stretch">
                                                    <Grid item>
                                                        <Typography component={'span'} variant="body2">
                                                            Период:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="space-between" alignItems="stretch">
                                                            <Grid item>
                                                                <Typography component={'span'} variant="body2">
                                                                    {report.beginning} - {report.ending}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography component={'span'} variant="body2">
                                                                    {!report.formed && 'Отчёт формируется'}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    ))}
                </CustomList>
            </Grid>
            <ListItem className={classes.paginationWrap}>
                <TablePagination
                    rowsPerPageOptions={[5, 15, 25, 50, 100]}
                    component='div'
                    className={classes.pagination}
                    labelRowsPerPage={'Записей на странице:'}
                    count={(reports && reports.meta) ? reports.meta.total : 0}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </ListItem>
        </Grid>
    )
}
