import * as AppStorage from "../../App/helpers/storage"

export default function reclamation(state = {
    filter: AppStorage.get('filterDeliveryReclamations') ? JSON.parse(AppStorage.get('filterDeliveryReclamations')) : {
        status: '',
        company: null,
        created: {
            from: null,
            to: null
        }
    },
    searchFilter: AppStorage.get('searchFilterDeliveryReclamations') ? JSON.parse(AppStorage.get('searchFilterDeliveryReclamations')) : {
        search: ''
    },
    reclamation: null,
    status: [],
    types: [],
    demands: []
}, action) {
    switch (action.type) {
        case "DELIVERY_RECLAMATIONS":
            return {
                ...state,
                ...action.payload
            }
        case "DELIVERY_RECLAMATIONS_FAILURE":
            return state
        case "DELIVERY_RECLAMATIONS_FILTER":
            AppStorage.set('filterDeliveryReclamations', JSON.stringify(action.payload))
            return {
                ...state,
                ...{
                    filter: {
                        ...state.filter,
                        ...action.payload
                    }
                }
            }
        case "DELIVERY_RECLAMATION_REQUEST":
            return state
        case "DELIVERY_RECLAMATION":
            return {
                ...state,
                ...{
                    reclamation: action.payload
                }
            }
        case "DELIVERY_RECLAMATION_FAILURE":
            return state
        case "DELIVERY_ADD_RECLAMATION_REQUEST":
            return state
        case "DELIVERY_ADD_RECLAMATION":
            return state
        case "DELIVERY_ADD_RECLAMATION_FAILURE":
            return state
        case "DELIVERY_RECLAMATION_ACTION_REQUEST":
            return state
        case "DELIVERY_RECLAMATION_ACTION_SUCCESS":
            return state
        case "DELIVERY_RECLAMATION_ACTION_FAILURE":
            return state
        case "DELIVERY_RECLAMATIONS_CLEAR":
            return {
                ...state,
                ...{
                    filter: {
                        status: '',
                        company: null,
                        created: {
                            from: null,
                            to: null
                        }
                    },
                    searchFilter: {
                        search: ''
                    }
                }
            }
        case "DELIVERY_RECLAMATION_STATUS_REQUEST":
            return state
        case "DELIVERY_RECLAMATION_STATUS":
            return {
                ...state,
                ...{
                    status: action.payload
                }
            }
        case "DELIVERY_RECLAMATION_STATUS_FAILURE":
            return state
        case "DELIVERY_RECLAMATION_TYPES_REQUEST":
            return state
        case "DELIVERY_RECLAMATION_TYPES":
            return {
                ...state,
                ...{
                    types: action.payload
                }
            }
        case "DELIVERY_RECLAMATION_TYPES_FAILURE":
            return state
        case "DELIVERY_RECLAMATION_DEMANDS_REQUEST":
            return state
        case "DELIVERY_RECLAMATION_DEMANDS":
            return {
                ...state,
                ...{
                    demands: action.payload
                }
            }
        case "DELIVERY_RECLAMATION_DEMANDS_FAILURE":
            return state
        case "DELIVERY_RECLAMATION_SEARCH_FILTER":
            AppStorage.set('searchFilterDeliveryReclamations', JSON.stringify(action.payload))
            return  {
                ...state,
                ...{
                    searchFilter: action.payload
                }
            }
        default:
            return state
    }
}
