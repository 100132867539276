import {HttpService} from "../../App/services/http"

export const CatalogService = {
    products,
    items
}

function products(params) {
    return HttpService.get(`/catalog/products`, params)
}

function items(params) {
    return HttpService.get(`/catalog/items`, params)
        .then(response => {
            return response.items
        })
}
