import {PurchasePlanService} from "../services/purchasePlan"

export const PurchasePlanActions = {
    plans,
    total,
    copyPurchases,
    add,
    plan,
    copyPlan,
    approve,
    decline,
    edit,
    deletePurchasePlans,
    purchasePlansEditRequests,
    sendForCorrection,
    requestForEdit,
    purchaseFilter
}


function edit(purchasePlanID, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_PLAN_EDIT_REQUEST"})

        PurchasePlanService.edit(purchasePlanID, params)
            .then(
                _ => {
                    dispatch({type: "PURCHASE_PLAN_EDIT_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'План закупки успешно отредактирован',
                            type:'success'
                        }
                    })
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_PLAN_EDIT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function sendForCorrection(ids) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        dispatch({
            type: "ALERT_SUCCESS", payload: {
                message: 'План закупки отправлен на корректировку',
                type: 'success'
            }
        })

        PurchasePlanService.sendForCorrection(ids)
            .then(_ => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                }, error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function requestForEdit(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_PLANS_REQUEST_FOR_EDIT_REQUEST"})

        PurchasePlanService.requestForEdit(values)
            .then(
                response => {
                    dispatch({type: "PURCHASE_PLANS_REQUEST_FOR_EDIT_SUCCESS"})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Запрос на корректировку отправлен',
                            type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_PLANS_REQUEST_FOR_EDIT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function approve(ids) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_PLAN_APPROVE_REQUEST"})

        PurchasePlanService.approve(ids)
            .then(
                _ => {
                    dispatch({type: "PURCHASE_PLAN_APPROVE_SUCCESS"})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'План закупки отправлен на утверждение',
                            type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_PLAN_APPROVE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )
    })
}

function decline(ids) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_PLAN_DECLINE_REQUEST"})

        PurchasePlanService.decline(ids)
            .then(
                _ => {
                    dispatch({type: "PURCHASE_PLAN_DECLINE_SUCCESS"})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                        message:'План закупки отклонен',
                        type:'success'
                    }})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_PLAN_DECLINE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )
    })
}

function plans(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PurchasePlanService.plans(params)
            .then(
                response => {
                    dispatch({type: "PURCHASE_PLANS_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "PURCHASE_PLANS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function total(params) {
    return dispatch => new Promise((resolve, reject) => {
        PurchasePlanService.plans({...params, meta: 'total'})
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    reject(error)
                }
            )
    })
}

function copyPurchases(purchasePlanID, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_PLAN_PURCHASES_COPY_REQUEST"})

        PurchasePlanService.copyPurchases(purchasePlanID, values)
            .then(
                () => {
                    dispatch({type: "PURCHASE_PLAN_PURCHASES_COPY_SUCCESS"})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Закупки успешно скопированы',
                            type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_PLAN_PURCHASES_COPY_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function add(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_PLAN_ADD_REQUEST"})

        PurchasePlanService.add(params)
            .then(
                resp => {
                    dispatch({type: "PURCHASE_PLAN_ADD_SUCCESS", payload: {data: resp.data, meta: resp.meta}})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'План закупки успешно создан',
                            type:'success'
                        }
                    })
                    resolve(resp)
                },
                error => {
                    dispatch({type: "PURCHASE_PLAN_ADD_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function plan(id, local = false) {
    return dispatch => new Promise((resolve, reject) => {
        if (!local) {
            dispatch({type: "LOADING", payload: true})
            dispatch({type: "PURCHASE_PLAN"})
        }

        PurchasePlanService.plan(id)
            .then(
                response => {
                    if (!local) {
                        dispatch({type: "PURCHASE_PLAN_SUCCESS", payload: response})
                        dispatch({type: "LOADING", payload: false})
                    }
                    resolve(response.plan)
                },
                error => {
                    if (!local) {
                        dispatch({type: "PURCHASE_FAILURE"})
                        dispatch({type: "LOADING", payload: false})
                    }
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject()
                }
            )

    })
}

function copyPlan(purchasePlanID, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_PLAN_COPY_REQUEST"})

        PurchasePlanService.copyPlan(purchasePlanID, values)
            .then(
                () => {
                    dispatch({type: "PURCHASE_PLAN_COPY_SUCCESS"})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'План закупки успешно скопирован',
                            type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_PLAN_COPY_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function deletePurchasePlans(ids) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_PLAN_DELETE_REQUEST"})

        PurchasePlanService.deletePurchasePlans(ids)
            .then(
                _ => {
                    dispatch({type: "PURCHASE_PLAN_DELETE_SUCCESS"})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Планы закупок успешно удалены',
                            type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_PLAN_DELETE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function purchasePlansEditRequests(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PurchasePlanService.plans(params)
            .then(
                purchasePlans => {
                    dispatch({type: "PURCHASE_PLANS_EDIT_REQUESTS_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(purchasePlans)
                },
                error => {
                    dispatch({type: "PURCHASE_PLANS_EDIT_REQUESTS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function purchaseFilter(params) {
    return dispatch => new Promise((resolve, reject) => {
        PurchasePlanService.purchaseFilter(params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    reject(error)
                }
            )
    })
}

