import {HttpService} from "../../App/services/http"

export const ShopService = {
    pricesContract,
    getProduct,
    updateProduct,
    imageProduct,
    downloadFromElmag,
    unsetStandardInfo,
    prices
}

function pricesContract(params) {
    return HttpService.get("/contract/supplier/prices", params)
}

function prices(params) {
    return HttpService.get("/prices", params)
}

function getProduct(id) {
    return HttpService.get(`/catalog/product/${id}`)
        .then(response => {
            return response.product
        })
}

function updateProduct(id, values) {
    return HttpService.post(`/catalog/product/${id}`, values)
}

function imageProduct(id, formData) {
    return HttpService.post(`/catalog/product/${id}/image`, formData, true, true)
}

function downloadFromElmag(id, formData) {
    return HttpService.post(`/contract/${id}/price`, formData, true, true)
}

function unsetStandardInfo(id) {
    return HttpService.put(`/catalog/product/${id}/standard/unset`)
}
