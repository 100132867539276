import {HttpService} from "../../App/services/http"

export const SupplierService = {
    offers,
    editOffer,
}

function offers(supplierId, params) {
    return HttpService.get(`/company/suppliers/${supplierId}/offers`, params)
}

function editOffer(offerId, values) {
    return HttpService.put(`/company/supplier/offers/${offerId}/items`, values)
}
