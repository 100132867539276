import React from "react"
import {fieldToTextField} from "formik-material-ui"

import {FormControl, FormHelperText, Input, InputAdornment, InputLabel} from "@material-ui/core"

const getProperty = (obj, prop) => {
    return prop.split('.')
        .reduce((m, i) => {
            return m && typeof m == 'object' ? m[i] : null;
        }, obj);
}

export const TextFieldWithError = (props) => {
    const {
        type,
        form: {touched, errors},
        field: {name}
    } = props

    const {
        label,
        multiline,
        fullWidth,
        helperText,
        inputProps,
        inputComponent,
        endAdornment,
        ...otherProps
    } = fieldToTextField(props)

    let error = undefined

    if (getProperty(touched, name)) {
        error = getProperty(errors, name)
    }

    return (
        <FormControl fullWidth={fullWidth}>
            <InputLabel {...otherProps}>{label}</InputLabel>
            <Input
                multiline={multiline}
                {...otherProps}
                inputProps={inputProps}
                onChange={inputProps && inputProps.hasOwnProperty('onChange') ? inputProps.onChange : function (event) {
                    props.form.setFieldValue(props.field.name, event.target.value)
                }}
                inputComponent={inputComponent}
                startAdornment={type === 'phone' ? <InputAdornment position="start">+7</InputAdornment> : null}
                endAdornment={endAdornment}
            />
            {(error || helperText) &&
                <FormHelperText error={!!error}>{error ?? helperText}</FormHelperText>
            }
        </FormControl>
    )
}
