export default function discountPrices(state = {priceLists: {data: [], meta: {total: 0, per_page: 15, current_page: 1}}}, action) {
    switch (action.type) {
        case "DISCOUNT_PRICES_REQUEST":
            return state
        case "DISCOUNT_PRICES_SUCCESS":
            let data = action.payload.data
            delete action.payload.data
            let meta = action.payload
            return {
                priceLists: {
                    data: data,
                    ...{...meta},
                }
            }
        case "DISCOUNT_PRICES_FAILURE":
            return state
        case "DISCOUNT_PRICE_REQUEST":
            return state
        case "DISCOUNT_PRICE_SUCCESS":
            return {
                price: action.payload,
                ...state
            }
        case "DISCOUNT_PRICE_FAILURE":
            return state
        default:
            return state
    }
}
