import React, { useState } from 'react'
import { Grid, makeStyles, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
    selectAllBtn: {
        width: "100%",
        marginTop: "4px",
        borderBottom: "3px solid rgb(220 220 220)"
    }
}))

export const CustomAutocomplete = (props) => {
    const classes = useStyles()

    const {items, name, label, values, setValues, getObject} = props
    const [value, setValue] = useState('')

    return (
        <Autocomplete
            required
            options={(items.length > 1) ? [{id: 'select_all', name: 'Выбрать все'}, ...items] : items}
            inputValue={value}
            onChange={(e, option)=> {
                if (option && option.hasOwnProperty('id')) {
                    if (option.id === 'select_all') {
                        setValues({
                            ...values,
                            ...{[name]: [
                                ...values[name],
                                ...items.map(getObject)
                            ]}
                        })
                    } else {
                        setValues({
                            ...values,
                            ...{[name]: [
                                ...values[name],
                                ...[option].map(getObject)
                            ]}
                        })
                    }
                }

                setValue('')
            }}
            renderOption={(option) => (
                (option.id === "select_all")
                ? <Grid className={classes.selectAllBtn}>
                    {option.name}
                </Grid>
                : <Grid>{option.name}</Grid>
            )}
            noOptionsText='Нет доступных вариантов'
            getOptionLabel={(option) => option.name ?? ''}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label={label}
                    onChange={(e) => {
                        setValue(e.target.value)
                    }}
                />
            )}
        />
    );
}
