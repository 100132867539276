import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik, getIn} from "formik"
import {TextField} from "formik-material-ui"
import {v4 as uuidv4} from 'uuid'
import format from "date-fns/format"
import DateFnsUtils from "@date-io/date-fns"
import ru from "date-fns/locale/ru"

import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    TextField as MuiTextField,
    Paper,
    MenuItem,
    Typography
} from "@material-ui/core"
import {Autocomplete} from "@material-ui/lab"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"
import {Close as CloseIcon, Add as AddIcon, Remove as RemoveIcon} from "@material-ui/icons"

import {TextFieldWithError as CustomTextField} from "../../App/components/Inputs/TextFieldWithError"
import {FileUpload} from "../../App/components/Inputs/FileUpload"
import {Submit} from "../../App/components/Button/Submit"
import {taxPercentValuesCatalogPrice} from '../../App/dics/dic'
import {PriceActions} from "../actions/price"
import {ButtonIcon} from "../../App/components/Button/ButtonIcon"
import {SystemActions} from "../../App/actions/system"
import {isWithExpireDate} from "../../App/helpers/category"
import {getValidationSchema} from "./validation/addProductDialog"
import {Category} from "../../App/components/Inputs/Category";
import {Standard} from "../../App/components/Inputs/Standard";

const useStyles = makeStyles(() => ({
    rootColumn: {
        "padding": "20px",
    },
    title: {
        "margin": "0",
        "padding-top": "2px",
        "font-size": "18px",
        "font-weight": "600",
        "color": "#fff",
        "text-transform": "uppercase",
        "display": "flex",
        "justify-content": "space-between",
        "align-items": "center",
        "min-height": "40px",
        "padding": "0 20px",
        "background-color": "#898989",
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    footerContainer: {
        "padding": "0 25px",
        "background-color": "#e7eaee",
        "height": "75px",
        margin: 0,
    },
    container: {
        "min-height": "calc(100vh - 535px)",
        "max-height": "calc(100vh - 300px)",
        "overflow": "auto",
        "width": "100%",
    },
    dialogContent: {
        padding: "0",
        "overflow": "hidden",
    },
    dialogContainer: {
        "& > div": {
            "max-width": "calc(100vw - 400px)"
        }

    },
    paper: {
        minHeight: "450px",
        marginBottom: "15px",
        boxShadow: "0 0px 5px rgba(0,0,0,.4) !important",
        borderBottom: "3px solid #c1c1c1 !important",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
        borderTop: "none !important",
    },
    select: {
        "& .MuiSelect-select:focus": {
            "background-color": "#fff",
        },
    },
    minus: {
        "&:hover": {
            "background-color": "#ad3e3b",
        },
        display: "inline-flex",
        marginTop: "0",
        "width": "32px",
        "min-width": "30px",
        "max-height": "35px",
        "padding": "0",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "30px",
        "font-size": "16px",
        "color": "#fff",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-bottom": "3px solid #ad3e3b",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "#d9534f",
        "cursor": "pointer",
        marginLeft: "2px",
    },
    fileUpload: {
        display: "inline-flex",
    },
    selectCategoryError: {
        "& .dx-texteditor.dx-editor-outlined": {
            "transform": "scaleX(1)",
            "border": "unset !important",
            "border-bottom": "2px solid #f27360 !important",
            "border-radius": "unset",
        },
        "& .dx-texteditor-input": {
            "padding": "0px !important",
            "min-height": "35px",
        },
        "margin-top": "-5px",
    },
    selectCategory: {
        "& .dx-texteditor.dx-editor-outlined": {
            "border": "unset !important",
            "border-bottom": "2px solid #c1c1c1 !important",
            "border-radius": "unset",
            "&:hover": {
                "border-bottom": "2px solid #898989 !important",
            },
        },
        "& .dx-texteditor-input": {
            "padding": "0px !important",
        },
        "&:after":{
            "transform": "scaleX(1)",
            "border-bottom-color": "9px solid #f27360 !important"
        }
    }
}));

export const AddProductDialog = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const {open, onClose, productId, mode} = props

    const {units, categories} = useSelector(state => state.system)
    const {activePrice, getPrice} = useSelector(state => state.prices)
    const {product} = useSelector(state => state.products)
    const {account} = useSelector(state => state.account)

    const [photoUrl, setPhotoUrl] = useState('')

    const readOnly = mode === 'view'

    const getPriceNameFromActive = () => {
        return activePrice && activePrice.hasOwnProperty('name') ? (<div>Добавление товара к прайс-листу: <span className={classes.priceName}>{activePrice.name}</span></div>) : (<span className={classes.priceName}>Добавление товара в общий доступ</span>)
    }

    const dialogTitle = ['edit', 'view'].indexOf(mode) !== -1 ? 'Карточка товара' : getPriceNameFromActive();

    useEffect(() => {
        const getData = async () => {
            if (!categories.length) {
                await dispatch(SystemActions.categories())
            }
            await dispatch(SystemActions.units())
        }

        getData().then(() => {})
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        const getProduct = async () => {
            return await dispatch(PriceActions.getProduct(productId))
        }
        if (['edit', 'view'].indexOf(mode) !== -1) {
            getProduct().then(response => {
                setPhotoUrl(response ? response.photo_path : '')
            })
        }
        return () => {
            dispatch({ type: "CLEAR_EDIT_PRODUCT"})
        }
        // eslint-disable-next-line
    }, [dispatch, productId])

    const getInitValues = (product) => {
        return product ? {
            standard: {
                name: product.name,
                id: product.standard?.id,
                category: {
                    id: product.category_id
                }
            },
            tax_percent: product.tax_percent,
            article_number: product.article_number,
            unit: product.unit,
            photo: '',
            category: product.category ?? null,
            category_id: product.category_id ? product.category_id : null,
            producer: product.producer ? product.producer : "",
            site_producer: product.site_producer ? product.site_producer : "",
            stock_quantity: product.stock_quantity,
            description: product.description ? product.description : "",
            min_ordered_quantity: product.min_ordered_quantity ? product.min_ordered_quantity : 0,
            price: product.price ? parseFloat(product.price).toFixed(2) : '',
            expiration_date: product.expiration_date ? new Date(product.expiration_date) : null,
            warehouse_id: product.warehouse_id ?? '',
            price_id: product.price_id,
            companyId: account.company.id,
            project_name: 'elmag'
        } : {
            standard: {
                name: null,
                id: null,
                category: {
                    id: null
                }
            },
            tax_percent: '',
            category: null,
            category_id: null,
            article_number: '',
            unit: '',
            photo: '',
            producer: '',
            site_producer: '',
            stock_quantity: '',
            description: '',
            min_ordered_quantity: 0,
            price: '',
            expiration_date: null,
            warehouse_id: '',
            price_id: activePrice && activePrice.hasOwnProperty('priceId') && activePrice.priceId ? activePrice.priceId : uuidv4(),
            companyId: activePrice && activePrice.hasOwnProperty('company_id') && activePrice.company_id ? activePrice.company_id : account.company.id,
            project_name: 'elmag'
        }
    }

    const isDisabled = (mode) => ((mode === 'edit') || readOnly)

    const onSubmit = (values, {setSubmitting}) => {
        const formData = new FormData()
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                switch (key) {
                    case 'expiration_date':
                        formData.append(key, values[key] ? format(values[key], 'yyyy-MM-dd') : '')
                        break
                    case 'standard':
                        formData.append(key, JSON.stringify(values[key]))
                        break
                    case 'unit':
                        formData.append(key, values[key].id)
                        break
                    default:
                        formData.append(key, values[key])
                }
            }
        }

        return dispatch( (mode === 'create') ? PriceActions.addProduct(formData, activePrice?.price_token) : PriceActions.updateProduct(productId, values)).then(() => {
            setSubmitting(false);
            dispatch({type: "SET_GET_PRICE", payload: !getPrice})
        }, errors => {
            if (errors) {}
            setSubmitting(false);
        })
    }

    const onAddStandard = (item, setFieldValue, values, setValues) => {
        setFieldValue('standard', item ?? null)
        if (item && item.hasOwnProperty('category') && item.category.id) {
            setValues({
                ...values,
                ...{
                    standard: item,
                    category: item.category,
                    category_id: item.category.id
                }
            })
        }
    }

    const removePhoto = (e, setFieldValue) => {
        e.stopPropagation()
        setFieldValue(`photo`, '')
        setPhotoUrl(null)
    }

    const unitOptionLabel = (unit) => unit ? `${unit.name}` : ''

    const onChangeUnit = (unit, setFieldValue) => {
        setFieldValue(`unit`, unit ? unit : '')
    }

    const unitRenderInput = (params, touched, errors) => {
        const error = getIn(touched, 'unit') && getIn(errors, 'unit');

        return <Field
            fullWidth
            component={MuiTextField}
            required={true}
            {...params}
            error={!!error}
            helperText={error ? error.id : null}
            name={'unit'}
            label="Единица измерения"
        />
    }

    return (
        <Dialog
            open={open}
            classes={{
                root: classes.dialog,
                container: classes.dialogContainer
            }}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle id="simple-dialog-title" classes={{root: classes.title,}} disableTypography={true}>
                {dialogTitle}
                <CloseIcon
                    className={classes.close}
                    onClick={(e) => {
                        setPhotoUrl('')
                        onClose(e)
                    }}
                />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {(product || (mode === 'create')) ? (
                    <Formik
                        validateOnBlur={false}
                        initialValues={getInitValues(product)}
                        validationSchema={getValidationSchema(product)}
                        onSubmit={onSubmit}
                    >
                        {({
                              values,
                              errors,
                              isSubmitting,
                              setFieldValue,
                              setFieldTouched,
                              handleSubmit,
                              setValues,
                              touched
                          }) => (
                            <Form className={classes.form}>
                                <Grid item>
                                    <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                                        <Grid item className={classes.container}>
                                            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                <Grid item xs={4} className={classes.rootColumn}>
                                                    <Paper elevation={0}  className={classes.paper} style={{
                                                        backgroundImage: `url(${photoUrl})`
                                                    }}/>
                                                    <Field
                                                        size="small"
                                                        component={(props) => (
                                                            <FileUpload {...props} className={classes.fileUpload} endIcon={<AddIcon/>}/>
                                                        )}
                                                        label="Выбрать фото"
                                                        name={`photo`}
                                                        InputLabelProps={{
                                                            name: null,
                                                        }}
                                                        InputProps={{
                                                            multiple: false,
                                                            onChange: (event) => {
                                                                const image = event.currentTarget.files[0]

                                                                setFieldValue(`photo`, image);
                                                                setPhotoUrl(URL.createObjectURL(new Blob([image])))
                                                                if (product) {
                                                                    const formData = new FormData()
                                                                    formData.append('photo', image)

                                                                    return dispatch(PriceActions.imageProduct(productId, formData)).then(() => {})
                                                                }
                                                            }
                                                        }}
                                                        disabled={readOnly}
                                                    />
                                                    <Grid container direction={'row'} spacing={2} style={{marginTop: 5}} alignItems={'center'}>
                                                        <Grid item>
                                                            <Typography style={{color:"rgba(0, 0, 0, 0.54)"}}>Удалить фото</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <ButtonIcon
                                                                className={classes.minus}
                                                                onClick={(e) => removePhoto(e, setFieldValue)}
                                                                children={[
                                                                    (
                                                                        <RemoveIcon
                                                                            className={classes.svg}
                                                                            key={'RemoveIcon'}
                                                                        />
                                                                    )
                                                                ]}
                                                                disabled={readOnly}/>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={8} className={classes.rootColumn}>
                                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                        <Grid item xs={6} className={errors.category_id ? classes.selectCategoryError : classes.selectCategory}>
                                                            <Category
                                                                isSubmitting={isSubmitting}
                                                                label="Категория"
                                                                required={true}
                                                                value={values.category?.id}
                                                                onChange={(value) => {
                                                                    const category = categories.find(cat => (cat.id === value?.id))

                                                                    setValues({
                                                                        ...values,
                                                                        ...{
                                                                            standard: '',
                                                                            category: category,
                                                                            category_id: category.id
                                                                        }
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} className={classes.column}>
                                                            <Standard
                                                                name={'standard'}
                                                                required={true}
                                                                disabled={isDisabled(mode)}
                                                                module={'Ордер'}
                                                                category={values.category}
                                                                standard={values.standard}
                                                                errors={errors}
                                                                touched={touched}
                                                                onChange={(value) => {
                                                                    if (value.standard) {
                                                                        setValues({
                                                                            ...values,
                                                                            ...{
                                                                                standard: value.standard?.id ? value.standard : {
                                                                                    name: value.standard
                                                                                },
                                                                                category: value.standard?.category ?? values.category,
                                                                                category_id: value.standard?.category?.id ?? values.category_id
                                                                            }
                                                                        })
                                                                    } else {
                                                                        setFieldValue(`standard`, null)
                                                                    }
                                                                }}
                                                                onAddStandard={(item) => onAddStandard(item, setFieldValue, values, setValues)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name="article_number"
                                                                type="text"
                                                                required={true}
                                                                disabled={isDisabled(mode)}
                                                                label="Артикул"
                                                                step={1}
                                                                inputProps={{
                                                                    min: 0
                                                                }}
                                                                component={CustomTextField}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} className={classes.column}>
                                                            <Autocomplete
                                                                options={units}
                                                                value={values.unit ?? ''}
                                                                name={'unit'}
                                                                disabled={isDisabled(mode)}
                                                                required={true}
                                                                getOptionLabel={unit => unitOptionLabel(unit)}
                                                                onChange={(e, unit) => onChangeUnit(unit, setFieldValue)}
                                                                noOptionsText='Единица измерения'
                                                                renderInput={(params) => unitRenderInput(params, touched, errors)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name="description"
                                                                type="text"
                                                                label="Описание"
                                                                step={1}
                                                                rows={4}
                                                                multiline={true}
                                                                component={CustomTextField}
                                                                disabled={readOnly}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name="producer"
                                                                type="text"
                                                                label="Производитель"
                                                                step={1}
                                                                inputProps={{
                                                                    min: 0
                                                                }}
                                                                component={CustomTextField}
                                                                disabled={readOnly}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name="site_producer"
                                                                type="text"
                                                                label="Сайт производителя"
                                                                step={1}
                                                                inputProps={{
                                                                    min: 0
                                                                }}
                                                                component={CustomTextField}
                                                                disabled={readOnly}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4} className={classes.column}>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                <KeyboardDatePicker
                                                                    disableToolbar
                                                                    fullWidth
                                                                    error={Boolean(errors.hasOwnProperty('expiration_date') && touched.hasOwnProperty('expiration_date'))}
                                                                    helperText={(errors.hasOwnProperty('expiration_date') && touched.hasOwnProperty('expiration_date')) && errors.expiration_date ? errors.expiration_date : null}
                                                                    name="expiration_date"
                                                                    required={Boolean(values.category_id && isWithExpireDate(values.category_id, categories))}
                                                                    className={classes.datePicker}
                                                                    orientation="landscape"
                                                                    key={`expiration_date`}
                                                                    variant="inline"
                                                                    minDate={new Date().setDate(new Date().getDate() + 2)}
                                                                    initialFocusedDate={new Date().setDate(new Date().getDate() + 2)}
                                                                    minDateMessage="Некорректная дата"
                                                                    format="dd-MM-yyyy"
                                                                    openTo="year"
                                                                    autoOk
                                                                    disablePast
                                                                    label="Мин. срок годности товара"
                                                                    value={values.expiration_date}
                                                                    onChange={date => setFieldValue(`expiration_date`, date)}
                                                                    onBlur={() => setFieldTouched(`expiration_date`, true, true)}
                                                                    onClose={() => setFieldTouched(`expiration_date`, true, true)}
                                                                    disabled={readOnly}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Grid>
                                                        <Grid item xs={4} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name="stock_quantity"
                                                                type="number"
                                                                label="Остаток на складе"
                                                                step={1}
                                                                required={true}
                                                                inputProps={{
                                                                    step: 0.0001,
                                                                    min: 0
                                                                }}
                                                                component={CustomTextField}
                                                                disabled={readOnly}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                type="text"
                                                                name="tax_percent"
                                                                label="Ставка НДС"
                                                                select
                                                                className={classes.select}
                                                                variant="standard"
                                                                component={TextField}
                                                                required={true}
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                disabled={readOnly}
                                                            >
                                                                {taxPercentValuesCatalogPrice.map((tax_percent) => (
                                                                    <MenuItem key={tax_percent.key} value={tax_percent.key}>{tax_percent.value}</MenuItem>
                                                                ))}
                                                            </Field>
                                                        </Grid>
                                                        <Grid item xs={4} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name="price"
                                                                type="number"
                                                                label="Цена"
                                                                required={true}
                                                                step={1}
                                                                inputProps={{
                                                                    step: 0.01,
                                                                    min: 0
                                                                }}
                                                                component={CustomTextField}
                                                                disabled={readOnly}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                type="text"
                                                                name="warehouse_id"
                                                                label="Склад"
                                                                select
                                                                className={classes.select}
                                                                variant="standard"
                                                                component={TextField}
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                disabled={readOnly}
                                                            >
                                                                {['Склад'].map((val, index) => (
                                                                    <MenuItem key={index} value={index + 1}>{val}</MenuItem>
                                                                ))}
                                                            </Field>
                                                        </Grid>
                                                        <Grid item xs={4} className={classes.column}>
                                                            <Field
                                                                fullWidth
                                                                name="min_ordered_quantity"
                                                                type="number"
                                                                label="Мин. кол-во для заказа"
                                                                step={1}
                                                                inputProps={{
                                                                    step: 0.01,
                                                                    min: 0
                                                                }}
                                                                component={CustomTextField}
                                                                disabled={readOnly}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.footer}>
                                    <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                        <Grid item className={classes.footerContent}>
                                            <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                <Grid item>
                                                    <Submit
                                                        disableElevation
                                                        variant="contained"
                                                        color="primary"
                                                        type="button"
                                                        onClick={handleSubmit}
                                                        disabled={isSubmitting || readOnly}>
                                                        Сохранить
                                                    </Submit>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                ) : null}
            </DialogContent>
        </Dialog>
    )
}
