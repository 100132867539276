import {WarningService} from "../services/warning"

export const WarningActions = {
    events,
    add,
    edit,
    read,
    remove
}

function events(params, state = true) {
    return dispatch => new Promise((resolve, reject) => {
        if (state) {
            dispatch({type: "LOADING", payload: true})
        }

        WarningService.events(params)
            .then(
                events => {
                    if (state) {
                        dispatch({type: "LOADING", payload: false})
                    }
                    resolve(events)
                },
                error => {
                    if (state) {
                        dispatch({type: "LOADING", payload: false})
                    }
                    reject(error)
                }
            )
    })
}

function add(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        WarningService.add(values)
            .then(
                (event) => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(event)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function edit(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        WarningService.edit(id, values)
            .then(
                (event) => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(event)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function read(id, state = true) {
    return dispatch => new Promise((resolve, reject) => {
        if (state) {
            dispatch({type: "LOADING", payload: true})
        }

        WarningService.read(id)
            .then(
                () => {
                    if (state) {
                        dispatch({type: "LOADING", payload: false})
                    }
                    resolve()
                },
                error => {
                    if (state) {
                        dispatch({type: "LOADING", payload: false})
                    }
                    reject(error)
                }
            )
    })
}

function remove(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        WarningService.remove(params)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
