import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {Field, Form, Formik} from "formik"
import * as Yup from "yup"
import DateFnsUtils from "@date-io/date-fns"
import ru from "date-fns/locale/ru"

import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, Typography} from "@material-ui/core"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"
import {Close as CloseIcon} from "@material-ui/icons"

import {Submit} from "../../App/components/Button/Submit"
import {ReclamationActions} from "../actions/reclamation"
import {TextFieldWithError as CustomTextField} from "../../App/components/Inputs/TextFieldWithError"

const useStyles = makeStyles(theme => ({
    item: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "cursor": "pointer"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    fullWidth: {
        "width": "100%"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    content: {
        "padding": "0 !important"
    },
    input: {
        "width": "100%",
    },
    column: {
        "height": "86px"
    },
    section: {
        "border-right": "2px solid #c1c1c1",
        "padding": "20px 30px",
        "overflow-y": "auto",
        "max-height": "calc(80vh - 63px)"
    },
    additionally: {
        "max-height": "calc(80vh - 63px)"
    },
    dialogContent: {
        "max-height": "calc(100vh - 210px)",
        "padding": "20px 24px !important"
    },
    footer: {
        "background-color": "#e7eaee",
        "justify-content": "flex-start"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    dialog: {
        "max-height": "70vh"
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    searchLabel: {
        "margin-bottom": "15px",
    },
    footerContent: {
        "padding": "10px 0px"
    },
    eclipsis: {
        "overflow": "hidden",
        "text-overflow": "ellipsis",
        "white-space": "nowrap"
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    titleDialog: {
        "& h2": {
            "display": "flex",
            "justify-content": "space-between",
        }
    }
}))

export const Confirmation = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { type, reclamation, setSuccess } = props

    const [open, setOpen] = useState(props.open)

    const handleClose = () => {
        setOpen(null)
        props.setDialog(false)
    }

    useEffect(() => {
        setOpen(props.open)
    }, [props.open]);

    return open ? (
        <Grid item>
            <Dialog
                open={!!open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogTitle className={classes.titleDialog}>
                    {type.name} рекламации
                    <CloseIcon className={classes.close} onClick={handleClose}/>
                </DialogTitle>
                <Formik
                    initialValues={{
                        decision_date: null,
                        comment: ''
                    }}
                    validationSchema={Yup.object().shape({
                        decision_date: (type.type === 'accept') ? Yup.mixed().test('min-date', 'Некорректная дата', value => {
                            if (value) {
                                let currentDate = new Date(value);
                                currentDate.setHours(0, 0, 0, 0)
                                let minDate = new Date()
                                minDate.setHours(0, 0, 0, 0)

                                return currentDate.valueOf() - minDate.valueOf() >= 0;
                            }

                            return true
                        }).required("Поле не может быть пустым или состоять из одних пробелов!") : Yup.mixed(),
                        comment: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        dispatch(ReclamationActions.action(reclamation.id, {...values, ...{type: type.type}})).then(
                            () => {
                                setSubmitting(false)
                                setSuccess(true)
                                handleClose()
                            },
                            errors => {
                                if (errors) {
                                }
                                setSubmitting(false);
                            }
                        )
                    }}
                >
                    {({
                          dirty,
                          values,
                          errors,
                          touched,
                          validateField,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setValues,
                          setFieldValue,
                          setFieldTouched,
                          isSubmitting,
                          isValid
                      }) => (
                        <Form>
                            <DialogContent className={classes.dialogContent}>
                                <Grid container className={classes.fullWidth} direction="row" justify="center" alignItems="stretch">
                                    <Grid item className={classes.fullWidth}>
                                        {(type.type === 'accept') ?
                                            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                <Grid item>
                                                    <Typography variant="h6">
                                                        Данная рекламация принята.
                                                    </Typography>
                                                </Grid>
                                                <Grid item className={classes.column}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                        <KeyboardDatePicker
                                                            disableToolbar
                                                            fullWidth
                                                            error={Boolean(errors.hasOwnProperty('decision_date') && touched.hasOwnProperty('decision_date'))}
                                                            helperText={(errors.hasOwnProperty('decision_date') && touched.hasOwnProperty('decision_date')) && errors.decision_date ? errors.decision_date : null}
                                                            name="decision_date"
                                                            required
                                                            orientation="landscape"
                                                            variant="inline"
                                                            minDateMessage="Некорректная дата"
                                                            format="dd-MM-yyyy"
                                                            openTo="date"
                                                            autoOk
                                                            disablePast
                                                            value={values.decision_date}
                                                            label="Срок решения по рекламации"
                                                            onChange={date => {
                                                                setFieldValue("decision_date", date, true)
                                                            }}
                                                            onBlur={e => {
                                                                setFieldTouched(`decision_date`, true, true)
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="comment"
                                                        multiline
                                                        rows={2}
                                                        type="text"
                                                        label="Комментарий"
                                                        required
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                {
                                                    (type.type === 'reject') &&
                                                        <Grid item>
                                                            <Typography variant="h6">
                                                                Вы не соглашаетесь с претензией.
                                                            </Typography>
                                                            <Typography variant="h6">
                                                                Данная рекламация будет отклонена.
                                                            </Typography>
                                                        </Grid>
                                                }
                                                {
                                                    (type.type === 'close') &&
                                                        <Grid item>
                                                            <Typography variant="h6">
                                                                Рекламация исполнена.
                                                            </Typography>
                                                        </Grid>
                                                }
                                                {
                                                    (type.type === 'not_executed') &&
                                                        <Grid item>
                                                            <Typography variant="h6">
                                                                Рекламация не исполнена.
                                                            </Typography>
                                                        </Grid>
                                                }
                                                <Grid item className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="comment"
                                                        multiline
                                                        rows={2}
                                                        type="text"
                                                        label="Комментарий"
                                                        required
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions className={classes.footer}>
                                <Grid container className={classes.footerContent} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Submit
                                            variant="contained"
                                            component="label"
                                            color="primary"
                                            type="submit"
                                            onClick={handleClose}
                                        >
                                            Отмена
                                        </Submit>
                                    </Grid>
                                    <Grid item>
                                        <Submit
                                            variant="contained"
                                            component="label"
                                            color="primary"
                                            disabled={isSubmitting || !isValid || !dirty}
                                            onClick={handleSubmit}
                                            type="submit"
                                        >
                                            Ок
                                        </Submit>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </Grid>
    ) : null
}
