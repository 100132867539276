export default function marketplace(state = {
    carts: [],
    items: {
        data: [],
        meta: {
            total: 0,
            per_page: 15,
            current_page: 1
        },
        category: null,
        filter_values: null
    },
    count: 0,
    cart: null
}, action) {
    switch (action.type) {
        case "CART":
            return {
                ...state,
                ...{cart: action.payload}
            }
        case "CARTS_REQUEST":
            return state
        case "CARTS":
            return {
                ...state,
                ...{carts: action.payload}
            }
        case "CARTS_FAILURE":
            return state
        case "CART_DELETE_REQUEST":
            return state
        case "CART_DELETE_SUCCESS":
            return {
                ...state,
                ...{carts: state.carts.filter(cart => ( cart.id !== action.payload ))},
                ...{cart: (state.cart && (state.cart.id === action.payload)) ? null : state.cart}
            }
        case "CART_DELETE_FAILURE":
            return state
        case "CART_ITEMS_REQUEST":
            return state
        case "CART_ITEMS":
            return {
                ...state,
                ...{items: action.payload}
            }
        case "CART_ITEMS_FAILURE":
            return state
        case "CART_ITEM_COUNT_REQUEST":
            return state
        case "CART_ITEM_COUNT":
            return {...state, ...{count: action.payload}}
        case "CART_ITEM_COUNT_FAILURE":
            return state
        case "CART_ORDER_REQUEST":
            return state
        case "CART_ORDER_SUCCESS":
            return state
        case "CART_ORDER_FAILURE":
            return state
        case "CART_ADD_ITEM_REQUEST":
            return state
        case "CART_ADD_ITEM_SUCCESS":
            return {
                ...state,
                ...{count: state.count + action.payload}
            }
        case "CART_ADD_ITEM_FAILURE":
            return state
        case "CART_EDIT_ITEM_REQUEST":
            return state
        case "CART_EDIT_ITEM_SUCCESS":
            return {
                ...state,
                ...{carts: state.carts.map(cart => {
                    if (cart.items.find((item) => item.id === action.payload.id)) {
                        return {
                            ...cart,
                            ...{items: cart.items.map(item => {
                                    if (item.id === action.payload.id) {
                                        return {
                                            ...item,
                                            ...{amount: parseInt(action.payload.amount)}
                                        }
                                    } else {
                                        return item
                                    }
                                })}
                        }
                    } else {
                        return cart
                    }
                })}
            }
        case "CART_EDIT_ITEM_FAILURE":
            return state
        case "CART_DELETE_ITEM_REQUEST":
            return state
        case "CART_DELETE_ITEM_SUCCESS":
            return {
                ...state,
                ...{carts: state.carts.map(cart => {
                        if (cart.items.find((item) => item.id === action.payload)) {
                            return {
                                ...cart,
                                ...{items: cart.items.filter(item => item.id !== action.payload)}
                            }
                        } else {
                            return cart
                        }
                    })}
            }
        case "CART_DELETE_ITEM_FAILURE":
            return state
        case "MARKETPLACE_ITEMS_REQUEST":
            return state
        case "MARKETPLACE_ITEMS_SUCCESS":
            return {
                ...state,
                ...{items: action.payload}
            }
        case "MARKETPLACE_ITEMS_FAILURE":
            return {
                ...state,
                ...{items: {data: [], meta: {total: 0, per_page: 15, current_page: 1}, category: null}}
            }
        case "MARKETPLACE_ITEMS_CLEAR":
            return {
                ...state,
                ...{items: {data: [], meta: {total: 0, per_page: 15, current_page: 1}, category: null}}
            }
        case "MARKETPLACE_ITEMS_CATEGORY":
            return {
                ...state,
                ...{items: {...state.items, ...{category: action.payload}}}
            }

        case "MARKETPLACE_ITEM_POSITIONS_REQUEST":
            return state
        case "MARKETPLACE_ITEM_POSITIONS_SUCCESS":
            return {
                ...state,
                ...{positions: action.payload}
            }
        case "MARKETPLACE_ITEM_POSITIONS_FAILURE":
            return state
        case "MARKETPLACE_ITEM_POSITIONS_CLEAR":
            return {
                ...state,
                ...{positions: null}
            }
        default:
            return state
    }
}
