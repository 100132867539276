import {HttpService} from "../../../../App/services/http"
import * as AppStorage from "../../../../App/helpers/storage"

export const UserService = {
    accounts,
    account,
    create,
    update,
    login,
    deleteUser
}

function accounts(params) {
    return HttpService.get("/accounts", params)
}

function account(id) {
    return HttpService.get(`/account/${id}`)
        .then(response => {
            return response.account
        })
}

function login(values) {
    return HttpService.post("/auth/user/login", values)
        .then(response => {
            AppStorage.remove('price_token')
            if (response.account && response.account.company) {
                AppStorage.set('price_token', response.account.company.tokens.price)
                delete (response.account.company.tokens.price)
            }
            if (response.account) {
                AppStorage.set('account', JSON.stringify(response.account))
            }

            return response
        })
}

function create(values) {
    return HttpService.post(`/auth/user/registration`, values).then(response => {
        if (response.account && response.account.company) {
            AppStorage.set('price_token', response.account.company.tokens.price)
            delete (response.account.company.tokens.price)
        }
        return response
    })
}

function update(id, values) {
    return HttpService.put(`/auth/user/${id}`, values)
}

function deleteUser(id) {
    return HttpService.deleteMethod(`/account/${id}`)
}
