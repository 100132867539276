import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core"

import {Close as CloseIcon} from "@material-ui/icons";
import {AgreementActions} from "../../../actions/AdditionalAgreement/agreement";
import {Submit} from "../../../../App/components/Button/Submit";
import {round} from "../../../../App/helpers/number";
import {RemoveButton} from "../../../../App/components/Button/RemoveButton";
import {NumberButton} from "../../../../App/components/Button/NumberButton";
import {ItemActions} from "../../../actions/AdditionalAgreement/item";
import {Create} from "./Item/Create";
import {SystemActions} from "../../../../App/actions/system";

const useStyles = makeStyles(() => ({
    text: {
        "height": "118px"
    },
    footer: {
        "background-color": "#e7eaee",
        "justify-content": "flex-start",
        minHeight: "30px",
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    dialogContent: {
        padding: 0,
    },
    titleDialog: {
        "& h2": {
            "display": "flex",
            "justify-content": "space-between",
        },
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    }
}))

const columns = [
    {name: 'category', title: 'Категория'},
    {name: 'name', title: 'Наименование'},
    {name: 'article', title: 'Артикул'},
    {name: 'unit', title: 'Ед.изм'},
    {name: 'amount', title: 'Количество'},
    {name: 'ordered', title: 'В заказе'},
    {name: 'price', title: 'Цена'},
    {name: 'tax', title: 'НДС'},
    {name: 'delete', title: ''}
]

export const Items = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { units } = useSelector(state => state.system)

    const { settings, contract, open, onClose, onClick } = props

    const [dialog, setDialog] = useState(false)
    const [price, setPrice] = useState(contract.agreement.price)
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [loading, setLoading] = useState(false)

    const [items, setItems] = useState({
        data: [],
        meta: {
            total: 0,
            per_page: rowsPerPage,
            current_page: page
        }
    })

    const handleChangePage = (event, newPage) => {
        setPage(Number(newPage) + 1)
        setLoading(false)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(1)
        setLoading(false)
    }

    useEffect(() => {
        const getItems = async () => {
            return await dispatch(AgreementActions.items(contract.id, {
                page: page,
                limit: rowsPerPage
            }))
        }

        if (!loading) {
            getItems().then(data => {
                setItems(data)
                setLoading(true)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loading]);

    useEffect(() => {
        dispatch({
            type: 'EDIT_CONTRACT_SUCCESS',
            payload: {
                ...contract,
                ...{
                    agreement: {
                        ...contract.agreement,
                        ...{price: price}
                    }
                }
            }
        })
        // eslint-disable-next-line
    }, [dispatch, price]);

    useEffect(() => {
        const getUnits = async () => {
            return await dispatch(SystemActions.units())
        }

        if (!units.length) {
            getUnits().then(() => {})
        }
    }, [dispatch, units])

    return (
        <Dialog
            open={!!open}
            onClose={onClose}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle className={classes.titleDialog}>
                Редактировать спецификацию
                <CloseIcon
                    className={classes.close}
                    onClick={onClose}
                />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column, index) => (
                                    <TableCell
                                        key={index}
                                    >
                                        {column.title}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.data.map((item) => {
                                return (
                                    <TableRow
                                        className={classes.tableRow}
                                        key={item.id}
                                    >
                                        <TableCell>
                                            {item.category?.name}
                                        </TableCell>
                                        <TableCell>
                                            {item.name}
                                        </TableCell>
                                        <TableCell>
                                            {item.article_number}
                                        </TableCell>
                                        <TableCell>
                                            {item.unit?.name}
                                        </TableCell>
                                        <TableCell style={{ maxWidth: 150 }}>
                                            <NumberButton
                                                name='amount'
                                                disabled={!settings.specification_editing.amount?.increase && !settings.specification_editing.amount?.reduction}
                                                onSubmit={(value) => {
                                                    return dispatch(ItemActions.update(contract.id, item.id, {name: 'amount', value: value}))
                                                }}
                                                onSuccess={(value) => {
                                                    setItems({
                                                        ...items,
                                                        ...{data: items.data.map(el => {
                                                            if (el.id === item.id) {
                                                                setPrice(price - (el.amount * el.price) + (value * el.price))

                                                                el.amount = value
                                                            }

                                                            return el
                                                        })}
                                                    })
                                                }}
                                                value={round(item.amount, 4)}
                                                label={value => round(value, 4)}
                                                params={{
                                                    min: item.ordered ?? 0,
                                                    step: 0.0001
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {item.ordered ? round(item.ordered, 4) : null}
                                        </TableCell>
                                        <TableCell style={{ maxWidth: 150 }}>
                                            <NumberButton
                                                name='price'
                                                disabled={!settings.specification_editing.price?.increase && !settings.specification_editing.price?.reduction}
                                                onSubmit={(value) => {
                                                    return dispatch(ItemActions.update(contract.id, item.id, {name: 'price', value: value}))
                                                }}
                                                onSuccess={(value) => {
                                                    setItems({
                                                        ...items,
                                                        ...{data: items.data.map(el => {
                                                            if (el.id === item.id) {
                                                                setPrice(price - (el.amount * el.price) + (el.amount * value))

                                                                el.price = value
                                                            }

                                                            return el
                                                        })}
                                                    })
                                                }}
                                                value={round(item.price, 2)}
                                                display={value => parseFloat(value).toLocaleString('ru-RU', {style: 'currency', currency: contract.currency.code}).replace('KZT', '₸')}
                                                params={{
                                                    step: 0.01
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {(item.tax_percent >= 0) ? `НДС ${item.tax_percent}%` : 'Без НДС'}
                                        </TableCell>
                                        <TableCell>
                                            <RemoveButton
                                                disabled={!settings.specification_editing.delete}
                                                item={item}
                                                onSubmit={(id) => {
                                                    return dispatch(ItemActions.remove(contract.id, id))
                                                }}
                                                onSuccess={(item) => {
                                                    setLoading(false)
                                                    setPrice(price - (item.price * item.amount))
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            <TableRow
                                className={classes.tableRow}
                            >
                                <TableCell colSpan={6} />
                                <TableCell>
                                    Сумма договора: {parseFloat(price).toLocaleString('ru-RU', {style: 'currency', currency: contract.currency.code}).replace('KZT', '₸')}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions className={classes.footer}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item>
                        <TablePagination
                            rowsPerPageOptions={[5, 15, 25, 50, 100]}
                            component='div'
                            labelRowsPerPage={'Записей на странице:'}
                            count={items.meta.total}
                            rowsPerPage={rowsPerPage}
                            page={page - 1}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                            <Grid item>
                                <Submit
                                    disableElevation
                                    disabled={!settings.specification_editing.add}
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    onClick={() => setDialog(true)}
                                >
                                    Добавить
                                </Submit>
                                {dialog && <Create
                                    contract={contract}
                                    open={dialog}
                                    onClose={() => setDialog(false)}
                                    onClick={(item) => {
                                        setLoading(false)
                                        setPrice(price + (item.amount * item.price))
                                    }}
                                />}
                            </Grid>
                            <Grid item>
                                <Submit
                                    disableElevation
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    onClick={() => {
                                        dispatch(AgreementActions.approve(contract.id)).then(() => {
                                            onClick()
                                            onClose()
                                        })
                                    }}
                                >
                                    Отправить на согласование
                                </Submit>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
