import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce} from 'use-debounce'
import clsx from "clsx"

import {
    Grid, InputAdornment, makeStyles, TextField,
} from "@material-ui/core"
import {
    Search as SearchIcon, GetApp as GetAppIcon
} from "@material-ui/icons"
import {green} from "@material-ui/core/colors"
import {Table} from "@devexpress/dx-react-grid-material-ui";

import {history} from "../../../App/helpers/history"
import {ColumnChooser} from "../../../App/components/Table/ColumnChooser"
import {ButtonIcon} from "../../../App/components/Button/ButtonIcon"
import {SettingsActions} from "../../../Settings/actions/settings"
import {DownloadActions} from "../../../Download/actions/download";
import {CustomTable} from "../../../App/components/Table/Table";
import {TableHeader} from "../../../App/components/Table/TableHeader";
import {Create as CreateButton} from "./Buttons/Create";
import {Delete as DeleteButton} from "./Buttons/Delete";
import {Edit as EditButton} from "./Buttons/Edit";
import {ScheduleActions} from "../../actions/schedule";
import format from "date-fns/format";
import {getFullName} from "../../../App/helpers/user";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.planningContent.width,
        padding: theme.planningContent.padding,
        margin: theme.planningContent.margin,
    },
    container: {
        "background-image": "linear-gradient(to bottom,#f6f6f6,#eee 85%,#b1b1b1)",
        "width": "100%",
        "height": "calc(100vh - 256px)"
    },
    filterLabel: {
        "background": "#fff",
        "margin-bottom": "0 !important",
        "margin-top": "5px !important",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        },
        "& input": {
            padding: "6px 15px 7px"
        }
    },
    tableContainer: {
        "min-height": "100%",
        "height": "calc(100vh - 400px)"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    searchLabel: {},
    searchInput: {
        "width": "calc(100% - 83px)"
    },
    searchButton: {
        "margin-bottom": "10px"
    },
    fullWidth: {
        "width": "100%"
    },
    inputContentMain: {
        "width": "100%",
        "height": theme.planningContent.height,
        "min-height": theme.planningContent.height,
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    active: {
        'height': '41px',
        'background-color': green[100],
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    loading: {
        'height': '41px',
        'background-color': '#fff',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    submit: {
        "height": "35px",
    },
    iconButton: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    },
    title: {
        "&>h2": {
            color: '#fff'
        }
    },
}))

const columns = [
    {name: 'name', title: 'Наименование'},
    {name: 'year', title: 'Отчётный плановый период', filter: 'year'},
    {name: 'duration', title: 'Временная единица плана', filter: 'durations'},
    {name: 'category', title: 'Категория', filter: 'categories'},
    {name: 'status', title: 'Статус'},
    {name: 'budget.limit', title: 'Лимит оперативного бюджета'},
    {name: 'budget.fact', title: 'Факт'},
    {name: 'budget.plan', title: 'План'},
    {name: 'comment', title: 'Комментарий'},
    {name: 'creator', title: 'Создатель', filter: 'creator'},
    {name: 'created', title: 'Дата создания'},
    {name: 'updated', title: 'Дата редактирования'}
]

export const Content = () => {
    const dispatch = useDispatch()
    const tableName = 'planning_schedules'

    const classes = useStyles()
    const {filter} = useSelector(state => state.filters[tableName])

    const [schedules, setSchedules] = useState({
        data: [],
        meta: {
            total: 0, per_page: 15, current_page: 1
        }
    })
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)

    const [selection, setSelection] = useState([])
    const [selectionItems, setSelectionItems] = useState([])
    const [selectionPage, setSelectionPage] = useState({})

    const [initialize, setInitialize] = useState(false);
    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
    const [tableRef, setTableRef] = useState(null)

    const [columnOrder, setColumnOrder] = useState([
        'name',
        'year',
        'duration',
        'category',
        'status',
        'budget.limit',
        'budget.fact',
        'budget.plan',
        'comment',
        'creator',
        'created',
        'updated',
    ])

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'name', width: 200},
        {columnName: 'year', width: 100},
        {columnName: 'duration', width: 100},
        {columnName: 'category', width: 200},
        {columnName: 'status', width: 150},
        {columnName: 'budget.limit', width: 200},
        {columnName: 'budget.fact', width: 150},
        {columnName: 'budget.plan', width: 150},
        {columnName: 'comment', width: 200},
        {columnName: 'creator', width: 200},
        {columnName: 'created', width: 175},
        {columnName: 'updated', width: 175}
    ])

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...((filter.hasOwnProperty('status') && !!filter.status.length) ? {status: filter.status} : {}),
            ...((filter.hasOwnProperty('categories') && !!filter.categories.length) ? {categories: filter.categories} : {}),
            ...((filter.hasOwnProperty('durations') && !!filter.durations.length) ? {durations: filter.durations} : {}),
            ...((filter.hasOwnProperty('year') && !!filter.year.from.length) ? {year_from: filter.year.from} : {}),
            ...((filter.hasOwnProperty('year') && !!filter.year.to.length) ? {year_to: filter.year.to} : {}),
            ...((filter.hasOwnProperty('creator') && !!filter.creator.length) ? {creator: filter.creator} : {}),
            ...(searchRequest ? {search: searchRequest} : {})
        }
    }

    const getSchedules = () => {
        const getData = async () => {
            let params = {
                limit: rowsPerPage,
                page: page,
                ...getParams()
            }
            return await dispatch(ScheduleActions.get(params))
        }

        getData().then(response => {
            setSchedules(response)

            let numbers = []

            if (selectionPage.hasOwnProperty(page)) {
                response.data.forEach((item, index) => {
                    if (selectionPage[page].find(id => (id === item.id))) {
                        numbers.push(index)
                    }
                })
            }

            setSelection(numbers)

            tableRef && tableRef.current && tableRef.current.scrollIntoView()
        })
    }

    useEffect(() => {
        getSchedules()
        // eslint-disable-next-line
    }, [dispatch, page, rowsPerPage, searchRequest, filter])

    useEffect(() => {
        if (!initialize) {
            dispatch(SettingsActions.table(tableName)).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                dispatch({type: "PLANNING_SCHEDULES_FILTER_CLEAR"})
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: tableName,
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: "PLANNING_SCHEDULES_FILTER", payload: {limitRows: newRowsPerPage}})
    }

    return initialize ? (
        <Grid item className={classes.content}>
            <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        id="search"
                        variant="standard"
                        name={'search'}
                        placeholder={`Поиск: наименование`}
                        fullWidth={true}
                        margin={'normal'}
                        onChange={(event) => {
                            setSearch(event.target.value)
                        }}
                        classes={{
                            root: clsx(classes.filterLabel, classes.searchLabel)
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon style={{color: '#c1c1c1'}}/>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                            <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                <EditButton
                                    disabled={(selectionItems.length !== 1)}
                                    schedule={selectionItems[0]}
                                />
                                <CreateButton />
                                <DeleteButton
                                    items={selectionItems}
                                    onClick={() => {
                                        setSelection([])
                                        setSelectionItems([])
                                        setSelectionPage({})
                                        getSchedules()
                                    }}
                                />
                                <Grid item>
                                    <ButtonIcon
                                        type="button"
                                        size="small"
                                        disabled={!schedules.meta.total}
                                        onClick={() => {
                                            dispatch(DownloadActions.create({
                                                type: tableName,
                                                columns: columnOrder.filter(name => (hiddenColumnNames.indexOf(name) < 0)),
                                                params: {
                                                    order: (filter.sort.name && filter.sort.direction) ? `${filter.sort.name}, ${filter.sort.direction}` : `created, desc`,
                                                    type: tableName,
                                                    ...Object.entries(getParams()).reduce((result, [key, value]) => {
                                                        result[key] = Array.isArray(value) ? value.join(',') : value

                                                        return result
                                                    }, {})
                                                }
                                            }))
                                        }}
                                        children={<GetAppIcon/>}
                                        title='Скачать таблицу'
                                        className={classes.iconButton}
                                    />
                                </Grid>
                                <Grid item>
                                    <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <CustomTable
                            meta={schedules.meta}
                            tableName={tableName}
                            rows={schedules.data.map(schedule => ({
                                name: schedule.name,
                                year: schedule.year,
                                duration: schedule.duration.name,
                                category: schedule.category.name,
                                status: schedule.published ? 'Опубликован' : 'Черновик',
                                'budget.limit': parseFloat(schedule.budget?.limit ?? 0).toLocaleString('ru-RU', {
                                    style: 'currency',
                                    currency: 'RUB'
                                }),
                                'budget.fact': parseFloat(schedule.budget?.fact ?? 0).toLocaleString('ru-RU', {
                                    style: 'currency',
                                    currency: 'RUB'
                                }),
                                'budget.plan': parseFloat(schedule.budget?.plan ?? 0).toLocaleString('ru-RU', {
                                    style: 'currency',
                                    currency: 'RUB'
                                }),
                                comment: schedule.comment,
                                created: format(new Date(schedule.created), 'dd.MM.yyyy'),
                                updated: format(new Date(schedule.updated), 'dd.MM.yyyy'),
                                creator: schedule.user ? getFullName(schedule.user) : null,
                            }))}
                            columns={columns}
                            page={{page: page, setPage: setPage, rowsPerPage: rowsPerPage, handlePageSizeChange: handlePageSizeChange}}
                            selection={{selection: selection, setSelection: setSelection}}
                            setTableRef={setTableRef}
                            columnsSettings={{columnOrder: columnOrder, setColumnOrder: setColumnOrder, setColumnWidths: setColumnWidths, columnWidths: columnWidths, hiddenColumnNames: hiddenColumnNames, setHiddenColumnNames: setHiddenColumnNames}}
                            tableHeader={TableHeader}
                            filterActionType={'PLANNING_SCHEDULES_FILTER'}
                            getValues={ScheduleActions.filter}
                            onSelectionChange={(numbers) => {
                                setSelection(numbers)
                                const data = schedules.data.filter((item, index) => (numbers.find(key => (key === index)) !== undefined)).map(item => item)
                                setSelectionItems([
                                    ...data,
                                    ...selectionItems.filter(item => !schedules.data.find(el => el.id === item.id))
                                ])
                                setSelectionPage(!!data.length ? {...selectionPage, [page]: data.map(item => item.id)} : Object.fromEntries(
                                    Object.entries(selectionPage).filter(key => (parseInt(key) !== parseInt(page))).map(([key, value]) => [key, value])
                                ))
                            }}
                            classInputContent={classes.inputContentMain}
                            main
                            rowComponentSelection={(props) => {
                                const { tableRow, children, highlighted, onToggle } = props;
                                const { row } = tableRow;

                                let timer = null;
                                let prevent = false;

                                const handleClick = () => {
                                    timer = setTimeout(() => {
                                        if (!prevent) {
                                            if (!window.getSelection().toString()) {
                                                onToggle()
                                            }
                                        }
                                        prevent = false;
                                    }, 250)
                                }

                                const handleDoubleClick = () => {
                                    clearTimeout(timer);
                                    prevent = true;
                                    history.push(`/planning/purchase-plans`, {schedule: schedules.data[tableRow.rowId].id});
                                }

                                return (
                                    <Table.Row
                                        tableRow={tableRow}
                                        children={children}
                                        onClick={handleClick}
                                        onDoubleClick={handleDoubleClick}
                                        className={highlighted ? classes.active : classes.default}
                                        row={row}
                                    />
                                )
                            }}
                            rowComponent={({ row, tableRow, children }) => (
                                <Table.Row
                                    tableRow={tableRow}
                                    children={children}
                                    row={row}
                                    color="black"
                                />
                            )}
                            filters={{
                                year: {
                                    name: 'year',
                                    type: 'year'
                                },
                                category: {
                                    name: 'categories',
                                    type: 'values'
                                },
                                duration: {
                                    name: 'durations',
                                    type: 'values'
                                },
                                creator: {
                                    name: 'creator',
                                    type: 'values',
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    ) : null
}
