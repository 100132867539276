export default function settings(state = {table: []}, action) {
    switch (action.type) {
        case "SETTINGS_REQUEST":
            return state
        case "SETTINGS":
            return {
                ...state,
                orderSettings: action.payload
            }
        case "SETTINGS_TABLE_REQUEST":
            return state
        case "SETTINGS_TABLE":
            return {
                ...state,
                table: action.payload
            }
        case "SETTINGS_MARKETPLACE_SUCCESS":
            return {
                ...state,
                marketplace: action.payload
            }
        default:
            return state
    }
}
