import {CustomerService} from "../services/customers"

export const CustomerActions = {
    customers,
    customer
}

function customers(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DISCOUNT_CUSTOMERS_REQUEST"})

        CustomerService.customers(params)
            .then(
                customers => {
                    dispatch({type: "DISCOUNT_CUSTOMERS_SUCCESS", payload: customers})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DISCOUNT_CUSTOMERS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function customer(id, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CUSTOMER_REQUEST"})

        CustomerService.customer(id, params)
            .then(
                customer => {
                    dispatch({type: "CUSTOMER_SUCCESS", payload: customer})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "CUSTOMER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
