import * as Yup from "yup"

function getValidationSchema() {
    return Yup.object().shape({
        purchase_plan_id: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
        category: Yup.mixed(),
        name: Yup.string(),
        conduction_method: Yup.string(),
        department: Yup.string(),
        type: Yup.string(),
        currency: Yup.string(),
        address: Yup.string(),
        planing_date_to_publish_notification: Yup.mixed(),
        lot: Yup.object().shape({
            name: Yup.string(),
            start_execution_month: Yup.mixed(),
            execution_month: Yup.mixed(),
            purchase_type: Yup.string(),
            files: Yup
                .array()
                .of(Yup.object().shape({
                    file: Yup.mixed().test('fileFormat', 'Документ должен быть файлом одного из следующих типов: csv, xls, xlsx, jpg, jpeg, png, bmp, gif, svg, doc, docx, pdf.', (value) => {
                        return ((value && (value instanceof File) && [
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            'application/vnd.ms-excel',
                            'text/csv',
                            'image/jpeg',
                            'image/gif',
                            'image/svg+xml',
                            'image/bmp',
                            'image/png',
                            'application/msword',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            'application/pdf',
                        ].includes(value.type)) || !value || (value && !(value instanceof File)))
                    }),
                    description: Yup.mixed().when('file', {
                        is: null,
                        then: Yup.mixed().nullable(),
                        otherwise: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                    }),
                })),
            category: Yup.mixed(),
            prepayment: Yup.number().nullable().min(1, 'Пожалуйста, введите значение от 1 до 100').max(100, 'Пожалуйста, введите значение от 1 до 100'),
            postponement: Yup.string().when('purchase_type', {
                is: (purchase_type) => (purchase_type === 'postponement') || (purchase_type === 'partial'),
                then: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!'),
                otherwise: Yup.string().matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
            }),
        })
    })}

export {getValidationSchema};
