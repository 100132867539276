import {PurchasesService} from "../services/purchases"
import {PurchasePlanService} from "../services/purchasePlan"

export const PurchaseActions = {
    purchasesByPurchasePlanID,
    totalByPurchasePlanID,
    purchases,
    total,
    conductionMethods,
    add,
    joint,
    edit,
    fundingSources,
    purchase,
    categoriesForCreate,
    categories,
    purchaseByID,
    approve,
    inProgress,
    decline,
    deletePurchases,
    addComment,
    getDiffVersion,
    actionTypes,
    purchasesFilter,
    getPublish

}

function approve(ids) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASES_APPROVE_REQUEST"})

        PurchasesService.approve(ids)
            .then(
                _ => {
                    dispatch({type: "PURCHASES_APPROVE_SUCCESS"})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                        message:'Закупка утверждена',
                        type:'success'
                    }})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASES_APPROVE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )
    })
}

function inProgress(purchaseID) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASES_IN_PROGRESS_REQUEST"})

        PurchasesService.inProgress(purchaseID)
            .then(
                () => {
                    dispatch({type: "PURCHASES_IN_PROGRESS_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASES_IN_PROGRESS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function decline(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASES_DECLINE_REQUEST"})

        PurchasesService.decline(values)
            .then(
                () => {
                    dispatch({type: "PURCHASES_DECLINE_SUCCESS"})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                        message:'Закупка успешно отклонена',
                        type:'success'
                    }})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASES_DECLINE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )
    })
}

function addComment(purchaseID, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASES_ADD_COMMENT_REQUEST"})

        PurchasesService.addComment(purchaseID, values)
            .then(
                () => {
                    dispatch({type: "PURCHASES_ADD_COMMENT_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASES_ADD_COMMENT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                        message:'Не удалось добавить комментарий',
                        type:'error'
                    }})
                    reject(error)
                }
            )
    })
}

function purchasesByPurchasePlanID(purchasePlanID, params, state = true) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PurchasesService.purchasesByPurchasePlanID(purchasePlanID, params)
            .then(
                purchases => {
                    if (state) {
                        dispatch({type: "PURCHASES_SUCCESS"})
                    }
                    dispatch({type: "LOADING", payload: false})
                    resolve(purchases)
                },
                error => {
                    if (state) {
                        dispatch({type: "PURCHASES_FAILURE"})
                    }
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function totalByPurchasePlanID(purchasePlanID, params) {
    return () => new Promise((resolve, reject) => {
        PurchasesService.purchasesByPurchasePlanID(purchasePlanID, {...params, meta: 'total'})
            .then(
                purchases => {
                    resolve(purchases)
                },
                error => {
                    reject(error)
                }
            )
    })
}

function purchases(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PurchasesService.purchases(params)
            .then(
                purchases => {
                    dispatch({type: "PURCHASES_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(purchases)
                },
                error => {
                    dispatch({type: "PURCHASES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function total(params) {
    return () => new Promise((resolve, reject) => {
        PurchasesService.purchases({...params, meta: 'total'})
            .then(
                purchases => {
                    resolve(purchases)
                },
                error => {
                    reject(error)
                }
            )
    })
}

function purchaseByID(purchaseID) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_REQUEST"})

        PurchasesService.purchaseByID(purchaseID)
            .then(
                purchase => {
                    dispatch({type: "PURCHASE_SUCCESS", payload: purchase})
                    dispatch({type: "LOADING", payload: false})
                    resolve(purchase)
                },
                error => {
                    dispatch({type: "PURCHASE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function purchase(purchaseID) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_REQUEST"})

        PurchasesService.purchase(purchaseID)
            .then(
                purchase => {
                    dispatch({type: "PURCHASE_SUCCESS", payload: purchase})
                    dispatch({type: "LOADING", payload: false})
                    resolve(purchase)
                },
                error => {
                    dispatch({type: "PURCHASE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function conductionMethods(fromPublish = false) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_CONDUCTION_METHODS_REQUEST"})

        PurchasesService.conductionMethods(fromPublish)
            .then(
                conductionMethods => {
                    dispatch({type: "PURCHASE_CONDUCTION_METHODS_SUCCESS", payload: conductionMethods})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_CONDUCTION_METHODS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function add(formData) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "ADD_PURCHASE_REQUEST"})

        PurchasesService.add(formData)
            .then(
                purchase => {
                    dispatch({type: "ADD_PURCHASE_SUCCESS", payload: purchase})
                    dispatch({type: "LOADING", payload: false})
                    resolve(purchase)
                },
                error => {
                    dispatch({type: "ADD_PURCHASE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function joint(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "ADD_JOINT_PURCHASE_REQUEST"})

        PurchasesService.joint(values)
            .then(
                purchase => {
                    dispatch({type: "ADD_JOINT_PURCHASE_SUCCESS", payload: purchase})
                    dispatch({type: "LOADING", payload: false})
                    resolve(purchase)
                },
                error => {
                    dispatch({type: "ADD_JOINT_PURCHASE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function edit(purchaseID, formData) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "ADD_PURCHASE_EDIT_REQUEST"})

        PurchasesService.edit(purchaseID, formData)
            .then(
                contract => {
                    dispatch({type: "ADD_PURCHASE_EDIT_SUCCESS", payload: contract})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "ADD_PURCHASE_EDIT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function fundingSources() {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_FUNDING_SOURCES_REQUEST"})

        PurchasesService.fundingSources()
            .then(
                fundingSources => {
                    dispatch({type: "PURCHASE_FUNDING_SOURCES_SUCCESS", payload: fundingSources})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_FUNDING_SOURCES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function categoriesForCreate(params = {}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_CATEGORIES_REQUEST"})

        PurchasePlanService.categories(params)
            .then(
                categories => {
                    dispatch({type: "PURCHASE_CATEGORIES_SUCCESS", payload: categories})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_CATEGORIES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function categories(purchaseID) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASE_CATEGORIES_REQUEST"})

        PurchasesService.categories(purchaseID)
            .then(
                categories => {
                    dispatch({type: "PURCHASE_CATEGORIES_SUCCESS", payload: categories})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASE_CATEGORIES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function deletePurchases(ids) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "PURCHASES_DELETE_REQUEST"})

        PurchasesService.deletePurchases(ids)
            .then(
                _ => {
                    dispatch({type: "PURCHASES_DELETE_SUCCESS"})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Закупки успешно удалены',
                            type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "PURCHASES_DELETE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                            message:error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function getDiffVersion(versionId, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PurchasesService.getDiffVersion(versionId, params)
        .then(
            diff => {
                dispatch({type: "DIFF_PURCHASE_VERSIONS_SUCCESS"})
                dispatch({type: "LOADING", payload: false})
                resolve(diff)
            },
            error => {
                dispatch({type: "DIFF_PURCHASE_VERSIONS_FAILURE"})
                dispatch({type: "LOADING", payload: false})
                dispatch({ type: "ALERT_ERROR", payload: {
                        message:error.message,
                        type:'error'
                    }
                })
                reject(error)
            }
        )
    })
}


function actionTypes(params) {
    return () => new Promise((resolve, reject) => {
        PurchasesService.actionTypes(params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    reject(error)
                }
            )
    })
}

function purchasesFilter(params) {
    return () => new Promise((resolve, reject) => {
        PurchasesService.purchasesFilter(params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    reject(error)
                }
            )
    })
}

function getPublish(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        PurchasesService.getPublish(params)
            .then(
                response => {
                    dispatch({type: "PUBLISH_PURCHASES_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "PUBLISH_PURCHASES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}
