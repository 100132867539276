import {DictionaryService} from "../services/dictionary"

export const DictionaryActions = {
    addToSelection,
    selectedStandards,
    deleteSelected
}

function selectedStandards(type, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        DictionaryService.selectedStandards(type, params)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "SELECTED_STANDARDS_SUCCESS", payload: response})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "SELECTED_STANDARDS_FAIL"})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function addToSelection(standards) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        DictionaryService.addToSelection(standards)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "SELECT_STANDARDS_SUCCESS", payload: response})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Эталоны успешно добавлены в отбор',
                            type:'success'
                        }})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "SELECT_STANDARDS_FAIL"})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function deleteSelected(ids) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        DictionaryService.deleteSelected(ids)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "DELETE_SELECT_STANDARDS_SUCCESS", payload: response})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Эталоны успешно удалены из отбора',
                            type:'success'
                        }
                    })
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "DELETE_SELECT_STANDARDS_FAIL"})
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}
