import React from "react"
import format from "date-fns/format"
import {ru} from "date-fns/locale"
import clsx from 'clsx'

import {
    Grid,
    makeStyles,
    Step,
    StepConnector,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
    withStyles
} from "@material-ui/core"
import {Check} from '@material-ui/icons'

const Connector = withStyles({
    alternativeLabel: {
        "top": "10",
        "left": "calc(-50% + 16px)",
        "right": "calc(50% + 16px)"
    },
    active: {
        '& $line': {
            "border-color": "#616161"
        },
    },
    completed: {
        '& $line': {
            "border-color": "#616161"
        },
    },
    line: {
        "border-color": "#eaeaf0",
        "border-top-width": "3",
        "border-radius": "1"
    },
    vertical: {
        "padding": "0 0 8px",
        "margin-left": "12px"
    }
})(StepConnector);

const useStepIconStyles = makeStyles({
    root: {
        "color": "#eaeaf0",
        "display": "flex",
        "height": "22",
        "align-items": "center"
    },
    active: {
        "color": "#616161"
    },
    circle: {
        "width": 12,
        "height": 12,
        "border-radius": '50%',
        "background-color": 'currentColor',
        "margin-left": "6px",
        "margin-right": "6px"
    },
    completed: {
        "color": "#616161",
        "z-index": "1",
        "font-size": "18"
    }
});

function StepIcon(props) {
    const classes = useStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
        </div>
    );
}

export const History = (props) => {
    let { lot } = props

    return (
        <Grid item>
            <Grid item>
                <Stepper activeStep={0} orientation="vertical" nonLinear={true} connector={<Connector />}>
                    {Object.keys(lot.order.log).map((created, i) => (
                        Object.keys(lot.order.log[created]).map((comment, i) => (
                            <Step key={i} active={true}>
                                <StepLabel StepIconComponent={StepIcon}>{`${comment} (${format(new Date(created), 'H:mm PP', {locale: ru})})`}</StepLabel>
                                <StepContent>
                                    <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                        <Grid item>
                                            {lot.order.log[created][comment].sort((first, second) => {
                                                return first.lot - second.lot
                                            }).map(log => {
                                                return log.description ? log.description.split("\n") : []
                                            }).flat().map((comment, index) => (
                                                comment ? <Typography key={index} variant="body2" gutterBottom>{comment}</Typography> : null
                                            ))}
                                        </Grid>
                                    </Grid>
                                </StepContent>
                            </Step>
                        ))
                    ))}
                </Stepper>
            </Grid>
        </Grid>
    )
}
