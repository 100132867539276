import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {useDebouncedCallback} from 'use-debounce'
import {Field, Form, Formik} from "formik"
import * as Yup from "yup"

import {
    Avatar,
    Checkbox,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    makeStyles,
    TextField,
    Typography
} from "@material-ui/core"
import {Search as SearchIcon} from "@material-ui/icons"

import {MessengerActions} from "../actions/messenger"
import {Submit} from "../../App/components/Button/Submit"
import {TextFieldWithError as CustomTextField} from "../../App/components/Inputs/TextFieldWithError"
import {getUserPosition} from '../../App/helpers/position'
import {getFullName} from "../../App/helpers/user"

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    additional: {
        'width': '320px',
        'min-height': '100vh'
    },
    column: {
        'width': "100%",
        'height': '86px',
        'padding': "8px",
    },
    line: {
        'width': "100%",
        'padding': "8px",
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    contact: {
        'width': "100%"
    },
    users: {
        'overflow-y': 'auto',
        'height': 'calc(100vh - 218px)',
        'padding-top': '0'
    },
    eclipsis: {
        'overflow': 'hidden',
        "text-overflow": "ellipsis",
        "white-space": "nowrap"
    },
    position: {
        'color': 'gray',
    }
}))

export const Create = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {socket} = props

    const [search, setSearch] = useState('')

    const debounced = useDebouncedCallback(
        (value) => {
            setLoading(value)
        }, 700
    )

    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState([])
    const {handleClose} = props

    const getUsers = async () => {
        return await dispatch(MessengerActions.users({
            page: 1,
            limit: 20,
            ...(search.length ? {search: search} : {})
        }))
    }

    useEffect(() => {
        if (!loading) {
            getUsers().then(response => {
                setUsers(response.data)
                setLoading(true)
            })
        }
        // eslint-disable-next-line
    }, [loading])

    return (
        <Grid container direction="row" justify="center" alignItems="stretch">
            <Grid item className={classes.additional}>
                <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                    <Formik
                        initialValues={{
                            name: '',
                            users: [],
                            type: 'group'
                        }}
                        validationSchema={Yup.object().shape({
                            name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            users: Yup.array().required("Поле не может быть пустым или состоять из одних пробелов!"),
                            type: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
                        })}
                        onSubmit={(values, {setSubmitting}) => {
                            dispatch(MessengerActions.create(values)).then(
                                chat => {
                                    socket.emit('chat', chat, () => {})
                                    dispatch({type: "MESSENGER_ADD_CHAT", payload: chat})
                                    setSubmitting(false)
                                    handleClose()
                                },
                                errors => {
                                    if (errors) {
                                    }
                                    setSubmitting(false);
                                }
                            )
                        }}
                    >
                        {({
                              values,
                              isSubmitting,
                              setFieldValue
                          }) => (
                            <Form className={classes.fullWidth}>
                                <Grid item className={classes.column}>
                                    <Field
                                        fullWidth
                                        name="name"
                                        type="string"
                                        label="Наименование"
                                        required
                                        component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item className={classes.line}>
                                    <TextField
                                        variant="standard"
                                        placeholder={`Поиск`}
                                        fullWidth
                                        margin={'normal'}
                                        value={search}
                                        onChange={(event) => {
                                            setSearch(event.target.value)
                                            debounced.callback(false)
                                        }}
                                        classes={{
                                            root: classes.filterLabel
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchIcon  style={{color: '#c1c1c1'}}/>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item className={classes.contact}>
                                    <List component="nav" className={classes.users}>
                                        {users.map(user => (<React.Fragment key={user.id}>
                                            <Divider/>
                                            <ListItem
                                                button
                                                onClick={() => {
                                                    if (values.users.includes(user.id)) {
                                                        setFieldValue('users', values.users.filter(id => (id !== user.id)))
                                                    } else {
                                                        setFieldValue('users', [...values.users, user.id])
                                                    }
                                                }}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar
                                                        alt={user.login}
                                                    />
                                                </ListItemAvatar>
                                                <Grid item>
                                                    <Typography>{getFullName(user)}</Typography>
                                                    <Typography className={classes.position}>{getUserPosition(user)}</Typography>
                                                </Grid>
                                                <ListItemSecondaryAction>
                                                    <Checkbox
                                                        edge="end"
                                                        onChange={() => {
                                                            if (values.users.includes(user.id)) {
                                                                setFieldValue('users', values.users.filter(id => (id !== user.id)))
                                                            } else {
                                                                setFieldValue('users', [...values.users, user.id])
                                                            }
                                                        }}
                                                        checked={values.users.includes(user.id)}
                                                    />
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </React.Fragment>))}
                                    </List>
                                </Grid>
                                <Grid item className={classes.line}>
                                    <Grid container direction="row" justify="space-between" alignItems="center">
                                        <Grid item>
                                            <Submit
                                                disableElevation
                                                variant="contained"
                                                color="secondary"
                                                type="button"
                                                onClick={() => {
                                                    handleClose()
                                                }}
                                                disabled={isSubmitting}
                                            >
                                                Отмена
                                            </Submit>
                                        </Grid>
                                        <Grid item>
                                            <Submit
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={isSubmitting || !values.users.length}
                                            >
                                                Создать
                                            </Submit>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Grid>
    )
}
