import React, {useEffect, useState} from 'react'

import {Grid, makeStyles, TextField, Typography} from '@material-ui/core'

const useStyles = makeStyles(() => ({
    fullWidth: {
        'width': '100%'
    }
}))

export const Period = (props) => {
    const classes = useStyles()

    const {filters, setFilters, setDefaultFilters, title} = props

    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState({[title.filter]: filters.hasOwnProperty([title.filter]) ? filters[title.filter] : {from: '', to: ''}})

    useEffect(() => {
        setFilters(filter)
        // eslint-disable-next-line
    }, [filter])

    useEffect(() => {
        if (!loading) {
            setLoading(true)
            setDefaultFilters({[title.filter]: {from: '', to: ''}})
        }
        // eslint-disable-next-line
    }, [loading])

    return (
        <React.Fragment>
            <Grid item>
                <Typography variant="caption">
                    {[title.filter] === 'price' ? 'Цена' : 'Год'}
                </Typography>
            </Grid>
            <Grid item className={classes.fullWidth}>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label='от'
                                    value={filter[title.filter].from}
                                    type="number"
                                    onChange={(event) => {
                                        setFilter({
                                            [title.filter]: {
                                                ...filter[title.filter],
                                                ...{from: event.target.value}
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label='до'
                                    value={filter[title.filter].to}
                                    type="number"
                                    onChange={(event) => {
                                        setFilter({
                                            [title.filter]: {
                                                ...filter[title.filter],
                                                ...{to: event.target.value}
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
            </Grid>
        </React.Fragment>
    )
}
