import {DemoService} from "../services/demo"

export const DemoActions = {
    order,
    contract,
    supplier,
    setPaymentSum,
    setOrderItemsValues
}

function order(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DEMO_ORDER_REQUEST"})

        DemoService.order(params)
            .then(
                response => {
                    dispatch({type: "DEMO_ORDER", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "DEMO_ORDER_FAILURE"})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function contract(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DEMO_CONTRACT_REQUEST"})

        DemoService.contract(params)
            .then(
                response => {
                    dispatch({type: "DEMO_CONTRACT", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "DEMO_CONTRACT_FAILURE"})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function supplier(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DEMO_SUPPLIER_REQUEST"})

        DemoService.supplier(params)
            .then(
                supplier => {
                    dispatch({type: "DEMO_SUPPLIER", payload: supplier})
                    dispatch({type: "LOADING", payload: false})
                    resolve(supplier)
                },
                error => {
                    dispatch({type: "DEMO_SUPPLIER_FAILURE"})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function setPaymentSum(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DEMO_SET_PAYMENT_SUM_REQUEST"})

        DemoService.setPaymentSum(values)
            .then(
                response => {
                    dispatch({type: "DEMO_SET_PAYMENT_SUM"})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Успешно отправлено',
                            type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DEMO_SET_PAYMENT_SUM"})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: 'Не удалось обновить',
                            type:'error'
                        }})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}


function setOrderItemsValues(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DEMO_SET_ORDER_ITEMS_PARAMS_REQUEST"})

        DemoService.setOrderItemsValues(values)
            .then(
                response => {
                    dispatch({type: "DEMO_SET_ORDER_ITEMS_PARAMS"})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Успешно отправлено',
                            type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DEMO_SET_ORDER_ITEMS_PARAMS"})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: 'Не удалось обновить',
                            type:'error'
                        }})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
