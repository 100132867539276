import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import format from "date-fns/format"
import {
    Grid,
    makeStyles,
    MenuItem,
    Select
} from "@material-ui/core"
import {Table} from "@devexpress/dx-react-grid-material-ui"
import {green} from "@material-ui/core/colors"
import {PurchaseActions} from "../../../actions/purchase";
import {ColumnChooser} from "../../../../App/components/Table/ColumnChooser"
import {CustomTable} from "../../../../App/components/Table/Table";
import {TableHeader} from "../../../../App/components/Table/TableHeader";
import {localState} from "../../../../App/components/LocalState";

const useStyles = makeStyles(() => ({
    inputContentVersions: {
        "width": "100%",
        "height": "calc(100vh - 354px)",
        "min-height": "calc(100% - 354px)",
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
}))

const productColumnsInVersion = [
    {name: 'name', title: 'Наименование'},
    {name: 'lot_number', title: 'Лот'},
    {name: 'price', title: 'Цена'},
    {name: 'amount', title: 'Кол-во'},
    {name: 'total', title: 'Сумма'},
    {name: 'status', title: 'Статус', filter: 'status'},
    {name: 'creator', title: 'Ответственный', filter: 'creator'},
    {name: 'created_at', title: 'Дата', filter: 'created_at'}
]

export const Version = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {purchase} = useSelector(state => ({...state.purchasePlans, ...state.account, ...state.purchases}))
    const [diffVersion, setDiffVersion] = useState({...localState,
        agg_info: {
            items_count: 0,
            average_price: 0,
        }
    })
    const {filter} = useSelector(state => state.filters['planning_purchase_plan_purchase_version'])

    const [hiddenColumnNamesProductInVer, setHiddenColumnNamesProductInVer] = useState([]);
    const [version, setVersion] = useState('')

    const [pageDiffVersion, setPageDiffVersion] = useState(1)
    const [rowsPerPageDiffVersion, setRowsPerPageDiffVersion] = useState(filter.limitRows)
    const {
        tableColumnExtensions,
        tableRef,
        setTableRef
    } = props

    const [loading, setLoading] = useState(false)

    const [columnProductVerWidths, setColumnProductVerWidths] = useState([
        {columnName: 'name', width: 200},
        {columnName: 'lot_number', width: 125},
        {columnName: 'price', width: 125},
        {columnName: 'amount', width: 125},
        {columnName: 'total', width: 155},
        {columnName: 'creator', width: 125},
        {columnName: 'created_at', width: 125},
        {columnName: 'status', width: 125}
    ])

    const [columnOrderProductInVer, setColumnOrderProductInVer] = useState([
        'name',
        'lot_number',
        'price',
        'amount',
        'total',
        'status',
        'creator',
        'created_at'
    ])

    const handleDiffVersionPageSizeChange = newRowsPerPage => {
        setPageDiffVersion(1)
        setRowsPerPageDiffVersion(newRowsPerPage)
        dispatch({type: 'DIFF_PURCHASE_VERSIONS_FILTER', payload: {limitRows: newRowsPerPage}})
    }

    const getDiffParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...((filter.hasOwnProperty('types') && !!filter.types.length) ? {types: filter.types} : {}),
        }
    }

    useEffect(() => {
        const getDiffVersion = async () => {
            let params = {
                limit: rowsPerPageDiffVersion,
                page: pageDiffVersion,
                ...getDiffParams()
            }
            const response = await dispatch(PurchaseActions.getDiffVersion(version, params))
            setDiffVersion(response)
        }

        if (!loading) {
            if (version) {
                getDiffVersion().then(() => {
                    dispatch({type: "VERSION_ROWS_COUNT", payload: rowsPerPageDiffVersion})
                    setLoading(true)
                    tableRef && tableRef.current && tableRef.current.scrollIntoView()
                })
            } else {
                setLoading(true)
            }
        }
        // eslint-disable-next-line
    }, [dispatch, loading])

    useEffect(() => {
        if (loading) {
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [pageDiffVersion, rowsPerPageDiffVersion, filter, version])

    return <React.Fragment>
        <Grid item xs={4}/>
        <Grid item xs={8}>
            <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                {purchase?.versions?.length ? (
                    <Grid item xs={1}>
                        <Select
                            fullWidth
                            value={version}
                            onChange={(e) => {
                                setVersion(e.target.value)
                            }}
                        >
                            {purchase.versions.map((version, idx) =>
                                <MenuItem key={idx} value={version.id}>{version.version}</MenuItem>
                            )}
                        </Select>
                    </Grid>
                ) : null}
                <Grid item xs={1}>
                    <Grid item>
                        <ColumnChooser columns={productColumnsInVersion} hiddenColumnNames={hiddenColumnNamesProductInVer} setHiddenColumnNames={setHiddenColumnNamesProductInVer}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <CustomTable
            meta={diffVersion.meta}
            tableName={'planning_purchase_plan_purchase_version'}
            rows={diffVersion.data.map(product => ({
                name: product.name,
                actions: product.actions,
                lot_number: product.lot_number,
                type_action: product.type_action,
                status: 'Без изменений',
                price: product.price,
                amount: parseFloat(!product.amount ? 0 : product.amount).toLocaleString('ru-RU'),
                total: product.price * product.amount,
                created_at: format(new Date(product.created_at), 'dd.MM.yyyy'),
                creator: product.creator,
            }))}
            columns={productColumnsInVersion}
            page={{page: pageDiffVersion, setPage: setPageDiffVersion, rowsPerPage: rowsPerPageDiffVersion, handlePageSizeChange: handleDiffVersionPageSizeChange}}
            setTableRef={setTableRef}
            tableColumnExtensions={tableColumnExtensions}
            columnsSettings={{columnOrder: columnOrderProductInVer, setColumnOrder: setColumnOrderProductInVer, setColumnWidths: setColumnProductVerWidths, columnWidths: columnProductVerWidths, hiddenColumnNames: hiddenColumnNamesProductInVer, setHiddenColumnNames: setHiddenColumnNamesProductInVer}}
            tableHeader={TableHeader}
            filterActionType={'DIFF_PURCHASE_VERSIONS_FILTER'}
            getValues={PurchaseActions.actionTypes}
            classInputContent={classes.inputContentVersions}
            rowComponent={({row, tableRow, children}) => {
                let actions = JSON.parse(row.actions);
                let background = '#fff';
                if (actions && actions.length) {
                    if (row.type_action === 'add') {
                        background = '#ddffdd';
                        row.status = 'Добавлено';
                    } else if (row.type_action === 'changed') {
                        background = '#faffd4';
                        row.status = 'Отредактировано';
                        actions.forEach(action => {
                            if (action.column) {
                                row[action.column] = <div><span
                                    style={{textDecoration: "line-through"}}>{action.previous_value}</span><br/><span>{action.value}</span>
                                </div>;
                            }
                        })
                    } else if (row.type_action === 'delete') {
                        background = '#ffcccc';
                        row.status = 'Удалено';
                    }
                }

                return (
                    <Table.Row
                        tableRow={tableRow}
                        children={children}
                        className={classes.default}
                        style={{background: background}}
                        row={row}
                    />
                )
            }}
            filters={{
                status: {
                    name: 'types',
                    type: 'action_type'
                },
            }}
        />
    </React.Fragment>
}
