import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {Field, FieldArray, Form, Formik} from "formik"
import {CheckboxWithLabel, TextField} from "formik-material-ui"
import DateFnsUtils from "@date-io/date-fns"
import format from "date-fns/format";
import ru from "date-fns/locale/ru"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    MenuItem,
    Paper,
    Typography,
} from "@material-ui/core"
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {Add as AddIcon} from "@material-ui/icons"

import {Submit} from "../../../../../../App/components/Button/Submit"
import {TextFieldWithError as CustomTextField} from "../../../../../../App/components/Inputs/TextFieldWithError"
import {SystemActions} from "../../../../../../App/actions/system"
import {ShopActions} from "../../../actions/shop"
import {Group} from "../../../../../../App/components/Paper/Group"
import {CompanyActions} from "../../../../../../Account/actions/company"
import {SettingsActions} from "../../../../../../Settings/actions/settings"
import {getValidationSchema} from "../../../validation/order"
import {Category} from "../../../../../../App/components/Inputs/Category";
import {PurchaseActions} from "../../../../../../Planning/actions/purchase";
import {Lot} from "./Lot";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 251px)"
    },
    tabContent: {
        "padding": "20px 29px !important"
    },
    column: {
        "height": "86px"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    error: {
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "height": "35px",
        "font-size": "0.75rem",
        "color": "#f44336"
    },
    selectCategoryError: {
        "& .dx-texteditor.dx-editor-outlined": {
            "transform": "scaleX(1)",
            "border": "unset !important",
            "border-bottom": "2px solid #f27360 !important",
            "border-radius": "unset",
        },
        "& .dx-texteditor-input": {
            "padding": "0px !important",
            "min-height": "35px",
        },
        "margin-top": "-5px",
    },
    selectCategory: {
        "& .dx-texteditor.dx-editor-outlined": {
            "border": "unset !important",
            "border-bottom": "2px solid #c1c1c1 !important",
            "border-radius": "unset",
            "&:hover": {
                "border-bottom": "2px solid #898989 !important",
            },
        },
        "& .dx-texteditor-input": {
            "padding": "0px !important",
        },
        "&:after": {
            "transform": "scaleX(1)",
            "border-bottom-color": "9px solid #f27360 !important"
        }
    },
}))

export const Content = () => {
    let {id} = useParams()
    const dispatch = useDispatch()
    const classes = useStyles()

    const {company} = useSelector(state => state.company)
    const {categories, currencies, status: statuses} = useSelector(state => state.system)
    const {orderSettings} = useSelector(state => state.settings)
    const {conductionMethods} = useSelector(state => state.purchases)
    const [order, setOrder] = useState(false)
    const [dialog, setDialog] = useState(false)
    const [category, setCategory] = useState({})
    const [standards, setStandards] = useState([])
    const [settings, setSettings] = useState([])
    const [showCatError, setShowCatError] = useState(false)

    useEffect(() => {
        const getSettings = async () => {
            return await dispatch(SettingsActions.order())
        }

        getSettings().then(() => {
        })
    }, [dispatch])

    useEffect(() => {
        if (id) {
            const getOrder = async () => {
                return await dispatch(ShopActions.order(id))
            }

            getOrder().then(async order => {
                if (order.from_planning) {
                    await dispatch(SystemActions.status())
                    await dispatch(PurchaseActions.conductionMethods(true))
                }
                let tmp = []
                let gen = {}
                order.lots.forEach((lot, index) => {
                    lot.items.forEach((item) => {
                        if (item.params?.generic) {
                            gen[index] = {
                                value: item.params?.generic,
                                count: gen.hasOwnProperty(index) ? gen[index].count + 1 : 1
                            }
                        }

                        if (item.standard) {
                            tmp.push(item.standard.id)
                        }
                    })
                })

                changeStandards(tmp, 'update')

                if (order?.category?.id) {
                    setCategory(order.category)
                }

                setOrder(order)
            })
        }
    }, [dispatch, id]);

    useEffect(() => {
        const getData = async () => {
            await dispatch(SettingsActions.getSettings('standard')).then(settings => {
                setSettings(settings)
            })
            await dispatch(SystemActions.categories())
            await dispatch(SystemActions.currencies())
            await dispatch(SystemActions.units())
            await dispatch(CompanyActions.company({
                include: "territories,legalDetail.city.region.country,legalDetail.executiveDirector,legalDetail.city.region.country,legalDetail.passportDetails,contactInformation,termsParticipation.regions.region,termsParticipation.files"
            }))
        }

        getData().then(() => {})
    }, [dispatch]);

    const changeStandards = (items, type) => {
        switch (type) {
            case 'add':
                setStandards([...standards, ...items])
                break;
            case 'remove':
                setStandards(standards.filter((standard) => !items.includes(standard)))
                break;
            case 'update':
                setStandards(items)
                break;
        }
    }

    const getStandards = () => {
        return standards.filter(function (el) {
            return el ?? (el != null);
        }).length ? standards.filter(function (el) {
            return el ?? (el != null);
        }).join(',') : null
    }

    const getGenericCategories = (id = null) => {
        id = id ?? categories.find(category => category.name === 'Лекарственные средства').id

        let result = [id]

        categories.filter(category => (category.category && (category.category.id === id))).forEach(category => {
            result = result.concat(getGenericCategories(category.id))
        })

        return result
    }

    const onSubmit = (values, {setSubmitting, setErrors}) => {
        const formData = new FormData()
        console.log(values)
        for (const name in values) {
            if (values.hasOwnProperty(name)) {
                if (Array.isArray(values[name])) {
                    for (let i = 0; i < values[name].length; i++) {
                        for (const val in values[name][i]) {
                            if (values[name][i].hasOwnProperty(val)) {
                                if (Array.isArray(values[name][i][val])) {
                                    if (val === 'files') {
                                        Array.from(values[name][i][val]).forEach((el, index) => {
                                            if (el.file && el.description) {
                                                if (el.file instanceof File) {
                                                    formData.append(`${name}[${i}][${val}][${index}][file]`, el.file, el.file.name);
                                                } else {
                                                    formData.append(`${name}[${i}][${val}][${index}][file][id]`, el.file.id);
                                                }
                                                formData.append(`${name}[${i}][${val}][${index}][description]`, el.description);
                                            }
                                        });
                                    } else {
                                        for (let j = 0; j < values[name][i][val].length; j++) {
                                            for (const item in values[name][i][val][j]) {
                                                if (values[name][i][val][j].hasOwnProperty(item)) {
                                                    if (values[name][i][val][j][item]) {
                                                        switch (item) {
                                                            case 'expiration_date':
                                                                formData.append(`${name}[${i}][${val}][${j}][expiration_date]`, format(values[name][i][val][j].expiration_date, 'yyyy-MM-dd'))
                                                                break
                                                            case 'standard':
                                                                if (values[name][i][val][j][item] instanceof Object) {
                                                                    formData.append(`${name}[${i}][${val}][${j}][standard_id]`, values[name][i][val][j][item].id)
                                                                    formData.append(`${name}[${i}][${val}][${j}][name]`, values[name][i][val][j][item].name)
                                                                    if (categories.find((el) => {
                                                                        return (el.id === category?.id) && (getGenericCategories().indexOf(el.id) !== -1)
                                                                    })) {
                                                                        const {value} = values[name][i][val][j][item].hasOwnProperty('values') ? values[name][i][val][j][item].values.find((val) => {
                                                                            return val.attribute.value === 'generics'
                                                                        }) : {value: values[name][i][val][j][item].generic}

                                                                        formData.append(`${name}[${i}][${val}][${j}][generic]`, value)
                                                                    }
                                                                } else {
                                                                    formData.append(`${name}[${i}][${val}][${j}][name]`, values[name][i][val][j][item])
                                                                }
                                                                break
                                                            case 'params':
                                                                for (const p in values[name][i][val][j][item]) {
                                                                    if (values[name][i][val][j][item].hasOwnProperty(p)) {
                                                                        if (values[name][i][val][j][item][p]) {
                                                                            formData.append(`${name}[${i}][${val}][${j}][${item}][${p}]`, values[name][i][val][j][item][p]);
                                                                        }
                                                                    }
                                                                }
                                                                break;
                                                            case 'unit':
                                                                if (values[name][i][val][j][item] instanceof Object) {
                                                                    formData.append(`${name}[${i}][${val}][${j}][${item}]`, values[name][i][val][j][item].id)
                                                                }
                                                                break
                                                            case 'id':
                                                                if (values[name][i][val][j][item]) {
                                                                    formData.append(`${name}[${i}][${val}][${j}][${item}]`, values[name][i][val][j][item])
                                                                }
                                                                break
                                                            default:
                                                                formData.append(`${name}[${i}][${val}][${j}][${item}]`, values[name][i][val][j][item])
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    if (values[name][i][val]) {
                                        switch (val) {
                                            case 'execution_month':
                                                formData.append(`${name}[${i}][${val}]`, format(values[name][i][val], 'MM.yyyy'))
                                                break
                                            case 'rationale':
                                                if (values[name][i][val] instanceof File) {
                                                    formData.append(`${name}[${i}][${val}]`, values[name][i][val])
                                                }
                                                break
                                            case 'technical_task':
                                                if (values[name][i][val] instanceof File) {
                                                    formData.append(`${name}[${i}][${val}]`, values[name][i][val])
                                                }
                                                break
                                            case 'draft_agreement':
                                                if (values[name][i][val] instanceof File) {
                                                    formData.append(`${name}[${i}][${val}]`, values[name][i][val])
                                                }
                                                break
                                            case 'type':
                                                formData.append(`${name}[${i}][type]`, values[name][i].type)
                                                switch (values[name][i][val]) {
                                                    case 'partial':
                                                        formData.append(`${name}[${i}][prepayment]`, values[name][i].prepayment)
                                                        formData.append(`${name}[${i}][postponement]`, values[name][i].postponement)
                                                        break
                                                    case 'postponement':
                                                        formData.append(`${name}[${i}][postponement]`, values[name][i].postponement)
                                                        break
                                                    default:
                                                }
                                                break
                                            case 'prepayment':
                                                break
                                            case 'postponement':
                                                break
                                            case 'params':
                                                for (const j in values[name][i][val]) {
                                                    if (values[name][i][val].hasOwnProperty(j)) {
                                                        if (values[name][i][val][j]) {
                                                            formData.append(`${name}[${i}][${val}][${j}]`, values[name][i][val][j]);
                                                        }
                                                    }
                                                }
                                                break;
                                            default:
                                                formData.append(`${name}[${i}][${val}]`, values[name][i][val])
                                        }
                                    }
                                }
                            }
                        }

                    }
                } else {
                    if (values[name] || ['cooperative_buying', 'unscheduled_purchase', 'is_unit_price'].includes(name)) {
                        switch (name) {
                            case 'application_start':
                            case 'application_deadline':
                                formData.append(name, format(values[name], 'yyyy-MM-dd HH:mmxxxxx'));
                                break;
                            case 'unscheduled_purchase':
                            case 'is_unit_price':
                            case 'cooperative_buying':
                                formData.append(name, JSON.stringify(values[name] ? 1 : 0));
                                break;
                            case 'params':
                                for (const val in values[name]) {
                                    if (values[name].hasOwnProperty(val)) {
                                        if (values[name][val]) {
                                            switch (val) {
                                                case 'documentation':
                                                    for (const i in values[name][val]) {
                                                        if (values[name][val].hasOwnProperty(i)) {
                                                            if (values[name][val][i]) {
                                                                formData.append(`${name}[${val}][${i}]`, values[name][val][i]);
                                                            }
                                                        }
                                                    }
                                                    break;
                                                case 'result_date':
                                                    formData.append(`${name}[${val}]`, format(values[name][val], 'yyyy-MM-dd HH:mmxxxxx'))
                                                    break;
                                                default:
                                                    formData.append(`${name}[${val}]`, values[name][val]);
                                            }
                                        }
                                    }
                                }
                                break;
                            default:
                                formData.append(name, values[name]);
                                break;
                        }
                    }
                }
            }
        }
        formData.append('from_planning', !!order?.from_planning ? '1' : '0')

        return dispatch(order ? ShopActions.edit(order.id, formData) : ShopActions.add(formData)).then(
            () => {
                setSubmitting(false);
            },
            error => {
                setErrors({
                    ...error.errors,
                    ...(error.errors.hasOwnProperty('lots') ? {
                        lots: error.errors.lots.map(lot => ({
                            ...lot,
                            ...(lot.hasOwnProperty('items') ? {
                                items: lot.items.map(item => ({
                                    ...item,
                                    ...(item.hasOwnProperty('name') ? {
                                        standard: item.name
                                    } : {})
                                }))
                            } : {})
                        }))
                    } : {})
                })
                setSubmitting(false);
            }
        )
    }

    if ((categories && categories.length) && orderSettings && currencies && company && ((id && order) || !id)) {
        return (
            <Grid item className={classes.content}>
                <Paper>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item className={classes.full}>
                            <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                <Grid item className={classes.fullWidth}>
                                    <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                        <Grid item className={classes.header}>
                                            <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                                <Grid item className={classes.fullWidth}>
                                                    <Typography className={classes.title}>
                                                        {order ? (order?.from_planning ? `Публикация № ${order.id}` : `Запрос № ${order.id}`) : "Создать запрос"}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Formik
                                        validateOnBlur={false}
                                        initialValues={function () {
                                            const lots = order ? order.lots.sort((first, second) => {
                                                return second.index - first.index
                                            }).map((lot) => {
                                                const items = lot.items.map((item) => {
                                                    return {
                                                        id: item.id ? item.id : null,
                                                        standard: item.standard_id ? {
                                                            standard_id: item.standard_id,
                                                            name: item.name,
                                                            generic: item.generic
                                                        } : item.name,
                                                        params: item?.params ?? {
                                                            okpd: null,
                                                            okved: null
                                                        },
                                                        amount: item.amount,
                                                        average_price: item.average_price,
                                                        unit: item.unit,
                                                        totalPrice: item.amount * parseFloat(item.average_price),
                                                        price: parseFloat(item.amount * item.average_price).toFixed(2),
                                                        expiration_date: item.expiration_date ? new Date(item.expiration_date.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2-$1-$3")) : null,
                                                        expiration_date_type: item.expiration_date_type ?? '',
                                                        expiration_date_number: item.expiration_date_number ? ((item.expiration_date_type === 'warranty_period') ? parseInt(item.expiration_date_number) : item.expiration_date_number) : '',
                                                    }
                                                })

                                                return {
                                                    execution_month: lot.execution_month ? new Date(lot.execution_month.replace(/(\d{2}).(\d{4})/, "$2.$1")) : null,
                                                    type: lot.prepayment ? 'partial' : (lot.postponement ? 'postponement' : 'complete'),
                                                    postponement: lot.postponement ?? '',
                                                    prepayment: lot.prepayment ?? 1,
                                                    number: lot.number ?? '',
                                                    items: items,
                                                    params: lot?.params ?? {
                                                        information: '',
                                                        subject: '',
                                                        description: '',
                                                    },
                                                    price: items.reduce((sum, item) => {
                                                        return sum + item.totalPrice
                                                    }, 0).toFixed(2),
                                                    rationale: {
                                                        name: (lot.files.filter(el => el.type !== null)).find((file) => file.type.key === 'rationale')?.name
                                                    },
                                                    technical_task: {
                                                        name: (lot.files.filter(el => el.type !== null)).find((file) => file.type.key === 'technical_task')?.name
                                                    },
                                                    draft_agreement: {
                                                        name: (lot.files.filter(el => el.type !== null)).find((file) => file.type.key === 'draft_agreement')?.name
                                                    },
                                                    files: lot.files.filter(el => !el.type).map(el => {
                                                        return {
                                                            file: {
                                                                id: el.id,
                                                                name: el.name
                                                            },
                                                            description: el.description
                                                        }
                                                    }),
                                                    max_contract_price: lot.max_contract_price ?? null
                                                }
                                            }) : []

                                            return {
                                                name: order ? order.name : '',
                                                is_unit_price: order ? order.is_unit_price : false,
                                                type: order ? (order?.from_planning ? order?.conduction_method?.key ?? null : order.type) : 'dynamic',
                                                territory: (order && order.territory) ? order.territory.id : '',
                                                ...((order && [1, 2].includes(order.status.index)) ? {comment: ''} : {}),
                                                cooperative_buying: order ? order.cooperative_buying : false,
                                                unscheduled_purchase: order ? order.unscheduled_purchase : false,
                                                reason: order ? (order.reason ?? '') : '',
                                                purchase_number: order ? (order.purchase_number ?? '') : '',
                                                application_start: order?.application_start ? new Date(order.application_start) : null,
                                                application_deadline: order?.application_deadline ? new Date(order.application_deadline) : null,
                                                category: order?.category?.id ? order.category.id : '',
                                                status: order?.from_planning ? order.status.index : ((order && ![1, 2].includes(order.status.index)) ? 'draft' : null),
                                                currency: order?.currency?.id ? order.currency.id : '',
                                                lots: lots,
                                                price: lots.reduce((sum, lot) => {
                                                    return sum + lot.price
                                                }, 0),
                                                from_planning: order ? order.from_planning : false,
                                                averagePriceTotal: 0,
                                                params: order?.params ? {
                                                    ...order.params,
                                                    result_date: order.params.result_date ? new Date(order.params.result_date) : null,
                                                } : {
                                                    requirements: '',
                                                    documentation: {
                                                        term: '',
                                                        address: '',
                                                        procedure: '',
                                                    },
                                                    payment: '',
                                                    result_date: null,
                                                    information: ''
                                                }
                                            }
                                        }()}
                                        validationSchema={getValidationSchema(order, company, dialog, orderSettings, category, categories, order?.from_planning)}
                                        onSubmit={onSubmit}
                                    >
                                        {({
                                              dirty,
                                              values,
                                              errors,
                                              touched,
                                              isSubmitting,
                                              isValid,
                                              setValues,
                                              setFieldTouched,
                                              setFieldValue,
                                              submitForm,
                                          }) => (
                                            <Form>
                                                <Grid item>
                                                    <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                                                        <Grid item className={classes.tabContent}>
                                                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                                <Grid item xs={6} className={classes.column}>
                                                                    <Field
                                                                        fullWidth
                                                                        name="name"
                                                                        type="text"
                                                                        label="Название"
                                                                        required
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                                {order?.from_planning ? (
                                                                    <Grid item xs={3} className={classes.column}>
                                                                        <Field
                                                                            fullWidth
                                                                            type="text"
                                                                            name="type"
                                                                            label="Способ проведения закупки"
                                                                            select
                                                                            variant="standard"
                                                                            component={TextField}
                                                                            required={true}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        >
                                                                            {conductionMethods.map(conductionMethod => (
                                                                                <MenuItem key={conductionMethod.id} value={conductionMethod.key}>
                                                                                    {conductionMethod.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Field>
                                                                    </Grid>
                                                                ) : (
                                                                    <Grid item xs={3} className={classes.column}>
                                                                        <Field
                                                                            fullWidth
                                                                            type="text"
                                                                            name="type"
                                                                            label="Тип"
                                                                            select
                                                                            variant="standard"
                                                                            disabled
                                                                            component={TextField}
                                                                            required={true}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        >
                                                                            <MenuItem value="static">
                                                                                Статический запрос
                                                                            </MenuItem>
                                                                            <MenuItem value="dynamic">
                                                                                Динамический запрос
                                                                            </MenuItem>
                                                                        </Field>
                                                                    </Grid>
                                                                )}
                                                                <Grid item xs={3} className={classes.column}>
                                                                    <Field
                                                                        fullWidth
                                                                        type="text"
                                                                        name="currency"
                                                                        label="Валюта"
                                                                        select
                                                                        variant="standard"
                                                                        component={TextField}
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                    >
                                                                        {currencies.map(currency => (
                                                                            <MenuItem key={currency.id} value={currency.id}>
                                                                                {currency.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Field>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                <Grid item xs={6} className={errors?.category ? classes.selectCategoryError : classes.selectCategory}>
                                                                    <Category
                                                                        isSubmitting={isSubmitting}
                                                                        label="Категория"
                                                                        value={values.category}
                                                                        onChange={(value) => {
                                                                            if (category?.id !== value?.id) {
                                                                                setCategory(value)
                                                                                changeStandards([], 'update')
                                                                                setValues({
                                                                                    ...values,
                                                                                    lots: [],
                                                                                    price: 0,
                                                                                    averagePriceTotal: 0,
                                                                                    category: value?.id,
                                                                                })
                                                                            }
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={3} className={classes.column}>
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                        <KeyboardDateTimePicker
                                                                            disabled={(order && [1, 2].includes(order.status.index))}
                                                                            disableToolbar
                                                                            fullWidth
                                                                            error={Boolean(errors?.hasOwnProperty('application_start') && touched?.hasOwnProperty('application_start'))}
                                                                            helperText={(errors?.hasOwnProperty('application_start') && touched?.hasOwnProperty('application_start')) ? errors?.application_start : null}
                                                                            name="application_start"
                                                                            format="HH:mm dd-MM-yyyy"
                                                                            variant="inline"
                                                                            openTo="year"
                                                                            label="Дата и время начала приема заявок"
                                                                            autoOk
                                                                            value={values.application_start}
                                                                            onChange={date => {
                                                                                setFieldValue("application_start", date);
                                                                            }}
                                                                            onBlur={() => {
                                                                                setFieldTouched("application_start", true, false)
                                                                            }}
                                                                            onClose={() => {
                                                                                setFieldTouched("application_start", true, false)
                                                                            }}
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                </Grid>
                                                                <Grid item xs={3} className={classes.column}>
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                        <KeyboardDateTimePicker
                                                                            disabled={(order && [1, 2].includes(order.status.index)) && !dirty}
                                                                            disableToolbar
                                                                            fullWidth
                                                                            error={Boolean(errors?.hasOwnProperty('application_deadline') && touched?.hasOwnProperty('application_deadline'))}
                                                                            helperText={(errors?.hasOwnProperty('application_deadline') && touched?.hasOwnProperty('application_deadline')) ? errors?.application_deadline : `Обратите внимание, минимальный срок приёма предложение ${orderSettings.time_to_submit_applications} часа`}
                                                                            name="application_deadline"
                                                                            format="HH:mm dd-MM-yyyy"
                                                                            variant="inline"
                                                                            openTo="year"
                                                                            label="Дата и время окончания приема заявок"
                                                                            autoOk
                                                                            value={values.application_deadline}
                                                                            onChange={date => {
                                                                                setFieldValue("application_deadline", date);
                                                                            }}
                                                                            onBlur={() => {
                                                                                setFieldTouched("application_deadline", true, false)
                                                                            }}
                                                                            onClose={() => {
                                                                                setFieldTouched("application_deadline", true, false)
                                                                            }}
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                                <Grid item xs={6} className={classes.column}>
                                                                    <Field
                                                                        fullWidth
                                                                        name="purchase_number"
                                                                        type="text"
                                                                        label="Номер закупки"
                                                                        disabled={!!values.unscheduled_purchase}
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                                {!order?.from_planning ? (
                                                                    <Grid item xs={3} className={classes.column}>
                                                                        <Field
                                                                            component={CheckboxWithLabel}
                                                                            name="unscheduled_purchase"
                                                                            required={!values.purchase_number}
                                                                            color="primary"
                                                                            Label={{label: "Внеплановая закупка"}}
                                                                            inputProps={{
                                                                                onChange: async (event) => {
                                                                                    const checked = event.target.checked

                                                                                    await setFieldValue("unscheduled_purchase", checked)

                                                                                    if (checked) {
                                                                                        await setFieldValue("purchase_number", '')
                                                                                        await setFieldValue("reason", '')
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                ) : null}
                                                                <Grid item xs={3} className={classes.column}>
                                                                    <Field
                                                                        fullWidth
                                                                        type="text"
                                                                        name="territory"
                                                                        label="Адрес поставки"
                                                                        select
                                                                        variant="standard"
                                                                        component={TextField}
                                                                        disabled={!company.territories.length}
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                    >
                                                                        {company.territories.map(territory => (
                                                                            <MenuItem key={territory.id} value={territory.id}>
                                                                                {territory.address}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Field>
                                                                </Grid>
                                                                {order?.from_planning ? (
                                                                    <Grid item xs={3} className={classes.column}>
                                                                        <Field
                                                                            fullWidth
                                                                            type="text"
                                                                            name="status"
                                                                            label="Статус"
                                                                            select
                                                                            variant="standard"
                                                                            component={TextField}
                                                                            required={true}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                        >
                                                                            {statuses.map(status => (
                                                                                <MenuItem key={status.id} value={status.index}>
                                                                                    {status.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Field>
                                                                    </Grid>
                                                                ) : null}
                                                            </Grid>
                                                            <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={2}>
                                                                <Grid item xs={3} className={classes.column}>
                                                                    <Field
                                                                      fullWidth
                                                                      name="params.requirements"
                                                                      type="text"
                                                                      label="Требования к участникам закупки"
                                                                      component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={3} className={classes.column}>
                                                                    <Field
                                                                      fullWidth
                                                                      name="params.documentation.term"
                                                                      type="text"
                                                                      label="Срок предоставления документации"
                                                                      component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={3} className={classes.column}>
                                                                    <Field
                                                                      fullWidth
                                                                      name="params.documentation.address"
                                                                      type="text"
                                                                      label="Место предоставления документации"
                                                                      component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={3} className={classes.column}>
                                                                    <Field
                                                                      fullWidth
                                                                      name="params.documentation.procedure"
                                                                      type="text"
                                                                      label="Порядок предоставления документации"
                                                                      component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                <Grid item xs={3} className={classes.column}>
                                                                    <Field
                                                                      fullWidth
                                                                      name="params.payment"
                                                                      type="text"
                                                                      label="Размер платы"
                                                                      component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={3} className={classes.column}>
                                                                    <KeyboardDateTimePicker
                                                                      disableToolbar
                                                                      fullWidth
                                                                      error={Boolean(errors?.params?.hasOwnProperty('result_date') && touched?.params?.hasOwnProperty('result_date'))}
                                                                      helperText={(errors?.params?.hasOwnProperty('result_date') && touched?.params?.hasOwnProperty('result_date')) ? errors?.params?.result_date : null}
                                                                      name="params.result_date"
                                                                      format="HH:mm dd-MM-yyyy"
                                                                      variant="inline"
                                                                      openTo="year"
                                                                      label="Дата подведения итогов"
                                                                      autoOk
                                                                      value={values.params.result_date}
                                                                      onChange={date => {
                                                                          setFieldValue("params.result_date", date);
                                                                      }}
                                                                      onBlur={() => {
                                                                          setFieldTouched("params.result_date", true, false)
                                                                      }}
                                                                      onClose={() => {
                                                                          setFieldTouched("params.result_date", true, false)
                                                                      }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6} className={classes.column}>
                                                                    <Field
                                                                      fullWidth
                                                                      name="params.information"
                                                                      type="text"
                                                                      label="Информация о запрете или об ограничении закупок товаров"
                                                                      component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            {values.unscheduled_purchase ? (
                                                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                                    <Grid item xs={6} className={classes.column}>
                                                                        <Field
                                                                            fullWidth
                                                                            name="reason"
                                                                            type="text"
                                                                            label="Обоснование внеплановой закупки"
                                                                            multiline
                                                                            required
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            ) : null}
                                                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                                {values?.from_planning ? <Grid item xs={2} className={classes.column}>
                                                                    <Field
                                                                        component={CheckboxWithLabel}
                                                                        name="is_unit_price" color="primary"
                                                                        Label={{label: "Цена за единицу"}}
                                                                    />
                                                                </Grid> : null}
                                                                <Grid item xs={2} className={classes.column}>
                                                                    <Field
                                                                        component={CheckboxWithLabel}
                                                                        name="cooperative_buying" color="primary"
                                                                        Label={{label: "Совместная закупка"}}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <FieldArray name="lots" render={arrayHelpers => (
                                                                <Group title="Лоты">
                                                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                        <Grid item>
                                                                            <Submit
                                                                                variant="contained"
                                                                                component="label"
                                                                                color="primary"
                                                                                endIcon={<AddIcon/>}
                                                                                onClick={() => {
                                                                                    if (!category.hasOwnProperty('id')) {
                                                                                        return setShowCatError(true);
                                                                                    }
                                                                                    arrayHelpers.insert(0, {
                                                                                        number: '',
                                                                                        execution_month: null,
                                                                                        type: 'complete',
                                                                                        prepayment: 1,
                                                                                        postponement: '',
                                                                                        rationale: null,
                                                                                        draft_agreement: null,
                                                                                        technical_task: null,
                                                                                        items: [],
                                                                                        files: [
                                                                                            {
                                                                                                file: null,
                                                                                                description: ''
                                                                                            }
                                                                                        ]
                                                                                    })
                                                                                }}
                                                                            >
                                                                                Новый лот
                                                                            </Submit>
                                                                        </Grid>
                                                                        {(errors?.lots?.length && !values?.lots?.length) ? (
                                                                            <Grid item>
                                                                                <Typography className={classes.error}>
                                                                                    Для публикации создайте лот!
                                                                                </Typography>
                                                                            </Grid>
                                                                        ) : null}
                                                                        <Grid item>
                                                                            {(!category.hasOwnProperty('id') && showCatError) ? (
                                                                                <Typography className={classes.error}>
                                                                                    Для создания лота сначала выберите категорию
                                                                                </Typography>
                                                                            ) : null}
                                                                        </Grid>
                                                                    </Grid>
                                                                    {values.lots.map((lot, index) => (
                                                                        <Lot
                                                                            key={index}
                                                                            index={index}
                                                                            category={category}
                                                                            currency={values.currency}
                                                                            settings={settings}
                                                                            getStandards={getStandards}
                                                                            changeStandards={changeStandards}
                                                                            lot={lot}
                                                                            values={values}
                                                                            errors={errors}
                                                                            touched={touched}
                                                                            setValues={setValues}
                                                                            setFieldValue={setFieldValue}
                                                                            setFieldTouched={setFieldTouched}
                                                                            remove={() => {
                                                                                changeStandards(lot.items.map((el) => {
                                                                                    if (el.standard) {
                                                                                        return el.standard.id
                                                                                    }
                                                                                    return null
                                                                                }), 'remove')

                                                                                const lots = values.lots.map((lot, idx) => {
                                                                                    if (idx === index) {
                                                                                        lot.price = 0
                                                                                    }

                                                                                    return lot
                                                                                })

                                                                                setValues({
                                                                                    ...values,
                                                                                    lots: lots,
                                                                                    price: lots.reduce(function (sum, lot) {
                                                                                        return sum + parseFloat(lot.price)
                                                                                    }, 0) ?? ''
                                                                                })

                                                                                arrayHelpers.remove(index)
                                                                            }}
                                                                        />
                                                                    ))}
                                                                </Group>
                                                            )}/>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {order ? (
                                                    <Grid item className={classes.footer}>
                                                        <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                                            <Grid item className={classes.footerContent}>
                                                                <Grid container className={classes.footerContainer} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                                    <Grid item>
                                                                        {values.price ? (
                                                                            <Typography>
                                                                                Итого: {values.currency ? parseFloat(values?.is_unit_price ? values.lots.reduce((total, lot) => total + (lot?.max_contract_price ?? 0), 0) : values.price).toLocaleString('ru-RU', {
                                                                                style: 'currency',
                                                                                currency: currencies.find(currency => currency.id === values.currency).code
                                                                            }).replace('KZT', '₸') : parseFloat(values.price)}
                                                                            </Typography>
                                                                        ) : null}
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                                            <Grid item>
                                                                                <Submit
                                                                                    disableElevation
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    type="button"
                                                                                    onClick={() => {
                                                                                        if (order?.from_planning) {
                                                                                            submitForm().then(() => {})
                                                                                        } else if (order.status.index === 0) {
                                                                                            setFieldValue('status', 'draft')
                                                                                            submitForm().then(() => {})
                                                                                        }

                                                                                        if ([1, 2].includes(order.status.index)) {
                                                                                            setDialog({
                                                                                                title: 'Сохранить изменения',
                                                                                                content: (
                                                                                                    <React.Fragment>
                                                                                                        <DialogContent>
                                                                                                            <Grid container direction="row" justify="center" alignItems="stretch" spacing={2}>
                                                                                                                <Grid item xs={8}>
                                                                                                                    <Field
                                                                                                                        fullWidth
                                                                                                                        name="comment"
                                                                                                                        type="text"
                                                                                                                        label="Комментарий"
                                                                                                                        multiline
                                                                                                                        rows={4}
                                                                                                                        required
                                                                                                                        component={CustomTextField}
                                                                                                                    />
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </DialogContent>
                                                                                                        <DialogActions>
                                                                                                            <Grid container direction="row" justify="flex-end" alignItems="stretch">
                                                                                                                <Grid item>
                                                                                                                    <Submit
                                                                                                                        variant="contained"
                                                                                                                        color="primary"
                                                                                                                        type="button"
                                                                                                                        onClick={() => {
                                                                                                                            submitForm().then(() => {})
                                                                                                                        }}
                                                                                                                        disabled={isSubmitting || !isValid}
                                                                                                                    >
                                                                                                                        Сохранить
                                                                                                                    </Submit>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </DialogActions>
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    }}
                                                                                    disabled={isSubmitting || !isValid || !dirty}
                                                                                >
                                                                                    Сохранить
                                                                                </Submit>
                                                                            </Grid>
                                                                            {(!order.from_planning && !order.status.index) ? (
                                                                                <Grid item>
                                                                                    <Submit
                                                                                        disableElevation
                                                                                        variant="contained"
                                                                                        color="primary"
                                                                                        type="button"
                                                                                        onClick={() => {
                                                                                            setFieldValue('status', null)
                                                                                            submitForm().then(() => {})
                                                                                        }}
                                                                                        disabled={isSubmitting || !isValid}
                                                                                    >
                                                                                        Опубликовать
                                                                                    </Submit>
                                                                                </Grid>
                                                                            ) : null}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            {dialog ? (
                                                                <Dialog
                                                                    open={!!dialog}
                                                                    onClose={() => {
                                                                        setDialog(false)
                                                                    }}
                                                                    fullWidth={true}
                                                                    maxWidth={'sm'}
                                                                >
                                                                    <DialogTitle>{dialog.title}</DialogTitle>
                                                                    {dialog.content}
                                                                </Dialog>
                                                            ) : null}
                                                        </Grid>
                                                    </Grid>
                                                ) : (
                                                    <Grid item className={classes.footer}>
                                                        <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                                            <Grid item className={classes.footerContent}>
                                                                <Grid container className={classes.footerContainer} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                                    <Grid item>
                                                                        {values.price ? (
                                                                            <Typography>
                                                                                Итого: {values.currency ? parseFloat(values?.is_unit_price ? values.lots.reduce((total, lot) => total + (lot?.max_contract_price ?? 0), 0) : values.price).toLocaleString('ru-RU', {
                                                                                    style: 'currency',
                                                                                    currency: currencies.find(currency => currency.id === values.currency).code
                                                                                }).replace('KZT', '₸') : parseFloat(values.price)}
                                                                            </Typography>
                                                                        ) : null}
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                                            <Grid item>
                                                                                <Submit
                                                                                    disableElevation
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    type="button"
                                                                                    onClick={() => {
                                                                                        setFieldValue('status', 'draft')
                                                                                        submitForm().then(() => {})
                                                                                    }}
                                                                                    disabled={isSubmitting}
                                                                                >
                                                                                    Черновик
                                                                                </Submit>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Submit
                                                                                    disableElevation
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    type="button"
                                                                                    onClick={() => {
                                                                                        setFieldValue('status', null)
                                                                                        submitForm().then(() => {})
                                                                                    }}
                                                                                    disabled={isSubmitting}
                                                                                >
                                                                                    Опубликовать
                                                                                </Submit>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            {dialog ? (
                                                                <Dialog
                                                                    open={!!dialog}
                                                                    onClose={() => {
                                                                        setDialog(false)
                                                                    }}
                                                                    fullWidth={true}
                                                                    maxWidth={'lg'}
                                                                >
                                                                    <DialogTitle>{dialog.title}</DialogTitle>
                                                                    {dialog.content}
                                                                </Dialog>
                                                            ) : null}
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Form>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }
    return null
}
