import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {
    Grid,
    InputAdornment,
    InputLabel,
    makeStyles,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField
} from "@material-ui/core"

import Typography from "@material-ui/core/Typography"
import format from "date-fns/format"
import {ru} from "date-fns/locale"
import {PurchaseActions} from "./actions/purchase"
import SearchIcon from "@material-ui/icons/Search";
import {endings} from "../App/helpers/endings";
import {Link} from "react-router-dom";
import {Submit} from "../App/components/Button/Submit";
import {history} from "../App/helpers/history";
import {Field, Form, Formik} from "formik";
import {SystemActions} from "../App/actions/system";
import {Select} from "formik-material-ui"
import {PurchaseActions as PlanningPurchaseActions} from "../Planning/actions/purchase";
import {TextField as CustomTextField} from "../App/components/Inputs/TextField";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {SelectTree} from "../App/components/Inputs/SelectTree";
import {useDebounce} from "use-debounce";
import {Clear as ClearIcon, Edit as EditIcon} from "@material-ui/icons";
import {Companies as Dialog} from "../Report/components/Order/Companies";
import * as AppStorage from "../App/helpers/storage";

const useStyles = makeStyles(() => ({
    content: {
        "width": "100%",
        "padding": "30px 250px",
        "background-image": "linear-gradient(to bottom,#f6f6f6,#eee)"
    },
    link: {
        "display": "flex",
        "line-height": "31px",
        "color": "#485868",
    },
    fullWidth: {
        "width": "100%"
    },
    fullWidthDate: {
        "width": "20%"
    },
    title: {
        "font-size": "16px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 126px)"
    },
    containerRows: {
        "width": "100%",
        "overflow": "auto",
        "height": "100%",
        "padding": "15px 15px 0 15px"
    },
    input: {
        "width": "100%",
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100% - 70px)",
        "min-height": "calc(100% - 70px)"
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    tableContainer: {
        "height": "auto",
        "margin-top": "5px",
    },
    table: {
        "border": "1px solid #e4e4e4",
    },
    row: {
        "width": "100%",
        "height": "max-content",
        "margin-bottom": "40px",
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    },
    productName: {
        "max-width": "350px",
        "min-width": "350px",
        "margin": "0",
        "margin-block-start": "0",
        "margin-block-end": "0",
    },
    actions: {
        "max-width": "245px",
        "min-width": "245px",
    },
    filter: {
        "margin-top": "-30px",
    },
    filters: {
        "border": "2px solid #c1c1c1",
        "padding": "8px",
        "margin": "0",
        "background": "#fff",
        "margin-top": "6px !important",
        "transition": "border-color .2s ease-in",
        "border-radius": "2px",
        "width": "calc(100% + 0px)",
        "margin-bottom": "10px"
    }
}))

const FILTERS_KEY = 'HOME_FILTERS';
const defaultValues = {
    categories: [],
    search: '',
    conduction_methods: [],
    statuses: [],
    price_from: '',
    price_to: '',
    created_at_start: null,
    created_at_end: null,
    application_deadline_start: null,
    application_deadline_end: null,
};

const columns = [
    {
        id: 'lot_id',
        label: '№ Лота',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'products',
        label: 'Объект закупки',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'count',
        label: 'Количество',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'average_price',
        label: 'НМЦ',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'postponement',
        label: 'Отсрочка, дней',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'contract_time',
        label: 'Срок исполнения,  дней',
        format: (value) => value.toLocaleString(),
    }
]

export const Index = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {categories} = useSelector(state => state.system)
    const {orders} = useSelector(state => state.purchase)
    const {conductionMethods} = useSelector(state => state.purchases)
    const {status: statuses} = useSelector(state => state.system)
    const [page, setPage] = useState(0)
    const [search, setSearch] = useState('')
    const [searchRequest] = useDebounce(search, 900)
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const containerRef = useRef('')
    const [loading, setLoading] = useState(false)
    const [dialog, setDialog] = useState(false)
    const [customers, setCustomers] = useState([])
    const [resetKey, setResetKey] = useState(1)
    const [initialValues, setInitialValues] = useState({})
    const [loadingOrders, setLoadingOrders] = useState(false)

    const getOrders = (filters = {}) => {
        filters = AppStorage.get(FILTERS_KEY);
        if (filters !== null) {
            filters = JSON.parse(filters);
            setSearch(filters.search)
            setCustomers(filters.customers);
            setInitialValues(filters)
            filters = Object.fromEntries(
                Object.entries(filters).filter(([_, value]) => value !== null)
            )
        } else {
            setInitialValues(defaultValues);
            filters = {};
        }
        dispatch(PurchaseActions.orders({
            ...filters,
            ...{
                from_main_page: true,
                limit: rowsPerPage,
                page: page + 1,
                ...(filters?.customers?.length ? {customers: filters.customers.map(customer => customer.id)} : {}),
                ...(searchRequest !== '' ? {search: searchRequest} : {})
            },
        })).then(() => {
            setLoadingOrders(false)
            containerRef.current && containerRef.current.scrollTo({behavior: 'smooth', top: 0})
        })
    }
    const getData = async () => {
        await dispatch(SystemActions.status())
        await dispatch(SystemActions.categories())
        await dispatch(PlanningPurchaseActions.conductionMethods())
    }

    useEffect(() => {
        getData().then(() => setLoading(true))
    }, [dispatch]);

    useEffect(() => {
        if (!loadingOrders) {
            setLoadingOrders(true)
            getOrders()
        }
    }, [page, rowsPerPage, customers]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const writeFilters = (values, newSearch = null) => {
        AppStorage.set(FILTERS_KEY, JSON.stringify({
            ...values,
            search: newSearch !== null ? newSearch : search,
            customers
        }));
    }

    const setCustomersFromDialog = (values) => {
        let filters = AppStorage.get(FILTERS_KEY);
        if (filters !== null) {
            filters = JSON.parse(filters)
            filters.customers = values;
            AppStorage.set(FILTERS_KEY, JSON.stringify(filters));
        } else {
            AppStorage.set(FILTERS_KEY, JSON.stringify({
                customers: values
            }));
        }
        setCustomers(values);
    }

    return (
        <Grid item className={classes.content}>
            {loading && <Formik
                validateOnBlur={false}
                initialValues={initialValues}
                onSubmit={async (values) => {
                    writeFilters(values)
                    const requestData = {
                        categories: values.categories && values.categories,
                        search: values.search && values.search,
                        conduction_methods: values.conduction_methods && values.conduction_methods,
                        statuses: values.statuses && values.statuses,
                        price_from: values.price_from && values.price_from,
                        price_to: values.price_to && values.price_to,
                        created_at_start: values.created_at_start && values.created_at_start.toISOString().split('T')[0],
                        created_at_end: values.created_at_end && values.created_at_end.toISOString().split('T')[0],
                        application_deadline_start: values.application_deadline_start && values.application_deadline_start.toISOString().split('T')[0],
                        application_deadline_end: values.application_deadline_end && values.application_deadline_end.toISOString().split('T')[0],
                    };
                    const filteredObj = Object.fromEntries(
                        Object.entries(requestData).filter(([_, value]) => value !== null)
                    );
                    await getOrders(filteredObj)
                }}
            >
                {({
                      values,
                      errors,
                      setFieldTouched,
                      touched,
                      setFieldValue,
                      setValues,
                      submitForm
                  }) => (
                    <Form>
                        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                            <Grid item xs={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="center"
                                      spacing={2}>
                                    <Grid item className={classes.fullWidth}>
                                        <TextField
                                            id="search"
                                            variant="standard"
                                            value={values.search}
                                            name="search"
                                            placeholder={`Поиск: номеру закупки, наименование закупки, наименование/ИНН заказчика, номеру запроса, наименованию товара/услуги`}
                                            fullWidth={true}
                                            margin={'normal'}
                                            onChange={(event) => {
                                                setFieldValue('search', event.target.value)
                                                setSearch(event.target.value)
                                                writeFilters(values, event.target.value)
                                                getOrders()
                                            }}
                                            classes={{
                                                root: classes.filterLabel
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <SearchIcon style={{color: '#c1c1c1'}}/>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}
                              className={classes.filters}>
                            <Grid item xs={2}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                    <Grid item xs={6}>
                                        <Field
                                            fullWidth
                                            name="price_from"
                                            type="number"
                                            label="Цена от"
                                            inputProps={{
                                                min: 0,
                                                step: 0.01,
                                            }}
                                            component={CustomTextField}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            fullWidth
                                            name="price_to"
                                            type="number"
                                            label="до"
                                            inputProps={{
                                                min: 0,
                                                step: 0.01
                                            }}
                                            component={CustomTextField}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sx={2}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        fullWidth
                                        error={Boolean(errors.hasOwnProperty('created_at_start') && errors.created_at_start && touched.hasOwnProperty('created_at_start') && touched.created_at_start)}
                                        helperText={errors.hasOwnProperty('created_at_start') && touched.hasOwnProperty('created_at_start') && (errors.created_at_start ? errors.created_at_start : null)}
                                        name="created_at_start"
                                        format="dd-MM-yyyy"
                                        invalidDateMessage="Некорректная дата"
                                        orientation="landscape"
                                        variant="inline"
                                        openTo="year"
                                        autoOk
                                        label="Дата размещения c"
                                        value={values.created_at_start}
                                        onChange={date => {
                                            setFieldValue("created_at_start", date, true)
                                        }}
                                        onBlur={() => {
                                            setFieldTouched(`created_at_start`, true, true)
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item sx={2}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        fullWidth
                                        error={Boolean(errors.hasOwnProperty('created_at_end') && errors.created_at_end && touched.hasOwnProperty('created_at_end') && touched.created_at_end)}
                                        helperText={errors.hasOwnProperty('created_at_end') && touched.hasOwnProperty('created_at_end') && (errors.created_at_end ? errors.created_at_end : null)}
                                        name="created_at_end"
                                        format="dd-MM-yyyy"
                                        invalidDateMessage="Некорректная дата"
                                        orientation="landscape"
                                        variant="inline"
                                        openTo="year"
                                        autoOk
                                        label="Дата размещения по"
                                        value={values.created_at_end}
                                        onChange={date => {
                                            setFieldValue("created_at_end", date, true)
                                        }}
                                        onBlur={() => {
                                            setFieldTouched(`created_at_end`, true, true)
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item sx={3} className={classes.fullWidthDate}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        fullWidth
                                        error={Boolean(errors.hasOwnProperty('application_deadline_start') && errors.application_deadline_start && touched.hasOwnProperty('application_deadline_start') && touched.application_deadline_start)}
                                        helperText={errors.hasOwnProperty('application_deadline_start') && touched.hasOwnProperty('application_deadline_start') && (errors.application_deadline_start ? errors.application_deadline_start : null)}
                                        name="application_deadline_start"
                                        format="dd-MM-yyyy"
                                        invalidDateMessage="Некорректная дата"
                                        orientation="landscape"
                                        variant="inline"
                                        openTo="year"
                                        autoOk
                                        label="Дата окончания подачи заявок c"
                                        value={values.application_deadline_start}
                                        onChange={date => {
                                            setFieldValue("application_deadline_start", date, true)
                                        }}
                                        onBlur={() => {
                                            setFieldTouched(`application_deadline_start`, true, true)
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item sx={3} className={classes.fullWidthDate}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        fullWidth
                                        error={Boolean(errors.hasOwnProperty('application_deadline_end') && errors.application_deadline_end && touched.hasOwnProperty('application_deadline_end') && touched.application_deadline_end)}
                                        helperText={errors.hasOwnProperty('application_deadline_end') && touched.hasOwnProperty('application_deadline_end') && (errors.application_deadline_end ? errors.application_deadline_end : null)}
                                        name="application_deadline_end"
                                        format="dd-MM-yyyy"
                                        invalidDateMessage="Некорректная дата"
                                        orientation="landscape"
                                        variant="inline"
                                        openTo="year"
                                        autoOk
                                        label="Дата окончания подачи заявок по"
                                        value={values.application_deadline_end}
                                        onChange={date => {
                                            setFieldValue("application_deadline_end", date, true)
                                        }}
                                        onBlur={() => {
                                            setFieldTouched(`application_deadline_end`, true, true)
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item>
                                    <Typography>
                                        Заказчики
                                    </Typography>
                                </Grid>
                                <Grid container className={classes.fullWidth} direction="row" justify="flex-start"
                                      alignItems="stretch" spacing={2}>
                                    <Grid item xs={8}>
                                        <TextField
                                            style={{"margin-top": "auto"}}
                                            fullWidth
                                            variant="standard"
                                            name={'customers'}
                                            margin={'normal'}
                                            disabled
                                            value={customers.length ? customers.map(customer => customer.legal_detail.name).join(', ') : 'Все'}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Submit
                                            variant="contained"
                                            component="label"
                                            color="primary"
                                            className={classes.icon}
                                            endIcon={<EditIcon/>}
                                            onClick={() => {
                                                setDialog('customers')
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Submit
                                            variant="contained"
                                            component="label"
                                            color="secondary"
                                            className={classes.icon}
                                            endIcon={<ClearIcon/>}
                                            disabled={!customers.length}
                                            onClick={() => {
                                                setCustomers([])
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}
                              className={classes.filters}>
                            <Grid item xs={3} className={classes.column}>
                                <InputLabel shrink={true}>
                                    Категория
                                </InputLabel>
                                <SelectTree
                                    options={categories.map(category => ({
                                        ...category,
                                        parent: category.category
                                    }))}
                                    value={values.categories}
                                    multiple={true}
                                    onChange={(value) => {
                                        setFieldValue('categories', value);
                                    }}
                                    reset={resetKey}
                                />
                            </Grid>
                            <Grid item xs={3} className={classes.column}>
                                <InputLabel shrink={true}>
                                    Статус
                                </InputLabel>
                                <Field
                                    fullWidth
                                    type="text"
                                    name="statuses"
                                    label="Статус"
                                    multiple
                                    variant="standard"
                                    component={Select}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {
                                        statuses.map(status => (
                                            <MenuItem key={status.index}
                                                      value={status.index}>
                                                {status.name}
                                            </MenuItem>
                                        ))
                                    }
                                </Field>
                            </Grid>
                            <Grid item xs={3} className={classes.column}>
                                <InputLabel shrink={true}>
                                    Способ проведения закупки
                                </InputLabel>
                                <Field
                                    fullWidth
                                    type="text"
                                    name="conduction_methods"
                                    label="Способ проведения закупки"
                                    multiple
                                    variant="standard"
                                    component={Select}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {
                                        conductionMethods.map(conductionMethod => (
                                            <MenuItem key={conductionMethod.id}
                                                      value={conductionMethod.key}>
                                                {conductionMethod.name}
                                            </MenuItem>
                                        ))
                                    }
                                </Field>
                            </Grid>
                            <Grid item xs={1} className={classes.column}>
                                <Submit
                                    className={classes.submit}
                                    disableElevation
                                    variant="contained"
                                    color="secondary"
                                    type="button"
                                    onClick={() => {
                                        setResetKey(resetKey + 1);
                                        setValues(defaultValues)
                                        setCustomers([]);
                                        setSearch('');
                                        AppStorage.remove(FILTERS_KEY);
                                    }}
                                >
                                    Сбросить
                                </Submit>
                            </Grid>
                            <Grid item xs={2} className={classes.column}>
                                <Submit
                                    className={classes.submit}
                                    disableElevation
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    onClick={() => {
                                        submitForm().then(() => {
                                        })
                                    }}
                                >
                                    Применить фильтры
                                </Submit>
                            </Grid>
                        </Grid>
                        <Dialog open={dialog} setDialog={setDialog} setCustomers={setCustomersFromDialog}
                                setSuppliers={() => {
                                }} customers={customers} suppliers={[]} isCompressed={true}/>
                    </Form>
                )}
            </Formik>}
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.fullWidth}>
                        <Grid container className={classes.container} direction="column" justify="space-between"
                              alignItems="stretch">
                            <Grid item className={classes.inputContent}>
                                <Grid ref={containerRef} container className={classes.containerRows} direction="column"
                                      wrap="nowrap" justify="flex-start" alignItems="flex-start">
                                    {orders.data && orders.data.map((order, idx) => {
                                        return (
                                            <Grid item className={classes.row} key={idx}>
                                                <Grid container direction="row" justify="space-between"
                                                      alignItems="stretch">
                                                    <Grid item>
                                                        <Typography className={classes.title}>
                                                            {order.id ? `№${order.id} - ` : null} {order.name ? order.name : null}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className={classes.title}>
                                                            {order.status.name}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container direction="row" justify="space-between"
                                                      alignItems="stretch">
                                                    <Grid item>
                                                        <Typography className={classes.title}>
                                                            {order.company.legal_detail.name}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container direction="row" justify="space-between"
                                                      alignItems="stretch">
                                                    <Grid item>
                                                        <Typography className={classes.title}>
                                                            {order.regions.name + ', '}{endings(order.count.lots, ['лот', 'лота', 'лотов'])}, {order.average_price ? parseFloat(order.average_price).toLocaleString('ru-RU', {
                                                            style: 'currency',
                                                            currency: 'RUB',
                                                        }) : null} от {format(new Date(order.created), 'H:mm PP', {locale: ru})} (мск {format(new Date(new Date(order.created).toLocaleString("en-US", {timeZone: "Europe/Moscow"})), 'H:mm PP', {locale: ru})})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        {order.application_deadline &&
                                                            <Typography className={classes.title}>
                                                                Подача предложений
                                                                до {format(new Date(order.application_deadline), 'H:mm PP', {locale: ru})} (мск {format(new Date(new Date(order.application_deadline).toLocaleString("en-US", {timeZone: "Europe/Moscow"})), 'H:mm PP', {locale: ru})})
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                {order?.conduction_method &&
                                                    <Grid container direction="row" justify="space-between"
                                                          alignItems="stretch">
                                                        <Grid item>
                                                            <Typography className={classes.title}>
                                                                Способ проведения: {order.conduction_method.name}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>}
                                                <TableContainer className={classes.tableContainer}>
                                                    <Table stickyHeader aria-label="sticky table"
                                                           className={classes.table}>
                                                        <TableHead>
                                                            <TableRow>
                                                                {columns.map((column) => (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                    >
                                                                        {column.label}
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {order.lots && order.lots.map((lot, index) => {
                                                                return (
                                                                    <TableRow
                                                                        className={classes.tableRow}
                                                                        hover key={index}
                                                                        onClick={() => {
                                                                            history.push(`/lots/${lot.id}`)
                                                                        }}
                                                                    >
                                                                        <TableCell>
                                                                            {lot.number ? lot.number : lot.index}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {lot.items.map((item, i) => (
                                                                                <Typography key={i}
                                                                                            className={classes.productName}>
                                                                                    {item.name}
                                                                                </Typography>
                                                                            ))}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {lot.items.map((item, i) => (
                                                                                <Typography key={i}>
                                                                                    {item.amount}
                                                                                </Typography>
                                                                            ))}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {lot.items.map((item, i) => (
                                                                                <Typography key={i}>
                                                                                    {item.average_price ? parseFloat(item.average_price).toLocaleString('ru-RU', {
                                                                                        style: 'currency',
                                                                                        currency: order.currency.code
                                                                                    }).replace('KZT', '₸') : '-'}
                                                                                </Typography>
                                                                            ))}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {lot.postponement}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {lot.contract_time}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                            <Grid item className={classes.footer}>
                                <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                    <Grid item className={classes.footerContent}>
                                        <Grid container className={classes.footerContainer} direction="row"
                                              justify="space-between" alignItems="center" spacing={2}>
                                            <Grid item>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                                    component='div'
                                                    labelRowsPerPage={'Записей на странице:'}
                                                    count={orders.meta.total}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Submit
                                                    disableElevation
                                                    variant="contained"
                                                    color="primary"
                                                    type="button"
                                                    component={Link}
                                                    to="/login"
                                                >
                                                    Войти
                                                </Submit>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}
