import React from "react"

import {Content} from "./Order/Content";
import {Menu} from "./Menu";
import {Menu as AppMenu} from "../../App/Menu";
import {Content as AppContent} from "../../App/Content";

export const Order = (props) => {
    return <React.Fragment>
        <AppMenu
            Component={Menu}
            {...props}
        />
        <AppContent
            Component={Content}
            {...props}
        />
    </React.Fragment>
}
