import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {Field, FieldArray, Form, Formik} from "formik"
import {TextField} from "formik-material-ui"
import * as Yup from "yup"
import DateFnsUtils from "@date-io/date-fns"
import format from "date-fns/format"
import {ru} from "date-fns/locale"

import {Grid, IconButton, ListItemIcon, ListItemText, makeStyles, MenuItem, Paper, Typography} from "@material-ui/core"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"
import {Add as AddIcon, Clear, ExpandLess, ExpandMore} from "@material-ui/icons"
import {green} from "@material-ui/core/colors"

import {Submit} from "../../../../App/components/Button/Submit"
import {TextFieldWithError as CustomTextField} from "../../../../App/components/Inputs/TextFieldWithError"
import {SystemActions} from "../../../../App/actions/system"
import {FileUpload} from "../../../../App/components/Inputs/FileUpload"
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon"
import {PurchaseActions} from "../../../actions/purchase"
import {history} from "../../../../App/helpers/history"
import {DialogWithVariantsChoice} from "../../../../App/components/DialogWithVariantsChoice/DialogWithVariantsChoice"
import {LotActions} from "../../../actions/lot"
import {Category} from "../../../../App/components/Inputs/Category";
import {SettingsActions} from "../../../../Settings/actions/settings";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.planningContent.width,
        padding: theme.planningContent.padding,
        margin: theme.planningContent.margin,
        position: "relative"
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important"
    },
    input: {
        "width": "100%"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
        "position": "absolute",
        "bottom": "0",
        "width": "100%",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    contentBody: {
        position: "relative"
    },
    paramsList: {
        height: "calc(100vh - 234px)",
        overflow: "auto",
        padding: "0 25px",
        marginBottom: "70px"
    },
    activeCategory: {
        'background-color': green[100],
        '&:hover': {
            'background-color': 'rgba(0, 0, 0, 0.04) !important',
        }
    },
    defaultCategory: {
        '&:hover': {
            'background-color': 'rgba(0, 0, 0, 0.04) !important',
        }
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    },
    errorFieldDatePicker: {
        "& input": {
            backgroundColor: 'rgb(242 115 96 / 50%)'
        }
    },
    errorField: {
        "& div": {
            backgroundColor: 'rgb(242 115 96 / 50%)'
        }
    },
}));

export const Content = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {purchaseID, lotID} = useParams()
    const {purchase, lot} = useSelector(state => state.purchases)
    const {categories} = useSelector(state => state.purchases)
    const [settings, setSettings] = useState([])
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const getData = async () => {
            await dispatch(SettingsActions.getSettings('planning')).then(settings => {
                setSettings(settings)
            })
            await dispatch(SystemActions.currencies())
            await dispatch(PurchaseActions.conductionMethods())
            await dispatch(PurchaseActions.categories(purchaseID))
            await dispatch(SystemActions.categories())
            await dispatch(PurchaseActions.purchaseByID(purchaseID))
            if (lotID) {
                await dispatch(LotActions.getLot(purchaseID, lotID))
            }
        }

        getData().then(() => {
            setLoading(true)
        })
        // eslint-disable-next-line
    }, [dispatch]);

    const getInitValues = (lotID, lot, categories) => {
        return {
            name: lotID ? (lot.name ?? '') : '',
            start_execution_month: lotID ? new Date(lot.start_execution_month) : null,
            execution_month: lotID ? new Date(lot.execution_month) : null,
            purchase_type: lotID ? lot.purchase_type : '',
            files: [],
            category: lotID ? categories.find(cat => (cat.id === lot.category_id)) : '',
            postponement: lotID ? lot.postponement : null,
            prepayment: lotID ? lot.prepayment : '',
        }
    }

    const getValidationSchema = () => {
        return Yup.object().shape({
            name: Yup.string().nullable(),
            start_execution_month: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
            execution_month: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
            purchase_type: Yup.string().required("Выберите тип оплаты!"),
            files: Yup
                .array()
                .of(Yup.object().shape({
                    file: Yup.mixed().test('fileFormat', 'Документ должен быть файлом одного из следующих типов: csv, xls, xlsx, jpg, jpeg, png, bmp, gif, svg, doc, docx, pdf.', (value) => {
                        return ((value && (value instanceof File) && [
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            'application/vnd.ms-excel',
                            'text/csv',
                            'image/jpeg',
                            'image/gif',
                            'image/svg+xml',
                            'image/bmp',
                            'image/png',
                            'application/msword',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            'application/pdf',
                        ].includes(value.type)) || !value || (value && !(value instanceof File)))
                    }),
                    description: Yup.mixed().when('file', {
                        is: null,
                        then: Yup.mixed().nullable(),
                        otherwise: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                    }),
                })),
            category: Yup.mixed().required("Выберите категорию!"),
            prepayment: Yup.number().nullable().min(1, 'Пожалуйста, введите значение от 1 до 100').max(100, 'Пожалуйста, введите значение от 1 до 100'),
            postponement: Yup.mixed().when('purchase_type', {
                is: 'complete',
                then: Yup.mixed().nullable(),
                otherwise: Yup.number('Поле должно состоять только из цифр!').required("Поле не может быть пустым или состоять из одних пробелов!")
            }),
        })
    }

    const validate = values => {
        const errors = {};

        if (purchase.planing_date_to_publish_notification) {
            const planing_date_to_publish_notification = new Date(purchase.planing_date_to_publish_notification)
            if (values.start_execution_month) {
                if ((planing_date_to_publish_notification.getFullYear() > values.start_execution_month.getFullYear()) || ((planing_date_to_publish_notification.getFullYear() === values.start_execution_month.getFullYear()) && (planing_date_to_publish_notification.getMonth() > values.start_execution_month.getMonth()))) {
                    errors.start_execution_month = 'План. месяц начала исполнения договора должен быть равен или больше месяца план. срока размещения извещения'
                }
            }
        }

        if (settings.categories_id_required_purchase_lot_name?.includes(values.category?.id)) {
            if (!values.name?.length) {
                errors.name = 'Поле не может быть пустым или состоять из одних пробелов!'
            }
        }

        return errors
    }

    const onSubmit = (values, {setSubmitting}) => {
        const formData = new FormData()
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                switch (key) {
                    case 'category':
                        formData.append(`${key}`, values[key].id)
                        break;
                    case 'files':
                        Array.from(values[key]).forEach((el, index) => {
                            if (el.file && el.description) {
                                if (el.file instanceof File) {
                                    formData.append(`${key}[${index}][file]`, el.file, el.file.name);
                                } else {
                                    formData.append(`${key}[${index}][file][id]`, el.file.id);
                                }
                                formData.append(`${key}[${index}][description]`, el.description);
                            }
                        });
                        break;
                    case 'execution_month':
                        formData.append(`${key}`, format(new Date(values[key]), 'yyyy-MM-dd'))
                        break;
                    case 'start_execution_month':
                        formData.append(`${key}`, format(new Date(values[key]), 'yyyy-MM-dd'))
                        break;
                    case 'purchase_type':
                        formData.append(`${key}`, values[key])
                        switch (values[key]) {
                            case 'partial':
                                formData.append(`prepayment`, values.prepayment)
                                formData.append(`postponement`, values.postponement ?? null)
                                break
                            case 'postponement':
                                formData.append(`postponement`, values.postponement ?? null)
                                break
                            default:
                        }
                        break
                    case 'prepayment':
                        break
                    case 'postponement':
                        break
                    default:
                        formData.append(`${key}`, values[key])
                }
            }
        }

        return dispatch(lotID ? LotActions.editLot(purchaseID, lotID, formData) : LotActions.addLot(purchaseID, formData)).then(
            (resp) => {
                setSubmitting(false)
                if (!lotID) {
                    setOpen(resp.lotID)
                }
                history.push(`/planning/purchase-plan/${purchase.purchase_plan_id}/purchase/${purchase.id}`)
            },
            errors => {
                if (errors) {
                }
                setSubmitting(false)
            }
        )
    }

    const changeStartExecutionMonth = (date, setValues, values) => {
        let max = new Date(date)
        max.setMonth(max.getMonth() + 11)

        if (values.execution_month > max) {
            setValues({
                ...values,
                ...{
                    execution_month: max,
                    start_execution_month: date
                }
            })
        } else {
            setValues({
                ...values,
                ...{
                    start_execution_month: date
                }
            })
        }
    }

    const buttons = [
        {
            xs: 12,
            callback: () => {
                history.push(`/planning/purchase-plan/${purchase.purchase_plan_id}/purchase/${purchaseID}`)
            },
            text: 'К списку лотов',
        },
        {
            xs: 12,
            callback: () => {
                history.push(`/planning/purchase-plan/${purchase.purchase_plan_id}/purchase/${purchaseID}/lot/${open}/purchase-product/add`)
            },
            text: 'Добавить ОЗ',
        },
        {
            xs: 12,
            callback: () => {
                history.go(`/planning/purchase/${purchaseID}/lot/add`)
            },
            text: 'Создать новый лот',
        },
    ]

    const getMinDateStartExecutionMonth = (purchase) => {
        const date = purchase.planing_date_to_publish_notification !== null
            ? new Date(purchase.planing_date_to_publish_notification.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2-$1-$3"))
            : new Date(purchase.purchase_plan.start_period);
        date.setDate(1);
        return date
    }

    return loading ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" alignItems="center" className={classes.contentBody}>
                    <Formik
                        validateOnBlur={false}
                        initialValues={getInitValues(lotID, lot, categories)}
                        validationSchema={getValidationSchema()}
                        validate={validate}
                        onSubmit={onSubmit}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              isSubmitting,
                              setValues,
                              setFieldValue,
                              setFieldTouched,
                              handleSubmit
                          }) => (
                            <Form className={classes.fullWidth}>
                                <Grid item className={classes.inputContent}>
                                    <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                        <Grid item className={classes.header}>
                                            <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                                <Grid item className={classes.fullWidth}>
                                                    <Typography className={classes.title}>
                                                        {lotID ? 'Редактирование' : 'Создание'} лота
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <DialogWithVariantsChoice
                                        open={open}
                                        maxWidth={'sm'}
                                        direction={'column'}
                                        onClose={() => history.push(`/planning/purchase-plan/${purchase.purchase_plan_id}/purchase/${purchaseID}`)}
                                        textTitle={'Внимание! Выберете дальнейшее действие'}
                                        buttons={buttons}
                                    />
                                    <Grid container className={classes.paramsList} direction="row" wrap="wrap" spacing={4}>
                                        <Grid item xs={4}>
                                            <Field
                                                fullWidth
                                                required={settings.categories_id_required_purchase_lot_name?.includes(values.category?.index)}
                                                name="name"
                                                type="text"
                                                label="Наименование лота"
                                                component={CustomTextField}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    fullWidth
                                                    name={`start_execution_month`}
                                                    required
                                                    disabled={!purchase.purchase_plan}
                                                    minDate={getMinDateStartExecutionMonth(purchase)}
                                                    maxDate={new Date(`${parseInt(lotID ? lot.execution_month : purchase.purchase_plan.planning_year)}-12-31`)}
                                                    views={["year", "month"]}
                                                    orientation="landscape"
                                                    variant="inline"
                                                    format="MM.yyyy"
                                                    openTo="year"
                                                    autoOk
                                                    label="Планируемый месяц начала исполнения договора"
                                                    value={values.start_execution_month}
                                                    onChange={date => changeStartExecutionMonth(date, setValues, values)}
                                                    onBlur={() => {
                                                        setFieldTouched(`start_execution_month`, true, true)
                                                    }}
                                                    onClose={() => {
                                                        setFieldTouched(`start_execution_month`, true, true)
                                                    }}
                                                    className={touched.start_execution_month && errors.start_execution_month ? classes.errorFieldDatePicker : null}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    fullWidth
                                                    name={`execution_month`}
                                                    required
                                                    disabled={!purchase.purchase_plan}
                                                    minDate={function () {
                                                        if (values.start_execution_month) {
                                                            return values.start_execution_month
                                                        }
                                                        return new Date(lotID ? lot.execution_month : purchase.purchase_plan.start_period)
                                                    }()}
                                                    maxDate={function () {
                                                        if (values.start_execution_month) {
                                                            let max = new Date(values.start_execution_month)
                                                            max.setMonth(max.getMonth() + 11)
                                                            max.setDate(31)
                                                            return max
                                                        }
                                                        return new Date(`${parseInt(lotID ? lot.execution_month : purchase.purchase_plan.planning_year) + 1}-12-31`)
                                                    }()}
                                                    views={["year", "month"]}
                                                    orientation="landscape"
                                                    variant="inline"
                                                    format="MM.yyyy"
                                                    openTo="year"
                                                    autoOk
                                                    label="Планируемый месяц исполнения договора"
                                                    value={values.execution_month}
                                                    onChange={date => {
                                                        setFieldValue(`execution_month`, date);
                                                    }}
                                                    onBlur={() => {
                                                        setFieldTouched(`execution_month`, true, true)
                                                    }}
                                                    onClose={() => {
                                                        setFieldTouched(`execution_month`, true, true)
                                                    }}
                                                    className={touched.execution_month && errors.execution_month ? classes.errorFieldDatePicker : null}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Category
                                                isSubmitting={isSubmitting}
                                                label="Подкатегория"
                                                value={values.category?.id}
                                                options={categories.map(category => category.id)}
                                                onChange={(value) => {
                                                    const category = categories.find(cat => (cat.id === value?.id))

                                                    setFieldValue('category', category)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3} className={classes.column}>
                                            <Field
                                                fullWidth
                                                type="text"
                                                name={`purchase_type`}
                                                label="Тип оплаты"
                                                select
                                                variant="standard"
                                                component={TextField}
                                                required
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                InputProps={{
                                                    onChange: (event) => {
                                                        setFieldValue(`purchase_type`, event.target.value, true)
                                                        setFieldValue(`postponement`, '', false)
                                                        setFieldValue(`prepayment`, 1, false)
                                                    }
                                                }}
                                                className={touched.purchase_type && errors.purchase_type ? classes.errorField : null}
                                            >
                                                {[
                                                    {type: 'complete', name: 'Предоплата'},
                                                    {type: 'partial', name: 'Частичная предоплата'},
                                                    {type: 'postponement', name: 'Постоплата'}
                                                ].map((item, i) => (
                                                    <MenuItem key={i} value={item.type}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Grid>
                                        {((values.purchase_type === 'postponement') || (values.purchase_type === 'partial')) &&
                                            <Grid item xs={3} className={classes.column}>
                                                <Field
                                                    fullWidth
                                                    name={`postponement`}
                                                    type="number"
                                                    label={`${(values.purchase_type === 'postponement') ? 'Отсрочка платежа, дней' : 'Отсрочка постоплаты, дней'}`}
                                                    required
                                                    step={1}
                                                    inputProps={{
                                                        min: 0
                                                    }}
                                                    component={CustomTextField}
                                                />
                                            </Grid>
                                        }
                                        {(values.purchase_type === 'partial') &&
                                            <Grid item xs={2} className={classes.column}>
                                                <Field
                                                    fullWidth
                                                    name={`prepayment`}
                                                    type="number"
                                                    label="Аванс"
                                                    required
                                                    step={1}
                                                    inputProps={{
                                                        min: 1,
                                                        max: 100
                                                    }}
                                                    endAdornment={'%'}
                                                    component={CustomTextField}
                                                />
                                            </Grid>
                                        }
                                        <Grid item xs={12}/>
                                        <Grid item xs={6}>
                                            <FieldArray name={`files`} render={arrayHelpers => (
                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2} style={{maxWidth: "calc(100% + 9px)"}}>
                                                    <Grid item xs={12}>
                                                        <Typography>Документы</Typography>
                                                        <Grid container direction="row" justify="flex-start" className={classes.fileRows} alignItems="flex-start" spacing={2}>
                                                            {values.files.map((el, idx) => (
                                                                <React.Fragment key={idx}>
                                                                    <Grid item xs={5}>
                                                                        <Field
                                                                            fullWidth
                                                                            style={{
                                                                                marginTop: "5px",
                                                                                lineHeight: "16px",
                                                                                fontSize: "16px",
                                                                                padding: "4px 0",
                                                                                paddingTop: "5px",
                                                                            }}
                                                                            name={`files[${idx}].description`}
                                                                            type="text"
                                                                            multiline
                                                                            rows="1"
                                                                            component={CustomTextField}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Field
                                                                            fullWidth
                                                                            size="small"
                                                                            component={(props) => (
                                                                                <FileUpload {...props} endIcon={"Обзор"}/>
                                                                            )}
                                                                            name={`files.${idx}.file`}
                                                                            InputLabelProps={{
                                                                                name: values.files && values.files.length && values.files[idx] && values.files[idx].file && values.files[idx].file.name ? values.files[idx].file.name : null
                                                                            }}
                                                                            InputProps={{
                                                                                onChange: (event) => {
                                                                                    setFieldValue(`files.${idx}.file`, event.currentTarget.files[0])
                                                                                    setFieldTouched(`files.${idx}.file`, true, false)
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={1} style={{padding: "8px 0"}}>
                                                                        <IconButton
                                                                            size="small"
                                                                            style={{padding: "3px 0"}}
                                                                            onClick={() => {
                                                                                arrayHelpers.remove(idx)
                                                                            }}
                                                                        >
                                                                            <Clear/>
                                                                        </IconButton>
                                                                    </Grid>
                                                                </React.Fragment>
                                                            ))}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={5}/>
                                                    <Grid item xs={7} style={{
                                                        display: "flex",
                                                        justifyContent: "flex-start"
                                                    }}>
                                                        <ButtonIcon
                                                            className={classes.button}
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                arrayHelpers.insert(values.files.length, {
                                                                    file: null,
                                                                    description: ''
                                                                })
                                                            }}
                                                            children={<AddIcon/>}>
                                                        </ButtonIcon>
                                                    </Grid>
                                                </Grid>
                                            )}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.footer}>
                                    <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                        <Grid item className={classes.footerContent}>
                                            <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                <Grid item>
                                                    <Submit
                                                        disableElevation
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        onClick={handleSubmit}
                                                        disabled={isSubmitting}
                                                    >
                                                        {lotID ? 'Сохранить' : 'Создать'}
                                                    </Submit>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Paper>
        </Grid>) : null
}
