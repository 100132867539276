import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {Grid, makeStyles, Tab, Tabs, Typography} from "@material-ui/core"
import {ArrowBack as ArrowBackIcon,} from "@material-ui/icons"

import {history} from "../../../../App/helpers/history"
import {PurchaseActions} from "../../../actions/purchase";
import {TabPanel} from "../../../../Account/components/Tabs/TabPanel"
import {round} from "../../../../App/helpers/number"
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon"
import {Chat} from "../../Comment/Chat"
import {PlanningActions} from "../../../actions/planning"
import * as AppStorage from "../../../../App/helpers/storage"
import {Lots} from "../Tables/Lots";
import {Products} from "../Tables/Products";
import {Version} from "../Tables/Version";
import {Log} from "../../Log";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.planningContent.width,
        padding: theme.planningContent.padding,
        margin: theme.planningContent.margin,
        position: 'relative',
    },
    container: {
        "background-image": "linear-gradient(to bottom,#f6f6f6,#eee 85%,#b1b1b1)",
        "width": "100%",
        "height": "calc(100vh - 256px)"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    tabs: {
        "&.MuiTab-textColorPrimary.Mui-selected": {
            backgroundColor: "#fff",
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "4px",
        }
    },
    fontSubtitle: {
        fontWeight: 700
    },
    backIconButton: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        position: 'absolute',
        left: '-8px',
        top: '-15px',
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    },
    header: {
        height: '116px',
        maxHeight: '116px',
        overflow: "hidden"
    }
}))

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {purchasePlanID, purchaseID} = useParams()
    const {account, purchase} = useSelector(state => ({...state.purchasePlans, ...state.account, ...state.purchases}))
    const {units} = useSelector(state => state.purchasePlans)

    const [value, setValue] = useState(0);

    const [tableRef, setTableRef] = useState(null)

    const [tableColumnExtensions] = useState([
        {columnName: 'amount', align: 'right'},
        {columnName: 'total', align: 'right'},
    ]);
    const [totalSummaryItems] = useState([
        {columnName: 'amount', type: 'total_amount'},
        {columnName: 'total', type: 'total_sum'},
    ]);

    const messages = {
        total_amount: 'Кол-во',
        total_sum: 'Итого',
    };

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'name', width: 200},
        {columnName: 'number', width: 200},
        {columnName: 'purchase_type', width: 200},
        {columnName: 'category', width: 250},
        {columnName: 'prepayment', width: 200},
        {columnName: 'funding_sources', width: 200},
        {columnName: 'postponement', width: 200},
        {columnName: 'start_execution_month', width: 200},
        {columnName: 'execution_month', width: 200},
        {columnName: 'items_count', width: 125},
        {columnName: 'total', width: 200},
        {columnName: 'railRoad', width: 125},
        {columnName: 'company', width: 150}
    ])

    useEffect(() => {
        const getData = async () => {
            await dispatch(PlanningActions.units())

            return dispatch(PurchaseActions.purchase(purchaseID)).then(resp => {
                return dispatch(PurchaseActions.inProgress(purchaseID))
            })
        }

        getData().then(() => {})
        // eslint-disable-next-line
    }, [dispatch]);

    const handleChange = (event, newValue) => setValue(newValue);

    const [openComment, setOpenComment] = useState(false)

    const [chat, setChat] = useState({
        id: '',
        title: ''
    })

    const closeComment = () => {
        setOpenComment(false)
        setChat({
            id: '',
            title: ''
        })
    }

    return (purchase && purchase.hasOwnProperty('id') && !!units.length && purchase.hasOwnProperty('status')) ? (
            <Grid item className={classes.content}>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} style={{position: 'relative'}}>
                    {purchasePlanID && <Grid item xs={12}>
                        <ButtonIcon
                            onClick={() => {
                                history.push(`/planning/purchase-plan/${purchasePlanID}`);
                            }}
                            children={<ArrowBackIcon/>}
                            className={classes.backIconButton}
                        />
                    </Grid>}
                    <Grid item xs={12} className={classes.header}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item xs={6}>
                                <Typography className={classes.title}>
                                    Закупка: {purchase.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.title} style={{textAlign: 'end'}}>
                                    Статус: {purchase.status.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <span className={classes.fontSubtitle}>Номер: </span> {purchase.number}
                            </Grid>
                            <Grid item xs={4}>
                                <span className={classes.fontSubtitle}>Категория: </span> {purchase.category ? purchase.category.name : null}
                            </Grid>
                            <Grid item xs={2}>
                                <span className={classes.fontSubtitle}>Количество ОЗ: </span>
                                {round(purchase.items_count === null ? 0 : purchase.items_count, 2)}
                            </Grid>
                            <Grid item xs={2}>
                                <span
                                    className={classes.fontSubtitle}>НМЦ: </span> {purchase.average_price ? parseFloat(purchase.average_price).toLocaleString('ru-RU', {
                                    style: 'currency',
                                    currency: 'RUB'
                                }) : 0}
                            </Grid>
                            <Grid item xs={12}>
                                <span className={classes.fontSubtitle}>Комментарий: </span> {purchase.comment}
                            </Grid>
                        </Grid>
                        {openComment && chat.id &&
                            <Chat anchor={'right'} open={openComment} chatID={chat.id} title={chat.title} onClose={closeComment} user={account} token={AppStorage.get('token')}/>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Tabs
                            value={value}
                            indicatorColor="primary"
                            className={classes.tabs}
                            textColor="primary"
                            onChange={handleChange}
                        >
                            <Tab value={0} label="Лоты" />
                            <Tab value={1} label="Объекты закупок" />
                            <Tab value={2} label="Версии" />
                            <Tab value={3} label="История" />
                        </Tabs>
                    </Grid>
                    <TabPanel value={value} index={0}>
                        <Lots
                            totalSummaryItems={totalSummaryItems}
                            tableColumnExtensions={tableColumnExtensions}
                            setColumnWidths={setColumnWidths}
                            columnWidths={columnWidths}
                            setTableRef={setTableRef}
                            tableRef={tableRef}
                            messages={messages}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Products
                            totalSummaryItems={totalSummaryItems}
                            setOpenComment={setOpenComment}
                            setChat={setChat}
                            setTableRef={setTableRef}
                            tableRef={tableRef}
                            messages={messages}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Version
                            tableColumnExtensions={tableColumnExtensions}
                            tableRef={tableRef}
                            setTableRef={setTableRef}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Log
                            plan={purchase.purchase_plan}
                            purchase={purchase}
                            hiddenColumns={['purchase']}
                            name={'planning_purchase_log'}
                        />
                    </TabPanel>
                </Grid>
            </Grid>
        ) : null
}
