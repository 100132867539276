import {AgreementService} from "../../services/AdditionalAgreement/agreement"

export const AgreementActions = {
    create,
    approve,
    items
}

function create(id, formData) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        AgreementService.create(id, formData)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function approve(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        AgreementService.approve(id)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )
    })
}

function items(id, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        AgreementService.items(id, params)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
