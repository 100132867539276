import {reportTypes} from '../../App/dics/dic'
import * as AppStorage from "../../App/helpers/storage"

function getFilter(){
    let filters = {}
    reportTypes.forEach((type) => filters[type] = {
        category: [],
        period: 1,
        search: null,
        ending: new Date(),
        beginning: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        statuses: [],
        customers: [],
        suppliers: [],
        amountFrom: '',
        amountTo: '',
        closed: [],
        offerItemName: ''
    })
    return filters
}

function getSave(state,action){
    let optionsFilter = {};
    optionsFilter[action.types.value] = action.payload;
    return {
        ...state.filterStorage,
        ...optionsFilter,
    }
}

export default function report(
    state = {
        reports: {
            data: [],
            meta: {
                total: 0
            }
        },
        beginning: null,
        ending: null,
        type:  {value:'purchase_history',name: "История закупок"},
        filter: AppStorage.get('filterReportsMenu') ? JSON.parse(AppStorage.get('filterReportsMenu')) :
            {
                beginning: null,
                ending: null,
                type:  {value:'purchase_history',name: "История закупок"},
                limit: 15,
                page: 1
            },
        filterStorage: (AppStorage.get('filterReports') && JSON.parse(AppStorage.get('filterReports')).purchase_history !== undefined) ? JSON.parse(AppStorage.get('filterReports')) : getFilter()
    },
    action
) {
    switch (action.type) {
        case "REPORTS_REQUEST":
            return state
        case "REPORTS":
            return {
                ...state,
                ...action.payload
            }
        case "REPORTS_FAILURE":
            return state
        case "REPORT_ADD_REQUEST":
            return state
        case "REPORT_ADD_SUCCESS":
            let inside = false

            if (state.beginning && state.ending) {
                const beginning = new Date(state.beginning)
                const ending = new Date(state.ending)
                const created = new Date(action.payload.created)
                ending.setHours(23,59,59,999);

                if (beginning.valueOf() <= created.valueOf() && created.valueOf() <= ending.valueOf()) {
                    inside = true
                }
            }

            return {
                ...state,
                ...{reports: inside ? (state.hasOwnProperty('reports') ? [action.payload, ...state.reports] : [action.payload]) : state.reports}
            }
        case "REPORT_ADD_FAILURE":
            return state
        case "REPORT_FORMED":
            return {
                ...state,
                ...{
                    reports: {
                        data: state.reports.data.map(report => {
                            if (report.id === action.payload) {
                                report.formats = !report.formed ? ['excel', 'pdf'] : report.formats
                                report.formed = true
                            }

                            return report
                        }),
                        meta: state.reports.meta
                    },
                }
            }
        case "REPORT_TYPE":
            return {
                ...state,
                ...{
                    type: action.payload,
                }
            }
        case "REPORT_FILE_REQUEST":
            return state
        case "REPORT_FILE_SUCCESS":
            return {
                ...state,
                ...{file: action.payload}
            }
        case "REPORT_FILE_FAILURE":
            return state
        case "REPORT_FILE_CLEAR":
            return {
                ...state,
                ...{file: null}
            }
        case "REPORTS_FILTER":
            AppStorage.set('filterReportsMenu', JSON.stringify(action.payload))
            return {
                ...state,
                ...{filter:action.payload ?? {beginning: null, ending: null, type: null, limit: 15, page: 1}},
            }
        case "REPORTS_FILTER_STORAGE":
            AppStorage.set('filterReports', JSON.stringify(getSave(state,action)))
            return {
                ...state,
                filterStorage: {
                    ...state.filterStorage,
                    ...getSave(state,action),
                }
            }
        default:
            return state
    }
}
