import {ContractService} from "../services/contract"

export const ContractActions = {
    companies,
    contracts,
    contract,
    edit,
    add,
    reject,
    approve,
    remove,
    items,
    deleteFile,
    changeStatus,
    statuses
}

function companies(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CONTRACT_COMPANIES_REQUEST"})

        ContractService.companies(params)
            .then(
                response => {
                    dispatch({type: "CONTRACT_COMPANIES", payload: {data: response.data, meta: response.meta}})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "CONTRACT_COMPANIES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function contracts(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        ContractService.contracts(params)
            .then(
                response => {
                    dispatch({type: "CONTRACTS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "CONTRACTS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function contract(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CONTRACT_REQUEST"})

        ContractService.contract(id)
            .then(
                contract => {
                    dispatch({type: "CONTRACT", payload: contract})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "CONTRACT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function add(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "ADD_CONTRACT_REQUEST"})

        ContractService.add(values)
            .then(
                contract => {
                    dispatch({type: "ADD_CONTRACT_SUCCESS", payload: contract})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "ADD_CONTRACT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function edit(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "EDIT_CONTRACT_REQUEST"})

        ContractService.edit(id, values)
            .then(
                contract => {
                    dispatch({type: "EDIT_CONTRACT_SUCCESS", payload: contract})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Договор успешно обновлен',
                            type:'success'
                        }
                    })
                    resolve()
                },
                error => {
                    dispatch({type: "EDIT_CONTRACT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function approve(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "EDIT_CONTRACT_REQUEST"})

        ContractService.approve(id)
            .then(
                contract => {
                    dispatch({type: "EDIT_CONTRACT_SUCCESS", payload: contract})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "EDIT_CONTRACT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function reject(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "EDIT_CONTRACT_REQUEST"})

        ContractService.reject(id, values)
            .then(
                contract => {
                    dispatch({type: "EDIT_CONTRACT_SUCCESS", payload: contract})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "EDIT_CONTRACT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function remove(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELETE_CONTRACT_REQUEST"})

        ContractService.remove(id)
            .then(
                response => {
                    dispatch({type: "DELETE_CONTRACT_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELETE_CONTRACT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function items(id, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CONTRACT_ITEMS_REQUEST"})

        ContractService.items(id, params)
            .then(
                response => {
                    dispatch({type: "CONTRACT_ITEMS", payload: response})
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "CONTRACT_ITEMS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function deleteFile(order_id, file_id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELETE_CONTRACT_FILE_REQUEST"})

        ContractService.deleteFile(order_id, file_id)
            .then(
                response => {
                    dispatch({type: "DELETE_CONTRACT_FILE_SUCCESS", payload: file_id})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELETE_CONTRACT_FILE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function changeStatus(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CHANGE_STATUS_CONTRACT_REQUEST"})

        ContractService.changeStatus(id, values)
            .then(
                () => {
                    dispatch({type: "CHANGE_STATUS_CONTRACT_SUCCESS"})
                    dispatch({
                        type: "ALERT_SUCCESS",
                        payload: {
                            message: 'Статус успешно изменён',
                            type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "CHANGE_STATUS_CONTRACT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function statuses(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "CONTRACT_STATUSES_REQUEST"})

        ContractService.statuses(id, values)
            .then(
                data => {
                    dispatch({type: "CONTRACT_STATUSES", payload: data})
                    dispatch({type: "LOADING", payload: false})
                    resolve(data)
                },
                error => {
                    dispatch({type: "CONTRACT_STATUSES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
