import {HttpService} from "../../../App/services/http"

export const ShopService = {
    orders,
    allOrders,
    order,
    add,
    edit,
    remove,
    offer,
    lot,
}

function orders(params) {
    return HttpService.get("/shop/supplier/orders", params)
}

function allOrders(params) {
    return HttpService.get("/purchase/orders", params)
}

function order(id) {
    return HttpService.get(`/shop/supplier/order/${id}`)
        .then(response => {
            return response.order
        })
}


function lot(id) {
    return HttpService.get(`/shop/supplier/lot/${id}`)
        .then(response => {
            return response.lot
        })
}

function offer(id) {
    return HttpService.get(`/shop/supplier/offer/${id}`)
        .then(response => {
            return response.data
        })
}

function add(formData) {
    return HttpService.post("/shop/supplier/offer", formData, true, true)
}

function edit(id, formData) {
    return HttpService.post(`/shop/supplier/offer/${id}`, formData, true, true)
}

function remove(id) {
    return HttpService.deleteMethod(`/shop/order/${id}`)
}
