export default function cumulativeDiscounts(state = {discounts: {data: [], meta: {total: 0, per_page: 15, current_page: 1}}}, action) {
    switch (action.type) {
        case "SUPPLIER_CUMULATIVE_DISCOUNTS_REQUEST":
            return {
                discounts: state.discounts
            }
        case "SUPPLIER_CUMULATIVE_DISCOUNTS":
            return {
                discounts: action.payload,
            }
        case "SUPPLIER_CUMULATIVE_DISCOUNTS_FAILURE":
            return {
                discounts: {}
            }
        case "SUPPLIER_CUMULATIVE_DISCOUNT_REQUEST":
            return {
                discount: state.discount,
                discounts: state.discounts
            }
        case "SUPPLIER_CUMULATIVE_DISCOUNT_RESET":
            return {
                discounts: state.discounts
            }
        case "SUPPLIER_DISCOUNT":
            return {
                discount: action.payload,
                discounts: state.discounts
            }
        case "SUPPLIER_CUMULATIVE_DISCOUNT_FAILURE":
            return {
                discount: state.discounts,
                discounts: state.discounts
            }
        default:
            return state
    }
}
