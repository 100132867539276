import React, {useState} from "react"
import {useDispatch} from "react-redux"

import {InputAdornment, Typography} from '@material-ui/core'
import {Field} from "formik";
import {DeliveryActions} from "../../actions/delivery";
import {TextFieldWithError as CustomTextField} from "../../../App/components/Inputs/TextFieldWithError";
import {round} from "../../../App/helpers/number";
import {DialogWithVariantsChoice} from "../../../App/components/DialogWithVariantsChoice/DialogWithVariantsChoice";

export const Amount = (props) => {
    const dispatch = useDispatch()

    const {name, order, item, disabled = false, setFieldValue, onBlur} = props

    const [dialog, setDialog] = useState(false)
    const [amount] = useState(item.amount)

    return <React.Fragment>
        <Field
            fullWidth
            name={name}
            type="number"
            inputProps={{
                ...{
                    step: 0.0001,
                    min: parseFloat(item.min_ordered_quantity ?? 0).toFixed(4),
                    onChange: (e) => {
                        const {value, name} = e.target
                        setFieldValue(name, value)
                    },
                    onBlur: (e) => {
                        const value = parseFloat(e.target.value).toFixed(4)

                        let condition = (!item.can_be_ordered || order.contract.offer.lot?.order?.form) ? (parseFloat(value) <= parseFloat((parseFloat(item.needed) + parseFloat(item.amount)).toFixed(4))) : true

                        if (!isNaN(value) && (value >= parseFloat(item.min_ordered_quantity ?? 0)) && condition) {
                            if (value === parseFloat(0).toFixed(4)) {
                                setDialog(true)
                            } else {
                                return dispatch(DeliveryActions.editItem(order.id, item.id, {amount: value})).then(onBlur)
                            }
                        }
                    }
                },
                ...((item.can_be_ordered || !order.contract.offer.lot?.order?.form) ? {} : {max: parseFloat(item.needed) + parseFloat(item.amount)})
            }}
            component={CustomTextField}
            endAdornment={item.unit ? <InputAdornment position="end">{item.unit.short}</InputAdornment> : null}
            disabled={disabled}
        />
        {Boolean(Number(item.amount_on_order)) && <Typography>*{round(item.amount_on_order, 4)} под заказ</Typography>}
        {dialog &&
            <DialogWithVariantsChoice
                open={dialog}
                textTitle={'Удалить позицию?'}
                buttons={[
                    {
                        xs: 6,
                        callback: () => {
                            setDialog(false)

                            return dispatch(DeliveryActions.removeItem(order.id, item.id)).then(onBlur)
                        },
                        text: 'Да',
                        color: 'secondary'
                    },
                    {
                        xs: 6,
                        callback: () => {
                            setDialog(false)
                            setFieldValue(name, amount)
                        },
                        text: 'Нет',
                    },
                ]}
            />
        }
    </React.Fragment>
}
