const getShortFullName = (user) => {
    return `${user.last_name ?? ''} ${user.first_name ? user.first_name.substring(0, 1) + '.' : ''} ${user.middle_name ? user.middle_name.substring(0, 1) + '.' : ''}`
}

const getFullName = (user) => {
    return `${user.last_name ?? ''} ${user.first_name ?? ''} ${user.middle_name ?? ''}`
}

export {
    getShortFullName,
    getFullName
}
