import React, {useState} from "react"
import {Link} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"

import {Grid, List, ListItem, ListItemText, makeStyles, Typography, withStyles} from '@material-ui/core'

import {AuthorizationService} from "../Auth/services/authorization"
import {AddProductDialog} from "./components/AddProductDialog"
import {Submit} from "../App/components/Button/Submit"

const useStyles = makeStyles(() => ({
    active: {
        "background-image": "none !important",
        "background-color": "#898989"
    },
    fullWidth: {
        width: "100%",
    },
    container: {
        height: "100%",
        width: "100%",
        margin: 0,
    },
    buttonBlock: {
        width: "100%",
        marginBottom: "50px",
    },
    submit: {
        margin: "15px 20px",
        width: "auto",
        minWidth: "calc(100% - 40px)",
    },
    labelAdd: {
        margin: "15px 20px",
    },
    priceName: {
        fontWeight: 600,
    }
}))

const CustomList = withStyles({
    root: {
        "padding": "0",
        "& .MuiListItem-button": {
            "background-image": "linear-gradient(to bottom,#e4e4e4,#f4f4f4)",
            "cursor": "pointer",
            "align-items": "center",
            "min-height": "50px",
            "padding": "5px 25px 0",
            "border-bottom": "1px solid #e4e4e4",
            "outline": "0"
        },
        "& .MuiListItemText-primary": {
            "font-size": "16px",
            "font-weight": "700",
            "text-transform": "uppercase"
        },
        "& .MuiListItem-button:hover": {
            "background-color": "#898989",
            "&:after": {
                "position": "absolute",
                "content": "''",
                "top": "0",
                "left": "0",
                "width": "100%",
                "height": "100%",
                "background-color": "rgba(0,0,0,.04)"
            }
        }
    }
})(List);

export const Menu = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {location} = props
    const {activePrice} = useSelector(state => state.prices)
    const {account} = useSelector(state => state.account)
    const {categories} = useSelector(state => state.system)
    const list = [
        {title: "Мои прайс-листы", to: "/supplier/prices", type: 'menu'},
    ]

    const [openAddProduct, setOpenAddProduct] = useState(false);

    const closeAddProductDialog = () => {
        setOpenAddProduct(false)
    }

    const getPrice = () => {
        if (!AuthorizationService.permissions(account, "access_to_elmag") && ((activePrice && activePrice.hasOwnProperty('type') && activePrice.type !== "contract") || !activePrice)) {
            return null
        }
        return activePrice && activePrice.hasOwnProperty('name') ? (
            <React.Fragment>
                к прайс-листу:
                <span
                    className={classes.priceName}
                >
                    {activePrice.name}
                </span>
            </React.Fragment>
        ) : (
            <span className={classes.priceName}>в общий доступ</span>
        )
    }

    const addProduct = () => {
        if (!activePrice) {
            dispatch({
                type: "SET_ACTIVE_PRICE",
                payload: {
                    priceId: null,
                    type: 'private'
                }
            })
        }
        setOpenAddProduct(true)
    }

    return (
        <React.Fragment>
            <Grid container direction="column" justify="space-between" alignItems="center"
                  className={classes.container}>
                <Grid item className={classes.fullWidth}>
                    <CustomList>
                        {list.map(option => (
                            <ListItem button key={option.title} component={Link} to={option.to}
                                      classes={{root: (location.pathname === option.to) ? classes.active : undefined}}>
                                <ListItemText primary={option.title} />
                            </ListItem>
                        ))}
                    </CustomList>
                </Grid>
                <Grid item className={classes.buttonBlock}>
                    {openAddProduct && <AddProductDialog categories={categories} open={openAddProduct} onClose={closeAddProductDialog} mode={'create'} />}
                    <Typography className={classes.labelAdd}>Добавить товар {getPrice()}</Typography>
                    <Submit
                        className={classes.submit}
                        disabled={!activePrice}
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={addProduct}
                    >
                        Добавить товар
                    </Submit>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
