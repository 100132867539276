import {useEffect, useRef, useState} from 'react'
import socketIOClient from 'socket.io-client'

import {useBeforeUnload} from './useBeforeUnload'

export const useChat = (chatId, user, token) => {
  const [users, setUsers] = useState([])
  const [messages, setMessages] = useState([])
  const socketRef = useRef(null)
  useEffect(() => {
    socketRef.current = socketIOClient(process.env.REACT_APP_COMMENTS_SOCKET_HOST, {
      path: process.env.REACT_APP_COMMENTS_SOCKET_URL,
      query: { chatId }
    })

    socketRef.current.emit('user:add', { token })

    socketRef.current.on('user:connect', () => {
      socketRef.current.emit('user:add', { token })
    })

    socketRef.current.on('users', (users) => {
      setUsers(users)
    })

    socketRef.current.on('user:add', () => {
      socketRef.current.emit('message:get')
    })

    socketRef.current.on('messages', (messages) => {
      setMessages(messages)
    })

    return () => {
      socketRef.current.disconnect()
    }

  }, [chatId, token])

  const sendMessage = async (text) => {
    socketRef.current.emit('message:add', {
      text: text,
      chat_id: chatId
    })
  }

  useBeforeUnload(() => {
    socketRef.current.emit('disconnect', user.id)
  })

  return { users, messages, sendMessage }
}
