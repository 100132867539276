import {HttpService} from "../../../App/services/http"

export const ItemService = {
    create,
    update,
    remove
}

function create(id, values) {
    return HttpService.post(`/contract/${id}/agreement/item`, values).then(response => response.item)
}

function update(contract, id, values) {
    return HttpService.put(`/contract/${contract}/agreement/item/${id}`, values, true, true)
}

function remove(contract, id) {
    return HttpService.deleteMethod(`/contract/${contract}/agreement/item/${id}`)
}
