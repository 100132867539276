import {HttpService} from "../../App/services/http"

export const SettingsService = {
    settings,
    update
}

function settings() {
    return HttpService.get(`/settings/planning`)
        .then(response => {
            return response.settings
        })
}

function update(values) {
    return HttpService.put("/settings/planning", values)
        .then(response => {
            return response.settings
        })
}
