import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {v4 as uuidv4} from 'uuid'
import {ru} from "date-fns/locale"

import {FormControl, Grid, makeStyles, MenuItem, Select, Typography} from "@material-ui/core"
import {Delete as DeleteIcon} from "@material-ui/icons"
import {Table} from "@devexpress/dx-react-grid-material-ui";

import {history} from "../../../App/helpers/history"
import {ButtonList} from "../../../App/components/ButtonList/ButtonList"
import {PriceActions} from "../../actions/price"
import {ProductListDialog} from "../ProductListDialog"
import {SystemActions} from '../../../App/actions/system'
import {CompanyActions} from "../../../Account/actions/company"
import {getPriceName} from "../../../App/helpers/name"
import {Submit} from "../../../App/components/Button/Submit"
import {ButtonIcon} from "../../../App/components/Button/ButtonIcon"
import {AuthorizationService} from "../../../Auth/services/authorization"
import format from "date-fns/format";
import {TableHeader} from "../../../App/components/Table/TableHeader";
import {CustomTable} from "../../../App/components/Table/Table";
import {SettingsActions} from "../../../Settings/actions/settings";
import {localState} from "../../../App/components/LocalState";
import {NewPrice as Dialog} from "./NewPrice";
import {ColumnChooser} from "../../../App/components/Table/ColumnChooser";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 202px)",
        "min-height": "calc(100vh - 202px)"
    },
    select: {
        'padding-left': "10px",
        "div": {
            marginTop: "0 !important",
        },
        "&:focus": {
            "background-color": "#fff",
        },
    },
    disabled: {
        "color": "#c1c1c1 !important",
    },
    formControl: {
        // "width": "100%",
        "&>div": {
            "&:before": {
                "border-bottom": "none",
                "border-bottom-style": "none !important",
            },
        },
    },
    tableRow: {
        cursor: "pointer",
    },
    tableRowActive: {
        "background-color": "rgb(0,0,0, 0.1);",
        cursor: "pointer",
    },
    tableRowDisabled: {
        pointerEvents: "none",
    },
    delete: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "#ad3e3b",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "#fff",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-bottom": "3px solid #ad3e3b",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "#d9534f",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    },
    contractNumber: {
        width: "fit-content",
        maxWidth: "200px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textDecoration: "underline",
    },
    itemsNameLink: {
        width: "fit-content",
        textDecoration: "underline",
        cursor: "pointer",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "250px"
    },
    button: {
        marginBottom: '10px'
    }
}))

const valButtonlist = [
    {
        key: 'all',
        value: 'Все'
    },
    {
        key: 'is_active',
        value: 'Активен'
    },
    {
        key: 'deleted',
        value: 'Удален'
    },
    {
        key: 'error',
        value: 'Не удалось загрузить'
    },
    {
        key: 'done_with_error',
        value: 'Загружен с ошибками'
    },
]

const downLoadTypes = [{key: 'file', value: 'Из файла'}, {key: 'ftp', value: 'FTP'}, {key: 'url', value: 'По ссылке'}];

const priceStatus = {
    in_process: 'в обработке',
    done: 'завершено',
    error: 'ошибка',
    deleted: 'удален',
    done_with_error: 'загружен с ошибками',
    not_active: 'неактивный',
};

const columns = [
    {name: 'type_download', title: 'Тип загрузки'},
    {name: 'price_name', title: 'Прайс-лист'},
    {name: 'contract_numbers', title: 'Номер договора'},
    {name: 'upload_period', title: 'Период обновления'},
    {name: 'deadline', title: 'Срок действия, до'},
    {name: 'rows_success', title: 'Загружено'},
    {name: 'rows_error', title: 'Ошибок'},
    {name: 'status', title: 'Статус'},
    {name: 'action', title: 'Действие'},
]

const defaultTypes = [
    {
        key: false,
        value: 'Без договора'
    },
    {
        key: true,
        value: 'C договором'
    }
]

const typeWithContract = [
    {
        key: true,
        value: 'C договором'
    }
]

export const Content = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [page, setPage] = useState(1)
    const [tabValue, setTab] = useState(0)
    const [status, setStatus] = useState('all')

    const [tableRef, setTableRef] = useState(null)
    const {filter} = useSelector(state => state.filters['prices'])
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [columnOrder, setColumnOrder] = useState([
        'type_download',
        'price_name',
        'contract_numbers',
        'upload_period',
        'deadline',
        'rows_success',
        'rows_error',
        'status',
        'action',
    ])

    const [initialize, setInitialize] = useState(false);

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'type_download', width: 150},
        {columnName: 'price_name', width: 200},
        {columnName: 'contract_numbers', width: 150},
        {columnName: 'upload_period', width: 150},
        {columnName: 'deadline', width: 150},
        {columnName: 'rows_success', width: 100},
        {columnName: 'rows_error', width: 100},
        {columnName: 'status', width: 100},
        {columnName: 'action', width: 100},
    ])
    const [withContractTab, setWithContractTab] = useState(0)

    const {categories} = useSelector(state => state.system)
    const {priceTypes} = useSelector(state => state.priceTypes)
    const {account} = useSelector(state => state.account)

    const accessToElmag = AuthorizationService.permissions(account, ["access_to_elmag", "access_to_marketplace"], true)
    const [withContract, setWithContract] = useState(!accessToElmag)

    const [prices, setPrices] = useState(localState)

    const [dialog, setDialog] = useState(false)

    const {getPrice} = useSelector(state => state.prices)
    const [open, setOpen] = useState(false);
    const [priceId, setPriceId] = useState('');
    const [activePrice, setActivePrice] = useState('private')
    const types = accessToElmag ? defaultTypes : typeWithContract

    const [priceToDownload, setPriceToDownload] = useState({
            priceId: uuidv4(),
            url: '',
            downloadType: 'file',
            updatePeriod: '',
            files: null,
            deadline: null,
        }
    )

    const downLoadTypesNodes = downLoadTypes.map((obj) =>
        <MenuItem value={obj.key}>{obj.value}</MenuItem>
    )

    useEffect(() => {
        if (!initialize) {
            dispatch(SettingsActions.table('prices')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'prices',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])


    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
        }
    }

    const getPrices = async () => {
        let params = {
            companyId: account.company.id,
            limit: rowsPerPage,
            page: page,
            status: status,
            type: withContract ? priceTypes.data.find((type) => type.name === 'contract').name : priceTypes.data.find((type) => type.name === 'public').name,
            ...getParams()
        }
        const result = await dispatch(withContract ? PriceActions.pricesContract(params) : PriceActions.get(params))
        setPrices(result)
    }

    useEffect(() => {
        const getCompany = async () => {
            return await dispatch(CompanyActions.company({
                include: "territories,legalDetail.city.region.country,legalDetail.executiveDirector,legalDetail.city.region.country,legalDetail.passportDetails,contactInformation,termsParticipation.regions.region,termsParticipation.files"
            }))
        }

        const getTypes = async () => {
            await dispatch(PriceActions.types())
        }

        if (!priceTypes) {
            getTypes().then((p) => {
                getCompany().then(() => {
                })
            })
        }

        if (priceTypes) {
            getPrices(priceTypes).then(() => {
                tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
            })
        }
        return () => {
            dispatch({type: "SET_ACTIVE_PRICE", payload: null})
        }
        // eslint-disable-next-line
    }, [dispatch, page, rowsPerPage, status, account.company.id, withContract, priceTypes, getPrice, filter])

    useEffect(() => {
        const getCategories = async () => {
            return await dispatch(SystemActions.categories())
        }

        if (!categories.length) {
            getCategories().then(() => {
            })
        }
        // eslint-disable-next-line
    }, [dispatch]);

    const openDialog = (price, open) => {
        setOpen(open)
        setPriceId(price.id)
        dispatch({
            type: "SET_ACTIVE_PRICE", payload: {
                priceId: price.id,
                type: price.type.name,
                name: getPriceName(price),
                status: price.status,
                category: price.category
            }
        })
    }

    const handleClose = () => {
        setOpen(false)
        setPriceId('')
        dispatch({type: "SET_ACTIVE_PRICE", payload: null})
    }

    const tabChange = (ev, newValue) => {
        setTab(newValue);
        setStatus(valButtonlist[newValue].key);
        setActivePrice('private')
    }

    const tabContractChange = (ev, newValue) => {
        setWithContractTab(newValue);
        setActivePrice('private')
        setWithContract(types[newValue].key);
        dispatch({type: "SET_ACTIVE_PRICE", payload: null})
    }

    const del = (e, id) => {
        e.stopPropagation()
        return dispatch(PriceActions.del(id))
    }

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: 'PRICES_FILTER', payload: {limitRows: newRowsPerPage}})
    }

    const getClass = (activePrice, price) => {
        if (price.status === 'deleted') {
            return classes.tableRowDisabled
        }
        return activePrice === price.id ? classes.tableRowActive : classes.tableRow
    }

    return <Grid item className={classes.content}>
        <Grid container spacing={2} justify="flex-end">
            <Grid item className={classes.button}>
                <Submit
                    disableElevation
                    disabled={withContract}
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => {
                        setDialog(true)
                    }}
                >
                    Загрузить прайс-лист
                </Submit>
            </Grid>
            <Grid item>
                <ColumnChooser columns={columns.filter(column => column.name !== 'action')} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
            </Grid>
        </Grid>
        <ProductListDialog id={priceId} account={account} categories={categories} open={open} onClose={handleClose} withContract={withContract}/>
        <Grid container spacing={0} justify="space-between">
            <Grid item>
                <ButtonList value={withContractTab} values={types} onChange={tabContractChange}/>
            </Grid>
            <Grid item>
                <ButtonList value={tabValue} values={valButtonlist} onChange={tabChange}/>
            </Grid>
        </Grid>
        <CustomTable
            meta={prices.meta}
            tableName={'prices'}
            rows={prices?.data.map((price) => ({
                type_download: <FormControl classes={{root: classes.formControl,}}>
                    <Select
                        labelId={price.id}
                        id={price.id}
                        classes={{
                            select: classes.select,
                            disabled: classes.disabled,
                        }}
                        value={price.download_type ?? ''}
                        disabled={true}
                        children={downLoadTypesNodes}
                    >
                    </Select>
                </FormControl>,
                price_name: <Typography
                    className={classes.itemsNameLink}
                    onClick={(e) => {
                        e.stopPropagation()
                        openDialog(price, true)
                    }}
                >
                    {getPriceName(price)}
                </Typography>,
                upload_period: <Typography className={classes.itemsNames}>
                    {price.tasks && price.tasks.length > 0 ? price.tasks[0].update_time : ''}
                </Typography>,
                status: <Typography className={classes.itemsNames} style={{minWidth: "80px"}}>
                    {priceStatus[price.status]}
                </Typography>,
                action: price.status !== 'deleted' ? (
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <ButtonIcon
                                onClick={(e) => {
                                    e.stopPropagation()
                                    del(e, price.id)
                                }}
                                children={<DeleteIcon/>}
                                className={classes.delete}
                            />
                        </Grid>
                    </Grid>
                ) : null,
                deadline: <Typography className={classes.itemsNames}>{price.deadline ? format(new Date(price.deadline), 'H:mm PP', {locale: ru}) : null}</Typography>,
                contract_numbers: <Grid item style={{maxWidth: "150px"}}>
                    {price.contract_numbers
                        ? price['contract_numbers'].map((number, idx) =>
                            <Typography
                                key={number}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    history.push(`/contracts?id=${price['contract_ids'][idx]}`)
                                }}
                                className={classes.contractNumber}
                                noWrap
                            >
                                {number}
                            </Typography>
                        )
                        : null}
                </Grid>,
                rows_success: <Typography className={classes.itemsNames}>{price.rows_success}</Typography>,
                rows_error: <Typography className={classes.itemsNames}>{price.rows_error}</Typography>,
            }))}
            columns={columns}
            page={{
                page: page,
                setPage: setPage,
                rowsPerPage: rowsPerPage,
                handlePageSizeChange: handlePageSizeChange
            }}
            setTableRef={setTableRef}
            columnsSettings={{
                columnOrder: columnOrder,
                setColumnOrder: setColumnOrder,
                setColumnWidths: setColumnWidths,
                columnWidths: columnWidths,
                hiddenColumnNames: hiddenColumnNames,
                setHiddenColumnNames: setHiddenColumnNames
            }}
            tableHeader={TableHeader}
            filterActionType={"PRICES_FILTER"}
            classInputContent={classes.inputContent}
            rowComponent={({row, tableRow, children}) => (
                <Table.Row
                    className={getClass(activePrice, prices.data[tableRow.rowId])}
                    key={prices.data[tableRow.rowId].id}
                    onClick={() => {
                        let id = prices.data[tableRow.rowId].id === activePrice ? 'private' : prices.data[tableRow.rowId].id
                        setActivePrice(id)
                        if (activePrice && id === 'private') {
                            dispatch({type: "SET_ACTIVE_PRICE", payload: null})
                        } else {
                            dispatch({
                                type: "SET_ACTIVE_PRICE", payload: {
                                    priceId: id,
                                    type: prices.data[tableRow.rowId].type.name,
                                    name: getPriceName(prices.data[tableRow.rowId].id),
                                    category: prices.data[tableRow.rowId].category
                                }
                            })
                        }

                    }}
                    tableRow={tableRow}
                    hover
                    row={row}
                    children={children}
                />
            )}
            filters={{
                type_download: {
                    nullButton: true
                },
                action: {
                    nullButton: true
                },
                price_name: {
                    nullButton: true
                },
                upload_period: {
                    nullButton: true
                },
            }}
        />
        {dialog && <Dialog
            open={dialog}
            priceToDownload={priceToDownload}
            setPriceToDownload={setPriceToDownload}
            downLoadTypesNodes={downLoadTypesNodes}
            onClose={() => {
                setDialog(false)
            }}
            priceTypes={priceTypes}
            account={account}
            getPrices={getPrices}
        />}
    </Grid>
}
