import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik, getIn} from "formik"
import {CheckboxWithLabel, TextField} from "formik-material-ui"
import * as Yup from "yup"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    MenuItem,
    TextField as MuiTextField,
} from "@material-ui/core"
import {Autocomplete} from "@material-ui/lab"
import {Close as CloseIcon} from "@material-ui/icons"

import {Submit} from "../../../../App/components/Button/Submit"
import {SystemActions} from "../../../../App/actions/system"
import {TextField as CustomTextField} from "../../../../App/components/Inputs/TextField"
import {CompanyActions} from "../../../../Company/actions/company"
import {PriceActions} from "../../../actions/Control/price"
import {localState} from "../../../../App/components/LocalState";

const useStyles = makeStyles(theme => ({
    item: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "cursor": "pointer"
    },
    fullWidth: {
        "width": "100%"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    content: {
        "padding": "0 !important"
    },
    input: {
        "width": "100%",
    },
    column: {
        "height": "86px"
    },
    section: {
        "border-right": "2px solid #c1c1c1",
        "padding": "20px 30px",
        "overflow-y": "auto",
        "max-height": "calc(80vh - 63px)"
    },
    additionally: {
        "max-height": "calc(80vh - 63px)"
    },
    dialogContent: {
        "max-height": "calc(100vh - 210px)"
    },
    footer: {
        "background-color": "#e7eaee",
        "justify-content": "flex-start"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    dialog: {
        "max-height": "70vh"
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    searchLabel: {
        "margin-bottom": "15px",
    },
    footerContent: {
        "padding": "10px 0px"
    },
    eclipsis: {
        "overflow": "hidden",
        "text-overflow": "ellipsis",
        "white-space": "nowrap"
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    titleDialog: {
        "& h2": {
            "display": "flex",
            "justify-content": "space-between",
        },
    }
}))

export const ItemForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { categories, items, regions } = useSelector(state => ({...state.system, ...{items: state.system.items.data}}))
    const { item, group } = props
    const [open, setOpen] = useState(props.open)
    const [suppliers, setSuppliers] = useState(localState)
    const [customers, setCustomers] = useState(localState)
    const [category, setCategory] = useState(null)
    const [standard, setStandard] = useState('')
    const [loading, setLoading] = useState(false)
    const [searchRegions, setSearchRegions] = useState([])
    const [searchCustomer, setSearchCustomer] = useState('')
    const [searchSupplier, setSearchSupplier] = useState('')

    useEffect(() => {
        const getData = async () => {
            if (!categories.length) {
                await dispatch(SystemActions.categories())
            }
        }

        getData().then(r => {})
        // eslint-disable-next-line
    }, [categories.length]);

    useEffect(() => {
        const getData = async () => {
            if (!regions.length) {
                await dispatch(SystemActions.regions())
            }
        }

        getData().then(r => {})
        // eslint-disable-next-line
    }, [regions.length]);

    useEffect(() => {
        const getItems = async () => {
            return await dispatch(SystemActions.items({
                active: true,
                ...{search: standard, limit: 20},
                ...(category ? {category: category} : {})
            }))
        }

        if (category || standard.length) {
            getItems().then(() => {})
        } else {
            dispatch({type: 'SYSTEM_ITEMS_CLEAR'})
        }
    }, [dispatch, standard, category])

    useEffect(() => {
        const getCustomers = async () => {
            return await dispatch(CompanyActions.customers({
                limit: 10,
                search: searchCustomer,
                ...(searchRegions.length ? {region: searchRegions} : {})
            }))
        }

        if (searchCustomer.length) {
            getCustomers().then(response => setCustomers(response))
        }
    }, [dispatch, searchRegions, searchCustomer])

    useEffect(() => {
        const getSuppliers = async () => {
            return await dispatch(CompanyActions.suppliers({
                limit: 10,
                search: searchSupplier
            }))
        }

        if (searchSupplier.length) {
            getSuppliers().then(response => setSuppliers(response))
        }
    }, [dispatch, searchSupplier])

    useEffect(() => {
        setOpen(props.open)
        setLoading(true)
    }, [dispatch, props.open]);

    const handleClose = () => {
        setOpen(false)
        props.setDialog(false)
    }

    const assembly = (categories, parent = 0, level = 0) => {
        let result = []

        if (categories.hasOwnProperty(parent)) {
            categories[parent].forEach(category => {
                result.push(<MenuItem style={{ paddingLeft: `${16 * (level + 1)}px`}} key={ category.id } value={ category.id }>{ category.name }</MenuItem>)

                result = result.concat(assembly(categories, category.id, level + 1))
            })
        }

        return result
    }

    const getCategoriesTree = categories => {
        let tmp = {}
        categories.forEach(category => {
            if (!tmp.hasOwnProperty((category.category !== null) ? category.category.id : 0)) {
                tmp[(category.category !== null) ? category.category.id : 0] = []
            }

            tmp[(category.category !== null) ? category.category.id : 0].push(category)
        })

        return assembly(tmp)
    }

    return loading ? (
        <Grid item>
            <Dialog
                open={!!open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle className={classes.titleDialog}>
                    {'Контроль цены'}
                    <CloseIcon className={classes.close} onClick={handleClose}/>
                </DialogTitle>
                <Formik
                    initialValues={{
                        category: {
                            id: item ? (item.category.id ?? '') : '',
                        },
                        standard: {
                            id: item ? (item.standard.id ?? '') : '',
                            name: item ? (item.name ?? '') : ''
                        },
                        regions: item ? item.regions : [],
                        customers: item ? item.customers : [],
                        territories: item ? item.territories : [],
                        suppliers: item ? item.suppliers : [],
                        price: {
                            from: item ? (item.price.from ?? '') : '',
                            to: item ? (item.price.to ?? '') : '',
                        },
                        notify: item ? item.notify : false
                    }}
                    validationSchema={Yup.object().shape({
                        category: Yup.object().shape({
                            id: Yup.number().nullable().required("Поле не может быть пустым или состоять из одних пробелов!")
                        }),
                        standard: Yup.object().shape({
                            id: Yup.number().nullable(),
                            name: Yup.string().nullable()
                        }),
                        regions: Yup.array().of(Yup.object().shape({
                            id: Yup.number().nullable()
                        })),
                        customers: Yup.array().of(Yup.object().shape({
                            id: Yup.number().nullable()
                        })),
                        territories: Yup.array().of(Yup.object().shape({
                            id: Yup.number().nullable()
                        })),
                        suppliers: Yup.array().of(Yup.object().shape({
                            id: Yup.number().nullable()
                        })),
                        price: Yup.object().shape({
                            from: Yup.number().nullable(),
                            to: Yup.number().nullable()
                        }),
                        notify: Yup.boolean()
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        return dispatch(item ? PriceActions.edit(item.id, values) : PriceActions.add({...values, ...{group: group}})).then(
                            () => {
                                handleClose()
                                setSubmitting(false);
                            },
                            errors => {
                                if (errors) {
                                }
                                setSubmitting(false);
                            }
                        )
                    }}
                >
                    {({
                          dirty,
                          values,
                          errors,
                          touched,
                          validateField,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setErrors,
                          setValues,
                          setFieldValue,
                          setFieldError,
                          setFieldTouched,
                          isSubmitting,
                          isValid,
                          submitForm
                      }) => (
                        <Form>
                            <DialogContent className={classes.dialogContent}>
                                <Grid container className={classes.fullWidth} direction="row" justify="center" alignItems="stretch">
                                    <Grid item className={classes.fullWidth}>
                                        <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
                                            <Grid item>
                                                <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                    <Grid item xs={6} className={classes.column}>
                                                        <Field
                                                            fullWidth
                                                            type="text"
                                                            name="category.id"
                                                            label="Категория"
                                                            select
                                                            variant="standard"
                                                            component={TextField}
                                                            required={true}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            InputProps={{
                                                                onChange: (e) => {
                                                                    const {value} = e.target

                                                                    setCategory(value)
                                                                    setValues({
                                                                        ...values,
                                                                        ...{
                                                                            category: {
                                                                                id: value
                                                                            },
                                                                            standard: {
                                                                                id: '',
                                                                                name: ''
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            {
                                                                getCategoriesTree(categories)
                                                            }
                                                        </Field>
                                                    </Grid>
                                                    <Grid item xs={6} className={classes.column}>
                                                        <Autocomplete
                                                            name="standard"
                                                            options={items}
                                                            value={values.standard}
                                                            getOptionLabel={item => item ? ((item instanceof Object) ? item.name : item) : ''}
                                                            noOptionsText='Эталон'
                                                            onChange={(e, item) => {
                                                                setStandard('')
                                                                if (item) {
                                                                    setCategory(item.category.id)
                                                                }
                                                                setValues({
                                                                    ...values,
                                                                    ...{
                                                                        ...(item ? {category: {
                                                                            id: item.category.id
                                                                        }} : {}),
                                                                        standard: {
                                                                            id: item ? item.id : '',
                                                                            name: item ? item.name : ''
                                                                        }
                                                                    }
                                                                })
                                                            }}
                                                            renderInput={params => {
                                                                const error = getIn(touched, `standard`) && getIn(errors, `standard`);

                                                                return <Field
                                                                    fullWidth
                                                                    component = {MuiTextField}
                                                                    {...params}
                                                                    onChange={(e) => {
                                                                        setStandard(e.target.value)
                                                                    }}
                                                                    error={!!error}
                                                                    helperText={error}
                                                                    name={`standard`}
                                                                    label="Эталон"
                                                                />
                                                            }}
                                                            onFocus ={(e, value) => {
                                                                dispatch({type: 'SYSTEM_ITEMS_CLEAR'})
                                                                setStandard('')
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                    <Grid item xs={4} className={classes.column} style={{"height": "100%"}}>
                                                        <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                            <Grid item className={classes.fullWidth}>
                                                                <Autocomplete
                                                                    name="regions"
                                                                    multiple
                                                                    options={regions}
                                                                    value={values.regions}
                                                                    getOptionLabel={option => {
                                                                        return option ? (option.hasOwnProperty('name') ? option.name : ''): ''
                                                                    }}
                                                                    noOptionsText={'Регион'}
                                                                    onChange={(e, items) => {
                                                                        setSearchRegions(items.length ? items.map(item => item.id) : [])
                                                                        setValues({
                                                                            ...values,
                                                                            ...{
                                                                                regions: items ? items : [],
                                                                                customers: [],
                                                                                territories: []
                                                                            }
                                                                        })
                                                                    }}
                                                                    renderInput={params => {
                                                                        const error = getIn(touched, "regions") && getIn(errors, "regions");

                                                                        return <Field
                                                                            fullWidth
                                                                            component={MuiTextField}
                                                                            {...params}
                                                                            error={!!error}
                                                                            helperText={error}
                                                                            name="regions"
                                                                            label={'Регион'}
                                                                        />
                                                                    }}
                                                                    onFocus={(e, value) => {
                                                                        setFieldTouched("regions", true, true)
                                                                    }}
                                                                    onBlur={(e, value) => {
                                                                        setFieldTouched("regions", true, true)
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4} className={classes.column} style={{"height": "100%"}}>
                                                        <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                            <Grid item className={classes.fullWidth}>
                                                                <Autocomplete
                                                                    filterOptions={(options, state) => options}
                                                                    name="customers"
                                                                    multiple
                                                                    options={customers}
                                                                    value={values.customers}
                                                                    getOptionLabel={option => {
                                                                        return option ? (option.hasOwnProperty('legal_detail') ? option.legal_detail.name : '') : ''
                                                                    }}
                                                                    noOptionsText={'Заказчик'}
                                                                    onChange={(e, items) => {
                                                                        setSearchCustomer('')
                                                                        setValues({
                                                                            ...values,
                                                                            ...{
                                                                                customers: items.length ? items.map(item => ({
                                                                                    id: item.id ,
                                                                                    legal_detail: {
                                                                                        name: item.legal_detail.name
                                                                                    },
                                                                                    territories: item.territories
                                                                                })) : [],
                                                                                territories: []
                                                                            }
                                                                        })
                                                                    }}
                                                                    renderInput={params => {
                                                                        const error = getIn(touched, "customers") && getIn(errors, "customers");

                                                                        return <Field
                                                                            fullWidth
                                                                            component={MuiTextField}
                                                                            {...params}
                                                                            onChange={(e) => {
                                                                                setSearchCustomer(e.target.value)
                                                                            }}
                                                                            error={!!error}
                                                                            helperText={error}
                                                                            name="customers"
                                                                            label={'Заказчик'}
                                                                        />
                                                                    }}
                                                                    onFocus={(e, value) => {
                                                                        setFieldTouched("customers", true, true)
                                                                        dispatch({type: 'CUSTOMERS_CLEAR'})
                                                                        setSearchCustomer('')
                                                                    }}
                                                                    onBlur={(e, value) => {
                                                                        setFieldTouched("customers", true, true)
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4} className={classes.column} style={{"height": "100%"}}>
                                                        <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                            <Grid item className={classes.fullWidth}>
                                                                <Autocomplete
                                                                    filterOptions={(options, state) => options}
                                                                    name="territories"
                                                                    multiple
                                                                    options={values.customers.map(customer => customer.territories).flat()}
                                                                    value={values.territories}
                                                                    getOptionLabel={option => {
                                                                        return option ? (option.hasOwnProperty('name') ? option.name : '') : ''
                                                                    }}
                                                                    disabled={!values.customers.length}
                                                                    noOptionsText={'Территория'}
                                                                    onChange={(e, items) => {
                                                                        setFieldValue("territories", items ? items : [])
                                                                    }}
                                                                    renderInput={params => {
                                                                        const error = getIn(touched, "territories") && getIn(errors, "territories");

                                                                        return <Field
                                                                            fullWidth
                                                                            component={MuiTextField}
                                                                            {...params}
                                                                            error={!!error}
                                                                            helperText={error}
                                                                            name="territories"
                                                                            label={'Территория'}
                                                                        />
                                                                    }}
                                                                    onFocus={(e, value) => {
                                                                        setFieldTouched("territories", true, true)
                                                                    }}
                                                                    onBlur={(e, value) => {
                                                                        setFieldTouched("territories", true, true)
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                    <Grid item xs={6} className={classes.column}>
                                                        <Autocomplete
                                                            filterOptions={(options, state) => options}
                                                            name="suppliers"
                                                            multiple
                                                            options={suppliers}
                                                            value={values.suppliers}
                                                            getOptionLabel={option => {
                                                                return option ? (option.hasOwnProperty('legal_detail') ? option.legal_detail.name : '') : ''
                                                            }}
                                                            noOptionsText={'Поставщик'}
                                                            onChange={(e, items) => {
                                                                setSearchSupplier('')
                                                                setFieldValue("suppliers", items.length ? items.map(item => ({
                                                                    id: item.id,
                                                                    legal_detail: {
                                                                        name: item.legal_detail.name
                                                                    }
                                                                })) : [])
                                                            }}
                                                            renderInput={params => {
                                                                const error = getIn(touched, "suppliers") && getIn(errors, "suppliers");

                                                                return <Field
                                                                    fullWidth
                                                                    component={MuiTextField}
                                                                    {...params}
                                                                    onChange={(e) => {
                                                                        setSearchSupplier(e.target.value)
                                                                    }}
                                                                    error={!!error}
                                                                    helperText={error}
                                                                    name="suppliers"
                                                                    label={'Поставщик'}
                                                                />
                                                            }}
                                                            onFocus={(e, value) => {
                                                                setFieldTouched("suppliers", true, true)
                                                                dispatch({type: 'SUPPLIERS_CLEAR'})
                                                                setSearchSupplier('')
                                                            }}
                                                            onBlur={(e, value) => {
                                                                setFieldTouched("suppliers", true, true)
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                    <Grid item xs={3} className={classes.column}>
                                                        <Field
                                                            fullWidth
                                                            name="price.from"
                                                            type="number"
                                                            label="Цена от"
                                                            inputProps={{
                                                                min: 0,
                                                                step: 0.01
                                                            }}
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} className={classes.column}>
                                                        <Field
                                                            fullWidth
                                                            name="price.to"
                                                            type="number"
                                                            label="до"
                                                            inputProps={{
                                                                min: 0,
                                                                step: 0.01
                                                            }}
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} className={classes.column}>
                                                        <Field
                                                            component={CheckboxWithLabel}
                                                            name="notify"
                                                            color="primary"
                                                            Label={{label: 'Уведомлять о новых'}}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions className={classes.footer}>
                                <Grid container className={ classes.footerContent} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Submit
                                            variant="contained"
                                            component="label"
                                            color="primary"
                                            disabled={isSubmitting || !isValid || !dirty}
                                            onClick={handleSubmit}
                                            type="submit"
                                        >
                                            {item ? 'Сохранить' : 'Создать'}
                                        </Submit>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </Grid>
    ) : null
}
