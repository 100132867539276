import React, {useState} from 'react'

import {
    Checkbox,
    Grid,
    IconButton,
    makeStyles,
    Popover,
    Table as TableMUI,
    TableBody,
    TableCell,
    TableContainer,
    TableRow as TableRowMUI,
    Tooltip,
    Typography
} from '@material-ui/core'
import {Settings} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    popover: {
        'width': '300px'
    },
    filter: {
        'width': '100%'
    },
    filterItem: {
        'white-space': 'normal !important'
    },
    table: {
        'height': '100%'
    },
    item: {
        'height': '100%'
    },
    filterContent: {
        width: '100%',
        padding: theme.spacing(2)
    }
}))

export const ColumnChooser = (props) => {
    const classes = useStyles()

    const {columns, hiddenColumnNames, setHiddenColumnNames} = props
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const columnsWithoutButtons = columns.filter(column => column.title !== ' ');

    return (
        <React.Fragment>
            <Tooltip title='Настройки столбцов'>
                <IconButton
                    size="small"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget)
                    }}
                >
                    <Settings />
                </IconButton>
            </Tooltip>
            {open ? <Popover
                classes={{
                    paper: classes.popover
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                    setAnchorEl(null)
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                    <Grid item className={classes.filterContent}>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item className={classes.fullWidth}>
                                <TableContainer>
                                    <TableMUI stickyHeader>
                                        <TableBody>
                                            {columnsWithoutButtons.map((column, index) => {
                                                const isItemSelected = (hiddenColumnNames.indexOf(column.name) === -1)

                                                return (
                                                    <TableRowMUI
                                                        key={index}
                                                        onClick={() => {
                                                            const selectedIndex = hiddenColumnNames.indexOf(column.name)

                                                            let select = []

                                                            if (selectedIndex === -1) {
                                                                select = select.concat(hiddenColumnNames, column.name)
                                                            } else if (selectedIndex === 0) {
                                                                select = select.concat(hiddenColumnNames.slice(1))
                                                            } else if (selectedIndex === hiddenColumnNames.length - 1) {
                                                                select = select.concat(hiddenColumnNames.slice(0, -1))
                                                            } else if (selectedIndex > 0) {
                                                                select = select.concat(
                                                                    hiddenColumnNames.slice(0, selectedIndex),
                                                                    hiddenColumnNames.slice(selectedIndex + 1),
                                                                )
                                                            }

                                                            setHiddenColumnNames(select)
                                                        }}
                                                        role="checkbox"
                                                        selected={isItemSelected}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox checked={isItemSelected}/>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography variant="body2" noWrap className={classes.filterItem}>{column.title}</Typography>
                                                        </TableCell>
                                                    </TableRowMUI>
                                                )
                                            })}
                                        </TableBody>
                                    </TableMUI>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Popover> : null}
        </React.Fragment>
    )
}
