import {CompanyService} from "../services/company"
import {getFullName} from "../../App/helpers/user";

export const CompanyActions = {
    company,
    create,
    editLegalDetail,
    editContactInformation,
    active,
    certified,
    information,
    companies,
    occupations,
    legalDetail,
    contactInformation,
    termsParticipation,
    addFile,
    deleteFile,
    approve,
    block,
    unBlock,
    approveByAccount,
    addInstitutionsBudgets,
    editInstitutionsBudgets,
    deleteInstitutionsBudgets,
    getInstitutionsBudgets,
    okved,
    getSubscriptions,
    createSubscription,
    editSubscription,
    deleteSubscription
}

function company(params= {}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})
        dispatch({type: "COMPANY_REQUEST"})

        CompanyService.company(params)
            .then(
                company => {
                    dispatch({type: "COMPANY", payload: company})
                    dispatch({type: "FILLING", payload: false})
                    resolve(company)
                },
                error => {
                    dispatch({type: "COMPANY_FAILURE"})
                    dispatch({type: "FILLING", payload: false})
                    reject(error)
                }
            )

    })
}

function create(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})
        dispatch({type: "COMPANY_CREATE_REQUEST"})

        CompanyService.create(values)
            .then(
                () => {
                    dispatch({type: "COMPANY_CREATE_SUCCESS"})
                    dispatch({type: "FILLING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "COMPANY_CREATE_FAILURE"})
                    dispatch({type: "FILLING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )

    })
}

function editLegalDetail(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})
        dispatch({type: "COMPANY_EDIT_REQUEST"})

        CompanyService.editLegalDetail(id, values)
            .then(
                company => {
                    dispatch({type: company.type ? "SUPPLIER_EDIT_SUCCESS" : "CUSTOMER_EDIT_SUCCESS", payload: company})
                    dispatch({type: "FILLING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "COMPANY_EDIT_FAILURE"})
                    dispatch({type: "FILLING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )

    })
}

function editContactInformation(id, formData) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})
        dispatch({type: "COMPANY_EDIT_REQUEST"})

        CompanyService.editContactInformation(id, formData)
            .then(
                company => {
                    dispatch({type: company.type ? "SUPPLIER_EDIT_SUCCESS" : "CUSTOMER_EDIT_SUCCESS", payload: company})
                    dispatch({type: "FILLING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "COMPANY_EDIT_FAILURE"})
                    dispatch({type: "FILLING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )

    })
}

function active(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})
        dispatch({type: "COMPANY_EDIT_ACTIVE_REQUEST"})

        CompanyService.active(id, values)
            .then(
                () => {
                    dispatch({type: "COMPANY_EDIT_ACTIVE_SUCCESS"})
                    dispatch({type: "FILLING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "COMPANY_EDIT_ACTIVE_FAILURE"})
                    dispatch({type: "FILLING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )

    })
}

function certified(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})
        dispatch({type: "COMPANY_EDIT_CERTIFIED_REQUEST"})

        CompanyService.certified(id, values)
            .then(
                () => {
                    dispatch({type: "COMPANY_EDIT_CERTIFIED_SUCCESS"})
                    dispatch({type: "FILLING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "COMPANY_EDIT_CERTIFIED_FAILURE"})
                    dispatch({type: "FILLING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )

    })
}

function information(id, params= {}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        CompanyService.information(id, params)
            .then(
                company => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(company)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function companies(params= {}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "COMPANIES_REQUEST"})

        CompanyService.companies(params)
            .then(
                company => {
                    dispatch({type: "COMPANIES", payload: company})
                    resolve(company)
                },
                error => {
                    dispatch({type: "COMPANIES_FAILURE"})
                    reject(error)
                }
            )
    })
}

function occupations(params= {}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "COMPANY_OCCUPATIONS_REQUEST"})

        CompanyService.occupations(params)
            .then(
                occupations => {
                    dispatch({type: "COMPANY_OCCUPATIONS", payload: occupations})
                    resolve()
                },
                error => {
                    dispatch({type: "COMPANY_OCCUPATIONS_FAILURE"})
                    reject(error)
                }
            )
    })
}

function legalDetail(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})
        dispatch({type: "COMPANY_LEGAL_DETAIL_REQUEST"})

        CompanyService.legalDetail(values)
            .then(
                () => {
                    let name = values.name
                    switch (values.type) {
                        case 'individual':
                            name = name ?? `ИП ${getFullName(values.director)}`
                            break;
                        default:
                            name = name ?? getFullName(values.director)
                    }

                    dispatch({type: "COMPANY_LEGAL_DETAIL_NAME", payload: name})
                    dispatch({type: "COMPANY_LEGAL_DETAIL_SUCCESS"})
                    dispatch({type: "FILLING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "COMPANY_LEGAL_DETAIL_FAILURE"})
                    dispatch({type: "FILLING", payload: false})
                    reject(error)
                }
            )

    })
}

function contactInformation(formData) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})
        dispatch({type: "COMPANY_CONTACT_INFORMATION_REQUEST"})

        CompanyService.contactInformation(formData)
            .then(
                () => {
                    dispatch({type: "COMPANY_CONTACT_INFORMATION_SUCCESS"})
                    dispatch({type: "FILLING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "COMPANY_CONTACT_INFORMATION_FAILURE"})
                    dispatch({type: "FILLING", payload: false})
                    reject(error)
                }
            )

    })
}

function termsParticipation(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})
        dispatch({type: "COMPANY_TERMS_PARTICIPATION_REQUEST"})

        CompanyService.termsParticipation(values)
            .then(
                () => {
                    dispatch({type: "COMPANY_TERMS_PARTICIPATION_SUCCESS"})
                    dispatch({type: "FILLING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "COMPANY_TERMS_PARTICIPATION_FAILURE"})
                    dispatch({type: "FILLING", payload: false})
                    reject(error)
                }
            )

    })
}

function addFile(formData) {
    return dispatch => new Promise((resolve, reject) => {
        CompanyService.addFile(formData)
            .then(
                content => {
                    dispatch({type: "COMPANY_ADD_CONTENT_SUCCESS", payload: content})
                    resolve(content)
                },
                error => {
                    reject(error)
                }
            )

    })
}

function deleteFile(id) {
    return dispatch => new Promise((resolve, reject) => {
        CompanyService.deleteFile(id)
            .then(
                response => {
                    dispatch({type: "COMPANY_DELETE_CONTENT_SUCCESS", payload: id})
                    resolve(response)
                },
                error => {
                    reject(error)
                }
            )

    })
}

function approve(id){
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        CompanyService.approve(id)
            .then(
                response => {
                    dispatch({type: "COMPANY_APPROVE_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Поставщик подтвержден',
                            type:'success'
                        }
                    })
                    resolve(response)
                },
                error => {
                    dispatch({type: "COMPANY_APPROVE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )

    })
}

function block(id){
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        CompanyService.block(id)
            .then(
                response => {
                    dispatch({type: "COMPANY_BLOCK_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Поставщик успешно заблокирован',
                            type:'success'
                        }
                    })
                    resolve(response)
                },
                error => {
                    dispatch({type: "COMPANY_BLOCK_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )

    })
}

function unBlock(id){
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        CompanyService.unBlock(id)
            .then(
                response => {
                    dispatch({type: "COMPANY_UNBLOCK_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Поставщик успешно заблокирован',
                            type:'success'
                        }
                    })
                    resolve(response)
                },
                error => {
                    dispatch({type: "COMPANY_UNBLOCK_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )

    })
}

function approveByAccount(values){
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        CompanyService.approveByAccount(values)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )

    })
}

function addInstitutionsBudgets(values,companyId){
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})
        dispatch({type: "COMPANY_EDIT_REQUEST"})

        CompanyService.addInstitutionsBudgets(values,companyId)
            .then(
                response => {
                    dispatch({type: "FILLING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "COMPANY_EDIT_FAILURE"})
                    dispatch({type: "FILLING", payload: false})
                    reject(error)
                }
            )

    })
}

function editInstitutionsBudgets(values,companyId,id){
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})
        dispatch({type: "COMPANY_EDIT_REQUEST"})

        CompanyService.editInstitutionsBudgets(values,companyId,id)
            .then(
                response => {
                    dispatch({type: "FILLING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "COMPANY_EDIT_FAILURE"})
                    dispatch({type: "FILLING", payload: false})
                    reject(error)
                }
            )

    })
}

function deleteInstitutionsBudgets(companyId,id){
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})
        dispatch({type: "COMPANY_EDIT_REQUEST"})

        CompanyService.deleteInstitutionsBudgets(companyId,id)
            .then(
                response => {
                    dispatch({type: "FILLING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "COMPANY_EDIT_FAILURE"})
                    dispatch({type: "FILLING", payload: false})
                    reject(error)
                }
            )

    })
}

function getInstitutionsBudgets(params, companyId){
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})
        dispatch({type: "COMPANY_GET_REQUEST"})

        CompanyService.getInstitutionsBudgets(params, companyId)
            .then(
                response => {
                    dispatch({type: "FILLING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "COMPANY_GET_FAILURE"})
                    dispatch({type: "FILLING", payload: false})
                    reject(error)
                }
            )

    })
}

function okved(params= {}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "COMPANY_OKVED_REQUEST"})

        CompanyService.okved(params)
            .then(
                res => {
                    dispatch({type: "COMPANY_OKVED_SUCCESS", payload: res})
                    dispatch({type: "LOADING", payload: false})
                    resolve(res)
                },
                error => {
                    dispatch({type: "COMPANY_OKVED_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function getSubscriptions(params, companyId) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})

        CompanyService.getSubscriptions(params, companyId)
            .then(
                response => {
                    dispatch({type: "FILLING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "FILLING", payload: false})
                    reject(error)
                }
            )

    })
}

function createSubscription(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})

        CompanyService.createSubscription(values)
            .then(
                response => {
                    dispatch({type: "FILLING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "FILLING", payload: false})
                    reject(error)
                }
            )

    })
}

function editSubscription(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})

        CompanyService.editSubscription(id, values)
            .then(
                response => {
                    dispatch({type: "FILLING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "FILLING", payload: false})
                    reject(error)
                }
            )

    })
}

function deleteSubscription(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})

        CompanyService.deleteSubscription(id)
            .then(
                response => {
                    dispatch({type: "FILLING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "FILLING", payload: false})
                    reject(error)
                }
            )

    })
}
