import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {
    Grid,
    Link,
    makeStyles,
    Typography
} from "@material-ui/core";
import {GetApp as GetAppIcon} from '@material-ui/icons'
import {Table} from "@devexpress/dx-react-grid-material-ui";

import {OperatorActions} from "../../actions/operator"
import {Submit} from "../../../App/components/Button/Submit"
import * as AppStorage from "../../../App/helpers/storage"
import {CustomTable} from "../../../App/components/Table/Table";
import {TableHeader} from "../../../App/components/Table/TableHeader";
import {localState} from "../../../App/components/LocalState";
import {ColumnChooser} from "../../../App/components/Table/ColumnChooser";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    link: {
        "display": "flex",
        "line-height": "31px",
        "color": "#485868",
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 200px)",
        "min-height": "calc(100vh - 200px)"
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    row: {
        "width": "100%",
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    sendOffer: {
        width: 'auto',
    },
    productName: {
        "max-width": "350px",
        "min-width": "350px",
        "margin": "0",
        "margin-block-start": "0",
        "margin-block-end": "0",
    },
}))

const columns = [
    {name: 'lot_id', title: '№'},
    {name: 'products', title: 'Товары'},
    {name: 'count', title: 'Количество'},
    {name: 'average_price', title: 'НМЦД, рублей'},
    {name: 'postponement', title: 'Отсрочка, дней'},
    {name: 'contract_time', title: 'Срок исполнения,  дней'},
    {name: 'download', title: 'Обоснование НМЦД'},
    {name: 'actions', title: 'Действие'},
];

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [lots, setLots] = useState(localState)
    const [tableRef, setTableRef] = useState(null)
    const {filter} = useSelector(state => state.filters['operator_lots'])
    const [page, setPage] = useState(1)
    const [rowsPerPage , setRowsPerPage] = useState(filter.limitRows)

    const [columnOrder, setColumnOrder] = useState([
        'lot_id',
        'products',
        'count',
        'average_price',
        'postponement',
        'contract_time',
        'download',
        'actions',
    ])

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'lot_id', width: 60},
        {columnName: 'products', width: 150},
        {columnName: 'count', width: 90},
        {columnName: 'average_price', width: 150},
        {columnName: 'postponement', width: 150},
        {columnName: 'contract_time', width: 150},
        {columnName: 'download', width: 150},
        {columnName: 'actions', width: 300},
    ])
    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
        }
    }

    const getLots = async () => {
        return await dispatch(OperatorActions.lots({
            limit: rowsPerPage,
            page: page,
            confirmed: false,
            ...getParams()
        }))
    }

    useEffect(() => {
        getLots().then((r) => {
            setLots(r)
            tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
        })
    }, [dispatch, page, rowsPerPage, filter]);

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: "OPERATOR_LOTS_FILTER", payload: {limitRows: newRowsPerPage}})
    }

    return <Grid item className={classes.content}>
        <Typography variant="h4" style={{marginBottom: "10px"}}>Согласование оферт</Typography>
        <Grid container direction="row" justify="flex-end" alignItems="stretch">
            <Grid item style={{margin: '4px'}}>
            <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
            </Grid>
        </Grid>
        <CustomTable
            meta={lots.meta}
            tableName={'operator_lots'}
            rows={lots.data?.map((lot) => ({
                lot_id: lot.id,
                products: lot.items.map(item => (
                     <Typography key={item.name} className={classes.productName}>
                         {item.name}
                     </Typography>
                 )),
                count: lot.items.map(item => (
                    <Typography key={item.id}>
                        {item.amount}
                    </Typography>
                )),
                average_price: lot.average_price ? parseFloat(lot.average_price).toLocaleString('ru-RU', {minimumFractionDigits: 2}) : null,
                download: lot.files.map((file, idx) => (['rationale'].indexOf(file.type?.key) !== -1 &&
                    <Typography
                        gutterBottom
                        key={file.type?.key}
                        style={{marginLeft: idx > 0 ? "20px" : "0", marginTop: 5}}
                    >
                        <Link
                            href={process.env.REACT_APP_HOST_API + `/shop/order/${lot.order.id}/file/${file.id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                            className={classes.link}
                        >
                            Скачать <GetAppIcon viewBox="0 0 24 24" />
                        </Link>
                    </Typography>
                )),
                actions : <React.Fragment>
                    <Submit
                        disableElevation
                        variant="contained"
                        color="primary"
                        type="button"
                        className={classes.sendOffer}
                        onClick={() => {
                            return dispatch(OperatorActions.confirmed(lot.id))
                        }}
                    >
                        Согласовать
                    </Submit>
                    <Submit
                        disableElevation
                        variant="contained"
                        color="primary"
                        type="button"
                        className={classes.sendOffer}
                        style={{marginLeft: "5px"}}
                        onClick={() => {
                            return dispatch(OperatorActions.notConfirmed(lot.id))
                        }}
                    >
                        Не согласовать
                    </Submit>
                </React.Fragment>,
                postponement: lot.postponement,
                contract_time: lot.contract_time,
            }))}
            columns={columns}
            page={{
                page: page,
                setPage: setPage,
                rowsPerPage: rowsPerPage,
                handlePageSizeChange: handlePageSizeChange
            }}
            setTableRef={setTableRef}
            columnsSettings={{
                columnOrder: columnOrder,
                setColumnOrder: setColumnOrder,
                setColumnWidths: setColumnWidths,
                columnWidths: columnWidths,
                hiddenColumnNames: hiddenColumnNames,
                setHiddenColumnNames: setHiddenColumnNames
            }}
            tableHeader={TableHeader}
            filterActionType={"OPERATOR_LOTS_FILTER"}
            classInputContent={classes.inputContent}
            rowComponent={({row, tableRow, children}) => (
                <Table.Row
                    tableRow={tableRow}
                    className={classes.tableRow}
                    hover
                    row={row}
                    children={children}
                    style={{'cursor': 'pointer'}}
                />
            )}
            filters={{
                actions: {
                    nullButton: true
                },
                products: {
                    nullButton: true
                },
                count: {
                    nullButton: true
                },
                download: {
                    nullButton: true
                },
            }}
        />
    </Grid>
}
