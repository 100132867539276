import React, {useState} from "react"
import {useSelector} from "react-redux"

import {
    Grid,
    makeStyles
} from "@material-ui/core"

import {item as rules} from "../../../helpers/permissions/rules"
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon";
import {FileCopy as FileCopyIcon} from "@material-ui/icons";
import {Dialog} from "./Copy/Dialog";
import {getMonthName} from "../../../../App/helpers/date";

const useStyles = makeStyles(() => ({
    button: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    }
}))

export const Copy = (props) => {
    const classes = useStyles()

    const [dialog, setDialog] = useState(false)

    const { items, onClick } = props

    const {account} = useSelector(state => state.account)

    const isErrorItem = (arr) => {
        return !!arr.find(item => item.price < 0)
    }

    return  (
        <Grid item>
            <ButtonIcon
                disabled={!rules.copy.includes(account.group) || !items.length || isErrorItem(items)}
                onClick={() => setDialog(true)}
                title='Копировать'
                children={<FileCopyIcon/>}
                className={classes.button}
            />
            {dialog &&
                <Dialog
                    open={dialog}
                    onClose={() => {
                        setDialog(false)
                        onClick()
                    }}
                    products={items.map(item => ({
                        id: item.id,
                        name: item.name,
                        purchase_plan_id: item.purchase_plan_id,
                        purchase_id: item.purchase_id,
                        purchase_lot_id: item.purchase_lot_id,
                        purchase_number: item.purchase_number ? `${item.purchase_number}/${item.lot_number}` : '',
                        month: getMonthName(new Date(item.demand_start_date)),
                        year: (new Date(item.demand_start_date)).getFullYear()
                    }))}
                />
            }
        </Grid>
    )
}
