import React, {useState} from "react"

import {
    IconButton, Menu, Fade, MenuItem
} from "@material-ui/core"

import {Clear} from "@material-ui/icons";

export const RemoveButton = (props) => {
    const { item, onSubmit, onSuccess, disabled = false } = props


    const [anchor, setAnchor] = useState({target: null, item: null})

    return (
        <React.Fragment>
            <IconButton
                disabled={disabled}
                size={"small"}
                onClick={(e) => {
                    e.stopPropagation()
                    setAnchor({
                        target: e.currentTarget,
                        item: item
                    })
                }}
            >
                <Clear/>
            </IconButton>
            <Menu
                anchorEl={anchor.target}
                keepMounted
                open={!!anchor.target}
                onClose={(e) => {
                    e.stopPropagation()
                    setAnchor({
                        target: null,
                        item: null
                    })
                }}
                TransitionComponent={Fade}
            >
                <MenuItem
                    onClick={(e) => {
                        e.stopPropagation()
                        setAnchor({
                            target: null,
                            item: null
                        })
                    }}
                >
                    Отмена
                </MenuItem>
                <MenuItem
                    onClick={(e) => {
                        e.stopPropagation()
                        onSubmit(anchor.item.id).then(
                            () => {
                                const item = anchor.item

                                setAnchor({
                                    target: null,
                                    item: null
                                })
                                onSuccess(item)
                            },
                            () => {
                                setAnchor({
                                    target: null,
                                    item: null
                                })
                            }
                        )
                    }}
                >
                    Подтвердить
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}
