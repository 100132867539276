function getIdsWithExpireDate(categories) {
    let categoryIds = []
    let rootCategory = process.env.REACT_APP_CATEGORY_WITH_EXPIRATION_DATE
    categoryIds = rootCategory.split(',')
    let countCategoriesIds = 0

    categoryIds = categoryIds.map(categoryId => Number(categoryId))

    if (rootCategory && categories && categories.length && categories.length > 0){
        while (true) {
            countCategoriesIds = categoryIds.length
            categories.forEach(category => {
                if (category.hasOwnProperty('category') && category.category !== null && categoryIds.indexOf(category.category.id) !== -1 && categoryIds.indexOf(category.id) === -1 ) {
                    categoryIds.push(category.id)
                }
            })
            if (categoryIds.length === countCategoriesIds){
                break
            }
        }
    }

    return categoryIds
}

function isWithExpireDate(id, categories) {
    let categoryIds = getIdsWithExpireDate(categories)
    return categoryIds.indexOf(id) !== -1
}

function getChild(rootCategoryID, categories) {
    let categoryIds = new Set()
    categoryIds.add(Number(rootCategoryID))
    let countCategoriesIds = 0
    if (rootCategoryID && categories && categories.length && categories.length > 0){
        while (true) {
            countCategoriesIds = categoryIds.size
            categories.forEach(category => {
                if (category.hasOwnProperty('category') && category.category !== null && categoryIds.has(category.category.id)) {
                    categoryIds.add(category.id)
                }
            })

            if (categoryIds.size === countCategoriesIds){
                break
            }
        }
    }

    return Array.from(categoryIds)
}

export { getIdsWithExpireDate, isWithExpireDate, getChild }
