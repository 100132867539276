import {HttpService} from "../../../App/services/http"

export const ChatService = {
    create,
    addUserToChat
}

function create(values) {
    return HttpService.post(`/planning/comment/chat`, values)
}

function addUserToChat(chatId) {
    return HttpService.post(`/planning/comment/chat/${chatId}/user`)
}



