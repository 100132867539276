import React from "react"
import {Field, FieldArray} from "formik"
import { TextField } from "formik-material-ui"

import {Card, CardContent, Grid, IconButton, makeStyles, Typography} from "@material-ui/core"
import {Autocomplete, createFilterOptions} from '@material-ui/lab'
import {Add, Clear} from "@material-ui/icons"

const useStyles = makeStyles(() => ({
    fullWidth: {
        'width': '100%'
    },
    card: {
        'width': '100%',
        'border-radius': 'unset'
    },
    label: {
        'color': 'rgba(0, 0, 0, 0.54)',
        'padding': '0',
        'font-size': '0.75rem',
        'font-weight': '400',
        'line-height': '1',
        'letter-spacing': '0.00938em'
    }
}))

export const FieldDosage = (props) => {
    const classes = useStyles()

    const { id, label, items, values, setFieldValue, setFieldTouched, isSubmitting, disabled } = props

    return (
        <Grid item className={classes.fullWidth}>
            <Card className={classes.card}>
                <CardContent>
                    <Grid item className={classes.fullWidth}>
                        <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                    <Grid item sm={9} className={classes.fullWidth}>
                                        <Typography variant="caption" className={classes.label}>{label}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                    <Grid item sm={6} className={classes.fullWidth}>
                                        <Field
                                            fullWidth
                                            name={`attributes.${id}.prefix`}
                                            disabled={ isSubmitting || disabled }
                                            label="Префикс"
                                            component={ TextField }
                                        />
                                    </Grid>
                                </Grid>
                                <FieldArray
                                    name={`attributes.${id}.values`}
                                    render={ arrayOptions => (
                                        <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                            {values[id].values.map((option, key) => (
                                                <Grid item key={key} className={classes.fullWidth}>
                                                    <Grid container direction='column' justify='center' alignItems='flex-start' spacing={2}>
                                                        <Grid item className={classes.fullWidth}>
                                                            <Grid container direction='row' justify='space-between' alignItems='flex-end' spacing={2}>
                                                                <Grid item xs={6} className={classes.fullWidth}>
                                                                    <Field
                                                                        fullWidth
                                                                        name={`attributes.${id}.values[${key}].amount`}
                                                                        type="number"
                                                                        disabled={ isSubmitting || disabled }
                                                                        label="Количество"
                                                                        inputProps={{
                                                                            step: 0.0001,
                                                                            onWheel: e => {
                                                                                e.target.blur()
                                                                                e.stopPropagation()
                                                                                setTimeout(() => {
                                                                                    e.target.focus()
                                                                                }, 0)
                                                                            }
                                                                        }}
                                                                        component={ TextField }
                                                                    />
                                                                </Grid>
                                                                <Grid item sm={4} className={classes.fullWidth}>
                                                                    <Autocomplete
                                                                        filterOptions={createFilterOptions({
                                                                            matchFrom: 'start',
                                                                            stringify: option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')
                                                                        })}
                                                                        options={ items }
                                                                        name={`attributes.${id}.values[${key}].unit`}
                                                                        getOptionLabel={option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')}
                                                                        disabled={ isSubmitting || disabled }
                                                                        defaultValue={() => (values.hasOwnProperty(id) ? { short: values[id].values[key].unit } : { short: '' })}
                                                                        onChange={(e, value) => {
                                                                            setFieldValue(`attributes.${id}.values[${key}].unit`, ((value instanceof Object) ? value.short : value))
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            if (items.find(item => item.short === e.target.value)) {
                                                                                setFieldTouched(`attributes.${id}.values[${key}].unit`, true)
                                                                            } else {
                                                                                setFieldValue(`attributes.${id}.values[${key}].unit`, '')
                                                                            }
                                                                        }}
                                                                        renderInput={params => (
                                                                            <Field
                                                                                fullWidth
                                                                                {...params}
                                                                                name={`attributes.${id}.values[${key}].unit`}
                                                                                type="text"
                                                                                label="Ед. изм."
                                                                                component={ TextField }
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <IconButton
                                                                        onClick={() => arrayOptions.remove(key)}
                                                                        color="primary"
                                                                        aria-label="Удалить"
                                                                        component="span"
                                                                    >
                                                                        <Clear/>
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item className={classes.fullWidth}>
                                                            <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                                <Grid item sm={6} className={classes.fullWidth}>
                                                                    <Field
                                                                        fullWidth
                                                                        name={`attributes.${id}.values[${key}].delimiter`}
                                                                        disabled={ isSubmitting || disabled }
                                                                        label="Разделитель"
                                                                        component={ TextField }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            <Grid item className={classes.fullWidth}>
                                                <IconButton
                                                    onClick={() => arrayOptions.push({value: '', unit: '', delimiter: ''})}
                                                    color={'primary'}
                                                    aria-label="Добавить"
                                                    component="span"
                                                >
                                                    <Add />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    )}
                                />
                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                    <Grid item sm={6} className={classes.fullWidth}>
                                        <Field
                                            fullWidth
                                            name={`attributes.${id}.volume.value`}
                                            type="number"
                                            disabled={ isSubmitting || disabled }
                                            label="Объём/вес"
                                            inputProps={{
                                                step: 0.0001,
                                                onWheel: e => {
                                                    e.target.blur()
                                                    e.stopPropagation()
                                                    setTimeout(() => {
                                                        e.target.focus()
                                                    }, 0)
                                                }
                                            }}
                                            component={ TextField }
                                        />
                                    </Grid>
                                    <Grid item sm={6} className={classes.fullWidth}>
                                        <Autocomplete
                                            filterOptions={createFilterOptions({
                                                matchFrom: 'start',
                                                stringify: option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')
                                            })}
                                            options={ items }
                                            name={`attributes.${id}.volume.unit`}
                                            getOptionLabel={option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')}
                                            disabled={ isSubmitting || disabled }
                                            defaultValue={() => (values.hasOwnProperty(id) ? { short: values[id].volume.unit } : { short: '' })}
                                            onChange={(e, value) => {
                                                setFieldValue(`attributes.${id}.volume.unit`, ((value instanceof Object) ? value.short : value))
                                            }}
                                            onBlur={(e) => {
                                                if (items.find(item => item.short === e.target.value)) {
                                                    setFieldTouched(`attributes.${id}.volume.unit`, true)
                                                } else {
                                                    setFieldValue(`attributes.${id}.volume.unit`, '')
                                                }
                                            }}
                                            renderInput={params => (
                                                <Field
                                                    fullWidth
                                                    {...params}
                                                    name={`attributes.${id}.volume.unit`}
                                                    type="text"
                                                    label="Ед. изм."
                                                    component={ TextField }
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                    <Grid item sm={6} className={classes.fullWidth}>
                                        <Field
                                            fullWidth
                                            name={`attributes.${id}.volume.delimiter`}
                                            disabled={ isSubmitting || disabled }
                                            label="Разделитель"
                                            component={ TextField }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                    <Grid item sm={12} className={classes.fullWidth}>
                                        <Field
                                            fullWidth
                                            name={`attributes.${id}.description`}
                                            disabled={ isSubmitting || disabled }
                                            label="Текст"
                                            component={ TextField }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                    <Grid item sm={12} className={classes.fullWidth}>
                                        <Field
                                            fullWidth
                                            name={`attributes.${id}.initial`}
                                            disabled={ isSubmitting || disabled }
                                            label="Начальное значение"
                                            component={ TextField }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}
