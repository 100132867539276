export default function invoice(
    state = {
        invoices: {
            data: [],
            meta: {
                total: 0,
                per_page: 50,
                current_page: 1
            }
        }
    },
    action
) {
    switch (action.type) {
        case "INVOICES_REQUEST":
            return state
        case "INVOICES_SUCCESS":
            return {
                invoices: action.payload
            }
        case "INVOICES_FAILURE":
            return state
        case "CREATE_INVOICE_REQUEST":
            return state
        case "CREATE_INVOICE_SUCCESS":
            let invoices = state.invoices.data

            if (state.invoices.meta.current_page === 1) {
                invoices.unshift(action.payload)

                if (invoices.length > state.invoices.meta.per_page) {
                    invoices.pop()
                }
            }

            return {
                invoices: {
                    data: invoices,
                    meta: {
                        ...state.invoices.meta,
                        total: state.invoices.meta.total + 1
                    }
                }
            }
        case "CREATE_INVOICE_FAILURE":
            return state
        default:
            return state
    }
}
