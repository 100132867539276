import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import {useDebouncedCallback} from 'use-debounce'
import * as Yup from "yup"
import clsx from "clsx"
import format from "date-fns/format"

import {
    Avatar,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Slide,
    TextField,
    Typography
} from "@material-ui/core"
import {Send, Info as InfoIcon, Close as CloseIcon, Search as SearchIcon, Delete as DeleteIcon, Add as AddIcon} from "@material-ui/icons"

import {MessengerActions} from "../actions/messenger"
import {TextFieldWithError as CustomTextField} from "../../App/components/Inputs/TextFieldWithError"
import {Submit} from "../../App/components/Button/Submit"
import {getUserPosition} from '../../App/helpers/position'
import {getFullName} from "../../App/helpers/user"

const useStyles = makeStyles(theme => ({
    infoButton: {
        "display": "flex",
        "align-items": "flex-end",
    },
    icon: {
        "& .MuiButton-endIcon": {
            "margin-left": "0",
            "margin-right": "0"
        }
    },
    fullWidth: {
        'width': '100%'
    },
    content: {
        'width': '600px',
        'border-left': '2px solid #c1c1c1',
        'min-height': '100vh'
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    slide:{
        'margin': "10px",
        'position': 'absolute',
        'z-index': 1,
        'width': "calc(100% - 345px)",
        'background': 'white',
        'border-bottom': '2px solid #c1c1c1',
    },
    label: {
        'width': "100%",
        'height': '48px',
        'border-bottom': '2px solid #c1c1c1',
    },
    margin: {
        'margin': "0"
    },
    eclipsis: {
        'overflow': 'hidden',
        "text-overflow": "ellipsis",
        "white-space": "nowrap"
    },
    messages: {
        'overflow-y': 'auto',
        'height': 'calc(100vh - 121px)',
        'padding-top': '0',
        'width': '100%'
    },
    message: {
        'width': "100%",
        'height': '67px',
        'border-top': '2px solid #c1c1c1',
    },
    text: {
        'width': "calc(100% - 64px)",
        '& .MuiTextField-root': {
            '& .MuiInput-root': {
                'padding': '6px 0 2px'
            }
        }
    },
    position: {
        'color': 'gray',
    }
}))

export const Chat = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const chatRef = useRef(null)
    const {socket} = props

    const { messages } = useSelector(state => state.messenger)

    const {account} = useSelector(state => state.account)

    const [openSettings, setOpenSettings] = useState(false)
    const [loading, setLoading] = useState(false)
    const [initialValues, setInitialValues] = useState({
        text: ''
    })
    const containerRef = useRef(null);
    const [search, setSearch] = useState('')
    const [users, setUsers] = useState([])
    const debounced = useDebouncedCallback(
        (value) => {
            setLoading(value)
        }, 700
    )

    const [chat, setChat] = useState(props.chat)

    const create = async (values) => {
        return await dispatch(MessengerActions.create(values))
    }

    const send = async (chat, values) => {
        socket.emit('message', {text: values.text, chat: chat.id}, message => {
            dispatch({type: "MESSENGER_ADD_CHAT_MESSAGE", payload: message})

            setInitialValues({
                text: ''
            })

            if (chatRef.current) {
                chatRef.current.scrollTo({
                    behavior: 'smooth',
                    top: chatRef.current.scrollHeight - chatRef.current.clientHeight
                })
            }
        })
    }

    const getMessages = async () => {
        return await dispatch(MessengerActions.messages(chat.id))
    }

    const getUsers = async () => {
        return await dispatch(MessengerActions.users({
            page: 1,
            limit: 20,
            ...(search.length ? {search: search} : {})
        }))
    }

    useEffect(() => {
        if (!loading) {
            if (chat.id) {
                getMessages().then(messages => {
                    socket.emit('view', messages.filter(message => !message.viewed).map(message => message.id))
                    dispatch({type: 'MESSENGER_CHAT_RECENT_CLEAR', payload: chat.id})
                    setLoading(true)
                })
            } else {
                dispatch({type: "MESSENGER_CHAT_MESSAGES", payload: []})
                setLoading(true)
            }
        }
        // eslint-disable-next-line
    }, [loading])

    useEffect(() => {
        if (messages.length) {
            socket.emit('view', [messages[messages.length - 1].id])
        }
        // eslint-disable-next-line
    }, [messages.length])

    useEffect(() => {
        if (!loading && search !== '') {
            getUsers().then(response => {
                setUsers(response.data.filter(function(user){
                    return !chat.users.find(chatUser => chatUser.id === user.id)
                }))
                setLoading(true)
            })
        }
        // eslint-disable-next-line
    }, [loading, search])

    useEffect(() => {
        if (chat.id !== props.chat.id) {
            setOpenSettings(false)
        }
        setChat(props.chat)
        setInitialValues({
            text: ''
        })
        setLoading(false)
        // eslint-disable-next-line
    }, [props.chat])

    return (
        <Grid item className={classes.content}>
            <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                <Grid item className={classes.label}>
                    <Grid container className={clsx(classes.margin, classes.fullWidth)} direction="row" justify="space-between" alignItems="center" spacing={2}>
                        <Grid item sm={1}>
                            <Avatar
                                className={classes.small}
                                alt={chat.name}
                            />
                        </Grid>
                        <Grid item sm={11}>
                            <Grid container className={classes.fullWidth} direction="row" justify="space-between" alignItems="center">
                                <Grid item>
                                    <Typography className={classes.eclipsis}>{chat.name}</Typography>
                                </Grid>
                                {chat.type.id === 3 && <Grid item className={classes.infoButton}>
                                    <Submit
                                        variant="contained"
                                        component="label"
                                        color="primary"
                                        className={classes.icon}
                                        endIcon={openSettings ? <CloseIcon/> : <InfoIcon/>}
                                        onClick={() => {
                                            setOpenSettings(!openSettings)
                                            setSearch('')
                                        }}
                                    />
                                </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Slide direction="down" in={openSettings} container={containerRef.current} className={classes.slide}>
                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="center" spacing={2}>
                            {chat.creator && chat.creator.id === account.id &&
                                <Grid item className={classes.column}>
                                    <TextField
                                        variant="standard"
                                        placeholder={`Поиск`}
                                        fullWidth
                                        margin={'normal'}
                                        value={search}
                                        onChange={(event) => {
                                            setSearch(event.target.value)
                                            debounced.callback(false)
                                        }}
                                        classes={{
                                            root: classes.filterLabel
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchIcon  style={{color: '#c1c1c1'}}/>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            }
                            {(search !== '' ? users.concat(chat.users) : chat.users).map(function(user){
                                return user.id !== account.id && <Grid item sm={12}>
                                    <Grid container direction="row" justify="space-between" alignItems="center">
                                        <Grid item>
                                            <Typography>{getFullName(user)}</Typography>
                                            <Typography className={classes.position}>{getUserPosition(user)}</Typography>
                                        </Grid>
                                        {chat.creator && chat.creator.id === account.id &&
                                            <Grid item>
                                                {chat.users.find(chatUser => chatUser.id === user.id) ?
                                                    <IconButton
                                                        type="submit"
                                                        onClick={() => {
                                                            dispatch(MessengerActions.deleteUser(chat.id,user.id)).then(
                                                                chat => {
                                                                    dispatch({type: "MESSENGER_DELETE_CHAT_USER", payload: chat})
                                                                }
                                                            )
                                                        }}
                                                        >
                                                        <DeleteIcon color="error" />
                                                    </IconButton> :
                                                    <IconButton
                                                        type="submit"
                                                        onClick={() => {
                                                            dispatch(MessengerActions.addUser(chat.id,user.id)).then(
                                                                chat => {
                                                                    dispatch({type: "MESSENGER_ADD_CHAT_USER", payload: chat})
                                                                }
                                                            )
                                                        }}
                                                        >
                                                        <AddIcon color="primary" />
                                                    </IconButton>
                                                }
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            })}
                        </Grid>
                    </Slide>
                </Grid>
                <Grid ref={chatRef} item className={classes.messages}>
                    <List>
                        {function () {
                            let date = null

                            return messages.map((message) => {
                                return <ListItem key={message.id}>
                                    <Grid container>
                                        {function () {
                                            if (date !== format(new Date(message.created), 'dd.MM.yyyy')) {
                                                date = format(new Date(message.created), 'dd.MM.yyyy')
                                                return <Grid item xs={12}>
                                                    <ListItemText align="center" secondary={date}/>
                                                </Grid>
                                            }
                                        }()}
                                        <Grid item xs={12}>
                                            <ListItemText className={classes.margin} align={(account.id === message.user.id) ? 'right' : 'left'} primary={message.text}/>
                                        </Grid>
                                        {(message.user?.company?.legal_detail?.name && !(account.id === message.user.id)) ? <Grid item xs={12}>
                                            <ListItemText className={classes.margin} align={(account.id === message.user.id) ? 'right' : 'left'} primary={message.user.company.legal_detail.name}/>
                                        </Grid> : null}
                                        <Grid item xs={12}>
                                            <ListItemText className={classes.margin} align={(account.id === message.user.id) ? 'right' : 'left'} secondary={`${((chat.type.key !== 'direct') && (account.id !== message.user.id)) ? `${getUserPosition(message.user)} ${getFullName(message.user)}, ` :  ''}${format(new Date(message.created), 'HH:mm')}`}/>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            })
                        }()}
                    </List>
                </Grid>
                <Grid item className={classes.message}>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={Yup.object().shape({
                            text: Yup.string()
                        })}
                        onSubmit={async (values, {setSubmitting}) => {
                            if (!chat.id) {
                                create({users: chat.users.map(user => user.id), type: 'direct'}).then(chat => {
                                    setChat(chat)
                                    dispatch({type: "MESSENGER_ADD_CHAT", payload: chat})
                                    dispatch({type: "MESSENGER_CHAT", payload: chat})
                                    socket.emit('chat', {...chat, ...{name: chat.users.find(user => (user.id === account.id)).login}}, () => {
                                        send(chat, values).then(_ => {
                                            setSubmitting(false)
                                        })
                                    })
                                })
                            } else {
                                send(chat, values).then(_ => {
                                    setSubmitting(false)
                                })
                            }
                        }}
                    >
                        {({
                              values,
                              isSubmitting,
                              setFieldValue
                          }) => (
                            <Form>
                                <Grid container className={clsx(classes.margin, classes.fullWidth)} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                    <Grid item className={classes.text}>
                                        <Field
                                            fullWidth
                                            name="text"
                                            type="string"
                                            rows={2}
                                            component={CustomTextField}
                                            inputProps={{
                                                onChange: (e) => {
                                                    const value = e.target.value
                                                    setFieldValue('text', value)
                                                    setInitialValues({text: value})
                                                },
                                                onClick: () => {
                                                    setOpenSettings(false)
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            color="primary"
                                            disabled={!socket.connected || isSubmitting || !values.text}
                                            type="submit"
                                        >
                                            <Send />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Grid>
    )
}
