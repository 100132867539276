import {SystemService} from "../services/system"
import {ReportService} from "../../Report/services/report";

export const SystemActions = {
    cities,
    currencies,
    regions,
    banks,
    categories,
    categoriesTree,
    units,
    status,
    items,
    generics,
    getRFCBRateCoefficient,
    customers,
    file
}

function cities(params= {}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SYSTEM_CITIES_REQUEST"})

        SystemService.cities(params)
            .then(
                cities => {
                    dispatch({type: "SYSTEM_CITIES_SUCCESS", payload: cities})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "SYSTEM_CITIES_FAILURE"})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    dispatch({type: "LOADING", payload: false})
                    reject()
                }
            )
    })
}

function currencies(params= {}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SYSTEM_CURRENCIES_REQUEST"})

        SystemService.currencies(params)
            .then(
                currencies => {
                    dispatch({type: "SYSTEM_CURRENCIES_SUCCESS", payload: currencies})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "SYSTEM_CURRENCIES_FAILURE"})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    dispatch({type: "LOADING", payload: false})
                    reject()
                }
            )
    })
}

function regions(params= {}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SYSTEM_REGIONS_REQUEST"})

        SystemService.regions(params)
            .then(
                regions => {
                    dispatch({type: "SYSTEM_REGIONS_SUCCESS", payload: regions})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "SYSTEM_REGIONS_FAILURE"})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    dispatch({type: "LOADING", payload: false})
                    reject()
                }
            )
    })
}

function banks(params= {}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SYSTEM_BANKS_REQUEST"})

        SystemService.banks(params)
            .then(
                banks => {
                    dispatch({type: "SYSTEM_BANKS_SUCCESS", payload: banks})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "SYSTEM_BANKS_FAILURE"})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    dispatch({type: "LOADING", payload: false})
                    reject()
                }
            )
    })
}

function categories(params= {}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SYSTEM_CATEGORIES_REQUEST"})

        SystemService.categories(params)
            .then(
                categories => {
                    dispatch({type: "SYSTEM_CATEGORIES_SUCCESS", payload: categories})
                    dispatch({type: "LOADING", payload: false})
                    resolve(categories)
                },
                error => {
                    dispatch({type: "SYSTEM_CATEGORIES_FAILURE"})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    dispatch({type: "LOADING", payload: false})
                    reject()
                }
            )
    })
}

function categoriesTree(params= {}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SYSTEM_CATEGORIES_TREE_REQUEST"})

        SystemService.categoriesTree(params)
            .then(
                categories => {
                    dispatch({type: "SYSTEM_CATEGORIES_TREE_SUCCESS", payload: categories})
                    dispatch({type: "LOADING", payload: false})
                    resolve(categories)
                },
                error => {
                    dispatch({type: "SYSTEM_CATEGORIES_TREE_FAILURE"})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    dispatch({type: "LOADING", payload: false})
                    reject()
                }
            )
    })
}

function units () {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'SYSTEM_UNITS_REQUEST' })

        SystemService.units()
            .then(
                units => {
                    dispatch({ type: 'SYSTEM_UNITS_SUCCESS', payload: units })
                    resolve()
                },
                error => {
                    dispatch({ type: 'SYSTEM_UNITS_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function status(params= {}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SYSTEM_ORDER_STATUS_REQUEST"})

        SystemService.status(params)
            .then(
                status => {
                    dispatch({type: "SYSTEM_ORDER_STATUS_SUCCESS", payload: status})
                    dispatch({type: "LOADING", payload: false})
                    resolve(status)
                },
                error => {
                    dispatch({type: "SYSTEM_ORDER_STATUS_FAILURE"})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    dispatch({type: "LOADING", payload: false})
                    reject()
                }
            )
    })
}

function items(params= {}, state = true) {
    return dispatch => new Promise((resolve, reject) => {
        if (state) {
            dispatch({type: "SYSTEM_ITEMS_REQUEST"})
        }

        SystemService.items(params)
            .then(
                response => {
                    if (state) {
                        dispatch({type: "SYSTEM_ITEMS_SUCCESS", payload: {data: response.data, meta: response.meta, category: params.hasOwnProperty('category') ? params.category : null}})
                    }
                    resolve(response)
                },
                error => {
                    if (state) {
                        dispatch({type: "SYSTEM_ITEMS_FAILURE"})
                        dispatch({type: "ALERT_ERROR", payload: {
                                message: error.message,
                                type:'error'
                            }})
                    }
                    reject()
                }
            )
    })
}


function generics(params= {}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SYSTEM_GENERICS_REQUEST"})

        SystemService.generics(params)
            .then(
                generics => {
                    dispatch({type: "SYSTEM_GENERICS_SUCCESS", payload: generics})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "SYSTEM_GENERICS_FAILURE"})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    dispatch({type: "LOADING", payload: false})
                    reject()
                }
            )
    })
}

function getRFCBRateCoefficient() {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "SYSTEM_RF_CB_RATE_COEFFICIENT_REQUEST"})

        SystemService.getRFCBRateCoefficient()
            .then(
                rf_cb_rate_coefficients => {
                    dispatch({type: "SYSTEM_RF_CB_RATE_COEFFICIENT_SUCCESS", payload: rf_cb_rate_coefficients})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "SYSTEM_GENERICS_FAILURE"})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    dispatch({type: "LOADING", payload: false})
                    reject()
                }
            )
    })
}

function customers(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        SystemService.customers(params)
            .then(
                companies => {
                    dispatch({type: "REPORT_COMPANIES", payload: {data: companies.data, meta: companies.meta}})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    dispatch({type: "LOADING", payload: false})
                    reject()
                }
            )
    })
}

function file(path) {
  return dispatch => new Promise((resolve, reject) => {
    SystemService.file(path)
      .then(
        data => {
          resolve(data)
        },
        error => {
          reject(error)
        }
      )
  })
}
