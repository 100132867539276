import React from "react"
import {TagBox as DevTagBox} from "devextreme-react"
import ArrayStore from "devextreme/data/array_store"

import {makeStyles} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    filterSelect: {
        "width": "100% !important",
        "border": "2px solid #c1c1c1 !important",
        "padding": "0 15px 0 15px !important",
        "background": "#fff !important",
        "margin-top": "6px !important",
        "transition": "border-color .2s ease-in !important",
        "border-radius": "2px !important",
        "&:hover": {
            "border-color": "#898989 !important",
        },
        "margin-bottom": "14px !important",
        "height": "36px"
    },
}))

export const TagBox = (props) => {
    const {data, placeholder, onValueChanged} = props
    const classes = useStyles()

    const formulationData = data !== null ? data.map((item, idx) => {return {id: idx, name: item}}) : [];

    let dataSource = new ArrayStore({
        data: formulationData,
        key: 'id',
    })

    const Item = function Item(item) {
        return (
            <div className="custom-item">
                <div className="product-name">{item.name}</div>
            </div>
        );
    }

    return (
        <DevTagBox
            className={classes.filterSelect}
            dataSource={dataSource}
            itemRender={Item}
            displayExpr="name"
            valueExpr="name"
            showSelectionControls={true}
            placeholder={placeholder}
            multiline={false}
            searchEnabled={true}
            noDataText={'Ничего не найдено'}
            selectAllText={'Выбрать все'}
            onValueChanged={onValueChanged}
        />
    )
}
