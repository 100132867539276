import React from "react";
import {fieldToTextField} from "formik-material-ui"

import {TextField} from "@material-ui/core"
import {Autocomplete as MuiAutocomplete} from "@material-ui/lab"

const getProperty = (obj, prop) => {
    return prop.split('.')
        .reduce((m, i) => {
            return m && typeof m == 'object' ? m[i] : null;
        }, obj);
}

export const Autocomplete = ({ textFieldProps, ...props }) => {
    const { form: { errors, touched, setTouched, setFieldValue } } = props;
    const { label, helperText, ...field } = fieldToTextField(props);
    const { name } = field;

    let error = undefined

    if (touched[name]) {
        error = getProperty(errors, name)
    }

    return (
        <MuiAutocomplete
            {...props}
            {...field}
            error={error}
            onChange={(_, value) => setFieldValue(name, value)}
            onBlur={() => setTouched({ [name]: true })}
            renderInput={ params => (
                <TextField label={label} {...params} {...textFieldProps} helperText={error} error={!!error} />
            )}
        />
    );
}
