import React from "react"
import {useSelector} from "react-redux"

import {
    Grid
} from "@material-ui/core"

import {history} from "../../../../App/helpers/history"
import {Submit} from "../../../../App/components/Button/Submit"
import {AuthorizationService} from "../../../../Auth/services/authorization";

export const Create = (props) => {

    const {account} = useSelector(state => state.account)

    return AuthorizationService.roles(account, 'supervisor') ? (
        <Grid item>
            <Submit
                disableElevation
                variant="contained"
                color="primary"
                type="button"
                style={{height: '35px'}}
                onClick={() => {
                    history.push(`/planning/schedule/add`)
                }}
            >
                Добавить общий план-график
            </Submit>
        </Grid>
    ) : null
}
