import React from "react"
import {Field} from "formik"
import { TextField } from "formik-material-ui"

import {Card, CardContent, Grid, makeStyles, Typography} from "@material-ui/core"
import {Autocomplete, createFilterOptions} from '@material-ui/lab'

const useStyles = makeStyles(() => ({
    fullWidth: {
        'width': '100%'
    },
    card: {
        'width': '100%',
        'border-radius': 'unset'
    },
    label: {
        'color': 'rgba(0, 0, 0, 0.54)',
        'padding': '0',
        'font-size': '0.75rem',
        'font-weight': '400',
        'line-height': '1',
        'letter-spacing': '0.00938em'
    }
}))

export const FieldNeedle = (props) => {
    const classes = useStyles()

    const { id, label, items, values, setFieldValue, setFieldTouched, isSubmitting, disabled } = props

    return (
        <Grid item className={classes.fullWidth}>
            <Card className={classes.card}>
                <CardContent>
                    <Grid item className={classes.fullWidth}>
                        <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                    <Grid item sm={9} className={classes.fullWidth}>
                                        <Typography variant="caption" className={classes.label}>{label}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                    <Grid item className={classes.fullWidth}>
                                        <Field
                                            fullWidth
                                            name={`attributes.${id}.information`}
                                            disabled={ isSubmitting || disabled }
                                            label="Информация об игле"
                                            component={ TextField }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                    <Grid item xs={6} className={classes.fullWidth}>
                                        <Field
                                            fullWidth
                                            name={`attributes.${id}.gauge.value`}
                                            disabled={ isSubmitting || disabled }
                                            label="Размер Gauge"
                                            component={ TextField }
                                        />
                                    </Grid>
                                    <Grid item sm={6} className={classes.fullWidth}>
                                        <Field
                                            fullWidth
                                            name={`attributes.${id}.gauge.additional`}
                                            disabled={ isSubmitting || disabled }
                                            label="Размер Gauge (доп.)"
                                            component={ TextField }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                    <Grid item sm={6} className={classes.fullWidth}>
                                        <Field
                                            fullWidth
                                            name={`attributes.${id}.diameter.value`}
                                            type="number"
                                            disabled={ isSubmitting || disabled }
                                            label="Диаметр"
                                            inputProps={{
                                                step: 0.0001,
                                                onWheel: e => {
                                                    e.target.blur()
                                                    e.stopPropagation()
                                                    setTimeout(() => {
                                                        e.target.focus()
                                                    }, 0)
                                                }
                                            }}
                                            component={ TextField }
                                        />
                                    </Grid>
                                    <Grid item sm={6} className={classes.fullWidth}>
                                        <Field
                                            fullWidth
                                            name={`attributes.${id}.diameter.delimiter`}
                                            disabled={ isSubmitting || disabled }
                                            label="Разделитель"
                                            component={ TextField }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                    <Grid item sm={6} className={classes.fullWidth}>
                                        <Field
                                            fullWidth
                                            name={`attributes.${id}.length.value`}
                                            type="number"
                                            disabled={ isSubmitting || disabled }
                                            label="Длина иглы"
                                            inputProps={{
                                                step: 0.0001,
                                                onWheel: e => {
                                                    e.target.blur()
                                                    e.stopPropagation()
                                                    setTimeout(() => {
                                                        e.target.focus()
                                                    }, 0)
                                                }
                                            }}
                                            component={ TextField }
                                        />
                                    </Grid>
                                    <Grid item sm={6} className={classes.fullWidth}>
                                        <Autocomplete
                                            filterOptions={createFilterOptions({
                                                matchFrom: 'start',
                                                stringify: option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')
                                            })}
                                            options={ items }
                                            getOptionLabel={option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')}
                                            disabled={ isSubmitting || disabled }
                                            defaultValue={() => (values.hasOwnProperty(id) ? { short: values[id].unit } : { short: '' })}
                                            onChange={(e, value) => {
                                                setFieldValue(`attributes.${id}.length.unit`, ((value instanceof Object) ? value.short : value))
                                            }}
                                            onBlur={(e) => {
                                                if (items.find(item => item.short === e.target.value)) {
                                                    setFieldTouched(`attributes.${id}.length.unit`, true)
                                                } else {
                                                    setFieldValue(`attributes.${id}.length.unit`, '')
                                                }
                                            }}
                                            renderInput={params => (
                                                <Field
                                                    fullWidth
                                                    {...params}
                                                    name={`attributes.${id}.length.unit`}
                                                    type="text"
                                                    label="Ед. изм."
                                                    component={ TextField }
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                    <Grid item sm={6} className={classes.fullWidth}>
                                        <Field
                                            fullWidth
                                            name={`attributes.${id}.length.delimiter`}
                                            disabled={ isSubmitting || disabled }
                                            label="Разделитель"
                                            component={ TextField }
                                        />
                                    </Grid>
                                    <Grid item sm={6} className={classes.fullWidth}>
                                        <Field
                                            fullWidth
                                            name={`attributes.${id}.bend`}
                                            disabled={ isSubmitting || disabled }
                                            label="Степень изгиба"
                                            component={ TextField }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                    <Grid item className={classes.fullWidth}>
                                        <Field
                                            fullWidth
                                            name={`attributes.${id}.description`}
                                            disabled={ isSubmitting || disabled }
                                            label="Текст"
                                            component={ TextField }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}
