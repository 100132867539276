import React, {useEffect, useRef, useState} from 'react'
import {Grid, InputAdornment, makeStyles, TablePagination,} from '@material-ui/core'
import {Form, Formik} from "formik";
import {TextField} from "../App/components/V2/Inputs/TextField";
import MagnifyingGlass from "../App/components/Svg/MagnifyingGlass";
import {useDispatch, useSelector} from "react-redux";
import {SystemActions} from "../App/actions/system";
import {PurchaseActions as PlanningPurchaseActions} from "../Planning/actions/purchase";
import {PurchaseActions} from "./actions/purchase";
import {endings} from "../App/helpers/endings";
import format from "date-fns/format";
import {ru} from "date-fns/locale";
import {Link} from "react-router-dom";
import {Submit} from "../App/components/Button/Submit";
import {useDebounce} from "use-debounce";
import {history} from "../App/helpers/history";

const useStyles = makeStyles(() => ({
    content: {
        width: '1280px',
    },
    filters: {
        marginTop: '30px',
        background: '#f6f6f6',
        borderRadius: '32px',
        width: '100%',
        height: 'auto',
        padding: '24px'
    },
    filtersLabel: {
        textTransform: 'initial',
        fontSize: '18px',
        fontWeight: 'bold',
        fontFamily: 'ALS Sirius',
        color: 'black'
    },
    expandLessIcon: {
        transition: '0.3s'
    },
    purchase: {
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        borderBottom: "2px solid #F6F6F6",
        paddingTop: "40px",
        paddingBottom: "20px",
        fontFamily: "ALS Sirius, Helvetica, sans-serif",
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "24px",
    },
    purchaseHeader: {
        display: "flex",
        flexDirection: "column",
        gap: "24px"
    },
    purchaseHeaderTop: {
        display: "flex",
        flexDirection: "column",
        gap: "16px"
    },
    purchaseHeaderBottom: {
        display: "flex",
        gap: "40px"
    },
    purchaseHeaderBottomItem: {
        display: "flex",
        flexDirection: "column",
        gap: "4px"
    },
    purchaseHeaderBottomItemDiv: {
        "&:first-child": {
            color: "#9B9B9B"
        }
    },
    purchaseHeaderBottomItemSpan: {
        fontSize: "14px",
        lineHeight: "20px"
    },
    purchaseName: {
        fontSize: "24px",
        lineHeight: "32px"
    },
    purchaseInfo: {
        display: "flex",
        flexDirection: "column",
        gap: "8px"
    },
    purchaseClient: {
        display: "flex",
        alignItems: "center",
        gap: "13px"
    },
    purchaseStatus: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
        ">span": {
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left center",
            paddingLeft: "16px"
        },
        "&-open": {backgroundImage: "url(../img/status-open.svg)"},
        "&-default": {backgroundImage: "url(../img/status-default.svg)"},
        "&-cancel": {backgroundImage: "url(../img/status-cancel.svg)"},
        "&-progress": {backgroundImage: "url(../img/status-progress.svg)"}
    },
    purchaseTable: {
        borderSpacing: "0",
        borderRadius: "16px 16px 0 0",
        cursor: "pointer",
        transition: "0.5s",
        "&:hover": {backgroundColor: "#E6E6E6"}
    },
    purchaseTableHead: {
        padding: "16px 17px",
        textAlign: "left",
        backgroundColor: "#F6F6F6",
        fontSize: "18px",
        fontWeight: 500,
        "&:first-child": {borderRadius: "16px 0 0 16px"},
        "&:last-child": {borderRadius: "0 16px 16px 0"}
    },
    purchaseTableBody: {
        fontSize: "18px",
        fontWeight: 500,
        padding: "16px 17px",
        textAlign: "left"
    },
    footer: {
        backgroundColor: "#F6F6F6",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px 30px",
        position: 'fixed',
        bottom: 0,
        width: 'inherit',
    },
    footerWrap: {
        display: "flex",
        gap: "48px"
    },
    footerPerPage: {
        display: "flex",
        gap: "16px",
        height: "48px",
        alignItems: "center"
    },
    selectedMenuItem: {
        '& .MuiMenuItem-root': {
            color: 'black',
            backgroundColor: 'white',
            fontFamily: "ALS Sirius, Helvetica, sans-serif",
            lineHeight: "24px",
        },
        '& .MuiMenuItem-root.MuiListItem-root.Mui-selected': {
            color: '#FA4022',
            backgroundColor: 'white'
        },
    },
    pagination: {
        '& .MuiTablePagination-caption.MuiTypography-body2': {
            color: 'black',
            fontFamily: "ALS Sirius, Helvetica, sans-serif",
            lineHeight: "24px",
            fontWeight: 500
        },
        '& .MuiSelect-select.MuiSelect-select': {
            color: 'black',
            fontFamily: "ALS Sirius, Helvetica, sans-serif",
            lineHeight: "24px",
            fontWeight: 500
        }
    },
    footerButton: {
        backgroundColor: "#FA4022",
        color: "#fff",
        cursor: "pointer",
        padding: "12px 20px",
        borderRadius: "12px",
        fontFamily: "ALS Sirius, Helvetica, sans-serif",
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "24px",
        width: 'unset',
        height: 'unset',
        borderBottom: 'unset',
        textTransform: 'unset'
    }
}))

export const IndexNew = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {orders} = useSelector(state => state.purchase)
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [page, setPage] = useState(0)
    const [loadingOrders, setLoadingOrders] = useState(false)
    const containerRef = useRef('')
    const [search, setSearch] = useState('')
    const [searchRequest] = useDebounce(search, 900)

    useEffect(() => {
        if (!loadingOrders) {
            setLoadingOrders(true)
            getOrders()
        }
    }, [page, rowsPerPage, searchRequest]);

    useEffect(() => {
        getData().then(() => {

        })
    }, [dispatch]);
    const getOrders = () => {
        dispatch(PurchaseActions.orders({
            ...{
                limit: rowsPerPage,
                page: page + 1,
                ...(searchRequest !== '' ? {search: searchRequest} : {})
            },
        })).then(() => {
            setLoadingOrders(false)
            containerRef.current && containerRef.current.scrollTo({behavior: 'smooth', top: 0})
        })
    }

    const getData = async () => {
        await dispatch(SystemActions.status())
        await dispatch(SystemActions.categoriesTree())
        await dispatch(PlanningPurchaseActions.conductionMethods())
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    return (
        <Grid item className={classes.content}>
            <Formik
                validateOnBlur={false}
                initialValues={{
                    search: ''
                }}
                onSubmit={async () => {

                }}
            >
                {({
                      values,
                  }) => (
                    <Form>
                        <Grid className={classes.filters}>
                            <TextField
                                id="search"
                                value={search}
                                name="search"
                                placeholder={`Поиск по номеру, наименованию, заказчику`}
                                onChange={(event) => {
                                    setSearch(event.target.value)
                                    setLoadingOrders(false)
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" style={{marginLeft: '15px'}}>
                                            <MagnifyingGlass/>
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true
                                }}
                            />
                            {/*<Button*/}
                            {/*    variant="text"*/}
                            {/*    onClick={() => setIsOpenAllFilters(!isOpenAllFilters)}*/}
                            {/*>*/}
                            {/*    <Filters/>*/}
                            {/*    <Typography className={classes.filtersLabel}>Фильтры</Typography>*/}
                            {/*    <ExpandLessIcon className={classes.expandLessIcon}*/}
                            {/*                    style={{rotate: isOpenAllFilters ? '180deg' : '0deg'}}/>*/}
                            {/*</Button>*/}
                            {/*{(isOpenAllFilters && loading) ? <Grid*/}
                            {/*    container*/}
                            {/*    direction="row"*/}
                            {/*    justify="flex-start"*/}
                            {/*    alignItems="center"*/}
                            {/*>*/}
                            {/*    <Grid item xs={3} className={classes.column}>*/}
                            {/*        <SelectTree*/}
                            {/*            label='Категория'*/}
                            {/*            options={categories.map(category => ({*/}
                            {/*                ...category,*/}
                            {/*                parent: category.category*/}
                            {/*            }))}*/}
                            {/*            value={values.categories}*/}
                            {/*            multiple={true}*/}
                            {/*            onChange={(value) => {*/}
                            {/*                setFieldValue('categories', value);*/}
                            {/*            }}*/}
                            {/*            reset={1}*/}
                            {/*        />*/}
                            {/*    </Grid>*/}
                            {/*</Grid> : null}*/}
                        </Grid>
                    </Form>
                )}
            </Formik>
            {orders?.data?.length ? orders.data.map((order, index) => {
                return <div className={classes.purchase} key={index}>
                    <div className={classes.purchaseHeader}>
                        <div className={classes.purchaseHeaderTop}>
                            <div
                                className={classes.purchaseName}>{order.id ? `№${order.id} - ` : null} {order.name ? order.name : null}</div>
                            <div className={classes.purchaseInfo}>
                                <div className={classes.purchaseClient}>
                                    <div>{order.company.legal_detail.name}</div>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#FA4022"/>
                                    </svg>
                                    <div>{order.regions.name}</div>
                                </div>
                                <div className={classes.purchaseStatus}>
                                    <div>Статус:</div>
                                    <span className="purchase__status-open">{order.status.name}</span>
                                </div>
                            </div>
                        </div>
                        <div className={classes.purchaseHeaderBottom}>
                            <div className={classes.purchaseHeaderBottomItem}>
                                <div className={classes.purchaseHeaderBottomItemDiv}>Кол-во лотов</div>
                                <div
                                    className={classes.purchaseHeaderBottomItemDiv}>{endings(order.count.lots, ['лот', 'лота', 'лотов'])}</div>
                            </div>
                            <div className={classes.purchaseHeaderBottomItem}>
                                <div className={classes.purchaseHeaderBottomItemDiv}>Общая стоимость</div>
                                <div
                                    className={classes.purchaseHeaderBottomItemDiv}>{order.average_price ? parseFloat(order.average_price).toLocaleString('ru-RU', {
                                    style: 'currency',
                                    currency: 'RUB',
                                }) : null}</div>
                            </div>
                            <div className={classes.purchaseHeaderBottomItem}>
                                <div className={classes.purchaseHeaderBottomItemDiv}>Метод определения поставщика</div>
                                <div
                                    className={classes.purchaseHeaderBottomItemDiv}>{order.type === 'static' ? 'Статический запрос' : 'Динамический запрос'}</div>
                            </div>
                            <div className={classes.purchaseHeaderBottomItem}>
                                <div className={classes.purchaseHeaderBottomItemDiv}>Время размещения</div>
                                <div
                                    className={classes.purchaseHeaderBottomItemDiv}>от {format(new Date(order.application_start), 'H:mm PP', {locale: ru})}</div>
                                <span
                                    className={classes.purchaseHeaderBottomItemSpan}>(МСК {format(new Date(new Date(order.application_start).toLocaleString("en-US", {timeZone: "Europe/Moscow"})), 'H:mm PP', {locale: ru})})</span>
                            </div>
                            <div className={classes.purchaseHeaderBottomItem}>
                                <div className={classes.purchaseHeaderBottomItemDiv}>Подача предложений</div>
                                <div
                                    className={classes.purchaseHeaderBottomItemDiv}>до {format(new Date(order.application_deadline), 'H:mm PP', {locale: ru})}</div>
                                <span
                                    className={classes.purchaseHeaderBottomItemSpan}>(МСК {format(new Date(new Date(order.application_deadline).toLocaleString("en-US", {timeZone: "Europe/Moscow"})), 'H:mm PP', {locale: ru})})</span>
                            </div>
                        </div>
                    </div>
                    <div className="purchase__body">
                        {order.lots && order.lots.map((lot, index) => {
                            return <table
                                className={classes.purchaseTable}
                                key={index}
                                onClick={() => {
                                    history.push(`/lots/${lot.id}`)
                                }}
                            >
                                <thead>
                                <tr>
                                    <th className={classes.purchaseTableHead} style={{width: '80px'}}>№ Лота</th>
                                    <th className={classes.purchaseTableHead} style={{width: '450px'}}>Объект закупки
                                    </th>
                                    <th className={classes.purchaseTableHead} style={{width: '80px'}}>Кол-во</th>
                                    <th className={classes.purchaseTableHead} style={{width: '165px'}}>НМЦ</th>
                                    <th className={classes.purchaseTableHead} style={{width: '95px'}}>Отсрочка</th>
                                    <th className={classes.purchaseTableHead} style={{width: '170px'}}>Срок исполнения
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {lot.items.map((item, i) => (
                                    <tr>
                                        <td className={classes.purchaseTableBody}>{i === 0 ? (lot.number ? lot.number : lot.index) : ''}</td>
                                        <td className={classes.purchaseTableBody}>
                                            <p>{item.name}</p>
                                        </td>
                                        <td className={classes.purchaseTableBody}>{item.amount}</td>
                                        <td className={classes.purchaseTableBody}>{item.average_price ? parseFloat(item.average_price).toLocaleString('ru-RU', {
                                            style: 'currency',
                                            currency: order.currency.code
                                        }).replace('KZT', '₸') : '-'}</td>
                                        <td className={classes.purchaseTableBody}>{lot.postponement}</td>
                                        <td className={classes.purchaseTableBody}>{lot.contract_time}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        })}
                    </div>
                </div>
            }) : null}
            <div className={classes.footer}>
                <div className={classes.footerWrap}>
                    <TablePagination
                        className={classes.pagination}
                        rowsPerPageOptions={[5, 15, 25, 50, 100]}
                        component='div'
                        labelRowsPerPage={'Записей на странице:'}
                        count={orders.meta.total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        SelectProps={{
                            MenuProps: {
                                classes: {paper: classes.selectedMenuItem}, // Применение стилей к выбранному элементу
                            },
                        }}
                    />
                </div>
                <Submit
                    className={classes.footerButton}
                    disableElevation
                    variant="contained"
                    color="primary"
                    type="button"
                    component={Link}
                    to="/login"
                >
                    Войти
                </Submit>
            </div>
        </Grid>
    )
}
