import {DeliveryService} from "../services/delivery"

export const DeliveryActions = {
    orders,
    order,
    arrange,
    remove,
    save,
    cancel,
    status,
    steps,
    files,
    addItem,
    addItems,
    addAllItems,
    editItem,
    removeItem,
    items,
    filter
}

function orders(params, local = false) {
    return dispatch => new Promise((resolve, reject) => {
        if (!local) {
            dispatch({type: "DELIVERY_ORDERS_REQUEST"})
        }
        dispatch({type: "LOADING", payload: true})

        DeliveryService.orders(params)
            .then(
                response => {
                    if (!local) {
                        dispatch({type: "DELIVERY_ORDERS", payload: response})
                    }
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    if (!local) {
                        dispatch({type: "DELIVERY_ORDERS_FAILURE"})
                    }
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function order(id, params, local = false) {
    return dispatch => new Promise((resolve, reject) => {
        if (!local) {
            dispatch({type: "DELIVERY_ORDER_REQUEST"})
        }
        dispatch({type: "LOADING", payload: true})

        DeliveryService.order(id, params)
            .then(
                order => {
                    if (!local) {
                        dispatch({type: "DELIVERY_ORDER", payload: order})
                    }
                    dispatch({type: "LOADING", payload: false})
                    resolve(order)
                },
                error => {
                    if (!local) {
                        dispatch({type: "DELIVERY_ORDER_FAILURE"})
                    }
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function items(id, params, local = false) {
    return dispatch => new Promise((resolve, reject) => {
        if (!local) {
            dispatch({type: "DELIVERY_ORDER_ITEMS_REQUEST"})
        }
        dispatch({type: "LOADING", payload: true})

        DeliveryService.items(id, params)
            .then(
                response => {
                    if (!local) {
                        dispatch({type: "DELIVERY_ORDER_ITEMS", payload: response})
                    }
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    if (!local) {
                        dispatch({type: "DELIVERY_ORDER_ITEMS_FAILURE"})
                    }
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function arrange(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_ORDER_ARRANGE_FAILURE"})

        DeliveryService.arrange(id, values)
            .then(
                response => {
                    dispatch({type: "DELIVERY_ORDER_ARRANGE_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELIVERY_ORDER_ARRANGE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR",
                        payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function remove(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_ORDER_DELETE_REQUEST"})

        DeliveryService.remove(id)
            .then(
                response => {
                    dispatch({type: "DELIVERY_ORDER_DELETE_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELIVERY_ORDER_DELETE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function save(id, formData) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_ORDER_UPDATE_REQUEST"})

        DeliveryService.save(id, formData)
            .then(
                response => {
                    dispatch({type: "DELIVERY_ORDER_UPDATE_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELIVERY_ORDER_UPDATE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function cancel(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_ORDER_CANCEL_REQUEST"})

        DeliveryService.cancel(id)
            .then(
                response => {
                    dispatch({type: "DELIVERY_ORDER_CANCEL_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELIVERY_ORDER_CANCEL_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function status(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_ORDER_STATUS_REQUEST"})

        DeliveryService.status(id, values)
            .then(
                response => {
                    dispatch({type: "DELIVERY_ORDER_STATUS_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELIVERY_ORDER_STATUS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function steps(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        DeliveryService.steps(id)
            .then(
                steps => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(steps)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function files(id, formData) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_ORDER_FILES_REQUEST"})

        DeliveryService.files(id, formData)
            .then(
                response => {
                    dispatch({type: "DELIVERY_ORDER_FILES_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELIVERY_ORDER_FILES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function addItem(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_ORDER_ADD_ITEM_REQUEST"})

        DeliveryService.addItem(id, values)
            .then(
                response => {
                    dispatch({type: "DELIVERY_ORDER_ADD_ITEM_SUCCESS"})
                    dispatch({type: "CATALOG_PRODUCTS_EDIT_AMOUNT_ITEM", payload: values})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELIVERY_ORDER_ADD_ITEM_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function addItems(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_ORDER_ADD_ITEMS_REQUEST"})

        DeliveryService.addItems(values)
            .then(
                response => {
                    dispatch({type: "DELIVERY_ORDER_ADD_ITEMS_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELIVERY_ORDER_ADD_ITEMS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function addAllItems(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_ORDER_ADD_ALL_ITEM_REQUEST"})

        DeliveryService.addAllItems(id)
            .then(
                response => {
                    dispatch({type: "DELIVERY_ORDER_ADD_ALL_ITEM_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Товары успешно добавлены в корзину',
                            type:'success'
                        }
                    })
                    resolve()
                },
                error => {
                    dispatch({type: "DELIVERY_ORDER_ADD_ALL_ITEM_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function editItem(order_id, id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_ORDER_EDIT_ITEM_REQUEST"})

        DeliveryService.editItem(order_id, id, values)
            .then(
                response => {
                    dispatch({type: "DELIVERY_ORDER_EDIT_ITEM_SUCCESS", payload: {order: {id: order_id}, id: id, amount: values.amount, total: values.total}})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELIVERY_ORDER_EDIT_ITEM_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function removeItem(order_id, id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_ORDER_DELETE_ITEM_REQUEST"})

        DeliveryService.removeItem(order_id, id)
            .then(
                response => {
                    dispatch({type: "DELIVERY_ORDER_DELETE_ITEM_SUCCESS", payload: {order: {id: order_id}, id: id}})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "DELIVERY_ORDER_DELETE_ITEM_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({
                        type: "ALERT_ERROR",
                        payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function filter(params) {
    return dispatch => new Promise((resolve, reject) => {
        DeliveryService.filter(params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    reject(error)
                }
            )
    })
}
