import React from "react"
import {makeStyles, Tooltip as MUITooltip} from "@material-ui/core"

const useStyles = makeStyles(() => ({
    tooltip: {
        "background-color": "#000",
        "font-size": "15px",
        "color": "#fff",
        "padding": "15px 15px",
    }
}));

export const Tooltip = ({title, placement, children}) => {
    const classes = useStyles();

    return title ? (
        <MUITooltip
            title={title}
            placement={placement}
            classes={{tooltip: classes.tooltip}}
        >
            {children}
        </MUITooltip>
    ) : children
}
