import {HttpService} from "../../App/services/http"

export const WarehouseService = {
    get,
    edit,
    getByAdmin
}

function get(params) {
    return HttpService.get("/company/supplier/warehouses", params)
}

function getByAdmin(supId, params) {
    return HttpService.get(`/company/supplier/${supId}/warehouses`, params)
}

function edit(values) {
    return HttpService.post("/company/supplier/warehouse", values)
}
