import {IntegrationService} from "../services/integration"

export const IntegrationActions = {
    permissions,
    integrations,
    create,
    edit,
    delete: remove
}

function permissions() {
    return dispatch => new Promise((resolve, reject) => {
        IntegrationService.permissions()
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )

    })
}

function integrations(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})

        IntegrationService.integrations(params)
            .then(
                response => {
                    dispatch({type: "FILLING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "FILLING", payload: false})
                    reject(error)
                }
            )

    })
}

function create(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})

        IntegrationService.create(values)
            .then(
                response => {
                    dispatch({type: "FILLING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "FILLING", payload: false})
                    reject(error)
                }
            )

    })
}

function edit(values, id){
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})

        IntegrationService.edit(values, id)
            .then(
                response => {
                    dispatch({type: "FILLING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "FILLING", payload: false})
                    reject(error)
                }
            )

    })
}

function remove(id){
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "FILLING", payload: true})

        IntegrationService.delete(id)
            .then(
                response => {
                    dispatch({type: "FILLING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "FILLING", payload: false})
                    reject(error)
                }
            )

    })
}
