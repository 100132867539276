export default function products(state = {}, action) {
    switch (action.type) {
        case "PRODUCT":
            return {
                ...state,
                products: action.payload,
            }
        case "PRODUCT_FAILURE":
            return state
        case "PRODUCT_RESET":
            return {}
        case "STANDARDS_SUCCESS":
            return {
                ...state,
                standards: action.payload,
            }
        case "GET_PRODUCT_SUCCESS":
            return {
                ...state,
                product: action.payload,
            }
        case "CLEAR_EDIT_PRODUCT":
            return {
                ...state,
                product: null,
            }
        case "STANDARDS_CLEAR":
            return {
                ...state,
                standards: [],
            }
        case "GET_PRODUCT_WITH_CATEGORY_SUCCESS":
            return {
                ...state,
                productsWithCategory: action.payload,
            }
        default:
            return state
    }
}
