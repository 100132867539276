import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import * as Yup from "yup"

import {Grid, makeStyles, Paper, Typography} from "@material-ui/core"

import {SettingsActions} from "../../actions/settings"
import {Submit} from "../../../App/components/Button/Submit"
import {TextFieldWithError as CustomTextField} from "../../../App/components/Inputs/TextFieldWithError"

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%"
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 111px)"
    },
    input: {
        "width": "100%",
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100% - 54px)",
        "min-height": "calc(100% - 54px)",
        "padding": "20px 30px 0",
        "overflow": "auto"
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    select: {
        "height": "43px"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    tableRowAlert: {
        "cursor": "pointer",
        "background-color": "#f9f1f0",
        "text-decoration": "none"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "18px !important"
    },
    notificationList: {
        height: "calc(100vh - 215px)",
        overflow: "auto",
    },
    border: {
        "border-bottom": "35px solid #c1c1c1 !important"
    }
}))

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {orderSettings} = useSelector(state => state.settings)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getSettings = async () => {
            return await dispatch(SettingsActions.order())
        }

        if (!loading) {
            getSettings().then(() => {
                setLoading(true)
            })
        }
    }, [dispatch, loading]);

    const getInitValues = (orderSettings) => {
        return {
            time_to_approve_offers: orderSettings.time_to_approve_offers,
            supplier_contract_execution_time: orderSettings.supplier_contract_execution_time,
            customer_contract_execution_time: orderSettings.customer_contract_execution_time,
            time_to_submit_applications: orderSettings.time_to_submit_applications,
            max_order_average_price: orderSettings.max_order_average_price,
            max_diff_offer_price: orderSettings.max_diff_offer_price,
            min_decrement_price: orderSettings.min_decrement_price,
            max_amount_basic_assets_ckb: orderSettings.max_amount_basic_assets_ckb,
            max_amount_basic_assets: orderSettings.max_amount_basic_assets

        }
    }

    const getValidation = () => {
        return Yup.object().shape({
            time_to_approve_offers: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
            supplier_contract_execution_time: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
            customer_contract_execution_time: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
            time_to_submit_applications: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
            max_order_average_price: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
            max_diff_offer_price: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
            min_decrement_price: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
            max_amount_basic_assets_ckb: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
            max_amount_basic_assets: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!")
        })
    }

    return loading ? (
            <Grid item className={classes.content}>
                <Paper>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item className={classes.fullWidth}>
                            <Grid container className={classes.container} direction="column" justify="space-between" alignItems="stretch">
                                <Formik
                                    initialValues={getInitValues(orderSettings)}
                                    validationSchema={getValidation()}
                                    onSubmit={(values, {setSubmitting}) => {
                                        return dispatch(SettingsActions.updateOrder(values)).then(
                                            () => {
                                                setSubmitting(false)
                                            },
                                            () => {
                                                setSubmitting(false)
                                            }
                                        )
                                    }}
                                >
                                    {({
                                          isSubmitting
                                      }) => (
                                        <Form className={classes.fullWidth}>
                                            <Grid item className={classes.inputContent}>
                                                <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                                    <Grid item className={classes.header}>
                                                        <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                                            <Grid item className={classes.fullWidth}>
                                                                <Typography className={classes.title}>
                                                                    Настройки ценовых запросов
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid container className={classes.notificationList} direction="row" justify="start" alignItems="stretch" spacing={4}>
                                                    <Grid item xs={4}>
                                                        <Field
                                                            fullWidth
                                                            name="time_to_approve_offers"
                                                            step={1}
                                                            type="number"
                                                            label="Время заказчика на подтверждение/отклонение предложений, час"
                                                            required
                                                            inputProps={{
                                                                min: 0,
                                                                step: 1
                                                            }}
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Field
                                                            fullWidth
                                                            name="supplier_contract_execution_time"
                                                            step={1}
                                                            type="number"
                                                            label="Время на подписание договора поставщиком, час"
                                                            required
                                                            inputProps={{
                                                                min: 0,
                                                                step: 1
                                                            }}
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Field
                                                            fullWidth
                                                            name="customer_contract_execution_time"
                                                            step={1}
                                                            type="number"
                                                            label="Время на подписание договора заказчиком, час"
                                                            required
                                                            inputProps={{
                                                                min: 0,
                                                                step: 1
                                                            }}
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Field
                                                            fullWidth
                                                            name="time_to_submit_applications"
                                                            type="number"
                                                            inputProps={{
                                                                min: 0,
                                                                step: 1
                                                            }}
                                                            label="Минимальное время между началом и окончанием приема заявок, час"
                                                            required
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Field
                                                            fullWidth
                                                            name="max_order_average_price"
                                                            inputProps={{
                                                                min: 0,
                                                                step: 0.01
                                                            }}
                                                            type="number"
                                                            label="Лимит ЦЗ по сумме, рубль"
                                                            required
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Field
                                                            fullWidth
                                                            name="max_diff_offer_price"
                                                            inputProps={{
                                                                min: 0,
                                                                step: 0.01,
                                                                max: 100
                                                            }}
                                                            type="number"
                                                            label="Разница от НМЦ для запроса обоснования цены, %"
                                                            required
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Field
                                                            fullWidth
                                                            inputProps={{
                                                                min: 0,
                                                                step: 0.01,
                                                                max: 100
                                                            }}
                                                            name="min_decrement_price"
                                                            type="number"
                                                            label="Минимальный шаг изменения цены в меньшую сторону при переторжке, %"
                                                            required
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Field
                                                            fullWidth
                                                            inputProps={{
                                                                min: 0,
                                                                step: 0.01
                                                            }}
                                                            name="max_amount_basic_assets_ckb"
                                                            type="number"
                                                            label="Ограничение максимальной суммы по категории 'Основные средства' для ЧУЗ ЦКБ 'РЖД-Медицина'"
                                                            required
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Field
                                                            fullWidth
                                                            inputProps={{
                                                                min: 0,
                                                                step: 0.01
                                                            }}
                                                            name="max_amount_basic_assets"
                                                            type="number"
                                                            label="Ограничение максимальной суммы по категории 'Основные средства' для остальных заказчиков"
                                                            required
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.footer}>
                                                <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                                    <Grid item className={classes.footerContent}>
                                                        <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Submit
                                                                    disableElevation
                                                                    variant="contained"
                                                                    color="primary"
                                                                    type="submit"
                                                                    disabled={isSubmitting}
                                                                >
                                                                    Сохранить
                                                                </Submit>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        ) : null
}
