import React, {Fragment, useRef, useState} from "react";
import {
  Button as MuiButton,
  ButtonGroup,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  makeStyles
} from "@material-ui/core";
import {ArrowDropDown as ArrowDropDownIcon} from "@material-ui/icons";
import * as AppStorage from "../../App/helpers/storage";
import {SystemActions} from "../../App/actions/system";
import {useDispatch} from "react-redux";
import {b64toBlob} from "../utils/b64toBlob";
import {Signature} from "../Signature";

const useStyles = makeStyles(theme => ({
  group: {
    "box-shadow": "none",
    "border-radius": "2px",
    "& .MuiButtonBase-root": {
      "border-bottom": "3px solid #898989",
      "padding": "5px 10px 0",
      "min-height": "35px",
      "height":"35px",
      "font-size": "16px",
      "font-weight": "500",
      "color": "white",
      "outline": "0",
      "&:disabled": {
        "background-color": "#c1c1c1",
        "border-bottom-color": "#a5adb1 !important",
        "color": "white"
      },
      '& .MuiButton-endIcon': {
        'padding-bottom': '3px'
      },
      '&.MuiButton-sizeSmall': {
        "min-height": "30px",
        "line-height": "1.50",
        "font-size": "0.9rem",
        "padding": "5px 7px 0"
      },
      '&.MuiButton-containedSecondary': {
        "border-bottom": "3px solid #b56a5f",
      },
      "&:first-child": {
        borderRadius: "2px 0 0 2px"
      },
      "&:last-child": {
        borderRadius: "0 2px 2px 0"
      }
    }
  },
  paper: {
    "margin-bottom": "4px",
    "border": "none !important",
    "& .MuiButtonBase-root": {
      "padding": "8px 12px"
    }
  }
}))

export const Button = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const {file, name, href, onContinue, required = true} = props
  const [signature, setSignature] = useState(file.sign)
  const [sign, setSign] = useState({open: false, files: []})

  const handleClose = () => setOpen(false)

  const token = AppStorage.get('token');

  return (
    <Fragment>
      <ButtonGroup className={classes.group} variant="contained" color="primary" ref={anchorRef}>
        <MuiButton>{name}</MuiButton>
        <MuiButton
          color="primary"
          size="small"
          onClick={() => setOpen(true)}
        >
          <ArrowDropDownIcon />
        </MuiButton>
      </ButtonGroup>
      {open ? (
        <Popper open={open} anchorEl={anchorRef.current} placement="top-end">
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={() => handleClose()}>
              <MenuList >
                <MenuItem
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = process.env.REACT_APP_HOST_API + href + (token?.length ? `?token=${AppStorage.get('token').replace('Bearer ', '')}` : '');
                    link.download = file.name;
                    link.click();
                    handleClose()
                  }}
                >
                  Скачать файл
                </MenuItem>
                {signature ? (
                  <MenuItem
                    onClick={() => {
                      handleClose()
                      const blob = b64toBlob(signature, 'text/plain')

                      const url = URL.createObjectURL(blob);

                      const a = document.createElement("a");
                      a.href = url;
                      a.download = `${file.name}.sig`;
                      a.click();

                      URL.revokeObjectURL(url);
                    }}
                  >
                    Скачать подпись
                  </MenuItem>
                ) : (onContinue ? (
                  <MenuItem
                    onClick={async () => {
                      handleClose()
                      dispatch(SystemActions.file(href)).then(
                        (blob) => {
                          const object = new File([blob], file.name, { type: blob.type });
                          setSign({open: true, files: [object]})
                        }
                      )
                    }}
                  >
                    Подписать
                  </MenuItem>
                ) : null)}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      ) : null}
      {sign.open ? (
        <Signature
          open={sign.open}
          onClose={() => setSign({open: false, files: []})}
          files={sign.files}
          onContinue={(objects) => {
            setSign({open: false, files: []})
            setSignature(objects[0].sign)
            onContinue(objects[0].sign)
          }}
          required={required}
        />
      ) : null}
    </Fragment>
  )
}
