import React from "react"
import {useSelector} from "react-redux"
import {Field, getIn} from "formik"
import {TextField} from "formik-material-ui"
import DateFnsUtils from "@date-io/date-fns"
import ru from "date-fns/locale/ru"

import {
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    MenuItem,
    TextField as MuiTextField,
} from "@material-ui/core"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {Autocomplete} from "@material-ui/lab";
import {Clear} from "@material-ui/icons"

import {TextFieldWithError as CustomTextField} from "../../../../../../App/components/Inputs/TextFieldWithError"
import {Standard} from "../../../../../../App/components/Inputs/Standard";

const useStyles = makeStyles(theme => ({
    column: {
        "height": "86px"
    },
    block: {
        "height": "172px"
    }
}))

export const Item = (props) => {
    const classes = useStyles()

    const {index, i, category, settings, currency, getStandards, changeStandards, item, values, errors, touched, setValues, setFieldValue, setFieldTouched, remove} = props
    const {categories, units, currencies} = useSelector(state => state.system)

    const unitAdornment = (standard, name = '') => {
        if (standard instanceof Object) {
            if (standard.hasOwnProperty('values')) {
                const attribute = standard.values.find(value => (value.attribute.name === 'Фасовка/Вес') || (value.attribute.name === 'Фасованный/Весовой'))

                name = 'шт'

                if (attribute) {
                    if (attribute.value.toLowerCase() === 'вес') {
                        let unit = standard.values.find(value => (value.attribute.type.key === 'unit'));

                        if (unit) {
                            name = unit.value
                        }
                    }
                }

                return name
            }
        }

        return name
    }

    const getCategoriesWithWarrantyPeriod = (id = null) => {
        let result = []

        if (id) {
            result.push(id)

            categories.filter(category => (category.category && (category.category.id === id))).forEach(category => {
                result = result.concat(getCategoriesWithWarrantyPeriod(category.id))
            })
        } else {
            categories.filter(category => ([47, 50].indexOf(category.id) !== -1)).forEach(category => {
                result = result.concat(getCategoriesWithWarrantyPeriod(category.id))
            })
        }

        return result
    }

    return (
        <Grid item key={i} style={{boxShadow: "0 0 5px 2px grey", margin: "20px 0px", paddingBottom: "45px"}}>
            <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                <Grid item xs={10} className={classes.block}>
                    <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                        <Grid item>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                <Grid item xs={10} className={classes.column}>
                                    <Standard
                                        name={`lots.${index}.items.${i}.standard`}
                                        module={'ЦЗ'}
                                        required={true}
                                        type={settings.categories?.find(el => el.category.id === category?.index)?.type}
                                        disabled={!category.hasOwnProperty('id')}
                                        errors={errors}
                                        touched={touched}
                                        noOptionsText='Укажите название товара'
                                        category={{
                                            ...category,
                                            active: false
                                        }}
                                        standard={item.standard}
                                        params={{
                                            except: getStandards()
                                        }}
                                        onChange={(value) => {
                                            const item = value[`lots.${index}.items.${i}.standard`]

                                            setFieldValue(`lots.${index}.items.${i}.standard`, item)
                                            setFieldValue(`lots.${index}.items.${i}.unit`, '')

                                            if (item) {
                                                setFieldValue(`lots.${index}.items.${i}.amount`, 1)

                                                if (item instanceof Object) {
                                                    const unit = units.find(unit => unit?.short === unitAdornment(item))

                                                    if (unit) {
                                                        setFieldValue(`lots.${index}.items.${i}.unit`, unit)
                                                    }

                                                    changeStandards([item.id], 'add')
                                                }
                                            } else if (item?.standard) {
                                                if (item.standard instanceof Object) {
                                                    changeStandards([item.standard.id], 'remove')
                                                }
                                            }
                                        }}
                                        onAddStandard={(item) => {
                                            setFieldValue(`lots.${index}.items.${i}.standard`, item)
                                            setFieldValue(`lots.${index}.items.${i}.unit`, '')

                                            if (item) {
                                                setFieldValue(`lots.${index}.items.${i}.amount`, 1)
                                                if (item instanceof Object) {
                                                    const unit = units.find(unit => unit?.short === unitAdornment(item))

                                                    if (unit) {
                                                        setFieldValue(`lots.${index}.items.${i}.unit`, unit)
                                                    }

                                                    changeStandards([item.id], 'add')
                                                }
                                            } else if (item.standard) {
                                                if (item.standard instanceof Object) {
                                                    changeStandards([item.standard.id], 'remove')
                                                }
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                <Grid item xs={5}>
                                    <Field
                                      fullWidth
                                      name={`lots.${index}.items.${i}.params.okpd`}
                                      type="text"
                                      label="Классификация по ОКПД2"
                                      component={CustomTextField}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <Field
                                      fullWidth
                                      name={`lots.${index}.items.${i}.params.okved`}
                                      type="text"
                                      label="Классификация по ОКВЭД2"
                                      component={CustomTextField}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                <Grid item xs={2} className={classes.column}>
                                    <Field
                                        fullWidth
                                        type="text"
                                        name={`lots.${index}.items.${i}.expiration_date_type`}
                                        label="Тип срока годности"
                                        select
                                        variant="standard"
                                        component={TextField}
                                        required={Boolean(category?.active)}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            onChange: (event) => {
                                                setFieldValue(`lots.${index}.items.${i}.expiration_date_type`, event.target.value, true)
                                                if (event.target.value === 'expiration_date') {
                                                    setFieldValue(`lots.${index}.items.${i}.expiration_date_number`, '');
                                                } else {
                                                    setFieldValue(`lots.${index}.items.${i}.expiration_date`, null);
                                                }
                                            }
                                        }}
                                    >
                                        {[
                                            ...[{
                                                type: 'expiration_date',
                                                name: 'Минимальная дата истечения срока годности'
                                            }],
                                            ...((getCategoriesWithWarrantyPeriod().indexOf(category?.id) !== -1) ? [{
                                                type: 'warranty_period',
                                                name: 'Гарантийный срок'
                                            }] : [{
                                                type: 'expiration_date_percent',
                                                name: 'Остаточный срок годности'
                                            }])
                                        ].map((item, i) => (
                                            <MenuItem key={i} value={item.type}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                                <Grid item xs={2} className={classes.column}>
                                    {(item.expiration_date_type === 'expiration_date') ? (
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                fullWidth
                                                error={Boolean((errors?.hasOwnProperty('lots') && errors?.lots[index] && errors?.lots[index].hasOwnProperty('items') && errors?.lots[index].items[i] && errors?.lots[index].items[i].expiration_date) && (touched?.hasOwnProperty('lots') && touched?.lots[index] && touched?.lots[index].hasOwnProperty('items') && touched?.lots[index].items[i] && touched?.lots[index].items[i].expiration_date))}
                                                helperText={((errors?.hasOwnProperty('lots') && errors?.lots[index] && errors?.lots[index].hasOwnProperty('items') && errors?.lots[index].items[i] && errors?.lots[index].items[i].expiration_date) && (touched?.hasOwnProperty('lots') && touched?.lots[index] && touched?.lots[index].hasOwnProperty('items') && touched?.lots[index].items[i] && touched?.lots[index].items[i].expiration_date)) ? errors?.lots[index].items[i].expiration_date : null}
                                                name={`lots.${index}.items.${i}.expiration_date`}
                                                required={Boolean(category?.active && (item.expiration_date_type === 'expiration_date'))}
                                                format="dd-MM-yyyy"
                                                orientation="landscape"
                                                variant="inline"
                                                openTo="year"
                                                label="Срок годности"
                                                autoOk
                                                disablePast
                                                value={item.expiration_date}
                                                onChange={date => {
                                                    setFieldValue(`lots.${index}.items.${i}.expiration_date`, date);
                                                }}
                                                onBlur={() => {
                                                    setFieldTouched(`lots.${index}.items.${i}.expiration_date`, true, true)
                                                }}
                                                onClose={() => {
                                                    setFieldTouched(`lots.${index}.items.${i}.expiration_date`, true, true)
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    ) : null}
                                    {(item.expiration_date_type === 'warranty_period') ? (
                                        <Field
                                            fullWidth
                                            name={`lots.${index}.items.${i}.expiration_date_number`}
                                            type="number"
                                            label="Срок годности, месяцев"
                                            required={Boolean(category?.active && item.expiration_date_type === 'warranty_period')}
                                            step={1}
                                            inputProps={{
                                                min: 0,
                                                step: 1
                                            }}
                                            component={CustomTextField}
                                        />
                                    ) : null}
                                    {(item.expiration_date_type === 'expiration_date_percent') ? (
                                        <Field
                                            fullWidth
                                            name={`lots.${index}.items.${i}.expiration_date_number`}
                                            type="number"
                                            label="Срок годности, %"
                                            required={Boolean(category?.active && item.expiration_date_type === 'expiration_date_percent')}
                                            step={0.01}
                                            inputProps={{
                                                min: 0,
                                                step: 0.01,
                                            }}
                                            component={CustomTextField}
                                        />
                                    ) : null}
                                </Grid>
                                {!values?.is_unit_price ? <Grid item xs={2} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name={`lots.${index}.items.${i}.amount`}
                                        type="number"
                                        label="Кол-во"
                                        step={0.01}
                                        inputProps={{
                                            step: 0.01,
                                            min: 1,
                                            onChange: (e) => {
                                                const lots = values.lots.map((lot, idx) => {
                                                    let items = lot.items

                                                    if (idx === index) {
                                                        items = items.map((item, j) => {
                                                            return (j === i) ? {
                                                                ...item,
                                                                ...{
                                                                    amount: e.target.value,
                                                                    price: (e.target.value && item.average_price) ? (e.target.value * item.average_price).toFixed(2) : ''
                                                                }
                                                            } : item
                                                        })
                                                    }

                                                    return {
                                                        ...lot,
                                                        ...{
                                                            items: items,
                                                            price: items.reduce(function (sum, item) {
                                                                return sum + parseFloat(item.price)
                                                            }, 0) ?? ''
                                                        }
                                                    }
                                                })

                                                setValues({
                                                    ...values,
                                                    lots: lots,
                                                    price: lots.reduce(function (sum, lot) {
                                                        return sum + parseFloat(lot.price)
                                                    }, 0) ?? ''
                                                })
                                            }
                                        }}
                                        endAdornment={<InputAdornment position="end">{unitAdornment(item.standard, item.unit?.short)}</InputAdornment>}
                                        component={CustomTextField}
                                    />
                                </Grid> : null}
                                {!category?.active ? (
                                    <Grid item xs={2} className={classes.column}>
                                        <Autocomplete
                                            options={units}
                                            name={`lots.${index}.items.${i}.unit`}
                                            value={item.unit}
                                            getOptionLabel={unit => {
                                                return unit ? unit.name : ''
                                            }}
                                            onChange={(e, unit) => {
                                                setFieldValue(`lots.${index}.items.${i}.unit`, unit ? unit : '')
                                            }}
                                            noOptionsText='Единица измерения'
                                            renderInput={params => {
                                                const error = getIn(errors, `lots.${index}.items.${i}.unit`);

                                                return <Field
                                                    fullWidth
                                                    component={MuiTextField}
                                                    {...params}
                                                    error={!!error}
                                                    helperText={error}
                                                    name={`lots.${index}.items.${i}.unit`}
                                                    label="Единица измерения"
                                                />
                                            }}
                                        />
                                    </Grid>
                                ) : null}
                                <Grid item xs={2} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name={`lots.${index}.items.${i}.average_price`}
                                        type="number"
                                        label="НМЦ за ед. товара"
                                        step={0.01}
                                        inputProps={{
                                            step: 0.01,
                                            min: 0,
                                            onChange: (e) => {
                                                const lots = values.lots.map((lot, idx) => {
                                                    let items = lot.items

                                                    if (idx === index) {
                                                        items = items.map((item, j) => {
                                                            return (j === i) ? {
                                                                ...item,
                                                                ...{
                                                                    average_price: e.target.value,
                                                                    price: (item.amount && e.target.value) ? (item.amount * e.target.value).toFixed(2) : ''
                                                                }
                                                            } : item
                                                        })
                                                    }

                                                    return {
                                                        ...lot,
                                                        ...{
                                                            items: items,
                                                            price: items.reduce(function (sum, item) {
                                                                return sum + parseFloat(item.price)
                                                            }, 0) ?? ''
                                                        }
                                                    }
                                                })

                                                setValues({
                                                    ...values,
                                                    lots: lots,
                                                    price: lots.reduce(function (sum, lot) {
                                                        return sum + parseFloat(lot.price)
                                                    }, 0) ?? ''
                                                })
                                            },
                                        }}
                                        endAdornment={currency ? <InputAdornment position="end">{currencies.find(el => el.id === currency).code === 'RUB' ? '₽' : '₸'}</InputAdornment> : null}
                                        component={CustomTextField}
                                    />
                                </Grid>
                                {!values?.is_unit_price ? <Grid item xs={2} className={classes.column}>
                                    <Field
                                        fullWidth
                                        name={`lots.${index}.items.${i}.price`}
                                        type="number"
                                        step={0.01}
                                        label="Сумма"
                                        inputProps={{
                                            min: 0
                                        }}
                                        disabled
                                        endAdornment={currency ? <InputAdornment position="end">{currencies.find(el => el.id === currency).code === 'RUB' ? '₽' : '₸'}</InputAdornment> : null}
                                        component={CustomTextField}
                                    />
                                </Grid> : null}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <IconButton
                        size={"small"}
                        onClick={remove}
                    >
                        <Clear/>
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    )
}
