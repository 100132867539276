import {MarketplaceService} from "../services/marketplace"

export const MarketplaceActions = {
    items,
    positions
}

function items(params= {}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "MARKETPLACE_ITEMS_REQUEST"})

        MarketplaceService.items(params)
            .then(
                items => {
                    dispatch({
                        type: "MARKETPLACE_ITEMS_SUCCESS",
                        payload: {
                            data: items.data,
                            meta: items.meta,
                            category: (params.hasOwnProperty('category_id') ? params.category_id : null),
                            filter_values: items.filter_values,
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "MARKETPLACE_ITEMS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    } })
                    reject()
                }
            )
    })
}

function positions(id, params= {}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "MARKETPLACE_ITEM_POSITIONS_REQUEST"})

        MarketplaceService.positions(id, params)
            .then(
                positions => {
                    dispatch({type: "MARKETPLACE_ITEM_POSITIONS_SUCCESS", payload: positions})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "MARKETPLACE_ITEM_POSITIONS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    } })
                    reject()
                }
            )
    })
}
