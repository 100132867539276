import React, {useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles} from "@material-ui/core"

import {plan as rules} from "../../../helpers/permissions/rules"
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon";
import {FileCopy as FileCopyIcon} from "@material-ui/icons";
import {Form, Formik, getIn} from "formik";
import * as Yup from "yup";
import {PurchasePlanActions} from "../../../actions/purchasePlan";
import {Submit} from "../../../../App/components/Button/Submit";
import {ScheduleActions} from "../../../actions/schedule";
import {AsyncAutocomplete} from "../../../../App/components/Inputs/AsyncAutocomplete";

const useStyles = makeStyles(() => ({
    button: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    }
}))

export const Copy = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [dialog, setDialog] = useState(false)

    const { plan, onClick, disabled = false } = props

    const {account} = useSelector(state => state.account)

    return  (
        <Grid item>
            <ButtonIcon
                disabled={disabled || !rules.copy.includes(account.group)}
                onClick={() => setDialog(true)}
                title='Копировать'
                children={<FileCopyIcon/>}
                className={classes.button}
            />
            {dialog &&
                <Dialog
                    open={!!dialog}
                    onClose={() => { setDialog(false) }}
                    fullWidth={true}
                    maxWidth={'sm'}
                >
                    <DialogTitle>Копирование ПЗ</DialogTitle>
                    <Formik
                        initialValues={{
                            schedule: '',
                        }}
                        validationSchema={Yup.object().shape({
                            schedule: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
                        })}
                        onSubmit={(values, {setSubmitting}) => {
                            values = {
                                schedule: values.schedule.id
                            }

                            return dispatch(PurchasePlanActions.copyPlan(plan.id, values)).then(
                                () => {
                                    setDialog(false)
                                    onClick()
                                },
                                () => {
                                    setSubmitting(false)
                                }
                            )
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              isSubmitting,
                              isValid,
                              setFieldValue,
                              setFieldTouched,
                          }) => (
                            <Form>
                                <DialogContent>
                                    <Grid container direction="row" justify="center" alignItems="stretch" spacing={2}>
                                        <Grid item xs={10}>
                                            <AsyncAutocomplete
                                                value={values.schedule}
                                                name={"schedule"}
                                                getOptionLabel={option => option.name}
                                                label={"Общий план-график *"}
                                                getValues={(search) => ScheduleActions.get({
                                                    limit: 15,
                                                    page: 1,
                                                    company: plan.company.id,
                                                    categories: plan.category.id,
                                                    ...(search ? {search: search} : {})
                                                })}
                                                onChange={async (e, value) => {
                                                    await setFieldValue('schedule', value ?? '')
                                                }}
                                                condition={true}
                                                setTouched={setFieldTouched}
                                                error={getIn(touched, 'schedule') && getIn(errors, 'schedule')}
                                                disabled={isSubmitting}
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Grid container direction="row" justify="flex-end"
                                          alignItems="stretch">
                                        <Grid item>
                                            <Submit
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={isSubmitting || !isValid}
                                            >
                                                Копировать
                                            </Submit>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </Dialog>
            }
        </Grid>
    )
}
