import {StandardService} from '../services/standard'

export const StandardActions = {
    dictionary,
    attributes,
    standard,
    allow,
    strategic,
    register
}

function dictionary (type, params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'STANDARD_DICTIONARY_ITEMS_REQUEST' })

        StandardService.dictionary(type, params)
            .then(
                items => {
                    dispatch({ type: 'STANDARD_DICTIONARY_ITEMS_SUCCESS', payload: items })
                    resolve(items)
                },
                error => {
                    dispatch({ type: 'STANDARD_DICTIONARY_ITEMS_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function attributes (id, params = {}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'STANDARD_ATTRIBUTE_ITEMS_REQUEST' })

        StandardService.attributes(id, params)
            .then(
                items => {
                    dispatch({ type: 'STANDARD_ATTRIBUTE_ITEMS_SUCCESS', payload: items })
                    resolve()
                },
                error => {
                    dispatch({ type: 'STANDARD_ATTRIBUTE_ITEMS_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function standard (formData) {
    return dispatch => new Promise((resolve, reject) => {
        StandardService.standard(formData)
            .then(
                standard => {
                    dispatch({
                        type: "ALERT_SUCCESS", payload: {
                            message:'Эталон добавлен.',
                            type:'success'
                        }
                    })
                    resolve(standard)
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: {
                            message: error.message,
                            type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function allow (values) {
    return dispatch => new Promise((resolve, reject) => {
        StandardService.allow(values)
            .then(
                () => {
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Эталоны успешно допущены',
                            type:'success'
                        }})
                    resolve()
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function strategic (values) {
    return dispatch => new Promise((resolve, reject) => {
        StandardService.strategic(values)
            .then(
                () => {
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                            message:'Эталоны успешно отмечены как стратегически важные',
                            type:'success'
                        }})
                    resolve()
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}


function register (params) {
    return dispatch => new Promise((resolve, reject) => {
        StandardService.register(params)
            .then(
                (response) => {
                    resolve(response)
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}
