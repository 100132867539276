export default function standard (state = { dictionaries: [], attributes: [] }, action) {
    switch (action.type) {
        case 'STANDARD_DICTIONARY_ITEMS_REQUEST':
            return state
        case 'STANDARD_DICTIONARY_ITEMS_SUCCESS':
            return {
                ...state,
                ...{dictionaries: action.payload}
            }
        case 'STANDARD_DICTIONARY_ITEMS_FAILURE':
            return {
                ...state,
                ...{dictionaries: []}
            }
        case 'STANDARD_DICTIONARY_ITEMS_CLEAR':
            return {
                ...state,
                ...{dictionaries: []}
            }
        case 'STANDARD_ATTRIBUTE_ITEMS_REQUEST':
            return state
        case 'STANDARD_ATTRIBUTE_ITEMS_SUCCESS':
            return {
                ...state,
                ...{attributes: action.payload}
            }
        case 'STANDARD_ATTRIBUTE_ITEMS_FAILURE':
            return {
                ...state,
                ...{attributes: []}
            }
        case 'STANDARD_ATTRIBUTE_ITEMS_CLEAR':
            return {
                ...state,
                ...{attributes: []}
            }
        default:
            return state
    }
}
