import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"

import {Grid, makeStyles, Paper} from "@material-ui/core"
import {DashboardActions} from "../../actions/dashboard";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 110px)"
    },
    inputContent: {
        "width": "100%",
        "height": "100%",
        "min-height": "100%"
    },
}))

export const Content = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {dashboard} = props

    const [token, setToken] = useState(null)

    useEffect(() => {
        const getToken = async () => {
            return await dispatch(DashboardActions.token({
                resource: { dashboard: dashboard },
                params: {}
            }))
        }

        getToken().then(data => setToken(data))

    }, [dispatch, dashboard])

    return <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.fullWidth}>
                        <Grid container className={classes.container} direction="column" justify="space-between" alignItems="stretch">
                            <Grid item className={classes.inputContent}>
                                {token &&
                                    <iframe
                                        title="dashboard"
                                        src={process.env.REACT_APP_METABASE_URL + "/embed/dashboard/" + token + "#theme=transparent&bordered=false&titled=false"}
                                        frameBorder="0"
                                        width="100%"
                                        height="100%"
                                        allowTransparency
                                    >
                                    </iframe>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
}
