import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core"

import {endings} from "../../../App/helpers/endings"
import {ProductsActions} from "../../actions/prices"
import {Submit} from "../../../App/components/Button/Submit"

const useStyles = makeStyles(() => ({
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    content: {
        "padding": "0 !important"
    },
    footer: {
        "background-color": "#e7eaee",
        "justify-content": "flex-start"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    }
}))

const columns = [
    {
        id: '0',
        label: 'Прайс-лист',
        format: (value) => value.toLocaleString(),
    },
    {
        id: '1',
        label: 'Загружено',
        format: (value) => value.toLocaleString(),
    }
];

export const Prices = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {priceLists}  = useSelector(state => state.discountPrices)
    const {setPrices, prices} = props
    const { account } = useSelector(state => state.account)

    const [open, setOpen] = useState(props.open);
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [page, setPage] = useState(0)
    const containerRef = useRef(null)

    const [selected, setSelected] = React.useState([])
    const isSelected = (price) => (selected.find(item => (item.id === price.id)) !== undefined)

    useEffect(() => {
        if (props.open) {
            const getPrices = async () => {
                let params = {
                    limit: rowsPerPage,
                    page: page + 1,
                    companyId: account.company.id,
                    status: 'done',
                    type: 'public',
                }
                await dispatch(ProductsActions.prices(params))
            }

            getPrices().then(_ => {
                containerRef.current && containerRef.current.scrollTo({ behavior: 'smooth', top: 0 })
                setOpen(props.open)
            })
        } else {
            setSelected([])
            setOpen(props.open)
        }
    }, [dispatch, props.open, rowsPerPage, page, account.company.id])

    useEffect(() => {
        if (open) {
            setSelected(prices)
        }
        props.setDialog(open)
    }, [open, prices, props])

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelected(priceLists.data.map((price) => price))
        } else {
            setSelected([])
        }
    };

    const handleSelect = (event, price) => {
        const selectedIndex = selected.find(item => (item.id === price.id))
        let newSelected = []

        if (selectedIndex === undefined) {
            newSelected = newSelected.concat(selected, price)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
        }

        setSelected(newSelected)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const getName = (price) => {
        let name

        switch (price.download_type) {
            case 'file':
                const files = JSON.parse(price.file_names);
                name = files && files.length > 1 ? files.length + ' ' + endings(files.length, ['файл', 'файла', 'файлов']) : files[0];
                break
            default:
                name = price.tasks[0].url
        }

        return name
    }

    return (
        <Grid item>
            <Dialog
                open={!!open}
                onClose={() => { setOpen(false) }}
                fullWidth={true}
                maxWidth={'xl'}
            >
                <DialogTitle>Прайс-листы</DialogTitle>
                <DialogContent ref={containerRef} className={classes.content}>
                    <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                        <Grid item className={classes.header}>
                        </Grid>
                        <Grid item>
                            <TableContainer className={classes.tableContainer}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    indeterminate={selected.length > 0 && selected.length < priceLists.meta.total}
                                                    checked={priceLists.meta.total > 0 && selected.length === priceLists.meta.total}
                                                    onChange={handleSelectAll}
                                                />
                                            </TableCell>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {priceLists && priceLists.hasOwnProperty('data') && priceLists.data.map((price, index) => {
                                            const isItemSelected = isSelected(price);

                                            return (
                                                <TableRow
                                                    onClick={(event) => handleSelect(event, price)}
                                                    className={classes.tableRow}
                                                    hover key={index}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {getName(price)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {price.rows_success}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.footer}>
                    <Grid container className={classes.footerContainer} direction="row" justify="space-between" alignItems="center" spacing={2}>
                        <Grid item>
                            <TablePagination
                                rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                component='div'
                                labelRowsPerPage={'Записей на странице:'}
                                count={(priceLists && priceLists.hasOwnProperty('meta')) ? priceLists.meta.total : 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" justify="flex-end" alignItems="stretch" spacing={2}>
                                <Grid item>
                                    <Submit
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={() => {
                                            setSelected([])
                                            setOpen(false)
                                        }}
                                    >
                                        Отмена
                                    </Submit>
                                </Grid>
                                <Grid item>
                                    <Submit
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={!selected.length}
                                        onClick={() => {
                                            setPrices(selected)
                                            setSelected([])
                                            setOpen(false)
                                        }}
                                    >
                                        Выбрать
                                    </Submit>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </DialogActions>
            </Dialog>
        </Grid>
    )
}
