import {OperatorService} from "../services/operator"

export const OperatorActions = {
    lots,
    confirmed,
    notConfirmed,
}

function lots(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "OPERATOR_LOTS_REQUEST"})

        OperatorService.lots(params)
            .then(
                lots => {
                    dispatch({type: "OPERATOR_LOTS_SUCCESS", payload: {data: lots.data, meta: lots.meta}})
                    dispatch({type: "LOADING", payload: false})
                    resolve(lots)
                },
                error => {
                    dispatch({type: "OPERATOR_LOTS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function confirmed(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "LOT_CONFIRMED_REQUEST"})

        OperatorService.confirmed(id)
            .then(
                lot => {
                    dispatch({type: "LOT_CONFIRMED_SUCCESS", payload: lot})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                        message:'Оферта успешно согласована',
                        type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                },
                error => {
                    dispatch({type: "LOT_CONFIRMED_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                        message:'Не удалось согласовать оферту',
                        type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}

function notConfirmed(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "LOT_NOT_CONFIRMED_REQUEST"})

        OperatorService.notConfirmed(id)
            .then(
                lot => {
                    dispatch({type: "LOT_NOT_CONFIRMED_SUCCESS", payload: lot})
                    dispatch({ type: "ALERT_SUCCESS", payload: {
                        message:'Оферта успешно согласована',
                        type:'success'
                        }
                    })
                    dispatch({type: "LOADING", payload: false})
                },
                error => {
                    dispatch({type: "LOT_NOT_CONFIRMED_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                        message:'Не удалось согласовать оферту',
                        type:'error'
                        }
                    })
                    reject(error)
                }
            )
    })
}
