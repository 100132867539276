import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, FieldArray, Form, Formik} from "formik"
import format from "date-fns/format"
import {ru} from "date-fns/locale"

import {Grid, InputAdornment, makeStyles, TextField, withStyles} from "@material-ui/core"
import {Search as SearchIcon} from "@material-ui/icons"

import {TextFieldWithError as CustomTextField} from "../../../../App/components/Inputs/TextFieldWithError"
import {Submit} from "../../../../App/components/Button/Submit"
import {EmployeeActions} from "../../../../Company/actions/employee"
import {getValidationSchema} from "./validation/employees"
import {useDebounce} from "use-debounce";
import {ColumnChooser} from "../../../../App/components/Table/ColumnChooser";
import {CustomTable} from "../../../../App/components/Table/Table";
import {TableHeader} from "../../../../App/components/Table/TableHeader";
import {Table} from "@devexpress/dx-react-grid-material-ui";

const useStyles = makeStyles(() => ({
    tabForm: {
        "width": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 298px)",
        "padding": "0 30px 10px"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    search: {
        "padding": "20px 30px 0"
    },
    fullWidth: {
        maxWidth: '100%',
    },
    column: {
        "height": "86px"
    },
    container: {
        "padding": "0 30px 10px"
    },
    footerContainerForm: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 349px)",
        "min-height": "calc(100vh- 349px)"
    },
}))

const OutlinedTextField = withStyles({
    root: {
        "background-color": "#fff",
        "margin-top": "5px !important",
        "& .MuiInputLabel-outlined": {
            "transform": "translate(14px, 9px) scale(1)"
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            "transform": "translate(14px, -6px) scale(0.75)"
        },
        "& .MuiOutlinedInput-input": {
            "background": "#ffffff",
            "padding": "8px 15px 8px 15px"
        },
        "& .MuiOutlinedInput-root": {
            "border-radius": "0",
            '& fieldset': {
                "border": "solid 2px #c1c1c1"
            },
            '&:hover fieldset': {
                "border": "solid 2px #c1c1c1"
            }
        }
    }
})(TextField);

export const Employees = (props) => {
    const dispatch = useDispatch()
    const {company, setLoading} = props
    const classes = useStyles()
    const {employees} = useSelector(state => state.employee)
    const [tableRef, setTableRef] = useState(null)
    const [list, setList] = useState(true)
    const [employee, setEmployee] = useState(null)
    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)
    const {filter} = useSelector(state => state.filters['employees'])

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(50)

    const [columns, setColumns] = useState([
        {name: 'full_name', title: 'ФИО'},
        {name: 'email', title: 'Электронная почта'},
        {name: 'phone', title: 'Номер телефона'},
        {name: 'position', title: 'Должность'},
        {name: 'created_at', title: 'Дата создания'},
    ])

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'full_name', width: 200},
        {columnName: 'email', width: 150},
        {columnName: 'phone', width: 150},
        {columnName: 'position', width: 150},
        {columnName: 'created_at', width: 250},
    ])

    const [columnOrder, setColumnOrder] = useState([
        'full_name',
        'email',
        'phone',
        'position',
        'created_at',
        'button',
    ])

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...(searchRequest ? {search: searchRequest} : {}),
        }
    }

    const getOrders = async () => {
        return await dispatch(EmployeeActions.employees({
            ...{
                limit: rowsPerPage,
                page: page
            },
            ...getParams()
        }))
    }

    useEffect(() => {
        getOrders().then((r) => {})
    }, [dispatch, page, rowsPerPage, filter, searchRequest]);

    const handlePagesSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: 'EMPLOYEES_FILTER', payload: {limitRows: newRowsPerPage}})
    }

    return list
        ? (
            <Grid item className={classes.fullWidth}>
                <Grid item className={classes.search}>
                    <OutlinedTextField
                        id="search"
                        variant="outlined"
                        name={'search'}
                        label='Поиск'
                        fullWidth={true}
                        margin={'normal'}
                        onChange={(event) => {
                            const value = event.target.value

                            setSearch(value.length ? value : null)
                        }}
                        classes={{
                            root: classes.searchLabel
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon style={{color: '#c1c1c1'}}/>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item className={classes.container}>
                    <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                        <Grid item>
                            <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                <Grid item>
                                    <Submit
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        onClick={() => {
                                            setList(false)
                                        }}
                                    >
                                        Добавить
                                    </Submit>
                                </Grid>
                                <Grid item style={{marginTop: '-4px'}}>
                                    <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="column" justify="space-between" alignItems="stretch">
                    <CustomTable
                        meta={employees.meta}
                        tableName={'employees'}
                        rows={employees?.data.map(employee => ({
                            item: employee,
                            full_name: `${employee['last_name']} ${employee['first_name']} ${employee['middle_name']}`,
                            email: employee.email,
                            position: employee.position,
                            phone: employee.phones !== null ? employee.phones[0].number : null,
                            created_at: <>{format(new Date(employee.created), 'dd.MM.yyyy HH:mm')}<br/>(мск {format(new Date(new Date(employee.created).toLocaleString("en-US", {timeZone: "Europe/Moscow"})), 'dd.MM.yyyy HH:mm', {locale: ru})})</>
                        }))
                        }
                        columns={columns}
                        page={{
                            page: page,
                            setPage: setPage,
                            rowsPerPage: rowsPerPage,
                            handlePageSizeChange: handlePagesSizeChange
                        }}
                        setTableRef={setTableRef}
                        columnsSettings={{
                            columnOrder: columnOrder,
                            setColumnOrder: setColumnOrder,
                            setColumnWidths: setColumnWidths,
                            columnWidths: columnWidths,
                            hiddenColumnNames: hiddenColumnNames,
                            setHiddenColumnNames: setHiddenColumnNames
                        }}
                        tableHeader={TableHeader}
                        filterActionType={'EMPLOYEES_FILTER'}
                        classInputContent={classes.inputContent}
                        rowComponent={({row, tableRow, children}) => (
                            <Table.Row
                                tableRow={tableRow}
                                className={classes.tableRow}
                                children={children}
                                row={row}
                                hover
                                onClick={() => {
                                    setEmployee(row.item)
                                    setList(false)
                                }}
                            />
                        )}
                        filters={{}}
                    />
                </Grid>
            </Grid>
        )
        : (
            <Grid item>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        last_name: employee ? employee.last_name : '',
                        first_name: employee ? employee.first_name : '',
                        middle_name: employee ? (employee.middle_name ?? '') : '',
                        login: employee ? employee.login : '',
                        email: employee ? (employee.email ?? '') : '',
                        skype: employee ? (employee.skype ?? '') : '',
                        phones: employee?.phones ? employee?.phones.map(phone => {
                            return {number: phone?.number ?? '', extra: phone?.extra ?? ''}
                        }) : [{number: '', extra: null}],
                        position: employee ? (employee.position ?? '') : '',
                        password: '',
                        confirmation: ''
                    }}
                    validationSchema={getValidationSchema(employee)}
                    onSubmit={(values, {setSubmitting, setErrors}) => {
                        dispatch(employee ? EmployeeActions.edit(company.id, employee.id, values) : EmployeeActions.add(company.id, values)).then(
                            () => {
                                setLoading(false)
                                setSubmitting(false)
                            },
                            error => {
                                if (error.errors) {
                                    setErrors(error.errors)
                                }
                                setSubmitting(false);
                            }
                        )
                    }}
                >
                    {({
                          dirty,
                          isSubmitting,
                          isValid,
                          values,
                      }) => (
                        <Form>
                            <Grid className={classes.tabForm} container direction="column" justify="space-between" alignItems="stretch">
                                <Grid item className={classes.inputContent}>
                                    <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
                                        <Grid item className={classes.list}>
                                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="last_name"
                                                        type="text"
                                                        label="Фамилия"
                                                        required
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="first_name"
                                                        type="text"
                                                        label="Имя"
                                                        required
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="middle_name"
                                                        type="text"
                                                        label="Отчество"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.list}>
                                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="login"
                                                        type="text"
                                                        label="Имя пользователя"
                                                        required
                                                        disabled={employee?.length > 0}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="position"
                                                        type="text"
                                                        label="Должность"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.list}>
                                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="email"
                                                        type="email"
                                                        label="Адрес электронной почты"
                                                        required
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <FieldArray name="phones" render={() => (
                                                    <React.Fragment>
                                                        {values.phones.map((phone, index) => {
                                                            return (
                                                                <Grid key={index} item xs={4}
                                                                      className={classes.column}>
                                                                    <Field
                                                                        fullWidth
                                                                        name={`phones.${index}.number`}
                                                                        type="phone"
                                                                        label="Номер телефона"
                                                                        required
                                                                        component={CustomTextField}
                                                                    />
                                                                </Grid>
                                                            );
                                                        })}
                                                    </React.Fragment>
                                                )}/>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="skype"
                                                        type="string"
                                                        label="Skype"
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.list}>
                                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="password"
                                                        type="password"
                                                        label="Пароль"
                                                        required={!employee}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.column}>
                                                    <Field
                                                        fullWidth
                                                        name="confirmation"
                                                        type="password"
                                                        label="Подтверждение пароля"
                                                        required={!employee}
                                                        component={CustomTextField}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.footer}>
                                <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                    <Grid item className={classes.footerContent}>
                                        <Grid container className={classes.footerContainerForm} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                            <Grid item>
                                                <Submit
                                                    disableElevation
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    onClick={() => {
                                                        setEmployee(null)
                                                        setList(true)
                                                    }}
                                                >
                                                    Отмена
                                                </Submit>
                                            </Grid>
                                            <Grid item>
                                                <Submit
                                                    disableElevation
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={isSubmitting || !isValid || !dirty}
                                                >
                                                    {employee ? 'Сохранить' : 'Добавить'}
                                                </Submit>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Grid>
        )
}
