import React from "react"
import {useSelector} from "react-redux"

import {Grid, makeStyles} from "@material-ui/core"

import {Wave} from "./components/Loading/Wave"

const useStyles = makeStyles(() => ({
    root: {
        "height": "100%",
        "width": "100%",
        "display": "flex",
        "z-index": 1600,
        "position": "fixed",
        "opacity": 0.7,
        "background": "#f1f1f1",
        "--sk-color": "#898989"
    }
}))

export const Loading = () => {
    const classes = useStyles()
    const {loading, filling} = useSelector(state => state.application)

    if (loading || filling) {
        return (
            <Grid item className={classes.root}>
                <Grid container alignItems="center" direction="column" justify="center">
                    <Grid item>
                        <Wave/>
                    </Grid>
                </Grid>
            </Grid>
        )
    } else {
        return null
    }
}
