import React from "react"
import {useSelector} from "react-redux"
import {Link} from "react-router-dom"

import {List, ListItem, ListItemText, makeStyles, withStyles} from '@material-ui/core'
import {AuthorizationService} from "../Auth/services/authorization"

const useStyles = makeStyles(() => ({
    active: {
        "background-image": "none !important",
        "background-color": "#898989"
    }
}))

const CustomList = withStyles({
    root: {
        "padding": "0",
        "& .MuiListItem-root": {
            "background-image": "linear-gradient(to bottom,#e4e4e4,#f4f4f4)",
            "align-items": "center",
            "min-height": "50px",
            "padding": "5px 25px 0",
            "border-bottom": "1px solid #e4e4e4",
            "outline": "0"
        },
        "& .MuiListItemIcon-root": {
            "min-width": "30px"
        },
        "& .MuiListItem-button:hover": {
            "background-color": "#898989",
            "&:after": {
                "position": "absolute",
                "content": "''",
                "top": "0",
                "left": "0",
                "width": "100%",
                "height": "100%",
                "background-color": "rgba(0,0,0,.04)"
            }
        },
        "& .MuiListItemText-secondary": {
            "color": "#485868",
            "font-size": "15px"
        },
        "& .MuiListItemText-primary": {
            "font-size": "16px",
            "font-weight": "700",
            "text-transform": "uppercase"
        }
    }
})(List);

export const Menu = (props) => {
    const classes = useStyles()
    const {location} = props

    const {account} = useSelector(state => {return {...state.account, ...state.deliveryStatus, ...state.delivery,...state.delivery.orders}})

    return (
        <React.Fragment>
            {AuthorizationService.permissions(account, ['change_delivery_order_status']) &&
                <CustomList>
                    <ListItem
                        button
                        component={Link}
                        to={'/delivery/order/change/status'}
                        classes={{root: location.pathname.match(new RegExp('/delivery/order')) ? classes.active : undefined}}
                    >
                        <ListItemText primary="Изменение статуса" />
                    </ListItem>
                </CustomList>
            }
            {AuthorizationService.roles(account, ['customer']) &&
                <CustomList>
                    <ListItem
                        button
                        component={Link}
                        to={'/delivery/auto/orders'}
                        classes={{root: location.pathname.match(new RegExp('/delivery/auto')) ? classes.active : undefined}}
                    >
                        <ListItemText primary="Автозаказ" />
                    </ListItem>
                </CustomList>
            }
            {AuthorizationService.roles(account, ['customer', 'supplier']) &&
                <CustomList>
                    <ListItem
                        button
                        component={Link}
                        to={'/delivery/orders'}
                        classes={{root: (location.pathname === '/delivery/orders') ? classes.active : undefined}}
                    >
                        <ListItemText primary="Заказы" />
                    </ListItem>
                </CustomList>
            }
        </React.Fragment>
    )
}
