export default function company(state = {}, action) {
    switch (action.type) {
        case "COMPANY_REQUEST":
            return state
        case "COMPANY":
            return {
                ...state,
                ...{company: action.payload}
            }
        case "COMPANY_FAILURE":
            return {}
        case "COMPANY_CREATE_REQUEST":
            return state
        case "COMPANY_CREATE_SUCCESS":
            return state
        case "COMPANY_CREATE_FAILURE":
            return state
        case "COMPANY_EDIT_ACTIVE_REQUEST":
            return state
        case "COMPANY_EDIT_ACTIVE_SUCCESS":
            return state
        case "COMPANY_EDIT_ACTIVE_FAILURE":
            return state
        case "COMPANY_EDIT_CERTIFIED_REQUEST":
            return state
        case "COMPANY_EDIT_CERTIFIED_SUCCESS":
            return state
        case "COMPANY_EDIT_CERTIFIED_FAILURE":
            return state
        case "COMPANIES_REQUEST":
            return state
        case "COMPANIES":
            return {
                ...state,
                ...{companies: action.payload}
            }
        case "COMPANIES_FAILURE":
            return state
        case "COMPANY_OCCUPATIONS_REQUEST":
            return state
        case "COMPANY_OCCUPATIONS":
            return {
                ...state,
                ...{occupations: action.payload}
            }
        case "COMPANY_OCCUPATIONS_FAILURE":
            return state
        case "COMPANY_LEGAL_DETAIL_NAME":
            return {
                ...state,
                ...{company: {...state.company, ...{legal_detail: {...state.company.legal_detail, ...{name: action.payload}}}}}
            }
        case "COMPANY_LEGAL_DETAIL_REQUEST":
            return state
        case "COMPANY_LEGAL_DETAIL_SUCCESS":
            return state
        case "COMPANY_LEGAL_DETAIL_FAILURE":
            return state
        case "COMPANY_TERMS_PARTICIPATION_REQUEST":
            return state
        case "COMPANY_TERMS_PARTICIPATION_SUCCESS":
            return state
        case "COMPANY_TERMS_PARTICIPATION_FAILURE":
            return state
        case "COMPANY_ADD_CONTENT_SUCCESS":
            return {
                ...state,
                ...{company: {
                    ...state.company,
                    ...{contents: {
                        ...state.company.contents,
                        ...{
                            ...state.company.contents,
                            ...{[action.payload.type.key]: [...(state.company.contents.hasOwnProperty(action.payload.type.key) ? state.company.contents[action.payload.type.key] : []),...[action.payload]]}
                        }
                    }}
                }}
            }
        case "COMPANY_DELETE_CONTENT_SUCCESS":
            let contents = {}

            Object.keys(state.company.contents).forEach(key =>
                contents[key] = state.company.contents[key].filter(file => (file.id !== action.payload))
            )

            return {
                ...state,
                ...{company: {
                    ...state.company,
                    ...{contents: contents}
                }}
            }
        case "COMPANY_OKVED_REQUEST":
            return state
        case "COMPANY_OKVED_SUCCESS":
            return {
                ...state,
                ...{okved: action.payload}
            }
        case "COMPANY_OKVED_FAILURE":
            return {
                ...state,
                ...{okved: []}
            }
        default:
            return state
    }
}
