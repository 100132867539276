import {DownloadService} from "../services/download"

export const DownloadActions = {
    files,
    create
}

function files(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        DownloadService.files(params)
            .then(
                files => {
                    dispatch({type: "DOWNLOAD_FILES"})
                    dispatch({type: "LOADING", payload: false})
                    resolve(files)
                },
                error => {
                    dispatch({type: "DOWNLOAD_FILES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({ type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )
    })
}

function create (values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'DOWNLOAD_FILE_CREATE_REQUEST' })

        DownloadService.create(values)
            .then(
                () => {
                    dispatch({ type: 'DOWNLOAD_FILE_CREATE_SUCCESS' })
                    dispatch({ type: 'ALERT_SUCCESS', payload: {
                        message: 'Файл добавлен в диспетчер загрузок' ,
                        type: 'success'
                    }})
                    dispatch({ type: 'FILLING', payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: 'DOWNLOAD_FILE_CREATE_FAILURE' })
                    if (!error.type) {
                        dispatch({ type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    }
                    dispatch({ type: 'FILLING', payload: false })
                    reject(error)
                }
            )
    })
}
