import {HttpService} from "../../App/services/http"

export const MarketplaceService = {
    items,
    positions
}

function items(params) {
    return HttpService.get("/marketplace/items", params)
}

function positions(id, params) {
    return HttpService.get(`/marketplace/item/${id}/positions`, params)
        .then(response => {
            return response.positions
        })
}
