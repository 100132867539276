import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"

import {Dialog, DialogContent, DialogTitle, makeStyles} from "@material-ui/core"
import {Close as CloseIcon} from "@material-ui/icons"

import {ProductList} from "../../Price/components/ProductList";
import {PriceActions} from "../../Price/actions/price";
import {getPriceName} from "../../App/helpers/name";

const useStyles = makeStyles(() => ({
    title: {
        "margin": "0",
        "padding-top": "2px",
        "font-size": "18px",
        "font-weight": "600",
        "color": "#fff",
        "text-transform": "uppercase",
        "display": "flex",
        "justify-content": "space-between",
        "align-items": "center",
        "min-height": "40px",
        "padding": "0 20px",
        "background-color": "#898989",
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    dialogContent: {
        padding: "0",
        "overflow": "hidden",
    },
    dialogContainer: {
        "& > div": {
            "max-width": "calc(100vw - 200px)",
            "min-height": "calc(100vh - 300px)",
            overflow: "hidden",
        }

    }
}));

export const EditPriceDialog = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {open, onClose, contract} = props
    const { categories } = useSelector(state => state.system)


    const getPrice = async () => {
        return await dispatch(PriceActions.getById(contract.id, contract.price_id))
    }


    useEffect(() => {
        getPrice().then(resp => {
            dispatch({type: "SET_ACTIVE_PRICE", payload: {
                    priceId: resp.price.id,
                    type: resp.price.type.name,
                    price_token: resp.price.price_token,
                    company_id: resp.price.company_id,
                    in_process: Boolean(resp.price.status === 'in_process'),
                    name: getPriceName(resp.price),
                    category: resp.category
                }})
        })
        // eslint-disable-next-line
    }, [dispatch])


    return (
        <Dialog aria-labelledby="simple-dialog-title" open={open} classes={{
            root: classes.dialog,
            container: classes.dialogContainer
        }} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle id="simple-dialog-title" classes={{
                root: classes.title,
            }} disableTypography={true}>Редактирование прайс-листа
                <CloseIcon className={classes.close} onClick={onClose} />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <ProductList categories={categories} priceId={contract.price_id} open={open} withContract={true}/>
            </DialogContent>
        </Dialog>
    )
}
