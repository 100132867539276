import React from 'react'
import classNames from "clsx"

import {makeStyles} from "@material-ui/core"

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '& .MuiTableCell-root': {
            padding: '6px'
        }
    },
}))

export const Root = ({children, className, ...restProps}) => {
    const classes = useStyles()

    return <div
        className={classNames(classes.root, className)}
        {...restProps}
    >
        {children}
    </div>
}
