import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {Field, FieldArray, Form, Formik} from "formik"
import {CheckboxWithLabel, fieldToTextField} from "formik-material-ui"
import * as Yup from "yup"
import DateFnsUtils from "@date-io/date-fns"
import format from "date-fns/format"
import subDays from "date-fns/subDays"
import {ru} from "date-fns/locale"

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@material-ui/core"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"
import {Add as AddIcon, Clear, ExpandMore, GetApp as GetAppIcon, Help as HelpIcon} from "@material-ui/icons"

import {Submit} from "../../../../../App/components/Button/Submit"
import {ShopActions} from "../../../actions/shop"
import {CompanyActions} from "../../../../../Account/actions/company"
import {round} from "../../../../../App/helpers/number"
import {OfferActions} from "../../../actions/offer"
import {TextFieldWithError as CustomTextField} from "../../../../../App/components/Inputs/TextFieldWithError"
import {FileUpload} from "../../../../../App/components/Inputs/FileUpload"
import {ButtonIcon} from "../../../../../App/components/Button/ButtonIcon"
import {SettingsActions} from "../../../../../Settings/actions/settings"
import {Signature} from "../../../../../Crypto/Signature";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    accordionItem: {
        "border-top": "0 !important",
        "box-shadow": "0 0 0 !important",
        "border-bottom": "1px solid rgba(224, 224, 224, 1) !important",
        "border-color": "grey"
    },
    fullHeight: {
        "height": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    input: {
        "width": "100%",
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 250px)"
    },
    tabContent: {
        "width": "100%",
        "padding": "20px 29px !important"
    },
    itemInput: {
        "margin-top": "0 !important"
    },
    column: {
        "height": "86px"
    },
    block: {
        "height": "172px"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    catError: {
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "height": "35px",
        "font-size": "0.75rem",
        "color": "#f44336"
    },
    group: {
        "padding-bottom": "16px"
    },
    itemClose: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0 !important",
    },
    filesUpload: {
        "&>div>div>div": {
            flexWrap: "nowrap",
            "&>div:first-child": {
                minWidth: "170px"
            }
        }
    },
    button: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "#898989",
        },
        "width": "auto",
        "max-height": "30px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "30px",
        "font-size": "16px",
        "color": "#fff",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-bottom": "3px solid #898989",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "#616161",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px",
        }
    },
    tooltipOfferStandard: {
        "background-color": "#000",
        "font-size": "15px",
        "color": "#fff",
        "padding": "15px 15px",
    },
    error: {
        "height": "35px",
        "font-size": "0.75rem",
        "color": "#f44336"
    },
}))

export const Content = (props) => {
    let {lotId, offerId} = useParams()
    const dispatch = useDispatch()
    const classes = useStyles()
    const {lot, orderSettings} = useSelector(state => ({...state.lot, ...state.settings}))
    const {company} = useSelector(state => state.company)
    const {products} = useSelector(state => state.lotProducts)
    const [productsErrors, setProductsErrors] = useState({})
    const [dirty, setDirty] = useState(false)
    const [lotAveragePrice, setLotAveragePrice] = useState(0)
    const [itemsAveragePrice, setItemsAveragePrice] = useState(0)
    const [showError, setShowError] = useState(false)
    const [sign, setSign] = useState({open: false, files: []})

    useEffect(() => {
        const getSettings = async () => {
            return await dispatch(SettingsActions.order())
        }

        getSettings().then(r => {})
    }, [dispatch])

    const taxPercentNodes = [
        {key: -1, value: 'Без НДС'},
        {key: 0, value: '0'},
        {key: 10, value: '10%'},
        {key: 20, value: '20%'},
    ].map((obj) =>
        <MenuItem value={obj.key}>{obj.value}</MenuItem>
    )

    const getValidationSchema = (orderSettings) => {
        return {
            contract_time: Yup
                .string()
                .matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                .required("Поле не может быть пустым или состоять из одних пробелов!"),
            file: Yup.mixed().test('fileFormat', 'Ценовое предложение должно быть PDF файлом.', (value) => {
                return ((value && ['application/pdf'].includes(value.type)) || (value && !value.type && value.name) || (lot.status.index === 7));
            }),
            signature: Yup.string().nullable(),
            specification: Yup.mixed().test('fileFormat', 'Спецификация должна быть файлом одного из следующих типов: csv, xls, xlsx, jpg, jpeg, png, bmp, gif, svg, doc, docx, pdf.', (value) => {
                return ((value && (value instanceof File) && [
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-excel',
                    'text/csv',
                    'image/jpeg',
                    'image/gif',
                    'image/svg+xml',
                    'image/bmp',
                    'image/png',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/pdf',
                ].includes(value.type)) || !value || (value && !(value instanceof File)))
            }),
            rationale: Yup.mixed().nullable().test('fileFormat', 'Обоснование цены должно быть файлом одного из следующих типов: doc, docx, xls, xlsx, pdf.', (value) => {
                return (!value || (value && [
                    'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'
                ].includes(value.type)) || (value && !value.type && value.name));
            }),
            files: Yup
                .array()
                .of(Yup.object().shape({
                    file: Yup.mixed().test('fileFormat', 'Документ должен быть файлом одного из следующих типов: csv, xls, xlsx, jpg, jpeg, png, bmp, gif, svg, doc, docx, pdf.', (value) => {
                        return ((value && (value instanceof File) && [
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            'application/vnd.ms-excel',
                            'text/csv',
                            'image/jpeg',
                            'image/gif',
                            'image/svg+xml',
                            'image/bmp',
                            'image/png',
                            'application/msword',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            'application/pdf',
                        ].includes(value.type)) || !value || (value && !(value instanceof File)))
                    }),
                    description: Yup.mixed().when('file', {
                        is: null,
                        then: Yup.mixed().nullable(),
                        otherwise: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                    }),
                })).nullable(),
            items: Yup.array().of(Yup.object().shape({
                price: Yup.number().min(0, 'Цена не может быть отрицательной!').required("Поле не может быть пустым или состоять из одних пробелов!"),
                expiration_date: Yup.mixed().nullable(),
                expiration_date_number: Yup.mixed().nullable(),
                description: Yup.string(),
                name: Yup.string(),
                tax_percent: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
                min_ordered_quantity: Yup.number().min(0, 'Количество не может быть отрицательным  числом!').required("Поле не может быть пустым или состоять из одних пробелов!").test('max_min_ordered_quantity', 'Поле не должно превышать кол-во товара из ценового запроса!', function (value) {
                    return this.parent.amount >= value
                }),
                amount: Yup.number().min(0, 'Количество не может быть отрицательным  числом!'),
                totalPrice: Yup.number(),
            })).required("Поле не может быть пустым или состоять из одних пробелов!"),
            priceTotal: Yup.number().max(itemsAveragePrice, "Цена выше НМЦ").when('items', (items, schema) =>
                schema.transform(() =>
                    items.reduce(function (sum, item) {
                        return sum + parseFloat(item.price ? item.price : 0)
                    }, 0),
                ),
            ),
            priceLotTotal: Yup.number().when("rationale", (rationale, schema) => {
                return !rationale ? schema.min(lotAveragePrice * (1 - (orderSettings.max_diff_offer_price / 100)), "Цена ниже допустимой, прикрепите обоснование цены") : schema
            })
        }
    }
    const getValidateSchemaFull = (validateSchema) => {
        return {
            ...validateSchema,
            postponement: Yup
                .string()
                .matches(/^[0-9]+$/, 'Поле должно состоять только из цифр!')
                .required("Поле не может быть пустым или состоять из одних пробелов!")
        }
    }

    useEffect(() => {
        const getLot = async () => {
            return await dispatch(ShopActions.lot(lotId))
        }
        getLot().then(lot => {
            setDirty(true)
        })
        return () => dispatch({type: "LOT_CLEAR"})
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        const getCompany = async () => {
            return await dispatch(CompanyActions.company({
                include: "territories,legalDetail.city.region.country,legalDetail.executiveDirector,legalDetail.city.region.country,legalDetail.passportDetails,contactInformation,termsParticipation.regions.region,termsParticipation.files"
            }))
        }
        getCompany().then(r => {
        })
    }, [dispatch]);

    useEffect(() => {
        const getProducts = async (standards) => {
            return await dispatch(ShopActions.getProducts({
                company_id: company.id,
                customer_id: lot.order.company.id,
                ...(!!standards.length ? {standard_ids: standards} : {}),
            }))
        }
        if (lot) {
            setLotAveragePrice(lot.average_price)
            setItemsAveragePrice(lot.items.reduce(function (sum, item) {
                return sum + parseFloat(item.average_price ? item.average_price : 0)
            }, 0))
        }
        if (company && lot && dirty && !offerId) {
            const standards = lot.items.filter(item => item.standard?.id).map(item => item.standard?.id) ?? null

            if (standards) {
                getProducts(standards).then(resp => {
                    let errors = {
                        items: []
                    }

                    lot.items.forEach(function (item) {
                        let product = resp.find(el => el.standard?.id === item.standard?.id);
                        if (product) {
                            let error = {}
                            if (item.amount > product.stock_quantity) {
                                error['amount'] = "Недостаточно товара в наличии"
                            }
                            if (item.amount < product.min_ordered_quantity) {
                                error['amount'] = "Минимальное количество товара для заказа, превышает количество товара указанного в предложении"
                            }
                            if (product.price > item.price) {
                                error['price'] = "Цена выше НМЦД"
                            }
                            if (item.expiration_date && (subDays(new Date(item.expiration_date), product.expiration_date) <= 0)) {
                                error['expiration_date'] = "Срок годности не удовлетворяет условию лота"
                            }
                            errors['items'].push(error);
                        } else {
                            errors['items'].push({name: `Проверьте правильность заполнения полей "Цена за позицию" и "Срок годности"`})
                        }
                    })
                    setProductsErrors(errors)
                })
            }
        }
        return () => dispatch({type: "PRODUCTS_CLEAR"})
        // eslint-disable-next-line
    }, [dispatch, lot, company, dirty]);

    const getInitValues = (offerId, company, lot, products) => {
        return (offerId || lot.offers[0]) ? {
            lotId: lotId,
            contract_time: lot.offers[0].revisions[0].contract_time,
            postponement: lot.offers[0].revisions[0].postponement ? lot.offers[0].revisions[0].postponement : '',
            delivery: lot.offers[0].delivery ? lot.offers[0].delivery : null,
            items: lot && lot.items && lot.items.length > 0 ? lot.items.map(function (item) {
                let offerItem = lot.offers[0].items.find(oi => oi.item && (oi.item.id === item.id));
                return {
                    price: parseFloat((offerItem && offerItem.price) ? offerItem.price : 0),
                    expiration_date: (offerItem && offerItem.expiration_date) ? new Date(offerItem.expiration_date.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2-$1-$3")) : null,
                    expiration_date_number: (offerItem && offerItem.expiration_date_number) ? offerItem.expiration_date_number : '',
                    description: (offerItem && offerItem.description) ? offerItem.description : '',
                    product_id: (offerItem && offerItem.product_id) ? offerItem.product_id : null,
                    article_number: (offerItem && offerItem.article_number) ? offerItem.article_number : '',
                    name: (offerItem && offerItem.item && (offerItem.name !== offerItem.item.name)) ? offerItem.name : '',
                    id: offerItem ? offerItem.id : null,
                    lot_item_id: item.id,
                    amount: round(item.amount, 2),
                    tax_percent: offerItem ? offerItem.tax_percent : -1,
                    min_ordered_quantity: offerItem ? offerItem.min_ordered_quantity : 1,
                    price_discount: null,
                    totalPrice: parseFloat((offerItem && offerItem.price) ? offerItem.price : 0) * item.amount,
                }
            }) : [],
            file: offerId ? null : lot.offers[0].files.filter(file => (file.type && (file.type.key === 'price_offer'))).map(file => {
                return {
                    id: file.id,
                    name: file.name,
                    description: file.description
                }
            }).shift(),
            signature: null,
            specification: offerId ? null : lot.offers[0].files.filter(file => (file.type && (file.type.key === 'specification'))).map(file => {
                return {
                    id: file.id,
                    name: file.name,
                    description: file.description
                }
            }).shift(),
            rationale: offerId ? null : lot.offers[0].files.filter(file => (file.type && (file.type.key === 'price_rationale'))).map(file => {
                return {
                    id: file.id,
                    name: file.name,
                    description: file.description
                }
            }).shift(),
            last_price: lot.offers[0].last_price,
            priceTotal: lot.items.reduce(function (sum, item) {
                return sum + parseFloat(item.price ? item.price : 0)
            }, 0),
            priceLotTotal: lot.offers[0].items.reduce(function (sum, item) {
                return sum + parseFloat(item.price ? item.price : 0) * lot.items.find(el => el.id === item.item.id).amount
            }, 0),
            files: offerId ? [
                {
                    file: null,
                    description: ''
                }
            ] : lot.offers[0].files.filter(file => !file.type).map(file => {
                return {
                    file: {
                        id: file.id,
                        name: file.name
                    },
                    description: file.description
                }
            })
        } : {
            lotId: lotId,
            contract_time: '',
            postponement: '',
            delivery: company && company.terms_participation && company.terms_participation.regions.filter(el => el.region.id === lot.order.regions.id).length > 0 ? company.terms_participation.regions.filter(el => el.region.id === lot.order.regions.id)[0].term : null,
            items: (lot && lot.items && !!lot.items.length) ? lot.items.map(function (item) {
                let product = products.filter(el => el.standard?.id === item.standard?.id);
                return {
                    price: !!product.length ? Number(product[0].price) : '',
                    expiration_date: (!!product.length && product[0].expiration_date) ? new Date(product[0].expiration_date) : null,
                    expiration_date_number: '',
                    description: '',
                    product_id: !!product.length ? product.id : null,
                    article_number: !!product.length ? product.article_number : null,
                    name: !!product.length ? product.name : '',
                    lot_item_id: item.id,
                    tax_percent: product.length > 0 && product[0].tax_percent ? product[0].tax_percent : -1,
                    min_ordered_quantity: !!product.length && product[0].min_ordered_quantity ? product[0].min_ordered_quantity : 1,
                    amount: (item.amount || (item.amount === 0)) ? round(item.amount, 2) : '',
                    price_discount: (!!product.length && product.price_discount) ? product.price_discount : null,
                    totalPrice: !!product.length ? Number(product[0].price) * item.amount : 0,
                }
            }) : [],
            file: null,
            signature: null,
            specification: null,
            rationale: null,
            priceTotal: itemsAveragePrice,
            priceLotTotal: lot.items.reduce(function (sum, item) {
                let product = products.filter(el => el.standard?.id === item.standard?.id);
                return sum + parseFloat(product.length > 0 && product[0].price ? product[0].price : 0) * item.amount
            }, 0),
            files: [
                {
                    file: null,
                    description: ''
                }
            ],
            last_price: false,
        }
    }

    return (lot && orderSettings) ? (
            <Grid item className={classes.content}>
                <Paper>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item className={classes.full}>
                            <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                <Grid item className={classes.fullWidth}>
                                    <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                        <Grid item className={classes.header}>
                                            <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                                <Grid item className={classes.fullWidth}>
                                                    <Typography className={classes.title}>
                                                        {offerId ? `Редактирование предложения доступно до ${format(new Date(lot.end_time_of_rebidding), 'H:mm PP', {locale: ru})} (мск ${format(new Date(new Date(lot.end_time_of_rebidding).toLocaleString("en-US", {timeZone: "Europe/Moscow"})), 'H:mm PP', {locale: ru})})` : `Создать предложение`}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.fullWidth}>
                                    <Formik
                                        validateOnBlur={false}
                                        initialValues={getInitValues(offerId, company, lot, products)}
                                        validationSchema={Yup.object().shape(lot.postponement ? getValidateSchemaFull(getValidationSchema(orderSettings)) : getValidationSchema(orderSettings))}
                                        onSubmit={(values, {setSubmitting}) => {
                                            const formData = new FormData()

                                            for (const i in values) {
                                                if (values.hasOwnProperty(i)) {
                                                    switch (i) {
                                                        case 'items':
                                                            values[i].forEach((item, index) => {
                                                                for (const j in item) {
                                                                    if (item.hasOwnProperty(j)) {
                                                                        switch (j) {
                                                                            case 'expiration_date':
                                                                                if (item[j]) {
                                                                                    formData.append(`${i}[${index}][${j}]`, format(item[j], 'yyyy-MM-dd'))
                                                                                }
                                                                                break
                                                                            case 'expiration_date_number':
                                                                                if (item[j]) {
                                                                                    formData.append(`${i}[${index}][${j}]`, item[j])
                                                                                }
                                                                                break
                                                                            default:
                                                                                formData.append(`${i}[${index}][${j}]`, item[j] === null ? '' : item[j])
                                                                        }
                                                                    }
                                                                }
                                                            })
                                                            break
                                                        case 'files':
                                                            Array.from(values.files).forEach((el, index) => {
                                                                if (el.file && el.description) {
                                                                    if (el.file instanceof File) {
                                                                        formData.append(`files[${index}][file]`, el.file, el.file.name);
                                                                    } else {
                                                                        formData.append(`files[${index}][file][id]`, el.file.id);
                                                                    }
                                                                    formData.append(`files[${index}][description]`, el.description);
                                                                }
                                                            });
                                                            break
                                                        case 'file':
                                                            if (values.file instanceof File) {
                                                                formData.append(`file`, values.file, values.file.name);
                                                            } else if (values.file) {
                                                                formData.append(`file[id]`, values.file.id);
                                                            }
                                                            break
                                                        case 'signature':
                                                            formData.append(`signature`, values.signature ?? '');
                                                            break
                                                        case 'specification':
                                                            if (values.specification) {
                                                                if (values.specification instanceof File) {
                                                                    formData.append(`specification`, values.specification, values.specification.name);
                                                                } else {
                                                                    formData.append(`specification[id]`, values.specification.id);
                                                                }
                                                            }
                                                            break
                                                        case 'rationale':
                                                            if (values.rationale) {
                                                                if (values.rationale instanceof File) {
                                                                    formData.append(`rationale`, values.rationale, values.rationale.name);
                                                                } else {
                                                                    formData.append(`rationale[id]`, values.rationale.id);
                                                                }
                                                            }
                                                            break
                                                        default:
                                                            formData.append(i, values[i] === null ? '' : values[i])
                                                    }
                                                }
                                            }
                                            if (offerId && !lot.end_time_of_rebidding) {
                                                return dispatch({
                                                    type: "ALERT_ERROR", payload: {
                                                        message: "Редактирование предложения недоступно",
                                                        type: 'error'
                                                    }
                                                })
                                            }

                                            return dispatch(offerId ? ShopActions.edit(offerId, formData) : ShopActions.add(formData)).then(() => {
                                                setSubmitting(false);
                                            }, errors => {
                                                if (errors) {
                                                }
                                                setSubmitting(false);
                                            })
                                        }}
                                        validate={values => {
                                            const errors = {}

                                            const MAX_FILE_SIZE = 50 * 1024 * 1024;
                                            let factFilesSize = 0;

                                            for (const key in values) {
                                                switch (key) {
                                                    case 'file':
                                                    case 'specification':
                                                        if(values[key]?.size) {
                                                            factFilesSize += values[key].size
                                                        }
                                                        break;
                                                    case 'files':
                                                        values[key].forEach(file => {
                                                            if (file?.size) {
                                                                factFilesSize += file.size
                                                            }
                                                        })
                                                        break;
                                                    default:
                                                }
                                            }
                                            if (MAX_FILE_SIZE < factFilesSize) {
                                               errors.files = 'Размер файлов превышает 50мб.'
                                            }

                                            lot.items.forEach((item, index) => {

                                                if (!errors.hasOwnProperty('items')) {
                                                    errors.items = []
                                                }

                                                if (item.expiration_date) {
                                                    if (!values.items[index].expiration_date) {
                                                        errors.items[index] = {
                                                            expiration_date: 'Поле не может быть пустым или состоять из одних пробелов!'
                                                        }
                                                    } else {
                                                        const expiration_date = new Date(item.expiration_date.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2-$1-$3"))
                                                        let current = values.items[index].expiration_date
                                                        current.setHours(0, 0, 0, 0)

                                                        if (expiration_date > current) {
                                                            errors.items[index] = {
                                                                expiration_date: `Значение не может быть меньше ${format(expiration_date, 'dd.MM.yyyy')}`
                                                            }
                                                        }
                                                    }
                                                }

                                                if (item.expiration_date_number) {
                                                    if (!values.items[index].expiration_date_number) {
                                                        errors.items[index] = {
                                                            expiration_date_number: 'Поле не может быть пустым или состоять из одних пробелов!'
                                                        }
                                                    } else {
                                                        // eslint-disable-next-line default-case
                                                        switch (item.expiration_date_type) {
                                                            case 'warranty_period':
                                                                if (parseInt(item.expiration_date_number) > parseInt(values.items[index].expiration_date_number)) {
                                                                    errors.items[index] = {
                                                                        expiration_date_number: `Значение не может быть меньше ${parseInt(item.expiration_date_number)}`
                                                                    }
                                                                }
                                                                break
                                                            case 'expiration_date_percent':
                                                                if (parseFloat(item.expiration_date_number) > parseFloat(values.items[index].expiration_date_number)) {
                                                                    errors.items[index] = {
                                                                        expiration_date_number: `Значение не может быть меньше ${parseFloat(item.expiration_date_number)}`
                                                                    }
                                                                }
                                                                break
                                                        }
                                                    }
                                                }

                                                if (item.average_price) {
                                                    if (Number(item.average_price) < Number(values.items[index].price)) {
                                                        errors.items[index] = {
                                                            price: 'Цена выше НМЦ'
                                                        }
                                                    }
                                                }
                                            })

                                            if (errors.hasOwnProperty('items') && !errors.items.length) {
                                                delete errors.items
                                            }

                                            return errors
                                        }}
                                    >
                                        {({
                                              values,
                                              errors,
                                              touched,
                                              validateForm,
                                              isSubmitting,
                                              setFieldValue,
                                              setFieldTouched,
                                              submitForm,
                                              setValues
                                          }) => (
                                            <Form className={classes.fullWidth}>
                                                <Grid item className={classes.fullWidth}>
                                                    <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                                                        <Grid item className={classes.tabContent}>
                                                            <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                <Grid item xs={3} className={classes.column}>
                                                                    <Field
                                                                        className={classes.regionInput}
                                                                        fullWidth
                                                                        disabled={(lot.status.index === 7)}
                                                                        label="Минимальный срок поставки, дней"
                                                                        name="contract_time"
                                                                        type="number"
                                                                        step={1}
                                                                        required
                                                                        inputProps={{
                                                                            min: 1,
                                                                            step: 1
                                                                        }}
                                                                        component={CustomTextField}
                                                                    />
                                                                    {(errors.contract_time && showError) ? (
                                                                        <Tooltip
                                                                            title="Минимальный срок поставки не указан"
                                                                            placement="right"
                                                                            classes={{tooltip: classes.tooltip}}
                                                                        >
                                                                            <IconButton
                                                                                style={{
                                                                                    "width": "24px",
                                                                                    "height": "24px",
                                                                                    "padding": "0"
                                                                                }}
                                                                            >
                                                                                <HelpIcon style={{"color": "#d9534f"}}/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    ) : null}
                                                                </Grid>
                                                                <Grid item xs={3} className={classes.column}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={(lot.status.index === 7)}
                                                                        name="postponement"
                                                                        type="number"
                                                                        label="Отсрочка платежа, дней"
                                                                        step={1}
                                                                        inputProps={{
                                                                            min: 0
                                                                        }}
                                                                        component={CustomTextField}
                                                                    />
                                                                    {(errors.postponement && showError) ? (
                                                                        <Tooltip
                                                                            title="Отсрочка платежа не указана"
                                                                            placement="right"
                                                                            classes={{tooltip: classes.tooltip}}
                                                                        >
                                                                            <IconButton
                                                                                style={{
                                                                                    "width": "24px",
                                                                                    "height": "24px",
                                                                                    "padding": "0"
                                                                                }}
                                                                            >
                                                                                <HelpIcon style={{"color": "#d9534f"}}/></IconButton>
                                                                        </Tooltip>
                                                                    ) : null}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                <Grid item className={classes.fullWidth}>
                                                                    <TableContainer>
                                                                        <Table stickyHeader aria-label="sticky table">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    {[
                                                                                        {
                                                                                            id: '0',
                                                                                            label: '№',
                                                                                            format: (value) => value.toLocaleString()
                                                                                        },
                                                                                        {
                                                                                            id: '1',
                                                                                            label: 'Объект закупки',
                                                                                            format: (value) => value.toLocaleString()
                                                                                        },
                                                                                        {
                                                                                            id: '2',
                                                                                            label: 'НМЦ за единицу',
                                                                                            format: (value) => value.toLocaleString()
                                                                                        },
                                                                                        {
                                                                                            id: '3',
                                                                                            label: 'Наименование',
                                                                                            format: (value) => value.toLocaleString()
                                                                                        },
                                                                                        {
                                                                                            id: '4',
                                                                                            label: 'Количество',
                                                                                            format: (value) => value.toLocaleString()
                                                                                        },
                                                                                        {
                                                                                            id: '5',
                                                                                            label: 'НДС',
                                                                                            format: (value) => value.toLocaleString()
                                                                                        },
                                                                                        {
                                                                                            id: '6',
                                                                                            label: 'Цена за позицию',
                                                                                            format: (value) => value.toLocaleString()
                                                                                        },
                                                                                        {
                                                                                            id: '7',
                                                                                            label: 'Общая цена',
                                                                                            format: (value) => value.toLocaleString()
                                                                                        },
                                                                                        {
                                                                                            id: '8',
                                                                                            label: 'Мин. количество товара для заказа',
                                                                                            format: (value) => value.toLocaleString()
                                                                                        },
                                                                                        {
                                                                                            id: '9',
                                                                                            label: 'Срок годности',
                                                                                            format: (value) => value.toLocaleString()
                                                                                        },
                                                                                        {
                                                                                            id: '10',
                                                                                            label: 'Примечание',
                                                                                            format: (value) => value.toLocaleString()
                                                                                        },
                                                                                    ].map((column) => (
                                                                                        <TableCell key={column.id}>
                                                                                            {column.label}
                                                                                        </TableCell>
                                                                                    ))}
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {lot.items.map((item, index) => (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell component="th" scope="row">
                                                                                            {index + 1}
                                                                                        </TableCell>
                                                                                        <TableCell width="30%" style={{position: "relative"}}>
                                                                                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                                                                                                <Grid item className={classes.paddingValue}>
                                                                                                    {item.name}
                                                                                                </Grid>
                                                                                                <Grid item className={classes.tooltipName}>
                                                                                                    {(productsErrors && showError && productsErrors.items && productsErrors.items[index].name && errors.items && errors.items[index]) ? (
                                                                                                        <Tooltip
                                                                                                            title={productsErrors.items[index].name}
                                                                                                            placement="right"
                                                                                                            classes={{
                                                                                                                tooltip: classes.tooltip
                                                                                                            }}
                                                                                                        >
                                                                                                            <IconButton
                                                                                                                style={{
                                                                                                                    "width": "24px",
                                                                                                                    "height": "24px",
                                                                                                                    "padding": "0"
                                                                                                                }}
                                                                                                            >
                                                                                                                <HelpIcon style={{"color": "#d9534f"}}/>
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    ) : null}
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            <Grid item style={{width: "max-content"}}>
                                                                                                {`${item.average_price} ${lot.order.currency.code === 'RUB' ? '₽' : '₸'}`}
                                                                                            </Grid>
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={(lot.status.index === 7)}
                                                                                                style={{
                                                                                                    marginTop: "0",
                                                                                                    lineHeight: "16px",
                                                                                                    fontSize: "16px",
                                                                                                    padding: "4px 0",
                                                                                                    paddingTop: "5px",
                                                                                                }}
                                                                                                name={`items.${index}.name`}
                                                                                                key={`items.${index}.name`}
                                                                                                type="text"
                                                                                                component={CustomTextField}
                                                                                            />
                                                                                        </TableCell>

                                                                                        <TableCell>
                                                                                            {item.amount || item.amount === 0 ? round(item.amount, 2) : ''} {item.unit ? item.unit.short : null}
                                                                                            {(productsErrors && productsErrors.items && productsErrors.items[index].amount) ? (
                                                                                                <Tooltip
                                                                                                    title={productsErrors.items[index].amount}
                                                                                                    placement="right"
                                                                                                    classes={{tooltip: classes.tooltip}}>
                                                                                                    <IconButton
                                                                                                        style={{
                                                                                                            "width": "24px",
                                                                                                            "height": "24px",
                                                                                                            "padding": "0"
                                                                                                        }}
                                                                                                    >
                                                                                                        <HelpIcon style={{"color": "#d9534f"}}/>
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            ) : null}
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            <Field
                                                                                                name={`items.${index}.tax_percent`}
                                                                                                key={`items.${index}.tax_percent`}
                                                                                                disabled={(lot.status.index === 7)}
                                                                                                type="number"
                                                                                                inputProps={{
                                                                                                    min: 0,
                                                                                                }}
                                                                                                style={{
                                                                                                    width: "120px",
                                                                                                    marginTop: "0"
                                                                                                }}
                                                                                                component={({
                                                                                                    textFieldProps,
                                                                                                    ...props
                                                                                                }) => {
                                                                                                    const {
                                                                                                        label,
                                                                                                        helperText,
                                                                                                        error,
                                                                                                        ...field
                                                                                                    } = fieldToTextField(props);
                                                                                                    const {name} = field;
                                                                                                    return (
                                                                                                        <Select
                                                                                                            id={name}
                                                                                                            disabled={(lot.status.index === 7)}
                                                                                                            classes={{
                                                                                                                select: classes.select,
                                                                                                            }}
                                                                                                            name={name}
                                                                                                            value={values.items[index] ? values.items[index].tax_percent : null}
                                                                                                            style={{minWidth: "80px"}}
                                                                                                            onChange={(e) => {
                                                                                                                setFieldValue(`items.${index}.tax_percent`, e.target.value)
                                                                                                            }}
                                                                                                            children={taxPercentNodes}
                                                                                                        >
                                                                                                        </Select>
                                                                                                    )
                                                                                                }}
                                                                                            />
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.tdWithTooltip}>
                                                                                            <Field
                                                                                                name={`items.${index}.price`}
                                                                                                key={`items.${index}.price`}
                                                                                                disabled={(lot.status.index === 7)}
                                                                                                type="number"
                                                                                                inputProps={{
                                                                                                    step: 0.01,
                                                                                                    onChange: async (e) => {
                                                                                                        await setValues({
                                                                                                            ...values,
                                                                                                            items: values.items.map((item, idx) => {
                                                                                                                item.price = idx === index ? e.target.value : item.price
                                                                                                                item.totalPrice = idx === index ? e.target.value * item.amount : item.totalPrice
                                                                                                                return item
                                                                                                            }),
                                                                                                            priceLotTotal: values.items.reduce(function (sum, item) {
                                                                                                                return sum + parseFloat(item.totalPrice)
                                                                                                            }, 0),
                                                                                                        })
                                                                                                    },
                                                                                                }}
                                                                                                style={{
                                                                                                    width: "120px",
                                                                                                    marginTop: "0",
                                                                                                    minWidth: "120px",
                                                                                                    maxWidth: "120px"
                                                                                                }}
                                                                                                endAdornment={<InputAdornment position="end">{lot.order.currency.code === 'RUB' ? '₽' : '₸'}</InputAdornment>}
                                                                                                component={CustomTextField}
                                                                                            />
                                                                                            {(errors.items && errors.items[index] && errors.items[index].price && showError) ? (
                                                                                                <Tooltip
                                                                                                    title={errors.items[index].price}
                                                                                                    placement="right"
                                                                                                    classes={{tooltip: classes.tooltip}}
                                                                                                >
                                                                                                    <IconButton
                                                                                                        style={{
                                                                                                            "width": "24px",
                                                                                                            "height": "24px",
                                                                                                            "padding": "0"
                                                                                                        }}
                                                                                                    >
                                                                                                        <HelpIcon style={{"color": "#d9534f"}}/>
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            ) : null}
                                                                                            {(productsErrors && productsErrors.items && productsErrors.items[index].price && showError && !(errors.items && errors.items[index].price)) ? (
                                                                                                <Tooltip
                                                                                                    title={errors.items[index].price}
                                                                                                    placement="right"
                                                                                                    classes={{tooltip: classes.tooltip}}
                                                                                                >
                                                                                                    <IconButton
                                                                                                        style={{
                                                                                                            "width": "24px",
                                                                                                            "height": "24px",
                                                                                                            "padding": "0"
                                                                                                        }}
                                                                                                    >
                                                                                                        <HelpIcon
                                                                                                            style={{"color": "#d9534f"}}/>
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            ) : null}
                                                                                            {(values.items && values.items.hasOwnProperty(index) && values.items[index].price_discount) ? (
                                                                                                <Typography component="span">
                                                                                                    С учетом скидки/надбавки {values.items[index].price_discount} %
                                                                                                </Typography>
                                                                                            ) : null}
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.tdWithTooltip}>
                                                                                            {(values.items && values.items.hasOwnProperty(index)) ? parseFloat(values.items[index].totalPrice).toLocaleString('ru-RU', {
                                                                                                style: 'currency',
                                                                                                currency: lot.order.currency.code
                                                                                            }).replace('KZT', '₸') : null}
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.tdWithTooltip}>
                                                                                            <Field
                                                                                                name={`items.${index}.min_ordered_quantity`}
                                                                                                key={`items.${index}.min_ordered_quantity`}
                                                                                                type="number"
                                                                                                inputProps={{
                                                                                                    step: 0.01,
                                                                                                    min: 0
                                                                                                }}
                                                                                                style={{
                                                                                                    width: "120px",
                                                                                                    marginTop: "0"
                                                                                                }}
                                                                                                component={CustomTextField}
                                                                                            />
                                                                                            {(errors.items && errors.items[index] && errors.items[index].min_ordered_quantity && showError) ? (
                                                                                                <Tooltip
                                                                                                    title={errors.items[index].min_ordered_quantity}
                                                                                                    placement="right"
                                                                                                    classes={{tooltip: classes.tooltip}}
                                                                                                >
                                                                                                    <IconButton
                                                                                                        style={{
                                                                                                            "width": "24px",
                                                                                                            "height": "24px",
                                                                                                            "padding": "0"
                                                                                                        }}
                                                                                                    >
                                                                                                        <HelpIcon style={{"color": "#d9534f"}}/>
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            ) : null}
                                                                                            {(productsErrors && productsErrors.items && productsErrors.items[index].min_ordered_quantity && showError && !(errors.items && errors.items[index].min_ordered_quantity)) ? (
                                                                                                <Tooltip
                                                                                                    title={errors.items[index].min_ordered_quantity}
                                                                                                    placement="right"
                                                                                                    classes={{tooltip: classes.tooltip}}
                                                                                                >
                                                                                                    <IconButton
                                                                                                        style={{
                                                                                                            "width": "24px",
                                                                                                            "height": "24px",
                                                                                                            "padding": "0"
                                                                                                        }}
                                                                                                    >
                                                                                                        <HelpIcon style={{"color": "#d9534f"}}/>
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            ) : null}
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.tdWithTooltip}>
                                                                                            {(item.expiration_date_type === 'expiration_date') ? (
                                                                                                <React.Fragment>
                                                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                                                        <KeyboardDatePicker
                                                                                                            disableToolbar
                                                                                                            fullWidth
                                                                                                            style={{width: "138px"}}
                                                                                                            error={Boolean((errors.hasOwnProperty('items') && errors.items[index] && errors.items[index].expiration_date) && (touched.hasOwnProperty('items') && touched.items[index] && touched.items[index].expiration_date))}
                                                                                                            helperText={((errors.hasOwnProperty('items') && errors.items[index] && errors.items[index].expiration_date) && (touched.hasOwnProperty('items') && touched.items[index] && touched.items[index].expiration_date)) ? errors.items[index].expiration_date : null}
                                                                                                            name={`items.${index}.expiration_date`}
                                                                                                            disabled={(lot.status.index === 7)}
                                                                                                            required
                                                                                                            className={classes.datePicker}
                                                                                                            {...(item.expiration_date ? {minDate: new Date(item.expiration_date.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2-$1-$3")), initialFocusedDate: new Date(item.expiration_date.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2-$1-$3"))} : {})}
                                                                                                            key={`items.${index}.expiration_date`}
                                                                                                            format="dd-MM-yyyy"
                                                                                                            invalidDateMessage="Некорректная дата"
                                                                                                            orientation="landscape"
                                                                                                            variant="inline"
                                                                                                            openTo="year"
                                                                                                            autoOk
                                                                                                            disablePast
                                                                                                            value={values.items.hasOwnProperty(index) ? values.items[index].expiration_date : null}
                                                                                                            onChange={date =>
                                                                                                                setFieldValue(`items.${index}.expiration_date`, date, true)
                                                                                                            }
                                                                                                            onBlur={e => {
                                                                                                                setFieldTouched(`items.${index}.expiration_date`, true, true)
                                                                                                            }}
                                                                                                        />
                                                                                                    </MuiPickersUtilsProvider>
                                                                                                    {(errors.items && showError && errors.items[index] && errors.items[index].expiration_date) ? (
                                                                                                        <Tooltip
                                                                                                            title="Срок годности ниже минимального или не указан"
                                                                                                            placement="right"
                                                                                                            classes={{tooltip: classes.tooltip}}
                                                                                                        >
                                                                                                            <IconButton
                                                                                                                style={{
                                                                                                                    "width": "24px",
                                                                                                                    "height": "24px",
                                                                                                                    "padding": "0"
                                                                                                                }}
                                                                                                            >
                                                                                                                <HelpIcon style={{"color": "#d9534f"}}/>
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    ) : null}
                                                                                                    {(productsErrors && showError && productsErrors.items && productsErrors.items[index].expiration_date && !(errors.items && errors.items[index].expiration_date)) ? (
                                                                                                        <Tooltip
                                                                                                            title={errors.items[index].expiration_date}
                                                                                                            placement="right"
                                                                                                            classes={{tooltip: classes.tooltip}}>
                                                                                                            <IconButton
                                                                                                                style={{
                                                                                                                    "width": "24px",
                                                                                                                    "height": "24px",
                                                                                                                    "padding": "0"
                                                                                                                }}
                                                                                                            >
                                                                                                                <HelpIcon style={{"color": "#d9534f"}}/>
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    ) : null}
                                                                                                </React.Fragment>
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    <Field
                                                                                                        name={`items.${index}.expiration_date_number`}
                                                                                                        disabled={(lot.status.index === 7)}
                                                                                                        type="number"
                                                                                                        step={(item.expiration_date_type === 'expiration_date_percent') ? 0.01 : 1}
                                                                                                        style={{marginTop: "0"}}
                                                                                                        inputProps={{
                                                                                                            min: 0,
                                                                                                            step: (item.expiration_date_type === 'expiration_date_percent') ? 0.01 : 1
                                                                                                        }}
                                                                                                        endAdornment={(item.expiration_date_type === 'expiration_date_percent') ? '%' : 'мес.'}
                                                                                                        component={CustomTextField}
                                                                                                    />
                                                                                                    {(errors.items && showError && errors.items[index] && errors.items[index].expiration_date_number) ? (
                                                                                                        <Tooltip
                                                                                                            title="Срок годности ниже минимального или не указан"
                                                                                                            placement="right"
                                                                                                            classes={{tooltip: classes.tooltip}}
                                                                                                        >
                                                                                                            <IconButton
                                                                                                                style={{
                                                                                                                    width: "24px",
                                                                                                                    height: "24px",
                                                                                                                    padding: "0"
                                                                                                                }}
                                                                                                            >
                                                                                                                <HelpIcon style={{"color": "#d9534f"}}/>
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    ) : null}
                                                                                                    {(productsErrors && showError && productsErrors.items && productsErrors.items[index].expiration_date_number && !(errors.items && errors.items[index].expiration_date_number)) ? (
                                                                                                        <Tooltip
                                                                                                            title={errors.items[index].expiration_date_number}
                                                                                                            placement="right"
                                                                                                            classes={{tooltip: classes.tooltip}}>
                                                                                                            <IconButton
                                                                                                                style={{
                                                                                                                    "width": "24px",
                                                                                                                    "height": "24px",
                                                                                                                    "padding": "0"
                                                                                                                }}
                                                                                                            >
                                                                                                                <HelpIcon style={{"color": "#d9534f"}}/>
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    ) : null}
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={(lot.status.index === 7)}
                                                                                                style={{
                                                                                                    marginTop: "0",
                                                                                                    lineHeight: "16px",
                                                                                                    fontSize: "16px",
                                                                                                    padding: "4px 0",
                                                                                                    paddingTop: "5px",
                                                                                                }}
                                                                                                name={`items.${index}.description`}
                                                                                                key={`items.${index}.description`}
                                                                                                type="text"
                                                                                                multiline
                                                                                                rows="1"
                                                                                                component={CustomTextField}
                                                                                            />
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                                <TableRow className={classes.total}>
                                                                                    <TableCell colSpan={6}>
                                                                                        Итого:
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <Grid container justify="flex-start" alignItems="center" wrap="nowrap" className={classes.posItem}>
                                                                                            <Grid item className={classes.paddingValue}>
                                                                                                {parseFloat(values.items.reduce(function (sum, item) {
                                                                                                    return sum + parseFloat(item.price ? item.price : 0)
                                                                                                }, 0)).toLocaleString('ru-RU', {
                                                                                                    style: 'currency',
                                                                                                    currency: lot.order.currency.code
                                                                                                }).replace('KZT', '₸')}
                                                                                            </Grid>
                                                                                            <Grid item className={classes.tooltipName}>
                                                                                                {(errors.priceTotal && showError) ? (
                                                                                                    <Tooltip
                                                                                                        title={errors.priceTotal}
                                                                                                        placement="right"
                                                                                                        classes={{tooltip: classes.tooltip}}
                                                                                                    >
                                                                                                        <IconButton
                                                                                                            style={{
                                                                                                                "width": "24px",
                                                                                                                "height": "24px",
                                                                                                                "padding": "0"
                                                                                                            }}
                                                                                                        >
                                                                                                            <HelpIcon style={{"color": "#d9534f"}}/>
                                                                                                        </IconButton>
                                                                                                    </Tooltip>
                                                                                                ) : null}
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </TableCell>
                                                                                    <TableCell colSpan={4}>
                                                                                        <Grid container justify="flex-start" alignItems="center" wrap="nowrap" className={classes.posItem}>
                                                                                            <Grid item className={classes.paddingValue}>
                                                                                                {parseFloat(values.priceLotTotal).toLocaleString('ru-RU', {
                                                                                                    style: 'currency',
                                                                                                    currency: lot.order.currency.code
                                                                                                }).replace('KZT', '₸')}
                                                                                            </Grid>
                                                                                            <Grid item className={classes.tooltipName}>
                                                                                                {(errors.priceLotTotal && showError) ? (
                                                                                                    <Tooltip
                                                                                                        title={errors.priceLotTotal}
                                                                                                        placement="right"
                                                                                                        classes={{tooltip: classes.tooltip}}
                                                                                                    >
                                                                                                        <IconButton
                                                                                                            style={{
                                                                                                                "width": "24px",
                                                                                                                "height": "24px",
                                                                                                                "padding": "0"
                                                                                                            }}
                                                                                                        >
                                                                                                            <HelpIcon style={{"color": "#d9534f"}}/>
                                                                                                        </IconButton>
                                                                                                    </Tooltip>
                                                                                                ) : null}
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </Grid>
                                                            </Grid>
                                                            {(!!company && !!company.contents && !!Object.keys(company.contents).length) ? (
                                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                    <Grid item className={classes.fullWidth}>
                                                                        <Accordion className={classes.accordionItem}>
                                                                            <AccordionSummary expandIcon={<ExpandMore/>}>
                                                                                <Typography>Документы</Typography>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                                    {Object.keys(company.contents).map(key => (
                                                                                        company.contents[key].map((file, index) => (
                                                                                            <Grid key={index} item xs={6}>
                                                                                                <Typography>{file.name} ({file.type.name})</Typography>
                                                                                            </Grid>
                                                                                        ))
                                                                                    ))}
                                                                                </Grid>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    </Grid>
                                                                </Grid>
                                                            ) : null}
                                                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                <Grid item className={classes.tabContent}>
                                                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                        <Grid item xs={6}>
                                                                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                                                <Grid item xs={5} className={classes.fileUpload}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        disabled={(lot.status.index === 7)}
                                                                                        size="small"
                                                                                        component={(props) => (
                                                                                            <FileUpload {...props} endIcon={"Обзор"}/>
                                                                                        )}
                                                                                        label="Ценовое предложение"
                                                                                        name={'file'}
                                                                                        InputLabelProps={{
                                                                                            name: values.file ? values.file.name : null
                                                                                        }}
                                                                                        InputProps={{
                                                                                            onChange: (event) => {
                                                                                                setSign({open: true, files: [...event.currentTarget.files]})
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    {sign.open ? (
                                                                                        <Signature
                                                                                          open={sign.open}
                                                                                          onClose={() => setSign({open: false, files: []})}
                                                                                          files={sign.files}
                                                                                          onContinue={(objects) => {
                                                                                              setSign({open: false, files: []})
                                                                                              setFieldValue(`file`, objects[0].file)
                                                                                              setFieldValue(`signature`, objects[0].sign)
                                                                                          }}
                                                                                        />
                                                                                    ) : null}
                                                                                </Grid>
                                                                                {!values.file ? (
                                                                                    <Grid item style={{padding: "0"}}>
                                                                                        <Tooltip
                                                                                            title="Обязательно прикрепите ценовое предложение"
                                                                                            placement="right"
                                                                                            classes={{tooltip: classes.tooltip}}
                                                                                        >
                                                                                            <IconButton
                                                                                                style={{
                                                                                                    "width": "24px",
                                                                                                    "height": "24px",
                                                                                                    "padding": "0"
                                                                                                }}
                                                                                            >
                                                                                                <HelpIcon style={{"color": "#485868"}}/>
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </Grid>
                                                                                ) : null}
                                                                            </Grid>
                                                                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                                                <Grid item xs={5} className={classes.fileUpload}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        disabled={(lot.status.index === 7)}
                                                                                        size="small"
                                                                                        component={(props) => (
                                                                                            <FileUpload {...props} endIcon={"Обзор"}/>
                                                                                        )}
                                                                                        label="Спецификация"
                                                                                        name={'specification'}
                                                                                        InputLabelProps={{
                                                                                            name: values.specification ? values.specification.name : null
                                                                                        }}
                                                                                        InputProps={{
                                                                                            onChange: (event) => {
                                                                                                setFieldValue('specification', event.currentTarget.files[0])
                                                                                                setFieldTouched('specification', true, false)
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <FieldArray
                                                                                name={`files`}
                                                                                render={arrayHelpers => (
                                                                                    <React.Fragment>
                                                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                                            {values.files.map((el, index) => (
                                                                                                <React.Fragment key={index}>
                                                                                                    <Grid item xs={5} className={classes.fileNameUpload}>
                                                                                                        <Field
                                                                                                            fullWidth
                                                                                                            disabled={(lot.status.index === 7)}
                                                                                                            style={{
                                                                                                                marginTop: "5px",
                                                                                                                lineHeight: "16px",
                                                                                                                fontSize: "16px",
                                                                                                                padding: "4px 0",
                                                                                                                paddingTop: "5px",
                                                                                                            }}
                                                                                                            name={`files.${index}.description`}
                                                                                                            key={`files.${index}.description`}
                                                                                                            type="text"
                                                                                                            multiline
                                                                                                            rows="1"
                                                                                                            component={CustomTextField}
                                                                                                        />
                                                                                                    </Grid>
                                                                                                    <Grid item xs={2}>
                                                                                                        <Field
                                                                                                            fullWidth
                                                                                                            disabled={(lot.status.index === 7)}
                                                                                                            size="small"
                                                                                                            component={(props) => (
                                                                                                                <FileUpload {...props} endIcon={"Обзор"}/>
                                                                                                            )}
                                                                                                            name={`files.${index}.file`}
                                                                                                            InputLabelProps={{
                                                                                                                name: values.files && values.files.length && values.files[index] && values.files[index].file && values.files[index].file.name ? values.files[index].file.name : null
                                                                                                            }}
                                                                                                            InputProps={{
                                                                                                                onChange: (event) => {
                                                                                                                    setFieldValue(`files.${index}.file`, event.currentTarget.files[0])
                                                                                                                    setFieldTouched(`files.${index}.file`, true, false)
                                                                                                                }
                                                                                                            }}
                                                                                                        />
                                                                                                    </Grid>
                                                                                                    <Grid item xs={1} style={{padding: "8px 0"}}>
                                                                                                        <IconButton
                                                                                                            disabled={(lot.status.index === 7)}
                                                                                                            size="small"
                                                                                                            onClick={() => {
                                                                                                                arrayHelpers.remove(index)
                                                                                                            }}
                                                                                                        >
                                                                                                            <Clear/>
                                                                                                        </IconButton>
                                                                                                    </Grid>
                                                                                                </React.Fragment>
                                                                                            ))}
                                                                                        </Grid>
                                                                                        <Grid container direction="row" justify="flex-end" alignItems="flex-start" spacing={2}>
                                                                                            <Grid item xs={7}>
                                                                                                <ButtonIcon
                                                                                                    disabled={(lot.status.index === 7)}
                                                                                                    className={classes.button}
                                                                                                    onClick={(e) => {
                                                                                                        e.stopPropagation()
                                                                                                        arrayHelpers.insert(values.files.length, {
                                                                                                            file: null,
                                                                                                            description: ''
                                                                                                        })
                                                                                                    }}
                                                                                                    children={<AddIcon/>}
                                                                                                />
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </React.Fragment>
                                                                                )}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            {(((errors.priceLotTotal || values.rationale) && showError) || (!offerId && lot.offers[0] && values.rationale)) ? (
                                                                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                                                    <Grid item>
                                                                                        <Field
                                                                                            fullWidth
                                                                                            disabled={(lot.status.index === 7)}
                                                                                            size="small"
                                                                                            component={(props) => (
                                                                                                <FileUpload {...props} endIcon={<AddIcon/>}/>
                                                                                            )}
                                                                                            label="Обоснование цены"
                                                                                            name={'rationale'}
                                                                                            InputLabelProps={{
                                                                                                name: values.rationale ? values.rationale.name : null
                                                                                            }}
                                                                                            InputProps={{
                                                                                                onChange: (event) => {
                                                                                                    setFieldValue('rationale', event.currentTarget.files[0])
                                                                                                    setFieldTouched('rationale', true, false)
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </Grid>
                                                                                    {!values.rationale ? (
                                                                                        <Grid item>
                                                                                            <Tooltip
                                                                                                title="Обязательно прикрепите обоснование цены"
                                                                                                placement="right"
                                                                                                classes={{tooltip: classes.tooltip}}
                                                                                            >
                                                                                                <IconButton
                                                                                                    style={{
                                                                                                        "width": "24px",
                                                                                                        "height": "24px",
                                                                                                        "padding": "0"
                                                                                                    }}
                                                                                                >
                                                                                                    <HelpIcon style={{"color": "#485868"}}/>
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </Grid>
                                                                                    ) : null}
                                                                                </Grid>
                                                                            ) : null}
                                                                        </Grid>
                                                                        {(errors.files?.length && !Array.isArray(errors.files)) ? (
                                                                            <Grid item xs={6}>
                                                                                <Typography className={classes.error}>
                                                                                   {errors.files}
                                                                                </Typography>
                                                                            </Grid>
                                                                        ) : null}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item className={classes.footer}>
                                                    <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                                        <Grid item className={classes.footerContent}>
                                                            <Grid container className={classes.footerContainer} direction="row" justify="space-between" alignItems="flex-start" spacing={2}>
                                                                <Grid item>
                                                                    <Submit
                                                                        disableElevation
                                                                        variant="contained"
                                                                        color="primary"
                                                                        type="button"
                                                                        disabled={isSubmitting}
                                                                        endIcon={<GetAppIcon/>}
                                                                        onClick={() => {
                                                                            setShowError(true)
                                                                            validateForm(values).then(obj => {
                                                                                if (!Object.keys(obj).length || ((Object.keys(obj).length === 1) && obj.hasOwnProperty('file'))) {
                                                                                    dispatch(OfferActions.getPriceOffer(values)).then(file => {
                                                                                        const link = document.createElement('a')
                                                                                        link.href = URL.createObjectURL(file);
                                                                                        link.setAttribute('download', 'Ценовое предложение.pdf');
                                                                                        document.body.appendChild(link);
                                                                                        link.click();
                                                                                    })
                                                                                }
                                                                            })
                                                                        }}
                                                                        download
                                                                    >
                                                                        Скачать ценовое предложение
                                                                    </Submit>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container direction="row" justify="flex-end" alignItems="flex-start" spacing={2}>
                                                                        {(lot.order.type === "dynamic") ? (
                                                                            <Grid item>
                                                                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                                                    <Grid item>
                                                                                        <Field
                                                                                            component={CheckboxWithLabel}
                                                                                            name="last_price"
                                                                                            color="primary"
                                                                                            disabled={((offerId && lot.offers[0].last_price) || (lot.status.index === 7))}
                                                                                            Label={{label: 'Последняя цена'}}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Tooltip
                                                                                            title="После сохранения с этим параметром редактирование предложения будет недоступно"
                                                                                            placement="right"
                                                                                            classes={{tooltip: classes.tooltip}}
                                                                                        >
                                                                                            <IconButton
                                                                                                style={{
                                                                                                    "width": "24px",
                                                                                                    "height": "24px",
                                                                                                    "padding": "0"
                                                                                                }}
                                                                                            >
                                                                                                <HelpIcon style={{"color": "#485868"}}/>
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        ) : null}
                                                                        <Grid item>
                                                                            <Submit
                                                                                disableElevation
                                                                                variant="contained"
                                                                                color="primary"
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    setShowError(true)
                                                                                    submitForm().then(r => {})
                                                                                }}
                                                                                disabled={isSubmitting}>
                                                                                {offerId ? 'Сохранить' : 'Опубликовать'}
                                                                            </Submit>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        ) : null
}
