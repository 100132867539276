import React from "react"
import {Field} from "formik"
import {TextField} from "formik-material-ui"

import {Grid, makeStyles} from "@material-ui/core"
import {Autocomplete, createFilterOptions} from '@material-ui/lab'

const useStyles = makeStyles(() => ({
    fullWidth: {
        'width': '100%'
    }
}))

export const FieldIntegerUnit = (props) => {
    const classes = useStyles()

    const { id, label, items, values, setFieldValue, setFieldTouched, isSubmitting, disabled } = props

    const value = values.hasOwnProperty(id) ? { short: values[id] } : { short: '' }

    return (
        <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
            <Grid item sm={7} className={classes.fullWidth}>
                <Field
                    fullWidth
                    name={ `attributes.${id}.value` }
                    type="number"
                    disabled={ isSubmitting || disabled }
                    label={ `${label}` }
                    inputProps={{
                        step: 1,
                        onWheel: e => {
                            e.target.blur()
                            e.stopPropagation()
                            setTimeout(() => {
                                e.target.focus()
                            }, 0)
                        }
                    }}
                    component={ TextField }
                />
            </Grid>
            <Grid item sm={5} className={classes.fullWidth}>
                <Autocomplete
                    filterOptions={createFilterOptions({
                        matchFrom: 'start',
                        stringify: option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')
                    })}
                    options={ items }
                    name={`attributes.${id}.unit`}
                    disabled={ isSubmitting || disabled }
                    getOptionLabel={option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')}
                    defaultValue={value}
                    onChange={(e, value) => {
                        setFieldValue(`attributes.${id}.unit`, ((value instanceof Object) ? value.short : value))
                    }}
                    onBlur={(e) => {
                        if (items.find(item => item.short === e.target.value)) {
                            setFieldTouched(`attributes.${id}.unit`, true)
                        } else {
                            setFieldValue(`attributes.${id}.unit`, '')
                        }
                    }}
                    renderInput={params => (
                        <Field
                            fullWidth
                            {...params}
                            name={`attributes.${id}.unit`}
                            type="text"
                            label="Ед. измерения"
                            component={ TextField }
                        />
                    )}
                />
            </Grid>
        </Grid>
    )
}
