import React from "react"
import DateFnsUtils from "@date-io/date-fns"
import ru from "date-fns/locale/ru"

import {Grid, InputAdornment, makeStyles, MenuItem, Select, TextField, withStyles} from "@material-ui/core"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {Search as SearchIcon} from '@material-ui/icons'

import {Submit} from "../../../App/components/Button/Submit"
import {TagBox} from "./Controls/TagBox"

const useStyles = makeStyles(theme => ({
    content: {
        "width": "calc(100% - 300px)",
        "padding": "30px 60px"
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    input: {
        "width": "100%",
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100% - 70px)",
        "min-height": "calc(100% - 70px)"
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    searchLabel: {
        "margin-bottom": "15px",
    },
    searchInput: {
        "width": "calc(100% - 83px)"
    },
    searchButton: {
        "margin-top": "5px"
    },
    amount: {
        "min-width": "100px"
    },
    lightTooltip: {
        "font-size": "0.8rem",
        "backgroundColor": "white",
        "color": "rgba(0, 0, 0, 0.87)",
        "border": "1px solid #ededed"
    },
    productName: {
        maxWidth: "400px",
    },
    filterSelect: {
        "width": "100% !important",
        "border": "2px solid #c1c1c1 !important",
        "padding": "0 15px 0 15px !important",
        "background": "#fff !important",
        "margin-top": "6px !important",
        "transition": "border-color .2s ease-in !important",
        "border-radius": "2px !important",
        "&:hover": {
            "border-color": "#898989 !important",
        },
        "margin-bottom": "14px !important",
        "height": "36px",
        "& .dx-tag-remove-button": {
            "display": "none",
        },
    },
}))

const OutlinedTextField = withStyles({
    root: {
        "background-color": "#fff",
        "margin-top": "5px !important",
        "& .MuiInputLabel-outlined": {
            "transform": "translate(14px, 9px) scale(1)"
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            "transform": "translate(14px, -6px) scale(0.75)"
        },
        "& .MuiOutlinedInput-input": {
            "background": "#ffffff",
            "padding": "8px 15px 8px 15px"
        },
        "& .MuiOutlinedInput-root": {
            "border-radius": "0",
            '& fieldset': {
                "border": "solid 2px #c1c1c1"
            },
            '&:hover fieldset': {
                "border": "solid 2px #c1c1c1"
            }
        }
    }
})(TextField);

const OutlinedTextFieldInputPropsStandardBasic = {
    min: 0,
    step: 1
}

const OutlinedTextFieldInputPropsSearch = {
    endAdornment: (
        <InputAdornment position="end">
            <SearchIcon style={{color: '#c1c1c1'}}/>
        </InputAdornment>
    )
}

const OutlinedDatePicker = withStyles({
    root: {
        "margin-top": "5px !important",
        "& .MuiInputLabel-outlined": {
            "transform": "translate(14px, 9px) scale(1)"
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            "transform": "translate(14px, -6px) scale(0.75)"
        },
        "& .MuiOutlinedInput-input": {
            "background": "#ffffff",
            "padding": "8px 15px 8px 15px"
        },
        "& .MuiOutlinedInput-root": {
            "border-radius": "0",
            '& fieldset': {
                "border": "solid 2px #c1c1c1"
            },
            '&:hover fieldset': {
                "border": "solid 2px #c1c1c1"
            }
        },
    }
})(KeyboardDatePicker);

const OutlinedSelect = withStyles({
    root: {
        "background-color": "#fff",
        "border": "solid 2px #c1c1c1",
        "margin-top": "5px !important",
        "padding-left": "5px"
    }
})(Select);

export const Controls = (props) => {
    const {commonParams, handlers, params} = props
    const classes = useStyles()

    const getFilterData = (filters, filterKey) => {
        return filters !== null && filters[filterKey] !== null ? JSON.parse(filters[filterKey]) : []
    }

    const getExpirationDateComponent = (params) =>  {
        const components = {
            expirationDate: (
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                    <OutlinedDatePicker
                        disableToolbar
                        fullWidth
                        name="expirationDate"
                        inputVariant="outlined"
                        orientation="landscape"
                        key={`discovered`}
                        variant="inline"
                        minDate={Date.now()}
                        minDateMessage="Некорректная дата"
                        format="dd-MM-yyyy"
                        openTo="year"
                        autoOk
                        label='Мин. срок годности товаров'
                        value={params.expirationDate.value ?? null}
                        onChange={params.expirationDate.handler}
                    />
                </MuiPickersUtilsProvider>
            ),
            expirationDatePercent: (
                <OutlinedTextField
                    id="expirationDatePercent"
                    name={'expirationDatePercent'}
                    label='Срок годности, %'
                    variant="outlined"
                    fullWidth={true}
                    margin={'normal'}
                    type="number"
                    value={params.expirationDatePercent.value}
                    inputProps={{
                        min: 0,
                        step: 0.01,
                    }}
                    onChange={params.expirationDatePercent.handler}
                />
            )
        }

        return components[params.expirationDateType.value];
    }

    const isDisabledSearch = (commonParams, params) => {
        return !(commonParams.category || commonParams.query.has('category')) || !params.postponement.value || !(params.expirationDate.value || params.expirationDatePercent.value)
    }

    return (
        <Grid container direction="row" justify="space-between" alignItems="stretch" spacing={2}>
            <Grid item xs={2}>
                <OutlinedSelect
                    fullWidth
                    labelId="expirationDateType"
                    id="expirationDateType"
                    value={params.expirationDateType.value}
                    label="Тип срока годности"
                    onChange={params.expirationDateType.handler}
                >
                    <MenuItem value={'expirationDate'}>Мин. срок годности товаров</MenuItem>
                    <MenuItem value={'expirationDatePercent'}>Остаточный срок годности</MenuItem>
                </OutlinedSelect>
            </Grid>
            <Grid item xs={3}>
                {getExpirationDateComponent(params)}
            </Grid>
            <Grid item xs={2}>
                <OutlinedTextField
                    id="standard-basic"
                    name={'postponement'}
                    label='Отсрочка платежа'
                    variant="outlined"
                    fullWidth={true}
                    margin={'normal'}
                    type="number"
                    value={params.postponement.value}
                    inputProps={OutlinedTextFieldInputPropsStandardBasic}
                    onChange={params.postponement.handler}
                />
            </Grid>
            <Grid item xs={4}>
                <OutlinedTextField
                    id="search"
                    variant="outlined"
                    name={'search'}
                    label='Поиск по наименованию, МНН'
                    fullWidth={true}
                    margin={'normal'}
                    onChange={handlers.onChangeSearch}
                    classes={{
                        root: classes.searchLabel
                    }}
                    InputProps={OutlinedTextFieldInputPropsSearch}
                />
            </Grid>
            <Grid item xs={1} className={classes.searchButton}>
                <Submit
                    disableElevation
                    variant="contained"
                    color="primary"
                    type="button"
                    disabled={isDisabledSearch(commonParams, params)}
                    onClick={handlers.onSubmitSearch}
                >
                    Найти
                </Submit>
            </Grid>
            {commonParams.isMedicinesCategory &&
                <React.Fragment>
                    <Grid item xs={4}>
                        <TagBox
                            data={getFilterData(commonParams.filterValues, 'formulation')}
                            placeholder="Поиск: Лекарственная форма"
                            onValueChanged={handlers.onFormulationChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TagBox
                            data={getFilterData(commonParams.filterValues, 'dosage')}
                            placeholder="Поиск: Дозировка"
                            onValueChanged={handlers.onDosageChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TagBox
                            data={getFilterData(commonParams.filterValues, 'packaging')}
                            placeholder="Поиск: Фасовка/Количество"
                            onValueChanged={handlers.onPackagingChange}
                        />
                    </Grid>
                </React.Fragment>
            }
        </Grid>
    )
}
