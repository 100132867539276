import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import format from "date-fns/format"
import {ru} from "date-fns/locale"

import {
    Grid,
    Link,
    makeStyles,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography
} from "@material-ui/core"
import {GetApp as GetAppIcon} from '@material-ui/icons'

import {TabPanel} from "../../../Account/components/Tabs/TabPanel"
import {day as formatDay} from "../../../App/helpers/date"
import {Submit} from "../../../App/components/Button/Submit"
import {PurchaseActions} from "../../actions/purchase";
import {useParams} from "react-router-dom";
import {history} from "../../../App/helpers/history";
import {AuthorizationService} from "../../../Auth/services/authorization";
import {Button as SignatureButton} from "../../../Crypto/Action/Button";

const useStyles = makeStyles(() => ({
    content: {
        "width": "100%",
        "padding": "30px 250px",
        "background-image": "linear-gradient(to bottom,#f6f6f6,#eee)"
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px 0 29px !important",
    },
    input: {
        "width": "100%",
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 282px)"
    },
    tableContainer: {
        "overflow": "auto"
    },
    tabContent: {
        "padding": "20px 29px !important"
    },
    itemInput: {
        "margin-top": "0 !important"
    },
    inputContent: {
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    download: {
        "color": "#485868"
    },
    winner: {
        '& .MuiTableCell-root': {
            'font-weight': '500'
        }
    },
    total: {
        "border-bottom": "none",
        'font-weight': '600'
    },
    link: {
        "display": "flex",
        "line-height": "31px",
        "color": "#485868",
    }
}))

export const Lot = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const classes = useStyles()
    const {account} = useSelector(state => state.account)
    const [lot, setLot] = React.useState(null);
    const [value, setValue] = React.useState(0);
    const [load, setLoad] = React.useState(false);

    useEffect(() => {
        const getLot = async () => {
            return await dispatch(PurchaseActions.lot(id))
        }

        if (!load) {
            getLot().then(lot => {
                setLot(lot)

                if (account) {
                    if (AuthorizationService.access(account, ["customer"]) && AuthorizationService.permissions(account, "access_to_elmag") && (lot.order.company.id === account.company?.id)) {
                        history.push(`/marketplace/request/${lot.order.id}`)
                    }

                    if (AuthorizationService.access(account, ["supplier_confirmed"]) && AuthorizationService.permissions(account, "access_to_elmag")) {
                        history.push(`/supplier/marketplace/my-orders/lot/${lot.id}`)
                    }
                }

                setLoad(true)
            })
        }
    }, [dispatch, load]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return load ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.full}>
                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                            <Grid item className={classes.fullWidth}>
                                <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                    <Grid item className={classes.header}>
                                        <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                            <Grid item>
                                                <Grid container className={classes.fullWidth} direction="row" justify="space-between" alignItems="stretch">
                                                    <Grid item>
                                                        <Typography className={classes.title}>
                                                            Лот № {lot.number ?? lot.index}
                                                        </Typography>
                                                        <Typography variant="button">
                                                            {lot.order.company.legal_detail.name}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="button">
                                                            {lot.status.name}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="button">
                                                    Подача предложений до {format(new Date(lot.order.application_deadline), 'H:mm PP',  {locale: ru})} (мск {format(new Date(new Date(lot.order.application_deadline).toLocaleString("en-US", {timeZone: "Europe/Moscow"})), 'H:mm PP',  {locale: ru})})
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.input}>
                                        <Grid container direction="column" justify="center" alignItems="stretch">
                                            <Grid item className={classes.fullWidth}>
                                                <Tabs
                                                    value={value}
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                    onChange={handleChange}
                                                >
                                                    <Tab value={0} label="Информация о лоте" />
                                                    {lot.hasOwnProperty('offers') && lot.offers.length > 0 && lot.offers.filter(offer => offer.approve === null).length === 0 && <Tab value={1} label="Предложения поставщиков" />}
                                                </Tabs>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid className={classes.tab} container direction="column" justify="space-between" alignItems="stretch">
                                    <Grid item className={classes.inputContent}>
                                        <TabPanel value={value} index={0}>
                                            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                <Grid item>
                                                    <Grid container direction="row" justify="space-between" alignItems="stretch" spacing={2}>
                                                        <Grid item>
                                                            <Grid container direction="column" justify="space-between" alignItems="stretch" spacing={2}>
                                                                <Grid item>
                                                                    <Typography variant="button">
                                                                        Ценовой запрос:
                                                                    </Typography>
                                                                    <Typography>
                                                                        {lot.order.name}
                                                                    </Typography>
                                                                </Grid>
                                                                {lot.order?.from_planning ? <Grid item>
                                                                    <Typography variant="button">
                                                                        Способ проведения:
                                                                    </Typography>
                                                                    <Typography>
                                                                        {lot.order.conduction_method.name}
                                                                    </Typography>
                                                                </Grid> : <Grid item>
                                                                        <Typography variant="button">
                                                                            Тип:
                                                                        </Typography>
                                                                        <Typography>
                                                                            {lot.order.type === 'static' ? 'Статический запрос' : 'Динамический запрос'}
                                                                        </Typography>
                                                                </Grid>}
                                                                {lot.expiration_date &&
                                                                    <Grid item>
                                                                        <Typography variant="button">
                                                                            Мин. срок годности:
                                                                        </Typography>
                                                                        <Typography>
                                                                            {lot.expiration_date ? `${lot.expiration_date} г.` : null}
                                                                        </Typography>
                                                                    </Grid>
                                                                }
                                                                <Grid item>
                                                                    <Typography variant="button">
                                                                        Территория:
                                                                    </Typography>
                                                                    <Typography>
                                                                        {lot?.order?.territory?.name}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction="column" justify="space-between" alignItems="stretch" spacing={2}>
                                                                <Grid item>
                                                                        <Typography variant="button">
                                                                            Срок действия/исполнения договора:
                                                                        </Typography>
                                                                        <Typography>
                                                                            {lot.contract_time ? formatDay(parseInt(lot.contract_time)) : null}
                                                                        </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="button">
                                                                        Категория:
                                                                    </Typography>
                                                                    <Typography>
                                                                        {lot.order.category.name}
                                                                    </Typography>
                                                                </Grid>
                                                                {lot.postponement &&
                                                                    <Grid item>
                                                                        <Typography variant="button">
                                                                            Отсрочка платежа:
                                                                        </Typography>
                                                                        <Typography>
                                                                            {lot.postponement}
                                                                        </Typography>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction="column" justify="space-between" alignItems="stretch">
                                                                <Grid item style={{ marginBottom: '20px'}}>
                                                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                        {(lot.order.files.find((file) => file.type?.key === 'draft_agreement')) &&
                                                                            <Grid item>
                                                                                <Typography gutterBottom><Link href={process.env.REACT_APP_HOST_API + `/purchase/order/lot/${lot.id}/file/${lot.order.files.find((file) => file.type?.key === 'draft_agreement').id}`} className={classes.link}>Скачать проект договора<GetAppIcon  viewBox="0 0 24 24" /></Link></Typography>
                                                                            </Grid>
                                                                        }
                                                                        {(lot.files.find((file) => file.type?.key === 'technical_task')) &&
                                                                            <Grid item>
                                                                                <Typography gutterBottom><Link href={process.env.REACT_APP_HOST_API + `/purchase/order/lot/${lot.id}/file/${(lot.files.filter(el => el.type !== null)).find((file) => file.type?.key === 'technical_task').id}`} className={classes.link}>Скачать техническое задание<GetAppIcon viewBox="0 0 24 24" /></Link></Typography>
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <TableContainer className={classes.tableContainer}>
                                                        <Table stickyHeader aria-label="sticky table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {[
                                                                        {
                                                                            id: '0',
                                                                            label: '№',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                        {
                                                                            id: '1',
                                                                            label: 'Объект закупки',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                        {
                                                                            id: '2',
                                                                            label: 'Кол-во',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                        {
                                                                            id: '3',
                                                                            label: 'НМЦ за единицу',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                        {
                                                                            id: '4',
                                                                            label: 'НМЦ позиции',
                                                                            format: (value) => value.toLocaleString(),
                                                                        }
                                                                    ].map((column) => (
                                                                        <TableCell key={column.id}>
                                                                            {column.label}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {lot.items.map((item, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell>
                                                                            {index + 1}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {item.name}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {item.amount ? parseFloat(item.amount) : 0}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {parseFloat(item.average_price).toLocaleString('ru-RU', {style: 'currency', currency: lot.order.currency.code}).replace('KZT', '₸')}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {parseFloat(item.average_price * item.amount).toLocaleString('ru-RU', {style: 'currency', currency: lot.order.currency.code}).replace('KZT', '₸')}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                                <TableRow>
                                                                    <TableCell className={classes.total} component="th" scope="row" />
                                                                    <TableCell className={classes.total} width="80%">
                                                                        ИТОГО
                                                                    </TableCell>
                                                                    <TableCell align="center" className={classes.total}>
                                                                        {lot.items.reduce(function (sum, item) { return parseFloat(sum) + parseFloat(item.amount) }, 0)}
                                                                    </TableCell>
                                                                    <TableCell className={classes.total}/>
                                                                    <TableCell align="center" className={classes.total}>
                                                                        {parseFloat(lot.average_price).toLocaleString('ru-RU', {style: 'currency', currency: lot.order.currency.code}).replace('KZT', '₸')}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                            <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                                <Grid item>
                                                    <TableContainer className={classes.tableContainer}>
                                                        <Table stickyHeader aria-label="sticky table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {[
                                                                        {
                                                                            id: '0',
                                                                            label: 'Поставщик',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                        {
                                                                            id: '1',
                                                                            label: 'Срок поставки',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                        {
                                                                            id: '2',
                                                                            label: 'Цена предложения, с учетом НДС',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                        {
                                                                            id: '3',
                                                                            label: 'Дата подачи',
                                                                            format: (value) => value.toLocaleString(),
                                                                        },
                                                                    ].map((column) => (
                                                                        <TableCell key={column.id}>
                                                                            {column.label}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {lot.offers.map((offer, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell>
                                                                            {offer.company ? offer.company.legal_detail.name : `Поставщик № ${index + 1}`}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {offer.delivery}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {parseFloat(offer.price).toLocaleString('ru-RU', {style: 'currency', currency: lot.order.currency.code}).replace('KZT', '₸')}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {format(new Date(offer.created), 'dd.MM.yyyy HH:mm')}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.footer}>
                                    <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                        <Grid item className={classes.footerContent}>
                                            <Grid container className={classes.footerContainer} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                <Grid item>
                                                    <Submit
                                                        disableElevation
                                                        variant="contained"
                                                        color="primary"
                                                        type="button"
                                                        onClick={() => {
                                                            window.location.href = '/';
                                                        }}
                                                    >
                                                        Назад
                                                    </Submit>
                                                </Grid>
                                                {(lot.order.files.find((file) => file.type.key === 'notice')) ? (
                                                  <Grid item>
                                                      <SignatureButton
                                                        file={lot.order.files.find((file) => file.type.key === 'notice')}
                                                        name="Извещение"
                                                        href={`/purchase/order/lot/${lot.id}/file/${lot.order.files.find((file) => file.type.key === 'notice').id}`}
                                                      />
                                                  </Grid>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    ) : null
}
