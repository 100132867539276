import React from "react"
import {useSelector} from "react-redux"
import {Field, FieldArray} from "formik"
import {TextField} from "formik-material-ui"
import DateFnsUtils from "@date-io/date-fns"
import ru from "date-fns/locale/ru"

import {
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    MenuItem,
    Tooltip,
    Typography,
} from "@material-ui/core"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {Add as AddIcon, Clear, Help as HelpIcon} from "@material-ui/icons"

import {Submit} from "../../../../../../App/components/Button/Submit"
import {TextFieldWithError as CustomTextField} from "../../../../../../App/components/Inputs/TextFieldWithError"
import {FileUpload} from "../../../../../../App/components/Inputs/FileUpload"
import {Group} from "../../../../../../App/components/Paper/Group"
import {ButtonIcon} from "../../../../../../App/components/Button/ButtonIcon"
import {Item} from "./Item";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        "width": "100%"
    },
    column: {
        "height": "86px"
    },
    error: {
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "height": "35px",
        "font-size": "0.75rem",
        "color": "#f44336"
    },
    group: {
        "padding-bottom": "16px"
    },
    itemClose: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0 !important",
    },
    fileRows: {
        overflowY: "auto",
        overflowX: "hidden",
        marginTop: "3px",
        maxHeight: "225px",
        "&>div:nth-child(2n)>div>div>div": {
            flexWrap: "nowrap",
        },
        "& .MuiFormHelperText-root": {
            color: "#f27360",
        }
    },
    filesUpload: {
        "&>div>div>div": {
            flexWrap: "nowrap",
            justifyContent: 'flex-start !important',
            "&>div:first-child": {
                minWidth: "170px"
            }
        }
    }
}))

export const Lot = (props) => {
    const classes = useStyles()

    const {index, category, currency, settings, getStandards, changeStandards, lot, values, errors, touched, setValues, setFieldValue, setFieldTouched, remove} = props
    const {categories, currencies} = useSelector(state => state.system)

    const getCategoriesWithWarrantyPeriod = (id = null) => {
        let result = []

        if (id) {
            result.push(id)

            categories.filter(category => (category.category && (category.category.id === id))).forEach(category => {
                result = result.concat(getCategoriesWithWarrantyPeriod(category.id))
            })
        } else {
            categories.filter(category => ([47, 50].indexOf(category.id) !== -1)).forEach(category => {
                result = result.concat(getCategoriesWithWarrantyPeriod(category.id))
            })
        }

        return result
    }

    return (
        <Group>
            <Grid container className={classes.group} direction="row" justify="space-between" alignItems="stretch" spacing={2}>
                <Grid item xs={12} className={classes.itemClose}>
                    <IconButton
                        size="medium"
                        onClick={remove}
                    >
                        <Clear/>
                    </IconButton>
                </Grid>
                <Grid item xs={7}>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                        {values.is_unit_price ? <Grid
                            item xs={12}
                            className={classes.column}
                        >
                            <Field
                                fullWidth
                                name={`lots.${index}.max_contract_price`}
                                type="number"
                                step={0.01}
                                required
                                label="Максимальная цена договора"
                                component={CustomTextField}
                            />
                        </Grid> : null}
                        <Grid item xs={6} className={classes.column}>
                            <Field
                                fullWidth
                                name={`lots.${index}.number`}
                                type="text"
                                label="Лот №"
                                component={CustomTextField}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.column}>
                            <Field
                                fullWidth
                                name={`lots.${index}.params.information`}
                                type="text"
                                label="Сведения о позиции плана закупки"
                                component={CustomTextField}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.column}>
                            <Field
                                fullWidth
                                name={`lots.${index}.params.subject`}
                                type="text"
                                label="Предмет договора"
                                component={CustomTextField}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.column}>
                            <Field
                                fullWidth
                                name={`lots.${index}.params.description`}
                                type="text"
                                label="Краткое описание предмета закупки"
                                component={CustomTextField}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.column}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    fullWidth
                                    error={Boolean((errors?.hasOwnProperty('lots') && errors?.lots[index] && errors?.lots[index].execution_month) && (touched?.hasOwnProperty('lots') && touched?.lots[index] && touched?.lots[index].execution_month))}
                                    helperText={((errors?.hasOwnProperty('lots') && errors?.lots[index] && errors?.lots[index].execution_month) && (touched?.hasOwnProperty('lots') && touched?.lots[index] && touched?.lots[index].execution_month)) ? errors?.lots[index].execution_month : null}
                                    name={`lots.${index}.execution_month`}
                                    views={["year", "month"]}
                                    orientation="landscape"
                                    variant="inline"
                                    format="MM.yyyy"
                                    openTo="year"
                                    autoOk
                                    disablePast
                                    label="Планируемый месяц исполнения договора"
                                    value={lot.execution_month}
                                    onChange={date => {
                                        setFieldValue(`lots.${index}.execution_month`, date);
                                    }}
                                    onBlur={() => {
                                        setFieldTouched(`lots.${index}.execution_month`, true, true)
                                    }}
                                    onClose={() => {
                                        setFieldTouched(`lots.${index}.execution_month`, true, true)
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6} className={classes.column}>
                            <Field
                                fullWidth
                                type="text"
                                name={`lots.${index}.type`}
                                label="Тип оплаты"
                                select
                                variant="standard"
                                component={TextField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                InputProps={{
                                    onChange: (event) => {
                                        setFieldValue(`lots.${index}.type`, event.target.value, true)
                                        setFieldValue(`lots.${index}.postponement`, '', false)
                                        setFieldValue(`lots.${index}.prepayment`, 1, false)
                                    }
                                }}
                            >
                                {[
                                    {
                                        type: 'complete',
                                        name: 'Предоплата'
                                    },
                                    {
                                        type: 'partial',
                                        name: 'Частичная предоплата'
                                    },
                                    {
                                        type: 'postponement',
                                        name: 'Постоплата'
                                    }
                                ].map((item, i) => (
                                    <MenuItem key={i} value={item.type}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Field>
                        </Grid>
                        {((lot.type === 'postponement') || (lot.type === 'partial')) ? (
                            <Grid item xs={(lot.type === 'postponement') ? 6 : 4} className={classes.column}>
                                <Field
                                    fullWidth
                                    name={`lots.${index}.postponement`}
                                    type="number"
                                    label={`${(lot.type === 'postponement') ? 'Отсрочка платежа, дней' : 'Отсрочка постоплаты, дней'}`}
                                    step={1}
                                    inputProps={{
                                        min: 0
                                    }}
                                    component={CustomTextField}
                                />
                            </Grid>
                        ) : null}
                        {(lot.type === 'partial') ? (
                            <Grid item xs={2} className={classes.column}>
                                <Field
                                    fullWidth
                                    name={`lots.${index}.prepayment`}
                                    type="number"
                                    label="Предоплата"
                                    step={1}
                                    inputProps={{
                                        min: 1,
                                        max: 100
                                    }}
                                    endAdornment={'%'}
                                    component={CustomTextField}
                                />
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                        <Grid item xs={12} className={classes.fullWidth}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                <Grid item xs={!(!!lot.rationale) ? 7 : 12} className={classes.filesUpload}>
                                    <Field
                                        fullWidth
                                        size="small"
                                        component={(props) => (
                                            <FileUpload {...props} endIcon={"Обзор"} tooltip={true}/>
                                        )}
                                        label="Обоснование НМЦ лота"
                                        name={`lots.${index}.rationale`}
                                        InputLabelProps={{
                                            name: lot.rationale ? lot.rationale.name : null
                                        }}
                                        InputProps={{
                                            onChange: (event) => {
                                                setFieldValue(`lots.${index}.rationale`, event.currentTarget.files[0])
                                                setFieldTouched(`lots.${index}.rationale`, true, false)
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.fullWidth}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                <Grid item xs={!(!!lot.draft_agreement) ? 7 : 12} className={classes.filesUpload}>
                                    <Field
                                        fullWidth
                                        size="small"
                                        component={(props) => (
                                            <FileUpload {...props} endIcon={"Обзор"} tooltip={true}/>
                                        )}
                                        label="Проект договора"
                                        name={`lots.${index}.draft_agreement`}
                                        InputLabelProps={{
                                            name: lot.draft_agreement ? lot.draft_agreement.name : null
                                        }}
                                        InputProps={{
                                            onChange: (event) => {
                                                setFieldValue(`lots.${index}.draft_agreement`, event.currentTarget.files[0])
                                                setFieldTouched(`lots.${index}.draft_agreement`, true, false)
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.fullWidth}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                <Grid item xs={!(!!lot.technical_task) ? 7 : 12} className={classes.filesUpload}>
                                    <Field
                                        fullWidth
                                        size="small"
                                        component={(props) => (
                                            <FileUpload {...props} endIcon={"Обзор"} tooltip={true}/>
                                        )}
                                        label="Техническое задание"
                                        name={`lots.${index}.technical_task`}
                                        InputLabelProps={{
                                            name: lot.technical_task ? lot.technical_task.name : null
                                        }}
                                        InputProps={{
                                            onChange: (event) => {
                                                setFieldValue(`lots.${index}.technical_task`, event.currentTarget.files[0])
                                                setFieldTouched(`lots.${index}.technical_task`, true, false)
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FieldArray
                            name={`lots[${index}]files`}
                            render={arrayHelpers => (
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2} style={{maxWidth: "calc(100% + 9px)"}}>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" justify="flex-start" className={classes.fileRows} alignItems="flex-start" spacing={2}>
                                            {lot.files.map((el, idx) => (
                                                <React.Fragment key={idx}>
                                                    <Grid item xs={5}>
                                                        <Field
                                                            fullWidth
                                                            style={{
                                                                marginTop: "5px",
                                                                lineHeight: "16px",
                                                                fontSize: "16px",
                                                                padding: "4px 0",
                                                                paddingTop: "5px",
                                                            }}
                                                            name={`lots[${index}]files[${idx}].description`}
                                                            type="text"
                                                            multiline
                                                            rows="1"
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Field
                                                            fullWidth
                                                            size="small"
                                                            component={(props) => (
                                                                <FileUpload {...props} endIcon={"Обзор"}/>
                                                            )}
                                                            name={`lots.${index}.files.${idx}.file`}
                                                            InputLabelProps={{
                                                                name: lot.files && lot.files.length && lot.files[idx] && lot.files[idx].file && lot.files[idx].file.name ? lot.files[idx].file.name : null
                                                            }}
                                                            InputProps={{
                                                                onChange: (event) => {
                                                                    setFieldValue(`lots.${index}.files.${idx}.file`, event.currentTarget.files[0])
                                                                    setFieldTouched(`lots.${index}.files.${idx}.file`, true, false)
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1} style={{padding: "8px 0"}}>
                                                        <IconButton
                                                            size="small"
                                                            style={{padding: "3px 0"}}
                                                            onClick={() => {
                                                                arrayHelpers.remove(idx)
                                                            }}
                                                        >
                                                            <Clear/>
                                                        </IconButton>
                                                    </Grid>
                                                </React.Fragment>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={5}/>
                                    <Grid item xs={7} style={{display: "flex", justifyContent: "flex-start"}}>
                                        <ButtonIcon
                                            className={classes.button}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                arrayHelpers.insert(lot.files.length, {
                                                    file: null,
                                                    description: ''
                                                })
                                            }}
                                            children={<AddIcon/>}
                                        />
                                    </Grid>
                                </Grid>
                            )}/>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FieldArray
                        name={`lots.${index}.items`}
                        render={arrayHelpers => (
                            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                {lot.items.map((item, i) => (
                                    <Item
                                        key={`items.${i}`}
                                        i={i}
                                        index={index}
                                        category={category}
                                        currency={currency}
                                        settings={settings}
                                        getStandards={getStandards}
                                        changeStandards={changeStandards}
                                        item={item}
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        setValues={setValues}
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                        remove={() => {
                                            if (item.standard) {
                                                changeStandards([item.standard.id], 'remove')
                                            }

                                            const lots = values.lots.map((lot, idx) => {
                                                let items = lot.items

                                                if (idx === index) {
                                                    items = items.map((item, j) => {
                                                        return (j === i) ? {
                                                            ...item,
                                                            ...{
                                                                price: 0
                                                            }
                                                        } : item
                                                    })
                                                }

                                                return {
                                                    ...lot,
                                                    ...{
                                                        items: items,
                                                        price: items.reduce(function (sum, item) {
                                                            return sum + parseFloat(item.price)
                                                        }, 0) ?? ''
                                                    }
                                                }
                                            })

                                            setValues({
                                                ...values,
                                                lots: lots,
                                                price: lots.reduce(function (sum, lot) {
                                                    return sum + parseFloat(lot.price)
                                                }, 0) ?? ''
                                            })

                                            arrayHelpers.remove(i)
                                        }}
                                    />
                                ))}
                                <Grid item xs={8}>
                                    <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={2}>
                                        <Grid item xs={8}>
                                            <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                                                <Grid item>
                                                    <Submit
                                                        size="small"
                                                        variant="contained"
                                                        component="label"
                                                        color="primary"
                                                        endIcon={<AddIcon/>}
                                                        onClick={() => {
                                                            arrayHelpers.push({
                                                                standard: '',
                                                                amount: '',
                                                                average_price: '',
                                                                unit: '',
                                                                price: '',
                                                                expiration_date: null,
                                                                expiration_date_type: ((getCategoriesWithWarrantyPeriod().indexOf(category?.id) !== -1) ? 'warranty_period' : 'expiration_date'),
                                                                expiration_date_number: ''
                                                            })
                                                        }}
                                                    >
                                                        Добавить объект закупки
                                                    </Submit>
                                                </Grid>
                                                {(errors?.lots?.[index]?.items?.length) ? (
                                                    <Grid item>
                                                        <Typography className={classes.error}>
                                                            Для публикации создайте объект закупки!
                                                        </Typography>
                                                    </Grid>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                        {(lot.items.length > 1) ? (
                                            <Grid item xs={2}>
                                                <Field
                                                    fullWidth
                                                    name={`lots.${index}.price`}
                                                    label="Итого"
                                                    type="number"
                                                    step={0.01}
                                                    inputProps={{min: 0}}
                                                    disabled
                                                    endAdornment={currency ? <InputAdornment position="end">{currencies.find(el => el.id === currency).code === 'RUB' ? '₽' : '₸'}</InputAdornment> : null}
                                                    component={CustomTextField}
                                                />
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </Grid>
                                {errors?.averagePriceTotal ? (
                                    <Grid item>
                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                            <Grid item>
                                                <Typography className={classes.error}>{errors?.averagePriceTotal}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : null}
                            </Grid>
                        )}/>
                </Grid>
            </Grid>
        </Group>
    )
}
