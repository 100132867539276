import {HttpService} from "../../App/services/http"

export const DictionaryService = {
    addToSelection,
    selectedStandards,
    deleteSelected
}

function selectedStandards(type, params) {
    return HttpService.get(`/dictionary/standards/${type}`, params)
}

function addToSelection(standards) {
    return HttpService.post("/dictionary/standards", standards)
}

function deleteSelected(ids) {
    return HttpService.deleteMethod("/dictionary/standards/selected", ids)
}
