import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce} from 'use-debounce'
import format from "date-fns/format"
import clsx from "clsx"
import {Table} from "@devexpress/dx-react-grid-material-ui";

import {Grid, InputAdornment, makeStyles, TextField} from "@material-ui/core"
import {CommentOutlined as CommentOutlinedIcon, Search as SearchIcon} from "@material-ui/icons"

import {Submit} from "../../../../App/components/Button/Submit"
import {AuthorizationService} from "../../../../Auth/services/authorization"
import {getMonthName} from "../../../../App/helpers/date"

import {
    IntegratedSummary,
} from '@devexpress/dx-react-grid'
import {green} from "@material-ui/core/colors";
import {ColumnChooser} from "../../../../App/components/Table/ColumnChooser";
import {ButtonIcon} from "../../../../App/components/Button/ButtonIcon";
import {PurchasePlanItemsActions} from "../../../actions/purchasePlanItems";
import GetAppIcon from "@material-ui/icons/GetApp";
import {JointPurchase} from "../Purchase/JointPurchase";
import {PlanningActions} from "../../../actions/planning";
import {SettingsActions} from "../../../../Settings/actions/settings";
import {SystemActions} from "../../../../App/actions/system";
import * as AppStorage from "../../../../App/helpers/storage";
import {Chat} from "../../Comment/Chat";
import {ChatActions} from "../../../actions/comment/chat";
import {DownloadActions} from "../../../../Download/actions/download";
import {CustomTable} from "../../../../App/components/Table/Table";
import {TableHeader} from "../../../../App/components/Table/TableHeader";
import {Copy as CopyButton} from "../../Products/Buttons/Copy";
import {Move as MoveButton} from "../../Products/Buttons/Move";
import {Delete as DeleteButton} from "../../Products/Buttons/Delete";
import {localState} from "../../../../App/components/LocalState";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.planningContent.width,
        padding: theme.planningContent.padding,
        margin: theme.planningContent.margin,
    },
    container: {
        "background-image": "linear-gradient(to bottom,#f6f6f6,#eee 85%,#b1b1b1)",
        "width": "100%",
        "height": "calc(100vh - 256px)"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    filterLabel: {
        "background": "#fff",
        "margin-bottom": "0 !important",
        "margin-top": "5px !important",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        },
        "& input": {
            padding: "6px 15px 7px"
        }
    },
    tableContainer: {
        "min-height": "100%",
        "height": "calc(100vh - 400px)"
    },
    tableItemsContainer: {
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    searchLabel: {
    },
    searchInput: {
        "width": "calc(100% - 83px)"
    },
    searchButton: {
        "margin-bottom": "10px"
    },
    fullWidth: {
        "width": "100%"
    },
    inputContentMain: {
        "width": "100%",
        "height": theme.planningContent.height,
        "min-height": theme.planningContent.height,
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    footer: {
        width: "100%",
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    },
    tabs: {
        "&.MuiTab-textColorPrimary.Mui-selected": {
            backgroundColor: "#fff",
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "4px",
        }
    },
    fontSubtitle: {
        fontWeight: 700
    },
    submit: {
        "height": "35px",
    },
    accordion: {
        "& .MuiAccordionSummary-content" : {
            "max-width": "calc(100% - 32px)",
        }
    },
    acDetails: {
        padding: "16px 0"
    },
    active: {
        'height': '41px',
        'background-color': `${green[100]} !important`,
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        fontSize: '16px',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    disabled: {
        'height': '41px',
        'background-color': 'rgba(244, 244, 244, 1)',
        '&:hover': {
            'background-color': `${green[50]} !important`
        },


        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    iconButton: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    },
    backIconButton: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "rgba(0, 0, 0, 0.1)",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        position: 'absolute',
        left: '205px',
        top: '42px',
        "color": "rgba(0, 0, 0, 0.54)",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
        }
    },
    dialogContent: {
        "max-height": "calc(100vh - 210px)",
        minHeight: "calc(100% - 64px)"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 150,
    },
    circle: {
        width: "25px",
        height: "25px",
        backgroundColor: "#ea4a4a",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        "&>span": {
            lineHeight: "20px"
        }
    },
}))

const productColumns = [
    { name: 'comment_counter', title: ' '},
    { name: 'number', title: 'Номер ОЗ'},
    { name: 'name', title: 'Начальное наименование' },
    { name: 'standard_name', title: 'Эталонное наименование' },
    { name: 'typed_name', title: 'Типизированное наименование' },
    { name: 'category_name', title: 'Категория', filter: 'categories' },
    { name: 'subcategory', title: 'Подкатегория', filter: 'subcategories' },
    { name: 'group', title: 'Группа', filter: 'groups' },
    { name: 'unit', title: 'Ед. измерения'},
    { name: 'funding_sources', title: 'Источник финансирования'},
    { name: 'generic', title: 'МНН' },
    { name: 'purchase_number', title: 'Закупка'},
    { name: 'lot_number', title: 'Лот'},
    { name: 'lot_name', title: 'Наименование лота'},
    { name: 'price', title: 'Цена'},
    { name: 'year', title: 'Год', filter: 'year'},
    { name: 'month', title: 'Месяц', filter: 'month'},
    { name: 'amount', title: 'Кол-во'},
    { name: 'total', title: 'Сумма'},
    { name: 'railRoad', title: 'ЖД', filter: 'rail_roads' },
    { name: 'region', title: 'Макрорегион', filter: 'regions' },
    { name: 'company', title: 'ЧУЗ', filter: 'companies' },
    { name: 'creator', title: 'Создатель', filter: 'creator'},
    { name: 'created_at', title: 'Дата создания', filter: 'created_at'},
    { name: 'editor', title: 'Редактор' },
    { name: 'updated_at', title: 'Дата редактирования' },
    { name: 'main_technical_specifications', title: 'Технические характеристики' },
    { name: 'analogues', title: 'Аналоги' },
    { name: 'equipment', title: 'Оборудование' },
    { name: 'medical_code', title: 'Код вида мед. изделия' },
    { name: 'exclusivity', title: 'Обоснование' },
    { name: 'description', title: 'Описание' }
]

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {account} = useSelector(state => ({...state.account, ...state.purchasePlans }))
    const {categories} = useSelector(state => state.system)
    const {units} = useSelector(state => state.purchasePlans)
    const [products, setProducts] = useState({
        ...localState,
        agg_info: {
            total_amount: 0,
            total_sum: 0,
        }
    })
    const [meta, setMeta] = useState(localState.meta)
    const {filter} = useSelector(state => state.filters['planning_purchase_plan_products'])
    const [hiddenColumnNamesProduct, setHiddenColumnNamesProduct] = useState([]);

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)
    const [initialize, setInitialize] = useState(false);
    const [dialogJointPurchase, setDialogJointPurchase] = useState(false)
    const [tableRef, setTableRef] = useState(null)
    const [loading, setLoading] = useState(false)

    const [tableColumnExtensions] = useState([
        { columnName: 'amount', align: 'right' },
        { columnName: 'total', align: 'right' },
    ]);

    const [totalSummaryItems] = useState([
        { columnName: 'amount', type: 'total_amount' },
        { columnName: 'total', type: 'total_sum' },
    ]);

    const messages = {
        total_amount: 'Кол-во',
        total_sum: 'Итого',
    };

    const [searchProducts, setSearchProducts] = useState(null)
    const [searchRequestProducts] = useDebounce(searchProducts, 900)

    const [selection, setSelection] = useState([])
    const [selectionItems, setSelectionItems] = useState([])
    const [selectionPage, setSelectionPage] = useState({})

    const [columnProductWidths, setColumnProductWidths] = useState([
        { columnName: 'comment_counter', width: 50 },
        { columnName: 'number', width: 150 },
        { columnName: 'name', width: 150 },
        { columnName: 'standard_name', width: 150 },
        { columnName: 'typed_name', width: 150 },
        { columnName: 'category_name', width: 200 },
        { columnName: 'subcategory', width: 200 },
        { columnName: 'group', width: 200 },
        { columnName: 'unit', width: 125 },
        { columnName: 'funding_sources', width: 200 },
        { columnName: 'generic', width: 125 },
        { columnName: 'purchase_number', width: 125 },
        { columnName: 'lot_number', width: 125 },
        { columnName: 'lot_name', width: 150 },
        { columnName: 'price', width: 125 },
        { columnName: 'year', width: 125 },
        { columnName: 'month', width: 125 },
        { columnName: 'amount', width: 150 },
        { columnName: 'total', width: 150 },
        { columnName: 'railRoad', width: 125 },
        { columnName: 'region', width: 125 },
        { columnName: 'company', width: 150 },
        { columnName: 'creator', width: 125 },
        { columnName: 'created_at', width: 125 },
        { columnName: 'editor', width: 125 },
        { columnName: 'updated_at', width: 150 },
        { columnName: 'main_technical_specifications', width: 150 },
        { columnName: 'analogues', width: 150 },
        { columnName: 'equipment', width: 150 },
        { columnName: 'medical_code', width: 150 },
        { columnName: 'exclusivity', width: 150 },
        { columnName: 'description', width: 150 }
    ])

    const [columnOrderProduct, setColumnOrderProduct] = useState([
        'comment_counter',
        'number',
        'name',
        'standard_name',
        'typed_name',
        'category_name',
        'subcategory',
        'group',
        'unit',
        'funding_sources',
        'generic',
        'purchase_number',
        'lot_number',
        'lot_name',
        'price',
        'year',
        'month',
        'amount',
        'total',
        'railRoad',
        'region',
        'company',
        'creator',
        'created_at',
        'editor',
        'updated_at',
        'main_technical_specifications',
        'analogues',
        'equipment',
        'medical_code',
        'exclusivity',
        'description'
    ])

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...((filter.hasOwnProperty('companies') && !!filter.companies.length) ? {companies: filter.companies} : {}),
            ...((filter.hasOwnProperty('rail_roads') && !!filter.rail_roads.length) ? {rail_roads: filter.rail_roads} : {}),
            ...((filter.hasOwnProperty('categories') && !!filter.categories.length) ? {categories: filter.categories} : {}),
            ...((filter.hasOwnProperty('subcategories') && !!filter.subcategories.length) ? {subcategories: filter.subcategories} : {}),
            ...((filter.hasOwnProperty('groups') && !!filter.groups.length) ? {groups: filter.groups} : {}),
            ...((filter.hasOwnProperty('month') && !!filter.month.length) ? {month: filter.month} : {}),
            ...((filter.hasOwnProperty('year') && !!filter.year.from.length) ? {planning_year_from: filter.year.from} : {}),
            ...((filter.hasOwnProperty('year') && !!filter.year.to.length) ? {planning_year_to: filter.year.to} : {}),
            ...((filter.hasOwnProperty('sources') && !!filter.sources.length) ? {sources: filter.sources} : {}),
            ...((filter.hasOwnProperty('purchase') && !!filter.purchase.length) ? {purchase: filter.purchase} : {}),
            ...(searchRequestProducts ? {search: searchRequestProducts} : {})
        }
    }

    const getProducts = async () => {
        let params = {
            limit: rowsPerPage,
            page: page,
            simple: true,
            ...getParams()
        }
        return await dispatch(PurchasePlanItemsActions.items(params))
    }

    const getTotal = async () => {
        let params = {
            limit: rowsPerPage,
            page: page,
            ...getParams()
        }

        return await dispatch(PurchasePlanItemsActions.total(params))
    }


    useEffect(() => {
        if (!loading) {
            getTotal().then(response => {
                setMeta({
                    ...meta,
                    ...response
                })
            })

            getProducts().then(response => {
                setProducts(response)

                let numbers = []

                if (selectionPage.hasOwnProperty(page)) {
                    response.data.forEach((item, index) => {
                        if (selectionPage[page].find(id => (id === item.id))) {
                            numbers.push(index)
                        }
                    })
                }

                setSelection(numbers)

                setLoading(true)
                tableRef && tableRef.current && tableRef.current.scrollIntoView()
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loading])

    useEffect(() => {
        if (loading) {
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [page, rowsPerPage, searchRequestProducts, filter])

    useEffect(() => {
        if (!dialogJointPurchase && initialize) {
            setSelection([])
            setSelectionItems([])
            setSelectionPage({})
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [dialogJointPurchase]);

    useEffect(() => {
        if (!initialize) {
            const getData = async () => {
                await dispatch(PlanningActions.units())
            }

            getData().then(() => {})

            if (!categories.length) {
                dispatch(SystemActions.categories())
            }

            dispatch(SettingsActions.table('planning_products')).then((settings) => {
                if (settings) {
                    setHiddenColumnNamesProduct(settings.hidden)
                    setColumnOrderProduct(settings.order)
                }
                dispatch({type: 'PURCHASE_PLAN_PRODUCTS_IN_PURCHASES_FILTER_CLEAR'})
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'planning_products',
                hidden: hiddenColumnNamesProduct,
                order: columnOrderProduct
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNamesProduct, columnOrderProduct])

    const summaryCalculator = (type, rows, getValue) => {
        switch (type) {
            case 'total_amount':
                return parseFloat(products.agg_info.total_amount).toLocaleString('ru-RU');
            case 'total_sum':
                return parseFloat(products.agg_info.total_sum).toLocaleString('ru-RU', {style: 'currency', currency: 'RUB',});
            default:
                return IntegratedSummary.defaultCalculator(type, rows, getValue);
        }
    };

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: 'PLANNING_PURCHASE_PLAN_PRODUCTS_FILTER', payload: {limitRows: newRowsPerPage}})
    }

    const [openComment, setOpenComment] = useState(false)

    const [chat, setChat] = useState({
        id: '',
        title: ''
    })

    const closeComment = () => {
        setOpenComment(false)
        setChat({
            id: '',
            title: ''
        })
    }

    const createChat = async (values) => {
        return await dispatch(ChatActions.create(values))
    }

    const addUserToChat = async (chatId) => {
        return await dispatch(ChatActions.addUserToChat(chatId))
    }

    return (initialize && !!units.length) ? (
        <Grid item className={classes.content}>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    {openComment && chat.id &&
                        <Chat anchor={'right'} open={openComment} chatID={chat.id} title={chat.title} onClose={closeComment} user={account} token={AppStorage.get('token')}/>
                    }
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                        <Grid item xs={12} style={{marginBottom: '-8px'}}>
                            <TextField
                                id="searchProducts"
                                variant="standard"
                                name={'searchProducts'}
                                placeholder={`Поиск: наименование объекта закупки, начальное наименование, номер закупки, МНН`}
                                fullWidth={true}
                                margin={'normal'}
                                onChange={(event) => {
                                    setSearchProducts(event.target.value)
                                }}
                                classes={{
                                    root: clsx(classes.filterLabel, classes.searchLabel)
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon  style={{color: '#c1c1c1'}}/>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                {AuthorizationService.roles(account, 'supervisor') && account.organization !== 'regional' &&
                                    <Grid item>
                                        <Submit
                                            className={classes.submit}
                                            disableElevation
                                            variant="contained"
                                            color="primary"
                                            disabled={!!(
                                                (selectionPage[page]?.map(x => x.category?.index).filter((value, index, self) => (self.indexOf(value) === index)).length !== 1) ||
                                                selectionPage[page]?.find(x => [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22].includes(x.purchase_status_index)) ||
                                                (selectionPage[page]?.map(x => x.purchase_plan_year).filter((value, index, self) => (self.indexOf(value) === index)).length !== 1) ||
                                                selectionPage[page]?.filter(x => (x.joint || x.item_id || !x.purchase_id)).length
                                            )}
                                            type="button"
                                            onClick={() => {
                                                setDialogJointPurchase(true)
                                            }}
                                        >
                                            Создать совместную закупку
                                        </Submit>
                                    </Grid>
                                }
                                <Grid item>
                                    <Submit
                                        className={classes.submit}
                                        disableElevation
                                        disabled={Boolean(selectionItems.length !== 1)}
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        onClick={() => {
                                            let product = selectionItems[0];
                                            if (product.chat_id) {
                                                let chat_id = product.chat_id
                                                addUserToChat(chat_id).then(() => {
                                                    setChat({
                                                        id: chat_id,
                                                        title: `Комментарии к ОЗ ${product.name}`
                                                    })
                                                    setOpenComment(true)
                                                })
                                            } else {
                                                createChat({itemId: product.id}).then(resp => {
                                                    setChat({
                                                        id: resp.chat.id,
                                                        title: `Комментарии к ОЗ ${product.name}`
                                                    })
                                                    setOpenComment(true)
                                                })
                                            }
                                        }}
                                    >
                                        Комментарии
                                    </Submit>
                                </Grid>
                                <CopyButton
                                    items={selectionItems}
                                    onClick={() => {
                                        setSelection([])
                                        setSelectionItems([])
                                        setSelectionPage({})
                                        setLoading(false)
                                        dispatch({type: 'PURCHASE_PLAN_ITEMS_BY_PARENT_IDS_CLEAR'})
                                    }}
                                />
                                <MoveButton
                                    items={selectionItems}
                                    onClick={() => {
                                        setSelection([])
                                        setSelectionItems([])
                                        setSelectionPage({})
                                        setLoading(false)
                                        dispatch({type: 'PURCHASE_PLAN_ITEMS_BY_PARENT_IDS_CLEAR'})
                                    }}
                                />
                                <DeleteButton
                                    items={selectionItems}
                                    onClick={() => {
                                        setSelection([])
                                        setSelectionItems([])
                                        setSelectionPage({})
                                        setLoading(false)
                                    }}
                                />
                                <Grid item>
                                    <ButtonIcon
                                        type="button"
                                        size="small"
                                        disabled={!meta.total}
                                        onClick={() => {
                                            dispatch(DownloadActions.create({
                                                type: 'planning_items',
                                                columns: columnOrderProduct.filter(name => (hiddenColumnNamesProduct.indexOf(name) < 0)).map(name => {
                                                    switch (name) {
                                                        case 'category_name':
                                                            return 'category';
                                                        default:
                                                            return name;
                                                    }
                                                }),
                                                params: {
                                                    order: (filter.sort.name && filter.sort.direction) ? `${filter.sort.name}, ${filter.sort.direction}` : `created, desc`,
                                                    type: 'planning_items',
                                                    ...Object.entries(getParams()).reduce((result, [key, value]) => {
                                                        result[key] = Array.isArray(value) ? value.join(',') : value

                                                        return result
                                                    }, {})
                                                }
                                            }))
                                        }}
                                        children={<GetAppIcon/>}
                                        title='Скачать таблицу'
                                        className={classes.iconButton}
                                    />
                                </Grid>
                                <Grid item>
                                    <ColumnChooser columns={productColumns} hiddenColumnNames={hiddenColumnNamesProduct} setHiddenColumnNames={setHiddenColumnNamesProduct} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <CustomTable
                    meta={meta}
                    tableName={'planning_purchase_plan_products'}
                    rows={products.data.map(product => ({
                        number: product.number,
                        name: product.name,
                        standard: product.standard,
                        standard_name:  product.standard?.name.standard,
                        typed_name: product.standard?.confirmed ? product.standard?.name.typed : null,
                        category_name: product.category?.dependencies[0],
                        subcategory: product.category?.dependencies[1],
                        group: product.category?.dependencies[2],
                        category: product.category,
                        unit: product.unit?.short,
                        funding_sources: product.funding_sources ? product.funding_sources.join(', ') : '',
                        purchase_number: product.purchase_number,
                        lot_number: product.lot_number,
                        lot_name: product.lot_name,
                        price: parseFloat(product.price).toLocaleString('ru-RU', {style: 'currency', currency: 'RUB',}),
                        generic: product.standard?.params?.generic,
                        year: (new Date(product.demand_start_date)).getFullYear(),
                        month: getMonthName(new Date(product.demand_start_date)),
                        amount: parseFloat((!product.amount || product.joint) ? 0 : product.amount).toLocaleString('ru-RU'),
                        total: parseFloat(product.price * product.amount).toLocaleString('ru-RU', {style: 'currency', currency: 'RUB'}),
                        railRoad: product.railRoad,
                        region: product.region,
                        company: product.company,
                        created_at: format(new Date(product.created_at), 'dd.MM.yyyy'),
                        creator: product.creator,
                        updated_at: product.editor ? format(new Date(product.updated_at), 'dd.MM.yyyy') : null,
                        editor: product.editor,
                        item_id: product.item_id,
                        joint: product.joint,
                        main_technical_specifications: product.main_technical_specifications ? product.main_technical_specifications.map(spec => (<p style={{margin: '5px 10px'}}>{spec}</p>)) : null,
                        analogues: product.analogues ? product.analogues.map(analogues => (<p style={{margin: '5px 10px'}}>{analogues}</p>)) : null,
                        equipment: product.equipment,
                        medical_code: product.medical_code,
                        exclusivity: product.exclusivity,
                        description: product.description,
                        chat_id: product.chat_id
                    }))}
                    columns={productColumns}
                    page={{page: page, setPage: setPage, rowsPerPage: rowsPerPage, handlePageSizeChange: handlePageSizeChange}}
                    selection={{selection: selection, setSelection: setSelection}}
                    totalSummaryItems={totalSummaryItems}
                    summaryCalculator={summaryCalculator}
                    setTableRef={setTableRef}
                    tableColumnExtensions={tableColumnExtensions}
                    columnsSettings={{columnOrder: columnOrderProduct, setColumnOrder: setColumnOrderProduct, setColumnWidths: setColumnProductWidths, columnWidths: columnProductWidths, hiddenColumnNames: hiddenColumnNamesProduct, setHiddenColumnNames: setHiddenColumnNamesProduct}}
                    tableHeader={TableHeader}
                    filterActionType={'PLANNING_PURCHASE_PLAN_PRODUCTS_FILTER'}
                    getValues={PurchasePlanItemsActions.filter}
                    messages={messages}
                    main
                    classInputContent={classes.inputContentMain}
                    selectedItems={{selectedItems: selection, setSelectedItems: setSelection}}
                    onSelectionChange={(numbers) => {
                        setSelection(numbers)
                        const data = products.data.filter((item, index) => (numbers.find(key => (key === index)) !== undefined)).map(item => item)
                        setSelectionItems([
                            ...data,
                            ...selectionItems.filter(item => !products.data.find(el => el.id === item.id))
                        ])
                        setSelectionPage(!!data.length ? {...selectionPage, [page]: data.map(item => item.id)} : Object.fromEntries(
                            Object.entries(selectionPage).filter(key => (parseInt(key) !== parseInt(page))).map(([key, value]) => [key, value])
                        ))
                    }}
                    rowComponentSelection={(props) => {
                        const { tableRow, children, highlighted, onToggle } = props
                        const { row } = tableRow

                        if (Object.keys(products.agg_info.chats_with_count_not_read_comments).length > 0 && row.chat_id && products.agg_info.chats_with_count_not_read_comments[row.chat_id]) {
                            row.count_unread_messages = products.agg_info.chats_with_count_not_read_comments[row.chat_id]
                        } else {
                            row.count_unread_messages = 0
                        }

                        row['comment_counter'] =
                            <div className={classes.circle}>
                                <span>{row.count_unread_messages}</span>
                            </div>

                        return (
                            <Table.Row
                                tableRow={tableRow}
                                children={children}
                                onClick={() => {
                                    if (!window.getSelection().toString()) {
                                        onToggle()
                                    }
                                }}
                                className={highlighted ? classes.active : (row.joint ? classes.disabled : classes.default)}
                                row={row}
                                style={{ ...({backgroundColor: AuthorizationService.roles(account, ['customer', 'supervisor']) ? (row.category?.active ? (row.standard?.deleted ? '#ffcccc' : (!row.standard?.confirmed ? '#fffde7' : {})) : {}) : {} })}}
                            />
                        )
                    }}
                    filters={{
                        year: {
                            name: 'year',
                            type: 'year',
                        },
                        category_name: {
                            name: 'categories',
                            type: 'values',
                        },
                        subcategory: {
                            name: 'subcategories',
                            type: 'values',
                        },
                        group: {
                            name: 'groups',
                            type: 'values',
                        },
                        railRoad: {
                            name: 'rail_roads',
                            type: 'values',
                        },
                        region: {
                            name: 'regions',
                            type: 'values',
                        },
                        company: {
                            name: 'companies',
                            type: 'values',
                        },
                        month: {
                            name: 'month',
                            type: 'month',
                        },
                        funding_sources: {
                            name: 'sources',
                            type: 'source',
                        },
                        purchase_number: {
                            name: 'purchase',
                            type: 'purchase_number',
                        },
                        comment_counter: {
                            button: <CommentOutlinedIcon
                                fontSize="small"
                                style={{display: "block", margin: "auto"}}
                            />
                        }
                }}
                />
            </Grid>
            {dialogJointPurchase ? <JointPurchase open={dialogJointPurchase} setDialogJointPurchase={setDialogJointPurchase} items={selectionItems} /> : null}
        </Grid>
    ) : null
}
