import {ItemService} from "../../services/AdditionalAgreement/item"

export const ItemActions = {
    create,
    update,
    remove
}

function create(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        ItemService.create(id, values)
            .then(
                item => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(item)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )
    })
}

function update(contract, id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        ItemService.update(contract, id, values)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )
    })
}

function remove(contract, id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        ItemService.remove(contract, id)
            .then(
                response => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(response)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                        message: error.message,
                        type:'error'
                    }})
                    reject(error)
                }
            )
    })
}
