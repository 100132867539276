import {HttpService} from "../../App/services/http"

export const EmployeeService = {
    employees,
    add,
    edit
}

function employees(params) {
    return HttpService.get(`/company/employees`, params)
}

function add(id, values) {
    return HttpService.post(`/company/${id}/employee`, values)
}

function edit(company_id, employee_id, values) {
    return HttpService.put(`/company/${company_id}/employee/${employee_id}`, values)
}
