import React from "react"
import {Link} from "react-router-dom"
import {useSelector} from "react-redux"

import {Grid, List, ListItem, ListItemText, makeStyles, withStyles} from '@material-ui/core'

import {AuthorizationService} from "../Auth/services/authorization"

const useStyles = makeStyles(() => ({
    active: {
        "background-image": "none !important",
        "background-color": "#898989"
    },
    fullWidth: {
        width: "100%",
    },
    container: {
        height: "100%",
        width: "100%",
        margin: 0,
    }
}))

const CustomList = withStyles({
    root: {
        "padding": "0",
        "& .MuiListItem-button": {
            "background-image": "linear-gradient(to bottom,#e4e4e4,#f4f4f4)",
            "cursor": "pointer",
            "align-items": "center",
            "min-height": "50px",
            "padding": "5px 25px 0",
            "border-bottom": "1px solid #e4e4e4",
            "outline": "0"
        },
        "& .MuiListItemText-primary": {
            "font-size": "16px",
            "font-weight": "700",
            "text-transform": "uppercase"
        },
        "& .MuiListItem-button:hover": {
            "background-color": "#898989",
            "&:after": {
                "position": "absolute",
                "content": "''",
                "top": "0",
                "left": "0",
                "width": "100%",
                "height": "100%",
                "background-color": "rgba(0,0,0,.04)"
            }
        }
    }
})(List);

export const Menu = (props) => {
    const classes = useStyles()
    const {account} = useSelector(state => state.account)
    const {location} = props

    const list = [
        ...((AuthorizationService.roles(account, "supervisor") && (account.organization === 'central')) ? [
            {title: "Общие план-графики", to: "/planning/schedules", type: 'menu'}
        ] : []),
        {title: "Планы закупок", to: "/planning/purchase-plans", type: 'menu'},
        {title: "Закупки", to: "/planning/purchases", type: 'menu'},
        {title: "Лоты", to: "/planning/lots", type: 'menu'},
        {title: "Объекты закупок", to: "/planning/products", type: 'menu'},
        {title: "Корректировки закупок", to: "/planning/purchases/edit-requests", type: 'menu'},
        ...((AuthorizationService.permissions(account, "access_to_planning_settings") && AuthorizationService.roles(account, "supervisor")) ? [
            {title: "Настройки", to: "/planning/settings", type: 'menu'}
        ] : []),
        {title: "Корректировки планов закупок", to: "/planning/purchase-plans/edit-requests", type: 'menu'},
        ...((account.organization !== 'regional') ? [
            {title: "Совместные закупки", to: "/planning/joint-purchases", type: 'menu'},
        ] : []),
    ]

    return (
        <React.Fragment>
            <Grid container direction="column" justify="space-between" alignItems="center" className={classes.container}>
                <Grid item className={classes.fullWidth}>
                    <CustomList>
                        {list.map(option => (
                            <ListItem button key={option.title} component={Link} to={option.to}
                                      classes={{root: (location.pathname === option.to) ? classes.active : undefined}}>
                                <ListItemText primary={option.title}/>
                            </ListItem>
                        ))}
                    </CustomList>
                </Grid>
            </Grid>
        </React.Fragment>
)
}
