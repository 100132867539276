import React from "react"
import {ru} from "date-fns/locale"

import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Input,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@material-ui/core"
import {Close as CloseIcon, Publish as PublishIcon} from "@material-ui/icons"
import {Submit} from "../../../App/components/Button/Submit";
import {ButtonIcon} from "../../../App/components/Button/ButtonIcon"
import {endings} from "../../../App/helpers/endings"
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"
import {PriceActions} from "../../actions/price";
import {v4 as uuidv4} from "uuid";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    select: {
        'padding-left': "10px",
        "div": {
            marginTop: "0 !important",
        },
        "&:focus": {
            "background-color": "#fff",
        },
    },
    textField: {
        width: "100%",
    },
    label: {
        fontSize: "16px",
        width: "auto",
        height: "auto",
        marginBottom: "5px",
    },
    borderRightNone: {
        "border-right": "none",
    },
    padLeft: {
        "padding-left": "0",
    },
    title: {
        "margin": "0",
        "padding-top": "2px",
        "font-size": "18px",
        "font-weight": "600",
        "color": "#fff",
        "text-transform": "uppercase",
        "display": "flex",
        "justify-content": "space-between",
        "align-items": "center",
        "min-height": "40px",
        "padding": "0 20px",
        "background-color": "#898989",
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    footerContainer: {
        "padding": "0 25px",
        "background-color": "#e7eaee",
        "height": "75px",
    },
    submit: {
        "width": "100%",
        "border-bottom": "3px solid #898989",
        "border-radius": "2px",
        "padding": "5px 10px 0",
        "min-width": "95px",
        "min-height": "35px",
        "font-size": "16px",
        "font-weight": "500",
        "color": "white",
        "outline": "0",
        "height": "35px",
        "&:disabled": {
            "background-color": "#c1c1c1",
            "border-bottom-color": "#898989 !important",
            "color": "white"
        }
    },
    dialogContent: {
        padding: "0",
        "overflow": "hidden",
    },
    dialogContainer: {
        "& > div": {
            "max-width": "calc(100vw - 200px)"
        }
    },
    column: {
        'margin': '20px',
    },
    priceNames: {
        maxWidth: "160px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    download: {
        maxWidth: '54px',
        "&:hover": {
            "background-color": "#898989",
        },
        "width": "auto",
        "min-width": "35px",
        "max-height": "35px",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "#fff",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-bottom": "3px solid #898989",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "#616161",
        "cursor": "pointer",
        "margin": "0",
        "& > span": {
            "padding": "5px 15px",
        }
    },
}));

const updatePeriods = [
    {key: 30, value: 'Каждые 30 минут'},
    {key: 60, value: 'Каждый час'},
    {key: 180, value: 'Каждые 3 часа'},
    {key: 360, value: 'Каждые 6 часов'},
    {key: 720, value: 'Каждые 12 часов'},
    {key: 5040, value: 'Каждые 7 дней'}
];

export const NewPrice = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {company} = useSelector(state => state.company)
    const {
        open,
        onClose,
        priceTypes,
        account,
        getPrices,
        setPriceToDownload,
        priceToDownload,
        downLoadTypesNodes
    } = props;

    const updatePeriodsNodes = updatePeriods.map((obj) =>
        <MenuItem value={obj.key}>{obj.value}</MenuItem>
    )

    const defaultParams = () => {
        setPriceToDownload({
            priceId: uuidv4(),
            url: '',
            downloadType: 'file',
            updatePeriod: '',
            files: null,
            deadline: null,
        })
    }

    const download = (e) => {
        e.stopPropagation()
        if (priceToDownload.downloadType !== 'file' && (priceToDownload.updatePeriod === '' || priceToDownload.url === '')) {
            return dispatch({
                type: "ALERT_ERROR", payload: {
                    message: `Не заполнены поля "Период обновления" или "Прайс-лист"`,
                    type: 'error'
                }
            });
        }

        const regionIds = company && company.terms_participation && company.terms_participation.regions ? [...company.terms_participation.regions.map(el => el.region.id), company.legal_detail.city.region.id] : []
        const type = priceTypes.data.find((type) => type.name === 'public').name
        let body

        if (priceToDownload.downloadType === 'file') {
            body = new FormData();
            body.append("id", priceToDownload.priceId);
            body.append("companyId", account.company.id);
            body.append("region_ids[]", JSON.stringify(regionIds));
            body.append("download_type", JSON.stringify(priceToDownload.downloadType));
            body.append("price_type", JSON.stringify(type));
            body.append("deadline", priceToDownload.deadline.toJSON());
            (priceToDownload.files ? {files: Array.from(priceToDownload.files)} : null).files.forEach(file => {
                body.append("files[]", file, file.name);
            });
        } else {
            body = JSON.stringify({
                id: priceToDownload.priceId,
                companyId: account.company.id,
                updatePeriod: priceToDownload.updatePeriod,
                url: priceToDownload.url,
                region_ids: regionIds,
                type: type,
                deadline: priceToDownload.deadline.toJSON(),
                download_type: JSON.stringify(priceToDownload.downloadType),
            })
        }
        return dispatch(PriceActions.download(body, priceToDownload.downloadType)).then(
            () => {
                getPrices().then(() => {
                    onClose()
                })
                defaultParams()
            }
        )
    }

    const inputLink = event => {
        setPriceToDownload({
            ...priceToDownload,
            url: event.target.value,
        })
    }

    return (
        <Dialog
            aria-labelledby="simple-dialog-title"
            open={open}
            classes={{
                root: classes.dialog,
                container: classes.dialogContainer
        }}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle id="simple-dialog-title" classes={{root: classes.title,}} disableTypography={true}>
                Загрузить прайс-лист <CloseIcon className={classes.close} onClick={onClose}/>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid container className={classes.fullWidth} direction="row" justify="space-evenly" alignItems="flex-start" spacing={2}>
                    <Grid item className={classes.column}>
                        <Typography className={classes.label}>Тип загрузки</Typography>
                        <Select
                            labelId={"downloadType"}
                            id={"downloadType"}
                            label={'тип'}
                            classes={{select: classes.select}}
                            name={"downloadType"}
                            value={priceToDownload.downloadType}
                            onChange={(e) => {
                                if (e.target.value !== 'file') {
                                    setPriceToDownload({
                                        ...priceToDownload,
                                        downloadType: e.target.value,
                                        files: null,
                                        updatePeriod: 30,
                                    })
                                } else {
                                    setPriceToDownload({
                                        ...priceToDownload,
                                        downloadType: e.target.value,
                                        updatePeriod: '',
                                        url: '',
                                    })
                                }
                            }}
                            children={downLoadTypesNodes}
                        />
                    </Grid>
                    <Grid item className={classes.column}>
                        <Typography className={classes.label}>Прайс-лист</Typography>
                        {priceToDownload.downloadType === 'file' &&
                            <React.Fragment>
                                {!priceToDownload.files &&
                                    <Submit variant="contained" component="label" color="primary" className={classes.submit}>
                                        Обзор
                                        <Input
                                            style={{display: "none"}}
                                            inputProps={{
                                                type: 'file',
                                                name: "files",
                                                multiple: true,
                                                onChange: function (event) {
                                                    setPriceToDownload({
                                                        ...priceToDownload,
                                                        files: event.currentTarget.files,
                                                    })

                                                },

                                            }}
                                        />
                                    </Submit>
                                }
                                {priceToDownload.files && priceToDownload.files.length > 0 &&
                                    <Grid container alignItems="center">
                                        <Grid item xs={10}>
                                            <Typography className={classes.priceNames}>
                                                {priceToDownload.files.length === 1 ? Array.from(priceToDownload.files).map(el => el.name).join(', ') : endings(priceToDownload.files.length, ['файл', 'файла', 'файлов'])}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <CloseIcon className={classes.close} onClick={(e) => {
                                                e.stopPropagation();
                                                setPriceToDownload({
                                                    ...priceToDownload,
                                                    files: null,
                                                });
                                            }}/>
                                        </Grid>
                                    </Grid>
                                }
                            </React.Fragment>
                        }
                        {priceToDownload.downloadType !== 'file' &&
                            <TextField classes={{
                                root: classes.textField,
                            }} value={priceToDownload.url} onChange={inputLink}/>
                        }
                    </Grid>
                    <Grid item className={classes.column}>
                        {priceToDownload.downloadType !== 'file' &&
                            <React.Fragment>
                                <Typography className={classes.label}>Период обновления</Typography>
                                <Select
                                    labelId={'update_period'}
                                    id={'update_period'}
                                    name="Период обновления"
                                    classes={{
                                        select: classes.select
                                    }}
                                    value={priceToDownload.updatePeriod ?? null}
                                    onChange={(e) => {
                                        setPriceToDownload({
                                            ...priceToDownload,
                                            updatePeriod: e.target.value,
                                        })
                                    }}
                                    children={updatePeriodsNodes}
                                />
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid item className={classes.column}>
                        <Typography className={classes.label}>Срок действия, до</Typography>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                            <KeyboardDateTimePicker
                                style={{width: "180px"}}
                                disableToolbar
                                fullWidth
                                name="deadline"
                                required
                                minDate={new Date().setDate(new Date().getDate())}
                                initialFocusedDate={new Date().setDate(new Date().getDate())}
                                minDateMessage="Некорректная дата"
                                invalidDateMessage="Некорректная дата"
                                format="HH:mm dd-MM-yyyy"
                                variant="inline"
                                openTo="year"
                                autoOk
                                disablePast
                                value={priceToDownload.deadline}
                                onChange={date => setPriceToDownload({
                                    ...priceToDownload,
                                    deadline: date,
                                })}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item className={classes.column}>
                        <Typography className={classes.label} style={{marginTop: '-5px'}}>Действие</Typography>
                        <ButtonIcon
                            disabled={!!(!priceToDownload.files && priceToDownload.url.length === 0) || !priceToDownload.deadline}
                            onClick={(e) => download(e)}
                            children={<PublishIcon/>}
                            className={classes.download}/>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
