import {UserService} from "../services/user"
import {history} from "../../../../App/helpers/history"

export const UserActions = {
    login,
    users,
    user,
    create,
    update,
    deleteUser
}

function login(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "LOGIN_USER_BY_ADMIN_REQUEST"})

        UserService.login(values)
            .then(
                response => {
                    dispatch({type: "LOGIN_USER_BY_ADMIN_CREATE_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ACCOUNT", payload: response.account})
                    history.push('/')
                    resolve()
                },
                error => {
                    dispatch({type: "LOGIN_USER_BY_ADMIN_CREATE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function users(params, local = false) {
    return dispatch => new Promise((resolve, reject) => {
        if (!local) {
            dispatch({type: "LOADING", payload: true})
            dispatch({type: "USERS_REQUEST"})
        }

        UserService.accounts(params)
            .then(
                users => {
                    if (!local) {
                        dispatch({type: "USERS_SUCCESS"})
                        dispatch({type: "LOADING", payload: false})
                    }
                    resolve(users)
                },
                error => {
                    if (!local) {
                        dispatch({type: "USERS_FAILURE"})
                        dispatch({type: "LOADING", payload: false})
                    }
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function user(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "USER_REQUEST"})

        UserService.account(id)
            .then(
                user => {
                    dispatch({type: "USER_SUCCESS", payload: user})
                    dispatch({type: "LOADING", payload: false})
                    resolve(user)
                },
                error => {
                    dispatch({type: "USER_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function create(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "USER_CREATE_REQUEST"})

        UserService.create(values)
            .then(
                user => {
                    dispatch({type: "USER_CREATE_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "USER_CREATE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function update(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "USER_UPDATE_REQUEST"})

        UserService.update(id, values)
            .then(
                user => {
                    dispatch({type: "USER_UPDATE_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "USER_UPDATE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}

function deleteUser(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "ACCOUNT_DELETE_REQUEST"})

        UserService.deleteUser(id)
            .then(
                user => {
                    dispatch({type: "ACCOUNT_DELETE_SUCCESS", payload: id})
                    dispatch({type: "LOADING", payload: false})
                    resolve(user)
                },
                error => {
                    dispatch({type: "ACCOUNT_DELETE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    dispatch({type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type:'error'
                        }})
                    reject(error)
                }
            )
    })
}
