import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useDebounce} from "use-debounce"

import {OperationBetweenTwoTables} from "../../../../../App/components/OperationBetweenTwoTables"
import {PurchasePlanItemsActions} from "../../../../actions/purchasePlanItems"
import {PurchaseActions} from "../../../../actions/purchase"
import {LotActions} from "../../../../actions/lot"
import {PurchasePlanActions} from "../../../../actions/purchasePlan"
import {localState} from "../../../../../App/components/LocalState";

const moveColumns = [
    {
        caption: "Номер закупки",
        dataField: "purchase_number",
        type: "string",
        width: 160,
    },
    {
        caption: "Наименование ОЗ",
        dataField: "name",
        type: "string",
    },
    {
        caption: "Месяц",
        dataField: "month",
        type: "string",
        width: 80,
    },
    {
        caption: "Год",
        dataField: "year",
        type: "number",
        width: 80,
    },
]

export const Dialog = (props) => {
    const dispatch = useDispatch()
    const { products, onClose, open } = props

    const { itemsByParentIDs } = useSelector((state) => state.purchasePlans)

    const [purchasePlans, setPurchasePlans] = useState(localState)
    const [purchasePlansMeta, setPurchasePlansMeta] = useState(localState.meta)
    const [purchases, setPurchases] = useState(localState)
    const [purchasesMeta, setPurchasesMeta] = useState(localState.meta)
    const [lots, setLots] = useState(localState)

    const [productsToOperations, setProductsToOperations] = useState(products)

    const [movePurchasePlanID, setMovePurchasePlanID] = useState("")
    const [moveLotID, setMoveLotID] = useState("")
    const [movePurchaseID, setMovePurchaseID] = useState("")

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)

    const handlePageChange = (newPage) => setPage(newPage + 1)
    const handlePageSizeChange = (newRowsPerPage) => {
        setPage(1);
        setRowsPerPage(newRowsPerPage)
    };

    const moveProducts = async (items) => {
        let products = items.map((item) => {
            return {
                id: item.id,
                purchase_id: item.purchase_id,
                purchase_lot_id: item.purchase_lot_id,
                purchase_plan_id: item.purchase_plan_id,
            }
        })
        let productIds = items.map((item) => {
            return item.id
        })
        if (movePurchasePlanID) {
            return await dispatch(PurchasePlanItemsActions.moveItems(movePurchasePlanID, {
                    items: products,
                    ...(moveLotID ? { new_lot_id: moveLotID } : {}),
                    ...(movePurchaseID ? {new_purchase_id: movePurchaseID} : {}),
                })).then((r) => {
                    setProductsToOperations(productsToOperations.filter((el) => productIds.indexOf(el.id) === -1))
                }).catch((resp) => {
                    let productsWithError = Object.keys(resp.items)
                    setProductsToOperations(productsToOperations.filter((el) => productsWithError.indexOf(String(el.id)) !== -1))
                })
        } else {
            return new Promise(function (resolve, reject) {
                dispatch({type: "ALERT_ERROR", payload: {
                        message: "Выберите план закупки",
                        type: "error",
                    }})
                return reject()
            })
        }
    }

    useEffect(() => {
        const getPurchasePlans = async () => {
            let params = {
                limit: 50,
                page: 1,
                mode: "move",
                simple: true,
                purchasePlanIds: Array.from(new Set(products.map((el) => el.purchase_plan_id))),
            }

            return await dispatch(PurchasePlanActions.plans(params))
        }

        const getTotal = async () => {
            let params = {
                limit: 50,
                page: 1,
                mode: "move",
                purchasePlanIds: Array.from(new Set(products.map((el) => el.purchase_plan_id))),
            }

            return await dispatch(PurchasePlanActions.total(params))
        }

        getTotal().then(response => {
            setPurchasePlansMeta({
                ...purchasePlansMeta,
                ...response
            })
        })

        getPurchasePlans().then((response) => {
            setPurchasePlans(response)
        })

        return () => {
            dispatch({ type: "PURCHASE_PLANS_CLEAR" })
        }
        // eslint-disable-next-line
    }, [dispatch])

    const removeProducts = async (items) => {
        let values = [];
        items.forEach((el, idx) => {
            values.push({
                lot_item_id: el.purchase_lot_id,
                id: el.id,
                purchase_plan_id: el.purchase_plan_id,
            })
        })

        return await dispatch(
            PurchasePlanItemsActions.deleteItems({
                products: values,
            })
        )
    }

    const getItemsInRightTable = async () => {
        let params = {
            limit: 100,
            page: 0,
            ...(movePurchasePlanID ? {purchase_plan_id: movePurchasePlanID} : {}),
            ...(movePurchaseID ? { purchase_id: movePurchaseID } : {}),
            ...(moveLotID ? { lot_id: moveLotID } : {}),
        };
        await dispatch(PurchasePlanItemsActions.itemsByParentIDs(params))
    }

    useEffect(() => {
        const getPurchases = async () => {
            let params = {
                limit: rowsPerPage,
                page: page,
                ...(searchRequest ? { search: searchRequest } : {}),
                simple: true,
                mode: "move"
            };
            return await dispatch(PurchaseActions.purchasesByPurchasePlanID(movePurchasePlanID, params))
        }

        const getTotal = async () => {
            let params = {
                limit: rowsPerPage,
                page: page,
                ...(searchRequest ? { search: searchRequest } : {}),
                mode: "move"
            }
            return await dispatch(PurchaseActions.totalByPurchasePlanID(movePurchasePlanID, params))
        }

        if (movePurchasePlanID) {
            getTotal().then(response => {
                setPurchasesMeta({
                    ...purchasesMeta,
                    ...response
                })
            })

            getPurchases().then((response) => {
                setPurchases(response)
            })
        }

        return () => {
            dispatch({ type: "PLANNING_PURCHASES_CLEAR" })
        }
        // eslint-disable-next-line
    }, [movePurchasePlanID, page, rowsPerPage, searchRequest])

    useEffect(() => {
        const getLots = async () => {
            let params = {
                limit: 50,
                page: 0,
            };
            const r = await dispatch(LotActions.lotsInPurchase(movePurchaseID, params))
            setLots(r)
        }

        if (movePurchaseID) {
            getLots().then((r) => {})
        }

        return () => {
            dispatch({ type: "PLANNING_PURCHASE_LOTS_CLEAR" })
        }
        // eslint-disable-next-line
    }, [movePurchaseID])

    useEffect(() => {
        if (movePurchasePlanID) {
            getItemsInRightTable().then((r) => {})
        }
        // eslint-disable-next-line
    }, [movePurchasePlanID, movePurchaseID, moveLotID])

    useEffect(() => {
        const setDefaultValues = () => {
            setMoveLotID(null)
            setMovePurchasePlanID(null)
            setMovePurchaseID(null)
        }
        return setDefaultValues()
    }, [])

    return (
        <OperationBetweenTwoTables
            itemsLeft={productsToOperations}
            itemsRight={itemsByParentIDs.data}
            columnsTables={moveColumns}
            open={open}
            title={"Перемещение ОЗ"}
            maxWidth={"lg"}
            fullWidth={true}
            leftToRightCallback={moveProducts}
            rightToLeftCallback={removeProducts}
            tableSelectorsLeft={[]}
            tableSelectorsRight={[
                {
                    id: "purchasePlans",
                    title: "Планы закупок",
                    meta: purchasePlansMeta,
                    prevId: movePurchasePlanID,
                    value: movePurchasePlanID,
                    callback: (e) => {
                        setMovePurchasePlanID(e.target.value);
                        setMovePurchaseID(null);
                        setMoveLotID(null);
                    },
                    items: purchasePlans.data,
                },
                {
                    id: "purchases",
                    title: "Закупки",
                    meta: purchasesMeta,
                    items: purchases.data,
                    prevId: movePurchasePlanID,
                    value: movePurchaseID,
                    search: {
                        search,
                        setSearch,
                    },
                    callback: (id) => {
                        setMovePurchaseID(id);
                        setMoveLotID(null);
                    },
                    isDisabled: !movePurchasePlanID,
                    pagination: {
                        page,
                        rowsPerPage,
                        handlePageChange,
                        handlePageSizeChange,
                    },
                },
                {
                    id: "lots",
                    title: "Лоты",
                    meta: lots.meta,
                    prevId: movePurchaseID,
                    value: moveLotID,
                    callback: (e) => {
                        setMoveLotID(e.target.value)
                    },
                    items: lots.data.map((lot) => {
                        return {
                            id: lot.id,
                            name: lot.number,
                        };
                    }),
                    isDisabled: !movePurchaseID,
                },
            ]}
            onClose={onClose}
            updateRightList={getItemsInRightTable}
        />
    );
};
