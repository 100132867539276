export default function discounts(state = {}, action) {
    switch (action.type) {
        case "SUPPLIER_DISCOUNTS":
            return {
                ...state,
                ...action.payload,
            }
        case "SUPPLIER_DISCOUNTS_FAILURE":
            return {
                state
            }
        case "SUPPLIER_DISCOUNT_REQUEST":
            return {
                ...state,
                discount: state.discount,
            }
        case "SUPPLIER_DISCOUNT_RESET":
            return state
        case "SUPPLIER_DISCOUNT":
            return {
                ...state,
                discount: action.payload,
            }
        case "SUPPLIER_DISCOUNT_FAILURE":
            return state
        default:
            return state
    }
}
