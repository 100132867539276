import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {Field, Form, Formik} from "formik"
import * as Yup from "yup"

import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles} from "@material-ui/core"
import {Close as CloseIcon} from "@material-ui/icons"

import {Submit} from "../../../../App/components/Button/Submit"
import {TextField as CustomTextField} from "../../../../App/components/Inputs/TextField"
import {GroupActions} from "../../../actions/Control/group"

const useStyles = makeStyles(theme => ({
    item: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "cursor": "pointer"
    },
    fullWidth: {
        "width": "100%"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    content: {
        "padding": "0 !important"
    },
    input: {
        "width": "100%",
    },
    column: {
        "height": "86px"
    },
    section: {
        "border-right": "2px solid #c1c1c1",
        "padding": "20px 30px",
        "overflow-y": "auto",
        "max-height": "calc(80vh - 63px)"
    },
    additionally: {
        "max-height": "calc(80vh - 63px)"
    },
    dialogContent: {
        "max-height": "calc(100vh - 210px)"
    },
    footer: {
        "background-color": "#e7eaee",
        "justify-content": "flex-start"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    dialog: {
        "max-height": "70vh"
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    searchLabel: {
        "margin-bottom": "15px",
    },
    footerContent: {
        "padding": "10px 0px"
    },
    eclipsis: {
        "overflow": "hidden",
        "text-overflow": "ellipsis",
        "white-space": "nowrap"
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    titleDialog: {
        "& h2": {
            "display": "flex",
            "justify-content": "space-between",
        },
    }
}))

export const GroupForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { group } = props
    const [open, setOpen] = useState(props.open)
    const [isDelete, setIsDelete] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setOpen(props.open)
        setLoading(true)
    }, [dispatch, props.open]);

    const handleClose = () => {
        setOpen(false)
        props.setDialog(false)
    }

    return loading ? (
        <Grid item>
            <Dialog
                open={!!open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogTitle className={classes.titleDialog}>
                    {group ? 'Редактировать группу' : 'Создать группу'}
                    <CloseIcon className={classes.close} onClick={handleClose}/>
                </DialogTitle>
                <Formik
                    initialValues={{
                        name: group ? group.name : '',
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        return dispatch(group ? GroupActions.edit(group.id, values) : GroupActions.add(values)).then(
                            () => {
                                handleClose()
                                setSubmitting(false)
                            },
                            errors => {
                                if (errors) {
                                }
                                setSubmitting(false)
                            }
                        )
                    }}
                >
                    {({
                          dirty,
                          values,
                          errors,
                          touched,
                          validateField,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setErrors,
                          setValues,
                          setFieldValue,
                          setFieldError,
                          setFieldTouched,
                          isSubmitting,
                          isValid,
                          submitForm
                      }) => (
                        <Form>
                            <DialogContent className={classes.dialogContent}>
                                <Grid container className={classes.fullWidth} direction="row" justify="center" alignItems="stretch">
                                    <Grid item className={classes.fullWidth}>
                                        <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
                                            <Grid item>
                                                <Grid container className={classes.fullWidth} direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                                    <Grid item xs={12} className={classes.column}>
                                                        <Field
                                                            fullWidth
                                                            name="name"
                                                            type="text"
                                                            label="Наименование"
                                                            component={CustomTextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions className={classes.footer}>
                                <Grid container className={ classes.footerContent} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                    <Grid item>
                                        {group &&
                                            <Submit
                                                variant="contained"
                                                component="label"
                                                color="secondary"
                                                type="button"
                                                disabled={isSubmitting || isDelete}
                                                onClick={() => {
                                                    setIsDelete(true)
                                                    return dispatch(GroupActions.remove(group.id)).then(
                                                        () => {
                                                            handleClose()
                                                            setIsDelete(false)
                                                        },
                                                        errors => {
                                                            if (errors) {
                                                            }
                                                            setIsDelete(false)
                                                        }
                                                    )
                                                }}
                                            >
                                                Удалить
                                            </Submit>
                                        }
                                    </Grid>
                                    <Grid item>
                                        <Submit
                                            variant="contained"
                                            component="label"
                                            color="primary"
                                            disabled={isSubmitting || !isValid || isDelete || !dirty}
                                            onClick={handleSubmit}
                                            type="submit"
                                        >
                                            {group ? 'Сохранить' : 'Создать'}
                                        </Submit>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </Grid>
    ) : null
}
