import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {
    Grid,
    makeStyles,
    Paper,
    TablePagination,
} from "@material-ui/core"

import {DeliveryActions} from "../../actions/delivery"
import {Cart} from "./components/Cart";
import {localState} from "../../../App/components/LocalState";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 111px)"
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100% - 70px)",
        "min-height": "calc(100% - 70px)",
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    }
}))

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {filter} = useSelector(state => state.delivery)

    const [orders, setOrders] = useState(localState)

    const [page, setPage] = useState(parseInt(orders.meta.current_page - 1))
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [loading, setLoading] = useState(false)

    const containerRef = useRef(null)

    useEffect(() => {
        if (loading) {
            return () => {
                dispatch({type: 'DELIVERY_ORDERS_CLEAR'})
            }
        }
    }, [dispatch, loading]);

    const getOrders = async () => {
        return await dispatch(DeliveryActions.orders({
            ...{
                limit: rowsPerPage,
                page: page + 1,
                active: false
            },
            ...(filter.company ? {company_id: filter.company.id} : {}),
            ...(filter.contract ? {contract_id: filter.contract.id} : {})
        }))
    }

    useEffect(() => {
        if (!loading) {
            getOrders().then(response => {
                setOrders(response)
                containerRef.current && containerRef.current.scrollTo({behavior: 'smooth', top: 0})
                setLoading(true)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, loading, page, rowsPerPage, filter.company, filter.contract]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    return <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.fullWidth}>
                        <Grid container className={classes.container} direction="column" justify="space-between" alignItems="stretch">
                            <Grid ref={containerRef} item className={classes.inputContent}>
                                {loading && orders.data.map((order) =>
                                    <Cart
                                        order={order}
                                        setOrder={(id, order = null) => {
                                            setOrders({
                                                ...orders,
                                                ...{
                                                    data: orders.data.filter(el => !order ? (el.id !== id) : true).map(el => {
                                                        if (el.id === id) {
                                                            el = order
                                                        }

                                                        return el
                                                    })
                                                }
                                            })
                                        }}
                                    />
                                )}
                            </Grid>
                            <Grid item className={classes.footer}>
                                <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                    <Grid item className={classes.footerContent}>
                                        <Grid container className={classes.footerContainer} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                            <Grid item>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                                    component='div'
                                                    labelRowsPerPage={'Записей на странице:'}
                                                    count={orders.meta.total}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
}
