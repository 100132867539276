import {StatusService} from "../services/status"

export const StatusActions = {
    status
}

function status() {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "DELIVERY_ORDER_STATUS_REQUEST"})

        StatusService.status()
            .then(
                status => {
                    dispatch({type: "DELIVERY_ORDER_STATUS", payload: status})
                    dispatch({type: "LOADING", payload: false})
                    resolve(status)
                },
                error => {
                    dispatch({type: "DELIVERY_ORDER_STATUS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
