import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import format from "date-fns/format"

import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Badge,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tabs,
    Tooltip,
    Typography
} from "@material-ui/core"
import {Add as AddIcon, ExpandMore as ExpandMoreIcon} from "@material-ui/icons"

import {PriceActions} from "../../actions/Control/price"
import {Submit} from "../../../App/components/Button/Submit"
import {ItemForm} from "./components/ItemForm"
import {day as formatDay} from "../../../App/helpers/date"
import {Card} from "../../../Company/components/Card"
import {GroupForm} from "./components/GroupForm"
import {GroupActions} from "../../actions/Control/group"

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    article: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "170px",
    },
    header: {
        "width": "100%",
        "padding": "20px 29px !important",
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 110px)"
    },
    input: {
        "width": "100%",
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100% - 114px)",
        "min-height": "calc(100% - 114px)",
        "overflow": "auto",
        "overflow-x": "hidden"
    },
    accordion: {
        "& .MuiAccordionSummary-content": {
            "max-width": "calc(100% - 32px)"
        }
    },
    accordionDetails: {
        "padding": "0"
    },
    accordionItem: {
        "border-top": "0 !important",
        "box-shadow": "0 0 0 !important",
        "border-bottom": "1px solid rgba(224, 224, 224, 1) !important",
        "border-color": "grey"
    },
    accordionActions: {
        "padding": "0 20px !important"
    },
    tab: {
        "width": "100%",
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    table: {
        "border": "1px solid #e4e4e4",
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
    tableRowAlert: {
        "cursor": "pointer",
        "background-color": "#f9f1f0",
        "text-decoration": "none"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "7px"
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    searchLabel: {
        "margin-bottom": "14px",
    },
    searchInput: {
        "width": "calc(100% - 83px)"
    },
    searchButton: {
        "margin-bottom": "10px"
    },
    amount: {
        "min-width": "100px"
    },
    sort: {
        "margin-bottom": "15px"
    },
    dialogContainer: {
        "& > div": {
            "max-width": "500px",
            "min-height": "300px",
            "max-height": "500px",
            overflow: "hidden",
        }
    },
    dialogTitle: {
        "margin": "0",
        "padding-top": "2px",
        "font-size": "18px",
        "font-weight": "600",
        "color": "#fff",
        "text-transform": "uppercase",
        "display": "flex",
        "justify-content": "space-between",
        "align-items": "center",
        "min-height": "40px",
        "padding": "0 20px",
        "background-color": "#898989",
    },
    button: {
        marginTop: 0,
        maxWidth: '45px',
        "&:hover": {
            "background-color": "#898989",
        },
        "width": "auto",
        "max-height": "35px",
        "max-width": "auto",
        "padding": "0",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "35px",
        "font-size": "16px",
        "color": "#fff",
        "font-weight": "600",
        "text-transform": "uppercase",
        "border": "none",
        "border-bottom": "3px solid #898989",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "#616161",
        "cursor": "pointer",
        "& > span": {
            "padding": "5px 15px",
            marginTop: "5px",
        }
    },
    dialogContent: {
        padding: 0,
    },
    footerContentDialog: {
        width: "100%",
        position: "absolute",
        bottom: 0,
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainerDialog: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    descriptionText: {
        margin: "0 0 15px",
        fontSize: "18px",
    },
    descriptionWrapper: {
        padding: "40px 50px",
        fontSize: "16px",
    },
    close: {
        cursor: "pointer"
    },
    orderLink: {
        color: "#898989",
        cursor: "pointer",
        "&:hover": {
            color: "#616161"
        }
    },
    tabButton: {
        "min-width": " auto"
    },
}))

const columns = [
    {
        label: '',
        format: (value) => value.toLocaleString(),
    },
    {
        label: 'Наименование',
        format: (value) => value.toLocaleString(),
    },
    {
        label: 'Заказчик',
        format: (value) => value.toLocaleString(),
    },
    {
        label: 'Поставщик',
        format: (value) => value.toLocaleString(),
    },
    {
        label: 'Город',
        format: (value) => value.toLocaleString(),
    },
    {
        label: 'Цена без НДС',
        format: (value) => value.toLocaleString(),
    },
    {
        label: 'НДС',
        format: (value) => value.toLocaleString(),
    },
    {
        label: 'Цена с НДС',
        format: (value) => value.toLocaleString(),
    },
    {
        label: 'Количество',
        format: (value) => value.toLocaleString(),
    },
    {
        label: 'Производитель',
        format: (value) => value.toLocaleString(),
    },
    {
        label: 'Срок годности',
        format: (value) => value.toLocaleString(),
    },
    {
        label: 'Тип',
        format: (value) => value.toLocaleString(),
    },
    {
        label: 'Срок поставки (дней)',
        format: (value) => value.toLocaleString(),
    },
    {
        label: 'Отсрочка платежа (дней)',
        format: (value) => value.toLocaleString(),
    },
    {
        label: 'Процент предоплаты',
        format: (value) => value.toLocaleString(),
    },
    {
        label: 'НМЦД',
        format: (value) => value.toLocaleString(),
    },
    {
        label: 'Начало срока действия цены',
        format: (value) => value.toLocaleString(),
    },
    {
        label: 'Конце срока действия цены',
        format: (value) => value.toLocaleString(),
    }
];

export const Content = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {items, positions} = useSelector(state => {
        return {...state.reportPriceControl}
    })
    const {groups} = useSelector(state => state.reportPriceControlGroup)

    const [loading, setLoading] = useState(false)
    const [item, setItem] = useState(null)
    const [page, setPage] = useState({items: parseInt(items.meta.current_page - 1), positions: 0})
    const [rowsPerPage, setRowsPerPage] = useState({items: 15, positions: 5})
    const [expanded, setExpanded] = useState(false)
    const [dialog, setDialog] = useState(false)
    const [dialogGroup, setDialogGroup] = useState(null)
    const [cardCompany, setCardCompany] = useState(null)
    const [openCard, setOpenCard] = useState(false)
    const [group, setGroup] = useState(0)
    const [tooltip, setTooltip] = useState(false)
    const containerRef = useRef(null)

    const handleChange = (item) => (event, isExpanded) => {
        if (isExpanded) {
            setItem(item)
        } else {
            setExpanded(false)
            setItem(null)
            setRowsPerPage({...rowsPerPage, ...{positions: 5}})
            setPage({...page, ...{positions: 0}})
        }
    };

    useEffect(() => {
        const getPositions = async () => {
            return await dispatch(PriceActions.positions(item.id, {
                limit: rowsPerPage.positions,
                page: page.positions + 1
            }))
        }

        if (item) {
            getPositions().then(_ => {
                setExpanded(item.id)
            })
        }
    }, [dispatch, page.positions, rowsPerPage.positions, item])

    useEffect(() => {
        if (!dialog) {
            setExpanded(false)
            setRowsPerPage({...rowsPerPage, ...{positions: 5}})
            setPage({...page, ...{positions: 0}})
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [dispatch, dialog])

    useEffect(() => {
        const getGroups = async () => {
            return await dispatch(GroupActions.groups())
        }

        if (!loading && !groups.length) {
            getGroups().then(groups => {

            })
        }
    }, [dispatch, loading, groups.length])

    useEffect(() => {
        setLoading(!groups.length)
        setGroup(!!groups.length ? groups[0] : 0)
        // eslint-disable-next-line
    }, [groups.length])

    useEffect(() => {
        const getItems = async (filter) => {
            return await dispatch(PriceActions.items({
                ...{
                    limit: rowsPerPage.items,
                    page: page.items + 1,
                    group: group.id,
                    ...(filter.region ? {region: filter.region} : {}),
                    ...(filter.customer ? {customer: filter.customer} : {}),
                    ...(filter.territory ? {territory: filter.territory} : {}),
                    ...(filter.supplier ? {supplier: filter.supplier} : {}),
                    ...(filter.price.from ? {price_from: filter.price.from} : {}),
                    ...(filter.price.to ? {price_to: filter.price.to} : {}),
                    ...(filter.new ? {new: filter.new} : {})
                }
            }))
        }

        setItem(null)
        if (!loading && group) {
            setLoading(true)
            getItems(items.filter).then(_ => {
                containerRef.current && containerRef.current.scrollTo({behavior: 'smooth', top: 0})
            })
        }
    }, [dispatch, page.items, rowsPerPage.items, items.filter, group, loading])

    return (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.fullWidth}>
                        <Grid container className={classes.container} direction="column" justify="space-between" alignItems="stretch">
                            <Grid item className={classes.fullWidth}>
                                {loading ? (
                                    <Tabs
                                        value={!!group ? group.id : 0}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        onChange={(event, newValue) => {
                                            if (!!newValue) {
                                                if (!group || (newValue !== group.id)) {
                                                    setLoading(false)
                                                    setGroup(groups.find((el) => (el.id === newValue)))
                                                } else {
                                                    setDialogGroup('update')
                                                }
                                            }
                                        }}
                                    >
                                        {
                                            groups.map((el) =>
                                                <Tab
                                                    key={el.id}
                                                    classes={{
                                                        root: classes.tabButton
                                                    }}
                                                    value={el.id}
                                                    label={el.name}
                                                />
                                            )
                                        }
                                        <Tab
                                            classes={{
                                                root: classes.tabButton
                                            }}
                                            value={0}
                                            label={
                                                <Tooltip
                                                    title="Создать группу"
                                                    placement="right"
                                                    classes={{tooltip: classes.tooltip}}
                                                    arrow
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    open={tooltip}
                                                >
                                                    <IconButton
                                                        size="small"
                                                        aria-label="close"
                                                        color="inherit"
                                                        onClick={() => {
                                                            setTooltip(false)
                                                            setDialogGroup('create')
                                                        }}
                                                    >
                                                        <AddIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        />
                                    </Tabs>
                                ) : null
                                }
                            </Grid>
                            <Grid ref={containerRef} item className={classes.inputContent}>
                                {items.data.map((item) => (
                                    <Accordion key={item.id} expanded={expanded === item.id} onChange={handleChange(item)} className={classes.accordionItem}>
                                        <AccordionSummary className={classes.accordion} expandIcon={<ExpandMoreIcon/>}>
                                            <Grid container direction="row" justify="space-between" alignItems="center">
                                                <Grid item xs={9}>
                                                    <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                        <Grid item>
                                                            <Badge color="secondary" badgeContent={item.new}>
                                                                <Typography>{item.name ? `${item.name}, ` : null}{item.category.name}</Typography>
                                                            </Badge>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails classes={{root: classes.accordionDetails}}>
                                            <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                                <Grid item className={classes.fullWidth}>
                                                    {positions.data.length
                                                        ? <TableContainer className={classes.tableContainer}>
                                                            <Table stickyHeader aria-label="sticky table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        {columns.map((column, index) => (
                                                                            <TableCell
                                                                                key={index}
                                                                                align={column.align}
                                                                                style={{minWidth: column.minWidth}}
                                                                            >
                                                                                {column.label}
                                                                            </TableCell>
                                                                        ))}
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {positions.data && positions.data.map((position) => {
                                                                        return (
                                                                            <TableRow key={position.id}>
                                                                                <TableCell>{position.new &&
                                                                                    <Badge color="secondary" variant="dot" />}</TableCell>
                                                                                <TableCell>{position.name}</TableCell>
                                                                                <TableCell>{position.customer.name}</TableCell>
                                                                                <TableCell>
                                                                                    <Typography
                                                                                        className={classes.orderLink}
                                                                                        onClick={(e) => {
                                                                                            e.stopPropagation();
                                                                                            setCardCompany(position.supplier)
                                                                                            setOpenCard(true)
                                                                                        }}
                                                                                    >
                                                                                        {position.supplier.legal_detail.name}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                                <TableCell>{position.supplier.legal_detail.city.name}</TableCell>
                                                                                <TableCell>{(position.tax_percent > 0) ? parseFloat(position.price - ((position.price / (100 + position.tax_percent)) * position.tax_percent)).toLocaleString('ru-RU', {
                                                                                    style: 'currency',
                                                                                    currency: position.currency.code
                                                                                }).replace('KZT', '₸') : parseFloat(position.price).toLocaleString('ru-RU', {
                                                                                    style: 'currency',
                                                                                    currency: position.currency.code
                                                                                }).replace('KZT', '₸')}</TableCell>
                                                                                <TableCell>{(position.tax_percent > 0) ? `${position.tax_percent}%` : 'Без НДС'}</TableCell>
                                                                                <TableCell>{parseFloat(position.price).toLocaleString('ru-RU', {
                                                                                    style: 'currency',
                                                                                    currency: position.currency.code
                                                                                }).replace('KZT', '₸')}</TableCell>
                                                                                <TableCell>{position.amount}</TableCell>
                                                                                <TableCell>{position.producer}</TableCell>
                                                                                <TableCell>{position.expiration_date}</TableCell>
                                                                                <TableCell>{position.form}</TableCell>
                                                                                <TableCell>{position.contract_time ? formatDay(position.contract_time) : null}</TableCell>
                                                                                <TableCell>{position.postponement ? formatDay(position.postponement) : null}</TableCell>
                                                                                <TableCell>{position.prepayment ? `${position.prepayment}%` : null}</TableCell>
                                                                                <TableCell>{position.average_price ? parseFloat(position.average_price).toLocaleString('ru-RU', {
                                                                                    style: 'currency',
                                                                                    currency: position.currency.code
                                                                                }).replace('KZT', '₸') : null}</TableCell>
                                                                                <TableCell>{format(new Date(position.created), 'dd.MM.yyyy HH:mm')}</TableCell>
                                                                                <TableCell>{format(new Date(position.deadline), 'dd.MM.yyyy HH:mm')}</TableCell>
                                                                            </TableRow>
                                                                        );
                                                                    })}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        : null
                                                    }
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                        <Divider/>
                                        <AccordionActions classes={{root: classes.accordionActions}}>
                                            <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                <Grid item>
                                                    <TablePagination
                                                        rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                                        component='div'
                                                        labelRowsPerPage={'Записей на странице:'}
                                                        count={positions.meta.total}
                                                        rowsPerPage={rowsPerPage.positions}
                                                        page={page.positions}
                                                        onChangePage={(event, newPage) => {
                                                            setPage({...page, ...{positions: newPage}})
                                                        }}
                                                        onChangeRowsPerPage={(event) => {
                                                            setRowsPerPage({...rowsPerPage, ...{positions: +event.target.value}})
                                                            setPage({...page, ...{positions: 0}})
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                        <Grid item>
                                                            <Submit
                                                                className={classes.submit}
                                                                disableElevation
                                                                variant="contained"
                                                                color="secondary"
                                                                type="button"
                                                                onClick={() => {
                                                                    return dispatch(PriceActions.remove(item.id)).then(
                                                                        () => {
                                                                            setLoading(false)
                                                                        }
                                                                    )
                                                                }}
                                                            >
                                                                Удалить
                                                            </Submit>
                                                        </Grid>
                                                        <Grid item>
                                                            <Submit
                                                                className={classes.submit}
                                                                disableElevation
                                                                variant="contained"
                                                                color="primary"
                                                                type="button"
                                                                onClick={() => {
                                                                    setDialog(true)
                                                                }}
                                                            >
                                                                Редактировать
                                                            </Submit>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionActions>
                                    </Accordion>
                                ))}
                            </Grid>
                            <Grid item className={classes.footer}>
                                <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                    <Grid item className={classes.footerContent}>
                                        <Grid container className={classes.footerContainer} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                            <Grid item>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 15, 25, 50, 100]}
                                                    component='div'
                                                    labelRowsPerPage={'Записей на странице:'}
                                                    count={items.meta.total}
                                                    rowsPerPage={rowsPerPage.items}
                                                    page={page.items}
                                                    onChangePage={(event, newPage) => {
                                                        setPage({...page, ...{items: newPage}})
                                                        setLoading(false)
                                                    }}
                                                    onChangeRowsPerPage={(event) => {
                                                        setRowsPerPage({...rowsPerPage, ...{items: +event.target.value}})
                                                        setPage({...page, ...{items: 0}})
                                                        setLoading(false)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Submit
                                                    disableElevation
                                                    variant="contained"
                                                    color="primary"
                                                    type="button"
                                                    onClick={() => {
                                                        if (group) {
                                                            setDialog(true)
                                                        } else {
                                                            dispatch({
                                                                type: "ALERT_ERROR",
                                                                payload: {
                                                                    message: 'Создайте группу.',
                                                                    type: 'error'
                                                                }
                                                            })
                                                            setTooltip(true)
                                                        }
                                                    }}
                                                >
                                                    Создать
                                                </Submit>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <ItemForm
                open={dialog}
                setDialog={setDialog}
                item={item}
                group={group}
            />
            <GroupForm
                open={dialogGroup}
                setDialog={setDialogGroup}
                group={(dialogGroup === 'create') ? null : group}
            />
            {(openCard && cardCompany) && <Card
                companyType={'поставщика'}
                company={cardCompany}
                open={openCard}
                onClose={() => {
                    setOpenCard(false)
                    setCardCompany(null)
                }}
            />}
        </Grid>
    )
}
