export default function employee(state = {employees: {data: [], meta: {total: 0, per_page: 15, current_page: 1}}}, action) {
    switch (action.type) {
        case "EMPLOYEES_REQUEST":
            return state
        case "EMPLOYEES":
            return {
                employees: action.payload,
            }
        case "EMPLOYEES_FAILURE":
            return state
        case "ADD_EMPLOYEE_REQUEST":
            return state
        case "ADD_EMPLOYEE_SUCCESS":
            return state
        case "ADD_EMPLOYEE_FAILURE":
            return state
        case "EDIT_EMPLOYEE_REQUEST":
            return state
        case "EDIT_EMPLOYEE_SUCCESS":
            return state
        case "EDIT_EMPLOYEE_FAILURE":
            return state
        default:
            return state
    }
}
