import {CompanyService} from "../services/company"

export const CompanyActions = {
    companies
}

function companies(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "REPORT_COMPANIES_REQUEST"})

        CompanyService.companies(params)
            .then(
                companies => {
                    dispatch({type: "REPORT_COMPANIES", payload: {data: companies.data, meta: companies.meta}})
                    dispatch({type: "LOADING", payload: false})
                    resolve(companies)
                },
                error => {
                    dispatch({type: "REPORT_COMPANIES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
