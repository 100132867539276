import React from "react"
import {Link} from "react-router-dom"

import {makeStyles, Typography} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    link: {
        "color": "#FFFFFF"
    },
    text: {
        "margin-bottom": 0
    }
}))

export const NoElmagAccessError = (props) => {
    const classes = useStyles()
    return  (
        <Typography className={classes.text} gutterBottom color="inherit">
            {props.message} <Link to={{pathname: "/account", params:{tabId: 2}}} className={classes.link}>Профиль -> Условия участия</Link>
        </Typography>
    )
}
