import {HttpService} from "../../App/services/http"

export const LogService = {
    log,
    filter
}

function log(plan, params = {}) {
    return HttpService.get(`/planning/plan/${plan}/log`, params)
}

function filter(plan, params) {
    return HttpService.get(`/planning/plan/${plan}/log/filter`, params)
}
