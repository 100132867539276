import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import * as Yup from "yup"
import format from "date-fns/format"

import {
    Avatar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputAdornment,
    makeStyles,
    Typography
} from "@material-ui/core"
import {Close as CloseIcon, AddShoppingCart as AddShoppingCartIcon} from "@material-ui/icons"

import {DeliveryActions} from "../../Delivery/actions/delivery"
import {Submit} from "../../App/components/Button/Submit"
import {TextFieldWithError as CustomTextField} from "../../App/components/Inputs/TextFieldWithError"
import {PriceActions} from "../../Price/actions/price"

const useStyles = makeStyles(() => ({
    item: {
        "background-image": "none !important",
        "border-bottom": "none !important",
        "cursor": "pointer"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    fullWidth: {
        "width": "100%"
    },
    fullHeight: {
        "height": "100%"
    },
    avatar: {
        "width": "150px",
        "height": "150px",
        "border-radius": "unset"
    },
    content: {
        "padding": "0 !important"
    },
    input: {
        "width": "100%",
    },
    column: {
        "height": "77px"
    },
    section: {
        "border-right": "2px solid #c1c1c1",
        "padding": "20px 30px",
        "overflow-y": "auto",
        "max-height": "calc(80vh - 63px)"
    },
    additionally: {
        "padding": "16px",
        "max-height": "calc(80vh - 63px)"
    },
    footer: {
        "background-color": "#e7eaee",
        "justify-content": "flex-start"
    },
    dialog: {
        "max-height": "70vh"
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "border": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    dialogContent: {
        "padding": "0"
    },
    titleDialog: {
        "& h2": {
            "display": "flex",
            "justify-content": "space-between",
        },
    }
}))

export const Product = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {item} = props
    const [open, setOpen] = useState(props.open)
    const [image, setImage] = useState(`${process.env.PUBLIC_URL}/storage/no-image.jpg`)
    const {product} = useSelector(state => state.products)

    useEffect(() => {
        const getProduct = async () => {
            return await dispatch(PriceActions.getProduct(item.id))
        }

        if (open) {
            getProduct().then(product => {
                setOpen(props.open)
                setImage(product?.photo_path ? product.photo_path : `${process.env.PUBLIC_URL}/storage/no-image.jpg`)
            })
        }

        return () => {
            dispatch({ type: "CLEAR_EDIT_PRODUCT"})
        }
        // eslint-disable-next-line
    }, [dispatch, open, item]);

    const handleClose = () => {
        setOpen(false)
        props.setDialog(false)
    }

    return product ? (
        <Grid item>
            <Dialog
                open={!!open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle className={classes.titleDialog}>Карточка товара<CloseIcon className={classes.close} onClick={handleClose}/></DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container className={classes.fullWidth} direction="row" justify="space-between" alignItems="stretch">
                        <Grid item xs={4} className={classes.section}>
                            <Grid container className={classes.fullHeight} direction="column" justify="center" alignItems="center">
                                <Grid item>
                                    <Avatar
                                        className={classes.avatar}
                                        style={product.photo_path ? {"width": "100%", "height": "100%"} : {}}
                                        src={image}
                                        imgProps={{
                                            onError: () => {
                                                setImage(`${process.env.PUBLIC_URL}/storage/no-image.jpg`)
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={8} className={classes.additionally}>
                            <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.title}>
                                        {product.name}
                                    </Typography>
                                    <Typography>
                                        Артикул {product.article_number}
                                    </Typography>
                                </Grid>
                                {item.description &&
                                    <Grid item>
                                        <Typography>{item.description}</Typography>
                                    </Grid>
                                }
                                <Grid item className={classes.fullWidth} >
                                    <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="flex-start">
                                        <Grid item className={classes.fullWidth}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center">
                                                <Grid item xs={3}>
                                                    <Typography>Производитель</Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography color="primary">{product.producer}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.fullWidth}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center">
                                                <Grid item xs={3}>
                                                    <Typography>Срок годности</Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography>{product.expiration_date ? format(new Date(product.expiration_date), 'dd.MM.yyyy') : null}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.fullWidth} >
                                    <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="flex-start">
                                        <Grid item className={classes.fullWidth}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center">
                                                <Grid item xs={3}>
                                                    <Typography>Поставщик</Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography color="primary">{item.offer.company.legal_detail.name}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.fullWidth}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center">
                                                <Grid item xs={3}>
                                                    <Typography>Остатки</Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography>{item.item.amount ? (parseFloat(item.item.amount) - (item.item.ordered ? parseFloat(item.item.ordered) : 0)).toFixed(2) : null}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.fullWidth}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center">
                                                <Grid item xs={3}>
                                                    <Typography>Ставка НДС</Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography>{(item.tax_percent >= 0) ? `НДС ${item.tax_percent}%` : 'Без НДС' }</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.fullWidth}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center">
                                                <Grid item xs={3}>
                                                    <Typography>Цена за ед.</Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography>{parseFloat(item.price).toLocaleString('ru-RU', {style: 'currency', currency: item.offer.contract.currency.code}).replace('KZT', '₸')}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.footer}>
                    <Grid container className={ classes.footerContent} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                            <Formik
                                initialValues={{
                                    id: item.id,
                                    amount: 0,
                                }}
                                validationSchema={Yup.object().shape({
                                    id: Yup.string(),
                                    // eslint-disable-next-line no-template-curly-in-string
                                    amount: Yup.number().min(parseFloat(item.min_ordered_quantity ?? 0).toFixed(2), "Мин. заказ >= ${min}").max(((parseFloat(item.item.amount) - (item.item.ordered ? parseFloat(item.item.ordered) : 0)) < parseFloat(item.min_ordered_quantity)) ? parseFloat(item.min_ordered_quantity).toFixed(2) : (parseFloat(item.item.amount) - (item.item.ordered ? parseFloat(item.item.ordered) : 0)).toFixed(2), "Макс. заказ <= ${max}").required("Поле не может быть пустым или состоять из одних пробелов!")
                                })}
                                onSubmit={(values, {setSubmitting, setFieldValue}) => {
                                    return dispatch(DeliveryActions.addItem(null, values)).then(
                                        () => {
                                            dispatch({type: 'CART_ITEMS_COUNT_LOADING', payload: false})
                                            setFieldValue('amount', 0, false)
                                            setSubmitting(false);
                                        },
                                        errors => {
                                            if (errors) {
                                            }
                                            setSubmitting(false);
                                        }
                                    )
                                }}
                            >
                                {({
                                      values,
                                      isValid
                                  }) => (
                                    <Form>
                                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                            <Grid item xs={2} className={classes.column}>
                                                <Field
                                                    fullWidth
                                                    name="amount"
                                                    type="number"
                                                    inputProps={{
                                                        step: 0.01,
                                                        min: parseFloat(item.min_ordered_quantity ?? 0).toFixed(2),
                                                        max: ((parseFloat(item.item.amount) - (item.item.ordered ? parseFloat(item.item.ordered) : 0)) < parseFloat(item.min_ordered_quantity)) ? parseFloat(item.min_ordered_quantity).toFixed(2) : (parseFloat(item.item.amount) - (item.item.ordered ? parseFloat(item.item.ordered) : 0)).toFixed(2)
                                                    }}
                                                    component={CustomTextField}
                                                    endAdornment={item.item.unit ? <InputAdornment position="end">{item.item.unit.short}</InputAdornment> : null}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Submit
                                                    disableElevation
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={!values.amount || !isValid}
                                                    startIcon={<AddShoppingCartIcon/>}
                                                >
                                                    В корзину
                                                </Submit>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Grid>
    ) : null
}
