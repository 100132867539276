import {NotificationService} from "../services/notification"

export const NotificationActions = {
    notifications,
    settings,
    update,
    count,
    types,
    remove,
    read
}

function notifications(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "NOTIFICATION_EVENTS_REQUEST"})

        NotificationService.notifications(params)
            .then(
                notifications => {
                    dispatch({type: "NOTIFICATION_EVENTS", payload: {data: notifications.data, meta: notifications.meta, count: notifications.count}})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "NOTIFICATION_EVENTS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function settings() {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "NOTIFICATION_SETTINGS_REQUEST"})

        NotificationService.settings()
            .then(
                settings => {
                    dispatch({type: "NOTIFICATION_SETTINGS", payload: settings})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "NOTIFICATION_SETTINGS_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function update(values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "NOTIFICATION_SETTINGS_UPDATE_REQUEST"})

        NotificationService.update(values)
            .then(
                settings => {
                    dispatch({type: "NOTIFICATION_SETTINGS_UPDATE_SUCCESS", payload: settings})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "NOTIFICATION_SETTINGS_UPDATE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function count(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "NOTIFICATION_EVENT_COUNT_REQUEST"})

        NotificationService.count(params)
            .then(
                count => {
                    dispatch({type: "NOTIFICATION_EVENT_COUNT", payload: count})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "NOTIFICATION_EVENT_COUNT_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function types(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "NOTIFICATION_EVENT_TYPES_REQUEST"})

        NotificationService.types(params)
            .then(
                types => {
                    dispatch({type: "NOTIFICATION_EVENT_TYPES", payload: types})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "NOTIFICATION_EVENT_TYPES_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function remove(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "NOTIFICATION_EVENT_DELETE_REQUEST"})

        NotificationService.remove(params)
            .then(
                response => {
                    dispatch({type: "NOTIFICATION_EVENT_DELETE_SUCCESS"})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "NOTIFICATION_EVENT_DELETE_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}

function read(id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})
        dispatch({type: "NOTIFICATION_EVENT_READ_REQUEST"})

        NotificationService.read(id)
            .then(
                response => {
                    dispatch({type: "NOTIFICATION_EVENT_READ_SUCCESS", payload: id})
                    dispatch({type: "LOADING", payload: false})
                    resolve()
                },
                error => {
                    dispatch({type: "NOTIFICATION_EVENT_READ_FAILURE"})
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
