import {DashboardService} from "../services/dashboard"

export const DashboardActions = {
    token
}

function token(params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({type: "LOADING", payload: true})

        DashboardService.token(params)
            .then(
                token => {
                    dispatch({type: "LOADING", payload: false})
                    resolve(token)
                },
                error => {
                    dispatch({type: "LOADING", payload: false})
                    reject(error)
                }
            )
    })
}
