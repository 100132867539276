import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import clsx from "clsx"

import {
    Grid, InputAdornment, makeStyles, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, TextField, Typography, Tooltip, InputLabel, Input, Checkbox, FormControlLabel, FormControl
} from "@material-ui/core"
import {Pagination} from '@material-ui/lab'

import {ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon, Error as ErrorIcon, Search as SearchIcon} from '@material-ui/icons'

import {PriceActions} from "../../actions/price"
import {ButtonList} from "../../../App/components/ButtonList/ButtonList"
import {taxPercentValuesProduct} from '../../../App/dics/dic'
import {round} from '../../../App/helpers/number'
import {ButtonIcon} from "../../../App/components/Button/ButtonIcon"
import {AddProductDialog} from "../AddProductDialog"
import {useDebouncedCallback} from "use-debounce";

const useStyles = makeStyles(theme => ({
    tableHeaderItem: {
        "bproduct-right": "2px solid rgba(211,211,211,.4)",
        "height": "40px",
        "display": "flex",
        "padding": "0 15px",
        "align-items": "center",
        "margin": "8px 0",
    },
    productRightNone: {
        "bproduct-right": "none",
    },
    padLeft: {
        "padding-left": "0",
    },
    title: {
        "margin": "0",
        "padding-top": "2px",
        "font-size": "18px",
        "font-weight": "600",
        "color": "#fff",
        "text-transform": "uppercase",
        "display": "flex",
        "justify-content": "space-between",
        "align-items": "center",
        "min-height": "40px",
        "padding": "0 20px",
        "background-color": "#898989",
    },
    close: {
        "color": "#fff",
        "position": "relative",
        "width": "33px",
        "height": "33px",
        "padding": "0",
        "font-size": "0",
        "bproduct": "none",
        "outline": "0",
        "background-color": "transparent",
        "cursor": "pointer",
        "&:hover": {
            "color": "#616161",
        }
    },
    footerContainer: {
        "padding": "0 25px",
        "background-color": "#e7eaee",
        "height": "75px",
    },
    submit: {
        "width": "100%",
        "bproduct-bottom": "3px solid #898989",
        "bproduct-radius": "2px",
        "padding": "5px 10px 0",
        "min-width": "95px",
        "min-height": "35px",
        "font-size": "16px",
        "font-weight": "500",
        "color": "white",
        "outline": "0",
        "height": "35px",
        "&:disabled": {
            "background-color": "#c1c1c1",
            "bproduct-bottom-color": "#898989 !important",
            "color": "white"
        }
    },
    etName: {
        minWidth: "100px",
        maxWidth: "200px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    columnName: {
        minWidth: "100px",
        maxWidth: "210px",
    },
    dialogContent: {
        padding: "0",
        "overflow": "hidden",
    },
    saveButton: {},
    rows: {
        "min-height": "calc(100vh - 295px)",
        "max-height": "calc(100vh - 295px)",
        "overflow": "auto",
        "width": "100%",
    },
    header: {
        "width": "100%",
    },
    dialogContainer: {
        "& > div": {
            "max-width": "calc(100vw - 200px)"
        }
    },
    rootGroup: {
        "max-height": "32px",
        "min-height": "32px",
        "width": "100%",
        "margin-bottom": "15px",
        "margin-top": "22px",
    },
    scroller: {
        "width": "100%",
        "display": "flex",
        "justify-content": "flex-end",
    },
    flexContainer: {
        "bproduct-radius": "4px",
        "bproduct": "1px solid rgba(0,0,0,.12)",
    },
    indicator: {
        "display": "none",
    },
    selected: {
        "background-color": "#898989",
        "&:hover": {
            "background-color": "#616161",
        }
    },
    tableContainer: {
        "height": "calc(100vh - 300px)",
        "border-top": "5px solid #898989",
        "overflow": "auto",
    },
    textField: {
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
    },
    svg: {
        "fill": "#d9534f",
        "width": "auto",
        "height": "31px",
        "stroke": "#fff",
        "fill-rule": "evenodd",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-miterlimit": "2",
        "stroke-width": "0px",
    },
    tooltipError: {
        "font-size": "14px",
        "background": "#d9534f",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    select: {
        "&:focus": {
            "background-color": "#fff",
        },
    },
    table: {
    },
    arrow: {
        "fill": "#fff",
        "width": "auto",
        "height": "35px",
        "stroke": "#fff",
        "fill-rule": "evenodd",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-miterlimit": "2",
        "stroke-width": "2px",
    },
    arrowBlock: {
        height: "calc(100vh - 294px)",
        marginTop: "52px"
    },
    arrowButton: {
        width: "45px",
        height: "45px",
        maxHeight: "45px"
    },
    withoutSelectionBlock: {
        marginBottom: "20px"
    },
    searchItems: {
        margin: "0 0 8px 0",
        width: "100%"
    },
    formControlCategory: {
        margin: "0 0 8px 0",
        minWidth: 120,
        maxWidth: 400,
    },
    selectMenu: {
        maxWidth: 500,
    },
    productRow: {
        cursor: "pointer",
        // height: "50px"
    },
    tableHeaderCheckbox:{
        paddingLeft: "12px",
        "& .MuiFormControlLabel-root": {
            marginLeft: 0,
        }
    },
    tableSelectedContainer: {
        "height": "calc(100vh - 434px)",
        "overflow": "auto",
        border: "1px solid #c1c1c1",
    },
    tableName: {
        fontSize: "24px",
        fontWeight: "600",
    },
    footerTable: {
        width: "calc(100% + 2px)",
        borderBottom: "1px solid #c1c1c1",
        borderLeft: "1px solid #c1c1c1",
        borderRight: "1px solid #c1c1c1",
    },
    tableWrapper: {
        marginBottom:"10px"
    },
    footer: {
        width: "100%",
    }
}));

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: "500px",
            maxWidth: "285px",
            minWidth: "285px",
            width: 250,
        },
    },
};

const MenuCategoryProps = {
    PaperProps: {
        style: {
            maxHeight: "500px",
            maxWidth: "485px",
            minWidth: "285px",
            width: 400,
        },
    },
};
const defaultColumns = [
    {
        id: 'article_number',
        label: 'Артикул продукта',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'batch',
        label: 'Серия',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'category_id',
        label: 'Категория',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'name',
        label: 'Название продукта',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'standard_name',
        label: 'Эталонное наименование',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'description',
        label: 'Описание продукта',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'stock_quantity',
        label: 'Остатки',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'unit',
        label: 'Единицы измерения',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'price_without_vat',
        label: 'Цена без НДС',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'tax_percent',
        label: 'Ставка НДС',
        value: 'Ставка НДС',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'producer',
        label: 'Производитель',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'expiration_date',
        label: 'Срок годности',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'min_ordered_quantity',
        label: 'Минимальное количество для заказа',
        format: (value) => value.toLocaleString(),
    },
    {
        id: 'can_be_ordered',
        label: 'Под заказ',
        format: (value) => value.toLocaleString(),
    },
];

const valButtonlist = [
    {
        key: 'all',
        value: 'Все'
    },
    {
        key: 'without_selection',
        value: 'Загруженные позиции'
    },
    {
        key: 'without_standard',
        value: 'Не сопоставлено'
    },
    {
        key: 'error',
        value: 'Ошибки'
    }
]

export const ProductList = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles();
    const {priceId, open, withContract} = props;
    const categories = props.categories;
    const isMountedRef = useRef(null);
    const {products, productsWithCategory} = useSelector(state => state.products)
    const [tabValue, setTab] = useState(0)
    const [status, setStatus] = useState('all')
    let columns = status !== 'all' ? defaultColumns.filter(el => el.id !== 'can_be_ordered') : defaultColumns
    const [mappingColumns, setMappingColumns] = useState(columns.map(el => el.id ))

    const valuesButtonList = withContract ? valButtonlist : valButtonlist.filter(el => el.key !== 'without_selection')
    const [selectedWithCategory, setSelectedWithCategory] = useState([]);
    const [selectedWithoutCategory, setSelectedWithoutCategory] = useState([]);

    const [totalWithCategory, setTotalWithCategory] = useState(null);
    const [totalWithoutCategory, setTotalWithoutCategory] = useState(null);

    const [search, setSearch] = useState(null)
    const debounced = useDebouncedCallback(
        (value) => {
            setSearch(value)
        },
        900
    );
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [openAddProduct, setOpenAddProduct] = useState(false);

    const closeAddProductDialog = () => {
        setOpenAddProduct(false)
        getProducts().then(r => {})
    }

    const handleChangeMappingColumn = (e) => {
        setMappingColumns(e.target.value)
    };

    const handleSelectCategory = (e) => {
        setSelectedCategory(e.target.value)
    };

    const renderColumn = (columnName, product) => {
        let nameColumnsError = []
        let error_messages = JSON.parse(product.error_message);
        if (error_messages && error_messages.length > 0) {
            nameColumnsError = error_messages.map(el => el.column)
        }
        switch (columnName) {
            case 'expiration_date':
                return product[columnName] !== null ? (<TextField
                    type="date"
                    defaultValue={product[columnName]}
                    className={classes.textField}
                    disabled={true}
                    fullWidth={true}/>) : (
                    nameColumnsError.indexOf(columnName) !== -1
                            ? <Tooltip
                                classes={{
                                    tooltip: classes.tooltipError
                                }}
                                title="Поле пустое или заполнено неверно"
                                placement="top-start">
                                <div className={classes.defaultError}>{product[columnName]}<ErrorIcon key={columnName}
                                                                                                      className={classes.svg}/>
                                </div>
                            </Tooltip>
                            : null
                )
            case 'category_id':
                return product[columnName] !== null ? categories.filter(item => item.id === product[columnName])[0].name : null
            default:
                return (nameColumnsError.indexOf(columnName) !== -1
                    ? <Tooltip
                        classes={{
                            tooltip: classes.tooltipError
                        }}
                        title="Поле пустое или заполнено неверно"
                        placement="top-start">
                        <div className={classes.defaultError}>{product[columnName]}<ErrorIcon key={columnName}
                                                                                              className={classes.svg}/>
                        </div>
                    </Tooltip>
                    : getColumn(columnName, product))
        }
    }

    const getColumn = (columnName, product) => {
        switch (columnName) {
            case 'stock_quantity':
                return (<div className={classes.default}>{product[columnName] ? round(product[columnName], 2) : null}</div>)
            case 'tax_percent':
                return (<div className={classes.default}>{taxPercentValuesProduct.filter(el => el.key === product.tax_percent)[0].value}</div>)
            case 'price_without_vat':
                return (<div className={classes.default}>{product[columnName] ? round(product[columnName], 2) : null}</div>)
            case 'min_ordered_quantity':
                return (<div className={classes.default}>{product[columnName] ? round(product[columnName], 2) : null}</div>)
            case 'can_be_ordered':
                return status === 'all' ? (
                    <Checkbox
                        disabled
                        checked={product[columnName]}
                        key={product.id}
                    />) : null
            case 'standard_name':
                return (<div className={classes.default}>{product.standard?.name.standard}</div>)
            case 'name':
                return (<div className={classes.columnName}>{product[columnName]}</div>)
            case 'unit':
                return (<div className={classes.columnName}>{product.unit ? product.unit.short : ''}</div>)
            default:
                return (<div className={classes.etName}>{product[columnName]}</div>)
        }
    }

    const tabChange = (ev, newValue) => {
        setTab(newValue);
        setStatus(valuesButtonList[newValue].key);
        setSearch(null);
    }

    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)

    const countOnPageAr = [5, 10, 25, 100]
    const countOnPageNodes = countOnPageAr.map((item, index) => <MenuItem value={index}>{item}</MenuItem>)

    const changeLimitOnPage = (event) => {
        if (products && products.meta.total < countOnPageAr[event.target.value]) {
            setPage(1);
        }
        setLimit(countOnPageAr[event.target.value]);
    }

    const changePage = (event, page) => {
        setPage(page);
    }

    const [limitProductsWithCategory, setLimitProductsWithCategory] = useState(10)
    const [pageProductsWithCategory, setPageProductsWithCategory] = useState(1)

    const countOnPageNodesProductsWithCategory = countOnPageAr.map((item, index) => <MenuItem value={index}>{item}</MenuItem>)

    const changeLimitOnPageProductsWithCategory = (event) => {
        if (productsWithCategory && productsWithCategory.meta.total < countOnPageAr[event.target.value]) {
            setPageProductsWithCategory(1);
        }
        setLimitProductsWithCategory(countOnPageAr[event.target.value]);
    }

    const changePageProductsWithCategory = (event, page) => {
        setPageProductsWithCategory(page);
    }

    const getProducts = async () => {
        let params = {
            id: priceId,
            limit: limit,
            page: page,
            status: status,
            ...(search ? {search: search} : {}),
            ...(withContract ? {withContract: true} : {}),
        }
        await dispatch(PriceActions.getProducts(params))
    }

    useEffect(() => {
        isMountedRef.current = true;
        if (open) {
            if (isMountedRef.current) {
                if (status !== 'without_selection') {
                    defaultSelected();
                }
                getProducts().then(r => {
                })
            }
        } else {
            dispatch({type: "PRODUCT_RESET"});
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [dispatch, page, limit, priceId, open, status, search])

    const getProductsWithCategory = async () => {
        let params = {
            id: priceId,
            limit: limitProductsWithCategory,
            page: pageProductsWithCategory,
            status: status,
            category_id: selectedCategory,
        }
        await dispatch(PriceActions.getProductsWithCategory(params))
    }

    useEffect(() => {
        isMountedRef.current = true;
        if (open && status === 'without_selection' && selectedCategory) {
            if (isMountedRef.current) {
                getProductsWithCategory().then(r => {
                })
            }
        } else {
            dispatch({type: "PRODUCT_RESET"});
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [dispatch, pageProductsWithCategory, limitProductsWithCategory, priceId, open, status, selectedCategory])

    const handleSelectAllWithCategoryClick = (e) => {
        selectAll(e, productsWithCategory, setSelectedWithCategory, setTotalWithCategory)
    };

    const handleSelectAllWithoutCategoryClick = (e) => {
        selectAll(e, products, setSelectedWithoutCategory, setTotalWithoutCategory)
    };

    const selectAll = (e, products, setSelected, setTotal) => {
        if (e.target.checked && products && products.data.length > 0) {
            const newSelecteds = products.data.map(el => el.id);
            setSelected(newSelecteds);
            setTotal(null);
            return;
        }
        setSelected([]);
    }

    const defaultSelected = () => {
        setSelectedWithCategory([]);
        setSelectedWithoutCategory([]);
        setTotalWithoutCategory(null);
        setTotalWithCategory(null);
    }

    const selectTotal = (e, products, setSelected, setTotal) => {
        if (e.target.checked && products && products.data.length > 0) {
            const newSelecteds = products.data.map(el => el.id);
            setSelected(newSelecteds);
            setTotal(products.meta.total);
            return;
        }
        setSelected([]);
        setTotal(null);
    }

    const handleSelectTotalWithCategoryClick = (e) => {
        selectTotal(e, productsWithCategory, setSelectedWithCategory, setTotalWithCategory)
    };

    const handleSelectTotalWithoutCategoryClick = (e) => {
        selectTotal(e, products, setSelectedWithoutCategory, setTotalWithoutCategory)
    };


    const handleClickWithCategory = (e, id) => {
        clickRow(id, selectedWithCategory, setSelectedWithCategory)
    };

    const clickRow = (id, selected, setSelected) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selectedWithCategory - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    }

    const handleClickWithoutCategory = (e, id) => {
        clickRow(id, selectedWithoutCategory, setSelectedWithoutCategory)
    };

    const isSelectedWithoutCategory = (id) => selectedWithoutCategory.indexOf(id) !== -1;

    const isSelectedWithCategory = (id) => selectedWithCategory.indexOf(id) !== -1;

    const getRenderFooter = (products, countOnPageAr, limit, countOnPageNodes, changeLimitOnPage, changePage, isTableFooter = false) => {
        return (
            <Grid item className={isTableFooter ? classes.footerTable : classes.footer}>
                {products && products.hasOwnProperty('meta') &&
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.footerContainer}>
                    <Grid item>
                        <Grid container
                              direction="row"
                              justify="flex-start"
                              alignItems="center">
                            <Grid item>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                    spacing={1}>
                                    <Grid item>
                                        <Typography>Записей на странице:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Select
                                            labelId="countOnPage"
                                            id="countOnPage"
                                            classes={{
                                                select: classes.select
                                            }}
                                            value={countOnPageAr.indexOf(limit)}
                                            onChange={changeLimitOnPage}
                                            children={countOnPageNodes}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography>{products.meta.from ? products.meta.from : 1} - {products.meta.to} &nbsp;из {products.meta.total}</Typography>
                            </Grid>
                            <Grid item>
                                <Pagination
                                    page={products.meta.current_page}
                                    count={products.meta.last_page}
                                    className={classes.pagination}
                                    onChange={changePage}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                }
            </Grid>
        )
    }

    const getAllLabel = (selected, total, products) => {
        return `Выделить все товары на странице ${selected.length && total === null && products && selected.length === products.data.length ? '(' + selected.length + ')' : ''}`
    }

    const getTotalLabel = (total) => {
        return `Выделить все товары в прайс-листе ${total ? '(' + total + ')' : ''}`
    }

    const assembly = (categories, parent = 0, level = 0) => {
        let result = []

        if (categories.hasOwnProperty(parent)) {
            categories[parent].forEach(category => {
                result.push(<MenuItem style={{ paddingLeft: `${16 * (level + 1)}px`}} key={ category.id } value={ category.id }>{ category.name }</MenuItem>)

                result = result.concat(assembly(categories, category.id, level + 1))
            })
        }

        return result
    }

    const getCategoriesTree = categories => {
        let tmp = {}
        categories.forEach(category => {
            if (!tmp.hasOwnProperty((category.category !== null) ? category.category.id : 0)) {
                tmp[(category.category !== null) ? category.category.id : 0] = []
            }

            tmp[(category.category !== null) ? category.category.id : 0].push(category)
        })

        return assembly(tmp)
    }

    return (
        <Grid container className={classes.fullHeight} direction="column" justify="space-between" alignItems="stretch">
            {openAddProduct && <AddProductDialog categories={categories} open={openAddProduct} onClose={closeAddProductDialog} mode={'create'}/>}
            <Grid container spacing={0} justify={status !== 'without_selection' ? "space-between" : "flex-end"}
                  style={{marginRight: "72px"}}>
                {status !== 'without_selection' &&
                <Grid item>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="mappingColumn" style={{fontSize: "18px", color: "#898989"}}>Колонки</InputLabel>
                        <Select
                            labelId="mappingColumn"
                            id="mappingColumn"
                            multiple
                            classes={{
                                select: classes.select,
                            }}
                            value={mappingColumns}
                            onChange={handleChangeMappingColumn}
                            input={<Input/>}
                            MenuProps={MenuProps}
                        >
                            {columns.map((obj) => (
                                <MenuItem key={obj.id} value={obj.id}>{obj.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                }
                <Grid item>
                    <ButtonList classes={{
                        root: classes.rootGroup,
                        flexContainer: classes.flexContainer,
                        scroller: classes.scroller,
                        indicator: classes.indicator,
                    }} value={tabValue} values={valuesButtonList} onChange={tabChange}/>
                </Grid>
            </Grid>
            {status !== 'without_selection' &&
            <React.Fragment>
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.filter(el => mappingColumns.indexOf(el.id) !== -1).map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{minWidth: column.minWidth}}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products && products.hasOwnProperty('data') && products.data.map((product) => {
                                return (
                                    <TableRow
                                        className={classes.tableRow}
                                        key={product.id}
                                    >
                                        {columns.filter(el => mappingColumns.indexOf(el.id) !== -1).map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{minWidth: column.minWidth}}
                                            >
                                                {renderColumn(column.id, product)}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {getRenderFooter(products, countOnPageAr, limit, countOnPageNodes, changeLimitOnPage, changePage)}
            </React.Fragment>
            }
            {status === 'without_selection' &&
            <Grid container justify={'center'} className={classes.withoutSelectionBlock}>
                <Grid item xs={5}>
                    <Grid container direction={'row'} alignItems={'flex-end'} style={{marginTop: "14px"}}
                          className={classes.tableWrapper}>
                        <Grid item xs={3}>
                            <Typography className={classes.tableName}>Без категории</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                id="search"
                                variant="standard"
                                name={'search'}
                                placeholder={'Поиск по наименованию'}
                                fullWidth={true}
                                margin={'normal'}
                                onChange={(e) => {
                                    debounced.callback(e.target.value)
                                }}
                                classes={{
                                    root: clsx(classes.filterLabel, classes.searchLabel)
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon style={{color: '#c1c1c1'}}/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <TableContainer className={classes.tableSelectedContainer}>
                        <Table
                            className={classes.table}
                            stickyHeader aria-label="sticky table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={Boolean(products && selectedWithoutCategory.length > 0 && totalWithoutCategory === null && selectedWithoutCategory.length === products.data.length)}
                                                onChange={handleSelectAllWithoutCategoryClick}
                                                inputProps={{'aria-label': 'select all'}}
                                            />
                                        } label={getAllLabel(selectedWithoutCategory, totalWithoutCategory, products)}
                                                          className={classes.tableHeaderCheckbox}/>
                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={!!totalWithoutCategory}
                                                onChange={handleSelectTotalWithoutCategoryClick}
                                                inputProps={{'aria-label': 'select all'}}
                                            />
                                        } label={getTotalLabel(totalWithoutCategory)}
                                                          className={classes.tableHeaderCheckbox}/>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {products && products.hasOwnProperty('data') && products.data.map((product) => {
                                    const isItemSelected = isSelectedWithoutCategory(product.id);
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClickWithoutCategory(event, product.id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            className={classes.productRow}
                                            tabIndex={-1}
                                            key={product.id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                />
                                                {product.name}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {getRenderFooter(products, countOnPageAr, limit, countOnPageNodes, changeLimitOnPage, changePage, true)}
                </Grid>
                <Grid item xs={1}>
                    <Grid container direction={'column'} alignItems={'center'} justify={'center'}
                          className={classes.arrowBlock} spacing={2}>
                        <Grid item>
                            <ButtonIcon
                                disabled={!(selectedWithoutCategory.length > 0 && selectedCategory)}
                                onClick={() => {
                                    return dispatch(PriceActions.setCategory({
                                        product_ids: totalWithoutCategory ? 'all' : selectedWithoutCategory,
                                        price_id: priceId,
                                        category_id: selectedCategory
                                    })).then(() => {
                                        setSelectedWithoutCategory([]);
                                        setTotalWithoutCategory(null);
                                        getProducts().then(() => {
                                            getProductsWithCategory().then(r => {
                                            })
                                        })
                                    })
                                }}
                                className={classes.arrowButton}
                                key={'toArrow'}
                                children={[(
                                    <ArrowForwardIcon
                                        key={'ArrowForwardIcon'}
                                        className={classes.arrow}/>)]}/>
                        </Grid>
                        <Grid item>
                            <ButtonIcon
                                disabled={!selectedWithCategory.length > 0}
                                onClick={() => {
                                    return dispatch(PriceActions.unsetCategory({
                                        product_ids: totalWithCategory ? 'all' : selectedWithCategory,
                                        price_id: priceId
                                    })).then(() => {
                                            setSelectedWithCategory([]);
                                            setTotalWithCategory(null);
                                            getProductsWithCategory().then(r => {
                                                getProducts().then(() => {
                                                })
                                            })
                                        }
                                    )
                                }}
                                className={classes.arrowButton}
                                key={'backArrow'}
                                children={[(
                                    <ArrowBackIcon
                                        key={'ArrowBackIcon'}
                                        className={classes.arrow}/>)]}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={5}>
                    <Grid item>
                        <Grid container direction={'row'} alignItems={'flex-end'} className={classes.tableWrapper}>
                            <Grid item xs={4}>
                                <Typography className={classes.tableName}>Выберите категорию</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl className={classes.formControlCategory}>
                                    <InputLabel id="selectedCategory" style={{fontSize: "16px", color: "#898989"}}>Категория</InputLabel>
                                    <Select
                                        labelId="selectedCategory"
                                        id="mappingColumn"
                                        classes={{
                                            select: classes.select,
                                            selectMenu: classes.selectMenu,
                                        }}
                                        value={selectedCategory ? selectedCategory : ''}
                                        onChange={handleSelectCategory}
                                        MenuProps={MenuCategoryProps}
                                    >
                                        {
                                            getCategoriesTree(categories)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <TableContainer className={classes.tableSelectedContainer}>
                        <Table
                            className={classes.table}
                            stickyHeader aria-label="sticky table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={selectedWithCategory.length > 0 && totalWithCategory === null && productsWithCategory.hasOwnProperty('data') && selectedWithCategory.length === productsWithCategory.data.length}
                                                onChange={handleSelectAllWithCategoryClick}
                                                inputProps={{'aria-label': 'select all'}}
                                            />
                                        }
                                                          label={getAllLabel(selectedWithCategory, totalWithCategory, productsWithCategory)}
                                                          className={classes.tableHeaderCheckbox}/>
                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={!!totalWithCategory}
                                                onChange={handleSelectTotalWithCategoryClick}
                                                inputProps={{'aria-label': 'select all'}}
                                            />
                                        } label={getTotalLabel(totalWithCategory)}
                                                          className={classes.tableHeaderCheckbox}/>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productsWithCategory && productsWithCategory.hasOwnProperty('data') && productsWithCategory.data.map((product) => {
                                    const isItemSelected = isSelectedWithCategory(product.id);
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClickWithCategory(event, product.id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={product.name}
                                            className={classes.productRow}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                />
                                                {product.name}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {getRenderFooter(productsWithCategory, countOnPageAr, limitProductsWithCategory, countOnPageNodesProductsWithCategory, changeLimitOnPageProductsWithCategory, changePageProductsWithCategory, true)}
                </Grid>
            </Grid>
            }
        </Grid>
    );
}
