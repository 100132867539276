import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import format from "date-fns/format"
import {ru} from "date-fns/locale"
import clsx from "clsx"

import {Grid, Icon, InputAdornment, makeStyles, TextField, Typography} from "@material-ui/core"
import {Search as SearchIcon} from "@material-ui/icons"
import {Table} from "@devexpress/dx-react-grid-material-ui";

import {CompanyActions} from "../../actions/company"
import {history} from "../../../App/helpers/history"
import {AuthorizationService} from "../../../Auth/services/authorization"
import {Submit} from "../../../App/components/Button/Submit"
import {customerStatuses} from "../../../App/dics/dic"
import {ButtonList} from "../../../App/components/ButtonList/ButtonList"
import {SettingsActions} from "../../../Settings/actions/settings";
import {ColumnChooser} from "../../../App/components/Table/ColumnChooser";
import {CustomTable} from "../../../App/components/Table/Table";
import {TableHeader} from "../../../App/components/Table/TableHeader";
import {localState} from "../../../App/components/LocalState";
import {useDebouncedCallback} from "use-debounce";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 223px)"
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100vh - 223px)",
        "min-height": "calc(100vh - 223px)"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none",
    },
    filterLabel: {
        "width": "calc(100% - 34px)",
        "background": "#fff",
        "margin-top": "6px !important",
        "padding": "0 15px 0 15px",
        "border-radius": "2px",
        "border": "2px solid #c1c1c1",
        "transition": "border-color .2s ease-in",
        "&:hover": {
            "border-color": "#898989",
        },
        '& .MuiInput-underline:before': {
            "content": "none",
            "border": "none",
        },
        '& .MuiInput-underline:after': {
            "content": "none",
            "border": "none",
        }
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    buttonList: {
        "& .MuiTabs-scroller ": {
            "justify-content": "flex-start"
        },
    }
}))

const columns = [
    {name: 'company_name', title: 'Наименование организации'},
    {name: 'inn', title: 'ИНН/БИН'},
    {name: 'kpp', title: 'КПП'},
    {name: 'email', title: 'Эл. адрес'},
    {name: 'director', title: 'ФИО директора'},
    {name: 'created', title: 'Дата создания'},
];

export const Content = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {account, filterCustomers} = useSelector(state => {
        return {...state.companies, ...state.account, ...state.companies}
    })

    const [customers, setCustomers] = useState(localState)

    const [page, setPage] = useState(1)
    const [tableRef, setTableRef] = useState(null)
    const {filter} = useSelector(state => state.filters['customers'])
    const [search, setSearch] = useState(filter.search)
    const debounced = useDebouncedCallback(
        (value) => {
            dispatch({
                type: "CUSTOMERS_FILTER",
                payload: {search: value}
            })
        },
        900
    );

    const [status, setStatus] = useState(filterCustomers.status)
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)

    const [columnOrder, setColumnOrder] = useState([
        'company_name',
        'inn',
        'kpp',
        'email',
        'director',
        'created',
    ])

    const [initialize, setInitialize] = useState(false);

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'company_name', width: 300},
        {columnName: 'inn', width: 150},
        {columnName: 'kpp', width: 150},
        {columnName: 'email', width: 150},
        {columnName: 'director', width: 200},
        {columnName: 'created', width: 200},
    ])

    useEffect(() => {
        debounced.callback(search)
        // eslint-disable-next-line
    }, [search])

    useEffect(() => {
        if (!initialize) {
            dispatch(SettingsActions.table('customers')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'customers',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
            ...(search ? {search: search} : {}),
            ...(status ? {status: customerStatuses[status].key} : {}),
        }
    }

    const getCustomers = async () => {
        return await dispatch(CompanyActions.customers({
            limit: rowsPerPage,
            page: page,
            ...getParams()
        }))
    }

    useEffect(() => {
        getCustomers().then(r => {
            setCustomers(r)
            tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
        })
    }, [dispatch, page, rowsPerPage, status, filter.sort, filter.search]);

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: "CUSTOMERS_FILTER", payload: {limitRows: newRowsPerPage}})
    }

    const tabChange = (e, newValue) => {
        setStatus(newValue);
        dispatch({type: "CUSTOMER_FILTER", payload: {search: search, status: newValue}})
    }

    return <Grid item className={classes.content}>
        <Grid item>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item className={classes.fullWidth}>
                    <TextField
                        id="search"
                        variant="standard"
                        name={'search'}
                        value={search ?? ''}
                        placeholder={'Поиск: название, ИНН/БИН, КПП'}
                        fullWidth={true}
                        margin={'normal'}
                        onChange={(event) => {
                            setSearch(event.target.value)
                        }}
                        classes={{
                            root: clsx(classes.filterLabel)
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon style={{color: '#c1c1c1'}}/>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid container direction="row" justify="space-between" alignItems="center" style={{padding: '8px'}}>
                    <Grid item className={classes.buttonList}>
                        <ButtonList value={status} values={customerStatuses} onChange={tabChange}/>
                    </Grid>
                    <Grid item style={{marginTop: '-16px'}}>
                        <Grid container direction='row' justify='start-end' alignItems='center' spacing={1}>
                            <Grid item>
                                {AuthorizationService.roles(account, "admin") &&
                                    <Submit
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        component={Link}
                                        to="/customer"
                                    >
                                        Добавить
                                    </Submit>
                                }
                            </Grid>
                            <Grid item style={{marginTop: '-4px'}}>
                                <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <CustomTable
            meta={customers.meta}
            tableName={'customers'}
            rows={customers?.data.map((customer) => ({
                company_name: <div style={{display: 'flex', alignItems:' center', padding: '8px'}}>
                    <Icon style={{marginRight: "8px"}}>
                        <img src={customer.status ? customer.status.icon : ''} alt={''}/>
                    </Icon>
                    <Typography component="div" noWrap>{!!customer.legal_detail && customer.legal_detail.name}</Typography>
                </div>,
                inn: !!customer.legal_detail && (customer.legal_detail.inn ?? customer.legal_detail.bin),
                kpp: !!customer.legal_detail && customer.legal_detail.kpp,
                email: !!customer.contact_information && customer.contact_information.emails,
                director: (!!customer.legal_detail && customer.legal_detail.hasOwnProperty('executive_director') && customer.legal_detail.executive_director) && `${customer.legal_detail.executive_director.last_name ?? ''} ${customer.legal_detail.executive_director.first_name ?? ''} ${customer.legal_detail.executive_director.middle_name ?? ''}`,
                created: format(new Date(customer.created), 'PP', {locale: ru}),
            }))}
            columns={columns}
            page={{
                page: page,
                setPage: setPage,
                rowsPerPage: rowsPerPage,
                handlePageSizeChange: handlePageSizeChange
            }}
            setTableRef={setTableRef}
            columnsSettings={{
                columnOrder: columnOrder,
                setColumnOrder: setColumnOrder,
                setColumnWidths: setColumnWidths,
                columnWidths: columnWidths,
                hiddenColumnNames: hiddenColumnNames,
                setHiddenColumnNames: setHiddenColumnNames
            }}
            tableHeader={TableHeader}
            filterActionType={"CUSTOMERS_FILTER"}
            classInputContent={classes.inputContent}
            rowComponent={({row, tableRow, children}) => (
                <Table.Row
                    tableRow={tableRow}
                    className={classes.tableRow}
                    hover
                    row={row}
                    children={children}
                    onClick={() => {
                        history.push(`/customer/${customers.data[tableRow.rowId].id}`)
                    }}
                    style={{'cursor': 'pointer'}}
                />
            )}
            filters={{
                email: {
                    nullButton: true
                },
            }}
        />
    </Grid>
}
