import React, {useState} from "react";
import format from "date-fns/format"

import {Grid, makeStyles, Typography} from "@material-ui/core"
import {Submit} from "../../../App/components/Button/Submit";
import {PurchasePlanItemsActions} from "../../actions/purchasePlanItems";
import {useDispatch} from "react-redux";

const useStyles = makeStyles(() => ({
    root: {
        marginBottom: "25px",
    },
    item: {
        minWidth: "500px"
    },
    userInfoLeft: {
        // color: "#898989"
    },
    userInfoRight: {
        // color: "blue"
    },
    company: {
        fontWeight: 500,
        marginBottom: "5px"
    },
    button: {
        "height": "35px",
    },
    leftMessage: {
        minWidth: "500px",
        color: "white",
        background: "#a9a9a9",
        alignSelf: "flex-start",
        maxWidth: "255px",
        wordWrap: "break-word",
        marginBottom: "12px",
        lineHeight: "24px",
        position: "relative",
        padding: "10px 20px",
        borderRadius: "25px",
        marginLeft: "7px",
        whiteSpace: "pre-line",
        "&>p": {
            color: "white",
        },
        "&:before": {
            left: "-7px",
            width: "20px",
            backgroundColor: "#a9a9a9",
            borderBottomRightRadius: "16px 14px",
            content: '""',
            position: "absolute",
            bottom: 0,
            height: "25px",
        },
        "&:after": {
            left: "-26px",
            width: "26px",
            backgroundColor: "#f3f3f3",
            borderBottomRightRadius: "10px",
            content: '""',
            position: "absolute",
            bottom: 0,
            height: "25px",
        }
    },
    rightMessage: {
        minWidth: "500px",
        color: "white",
        background: "#00b28e",
        alignSelf: "flex-end",
        maxWidth: "255px",
        wordWrap: "break-word",
        marginBottom: "12px",
        lineHeight: "24px",
        position: "relative",
        padding: "10px 20px",
        borderRadius: "25px",
        marginRight: "7px",
        whiteSpace: "pre-line",
        "&>p": {
            color: "white",
        },
        "&:before": {
            right: "-7px",
            width: "20px",
            backgroundColor: "#00b28e",
            borderBottomLeftRadius: "16px 14px",
            content: '""',
            position: "absolute",
            bottom: 0,
            height: "25px",
        },
        "&:after": {
            right: "-26px",
            width: "26px",
            backgroundColor: "#f3f3f3",
            borderBottomLeftRadius: "10px",
            content: '""',
            position: "absolute",
            bottom: 0,
            height: "25px",
        }
    }
}));

export const Actions = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles();

    const {type, params} = props

    const [isSubmitting, setIsSubmitting] = useState(false)

    switch (type) {
        case 'standard':
            return <Grid item>
                <Submit
                    className={classes.button}
                    disableElevation
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => {
                        setIsSubmitting(true)
                        return dispatch(PurchasePlanItemsActions.updateStandard(params.item_id, {standard_id: params.standard_id})).then(() => {
                            setIsSubmitting(false)
                        })
                    }}
                >
                    Принять эталон
                </Submit>
            </Grid>
        default:
            return null
    }
}

export const MessageLeft = (props) => {
    const classes = useStyles();

    const {message, user, company} = props

    return (
        <Grid container direction="column" justify="flex-end" alignItems="flex-start" className={classes.root}>
            <Grid item>
                <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.item}>
                    <Grid item xs={8}>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" className={classes.userInfoLeft}>
                            <Grid item className={classes.company}>{company}</Grid>
                            <Grid item>{user}</Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>{format(new Date(message.created_at), 'HH:mm')}</Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.leftMessage}>
                <Typography>{message.text}</Typography>
            </Grid>
            {message.action &&
                <Grid item>
                    <Grid container direction="row" justify="flex-end" alignItems="flex-start" className={classes.item}>
                        <Actions type={message.action.type} params={message.params} />
                    </Grid>
                </Grid>
            }
        </Grid>
    );
};

export const MessageRight = (props) => {
    const classes = useStyles();

    const {message, user, company} = props

    return (
        <Grid container direction="column" justify="flex-end" alignItems="flex-end" className={classes.root}>
            <Grid item>
                <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.item}>
                    <Grid item xs={8}>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" className={classes.userInfoRight}>
                            <Grid item className={classes.company}>{company}</Grid>
                            <Grid item>{user}</Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>{format(new Date(message.created_at), 'HH:mm')}</Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.rightMessage}>
                <Typography>{message.text}</Typography>
            </Grid>
        </Grid>
    );
};
