import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {Users} from "./Users";
import {UserActions} from "./actions/user"
import {SettingsActions} from "../../../Settings/actions/settings";
import {localState} from "../../../App/components/LocalState";

export const OperatorsContent = () => {
    const dispatch = useDispatch()
    const [users, setUsers] = useState(localState)

    const [page, setPage] = useState(1)
    const [tableRef, setTableRef] = useState(null)
    const {filter} = useSelector(state => state.filters['operators'])
    const [rowsPerPage, setRowsPerPage] = useState(filter.limitRows)

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'login', width: 200},
        {columnName: 'email', width: 200},
        {columnName: 'full_name', width: 300},
        {columnName: 'roles', width: 150},
        {columnName: 'created_at', width: 150},
    ])

    const [columnOrder, setColumnOrder] = useState([
        'full_name',
        'login',
        'email',
        'roles',
        'created_at'
    ])

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const [initialize, setInitialize] = useState(false);

    useEffect(() => {
        if (!initialize) {
            dispatch(SettingsActions.table('operators')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                }
                dispatch({type: "FILTER_CLEAR"})
                setInitialize(true)
            })
        }
        // eslint-disable-next-line
    }, [initialize])

    useEffect(() => {
        if (initialize) {
            dispatch(SettingsActions.tableUpdate({
                name: 'operators',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    const getParams = () => {
        return {
            ...(filter.sort.name && filter.sort.direction ? {name: filter.sort.name} : {}),
            ...(filter.sort.direction ? {direction: filter.sort.direction} : {}),
        }
    }

    useEffect(() => {
        const getOperators = async () => {
            return await dispatch(UserActions.users({
                role: 'operator',
                limit: rowsPerPage,
                page: page,
                ...getParams()
            }))
        }
        getOperators().then((response) => {
            setUsers(response)
            tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
        })
    }, [dispatch, page, rowsPerPage, filter]);

    const handlePageSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
        dispatch({type: 'OPERATORS_FILTER', payload: {limitRows: newRowsPerPage}})
    }

    return <Users
        setTableRef={setTableRef}
        operators={users}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        handlePagesSizeChange={handlePageSizeChange}
        label={'Операторы'}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
        setColumnWidths={setColumnWidths}
        columnWidths={columnWidths}
        hiddenColumnNames={hiddenColumnNames}
        setHiddenColumnNames={setHiddenColumnNames}
    />
}
