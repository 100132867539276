import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik, getIn} from "formik"
import {TextField} from "formik-material-ui"
import {useDebounce} from "use-debounce";
import * as Yup from "yup";

import {Grid, makeStyles, MenuItem, Paper, TextField as MuiTextField, Typography} from "@material-ui/core"
import {Autocomplete} from "@material-ui/lab";

import {Submit} from "../../../App/components/Button/Submit"
import {ContractActions} from "../../actions/contract"

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
    },
    header: {
        "width": "100%"
    },
    container: {
        "width": "100%",
        "height": "calc(100vh - 111px)"
    },
    input: {
        "width": "100%",
    },
    inputContent: {
        "width": "100%",
        "height": "calc(100% - 54px)",
        "min-height": "calc(100% - 54px)",
        "padding": "20px 30px 0",
        "overflow": "auto"
    },
    footer: {
        "padding": "4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-right": "5px",
        "padding-top": "18px !important"
    },
    notificationList: {
        height: "calc(100vh - 215px)",
        overflow: "auto",
    }
}))

export const Content = () => {
    const {statuses} = useSelector(state => state.contract)

    const dispatch = useDispatch()
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [contracts, setContracts] = useState([])
    const [search, setSearch] = useState(null)
    const [searchRequest] = useDebounce(search, 900)

    useEffect(() => {
        const getStatuses = async () => {
            await dispatch(ContractActions.statuses())
        }

        if (!loading) {
            getStatuses().then(_ => {
                setLoading(true)
            })
        }
        // eslint-disable-next-line
    }, [loading]);

    useEffect(() => {
        const getContracts = async () => {
            return await dispatch(ContractActions.contracts({
                limit: 10,
                page: 1,
                include: 'log.status',
                ...(searchRequest ? {search: searchRequest} : {}),
            }))
        }

        if (searchRequest) {
            getContracts().then(contracts => {
                setContracts(contracts.data)
            })
        }
        // eslint-disable-next-line
    }, [dispatch, searchRequest])

    return loading ? (
        <Grid item className={classes.content}>
            <Paper>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.fullWidth}>
                        <Grid container className={classes.container} direction="column" justify="space-between" alignItems="stretch">
                            <Formik
                                initialValues={{
                                    contract: {},
                                    status: ''
                                }}
                                validationSchema={Yup.object().shape({
                                    contract: Yup.mixed().required("Поле не может быть пустым"),
                                    status: Yup.string().required("Поле не может быть пустым, необходимо выбрать статус"),
                                })}
                                onSubmit={(values, {setSubmitting}) => {
                                    return dispatch(ContractActions.changeStatus(values.contract.id, values)).then(
                                        () => {
                                            setSubmitting(false)
                                        },
                                        () => {
                                            setSubmitting(false)
                                        }
                                    )
                                }}
                            >
                                {({
                                      values,
                                      errors,
                                      touched,
                                      setFieldTouched,
                                      setFieldValue,
                                      isSubmitting
                                  }) => (
                                    <Form className={classes.fullWidth}>
                                        <Grid item className={classes.inputContent}>
                                            <Grid container className={classes.fullWidth} direction="column" justify="space-between" alignItems="stretch">
                                                <Grid item className={classes.header}>
                                                    <Grid container className={classes.fullWidth} direction="column" justify="flex-start" alignItems="stretch">
                                                        <Grid item className={classes.fullWidth}>
                                                            <Typography className={classes.title}>
                                                                Изменение статуса договора
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.notificationList} direction="row" justify="flex-start" alignItems="stretch" spacing={4}>
                                                <Grid item xs={4}>
                                                    <Autocomplete
                                                        filterOptions={(options) => options}
                                                        name="contract"
                                                        required
                                                        options={contracts}
                                                        getOptionLabel={contract => {
                                                            return contract ? (contract.hasOwnProperty('offer') ? (contract.offer.lot.order.name ? `${contract.id} ${contract.offer.lot.order.name}` : `${contract.id}`) : '') : ''
                                                        }}
                                                        noOptionsText='Данные не найдены'
                                                        onChange={(e, contract) => {
                                                            if (contract) {
                                                                setFieldValue('contract', contract)
                                                            } else {
                                                                setFieldValue('contract', {})
                                                                setFieldTouched('status', false)
                                                            }
                                                        }}
                                                        renderInput={params => {
                                                            const error = getIn(touched, 'id') && getIn(errors, 'id');

                                                            return <Field
                                                                fullWidth
                                                                component={MuiTextField}
                                                                required
                                                                {...params}
                                                                onChange={(e) => {
                                                                    e.target.value.length ? setSearch(e.target.value) : setContracts([])
                                                                }}
                                                                error={!!error}
                                                                helperText={error ?? 'Поиск: номер, идентификатор'}
                                                                name="contract"
                                                                label="ID договора"
                                                            />
                                                        }}
                                                        onFocus={() => {
                                                            setFieldTouched('contract', true, true)
                                                        }}
                                                        onBlur={() => {
                                                            setFieldTouched('contract', true, true)
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Field
                                                        fullWidth
                                                        type="text"
                                                        name="status"
                                                        label="Статус"
                                                        select
                                                        disabled={isSubmitting || !values.contract.hasOwnProperty('id')}
                                                        variant="standard"
                                                        component={TextField}
                                                        required
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                    >
                                                        {
                                                            statuses.map(status => (
                                                                <MenuItem
                                                                    disabled={
                                                                        (values.contract.hasOwnProperty('log') || values.contract.hasOwnProperty('status'))
                                                                            ? (!values.contract.log.find(log => log.status.id === status.id) || (values.contract.status.id === status.id))
                                                                            : true
                                                                    }
                                                                    key={status.id}
                                                                    value={status.id}>
                                                                    {status.name}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Field>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.footer}>
                                            <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                                <Grid item className={classes.footerContent}>
                                                    <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                                        <Grid item>
                                                            <Submit
                                                                disableElevation
                                                                variant="contained"
                                                                color="primary"
                                                                type="submit"
                                                                disabled={isSubmitting}
                                                            >
                                                                Изменить
                                                            </Submit>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    ) : null
}
