import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"

import {
    Grid,
    makeStyles,
} from "@material-ui/core"
import {Table} from "@devexpress/dx-react-grid-material-ui";
import {CustomTable} from "../../../../App/components/Table/Table";
import {TableHeader} from "../../../../App/components/Table/TableHeader";
import {useParams} from "react-router-dom";
import {Edit} from "./Integration/Buttons/Edit";
import {Add} from "./Integration/Buttons/Add";
import {Delete} from "./Integration/Buttons/Delete";
import {IntegrationActions} from "../../../actions/integration";

const useStyles = makeStyles(() => ({
    fullWidth: {
        "width": "100%"
    },
    inputContent: {
        "width": "100%",
        'height': 'calc(100vh - 307px)',
        'min-height': 'calc(100vh - 307px)',
    },
    footer: {
        "padding": "4px !important",
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    tableContainer: {
        "height": "100%",
        "min-height": "100%"
    },
    tableRow: {
        "cursor": "pointer",
        "text-decoration": "none"
    },
}))

export const Integrations = (props) => {
    const dispatch = useDispatch()
    let {id} = useParams()

    const {setLoading} = props
    const classes = useStyles()
    const [integrations, setIntegrations] = useState({data: [], meta: {total: 0}})

    const columns = [
        {name: 'name', title: 'Наименование'},
        {name: 'token', title: 'Токен'},
        {name: 'buttons', title: ' '},
    ]

    const [tableRef, setTableRef] = useState(null)

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(50)

    const [columnWidths, setColumnWidths] = useState([
        {columnName: 'name', width: 450},
        {columnName: 'token', width: 450},
        {columnName: 'buttons', width: 350},
    ])

    const [columnOrder, setColumnOrder] = useState([
        'name',
        'token',
        'buttons',
    ])

    const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

    const handlePagesSizeChange = newRowsPerPage => {
        setPage(1)
        setRowsPerPage(newRowsPerPage)
    }

    useEffect(() => {
        const getIntegrations = async () => {
            return await dispatch(IntegrationActions.integrations({
                limit: rowsPerPage,
                page: page,
            }))
        }

        getIntegrations().then((data) => {
            setIntegrations(data)
            tableRef && tableRef.current && tableRef.current.scrollIntoView({behavior: 'smooth'})
        })
    }, [dispatch, page, rowsPerPage, id]);

    return (
        <Grid item className={classes.fullWidth}>
            <Grid container direction="column" justify="space-between" alignItems="stretch">
                <Grid item className={classes.footer}>
                    <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                        <Grid item className={classes.footerContent}>
                            <Grid container className={classes.footerContainer} direction="row" justify="flex-end" alignItems="center" spacing={2}>
                                <Grid item>
                                    <Add setLoading={setLoading} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <CustomTable
                    meta={integrations.meta}
                    tableName={'integrations'}
                    rows={integrations?.data.map((integration) => ({
                        name: integration.name,
                        token: integration.token,
                        buttons: <React.Fragment>
                            <Edit integration={integration} setLoading={setLoading} />
                            <Delete integration={integration} setLoading={setLoading} />
                        </React.Fragment>
                    }))}
                    columns={columns}
                    page={{page: page, setPage: setPage, rowsPerPage: rowsPerPage, handlePageSizeChange: handlePagesSizeChange}}
                    setTableRef={setTableRef}
                    columnsSettings={{
                        columnOrder: columnOrder,
                        setColumnOrder: setColumnOrder,
                        setColumnWidths: setColumnWidths,
                        columnWidths: columnWidths,
                        hiddenColumnNames: hiddenColumnNames,
                        setHiddenColumnNames: setHiddenColumnNames
                    }}
                    tableHeader={TableHeader}
                    classInputContent={classes.inputContent}
                    rowComponent={({row, tableRow, children}) => (
                        <Table.Row
                            tableRow={tableRow}
                            children={children}
                            className={classes.tableRow}
                            row={row}
                        />
                    )}
                    filters={{
                        name: {
                            nullButton: true,
                        },
                        token: {
                            nullButton: true,
                        },
                        buttons: {
                            nullButton: true
                        },
                    }}
                />
            </Grid>
        </Grid>
    )
}
