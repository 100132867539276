import {HttpService} from "../../../App/services/http"

export const ItemService = {
    items,
    count,
    add,
    amount,
    remove
}

function items(id, params) {
    return HttpService.get(`/marketplace/cart/${id}/items`, params)
}

function count() {
    return HttpService.get(`/marketplace/cart/item/count`)
        .then(response => {
            return response.count
        })
}

function add(values) {
    return HttpService.post("/marketplace/cart/item", values)
}

function amount(id, values) {
    return HttpService.put(`/marketplace/cart/item/${id}/amount`, values)
}

function remove(id) {
    return HttpService.deleteMethod(`/marketplace/cart/item/${id}`)
}
