import React, {Fragment, useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {Field, Form, Formik} from "formik"
import * as Yup from "yup"
import DateFnsUtils from "@date-io/date-fns"
import format from "date-fns/format"
import {ru} from "date-fns/locale"

import {
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Link,
    makeStyles,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Tooltip,
    Typography
} from "@material-ui/core"
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {
    FileCopy,
    GetApp as GetAppIcon,
    Help as HelpIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon
} from '@material-ui/icons'

import {ShopActions} from "../../actions/shop"
import {TabPanel} from "../../../../../Account/components/Tabs/TabPanel"
import {Submit} from "../../../../../App/components/Button/Submit"
import {TextFieldWithError as CustomTextField} from "../../../../../App/components/Inputs/TextFieldWithError"
import {Log} from "./Log"
import {round} from "../../../../../App/helpers/number"
import {ButtonIcon} from "../../../../../App/components/Button/ButtonIcon"
import {Card} from "../../../../../Company/components/Card"
import {history} from "../../../../../App/helpers/history"
import {History} from "./History"
import {endings} from "../../../../../App/helpers/endings"
import {SettingsActions} from "../../../../../Settings/actions/settings"
import * as AppStorage from "../../../../../App/helpers/storage"
import {parseCurrency} from "../../../../../App/helpers/currency";
import {Download as DownloadSignature} from "../../../../../Crypto/Download";
import {Button as SignatureButton} from "../../../../../Crypto/Action/Button";
import {Link as SignatureLink} from "../../../../../Crypto/Action/Link";

const useStyles = makeStyles(theme => ({
    content: {
        width: theme.content.width,
        padding: theme.content.padding,
        margin: theme.content.margin,
    },
    fullWidth: {
        "width": "100%"
    },
    full: {
        "width": "100%"
    },
    title: {
        "font-size": "20px",
        "color": "#485868",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis",
        "padding-right": "8px"
    },
    header: {
        "width": "100%",
        "padding": "20px 29px 0 29px !important",
    },
    input: {
        "width": "100%",
    },
    tab: {
        "width": "100%",
        "overflow": "auto",
        "height": "calc(100vh - 303px)",
        "margin-bottom": "4px"
    },
    tableContainer: {
        "overflow": "auto"
    },
    tabContent: {
        "padding": "20px 29px !important"
    },
    itemInput: {
        "margin-top": "0 !important"
    },
    inputContent: {
        "padding": "20px 30px 0"
    },
    column: {
        "height": "86px"
    },
    footer: {
        "padding": "0 4px 4px 4px !important",
    },
    footerContent: {
        "height": "70px",
        "background-color": "#e7eaee",
        "padding": "0 20px !important"
    },
    footerContainer: {
        "padding-top": "17px",
        "padding-right": "5px",
        "padding": "12px"
    },
    download: {
        "color": "#485868"
    },
    winner: {
        '& .MuiTableCell-root': {
            'font-weight': '500'
        }
    },
    line: {
        "text-decoration": "line-through",
        "&:hover": {
            fontWeight: 500,
        }
    },
    companyClick: {
        "&:hover": {
            fontWeight: 500,
        }
    },
    tooltip: {
        "max-width": "200px",
        "border-radius": "0px !important",
        "font-size": "0.8rem"
    },
    lightTooltip: {
        "font-size": "0.8rem",
        "backgroundColor": "white",
        "color": "rgba(0, 0, 0, 0.87)",
        "border": "1px solid #ededed"
    },
    badge: {
        "right": "-12px"
    },
    tabBadge: {
        "padding-right": "34px"
    },
    reject: {
        "&:hover": {
            "background-color": "#ad3e3b",
        },
        display: "inline-flex",
        marginTop: "0",
        "width": "125px",
        "min-width": "30px",
        "max-height": "35px",
        "padding": "0",
        "justify-content": "center",
        "align-items": "center",
        "min-height": "30px",
        "font-size": "16px",
        "color": "#fff",
        "font-weight": "500",
        "text-transform": "uppercase",
        "border": "none",
        "border-bottom": "3px solid #ad3e3b",
        "border-radius": "2px",
        "outline": "0",
        "background-color": "#d9534f",
        "cursor": "pointer",
        marginLeft: "15px",
    },
    approve: {
        display: "inline-flex",
        marginTop: "0",
        "width": "95px",
        "font-weight": "500",
        "max-height": "35px",
        "min-height": "30px",
    },
    fileItem: {
        maxHeight: "25px"
    },
    link: {
        "display": "flex",
        "line-height": "31px",
        "color": "#485868",
    }
}))

const filterLotFiles = (files) => {
    let maxProtocolId = 0;
    files.forEach(file => {
        if ((file.type?.key === 'protocol') && (file.id > maxProtocolId)) maxProtocolId = file.id;
    })
    return files.filter(file => !file.type || file.type.key !== 'protocol' || (file.type.key === 'protocol' && file.id === maxProtocolId))
}

export const Content = () => {
    let {id} = useParams()

    const dispatch = useDispatch()
    const classes = useStyles()
    const {orderSettings} = useSelector(state => state.settings)
    const [order, setOrder] = React.useState(null);
    const [value, setValue] = React.useState(1);
    const [loading, setLoading] = React.useState(false)
    const [dialog, setDialog] = React.useState(false);
    const [submit, setSubmit] = React.useState(false);
    const [winnerIndexes, setWinnerIndexes] = React.useState([]);
    const [dialogReject, setDialogReject] = React.useState(false);
    const [openRow, setOpenRow] = React.useState(false);
    const [cardCompany, setCardCompany] = useState(null);
    const [openCard, setOpenCard] = useState(false)

    useEffect(() => {
        const getSettings = async () => {
            return await dispatch(SettingsActions.order())
        }

        getSettings().then(() => {})
    }, [dispatch])

    const getAction = (order) => {
        let action = null

        const cancel = (
            <Grid item>
                <Grid container direction="row" justify="flex-end" alignItems="stretch" spacing={2}>
                    {((parseInt(order.status.index) === 2) && (!order.application_deadline_prolongation || (order.application_deadline_prolongation < 2))) &&
                        <Grid item>
                            <Submit
                                disableElevation
                                variant="contained"
                                color="primary"
                                type="button"
                                disabled={submit}
                                onClick={() => {
                                    setDialog({
                                        title: 'Продление срока подачи предложений',
                                        content: (
                                            <Formik
                                                initialValues={{
                                                    application_deadline: order.application_deadline
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    application_deadline: Yup.mixed().test('min-date', 'Некорректная дата', function (value) {
                                                        if (value) {
                                                            let currentDate = new Date(value)
                                                            let minDate = new Date(order.application_deadline)

                                                            return currentDate.valueOf() - minDate.valueOf() >= 0
                                                        }

                                                        return true
                                                    }).required("Поле не может быть пустым или состоять из одних пробелов!")
                                                })}
                                                onSubmit={(values, {setSubmitting}) => {
                                                    setSubmit(true)
                                                    return dispatch(ShopActions.deadline(order.id, {application_deadline: format(values.application_deadline, 'yyyy-MM-dd HH:mmxxxxx')})).then(
                                                        () => {
                                                            setSubmit(false)
                                                            setDialog(false)
                                                            setLoading(false)
                                                        },
                                                        errors => {
                                                            if (errors) {
                                                            }
                                                            setSubmit(false)
                                                            setSubmitting(false)
                                                            setLoading(false)
                                                        }
                                                    )
                                                }}
                                            >
                                                {({
                                                      dirty,
                                                      errors,
                                                      touched,
                                                      isSubmitting,
                                                      isValid,
                                                      values,
                                                      setFieldValue,
                                                      setFieldTouched
                                                  }) => (
                                                    <Form>
                                                        <DialogContent>
                                                            <Grid container direction="row" justify="center" alignItems="stretch" spacing={2}>
                                                                <Grid item xs={8}>
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                        <KeyboardDateTimePicker
                                                                            disableToolbar
                                                                            fullWidth
                                                                            error={Boolean(errors.hasOwnProperty('application_deadline') && touched.hasOwnProperty('application_deadline'))}
                                                                            helperText={(errors.hasOwnProperty('application_deadline') && touched.hasOwnProperty('application_deadline')) ? errors.application_deadline : `Обратите внимание, минимальный срок приёма предложение ${orderSettings.time_to_submit_applications} часа`}
                                                                            name="application_deadline"
                                                                            required
                                                                            format="HH:mm dd-MM-yyyy"
                                                                            variant="inline"
                                                                            openTo="date"
                                                                            label="Дата и время окончания приема заявок"
                                                                            autoOk
                                                                            disablePast
                                                                            value={values.application_deadline}
                                                                            onChange={date => {
                                                                                setFieldValue("application_deadline", date);
                                                                            }}
                                                                            onBlur={() => {
                                                                                setFieldTouched("application_deadline", true, false)
                                                                            }}
                                                                            onClose={() => {
                                                                                setFieldTouched("application_deadline", true, false)
                                                                            }}
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                </Grid>
                                                            </Grid>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Grid container direction="row" justify="flex-end" alignItems="stretch">
                                                                <Grid item>
                                                                    <Submit
                                                                        variant="contained"
                                                                        color="primary"
                                                                        type="submit"
                                                                        disabled={isSubmitting || !isValid || !dirty}
                                                                    >
                                                                        Продлить
                                                                    </Submit>
                                                                </Grid>
                                                            </Grid>
                                                        </DialogActions>
                                                    </Form>
                                                )}
                                            </Formik>
                                        )
                                    })
                                }}
                            >
                                Продлить
                            </Submit>
                        </Grid>
                    }
                    <Grid item>
                        <Submit
                            disableElevation
                            variant="contained"
                            color="primary"
                            type="button"
                            disabled={submit}
                            onClick={() => {
                                history.push(`/marketplace/request/${order.id}/edit`)
                            }}
                        >
                            Редактировать
                        </Submit>
                    </Grid>
                    <Grid item>
                        <Submit
                            disableElevation
                            variant="contained"
                            color="primary"
                            type="button"
                            disabled={submit}
                            onClick={() => {
                                setDialog({
                                    title: 'Отмена заявки',
                                    content: (
                                        <Formik
                                            initialValues={{
                                                comment: ''
                                            }}
                                            validationSchema={Yup.object().shape({
                                                comment: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
                                            })}
                                            onSubmit={(values, {setSubmitting}) => {
                                                setSubmit(true)
                                                return dispatch(ShopActions.cancel(order.id, values)).then(
                                                    () => {
                                                        setSubmit(false)
                                                        setDialog(false)
                                                        setLoading(false)
                                                    },
                                                    errors => {
                                                        if (errors) {
                                                        }
                                                        setSubmit(false)
                                                        setSubmitting(false)
                                                        setLoading(false)
                                                    }
                                                )
                                            }}
                                        >
                                            {({
                                                  dirty,
                                                  isSubmitting,
                                                  isValid
                                              }) => (
                                                <Form>
                                                    <DialogContent>
                                                        <Field
                                                            fullWidth
                                                            multiline
                                                            rows={6}
                                                            name="comment"
                                                            type="text"
                                                            label="Комментарий"
                                                            component={CustomTextField}
                                                        />
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Grid container direction="row" justify="flex-end" alignItems="stretch">
                                                            <Grid item>
                                                                <Submit
                                                                    variant="contained"
                                                                    color="primary"
                                                                    type="submit"
                                                                    disabled={isSubmitting || !isValid || !dirty}
                                                                >
                                                                    Отменить
                                                                </Submit>
                                                            </Grid>
                                                        </Grid>
                                                    </DialogActions>
                                                </Form>
                                            )}
                                        </Formik>
                                    )
                                })
                            }}
                        >
                            Отменить
                        </Submit>
                    </Grid>
                </Grid>
            </Grid>
        )

        switch (parseInt(order.status.index)) {
            case 1:
            case 2:
                action = cancel
                break
            case 5:
                action = (
                    <Grid item>
                        <Submit
                            disableElevation
                            variant="contained"
                            color="primary"
                            type="button"
                            disabled={submit}
                            onClick={() => {
                                setDialog({
                                    title: 'Укажите новые даты начала и окончания приема заявок',
                                    content: (
                                        <Formik
                                            initialValues={{
                                                application_start: null,
                                                application_deadline: null,
                                            }}
                                            validationSchema={Yup.object().shape({
                                                application_start: Yup.mixed().test('min-date', 'Некорректная дата', function (value) {
                                                    let currentDate = new Date(value);
                                                    let minDate = new Date()

                                                    return currentDate.valueOf() - minDate.valueOf() >= 0;
                                                }).required("Поле не может быть пустым или состоять из одних пробелов!"),
                                                application_deadline: Yup.mixed().test('min-date', 'Некорректная дата', function (value) {
                                                    let currentDate = new Date(value)
                                                    let diffTime = Number(orderSettings.time_to_submit_applications) * 3600000;
                                                    let minDate = this.parent.application_start ? new Date(this.parent.application_start) : new Date();

                                                    return currentDate.valueOf() - minDate.valueOf() >= diffTime
                                                }).required("Поле не может быть пустым или состоять из одних пробелов!"),
                                            })}
                                            onSubmit={(values, {setSubmitting}) => {
                                                let requestValues = {
                                                    application_start: format(values.application_start, 'yyyy-MM-dd HH:mmxxxxx'),
                                                    application_deadline: format(values.application_deadline, 'yyyy-MM-dd HH:mmxxxxx'),
                                                    from_planning: !!order?.from_planning
                                                }
                                                setSubmit(true)

                                                return dispatch(ShopActions.copy(order.id, requestValues)).then(
                                                    () => {
                                                        setSubmit(false)
                                                        setSubmitting(false)
                                                        setDialog(false)
                                                    },
                                                    errors => {
                                                        if (errors) {
                                                        }
                                                        setSubmit(false)
                                                        setSubmitting(false)
                                                    }
                                                )
                                            }}
                                        >
                                            {({
                                                  dirty,
                                                  isSubmitting,
                                                  isValid,
                                                  errors,
                                                  validateForm,
                                                  setFieldTouched,
                                                  setFieldValue,
                                                  values
                                              }) => (
                                                <Form>
                                                    <DialogContent>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                            <Grid item xs={6} className={classes.column}>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                    <KeyboardDateTimePicker
                                                                        disableToolbar
                                                                        fullWidth
                                                                        error={Boolean(errors.hasOwnProperty('application_start'))}
                                                                        helperText={errors.hasOwnProperty('application_start') ? errors.application_start : null}
                                                                        name="application_start"
                                                                        required
                                                                        format="HH:mm dd-MM-yyyy"
                                                                        variant="inline"
                                                                        ampm={false}
                                                                        openTo="date"
                                                                        label="Дата и время начала приема заявок"
                                                                        autoOk
                                                                        disablePast
                                                                        value={values.application_start}
                                                                        onChange={date => {
                                                                            setFieldValue("application_start", date);
                                                                        }}
                                                                        onClose={() => {
                                                                            setFieldTouched("application_start", true, false)
                                                                        }}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </Grid>
                                                            <Grid item xs={6} className={classes.column}>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                                                    <KeyboardDateTimePicker
                                                                        disableToolbar
                                                                        fullWidth
                                                                        error={Boolean(errors.hasOwnProperty('application_deadline'))}
                                                                        helperText={errors.hasOwnProperty('application_deadline') ? errors.application_deadline : `Обратите внимание, минимальный срок приёма предложение ${orderSettings.time_to_submit_applications} часа`}
                                                                        name="application_deadline"
                                                                        required
                                                                        format="HH:mm dd-MM-yyyy"
                                                                        variant="inline"
                                                                        openTo="date"
                                                                        ampm={false}
                                                                        label="Дата и время окончания приема заявок"
                                                                        autoOk
                                                                        disablePast
                                                                        value={values.application_deadline}
                                                                        onChange={date => {
                                                                            setFieldValue("application_deadline", date);
                                                                        }}
                                                                        onClose={() => {
                                                                            setFieldTouched("application_deadline", true, false)
                                                                            validateForm().then()
                                                                        }}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </Grid>
                                                        </Grid>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Grid container direction="row" justify="flex-end" alignItems="stretch">
                                                            <Grid item>
                                                                <Submit
                                                                    variant="contained"
                                                                    color="primary"
                                                                    type="submit"
                                                                    disabled={isSubmitting || !isValid || !dirty}
                                                                >
                                                                    Перевыставить
                                                                </Submit>
                                                            </Grid>
                                                        </Grid>
                                                    </DialogActions>
                                                </Form>
                                            )}
                                        </Formik>
                                    )
                                })
                            }}
                        >
                            Перевыставить
                        </Submit>
                    </Grid>
                )
                break
            default:
        }

        return action
    }

    useEffect(() => {
        const getOrder = async () => {
            return await dispatch(ShopActions.order(id))
        }

        if (!loading) {
            getOrder().then(order => {
                if (order.winner) {
                    let tmp = {}

                    order.winner.items.forEach((item) => {
                        tmp[item.item_id] = item.price
                    })

                    setValue(0)
                } else if ((order.status.index === 14)) {
                    setValue(0)
                }
                order.lots?.forEach((lot, idx) => {
                    lot.offers?.forEach((offer, index) => {
                        if (offer.winner) {
                            let winnerIdxs = winnerIndexes;
                            winnerIdxs[idx] = index;
                            setWinnerIndexes(winnerIdxs)
                        }
                    });
                })

                setOrder(order)
                setLoading(true)
            })
        }

    }, [dispatch, id, loading, winnerIndexes]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getSupplierStatus = (order, offer, lot) => {
        let countOffers = lot.offers.filter(offer => offer.approve).length
        let minPrice = Math.min.apply(null, lot.offers.filter(offer => (offer.approve && !offer.winner)).map(el => el.price))
        let secondWinner = lot.offers.filter(offer => (Number(offer.price) === minPrice && !offer.winner && offer.approve))

        if (!offer.active) {
            return 'Отменено';
        }
        if (order.status.index === 2 && offer.active && !offer.winner) {
            return 'Сбор заявок';
        }
        if (offer.active && offer.winner) {
            return 'Победитель';
        }
        if (lot.status.index === 8 && offer.active && !offer.winner) {
            if (offer.approve !== null) {
                return offer.approve ? 'Допущен' : 'Не допущен';
            }
            return 'В работе';
        }
        if (offer.active && offer.approve && secondWinner.length && secondWinner.length > 0 && secondWinner[0].id === offer.id && countOffers > 1) {
            return (
                <Typography> 2 место <Tooltip title="Будет выбран поставщиком, если не состоится сделка с победителем"
                                              placement="left" classes={{tooltip: classes.tooltip}}><IconButton
                    style={{"width": "24px", "height": "24px", "padding": "0"}}><HelpIcon style={{"color": "#485868"}}/></IconButton></Tooltip></Typography>)
        }
        if (offer.approve !== null) {
            return offer.approve ? '-' : 'Не допущен';
        }
        return '—';
    };

    const setTimeToEditDialog = (lots, type) => {
        let lot = lots.filter(lot => (
            (lot.offers.filter(offer => offer.approve === true).length >= 2 && lot.offers.filter(offer => offer.approve !== null && offer.active === true).length === lot.offers.filter(offer => offer.active === true).length)
            && (lot.offers.filter(offer => offer.last_price === false).length > 0)
            && lot.end_time_of_rebidding === null && [8, 3].indexOf(lot.status.index) !== -1
        ));

        if (type === 'dynamic' && lot && !!lot.length && !dialogReject) {
            return setDialogReject({
                title: 'Укажите время окончания торгов',
                content: (
                    <Formik
                        initialValues={{
                            end_time_of_rebidding: lot[0].application_deadline
                        }}
                        validationSchema={Yup.object().shape({
                            end_time_of_rebidding: Yup
                                .mixed()
                                .required(`Поле не может быть пустым!`)
                                .test('min-date', 'Минимальное время переторжки один час', function (value) {
                                    let currentDate = new Date(value);
                                    let minDate = new Date()
                                    minDate.setDate(new Date().getDate())
                                    minDate.setHours(minDate.getHours() + 1)

                                    return currentDate.valueOf() - minDate.valueOf() >= 0;
                                }),
                        })}
                        onSubmit={(values, {setSubmitting}) => {
                            return dispatch(ShopActions.setEndTimeOfTrading(lot[0].id, values)).then(
                                () => {
                                    setDialogReject(false)
                                },
                                errors => {
                                    if (errors) {
                                    }
                                    setSubmitting(false)
                                }
                            )
                        }}
                    >
                        {({
                              dirty,
                              isSubmitting,
                              isValid,
                              values,
                              setFieldTouched,
                              setFieldValue,
                              errors,
                              touched
                          }) => (
                            <Form>
                                <DialogContent>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                                        <KeyboardDateTimePicker
                                            disableToolbar
                                            fullWidth
                                            error={Boolean(errors.hasOwnProperty('end_time_of_rebidding') && touched.hasOwnProperty('end_time_of_rebidding'))}
                                            helperText={(errors.hasOwnProperty('end_time_of_rebidding') && touched.hasOwnProperty('end_time_of_rebidding')) ? errors.end_time_of_rebidding : null}
                                            name="end_time_of_rebidding"
                                            required
                                            format="HH:mm dd-MM-yyyy"
                                            variant="inline"
                                            openTo="date"
                                            label="Время окончания торгов"
                                            autoOk
                                            disablePast
                                            value={values.end_time_of_rebidding}
                                            onChange={date => {
                                                setFieldValue("end_time_of_rebidding", date);
                                            }}
                                            onBlur={() => {
                                                setFieldTouched("end_time_of_rebidding", true, false)
                                            }}
                                            onClose={() => {
                                                setFieldTouched("end_time_of_rebidding", true, false)
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </DialogContent>
                                <DialogActions>
                                    <Grid container direction="row" justify="flex-end"
                                          alignItems="stretch">
                                        <Grid item>
                                            <Submit
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={isSubmitting || !isValid || !dirty}
                                            >
                                                Ок
                                            </Submit>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                )
            })
        }
        return null
    }

    const getExpirationDate = (item) => {
        if (item.expiration_date_type === 'warranty_period') {
            return item.expiration_date_number ? endings(parseInt(item.expiration_date_number), ['месяц', 'месяца', 'месяцев']) : null;
        } else if (item.expiration_date_type === 'expiration_date_percent') {
            return item.expiration_date_number ? `${item.expiration_date_number} %` : null;
        } else {
            return item.expiration_date ? item.expiration_date + ' г.' : null;
        }
    }

    const handleClose = () => {
        setOpenCard(false)
        setCardCompany(null)
    }

    const displayContractTabStatuses = () => [3, 5, 7].includes(order.status.index)

    return (order && orderSettings) ? (
            <Grid item className={classes.content}>
                <Paper>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item className={classes.full}>
                            <Grid container className={classes.fullWidth} direction="column" justify="space-between"
                                  alignItems="stretch">
                                <Grid item className={classes.fullWidth}>
                                    <Grid container className={classes.fullWidth} direction="column"
                                          justify="space-between" alignItems="stretch">
                                        <Grid item className={classes.header}>
                                            <Grid container className={classes.fullWidth} direction="column"
                                                  justify="space-between" alignItems="stretch">
                                                <Grid item>
                                                    <Grid container className={classes.fullWidth} direction="row"
                                                          justify="space-between" alignItems="stretch">
                                                        <Grid item>
                                                            <Typography component="span" className={classes.title}>
                                                                {order?.from_planning ? `Публикация № ${order.id}` : `Запрос № ${order.id}`}
                                                            </Typography>
                                                            <Typography component="span">
                                                                {order.updated ? `Изменён ${format(new Date(order.updated.created), 'dd.MM.yyyy HH:mm', {locale: ru})}${order.updated.comment ? ` - ${order.updated.comment}` : ''}` : ''}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="button">
                                                                {order.status.index === 8 ? "В работе" : order.status.name}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="button">
                                                        Подача предложений
                                                        с {format(new Date(order.application_start), 'dd.MM.yyyy HH:mm')} до {format(new Date(order.application_deadline), 'dd.MM.yyyy HH:mm')}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.input}>
                                            <Grid container direction="column" justify="center" alignItems="stretch">
                                                <Grid item className={classes.fullWidth}>
                                                    <Tabs
                                                        value={value}
                                                        indicatorColor="primary"
                                                        textColor="primary"
                                                        onChange={handleChange}
                                                    >
                                                        {displayContractTabStatuses() && <Tab value={0} label="Договор" />}
                                                        <Tab value={1} label="Информация о ценовом запросе" />
                                                        {[1, 2].indexOf(order.status.index) === -1 && <Tab value={2} className={classes.tabBadge} label="Предложения поставщиков" />}
                                                        {!!Object.keys(order.log).length && <Tab value={3} className={classes.tabBadge} label="История" />}
                                                    </Tabs>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.fullWidth}>
                                    <Grid className={classes.tab} container direction="column" justify="space-between"
                                          alignItems="stretch">
                                        <Grid item className={classes.inputContent}>
                                            {displayContractTabStatuses() &&
                                                <TabPanel value={value} index={0}>
                                                    <Log order={order} setLoading={setLoading}/>
                                                </TabPanel>
                                            }
                                            <TabPanel value={value} index={1}>
                                                <Grid container direction="column" justify="flex-start"
                                                      alignItems="stretch" spacing={2}>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="space-between"
                                                              alignItems="flex-start" spacing={2}>
                                                            <Grid item xs={3}>
                                                                <Grid container direction="column" justify="flex-start"
                                                                      alignItems="stretch" spacing={2}>
                                                                    <Grid item>
                                                                        <Typography variant="button">
                                                                            Название:
                                                                        </Typography>
                                                                        <Typography>
                                                                            {order.name}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Grid container direction="column" justify="flex-start"
                                                                      alignItems="stretch" spacing={2}>
                                                                    <Grid item>
                                                                        <Typography variant="button">
                                                                            Номер закупки:
                                                                        </Typography>
                                                                        <Typography>
                                                                            {order.unscheduled_purchase ? 'Внеплановая' : order.purchase_number}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Grid container direction="row" justify="flex-end" alignItems="flex-end">
                                                                    {order.files.find((file) => file.type.key === 'draft_agreement') &&
                                                                        <Grid item>
                                                                            <Button
                                                                                className={classes.download}
                                                                                endIcon={<GetAppIcon/>}
                                                                                href={process.env.REACT_APP_HOST_API + `/shop/order/${order.id}/file/${order.files.find((file) => file.type.key === 'draft_agreement').id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                            >
                                                                                Скачать проект договора
                                                                            </Button>
                                                                        </Grid>
                                                                    }
                                                                    {order.files.find((file) => file.type.key === 'technical_task') &&
                                                                        <Grid item>
                                                                            <Button
                                                                                className={classes.download}
                                                                                endIcon={<GetAppIcon/>}
                                                                                href={process.env.REACT_APP_HOST_API + `/shop/order/${order.id}/file/${order.files.find((file) => file.type.key === 'technical_task').id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                            >
                                                                                Скачать техническое задание
                                                                            </Button>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={2}>
                                                            {order?.from_planning && <Grid item xs={3}>
                                                                <Grid container direction="column" justify="flex-start"
                                                                      alignItems="stretch" spacing={2}>
                                                                    <Grid item>
                                                                        <Typography variant="button">
                                                                            Способ проведения:
                                                                        </Typography>
                                                                        <Typography>
                                                                            {order?.conduction_method?.name}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>}
                                                            {!order?.from_planning && <Grid item xs={3}>
                                                                <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                    <Grid item>
                                                                        <Typography variant="button">
                                                                            Тип:
                                                                        </Typography>
                                                                        <Typography>
                                                                            {order.type === 'static' ? 'Статический запрос' : 'Динамический запрос'}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>}
                                                            <Grid item xs={6}>
                                                                <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                                                                    <Grid item>
                                                                        <Typography variant="button">
                                                                            Категория:
                                                                        </Typography>
                                                                        <Typography>
                                                                            {order?.category?.name}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                    <Grid item>
                                                                        <Typography variant="button">
                                                                            НМЦД:
                                                                        </Typography>
                                                                        <Typography>
                                                                            {order?.is_unit_price ? parseCurrency(order?.lots?.reduce((total, lot) => total + (lot?.max_contract_price ?? 0), 0), order?.currency?.code) : parseCurrency(order?.average_price, order?.currency?.code)}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                                            <Grid item xs={12} style={{marginBottom: "10px"}}>
                                                                <Typography variant="button">
                                                                    Документы:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                                                    {order.lots?.map((lot) => {
                                                                        return filterLotFiles(lot.files, lot.status).map((file, index) => {
                                                                            switch (file.type?.key) {
                                                                                case 'protocol':
                                                                                case 'rebidding_protocol':
                                                                                    return (
                                                                                        <Grid item key={index}>
                                                                                            <SignatureLink
                                                                                                file={file}
                                                                                                name={`${file.type.name} (Лот № ${lot.number ?? lot.index})`}
                                                                                                href={`/shop/order/${order.id}/file/${file.id}`}
                                                                                                onContinue={async (sign) => {
                                                                                                    if (sign) {
                                                                                                        await dispatch(ShopActions.sign(order.id, file.id, {sign: sign}))
                                                                                                    }
                                                                                                }}
                                                                                                required={false}
                                                                                            />
                                                                                        </Grid>
                                                                                    )
                                                                                default:
                                                                                    return (
                                                                                        <Grid item key={index}>
                                                                                            <Typography gutterBottom>
                                                                                                <Link
                                                                                                    href={process.env.REACT_APP_HOST_API + `/shop/order/${order.id}/file/${file.id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                                    className={classes.link}
                                                                                                >
                                                                                                    {`${file.type?.name ?? file.name} (Лот № ${lot.number ?? lot.index})`}
                                                                                                </Link>
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    )
                                                                            }
                                                                        })
                                                                    })}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <TableContainer className={classes.tableContainer}>
                                                            <Table stickyHeader aria-label="sticky table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        {[
                                                                            {
                                                                                id: '0',
                                                                                label: 'Лот №',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '1',
                                                                                label: 'Объект закупки',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '2',
                                                                                label: 'Кол-во',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '3',
                                                                                label: 'НМЦ за единицу',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '4',
                                                                                label: 'НМЦ позиции',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '6',
                                                                                label: 'Срок годности',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '5',
                                                                                label: 'НМЦ лота',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '7',
                                                                                label: 'Планируемый месяц исполнения договора',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '9',
                                                                                label: 'Отсрочка платежа, дней',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '10',
                                                                                label: 'Окончание приёма заявок',
                                                                                format: (value) => value.toLocaleString(),
                                                                            }
                                                                        ].map((column, index) => (
                                                                            <TableCell key={column.id} colSpan={!index ? 2 : 1}>
                                                                                {column.label}
                                                                            </TableCell>
                                                                        ))}
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {order.lots.map((lot) => (
                                                                        lot.items.map((item, index) => (
                                                                            <TableRow key={index}>
                                                                                {!index ? (
                                                                                    <React.Fragment>
                                                                                        <TableCell component="th" scope="row" rowSpan={lot.items.length}>
                                                                                            {lot.number ? lot.number : lot.index}
                                                                                        </TableCell>
                                                                                        <TableCell component="th" scope="row" rowSpan={lot.items.length}>
                                                                                            <IconButton
                                                                                                size="small"
                                                                                                onClick={() => {
                                                                                                    const textField = document.createElement('textarea')
                                                                                                    textField.innerText = `${window.location.origin}/lots/${lot.id}`
                                                                                                    document.body.appendChild(textField)
                                                                                                    textField.select()
                                                                                                    document.execCommand('copy')
                                                                                                    textField.remove()
                                                                                                }}
                                                                                            >
                                                                                                <FileCopy fontSize="small" />
                                                                                            </IconButton>
                                                                                        </TableCell>
                                                                                    </React.Fragment>
                                                                                ) : null}
                                                                                <TableCell>
                                                                                    <Tooltip classes={{tooltip: classes.lightTooltip}} title={item.name} placement="bottom">
                                                                                        <span>{item.name}</span>
                                                                                    </Tooltip>
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {item.amount || item.amount === 0 ? round(item.amount, 2) : ''} {item.unit ? item.unit.short : null}
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {parseCurrency(item.average_price, order.currency.code)}
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {order?.is_unit_price ? null : parseCurrency(item.average_price * item.amount, order.currency.code)}
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {getExpirationDate(item)}
                                                                                </TableCell>
                                                                                {!index &&
                                                                                    <React.Fragment>
                                                                                        <TableCell rowSpan={lot.items.length} align="center">
                                                                                           {parseCurrency(order?.is_unit_price ?  lot.max_contract_price : lot.average_price, order.currency.code)}
                                                                                        </TableCell>
                                                                                        <TableCell rowSpan={lot.items.length} align="center">
                                                                                            {lot.execution_month}
                                                                                        </TableCell>
                                                                                        <TableCell rowSpan={lot.items.length} align="center">
                                                                                            {lot.postponement}
                                                                                        </TableCell>
                                                                                        <TableCell rowSpan={lot.items.length} align="center">
                                                                                            {format(new Date(order.application_deadline), 'HH:mm dd.MM.yyyy')} г.
                                                                                        </TableCell>
                                                                                    </React.Fragment>
                                                                                }
                                                                            </TableRow>
                                                                        ))
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={value} index={2}>
                                                {(openCard && cardCompany) ? <Card contractID={null} companyType={'поставщика'} company={cardCompany} open={openCard} onClose={handleClose}/> : null}
                                                <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                                    <Grid item>
                                                        <TableContainer className={classes.tableContainer}>
                                                            <Table stickyHeader aria-label="sticky table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        {[
                                                                            {
                                                                                id: '0',
                                                                                label: 'Лот №',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '1',
                                                                                label: '',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '2',
                                                                                label: 'Поставщик',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '3',
                                                                                label: 'Срок поставки',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '4',
                                                                                label: 'Цена предложения, с учетом НДС',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '5',
                                                                                label: 'Статус',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '6',
                                                                                label: 'Дата подачи',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                            {
                                                                                id: '7',
                                                                                label: 'Подтверждение',
                                                                                format: (value) => value.toLocaleString(),
                                                                            },
                                                                        ].map((column) => (
                                                                            <TableCell key={column.id}>
                                                                                {column.label}
                                                                            </TableCell>
                                                                        ))}
                                                                        {order.type === 'dynamic' &&
                                                                            <TableCell key={'8'}>
                                                                                Редактирование до
                                                                            </TableCell>
                                                                        }
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {value === 2 && setTimeToEditDialog(order.lots, order.type)}
                                                                    {order.lots.map(lot => (
                                                                        lot.offers.map((offer, index) => (
                                                                            <React.Fragment key={offer.created}>
                                                                                <TableRow key={index}
                                                                                          className={offer.winner ? classes.winner : null}
                                                                                          onClick={() => {
                                                                                              if (offer.files.filter(el => (el.type === null || el.type.key === 'price_offer')).length === 0) {
                                                                                                  return;
                                                                                              }
                                                                                              setOpenRow(!openRow || (openRow !== offer.id) ? offer.id : false)
                                                                                          }}
                                                                                          style={{cursor: "pointer"}}>
                                                                                    {!index &&
                                                                                        <TableCell component="th"
                                                                                                   scope="row"
                                                                                                   rowSpan={lot.offers.length * 2}
                                                                                                   onClick={(e) => e.stopPropagation()}>
                                                                                            {lot.number ? lot.number : lot.index}
                                                                                        </TableCell>
                                                                                    }
                                                                                    <TableCell>
                                                                                        {offer.files.filter(el => (el.type === null || el.type.key === 'price_offer')).length !== 0 &&
                                                                                            <IconButton
                                                                                                aria-label="expand row"
                                                                                                size="small">
                                                                                                {openRow === offer.id ?
                                                                                                    <KeyboardArrowUpIcon/> :
                                                                                                    <KeyboardArrowDownIcon/>}
                                                                                            </IconButton>
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        className={!offer.active ? classes.line : classes.companyClick}
                                                                                        onClick={(e) => {
                                                                                            e.stopPropagation();
                                                                                            if (offer.company) {
                                                                                                setCardCompany(offer.company)
                                                                                                setOpenCard(true)
                                                                                            }
                                                                                        }}>
                                                                                        {offer.company ? offer.company.legal_detail.name : `Поставщик № ${index + 1}`}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {offer.revision.contract_time}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {parseCurrency(offer.price, order.currency.code)}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {getSupplierStatus(order, offer, lot)}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {format(new Date(offer.created), 'dd.MM.yyyy HH:mm')}
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        style={{minWidth: "235px"}}>
                                                                                        {offer.approve === null && offer.active && lot.status.index === 8 &&
                                                                                            <React.Fragment>
                                                                                                <ButtonIcon
                                                                                                    className={classes.approve}
                                                                                                    onClick={(e) => {
                                                                                                        e.stopPropagation()
                                                                                                        return dispatch(ShopActions.approveOffer(offer.id, {
                                                                                                            approve: true
                                                                                                        })).then(() => {
                                                                                                        })
                                                                                                    }}
                                                                                                    children={"Допущен"}/>
                                                                                                <Submit
                                                                                                    disableElevation
                                                                                                    variant="contained"
                                                                                                    type="button"
                                                                                                    className={classes.reject}
                                                                                                    onClick={(e) => {
                                                                                                        e.stopPropagation()
                                                                                                        setDialogReject({
                                                                                                            title: 'Обоснование отказа',
                                                                                                            content: (
                                                                                                                <Formik
                                                                                                                    initialValues={{
                                                                                                                        comment: '',
                                                                                                                        approve: false,
                                                                                                                    }}
                                                                                                                    validationSchema={Yup.object().shape({
                                                                                                                        comment: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
                                                                                                                    })}
                                                                                                                    onSubmit={(values, {setSubmitting}) => {
                                                                                                                        return dispatch(ShopActions.approveOffer(offer.id, values)).then(
                                                                                                                            () => {
                                                                                                                                setDialogReject(false)
                                                                                                                            },
                                                                                                                            errors => {
                                                                                                                                if (errors) {
                                                                                                                                }
                                                                                                                                setSubmitting(false)
                                                                                                                            }
                                                                                                                        )
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {({
                                                                                                                          dirty,
                                                                                                                          isSubmitting,
                                                                                                                          isValid
                                                                                                                      }) => (
                                                                                                                        <Form>
                                                                                                                            <DialogContent>
                                                                                                                                <Field
                                                                                                                                    fullWidth
                                                                                                                                    multiline
                                                                                                                                    rows={6}
                                                                                                                                    name="comment"
                                                                                                                                    type="text"
                                                                                                                                    label="Комментарий"
                                                                                                                                    component={CustomTextField}
                                                                                                                                />
                                                                                                                            </DialogContent>
                                                                                                                            <DialogActions>
                                                                                                                                <Grid
                                                                                                                                    container
                                                                                                                                    direction="row"
                                                                                                                                    justify="flex-end"
                                                                                                                                    alignItems="stretch">
                                                                                                                                    <Grid
                                                                                                                                        item>
                                                                                                                                        <Submit
                                                                                                                                            variant="contained"
                                                                                                                                            color="primary"
                                                                                                                                            type="submit"
                                                                                                                                            disabled={isSubmitting || !isValid || !dirty}
                                                                                                                                        >
                                                                                                                                            Ок
                                                                                                                                        </Submit>
                                                                                                                                    </Grid>
                                                                                                                                </Grid>
                                                                                                                            </DialogActions>
                                                                                                                        </Form>
                                                                                                                    )}
                                                                                                                </Formik>
                                                                                                            )
                                                                                                        })
                                                                                                    }}
                                                                                                >
                                                                                                    Не допущен
                                                                                                </Submit>
                                                                                            </React.Fragment>
                                                                                        }
                                                                                    </TableCell>
                                                                                    {order.type === 'dynamic' && !index &&
                                                                                        <TableCell component="th" scope="row" rowSpan={lot.offers.length * 2} onClick={(e) => e.stopPropagation()}>
                                                                                            {lot.end_time_of_rebidding &&
                                                                                                <Typography style={{
                                                                                                    minWidth: "170px",
                                                                                                }}>{format(new Date(lot.end_time_of_rebidding), 'H:mm PP', {locale: ru})}<br/>(мск {format(new Date(new Date(lot.end_time_of_rebidding).toLocaleString("en-US", {timeZone: "Europe/Moscow"})), 'H:mm PP', {locale: ru})})</Typography>
                                                                                            }
                                                                                        </TableCell>
                                                                                    }
                                                                                </TableRow>
                                                                                {offer.files.filter(el => (el.type === null || el.type.key === 'price_offer')).length !== 0 &&
                                                                                    <TableRow key={offer.id}>
                                                                                        <TableCell style={{
                                                                                            paddingBottom: 0,
                                                                                            paddingTop: 0
                                                                                        }} colSpan={7}>
                                                                                            <Collapse
                                                                                                in={offer.id === openRow}
                                                                                                timeout="auto"
                                                                                                unmountOnExit
                                                                                            >
                                                                                                <Grid container direction="column" justify="flex-start" alignItems="flex-start" style={{margin: "20px 0"}}>
                                                                                                    {offer.files.find((file) => (file.type !== null && file.type.key === 'price_offer')) &&
                                                                                                        <Grid item className={classes.fileItem}>
                                                                                                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                                                                                <Grid item>
                                                                                                                    <Button
                                                                                                                      className={classes.download}
                                                                                                                      endIcon={< GetAppIcon/>}
                                                                                                                      href={process.env.REACT_APP_HOST_API + `/shop/order/${order.id}/offer/file/${offer.files.sort(function(a, b) {
                                                                                                                          return - ( a.id - b.id );
                                                                                                                      }).find((file) => (file.type !== null && file.type.key === 'price_offer')).id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                                                    >
                                                                                                                        Скачать Ценовое предложение
                                                                                                                    </Button>
                                                                                                                </Grid>
                                                                                                                {offer.files.sort(function(a, b) {
                                                                                                                    return - ( a.id - b.id );
                                                                                                                }).find(file => (file.type?.key === 'price_offer')).sign ? (
                                                                                                                  <Grid item>
                                                                                                                      <DownloadSignature size="small" file={offer.files.find(file => (file.type?.key === 'price_offer'))} />
                                                                                                                  </Grid>
                                                                                                                ) : null}
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    }
                                                                                                    {offer.files.find((file) => (file.type !== null && file.type.key === 'price_rationale')) &&
                                                                                                        <Grid item className={classes.fileItem}>
                                                                                                            <Button
                                                                                                                className={classes.download}
                                                                                                                endIcon={
                                                                                                                    < GetAppIcon/>}
                                                                                                                href={process.env.REACT_APP_HOST_API + `/shop/order/${order.id}/offer/file/${offer.files.find((file) => (file.type !== null && file.type.key === 'price_rationale')).id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                                            >
                                                                                                                Скачать Обоснование цены
                                                                                                            </Button>
                                                                                                        </Grid>
                                                                                                    }
                                                                                                    {offer.files.find((file) => (file.type !== null && file.type.key === 'specification')) &&
                                                                                                        <Grid item className={classes.fileItem}>
                                                                                                            <Button
                                                                                                                className={classes.download}
                                                                                                                endIcon={
                                                                                                                    < GetAppIcon/>}
                                                                                                                href={process.env.REACT_APP_HOST_API + `/shop/order/${order.id}/offer/file/${offer.files.find((file) => (file.type !== null && file.type.key === 'specification')).id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                                            >
                                                                                                                Скачать Спецификацию
                                                                                                            </Button>
                                                                                                        </Grid>
                                                                                                    }
                                                                                                    {offer.files.filter(el => el.type === null).map(file => (
                                                                                                        <Grid item className={classes.fileItem} key={file.id}>
                                                                                                            <Button
                                                                                                                className={classes.download}
                                                                                                                endIcon={
                                                                                                                    < GetAppIcon/>}
                                                                                                                href={process.env.REACT_APP_HOST_API + `/shop/order/${order.id}/offer/file/${file.id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                                                                                                            >
                                                                                                                Скачать {file.description}
                                                                                                            </Button>
                                                                                                        </Grid>
                                                                                                    ))
                                                                                                    }
                                                                                                </Grid>
                                                                                            </Collapse>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                }
                                                                            </React.Fragment>
                                                                        ))
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            {!!Object.keys(order.log).length ? (
                                                <TabPanel value={value} index={3}>
                                                    <History order={order}/>
                                                </TabPanel>
                                            ) : null}
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.footer}>
                                        <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
                                            <Grid item className={classes.footerContent}>
                                                <Grid container className={classes.footerContainer} direction="row" justify="space-between" alignItems="center" spacing={2}>
                                                    <Grid item>
                                                        <Grid container direction="row" justify="center" alignItems="stretch" spacing={2}>
                                                            {(order.files.find((file) => file.type.key === 'notice')) ? (
                                                              <Grid item>
                                                                  <SignatureButton
                                                                    file={order.files.find((file) => file.type.key === 'notice')}
                                                                    name="Извещение"
                                                                    href={`/shop/order/${order.id}/file/${order.files.find((file) => file.type.key === 'notice').id}`}
                                                                    onContinue={async (sign) => {
                                                                        if (sign) {
                                                                            await dispatch(ShopActions.sign(order.id, order.files.find((file) => file.type.key === 'notice').id, {sign: sign}))
                                                                        }
                                                                    }}
                                                                    required={false}
                                                                  />
                                                              </Grid>
                                                            ) : null}
                                                        </Grid>
                                                    </Grid>
                                                    {getAction(order)}
                                                    {dialog ? (
                                                        <Dialog
                                                            open={!!dialog}
                                                            onClose={() => {
                                                                setDialog(false)
                                                            }}
                                                            fullWidth={true}
                                                            maxWidth={order.status.index === 5 ? 'md' : 'sm'}
                                                        >
                                                            <DialogTitle>{dialog.title}</DialogTitle>
                                                            {dialog.content}
                                                        </Dialog>
                                                    ) : null}
                                                </Grid>
                                                {dialogReject ? (
                                                    <Dialog
                                                        open={!!dialogReject}
                                                        onClose={() => {
                                                            setDialogReject(false)
                                                        }}
                                                        fullWidth={true}
                                                        maxWidth={'sm'}
                                                    >
                                                        <DialogTitle>{dialogReject.title}</DialogTitle>
                                                        {dialogReject.content}
                                                    </Dialog>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        ) : null
}
