import {HttpService} from "../../../App/services/http"

export const PriceService = {
    items,
    add,
    edit,
    remove,
    positions
}

function items(params) {
    return HttpService.get(`/report/control/price/items`, params)
}

function add(values) {
    return HttpService.post("/report/control/price/item", values)
        .then(response => {
            return response.item
        })
}

function edit(id, values) {
    return HttpService.put(`/report/control/price/item/${id}`, values)
        .then(response => {
            return response.item
        })
}

function remove(id) {
    return HttpService.deleteMethod(`/report/control/price/item/${id}`)
}

function positions(id, params) {
    return HttpService.get(`/report/control/price/item/${id}/positions`, params)
}
